export const initialPhoneNumber = {
    phone_number: ""
}

export const initialExtension = {
    call_record_extension: ""
}

export const initialAdditionalFields = {
    additional_field_id: 0,
    values: [{
        additional_field_value: 0
    }]
}

export const initialUpdateAdditionalField = {
    additional_field_id: 0,
    additional_field_value: 0
}

export const initialUpdateCallEvaluationScore = {
    call_record_evaluation_id: 0,
    call_record_evaluation_score: 0
}

export const initialAutoCompleteAdditional = {
    organization_additional_field_option_id: 0,
    organization_additional_field_option_value: "none",
}


export const initialFilterData = {
    start_date: null,
    end_date: null,
    phoneNumber: "",
    extension: "",
    category: [],
    purpose: [],
    complexity: [],
    agents: [],
    call_record_additional_field: [],
    flag_alert: null
}

export const initialAgentData = {
    call_center_id: 0,
    agent_name: ""
}

export const cardDetailsArray = [
    {
        evaluation_rating: 85,
        audio_file_id: 1,
        audio_file_name: "83960175-4253(11846059).mp4",
        audio_file_URL: "https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3",
        audio_file_duration: "00:22",
        phone_number_value: "83960175-4253",
        audio_purpose: {
            audio_purpose_id: 1,
            audio_purpose_value: "General Inquiries"
        },
        audio_complexity: {
            audio_complexity_id: 1,
            audio_complexity_value: "Low"
        },
        audio_agent: [
            {
                agent_id: 0,
                agent_name: ""
            }
        ],
        audio_transcipted_data: [
            {
                transcripted_id: 1,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Hii."
            },
            {
                transcripted_id: 2,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "Hello Good Morning."
            },
            {
                transcripted_id: 3,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Good Morning."
            },
            {
                transcripted_id: 4,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "How can i help you?"
            }
        ],
        audio_call_analysis_data: [
            {
                audio_call_analysis_id: 1,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 2,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 3,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            }
        ],
        audio_evaluation_data: {
            attention_empathy: {
                warmth_in_the_greeting_and_farewell: null,
                adequate_management_of_patient_emotions: 100,
                active_listening_and_understanding_of_patient_needs: 100
            },
            knowledge_of_the_service: {
                accuracy_and_clarity_of_the_information_provided: 100,
            },
            call_process: {
                efficiency_in_resolving_the_reason_for_the_call: 100,
                compliance_with_internal_procedures_and_policies: 100
            },
            sales_promotion_and_appointments: {
                ability_to_identify_sales_opportunities_during_the_call: 87,
                effective_presentation_of_promotions_and_medical_plans: null
            },
            communication_and_professionalism: {
                proper_use_of_tone_and_voice_modulation: 100 
            }
        },
        audio_date_received: "24-08-2021 17:08"
    },
    {
        evaluation_rating: 76,
        audio_file_id: 2,
        audio_file_name: "83960175-4254(11846059).mp4",
        audio_file_URL: "https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3",
        audio_file_duration: "00:33",
        phone_number_value: "83960175-4254",
        audio_purpose: {
            audio_purpose_id: 2,
            audio_purpose_value: "Medical Appointments"
        },
        audio_complexity: {
            audio_complexity_id: 2,
            audio_complexity_value: "Moderate"
        },
        audio_agent: [
            {
                agent_id: 0,
                agent_name: ""
            }
        ],
        audio_transcipted_data: [
            {
                transcripted_id: 1,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 2,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            },
            {
                transcripted_id: 3,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 4,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            }
        ],
        audio_call_analysis_data: [
            {
                audio_call_analysis_id: 1,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 2,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 3,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            }
        ],
        audio_evaluation_data: {
            attention_empathy: {
                warmth_in_the_greeting_and_farewell: null,
                adequate_management_of_patient_emotions: 100,
                active_listening_and_understanding_of_patient_needs: 100
            },
            knowledge_of_the_service: {
                accuracy_and_clarity_of_the_information_provided: 100,
            },
            call_process: {
                efficiency_in_resolving_the_reason_for_the_call: 100,
                compliance_with_internal_procedures_and_policies: 100
            },
            sales_promotion_and_appointments: {
                ability_to_identify_sales_opportunities_during_the_call: 100,
                effective_presentation_of_promotions_and_medical_plans: null
            },
            communication_and_professionalism: {
                proper_use_of_tone_and_voice_modulation: 100 
            }
        },
        audio_date_received: "24-08-2021 17:08"
    },
    {
        evaluation_rating: 34,
        audio_file_id: 3,
        audio_file_name: "83960175-4255(11846059).mp4",
        audio_file_URL: "https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3",
        audio_file_duration: "00:44",
        phone_number_value: "83960175-4255",
        audio_purpose: {
            audio_purpose_id: 5,
            audio_purpose_value: "Urgent Medical Inquiries"
        },
        audio_complexity: {
            audio_complexity_id: 4,
            audio_complexity_value: "Critical"
        },
        audio_agent: [
            {
                agent_id: 1,
                agent_name: "Name 1"
            }
        ],
        audio_transcipted_data: [
            {
                transcripted_id: 1,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 2,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            },
            {
                transcripted_id: 3,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 4,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            }
        ],
        audio_call_analysis_data: [
            {
                audio_call_analysis_id: 1,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 2,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 3,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            }
        ],
        audio_evaluation_data: {
            attention_empathy: {
                warmth_in_the_greeting_and_farewell: null,
                adequate_management_of_patient_emotions: 100,
                active_listening_and_understanding_of_patient_needs: 100
            },
            knowledge_of_the_service: {
                accuracy_and_clarity_of_the_information_provided: 100,
            },
            call_process: {
                efficiency_in_resolving_the_reason_for_the_call: 100,
                compliance_with_internal_procedures_and_policies: 100
            },
            sales_promotion_and_appointments: {
                ability_to_identify_sales_opportunities_during_the_call: 100,
                effective_presentation_of_promotions_and_medical_plans: null
            },
            communication_and_professionalism: {
                proper_use_of_tone_and_voice_modulation: 100 
            }
        },
        audio_date_received: "24-08-2021 17:08"
    },
    {
        evaluation_rating: 52,
        audio_file_id: 3,
        audio_file_name: "83960175-4255(11846059).mp4",
        audio_file_duration: "00:44",
        phone_number_value: "83960175-4255",
        audio_purpose: {
            audio_purpose_id: 5,
            audio_purpose_value: "Urgent Medical Inquiries"
        },
        audio_complexity: {
            audio_complexity_id: 4,
            audio_complexity_value: "Critical"
        },
        audio_agent: [
            {
                agent_id: 1,
                agent_name: "Name 1"
            }
        ],
        audio_transcipted_data: [
            {
                transcripted_id: 1,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 2,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            },
            {
                transcripted_id: 3,
                transcripted_person_name: "Person 1",
                transcripted_message_vale: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                transcripted_id: 4,
                transcripted_person_name: "Person 2",
                transcripted_message_vale: "When a unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it."
            }
        ],
        audio_call_analysis_data: [
            {
                audio_call_analysis_id: 1,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 2,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            },
            {
                audio_call_analysis_id: 3,
                audio_call_analysis_answer: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            }
        ],
        audio_evaluation_data: {
            attention_empathy: {
                warmth_in_the_greeting_and_farewell: null,
                adequate_management_of_patient_emotions: 100,
                active_listening_and_understanding_of_patient_needs: 100
            },
            knowledge_of_the_service: {
                accuracy_and_clarity_of_the_information_provided: 100,
            },
            call_process: {
                efficiency_in_resolving_the_reason_for_the_call: 100,
                compliance_with_internal_procedures_and_policies: 100
            },
            sales_promotion_and_appointments: {
                ability_to_identify_sales_opportunities_during_the_call: 100,
                effective_presentation_of_promotions_and_medical_plans: null
            },
            communication_and_professionalism: {
                proper_use_of_tone_and_voice_modulation: 100 
            }
        },
        audio_date_received: "24-08-2021 17:08"
    }
]

export const purposeCallLists = [
    {
        purpose_id: 1,
        purpose_value: "General Inquiries"
    },
    {
        purpose_id: 2,
        purpose_value: "Medical Appointments"
    },
    {
        purpose_id: 3,
        purpose_value: "Administrative Inquiries"
    },
    {
        purpose_id: 4,
        purpose_value: "Non-Urgent Medical Inquiries"
    },
    {
        purpose_id: 5,
        purpose_value: "Urgent Medical Inquiries"
    },
    {
        purpose_id: 6,
        purpose_value: "Complaints and Claims"
    },
    {
        purpose_id: 7,
        purpose_value: "Inquiries About Medical Procedures"
    },
    {
        purpose_id: 8,
        purpose_value: "Inquiries About Medications"
    },
    {
        purpose_id: 9,
        purpose_value: "Technical Support for Digital Tools"
    }
]

export const complexityTypes = [
    {
        complexity_id: 1,
        complexity_value: "Low"
    },
    {
        complexity_id: 2,
        complexity_value: "Moderate"
    },
    {
        complexity_id: 3,
        complexity_value: "High"
    },
    {
        complexity_id: 4,
        complexity_value: "Critical"
    },
]

export const agentLists = [
    {
        agent_id: 1,
        agent_name: "Name 1"
    }
]

export function calculateEvaluation(parameter_name, parameter) {
    if (parameter_name === "attention_empathy") {
        return ((parameter.warmth_in_the_greeting_and_farewell === null ? 0 : (5*parameter.warmth_in_the_greeting_and_farewell/100)) + (parameter.adequate_management_of_patient_emotions === null ? 0 : (5*parameter.adequate_management_of_patient_emotions/100)) + (parameter.active_listening_and_understanding_of_patient_needs === null ? 0 : (5*parameter.active_listening_and_understanding_of_patient_needs/100))).toFixed(2)
    }
    else if (parameter_name === "knowledge_of_the_service") {
        return (parameter.accuracy_and_clarity_of_the_information_provided === null ? 0 : (25*parameter.accuracy_and_clarity_of_the_information_provided)/100).toFixed(2)
    }
    else if (parameter_name === "call_process") {
        return ((parameter.efficiency_in_resolving_the_reason_for_the_call === null ? 0 : (10*parameter.efficiency_in_resolving_the_reason_for_the_call)/100) + (parameter.compliance_with_internal_procedures_and_policies === null ? 0 : (10*parameter.compliance_with_internal_procedures_and_policies)/100)).toFixed(2)
    }
    else if (parameter_name === "sales_promotion_and_appointments") {
        return ((parameter.ability_to_identify_sales_opportunities_during_the_call === null ? 0 : (20*parameter.ability_to_identify_sales_opportunities_during_the_call)/100) + (parameter.effective_presentation_of_promotions_and_medical_plans === null ? 0 : (10*parameter.effective_presentation_of_promotions_and_medical_plans)/100)).toFixed(2)
    }
    else if (parameter_name === "communication_and_professionalism") {
        return (parameter.proper_use_of_tone_and_voice_modulation === null ? 0 : (10*parameter.proper_use_of_tone_and_voice_modulation)/100).toFixed(2)
    } 
    else {
        return ""
    }
}

export function getRatingColor (rating) {
    if(rating >= 85) {
        return "#60B61E"
    }
    else if(rating >= 65 && rating < 85) {
        return "#FFA500"
    }
    else if( rating >= 35 && rating < 65) {
        return "#EDD926"
    }
    else {
        return "#DA0707"
    }
}

export const callAgentList = [
    {
        "call_center_id": 1,
        "call_center_name": "Call Center 1",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z",
        "call_agents": [
            {
                "agent_id": 1,
                "agent_name": "Test 1",
                "agent_phone_number": "+91-9876543210",
                "created_at_timestamp": "2024-08-26T18:30:00.000Z"
            },
            {
                "agent_id": 2,
                "agent_name": "Test 2",
                "agent_phone_number": "+91-9876543210",
                "created_at_timestamp": "2024-08-26T18:30:00.000Z"
            },
            {
                "agent_id": 3,
                "agent_name": "Test 3",
                "agent_phone_number": "+91-9876543210",
                "created_at_timestamp": "2024-08-26T18:30:00.000Z"
            }
        ]
    },
    {
        "call_center_id": 2,
        "call_center_name": "Call Center 2",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z",
        "call_agents": [
            {
                "agent_id": 2,
                "agent_name": "Test 2",
                "agent_phone_number": "+91-9876543211",
                "created_at_timestamp": "2024-08-26T18:30:00.000Z"
            }
        ]
    }
]


export const callComplexity = [
    {
        "call_complexity_id": 1,
        "call_complexity_level": "Low Complexity",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_complexity_id": 2,
        "call_complexity_level": "Moderate Complexity",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_complexity_id": 3,
        "call_complexity_level": "High Complexity",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_complexity_id": 4,
        "call_complexity_level": "Critical",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    }
]

export const callCategories = [
    {
        "call_category_id": 1,
        "call_category_name": "General Inquiries",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 2,
        "call_category_name": "Medical Appointments",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 3,
        "call_category_name": "Administrative Inquiries",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 4,
        "call_category_name": "Non-Urgent Medical Inquiries",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 5,
        "call_category_name": "Urgent Medical Inquiries",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 6,
        "call_category_name": "Complaints and Claims",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 7,
        "call_category_name": "Inquiries About Medical Procedures",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 8,
        "call_category_name": "Inquiries About Medications",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    },
    {
        "call_category_id": 9,
        "call_category_name": "Technical Support for Digital Tools",
        "created_at_timestamp": "2024-08-26T18:30:00.000Z"
    }
]

export const phoneNumbersList = [
    {
        phone_number: "83960176-4253"
    },
    {
        phone_number: "83960175-4254"
    },
    {
        phone_number: "83960175-4255",
    },
    {
        phone_number: "83960175-4256"
    }
]

export const extensionList = [
    ".mp4",
    ".mp3"
]


export function calculatePercentage (score, weight) {
    return ((score/weight)*100).toFixed(2)
}

export function subEvaluationScore (subField) {
    let score = 0;
    subField.map((field) => {
        score = score + Number(field.call_record_evaluation_score);
    })
    // return score.toFixed(2);
    return score;
}