import { Avatar, Divider, Typography } from "@mui/material";
import React from "react";
import globalStyle from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/chatbotStyles/chats.module.scss"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
interface contactInfo {
    name?: string,
    phoneNumber?: string;
    profilePicture?: string;
    email?: string
}
interface ContactCardProps {
    contactInfo?: contactInfo[]
}
function ContactCard(props: ContactCardProps) {
    return (
        <>
            {/* <p className={globalStyle.primaryFontWeight}>Contact</p>
            {props.contactInfo && props.contactInfo.map((contact: contactInfo) => (
                <div className="card">
                    <div className="top" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p className="name">{contact.name}</p>
                        <Avatar src={contact.profilePicture} />
                    </div>
                    <div className="bottom">
                        <Typography className={`${globalStyle.primaryFontSize}`}>{contact.phoneNumber}</Typography>
                        <Typography className={`${globalStyle.primaryFontSize} ${globalStyle.primaryFontWeight}`}>{contact.email}</Typography>

                    </div>
                </div>
            ))} */}
            {/* <p className={globalStyle.primaryFontWeight}>Contact Details</p> */}
            {props.contactInfo && props.contactInfo.map((contact: contactInfo) => (
                <div className={styles.card}>
                    <div className={styles.card_top}>
                        <Avatar src={contact.profilePicture} className={styles.contact_profile} />
                        <p className={styles.contact_name}>{contact.name}</p>
                    </div>
                    <div className={styles.card_bottom}>
                        <div className={styles.contact_number}>
                            <CallIcon className={styles.call_icon} />
                            <Typography component={"span"}>{contact.phoneNumber}</Typography>
                        </div>
                        <div className={styles.contact_email}>
                            <EmailIcon className={styles.email_icon}/>
                            <Typography component={"span"}>{contact.email}</Typography>
                        </div>
                    </div>
                {/* <Divider style={{opacity: "0.8"}} /> */}
                </div>
            ))}
        </>
    );
}

export default ContactCard;
