import React, { MutableRefObject, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CreateDocResponseModel,
    CreateDocPayloadModel,
    DocumentListDataModel,
    documentStepDataModel,
    StepModel,
    updateDocumentFormRequestPayloadModel,
    UpdateDocumentInfoRequestPayloadModel,
    ExecutePromptRequestPayloadModel,
    ExecutePromptResponseDataModel,
    DocumentContentTypeResponseDataModel,
    UpdateDocumentOutlineDataPayloadModel,
    DeleteDocumentOutlineDataParamsModel,
    UpdateDocumentTitlePayloadModel,
    UpdateDocumentEditorPayloadModel,
    ExecutePromptResponseModel,
    executePromptResponseParaphraseObj,
    popupListObj,
    functionParameterTypeList,
    functionMethodList,
    timezoneList,
    routeSourceObj,
    feedbackStatus,
    feedbackKnowledgeBaseStatus,
} from "../models/axiosModel";
import agent from "../services/api";

const GenericDataContext = React.createContext<GenericDataContextPayload | undefined>(
    undefined
);

export interface GenericDataContextPayload {
    popupList: popupListObj[];
    setPopupList: React.Dispatch<React.SetStateAction<popupListObj[]>>
    GetPopupList: () => void
    getFunctionParamsList: () => void
    functionParameterTypeDataList: functionParameterTypeList[]
    functionMethodsList: functionMethodList[]
    getFunctioMethodList: () => void;
    genericTimezoneList: timezoneList[];
    setGenericTimezoneList: React.Dispatch<React.SetStateAction<timezoneList[]>>
    getGenericTimezoneList: () => void;
    routeSources: routeSourceObj[];
    setRouteSources: React.Dispatch<React.SetStateAction<routeSourceObj[] | []>>;
    GetRouteSourceList: () => void;
    feedbackStatusData: feedbackStatus[],
    setFeedbackStatusData: React.Dispatch<React.SetStateAction<feedbackStatus[]>>,
    feedbackKnowledgeBaseStatusData: feedbackKnowledgeBaseStatus[],
    setFeedbackKnowledgeBaseStatusData: React.Dispatch<React.SetStateAction<feedbackKnowledgeBaseStatus[]>>,
}

const GenericDataProvider = ({ children }: ChildrenProps) => {

    const [popupList, setPopupList] = useState<popupListObj[]>([])
    const GetPopupList = async () => {
        const response = await agent.getPopupList.get();
        if (response.status) {
            setPopupList(response.data)
        } else {
            setPopupList([])
        }
    }
    const [functionParameterTypeDataList, setFunctionParameterTypeDataList] = useState([])
    const [functionMethodsList, setFunctionMethodsList] = useState([]);
    const [genericTimezoneList, setGenericTimezoneList] = useState<timezoneList[]>([])
    const [routeSources, setRouteSources] = useState<routeSourceObj[] | []>([])

    const [feedbackStatusData, setFeedbackStatusData] = useState<feedbackStatus[]>([]);
    const [feedbackKnowledgeBaseStatusData, setFeedbackKnowledgeBaseStatusData] = useState<feedbackKnowledgeBaseStatus[]>([]);

    const getFunctionParamsList = async () => {
        const response = await agent.getFunctionParameterTypeData.get();
        if (response.status) {
            setFunctionParameterTypeDataList(response.data)
        } else {
            setFunctionParameterTypeDataList([])
        }
    }
    const getFunctioMethodList = async () => {
        const response = await agent.getFunctionMethods.get();
        if (response.status) {
            setFunctionMethodsList(response.data)
        } else {
            setFunctionMethodsList([])
        }
    }
    const getGenericTimezoneList = async () => {
        const response = await agent.getGenericTimezone.get();
        if (response.status) {
            setGenericTimezoneList(response.data)
        } else {
            setGenericTimezoneList([])
        }
    }
    const GetRouteSourceList = async () => {
        const response = await agent.getRouteSourceIds.get();
        if (response.status) {
            setRouteSources(response.data)
        } else {
            setRouteSources([])
        }
    }
    const payload = {
        popupList,
        setPopupList,
        GetPopupList,
        getFunctionParamsList,
        functionParameterTypeDataList,
        functionMethodsList,
        getFunctioMethodList,
        genericTimezoneList,
        setGenericTimezoneList,
        getGenericTimezoneList,
        routeSources,
        setRouteSources,
        GetRouteSourceList,
        feedbackStatusData,
        setFeedbackStatusData,
        feedbackKnowledgeBaseStatusData,
        setFeedbackKnowledgeBaseStatusData,
    };
    return <GenericDataContext.Provider value={payload}>{children}</GenericDataContext.Provider>;
};

export { GenericDataContext, GenericDataProvider };
