import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../layout/header/header';
import SideNavigationPanel from '../layout/header/sideNavigationPanel';
import { Drawer, Grid, useMediaQuery, } from '@mui/material';
import { Outlet, useSearchParams } from 'react-router-dom';
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalPopUp from '../utility/globalPopUp';
import GetStartedPopUpData from '../utility/getStartedPopUpData';
import { UserContext, UserContextPayload } from '../../context/userContext';
import IconsColor from '../utility/iconsColor';
import closeArrow from "../../assets/closeArrow.png";
import Support from '../utility/support';
import { IntegrationContext, IntegrationContextPayload } from '../../context/integrationContext';
import whatsappBusiness from '../../assets/chatbotAssets/whatsappBusiness.svg'
import whatsappIcon from '../../assets/Whatsapp.svg'
import facebookMessenger from '../../assets/chatbotAssets/facebookMessenger.svg'
import instagramIcon from '../../assets/images/channels/instagram/instagram.png'
import { ConsultationComponent } from '../utility/consult';
import { menuItemsJunagadh, publicMenuItems, publicMenuNewItems } from '../../utils/constants';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../context/callAnalysisContext';

interface MenuItem {
    menuName: string;
    menuKey: string;
    navigationLink: string;
    imgSrc: React.ReactElement;
    whiteImgSrc: React.ReactElement;
    coloredImgSrc: React.ReactElement;
    flagDisabled?: boolean,
    flagHeaderKey?: boolean
}
const drawerWidth = 233;


export default function Dashboard() {
    const smallScreen = useMediaQuery('(max-width : 1220px) and (min-width : 0px)')
    const mobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const { sidePanelMenuItems, setSidePanelMenuItems, openConsultationBar, flagHideConsultation, userDetail, customPagesData, currentActiveMenu, currentActiveMobileMenu } = React.useContext(UserContext) as UserContextPayload;
    const { integrationList, slackIntegrations } = React.useContext(IntegrationContext) as IntegrationContextPayload;
    const { callRecordsData } = React.useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [flagShowRecaptchaPopUp, setFlagShowRecaptchaPopUp] = React.useState(false)
    const [searchParams] = useSearchParams()
    const [flagFAB, setFlagFAB] = React.useState(true)
    let id = searchParams.get("id");
    const organization_id = searchParams.get('organization_id')

    const integrationMenuItems = [
        {
            menuName: `${integrationList?.find((integration) => integration.meta_integration_id === Number(searchParams.get('id')))?.meta_integration_whatsapp_data?.waba_name || "WhatsApp Business"}`,
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: "",
            imgSrc: <img src={whatsappIcon} width={"22"} height={"22"} />,
            whiteImgSrc: <img src={whatsappIcon} width={"22"} height={"22"} />,
            coloredImgSrc: <img src={whatsappIcon} width={"22"} height={"22"} />,
        },
        {
            menuName: "Account Setup",
            menuKey: "account",
            navigationLink: id ? `/channels/viewintegration/whatsapp?id=${id}` : `/channels/viewintegration/whatsapp`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="account" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="account" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="account" height={"18"} width={"18"} />,
        },
        {
            menuName: "Phone Numbers",
            menuKey: "phonenumbers",
            navigationLink: `/channels/viewintegration/whatsapp/phonenumbers?id=${id}`,
            flagDisabled: id ? false : true,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="phoneNumber" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="phoneNumber" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="phoneNumber" height={"18"} width={"18"} />,
        },
        {
            menuName: "Template Messages",
            menuKey: "templatemessage",
            navigationLink: `/channels/viewintegration/whatsapp/templatemessages?id=${id}`,
            flagDisabled: id ? false : true,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="templatemessage" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="templatemessage" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="templatemessage" height={"18"} width={"18"} />,
        },
    ]

    const facebookIntegrationMenuItems = [
        {
            menuName: "Facebook Messenger",
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: "",
            imgSrc: <img src={facebookMessenger} width={"22"} height={"22"} />,
            whiteImgSrc: <img src={facebookMessenger} width={"22"} height={"22"} />,
            coloredImgSrc: <img src={facebookMessenger} width={"22"} height={"22"} />,
        },
        {
            menuName: "Account Setup",
            menuKey: "accountfacebook",
            navigationLink: id ? `/channels/viewintegration/facebook?id=${id}` : `/channels/viewintegration/facebook`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="account" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="account" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="account" height={"18"} width={"18"} />,
        },
    ]

    const instagramIntegrationMenuItems = [
        {
            menuName: "Instagram",
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: "",
            imgSrc: <img src={instagramIcon} width={"22"} height={"22"} />,
            whiteImgSrc: <img src={instagramIcon} width={"22"} height={"22"} />,
            coloredImgSrc: <img src={instagramIcon} width={"22"} height={"22"} />,
        },
        {
            menuName: "Account Setup",
            menuKey: "accountInstagram",
            navigationLink: id ? `/channels/viewintegration/instagram?id=${id}` : `/channels/viewintegration/instagram`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="account" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="account" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="account" height={"18"} width={"18"} />,
        },
    ]
    const slackMenuItems = [
        {
            menuName: `${slackIntegrations?.find((slackIntegration) => slackIntegration.slack_integration_id === Number(searchParams.get('id')))?.slack_team_name || "Slack Business"}`,
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: "",
            imgSrc: <IconsColor iconName="colouredSlackIcon" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor iconName="colouredSlackIcon" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor iconName="colouredSlackIcon" height={"18"} width={"18"} />,
        },
        {
            menuName: "Account Setup",
            menuKey: "accountslack",
            navigationLink: id ? `/channels/viewintegration/slack?id=${id}` : `/channels/viewintegration/slack`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="account" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="account" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="account" height={"18"} width={"18"} />,
        },
        {
            menuName: "Slack Channels",
            menuKey: "slackchannels",
            navigationLink: `/channels/viewintegration/slack/channels?id=${id}`,
            flagDisabled: id ? false : true,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="slackChannel" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="slackChannel" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="slackChannel" height={"18"} width={"18"} />,
        },

    ]
    const organizationMenuItems = [
        {
            menuName: "Workspace",
            menuKey: "workspace",
            navigationLink: organization_id ? `/organization?organization_id=${organization_id}` : '/organization',
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="workspace" height={"18"} width={"18"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="workspace" height={"18"} width={"18"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="workspace" height={"18"} width={"18"} />,
        }
    ]

    const callAnalysisMenuItems = [
        {
            menuName: "Dashboard",
            menuKey: "dashboard",
            // navigationLink: organization_id ? `/organization?organization_id=${organization_id}` : '/organization',
            navigationLink: '/callanalysis',
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callanalysisdashboard" height={"17"} width={"17"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callanalysisdashboard" height={"17"} width={"17"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="callanalysisdashboard" height={"17"} width={"17"} />,
        },
        {
            menuName: "Call Analysis List",
            menuKey: "callanalysis",
            // navigationLink: organization_id ? `/organization?organization_id=${organization_id}` : '/organization',
            navigationLink: '/callanalysis/list',
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callAnalysis" height={"17"} width={"17"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callAnalysis" height={"17"} width={"17"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="callAnalysis" height={"17"} width={"17"} />,
        }
    ]

    React.useEffect(() => {
        if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot")) {
            setOpen(true)
        } else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/facebook")) {
            setSidePanelMenuItems(facebookIntegrationMenuItems.slice(1))
        }
        else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/instagram")) {
            setSidePanelMenuItems(instagramIntegrationMenuItems.slice(1))
        }
        else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/whatsapp")) {
            setSidePanelMenuItems(integrationMenuItems.slice(1))
        } else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/slack")) {
            setSidePanelMenuItems(slackMenuItems.slice(1))
        }
        // else if (callRecordsData && callRecordsData.call_center && callRecordsData.call_center.length > 0) {
        //     if (customPagesData && customPagesData.length > 0) {
        //         const userSpecificMenu = [
        //             {
        //                 menuName: "Dashboard",
        //                 menuKey: "userdashboard",
        //                 navigationLink: '/user/dashboard',
        //                 imgSrc: <IconsColor fillColor="#4C4A4D" iconName="homeDashboard" height={"14"} width={"14"} />,
        //                 whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="homeDashboard" height={"14"} width={"14"} />,
        //                 coloredImgSrc: <IconsColor fillColor="#000000" iconName="homeDashboard" height={"14"} width={"14"} />,
        //                 subMenu: customPagesData.map((pages) => ({
        //                     menuName: pages.custom_page_name,
        //                     menuKey: pages.custom_page_name.toLowerCase(),
        //                     navigationLink: pages.custom_page_slug,
        //                     flagUserSpecific: true,
        //                     imgSrc: <img src={pages.custom_page_icon_url} height={"16"} width={"16"} />,
        //                     whiteImgSrc: <img src={pages.custom_page_icon_selected_url} height={"16"} width={"16"} />,
        //                     coloredImgSrc: <img src={pages.custom_page_icon_hover_url} height={"16"} width={"16"} />
        //                 }))
        //             },
        //         ]
        //         setSidePanelMenuItems([...publicMenuNewItems, ...userSpecificMenu])
        //     }
        //     else {
        //         setSidePanelMenuItems(publicMenuNewItems)
        //     }
        //     setOpen(true)
        // }
        else {
            if(userDetail?.user_organization_data?.organization_id === 2545) {
                setSidePanelMenuItems(menuItemsJunagadh)
            }
            else if (customPagesData && customPagesData.length > 0) {
                const userSpecificMenu = [
                    {
                        menuName: "Dashboard",
                        menuKey: "userdashboard",
                        navigationLink: '/user/dashboard',
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="userDashboard" height={"15"} width={"15"} />,
                        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="userDashboard" height={"15"} width={"15"} />,
                        coloredImgSrc: <IconsColor fillColor="#000000" iconName="userDashboard" height={"15"} width={"15"} />,
                        subMenu: customPagesData.map((pages) => ({
                            menuName: pages.custom_page_name,
                            menuKey: pages.custom_page_name.toLowerCase(),
                            navigationLink: pages.custom_page_slug,
                            flagUserSpecific: true,
                            imgSrc: <img src={pages.custom_page_icon_url} height={"16"} width={"16"} />,
                            whiteImgSrc: <img src={pages.custom_page_icon_selected_url} height={"16"} width={"16"} />,
                            coloredImgSrc: <img src={pages.custom_page_icon_hover_url} height={"16"} width={"16"} />
                        }))
                    },
                ]
                setSidePanelMenuItems([...publicMenuItems, ...userSpecificMenu])
            }
            else {
                setSidePanelMenuItems(publicMenuItems)
            }
            setOpen(true)
        }
    }, [window.location.pathname])

    React.useEffect(() => {
        if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/facebook") && id) {
            setSidePanelMenuItems(facebookIntegrationMenuItems)
        } else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/instagram") && id) {
            setSidePanelMenuItems(instagramIntegrationMenuItems)
        } else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/whatsapp") && id) {
            setSidePanelMenuItems(integrationMenuItems)

        } else if (window.location.pathname.toLowerCase().includes("/channels/viewintegration/slack") && id) {
            setSidePanelMenuItems(slackMenuItems)
        }
        else if (window.location.pathname.toLowerCase().includes("/organization")) {
            setSidePanelMenuItems(organizationMenuItems)
        } else if (window.location.pathname.toLowerCase().includes("/callanalysis")) {
            // setSidePanelMenuItems(callAnalysisMenuItems)
            // setOpen(false)
        }
    }, [integrationList, window.location.pathname])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    let isDrawerClosed = searchParams.get('collapsed')
    const [open, setOpen] = React.useState(isDrawerClosed === 'true' ? false : isDrawerClosed === 'false' ? true : true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // one tap login
    const [test, setTest] = React.useState(false);

    const handleDrawerOpentest = () => {
        setTest(!test);
    };

    const handleDrawerClosetest = () => {
        setTest(false);
    };
    return (
        <>
            {flagShowRecaptchaPopUp &&
                <GlobalPopUp
                    borderRadius='20px'
                    open={flagShowRecaptchaPopUp}
                    onClose={() => { setFlagShowRecaptchaPopUp(false) }}
                    // title={"Get started"}
                    flagBackdrop
                    flagShowCloseIcon={false}
                    handleClose={() => { setFlagShowRecaptchaPopUp(false) }}
                >
                    <GetStartedPopUpData />
                </GlobalPopUp>
            }

            <Grid container className={globalStyles.containerflexWrapStyle}>

                {/* *****************Temp Comment For the task of : BG image for sign up popup - show the platform  {userDetail.flagUser && */}
                <Grid item sx={{ width: { sm: open ? drawerWidth : 70 }, flexShrink: { sm: 0 }, position: "relative" }} >
                    {open ? (
                        <img src={closeArrow}
                            className={globalStyles.closeArrowStyle}
                            onClick={handleDrawerClose} />


                    ) : (
                        <img src={closeArrow}
                            className={globalStyles.openArrowStyle}
                            onClick={handleDrawerOpen} />

                    )}
                    <SideNavigationPanel
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        // menuItems={userDetail.flag_user_anonymous ? menuItemsPublic : menuItemsPrivate}
                        menuItems={sidePanelMenuItems}
                        openDesktopDrawer={open}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}

                    />
                </Grid>
                {/* ******************************* } ****************************************************************** */}


                <Grid item sx={{ width: "100%" }} // sx={{ width: { sm: `calc(100% - ${open ? drawerWidth : 96}px)` } }} 
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={globalStyles.headerMenuIcon}
                        sx={{ marginLeft: "0px", display: { xl: "none !important", lg: "none !important", md: "none !important", sm: 'none !important' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container sx={{ justifyContent: "space-between" }} direction={"column"}>
                        <Grid item sx={{ display: { xl: "none !important", lg: "none !important", md: "none !important", sm: "none !important" } }}>
                            <Header flagShowLogo={true} menuItems={sidePanelMenuItems} />
                        </Grid>
                        <Grid item sx={{ width: mobileScreen ? "100%" : open ? "calc(100vw - 240px) !important" : "calc(100vw - 70px) !important" }} className={`${globalStyles.dashboardMaxWidthStyles}`}>
                            <Grid container className={globalStyles.dashboardOutletWrapper}>
                                {
                                    customPagesData?.filter((data) => data.custom_page_slug === currentActiveMenu || data.custom_page_slug === currentActiveMobileMenu) && customPagesData?.filter((data) => data.custom_page_slug === currentActiveMenu || data.custom_page_slug === currentActiveMobileMenu).length > 0 ?
                                        <div
                                            style={{ marginTop: "0px", minHeight: "calc(100vh - 10px)", padding: "1px", width: "100%" }}
                                            dangerouslySetInnerHTML={{ __html: mobileScreen ? `<iframe src=\"${process.env.REACT_APP_CUSTOM_BASE_URL}${currentActiveMobileMenu}\" width=\"100%\" style=\"height: 100%;min-height: calc(100vh - 250px);border-radius: 10px;\"frameborder=\"0\"></iframe>` : `<iframe src=\"${process.env.REACT_APP_CUSTOM_BASE_URL}${currentActiveMenu}\" width=\"100%\" style=\"height: 100%;min-height: calc(100vh - 250px);border-radius: 10px;\"frameborder=\"0\"></iframe>` }}
                                        />
                                        :
                                        <Outlet />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Drawer sx={{
                    width: openConsultationBar ? 330 : 0,
                    flexShrink: 0,
                    transition: 'width 0.25s ease',
                    '& .MuiDrawer-paper': {
                        width: openConsultationBar ? 330 : 0,
                        transition: 'width 0.25s ease'
                    },
                }} variant='persistent' open={openConsultationBar} anchor="right">
                    <Support closeDrawer={handleDrawerClosetest} />
                </Drawer>
            </Grid>
            {!openConsultationBar && !flagHideConsultation &&
                <>
                    <Grid item sx={{ position: "fixed", bottom: "17px", right: "42px" }}>
                        <ConsultationComponent onClickConsultation={handleDrawerOpentest} onCloseConsultation={() => { setFlagFAB(false) }} />
                    </Grid>
                </>
            }
        </>
    );
}
