import { Button, Grid, IconButton, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react'
import CallAnalysisCard from './callAnalysisCard';
import CallAnalysisAudio from './callAnalysisAudio';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext';
import CallAnalysisPagination from './callAnalysisPagination';
import IconsColor from '../../utility/iconsColor';
import styles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import AddIcon from '@mui/icons-material/Add';
import globalStyles from './../../../assets/styles/global.module.scss';
import iconStyles from "../../../assets/styles/componentStyles/template.module.scss";
import { useNavigate } from 'react-router-dom';

const CallAnalysisDetails = () => {
    const { GetCallRecordsApi, GetCallRecordDetailApi, currentAudioAnalysis, setCurrentAudioAnalysis, callRecordsDetails, appliedFilter, waveLoading, setWaveLoading, callRecordsData, flagAnalysisData, viewRecordDetail, page } = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const {setFlagLoading, flagLoading} = useContext(UserContext) as UserContextPayload
    const navigate = useNavigate();
    
    const smallScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)');
    const newTabletScreen = useMediaQuery('(max-width : 1200px) and (min-width : 1000px)');
    
    const flagmobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 900px)');
    const cardInnerHeight = appliedFilter ? (window.innerHeight - 235) : (window.innerHeight - 180);
    const cardInnerMobileHeight = appliedFilter ? (window.innerHeight - 210) : (window.innerHeight - 155);
    const cardInnerTabletHeight = appliedFilter ? (window.innerHeight - 240) : (window.innerHeight - 190);
    const detailInnerHeight = appliedFilter ? (window.innerHeight - 170) : (window.innerHeight - 120);
    const detailInnerMobileHeight = appliedFilter ? (window.innerHeight - 160) : (window.innerHeight - 110);
    const detailInnerTabletHeight = appliedFilter ? (window.innerHeight - 190) : (window.innerHeight - 140);

    const recordsPerPage = 100;
    const startIndex = page * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const currentRecords = callRecordsDetails?.slice(startIndex, endIndex);

    const handleRegenerateCall = async (e: any, record_id: number) => {
        e.preventDefault();
        e.stopPropagation();
        setFlagLoading({...flagLoading, flagRoundLoader: true})
        const payload = {
            call_record_id: record_id
        }
        const response = await agent.regenerateCallRecord.put(payload);
        if(response.status) {
            await GetCallRecordDetailApi(record_id);
            await GetCallRecordsApi();
        }
        setFlagLoading({...flagLoading, flagRoundLoader: false})
    }

    return (
        callRecordsDetails && callRecordsDetails.length !== 0 && 
        <Grid item sx={{width: "100%"}}>
            {
                !smallScreen ? 
                    <Grid container direction={"column"} gap={"12px"}>
                        {/* <Grid item alignSelf={"end"}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item className={`${iconStyles.addChatbotBtn}`}>
                                    <Button
                                        variant='outlined'
                                        size='large'
                                        sx={{ width: "178px" }}
                                        fullWidth
                                        startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                        className={globalStyles.addDocumentBtn}
                                        onClick={() => { navigate('/callanalysis/callrecords/newcallrecords') }}
                                    >
                                        New Records
                                    </Button>
                                </Grid>
                                <IconButton
                                    className={`${globalStyles.cmpGlobalMobileAddIcon} ${iconStyles.addChatbotIcon}`}
                                    onClick={() => { navigate('/callanalysis/callrecords/newcallrecords') }}
                                >
                                    <AddIcon style={{ height: "28px", width: 'auto' }} />
                                </IconButton>
                            </Grid>
                        </Grid> */}
                        <Grid item sx={{width: "100%"}}>
                            <Grid container direction={"row"} flexWrap={"nowrap"} gap={2}>
                                <Grid item className={styles.leftSectionStyle} sx={{width: "100%"}}>
                                    <Grid container direction={"column"} gap={"10px"}>
                                        <Grid item className={styles.paginationGridStyle}>
                                            <Grid container>
                                                <Grid item>
                                                    <CallAnalysisPagination />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{padding: "5px 5px 5px 2px", height: `${cardInnerHeight}px`, maxHeight: `${cardInnerHeight}px`, overflow: "auto", boxSizing: "border-box"}}>
                                            <Grid direction={"column"} gap={"10px"} sx={{display: "flex"}}>
                                                {callRecordsDetails && callRecordsDetails.length > 0 && currentRecords && currentRecords.map((callRecordDetail) => 
                                                    <CallAnalysisCard callAnalysisInfo={callRecordDetail} setCurrentAudioAnalysis={setCurrentAudioAnalysis} currentAudioAnalysis={currentAudioAnalysis} waveLoading={waveLoading} setWaveLoading={setWaveLoading} handleRegenerateCall={handleRegenerateCall}/>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>

                                <Grid item sx={{width: "100%", maxWidth: "calc(100% - 340px)", height: `${detailInnerHeight}px`, maxHeight: `${detailInnerHeight}px`, overflow: "auto", borderRadius: "10px", backgroundColor: "#FFFFFF", boxShadow: "0px 0px 4px rgba(0,0,0,0.25)"}}>
                                    <Grid container sx={{height: !currentAudioAnalysis ? "100%" : "", alignItems: !currentAudioAnalysis ? "center" : ""}}>
                                        <Grid item sx={{width: "100%"}}>
                                            {!currentAudioAnalysis && callRecordsData &&
                                                <Grid container gap={2} alignItems={"center"} direction={"column"} justifyContent={"center"}>
                                                    <Grid item>
                                                        <IconsColor iconName="callAnalysis" height="56" width="56" fillColor="#959595"/>
                                                    </Grid>
                                                    <Grid item textAlign={"center"}>
                                                        <span style={{fontSize: "15px", fontWeight: "600", color: "#959595"}}>No record selected. Please choose a record from the list to
                                                        view its detailed analysis.</span>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentAudioAnalysis && callRecordsData &&
                                                <CallAnalysisAudio handleRegenerateCall={handleRegenerateCall}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                :
                <>
                    <Grid container direction={"column"} gap={"16px"} sx={{padding: newTabletScreen ? "0px 0px 5px 5px" : "0px 20px 5px 5px"}}>
                        {/* <Grid item alignSelf={"end"}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        size='large'
                                        sx={{ width: "168px" }}
                                        fullWidth
                                        startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                        className={globalStyles.addDocumentBtn}
                                        onClick={() => { navigate('/callanalysis/callrecords/newcallrecords') }}
                                    >
                                        New Records
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item sx={{width: "100%"}}>
                            <Grid container direction={"row"} flexWrap={"nowrap"}>
                            {!flagAnalysisData && 
                                <Grid item sx={{width: "100%"}}>
                                    <Grid container direction={"column"} gap={"10px"}>
                                        <Grid item sx={{ padding: "0px 8px 0px 0px", boxShadow: "0px 0px 2px rgba(0,0,0,0.5)", borderRadius: "5px", marginRight: "2px", maxWidth: "320px !important" }}>
                                            <Grid container>
                                                <Grid item>
                                                    <CallAnalysisPagination />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item 
                                            sx={{
                                                padding: "5px 5px 5px 2px", 
                                                height: flagmobileScreen ? `${cardInnerMobileHeight}px` : flagTabletScreen ? `${cardInnerTabletHeight}px` : `${cardInnerHeight}px`, 
                                                maxHeight: flagmobileScreen ? `${cardInnerMobileHeight}px` : flagTabletScreen ? `${cardInnerTabletHeight}px` : `${cardInnerHeight}px`, 
                                                overflow: "auto", 
                                                boxSizing: "border-box"
                                            }}
                                        >
                                            <Grid direction={{xs:"column", sm: "row"}} gap={"15px"} sx={{display: "flex"}} flexWrap={"wrap"}>
                                                {callRecordsDetails && callRecordsDetails.length > 0 && currentRecords && currentRecords.map((callRecordDetail) => 
                                                    <CallAnalysisCard callAnalysisInfo={callRecordDetail} setCurrentAudioAnalysis={setCurrentAudioAnalysis} currentAudioAnalysis={currentAudioAnalysis} waveLoading={waveLoading} setWaveLoading={setWaveLoading} handleRegenerateCall={handleRegenerateCall}/>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            </Grid>
                            {flagAnalysisData &&
                                <Grid item 
                                    sx={{
                                        width: "100%", 
                                        maxWidth: "100%", 
                                        height: flagmobileScreen ? `${detailInnerMobileHeight}px` : flagTabletScreen ? `${detailInnerTabletHeight}px` : `${detailInnerHeight}px`, 
                                        maxHeight: flagmobileScreen ? `${detailInnerMobileHeight}px` : flagTabletScreen ? `${detailInnerTabletHeight}px` : `${detailInnerHeight}px`, 
                                        overflow: "auto", 
                                        borderRadius: "10px", 
                                        backgroundColor: "#FFFFFF", 
                                        padding: viewRecordDetail ? "10px" : "0px 15px 0px 0px"
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sx={{width: "100%"}}>
                                            {currentAudioAnalysis && callRecordsData &&
                                                <CallAnalysisAudio handleRegenerateCall={handleRegenerateCall}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }                      
                        </Grid>
                    </Grid>
                </>
            } 
        </Grid>
    )
}

export default CallAnalysisDetails
