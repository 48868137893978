import { Autocomplete, Avatar, Divider, Grid, IconButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Required from '../../utility/required';
import IconsColor from '../../utility/iconsColor';
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss';
import globalStyles from '../../../assets/styles/global.module.scss'
import { agentLists, calculateEvaluation, calculatePercentage, callAgentList, complexityTypes, getRatingColor, initialAutoCompleteAdditional, purposeCallLists, subEvaluationScore } from './callAnalysisConstants';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import MicIcon from '@mui/icons-material/Mic';
import { CallAudio } from './callAudio';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { number } from 'yup';
import GlobalButton from '../../utility/globalButton/button';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AlertsTable from './alertsTable';
import TimelapseIcon from '@mui/icons-material/Timelapse';

interface callAnalysisAudioInterface {
    handleRegenerateCall: (e: any, record_id: number) => Promise<void>,
}

const CallAnalysisAudio = (props: callAnalysisAudioInterface) => {
    const { handleRegenerateCall } = props;
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error } = useContext(UserContext) as UserContextPayload
    const { callCategories, callComplexity, callAgentsData, currentAudioAnalysis, callCenterDetails, waveLoading, setWaveLoading, setAddNewAgentFlag,
        handleUpdateCallAgent, handleUpdateCallCategory, handleUpdateCallComplexity, filterDate, applyFilters, setViewRecordDetail, setUpdateAgentFlag,
        setUpdateAgentPayload, setFlagAnalysisData, viewRecordDetail, callParentCategories, handleUpdateCallParentCategory, callAnalysisAdditionalFields, handleUpdateAdditionalFields,
        GetCallRecordDetailApi, additinalFieldValue, setAdditionalFieldValue, parentCategoryId, handleUpdateCallEvaluationScore, setTimeToScroll, timeToScroll, setFlagReportProblem
    } = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const [fileAudio, setFileAudio] = useState<string>("")
    const mobileTableScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)');
    const smallScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)');
    const LargeScreen = useMediaQuery('(min-width : 1350px)');
    const [supervisorValue, setSupervisorValue] = useState(null)

    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [newScore, setNewScore] = useState<number | null>(null);
    const [showEditIconId, setShowEditIconId] = useState<number | null>(null);

    const handleChangeAgent = async (agentId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const resp = await handleUpdateCallAgent(agentId);
        if (resp.status) {
            applyFilters(filterDate);
            setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            await GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setViewRecordDetail(false)
    }

    const handleChangeCallParentCategory = async (categoryParentId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const resp = await handleUpdateCallParentCategory(categoryParentId);
        if (resp.status) {
            applyFilters(filterDate);
            setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            await GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setViewRecordDetail(false)
    }

    const handleChangeCategory = async (categoryId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const resp = await handleUpdateCallCategory(categoryId);
        if (resp.status) {
            applyFilters(filterDate);
            setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            await GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setViewRecordDetail(false)
    }

    const handleChangeComplexity = async (complexityId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const resp = await handleUpdateCallComplexity(complexityId);
        if (resp.status) {
            applyFilters(filterDate);
            setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            await GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setViewRecordDetail(false)
    }

    const handleChangeAdditionalField = async (additional_field_ids: number, additional_field_option_ids: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const resp = await handleUpdateAdditionalFields(additional_field_ids, additional_field_option_ids);
        if (resp.status) {
            applyFilters(filterDate);
            setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            await GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setViewRecordDetail(false)
    }

    const handleBackAudio = () => {
        if (!waveLoading) {
            setFlagAnalysisData(false)
        }
    }

    useEffect(() => {
        setFileAudio("")
        if (currentAudioAnalysis) {
            // const fileResponse = `${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${currentAudioAnalysis.call_record_google_storage_id}&flag_info=false`;
            setFileAudio(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${currentAudioAnalysis.call_record_google_storage_id}&flag_info=false`)
        }
    }, [currentAudioAnalysis])

    const handleEditClick = (index: number, currentScore: number) => {
        setEditIndex(index);
        setNewScore(currentScore);
    };

    const handleBlur = async (index: number, oldScore: number) => {
        setEditIndex(null);
        const payload = {
            call_record_evaluation_id: index,
            call_record_evaluation_score: newScore || 0
        }
        if (newScore !== oldScore) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const resp = await handleUpdateCallEvaluationScore(payload);
            if (resp.status) {
                applyFilters(filterDate);
                GetCallRecordDetailApi(currentAudioAnalysis?.call_record_id || 0)
                setSuccess({ ...success, flagShowSuccess: true, message: "Call Record Updated Successfully!" })
            }
            else {
                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            setViewRecordDetail(false)
        }
    };

    const handleNewScore = (value: string, weight: number) => {
        if (Number(value) <= weight) {
            setNewScore(Number(value))
        }
    }

    const handleTranscript = (transcriptValue: string | undefined) => {
        if (transcriptValue) {
            return transcriptValue
                .split("\n")
                .map(itm => itm.split("] "))
                .map(itm => {
                    if (itm.length > 1) {
                        return [
                            itm[0] + "]",
                            itm[1]?.replace(itm[1]?.substring(itm[1].indexOf("Speaker ") + 10), "").trim(),
                            itm[1]?.substring(itm[1].indexOf("Speaker ") + 10).trim(),
                        ];
                    } else {
                        return [
                            itm[0]?.replace(itm[0]?.substring(itm[0].indexOf("Speaker ") + 10), "").trim(),
                            itm[0]?.substring(itm[0].indexOf("Speaker ") + 10).trim(),
                        ];
                    }
                });
        }
        return [];
    };

    useEffect(() => {
        setTimeout(() => {
            const container = document.getElementById('audioDetail')
            container?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 500);
    }, [timeToScroll])

    return (
        <Grid item sx={{ padding: viewRecordDetail ? "20px" : smallScreen ? "5px" : "20px", overflowY: "auto", zIndex: "1" }} id="audioDetail">
            <Grid container direction={"column"} gap={"20px"} >
                <Grid item sx={{ width: "100%" }}>
                    <Grid container direction={"column"} gap={3}>
                        {smallScreen && !viewRecordDetail &&
                            <Grid item>
                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item onClick={() => handleBackAudio()}>
                                        <Grid container direction={"row"} alignItems={"center"} gap={1} flexWrap={"nowrap"}>
                                            <Grid item sx={{ display: "flex" }}>
                                                <IconsColor iconName="backAudio" height="18" width="18" fillColor="#444444" />
                                            </Grid>
                                            <Grid item>
                                                <span className={styles.backFontStyle}>Back</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction={"row"} gap={1}>
                                            <Grid item>
                                                {/* <Tooltip title="Replay" placement='top'><PlayCircleIcon style={{fontSize: "16px"}}/></Tooltip> */}
                                                <GlobalButton buttonText={"Reportar un Problema"} className='primaryButtonStyle' sx={{ width: "190px" }} onClick={() => setFlagReportProblem(true)} />
                                            </Grid>
                                            {/* <Grid item className={styles.audioPlayIconStyle}>
                                                <MicIcon style={{fontSize: "16px"}}/>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item>
                            <Grid container direction={"row"} flexWrap={"nowrap"} gap={"12px"} alignItems={"center"} sx={{ position: "relative" }}>
                                <Grid item className={styles.evaluationRatingGrid}>
                                    <Avatar sx={{ bgcolor: getRatingColor(currentAudioAnalysis?.call_record_eval_score) }}>{Math.round(Number(currentAudioAnalysis?.call_record_eval_score))}</Avatar>
                                </Grid>
                                <Grid item>
                                    <span className={styles.audioFileNameStyle}>{currentAudioAnalysis?.call_record_google_storage_file_name}</span>
                                </Grid>
                                <Grid item>
                                    <AvTimerIcon sx={{ fontSize: "18px", verticalAlign: "middle" }} /><span className={styles.durationFieldValue}>{currentAudioAnalysis?.call_record_duration}</span>
                                </Grid>
                                {currentAudioAnalysis?.call_record_alert && currentAudioAnalysis?.call_record_alert.length > 0 &&
                                    <Grid item>
                                        <Tooltip title={`Alerts (${currentAudioAnalysis?.call_record_alert.length})`} placement='top'><ReportProblemIcon style={{ fontSize: "18px", color: "red", display: "flex", marginTop: "2px" }} /></Tooltip>
                                    </Grid>
                                }
                                {!smallScreen &&
                                    <Grid item className={styles.audioIconPosition}>
                                        <Grid container direction={"row"} gap={1}>
                                            <Grid item>
                                                {/* <Tooltip title="Replay" placement='top'><PlayCircleIcon style={{fontSize: "16px"}}/></Tooltip> */}
                                                {/* <GlobalButton 
                                                    buttonText={"Re-Evaluate"} 
                                                    className='secondaryButtonStyle' 
                                                    onClick={(e) => handleRegenerateCall(e, currentAudioAnalysis?.call_record_id || 0)}
                                                    sx={{width: "140px !important", boxShadow: "0px 0px 2px rgba(106,9,125,0.5) !important"}}
                                                /> */}
                                                <GlobalButton buttonText={"Reportar un Problema"} className='primaryButtonStyle' sx={{ width: "190px" }} onClick={() => setFlagReportProblem(true)} />
                                            </Grid>
                                            {/* <Grid item className={styles.audioPlayIconStyle}>
                                                <MicIcon style={{fontSize: "16px"}}/>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <Grid container direction={LargeScreen ? "row" : "column"} flexWrap={"nowrap"} gap={3} alignItems={"start"}>
                                <Grid item sx={{ width: "100%", boxShadow: "0px 0px 2px rgba(0,0,0,0.3)", borderRadius: "10px", padding: "15px", height: "100%" }} >
                                    {fileAudio !== "" && <CallAudio audioURL={fileAudio} waveLoading={waveLoading} setWaveLoading={setWaveLoading} timeToScroll={timeToScroll} currentAudioAnalysis={currentAudioAnalysis} />}
                                </Grid>
                                <Grid item sx={{ width: "100%" }}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item sx={{ width: "100%" }}>
                                            <Grid container direction={"row"} flexWrap={"nowrap"} justifyContent={"space-between"}>
                                                <Grid item sx={{ width: "100%" }}>
                                                    <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Agent </span>
                                                </Grid>
                                                <Grid item sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", cursor: "pointer" }} onClick={() => setAddNewAgentFlag(true)}>
                                                    <IconsColor iconName="plus" width="17" height="17" fillColor="#6A097D" />
                                                    <span className={`${styles.newAgentStyle} ${styles.lightFontWeightField}`} style={{ marginLeft: "8px", fontSize: "14.5px" }}>New Agent</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={callAgentsData?.find((agents) => agents.call_center_id === callCenterDetails?.call_center_id)?.call_agents.find((param) => param.agent_id == (currentAudioAnalysis?.agent_id)) ?? null}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeAgent(value.agent_id)
                                                }}
                                                getOptionLabel={(option) => option.agent_name}
                                                disableClearable
                                                options={callAgentsData?.find((agents) => agents.call_center_id === callCenterDetails?.call_center_id)?.call_agents.sort((a, b) => a.agent_name.localeCompare(b.agent_name)) || []}
                                                // renderOption={(props, option) => (
                                                //     <li {...props}>                                                     
                                                //         <ListItemText primary={option.agent_name} />
                                                //         <ListItemIcon onClick={(event) => {event.stopPropagation();setUpdateAgentPayload({call_center_id: callCenterDetails?.call_center_id || 0, agent_name: option.agent_name});setUpdateAgentFlag(true)}} sx={{justifyContent: "end"}}>
                                                //             <EditIcon />
                                                //         </ListItemIcon>
                                                //     </li>
                                                //   )}
                                                renderInput={(params) => <TextField {...params} placeholder='Select Agent' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{ width: "100%" }}>
                    <Grid container direction={"column"} gap={1}>
                        <Grid item sx={{ width: "100%" }}>
                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Transcript </span>
                        </Grid>
                        <Grid item className={styles.transcriptsViewStyle} sx={{ width: "100%" }}>
                            <Grid container>
                                <Grid item className={styles.transcriptDataStyle}>
                                    <Grid container direction={"column"} gap={"2px"} sx={{ boxSizing: "border-box" }}>
                                        {/* <pre style={{maxWidth: "100%"}} className={`${styles.transcriptAnswer} ${styles.transcriptPreLine}`}>{currentAudioAnalysis?.call_record_data_transcript}</pre> */}
                                        {handleTranscript(currentAudioAnalysis?.call_record_data_transcript)?.map((data: any) =>
                                            data.length > 2 ?
                                                <Grid item sx={{ width: "100%" }}>
                                                    <Grid container gap={"12px"} flexWrap={"nowrap"}>
                                                        {mobileTableScreen ?
                                                            <Grid item sx={{ display: "flex" }}>
                                                                <Tooltip title={data[0]}>
                                                                    <TimelapseIcon sx={{ fontSize: "18px", width: "18px", height: "18px" }} />
                                                                </Tooltip>
                                                            </Grid>
                                                            :
                                                            <Grid item className={`${styles.transcriptAnswer}`} sx={{ borderRight: "1px solid #EAEAEA", whiteSpace: "nowrap", width: "100%", maxWidth: "110px" }}>
                                                                {data[0]}
                                                            </Grid>
                                                        }
                                                        <Grid item sx={{ width: "100%", maxWidth: !mobileTableScreen ? "calc(100% - 110px)" : "100%" }}>
                                                            <Grid container gap={"6px"} flexWrap={"nowrap"}>
                                                                <Grid item className={`${styles.transcriptAnswer}`} sx={{ whiteSpace: "nowrap", fontWeight: "500 !important" }}>{data[1]}</Grid>
                                                                <Grid item className={`${styles.transcriptAnswer}`} sx={{ wordBreak: "break-word" }}>{data[2]}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item sx={{ width: "100%" }}>
                                                    <Grid container gap={"12px"} flexWrap={"nowrap"}>
                                                        <Grid item sx={{ width: "100%", maxWidth: !mobileTableScreen ? "calc(100%)" : "100%" }}>
                                                            <Grid container gap={"8px"} flexWrap={"nowrap"}>
                                                                <Grid item className={`${styles.transcriptAnswer}`} sx={{ whiteSpace: "nowrap", fontWeight: "500 !important" }}>{data[0]}</Grid>
                                                                <Grid item className={`${styles.transcriptAnswer}`} sx={{ wordBreak: "break-word" }}>{data[1]}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        )
                                        }
                                    </Grid>
                                </Grid>
                                {currentAudioAnalysis?.call_record_speaker && currentAudioAnalysis?.call_record_speaker.length > 0 && <Divider sx={{ width: "100%", opacity: "0.5" }} />}
                                {currentAudioAnalysis?.call_record_speaker && currentAudioAnalysis?.call_record_speaker.length > 0 &&
                                    <Grid item margin={"12px"}>
                                        <span style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>{`Speaker 1: ${currentAudioAnalysis?.call_record_speaker.find((speaker) => speaker.call_record_speaker_name === "Speaker1")?.call_record_speaker_role || "-"}, `}</span>
                                        <span style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>{`Speaker 2: ${currentAudioAnalysis?.call_record_speaker.find((speaker) => speaker.call_record_speaker_name === "Speaker2")?.call_record_speaker_role || "-"}`}</span>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Divider style={{ opacity: "1", width: "100%" }} />
                </Grid>

                <Grid item sx={{ width: "100%" }}>
                    <Grid container direction={"column"} gap={2}>
                        <Grid item>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <span className={styles.aiAnalysisFontStyle}>AI Analysis</span>
                                </Grid>
                                <Grid item>
                                    {/* <Tooltip title="Replay" placement='top'><PlayCircleIcon style={{fontSize: "16px"}}/></Tooltip> */}
                                    <GlobalButton
                                        buttonText={"Re-Evaluate"}
                                        className='secondaryButtonStyle'
                                        onClick={(e) => handleRegenerateCall(e, currentAudioAnalysis?.call_record_id || 0)}
                                        sx={{ width: "140px !important", boxShadow: "0px 0px 2px rgba(106,9,125,0.5) !important" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sx={{ width: "100%" }}>
                            <Grid container flexWrap={{ xs: "wrap", md: "nowrap" }} gap={"12px"}>
                                {currentAudioAnalysis?.call_record_alert && currentAudioAnalysis.call_record_alert.length > 0 &&
                                    <Grid item xs={12} md={6} sx={{ borderRight: { md: "1px solid #EAEAEA" }, paddingRight: { md: "15px" } }}>
                                        <Grid container direction={"column"} gap={"12px"} flexWrap={"nowrap"}>
                                            <Grid item>
                                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>AI Alerts</span>
                                            </Grid>
                                            <Grid item>
                                                <AlertsTable setTimeToScroll={setTimeToScroll} alertsList={currentAudioAnalysis?.call_record_alert} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12} md={currentAudioAnalysis?.call_record_alert && currentAudioAnalysis.call_record_alert.length > 0 ? 6 : 12}>
                                    <Grid container direction={"column"} gap={"12px"}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>AI Descriptive Analysis</span>
                                        </Grid>
                                        <Grid item className={styles.transcriptsViewStyle}>
                                            <Grid container>
                                                <Grid item className={styles.aiAnalysisComponent}>
                                                    <Grid container direction={"column"} gap={2}>
                                                        <span className={styles.transcriptAnswer}>{currentAudioAnalysis?.call_record_data_ai_analysis}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sx={{ width: "100%" }}>
                            <Grid container direction={{ xs: "column", lg: "row" }} flexWrap={"nowrap"} gap={2} sx={{ display: "grid", gridTemplateColumns: mobileTableScreen ? "repeat(1,1fr)" : "repeat(2,1fr)" }}>
                                {callAnalysisAdditionalFields && callAnalysisAdditionalFields.map((additionalFields) =>
                                    <Grid item sx={{ width: "100%" }} key={additionalFields.call_analysis_additional_field_id}>
                                        <Grid container direction={"column"} gap={1}>
                                            <Grid item>
                                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>{additionalFields.organization_additional_field_name}</span>
                                            </Grid>
                                            <Grid item>
                                                {additionalFields.organization_additional_field_data_type.toLowerCase() === "dropdown" &&
                                                    <Autocomplete
                                                        className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                        value={currentAudioAnalysis?.call_record_additional_field
                                                            ?.find((fields) => fields.call_analysis_additional_field_id === additionalFields.call_analysis_additional_field_id)
                                                            ?.call_record_additional_field_value[0] || null}
                                                        // value={additinalFieldValue && additinalFieldValue.length > 0 ? additinalFieldValue.find((field) => field.call_analysis_additional_field_id === additionalFields.call_analysis_additional_field_id)?.call_record_additional_field_value[0] : null}
                                                        onChange={(event: React.SyntheticEvent, value: any) => {
                                                            handleChangeAdditionalField(additionalFields.call_analysis_additional_field_id, value.organization_additional_field_option_id)
                                                        }}
                                                        // isOptionEqualToValue={(option, value) => option.organization_additional_field_option_id === value?.organization_additional_field_option_id}
                                                        getOptionLabel={(option) => option.organization_additional_field_option_value}
                                                        disableClearable
                                                        options={additionalFields.organization_additional_field_option.map(option => ({
                                                            "organization_additional_field_option_id": option.organization_additional_field_option_id,
                                                            "organization_additional_field_option_value": option.organization_additional_field_option_value,
                                                        })) || []}
                                                        renderInput={(params) => <TextField {...params} placeholder={`Select ${additionalFields.organization_additional_field_name}`} />}
                                                        sx={{ padding: "2px 4px !important" }}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item sx={{ width: "100%" }}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Category </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={parentCategoryId && callParentCategories && callParentCategories.length > 0 && callParentCategories.find((param) => param.call_parent_category_id == parentCategoryId)}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeCallParentCategory(value.call_parent_category_id)
                                                }}
                                                getOptionLabel={(option) => option.call_parent_category_name}
                                                disableClearable
                                                options={callParentCategories || []}
                                                renderInput={(params) => <TextField {...params} placeholder='Select Category' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{ width: "100%" }}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Sub Category </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={callCategories ? callCategories.find((param) => param.call_category_id == (currentAudioAnalysis?.call_category_id)) : null}
                                                // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                                //     if (value) {
                                                //         props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                                //         props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                                //     } else {
                                                //         props.onChangePayload("", 'function_parameter_type_id', '');
                                                //         props.onChangePayload("", 'function_parameter_type_name', '');
                                                //     }
                                                // }}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeCategory(value.call_category_id)
                                                }}
                                                getOptionLabel={(option) => option.call_category_name}
                                                disableClearable
                                                options={callCategories || []}
                                                renderInput={(params) => <TextField {...params} placeholder='Select Sub Category' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{ width: "100%" }}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Complexity </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={callComplexity ? callComplexity.find((param) => param.call_complexity_id == (currentAudioAnalysis?.call_complexity_id)) : null}
                                                // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                                //     if (value) {
                                                //         props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                                //         props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                                //     } else {
                                                //         props.onChangePayload("", 'function_parameter_type_id', '');
                                                //         props.onChangePayload("", 'function_parameter_type_name', '');
                                                //     }
                                                // }}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeComplexity(value.call_complexity_id)
                                                }}
                                                getOptionLabel={(option) => option.call_complexity_level}
                                                options={callComplexity || []}
                                                disableClearable
                                                renderInput={(params) => <TextField {...params} placeholder='Select Complexity' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* 
                        <Grid item sx={{width: "100%"}}>
                            <Grid container direction={{xs: "column", lg: "row"}} flexWrap={"nowrap"} gap={3}>
                                <Grid item sx={{width: "100%"}}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Category </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={parentCategoryId && callParentCategories && callParentCategories.length > 0 && callParentCategories.find((param) => param.call_parent_category_id == parentCategoryId)}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeCallParentCategory(value.call_parent_category_id)
                                                }}
                                                getOptionLabel={(option) => option.call_parent_category_name}
                                                disableClearable
                                                options={callParentCategories || []}
                                                renderInput={(params) => <TextField {...params} placeholder='Select Category' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{width: "100%"}}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Sub Category </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={callCategories ? callCategories.find((param) => param.call_category_id == (currentAudioAnalysis?.call_category_id)) : null}
                                                // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                                //     if (value) {
                                                //         props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                                //         props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                                //     } else {
                                                //         props.onChangePayload("", 'function_parameter_type_id', '');
                                                //         props.onChangePayload("", 'function_parameter_type_name', '');
                                                //     }
                                                // }}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeCategory(value.call_category_id)
                                                }}
                                                getOptionLabel={(option) => option.call_category_name}
                                                disableClearable
                                                options={callCategories || []}
                                                renderInput={(params) => <TextField {...params} placeholder='Select Sub Category' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{width: "100%"}}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Complexity </span>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                value={callComplexity ? callComplexity.find((param) => param.call_complexity_id == (currentAudioAnalysis?.call_complexity_id)) : null}
                                                // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                                //     if (value) {
                                                //         props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                                //         props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                                //     } else {
                                                //         props.onChangePayload("", 'function_parameter_type_id', '');
                                                //         props.onChangePayload("", 'function_parameter_type_name', '');
                                                //     }
                                                // }}
                                                onChange={(event: React.SyntheticEvent, value: any) => {
                                                    handleChangeComplexity(value.call_complexity_id)
                                                }}
                                                getOptionLabel={(option) => option.call_complexity_level}
                                                options={callComplexity || []}
                                                disableClearable
                                                renderInput={(params) => <TextField {...params} placeholder='Select Complexity' />}
                                                sx={{ padding: "2px 4px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}

                        {currentAudioAnalysis?.call_record_evaluation_score && currentAudioAnalysis?.call_record_evaluation_score.length > 0 &&
                            <Grid item sx={{ width: "100%" }}>
                                <Grid container direction={"column"} gap={"12px"}>
                                    <Grid item>
                                        <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>AI Evaluation</span>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <TableContainer sx={{ borderRadius: "5px", boxShadow: "0px 0px 2px rgb(106, 9, 125)" }}>
                                            <Table aria-label="simple table">
                                                <TableHead sx={{ backgroundColor: "#FBF4FF" }}>
                                                    <TableRow>
                                                        <TableCell className={`${styles.tableBorderLessCell} ${styles.finalNoteFontStyle}`} sx={{ width: "40%" }}>Evaluation Parameters</TableCell>
                                                        <TableCell className={`${styles.tableBorderLessCell} ${styles.finalNoteFontStyle}`} sx={{ width: "10%", textAlign: "center" }}>Weight</TableCell>
                                                        <TableCell className={`${styles.tableBorderLessCell} ${styles.finalNoteFontStyle}`} sx={{ width: "10%", textAlign: "center" }}>Score</TableCell>
                                                        <TableCell className={`${styles.tableBorderLessCell} ${styles.finalNoteFontStyle}`}>Explanation</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {currentAudioAnalysis.call_record_evaluation_score.map((evaluation: any) =>
                                                        <>
                                                            <TableRow>
                                                                <TableCell className={`${styles.tableCellDarkBorder} ${styles.tableBodyHeadingFontStyle}`}>
                                                                    {evaluation.call_evaluation_parameter_description ? `${evaluation.call_evaluation_parameter_name} (${evaluation.call_evaluation_parameter_description})` : evaluation.call_evaluation_parameter_name}
                                                                </TableCell>
                                                                <TableCell className={`${styles.tableCellDarkBorder} ${styles.tableBodyHeadingFontStyle}`} sx={{ textAlign: "center" }}>
                                                                    {evaluation.call_evaluation_parameter_weight}
                                                                </TableCell>
                                                                <TableCell className={`${styles.tableCellDarkBorder} ${styles.tableBodyHeadingFontStyle}`} sx={{ textAlign: "center" }}>
                                                                    {subEvaluationScore(evaluation.sub_call_evaluation_parameter)}
                                                                </TableCell>
                                                                <TableCell className={`${styles.tableCellDarkBorder} ${styles.tableBodyHeadingFontStyle}`}>
                                                                    -
                                                                </TableCell>
                                                            </TableRow>
                                                            {evaluation.sub_call_evaluation_parameter.map((subParameter: any, index: number) =>
                                                                <TableRow key={subParameter.call_record_evaluation_id}
                                                                    onMouseEnter={() => setShowEditIconId(subParameter.call_record_evaluation_id)}
                                                                    onMouseLeave={() => setShowEditIconId(null)}
                                                                >
                                                                    <TableCell className={`${styles.tableCellBorder} ${styles.tableBodySubHeadingFontStyle}`}>
                                                                        {subParameter.call_evaluation_parameter_description ? `${subParameter.call_evaluation_parameter_name} (${subParameter.call_evaluation_parameter_description})` : subParameter.call_evaluation_parameter_name}
                                                                    </TableCell>
                                                                    <TableCell className={`${styles.tableCellBorder} ${styles.tableBodySubHeadingFontStyle}`} sx={{ textAlign: "center" }}>
                                                                        {subParameter.call_record_evaluation_weight}
                                                                    </TableCell>
                                                                    <TableCell className={`${styles.tableCellBorder} ${styles.tableBodySubHeadingFontStyle}`}
                                                                        sx={{ textAlign: "center", position: "relative", backgroundColor: showEditIconId === subParameter.call_record_evaluation_id ? "#FAFAFA" : "#FFFFFF" }}
                                                                        onDoubleClick={() => handleEditClick(subParameter.call_evaluation_parameter_id, subParameter.call_record_evaluation_score)}
                                                                    >
                                                                        {/* {`${calculatePercentage(subParameter.call_record_evaluation_score, subParameter.call_record_evaluation_weight)}%`} */}
                                                                        {editIndex === subParameter.call_evaluation_parameter_id ? (
                                                                            <TextField
                                                                                type='number'
                                                                                value={newScore}
                                                                                onChange={(e) => handleNewScore(e.target.value, subParameter.call_record_evaluation_weight)}
                                                                                onBlur={() => handleBlur(subParameter.call_record_evaluation_id, subParameter.call_record_evaluation_score)}
                                                                                autoFocus
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-input': {
                                                                                        padding: "8.5px 7px !important",
                                                                                        fontSize: "14px !important",
                                                                                        fontWeight: "500",
                                                                                        boxShadow: "0px 0px 2px rgba(0,0,0,0.2)",
                                                                                        borderRadius: "5px",
                                                                                        textAlign: "center",
                                                                                        backgroundColor: "#FFFFFF"
                                                                                    },
                                                                                    border: 'none',
                                                                                    "& fieldset": {
                                                                                        border: 'none',
                                                                                    },
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {subParameter.call_record_evaluation_score}
                                                                                {showEditIconId === subParameter.call_record_evaluation_id &&
                                                                                    <IconButton onClick={() => handleEditClick(subParameter.call_evaluation_parameter_id, subParameter.call_record_evaluation_score)} sx={{ position: "absolute", right: "0px", top: "0px" }}>
                                                                                        <EditIcon style={{ fontSize: "14px", display: "flex", textAlign: "end" }} />
                                                                                    </IconButton>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className={`${styles.tableCellBorder} ${styles.tableBodySubHeadingFontStyle}`}>
                                                                        {subParameter.call_record_evaluation_score_explanation ? subParameter.call_record_evaluation_score_explanation : "-"}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </>
                                                    )}

                                                    <TableRow sx={{ backgroundColor: "#FBF4FF" }}>
                                                        <TableCell className={`${styles.finalNoteCellStyle} ${styles.finalNoteFontStyle}`}>FINAL SCORE</TableCell>
                                                        <TableCell className={`${styles.finalNoteCellStyle} ${styles.finalNoteSubFontStyle}`} sx={{ textAlign: "center" }}>100</TableCell>
                                                        <TableCell className={`${styles.finalNoteCellStyle} ${styles.finalNoteSubFontStyle}`} sx={{ textAlign: "center" }}>
                                                            {currentAudioAnalysis.call_record_eval_score}
                                                            {/*(Number(subEvaluationScore(currentAudioAnalysis.call_record_evaluation_score.find((evaluation: any) => evaluation.call_evaluation_parameter_id === 5).sub_call_evaluation_parameter)))).toFixed(2)*/}
                                                        </TableCell>
                                                        <TableCell className={`${styles.finalNoteCellStyle} ${styles.finalNoteSubFontStyle}`}>-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CallAnalysisAudio
