import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import { checkForErrorCode, emptyUserDetail, flagAccessNotGranted, flagCookieExpired, flagDataNotFound, getErrorMessage, invalidQueryParameter, isAdmin, isDev } from '../../../utils/constants';
import IconsColor from '../../utility/iconsColor';
import { Autocomplete, Breadcrumbs, Button, Divider, Grid, IconButton, Link, TextField, useMediaQuery } from '@mui/material';
import styles from "../../../assets/styles/componentStyles/dataconnector.module.scss";
import templateStyles from '../../../assets/styles/componentStyles/template.module.scss'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import globalStyles from "../../../assets/styles/global.module.scss";
import chatbotIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png"
import { chatbotListInterface, getChatbotResponseModel } from '../../../models/axiosModel';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import { CalendlyContext, CalendlyContextType } from '../../../context/calendlyIntegrationContext';
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';
import AddIcon from '@mui/icons-material/Add';
import { MessageFormatContext, MessageFormatContextPayload } from '../../../context/messageFormatContext';

const ChatbotDetails = () => {
    const { setError, error, setFlagLoading, userDetail, setUserDetail, setSidePanelMenuItems, flagLoading } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotDataApi, chatBotDetails, GetChatbotListApi, setChatbotList, chatbotList, setChatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const { AddNewLeadGeneration, createLeadGenerationPayload, leadGenerationList } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const { resetChatbotCalendlyData } = useContext(CalendlyContext) as CalendlyContextType
    const { integrationList } = useContext(IntegrationContext) as IntegrationContextPayload
    const { staticMessageFormatPayload } = useContext(MessageFormatContext) as MessageFormatContextPayload
    const [searchParams] = useSearchParams()
    let chatbot_id = searchParams.get("chatbot_id");
    let lead_id = searchParams.get('lead_id')
    let pathname = window.location.pathname.toLowerCase();
    let message_format_id = searchParams.get('message_format_id')
    const [flagShowValue, setFlagShowValue] = useState(false);
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const editableSubMenuItems = [
        {
            menuName: chatBotDetails?.chatbot_name,
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: `/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`,
            imgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
            whiteImgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
            coloredImgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
        },
        {
            menuName: "Basic Details",
            menuKey: "basic_details",
            navigationLink: `/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="basicdetail" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="basicdetail" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="basicdetail" />,
        },
        {
            menuName: "Channels",
            menuKey: "channels",
            navigationLink: "/chatbot/viewchatbot/channels",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="channel" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="channel" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="channel" />,
            subMenu: [
                {
                    menuName: "WhatsApp",
                    menuKey: "whatsapp",
                    navigationLink: `/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="whatsappDataConnector" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="whatsappDataConnector" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="whatsappDataConnector" height={"16"} width={"16"} />,
                },
                {
                    menuName: "FB Messenger",
                    menuKey: "facebook",
                    navigationLink: `/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="facebookDataConnector" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="facebookDataConnector" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="facebookDataConnector" height={"16"} width={"16"} />,
                },
                {
                    menuName: "Instagram",
                    menuKey: "instagram",
                    navigationLink: `/chatbot/viewchatbot/channels/instagram?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="instagramDataConnector" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="instagramDataConnector" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="instagramDataConnector" height={"16"} width={"16"} />,
                },
                {
                    menuName: "Website",
                    menuKey: "website",
                    navigationLink: `/chatbot/viewchatbot/channels/website?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                },
                // {
                //     menuName: "Calendly",
                //     menuKey: "calendly",
                //     navigationLink: `/chatbot/viewchatbot/channels/calendly?chatbot_id=${chatbot_id}`,
                //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="calender" height={"16"} width={"16"} />,
                //     whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="calender" height={"16"} width={"16"} />,
                //     coloredImgSrc: <IconsColor fillColor="#000000" iconName="calender" height={"16"} width={"16"} />,
                // },
                {
                    menuName: "Slack",
                    menuKey: "slack",
                    navigationLink: `/chatbot/viewchatbot/channels/slack?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="slack" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="slack" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="slack" height={"16"} width={"16"} />,
                },
            ]
        },
        {
            menuName: "Integrations",
            menuKey: "data_connector",
            navigationLink: `/chatbot/viewchatbot/integration?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="dataconnector" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="dataconnector" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="dataconnector" />,
        },
        {
            menuName: "Persistent Menu",
            menuKey: "persistent_menu",
            navigationLink: `/chatbot/viewchatbot/persistentmenu?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="persistentmenu" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="persistentmenu" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="persistentmenu" />,
        },
        {
            menuName: "Try Chatbot",
            menuKey: "try_chatbot",
            navigationLink: `/chatbot/viewchatbot/trychatbot?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="chatbot" />,
        },
        {
            menuName: "Inbox",
            menuKey: "inbox",
            navigationLink: `/chatbot/viewchatbot/inbox?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chat" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chat" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="chat" />,
        },
        {
            menuName: "Lead Generation",
            menuKey: "leadgeneration",
            navigationLink: `/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="leadGeneration" height={"24"} width={"24"} />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="leadGeneration" height={"24"} width={"24"} />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="leadGeneration" height={"24"} width={"24"} />,
        },
    ];

    const dynamicDataListMenu = {
        menuName: "Products",
        menuKey: "products",
        navigationLink: `/chatbot/viewchatbot/products?chatbot_id=${chatbot_id}`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="products" height={"24"} width={"24"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="products" height={"24"} width={"24"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="products" height={"24"} width={"24"} />,
    }

    const messageFormatMenu = {
        menuName: "Message Formats",
        menuKey: "message_format",
        navigationLink: `/chatbot/viewchatbot/messageformat?chatbot_id=${chatbot_id}`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chats" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chats" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="chats" />,
        flagAdmin: true
    }

    const viewSubMenuItems = [
        {
            menuName: chatBotDetails?.chatbot_name,
            menuKey: "none",
            flagHeaderKey: true,
            navigationLink: `/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`,
            imgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
            whiteImgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
            coloredImgSrc: <img src={`${chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotIcon}`} height={chatBotDetails?.chatbot_logo_url ? "24" : "26"} width={chatBotDetails?.chatbot_logo_url ? "24" : "26"} style={{ borderRadius: "50%" }} />,
        },
        {
            menuName: "Basic Details",
            menuKey: "basic_details",
            navigationLink: `/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="basicdetail" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="basicdetail" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="basicdetail" />,
        },
        {
            menuName: "Channels",
            menuKey: "channels",
            navigationLink: "/chatbot/viewchatbot/channels",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="channel" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="channel" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="channel" />,
            subMenu: [
                {
                    menuName: "Website",
                    menuKey: "website",
                    navigationLink: `/chatbot/viewchatbot/channels/website?chatbot_id=${chatbot_id}`,
                    imgSrc: <IconsColor fillColor="#4C4A4D" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                    whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                    coloredImgSrc: <IconsColor fillColor="#000000" iconName="websiteDataConnector" height={"16"} width={"16"} />,
                },
            ]
        },
        {
            menuName: "Try Chatbot",
            menuKey: "try_chatbot",
            navigationLink: `/chatbot/viewchatbot/trychatbot?chatbot_id=${chatbot_id}`,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
            whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
            coloredImgSrc: <IconsColor fillColor="#000000" iconName="chatbot" />,
        },
    ];
    let navigate = useNavigate();
    useEffect(() => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let chatbotId = searchParams.get('chatbot_id')
        if (chatbotId) {
            (async () => {
                resetChatbotCalendlyData()
                // console.log(chatBotDetails, " chat bot details in details component")
                // if (chatBotDetails && chatBotDetails?.chatbot_calendly_data?.length > 0) {
                //     setFlagAddAccount(false)
                // }
                let chatbotId = Number(chatbot_id)
                const response = await GetChatbotDataApi(chatbotId)
                let chatBotResponse = await GetChatbotListApi();
                if (chatBotResponse?.status) {
                    if (chatBotResponse?.data?.length) {
                        setChatbotList(chatBotResponse.data)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                        setFlagShowValue(true);
                    }
                    else {
                        setFlagShowValue(true)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    }
                }
                if (!response.status) {
                    let errorCode = checkForErrorCode(response);
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {
                        if (invalidQueryParameter(response) || flagAccessNotGranted(userDetail, errorCode) || flagDataNotFound(userDetail, errorCode)) {
                            navigate("/dashboard/chatbot")
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                }
            })()
        } else {
            navigate("/dashboard/chatbot")
        }
    }, [chatbot_id])

    const checkAdminOrUser = () => {
        if (chatBotDetails?.flag_dynamic_data) {
            // editableSubMenuItems.push(dynamicDataListMenu) 
            editableSubMenuItems.splice(4, 0, dynamicDataListMenu);
        }
        if (isAdmin(userDetail)) {
            editableSubMenuItems.push(messageFormatMenu)
            viewSubMenuItems.push(messageFormatMenu);
        }
    }

    useEffect(() => {
        checkAdminOrUser()
        if (chatBotDetails?.chatbot_flag_edit) {
            setSidePanelMenuItems(editableSubMenuItems)
        } else {
            setSidePanelMenuItems(viewSubMenuItems)
            // if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/whatsapp" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/chats" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/dataconnector" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/createdataconnector") {
            //     navigate(`/chatbot/viewchatbot/basicdetail${window.location.search}`)
            // }
        }
    }, [window.location.pathname, chatBotDetails])

    const handleOptionChange = (e: React.ChangeEvent<{}>, value: chatbotListInterface) => {
        const selectedChatbot: any = chatbotList?.find((chatbot) => chatbot.chatbot_id === value.chatbot_id);
        if (selectedChatbot) {
            // setChatBotDetails(selectedChatbot);
            // setSearchParams(`chatbot_id=${selectedChatbot.chatbot_id}`);
            navigate(`${pathname}?chatbot_id=${selectedChatbot.chatbot_id}`)
        }
    }

    return (
        <>
            {(pathname !== "/chatbot/viewchatbot/integration/createcustomapi" && pathname !== "/chatbot/viewchatbot/products") &&
                <Grid item xl={12} xs={12} className={`${globalStyles.fullwidth} ${globalStyles.dashboardChildWrapper} ${styles.dataConnectorDashboard}`} sx={{ width: "100%" }}>
                    {flagShowValue && <Grid container alignItems={"center"} justifyContent={"end"} >
                        <Grid item xs={12}>
                            <Grid container xs={12} sx={{ alignItems: "center" }}>
                                <Grid item sx={{ marginBottom: "5px", width: "100%" }}>
                                    <GlobalPageHeaderText
                                        flagTimezone={pathname === "/chatbot/viewchatbot/inbox" && !smallScreen ? true : false}
                                        actionBlock={
                                            pathname === "/chatbot/viewchatbot/leadgeneration" && leadGenerationList && (leadGenerationList.chatbot_lead_data.length > 0) &&
                                            <Grid item>
                                                <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                                                    <Grid item>
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid item className={`${templateStyles.addChatbotBtn}`}>
                                                                <Button
                                                                    variant='outlined'
                                                                    size='large'
                                                                    sx={{ width: "178px" }}
                                                                    fullWidth
                                                                    startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                                                    className={globalStyles.addDocumentBtn}
                                                                    onClick={() => { navigate(`/chatbot/viewchatbot/leadgeneration/createleadgeneration?chatbot_id=${chatbot_id}`); AddNewLeadGeneration() }}
                                                                >
                                                                    New Lead
                                                                </Button>
                                                            </Grid>
                                                            <IconButton
                                                                className={`${globalStyles.cmpGlobalMobileAddIcon} ${templateStyles.addChatbotIcon}`}
                                                                onClick={() => { navigate(`/chatbot/viewchatbot/leadgeneration/createleadgeneration?chatbot_id=${chatbot_id}`); AddNewLeadGeneration() }}
                                                            >
                                                                <AddIcon style={{ height: "28px", width: 'auto' }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        mainHeadText={
                                            <Grid item xs={9} lg={6}>
                                                <Autocomplete
                                                    disableClearable
                                                    value={chatbotList && chatBotDetails ? chatbotList.find((chatbot) => Number(chatbot.chatbot_id) == chatBotDetails.chatbot_id) : undefined}
                                                    className={`${styles.chatbotTitleSelect} ${globalStyles.globalHeaderFontSize} ${globalStyles.globalHeaderFontWeight}`}
                                                    options={chatbotList ? chatbotList : []}
                                                    getOptionLabel={(option: chatbotListInterface) => option.chatbot_name}
                                                    onChange={(e, value) => handleOptionChange(e, value)}
                                                    renderInput={(params) => <TextField
                                                        {...params} placeholder='Select' />}
                                                />

                                            </Grid>
                                        }
                                        flagMainTextOnly
                                        mainSubText={
                                            !smallScreen && <Breadcrumbs
                                                aria-label="breadcrumb"
                                                className={styles.cbHeaderBreadCrumb}
                                                sx={{
                                                    '& .MuiBreadcrumbs-separator': {
                                                        marginRight: '3px',
                                                        marginLeft: '3px'
                                                    },
                                                    '& .MuiBreadcrumbs-ol': {
                                                        flexWrap: "nowrap",
                                                        whiteSpace: "nowrap",
                                                        overflow: "auto",
                                                        textOverflow: "ellipsis",

                                                        '&::-webkit-scrollbar': {
                                                            width: "1px !important",
                                                            height: "1px !important"
                                                        },

                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: "#FFFFFF !important"
                                                        }
                                                    },
                                                }}
                                            >
                                                {(pathname !== "/" && pathname !== "/chatbot") &&
                                                    <Link
                                                        underline="hover"
                                                        onClick={() => { navigate("/chatbot") }}
                                                        className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                    >
                                                        Home
                                                    </Link>}

                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate("/chatbot") }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    AI Chatbot
                                                </Link>

                                                {(pathname === "/chatbot/viewchatbot" || pathname === "/chatbot/viewchatbot/basicdetail") &&
                                                    <Link
                                                        underline="hover"
                                                        className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                    >
                                                        {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                    </Link>
                                                }

                                                {(pathname === "/chatbot/viewchatbot/channels/whatsapp" || pathname === "/chatbot/viewchatbot/channels/website" || pathname === "/chatbot/viewchatbot/channels/facebook" || pathname === "/chatbot/viewchatbot/channels/instagram" || pathname === "/chatbot/viewchatbot/channels/calendly" || pathname === "/chatbot/viewchatbot/channels/calendly/authoriseaccount" || pathname === "/chatbot/viewchatbot/channels/calendly/selectcalendlyevent" || pathname === "/chatbot/viewchatbot/channels/calendly/fieldsetup" || pathname === "/chatbot/viewchatbot/channels/calendly/setupAccount" || pathname === "/chatbot/viewchatbot/channels/slack") &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            {pathname === "/chatbot/viewchatbot/channels/whatsapp" ? "WhatsApp Integration" : pathname === "/chatbot/viewchatbot/channels/facebook" ? "Facebook Integration" : pathname === "/chatbot/viewchatbot/channels/instagram" ? "Instagram Integration" : (pathname === "/chatbot/viewchatbot/channels/calendly" || pathname === "/chatbot/viewchatbot/channels/calendly/authoriseaccount" || pathname === "/chatbot/viewchatbot/channels/calendly/selectcalendlyevent" || pathname === "/chatbot/viewchatbot/channels/calendly/fieldsetup" || pathname === "/chatbot/viewchatbot/channels/calendly/setupAccount") ? "Calendly" : pathname === "/chatbot/viewchatbot/channels/slack" ? "Slack Integration" : "Website"}
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {(pathname === "/chatbot/viewchatbot/channels/viewwhatsappintegration") &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            WhatsApp Integration
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Integration Details
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {(pathname === "/chatbot/viewchatbot/channels/viewfacebookintegration") &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            Facebook Integration
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Integration Details
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/integration" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Integrations
                                                        </Link>
                                                    </Breadcrumbs>
                                                }
                                                {pathname === "/chatbot/viewchatbot/integration/customapi" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/integration?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            Integrations
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Custom API
                                                        </Link>
                                                    </Breadcrumbs>
                                                }
                                                {pathname === "/chatbot/viewchatbot/integration/slack" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/integration?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            Integrations
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Slack
                                                        </Link>
                                                    </Breadcrumbs>
                                                }
                                                {pathname === "/chatbot/viewchatbot/trychatbot" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Try Chatbot
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/inbox" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Inbox
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/messageformat" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            Message Formats
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/messageformat/staticformat" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/messageformat?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                        >
                                                            Message Formats
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            {message_format_id ? staticMessageFormatPayload.chatbot_static_message_name : "Create Static Message Format"}
                                                        </Link>
                                                    </Breadcrumbs>
                                                }


                                                {pathname === "/chatbot/viewchatbot/persistentmenu" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            Persistent Menu
                                                        </Link>
                                                    </Breadcrumbs>
                                                }
                                                {(pathname === "/chatbot/viewchatbot/integration/calendly") &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/integration`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            Integrations
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                        >
                                                            Calendly
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/leadgeneration" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            // onClick={() => { lead_id && navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            Lead Generation
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {pathname === "/chatbot/viewchatbot/leadgeneration/createleadgeneration" &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                        >
                                                            Lead Generation
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            {lead_id ? createLeadGenerationPayload.chatbot_lead_name : "Add New Lead"}
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                                {(pathname === "/chatbot/viewchatbot/leadgeneration/email" || pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet" || pathname === "/chatbot/viewchatbot/leadgeneration/slack") &&
                                                    <Breadcrumbs>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                        >
                                                            {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`) }}
                                                            className={lead_id ? `${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}` : `${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            Lead Generation
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            onClick={() => { navigate(`/chatbot/viewchatbot/leadgeneration/createleadgeneration?chatbot_id=${chatbot_id}&lead_id=${lead_id}`) }}
                                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                        >
                                                            {createLeadGenerationPayload.chatbot_lead_name}
                                                        </Link>
                                                        <Link
                                                            underline="hover"
                                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                        >
                                                            {pathname === "/chatbot/viewchatbot/leadgeneration/email" ? "Email" : pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet" ? "Spreadsheet" : "Slack"}
                                                        </Link>
                                                    </Breadcrumbs>
                                                }

                                            </Breadcrumbs>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {!smallScreen && <Divider className={globalStyles.dividerStyles} style={{ color: "#000" }} />}
                    </Grid>}
                </Grid>
            }

            {flagShowValue && <Outlet />}
        </>
    )
}

export default ChatbotDetails
