import { Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { convertKeysToLowerCase, isJsonString } from '../../../utils/constants';
import styles from '../../../assets/styles/componentStyles/carouselCard.module.scss';
import carouselStyles from '../../../assets/styles/componentStyles/widgetComponentStyles/dynamicCarousel.module.scss'

const CardCarouselDynamicFormat = (props: any) => {
    const { handleMouseDown, handleMouseUp, image, handleActionButton, getActionButtonIcon, chatbotTheme } = props;
    const [currentCartData, setCurrentCardData] = useState(image?.variants?.find((data: any) => data.flag_default === true) || image?.variants ? image?.variants[0] : null)

    return (
        <Grid container>
            <Grid item xs={12} sx={{ padding: '0px' }}>
                <Grid container>
                    <Grid item xs={12} className={styles.chat_crl_img_item} sx={{borderBottom: "1px solid #EAEAEA" }}>
                        <img
                            onMouseDown={handleMouseDown}
                            onMouseUp={(event) => handleMouseUp(event, image?.variants?.find((data: any) => data.flag_default === true) || image?.variants ? image?.variants[0] : null, true)}
                            src={
                                (() => {
                                    const fieldData = currentCartData?.variant_data?.find((data: any) => data.field_name === "image_url");
                                    return Array.isArray(fieldData?.field_value)
                                        ? fieldData.field_value.length > 0 ? fieldData.field_value[0] : "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"
                                        : fieldData?.field_value ||
                                          "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png";
                                })()
                            }
                            alt=""
                            loading='lazy'
                            className={carouselStyles.dynamic_image_style}
                            style={{objectFit : image?.flag_image_compact ? "scale-down" : "cover", objectPosition : image?.flag_image_compact ? "center": "top"}}
                        />
                        {/* </LazyLoad> */}
                    </Grid>

                    <Grid item xs={12} sx={{ cursor: "pointer", height: "100%", paddingTop: "0px", borderRadius: "0px 0px 10px 10px", background: chatbotTheme?.system_msg_bg_color ? chatbotTheme?.system_msg_bg_color : "#fff" }}>
                        <Grid container sx={{marginBlock: "7px 4px !important" }}>
                            <Grid item xs={12} className={carouselStyles.lower_section_carousel_style}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ paddingRight: "0px !important", overflowY: "scroll", margin: "0 !important" }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ margin: '5px 0px 0px', padding: '5px 9px 5px', width: "100%" }}>
                                                <Grid container direction={"column"} alignItems={"start"}>

                                                    <Grid item mb={currentCartData?.variant_data?.find((data: any) => data.field_name === "product_description") ? "" : "10px"}>
                                                        <Typography
                                                            component="span"
                                                            className={`${carouselStyles.global_textfield_common_style} ${carouselStyles.primary_textfield_style}`}
                                                            sx={{color: image?.item_style?.header_color ? `${image?.item_style?.header_color}` : "#000"}}
                                                        >
                                                            {`${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_name")?.field_value} `}
                                                        </Typography>
                                                    </Grid>

                                                    {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_description") &&
                                                        <Grid item mt={"5px"} mb={"5px"}>
                                                            <Typography
                                                                component="span"
                                                                className={`${carouselStyles.global_textfield_common_style} ${carouselStyles.ternary_textfield_style} ${carouselStyles.ternary_textfield_light_style}`}
                                                            >
                                                                {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_description")?.field_value}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    {currentCartData?.variant_data?.filter((data: any) => data?.field_flag_dynamic && data.field_flag_dynamic === true) && currentCartData?.variant_data?.filter((data: any) => data?.field_flag_dynamic && data.field_flag_dynamic === true)?.length > 0 &&
                                                        currentCartData?.variant_data?.filter((data: any) => data?.field_flag_dynamic && data.field_flag_dynamic === true).map((dynamic: any) => 
                                                            <Grid item mt={"6px"} xs={12} sx={{width: "100%"}}>
                                                                <Grid container justifyContent={"space-between"}>
                                                                    {dynamic.field_label !== "" && !dynamic?.flag_parse_and_create &&
                                                                        <Grid item className={carouselStyles.ternary_dark_textfield_style} xs={5}>
                                                                            {dynamic.field_label}
                                                                        </Grid>
                                                                    }
                                                                    <Grid item className={carouselStyles.ternary_dark_textfield_style} xs={dynamic.field_label !== "" && !dynamic?.flag_parse_and_create ? 7 : 12}>
                                                                        {dynamic?.flag_parse_and_create ? 
                                                                            Array.isArray(dynamic.field_value) ?
                                                                            dynamic.field_value.map((fieldvalues: any, index: any) => (
                                                                                <ul key={index} className={carouselStyles.dynamic_list_style}>
                                                                                    <li>
                                                                                        {Object.values(fieldvalues)[0] as String}
                                                                                        <ul className={carouselStyles.dynamic_sub_list_style}>
                                                                                            {Object.entries(fieldvalues)
                                                                                                .slice(1)
                                                                                                .map(([key, value], index) => (
                                                                                                    <li key={index}>
                                                                                                        {key}: 
                                                                                                        <span 
                                                                                                            className={`${carouselStyles.ternary_textfield_style}`}
                                                                                                            style={{color: image?.item_style?.field_value_color ? `${image?.item_style?.field_value_color}` : "#7A7A7A"}}
                                                                                                        >
                                                                                                            {value === "" ? " -" : ` ${value}`}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                ))}
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            ))
                                                                            :
                                                                            dynamic.field_value && dynamic.field_value !== "" && dynamic.field_value !== "[]" && !Array.isArray(dynamic.field_value) &&
                                                                            JSON.parse(dynamic.field_value).map((fields: any, index: any) => (
                                                                                <ul key={index} className={carouselStyles.dynamic_list_style}>
                                                                                    <li>
                                                                                        {Object.values(fields)[0] as String}
                                                                                        <ul className={carouselStyles.dynamic_sub_list_style}>
                                                                                            {Object.entries(fields)
                                                                                                .slice(1)
                                                                                                .map(([key, value], index) => (
                                                                                                    <li key={index}>
                                                                                                        {key}: 
                                                                                                            <span 
                                                                                                                className={carouselStyles.ternary_textfield_style}
                                                                                                                style={{color: image?.item_style?.field_value_color ? `${image?.item_style?.field_value_color}` : "#7A7A7A"}}
                                                                                                            >
                                                                                                                {value === "" ? " -" : ` ${value}`}
                                                                                                            </span>
                                                                                                    </li>
                                                                                                ))}
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            ))
                                                                        : 
                                                                            <span 
                                                                                className={carouselStyles.ternary_textfield_style}
                                                                                style={{
                                                                                    color: image?.item_style?.field_value_color ? `${image?.item_style?.field_value_color}` : dynamic?.flag_url ? "blue" : "#7A7A7A",
                                                                                    fontStyle: dynamic?.flag_url ? "italic" : ""
                                                                                }}
                                                                                onClick={() => {dynamic?.flag_url && window.open(dynamic.field_value, "_blank")}}
                                                                            >
                                                                                {dynamic?.flag_url ? dynamic?.url_text != "" ? dynamic?.url_text : dynamic?.field_value : (dynamic?.field_value === "" || dynamic?.field_value == "[]" ) ? "-" : dynamic?.field_value}
                                                                            </span>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {convertKeysToLowerCase(image)?.cta_buttons && convertKeysToLowerCase(image)?.cta_buttons?.map((button: any) => {
                return <Grid container mt={1}>
                    <Divider style={{ width: '100%' }} />
                    <Grid item xs={12} className={styles.chat_crl_media_item} sx={{ cursor: "pointer" }}>
                        <Grid container sx={{ justifyContent: 'center' }} onClick={() => { handleActionButton(button) }}>
                            {getActionButtonIcon(button.message_format_id)}

                            <Typography
                                component="span"
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#2E79BD',
                                    marginLeft: "5px !important",
                                }}
                            >
                                {button.message_fields.url_text}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            })
            }
        </Grid>
    )
}

export default CardCarouselDynamicFormat
