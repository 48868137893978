import React, { useContext, useEffect, useState } from 'react'
import { chatbotLeadDataModel, createLeadGenerationPayloadModel, createLeadGenerationResponseModel, getGenericFieldTypeDataResponseModel, leadFieldDataModel } from '../../../models/axiosModel'
import { Button, Grid, ListItemIcon, Menu, MenuItem, useMediaQuery } from '@mui/material'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationCard.module.scss'
import globalStyles from './../../../assets/styles/global.module.scss'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconsColor from '../../utility/iconsColor'
import GlobalPopUp from '../../utility/globalPopUp'
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import agent from '../../../services/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import { initialLeadGeneraionData } from './leadGenerationConstants'
import spreadsheetIcon from './../../../assets/images/leadGeneration/spreadsheet.svg'
import slackIcon from './../../../assets/images/leadGeneration/slack.svg'
import mailIcon from './../../../assets/images/leadGeneration/mail.svg'

const LeadGenerationList = () => {
    const { setFlagLoading, setSuccess, flagLoading, success, setError, error } = useContext(UserContext) as UserContextPayload;
    const { leadGenerationList, GetLeadGenerationList, setTempLeadGenerationPayload, setCreateLeadGenerationPayload, setStepContainer } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const [headerListMenu, setHeaderListMenu] = useState<null | HTMLElement>(null);
    const open = Boolean(headerListMenu);
    const [flagDeleteLeadGenerationPopup, setFlagDeleteLeadGenerationPopup] = useState(false);
    const [leadGenerationToDeleted, setLeadGenerationToDeleted] = useState<chatbotLeadDataModel>();
    const [searchParams] = useSearchParams();
    let chatbot_id = searchParams.get("chatbot_id");
    let lead_id = searchParams.get("lead_id");
    const navigate = useNavigate();
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 900px)');

    useEffect(() => {
        setStepContainer(1)
    }, [])

    const handleClickLeadGenerationListMenu = (event: React.MouseEvent<HTMLButtonElement>, lead_id: number) => {
        setHeaderListMenu(event.currentTarget);
        setLeadGenerationToDeleted(leadGenerationList?.chatbot_lead_data.find((data) => data.chatbot_lead_id === lead_id))
    };

    const handleCloseLeadGenerationListMenu = () => {
        setHeaderListMenu(null);
    };

    const deleteLeadGenerationPopupOpen = () => {
        setFlagDeleteLeadGenerationPopup(true)
    }

    const updateLeadGenerationData = async () => {
        // await GetLeadGenerationList(chatbot_id || "", leadGenerationToDeleted?.chatbot_lead_id?.toString())
        navigate(`${window.location.pathname}/createleadgeneration?chatbot_id=${chatbot_id}&lead_id=${leadGenerationToDeleted?.chatbot_lead_id}`)
    }

    const handleRemoveLeadGeneration = async (lead_id?: number) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.deleteLeadGeneration.delete(chatbot_id || "", lead_id?.toString() || "");
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Lead Generation Removed Successfully" })
            setCreateLeadGenerationPayload(initialLeadGeneraionData)
            setTempLeadGenerationPayload(initialLeadGeneraionData)
            await GetLeadGenerationList(chatbot_id || "")
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFlagDeleteLeadGenerationPopup(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    const handleShowImage = (name: string) => {
        switch (name) {
            case "Email":
                return mailIcon
            case "Slack":
                return slackIcon
            case "Spreadsheet":
                return spreadsheetIcon
            default:
                return mailIcon
        }
    }

    const menuItems = [
        { label: 'Update', icon: <EditIcon sx={{ fontSize: "18px", color: "#7A7A7A" }} />, action: (e: any) => updateLeadGenerationData() },
        { label: 'Delete', icon: <IconsColor iconName="DeleteIcon" width="16" height="16" fillColor="#7A7A7A" />, action: (e: any) => deleteLeadGenerationPopupOpen() },
    ];

    const innerHeight = flagMobileScreen ? (window.innerHeight - 140) : flagTabletScreen ? (window.innerHeight - 160) : (window.innerHeight - 120);

    return (
        <Grid container sx={{ padding: { xs: "0px 20px 3px 23px", lg: "0px 25px 3px 27px", height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto" } }} onClick={(event) => { event.stopPropagation(); }}>
            {/* {!lead_id ?  */}
            <Grid item xs={12}>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        <span className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>Created Gen List</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container gap={2} sx={{ padding: "0px 5px" }}>
                            {leadGenerationList?.chatbot_lead_data.map((list) =>
                                <Grid item className={styles.leadGenerCardStyle} onClick={() => navigate(`${window.location.pathname}/createleadgeneration?chatbot_id=${chatbot_id}&lead_id=${list.chatbot_lead_id}`)}>
                                    <Grid container direction={"column"} gap={2}>
                                        <Grid item>
                                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                <Grid item>
                                                    <Grid container alignItems={"center"} gap={2}>
                                                        <Grid item className={styles.leadGenerationCardIcon}>
                                                            <IconsColor iconName="leadGeneration" width="28" height="28" fillColor="#6A097D" />
                                                        </Grid>
                                                        <Grid item>
                                                            <span className={styles.connectedFontStyle} style={{ color: list.chatbot_lead_integration_type && list.chatbot_lead_integration_type.length > 0 ? "#32BB46" : "#BB3232" }}>{list.chatbot_lead_integration_type && list.chatbot_lead_integration_type.length > 0 ? "Connected" : "Not Connected"}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container gap={"6px"} alignItems={"center"}>
                                                        {list.chatbot_lead_integration_type && list.chatbot_lead_integration_type.length > 0 &&
                                                            <Grid item>
                                                                <Grid container gap={"7px"}>
                                                                    {list.chatbot_lead_integration_type.map((data) =>
                                                                        <img src={handleShowImage(data.chatbot_lead_integration_type_name)} height={"20"} width={"20"} style={{display: "flex"}}/>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            {/* <MoreVertIcon style={{fontSize: "20px", color: "#7A7A7A"}} /> */}
                                                            <Button
                                                                id="basic-button"
                                                                className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                                                                aria-controls={open ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? 'true' : undefined}
                                                                disableRipple
                                                                onClick={(event) => { event.stopPropagation(); handleClickLeadGenerationListMenu(event, list.chatbot_lead_id) }}
                                                            >
                                                                <MoreVertIcon />
                                                            </Button>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={headerListMenu}
                                                                open={open}
                                                                className={styles.moreVerticalIconMenu}
                                                                onClose={handleCloseLeadGenerationListMenu}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                            >
                                                                {menuItems.map((item: any, index: any) => (
                                                                    <MenuItem
                                                                        className={globalStyles.menuItemStyle}
                                                                        key={index}
                                                                        onClick={(e) => { e.stopPropagation(); item.action(); handleCloseLeadGenerationListMenu(); }}
                                                                    // disabled={item.disabled ? item.disabled(rowDetail) : false}
                                                                    >
                                                                        <ListItemIcon>
                                                                            {item.icon}
                                                                        </ListItemIcon>
                                                                        <span>
                                                                            {item.label}
                                                                        </span>
                                                                    </MenuItem>
                                                                ))}
                                                            </Menu>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <span className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>{list.chatbot_lead_name}</span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item>
                                                    <span className={`${styles.cardFieldStyle} ${globalStyles.secondaryFontWeight}`}>No. of Fields</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className={`${globalStyles.formLabelFontSize} ${globalStyles.secondaryFontWeight}`}>{list.chatbot_lead_field_count ? list.chatbot_lead_field_count : "-"}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* :
                <Grid item xs={12}>
                    <LeadGenerationData 
                        leadGenerationList={leadGenerationList}
                        createLeadGenerationPayload={createLeadGenerationPayload} 
                        setLeadGenerationFieldPayload={setLeadGenerationFieldPayload}
                        genericFieldDataType={genericFieldDataType}
                        setAddNewLeadField={setAddNewLeadField}
                        setFlagUpdateField={setFlagUpdateField}
                        setFlagDeleteFieldData={setFlagDeleteFieldData}
                    />
                </Grid>
            } */}

            {flagDeleteLeadGenerationPopup &&
                <GlobalPopUp
                    open={flagDeleteLeadGenerationPopup}
                    handleClose={() => { setFlagDeleteLeadGenerationPopup(false) }}
                >
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to delete ${leadGenerationToDeleted?.chatbot_lead_name}`}
                        onClose={() => { setFlagDeleteLeadGenerationPopup(false) }}
                        onDelete={() => handleRemoveLeadGeneration(leadGenerationToDeleted?.chatbot_lead_id)}
                        sx={{ maxWidth: "400px" }}
                    />
                </GlobalPopUp>
            }
        </Grid>
    )
}

export default LeadGenerationList
