import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, TablePagination, Menu, MenuItem, TextField, ListItemIcon, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import globalStyles from "../../assets/styles/global.module.scss";
import deleteDoc from "../../assets/deleteDoc.svg";
import globalStrenameDocumentyles from "../../assets/renameDocument.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import docIcon from "../../assets/document.svg";
import deleteIcon from "../../assets/deleteChatbotDomain.svg";
import { HeaderListObjectModel } from '../../models/componentModel';
import { DocumentListDataModel } from '../../models/axiosModel';
import addSubMenuIcon from "../../assets/addSubMenuIcon.png";
import moment from 'moment';
import { ChatbotContext, ChatbotContextPayload } from '../../context/chatbotContext';
import dashboardStyles from './../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'


interface RowAllowEditInterface {
    document_id: number
}

export const GlobalTable = (props: any) => {
    const {
        rowData, headerList, tableHeight, onRowClick, deleteRowData, renameDocumentName, flagPagination, rowClicked, tableContainerHeight, tableContainerMinHeight, selectedRow, additionalRowClass,
        menuItemsFromParent, hoveredRow, setHoveredRow, menuItemOnClickOfAddIcon
    } = props
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowAllowEdit, setRowAllowEdit] = useState<RowAllowEditInterface | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElAdd, setAnchorElAdd] = useState<null | HTMLElement>(null);
    const [currentRowIndex, setCurrentRowIndex] = useState<string | null>(null);
    const [sortByUpdatedAt, setSortByUpdatedAt] = useState<'asc' | 'desc'>('asc');
    const [rowDataState, setRowDataState] = useState<DocumentListDataModel[]>([]);
    const [flagDocumentNameChanged, setFlagDocumentNameChanged] = useState(false);
    const [flagRenameButtonClicked, setRenameButtonClicked] = useState(false);
    const [dateHover, setDateHover] = useState(false);

    const [currRowIndForOpenAddDialog, setCurrRowIndForOpenAddDialog] = useState<string | null>(null);
    const { chatbotStaticMessageFormat } = useContext(ChatbotContext) as ChatbotContextPayload

    useEffect(() => {
        // rowData?.map((dateFormat: any) => {
        //     dateFormat.updated_at_timestamp = moment(dateFormat.updated_at_timestamp).format('DD-MM-YY')
        // })
        setRowDataState(rowData);
    }, [rowData])
    const menuItems = [
        {
            label: 'Rename', icon: <img src={globalStrenameDocumentyles} />, action: (e: any) => {
                setRenameButtonClicked(true)
                setTimeout(() => {
                    setRowAllowEdit(e)
                }, 50);
            }
        },
        { label: 'Delete', icon: <img src={deleteDoc} />, action: (e: any) => deleteRowData(e) },
    ];


    const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: string) => {
        setCurrentRowIndex(rowIndex);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setCurrentRowIndex(null);
        setAnchorEl(null);
    };

    const handleAddMenuOpen = (sequenceNumber: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setCurrRowIndForOpenAddDialog(sequenceNumber);
        setAnchorElAdd(e.currentTarget);
    }

    const handleAddMenuClose = () => {
        setAnchorElAdd(null);
        setCurrRowIndForOpenAddDialog(null);
    }

    const handleStatusColor = (value: any) => {
        switch (value) {
            case "Active":
                return { backgroundColor: "rgba(20, 178, 55, 0.1)", color: "#14B237", boxShadow: "0px 0px 2px #14B237" }
            case "Registered":
                return { backgroundColor: "rgba(221, 200, 10, 0.1)", color: "#D3C00D", boxShadow: "0px 0px 2px #DDC80A" }
        }
    }

    const handleSortByUpdatedAt = () => {
        const newSortOrder = sortByUpdatedAt === 'asc' ? 'desc' : 'asc';
        setSortByUpdatedAt(newSortOrder);

        const sortedData = [...rowDataState].sort((a, b) => {
            const timestampA = moment(a.updated_at_timestamp).valueOf();
            const timestampB = moment(b.updated_at_timestamp).valueOf();

            if (newSortOrder === 'asc') {
                if (isNaN(timestampB)) {
                    return timestampA;
                }
                if (timestampA === timestampB) {
                    const timeA = moment(a.updated_at_timestamp).format('HH:mm:ss');
                    const timeB = moment(b.updated_at_timestamp).format('HH:mm:ss');
                    return timeA.localeCompare(timeB);
                }
                return timestampA - timestampB;
            } else {
                if (isNaN(timestampB)) {
                    return timestampA;
                }
                if (timestampA === timestampB) {
                    const timeA = moment(a.updated_at_timestamp).format('HH:mm:ss');
                    const timeB = moment(b.updated_at_timestamp).format('HH:mm:ss');
                    return timeB.localeCompare(timeA);
                }
                return timestampB - timestampA;
            }
        });
        setRowDataState(sortedData);
    };

    function formatValue(rowDetail: any, headerObj: HeaderListObjectModel) {
        const value = rowDetail[headerObj.valueKey];
        if (headerObj.flagHtml) {
            return <span style={{ backgroundColor: handleStatusColor(value)?.backgroundColor, color: handleStatusColor(value)?.color, boxShadow: handleStatusColor(value)?.boxShadow, padding: "3px 16px", borderRadius: "15px", fontWeight: "600", display: "flex" }}>{value}</span>
        }

        return typeof value === "string" ?
            (value.trim().length === 0 ? "-" : value) :
            Array.isArray(value) ?
                (value.length === 0 ? "-" : value) :
                value;
    }

    const generateSequenceNumber = (prefix: string, index: number) => {
        return prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
    };

    const renderTableRows = (rowDetails: any, prefix: string = "") => {
        if (rowDetails.length > 0) {
            return (
                rowDetails?.map((rowDetail: any, i: number) => {
                    const sequenceNumber = generateSequenceNumber(prefix, i);
                    return (
                        <>
                            <TableRow onClick={() => { onRowClick(rowDetail) }} key={`${rowDetail.chatbot_persistent_menu_id}`} className={selectedRow === i ? `${globalStyles.bodyRow} ${globalStyles.selectedBodyRow} ${dashboardStyles.cellRow}` : `${globalStyles.bodyRow} ${dashboardStyles.cellRow}`} onMouseEnter={() => { setHoveredRow && setHoveredRow(rowDetail) }} onMouseLeave={() => { setHoveredRow && setHoveredRow(null); setAnchorElAdd(null) }} >
                                {headerList?.map((headerObj: HeaderListObjectModel) => {
                                    return (
                                        !headerObj.hidden &&
                                        <TableCell
                                            align={headerObj.alignment}
                                            sx={headerObj.sx ? headerObj.sx : ""}
                                            className={headerObj.iconKey == "documentIcon" ? globalStyles.documentClickCell : ""}
                                            onClick={() => props.onCellClick ? props.onCellClick(headerObj, rowDetail) : props.onRowClick(rowDetail)}>
                                            {headerObj.iconKey == "editIcon" ?
                                                (
                                                    <>
                                                        <MoreVertIcon
                                                            sx={{ cursor: "pointer", color: "#7A7A7A" }}
                                                            onClick={(event: any) => handleMenuOpen(event, sequenceNumber)}
                                                        />
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl) && currentRowIndex === sequenceNumber}
                                                            onClose={handleMenuClose}
                                                        >
                                                            {
                                                                menuItemsFromParent ?
                                                                    <>
                                                                        {menuItemsFromParent.map((item: any, index: any) => (
                                                                            <MenuItem
                                                                                className={globalStyles.menuItemStyle}
                                                                                key={index}
                                                                                onClick={(e) => { handleMenuClose(); item.action(rowDetail); }}
                                                                                disabled={item.disabled ? item.disabled(rowDetail) : false}
                                                                                sx={{ fontSize: "14px" }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {item.icon}
                                                                                </ListItemIcon>
                                                                                <span>
                                                                                    {item.label}
                                                                                </span>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {menuItems.map((item, index) => (
                                                                            <MenuItem
                                                                                className={globalStyles.menuItemStyle}
                                                                                key={index}
                                                                                onClick={(e) => { handleMenuClose(); item.action(rowDetail); }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {item.icon}
                                                                                </ListItemIcon>
                                                                                <span>
                                                                                    {item.label}
                                                                                </span>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </>

                                                            }
                                                        </Menu>
                                                    </>
                                                )
                                                :
                                                <>
                                                    <Grid container
                                                        className={`${globalStyles.tableRowStyle} ${globalStyles[additionalRowClass]}`}
                                                        style={{
                                                            justifyContent: headerObj.iconKey == "deleteIcon" ? "end" : "start",
                                                            flexWrap: "nowrap"
                                                        }}
                                                    >
                                                        {headerObj.flagIcon ?
                                                            <Grid item style={{ marginRight: "10px", alignItems: "center", display: "flex" }}  >
                                                                {headerObj.iconKey == "deleteIcon" ?
                                                                    <img src={deleteIcon} className={globalStyles.tableCategoryImage} style={{ cursor: "pointer" }}
                                                                        onClick={(e: any) => {
                                                                            deleteRowData(e, rowDetail)
                                                                        }}
                                                                    />
                                                                    :
                                                                    headerObj.iconKey == "documentIcon" ?
                                                                        <img src={docIcon} className={globalStyles.tableCategoryImage} />
                                                                        :
                                                                        <img src={rowDetail.iconSrc} className={globalStyles.tableCategoryImage} />
                                                                }
                                                            </Grid>
                                                            : ""}
                                                        {headerObj.flagHover === true &&
                                                            <Grid item style={{ display: "flex", alignItems: "center", width: "1px" }}>
                                                                {headerObj.iconKey === "addIcon" ?
                                                                    <>
                                                                        {(rowDetail.chatbot_persistent_menu_id === hoveredRow?.chatbot_persistent_menu_id) &&
                                                                            <>
                                                                                {/* <img src={addSubMenuIcon} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props.onHoveredIconClick(headerObj, rowDetail) }} /> */}
                                                                                <img src={addSubMenuIcon} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); handleAddMenuOpen(sequenceNumber, e) }} />
                                                                                <Menu
                                                                                    anchorEl={anchorElAdd}
                                                                                    keepMounted
                                                                                    open={Boolean(anchorElAdd) && currRowIndForOpenAddDialog === sequenceNumber}
                                                                                    onClose={handleAddMenuClose}
                                                                                >
                                                                                    {menuItemOnClickOfAddIcon ?
                                                                                        <>
                                                                                            {menuItemOnClickOfAddIcon.map((item: any, ind: number) => {
                                                                                                return (
                                                                                                    <MenuItem
                                                                                                        className={globalStyles.menuItemStyle}
                                                                                                        key={ind}
                                                                                                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleAddMenuClose(); item.action(headerObj, rowDetail); }}
                                                                                                        disabled={item.disabled ? item.disabled(rowDetail) : false}
                                                                                                        sx={{ fontSize: "14px" }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            {item.label}
                                                                                                        </span>
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </Menu>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <> </>
                                                                }
                                                            </Grid>
                                                        }
                                                        {headerObj.iconKey === "sequenceNumber" &&
                                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{sequenceNumber}</Typography>
                                                        }
                                                        {headerObj.iconKey === "action_type" &&
                                                            <>
                                                                {rowDetail.static_message_id ? <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Static Message</Typography>
                                                                    : (rowDetail.chatbot_persistent_menu_value?.length > 0 && rowDetail.chatbot_persistent_menu_value != null) ? <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>Text</Typography>
                                                                        : <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>None</Typography>}
                                                            </>
                                                        }
                                                        {headerObj.iconKey === "action" &&
                                                            <>
                                                                {rowDetail.static_message_id ? chatbotStaticMessageFormat.find((msgFormat) => msgFormat.chatbot_static_message_id === rowDetail.static_message_id)?.chatbot_static_message_name
                                                                    : (rowDetail.chatbot_persistent_menu_value?.length > 0 && rowDetail.chatbot_persistent_menu_value != null) ? rowDetail.chatbot_persistent_menu_value
                                                                        : <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>-</Typography>}
                                                            </>
                                                        }
                                                        <Grid item>
                                                            {(rowAllowEdit != null && rowAllowEdit.document_id) == rowDetail.document_id ?
                                                                headerObj.iconKey == "documentIcon" ?
                                                                    <TextField
                                                                        key="1"
                                                                        defaultValue={rowDetail[headerObj.valueKey]}
                                                                        autoFocus
                                                                        className={globalStyles.rowTextfield}
                                                                        onChange={((e) => {
                                                                            setRowDataState((prevValue: DocumentListDataModel[]) => {
                                                                                const response: DocumentListDataModel[] = prevValue.map((value: DocumentListDataModel) => {
                                                                                    if (value.document_uuid === rowDetail.document_uuid) {
                                                                                        if (e.target.value.length != 0) {
                                                                                            value.document_name = e.target.value;
                                                                                        }
                                                                                        else {
                                                                                            value.document_name = rowDetail.document_name
                                                                                        }
                                                                                    }
                                                                                    return value;
                                                                                })
                                                                                return response;
                                                                            })
                                                                            setFlagDocumentNameChanged(true)
                                                                        })}
                                                                        onKeyDown={(e: any) => {
                                                                            if (e.key === 'Enter') {
                                                                                e.preventDefault();
                                                                                if (flagDocumentNameChanged && e.target.value.trim() !== '') {
                                                                                    renameDocumentName(e.target.value, rowDetail);
                                                                                    setRowAllowEdit(null);
                                                                                    setFlagDocumentNameChanged(false);
                                                                                    setRenameButtonClicked(false)
                                                                                } else {
                                                                                    setRowAllowEdit(null);
                                                                                    setRenameButtonClicked(false)
                                                                                }
                                                                            }
                                                                            else if (e.key === 'Escape') {
                                                                                // e.preventDefault();
                                                                                // e.target.value = rowDetail[headerObj.valueKey];
                                                                                renameDocumentName(e.target.value, rowDetail, "escape");
                                                                                setRowAllowEdit(null);
                                                                                setRenameButtonClicked(false);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {

                                                                            if (flagDocumentNameChanged && e.target.value) {
                                                                                renameDocumentName(e.target.value, rowDetail)
                                                                                setRowAllowEdit(null)
                                                                                setFlagDocumentNameChanged(false)
                                                                                setRenameButtonClicked(false)
                                                                            }
                                                                            else {
                                                                                setRowAllowEdit(null)
                                                                                setRenameButtonClicked(false)
                                                                            }
                                                                        }}
                                                                    />
                                                                    :
                                                                    formatValue(rowDetail, headerObj)
                                                                :
                                                                formatValue(rowDetail, headerObj)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                            {rowDetail.sub_chatbot_persistent_menu?.length > 0 && renderTableRows(rowDetail.sub_chatbot_persistent_menu, sequenceNumber)}
                        </>
                    )
                })
            );
        }
    }
    return (
        <>
            <TableContainer
                // className={`${globalStyles.tableContainer}`}
                sx={{ maxHeight: tableHeight, height: tableContainerHeight, minHeight: tableContainerMinHeight }}
            >
                <Table aria-label="simple table" stickyHeader className={globalStyles.WritewizGlobaltable} >
                    <TableHead>
                        <TableRow className={globalStyles.headerRow}>
                            {headerList?.map((headerCell: HeaderListObjectModel) => (
                                !headerCell.hidden &&
                                <TableCell
                                    align={headerCell.alignment}
                                    sx={headerCell.sx ? headerCell.sx : ""}
                                    className={headerCell.iconKey === 'documentIcon' ? globalStyles.iconCell : headerCell.name === 'Last Modified' ? globalStyles.iconCellLastModifield : ''}
                                    onMouseEnter={() => { headerCell.valueKey === 'formatted_timestamp' && setDateHover(true) }}
                                    onMouseLeave={() => { headerCell.valueKey === 'formatted_timestamp' && setDateHover(false) }}>
                                    {headerCell.valueKey === 'formatted_timestamp' && dateHover ?
                                        <>
                                            {headerCell?.name}
                                            <span onClick={handleSortByUpdatedAt} style={{ cursor: "pointer", paddingLeft: "10px" }} >
                                                {sortByUpdatedAt === 'asc' ? '▲' : '▼'}
                                            </span>
                                        </>
                                        :
                                        headerCell?.name
                                    }
                                    {headerCell?.subHeaderFlag == true &&
                                        <div className={globalStyles[headerCell.subHeaderClassName]}>
                                            {headerCell?.subHeaderText}
                                        </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowDataState.length ?
                                // rowDataState?.map((rowDetail: any, i: number) => {
                                //     return (
                                //         <>
                                //             <TableRow onClick={() => { onRowClick(rowDetail) }} key={i} className={selectedRow === i ? `${globalStyles.bodyRow} ${globalStyles.selectedBodyRow}` : `${globalStyles.bodyRow}`} onMouseEnter={() => { setHoveredRow(rowDetail) }} onMouseLeave={() => { setHoveredRow(null) }} >
                                //                 {headerList?.map((headerObj: HeaderListObjectModel) => {
                                //                     return (
                                //                         !headerObj.hidden &&
                                //                         <TableCell
                                //                             align={headerObj.alignment}
                                //                             sx={headerObj.sx ? headerObj.sx : ""}
                                //                             className={headerObj.iconKey == "documentIcon" ? globalStyles.documentClickCell : ""}
                                //                             onClick={() => props.onCellClick ? props.onCellClick(headerObj, rowDetail) : props.onRowClick(rowDetail)}>
                                //                             {headerObj.iconKey == "editIcon" ?
                                //                                 (
                                //                                     <>
                                //                                         <MoreVertIcon
                                //                                             sx={{ cursor: "pointer", color: "#7A7A7A" }}
                                //                                             onClick={(event: any) => handleMenuOpen(event, i)}
                                //                                         />
                                //                                         <Menu
                                //                                             anchorEl={anchorEl}
                                //                                             keepMounted
                                //                                             open={Boolean(anchorEl) && currentRowIndex === i}
                                //                                             onClose={handleMenuClose}
                                //                                         >
                                //                                             {
                                //                                                 menuItemsFromParent ?
                                //                                                     <>
                                //                                                         {menuItemsFromParent.map((item: any, index: any) => (
                                //                                                             <MenuItem
                                //                                                                 className={globalStyles.menuItemStyle}
                                //                                                                 key={index}
                                //                                                                 onClick={(e) => { handleMenuClose(); item.action(rowDetail); }}
                                //                                                                 disabled={item.disabled ? item.disabled(rowDetail) : false}
                                //                                                             >
                                //                                                                 <ListItemIcon>
                                //                                                                     {item.icon}
                                //                                                                 </ListItemIcon>
                                //                                                                 <span>
                                //                                                                     {item.label}
                                //                                                                 </span>
                                //                                                             </MenuItem>
                                //                                                         ))}
                                //                                                     </>
                                //                                                     :
                                //                                                     <>
                                //                                                         {menuItems.map((item, index) => (
                                //                                                             <MenuItem
                                //                                                                 className={globalStyles.menuItemStyle}
                                //                                                                 key={index}
                                //                                                                 onClick={(e) => { handleMenuClose(); item.action(rowDetail); }}
                                //                                                             >
                                //                                                                 <ListItemIcon>
                                //                                                                     {item.icon}
                                //                                                                 </ListItemIcon>
                                //                                                                 <span>
                                //                                                                     {item.label}
                                //                                                                 </span>
                                //                                                             </MenuItem>
                                //                                                         ))}
                                //                                                     </>

                                //                                             }
                                //                                         </Menu>
                                //                                     </>
                                //                                 )
                                //                                 :
                                //                                 <>
                                //                                     <Grid container
                                //                                         className={`${globalStyles.tableRowStyle} ${globalStyles[additionalRowClass]}`}
                                //                                         style={{
                                //                                             justifyContent: headerObj.iconKey == "deleteIcon" ? "end" : "start",
                                //                                             flexWrap: "nowrap"
                                //                                         }}
                                //                                     >
                                //                                         {headerObj.flagIcon ?
                                //                                             <Grid item style={{ marginRight: "10px", alignItems: "center", display: "flex" }}  >
                                //                                                 {headerObj.iconKey == "deleteIcon" ?
                                //                                                     <img src={deleteIcon} className={globalStyles.tableCategoryImage} style={{ cursor: "pointer" }}
                                //                                                         onClick={(e: any) => {
                                //                                                             deleteRowData(e, rowDetail)
                                //                                                         }}
                                //                                                     />
                                //                                                     :
                                //                                                     headerObj.iconKey == "documentIcon" ?
                                //                                                         <img src={docIcon} className={globalStyles.tableCategoryImage} />
                                //                                                         :
                                //                                                         <img src={rowDetail.iconSrc} className={globalStyles.tableCategoryImage} />
                                //                                                 }
                                //                                             </Grid>
                                //                                             : ""}
                                //                                         {headerObj.flagHover === true &&
                                //                                             <Grid item style={{ display: "flex", alignItems: "center", width: "1px" }}>
                                //                                                 {headerObj.iconKey === "addIcon" ?
                                //                                                     <>
                                //                                                         {(rowDetail.chatbot_persistent_menu_id === hoveredRow?.chatbot_persistent_menu_id) &&
                                //                                                             <img src={addSubMenuIcon} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props.onHoveredIconClick(headerObj, rowDetail) }} />
                                //                                                         }
                                //                                                     </>
                                //                                                     :
                                //                                                     <> </>
                                //                                                 }
                                //                                             </Grid>
                                //                                         }
                                //                                         {headerObj.iconKey === "sequenceNumber" &&
                                //                                             <Typography>{i + 1}</Typography>
                                //                                         }
                                //                                         <Grid item>
                                //                                             {(rowAllowEdit != null && rowAllowEdit.document_id) == rowDetail.document_id ?
                                //                                                 headerObj.iconKey == "documentIcon" ?
                                //                                                     <TextField
                                //                                                         key="1"
                                //                                                         defaultValue={rowDetail[headerObj.valueKey]}
                                //                                                         autoFocus
                                //                                                         className={globalStyles.rowTextfield}
                                //                                                         onChange={((e) => {
                                //                                                             setRowDataState((prevValue: DocumentListDataModel[]) => {
                                //                                                                 const response: DocumentListDataModel[] = prevValue.map((value: DocumentListDataModel) => {
                                //                                                                     if (value.document_uuid === rowDetail.document_uuid) {
                                //                                                                         if (e.target.value.length != 0) {
                                //                                                                             value.document_name = e.target.value;
                                //                                                                         }
                                //                                                                         else {
                                //                                                                             value.document_name = rowDetail.document_name
                                //                                                                         }
                                //                                                                     }
                                //                                                                     return value;
                                //                                                                 })
                                //                                                                 return response;
                                //                                                             })
                                //                                                             setFlagDocumentNameChanged(true)
                                //                                                         })}
                                //                                                         onKeyDown={(e: any) => {
                                //                                                             if (e.key === 'Enter') {
                                //                                                                 e.preventDefault();
                                //                                                                 if (flagDocumentNameChanged && e.target.value.trim() !== '') {
                                //                                                                     renameDocumentName(e.target.value, rowDetail);
                                //                                                                     setRowAllowEdit(null);
                                //                                                                     setFlagDocumentNameChanged(false);
                                //                                                                     setRenameButtonClicked(false)
                                //                                                                 } else {
                                //                                                                     setRowAllowEdit(null);
                                //                                                                     setRenameButtonClicked(false)
                                //                                                                 }
                                //                                                             }
                                //                                                             else if (e.key === 'Escape') {
                                //                                                                 // e.preventDefault();
                                //                                                                 // e.target.value = rowDetail[headerObj.valueKey];
                                //                                                                 renameDocumentName(e.target.value, rowDetail, "escape");
                                //                                                                 setRowAllowEdit(null);
                                //                                                                 setRenameButtonClicked(false);
                                //                                                             }
                                //                                                         }}
                                //                                                         onBlur={(e) => {

                                //                                                             if (flagDocumentNameChanged && e.target.value) {
                                //                                                                 renameDocumentName(e.target.value, rowDetail)
                                //                                                                 setRowAllowEdit(null)
                                //                                                                 setFlagDocumentNameChanged(false)
                                //                                                                 setRenameButtonClicked(false)
                                //                                                             }
                                //                                                             else {
                                //                                                                 setRowAllowEdit(null)
                                //                                                                 setRenameButtonClicked(false)
                                //                                                             }
                                //                                                         }}
                                //                                                     />
                                //                                                     :
                                //                                                     formatValue(rowDetail, headerObj)
                                //                                                 :
                                //                                                 formatValue(rowDetail, headerObj)
                                //                                             }
                                //                                         </Grid>
                                //                                     </Grid>
                                //                                 </>
                                //                             }
                                //                         </TableCell>
                                //                     )
                                //                 })}
                                //             </TableRow>

                                //             {rowDetail.sub_chatbot_persistent_menu?.length > 0 && rowDetail.sub_chatbot_persistent_menu?.map((subRowDetail: any, ind: number) => {
                                //                 return (
                                //                     <TableRow onClick={() => { onRowClick(subRowDetail) }} key={ind} className={selectedRow === ind ? `${globalStyles.bodyRow} ${globalStyles.selectedBodyRow}` : `${globalStyles.bodyRow}`} onMouseEnter={() => { setHoveredRow(subRowDetail) }} onMouseLeave={() => { setHoveredRow(null) }} >
                                //                         {headerList?.map((headerObj: HeaderListObjectModel) => {
                                //                             return (
                                //                                 !headerObj.hidden &&
                                //                                 <TableCell
                                //                                     align={headerObj.alignment}
                                //                                     sx={headerObj.sx ? headerObj.sx : ""}
                                //                                     className={headerObj.iconKey == "documentIcon" ? globalStyles.documentClickCell : ""}
                                //                                     onClick={() => props.onCellClick ? props.onCellClick(headerObj, subRowDetail) : props.onRowClick(subRowDetail)}>
                                //                                     {headerObj.iconKey == "editIcon" ?
                                //                                         (
                                //                                             <>
                                //                                                 <MoreVertIcon
                                //                                                     sx={{ cursor: "pointer", color: "#7A7A7A" }}
                                //                                                     onClick={(event: any) => handleMenuOpen(event, ind)}
                                //                                                 />
                                //                                                 <Menu
                                //                                                     anchorEl={anchorEl}
                                //                                                     keepMounted
                                //                                                     open={Boolean(anchorEl) && currentRowIndex === ind}
                                //                                                     onClose={handleMenuClose}
                                //                                                 >
                                //                                                     {
                                //                                                         menuItemsFromParent ?
                                //                                                             <>
                                //                                                                 {menuItemsFromParent.map((item: any, index: any) => (
                                //                                                                     <MenuItem
                                //                                                                         className={globalStyles.menuItemStyle}
                                //                                                                         key={index}
                                //                                                                         onClick={(e) => { handleMenuClose(); item.action(subRowDetail); }}
                                //                                                                         disabled={item.disabled ? item.disabled(subRowDetail) : false}
                                //                                                                     >
                                //                                                                         <ListItemIcon>
                                //                                                                             {item.icon}
                                //                                                                         </ListItemIcon>
                                //                                                                         <span>
                                //                                                                             {item.label}
                                //                                                                         </span>
                                //                                                                     </MenuItem>
                                //                                                                 ))}
                                //                                                             </>
                                //                                                             :
                                //                                                             <>
                                //                                                                 {menuItems.map((item, index) => (
                                //                                                                     <MenuItem
                                //                                                                         className={globalStyles.menuItemStyle}
                                //                                                                         key={index}
                                //                                                                         onClick={(e) => { handleMenuClose(); item.action(subRowDetail); }}
                                //                                                                     >
                                //                                                                         <ListItemIcon>
                                //                                                                             {item.icon}
                                //                                                                         </ListItemIcon>
                                //                                                                         <span>
                                //                                                                             {item.label}
                                //                                                                         </span>
                                //                                                                     </MenuItem>
                                //                                                                 ))}
                                //                                                             </>

                                //                                                     }
                                //                                                 </Menu>
                                //                                             </>
                                //                                         )
                                //                                         :
                                //                                         <>
                                //                                             <Grid container
                                //                                                 className={`${globalStyles.tableRowStyle} ${globalStyles[additionalRowClass]}`}
                                //                                                 style={{
                                //                                                     justifyContent: headerObj.iconKey == "deleteIcon" ? "end" : "start",
                                //                                                     flexWrap: "nowrap"
                                //                                                 }}
                                //                                             >
                                //                                                 {headerObj.flagIcon ?
                                //                                                     <Grid item style={{ marginRight: "10px", alignItems: "center", display: "flex" }}  >
                                //                                                         {headerObj.iconKey == "deleteIcon" ?
                                //                                                             <img src={deleteIcon} className={globalStyles.tableCategoryImage} style={{ cursor: "pointer" }}
                                //                                                                 onClick={(e: any) => {
                                //                                                                     deleteRowData(e, subRowDetail)
                                //                                                                 }}
                                //                                                             />
                                //                                                             :
                                //                                                             headerObj.iconKey == "documentIcon" ?
                                //                                                                 <img src={docIcon} className={globalStyles.tableCategoryImage} />
                                //                                                                 :
                                //                                                                 <img src={subRowDetail.iconSrc} className={globalStyles.tableCategoryImage} />
                                //                                                         }
                                //                                                     </Grid>
                                //                                                     : ""}
                                //                                                 {headerObj.flagHover === true &&
                                //                                                     <Grid item style={{ display: "flex", alignItems: "center", width: "1px" }}>
                                //                                                         {headerObj.iconKey === "addIcon" ?
                                //                                                             <>
                                //                                                                 {(subRowDetail.chatbot_persistent_menu_id === hoveredRow?.chatbot_persistent_menu_id) &&
                                //                                                                     <img src={addSubMenuIcon} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props.onHoveredIconClick(headerObj, subRowDetail) }} />
                                //                                                                 }
                                //                                                             </>
                                //                                                             :
                                //                                                             <>
                                //                                                             </>
                                //                                                         }
                                //                                                     </Grid>
                                //                                                 }
                                //                                                 {headerObj.iconKey === "sequenceNumber" &&
                                //                                                     <Typography>{(i + 1) + `.${ind + 1}`}</Typography>
                                //                                                 }
                                //                                                 <Grid item>
                                //                                                     {(rowAllowEdit != null && rowAllowEdit.document_id) == subRowDetail.document_id ?
                                //                                                         headerObj.iconKey == "documentIcon" ?
                                //                                                             <TextField
                                //                                                                 key="1"
                                //                                                                 defaultValue={subRowDetail[headerObj.valueKey]}
                                //                                                                 autoFocus
                                //                                                                 className={globalStyles.rowTextfield}
                                //                                                                 onChange={((e) => {
                                //                                                                     setRowDataState((prevValue: DocumentListDataModel[]) => {
                                //                                                                         const response: DocumentListDataModel[] = prevValue.map((value: DocumentListDataModel) => {
                                //                                                                             if (value.document_uuid === subRowDetail.document_uuid) {
                                //                                                                                 if (e.target.value.length != 0) {
                                //                                                                                     value.document_name = e.target.value;
                                //                                                                                 }
                                //                                                                                 else {
                                //                                                                                     value.document_name = subRowDetail.document_name
                                //                                                                                 }
                                //                                                                             }
                                //                                                                             return value;
                                //                                                                         })
                                //                                                                         return response;
                                //                                                                     })
                                //                                                                     setFlagDocumentNameChanged(true)
                                //                                                                 })}
                                //                                                                 onKeyDown={(e: any) => {
                                //                                                                     if (e.key === 'Enter') {
                                //                                                                         e.preventDefault();
                                //                                                                         if (flagDocumentNameChanged && e.target.value.trim() !== '') {
                                //                                                                             renameDocumentName(e.target.value, subRowDetail);
                                //                                                                             setRowAllowEdit(null);
                                //                                                                             setFlagDocumentNameChanged(false);
                                //                                                                             setRenameButtonClicked(false)
                                //                                                                         } else {
                                //                                                                             setRowAllowEdit(null);
                                //                                                                             setRenameButtonClicked(false)
                                //                                                                         }
                                //                                                                     }
                                //                                                                     else if (e.key === 'Escape') {
                                //                                                                         // e.preventDefault();
                                //                                                                         // e.target.value = rowDetail[headerObj.valueKey];
                                //                                                                         renameDocumentName(e.target.value, subRowDetail, "escape");
                                //                                                                         setRowAllowEdit(null);
                                //                                                                         setRenameButtonClicked(false);
                                //                                                                     }
                                //                                                                 }}
                                //                                                                 onBlur={(e) => {

                                //                                                                     if (flagDocumentNameChanged && e.target.value) {
                                //                                                                         renameDocumentName(e.target.value, subRowDetail)
                                //                                                                         setRowAllowEdit(null)
                                //                                                                         setFlagDocumentNameChanged(false)
                                //                                                                         setRenameButtonClicked(false)
                                //                                                                     }
                                //                                                                     else {
                                //                                                                         setRowAllowEdit(null)
                                //                                                                         setRenameButtonClicked(false)
                                //                                                                     }
                                //                                                                 }}
                                //                                                             />
                                //                                                             :
                                //                                                             formatValue(subRowDetail, headerObj)
                                //                                                         :
                                //                                                         formatValue(subRowDetail, headerObj)
                                //                                                     }
                                //                                                 </Grid>
                                //                                             </Grid>
                                //                                         </>
                                //                                     }
                                //                                 </TableCell>
                                //                             )
                                //                         })}
                                //                     </TableRow>
                                //                 )
                                //             })}
                                //         </>
                                //     )
                                // }) 
                                <>
                                    {renderTableRows(rowDataState)}
                                </>
                                :
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>

            </TableContainer>
            {flagPagination ? "" : <TablePagination
                component="div"
                count={rowData?.length || 0}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                }
                labelRowsPerPage="Rows per page:"
                rowsPerPageOptions={[5, 10, 25, 50]}
            />}
        </>
    )
}
