import { Avatar, Grid, Tooltip, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisCard.module.scss';
import MicIcon from '@mui/icons-material/Mic';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { getRatingColor } from './callAnalysisConstants';
import { formatCallAnalysisTimeAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import agent from '../../../services/api';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { callRecordsInterface } from '../../../models/axiosModel';

interface callAnalysisCardInterface {
    callAnalysisInfo: callRecordsInterface,
    setCurrentAudioAnalysis: any,
    currentAudioAnalysis: any,
    waveLoading: boolean,
    handleRegenerateCall: (e: any, record_id: number) => Promise<void>,
    setWaveLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const CallAnalysisCard = (props: callAnalysisCardInterface) => {
    const { callAnalysisInfo, setCurrentAudioAnalysis, currentAudioAnalysis, waveLoading, setWaveLoading, handleRegenerateCall } = props;
    const {userDetail} = useContext(UserContext) as UserContextPayload;
    const {GetCallRecordDetailApi, setFlagAnalysisData, setAdditionalFieldValue, setParentCategoryId, GetCallRecordsApi, setTimeToScroll} = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const smallScreen = useMediaQuery('(max-width : 1000px) and (min-width : 0px)');
    const {setFlagLoading, flagLoading} = useContext(UserContext) as UserContextPayload

    const handleCardClick = async (recordId: number) => {
        setFlagLoading({...flagLoading, flagRoundLoader: true})
        setFlagAnalysisData(true);
        setTimeToScroll(null)
        if(!waveLoading) {
            if(smallScreen) {
                setCurrentAudioAnalysis(null)
            }
            setAdditionalFieldValue([]);
            setParentCategoryId(null);
            await GetCallRecordDetailApi(recordId);
        }
        setFlagLoading({...flagLoading, flagRoundLoader: false})
    }

    return (
        callAnalysisInfo &&
        <Grid item xs={5.8} className={styles.callAnalysisCardStyle} sx={{position: "relative", backgroundColor: (currentAudioAnalysis && currentAudioAnalysis.call_record_id === callAnalysisInfo.call_record_id) && !smallScreen ? "rgba(247, 233, 255, 0.4)" : "#FFFFFF"}} onClick={() => {handleCardClick(callAnalysisInfo.call_record_id)}}>
            <Grid container direction={"column"} gap={"12px"}>
                <Grid item className={styles.fullWidth}>
                    <Grid container direction={"row"} flexWrap={"nowrap"} gap={"7px"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={9}>
                            <Grid container direction={"row"} flexWrap={"nowrap"} alignItems={"center"} gap={"10px"} sx={{wordBreak: "break-all"}}>
                                <Grid item className={styles.evaluationRatingGrid}>
                                    <Avatar sx={{ bgcolor: getRatingColor(callAnalysisInfo.call_record_eval_score) }}>{Math.round(Number(callAnalysisInfo.call_record_eval_score))}</Avatar>
                                </Grid>
                                <Grid item>
                                    <span className={styles.audioFileNameStyle}>{callAnalysisInfo.call_record_google_storage_file_name}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} textAlign={"end"}>
                            <AvTimerIcon sx={{fontSize: "14px", verticalAlign: "middle"}}/><span className={styles.durationFieldValue}>{callAnalysisInfo.call_record_duration}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={styles.fullWidth}>
                    <Grid container direction={"column"} gap={"6px"}>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Phone Number</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{callAnalysisInfo.phone_number}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Category</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{callAnalysisInfo.call_parent_category_name ? callAnalysisInfo.call_parent_category_name : "-"}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Sub Category</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{callAnalysisInfo.call_category_name}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Complexity</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{callAnalysisInfo.call_complexity_level}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Agent</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{callAnalysisInfo.agent_name === null ? "-" : callAnalysisInfo.agent_name}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={styles.fullWidth}>
                            <Grid container direction={"row"} gap={"12px"} flexWrap={"nowrap"}>
                                <Grid item xs={5}>
                                    <span className={styles.cardFieldLabel}>Date Received</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span className={styles.cardFieldValue}>{formatCallAnalysisTimeAccordingToTimezoneUsingMoment(callAnalysisInfo.created_at_timestamp,userDetail.timezone_hour)}</span>
                                </Grid>
                            </Grid>
                        </Grid>

                        {callAnalysisInfo?.total_alert > 0 &&
                            <Grid item className={styles.audioIconPosition}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item>
                                        <Tooltip title={`Alerts (${callAnalysisInfo.total_alert})`} placement='top'><ReportProblemIcon style={{fontSize: "18px", color: "red"}}/></Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CallAnalysisCard
