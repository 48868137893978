import { Button, Divider, Grid, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import RoundLoader from '../../utility/globalLoader'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import globalStyles from './../../../assets/styles/global.module.scss'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import headerStyles from '../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import GlobalPopUp from '../../utility/globalPopUp';
import { CallAnalysisFilter } from './callAnalysisFilter';
import moment, { Moment } from 'moment';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext';
import { Outlet, useNavigate } from 'react-router-dom';
import CallAnalysisAudio from './callAnalysisAudio';
import CreateCallAgent from './createCallAgent';
import CloseIcon from '@mui/icons-material/Close';
import { initialExtension, initialFilterData, initialPhoneNumber } from './callAnalysisConstants';
import { additionalFieldFilters, agentsNames, categoryNames, complexityLevels, parentCategoryNames } from '../../../models/axiosModel';
import EmptyState from '../../global/emptyState';
import GlobalButton from '../../utility/globalButton/button';
import ChatbotEmptyStateIcon from "../../../assets/images/chatbot/chatbotEmptyState.png";
import emptyStateWhatsappTemplate from "../../../assets/images/whatsapp/emptyWhatsappTemplateState.png";
import IconsColor from '../../utility/iconsColor';
import emptyPageImage from './../../../assets/errorPageIllustration.png'
import agent from '../../../services/api';
import AddIcon from '@mui/icons-material/Add';
import iconStyles from "../../../assets/styles/componentStyles/template.module.scss";
import CallAnalysisReportProblem from './callAnalysisReportProblem';

const CallAnalysisGlobal = () => {
    const navigate = useNavigate();
    const { GetCallRecordsApi, GetCallCategoryApi, GetCallComplexityApi, GetCallAgentsApi, GetPhoneNumbersApi, callRecordsData, callCategories, callComplexity,
        callAgentsData, phoneNumbersData, dashboardAnalysisData, setCurrentAudioAnalysis, setCallRecordsDetails, setCallCenterDetails, setFilterData, filterDate, setStartDate, setEndDate,
        setPhoneNumberFilter, phoneNumberFilter, setExtensionFilter, setSelectedComplexityLevels, setSelectedPurposeCategories, setAgentsListFilter, initialLoad, setInitialLoad,
        applyFilters, formatDate, setFlagAddFilter, viewRecordDetail, setViewRecordDetail, waveLoading, currentAudioAnalysis, flagAddFilter, addNewAgentFlag,
        setAddNewAgentFlag, appliedFilter, handleFilterData, callCenterDetails, handleStartEndDate, extensionFilter, agentListsFilter, selectedComplexityLevels,
        selectedPurposeCategories, handleCancel, callRecordsDetails, updateAgentFlag, setUpdateAgentFlag, GetCallRecordDetailApi, GetExtensionListApi, extensionListData,
        GetCallCategoryParentApi, callParentCategories, setParentCategoriesFilter, parentCategoriesFilter, GetCallAnalysisAdditionalFieldApi, setSelectedAdditionalFieldsFilter,
        callAnalysisAdditionalFields, selectedAdditionalFieldsFilter, setAlertsOnlyFilter, flagReportProblem, setFlagReportProblem } = useContext(CallAnalysisContext) as CallAnalysisContextPayload;

    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    // const [callCenterDetails, setCallCenterDetails] = useState(callRecords.call_center[0])
    const flagTabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)');
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error, setFlagShowScheduleCall } = useContext(UserContext) as UserContextPayload;

    useEffect(() => {
        // GetCallRecordsApi()
        // GetCallEvaluationParamerApi()
        GetCallCategoryParentApi()
        GetCallAnalysisAdditionalFieldApi()
        GetCallCategoryApi()
        GetCallComplexityApi()
        GetCallAgentsApi()
        GetPhoneNumbersApi()
        GetExtensionListApi()
    }, [])


    const handleRegenerateCall = async (e: any, record_id: number) => {
        e.preventDefault();
        e.stopPropagation();
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const payload = {
            call_record_id: record_id
        }
        const response = await agent.regenerateCallRecord.put(payload);
        if (response.status) {
            await GetCallRecordDetailApi(record_id);
            applyFilters(filterDate)
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    useEffect(() => {
        if (callRecordsData && callRecordsData.call_center && callRecordsData.call_center.length > 0) {
            // if(callRecordsData?.call_center[0]?.call_records.length > 0 && window.location.pathname !== "/callanalyis") {
            //     // GetCallRecordDetailApi(currentAudioAnalysis ? currentAudioAnalysis.call_record_id : callRecordsData?.call_center[0]?.call_records[0].call_record_id)
            //     GetCallRecordDetailApi(callRecordsData?.call_center[0]?.call_records[0].call_record_id)
            // }
            if (!currentAudioAnalysis) {
                setCurrentAudioAnalysis(null)
            }
            setCallRecordsDetails(callRecordsData?.call_center[0]?.call_records)
            setCallCenterDetails(callRecordsData?.call_center[0])
        }
        else {
            setCurrentAudioAnalysis(null)
            setCallRecordsDetails([])
        }
    }, [callRecordsData])

    // useEffect(() => {
    //     if (window.location.pathname === "/callanalysis") {
    //         setCurrentAudioAnalysis(null)
    //     }
    // }, [window.location.pathname])

    useEffect(() => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        setCurrentAudioAnalysis(null)
        const searchParams = new URLSearchParams(window.location.search);
        handleCancel()

        if (window.location.pathname === "/callanalysis") {
            setStartDate(moment().subtract(6, "month"));
            setEndDate(moment());
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }

        if (searchParams) {
            const rawFlagAlert = searchParams.get('flag_alert');
            const isValidFlagAlert = rawFlagAlert === "true";

            const filters = {
                start_date: searchParams.get('start_date') || (window.location.pathname === "/callanalysis" ? formatDate(moment().subtract(6, "month")) : null),
                end_date: searchParams.get('end_date') || (window.location.pathname === "/callanalysis" ? formatDate(moment()) : null),
                phoneNumber: searchParams.get('phone_number') || "",
                extension: searchParams.get('extension') || "",
                category: searchParams.get('call_parent_category_id')
                    ? searchParams.get('call_parent_category_id')!.split(',').map(id => ({
                        call_parent_category_id: Number(id),
                        call_parent_category_name: ""
                    }))
                    : [],
                purpose: searchParams.get('call_category_id')
                    ? searchParams.get('call_category_id')!.split(',').map(id => ({
                        call_category_id: Number(id),
                        call_category_name: ""
                    }))
                    : [],
                complexity: searchParams.get('call_complexity_id')
                    ? searchParams.get('call_complexity_id')!.split(',').map(id => ({
                        call_complexity_id: Number(id),
                        call_complexity_level: ""
                    }))
                    : [],
                agents: searchParams.get('agent_id')
                    ? searchParams.get('agent_id')!.split(',').map(id => ({
                        agent_id: Number(id),
                        agent_name: ""
                    }))
                    : [],
                call_record_additional_field: searchParams.get('call_record_additional_field')
                    ? JSON.parse(searchParams.get('call_record_additional_field')!)
                    : [],
                flag_alert: isValidFlagAlert ? rawFlagAlert : null
            };

            setFilterData(filters);

            if (filters.start_date) {
                setStartDate(moment(filters.start_date));
            }
            if (filters.end_date) {
                setEndDate(moment(filters.end_date));
            }
            if (filters.phoneNumber) {
                setPhoneNumberFilter({ ...phoneNumberFilter, phone_number: filters.phoneNumber });
            }
            if (filters.extension) {
                setExtensionFilter({ ...extensionFilter, call_record_extension: filters.extension });
            }
            if (filters.category.length > 0) {
                setParentCategoriesFilter(filters.category);
            }
            if (filters.purpose.length > 0) {
                setSelectedPurposeCategories(filters.purpose);
            }
            if (filters.complexity.length > 0) {
                setSelectedComplexityLevels(filters.complexity);
            }
            if (filters.agents.length > 0) {
                setAgentsListFilter(filters.agents);
            }
            if (filters.call_record_additional_field.length > 0) {
                setSelectedAdditionalFieldsFilter(filters.call_record_additional_field);
            }
            if (filters.flag_alert) {
                setAlertsOnlyFilter(filters.flag_alert === "true");
            }
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }, [window.location.pathname]);

    useEffect(() => {
        if (window.location.pathname !== "/callanalysis/callrecords/newcallrecords") {
            if (initialLoad) {
                setInitialLoad(false);
            } else {
                applyFilters(filterDate);
            }
        }
    }, [filterDate])

    return (
        callRecordsData && callRecordsData.call_center && callRecordsData.call_center.length > 0 ?
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper}`}>
                <Grid container sx={{ padding: { xs: "15px 0px", sm: "18px 0px" } }} direction={"column"} flexWrap={"nowrap"}>
                    <Grid item xs={12} sm={12}>
                        <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                            <Grid item className={globalStyles.fullwidth} sx={{ marginBottom: !smallScreen ? "10px" : "", padding: { xs: "0px 20px", lg: "0px 25px 0px 27px" } }}>
                                <GlobalPageHeaderText
                                    mainHeadText={window.location.pathname === "/callanalysis" ? "Dashboard" : window.location.pathname === "/callanalysis/callrecords/newcallrecords" ? "New Call Records" : "Call Records"}
                                    flagMainTextOnly
                                    actionBlock={
                                        !smallScreen &&
                                        <Grid container gap={"12px"} justifyContent={flagTabletScreen ? "start" : "end"} flexWrap={"nowrap"}>
                                            {window.location.pathname == "/callanalysis/callrecords" && !flagTabletScreen &&
                                                <Grid item>
                                                    <GlobalButton
                                                        className='borderedButtonStyle'
                                                        buttonText={"New Records"}
                                                        onClick={() => { navigate('/callanalysis/callrecords/newcallrecords') }}
                                                        startIcon={<IconsColor iconName="plus" fillColor="#6A097D" width="15px" height="15px" />}
                                                        sx={{ width: "160px" }}
                                                    />
                                                </Grid>
                                            }
                                            {window.location.pathname == "/callanalysis/callrecords" && flagTabletScreen &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={() => { navigate('/callanalysis/callrecords/newcallrecords') }}
                                                    sx={{ padding: "3.5px 12px !important" }}
                                                >
                                                    <IconsColor iconName="plus" fillColor="#6A097D" width="15px" height="15px" />
                                                </IconButton>
                                            }
                                            <Grid item>
                                                <GlobalButton
                                                    className='primaryButtonStyle'
                                                    buttonText={window.location.pathname === "/callanalysis" || window.location.pathname === "/callanalysis/callrecords/newcallrecords" ? "View Call Records" : "View Dashboard"}
                                                    onClick={() => { window.location.pathname === "/callanalysis" || window.location.pathname === "/callanalysis/callrecords/newcallrecords" ? navigate("/callanalysis/callrecords") : navigate("/callanalysis") }}
                                                    // startIcon={<IconsColor iconName="callanalysisdashboard" height="16" width="16" fillColor="#FFF" />}
                                                    sx={{ width: "170px" }}
                                                />
                                            </Grid>
                                            {window.location.pathname !== "/callanalysis/callrecords/newcallrecords" &&
                                                <Grid item>
                                                    <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                                                        <Grid item>
                                                            <IconButton
                                                                className={`${globalStyles.cmpGlobalMobileAddIcon} ${styles.addFilterIcon}`}
                                                                onClick={() => { setFlagAddFilter(true) }}
                                                            >
                                                                <FilterAltOutlinedIcon style={{ height: "28px", width: 'auto' }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>

                                    }
                                />
                                {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${headerStyles.dividerStyle}`} sx={{ paddingTop: "10px !important" }} />}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ padding: flagSmallScreen ? "5px 0px 5px 20px" : "5px 22px" }}>
                        <Grid container direction={"column"}>
                            {appliedFilter &&
                                <Grid item sx={{ width: "100%" }} marginBottom={"15px"}>
                                    <Grid container direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
                                        <Grid item marginLeft={"5px"}>
                                            <Grid container direction={"row"} alignItems={"center"} gap={1}>
                                                {filterDate.start_date && !filterDate.end_date &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        handleFilterData("start_date");
                                                                        setStartDate(null)
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {filterDate.start_date}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {!filterDate.start_date && filterDate.end_date &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        handleFilterData("end_date");
                                                                        setEndDate(null)
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {filterDate.end_date}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {filterDate.start_date && filterDate.end_date &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => { handleStartEndDate() }}
                                                                />
                                                            }
                                                        >
                                                            {`${filterDate.start_date} to ${filterDate.end_date}`}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {filterDate.phoneNumber && phoneNumbersData?.some((phones) => phones.phone_number === filterDate.phoneNumber) &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        handleFilterData("phoneNumber");
                                                                        setPhoneNumberFilter(initialPhoneNumber)
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {filterDate.phoneNumber}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {filterDate.flag_alert &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        setAlertsOnlyFilter(false)
                                                                        handleFilterData("flag_alert", null)
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {`Alerts Only`}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {filterDate.extension && extensionListData?.some((extensions) => extensions.call_record_extension === filterDate.extension) &&
                                                    <Grid item>
                                                        <Button
                                                            className={styles.filterButtons}
                                                            endIcon={
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        handleFilterData("extension");
                                                                        setExtensionFilter(initialExtension)
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {filterDate.extension}
                                                        </Button>
                                                    </Grid>
                                                }
                                                {filterDate.category && filterDate.category.length > 0 && callParentCategories && callParentCategories.length > 0 &&
                                                    filterDate.category.map((categories: parentCategoryNames) => (
                                                        <Grid item key={categories.call_parent_category_id}>
                                                            <Button
                                                                className={styles.filterButtons}
                                                                endIcon={
                                                                    <CloseIcon
                                                                        onClick={() => {
                                                                            handleFilterData("category", categories);
                                                                            setParentCategoriesFilter(parentCategoriesFilter.filter((item: parentCategoryNames) => item.call_parent_category_id !== categories.call_parent_category_id));
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {callParentCategories.find((pcategories: any) => categories.call_parent_category_id === pcategories.call_parent_category_id)?.call_parent_category_name || 'Unknown'}
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }
                                                {filterDate.purpose && filterDate.purpose.length > 0 && callCategories && callCategories.length > 0 &&
                                                    filterDate.purpose.map((purposes: categoryNames) => (
                                                        <Grid item key={purposes.call_category_id}>
                                                            <Button
                                                                className={styles.filterButtons}
                                                                endIcon={
                                                                    <CloseIcon
                                                                        onClick={() => {
                                                                            handleFilterData("purpose", purposes);
                                                                            setSelectedPurposeCategories(selectedPurposeCategories.filter((item: categoryNames) => item.call_category_id !== purposes.call_category_id));
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {callCategories.find((categories: any) => purposes.call_category_id === categories.call_category_id)?.call_category_name || 'Unknown'}
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }
                                                {filterDate.complexity && filterDate.complexity.length > 0 && callComplexity && callComplexity.length > 0 &&
                                                    filterDate.complexity.map((complexity: complexityLevels) => (
                                                        <Grid item key={complexity.call_complexity_id}>
                                                            <Button
                                                                className={styles.filterButtons}
                                                                endIcon={
                                                                    <CloseIcon
                                                                        onClick={() => {
                                                                            handleFilterData("complexity", complexity);
                                                                            setSelectedComplexityLevels(selectedComplexityLevels.filter((item: complexityLevels) => item.call_complexity_id !== complexity.call_complexity_id));
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {callComplexity.find((complexities: any) => complexities.call_complexity_id === complexity.call_complexity_id)?.call_complexity_level || 'Unknown'}
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }
                                                {filterDate.agents && filterDate.agents.length > 0 && callComplexity && callAgentsData?.find((agents) => agents.call_center_id === callCenterDetails?.call_center_id)?.call_agents &&
                                                    filterDate.agents.map((agents: agentsNames) => (
                                                        <Grid item key={agents.agent_id}>
                                                            <Button
                                                                className={styles.filterButtons}
                                                                endIcon={
                                                                    <CloseIcon
                                                                        onClick={() => {
                                                                            handleFilterData("agents", agents);
                                                                            setAgentsListFilter(agentListsFilter.filter((item: agentsNames) => item.agent_id !== agents.agent_id));
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {callAgentsData?.find((agents) => agents.call_center_id === callCenterDetails?.call_center_id)?.call_agents.find((agent: any) => agent.agent_id === agents.agent_id)?.agent_name || 'Unknown'}
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }
                                                {filterDate.call_record_additional_field && filterDate.call_record_additional_field.length > 0 && callAnalysisAdditionalFields &&
                                                    filterDate.call_record_additional_field.map((fields: additionalFieldFilters) => (
                                                        fields.values.map((value) => (
                                                            <Grid item key={fields.additional_field_id}>
                                                                <Button
                                                                    className={styles.filterButtons}
                                                                    endIcon={
                                                                        <CloseIcon
                                                                            onClick={() => {
                                                                                handleFilterData("call_record_additional_field", { additional_field_id: fields.additional_field_id, values: [value] });
                                                                                setSelectedAdditionalFieldsFilter((prevFilters) => {
                                                                                    return prevFilters.map((filter) => {
                                                                                        if (filter.additional_field_id === fields.additional_field_id) {
                                                                                            return {
                                                                                                ...filter,
                                                                                                values: filter.values.filter(
                                                                                                    (v: any) => v.additional_field_value !== value.additional_field_value
                                                                                                ),
                                                                                            };
                                                                                        }
                                                                                        return filter;
                                                                                    }).filter((filter) => filter.values.length > 0);
                                                                                });
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    {callAnalysisAdditionalFields?.find((field) => field.call_analysis_additional_field_id === fields.additional_field_id)?.organization_additional_field_option.find((option: any) => option.organization_additional_field_option_id === value.additional_field_value)?.organization_additional_field_option_value || 'Unknown'}
                                                                </Button>
                                                            </Grid>
                                                        ))
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ padding: flagSmallScreen ? "0px 22px 0px 0px" : "" }}>
                                            <span onClick={() => handleCancel(true)} className={styles.removeAllFilterFontStyle}>Remove All Filters</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            <Outlet />

                            {callRecordsData && callRecordsDetails && callRecordsDetails.length == 0 && !dashboardAnalysisData && window.location.pathname === "/callanalysis" &&
                                <Grid item sx={{ width: "100%" }}>
                                    <EmptyState
                                        height={'calc(100vh - 200px)'}
                                        pageIcon={<img alt="chatbot" src={ChatbotEmptyStateIcon} style={{ width: "165px", height: "auto" }} />}
                                        pageSubText={"No Such Data Found"}
                                        primaryActionBtn={<GlobalButton
                                            buttonText={"Remove All Filters"}
                                            className='primaryButtonStyle'
                                            onClick={() => handleCancel(true)}
                                        />}
                                    />
                                </Grid>
                            }

                            {callRecordsData && callRecordsDetails && callRecordsDetails.length == 0 && window.location.pathname !== "/callanalysis" && (JSON.stringify(initialFilterData) !== JSON.stringify(filterDate)) &&
                                <Grid item sx={{ width: "100%" }}>
                                    <EmptyState
                                        height={'calc(100vh - 200px)'}
                                        pageIcon={<img alt="chatbot" src={ChatbotEmptyStateIcon} style={{ width: "165px", height: "auto" }} />}
                                        pageSubText={"No Such Data Found"}
                                        primaryActionBtn={<GlobalButton
                                            buttonText={"Remove All Filters"}
                                            className='primaryButtonStyle'
                                            onClick={() => handleCancel(true)}
                                        />}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                {viewRecordDetail &&
                    <GlobalPopUp
                        open={viewRecordDetail}
                        onClose={() => { !waveLoading && setViewRecordDetail(false); !waveLoading && setCurrentAudioAnalysis(null) }}
                        // title={}
                        flagBackdrop
                        paperMinWidth={flagSmallScreen ? "310px" : "700px"}
                        paperMaxwidth={flagSmallScreen ? "610px" : "750px"}
                        flagShowCloseIcon={true}
                        height={"100%"}
                        handleClose={() => { !waveLoading && setViewRecordDetail(false); !waveLoading && setCurrentAudioAnalysis(null) }}
                    >
                        {currentAudioAnalysis &&
                            <CallAnalysisAudio handleRegenerateCall={handleRegenerateCall} />
                        }
                    </GlobalPopUp>
                }

                {addNewAgentFlag &&
                    <GlobalPopUp
                        open={addNewAgentFlag}
                        onClose={() => { setAddNewAgentFlag(false) }}
                        // title={}
                        flagBackdrop
                        paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                        paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setAddNewAgentFlag(false) }}
                    >
                        <CreateCallAgent flagUpdate={false} />
                    </GlobalPopUp>
                }

                {/* {updateAgentFlag &&
                <GlobalPopUp
                    open={updateAgentFlag}
                    onClose={() => { setUpdateAgentFlag(false) }}
                    // title={}
                    flagBackdrop
                    paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                    paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                    flagShowCloseIcon={true}
                    handleClose={() => { setUpdateAgentFlag(false) }}
                >
                    <CreateCallAgent flagUpdate={true}/>
                </GlobalPopUp>
            } */}

                {flagAddFilter &&
                    <GlobalPopUp
                        open={flagAddFilter}
                        onClose={() => { setFlagAddFilter(false) }}
                        // title={}
                        flagBackdrop
                        paperMinWidth={smallScreen || flagTabletScreen ? "90%" : "700px"}
                        paperMaxwidth={smallScreen || flagTabletScreen ? "750px" : "750px"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setFlagAddFilter(false) }}
                    >
                        <CallAnalysisFilter />
                    </GlobalPopUp>
                }

                {flagReportProblem &&
                    <GlobalPopUp
                        open={flagReportProblem}
                        onClose={() => { setFlagReportProblem(false) }}
                        // title={}
                        flagBackdrop
                        paperMinWidth={smallScreen || flagTabletScreen ? "90%" : "700px"}
                        paperMaxwidth={smallScreen || flagTabletScreen ? "750px" : "750px"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setFlagReportProblem(false) }}
                    >
                        <CallAnalysisReportProblem />
                    </GlobalPopUp>
                }
            </Grid>
            :
            callRecordsData &&
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper}`}>
                <Grid container sx={{ padding: { xs: "15px 25px", sm: "18px 25px", height: "calc(100vh - 70px)" } }} flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12} md={11} lg={8} xl={6}>
                        <Grid container direction={"column"} alignItems={"center"} sx={{ boxShadow: "0px 0px 6px rgba(0,0,0,0.15)", height: "100%", padding: "40px", borderRadius: "15px" }} justifyContent={"center"}>
                            {/* <Grid item>
                                <img src={emptyPageImage} alt="" className={styles.errorImage}/>
                            </Grid> */}
                            <Grid item textAlign={"center"}>
                                <span className={styles.errorTextStyle}>Contact our support team to enable this feature.</span>
                            </Grid>
                            <Grid item mt={4}>
                                <GlobalButton
                                    buttonText={"Schedule Meeting"}
                                    sx={{ width: "190px" }}
                                    className='primaryButtonStyle'
                                    onClick={() => setFlagShowScheduleCall(true)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default CallAnalysisGlobal
