import { Box, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import IconsColor from '../../utility/iconsColor';
import { checkForErrorCode, formatDateAccordingToTimezoneUsingMoment, getErrorMessage, getMessage, getParseData, getTag, hexToRgba, isAdmin, isDev, isJsonString, patternToRemoveSource, primaryColor } from '../../../utils/constants';
import globalStyles from "../../../assets/styles/global.module.scss";
import { UserContext, UserContextPayload } from '../../../context/userContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CopyToClipboard from 'react-copy-to-clipboard';
import InfoIcon from '@mui/icons-material/Info';
import { stripMarkdown } from './constants';
import { reactionData } from '../../../models/axiosModel';
import botIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png";
import MessageFormatComponents from './messageFormatComponents';
import agent from '../../../services/api';
import { useSearchParams } from 'react-router-dom';
import GlobalButton from '../../utility/globalButton/button';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
interface ChatbotThread {
    flag_loader: boolean;
    flag_user: boolean;
    message: string;
    flagOpenMenu: boolean;
    flagOpenEmojiList: boolean;
    anchorEl: null | HTMLElement;
    chatbot_thread_id: number;
}

interface WebSocketResponse {
    response: {
        status: boolean;
        data: {
            chatbot_thread: ChatbotThread[];
        };
    };
    payload?: {
        url: string;
    };
}
interface chatUserThreadLogViewInterface {
    currentActiveChatbotThread: any,
    open: boolean,
    setChatlevelMenu: any,
    setChatbotMessageCopyHover: any,
    handleOpenMenu: any,
    getMessageType: any,
    copyText: any,
    setCopyText: any,
    chatbotMessageCopyHover: any,
    setFeedbackDetails: any,
    drawerProps: any,
    setDrawerProps: any,
    subType: any,
    handleCloseMenu: any,
    filteredData: any,
    setFeedbackData: any,
    setValue: any,
    getThread: any,
    setCurrentActiveChatbotThread: any,
    setFlagFeedbackDialog: any,
    setFlagTellusMoreOptionBtn: any,
    setThreadId: any,
    getSourceDetails: any,
    setIsFeedbackFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
}
function ChatUserThreadLogView(props: chatUserThreadLogViewInterface) {
    const { currentActiveChatbotThread, open, setChatlevelMenu, setChatbotMessageCopyHover, handleOpenMenu, getMessageType, copyText, setCopyText, chatbotMessageCopyHover, setFeedbackDetails, drawerProps, setDrawerProps, subType, handleCloseMenu, filteredData, setFeedbackData, setValue, getThread, setCurrentActiveChatbotThread, setFlagTellusMoreOptionBtn, setFlagFeedbackDialog, setThreadId, getSourceDetails, setIsFeedbackFormOpen } = props;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { userDetail, webSocketObj, setWebSocketObj, setFlagLoading, setError } = useContext(UserContext) as UserContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    const feedback_id = searchParams.get('feedback_id')
    const [inputValue, setinputValue] = useState("")
    const innerHeight = (window.innerHeight - 110);
    const innerHeightCenter = (window.innerHeight - 225);
    // console.log("currentActiveChatbotThread", currentActiveChatbotThread)
    const handleInitializeSocket = async () => {
        try {
            const socketUrl = process.env.REACT_APP_SOCKET_URL;
            let websocket: WebSocket | null = null;
            let pingInterval: NodeJS.Timeout | null = null;
            let intervalId: NodeJS.Timeout | null = null;
            let timeoutId: NodeJS.Timeout | null = null;
            const isMuted = false;

            const setChatBotDetail = (data: any) => {
                // Placeholder function for updating chatbot details
            };
            const setFlagInputDisable = (flag: boolean) => {
                // Placeholder function for input disable flag
            };

            const setOpenSnackbar = (data: any) => {
                // Placeholder function for showing snackbar
            };

            const playMessageReceived = () => {
                // Placeholder function for playing sound
            };

            const createWebSocket = () => {
                websocket = new WebSocket(socketUrl!);
                // @ts-ignore
                websocket['chatbot_user_id'] = currentActiveChatbotThread.chatbot_user_id
                setWebSocketObj(websocket)
                websocket.onopen = () => {

                    pingInterval = setInterval(() => {
                        if (websocket && websocket.readyState === WebSocket.OPEN) {
                            websocket.send("Keep Alive...");
                        }
                    }, 10000);
                };

                websocket.onmessage = async (event: MessageEvent) => {
                    const container = document.getElementById('chatUI');
                    const wsResponse: WebSocketResponse = JSON.parse(event.data);
                    const res: any = wsResponse.response;

                    if (wsResponse?.payload?.url === "chatbot/get-user-chats") {
                        if (res.status) {
                            if (res.status && res.data && res.data.length > 0) {
                                let chatbot_id = searchParams.get('chatbot_id')
                                let activeChats: any
                                let closedChats: any
                                let pagination: any
                                if (window.location.pathname.toLocaleLowerCase().includes('viewchatbot')) {
                                    activeChats = res.data[0].active_chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                                        const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                                        return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                                    })
                                    closedChats = res.data[0].closed_chats.chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                                        const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                                        return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                                    })
                                    pagination = res.data[0].closed_chats.pagination
                                } else {
                                    activeChats = res.data[0].active_chats.flatMap((chatbot: any) => {
                                        const { chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id, chatbot_id } = chatbot
                                        return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                                    })
                                    closedChats = res.data[0].closed_chats.chats.flatMap((chatbot: any) => {
                                        const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                                        return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                                    })
                                    pagination = res.data[0].closed_chats.pagination
                                }
                                if (activeChats.length > 0) {
                                    setSearchParams((prevParams: any) => {
                                        const newParams = new URLSearchParams(prevParams);
                                        newParams.set('chatbot_user_id', activeChats[0].chatbot_user_id);
                                        return newParams;
                                    });
                                    let activeChat = activeChats[0]
                                    let started_date = activeChat.chatbot_thread_data[0].timestamp ?? null
                                    let end_date = activeChat.chatbot_thread_data[activeChat.chatbot_thread_data.length - 1].timestamp ?? null
                                    getSourceDetails(
                                        activeChat.chatbot_user_ip_address,
                                        activeChat.chatbot_user_route_source,
                                        activeChat.chatbot_phone_number,
                                        activeChat.chatbot_user_name,
                                        started_date,
                                        end_date,
                                        activeChat.chatbot_user_city,
                                        activeChat.chatbot_user_country,
                                        activeChat.chatbot_user_input_token,
                                        activeChat.chatbot_user_output_token,
                                        activeChat.openai_assistant_id,
                                        activeChat.openai_thread_id
                                    )
                                    setCurrentActiveChatbotThread(activeChats[0])
                                } else {
                                    if (closedChats.length > 0) {
                                        setSearchParams(prevParams => {
                                            const newParams = new URLSearchParams(prevParams);
                                            newParams.set('chatbot_user_id', closedChats[0].chatbot_user_id);
                                            return newParams;
                                        });
                                        setCurrentActiveChatbotThread(closedChats[0])
                                        let activeChat = closedChats[0]
                                        let started_date = activeChat.chatbot_thread_data[0].timestamp ?? null
                                        let end_date = activeChat.chatbot_thread_data[activeChat.chatbot_thread_data.length - 1].timestamp ?? null
                                        getSourceDetails(activeChat.chatbot_user_ip_address,
                                            activeChat.chatbot_user_route_source,
                                            activeChat.chatbot_phone_number,
                                            activeChat.chatbot_user_name,
                                            started_date,
                                            end_date,
                                            activeChat.chatbot_user_city,
                                            activeChat.chatbot_user_country,
                                            activeChat.chatbot_user_input_token,
                                            activeChat.chatbot_user_output_token,
                                            activeChat.openai_assistant_id,
                                            activeChat.openai_thread_id
                                        )
                                    }
                                }
                                setTimeout(() => {
                                    // let container = document.getElementById(chatbot_user_id.toString())
                                    if (container) {
                                        container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                                    }
                                }, 800)
                            }
                            if (container) {
                                setTimeout(() => {
                                    container.scrollTo({
                                        top: container.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                }, 100);
                            }
                        } else {
                            setFlagInputDisable(false);
                            setChatBotDetail((prevState: any) => ({
                                ...prevState,
                                chatbot_thread: prevState.chatbot_thread?.map((thread: ChatbotThread) => ({
                                    ...thread,
                                    flag_loader: false
                                }))
                            }));
                            setOpenSnackbar((prevState: any) => ({ ...prevState, flagShowSnackbar: true }));
                        }
                    }
                };

                websocket.onclose = (event: CloseEvent) => {
                    clearInterval(pingInterval!);
                    pingInterval = null;

                    if (event.code !== 1000 && event.code !== 1008) {
                        createWebSocket();
                    }
                };
            };

            if (socketUrl) {
                createWebSocket();
            }

            return {
                status: true,
                webSocketObjTemp: websocket
            };
        } catch (err) {
            console.error("WebSocket initialization error", err);
        }
    };
    const waitForSocketConnection = (websocket: any, callback: any) => {
        setTimeout(() => {
            if (websocket.readyState === WebSocket.OPEN) {
                if (callback) callback();
            } else {
                waitForSocketConnection(websocket, callback);
            }
        }, 5);
    };
    async function handleSendMessageManually() {
        let suggestion = inputValue
        const container = document.getElementById('chatUI');
        if (container) {
            setTimeout(() => {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: 'smooth'
                });
            }, 100); // Delay of 100 milliseconds (adjust as needed)
        }
        let tempData = { ...currentActiveChatbotThread }
        tempData?.chatbot_thread_data?.push(
            // to show user msg
            {
                chatbot_thread_value: inputValue,
                timestamp: new Date(),
                flag_user_message: false,
                chatbot_user_active_flag: true
            },
        )
        tempData?.chatbot_thread?.push()
        setCurrentActiveChatbotThread(tempData)
        let data = {
            token: currentActiveChatbotThread.chatbot_token,
            payload: {
                message: suggestion,
                chatbot_user_id: currentActiveChatbotThread.chatbot_user_id,

            }
        }
        setinputValue("")
        let query = {
            chatbot_token: currentActiveChatbotThread.chatbot_token,
        }
        if (webSocketObj && webSocketObj.readyState == 3) {
            let initializeSocket = await handleInitializeSocket();
            if (initializeSocket && initializeSocket.status && initializeSocket.webSocketObjTemp) {
                const payload = { ...data.payload }
                let currentTime = new Date().getTime()
                waitForSocketConnection(initializeSocket.webSocketObjTemp, () => {
                    // @ts-ignore
                    initializeSocket?.webSocketObjTemp.send(JSON.stringify({
                        "url": `chatbot/chatbot-threads-manual`,
                        "operation_id": `chat-${currentTime}`,
                        "payload": payload,
                        "origin": process.env.REACT_APP_SITE_URL,
                        "token": currentActiveChatbotThread.chatbot_token,
                        "method": "POST",
                        "query": query
                    }));
                });

            }
        } else {
            const payload = { ...data.payload }
            let currentTime = new Date().getTime()
            waitForSocketConnection(webSocketObj, () => {
                webSocketObj.send(JSON.stringify({
                    "url": "chatbot/chatbot-threads-manual",
                    "operation_id": `chat-${currentTime}`,
                    "payload": payload,
                    "origin": process.env.REACT_APP_SITE_URL,
                    "token": currentActiveChatbotThread.chatbot_token,
                    "method": "POST",
                    "query": query
                }))
            });
        }


    }
    useEffect(() => {
        makeManualOverrideConnection()
    }, [currentActiveChatbotThread])
    function makeManualOverrideConnection() {
        if (currentActiveChatbotThread && currentActiveChatbotThread.flag_manual_override && webSocketObj?.chatbot_user_id !== currentActiveChatbotThread.chatbot_user_id) {
            handleInitializeSocket()
        }
    }
    async function handleToggleManualOverride() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let payload = {
            chatbot_user_id: currentActiveChatbotThread.chatbot_user_id
        }
        const response = await agent.handleToggleManualOverride.put(payload)
        if (response.status) {
            setCurrentActiveChatbotThread((prevState: any) => ({ ...prevState, flag_manual_override: !prevState.flag_manual_override }))
        } else {
            let errorCode = checkForErrorCode(response)
            setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <Grid
            item sx={{
                borderRight: "0.5px solid #DEDEDE",
                width: feedback_id ? open ? "calc(100% - 250px)" : "calc(100% - 70px)" : open ? "calc(100% - 600px)" : "calc(100% - 420px)",
                height: `${innerHeight}px !important`
            }}>
            <Grid container direction={"column"} gap={"0px"}>
                <Grid item>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item padding={"15px"} sx={{ backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#FFFFFF", color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_send_message_font_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_font_color : "#6A097D", width: "100%" }}>
                            <Grid container justifyContent={"space-between"} flexWrap={"nowrap"}>
                                <Grid item>
                                    <Grid container alignItems={"center"} gap={"12px"}>
                                        <Grid item>
                                            <Grid container gap={1} alignItems={"center"}>
                                                <Grid item><img src={currentActiveChatbotThread?.chatbot_logo_url ? currentActiveChatbotThread.chatbot_logo_url : chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : botIcon}
                                                    alt="chatbot Icon"
                                                    style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", display: "flex", aspectRatio: "1" }} /> </Grid>
                                                <Grid item className={styles.chatbotNameHeading} sx={{ color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D" }}>
                                                    {currentActiveChatbotThread?.chatbot_name ? currentActiveChatbotThread?.chatbot_name : chatBotDetails?.chatbot_name}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={styles.userNameHeading} sx={{ color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D" }}>
                                            {currentActiveChatbotThread?.chatbot_user_name}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container flexWrap={"nowrap"} alignItems={"center"}>
                                        <Grid item>
                                            {currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_active_flag &&
                                                <GlobalButton
                                                    sx={{
                                                        marginRight: "10px",
                                                        color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color} !important` : "",
                                                        backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color} !important` : primaryColor,
                                                    }}
                                                    className=''
                                                    onClick={handleToggleManualOverride}
                                                    buttonText={currentActiveChatbotThread && currentActiveChatbotThread.flag_manual_override ? "Switch to AI" : "Takeover"}
                                                />
                                            }
                                        </Grid>
                                        {/* {isAdmin(userDetail) && currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_route_source.toLowerCase() == "whatsapp" && */}
                                        {isAdmin(userDetail) &&
                                            <Grid item>
                                                <MoreVertIcon
                                                    onClick={(event) => { setChatlevelMenu((prevState: any) => ({ ...prevState, flagOpen: true, anchorEl: event.currentTarget })) }}
                                                    style={{ color: "#869596", cursor: "pointer", display: "flex" }} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>

                <Divider className={styles.dividerStyle}
                />

                <Grid item sx={{ height: `${innerHeightCenter}px !important`, width: "100% !important", backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF", paddingLeft: "15px", paddingBlock: "10px" }}>
                    <Grid container className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}>
                            <Box
                                className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                            >
                                <Grid container
                                    className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                                >
                                    <Grid item xs={12}
                                        className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                                        sx={{
                                            borderRadius: "15px"
                                        }}>
                                        <Grid container className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`} sx={{ overflow: "hidden" }} >

                                            <>
                                                <Grid item xs={12} className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}>
                                                    <Grid container id="chatUI" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "0px 0px !important" }} overflow={"auto"}
                                                        className={`${globalStyles.fullHeight} ${globalStyles.fullwidth} ${styles.chatbotChatMainSectionItem}`}
                                                    >

                                                        {(currentActiveChatbotThread !== null && currentActiveChatbotThread?.chatbot_thread_data[0].chatbot_thread_id !== "") ?


                                                            currentActiveChatbotThread ? currentActiveChatbotThread?.chatbot_thread_data.map((thread: any) => {

                                                                const isBot = thread?.flag_user_message == false
                                                                return (
                                                                    <>
                                                                        {!isBot ? (
                                                                            <>
                                                                                <Grid item sx={{ width: '100%', textAlign: 'start', margin: "10px 0px" }}>
                                                                                    <Grid container sx={{ justifyContent: "start", flexWrap: "" }}>

                                                                                        {/* <Grid item className={styles.chatbotChatIconDiv} >
                                                                                            <img src={currentActiveChatbotThread?.chatbot_logo_url ? currentActiveChatbotThread.chatbot_logo_url : botIcon}
                                                                                                alt="chatbot Icona"
                                                                                                style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", aspectRatio: "1" }} />
                                                                                        </Grid> */}

                                                                                        <Grid item
                                                                                            onMouseEnter={() => setChatbotMessageCopyHover(thread?.chatbot_thread_id)}
                                                                                            onMouseLeave={() => setChatbotMessageCopyHover(null)}
                                                                                            sx={{ maxWidth: 'calc(100% - 30px)', width: "100%" }}
                                                                                        >
                                                                                            <Grid container>
                                                                                                <Grid item
                                                                                                    direction={"column"}
                                                                                                    sx={{
                                                                                                        width: "100%",
                                                                                                        maxWidth: "calc(100% - 50px)",
                                                                                                    }}
                                                                                                >
                                                                                                    <Grid container>
                                                                                                        <Grid item
                                                                                                            className={styles.parentbotMsgBoxStyle}
                                                                                                            sx={{
                                                                                                                marginLeft: "6px",
                                                                                                                padding: "15px 10px 10px 10px",
                                                                                                                borderRadius: "0px 10px 10px 10px",
                                                                                                                boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                                                                                                                fontSize: "13px",
                                                                                                                fontWeight: "400",
                                                                                                                width: "calc(100% - 10px) !important",
                                                                                                                backgroundColor: currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#FBF4FF",
                                                                                                                maxWidth: "fit-content !important",
                                                                                                                position: "relative"
                                                                                                            }}
                                                                                                        >

                                                                                                            <pre
                                                                                                                className={styles.botMsgBoxStyle}
                                                                                                                style={{
                                                                                                                    color: currentActiveChatbotThread.chatbot_theme?.user_msg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "black",
                                                                                                                    overflow: "unset",
                                                                                                                    width: "100%",
                                                                                                                    // textWrap: "pretty",
                                                                                                                    whiteSpace: "normal",
                                                                                                                    wordBreak: "break-word",
                                                                                                                    maxWidth: "unset",
                                                                                                                    background: "unset !important",
                                                                                                                    position: "relative"
                                                                                                                } as CSSProperties}
                                                                                                            >
                                                                                                                {thread.quote_reply_data && thread.quote_reply_data.chatbot_thread_id &&
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            background: "#E0E0E0", padding: "15px", cursor: "pointer", borderRadius: "10px",
                                                                                                                            borderLeft: "5px solid #6A097D",
                                                                                                                            marginBottom: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            style={{ color: "#6A097D", fontWeight: "600" }}
                                                                                                                        >
                                                                                                                            {currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id)?.flag_user_message ? "You" : currentActiveChatbotThread.chatbot_name}
                                                                                                                        </div>

                                                                                                                        {
                                                                                                                            getTag(currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id),
                                                                                                                                "messageReply",
                                                                                                                                // true,
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {getTag(thread, getMessageType(thread))}
                                                                                                            </pre>

                                                                                                            {thread.reaction_data && thread.reaction_data[0].reaction_emoji_character !== "" && thread.reaction_data.map((reaction: reactionData) => {
                                                                                                                return <>
                                                                                                                    <p className={styles.reactionStyle}>{reaction.reaction_emoji_character}</p>
                                                                                                                </>
                                                                                                            }
                                                                                                            )}

                                                                                                            {thread?.chatbot_thread_id == chatbotMessageCopyHover &&
                                                                                                                <CopyToClipboard text={stripMarkdown(getMessage(thread))}>
                                                                                                                    <Tooltip
                                                                                                                        title={copyText}
                                                                                                                        onMouseLeave={() => setCopyText("Copy!")}
                                                                                                                        onClick={() => {
                                                                                                                            setCopyText("Copied!");
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Grid item sx={{ cursor: 'pointer', position: "absolute", right: "-25px", bottom: "0px" }}>
                                                                                                                            <IconsColor iconName={"Copy"}
                                                                                                                                // fillColor={currentActiveChatbotThread.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#6A097D"}
                                                                                                                                fillColor={"black"}
                                                                                                                                width={"16"} height={"16"} />&nbsp;
                                                                                                                        </Grid>
                                                                                                                    </Tooltip>
                                                                                                                </CopyToClipboard>
                                                                                                            }

                                                                                                            <span style={{ display: "flex", color: currentActiveChatbotThread.chatbot_theme?.user_msg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "black", fontSize: "10px", fontWeight: "500", justifyContent: "end", marginTop: "10px" }}>{formatDateAccordingToTimezoneUsingMoment(thread.timestamp, userDetail.timezone_hour)}</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>


                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Grid item sx={{ maxWidth: 'calc(100% - 10px)', width: "auto", textAlign: 'start', margin: "10px 0px" }}
                                                                                    onMouseEnter={() => setChatbotMessageCopyHover(thread?.chatbot_thread_id)}
                                                                                    onMouseLeave={() => setChatbotMessageCopyHover(null)}
                                                                                >
                                                                                    <Grid container sx={{ alignItems: "end", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                        <Grid item
                                                                                            sx={{
                                                                                                display: getParseData(thread?.chatbot_thread_value)?.error_message ? "block" : getParseData(thread?.chatbot_thread_value)?.flag_hide_main_message ? "none" : "block",
                                                                                                marginRight: "10px",
                                                                                                padding: "8px 8px",
                                                                                                borderRadius: "10px 10px 0px 10px",
                                                                                                boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                fontSize: "13px",
                                                                                                fontWeight: "400",
                                                                                                backgroundColor: currentActiveChatbotThread.chatbot_theme?.system_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_bg_color : chatBotDetails?.chatbot_theme.system_msg_bg_color ? chatBotDetails?.chatbot_theme.system_msg_bg_color : "#F4F4F4",
                                                                                                position: "relative",
                                                                                                maxWidth: "calc(100% - 80px) !important",
                                                                                            }}
                                                                                        >
                                                                                            <IconButton
                                                                                                aria-label="more"
                                                                                                id={thread?.chatbot_thread_id?.toString()}
                                                                                                key={thread.chatbot_thread_id}
                                                                                                aria-haspopup="true"
                                                                                                disableRipple
                                                                                                disableFocusRipple
                                                                                                disableTouchRipple
                                                                                                sx={{
                                                                                                    position: "absolute",
                                                                                                    top: "-10px",
                                                                                                    right: "-5px",
                                                                                                }}
                                                                                                className="chatbotMenuIcon"
                                                                                                onClick={(e) => {
                                                                                                    handleOpenMenu(e, thread.chatbot_thread_id)
                                                                                                }}
                                                                                            >
                                                                                                <KeyboardArrowDownIcon
                                                                                                    sx={{
                                                                                                        fontSize: "large",
                                                                                                        width: '20px',
                                                                                                        height: 'auto'
                                                                                                    }}
                                                                                                />
                                                                                            </IconButton>

                                                                                            <Typography
                                                                                                sx={{
                                                                                                    color: currentActiveChatbotThread.chatbot_theme?.system_msg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "black",
                                                                                                    wordBreak: 'break-word',
                                                                                                    fontSize: "13px",
                                                                                                    whiteSpace: "pre-line"
                                                                                                }}
                                                                                            >
                                                                                                {thread.quote_reply_data && thread.quote_reply_data.chatbot_thread_id != null && (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            position: 'relative',
                                                                                                            background: "#E0E0E0",
                                                                                                            padding: "10px",
                                                                                                            cursor: "pointer",
                                                                                                            borderRadius: "10px",
                                                                                                            borderLeft: "5px solid #6A097D",
                                                                                                            marginBottom: "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{ color: "#6A097D", fontWeight: "600" }}
                                                                                                        >
                                                                                                            {currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id)?.flag_user_message ? "You" : currentActiveChatbotThread.chatbot_name}
                                                                                                        </div>
                                                                                                        {getTag(currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id), "messageReply", true)}
                                                                                                    </div>
                                                                                                )}
                                                                                                {isJsonString(thread.chatbot_thread_value) && !thread.flag_loader && typeof getParseData(thread.chatbot_thread_value) == "object" && (getParseData(thread.chatbot_thread_value)?.error_message) ?
                                                                                                    getParseData(thread.chatbot_thread_value)?.error_message
                                                                                                    :
                                                                                                    isJsonString(thread.chatbot_thread_value) && !thread.flag_loader && typeof getParseData(thread.chatbot_thread_value) == "object" && Math.trunc(getParseData(thread.chatbot_thread_value).message_format_id) == 8 ?
                                                                                                        <span>{getTag(thread, getMessageType(thread))}</span>
                                                                                                        :
                                                                                                        getTag(thread, getMessageType(thread))}
                                                                                            </Typography>

                                                                                            {/* {thread.chatbot_message_feedback && thread.chatbot_message_feedback.length > 0 && thread.chatbot_message_feedback[0].chatbot_message_feedback_id != "" && */}
                                                                                            {/* {thread.chatbot_message_feedback && thread.chatbot_message_feedback.length > 0 && thread.chatbot_message_feedback[0].feedback_id && thread.chatbot_message_feedback[0].feedback_id != "" && */}
                                                                                            {currentActiveChatbotThread?.feedback?.find((item: any) => item.feedback_unique_id === thread.chatbot_thread_id) &&
                                                                                                <p style={{ position: "absolute", left: "-30px", top: "0px", marginTop: "0" }}>
                                                                                                    <Tooltip
                                                                                                        onClick={() => { setFeedbackDetails(currentActiveChatbotThread?.feedback?.find((item: any) => item.feedback_unique_id === thread.chatbot_thread_id)); setValue(1) }}
                                                                                                        title={currentActiveChatbotThread?.feedback?.find((item: any) => item.feedback_unique_id === thread.chatbot_thread_id).feedback_option_name} placement='top'>
                                                                                                        {/* onClick={() => { setFeedbackDetails(thread.chatbot_message_feedback[0]); setValue(1) }} */}
                                                                                                        {/* title={thread.chatbot_message_feedback[0].feedback_option_name} placement='top'> */}
                                                                                                        <InfoIcon className={styles.chatbotRatingIconStyle} sx={{ fontSize: "20px !important", marginLeft: "7px", cursor: "pointer" }} />
                                                                                                    </Tooltip>
                                                                                                </p>

                                                                                            }

                                                                                            {thread.reaction_data && thread.reaction_data[0].reaction_emoji_character !== "" && thread.reaction_data.map((reaction: reactionData) => {
                                                                                                return <>
                                                                                                    <p className={styles.reactionUserStyle}>{reaction.reaction_emoji_character}</p>
                                                                                                </>
                                                                                            }
                                                                                            )}

                                                                                            {thread.chatbot_thread_id == chatbotMessageCopyHover &&
                                                                                                <>
                                                                                                    <CopyToClipboard text={stripMarkdown(getMessage(thread))}>
                                                                                                        <Tooltip
                                                                                                            title={copyText}
                                                                                                            onMouseLeave={() => setCopyText("Copy!")}
                                                                                                            onClick={() => {
                                                                                                                setCopyText("Copied!");
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid item sx={{ cursor: 'pointer', position: "absolute", left: "-25px", bottom: "0px" }}>
                                                                                                                <IconsColor
                                                                                                                    iconName={"Copy"}
                                                                                                                    fillColor={"black"}
                                                                                                                    width={"16"}
                                                                                                                    height={"16"}
                                                                                                                />&nbsp;
                                                                                                            </Grid>
                                                                                                        </Tooltip>
                                                                                                    </CopyToClipboard>
                                                                                                </>
                                                                                            }

                                                                                            <span style={{ display: "flex", color: currentActiveChatbotThread.chatbot_theme?.system_msg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "black", fontSize: "10px", fontWeight: "500", justifyContent: "end", marginTop: "10px" }}> {formatDateAccordingToTimezoneUsingMoment(thread.timestamp, userDetail.timezone_hour, "all")}</span>
                                                                                        </Grid>
                                                                                        <Grid item className={styles.chatbotChatIconDiv}>
                                                                                            <Grid container direction={"column"} gap={"2px"} alignItems={"end"}>
                                                                                                {isAdmin(userDetail) &&
                                                                                                    <Grid item sx={{ marginRight: "12px" }}>
                                                                                                        <Tooltip
                                                                                                            title={
                                                                                                                <Grid container direction={"column"}>
                                                                                                                    <Grid item>
                                                                                                                        <Grid container justifyContent={"space-between"} gap={1}>
                                                                                                                            <Grid item><span>{`AI Response Time: `}</span></Grid>
                                                                                                                            <Grid item><span>{thread?.ai_response_time}</span></Grid>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Grid container justifyContent={"space-between"} gap={1}>
                                                                                                                            <Grid item><span>{`Total Response Time: `}</span></Grid>
                                                                                                                            <Grid item><span>{thread?.total_response_time}</span></Grid>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            } placement='top-end'
                                                                                                        >
                                                                                                            <ScheduleSendIcon sx={{ fontSize: "20px !important", color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color : "#000", cursor: "pointer" }} />
                                                                                                        </Tooltip>
                                                                                                    </Grid>
                                                                                                }
                                                                                                <Grid item>
                                                                                                    <img src={currentActiveChatbotThread?.chatbot_logo_url ? currentActiveChatbotThread.chatbot_logo_url : botIcon}
                                                                                                        alt="chatbot Icona"
                                                                                                        style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", aspectRatio: "1", marginRight: "12px" }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                <Menu
                                                                                                    id={thread?.chatbot_thread_id?.toString()}
                                                                                                    MenuListProps={{
                                                                                                        'aria-labelledby': 'long-button',
                                                                                                    }}
                                                                                                    className={`${styles.feedbackMenuItem}`}
                                                                                                    anchorEl={thread.anchorEl ?? null}
                                                                                                    key={thread.chatbot_thread_id}
                                                                                                    open={thread.flagOpenMenu ?? false}
                                                                                                    // onClick={() => { setFlagNotScrollingContainer(true) }}
                                                                                                    onClose={(e) => handleCloseMenu(e, thread.chatbot_thread_id)}
                                                                                                    anchorOrigin={{
                                                                                                        vertical: 'top',
                                                                                                        horizontal: 'right',
                                                                                                    }}
                                                                                                    transformOrigin={{
                                                                                                        vertical: -20,
                                                                                                        horizontal: 'center',
                                                                                                    }}
                                                                                                    PaperProps={{
                                                                                                        style: {
                                                                                                            // maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                            // width: '130px',
                                                                                                            borderRadius: '5px',
                                                                                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                            padding: "0 8px"
                                                                                                        },
                                                                                                    }}
                                                                                                >
                                                                                                    <MenuItem
                                                                                                        // disabled={thread.chatbot_message_feedback ? (thread.chatbot_message_feedback[0].chatbot_message_feedback_id == "" ? false : true) : true}
                                                                                                        disabled={currentActiveChatbotThread?.feedback ? (currentActiveChatbotThread?.feedback?.find((item: any) => item.feedback_unique_id === thread.chatbot_thread_id) ? true : false) : false}
                                                                                                        onClick={(event) => {
                                                                                                            setIsFeedbackFormOpen(true);
                                                                                                            setFeedbackData((prevState: any) => ({ ...prevState, chatbot_thread_id: thread.chatbot_thread_id, flagFeedbackDialog: true }))
                                                                                                            setThreadId(thread.chatbot_thread_id)
                                                                                                            setCurrentActiveChatbotThread((prevState: any) => ({ ...prevState, chatbot_thread_data: prevState.chatbot_thread_data.map((temp: any) => temp.chatbot_thread_id == thread.chatbot_thread_id ? ({ ...temp, flagOpenMenu: false, anchorEl: null }) : temp) }))
                                                                                                            setTimeout(() => {
                                                                                                                const container = document.getElementById('checkBlock')
                                                                                                                container?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                                                                                                            }, 500);
                                                                                                            setFlagFeedbackDialog(true)
                                                                                                            setFlagTellusMoreOptionBtn(null)
                                                                                                            // }
                                                                                                        }
                                                                                                        }
                                                                                                        className="menu-message-item"

                                                                                                    >
                                                                                                        Feedback
                                                                                                    </MenuItem>
                                                                                                </Menu>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    {currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_route_source.toLowerCase() !== "whatsapp" &&
                                                                                        <Grid container sx={{ alignItems: "end", justifyContent: "end", flexWrap: "nowrap", marginLeft: "30px", maxWidth: "calc(100% - 85px) !important" }}>
                                                                                            <MessageFormatComponents
                                                                                                thread={thread}
                                                                                                currentActiveChatbotThread={currentActiveChatbotThread}
                                                                                                getThread={getThread}
                                                                                                drawerProps={drawerProps}
                                                                                                setDrawerProps={setDrawerProps}
                                                                                            />
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                        )
                                                                        }
                                                                    </>
                                                                )
                                                            }) : ""
                                                            :
                                                            <>
                                                                {subType == 'Active' ?
                                                                    <>
                                                                        {filteredData.activeChats.length == 0 ?
                                                                            <>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ color: currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#000000" }}>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography></Grid>
                                                                                </Grid>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ color: currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#000000" }}>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation selected yet.</Typography></Grid>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {filteredData.closedChats.length == 0 ?
                                                                            <>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ color: currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#000000" }}>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography></Grid>
                                                                                </Grid>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ color: currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#000000" }}>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation selected yet.</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item sx={{ opacity: "1", paddingInline: "15px", paddingBottom: "10px", backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme?.chatbot_back_ground_color : "#FFFFFF" }}>
                    <Grid container gap={1} flexWrap={"nowrap"}>
                        <Grid item
                            sx={{
                                width: "100%",
                                borderRadius: "10px",
                                background: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                border: `1px solid ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                boxShadow: `0 1px 2px ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`
                            }}
                        >
                            <TextField
                                value={inputValue}
                                onChange={((event) => (setinputValue(event.target.value)))}
                                disabled={currentActiveChatbotThread && currentActiveChatbotThread.flag_manual_override ? !currentActiveChatbotThread.flag_manual_override : true}
                                placeholder='Type a message...'
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault(); // Prevent the default action to avoid a new line being added
                                        if (currentActiveChatbotThread.flag_manual_override) {
                                            handleSendMessageManually();
                                        }
                                    }
                                }}
                                sx={{
                                    border: 'none',
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    width: "100%",
                                    borderRadius: "10px",
                                    backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color : "#FFFFFF",
                                    '& .Mui-disabled': {
                                        '-webkit-text-fill-color': currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_font_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: "12.5px 14px !important",
                                        fontSize: "14px !important",
                                        fontWeight: "500"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item className={styles.chatbot_preview_arrow_item} sx={{ backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color }}>
                            <IconButton
                                onClick={handleSendMessageManually}
                                disabled={currentActiveChatbotThread && currentActiveChatbotThread.flag_manual_override ? !currentActiveChatbotThread.flag_manual_override : true}
                                sx={{
                                    border: `1px solid ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                    borderRadius: '8px',
                                    height: '100%',
                                    width: '100%',
                                    padding: '5px'
                                }}
                            >
                                <IconsColor
                                    fillColor={currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color}
                                    iconName="send"
                                    width="18px"
                                    height="18px"
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>

    )
}

export default ChatUserThreadLogView