import { Autocomplete, Chip, Grid, TextField, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';
import MixedModeTagify from '../../global/mixedTag';
import Required from '../../utility/required';
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import chipStyles from './../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import globalStyles from './../../../assets/styles/global.module.scss'
import { initialContentType } from './leadGenerationConstants';

interface masterValueInterface {
    id: number,
    value: string
}

interface contentType {
    field_id: number,
    field_name: string
}

interface emailFormInterface {
    customTemplatePromptActionData: String,
    setCustomTemplatePromptActionData: React.Dispatch<React.SetStateAction<String>>,
    handleChangeEmailConfigPayload: (field: string, value: string) => void,
    masterValues: [] | masterValueInterface[],
    setMasterValues: React.Dispatch<React.SetStateAction<[] | masterValueInterface[]>>,
    contentType: contentType,
    contentTypeFields: {
        field_id: number;
        field_name: string;
    }[],
    handleChangeContentType: (value: contentType | null) => void,
    enumValue: string,
    setEnumValue: React.Dispatch<React.SetStateAction<string>>
}

const EmailConfigForm = (props: emailFormInterface) => {
    const {customTemplatePromptActionData, setCustomTemplatePromptActionData, handleChangeEmailConfigPayload, masterValues, setMasterValues, contentType, contentTypeFields, 
        handleChangeContentType, enumValue, setEnumValue} = props;
    const {emailConfigPayload, editIntegrationData} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload;
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const [searchParams] = useSearchParams();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [flagEmailValid, setFlagEmailValid] = useState(true)

    const handleCheckEmailValidation = (value: any) => {
        handleChangeEmailConfigPayload('email_to', value.join(','));
        // handleChangeEmailConfigPayload('email_to', value);
        setEnumValue("")
        if(emailRegex.test(enumValue)) {
            setFlagEmailValid(true)
        }
        else {
            setFlagEmailValid(false)
        }
    }

    return (
        <Grid container gap={2}>
            <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Email Name <Required /></span>
                    </Grid>
                    <Grid item xs={9.5}>
                        <TextField
                            sx={{
                                width: "100%",
                                backgroundColor: "#FFFFFF !important",
                                border: 'none', 
                                "& fieldset": { border: 'none' },
                                boxShadow: "0px 0px 3px rgba(0,0,0,0.25)", 
                                borderRadius: "5px",
                                '& .MuiOutlinedInput-input': {
                                    padding: "11.5px 14px !important",
                                    fontSize: flagSmallScreen ? "14px !important" : "15px !important",
                                    fontWeight: "500"
                                }
                            }}
                            disabled={!editIntegrationData}
                            value={emailConfigPayload.email_name}
                            // placeholder='Enter Lead Name'
                            onChange={(event) => handleChangeEmailConfigPayload('email_name', event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Send To <Required /></span>
                    </Grid>
                    <Grid item xs={9.5}>
                        <Autocomplete
                            options={[]}
                            freeSolo
                            multiple
                            className={`${chipStyles.autocompleteValues} ${styles.enumChipStyle}`}
                            value={emailConfigPayload?.email_to ? emailConfigPayload.email_to.split(',') : []}
                            disabled={!editIntegrationData}
                            onChange={(event: any, value: any) => {
                                handleCheckEmailValidation(value)
                            }}
                            //@ts-ignore
                            onInputChange={((event) => { setEnumValue(event.target.value); setFlagEmailValid(true) })}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option}
                                        deleteIcon={<CloseIcon />}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} placeholder="" />}
                        />
                        {enumValue && flagEmailValid &&
                            <div style={{ fontSize: "13px", fontWeight: "500", color: "#4C4A4D", marginTop: "5px" }}>
                                Press Enter to Save: <span style={{ fontSize: "14px", fontWeight: "600", color: "#6A097D" }}>{enumValue}</span> 
                            </div>
                        }
                        {!flagEmailValid && editIntegrationData &&
                            <div style={{ fontSize: "13px", fontWeight: "500", color: "#4C4A4D", marginTop: "5px"}}>
                                <span style={{ fontSize: "14px", fontWeight: "600", color: "red" }}>Please Enter Valid Email Address</span> 
                            </div>
                        }
                        {/* {!flagEmailValid && !enumValue &&
                            <div style={{ fontSize: "13px", fontWeight: "500", color: "#4C4A4D", marginTop: "5px"}}>
                                <span style={{ fontSize: "14px", fontWeight: "600", color: "red" }}>Please Enter Valid Email Address</span> 
                            </div>
                        } */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Subject <Required /></span>
                    </Grid>
                    <Grid item xs={9.5}>
                        <TextField
                            sx={{
                                width: "100%",
                                backgroundColor: "#FFFFFF !important",
                                border: 'none', 
                                "& fieldset": { border: 'none' },
                                boxShadow: "0px 0px 3px rgba(0,0,0,0.25)", 
                                borderRadius: "5px",
                                '& .MuiOutlinedInput-input': {
                                    padding: "11.5px 14px !important",
                                    fontSize: flagSmallScreen ? "14px !important" : "15px !important",
                                    fontWeight: "500"
                                }
                            }}
                            disabled={!editIntegrationData}
                            value={emailConfigPayload.email_subject}
                            // placeholder='Enter Lead Name'
                            onChange={(event) => handleChangeEmailConfigPayload('email_subject', event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Raw Content / HTML <Required /></span>
                    </Grid>
                    <Grid item xs={9.5} className={styles.emailContentLabelSpacing}>
                        <MixedModeTagify
                            // key={new Date().getTime()}
                            value={customTemplatePromptActionData}
                            setValue={setCustomTemplatePromptActionData}
                            whiteListOptions={masterValues}
                            editIntegrationData={editIntegrationData}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2}>
                        <span className={styles.formHeadingFontStyle}>Content Type <Required /></span>
                    </Grid>
                    <Grid item xs={10}>
                        <Autocomplete
                            value={contentType}
                            options={contentTypeFields}
                            getOptionLabel={(option) => option.field_name}
                            renderInput={(params) => <TextField {...params} placeholder='Select Content Type' />}
                            onChange={(event, value) => handleChangeContentType(value)}
                            className={`${globalStyles.autocompleteParaphrasing} ${styles.methodAutoComplete}`}
                        />
                    </Grid>
                </Grid>
            </Grid> */}

            {/* {contentType.field_name === "Raw Content" && 
                <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                    <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                        <Grid item xs={2}>
                            <span className={styles.formHeadingFontStyle}>Raw Content <Required /></span>
                        </Grid>
                        <Grid item xs={10} className={styles.emailContentLabelSpacing}>
                            <MixedModeTagify
                                // key={new Date().getTime()}
                                value={customTemplatePromptActionData}
                                setValue={setCustomTemplatePromptActionData}
                                whiteListOptions={masterValues}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            } */}

            {/* {contentType.field_name === "HTML" && 
                <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                    <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                        <Grid item xs={2}>
                            <span className={styles.formHeadingFontStyle}>HTML Content <Required /></span>
                        </Grid>
                        <Grid item xs={10} className={styles.emailContentLabelSpacing}>
                            <MixedModeTagify
                                // key={new Date().getTime()}
                                value={customTemplatePromptActionData}
                                setValue={setCustomTemplatePromptActionData}
                                whiteListOptions={masterValues}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            } */}
        </Grid>
    )
}

export default EmailConfigForm