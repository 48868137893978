import { Button, Divider, Grid } from '@mui/material';
import React, { useState } from 'react'
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/cartForm.module.scss'
import CartMenu from './cartMenu';
import emptyIcon from './../../../../assets/images/chatWidgetComponents/empty-cart.svg'

const CartFormGlobal = (props: any) => {
    const {data, cartData, ctaButtons, threadId, chatbotTheme, thread} = props;
    const [totalPrice, setTotalPrice] = useState(0);

    function numberWithCommas(x: any) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }

    return (
        <Grid container direction={"column"} gap={"0px"} mt={2} className={styles.cart_form_container_style} sx={{backgroundColor: "white", boxShadow: "0px 0px 3px rgba(0,0,0,0.2)", borderRadius: "7px", maxWidth: "500px !important"}}>

            <Grid item sx={{width: "100%"}} textAlign={"center"} pb={"15px"}>
                <span className={styles.cart_form_heading_text_style} >Cart Details</span>
            </Grid>
            <Divider />

            <Grid item sx={{width: "100%"}} className={styles.cart_details_item_style} mt={"4px"}>
                {cartData && cartData.length > 0 ? 
                    cartData.map((cart: any) =>
                        <CartMenu cart={cart} thread_id={threadId} thread={thread} formatData={data} setTotalPrice={setTotalPrice} numberWithCommas={numberWithCommas} chatbotTheme={chatbotTheme}/>
                    )
                    :
                    <Grid container direction={"column"} alignItems={"center"} gap={1} sx={{height: "100%"}} justifyContent={"center"}>
                        <Grid item>
                            <img src={emptyIcon} height={"180"} width={"180"}/>
                        </Grid>
                        <Grid item>
                            <span style={{fontSize: "15px", fontWeight: "600", textAlign: "center"}}>Your cart is empty.</span>
                        </Grid>
                    </Grid>
                }
            </Grid>

            {cartData && cartData.length > 0  &&
                <Grid item sx={{width: "100%", paddingInline: "15px"}}>
                    {/* <Divider  sx={{opacity: "0.5"}}/> */}
                    <Grid container flexWrap={"nowrap"} justifyContent={"space-between"} mt={"10px"} alignItems={"center"}>
                        <Grid item xs={7} sx={{width: "100%", maxWidth: "200px", padding: "10px", borderRadius: "5px", boxShadow: "rgba(0,0,0,0.3)"}}>
                            <span className={styles.cart_form_product_total_price_style} style={{color: "#000"}}>
                                Total Amount: 
                            </span>&nbsp;                         
                            <span className={styles.cart_form_product_total_price_style} style={{color: "#000"}}>
                                {data.currency}
                            </span>&nbsp;
                            <span className={styles.cart_form_product_total_price_style} style={{color: "#000"}}>
                                {numberWithCommas(totalPrice)}
                            </span>
                        </Grid>

                        <Grid item xs={5} sx={{width: "100%"}} textAlign={"end"}>
                            {ctaButtons && ctaButtons.length > 0 && ctaButtons.map((cta: any) =>
                                <Button
                                    disableRipple
                                    disabled
                                    className={styles.cart_form_cta_button_style}
                                    sx={{
                                        backgroundColor: chatbotTheme.chatbot_header_color ? `${chatbotTheme.chatbot_header_color} !important` : "#6A097D", 
                                        color: chatbotTheme.chatbot_header_font_color ? `${chatbotTheme.chatbot_header_font_color} !important` : "#FFF !important", 
                                        '&:hover': {
                                            backgroundColor: chatbotTheme.chatbot_header_font_color ? `${chatbotTheme.chatbot_header_font_color} !important` : "#FFF !important",
                                            color: chatbotTheme.chatbot_header_color ? `${chatbotTheme.chatbot_header_color} !important` : "#6A097D"
                                        }
                                    }}
                                >
                                    {cta.message_fields.url_text}
                                </Button>
                            )}               
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default CartFormGlobal
