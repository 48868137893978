import { Divider, Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import FileUpload from '../../utility/fileUpload/fileUpload'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import axios from 'axios'
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants'
import InfoIcon from '@mui/icons-material/Info';
import GlobalButton from '../../utility/globalButton/button'
import { useNavigate } from 'react-router-dom'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'
import agent from '../../../services/api'
import GlobalPopUp from '../../utility/globalPopUp'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import uploadGIF from './../../../assets/images/callAnalysis/uploadLoader.gif'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const NewCallRecord = () => {
    const navigate = useNavigate()
    const detailInnerHeight = (window.innerHeight - 110);
    const {setFlagLoading, flagLoading, setError, error, setSuccess, success, userDetail} = useContext(UserContext) as UserContextPayload
    const { callCenterDetails } = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 900px)');
    const [files, setFiles] = useState<any>([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const [flagUploadCallRecords, setFlagUploadCallRecords] = useState<boolean>(false)

    const uploadFileHandler = async (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            const file: any = e.target.files[i];
            file.isUploading = true;
            setFiles([file]);

            // file.id=i;
            // setFiles((old: any) => {
            //     let temp: any = [...old]
            //     temp.push(file)
            //     return temp
            // })
            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");

            // let config = {
            //     onUploadProgress: (progressEvent: any) => {
            //         const { loaded, total } = progressEvent;
            //         var percent = Math.floor((loaded * 100) / total)
            //         setProgress({ ...progress, percent: percent, filename: file.name })
            //     }
            // }

            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            // await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            //     withCredentials: true,
            //     onUploadProgress: config.onUploadProgress,
            // }).then(async (res) => {
            //     if (res.status) {
                    file.isUploading = false;
                    // file.file_data = res.data.data;


                    // file.google_storage_id = res.data.data.google_storage_id;
                    await setFiles((old: any) => {
                        let temp = [...old]
                        let fileType = file.name.split('.').pop()
                        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        temp.map((rr: any) => {
                            if (rr.google_storage_id == file.google_storage_id) {
                                rr.isUploading = false;
                                // rr.file_data = res.data.data;
                                // rr.google_storage_id = res.data.data.google_storage_id;
                                // rr.page_field_id = id;
                                rr.previewImageSrc = fileTypeIconSrc;
                            }
                        })
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
                        return temp
            //         })

            //         setIsFileUploaded(false);

            //         // let images = [];

            //         // for (let i = 0; i < e.target.files.length; i++) {
            //         //     images.push(URL.createObjectURL(e.target.files[i]))
            //         // }
            //     }
            // }).catch((err) => {
            //     setError({ ...error, flagShowError: true, message: "Something went wrong!" })
            //     setFiles([])
            //     // inform the user
            //     console.error(err)
            //     // removeFile(file.name)
            // });

            // setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        })}
    }


    const removeFileHandler = async (selected_file: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        setFiles(files.filter((file: any) => file.id != selected_file.id));
        setSuccess({ ...success, flagShowSuccess: true, message: "File Removed Successfully" })
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    const handleUploadCallRecords = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const formData = new FormData();
        formData.append('call_center_id', String(callCenterDetails?.call_center_id || 0));

        for (let i = 0; i < files.length; i++) {
            const file: any = files[i];

            const fileToUpload = new File([file], `${file.original_file_name || file?.name}`, { type: file.type });
            formData.append('files', fileToUpload);
        }
        setFlagUploadCallRecords(true)
        try {
            const response = axios.post(`${process.env.REACT_APP_BASE_URL}/api/call-analysis/batch-import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            // console.log("Upload successful:", response);
        } catch (error) {
            // console.error("Error uploading files:", error);
        }
        setFiles([])
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })      
    }

    return (
        <Grid item sx={{width: "100%", height: `${detailInnerHeight}px`, maxHeight: `${detailInnerHeight}px !important`, overflow: "auto", marginRight: "5px", paddingRight: flagSmallScreen ? "20px": ""}}>
            <Grid container>
                <Grid item xs={12} lg={8} xl={6}>
                    <Grid container gap={1}>
                        <Grid item>
                            <span className={styles.headingTextStyle}>Import Call Records</span>
                            <Tooltip title={"Only .mp3, .mp4 and .wav files are allowed. [One file at a time]"} placement='top'>
                                <InfoIcon className={styles.infoIconStyles} />
                            </Tooltip>
                        </Grid>
                        <Grid item sx={{width: "100%"}}>
                            <Grid container flexWrap={"nowrap"} gap={1} alignItems={"start"}>
                                <Grid item sx={{width: "100%"}}>
                                    <FileUpload
                                        uploadedFileDetails={files}
                                        multipleFileAllow={false}
                                        fileSizeAllowed={100}
                                        fileTypeAllowed={'.mp3, .mp4, .wav'}
                                        fileNumberAllowed={1}
                                        isDisabled={false}
                                        uploadFileHandler={uploadFileHandler}
                                        removeFileHandler={(id: number) => removeFileHandler(id)}
                                        isDeleteFileIconVisible={isFileUploaded}
                                    />
                                </Grid>
                                <Grid item>
                                    {flagTabletScreen ?
                                        <Tooltip title="Import">
                                            <IconButton
                                                sx={{ display: "flex", background: "white", borderRadius: "5px", boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.25)", padding: "6px 8px" }}
                                                onClick={() => handleUploadCallRecords()}
                                                disabled={files.length === 0}
                                            >
                                                <CloudUploadIcon sx={{ color: files.length === 0 ? "#cccccc" : "#6a097d" }} />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <GlobalButton
                                            buttonText={"Import"}
                                            sx={{width: "120px"}}
                                            className='primaryButtonStyle'
                                            disabled={files.length === 0}
                                            onClick={() => handleUploadCallRecords()}
                                        />
                                    }
                                </Grid>
                            </Grid>                           
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {flagUploadCallRecords &&
                <GlobalPopUp
                    open={flagUploadCallRecords}
                    onClose={() => { setFlagUploadCallRecords(false) }}
                    // title={}
                    flagBackdrop
                    paperMinWidth={flagSmallScreen ? "310px" : "700px"}
                    paperMaxwidth={flagSmallScreen ? "610px" : "750px"}
                    flagShowCloseIcon={true}
                    height={"auto"}
                    handleClose={() => { setFlagUploadCallRecords(false) }}
                >
                    <Grid item sx={{ width: "100%", paddingInline: "20px" }}>
                        <Grid container direction={"column"} gap={"20px"}>
                            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                                <span className={styles.popupTitleStyle}>Import Call Records</span>
                            </Grid>
                            <Grid item>
                                <Divider className={styles.popupDividerStyle} />
                            </Grid>
                            <Grid item sx={{ width: "100%"}} mb={"25px"}>
                                <Grid container gap={"0px"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                    <Grid item sx={{ width: "100%" }} xs={12}>
                                        <img src={uploadGIF} height={"140"}/>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }} xs={11} lg={9}>
                                        <span className={styles.headingTextStyle}>
                                            Your call records are being processed. This may require a few minutes for evaluation.
                                        </span>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }} xs={12} mt={3}>
                                        <GlobalButton 
                                            buttonText={"Okay"}
                                            className='primaryButtonStyle'
                                            sx={{width: "140px"}}
                                            onClick={() => {setFlagUploadCallRecords(false); navigate('/callanalysis/callrecords')}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </GlobalPopUp>
            }
        </Grid>
    )
}

export default NewCallRecord