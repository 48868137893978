import { Autocomplete, InputAdornment, Menu, MenuItem, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { GenericDataContext, GenericDataContextPayload } from '../../context/genericDataContext';
import { UserContext, UserContextPayload } from '../../context/userContext';
import globalStyles from "../../assets/styles/global.module.scss"
import { timezoneList } from '../../models/axiosModel';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../utils/constants';
import agent from '../../services/api';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface TimeZoneInterface {
    showTimeZone: boolean,
    setShowTimeZone: any,
    anchorEl: any,
    setAnchorEl: any
}
export default function TimeZoneComponent(props: TimeZoneInterface) {
    const handleClose = () => {
        props.setAnchorEl(null);
        props.setShowTimeZone(false)
    };
    const { genericTimezoneList } = useContext(GenericDataContext) as GenericDataContextPayload
    const navigate = useNavigate()
    const { userDetail, setUserDetail, setError, error, setSuccess, setFlagLoading } = useContext(UserContext) as UserContextPayload
    const updateUserTimezone = async (value: timezoneList) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let payload = {
            timezone_id: value.timezone_id
        }
        const response = await agent.updateUserTimezone.put(payload)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Timezone updated Successfully!" }))
            setUserDetail((prevState) => ({ ...prevState, timezone_id: value.timezone_id, timezone_hour: value.timezone_hour, timezone_name: value.timezone_name, timezone_standard_name: value.timezone_standard_name }))
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTimezones, setFilteredTimezones] = useState(genericTimezoneList);

    useEffect(() => {
        setFilteredTimezones(
            genericTimezoneList.filter(timezone => 
                timezone.timezone_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, genericTimezoneList]);

  return (
    <>
        <Menu
            anchorEl={props.anchorEl}
            id="account-menu"
            open={props.showTimeZone}
            className={globalStyles.timeZoneMenu}
            onClose={handleClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 22,
                ml: -0.5,
                mr: 1,
                },
                '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
                ul:{
                    padding: "0 !important",
                },
                li:{
                    minHeight: "0 !important",
                    paddingInline: "10px !important",
                    fontSize: "14px !important"
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem sx={{maXwidth: "240px", display: "block", wordBreak: "break-word", maxHeight: "calc(100vh - 300px)", overflowY: "auto", backgroundColor: "#FFFFFF"}} disableRipple>
                <MenuItem sx={{maxWidth: "240px", wordBreak: "break-word", display: "block", whiteSpace: "normal", marginBlock: "6px"}} className={globalStyles.searchTimeZone} disableRipple>
                        <TextField 
                            autoFocus
                            placeholder='Search Timezone...' 
                            value={searchTerm} 
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <CloseIcon onClick={()=> setSearchTerm('')}/>
                                ),
                            }}
                        />
                </MenuItem>
                {filteredTimezones.length > 0 ? filteredTimezones.map((timezone) => <MenuItem 
                                                            sx={{maxWidth: "240px", wordBreak: "break-word", display: "block", whiteSpace: "normal", backgroundColor: timezone.timezone_id == userDetail.timezone_id ? "#FBF4FF" : "#FFFFFF"}}
                                                            onClick={() => {updateUserTimezone(timezone); props.setShowTimeZone(false)}}
                                                        >
                                                            {timezone.timezone_name}
                                                        </MenuItem>
                                                )
                                                :
                                                <span style={{textAlign: "center", display: "block", width: "100%", marginBlock: "6px"}}>No such Timezone Available</span>
                }
                </MenuItem>
        </Menu>
    </>
  )
}
