import { Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { hexToRgba } from '../../../../utils/constants';
import ExtensionTable from './extensionTable';
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/extensionDrawer.module.scss'

const initialSubEntry = {
    sub_entry_name: "",
    sub_entries: null
}

const ExtensionFields = (props: any) => {
    const { data, chatbotTheme } = props;

    const [currentExtensionData, setCurrentExtensionData] = useState(data?.message_fields?.form_data && data?.message_fields?.form_data?.length > 0 ? data?.message_fields?.form_data[0] : null)
    const [tabHeading, setTabHeading] = useState(data?.message_fields?.form_data && data?.message_fields?.form_data?.length > 0 ? data?.message_fields?.form_data[0].hospital_name : null)
    const [subEntries, setSubEntries] = useState(initialSubEntry);
    const [flagShowHeader, setFlagShowHeader] = useState(true);
    const [headerTitles, setHeaderTitles] = useState([data?.message_fields?.title]);
    const [step, setStep] = useState(0);
    const [departmentEntries, setDepartmentEntries] = useState(null)

    const handleChangeCardData = (menuName: any) => {
        setCurrentExtensionData(data?.message_fields?.form_data.find((cData: any) => cData.hospital_name == menuName));
        setTabHeading(menuName)
    }

    const handleSubEntries = (subVariants: any) => {
        setFlagShowHeader(false);
        setStep(step+1);
        // if(!headerTitles.find((titles) => titles == subVariants.name)) {
            setHeaderTitles([...headerTitles, subVariants.name])
        // }
        if(subVariants?.departments) {
            setCurrentExtensionData(subVariants?.departments);
        }
        if(subVariants?.departments && subVariants.entries) {
            setDepartmentEntries(subVariants.entries)
        }
        if(!subVariants?.departments && subVariants.entries) {
            setSubEntries({
                sub_entry_name: subVariants.name,
                sub_entries: subVariants.entries
            });
        }
    }

    const handleBackExtensions = () => {
        headerTitles.pop();
        if(step == 1) {
            setFlagShowHeader(true);
            setCurrentExtensionData(data?.message_fields?.form_data?.find((formD: any) => formD.hospital_name == tabHeading));
            if(data?.message_fields?.form_data?.find((formD: any) => formD.hospital_name == tabHeading).entries) {
                setDepartmentEntries(data?.message_fields?.form_data?.find((formD: any) => formD.hospital_name == tabHeading).entries)
            }
            else {
                setDepartmentEntries(null);
            }
        }
        if(subEntries.sub_entries) {
            setSubEntries(initialSubEntry)
        }
        setStep(step-1);
    }

    return (
        <Grid container direction={"column"} className={styles.extension_form_grid_style} gap={"12px"}>
            <Grid item>
                <Grid container direction={"column"} gap={"6px"}>
                    {flagShowHeader &&
                        <Grid item>
                            <span className={styles.extension_name_heading_style}>{data?.message_fields?.title}</span>
                        </Grid>
                    }
                    {!flagShowHeader &&
                        <Grid item>
                            <Grid container flexWrap={"nowrap"} gap={"6px"}>
                                <Grid item>
                                    <KeyboardBackspaceIcon onClick={() => handleBackExtensions()} sx={{cursor: "pointer"}}/>
                                </Grid>
                                <Grid item>
                                    <span className={styles.extension_name_heading_style}>{headerTitles[step]}</span>
                                </Grid>
                            </Grid> 
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Divider sx={{marginTop: "4px"}}/>

            {flagShowHeader &&
                <Grid item>
                    <Grid container gap={"12px"}>
                        {data?.message_fields?.form_data && data?.message_fields?.form_data?.length > 0 ?                           
                            data?.message_fields?.form_data.map((cardData: any) => 
                                <Grid item className={currentExtensionData?.hospital_name == cardData?.hospital_name ? styles.selected_extension_item_style : styles.extension_name_item_style} onClick={() => handleChangeCardData(cardData?.hospital_name)}
                                    sx={{
                                        borderBottom: currentExtensionData?.hospital_name == cardData?.hospital_name ? `1px solid ${hexToRgba(chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6a097d", 1)}` : ""
                                    }}
                                >
                                    <span className={currentExtensionData?.hospital_name == cardData?.hospital_name ? styles.selected_extension_name_style : styles.extension_name_style}
                                        style={{
                                            color: currentExtensionData?.hospital_name == cardData?.hospital_name ? chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6a097d" : ""
                                        }}
                                    >
                                        {cardData?.hospital_name}
                                    </span>
                                </Grid>
                            )
                            :
                            <></>
                        }
                    </Grid>
                </Grid>
            }

            {!subEntries.sub_entries &&        
                <Grid item className={styles.extensions_sub_fields_grid_style}>
                    <Grid container direction={"column"} gap={2}>
                        <Grid item>
                            <Grid container justifyContent={"space-between"} gap={"12px"}>
                                {currentExtensionData?.departments && currentExtensionData?.departments?.length > 0 ?
                                    currentExtensionData?.departments?.map((subVarData: any) => 
                                        <Grid item xs={5.75}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    color: chatbotTheme?.chatbot_send_message_bg_color ? chatbotTheme?.chatbot_send_message_bg_color : "#fff",
                                                },
                                                bgcolor: "rgba(92, 88, 149, 0.04)",
                                                color: chatbotTheme?.chatbot_send_message_font_color ? chatbotTheme?.chatbot_send_message_font_color : "#000",
                                                boxShadow: `0px 0px 3px rgba(92, 88, 149, 0.4)`,
                                                borderRadius: '5px',
                                                justifyContent: 'start',
                                                padding: "10px 15px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleSubEntries(subVarData)}
                                        >
                                            <span className={styles.variant_name_text_style}>{subVarData.name}</span>
                                        </Grid>
                                    )
                                    :
                                    currentExtensionData?.map((extensions: any) => 
                                        <Grid item xs={5.75}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    color: chatbotTheme?.chatbot_send_message_bg_color ? chatbotTheme?.chatbot_send_message_bg_color : "#fff",
                                                },
                                                bgcolor: "rgba(92, 88, 149, 0.04)",
                                                color: chatbotTheme?.chatbot_send_message_font_color ? chatbotTheme?.chatbot_send_message_font_color : "#000",
                                                boxShadow: `0px 0px 3px rgba(92, 88, 149, 0.4)`,
                                                borderRadius: '5px',
                                                justifyContent: 'start',
                                                padding: "10px 15px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleSubEntries(extensions)}
                                        >
                                            <span className={styles.variant_name_text_style}>{extensions.name}</span>
                                        </Grid>
                                    )
                                }

                                {departmentEntries &&
                                    <Grid item sx={{width: "100%"}} mt={"12px"}>
                                        <Grid container gap={"12px"} direction={"column"}>
                                            <Grid item sx={{width: "100%"}}>
                                                <Divider />
                                            </Grid>
                                            <Grid item sx={{width: "100%"}}>
                                                <ExtensionTable subEntries={departmentEntries} chatbotTheme={chatbotTheme} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {subEntries.sub_entries &&
                <Grid item className={styles.extensions_sub_fields_grid_style}>
                    <Grid container direction={"column"} gap={1}>
                        <Grid item className={styles.extension_name_heading_text_style}>
                            <span
                                style={{
                                    color: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6A097D",
                                }}
                            >
                                {subEntries.sub_entry_name}
                            </span>
                        </Grid>
                        <Grid item sx={{width: "100%"}}>
                            <ExtensionTable subEntries={subEntries.sub_entries} chatbotTheme={chatbotTheme} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            
        </Grid>
    )
}

export default ExtensionFields