import { useContext, useEffect, useState } from 'react';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import StepContainer from './global/stepContainer';
import AccountDetails from './global/calendlyAccountDetails';
import { CalendlyContext, CalendlyContextType } from '../../../../context/calendlyIntegrationContext';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import GlobalPopUp from '../../../utility/globalPopUp';

const AppointmentSchedule = () => {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setFlagLoading } = useContext(UserContext) as UserContextPayload;
    const { setGenericChatbotCalendlyData, genericChatbotCalendlyData, genericCalendlyData, setGenericCalendlyData, GetGenericCalendlyData } = useContext(CalendlyContext) as CalendlyContextType;

    useEffect(() => {
        const genericDataCall = async () => {
            if (chatBotDetails && chatBotDetails?.chatbot_calendly_data) {
                setGenericChatbotCalendlyData(chatBotDetails?.chatbot_calendly_data)
            }
            try {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
                const response = await GetGenericCalendlyData();
                if (response.status) {
                    setGenericCalendlyData(response?.data)
                } else {
                    setGenericCalendlyData([])
                }
            } catch (error) {
                console.log(error, 'error')
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }
        genericDataCall()
    }, [chatBotDetails])


    return (
        <>
            {genericCalendlyData &&
                <>
                    {(genericCalendlyData && genericCalendlyData?.length > 0) || (genericChatbotCalendlyData && genericChatbotCalendlyData?.length > 0) ?
                        <>
                            <AccountDetails generic_calendly_data={genericCalendlyData} chatbot_calendly_data={genericChatbotCalendlyData} flagEdit={true} />
                        </> :
                        <>
                            <StepContainer />
                        </>}
                </>
            }

        </>
    );
};

export default AppointmentSchedule;
