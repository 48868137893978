import React, { useContext, useEffect, useRef, useState } from 'react'
import WaveSurfer from "wavesurfer.js"
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import { Button, Grid } from '@mui/material';
import IconsColor from '../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import CallAnalysisWaveLoader from './callAnalysisWaveLoader';
import { callRecordDetailInterface } from '../../../models/axiosModel';

interface audioFileInterface {
    audioURL: string,
    waveLoading: boolean,
    setWaveLoading: React.Dispatch<React.SetStateAction<boolean>>,
    timeToScroll: number | null,
    currentAudioAnalysis: callRecordDetailInterface | null
}

export const CallAudio = (props: audioFileInterface) => {
    const { audioURL, waveLoading, setWaveLoading, timeToScroll, currentAudioAnalysis } = props;
    const [audioPlay, setAudioPlay] = useState(false);
    const [audioSpeed, setAudioSpeed] = useState(1);
    const [volume, setVolume] = useState(0.5);
    const waveformRef = useRef(null);
    const wavesurferRef = useRef<WaveSurfer | null>(null);
    const [flagMute, setFlagMute] = useState(false);
    const [currentTime, setCurrentTime] = useState<number>();
    const {setFlagLoading, flagLoading} = useContext(UserContext) as UserContextPayload

    useEffect(() => {
        if (!wavesurferRef.current) {
            setWaveLoading(true);
            setAudioPlay(false)
            wavesurferRef.current = WaveSurfer.create({
                container: '#waveform',
                waveColor: '#C4C4C4',
                progressColor: '#6C0C7F',
                // @ts-ignore
                partialRender: true,
                dragToSeek: true,
                height: 34,
                hideScrollbar: true,
                normalize: true,
                barGap: 2.5,
                barHeight: 20,
                barRadius: 20,
                barWidth: 4,
            });
            wavesurferRef.current.load(audioURL);
            wavesurferRef.current.setVolume(volume);
            wavesurferRef.current.on('ready', () => {
                // setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setWaveLoading(false)
                if(timeToScroll) {
                    wavesurferRef.current?.seekTo(timeToScroll / wavesurferRef.current.getDuration());
                    setCurrentTime(wavesurferRef.current?.getCurrentTime())
                }
            });

            wavesurferRef.current.on('finish', () => {
                setAudioPlay(false);
            });

            return () => {
                wavesurferRef.current?.destroy();
                wavesurferRef.current = null;
            };
        }
    }, [audioURL]);

    useEffect(() => {
        if (wavesurferRef.current && timeToScroll) {
            const duration = wavesurferRef.current.getDuration();
            if (duration > 0) {
                wavesurferRef.current.seekTo(timeToScroll / duration);
                setAudioPlay(true);
                wavesurferRef.current.play();
                setCurrentTime(wavesurferRef.current.getCurrentTime());
            }
        }
    }, [timeToScroll]);

    // useEffect(() => {
    //     if (!hasEffectRun.current && currentActiveChatbotThread !== null) {
    //         const element = document.getElementById("chatUI")
    //         element?.scrollTo(0, element.scrollHeight)
    //         hasEffectRun.current = true;
    //     }
    // }, [currentActiveChatbotThread])

    const handleStop = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.stop();
            setAudioPlay(false);
        }
    };

    const handlePlayPause = () => {
        if (wavesurferRef.current) {
            if (wavesurferRef.current.isPlaying()) {
                wavesurferRef.current.pause();
                setAudioPlay(false);
            } else {
                wavesurferRef.current.play();
                setAudioPlay(true);
            }
        }
    };

    const handleSkipForward = () => {
        wavesurferRef.current?.skip(2);
    };

    const handleSkipBack = () => {
        wavesurferRef.current?.skip(-2);
    };

    const setPlaybackRate = (rate: number) => {
        if (wavesurferRef.current) {
            wavesurferRef.current.setPlaybackRate(rate);
            setAudioSpeed(rate);
        }
    };

    const increaseSpeed = () => {
        if (audioSpeed < 2) {
            setPlaybackRate(audioSpeed + 0.25);
        }
    };

    const decreaseSpeed = () => {
        if (audioSpeed > 0.5) {
            setPlaybackRate(audioSpeed - 0.25);
        }
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVolume(parseFloat(event.target.value));
        wavesurferRef.current?.setVolume(parseFloat(event.target.value));
    };

    const handleMuteVolume = () => {
        if (!flagMute) {
            setVolume(0);
            wavesurferRef.current?.setVolume(0);
        } else {
            setVolume(0.5);
            wavesurferRef.current?.setVolume(0.5);
        }
        setFlagMute(!flagMute)
    }

    const handleFileDownload = (call_record_google_storage_id: any, call_record_google_storage_file_name: any) => {
        if (call_record_google_storage_file_name) {
            if (call_record_google_storage_id) {
                fetch(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${call_record_google_storage_id}&flag_info=false`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const fileToDownload = new File([blob], `${call_record_google_storage_file_name}`);
                        const url = URL.createObjectURL(fileToDownload);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileToDownload.name;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        // console.error('There was a problem with the fetch operation:', error);
                    });
            }
        }
    }

    return (
        <div style={{maxHeight: waveLoading ? "80px" : "auto", width: "100%"}}>
        {waveLoading && 
            <Grid container justifyContent={"center"}>
                <Grid item sx={{width: "100%", textAlign: "center"}}>
                    <CallAnalysisWaveLoader />
                </Grid>
            </Grid>
        }
            <div ref={waveformRef}>
                <div id="waveform"></div>
                {!waveLoading && 
                    <div className={styles.audioComponentsStyles}>
                        <Button className={styles.buttonStyle} onClick={() => decreaseSpeed()}><IconsColor iconName="audioDecrease" /></Button>
                        <span className={styles.audioSpeedTextStyle}>{audioSpeed.toFixed(2)}</span>
                        <Button className={styles.buttonStyle} onClick={() => increaseSpeed()}><IconsColor iconName="audioIncrease" /></Button>
                        <Button className={styles.buttonStyle} onClick={() => handleSkipBack()}><FastRewindIcon className={styles.mobileIconStyle}/></Button>
                        <Button className={`${styles.buttonStyle} ${styles.playButtonStyle}`} onClick={() => handlePlayPause()}>
                            {audioPlay ? <PauseIcon className={styles.mobileIconStyle}/> : <PlayArrowIcon className={styles.mobileIconStyle}/>}
                        </Button>
                        <Button className={styles.buttonStyle} onClick={() => handleSkipForward()}><FastForwardIcon className={styles.mobileIconStyle}/></Button>
                        <div style={{display: "flex", flexWrap: "nowrap", alignItems: "center"}}>
                            <Button className={styles.buttonStyle} onClick={() => handleMuteVolume()}>{flagMute ? <VolumeOffIcon className={styles.mobileIconStyle}/> : <VolumeUpIcon className={styles.mobileIconStyle}/>}</Button>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={handleVolumeChange}
                                className={styles.customRange}
                                style={{ width: '70px' }}
                            />
                        </div>
                        <div style={{marginLeft: "5px", cursor: "pointer"}} onClick={() => handleFileDownload(currentAudioAnalysis?.call_record_google_storage_id, currentAudioAnalysis?.call_record_google_storage_file_name)}>
                            <IconsColor iconName="download" width="25px" height="25px" fillColor="#6A097D" />
                        </div>
                        {/* <span style={{fontSize: "12px", fontWeight: "500"}}>{currentTime}</span> */}
                    </div>
                }
            </div>
        </div>
    )
}
