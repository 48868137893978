import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import React, { useContext } from 'react'
import { getCartItemResopnse } from '../../../models/axiosModel';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { makeStyles } from '@mui/styles';

interface DataListTableProps {
    cartItemData: getCartItemResopnse[],
}

const DataListTable: React.FC<DataListTableProps> = ({ cartItemData }) => {

    const { userDetail } = useContext(UserContext) as UserContextPayload;

    const useStyles = makeStyles(() => ({
        customTooltip: {
            backgroundColor: '#FFF',
            color: '#000',
            fontSize: '13px',
            padding: '8px 15px',
            borderRadius: 5,
            margin: "0px",
            boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
        },
    }));
    const classes = useStyles();

    return (
        <TableContainer sx={{ borderRadius: "5px", border: "1px solid #e9e9e9", boxSizing: "border-box", width: "100%", maxHeight: `${window.innerHeight - 290}px`, overflow: "auto" }} >
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Item ID</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Variant ID</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Type</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Additional Data</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Updated at Timestamp</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {cartItemData?.length > 0 ?
                        cartItemData.map((item: getCartItemResopnse, ind: number) => {
                            return (
                                <TableRow key={ind} className={dashboardStyles.cellRow}>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{item.item_id}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{item.variant_id}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{item.type}</TableCell>
                                    <Tooltip
                                        // title={JSON.stringify(item.cart_item_json_value)}  
                                        title={
                                            <div style={{ whiteSpace: "pre-line" }}>
                                                {Object.entries(item.cart_item_json_value).map(([key, val]) => `${key}: ${val}`).join('\n')}
                                            </div>
                                        }
                                        classes={{ tooltip: classes.customTooltip }}
                                        placement='bottom-end'
                                    >
                                        <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{ maxWidth: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {(() => {
                                                const [key, value] = Object.entries(item.cart_item_json_value)[0];
                                                return `${key}: ${value} ...`;
                                            })()}
                                        </TableCell>
                                    </Tooltip>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formatDateAccordingToTimezoneUsingMoment(item.updated_at_timestamp, userDetail.timezone_hour)}</TableCell>
                                </TableRow>
                            )
                        })
                        :
                        <TableRow className={dashboardStyles.cellRow}>
                            <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                No Data Available
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>

    )
}

export default DataListTable
