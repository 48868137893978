import { createContext, useState, ReactNode, useContext } from 'react';
import agent from '../services/api';
import { calendlyAuthURLPayloadModel, getCalendlyAuthURLResponseModel, getCalendlyIntegrationResponseModel, getCallbackStatusResponseModel, postChatbotCalendlyEventTypeDataPayloadModel, PostChatbotCalendlyEventTypeDataResponseModel, chatbotCalendlyDataInterface } from '../models/axiosModel';

export interface CalendlyContextType {
    selectedEvent: number[];
    setSelectedEvent: any;
    fieldData: any;
    setFieldData: any;
    isPopupMode: boolean | null;
    setIsPopupMode: (eventName: boolean) => void;
    step: string | null;
    setStep: (eventName: string) => void;
    calendlyAuthData: getCalendlyAuthURLResponseModel | null;
    setCalendlyAuthData: React.Dispatch<React.SetStateAction<getCalendlyAuthURLResponseModel | null>>;
    calendlyAuthUrlApi: any;
    callbackSuccessData: getCallbackStatusResponseModel | null;
    setCallbackSuccessData: React.Dispatch<React.SetStateAction<getCallbackStatusResponseModel | null>>;
    GetCallbackStatus: any;
    calendlyIntegrationData: getCalendlyIntegrationResponseModel[] | null;
    setCalendlyIntegrationData: React.Dispatch<React.SetStateAction<getCalendlyIntegrationResponseModel[] | null>>;
    GetCalendlyIntegrationData: any;
    resetChatbotCalendlyData: any;
    chatbotCalendlyEventTypeData: PostChatbotCalendlyEventTypeDataResponseModel[] | null;
    setChatbotCalendlyEventTypeData: React.Dispatch<React.SetStateAction<PostChatbotCalendlyEventTypeDataResponseModel[] | null>>;
    PostChatbotCalendlyEventTypeData: any
    flagAddAccount: boolean;
    setFlagAddAccount: React.Dispatch<React.SetStateAction<boolean>>;
    genericChatbotCalendlyData: any;
    setGenericChatbotCalendlyData: any;
    editAccDetailPopupOpen: boolean;
    setEditAccDetailPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    DeleteChatbotCalendlyIntegration: any;
    GetGenericCalendlyData: any;
    genericCalendlyData: any;
    setGenericCalendlyData: any;
    connectedAccountsArray: any,
    setConnectedAccountsArray: any,
    integratedAccountsArray: any,
    setIntegratedAccountsArray: any
}

const CalendlyContext = createContext<CalendlyContextType | undefined>(undefined);

const CalendlyProvider = ({ children }: { children: ReactNode }) => {

    const [selectedEvent, setSelectedEvent] = useState<number[]>([]);
    const [isPopupMode, setIsPopupMode] = useState<boolean | null>(false);
    const [step, setStep] = useState<string | null>('1')
    const [calendlyAuthData, setCalendlyAuthData] = useState<getCalendlyAuthURLResponseModel | null>(null)
    const [callbackSuccessData, setCallbackSuccessData] = useState<getCallbackStatusResponseModel | null>(null)
    const [calendlyIntegrationData, setCalendlyIntegrationData] = useState<getCalendlyIntegrationResponseModel[] | null>(null)
    const [chatbotCalendlyEventTypeData, setChatbotCalendlyEventTypeData] = useState<PostChatbotCalendlyEventTypeDataResponseModel[] | null>(null)
    const [saveBtn, setSaveBtn] = useState<boolean | null>(null)
    const [genericChatbotCalendlyData, setGenericChatbotCalendlyData] = useState<chatbotCalendlyDataInterface[] | null>(null)
    const [editAccDetailPopupOpen, setEditAccDetailPopupOpen] = useState<boolean>(false)
    const [flagAddAccount, setFlagAddAccount] = useState(false)
    const [fieldData, setFieldData] = useState<any>([])
    const [genericCalendlyData, setGenericCalendlyData] = useState<any>(null)
    const [connectedAccountsArray, setConnectedAccountsArray] = useState<any>([])
    const [integratedAccountsArray, setIntegratedAccountsArray] = useState<any>([])


    const resetChatbotCalendlyData = () => {
        setStep('1');
        setCalendlyIntegrationData(null)
        setSelectedEvent([])
        setGenericChatbotCalendlyData(null)
        setConnectedAccountsArray([])
        setIntegratedAccountsArray([])
    }

    const calendlyAuthUrlApi = async (payload: calendlyAuthURLPayloadModel) => {
        const response = await agent.calendlyAuthUrlApi.post(payload);
        if (response.status) {
            setCalendlyAuthData(response?.data);
        }
        return response
    };

    const GetCallbackStatus = async (state: string) => {
        const response = await agent.getCallbackStatus.get(state);
        if (response) {
            setCallbackSuccessData(response?.data);
        }
        return response
    }

    const GetCalendlyIntegrationData = async (calendly_integration_id: string) => {
        const response = await agent.getCalendlyIntegrationData.get(calendly_integration_id);
        if (response.status) {
            setCalendlyIntegrationData(response?.data)
        }
        return response
    }

    const GetGenericCalendlyData = async () => {
        const response = await agent.getCalendlyIntegrationData.get();
        if (response.status) {
            setGenericCalendlyData(response?.data)
        }
        return response
    }

    const PostChatbotCalendlyEventTypeData = async (payload: postChatbotCalendlyEventTypeDataPayloadModel) => {
        const response = await agent.postChatbotCalendlyEventTypeData.post(payload)
        if (response.status) {
            setChatbotCalendlyEventTypeData(response?.data)
        }
        return response
    }

    const DeleteChatbotCalendlyIntegration = async (calendly_integration_id: string, chatbot_id: string) => {
        const response = await agent.deleteChatbotCalendlyIntegration.delete(calendly_integration_id, chatbot_id)
        if (response.status) {
            return Response
        }
    }

    const payload: CalendlyContextType = {
        selectedEvent,
        setSelectedEvent,
        fieldData,
        setFieldData,
        isPopupMode,
        setIsPopupMode,
        step,
        setStep,
        calendlyAuthData,
        setCalendlyAuthData,
        calendlyAuthUrlApi,
        GetCallbackStatus,
        callbackSuccessData,
        setCallbackSuccessData,
        calendlyIntegrationData,
        setCalendlyIntegrationData,
        GetCalendlyIntegrationData,
        resetChatbotCalendlyData,
        setChatbotCalendlyEventTypeData,
        chatbotCalendlyEventTypeData,
        PostChatbotCalendlyEventTypeData,
        flagAddAccount,
        setFlagAddAccount,
        genericChatbotCalendlyData,
        setGenericChatbotCalendlyData,
        editAccDetailPopupOpen,
        setEditAccDetailPopupOpen,
        DeleteChatbotCalendlyIntegration,
        GetGenericCalendlyData,
        genericCalendlyData,
        setGenericCalendlyData,
        connectedAccountsArray,
        setConnectedAccountsArray,
        integratedAccountsArray,
        setIntegratedAccountsArray
    };

    return (
        <CalendlyContext.Provider value={payload}>
            {children}
        </CalendlyContext.Provider>
    );
};

export { CalendlyContext, CalendlyProvider };
