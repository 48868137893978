import React from 'react'
import styles from '../../../assets/styles/componentStyles/carouselCard.module.scss'
import { Divider, Grid, Typography } from '@mui/material'
import { convertKeysToLowerCase } from '../../../utils/constants';

const RealEstateCardFormat = (props: any) => {
    const {image, handleActionButton, getActionButtonIcon, handleMouseDown, handleMouseUp} = props;

    return (
        <Grid container>
            <Grid item xs={12} sx={{ padding: '0px' }}>
                <Grid container>
                    <Grid item xs={12} className={styles.chat_crl_img_item} sx={{borderBottom: "1px solid #EAEAEA"}}>
                        {/* <LazyLoad style={{ height: "100%", width: "100%" }}> */}
                        <img
                            onMouseDown={handleMouseDown}
                            onMouseUp={(event) => handleMouseUp(event, image)}
                            src={image.image_url || "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"}
                            alt=""
                            style={{ borderRadius: "5px", cursor: "pointer", width: '100%', height: '100%', maxHeight: '100%', objectFit:image?.flag_image_compact ? "scale-down": "cover", objectPosition: image?.flag_image_compact ? "center" : "top" }}
                        />
                        {/* </LazyLoad> */}
                    </Grid>

                    <Grid item xs={12} className={styles.chat_crl_img_captions_item} sx={{ margin: '8px 0px', padding: '3px 10px', }}>
                        <Grid container direction={"column"} alignItems={"start"}>
                            <Typography
                                component="span"
                                className={styles.chat_crl_img_caption_text}
                            >
                                {String(Object.values(image.additional_information)[0])}
                                {/* {image.additional_information.doctor_name} */}
                            </Typography>
                            <Typography
                                component="span"
                                className={styles.chat_crl_img_caption_text_sub}
                                sx={{ minHeight: "35px", marginTop: "5px" }}
                            >
                                {String(Object.values(image.additional_information)[1])}
                                {/* {image.additional_information.doctor_specialization} */}
                            </Typography>
                            <Grid container sx={{ marginTop: '6px' }}>
                            {Array.isArray(Object.values(image.additional_information)[2]) &&
                                //@ts-ignore
                                Object.values(image.additional_information)[2].map((info: any, index: any) => (
                                    <Grid item xs={12} key={index}>
                                        <Grid container flexWrap={"nowrap"}>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: "12px", color: "#000000", fontWeight: "500" }}>{info.name}</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ fontSize: "12px", color: "#707070", fontWeight: "500" }}>{info.value}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            {convertKeysToLowerCase(image)?.cta_buttons?.map((button: any) => {
                return <>
                    <Divider style={{ width: '100%' }} />
                    <Grid item xs={12} className={styles.chat_crl_media_item} sx={{ cursor: "pointer" }}>
                        <Grid container sx={{ justifyContent: 'center', alignItems: "center" }} onClick={() => { handleActionButton(button) }}>
                            {getActionButtonIcon(button?.message_format_id)}

                            <Typography
                                component="span"
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#2E79BD',
                                    marginLeft: "5px !important",
                                }}
                            >
                                {button?.message_fields?.url_text}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
              })
            }
        </Grid>
    )
}

export default RealEstateCardFormat
