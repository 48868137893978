import { Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import GlobalTextField from '../../utility/globalTextfield'
import GlobalButton from '../../utility/globalButton/button'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { useSearchParams } from 'react-router-dom'
import { addChatbotDomainPayloadModel, updateChatbotDomainPayloadModel } from '../../../models/axiosModel'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext'

export const AddEditDomainPopup = (props: any) => {
    const { setFlagDomainPopup, setCurrentDomain, currentDomain, flagEdit } = props
    const { setError, error, setFlagLoading, success, setSuccess, flagLoading } = useContext(UserContext) as UserContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    const { AddChatbotDomain, setChatBotDetails, UpdateChatbotDomain } = useContext(ChatbotContext) as ChatbotContextPayload

    const [flagDisabledButton, setflagDisabledButton] = useState(true)

    useEffect(() => {
        if (currentDomain !== "" && currentDomain !== null && currentDomain !== undefined) {
            setflagDisabledButton(false)
        } else {
            setflagDisabledButton(true)
        }
    }, [currentDomain])


    const handleAddDomain = async () => {
        let chatbotId = searchParams.get('chatbot_id')
        let payload: addChatbotDomainPayloadModel = {
            chatbot_id: chatbotId ? chatbotId : "",
            chatbot_domain_name: currentDomain
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await AddChatbotDomain(payload)
        if (response.status) {
            setChatBotDetails(response.data)
            setSuccess({ ...success, flagShowSuccess: true, message: "Domain Added Successfully!" })
            setFlagDomainPopup(false)
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
            setFlagDomainPopup(false)
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });

    }

    const handleEditDomain = async () => {

        let payload: updateChatbotDomainPayloadModel = {
            chatbot_domain_id: flagEdit.domain_id ? flagEdit.domain_id : "",
            chatbot_domain_name: currentDomain
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await UpdateChatbotDomain(payload)
        if (response.status) {
            setChatBotDetails(response.data)
            setSuccess({ ...success, flagShowSuccess: true, message: "Domain Updated Successfully!" })
            setFlagDomainPopup(false)
        }
        else {
            setError({
                ...error,
                flagShowError: true,
                message: response.error.message
            })
            setFlagDomainPopup(false)
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ padding: "10px 30px 20px" }}>
                    <Grid container >
                        <Grid item xs={10} sx={{ margin: "0px 0px 10px" }}>
                            <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500" }}>
                                Domain Name
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                                    <GlobalTextField
                                        placeholder="example.com"
                                        onChange={(e: any) => { setCurrentDomain(e.target.value) }}
                                        value={currentDomain}
                                        autoFocus={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ justifyContent: "end" }}>
                                        <Grid item>
                                            <GlobalButton
                                                buttonText='Cancel'
                                                flagFullWidth
                                                className='cancelDomainButton'
                                                onClick={() => { setFlagDomainPopup(false) }} />
                                        </Grid>
                                        <Grid item style={{ marginLeft: "15px" }}>
                                            <GlobalButton
                                                flagFullWidth
                                                buttonText='Save'
                                                className="addButton"
                                                disabled={flagDisabledButton}
                                                // flagGenerate={true}
                                                onClick={() => { flagEdit.flag ? handleEditDomain() : handleAddDomain() }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
