import React from 'react';
import { Grid } from '@mui/material';
import globalStyles from "../../assets/styles/global.module.scss";

function PrivacyCookiePolicy() {
  return (
    <Grid container direction="column" alignItems={"center"} className={globalStyles.htmlGridContainer}>
      <Grid item className={globalStyles.htmlHeading}>Privacy & Cookie Policy</Grid>
      <Grid item className={globalStyles.htmlGridItemStyles}>
        <>
          <p><br /></p>
          <p>This policy is effective as of 27 September 2023 and was last updated on 27 September 2023.</p>
          <h1>Information We Collect<br /><br />Information we collect includes both information&nbsp;you consciously and
            willingly provide us while utilizing or engaging with any of our services and promotions, as well as any
            information automatically transmitted by your devices during the process of accessing our products and services.
          </h1>
          <p><br /></p>
          <h2>Log Data</h2>
          <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It
            may include your device&rsquo;s Internet Protocol (IP) address, your browser type and version, the pages you
            visit, the time and date of your visit, the time spent on each page, other details about your visit, and
            technical details that occur in conjunction with any errors you may encounter.</p>
          <p>Please be aware that while this information may not be personally identifying by itself, it may be possible to
            combine it with other data to personally identify individual persons.</p>
          <p><br /></p>
          <h2>Personal Information</h2>
          <p>We may ask for personal information which may include one or more of the following:</p>
          <p><br /></p>
          <ul>
            <li>
              <p>Name</p>
            </li>
            <li>
              <p>Email</p>
            </li>
            <li>
              <p>Phone/mobile number</p>
            </li>
            <li>
              <p>Other profile information<br /><br /></p>
            </li>
          </ul>
          <h2>Legitimate Reasons for Processing Your Personal Information</h2>
          <p>We only collect and use your personal information when we have a legitimate reason for doing so. In which
            instance, we only collect personal information that is reasonably necessary to provide our services to you.</p>
          <p><br /></p>
          <p>Collection and Use of Information</p>
          <p>We may collect personal information from you when you do any of the following on our website:</p>
          <p><br /></p>
          <ul>
            <li>
              <p>Sign up to receive updates from us via email or social media channels</p>
            </li>
            <li>
              <p>Use a mobile device or web browser to access our content</p>
            </li>
            <li>
              <p>Contact us via email, social media, or on any similar technologies</p>
            </li>
            <li>
              <p>When you mention us on social media</p>
            </li>
          </ul>
          <p>We may collect, hold, use, and disclose information for the following purposes, and personal information will not
            be further processed in a manner that is incompatible with these purposes:</p>
          <p><br /></p>
          <ul>
            <li>
              <p>to contact and communicate with you</p>
            </li>
            <li>
              <p>for advertising and marketing, including to send you promotional information about our products and
                services and information about third parties that we consider may be of interest to you</p>
            </li>
            <li>
              <p>to enable you to access and use our website, associated applications, and associated social media
                platforms</p>
            </li>
            <li>
              <p>for internal record keeping and administrative purposes</p>
            </li>
            <li>
              <p>to comply with our legal obligations and resolve any disputes that we may have</p>
            </li>
            <li>
              <p>for security and fraud prevention, and to ensure that our sites and apps are safe, secure, and used in
                line with our terms of use</p>
            </li>
          </ul>
          <p>Please be aware that we may combine information we collect about you with general information or research data we
            receive from other trusted sources.</p>
          <p><br /></p>
          <p>Security of Your Personal Information</p>
          <p>When we collect and process personal information, and while we retain this information, we will protect it within
            commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying,
            use, or modification.</p>
          <p><br /></p>
          <p>Although we will do our best to protect the personal information you provide to us, we advise that no method of
            electronic transmission or&nbsp;storage is 100% secure, and no one can guarantee absolute data security. We will
            comply with&nbsp;laws applicable to us in respect of any data breach.</p>
          <p><br /></p>
          <p>You are responsible for selecting any password and its overall security strength, ensuring the&nbsp;security of
            your own information&nbsp;within the bounds of our services.</p>
          <p><br /></p>
          <p>How Long We Keep Your Personal Information</p>
          <p>We keep your personal information only for as long as we need to. This time period may depend on what we are
            using your information for, in accordance with this privacy policy. If your personal information is no longer
            required, we will delete it or make it anonymous by removing all details that identify you.</p>
          <p><br /></p>
          <p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or
            reporting obligation or for archiving purposes in the public interest, scientific, or historical research
            purposes or statistical purposes.</p>
          <h1>Children&rsquo;s Privacy</h1>
          <p>We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly
            collect personal information about children under 13.</p>
          <p><br /></p>
          <p>Disclosure of Personal Information to Third Parties</p>
          <p>We may disclose personal information to:</p>
          <p><br /></p>
          <ul>
            <li>
              <p>a parent, subsidiary, or affiliate of our company</p>
            </li>
            <li>
              <p>third party service providers for the purpose of enabling them to provide their services, for example, IT
                service providers, data storage, hosting and server providers, advertisers, or analytics platforms</p>
            </li>
            <li>
              <p>our employees, contractors, and/or related entities</p>
            </li>
            <li>
              <p>our existing or potential agents or business partners</p>
            </li>
            <li>
              <p>sponsors or promoters of any competition or promotion we run</p>
            </li>
            <li>
              <p>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in
                connection with any actual or prospective legal proceedings, or in order to establish, exercise, or
                defend our legal rights</p>
            </li>
            <li>
              <p>third parties, including agents or sub-contractors, who assist us in providing information, products,
                services, or direct marketing to you third parties to collect and process data</p>
            </li>
          </ul>
          <p><br /></p>
          <p>International Transfers of Personal Information</p>
          <p>The personal information we collect is stored and/or processed where we or our partners, affiliates, and
            third-party providers maintain facilities. Please be aware that the locations to which we store, process, or
            transfer your personal information may not have the same data protection laws as the country in which you
            initially provided the information. If we transfer your personal information to third parties in other
            countries: (i)&nbsp;we will perform those transfers in accordance with the requirements of applicable law; and
            (ii) we will protect the transferred personal information in accordance with this privacy policy.</p>
          <p><br /></p>
          <p>Your Rights and Controlling Your Personal Information</p>
          <p>You always retain the right to withhold personal information from us, with the understanding that your experience
            of our website may be affected. We will not discriminate against you for exercising any of your rights over your
            personal information. If you do provide us with personal information you understand that we will collect, hold,
            use and disclose it in accordance with this privacy policy. You retain the right to request details of any
            personal information we hold about you.</p>
          <p><br /></p>
          <p>If we receive personal information about you from a third party, we will protect it as set out in this privacy
            policy. If you are a third party providing personal information about somebody else, you represent and warrant
            that you have such person&rsquo;s consent to provide the personal information to us.</p>
          <p><br /></p>
          <p>If you have previously agreed to us using your personal information for direct marketing purposes, you may change
            your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of
            communications. Please be aware we may need to request specific information from you to help us confirm your
            identity.</p>
          <p><br /></p>
          <p>If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or
            misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps
            to correct any information found to be inaccurate, incomplete, misleading, or out of date.</p>
          <p><br /></p>
          <p>If you believe that we have&nbsp;breached a relevant data protection law&nbsp;and wish to make a complaint,
            please contact us using the details below and provide us with full details of the alleged breach. We will
            promptly investigate your&nbsp;complaint&nbsp;and respond to you, in writing, setting out the outcome of our
            investigation and the steps we will take to deal with your complaint.&nbsp;You also have the right to contact a
            regulatory body or data protection authority in relation to your complaint.</p>
          <p><br /></p>
          <p>Use of Cookies</p>
          <p>We use &ldquo;cookies&rdquo; to collect information about you and your activity across our site. A cookie is a
            small piece of data that our website stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content based on preferences you have specified.</p>
          <p><br /></p>
          <p>When you first visit our sites using a new browser, or if you visit in private browsing mode, we will provide you
            with a cookies permission banner seeking your consent to use of cookies as required by law. From this banner you
            will be able to access our cookies management tool. By clicking OK or clicking through to any part of the site,
            we will start to manage your visit using cookies.</p>
          <p><br /></p>
          <p>Limits of Our Policy</p>
          <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over
            the content and policies of those sites, and cannot accept responsibility or liability for their respective
            privacy practices.</p>
          <p><br /></p>
          <p>Changes to This Policy</p>
          <p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current
            acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will
            post the changes here at the same link by which you are accessing this privacy policy.</p>
          <p><br /></p>
          <p>If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as
            applicable, any new uses of your personal information.</p>
          <p><br /></p>
          <p>Contact Us</p>
          <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p>
          <p><br /></p>
          <p>Email support@chronox.ai</p>
          <p><br /></p>
          <p><br /></p>
        </>
      </Grid>
    </Grid>
  );
}

export default PrivacyCookiePolicy;