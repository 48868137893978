import React, { useContext, useEffect, useState } from 'react'
import AccountInformationCard from '../../utility/accountInformationCard';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import agent from '../../../services/api';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { globalIntegration, metaIntegrationPhoneNumberData } from '../../../models/axiosModel';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import GlobalPopUp from '../../utility/globalPopUp';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import Confirmation from '../../popupFiles/confirmation';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';

const ChatbotWhatsAppIntegrationDetails = () => {
    const navigate = useNavigate()
    const [currentActiveWhatsappIntegration, setCurrentActiveWhatsappIntegration] = useState<any>(null)
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const [searchParams] = useSearchParams()
    const { setSuccess, success, setError, error, setFlagLoading, userDetail } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotIntegrationData, getChatbotIntegrationData, setGetChatbotIntegrationData } = useContext(MetaContext) as MetaContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [flagAddIntegration, setFlagAddIntegration] = useState(false)
    const [phoneNumberIntegrate, setPhoneNumberIntegrate] = useState<any>()

    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState({
        meta_integration_id: "",
        chatbot_id: "",
        phone_number_id: "",
        meta_integration_type_id: ""
    })
    const [removeChatbotIntegrationDetails, setRemoveChatbotIntegrationDetails] = useState({
        chatbot_id: "",
        meta_integration_type_id: "",
        phone_number_id: "",
        meta_integration_id: ""
    })

    useEffect(() => {
        if (getChatbotIntegrationData !== null && getChatbotIntegrationData.length > 0 && getChatbotIntegrationData.flatMap((integration: globalIntegration) => integration.meta_integration_whatsapp_data).filter((data) => data).length > 0) {
            let assignment_id = Number(searchParams.get('assignment_id'))
            if (assignment_id) {
                let whatsapIntegrationData = getChatbotIntegrationData.flatMap((item) => {
                    const { meta_integration_id, meta_integration_type_assignment_data } = item;
                    const { meta_integration_type_id } = meta_integration_type_assignment_data;
                    const whatsappData = item.meta_integration_whatsapp_data;

                    if (whatsappData) {
                        return { ...whatsappData, meta_integration_id, meta_integration_type_id };
                    }

                    return [];
                });
                let currentActiveWhatsappData = whatsapIntegrationData.filter((data) => data.meta_integration_whatsapp_id == assignment_id)
                if (currentActiveWhatsappData && currentActiveWhatsappData.length > 0) {
                    setCurrentActiveWhatsappIntegration(currentActiveWhatsappData)
                } else {
                    let chatbot_id = searchParams.get('chatbot_id')
                    navigate(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`)
                }
            } else {
                let chatbot_id = searchParams.get('chatbot_id')
                navigate(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`)
            }
        } else {
            let chatbot_id = searchParams.get('chatbot_id')
            navigate(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`)
        }
    }, [getChatbotIntegrationData, window.location.href])


    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbotIntegration.delete(removeChatbotIntegrationDetails.chatbot_id, removeChatbotIntegrationDetails.meta_integration_id, removeChatbotIntegrationDetails.meta_integration_type_id, removeChatbotIntegrationDetails.phone_number_id);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(removeChatbotIntegrationDetails.chatbot_id, "")
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setShowDeleteConfirmation(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }
    const handleRemoveChatbotIntegration = (phone_number_id: string) => {
        if (getChatbotIntegrationData && currentActiveWhatsappIntegration) {
            let chatbot_id = searchParams.get('chatbot_id')
            if (chatbot_id) {
                setRemoveChatbotIntegrationDetails(
                    {
                        chatbot_id: chatbot_id,
                        meta_integration_type_id: currentActiveWhatsappIntegration[0].meta_integration_type_id,
                        phone_number_id: phone_number_id,
                        meta_integration_id: currentActiveWhatsappIntegration[0].meta_integration_id
                    }
                )
            }
            setShowDeleteConfirmation(true)
            setPhoneNumberIntegrate(currentActiveWhatsappIntegration[0].meta_integration_phone_number_data.find((integration: any) => integration.phone_number_id === phone_number_id).display_phone_number)
        }
    }
    function addChatbotIntegration(phone_number_id: string) {

        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id && currentActiveWhatsappIntegration) {
            setAddChatbotIntegrationDetails((prevState) => ({
                ...prevState,
                phone_number_id: phone_number_id,
                meta_integration_type_id: currentActiveWhatsappIntegration[0].meta_integration_type_id,
                chatbot_id: chatbot_id,
                meta_integration_id: currentActiveWhatsappIntegration[0].meta_integration_id
            }))
        }
        setFlagAddIntegration(true)
        setPhoneNumberIntegrate(currentActiveWhatsappIntegration[0].meta_integration_phone_number_data.find((integration: any) => integration.phone_number_id === phone_number_id).display_phone_number)
    }
    async function AddChatbotIntegration() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.addChatbotIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            setGetChatbotIntegrationData(response.data)
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagAddIntegration(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <>
            {currentActiveWhatsappIntegration !== null && currentActiveWhatsappIntegration[0].meta_integration_phone_number_data.map((integration: metaIntegrationPhoneNumberData, index: number) => {
                return <>
                    <AccountInformationCard
                        key={index}
                        flagDelete={false}
                        title={`WhatsApp Account ${index + 1}`}
                        currentlySelected={integration.chatbot_integration_assignment_data.length > 0}
                        showRemoveChatbotIntegration={integration.chatbot_integration_assignment_data.filter((chatbotIntegration) => chatbotIntegration.chatbot_id.toString() == searchParams.get('chatbot_id')).length > 0}
                        removeChatbotIntegration={(phone_number_id: string) => { handleRemoveChatbotIntegration(phone_number_id) }}
                        addChatbotIntegration={(phone_number_id) => { addChatbotIntegration(phone_number_id) }}
                        details={{
                            display_phone_number: integration.display_phone_number,
                            quality_rating: integration.quality_rating,
                            phone_number_id: integration.phone_number_id,
                            linked_chatbots: integration.chatbot_integration_assignment_data,
                            business_name: integration.verified_name,
                        }}
                        flagIntegration={true}
                    />
                </>
            })}

            {flagAddIntegration &&
                <GlobalPopUp
                    open={flagAddIntegration}
                    handleClose={() => { setFlagAddIntegration(false) }}
                    flagShowCloseIcon
                >
                    <Confirmation
                        integration='whatsapp'
                        cancel={() => { setFlagAddIntegration(false) }}
                        continue={AddChatbotIntegration}
                        confirmationMessage={`Are you sure you want to integrate ${phoneNumberIntegrate} to ${chatBotDetails?.chatbot_name}`}
                    />
                </GlobalPopUp>
            }
            {
                showDeleteConfirmation &&
                <GlobalPopUp
                    open={showDeleteConfirmation}
                    handleClose={() => { setShowDeleteConfirmation(false) }}
                >
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to remove ${chatBotDetails?.chatbot_name} from ${phoneNumberIntegrate}`}
                        onClose={() => { setShowDeleteConfirmation(false) }}
                        onDelete={deleteChatbotIntegration}
                        sx={{ maxWidth: "400px" }}
                    />
                </GlobalPopUp>
            }

        </>
    )
}

export default ChatbotWhatsAppIntegrationDetails;