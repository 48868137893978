import { Button, Grid, InputLabel, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalTextField from '../../utility/globalTextfield';
import Required from '../../utility/required';
import connectorstyle from '../../../assets/styles/componentStyles/dataconnector.module.scss'
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../utility/fileUpload/fileUpload';
import axios from 'axios';
interface createReqParamsPayload {
    function_loader_id: number | null,
    function_loader_icon: string,
    function_loader_text: string,
}
interface requestParamsProps {
    flagKeyExistError: any,
    setFlagKeyExistError: any,
    createReqParamsPayload: createReqParamsPayload
    onChangePayload: (value: any, keyName: string) => void,
    handleSaveReqParams: () => void;
    handleCancelSaveParams: (objName: string) => void;
}
const DynamicLoaderForm = (props: requestParamsProps) => {
    const { functionParameterTypeDataList } = useContext(GenericDataContext) as GenericDataContextPayload;
    const [enumValue, setEnumValue] = useState("")
    const getSaveButtonDisableFlag = () => {
        if (props.createReqParamsPayload.function_loader_text !== ""
            // && props.createReqParamsPayload.function_loader_icon !== ""
        ) {
            return false
        } else {
            return true
        }
    }
    async function uploadFileHandler(event: any, keyName: string) {
        for (var i = 0; i < event.target.files.length; i++) {
            const file: any = event.target.files[i];
            file.isUploading = true;
            // @ts-ignore
            // setImageFiles((prevState) => ({ ...prevState, [keyName]: [...prevState[keyName], file] }))

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");
            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    // setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }
            )
                .then(async (res) => {
                    if (res.status) {
                        file.isUploading = false;
                        file.file_data = res.data.data;
                        file.google_storage_id = res.data.data.google_storage_id;
                        props.onChangePayload(res.data.data.file_path, 'function_loader_icon')
                        // setImageFiles((prevState) => ({
                        //     ...prevState,
                        //     // @ts-ignore
                        //     [keyName]: prevState[keyName].map((file: any) => {
                        //         let fileType = file.name.split('.').pop();
                        //         let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? "";

                        //         // Assuming you want to compare file.google_storage_id with some other value, e.g., res.data.data.google_storage_id
                        //         if (file.google_storage_id === res.data.data.google_storage_id) {
                        //             return {
                        //                 ...file,
                        //                 isUploading: false,
                        //                 file_data: res.data.data,
                        //                 google_storage_id: res.data.data.google_storage_id,
                        //                 previewImageSrc: fileTypeIconSrc,
                        //                 name: res.data.data.google_storage_original_file_name,
                        //                 size: res.data.data.goolge_storage_file_size
                        //             };
                        //         }

                        //         return file;
                        //     })
                        // }));
                        let images = [];

                        for (let i = 0; i < event.target.files.length; i++) {
                            images.push(URL.createObjectURL(event.target.files[i]))
                        }
                    } else {
                        // setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        // setImageFiles()
                    }
                })
                .catch((err) => {
                    // setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                    // setFiles([])
                    console.error(err)
                });
        }

    }
    function removeFileHandler(data: string) {
        props.onChangePayload('', 'function_loader_icon')
    }
    return (
        <>
            <Grid container className={`${styles.dataConnectorPopupSpacing}`}>
                <Grid item xs={12} className={`${styles.dataConnectorFormPopup} ${styles.dataConnectorPopupBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography
                                component='span'
                                className={styles.dataConnectorHeaderText}
                            >
                                {props.createReqParamsPayload.function_loader_id ? `Update Loader Details` : 'Add New Loader Details'
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.dataconnectorHeaderDivider} />
                        </Grid>

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} className={connectorstyle.createFormField} gap={"20px"} p={1}>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Loader Text</InputLabel><Required /></Grid>
                                                <Grid item>
                                                    <GlobalTextField
                                                        placeholder='E.g This might take a while! Please wait'
                                                        value={props.createReqParamsPayload.function_loader_text}
                                                        onChange={(event) => {
                                                            props.setFlagKeyExistError((prevState: any) => ({ ...prevState, flagParamsKey: false }));
                                                            props.onChangePayload(event.target.value, 'function_loader_text')
                                                        }} /></Grid>
                                                {props.flagKeyExistError.flagParamsKey && <span className={globalStyles.validationText}>This key already exist!</span>}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>GIF</InputLabel>
                                                    {/* <Required /> */}
                                                </Grid>
                                                <Grid item>
                                                    <FileUpload
                                                        uploadedFileDetails={[props.createReqParamsPayload.function_loader_icon || ""]}
                                                        multipleFileAllow={false}
                                                        fileSizeAllowed={5}
                                                        fileTypeAllowed={'.gif'}
                                                        fileNumberAllowed={1}
                                                        uploadFileHandler={(event: any) => { uploadFileHandler(event, 'keyName') }}
                                                        removeFileHandler={removeFileHandler}
                                                        isDisabled={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: "0px" }} mt={2}>
                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>

                                <Grid item xs={12} className={`${connectorstyle.globalHeadRightSubItem} `}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "center" }} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbCancelButton}
                                                onClick={() => { props.handleCancelSaveParams('reqParams') }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ marginLeft: "15px" }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbSaveButton}
                                                onClick={() => { props.handleSaveReqParams() }}
                                                disabled={getSaveButtonDisableFlag()}
                                                sx={{ opacity: getSaveButtonDisableFlag() ? "0.5" : "" }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DynamicLoaderForm
