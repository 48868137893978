import { Button, Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
// import Rating from '@mui/material/Rating';
// import Rating from '@mui/material/Rating';
import styles from '../../../../assets/styles/componentStyles/ratingCardStyles.module.scss'
import { Rating } from "@smastrom/react-rating";
import '@smastrom/react-rating/style.css'
import { hexToRgba } from '../../../../utils/constants';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';

const RatingCard = (props: any) => {
    const { thread, nextThread } = props
    const [value, setValue] = React.useState(0);
    const [flagSubmit, setFlagSubmit] = React.useState(false);
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload

    const CUSTOM_GROUP_LABEL_ID = 'group_label';
    const CUSTOM_ITEM_LABELS = ['Bad', 'Poor', 'Average', 'Very Good', 'Excellent'];
    const CUSTOM_ITEM_LABELS_IDS = ['label_1', 'label_2', 'label_3', 'label_4', 'label_5'];
    // const [rating, setRating] = useState(0);
    // console.log('rat', rating);

    useEffect(() => {
        // if (chatBotDetails && chatBotDetails?.) {
        //     let threadFinal = chatBotDetails?.chatbot_thread.find((thread1) => thread1?.chatbot_thread_id == thread?.chatbot_thread_id)
        //     if (threadFinal) {
        //         let threadIndex = chatBotDetails?.chatbot_thread?.findIndex((thread) => thread?.chatbot_thread_id == threadFinal?.chatbot_thread_id)
        //         if (threadIndex) {
        //             let nextThread = chatBotDetails.chatbot_thread[threadIndex + 1]
        //             if (nextThread) {
        //                 let value = nextThread.message
        //                 let ratingIndex = props.data.message_fields.rating_names.findIndex((name) => name == value)
        //                 setValue(ratingIndex + 1)
        //                 setFlagSubmit(true)
        //             }
        //         }
        //     }
        // }
    }, [])


    function isDisable() {
        if (thread?.seq || flagSubmit) {
            return true
        } else {
            return false
        }
    }

    const StarDrawing = (
        <path d="m14.43 10-1.47-4.84c-.29-.95-1.63-.95-1.91 0L9.57 10H5.12c-.97 0-1.37 1.25-.58 1.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09l3.69-2.8 3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.45z"></path>
    );

    const customStyles = {
        itemShapes: StarDrawing,
        activeFillColor: chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color : "#6a097d",
        inactiveFillColor: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#fff",
    };
    return (
        <>
            <Grid item className={styles.cr_main_item}>
                <Grid container>
                    <Grid item xs={12} className={styles.cr_sub_item}
                    >
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Grid item xs={12}>
                                        <Rating
                                            isDisabled={true}
                                            value={nextThread ? props.data.message_fields.rating_names.findIndex((e: any) => { return e == nextThread.chatbot_thread_value }) + 1 : 0}
                                            //@ts-ignore
                                            // onChange={(event: any, newValue: any) => {
                                            //     setValue(event);
                                            // }}
                                            visibleLabelId={props.data.message_fields.rating_names}
                                            // visibleItemLabelIds={CUSTOM_ITEM_LABELS_IDS}
                                            itemStyles={customStyles}
                                            transition="colors"
                                            className={styles.ratings}
                                            style={{ width: "100%" }}
                                        />
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(5, 1fr)',
                                                justifyContent: 'space-evenly',
                                                width: "100%",
                                                height: 'auto',
                                            }}
                                            className={styles.rating_label_column}
                                        >
                                            {props.data.message_fields.rating_names.map((label: any, index: any) => (
                                                <span
                                                    key={label}
                                                    id={index}
                                                    style={{
                                                        opacity: nextThread && index === props.data.message_fields.rating_names.findIndex((e: any) => { return e == nextThread.chatbot_thread_value }) ? 1 : 0.6,
                                                        fontSize: '13px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                    }}
                                                    className={styles.rating_labels}
                                                >
                                                    {label}
                                                </span>
                                            ))}
                                        </div>

                                        {/********************** 
                                         * Customize Rating 
                                         * ********************/}
                                        {/* <Grid item>
                                            <Grid container sx={{ justifyContent: 'space-evenly', gap: '15px' }}>
                                                {ratingDetails.map((detail, index) => {
                                                    const currentRating = index + 1;

                                                    return (
                                                        <Grid item key={index} sx={{ width: '80px' }}>
                                                            <Grid container sx={{ justifyContent: 'center', flexDirection: 'column', flexWrap: 'nowrap' }}>
                                                                <Grid item
                                                                    sx={{
                                                                        minWidth: '60px',
                                                                        width: 'auto',
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center"
                                                                    }}
                                                                    onClose={() => { setRating(null) }}
                                                                >
                                                                    <label style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                                                                        <input
                                                                            type="radio"
                                                                            style={{ display: 'none' }}
                                                                            name="rating"
                                                                            value={currentRating}
                                                                            onChange={() => setRating(currentRating)}

                                                                        />
                                                                        <span
                                                                            className="star"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                color: currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9",
                                                                                fontSize: '40px'
                                                                            }}
                                                                            onMouseEnter={() => setHover(currentRating)}
                                                                            onMouseLeave={() => setHover(null)}
                                                                        >
                                                                            {detail.icon}
                                                                        </span>
                                                                    </label>
                                                                </Grid>
                                                                <Grid item >
                                                                    <div
                                                                        style={{ fontSize: '13px', fontWeight: '500' }}
                                                                    >
                                                                        {detail.label}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid> */}

                                        {/* MUI Rating */}
                                        {/* <Rating
                                            className='cr-star'
                                            name="simple-controlled"
                                            disabled={isDisable()}
                                            value={value}
                                            sx={{
                                                "& .MuiRating-iconFilled": { color: chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color : "#6a097d", }
                                            }}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            onClose={() => { setValue(0) }}
                                        />
                                        <Box display="flex" justifyContent="space-between" width="100%">
                                            {props?.data && props?.data.message_fields && props?.data.message_fields?.rating_names?.map((key) => (
                                                <Box key={key} display="flex" flexDirection="column" alignItems="center" sx={{ width: '20%' }}>
                                                    <Typography
                                                        variant="span"
                                                        className='cr-rating-labes'
                                                    >{key}</Typography>
                                                </Box>
                                            ))}
                                        </Box> */}

                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* {!isDisable() &&
                                <Grid item
                                    sx={{ textAlign: 'start', margin: '12px 0px 0px 0px' }}>
                                    <Button
                                        variant='outlined'
                                        // onClick={() => {
                                        //     props.handleRecursion({ suggestion_name: props.data.message_fields.rating_names[value - 1] })
                                        //     setFlagSubmit(true)
                                        // }}
                                        className={styles.rating_submit_btn}
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            color: chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color : "#6a097d",
                                            background: chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color : "",
                                            border: `1px solid ${hexToRgba(chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color : "#6A097D")}`,
                                            borderRadius: "5px",
                                            boxShadow: `0px 0px 1px 0px ${hexToRgba(chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color : "#6A097D")}`
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            } */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RatingCard