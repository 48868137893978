import React, { useContext, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { additionalFieldFilters, agentsNames, autoCompleteAdditionalValue, callAnalysisFeedbackPayload, callAnalysisFeedbackTypeResponseModel, callAnalysisOptionResponseModel, callAnalysisPagination, callAnalysisPaginationModel, callCenterInterface, callRecordDetailAdditionalField, callRecordDetailInterface, callRecordsInterface, categoryNames, CommonResponseModel, complexityLevels, dashboardAnalysisResponseModel, getAdditionalFieldResponseModel, getCallAgentResponseModel, getCallAnalysisFeedbackResponseModel, getCallCategoryResponseModel, getCallComplexityResponseModel, getCallEvaluationParameterResponseModel, getCallParentCategoryResponse, getCallRecordsResponseModel, getExtensionsListResponseModel, getPhoneNumbersResponseModel, parentCategoryNames, updateAgentInterface, updateCallEvaluationScore } from "../models/axiosModel";
import agent from "../services/api";
import moment, { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';
import { useNavigate } from "react-router-dom";
import { initialAgentData, initialExtension, initialFilterData, initialPhoneNumber, initialUpdateAdditionalField, initialUpdateCallEvaluationScore } from "../components/dashboard/callAnalysis/callAnalysisConstants";

const CallAnalysisContext = React.createContext<CallAnalysisContextPayload | undefined>(undefined);

export interface CallAnalysisContextPayload {
    GetCallCategoryApi: () => Promise<CommonResponseModel<getCallCategoryResponseModel[] | null>>;
    callCategories: getCallCategoryResponseModel[] | null;
    setCallCategories: React.Dispatch<React.SetStateAction<getCallCategoryResponseModel[] | null>>;

    GetCallCategoryParentApi: () => Promise<CommonResponseModel<getCallParentCategoryResponse[] | null>>;
    callParentCategories: getCallParentCategoryResponse[] | null;
    setCallParentCategories: React.Dispatch<React.SetStateAction<getCallParentCategoryResponse[] | null>>;

    GetCallComplexityApi: () => Promise<CommonResponseModel<getCallComplexityResponseModel[] | null>>;
    callComplexity: getCallComplexityResponseModel[] | null;
    setCallComplexity: React.Dispatch<React.SetStateAction<getCallComplexityResponseModel[] | null>>;

    GetCallAgentsApi: () => Promise<CommonResponseModel<getCallAgentResponseModel[] | null>>;
    callAgentsData: getCallAgentResponseModel[] | null;
    setCallAgentsData: React.Dispatch<React.SetStateAction<getCallAgentResponseModel[] | null>>;

    GetCallRecordsApi: (start_date?: string | null, end_date?: string | null, phone_number?: string | null, extension?: string | null, call_parent_category_id?: number | null, call_category_id?: number | null, call_complexity_id?: number | null, agent_id?: number | null, call_record_additional_field?: additionalFieldFilters[] | null, flag_alert?: boolean | null, flag_dashboard_analysis?: boolean | null, flag_score_in_asc?: boolean | null) => Promise<CommonResponseModel<getCallRecordsResponseModel | null>>;
    callRecordsData: getCallRecordsResponseModel | null;
    setCallRecordsData: React.Dispatch<React.SetStateAction<getCallRecordsResponseModel | null>>;

    GetCallAnalysisAdditionalFieldApi: () => Promise<CommonResponseModel<getAdditionalFieldResponseModel[] | null>>;
    callAnalysisAdditionalFields: getAdditionalFieldResponseModel[] | null;
    setCallAnalysisAdditionalFields: React.Dispatch<React.SetStateAction<getAdditionalFieldResponseModel[] | null>>;

    GetCallEvaluationParamerApi: () => Promise<CommonResponseModel<getCallEvaluationParameterResponseModel[] | null>>;
    callEvaluationRecord: getCallEvaluationParameterResponseModel[] | null;
    setCallEvaluationRecord: React.Dispatch<React.SetStateAction<getCallEvaluationParameterResponseModel[] | null>>;

    GetPhoneNumbersApi: () => Promise<CommonResponseModel<getPhoneNumbersResponseModel[] | null>>;
    phoneNumbersData: getPhoneNumbersResponseModel[] | null;
    setPhoneNumbersData: React.Dispatch<React.SetStateAction<getPhoneNumbersResponseModel[] | null>>;

    GetExtensionListApi: () => Promise<CommonResponseModel<getExtensionsListResponseModel[] | null>>;
    extensionListData: getExtensionsListResponseModel[] | null;
    setExtensionListData: React.Dispatch<React.SetStateAction<getExtensionsListResponseModel[] | null>>;

    GetCallRecordDetailApi: (call_record_id: number) => Promise<CommonResponseModel<callRecordDetailInterface | null>>;

    dashboardAnalysisData: dashboardAnalysisResponseModel | null;
    setDashboardAnalysisData: React.Dispatch<React.SetStateAction<dashboardAnalysisResponseModel | null>>;

    //common file calls
    currentAudioAnalysis: callRecordDetailInterface | null;
    setCurrentAudioAnalysis: React.Dispatch<React.SetStateAction<callRecordDetailInterface | null>>;

    callRecordsDetails: callRecordsInterface[] | null;
    setCallRecordsDetails: React.Dispatch<React.SetStateAction<callRecordsInterface[] | null>>;

    callRecordPagination: callAnalysisPagination | null;
    setCallRecordPagination: React.Dispatch<React.SetStateAction<callAnalysisPagination | null>>;

    callCenterDetails: callCenterInterface | null;
    setCallCenterDetails: React.Dispatch<React.SetStateAction<callCenterInterface | null>>;

    flagAddFilter: boolean;
    setFlagAddFilter: React.Dispatch<React.SetStateAction<boolean>>;

    parentCategoryId: number | null;
    setParentCategoryId: React.Dispatch<React.SetStateAction<number | null>>

    selectedPurposeCategories: categoryNames[];
    setSelectedPurposeCategories: React.Dispatch<React.SetStateAction<categoryNames[]>>;

    selectedComplexityLevels: complexityLevels[];
    setSelectedComplexityLevels: React.Dispatch<React.SetStateAction<complexityLevels[]>>;

    selectedAdditionalFieldsFilter: additionalFieldFilters[];
    setSelectedAdditionalFieldsFilter: React.Dispatch<React.SetStateAction<additionalFieldFilters[]>>;

    parentCategoriesFilter: parentCategoryNames[];
    setParentCategoriesFilter: React.Dispatch<React.SetStateAction<parentCategoryNames[]>>;

    phoneNumberFilter: any;
    setPhoneNumberFilter: React.Dispatch<any>;

    extensionFilter: any;
    setExtensionFilter: React.Dispatch<any>;

    agentListsFilter: agentsNames[];
    setAgentsListFilter: React.Dispatch<React.SetStateAction<agentsNames[]>>;

    alertsOnlyFilter: boolean;
    setAlertsOnlyFilter: React.Dispatch<React.SetStateAction<boolean>>;

    startDate: moment.Moment | null;
    setStartDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;

    endDate: moment.Moment | null;
    setEndDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;

    focusedInput: FocusedInputShape | null;
    setFocusedInput: React.Dispatch<React.SetStateAction<FocusedInputShape | null>>;

    appliedFilter: boolean;
    setAppliedFilter: React.Dispatch<React.SetStateAction<boolean>>;

    filterDate: any;
    setFilterData: React.Dispatch<any>;

    waveLoading: boolean;
    setWaveLoading: React.Dispatch<React.SetStateAction<boolean>>;

    initialLoad: boolean;
    setInitialLoad: React.Dispatch<React.SetStateAction<boolean>>;

    addNewAgentFlag: boolean;
    setAddNewAgentFlag: React.Dispatch<React.SetStateAction<boolean>>;

    updateAgentFlag: boolean;
    setUpdateAgentFlag: React.Dispatch<React.SetStateAction<boolean>>;

    flagAnalysisData: boolean;
    setFlagAnalysisData: React.Dispatch<React.SetStateAction<boolean>>;

    viewRecordDetail: boolean;
    setViewRecordDetail: React.Dispatch<React.SetStateAction<boolean>>;

    updateAgentPayload: updateAgentInterface;
    setUpdateAgentPayload: React.Dispatch<React.SetStateAction<updateAgentInterface>>;

    additinalFieldValue: callRecordDetailAdditionalField[];
    setAdditionalFieldValue: React.Dispatch<React.SetStateAction<callRecordDetailAdditionalField[]>>;

    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;

    timeToScroll: number | null;
    setTimeToScroll: React.Dispatch<React.SetStateAction<number | null>>;

    handleUpdateCallAgent: (agentId: number) => Promise<any>;
    handleUpdateCallParentCategory: (categoryParentId: number) => Promise<any>;
    handleUpdateCallCategory: (categoryID: number) => Promise<any>;
    handleUpdateCallComplexity: (complexityId: number) => Promise<any>;
    handleUpdateAdditionalFields: (additional_field_ids: number, additional_field_option_ids: number) => Promise<any>;
    handleUpdateCallEvaluationScore: (callEvaluationPaylod: updateCallEvaluationScore) => Promise<any>;
    formatDate: (dateString: Moment | null) => string | null;
    applyFilters: (filters: any, pagination?: any) => void;
    handleFilterData: (field: string, data?: any) => void;
    handleStartEndDate: () => void;
    handleCancel: (flagRemove?: boolean) => void;

    flagReportProblem: boolean;
    setFlagReportProblem: React.Dispatch<React.SetStateAction<boolean>>;

    GetCallAnalysisOption: () => Promise<any>;
    callAnalysisFeedbackOptions: callAnalysisOptionResponseModel[] | null;
    setCallAnalysisFeedbackOptions: React.Dispatch<React.SetStateAction<callAnalysisOptionResponseModel[] | null>>;

    GetCallAnalysisFeedbackType: () => Promise<any>;
    callAnalysisFeedbackType: callAnalysisFeedbackTypeResponseModel[] | null;
    setCallAnalysisFeedbackType: React.Dispatch<React.SetStateAction<callAnalysisFeedbackTypeResponseModel[] | null>>;

    CreateCallAnalysisFeedbackApi: (payload: callAnalysisFeedbackPayload) => Promise<any>;
}

const CallAnalysisProvider = ({ children }: ChildrenProps) => {
    const navigate = useNavigate()
    const [callCategories, setCallCategories] = useState<getCallCategoryResponseModel[] | null>(null);
    const [callParentCategories, setCallParentCategories] = useState<getCallParentCategoryResponse[] | null>(null);
    const [callComplexity, setCallComplexity] = useState<getCallComplexityResponseModel[] | null>(null);
    const [callAgentsData, setCallAgentsData] = useState<getCallAgentResponseModel[] | null>(null);
    const [callRecordsData, setCallRecordsData] = useState<getCallRecordsResponseModel | null>(null);
    const [callAnalysisAdditionalFields, setCallAnalysisAdditionalFields] = useState<getAdditionalFieldResponseModel[] | null>(null);
    const [phoneNumbersData, setPhoneNumbersData] = useState<getPhoneNumbersResponseModel[] | null>(null);
    const [extensionListData, setExtensionListData] = useState<getExtensionsListResponseModel[] | null>(null);
    const [callEvaluationRecord, setCallEvaluationRecord] = useState<getCallEvaluationParameterResponseModel[] | null>(null);
    const [dashboardAnalysisData, setDashboardAnalysisData] = useState<dashboardAnalysisResponseModel | null>(null);

    //common file components
    const [currentAudioAnalysis, setCurrentAudioAnalysis] = useState<callRecordDetailInterface | null>(null)
    const [callRecordsDetails, setCallRecordsDetails] = useState<callRecordsInterface[] | null>(null)
    const [callRecordPagination, setCallRecordPagination] = useState<callAnalysisPagination | null>(null)
    const [callCenterDetails, setCallCenterDetails] = useState<callCenterInterface | null>(null)
    const [flagAddFilter, setFlagAddFilter] = useState(false)
    const [selectedPurposeCategories, setSelectedPurposeCategories] = useState<categoryNames[]>([]);
    const [selectedComplexityLevels, setSelectedComplexityLevels] = useState<complexityLevels[]>([]);
    const [selectedAdditionalFieldsFilter, setSelectedAdditionalFieldsFilter] = useState<additionalFieldFilters[]>([]);
    const [parentCategoriesFilter, setParentCategoriesFilter] = useState<parentCategoryNames[]>([]);
    const [phoneNumberFilter, setPhoneNumberFilter] = useState<any | null>(initialPhoneNumber);
    const [extensionFilter, setExtensionFilter] = useState<any | null>(initialExtension);
    const [agentListsFilter, setAgentsListFilter] = useState<agentsNames[]>([]);
    const [alertsOnlyFilter, setAlertsOnlyFilter] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Moment | null>(null)
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const [appliedFilter, setAppliedFilter] = useState(false);
    const [filterDate, setFilterData] = useState<any>(initialFilterData);
    const [waveLoading, setWaveLoading] = useState(false);

    const [initialLoad, setInitialLoad] = useState(true);
    const [addNewAgentFlag, setAddNewAgentFlag] = useState(false);
    const [updateAgentFlag, setUpdateAgentFlag] = useState(false);
    const [viewRecordDetail, setViewRecordDetail] = useState(false);
    const [updateAgentPayload, setUpdateAgentPayload] = useState<updateAgentInterface>(initialAgentData);
    const [flagAnalysisData, setFlagAnalysisData] = useState(false);
    const [additinalFieldValue, setAdditionalFieldValue] = useState<callRecordDetailAdditionalField[]>([]);
    const [parentCategoryId, setParentCategoryId] = useState<number | null>(null);
    const [page, setPage] = React.useState(0);
    const [timeToScroll, setTimeToScroll] = useState<number | null>(null);

    const [flagReportProblem, setFlagReportProblem] = useState<boolean>(false);
    const [callAnalysisFeedbackOptions, setCallAnalysisFeedbackOptions] = useState<callAnalysisOptionResponseModel[] | null>(null);
    const [callAnalysisFeedbackType, setCallAnalysisFeedbackType] = useState<callAnalysisFeedbackTypeResponseModel[] | null>(null);

    const GetCallCategoryApi = async (): Promise<any> => {
        const response = await agent.getCallCategory.get();
        if (response.status) {
            setCallCategories(response.data)
        }
        return response;
    }

    const GetCallCategoryParentApi = async (): Promise<any> => {
        const response = await agent.getCallParentCategory.get();
        if (response.status) {
            setCallParentCategories(response.data)
        }
        return response;
    }

    const GetCallComplexityApi = async (): Promise<any> => {
        const response = await agent.getCallComplexity.get();
        if (response.status) {
            setCallComplexity(response.data)
        }
        return response;
    }

    const GetCallAgentsApi = async (): Promise<any> => {
        const response = await agent.getCallAgent.get();
        if (response.status) {
            setCallAgentsData(response.data)
        }
        return response;
    }

    const GetCallRecordsApi = async (start_date?: string | null, end_date?: string | null, phone_number?: string | null, extension?: string | null, call_parent_category_id?: number | null, call_category_id?: number | null, call_complexity_id?: number | null, agent_id?: number | null, call_record_additional_field?: additionalFieldFilters[] | null, flag_alert?: boolean | null, flag_dashboard_analysis?: boolean | null, flag_score_in_asc?: boolean | null): Promise<any> => {
        const response = await agent.getCallRecords.get(start_date, end_date, phone_number, extension, call_parent_category_id, call_category_id, call_complexity_id, agent_id, call_record_additional_field, flag_alert, flag_dashboard_analysis, flag_score_in_asc);
        if (response.status && response.dashboard_data) {
            setDashboardAnalysisData(response.dashboard_data)
        }
        if (response.status && response.pagination) {
            setCallRecordPagination(response.pagination)
        }
        if (!response.pagination) {
            setCallRecordPagination(null)
        }
        if (!response.dashboard_data) {
            setDashboardAnalysisData(null)
        }
        if (response.status) {
            setCallRecordsData(response.data)
        }
        else {
            setCallRecordsData(null)
        }
        return response;
    }

    const GetCallRecordDetailApi = async (call_record_id: number): Promise<any> => {
        const response = await agent.getCallRecordDetails.get(call_record_id);
        if (response.status) {
            setCurrentAudioAnalysis(response.data);
            setParentCategoryId(response.data.call_parent_category_id)
            if (response.data.call_record_additional_field) {
                setAdditionalFieldValue(response.data.call_record_additional_field)
            }
            else {
                setAdditionalFieldValue([])
            }
        }
        else {
            setCurrentAudioAnalysis(null);
        }
    }

    const GetCallAnalysisAdditionalFieldApi = async (): Promise<any> => {
        const response = await agent.getCallAnalysisAdditionalField.get();
        if (response.status) {
            setCallAnalysisAdditionalFields(response.data)
        }
        return response;
    }

    const GetCallEvaluationParamerApi = async (): Promise<any> => {
        const response = await agent.getCallEvaluationParameter.get();
        if (response.status) {
            setCallEvaluationRecord(response.data)
        }
        return response;
    }

    const GetPhoneNumbersApi = async (): Promise<any> => {
        const response = await agent.getPhoneNumbersList.get();
        if (response.status) {
            setPhoneNumbersData(response.data)
        }
        return response;
    }

    const GetExtensionListApi = async (): Promise<any> => {
        const response = await agent.getExtensionsList.get();
        if (response.status) {
            setExtensionListData(response.data)
        }
        return response;
    }


    //common function to use
    const handleUpdateCallAgent = async (agentId: number) => {
        const additionals = currentAudioAnalysis?.call_record_additional_field ?
            currentAudioAnalysis?.call_record_additional_field.map((fields) => ({
                additional_field_id: fields.call_analysis_additional_field_id,
                additional_field_value: fields.call_record_additional_field_value[0]?.organization_additional_field_option_id
            })) : [];
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: agentId,
            call_category_id: currentAudioAnalysis?.call_category_id || 0,
            call_complexity_id: currentAudioAnalysis?.call_complexity_id || 0,
            call_parent_category_id: currentAudioAnalysis?.call_parent_category_id || 0,
            call_record_additional_field: additionals,
            call_record_evaluation: initialUpdateCallEvaluationScore
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const handleUpdateCallParentCategory = async (categoryParentId: number) => {
        const additionals = currentAudioAnalysis?.call_record_additional_field ?
            currentAudioAnalysis?.call_record_additional_field.map((fields) => ({
                additional_field_id: fields.call_analysis_additional_field_id,
                additional_field_value: fields.call_record_additional_field_value[0]?.organization_additional_field_option_id
            })) : [];
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: currentAudioAnalysis?.agent_id || 0,
            call_category_id: currentAudioAnalysis?.call_category_id || 0,
            call_complexity_id: currentAudioAnalysis?.call_complexity_id || 0,
            call_parent_category_id: categoryParentId,
            call_record_additional_field: additionals,
            call_record_evaluation: initialUpdateCallEvaluationScore
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const handleUpdateCallCategory = async (categoryID: number) => {
        const additionals = currentAudioAnalysis?.call_record_additional_field ?
            currentAudioAnalysis?.call_record_additional_field.map((fields) => ({
                additional_field_id: fields.call_analysis_additional_field_id,
                additional_field_value: fields.call_record_additional_field_value[0]?.organization_additional_field_option_id
            })) : [];
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: currentAudioAnalysis?.agent_id || 0,
            call_category_id: categoryID,
            call_complexity_id: currentAudioAnalysis?.call_complexity_id || 0,
            call_parent_category_id: currentAudioAnalysis?.call_parent_category_id || 0,
            call_record_additional_field: additionals,
            call_record_evaluation: initialUpdateCallEvaluationScore
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const handleUpdateCallComplexity = async (complexityId: number) => {
        const additionals = currentAudioAnalysis?.call_record_additional_field ?
            currentAudioAnalysis?.call_record_additional_field.map((fields) => ({
                additional_field_id: fields.call_analysis_additional_field_id,
                additional_field_value: fields.call_record_additional_field_value[0]?.organization_additional_field_option_id
            })) : [];
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: currentAudioAnalysis?.agent_id || 0,
            call_category_id: currentAudioAnalysis?.call_category_id || 0,
            call_complexity_id: complexityId,
            call_parent_category_id: currentAudioAnalysis?.call_parent_category_id || 0,
            call_record_additional_field: additionals,
            call_record_evaluation: initialUpdateCallEvaluationScore
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const handleUpdateAdditionalFields = async (additional_field_ids: number, additional_field_option_ids: number) => {
        const additionals = [{
            additional_field_id: additional_field_ids,
            additional_field_value: additional_field_option_ids
        }]
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: currentAudioAnalysis?.agent_id || 0,
            call_category_id: currentAudioAnalysis?.call_category_id || 0,
            call_complexity_id: currentAudioAnalysis?.call_complexity_id || 0,
            call_parent_category_id: currentAudioAnalysis?.call_parent_category_id || 0,
            call_record_additional_field: additionals || [],
            call_record_evaluation: initialUpdateCallEvaluationScore
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const handleUpdateCallEvaluationScore = async (callEvaluationPaylod: updateCallEvaluationScore) => {
        const additionals = currentAudioAnalysis?.call_record_additional_field ?
            currentAudioAnalysis?.call_record_additional_field.map((fields) => ({
                additional_field_id: fields.call_analysis_additional_field_id,
                additional_field_value: fields.call_record_additional_field_value[0]?.organization_additional_field_option_id
            })) : [];
        const payload = {
            call_record_id: currentAudioAnalysis?.call_record_id || 0,
            agent_id: currentAudioAnalysis?.agent_id || 0,
            call_category_id: currentAudioAnalysis?.call_category_id || 0,
            call_complexity_id: currentAudioAnalysis?.call_complexity_id || 0,
            call_parent_category_id: currentAudioAnalysis?.call_parent_category_id || 0,
            call_record_additional_field: additionals,
            call_record_evaluation: callEvaluationPaylod
        }
        const respose = await agent.updateCallRecords.put(payload);
        return respose;
    }

    const formatDate = (dateString: Moment | null) => {
        return dateString ? dateString.format('YYYY-MM-DD') : null
    };

    const applyFilters = (filters: any) => {
        // setFilterData(filters)
        setPage(0)
        const searchParams = new URLSearchParams();

        if (filters.start_date) searchParams.append('start_date', filters.start_date);
        if (filters.end_date) searchParams.append('end_date', filters.end_date);
        if (filters.phoneNumber) searchParams.append('phone_number', filters.phoneNumber);
        if (filters.extension) searchParams.append('extension', filters.extension);
        // if (filters.purpose && filters.purpose.length > 0) searchParams.append('call_category_id', filters.purpose[0].call_category_id.toString());
        // if (filters.complexity && filters.complexity.length > 0) searchParams.append('call_complexity_id', filters.complexity[0].call_complexity_id.toString());
        // if (filters.agents && filters.agents.length > 0) searchParams.append('agent_id', filters.agents[0].agent_id.toString());
        if (filters.category && filters.category.length > 0) {
            const categoryIds = filters.category.map((p: parentCategoryNames) => p.call_parent_category_id).join(',');
            searchParams.append('call_parent_category_id', categoryIds);
        }

        if (filters.purpose && filters.purpose.length > 0) {
            const purposeIds = filters.purpose.map((p: categoryNames) => p.call_category_id).join(',');
            searchParams.append('call_category_id', purposeIds);
        }

        if (filters.complexity && filters.complexity.length > 0) {
            const complexityIds = filters.complexity.map((p: complexityLevels) => p.call_complexity_id).join(',');
            searchParams.append('call_complexity_id', complexityIds);
        }

        if (filters.agents && filters.agents.length > 0) {
            const agentsIds = filters.agents.map((p: agentsNames) => p.agent_id).join(',');
            searchParams.append('agent_id', agentsIds);
        }

        if (filters.call_record_additional_field && filters.call_record_additional_field.length > 0) {
            const additionalFieldFilter = JSON.stringify(filters.call_record_additional_field);
            searchParams.append('call_record_additional_field', additionalFieldFilter);
        }

        if (filters.flag_alert) {
            searchParams.append('flag_alert', filters.flag_alert);
        }

        navigate(`?${searchParams.toString()}`);

        const { agents, complexity, start_date, end_date, extension, phoneNumber, category, purpose, call_record_additional_field, flag_alert } = filters;

        const startDate = start_date || null;
        const endDate = end_date || null;
        const phone_number = phoneNumber || null;
        const extensionParam = extension || null;
        // const call_category_id = purpose?.length > 0 ? purpose[0].call_category_id : null;
        // const call_complexity_id = complexity?.length > 0 ? complexity[0].call_complexity_id : null;
        // const agent_id = agents?.length > 0 ? agents[0].agent_id : null;
        const call_parent_category_ids = category?.length > 0 ? category.map((p: parentCategoryNames) => p.call_parent_category_id) : null;
        const call_category_ids = purpose?.length > 0 ? purpose.map((p: categoryNames) => p.call_category_id) : null;
        const call_complexity_id = complexity?.length > 0 ? complexity.map((c: complexityLevels) => c.call_complexity_id) : null;
        const agent_id = agents?.length > 0 ? agents.map((a: agentsNames) => a.agent_id) : null;
        const flagAlert = flag_alert;
        const flag_dashboard_analysis = true;
        const flag_score_in_asc = true;

        if (window.location.pathname === "/callanalysis") {
            GetCallRecordsApi(startDate, endDate, phone_number, extensionParam, call_parent_category_ids, call_category_ids, call_complexity_id, agent_id, call_record_additional_field, flagAlert, flag_dashboard_analysis, flag_score_in_asc)
        }
        else {
            GetCallRecordsApi(startDate, endDate, phone_number, extensionParam, call_parent_category_ids, call_category_ids, call_complexity_id, agent_id, call_record_additional_field, flagAlert)
        }

        if (JSON.stringify(initialFilterData) !== JSON.stringify(filterDate)) {
            setAppliedFilter(true)
        } else {
            setAppliedFilter(false)
        }

        setFlagAddFilter(false)
    };

    const handleFilterData = (field: string, data?: any) => {
        if (!data) {
            if (field === "phoneNumber" || field === "extension") {
                setFilterData({ ...filterDate, [field]: "" })
            }
            else {
                setFilterData({ ...filterDate, [field]: null })
            }
        }
        else {
            if (field === "call_record_additional_field") {
                setFilterData({
                    ...filterDate,
                    [field]: filterDate[field]
                        .map((item: any) => {
                            if (item.additional_field_id === data.additional_field_id) {
                                return {
                                    ...item,
                                    values: item.values.filter(
                                        (val: any) => val.additional_field_value !== data.values[0].additional_field_value
                                    ),
                                };
                            }
                            return item;
                        })
                        .filter((item: any) => item.values.length > 0),
                });
            }
            else if (field === "flag_alert") {
                setFilterData({
                    ...filterDate,
                    [field]: data
                })
            }
            else {
                setFilterData({
                    ...filterDate,
                    [field]: filterDate[field].filter((item: any) => JSON.stringify(item) !== JSON.stringify(data))
                });
            }
            setFlagAnalysisData(false)
        }
    }

    const handleStartEndDate = () => {
        setFilterData({ ...filterDate, start_date: null, end_date: null })
        setStartDate(null)
        setEndDate(null)
    }

    const handleCancel = (flagRemove?: boolean) => {
        setStartDate(null);
        setEndDate(null);
        setPhoneNumberFilter(initialPhoneNumber);
        setExtensionFilter(initialExtension);
        setAgentsListFilter([]);
        setSelectedPurposeCategories([]);
        setSelectedComplexityLevels([]);
        setSelectedAdditionalFieldsFilter([]);
        setParentCategoriesFilter([]);
        setAlertsOnlyFilter(false);
        // setAppliedFilter(false);
        if (flagRemove) {
            setFilterData(initialFilterData)
        }
    };

    const GetCallAnalysisOption = async (): Promise<any> => {
        const response = await agent.getCallAnalysisOption.get();
        if (response.status) {
            setCallAnalysisFeedbackOptions(response.data)
        }
        return response;
    }

    const GetCallAnalysisFeedbackType = async (): Promise<any> => {
        const response = await agent.getCallAnalysisFeedbackType.get();
        if (response.status) {
            setCallAnalysisFeedbackType(response.data)
        }
        return response;
    }

    const CreateCallAnalysisFeedbackApi = async (payload: callAnalysisFeedbackPayload): Promise<any> => {
        const response = await agent.createCallAnalysisFeedback.post(payload);
        return response;
    }

    const payload = {
        GetCallCategoryApi,
        callCategories,
        setCallCategories,

        GetCallCategoryParentApi,
        callParentCategories,
        setCallParentCategories,

        GetCallComplexityApi,
        callComplexity,
        setCallComplexity,

        GetCallAgentsApi,
        callAgentsData,
        setCallAgentsData,

        GetCallRecordsApi,
        callRecordsData,
        setCallRecordsData,

        GetCallAnalysisAdditionalFieldApi,
        callAnalysisAdditionalFields,
        setCallAnalysisAdditionalFields,

        GetCallEvaluationParamerApi,
        callEvaluationRecord,
        setCallEvaluationRecord,

        GetPhoneNumbersApi,
        phoneNumbersData,
        setPhoneNumbersData,

        GetExtensionListApi,
        extensionListData,
        setExtensionListData,

        GetCallRecordDetailApi,

        dashboardAnalysisData,
        setDashboardAnalysisData,

        page,
        setPage,

        timeToScroll,
        setTimeToScroll,

        currentAudioAnalysis,
        setCurrentAudioAnalysis,
        callRecordsDetails,
        setCallRecordsDetails,
        callRecordPagination,
        setCallRecordPagination,
        callCenterDetails,
        setCallCenterDetails,
        flagAddFilter,
        setFlagAddFilter,
        selectedPurposeCategories,
        setSelectedPurposeCategories,
        selectedComplexityLevels,
        setSelectedComplexityLevels,
        selectedAdditionalFieldsFilter,
        setSelectedAdditionalFieldsFilter,
        parentCategoriesFilter,
        setParentCategoriesFilter,
        phoneNumberFilter,
        setPhoneNumberFilter,
        extensionFilter,
        setExtensionFilter,
        agentListsFilter,
        setAgentsListFilter,
        alertsOnlyFilter,
        setAlertsOnlyFilter,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        focusedInput,
        setFocusedInput,
        appliedFilter,
        setAppliedFilter,
        filterDate,
        setFilterData,
        waveLoading,
        setWaveLoading,
        initialLoad,
        setInitialLoad,
        addNewAgentFlag,
        setAddNewAgentFlag,
        updateAgentFlag,
        setUpdateAgentFlag,
        viewRecordDetail,
        setViewRecordDetail,
        updateAgentPayload,
        setUpdateAgentPayload,
        handleUpdateCallAgent,
        handleUpdateCallParentCategory,
        handleUpdateCallCategory,
        handleUpdateCallComplexity,
        handleUpdateAdditionalFields,
        handleUpdateCallEvaluationScore,
        formatDate,
        applyFilters,
        handleFilterData,
        handleStartEndDate,
        handleCancel,
        flagAnalysisData,
        setFlagAnalysisData,
        additinalFieldValue,
        setAdditionalFieldValue,
        parentCategoryId,
        setParentCategoryId,
        flagReportProblem,
        setFlagReportProblem,
        GetCallAnalysisOption,
        callAnalysisFeedbackOptions,
        setCallAnalysisFeedbackOptions,
        GetCallAnalysisFeedbackType,
        callAnalysisFeedbackType,
        setCallAnalysisFeedbackType,
        CreateCallAnalysisFeedbackApi,
    };

    return (
        <CallAnalysisContext.Provider value={payload}>
            {children}
        </CallAnalysisContext.Provider>
    );
};

export { CallAnalysisContext, CallAnalysisProvider };