import { Grid, Typography } from '@mui/material'
import React from 'react'
import styles from '../../../assets/styles/componentStyles/carouselCard.module.scss'
import IconsColor from '../iconsColor';

export const AmenitiesCardCarousel = (props: any) => {
    const { image, handleMouseDown, handleMouseUp } = props;

    return (
        <Grid container>
            <Grid item xs={12} sx={{ padding: '0px' }}>
                <Grid container>
                    {image.additional_information &&
                        <Grid item xs={12} sx={{ margin: '5px 0px 0px', padding: '10px 9px 10px', width: "100%", borderBottom: "1px solid #EAEAEA" }}>
                            <Grid container direction={"column"} alignItems={"start"}>
                                <Grid item>
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontSize: "14px !important", fontWeight: "600 !important", color: "#000000 !important", overflow: "hidden !important", textAlign: "left !important",
                                            textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.6px"
                                        }}
                                    >
                                        {String(Object.values(image.additional_information)[0])}
                                    </Typography>
                                </Grid>
                                <Grid item mt={"4px"}>
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontSize: "11px !important", fontWeight: "500 !important", color: "#rgba(51,51,51) !important", overflow: "hidden !important", textAlign: "left !important",
                                            textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.6px"
                                        }}
                                    >
                                        {String(Object.values(image.additional_information)[1])}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {image.image_url &&
                        <Grid item xs={12} className={styles.chat_crl_img_item} sx={{marginBottom:"10px !important", borderBottom: "1px solid #EAEAEA"}}>
                            {/* <LazyLoad style={{ height: "100%", width: "100%" }}> */}
                            <img
                                onMouseDown={handleMouseDown}
                                onMouseUp={(event) => handleMouseUp(event, image)}
                                src={image.image_url || "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"}
                                alt=""
                                style={{ borderRadius: "0px", cursor: "pointer", width: '100%', height: '100%', maxHeight: '100%', objectFit: "cover", objectPosition: "top" }}
                            />
                            {/* </LazyLoad> */}
                        </Grid>
                    }

                    {image.additional_information &&
                        <Grid item xs={12} sx={{ padding: '0px 0px 10px 10px', width: "100%" }}>
                            <Grid container direction={"column"} alignItems={"start"}>
                                <Grid item>
                                    <Grid container className={image.image_url ? styles.amenities_grid_style : styles.amenities_grid_content_style} justifyContent={"center"} gap={"6px"} alignItems={"center"}>
                                        {Array.isArray(Object.values(image.additional_information)[2]) &&
                                            //@ts-ignore
                                            Object.values(image.additional_information)[2].map((info, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <Grid container flexWrap={"nowrap"} direction={"row"} alignItems={"center"} gap={1}>
                                                        <Grid item sx={{ boxShadow: '0px 0px 2px rgba(0,0,0,0.3)', borderRadius: "50%", padding: "6px" }}>
                                                            <img src={info.image_url} height={"20"} width={"20"} />
                                                        </Grid>
                                                        <Grid item>
                                                            <span
                                                                style={{ fontSize: "12px", color: info?.flag_allow_action ? "#064EB9" : "#000000", fontWeight: "500", cursor: info?.flag_allow_action ? "pointer" : "auto" }}
                                                            >
                                                                {info.text_value}
                                                            </span>
                                                            {info?.flag_allow_action &&
                                                                <span style={{ marginLeft: "8px", verticalAlign: "middle" }}>
                                                                    <IconsColor iconName="forwardArrow" width="12" height="12" fillColor={"#064EB9"} />
                                                                </span>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}