import { Divider, Grid, TextField, Typography } from '@mui/material'
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import React, { useContext, useState } from 'react'
import GlobalButton from '../../utility/globalButton/button';
import { StringChain } from 'lodash';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';

export interface AddNewListPopupProps {
    handleAddNewListSaveButtonClick: (name: string, id?: number) => void;
    flagEditList: boolean,
    requiredList?: any,
    setFlagEditListDialog?: React.Dispatch<React.SetStateAction<boolean>>,
}

const AddNewListPopup: React.FC<AddNewListPopupProps> = ({ handleAddNewListSaveButtonClick, flagEditList, requiredList, setFlagEditListDialog }) => {

    const { setFlagAddNewListDialog } = useContext(ContactContext) as ContactContextPayload;

    const [listNameValue, setListNameValue] = useState<string>(requiredList ? requiredList.contact_list_name : "");

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}> {flagEditList ? "Edit List" : "Add New List"}</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={style.popupBodyGridContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>List Name <span style={{ color: "#C60000" }}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={listNameValue}
                                                onChange={(e) => { setListNameValue(e.target.value) }}
                                                placeholder='Enter List Name'
                                                className={`${style.campaignContactPopupTextField}`}
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"} sx={{ padding: "20px 0" }}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Cancel"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='borderedButtonStyle'
                                        onClick={() => {
                                            setFlagAddNewListDialog(false);
                                            if (setFlagEditListDialog) {
                                                setFlagEditListDialog(false);
                                            }
                                        }}
                                    >
                                    </GlobalButton>
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText={flagEditList ? "Save" : "Add"}
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        disabled={listNameValue.trim().length === 0}
                                        onClick={() => {
                                            setFlagAddNewListDialog(false);
                                            if (requiredList && flagEditList) {
                                                handleAddNewListSaveButtonClick(listNameValue, requiredList.contact_list_id)
                                            } else {
                                                handleAddNewListSaveButtonClick(listNameValue);
                                            }
                                        }}
                                    >
                                    </GlobalButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddNewListPopup
