import * as React from 'react';
import styles from "../../../assets/styles/componentStyles/button.module.scss"
import TextField, { TextFieldProps } from '@mui/material/TextField';
import globalStyles from "../../assets/styles/global.module.scss"


export default function GlobalTextField(props: TextFieldProps) {


  return (
    <TextField
      dir="auto"
      autoFocus={props.autoFocus}
      sx={props.sx}
      required={props.required}
      type={props.type}
      // className={props.className}
      className={`${globalStyles.globalTextfieldStyle} ${props.className}`}
      fullWidth={props.fullWidth}
      id={props.id}
      label={props.label}
      name={props.name}
      autoComplete={props.autoComplete}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      error={props.error} // Apply error styling if user_email is invalid
      helperText={props.helperText}
      InputProps={props.InputProps}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      rows={props.rows}
      multiline={props.multiline}
      onFocus={props.onFocus}

    />
  )
}
