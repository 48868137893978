import { Button, ClickAwayListener, Divider, Grid, IconButton, List, ListItem, Paper, Popper, Switch, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate } from 'react-router-dom';
import globalStyles from "../../../assets/styles/global.module.scss";
import chatbotStyles from "../../../assets/styles/componentStyles/chatbotList.module.scss";
import individualAvatar from "../../../assets/chatbotProfileAvtar.png";
import styles from "../../../assets/styles/componentStyles/template.module.scss";
import AddIcon from '@mui/icons-material/Add';
import agent from '../../../services/api';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import deleteIcon from '../../../assets/deleteIcon.svg'
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import noDataFound from '../../../assets/CampaignNoDataFound.png'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import GlobalPopUp from '../../utility/globalPopUp';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';
import IconsColor from '../../utility/iconsColor';
import ErrorIcon from '@mui/icons-material/Error';
import EmptyState from '../../global/emptyState';
import ChatbotEmptyStateIcon from "../../../assets/images/chatbot/chatbotEmptyState.png";
import GlobalButton from '../../utility/globalButton/button';
import EastIcon from '@mui/icons-material/East';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import slackIcon from './../../../assets/images/channels/slack/slack (3).svg'
import calendlyIcon from './../../../assets/images/channels/calendly/calendarIcon.svg'
import whatsappIcon from './../../../assets/images/chatbot/whatsApp/Whatsapp.svg'
import facebookIcon from './../../../assets/images/channels/facebook/facebookMessenger.svg'
import instagramIcon from './../../../assets/images/channels/instagram/instagram.png'

interface sampleList {
    sampleChatbots?: any
}

const ChatbotList = (props: sampleList) => {
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)')
    const innerHeight = flagSmallScreen ? (window.innerHeight - 230) : (window.innerHeight - 190);

    const navigate = useNavigate()
    const { userDetail, setFlagLoading, setError, error, flagLoading, success, setSuccess, setShowLoginPopup, showLoginPopup, setFlagShowCreditsExhausted, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotListApi, setChatBotDetails, chatbotList, setChatbotList } = useContext(ChatbotContext) as ChatbotContextPayload
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openUpgradationpopup, setUpgradationpopup] = useState(false)
    const [deleteChatBotDetails, setDeleteChatBotDetails] = useState({
        chatbot_id: '',
        chatbot_name: ''
    })
    const [chatbotOriginalDetails, setChatbotOriginalDetails] = useState<any | null>(null);
    const [askUserToSigninPopupData, setAskUserToSigninPopupData] = useState({
        heading: "Welcome to our exclusive Chatbot feature, reserved for our premium users! 🌟 As a valued member of our community, we invite you to experience the full power of our Chatbot capabilities to create and customize your own chatbot with a personalized knowledge base..",
        subHeading: "   Sign in now to claim your 10-day free premium trial and gain instant access to the Spell Checker tab. With premium access, you'll enjoy error-free writing, enhanced grammar suggestions, and a seamless writing experience.",
        content: "Don't miss out on this opportunity to elevate your writing to new heights. Join our premium community today and start perfecting your content effortlessly!"
    })
    const [flagSampleChatbot, setFlagSampleChatbot] = useState(false);
    const [openMenu, setOpenMenu] = useState(null)
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const handleClose = () => {
        setOpenMenu(null)
    }
    useEffect(() => {
        if (showLoginPopup) {

        } else {
            if (userDetail.flagUser) {
                if (userDetail.flag_user_anonymous) {
                    setUpgradationpopup(true)
                } else {
                    if (userDetail.user_role && (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin")) {
                    } else {
                        if (userDetail.plan_detail && userDetail?.plan_detail?.credit_usage_data && userDetail?.plan_detail?.credit_usage_data != null && userDetail.plan_detail.credit_usage_data != null && (userDetail?.plan_detail?.credit_usage_data?.stripe_product_name == null || userDetail?.plan_detail?.credit_usage_data?.stripe_product_name === "Free")) {
                            setAskUserToSigninPopupData({
                                heading: "Welcome to our exclusive Chatbot feature, reserved for our premium users! 🌟 As a valued member of our community, we invite you to experience the full power of our Chatbot capabilities to create and customize your own chatbot with a personalized knowledge base..",
                                subHeading: "Sign in now to claim your 10-day free premium trial and gain instant access to the Spell Checker tab. With premium access, you'll enjoy error-free writing, enhanced grammar suggestions, and a seamless writing experience.",
                                content: "Don't miss out on this opportunity to elevate your writing to new heights. Join our premium community today and start perfecting your content effortlessly!"
                            })
                            setUpgradationpopup(true)
                        }
                    }
                }
            } else {
                setUpgradationpopup(true)
            }
        }
    }, [userDetail, showLoginPopup])
    useEffect(() => {
        if (!props.sampleChatbots) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                setChatBotDetails(null)
                let chatBotResponse = await GetChatbotListApi()
                if (chatBotResponse?.status) {
                    if (chatBotResponse?.data?.length) {
                        setChatbotOriginalDetails(chatBotResponse.data)
                    } else {
                        setChatbotOriginalDetails(null)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    }
                } else {
                    setChatbotList(null)
                    setChatbotOriginalDetails(null)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    let errorCode = checkForErrorCode(chatBotResponse)
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                }
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            })()
        }
    }, [])

    // const handleViewChatbot = async (chatbot_id: number) => {
    //     if (userDetail.flagUser) {
    //         if (userDetail.flag_user_anonymous) {
    //             setUpgradationpopup(true)
    //         } else {
    //             if (userDetail.user_role && (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin")) {
    //                 navigate(`/chatbot/viewChatbot?chatbot_id=${chatbot_id}`)
    //             } else {
    //                 if (userDetail.plan_detail && userDetail.plan_detail.credit_usage_data != null && userDetail.plan_detail.credit_usage_data != null) {
    //                     if ((userDetail.plan_detail.credit_usage_data.stripe_product_name == null || userDetail.plan_detail.credit_usage_data.stripe_product_name === "Free")) {
    //                         setAskUserToSigninPopupData({
    //                             heading: "Welcome to our exclusive Chatbot feature, reserved for our premium users! 🌟 As a valued member of our community, we invite you to experience the full power of our Chatbot capabilities to create and customize your own chatbot with a personalized knowledge base..",
    //                             subHeading: "Sign in now to claim your 10-day free premium trial and gain instant access to the Spell Checker tab. With premium access, you'll enjoy error-free writing, enhanced grammar suggestions, and a seamless writing experience.",
    //                             content: "Don't miss out on this opportunity to elevate your writing to new heights. Join our premium community today and start perfecting your content effortlessly!"
    //                         })
    //                         setUpgradationpopup(true)
    //                     } else {
    //                         navigate(`/chatbot/viewChatbot?chatbot_id=${chatbot_id}`)
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         setUpgradationpopup(true)
    //     }

    // }
    const handleViewChatbot = async (chatbot_id: number) => {

        navigate(`/chatbot/viewChatbot?chatbot_id=${chatbot_id}`)

    }
    const handleDeleteBot = async (id: any) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbot.delete(id)
        if (response.status) {
            // let chatbotResponse = await GetChatbotListApi()
            if (response?.status) {
                if (response.data.length) {
                    setChatbotList(response.data)
                    setChatbotOriginalDetails(response.data)
                } else {
                    setChatbotList(null)
                    setChatbotOriginalDetails(null)
                }
            } else {
                setChatbotList(null)
                setChatbotOriginalDetails(null)
            }
            setOpenDeletePopup(false)
            setSuccess({ ...success, flagShowSuccess: true, message: "Chatbot Deleted Successfully!" })
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else if (response.message == "canceled") {
                }
                else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    };

    const handleSampleChatbots = () => {
        setFlagSampleChatbot(true);
    }

    const handleSearch = (event: any) => {
        const query = event?.target?.value?.toLowerCase()
        if (chatbotList != null) {
            const chatbotFilterData = chatbotList.filter((chatbot: any) => {
                return (
                    chatbot.chatbot_name !== null && chatbot?.chatbot_name?.toLowerCase().includes(query)
                    // chatbot.chatbot_discription !== null && chatbot?.chatbot_discription?.toLowerCase().includes(query)

                )
            })
            setChatbotOriginalDetails(chatbotFilterData)
        }
    }
    // const onClickCont = () => {
    //     if (!userDetail.flagUser) {
    //         setUpgradationpopup(false);
    //         setShowLoginPopup(true)
    //     } else {
    //         if (userDetail.flag_user_anonymous) {
    //             setUpgradationpopup(false);
    //             setShowLoginPopup(true)
    //         } else {
    //             if (userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name === null || userDetail.plan_detail?.credit_usage_data.stripe_product_name === "Free")) {
    //                 setUpgradationpopup(false);
    //                 navigate("/settings-plansAndBilling")
    //             }
    //         }
    //     }
    // }
    const handleCreateChatbot = () => {
        navigate("/createChatbot")
    }
    const handleUpdateFlagInternal = async (chatbotDetails: any, flagValue: string, value: any) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let flagPublic = chatbotDetails.chatbot_flag_public;
        let flagInternal = chatbotDetails.flag_internal_only
        if (flagValue == 'flagInternal') {
            flagInternal = value;
            if (value == true) {
                flagPublic = !value
            }
        } else {
            flagPublic = value;
            if (value == true) {
                flagInternal = !value
            }
        }
        let payload = {
            chatbot_id: chatbotDetails.chatbot_id,
            chatbot_flag_public: flagPublic,
            flag_internal_only: flagInternal
        }
        const response = await agent.updateFlagInternal.put(payload)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Chatbot updated successfully!" }))
            GetChatbotListApi()
        } else {
            let errorCode = checkForErrorCode(response);
            setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    // const handleCreateChatbot = () => {
    //     if (userDetail.flagUser) {
    //         if (userDetail.flag_user_anonymous) {
    //             setUpgradationpopup(true)
    //         } else {
    //             if (userDetail.user_role && (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin")) {
    //                 navigate("/createChatbot")
    //             } else {
    //                 if (userDetail.plan_detail && userDetail.plan_detail.credit_usage_data != null && userDetail.plan_detail.credit_usage_data != null) {
    //                     if ((userDetail.plan_detail.credit_usage_data.stripe_product_name == null || userDetail.plan_detail.credit_usage_data.stripe_product_name === "Free")) {
    //                         setAskUserToSigninPopupData({
    //                             heading: "Welcome to our exclusive Chatbot feature, reserved for our premium users! 🌟 As a valued member of our community, we invite you to experience the full power of our Chatbot capabilities to create and customize your own chatbot with a personalized knowledge base..",
    //                             subHeading: "Sign in now to claim your 10-day free premium trial and gain instant access to the Spell Checker tab. With premium access, you'll enjoy error-free writing, enhanced grammar suggestions, and a seamless writing experience.",
    //                             content: "Don't miss out on this opportunity to elevate your writing to new heights. Join our premium community today and start perfecting your content effortlessly!"
    //                         })
    //                         setUpgradationpopup(true)
    //                     } else {
    //                         navigate("/createChatbot")
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         setUpgradationpopup(true)
    //     }
    // }

    const handleCheckIntegration = (integration: string) => {
        let integrationName = integration.toLowerCase();

        switch (integrationName) {
            case "slack":
                return <img src={slackIcon} height={"18"} width={"18"} />;
            case "calendly":
                return <IconsColor iconName="calendly" height={"18"} width={"18"} />;
            case "whatsapp":
                return <img src={whatsappIcon} height={"20"} width={"20"} />;
            case "facebook":
                return <img src={facebookIcon} height={"16"} width={"16"} />;
            case "instagram":
                return <img src={instagramIcon} height={"16"} width={"16"} />;
            default:
                return <img src={individualAvatar} height={"16"} width={"16"} />;
        }
    }

    return (
        <>
            {/* {openUpgradationpopup &&
                <GlobalPopUp paperMinWidth="400px" borderRadius='12px' flagShowCloseIcon={true} flagBackdrop={false} open={true} handleClose={() => { setUpgradationpopup(false) }}>
                    <PlanHasExhausted />
                    <UpgradePersonaPopup
                        heading="Welcome to our exclusive Chatbot feature, reserved for our premium users! 🌟 As a valued member of our community, we invite you to experience the full power of our Chatbot capabilities to create and customize your own chatbot with a personalized knowledge base.."
                        subHeading="   Sign in now to claim your 10-day free premium trial and gain instant access to the Spell Checker tab. With premium access, you'll enjoy error-free writing, enhanced grammar suggestions, and a seamless writing experience."
                        content="Don't miss out on this opportunity to elevate your writing to new heights. Join our premium community today and start perfecting your content effortlessly!"
                        onClickCont={onClickCont}
                    />
                </GlobalPopUp>} */}
            {openDeletePopup && (
                <GlobalDeletePopup
                    deleteDescription={`Are you sure want to delete ${deleteChatBotDetails.chatbot_name} chatbot`}
                    open={openDeletePopup}
                    onClose={() => {
                        setOpenDeletePopup(false)
                    }}
                    onDelete={() => {
                        handleDeleteBot(deleteChatBotDetails.chatbot_id)
                        setOpenDeletePopup(false)
                    }}
                />
            )
            }

            {flagSampleChatbot &&
                <GlobalPopUp
                    titlePadding={flagSmallScreen ? "40px 25px 20px" : "50px 40px 20px"}
                    paperMinWidth={flagSmallScreen ? "92%" : "85%"}
                    paperMaxwidth={flagSmallScreen ? "92%" : "85%"}
                    height={"100%"}
                    open={flagSampleChatbot}
                    onClose={() => { setFlagSampleChatbot(false) }}
                    title={"Sample Chatbots"}
                    flagShowCloseIcon={true}
                    titleAlignment={"start"}
                    handleClose={() => { setFlagSampleChatbot(false) }}
                // className={styles.newDocumentPopup}
                >
                    <Grid item>
                        <ChatbotList sampleChatbots={true} />
                    </Grid>
                </GlobalPopUp>
            }


            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                <Grid container sx={{ padding: props.sampleChatbots ? "0px 0px 0px 30px" : "20px 22px 25px 27px" }} className={styles.chatbotGrid}>
                    {props.sampleChatbots ?
                        <>
                            <Grid item className={styles.sampleChatbotsGrid}>
                                <Grid container>
                                    <>
                                        {chatbotList && chatbotList?.filter((chatbot: any) => chatbot.chatbot_flag_public === true).length > 0 &&
                                            chatbotList?.filter((chatbot: any) => chatbot.chatbot_flag_public === true).map((bot: any, index: number) => (
                                                <>
                                                    {/* <GlobalChatbotTooltip option={bot} key={bot.chatbot_id} placement={"right-start"}> */}
                                                    <Grid item xl={2.3} lg={3} md={4} sm={12} xs={12}
                                                        sx={{ cursor: "pointer", position: "relative" }}
                                                        className={chatbotStyles.personaCreatedList}
                                                        onClick={() => { handleViewChatbot(bot.chatbot_id) }}
                                                    >
                                                        <Popper
                                                            key={index}
                                                            anchorEl={anchorEl}
                                                            onClick={handleClose}
                                                            open={openMenu == bot.chatbot_id}
                                                            id="composition-menu"
                                                            aria-labelledby="composition-button"
                                                            sx={{ zIndex: "99999" }}
                                                        >
                                                            <Paper key={index} sx={{ zIndex: "99999" }}>
                                                                <ClickAwayListener onClickAway={handleClose}>
                                                                    <List>
                                                                        <ListItem
                                                                            key={index}
                                                                            onClick={(e) => { e.stopPropagation() }}>
                                                                            <Grid container gap="20px" justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Grid item className={globalStyles.displayFlex}>Flag Public</Grid>
                                                                                <Grid item><Switch checked={bot.chatbot_flag_public} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleUpdateFlagInternal(bot, 'flagPublic', event.target.checked) }} /></Grid>
                                                                            </Grid>
                                                                        </ListItem>
                                                                        <ListItem
                                                                            key={index}
                                                                            onClick={(e) => { e.stopPropagation(); console.log(bot, "bot---") }}>
                                                                            <Grid container gap="20px" justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Grid item className={globalStyles.displayFlex}>Flag Internal</Grid>
                                                                                <Grid item><Switch checked={bot.flag_internal_only} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleUpdateFlagInternal(bot, 'flagInternal', event.target.checked) }} /></Grid>
                                                                            </Grid>
                                                                        </ListItem>
                                                                    </List>
                                                                </ClickAwayListener>
                                                            </Paper>

                                                        </Popper>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid container sx={{ justifyContent: "space-between", alignItems: "start" }}>

                                                                    <Grid item className={chatbotStyles.chatbotProfileAvtarItem}
                                                                        sx={{
                                                                            background: bot?.chatbot_logo_id ? "transparent" : "#FAF0FF",
                                                                        }}
                                                                    >
                                                                        {/* <LazyLoadingImage src={bot?.chatbot_logo_url ? bot.chatbot_logo_url : individualAvatar} className={bot?.chatbot_logo_id ? chatbotStyles.chatbot_icon : chatbotStyles.default_icon} flagShow /> */}
                                                                        <img src={bot?.chatbot_logo_url ? bot.chatbot_logo_url : individualAvatar} alt="chatbot Icon" className={bot?.chatbot_logo_id ? chatbotStyles.chatbot_icon : chatbotStyles.default_icon} />

                                                                        {/* <img src={bot?.chatbot_logo_id ? `${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${bot.chatbot_logo_id}&flag_info=false` : individualAvatar} alt="chatbot Icon" style={{ width: bot?.chatbot_logo_id ? "44px" : "30px", height: bot?.chatbot_logo_id ? "44px" : "30px", borderRadius: "50%" }} /> */}
                                                                        {/* <CardMedia
                                                                        component="img"
                                                                        sx={{ width: "30px", height: "30px", objectFit: "none" }}
                                                                        className={chatbotStyles.avatarImage}
                                                                        image={individualAvatar}
                                                                        alt="icon"
                                                                        /> */}
                                                                    </Grid>

                                                                    <Grid item className={chatbotStyles.chatbotDeleteIconItem}>
                                                                        <Grid container gap="5px" sx={{ height: "24px" }}>
                                                                            {isDev(userDetail) && bot.flag_internal_only &&
                                                                                <Grid item className={globalStyles.displayFlex}>
                                                                                    <Tooltip title={"Flag Internal true"}>
                                                                                        <ErrorIcon sx={{ color: "#CA0035", fontSize: "22px !important" }} />
                                                                                    </Tooltip>
                                                                                </Grid>}
                                                                            {isDev(userDetail) &&
                                                                                <IconButton sx={{ padding: "0px !important" }} onClick={(e) => { e.stopPropagation(); setOpenMenu(bot.chatbot_id); setAnchorEl(e.currentTarget) }}>
                                                                                    <IconsColor iconName="settings" fillColor="#7A7A7A" width="18" height="18" />
                                                                                </IconButton>}
                                                                            {bot.chatbot_flag_edit &&
                                                                                <Grid item className={globalStyles.displayFlex}>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        sx={{ padding: "0px 5px !important" }}
                                                                                        // sx={{ display: hoveredCard === bot.chatbot_id ? 'block !important' : 'none !important', }}
                                                                                        onClick={(e: any) => { e.stopPropagation(); setDeleteChatBotDetails(bot); setOpenDeletePopup(true) }}
                                                                                    >
                                                                                        <img src={deleteIcon} alt="delete" className={globalStyles.cardDeleteIcon} />
                                                                                    </IconButton>
                                                                                </Grid>}
                                                                            {bot.chatbot_flag_public &&
                                                                                <Grid item sx={{ width: "auto" }} marginLeft={1}>
                                                                                    <Grid item sx={{ background: "rgba(10, 106, 221, 0.1)", color: "#0A6ADD", padding: "7px 10px", fontSize: "13px", fontWeight: "500", boxShadow: "0px 0px 2px 0px rgba(10, 106, 221, 0.5)", borderRadius: "5px" }}>
                                                                                        Sample Chatbot
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }

                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} className={chatbotStyles.chatbotNameItem}>
                                                                <Typography component={'span'} className={`${chatbotStyles.chatbotHeadText} ${globalStyles.cardHeaderFontSize}`}>{bot.chatbot_name}</Typography>
                                                            </Grid>
                                                            <Grid item sx={{ marginTop: "15px" }}>
                                                                <GlobalButton endIcon={<EastIcon sx={{ color: "#fff" }} />} buttonText={"Try it"} sx={{ fontSize: "13px !important" }} className='primaryButtonStyle' onClick={() => { handleViewChatbot(bot.chatbot_id) }} />
                                                            </Grid>
                                                            {/* <Grid item xs={12} className={chatbotStyles.chatbotDescItem}>
                                                                <Typography component={'span'} className={`${chatbotStyles.chatbotDescText} ${globalStyles.cardDescriptionFontSize}`}>
                                                                    {bot.chatbot_discription !== null ?
                                                                        (bot.chatbot_discription.length < 35 ? bot.chatbot_discription : bot.chatbot_discription.substring(0, 35) + "...") :
                                                                        (bot.chatbot_discription)
                                                                    }
                                                                </Typography>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    {/* </GlobalChatbotTooltip> */}
                                                </>
                                            ))
                                        }
                                    </>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12}>
                                <Grid container sx={{ alignItems: "center" }}>
                                    <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                                        <GlobalPageHeaderText
                                            mainHeadText={"AI Chatbot"}
                                            flagMainTextOnly
                                            // mainSubText={"Create chatbot for your data that matches your brand’s personality"}
                                            actionBlock={<>  <Grid item >
                                                <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }} flexWrap={"nowrap"}>
                                                    {(chatbotList == null || chatbotList == undefined) ?
                                                        (<> </>) : (
                                                            <>
                                                                {/* <Grid item className={`${globalStyles.globalHeadLeftSubItem}`}>
                                                                    <Grid container>
                                                                        <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                                            <GlobalSearch
                                                                                fullWidth
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                                                                size="small"
                                                                                placeholder="Search"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> */}
                                                                <Grid item
                                                                    className={`
                                                                 ${styles.headRightSubItem}`}>
                                                                    <Grid container sx={{ alignItems: "start" }}>
                                                                        <Grid item
                                                                        >
                                                                            <Button
                                                                                variant='outlined'
                                                                                size='large'
                                                                                fullWidth
                                                                                className={globalStyles.sampleChatbotBtn}
                                                                                onClick={() => { handleSampleChatbots() }}
                                                                                endIcon={<EastIcon sx={{ color: '#6A097D' }} />}
                                                                            >
                                                                                Try Sample Chatbot
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item
                                                                >
                                                                    <Grid container sx={{ alignItems: "center" }}>
                                                                        <Grid item className={`
                                                                    ${styles.addChatbotBtn}`}>
                                                                            <Button
                                                                                variant='outlined'
                                                                                size='large'
                                                                                sx={{ width: "178px" }}
                                                                                fullWidth
                                                                                startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                                                                className={globalStyles.addDocumentBtn}
                                                                                onClick={() => { handleCreateChatbot() }}
                                                                            >
                                                                                Create Chatbot
                                                                            </Button>
                                                                        </Grid>
                                                                        <IconButton
                                                                            className={`${globalStyles.cmpGlobalMobileAddIcon} ${styles.addChatbotIcon}`}
                                                                            onClick={() => { handleCreateChatbot() }}
                                                                        >
                                                                            <AddIcon style={{ height: "28px", width: 'auto' }} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid></>}
                                        />
                                    </Grid>

                                    <Divider className={`${globalStyles.dividerStyles} ${styles.dividerStyle}`} sx={{ paddingTop: "6px !important" }} />
                                </Grid>
                            </Grid>

                            {/* <Grid item className={`${globalStyles.globalHeadLeftSubItem}`}> */}
                            <Grid item sx={{ width: "100%" }}>
                                <Grid container mt={"10px"} justifyContent={{ xs: "start", sm: "end" }}>
                                    <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                        <GlobalSearch
                                            fullWidth
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                            size="small"
                                            placeholder="Search"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={styles.listsMainContainer} mt={2} sx={{ maxHeight: `${innerHeight}px !important` }}>
                                <Grid container>
                                    <>
                                        {chatbotOriginalDetails && chatbotOriginalDetails?.filter((chatbot: any) => chatbot.chatbot_flag_public === false).length > 0 ? (
                                            chatbotOriginalDetails?.filter((chatbot: any) => chatbot.chatbot_flag_public === false).map((bot: any, index: number) => (
                                                <>
                                                    {/* <GlobalChatbotTooltip option={bot} key={bot.chatbot_id} placement={"right-start"}> */}
                                                    <Grid item xl={2.3} lg={3} md={4} sm={12} xs={12}
                                                        sx={{ cursor: "pointer", position: "relative" }}
                                                        className={chatbotStyles.personaCreatedList}
                                                        onClick={() => { handleViewChatbot(bot.chatbot_id) }}
                                                    >
                                                        <Popper
                                                            key={index}
                                                            anchorEl={anchorEl}
                                                            onClick={handleClose}
                                                            open={openMenu == bot.chatbot_id}
                                                            id="composition-menu"
                                                            aria-labelledby="composition-button"
                                                        >
                                                            <Paper key={index}>
                                                                <ClickAwayListener onClickAway={handleClose}>
                                                                    <List>
                                                                        <ListItem
                                                                            key={index}
                                                                            onClick={(e) => { e.stopPropagation() }}>
                                                                            <Grid container gap="20px" justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Grid item className={globalStyles.displayFlex}>Flag Public</Grid>
                                                                                <Grid item><Switch checked={bot.chatbot_flag_public} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleUpdateFlagInternal(bot, 'flagPublic', event.target.checked) }} /></Grid>
                                                                            </Grid>
                                                                        </ListItem>
                                                                        <ListItem
                                                                            key={index}
                                                                            onClick={(e) => { e.stopPropagation(); console.log(bot, "bot---") }}>
                                                                            <Grid container gap="20px" justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Grid item className={globalStyles.displayFlex}>Flag Internal</Grid>
                                                                                <Grid item><Switch checked={bot.flag_internal_only} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleUpdateFlagInternal(bot, 'flagInternal', event.target.checked) }} /></Grid>
                                                                            </Grid>
                                                                        </ListItem>
                                                                    </List>
                                                                </ClickAwayListener>
                                                            </Paper>

                                                        </Popper>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid container sx={{ justifyContent: "space-between", alignItems: "start" }}>
                                                                    <Grid item className={chatbotStyles.chatbotProfileAvtarItem}
                                                                        sx={{
                                                                            background: bot?.chatbot_logo_id ? "transparent" : "#FAF0FF"
                                                                        }}
                                                                    >
                                                                        {/* <LazyLoadingImage src={bot?.chatbot_logo_url ? bot.chatbot_logo_url : individualAvatar} className={bot?.chatbot_logo_id ? chatbotStyles.chatbot_icon : chatbotStyles.default_icon} flagShow /> */}
                                                                        <img src={bot?.chatbot_logo_url ? bot.chatbot_logo_url : individualAvatar} alt="chatbot Icon" className={bot?.chatbot_logo_id ? chatbotStyles.chatbot_icon : chatbotStyles.default_icon} />

                                                                        {/* <img src={bot?.chatbot_logo_id ? `${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${bot.chatbot_logo_id}&flag_info=false` : individualAvatar} alt="chatbot Icon" style={{ width: bot?.chatbot_logo_id ? "44px" : "30px", height: bot?.chatbot_logo_id ? "44px" : "30px", borderRadius: "50%" }} /> */}
                                                                        {/* <CardMedia
                                                                        component="img"
                                                                        sx={{ width: "30px", height: "30px", objectFit: "none" }}
                                                                        className={chatbotStyles.avatarImage}
                                                                        image={individualAvatar}
                                                                        alt="icon"
                                                                    /> */}
                                                                    </Grid>
                                                                    <Grid item className={chatbotStyles.chatbotDeleteIconItem}>
                                                                        <Grid container gap="5px" sx={{ height: "24px" }} alignItems={"center"}>
                                                                            {bot.integrations && bot.integrations.length > 0 &&
                                                                                <Grid item
                                                                                    marginRight={"2px"}
                                                                                >
                                                                                    <Grid container gap={"6px"}>
                                                                                        {bot.integrations.map((integ: any) =>
                                                                                            <IconButton sx={{ padding: "0px !important" }}
                                                                                            //  onClick={(e) => { e.stopPropagation(); setOpenMenu(bot.chatbot_id); setAnchorEl(e.currentTarget) }}
                                                                                            >
                                                                                                {handleCheckIntegration(integ)}
                                                                                                {/* <IconsColor iconName="settings" fillColor="#7A7A7A" width="18" height="18" /> */}
                                                                                            </IconButton>
                                                                                            // <Grid item sx={{display: "flex"}}>
                                                                                            //     {handleCheckIntegration(integ)}
                                                                                            // </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                            {isDev(userDetail) && bot.flag_internal_only &&
                                                                                <Grid item className={globalStyles.displayFlex}>
                                                                                    <Tooltip title={"Flag Internal true"}>
                                                                                        <ErrorIcon sx={{ color: "#CA0035", fontSize: "22px !important" }} />
                                                                                    </Tooltip>
                                                                                </Grid>}
                                                                            {isDev(userDetail) &&
                                                                                <IconButton sx={{ padding: "0px !important" }} onClick={(e) => { e.stopPropagation(); setOpenMenu(bot.chatbot_id); setAnchorEl(e.currentTarget) }}>
                                                                                    <IconsColor iconName="settings" fillColor="#7A7A7A" width="18" height="18" />
                                                                                </IconButton>}
                                                                            {bot.chatbot_flag_edit &&
                                                                                <Grid item className={globalStyles.displayFlex}>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        sx={{ padding: "0px 5px !important" }}
                                                                                        // sx={{ display: hoveredCard === bot.chatbot_id ? 'block !important' : 'none !important', }}
                                                                                        onClick={(e: any) => { e.stopPropagation(); setDeleteChatBotDetails(bot); setOpenDeletePopup(true) }}
                                                                                    >
                                                                                        <img src={deleteIcon} alt="delete" className={globalStyles.cardDeleteIcon} height={"16"} width={"16"} />
                                                                                    </IconButton>
                                                                                </Grid>}
                                                                            {bot.chatbot_flag_public &&
                                                                                <Grid item sx={{ width: "98px" }}>
                                                                                    <Grid item sx={{ background: "#6A097D", color: "#fff", padding: "3px 10px", fontSize: "13px", borderRadius: "12px 0 0 12px", position: "absolute", right: "0" }}>
                                                                                        Sample Chatbot
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} className={chatbotStyles.chatbotNameItem}>
                                                                <Typography component={'span'} className={`${chatbotStyles.chatbotHeadText} ${globalStyles.cardHeaderFontSize}`}>{bot.chatbot_name}</Typography>
                                                            </Grid>

                                                            <Grid item xs={9} className={chatbotStyles.conversationBox} mt={2}>
                                                                <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                                                                    <Grid item sx={{ display: "flex" }} mt={"2px"}>
                                                                        <IconsColor iconName="conversations" height="16" width="16" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <span className={chatbotStyles.conversationFontStyle}>#Conversations: </span>
                                                                        <span className={chatbotStyles.conversationFontStyle}>{bot.total_chats}</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            {/* <Grid item xs={12} className={chatbotStyles.chatbotDescItem}>
                                                                <Typography component={'span'} className={`${chatbotStyles.chatbotDescText} ${globalStyles.cardDescriptionFontSize}`}>
                                                                    {bot.chatbot_discription !== null ?
                                                                        (bot.chatbot_discription.length < 35 ? bot.chatbot_discription : bot.chatbot_discription.substring(0, 35) + "...") :
                                                                        (bot.chatbot_discription)
                                                                    }
                                                                </Typography>
                                                            </Grid>*/}
                                                        </Grid>
                                                    </Grid>
                                                    {/* </GlobalChatbotTooltip> */}
                                                </>
                                            ))
                                        ) : (chatbotList !== null && chatbotList?.filter((chatbot: any) => chatbot.chatbot_flag_public === false).length == 0 ? (
                                            <>
                                                <EmptyState
                                                    height={'calc(100vh - 250px)'}
                                                    pageIcon={<img alt="chatbot" src={ChatbotEmptyStateIcon} style={{ width: "165px", height: "auto" }} />}
                                                    pageSubText={"No Chatbot Created Yet! Start With a New Chatbot"}
                                                    primaryActionBtn={<GlobalButton
                                                        buttonText={"New Chatbot"}
                                                        className='primaryButtonStyle'
                                                        onClick={handleCreateChatbot}
                                                        startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                                    />}
                                                    secondaryActionBtn={<GlobalButton variant='text' className='primaryTextButtonStyle' buttonText={"Try Sample Chatbot"} onClick={() => { setFlagSampleChatbot(true) }} />}
                                                />
                                                {/* <Grid item xs={12} className={styles.noDataFoundItem}>
                                                    <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                        <Grid item>
                                                            <img src={chatbotNotFound} alt="" />
                                                        </Grid>
                                                        <Grid item sx={{ margin: "5px 0px 0px" }}>
                                                            <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500", color: "#4D4D4D", }}>
                                                                No Chatbot Created Yet! Start With a New Chatbot
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ textAlign: "center", width: "250px", maxWidth: "250px", margin: "0px auto" }}>
                                                            <Button
                                                                variant='text'
                                                                size='large'
                                                                // fullWidth
                                                                // startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                                className={globalStyles.globalRedirecetionBtn}
                                                                onClick={() => { handleCreateChatbot() }}
                                                            >
                                                                Create New Chatbot
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                            </>
                                        ) : (chatbotOriginalDetails !== null && chatbotOriginalDetails.length == 0 &&
                                            <>
                                                <Grid item xs={12} className={styles.noDataFoundItem}>
                                                    <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                        <Grid item>
                                                            <img src={noDataFound} alt="" />
                                                        </Grid>
                                                        <Grid item >
                                                            <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500" }}>
                                                                No Data Found!
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                        )}
                                    </>
                                </Grid>
                            </Grid>
                        </>
                    }


                </Grid>
            </Grid >
        </>
    )
}

export default ChatbotList;