import { Button, Divider, Grid, TextField, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { callAnalysisFeedbackPayload, callAnalysisOptionResponseModel } from '../../../models/axiosModel'
import GlobalButton from '../../utility/globalButton/button'
import Required from '../../utility/required'
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants'

const CallAnalysisReportProblem = () => {
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error, userDetail } = useContext(UserContext) as UserContextPayload
    const { GetCallAnalysisOption, callAnalysisFeedbackOptions, GetCallAnalysisFeedbackType, callAnalysisFeedbackType, currentAudioAnalysis, CreateCallAnalysisFeedbackApi, setFlagReportProblem } = useContext(CallAnalysisContext) as CallAnalysisContextPayload


    const [selectedFeedbackIssue, setSelectedFeedbackIssue] = useState<callAnalysisOptionResponseModel[] | null>(null)
    const largeScreen = useMediaQuery('(max-width : 1600px) and (min-width : 0px)');
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            await GetCallAnalysisOption()
            await GetCallAnalysisFeedbackType()
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        })()
    }, [])

    const handleCancelFeedback = () => {
        setSelectedFeedbackIssue(null)
    }

    const isSubmitFeedbackDisbaled = () => {
        if (selectedFeedbackIssue === null) {
            return true;
        }
        const flag = selectedFeedbackIssue.every(issue =>
            issue.call_analysis_feedback_option_field.every(field =>
                (field.call_analysis_feedback_data_value && field.call_analysis_feedback_data_value?.trim().length > 0) || (!field.flag_required)
            )
        );
        return !flag;
    }

    const getCreateFeedbackPayload = () => {
        if (selectedFeedbackIssue) {
            const payloadArray = selectedFeedbackIssue.map((issue) => {
                return ({
                    call_analysis_feedback_option_id: issue.call_analysis_feedback_option_id,
                    call_analysis_feedback_type_id: callAnalysisFeedbackType?.find((type) => type.call_analysis_feedback_type_name.toLowerCase() === "call record")?.call_analysis_feedback_type_id || 1,
                    call_analysis_feedback_unique_id: currentAudioAnalysis?.call_record_id || 0,
                    call_analysis_feedback_option_field_data: issue.call_analysis_feedback_option_field.map((field) => {
                        return ({
                            call_analysis_feedback_option_field_id: field.call_analysis_feedback_option_field_id,
                            call_analysis_feedback_option_field_value: field.call_analysis_feedback_data_value || "",
                        })
                    })
                })
            });

            return {
                call_analysis_feedback_option: payloadArray,
            }
        }
        return {
            call_analysis_feedback_option: [],
        };
    }

    const onClickOfSubmit = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const payload: callAnalysisFeedbackPayload = getCreateFeedbackPayload();
        const res = await CreateCallAnalysisFeedbackApi(payload);
        if (res.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Su informe de problema ha sido enviado exitosamente." });
        } else {
            let errorCode = checkForErrorCode(res);
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagReportProblem(false);
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const onClickOfOption = (value: callAnalysisOptionResponseModel) => {
        if (selectedFeedbackIssue) {
            if (selectedFeedbackIssue.find((issue) => issue.call_analysis_feedback_option_id === value.call_analysis_feedback_option_id)) {
                if (selectedFeedbackIssue.length === 1) setSelectedFeedbackIssue(null);
                else setSelectedFeedbackIssue(selectedFeedbackIssue.filter((issue) => issue.call_analysis_feedback_option_id != value.call_analysis_feedback_option_id));
            } else {
                setSelectedFeedbackIssue([...selectedFeedbackIssue, value]);
            }
        } else {
            setSelectedFeedbackIssue([value]);
        }
    }

    const onChangeOfTextField = (val: string, ind: number, id: number) => {
        if (selectedFeedbackIssue) {
            setSelectedFeedbackIssue((prev) => {
                return prev?.map((issue, index) => {
                    if (ind === index) {
                        return {
                            ...issue,
                            call_analysis_feedback_option_field: issue.call_analysis_feedback_option_field.map((item) => {
                                if (item.call_analysis_feedback_option_field_id === id) {
                                    return {
                                        ...item,
                                        call_analysis_feedback_data_value: val,
                                    }
                                }
                                return item;
                            })
                        }
                    }
                    return issue;
                }) || null;
            })
        }
    }

    return (
        <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"} sx={{ zIndex: "1" }}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                <span className={styles.popupTitleStyle}>Reportar un Problema</span>
            </Grid>
            <Grid item>
                <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
            </Grid>
            {callAnalysisFeedbackOptions &&
                <Grid item sx={{ width: "100%" }}>
                    <Grid container direction={"column"} gap={"15px"}>
                        <Grid item>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <span className={styles.reportAProblemQuestionStyle}>Categoría de problema <Required /></span>
                                </Grid>
                                <Grid item>
                                    <Grid container gap={1}>
                                        {callAnalysisFeedbackOptions.sort((a, b) => a.call_analysis_feedback_option_seq - b.call_analysis_feedback_option_seq).map((options: callAnalysisOptionResponseModel) =>
                                            <Grid item key={options.call_analysis_feedback_option_id}>
                                                <Button
                                                    variant='outlined'
                                                    size='medium'
                                                    className={`${styles.reportAProblemButtonStyle} 
                                                        ${selectedFeedbackIssue?.find((issue) => issue.call_analysis_feedback_option_id === options.call_analysis_feedback_option_id) ?
                                                            styles.reportAProblemSelectedButton : styles.reportAProblemButton}`}
                                                    onClick={() => { onClickOfOption(options); }}
                                                >
                                                    {options.call_analysis_feedback_option_name}
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {(selectedFeedbackIssue && selectedFeedbackIssue?.length > 0) &&
                            <Grid item>
                                <Divider sx={{ marginBlock: "8px 15px" }} />
                                <Grid container direction={"column"} gap={"12px"} className={styles.reportProblemsContainer}>
                                    <Grid item>
                                        <Grid container direction={"column"} gap={2}>
                                            {selectedFeedbackIssue?.map((issue, ind) => {
                                                return (
                                                    <>
                                                        {ind != 0 &&
                                                            <Grid item mt={1}>
                                                                <Divider />
                                                            </Grid>
                                                        }
                                                        {issue.call_analysis_feedback_option_field?.sort((a, b) => a.call_analysis_feedback_option_field_seq - b.call_analysis_feedback_option_field_seq).map((fields) =>
                                                            <Grid item>
                                                                <Grid container direction={"column"} gap={"6px"}>
                                                                    <Grid item>
                                                                        <span className={styles.reportAProblemQuestionStyle}>{fields.call_analysis_feedback_option_field_value}{fields.flag_required && <Required />}</span>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {fields.field_input_type_name.toLocaleLowerCase() === "text" &&
                                                                            <TextField
                                                                                variant="outlined"
                                                                                className={styles.reportAProblemTextField}
                                                                                // sx={{ background: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "rgba(250, 240, 255, 1)" }} 
                                                                                placeholder={fields.call_analysis_feedback_option_field_placeholder}
                                                                                fullWidth
                                                                                value={fields.call_analysis_feedback_data_value}
                                                                                onChange={(e) => { onChangeOfTextField(e.target.value, ind, fields.call_analysis_feedback_option_field_id); }}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        }
                        <Grid item sx={{ width: "100%" }} mt={2}>
                            <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText={"Reiniciar"}
                                        className='borderedButtonStyle'
                                        sx={{ minWidth: smallScreen ? "100px" : "130px", fontSize: largeScreen ? "13px !important" : "" }}
                                        onClick={() => handleCancelFeedback()}
                                    />
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText={"Entregar"}
                                        className='primaryButtonStyle'
                                        sx={{ minWidth: smallScreen ? "100px" : "130px" }}
                                        disabled={isSubmitFeedbackDisbaled()}
                                        onClick={() => onClickOfSubmit()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default CallAnalysisReportProblem
