import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";

const NotifyExtentionContext = React.createContext<NotifyExtentionContextPayload | undefined>(undefined);

export interface NotifyExtentionContextPayload {
    SendPostMessageToExtention: (payload: any) => void;
}

const NotifyExtentionProvider = ({ children }: ChildrenProps) => {


    const SendPostMessageToExtention = async (data: any) => {
        try {
            // window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: data }, "*");
            // window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: data }, "*");
            // The ID of the extension we want to talk to.
            const editorExtensionId = process.env.REACT_APP_WRITEWIZ_EXTENSION_ITEM_ID;
            // Make a simple request:
            chrome.runtime.sendMessage(editorExtensionId, {
                "type": "SET_WRITEWIZ_LOGIN_DETAILS",
                "data": data
            },
                function (response: any) {
                    if (!response.success)
                        console.log(response)
                });
        } catch (error) {
            console.error("SendPostMessageToExtention catch==");
            console.error(error);
        }
    }
    const payload = { SendPostMessageToExtention };

    return (
        <NotifyExtentionContext.Provider value={payload}>
            {children}
        </NotifyExtentionContext.Provider>
    );
};

export { NotifyExtentionContext, NotifyExtentionProvider };
