
import { Grid, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/accountInformationCard.module.scss";
import GlobalButton from '../../utility/globalButton/button';
import { chatbotIntegrationAssignmentData } from '../../../models/axiosModel';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import { getIntegrationIcon } from '../../../utils/getIcons';
import defaultprofile_picture_url from "../../../assets/images/instagram/insta_default_pp.jpg";
interface Details {
    page_id?: any
    chatbot_name?: string
    chatbot_id?: string | number
    page_name?: string
    page_category?: string,
    linked_chatbots?: chatbotIntegrationAssignmentData[],
    biography?: string,
    followers_count?: number,
    follows_count?: number,
    profile_picture_url?: string,
}

interface TempInstagramCardProps {
    title: string
    details: Details
    currentlySelected: boolean
    flagDelete: boolean
    flagShowMetaIcon?: boolean
    deleteChatbotIntegration?: () => void;
    removeChatbotIntegration?: (page_id: string) => void
    addChatbotIntegration?: (page_id: string, event?: React.MouseEvent<HTMLButtonElement>) => void,
    showRemoveChatbotIntegration?: boolean,
    flagIntegration?: boolean,
    flagShowLinkedChatbot?: boolean,
    disabled?: boolean,
    integrationType: string,
}


const getValueStyle = (keyName: string, value: any) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        default:
            return { fontSize: "15px", fontWeight: "400", color: "#000" }
    }
}

const getDisplayKeyName = (keyName: string) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        case "page_id":
            return "Page Id";
        case "linked_chatbots":
            return "Linked Chatbots"
        case "chatbot_name":
            return "Linked Chatbots"
        case "page_category":
            return "Page Category"
        case "page_name":
            return "Page Name"
        case "meta_business_id":
            return "Meta Business ID";

        case "":
        default:
            return null
    }
}
export default function TempInstagramCard(props: TempInstagramCardProps) {

    const { title, details, currentlySelected, showRemoveChatbotIntegration } = props;
    const { chatbotList } = useContext(ChatbotContext) as ChatbotContextPayload
    //   let pageURL = `https://www.instagram.com/${igAccountInfo.username}/`

    return (
        <>

            <Grid item className={styles.mainGrid} style={{
                opacity: props.disabled ? "0.5" : "1"
            }}>
                <Grid container direction={"column"}>
                    {
                        <Grid item>
                            <Grid container direction={"row"}
                                gap={3}
                            >
                                <Grid item>
                                    <Grid container className={styles.facebookIntegrationCard} direction={"column"}>
                                        <Grid item className={styles.instagramPageID}>
                                            <span className={styles.instagramIDStyle}>ID: {props.details.page_id}</span>
                                        </Grid>

                                        <Grid item>
                                            <Grid container justifyContent={"end"}>
                                                <Grid item className={styles.instagramIcon} sx={{ padding: "0px !important", width: "40px !important", height: "40px !important" }}>
                                                    <img alt="" src={props.details.profile_picture_url ? props.details.profile_picture_url : defaultprofile_picture_url} height={"40"} width={"40"} style={{ borderRadius: "50%" }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item className={styles.integrationPageName} sx={{ marginTop: "20px" }}>
                                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                <Grid item>  <span className={styles.pageNameStyle}>{props.details.page_name}</span></Grid>
                                                <Grid item sx={{ display: "flex", alignContent: "center" }}>
                                                    <a style={{ fontSize: "13px", textDecoration: "none" }} href={`https://www.instagram.com/${props.details.page_name}`}
                                                        rel="noreferrer"
                                                        target='_blank'
                                                    >
                                                        Visit
                                                    </a>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        {/* <Grid item className={styles.integrationPageCategory} sx={{ maxWidth: "100% !important" }}>
                                            <Grid container direction={"column"}>
                                                <Grid item className={styles.pageCategoryStyle}>
                                                    <span>{"Page Category"}</span>
                                                </Grid>

                                                <Grid item className={`${styles.pageCategoryTextStyle} ${styles.slackDescriptionTextStyle}`}>
                                                    <span>{props.details.page_category ? props.details.page_category : "-"}</span>
                                                </Grid>


                                            </Grid>
                                        </Grid> */}

                                        {
                                            <Grid item className={styles.integrationPageCategory}>
                                                <Grid container direction={"row"} >
                                                    <Grid item>
                                                        <Grid container direction={"column"}>
                                                            <Grid item className={styles.pageCategoryStyle}>
                                                                <span>Bio</span>
                                                            </Grid>
                                                            {
                                                                <Grid item className={styles.chatbotNameStyle}>
                                                                    {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                        return <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbot.chatbot_name}</a>
                                                                    }) : <span style={{ fontWeight: "400", fontSize: "14px !important" }}>{props.details.biography ? props.details.biography : "-"}</span>}
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }

                                        {<Grid item className={styles.integrationPageCategory}>
                                            <Grid container direction={"row"} gap={1} justifyContent={"space-between"}>
                                                <Grid item >
                                                    <Grid container direction={"column"}>
                                                        <Grid item className={styles.pageCategoryStyle}>
                                                            <span>Followers</span>
                                                        </Grid>
                                                        <Grid item className={styles.chatbotNameStyle}>
                                                            {!props.flagIntegration &&
                                                                <Grid item className={styles.chatbotNameStyle} sx={{ display: "flex" }} justifyContent={"center"}>
                                                                    <span style={{ fontSize: "14px !important" }}>{props.details.followers_count ? props.details.followers_count : "-"}</span>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item >
                                                    <Grid container direction={"column"}>
                                                        <Grid item className={styles.pageCategoryStyle}>
                                                            <span>Following</span>
                                                        </Grid>
                                                        <Grid item className={styles.chatbotNameStyle} sx={{ display: "flex" }} justifyContent={"center"}>

                                                            <span style={{ fontSize: "14px !important" }}>{props.details.follows_count ? props.details.follows_count : "-"}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>}


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
