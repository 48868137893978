import { Button, Divider, Grid } from '@mui/material';
import React from 'react'
import styles from '../../../assets/styles/componentStyles/messageFormatForms/messageFormats.module.scss'


interface formatPopup {
    format: any,
    handleFormats?: any
}

const MessageFormatPopup = (props: formatPopup) => {
    const { format, handleFormats } = props;

    return (
        <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                <span className={styles.popupTitleStyle}>{format.message_format_name}</span>
            </Grid>
            <Grid item>
                <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
            </Grid>
            <Grid item>
                <Grid container direction={"row"} gap={"20px"}>
                    {format.message_format_examples && format.message_format_examples.length > 0 && format.message_format_examples.map((examples: any) =>
                        <Grid item>
                            <Grid container direction={"column"} gap={"10px"}>
                                <Grid item>
                                    <span className={styles.popupCardHeadingStyle}>{examples.message_format_sub_name}</span>
                                </Grid>

                                <Grid item className={styles.messageFormatImageStyle}>
                                    <Grid container className={styles.messageFormatImageInsideStyle} justifyContent={"center"} alignContent={"center"}>
                                        <img style={{ width: "174px", height: "214px" }} src={examples.message_format_sub_img} />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {/* <Button onClick={() => handleFormats(format.message_format_id)} className={styles.useThisCardButton}>Use This Card</Button> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MessageFormatPopup
