import { Box, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import IconsColor from '../utility/iconsColor'
import GlobalButton from '../utility/globalButton/button'
import styles from "../../assets/styles/componentStyles/planHasExhausted.module.scss";
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router';
const PlanHasExhausted = () => {
    const { userDetail, setFlagShowCreditsExhausted, setShowLoginPopup } = useContext(UserContext) as UserContextPayload;
    const navigate = useNavigate();
    function formatDate(inputDate: string | undefined) {
        // Create a Date object from the input string
        if (inputDate) {
            const date = new Date(inputDate);

            // Get the day, month, and year from the Date object
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();

            // Add the appropriate suffix to the day
            let daySuffix;
            if (day === 1 || day === 21 || day === 31) {
                daySuffix = 'st';
            } else if (day === 2 || day === 22) {
                daySuffix = 'nd';
            } else if (day === 3 || day === 23) {
                daySuffix = 'rd';
            } else {
                daySuffix = 'th';
            }

            // Format the date string
            const formattedDate = ` ${month} ${day}${daySuffix} ${year}`;

            return formattedDate;
        }
    }
    const isUserRegistered = () => {
        if (userDetail.flag_user_anonymous) {
            return false
        } else {
            return userDetail.flag_email_verified
        }
    }
    const isUserPremium = () => {
        if (userDetail.plan_detail?.credit_usage_data?.stripe_product_name == "Premium") {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        isUserRegistered()
    }, [userDetail])
    const primaryBtnOnClick = () => {
        let url = encodeURIComponent(window.location.href)
        setFlagShowCreditsExhausted(false)
        if (isUserRegistered()) {
            window.open(`${window.location.origin}/dashboard/settings-plansAndBilling`, "_blank")
        } else {
            // navigate("/login?redirect=" + url);
            setShowLoginPopup(true)
        }
    }
    return (
        <Grid container className={styles.mainGrid} alignItems={"center"} direction={"column"}>
            <Grid item className={styles.upperGrid}>
                <Grid container alignItems={"center"} direction="column">
                    <Grid item className={styles.meterWrapper}>
                        <IconsColor iconName="meter" width="80px" height="80px" />
                    </Grid>
                    <Grid item className={styles.heading}>
                        {isUserRegistered() ? "Oops! Your plan has exhausted" : "Oops, No more credit left"}
                    </Grid>
                    <Grid item className={styles.textAlignCenter}>
                        {isUserRegistered() ?
                            <>
                                {isUserPremium() ?
                                    <div>
                                        <span className={styles.subHeading}>Upgrade now to a new plan and continue enjoying the benefits of WriteWiz AI with our </span>
                                        <span className={styles.coloredSubheading}>premium features</span>
                                    </div>
                                    :
                                    <div>
                                        <span className={styles.subHeading}>Your monthly quota has reached its limit and will be renewed on {formatDate(userDetail?.plan_detail?.credit_usage_data?.credit_end_date)}. For immediate use of WriteWiz AI, please upgrade to our premium plan and continue enjoying the benefits with our </span>
                                        <span className={styles.coloredSubheading}>premium features</span>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <div >
                                    <span className={styles.subHeading}>Create an account to keep using Writewiz AI & be rewarded with a </span>
                                    <span className={styles.coloredSubheading}>Free Premium Trial!</span>
                                </div>
                            </>
                        }
                    </Grid>
                    <Grid item>
                        <Grid container alignItems={"center"} direction={"column"}>
                            <Grid item className={styles.signUpBtnWrapper}><GlobalButton sx={{ fontSize: "15px !important" }} flagFullWidth buttonText={isUserRegistered() ? <><IconsColor iconName="diamond" fillColor="white" height="20px" width="20px" /><Box sx={{ paddingLeft: "7px !important" }} >Upgrade</Box></> : "Sign up"} className='primaryButtonStyle' onClick={primaryBtnOnClick} /></Grid>
                            {!isUserRegistered() && <Grid item className={styles.creditText}>No Credit Card Required</Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!isUserRegistered() &&
                <Grid item className={styles.secondGrid}>
                    <Grid container>
                        <Typography className={styles.bottomText}> Already have an account? </Typography>  <Typography className={styles.bottomColoredText} onClick={primaryBtnOnClick}> Login</Typography>
                    </Grid>
                </Grid>}
        </Grid>
    )
}

export default PlanHasExhausted