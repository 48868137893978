import React, { useContext, useEffect } from 'react'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import globalStyles from './../../../assets/styles/global.module.scss'
import { Grid } from '@mui/material'
import GlobalButton from '../../utility/globalButton/button'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'

interface configTopInterface {
    handleSave: (flagUpdate?: boolean) => Promise<any>,
    isDisable: () => boolean,
    handleCancel: () => void
}

const ConfigTopSection = (props: configTopInterface) => {
    const {handleSave, isDisable, handleCancel} = props;
    const { leadGenerationList } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    return (
        <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
            <Grid item>
                <span className={`${globalStyles.globalHeadText} ${globalStyles.globalHeaderFontStyle}`}>{leadGenerationList?.chatbot_lead_data[0].chatbot_lead_name}</span>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"row"} gap={2} justifyContent={"left"}>
                            <Grid item>
                                {/* <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelLeadGeneration()}/> */}
                                <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancel()}/>
                            </Grid>
                            <Grid item>
                                <GlobalButton
                                    // buttonText={flagUpdate ? "Update" : "Save"}
                                    buttonText={"Save"}
                                    className='primaryButtonStyle' 
                                    sx={{ minWidth: "130px" }} 
                                    onClick={() => handleSave(false)}
                                    disabled={isDisable()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConfigTopSection
