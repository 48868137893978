import { formatDateAccordingToTimezoneUsingMoment } from "../../../../../utils/constants";
import TemplateCard from "../../../../global/templateCard";
import { Grid } from "@mui/material";
import IconsColor from "../../../../utility/iconsColor";
import globalStyles from '../../../../../assets/styles/global.module.scss';
import GlobalPopUp from "../../../../utility/globalPopUp";
import { useContext, useEffect, useState } from "react";
import { CalendlyContext, CalendlyContextType } from '../../../../../context/calendlyIntegrationContext';
import StepContainer from "./stepContainer";
import GlobalDeletePopup from "../../../../utility/globalDeletePopup/globalDeletePopup";
import GlobalButton from "../../../../utility/globalButton/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { chatbotCalendlyDataInterface } from "../../../../../models/axiosModel";
import { UserContext, UserContextPayload } from "../../../../../context/userContext";
import styles from '../../../../../assets/styles/componentStyles/calendlyStyles/calendly.module.scss'
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from "../../../../../utils/constants";
import { ChatbotContext, ChatbotContextPayload } from "../../../../../context/chatbotContext";
import ChatbotDetails from "../../chatbotDetails";
import integrationStyles from '../../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'

interface AccountDetailsProps {
    chatbot_calendly_data: chatbotCalendlyDataInterface[];
    flagEdit: boolean;
    generic_calendly_data: any;
}

const AccountDetails = ({ flagEdit, chatbot_calendly_data, generic_calendly_data }: AccountDetailsProps) => {

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
    const [selectedCalendlyIntegrationId, setSelectedCalendlyIntegrationId] = useState<string | number | null>(null);
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [linkedChatbotAccounts, setLinkedChatbotAccounts] = useState<any>(null)
    const [remainingLinkedAccounts, setRemainingLinkedAccounts] = useState<any>(null)
    const { setIsPopupMode, setFlagAddAccount, GetCalendlyIntegrationData, flagAddAccount, calendlyIntegrationData, editAccDetailPopupOpen, setEditAccDetailPopupOpen, setSelectedEvent, DeleteChatbotCalendlyIntegration, genericChatbotCalendlyData, calendlyAuthUrlApi, GetCallbackStatus, setStep, integratedAccountsArray, connectedAccountsArray, setIntegratedAccountsArray, setConnectedAccountsArray } = useContext(CalendlyContext) as CalendlyContextType;
    const { setFlagLoading, userDetail, setError, setSuccess, success, error } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotDataApi } = useContext(ChatbotContext) as ChatbotContextPayload
    const [flagSelectFromExisting, setFlagSelectFromExisting] = useState(false)
    const navigate = useNavigate()
    function filterNonExistingInTemp2(temp1: any, temp2: any) {
        if (temp2.length === 0) {
            return temp1;
        }

        const temp2Ids = new Set(temp2.map((item: any) => item.calendly_integration_id));

        return temp1.filter((item: any) => !temp2Ids.has(item.calendly_integration_id));
    }

    useEffect(() => {
        if (chatBotDetails && generic_calendly_data) {
            setLinkedChatbotAccounts(chatBotDetails.chatbot_calendly_data)
            const filteredResult = filterNonExistingInTemp2(generic_calendly_data, chatBotDetails.chatbot_calendly_data);
            setRemainingLinkedAccounts(filteredResult)
        }
    }, [chatBotDetails, generic_calendly_data])
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');

    const menuItems = (calendlyIntegrationId: string | number) => [
        { label: "Delete", action: (e: any) => { e.stopPropagation(); e.preventDefault(); handleDeleteBtnClick(calendlyIntegrationId) } }
    ];
    useEffect(() => {
        if (chatBotDetails && generic_calendly_data && chatbot_calendly_data) {
            filteringFunction();
        }
    }, [chatBotDetails, generic_calendly_data, chatbot_calendly_data])

    const filteringFunction = () => {
        let newConnectedAccountsArray = [...connectedAccountsArray];
        let newIntegratedAccountsArray = [...integratedAccountsArray];

        generic_calendly_data?.forEach((genericAcc: any) => {
            const isAlreadyIntegrated = chatbot_calendly_data?.some(
                (chatbotAcc: any) => chatbotAcc.calendly_integration_id === genericAcc.calendly_integration_id
            );

            if (isAlreadyIntegrated) {
                newConnectedAccountsArray.push(genericAcc);
            } else {
                newIntegratedAccountsArray = newIntegratedAccountsArray.filter(
                    (integration) => integration.calendly_integration_id !== genericAcc.calendly_integration_id
                );
                newIntegratedAccountsArray.push(genericAcc);
            }
        });
        setConnectedAccountsArray(newConnectedAccountsArray);
        setIntegratedAccountsArray(newIntegratedAccountsArray);
    };


    const handleEditPopupOpen = async (calendly_integration_id: any) => {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            setSelectedEvent([]);
            const response = await GetCalendlyIntegrationData(calendly_integration_id);
            if (response.status) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            } else {
                let errorCode = checkForErrorCode(response.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
        setIsPopupMode(true);
        setEditAccDetailPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupMode(false);
        navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${searchParams.get('chatbot_id')}`)
        setEditAccDetailPopupOpen(false);
    };

    const handleDeleteBtnClick = (calendlyIntegrationId: string | number) => {
        setIsPopupMode(false);
        setEditAccDetailPopupOpen(false);
        setSelectedCalendlyIntegrationId(calendlyIntegrationId);
        setDeleteConfirmationModal(true);
    };

    const handleDeleteCard = async () => {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            if (!selectedCalendlyIntegrationId || !chatbot_id) {
                console.error("Missing calendly_integration_id or chatbot_id");
                return;
            }
            const response = await DeleteChatbotCalendlyIntegration(selectedCalendlyIntegrationId, chatbot_id);
            if (response) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
                setSuccess({ ...success, flagShowSuccess: true, message: 'deletion successful!' })
                setDeleteConfirmationModal(false);
                const updatedChatbotDetailsResponse = await GetChatbotDataApi(Number(chatbot_id))
                if (updatedChatbotDetailsResponse) {
                    setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
                }
            }
            else {
                let errorCode = checkForErrorCode(response.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.log(error, 'error')
        } finally {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
    };

    function getTemplateCardExtraFields(calendlyIntegration: any) {
        if (calendlyIntegration.chatbot_calendly_assignment_data && calendlyIntegration.chatbot_calendly_assignment_data[0].event_type_name) {
            return [
                {
                    fieldName: 'User Email',
                    fieldValue: calendlyIntegration.calendly_integration_user_email,
                },
                {
                    fieldName: 'Event Type',
                    fieldValue: calendlyIntegration.chatbot_calendly_assignment_data && calendlyIntegration.chatbot_calendly_assignment_data[0].event_type_name,
                },
                {
                    fieldName: 'Created At',
                    fieldValue: formatDateAccordingToTimezoneUsingMoment(calendlyIntegration.created_at_timestamp),
                },
            ];
        } else {
            return [

                {
                    fieldName: 'User Email',
                    fieldValue: calendlyIntegration.calendly_integration_user_email,
                },
                {
                    fieldName: 'Created At',
                    fieldValue: formatDateAccordingToTimezoneUsingMoment(calendlyIntegration.created_at_timestamp),
                }
            ];
        }
    }

    const menuItemToShow = (integrationId: any) => {
        return (
            flagEdit ? menuItems(integrationId) : []
        )
    }

    const handleAddAccount = async () => {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));

            let currentUrl = window.location.href;
            const payload = {
                current_url: currentUrl,
                integration_app_id: 1,
            };

            const response = await calendlyAuthUrlApi(payload);

            if (response.status) {
                const authUrl = response?.data?.auth_url;
                if (authUrl) {
                    window.open(response.data.auth_url, "_blank")
                    const stateValue = new URL(authUrl).searchParams.get('state');
                    if (stateValue) {
                        pollForCallbackStatus(stateValue);
                    } else {
                        console.error('State param not found in the auth_url.');
                    }
                }
            } else {
                let errorCode = checkForErrorCode(response)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleCallbackSuccess = (data: any) => {
        const { integration_type_name, integration_details } = data;
        switch (integration_type_name.toLowerCase()) {
            case 'calendly':
                makeIntegrationDataCall(integration_details.calendly.id);
                break;
            case 'acuity':
                makeIntegrationDataCall(integration_details.acuity.id);
                break;
            default:
                console.error('Unknown integration type:', integration_type_name);
        }
    };

    const pollForCallbackStatus = (stateValue: string) => {
        const pollInterval = 5000;
        const maxPollingTime = 60000;
        let elapsedTime = 0;

        const intervalId = setInterval(async () => {
            try {
                const response = await GetCallbackStatus(stateValue);

                if (response.status) {
                    const integrationStatus = response?.data?.integration_status?.toLowerCase();

                    if (integrationStatus === 'completed' || integrationStatus === 'failed') {
                        clearInterval(intervalId);
                        if (integrationStatus === 'failed') {
                            setError({ ...error, flagShowError: true, message: response?.data?.reason })
                        } else {
                            handleCallbackSuccess(response?.data);
                        }
                    } else {
                        console.log("Callback not completed, retrying...");
                    }

                    elapsedTime += pollInterval;

                    if (elapsedTime >= maxPollingTime) {
                        console.log("Polling stopped after 1 minute.");
                        clearInterval(intervalId);
                    }
                } else {
                    let errorCode = checkForErrorCode(response.data)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            } catch (error) {
                console.error("Error polling for callback status:", error);
                clearInterval(intervalId);
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }, pollInterval);
    };


    const makeIntegrationDataCall = async (id: any) => {
        try {
            const response = await GetCalendlyIntegrationData(id);
            if (response.status) {
                setStep('2');
                setFlagAddAccount(true);
                setEditAccDetailPopupOpen(true)
            } else {
                let errorCode = checkForErrorCode(response.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.error("Error fetching integration data:", error);
        } finally {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
    };



    return (
        <>
            <>
                {/* {flagAddAccount &&
                    <GlobalPopUp
                        flagShowCloseIcon
                        flagBackdrop
                        paperMinWidth={'90vw'}
                        height='90vh'
                        handleClose={() => { setFlagAddAccount(false) }}
                        open={flagAddAccount}>
                        <StepContainer />
                    </GlobalPopUp>

                } */}
            </>
            {flagSelectFromExisting &&
                <GlobalPopUp
                    flagShowCloseIcon
                    flagBackdrop
                    paperMinWidth={'90vw'}
                    height='90vh'
                    handleClose={() => { setFlagSelectFromExisting(false) }}
                    open={flagSelectFromExisting}>
                    <div style={{ padding: "20px" }}>
                        {remainingLinkedAccounts && remainingLinkedAccounts.length > 0 && (
                            <Grid item >
                                <Grid container gap={3} direction={"column"}>
                                    <Grid item xs={8}>
                                        <span style={{ paddingLeft: "10px" }} className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight}`}>
                                            Authorised Calendly Accounts
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            {remainingLinkedAccounts.map((calendlyIntegration: any, index: number) => (
                                                <Grid item key={index}>
                                                    <TemplateCard
                                                        extraFields={getTemplateCardExtraFields(calendlyIntegration)}
                                                        cardOnClick={() => {
                                                            setFlagSelectFromExisting(false)
                                                            handleEditPopupOpen(calendlyIntegration.calendly_integration_id)
                                                        }}
                                                        cardImage={
                                                            <span
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#F7E9FF",
                                                                    width: "45px",
                                                                    height: "45px"
                                                                }}
                                                            >
                                                                <IconsColor iconName="calendlyCalenderIcon" width="22px" height="22px" />
                                                            </span>
                                                        }
                                                        cardName={calendlyIntegration.calendly_integration_user_name}
                                                        flagFieldCount
                                                        fieldText={`fields: ${calendlyIntegration?.chatbot_calendly_assignment_data?.[0]?.chatbot_calendly_question_data?.length || 0}`}
                                                        MoreVertIcon
                                                        menuItems={[]}
                                                        class={styles.templateCardStyle}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        )}
                        {remainingLinkedAccounts.length == 0 &&
                            <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%", marginBottom: "20px" }} padding={"30px"}>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>No Account Authorised Yet.</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item>
                            <GlobalButton
                                sx={{ fontWeight: '400', fontSize: '12px' }}
                                buttonText={"Calendly Account"}
                                onClick={() => {
                                    setFlagSelectFromExisting(false);
                                    handleAddAccount()
                                }
                                } className={'blackShadowedButton'}
                                startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                            />
                        </Grid>
                    </div>
                </GlobalPopUp>
            }
            {linkedChatbotAccounts &&
                <>
                    {linkedChatbotAccounts && linkedChatbotAccounts.length > 0 ?
                        <Grid container sx={{ padding: "10px" }} direction={"column"} gap={3}>
                            <Grid item>
                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <span className={integrationStyles.whatsappIntegrationFontStyle}>Calendly accounts linked with your chatbot</span>
                                    </Grid>
                                    <Grid item >
                                        <GlobalButton
                                            sx={{ fontWeight: '400', fontSize: '12px' }}
                                            buttonText="Setup another account"
                                            startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />}
                                            className="primaryButtonStyle"
                                            onClick={() =>
                                                setFlagSelectFromExisting(true)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {linkedChatbotAccounts?.map((calendlyIntegration: any) => {
                                return (
                                    <TemplateCard
                                        extraFields={getTemplateCardExtraFields(calendlyIntegration)}
                                        cardOnClick={() => handleEditPopupOpen(calendlyIntegration.calendly_integration_id)}
                                        cardImage={
                                            <span style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "50%",
                                                backgroundColor: "#F7E9FF",
                                                width: "45px",
                                                height: "45px"
                                            }}>
                                                <IconsColor iconName="calendlyCalenderIcon" width="22px" height="22px" />
                                            </span>
                                        }
                                        cardName={calendlyIntegration.calendly_integration_user_name}
                                        flagFieldCount
                                        fieldText={`fields: ${calendlyIntegration?.chatbot_calendly_assignment_data?.[0]?.
                                            chatbot_calendly_question_data?.length}`}
                                        MoreVertIcon
                                        menuItems={menuItemToShow(calendlyIntegration?.calendly_integration_id)}
                                        class={styles.templateCardStyle}
                                    />
                                )
                            })
                            }

                        </Grid>
                        :
                        <>
                            <Grid item
                                sx={{
                                    backgroundColor: "rgba(247, 233, 255, 0.5)",
                                    borderRadius: "5px",
                                    width: "100%",
                                    padding: '30px',
                                    margin: '10px 0px'
                                }}
                            >
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>
                                            This Chatbot is not linked with any Calendly Account yet.
                                        </span>
                                    </Grid>
                                    <Grid item sx={{ paddingTop: "20px" }}>
                                        <GlobalButton
                                            sx={{ padding: '6px 20px !important' }}
                                            buttonText="Add New"
                                            startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />}
                                            className="primaryButtonStyle"
                                            onClick={() =>
                                                setFlagSelectFromExisting(true)
                                                // handleAddAccount()
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>}
            {editAccDetailPopupOpen && !deleteConfirmationModal && (
                <GlobalPopUp
                    handleClose={handlePopupClose}
                    open={editAccDetailPopupOpen}
                    flagShowCloseIcon
                    flagBackdrop
                    title="Configure Event Type"
                    paperMinWidth={'90vw'}
                    height='90vh'
                    flagDivider={true}
                >
                    <div style={{ padding: "20px" }}>
                        <StepContainer />
                    </div>
                </GlobalPopUp>
            )}

            {deleteConfirmationModal && (
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete the event type`}
                    onClose={() => { setIsPopupMode(false); setEditAccDetailPopupOpen(false); setDeleteConfirmationModal(false); }}
                    onDelete={handleDeleteCard}
                />
            )}
        </ >
    );
};

export default AccountDetails;
