import React, { useContext, useEffect, useState } from 'react'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext'
import { chatbotListInterface } from '../../../models/axiosModel';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/template.module.scss";
import { Grid, Typography } from '@mui/material';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import EmptyState from '../../global/emptyState';
import ChatbotEmptyStateIcon from "../../../assets/images/chatbot/chatbotEmptyState.png";
import GlobalButton from '../../utility/globalButton/button';
import { useNavigate } from 'react-router-dom';
import IconsColor from '../../utility/iconsColor';
import noDataFound from '../../../assets/CampaignNoDataFound.png';
import chatbotStyles from "../../../assets/styles/componentStyles/chatbotList.module.scss";
import individualAvatar from "../../../assets/chatbotProfileAvtar.png";
import ConfirmationPopover from '../../utility/confirmationPopover';


interface DeployChatbotDialogProps {
    pageName: string,
    showConfirmationPopover: boolean,
    setShowConfirmationPopover: React.Dispatch<React.SetStateAction<boolean>>,
    confirmationPopoverAnchorEl: HTMLButtonElement | null,
    setConfirmationPopoverAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
    handleCloseConfirmationPopover: () => void,
    onDeployClick: (chatbot_id: string) => void,
}
const DeployChatbotDialog: React.FC<DeployChatbotDialogProps> = ({ pageName, showConfirmationPopover, setShowConfirmationPopover, confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl, handleCloseConfirmationPopover, onDeployClick }) => {

    const { chatbotList, GetChatbotListApi } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setFlagLoading, setError, error, flagLoading, success, setSuccess } = useContext(UserContext) as UserContextPayload;
    const [filteredChatBotList, setFilteredChatBotList] = useState<chatbotListInterface[] | null>(null);

    // const [showConfirmationPopover, setShowConfirmationPopover] = React.useState(false);
    // const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const navigate = useNavigate()

    // const innerHeight = 
    const handleSearch = (event: any) => {
        const query = event?.target?.value?.toLowerCase()
        if (chatbotList != null) {
            const chatbotFilterData = chatbotList.filter((chatbot: any) => {
                return (chatbot.chatbot_name !== null && chatbot?.chatbot_name?.toLowerCase().includes(query))
            })
            setFilteredChatBotList(chatbotFilterData)
        }
    }

    useEffect(() => {
        if (!chatbotList) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                let chatBotResponse = await GetChatbotListApi()
                if (chatBotResponse?.status) {
                    if (chatBotResponse?.data?.length) {
                        // setFilteredChatBotList(chatBotResponse.data.filter((chatbot: any) => chatbot.chatbot_flag_public)) 
                        setFilteredChatBotList(chatBotResponse.data);
                    } else {
                        setFilteredChatBotList(null)
                    }
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })();
        } else {
            // setFilteredChatBotList(chatbotList.filter((chatbot: any) => chatbot.chatbot_flag_public)); 
            setFilteredChatBotList(chatbotList);
        }
    }, []);

    return (
        <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                <Grid container sx={{ padding: "20px 22px 25px 27px" }} className={styles.chatbotGrid}>
                    <Grid item sx={{ width: "100%" }}>
                        <Grid container mt={"10px"} justifyContent={{ xs: "start", sm: "end" }}>
                            <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                <GlobalSearch
                                    fullWidth
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                    size="small"
                                    placeholder="Search"
                                />
                            </Grid>

                            <Grid item className={styles.listsMainContainer} mt={2}>
                                <Grid container>
                                    <>
                                        {filteredChatBotList && filteredChatBotList?.filter((chatbot: any) => chatbot.chatbot_flag_public === false).length > 0 ?

                                            filteredChatBotList.filter((chatbot: any) => chatbot.chatbot_flag_public === false).map((bot: any, index: number) => {
                                                return (
                                                    <>
                                                        <Grid item xl={2.3} lg={3} md={4} sm={12} xs={12}
                                                            sx={{ position: "relative" }}
                                                            className={chatbotStyles.personaCreatedList}
                                                        >
                                                            <Grid container>

                                                                <Grid item xs={12}>
                                                                    <Grid container sx={{ justifyContent: "space-between", alignItems: "start" }}>
                                                                        <Grid item className={chatbotStyles.chatbotProfileAvtarItem}
                                                                            sx={{
                                                                                background: bot?.chatbot_logo_id ? "transparent" : "#FAF0FF"
                                                                            }}
                                                                        >
                                                                            <img src={bot?.chatbot_logo_url ? bot.chatbot_logo_url : individualAvatar} alt="chatbot Icon" className={bot?.chatbot_logo_id ? chatbotStyles.chatbot_icon : chatbotStyles.default_icon} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} className={chatbotStyles.chatbotNameItem}>
                                                                    <Typography component={'span'} className={`${chatbotStyles.chatbotHeadText} ${globalStyles.cardHeaderFontSize}`}>{bot.chatbot_name}</Typography>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Grid item className={chatbotStyles.conversationBox} mt={2} sx={{ padding: "7px 10px !important" }}>
                                                                            <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                                                                                <Grid item sx={{ display: "flex" }} mt={"2px"}>
                                                                                    <IconsColor iconName="conversations" height="16" width="16" />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <span className={chatbotStyles.conversationFontStyle}># </span>
                                                                                    <span className={chatbotStyles.conversationFontStyle}>{bot.total_chats}</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item mt={2}>
                                                                            <GlobalButton buttonText={"Deploy"} className='linkChatbotButtonStyle' onClick={(e) => {
                                                                                setConfirmationPopoverAnchorEl(e.currentTarget);
                                                                                setShowConfirmationPopover(true)
                                                                            }} />
                                                                        </Grid>

                                                                        <ConfirmationPopover
                                                                            open={showConfirmationPopover}
                                                                            anchorEl={confirmationPopoverAnchorEl}
                                                                            handleClose={() => handleCloseConfirmationPopover()}
                                                                            onConfirmation={() => { onDeployClick(bot.chatbot_id) }}
                                                                            descriptionText={<p>Are you sure you want to deploy <span style={{ fontWeight: "600" }}>{bot.chatbot_name}</span> on <span style={{ fontWeight: "600" }}>{pageName}</span> ?</p>}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })
                                            :
                                            (chatbotList !== null && chatbotList?.filter((chatbot: any) => chatbot.chatbot_flag_public === false).length == 0 ?
                                                <EmptyState
                                                    height={'calc(100vh - 250px)'}
                                                    pageIcon={<img alt="chatbot" src={ChatbotEmptyStateIcon} style={{ width: "165px", height: "auto" }} />}
                                                    pageSubText={"No Chatbot Created Yet! Start With a New Chatbot"}
                                                    primaryActionBtn={<GlobalButton
                                                        buttonText={"New Chatbot"}
                                                        className='primaryButtonStyle'
                                                        onClick={() => { navigate("/createChatbot") }}
                                                        startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                                    />}
                                                />

                                                : (filteredChatBotList !== null && filteredChatBotList.length == 0 &&

                                                    <Grid item xs={12} className={styles.noDataFoundItem} sx={{ height: "calc(100vh - 300px) !important" }}>
                                                        <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                            <Grid item>
                                                                <img src={noDataFound} alt="" />
                                                            </Grid>
                                                            <Grid item >
                                                                <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    No Data Found!
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )
                                        }
                                    </>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DeployChatbotDialog
