import React, { useEffect, useState } from 'react'
// import './fileUpload.css'
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Close';
import IconsColor from '../../utility/iconsColor';
import styles from "../../../assets/styles/componentStyles/utility/fileUpload.module.scss";
import globalStyles from "../../../assets/styles/global.module.scss";
import { Button, IconButton, Tooltip } from '@mui/material';
import GlobalButton from '../globalButton/button';
import { FileUploadOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
// import "../../../../assets/styles/parentFormwizStyles.css"
var FormData = require('form-data');

interface fileUploadInterface {
    uploadedFileDetails: any,
    multipleFileAllow: boolean,
    fileSizeAllowed: number,
    fileTypeAllowed: string,
    fileNumberAllowed: number,
    uploadFileHandler: (event: any) => void,
    removeFileHandler: (data: any) => void,
    isDisabled: boolean | string,
    isDeleteFileIconVisible?: boolean,
    bgColor?: string,
    flagShowJustIcon?: boolean,
}

const FileUpload = (props: fileUploadInterface) => {
    const { uploadedFileDetails,
        multipleFileAllow,
        fileSizeAllowed,
        fileTypeAllowed,
        fileNumberAllowed,
        uploadFileHandler,
        removeFileHandler,
        isDisabled,
        isDeleteFileIconVisible,
        bgColor,
        flagShowJustIcon,
    } = props
    const [fileError, setFileError] = React.useState({ status: false, message: "" });
    const [showFileUploader, setShowFileUploader] = React.useState(true);
    useEffect(() => {
        if (uploadedFileDetails && uploadedFileDetails.length && uploadedFileDetails[0] !== "") {
            if (multipleFileAllow) {
                let countFileUpload = uploadedFileDetails.length
                if (countFileUpload == fileNumberAllowed) {
                    setShowFileUploader(false);
                } else {
                    setShowFileUploader(true);
                }
            } else {
                if (uploadedFileDetails.length) {
                    setShowFileUploader(false);
                } else {
                    setShowFileUploader(true)
                }
            }
        } else {
            setShowFileUploader(true);
        }
    }, [uploadFileHandler])

    const formatFileSize = (bytes: any, decimalPoint: any) => {
        if (bytes == 0) return '';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const RemoveConfirmation = (data: any) => {
        setFileError({
            status: false,
            message: ""
        })
        const result = window.confirm("Are you sure you want to remove the file?");
        if (result) {
            removeFileHandler(data)
        } else {
            return
        }

    }

    const validationFile = async (event: any) => {
        try {

            if (!event.target.files || event.target.files.length === 0) return false;
            let filesEvent = Array.from(event.target.files);
            let findFilesFromParent = uploadedFileDetails[0] == "" ? [] : uploadedFileDetails
            if (!multipleFileAllow && (filesEvent && filesEvent.length > 1)) {
                setFileError({
                    status: true,
                    message: "Only a single file can be uploaded."
                });
                return false;
            } else if ((filesEvent.length > fileNumberAllowed) || (filesEvent.length + (findFilesFromParent.length || 0) > fileNumberAllowed)) {
                setFileError({
                    status: true,
                    message: `You can only upload up to ${fileNumberAllowed} file(s).`
                });
                return false;
            }

            // Check if any file exceeds the maximum allowed size
            const oversizedFile = filesEvent.find((file: any) => (file?.size / 1048576) > fileSizeAllowed);
            if (oversizedFile) {
                setFileError({
                    status: true,
                    message: `Please upload a file smaller than ${fileSizeAllowed} MB`
                });
                return false;
            }

            // Check if all files have the correct file type
            if (fileTypeAllowed) {
                const validFileTypes = fileTypeAllowed.split(", ").map((type: any) => type.trim());
                const invalidFileType = filesEvent.some((file: any) => {
                    const fileExtension = file.name.split('.').pop();
                    return !validFileTypes.includes(`.${fileExtension}`);
                });

                if (invalidFileType) {
                    setFileError({
                        status: true,
                        message: `Invalid file type. Allowed types are: ${fileTypeAllowed}`
                    });
                    return false;
                }
            }

            setFileError({
                status: false,
                message: ""
            });
            return true;

        } catch (e) {
            console.log(e)
            setFileError({
                status: true,
                message: "Something went wrong."
            });
            return false;
        }
    }

    const uploadHandler = async (event: any) => {
        var checkFilevalidation = await validationFile(event)
        if (!checkFilevalidation) {
            return
        }
        if (!event.target.files.length) return;

        props.uploadFileHandler(event)
    }

    const handleFileDownload = (file: any) => {
        console.log('file', file)
        if (file.original_file_name || file.file_name) {
            if (file.google_storage_id) {
                fetch(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${file.google_storage_id}&flag_info=false`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const fileToDownload = new File([blob], `${file.original_file_name || file?.file_name}`);
                        const url = URL.createObjectURL(fileToDownload);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileToDownload.name;
                        document.body.appendChild(a);
                        a.click();
                        console.log('reached here')
                        // Clean up
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            }
        }
    }

    const useStyles = makeStyles(() => ({
        customTooltip: {
            backgroundColor: '#ff0000',
            color: '#fff',
            fontSize: '13px',
            padding: '8px 15px',
            borderRadius: 5,
        },
        customArrow: {
            color: '#ff0000',
        },
    }));
    const classes = useStyles();

    return (
        <>
            <Grid container>
                <Grid item className="file-card" direction="column">
                    <>
                        <Grid container direction="column">
                            {/* @ts-ignore */}
                            {(showFileUploader[props.fileDetails?.page_field_id] || !props.fileDetails?.fileNewDesign) &&
                                <Grid item className={`${flagShowJustIcon ? 'justShowIcon-input' : 'file-inputs'}`} sx={{ opacity: isDisabled ? "0.5" : "1" }}>
                                    <input
                                        id="image"
                                        accept={fileTypeAllowed}
                                        type="file"
                                        multiple
                                        className="file-inputs"
                                        disabled={!showFileUploader}
                                        style={{ display: isDisabled == true || isDisabled == "true" ? "none" : "" }}
                                        onChange={uploadHandler} >
                                    </input>
                                    <>

                                        {flagShowJustIcon ?
                                            <IconButton
                                                disableFocusRipple
                                                disableRipple
                                                disabled={isDisabled == true || isDisabled == "true"}
                                                sx={{ opacity: isDisabled == true || isDisabled == "true" ? "1" : "", cursor: isDisabled == true || isDisabled == "true" ? "default" : "pointer", color: "#fff !important" }}
                                            >
                                                <FileUploadOutlined />
                                            </IconButton>
                                            :
                                            <button
                                                disabled={isDisabled == true || isDisabled == "true"}
                                                style={{ color: "#4C4A4D", display: "flex", flexDirection: "column", opacity: isDisabled == true || isDisabled == "true" ? "0.5" : "", cursor: isDisabled == true || isDisabled == "true" ? "unset" : "pointer", backgroundColor: bgColor ? bgColor : "" }} >
                                                <Grid container justifyContent={"center"} alignItems="center">
                                                    <Grid item style={{ marginRight: "7px", display: "flex" }}>
                                                        <IconsColor iconName='uploadToGoogleStorage' width="35px" height="25px" fillColor="#000" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" justifyContent="center" alignItems="start">
                                                            <div style={{ display: "flex" }}>
                                                                <span style={{ color: "#000", fontSize: "13px", fontWeight: "400", display: "flex" }}>Drag & Drop files here, or {" "}</span><span style={{ color: "#6A097D", fontSize: "13px", fontWeight: "400", display: "flex", paddingLeft: "4px" }}>Browse</span>
                                                            </div>
                                                            <Grid item sx={{ display: "flex" }}><span style={{ color: "#7A7A7A", fontSize: "13px", fontWeight: "400", opacity: "0.8" }}>{fileSizeAllowed} MB limit for single file</span></Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </button>
                                        }
                                    </>
                                </Grid>
                            }

                            <>
                                {!flagShowJustIcon &&
                                    <>
                                        {uploadedFileDetails && uploadedFileDetails[0] !== "" && uploadedFileDetails.map((fileArray: any) => {

                                            var roundedNumber = formatFileSize(fileArray?.size ? fileArray?.size : 0, "")
                                            return <>
                                                {<Grid item sx={{ display: "flex", alignSelf: "start", width: "100%", marginTop: "10px" }}
                                                // className={!fileArray.google_storage_id ? "file_item" : "file_item_after_uploaded"}
                                                >
                                                    <Grid container sx={{ width: "100%", backgroundColor: bgColor ? bgColor : "" }} className={fileArray.previewImageSrc ? "fileUploadedGrid" : "file_background_design"}>
                                                        <Grid item direction="column" sx={{ width: "100%" }}>
                                                            <Grid container alignItems="center" className={"delete_container"}>
                                                                <a rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${fileArray.google_storage_id}&flag_info=false`} download={fileArray.name} target="_blank" style={{ color: "unset", textDecoration: "unset", width: "90%" }}>
                                                                    <Grid sx={{ width: "100%" }} item xs={11} className="file_name" >
                                                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", whiteSpace: "nowrap", wordBreak: "break-all", width: "100%" }}>
                                                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                                                                {fileArray.previewImageSrc &&
                                                                                    <img className="file_type_uploaded" src={fileArray.previewImageSrc} alt="file" style={{ width: "32px", height: "32px", marginRight: "8px" }} />
                                                                                }
                                                                                <span style={{ whiteSpace: "pre-wrap", fontSize: "13px" }} className="file_name_txt">{fileArray.name || fileArray}  </span>
                                                                            </div>
                                                                            {roundedNumber !== '' &&
                                                                                <>
                                                                                    &nbsp;
                                                                                    &nbsp;
                                                                                    <div>
                                                                                        <span className="file_size"> {roundedNumber}</span>
                                                                                    </div>
                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </Grid>
                                                                </a>
                                                                {(isDisabled != true) &&
                                                                    <>
                                                                        <Grid item xs={(isDeleteFileIconVisible != undefined && !isDeleteFileIconVisible) ? 1 : 0.5}>
                                                                            <Grid container gap="10px" flexWrap={"nowrap"} justifyContent={"end"}>
                                                                                {fileArray.google_storage_id &&
                                                                                    <Grid item className={`${globalStyles.clickable} delete_icon`} sx={{ zIndex: "1" }} onClick={() => { handleFileDownload(fileArray) }}>
                                                                                        <IconsColor iconName="download" width="25px" height="25px" fillColor="#000" />
                                                                                    </Grid>}

                                                                                {isDeleteFileIconVisible != undefined ?
                                                                                    !isDeleteFileIconVisible &&
                                                                                    <Grid item sx={{ zIndex: "1" }} className={`${globalStyles.clickable} delete_icon`}>
                                                                                        <DeleteIcon className="delete_onclick" onClick={() => {
                                                                                            RemoveConfirmation(fileArray.google_storage_id ? fileArray.google_storage_id : fileArray)
                                                                                        }} />
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item sx={{ zIndex: "1" }} className={`${globalStyles.clickable} delete_icon`}>
                                                                                        <DeleteIcon className="delete_onclick" onClick={() => {
                                                                                            RemoveConfirmation(fileArray.google_storage_id ? fileArray.google_storage_id : fileArray)
                                                                                        }} />
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            </>
                                            // }
                                        })}
                                    </>
                                }
                            </>
                            {fileError.status == true ?
                                flagShowJustIcon ?
                                    <Grid item style={{ paddingTop: "3px", textAlign: "center" }}>
                                        <Tooltip title={fileError.message} placement='bottom' arrow
                                            classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                                        >
                                            <div style={{ display: "inline-block" }}>
                                                <IconsColor fillColor="#ff0000" iconName="info" height={"18"} width={"18"} />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    :
                                    <Grid item style={{ color: "red", fontSize: "14px", paddingTop: "3px" }}>
                                        {fileError.message}
                                    </Grid>
                                : ""}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </>
    )
}

export default FileUpload;