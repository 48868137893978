import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react'
import GlobalForm from '../../utility/formWiz/globalForm';
import styles from '../../../assets/styles/componentStyles/chatbotStyles/chatbotSettings.module.scss'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import axios from 'axios';
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';

interface ChatbotSettingSectionInterface {
    flagSmallScreen: boolean,
    flagMobileScreen: boolean,
    setCreateChatbotFormData: any,
    createChatbotFormData: any,
    files: any,
    setFiles: any,
    currentTab: string
    setFieldValueOfTheme?: any
}

const ChatbotSettings = (props: ChatbotSettingSectionInterface) => {


    const {
        flagMobileScreen,
        flagSmallScreen,
        createChatbotFormData,
        setCreateChatbotFormData,
        setFiles,
        files,
        setFieldValueOfTheme

    } = props

    const { setFlagLoading, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;
    const { UpdateChatbotApi, chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [submissionID, setsubmissionID] = useState(null);

    const [progress, setProgress] = useState({ filename: "", percent: 0 });


    const formSubmitFunction = async (data: any) => {
        var finalData = data;
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createChatbotFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.options && data.field_option_data_id) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCreateChatbotFormData(tempdata)
    }

    const uploadFileHandler = async (event: any, id: any, removeFile: any) => {
        for (var i = 0; i < event.target.files.length; i++) {
            const file: any = event.target.files[i];
            file.isUploading = true;
            setFiles((old: any) => {
                let temp: any = [...old]
                temp.push(file)
                return temp
            })

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");
            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }
            )
                .then(async (res) => {
                    if (res.status) {
                        file.isUploading = false;
                        file.file_data = res.data.data;


                        file.google_storage_id = res.data.data.google_storage_id;
                        await setFiles((old: any) => {
                            let temp = [...old]
                            let fileType = file.name.split('.').pop()
                            let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                            temp.map((rr: any) => {
                                if (rr.google_storage_id == file.google_storage_id) {
                                    rr.isUploading = false;
                                    rr.file_data = res.data.data;
                                    rr.google_storage_id = res.data.data.google_storage_id;
                                    rr.page_field_id = id;
                                    rr.previewImageSrc = fileTypeIconSrc;
                                }
                            })
                            var tempdata = { ...createChatbotFormData }
                            const googleStorageIds = temp.filter((item: any) => item.page_field_id == id);

                            tempdata.data.global_form_field.map((newvalue: any) => {
                                if (newvalue.page_field_id === id) {
                                    // debugger
                                    newvalue.value = googleStorageIds
                                }
                            })
                            setCreateChatbotFormData(tempdata)
                            return temp
                        })

                        let images = [];

                        for (let i = 0; i < event.target.files.length; i++) {
                            images.push(URL.createObjectURL(event.target.files[i]))
                        }
                    } else {
                        setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        setFiles([])
                    }
                })
                .catch((err) => {
                    setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                    setFiles([])
                    // inform the user
                    console.error(err)
                    // removeFile(file.name)
                });
        }
    }

    const removeFile = (google_id: any, page_field_id: any) => {
        var google_s_id: any = {};
        google_s_id['file_id'] = google_id
        const fileSet: any = files.filter((file: any) => file.google_storage_id !== google_id)
        var finalFiles: any = [...fileSet];
        var tempdata = { ...createChatbotFormData }
        const googleStorageIds = finalFiles.map((item: any) => item.google_storage_id);

        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.page_field_id === page_field_id) {
                newvalue.value = finalFiles.filter((file: any) => file.page_field_id == newvalue.page_field_id)
            }
        })
        setCreateChatbotFormData(tempdata)
        setFiles(finalFiles)
        // axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${google_id}`, {
        //     headers: { 'Content-Type': 'multipart/form-data' },
        //     withCredentials: true,
        // }
        // ).then(async (res) => {
        //     if (res.data.status) {
        //         const fileSet: any = files.filter((file: any) => file.google_storage_id !== google_id)
        //         var finalFiles: any = [...fileSet];
        //         var tempdata = { ...createChatbotFormData }
        //         const googleStorageIds = finalFiles.map((item: any) => item.google_storage_id);

        //         tempdata.data.global_form_field.map((newvalue: any) => {
        //             if (newvalue.page_field_id === page_field_id) {
        //                 newvalue.value = googleStorageIds
        //             }
        //         })
        //         setCreateChatbotFormData(tempdata)
        //         setFiles(finalFiles)
        //     } else {
        //         let errorCode = checkForErrorCode(res.data)
        //         if (ifUserMessageShouldBeDynamic(errorCode)) {
        //             setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
        //         } else {
        //             setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
        //         }
        //     }
        // }
        // ).catch((err) => {
        //     console.error(err)
        // });
    }
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className={flagSmallScreen ? styles.chatbotSettingsForm : ""}>
                            {props.currentTab == "Settings" &&
                                <GlobalForm
                                    fields={createChatbotFormData.data.global_form_field.slice(0, 12)}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createChatbotFormData.data.doc_page_theme[0]}
                                    formData={createChatbotFormData.data}
                                    setFieldValue={setFieldValue}
                                    recaptchaFlag="true"
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                    filesInParent={files}
                                    removeFile={(e: any, page_field_id: any) => { removeFile(e, page_field_id) }}
                                />}

                            {props.currentTab == "Theme Settings" &&
                                <GlobalForm
                                    fields={createChatbotFormData.data.global_form_field.slice(12)}
                                    // submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    // handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createChatbotFormData.data.doc_page_theme[0]}
                                    formData={createChatbotFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValueOfTheme}
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                    filesInParent={files}
                                    // progressInParent={progress}
                                    removeFile={(e: any, page_field_id: any) => { removeFile(e, page_field_id) }}
                                />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotSettings;