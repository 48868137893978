import { createContext, ReactNode, useState } from 'react';
import agent from '../services/api';
import { createStaticMessageFormatPayload, getStaticMessageFormatResponse } from '../models/axiosModel';
import { initialStaticMessageFormatPayload } from '../components/dashboard/messageFormats/messageFormatConstants';

export interface MessageFormatContextPayload {
    currentSubTab: string,
    setCurrentSubTab: React.Dispatch<React.SetStateAction<string>>,

    staticMessageFormatList: getStaticMessageFormatResponse[] | null,
    setStaticMessageFormatList: React.Dispatch<React.SetStateAction<getStaticMessageFormatResponse[] | null>>,
    GetStaticMessageFormatList: (chatbot_id: string) => Promise<any>,

    staticMessageFormatPayload: createStaticMessageFormatPayload,
    setStaticMessageFormatPayload: React.Dispatch<React.SetStateAction<createStaticMessageFormatPayload>>,
    handleStaticMessageFormatPayload: (chatbot_id: number, field: string, value: string | boolean) => void,

    tempStaticMessageFormatPayload: createStaticMessageFormatPayload,
    setTempStaticMessageFormatPayload: React.Dispatch<React.SetStateAction<createStaticMessageFormatPayload>>,

    handleCreateStaticMessageFormat: (payoad: createStaticMessageFormatPayload) => Promise<any>,
    handleUpdateStaticMessageFormat: (payoad: createStaticMessageFormatPayload) => Promise<any>
}

const MessageFormatContext = createContext<MessageFormatContextPayload | undefined>(undefined);

const MessageFormatProvider = ({ children }: { children: ReactNode }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [currentSubTab, setCurrentSubTab] = useState<string>(urlParams.get('currentTab') || "Dynamic Message Format")
    const [staticMessageFormatList, setStaticMessageFormatList] = useState<getStaticMessageFormatResponse[] | null>(null);

    const [staticMessageFormatPayload, setStaticMessageFormatPayload] = useState<createStaticMessageFormatPayload>(initialStaticMessageFormatPayload)
    const [tempStaticMessageFormatPayload, setTempStaticMessageFormatPayload] = useState<createStaticMessageFormatPayload>(initialStaticMessageFormatPayload)

    const GetStaticMessageFormatList = async (chatbot_id: string): Promise<any> => {
        const response = await agent.getStaticMessageFormat.get(chatbot_id);
        if (response.status) {
            setStaticMessageFormatList(response.data)
        }
        else {
            setStaticMessageFormatList(null)
        }
        return response;
    }

    const handleStaticMessageFormatPayload = (chatbot_id: number, field: string, value: string | boolean) => {
        setStaticMessageFormatPayload((prevState) => ({
            ...prevState,
            [field]: value,
            chatbot_id: chatbot_id,
        }));
    };

    const handleCreateStaticMessageFormat = async (payload: createStaticMessageFormatPayload): Promise<any> => {
        const response = await agent.createStaticMessageFormat.post(payload);
        if (response.status) {
            // setStaticMessageFormatList(response.data)
        }
        else {
            // setStaticMessageFormatList(null)
        }
        return response;
    }

    const handleUpdateStaticMessageFormat = async (payload: createStaticMessageFormatPayload): Promise<any> => {
        const response = await agent.updateStaticMessageFormat.put(payload);
        if (response.status) {
            // setStaticMessageFormatList(response.data)
        }
        else {
            // setStaticMessageFormatList(null)
        }
        return response;
    }

    const payload: MessageFormatContextPayload = {
        currentSubTab,
        setCurrentSubTab,
        staticMessageFormatList,
        setStaticMessageFormatList,
        GetStaticMessageFormatList,
        staticMessageFormatPayload,
        setStaticMessageFormatPayload,
        handleStaticMessageFormatPayload,
        tempStaticMessageFormatPayload,
        setTempStaticMessageFormatPayload,
        handleCreateStaticMessageFormat,
        handleUpdateStaticMessageFormat
    }

    return (
        <MessageFormatContext.Provider value={payload}>
            {children}
        </MessageFormatContext.Provider>
    );
}

export { MessageFormatContext, MessageFormatProvider };