import { Breadcrumbs, Divider, Grid, Link, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbot.module.scss";
import globalStyles from "../../../assets/styles/global.module.scss";
import { useNavigate } from 'react-router-dom';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import ChatbotPreviewSection from '../chatbot/chatbotPreviewSection';
import chronoxLogo from "../../../assets/images/global/Chronox Logo.svg"

interface propsViewChatbot {
    currentTab: string
}
const TryUserChatbot = ({ currentTab }: propsViewChatbot) => {
    let navigate = useNavigate();
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1200px)')
    const [openSwipableDrawer, setOpenSwipableDrawer] = useState<boolean>(false)

    const toggleDrawer = (newOpen: any) => () => {
        setOpenSwipableDrawer(newOpen);
    };

    const innerHeight = (window.innerHeight - 90);


    return (
        <>
            <Grid container className={`${globalStyles.dashboardChildWrapper} ${styles.trydashboardStyle}`} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto" }} direction="column">
                <Grid item xs={12} className={`${styles.cbHeaderMainItem} ${globalStyles.fullHeight}`} sx={{ position: "relative" }}>
                    <Grid container

                        className={`${styles.container} ${globalStyles.fullHeight}`} direction={"column"} alignItems={"center"}>
                        <Grid item className={styles.fixGridContainer} xs={12}></Grid>
                        <Grid item xs={12} className={`${styles.tryChatbotDesign} ${globalStyles.fullHeight}`}>
                            <ChatbotPreviewSection
                                flagSmallScreen={flagSmallScreen}
                                setOpenSwipableDrawer={setOpenSwipableDrawer}
                                openSwipableDrawer={openSwipableDrawer}
                                toggleDrawer={toggleDrawer}
                                currentTab={currentTab}
                                flagTryIt={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default TryUserChatbot
