import { Divider, Grid, IconButton, TextField, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import GlobalButton from '../../utility/globalButton/button'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import { createLeadGenerationPayloadModel, createLeadGenerationResponseModel, getGenericFieldTypeDataResponseModel, getLeadIntegrationTypeDataResponse, leadFieldDataModel } from '../../../models/axiosModel'
import Required from '../../utility/required'
import LeadGenerationFieldTable from './leadGenerationFieldTable'
import globalStyles from './../../../assets/styles/global.module.scss'
import addIcon from './../../../assets/chatbotAssets/addCircularReqIcon.svg'
import GlobalPopUp from '../../utility/globalPopUp'
import AddLeadGenerationField from './AddLeadGenerationField'
import { initialIntegrationPayload, initialLeadGeneraionData, initialLeadGenerationField } from './leadGenerationConstants'
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dataStyles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationDataStyle.module.scss'
import IconsColor from '../../utility/iconsColor'
import LeadDetailsForm from './leadDetailsForm'
import AddNewLeadIntegration from './addNewLeadIntegrationPopup'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import LeadGenerationStepContainer from './leadGenerationStepContainer'
import LeadGenerationIntegrationCards from './leadGenerationIntegrationCards'
import EmailConfig from './emailConfig'
import SlackConfig from './slackConfig'
import AddIcon from '@mui/icons-material/Add';
import GeneratedLeadData from './generatedLeadData'

interface newDetailsInterface {
    handleSaveLeadGenerationData: () => Promise<any>,
    handleSaveLeadGeneration: () => Promise<any>
}

const NewLeadDetails = (props: newDetailsInterface) => {
    const { handleSaveLeadGenerationData, handleSaveLeadGeneration } = props;
    const { createLeadGenerationPayload, addNewLeadField, setAddNewLeadField, leadGenerationFieldPayload, setLeadGenerationFieldPayload, setFlagUpdateField, flagDeleteFieldData,
        setFlagDeleteFieldData, handleDeleteFieldData, addIntegrationPopup, setAddIntegrationPopup, tempLeadGenerationPayload, stepContainer, handleCancelSelectLeadIntegration,
        selectedIntegrationType, leadGenerationList, setSelectedTabField, selectedTabField } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload

    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1200px)');
    const tabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 800px)');
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    let chatbot_id = searchParams.get('chatbot_id')
    let lead_id = searchParams.get('lead_id');

    const isLeadIntegrationSaveDisabled = () => {
        if (JSON.stringify(selectedIntegrationType) === JSON.stringify(initialIntegrationPayload)) {
            return true;
        }
        else {
            return false;
        }
    }

    const innerHeight = flagMobileScreen ? (window.innerHeight - 100) : (window.innerHeight - 120);

    return (
        <Grid item sx={{ padding: flagMobileScreen ? "0px 10px 0px 13px" : "0px 20px 0px 23px", width: "100%" }} xs={12}>
            <Grid container direction={"column"} className={styles.newLeadStyleGlobal} sx={{height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`}}>
                <Grid item className={!lead_id ? styles.newLeadDetailsStyle : ""} sx={{ padding: lead_id ? "4px" : "", width: "100%", maxWidth: "100%" }}>

                    {/* {lead_id && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.length > 0 && */}
                    {lead_id &&
                        <Grid container justifyContent={"space-between"} alignItems={"center"} mb={2} flexWrap={"nowrap"} gap={3}>
                            <Grid item>
                                <span className={`${globalStyles.globalHeadText} ${globalStyles.globalHeaderFontStyle}`}>{tempLeadGenerationPayload?.chatbot_lead_name}</span>
                            </Grid>
                            {!flagSmallScreen &&
                                <Grid item className={dataStyles.fieldLeadData}>
                                    <Grid container gap={1} flexWrap={"nowrap"} className={dataStyles.tabGridStyle}>
                                        <Grid item className={dataStyles.leadDetailSelectStyle} sx={{ backgroundColor: selectedTabField === "Lead Details" ? "#FFFFFF" : "", boxShadow: selectedTabField === "Lead Details" ? "0px 0px 2px rgba(0,0,0,0.25)" : "" }}
                                            onClick={() => setSelectedTabField("Lead Details")}
                                        >
                                            <span className={dataStyles.leadDetailSelectTextStyle}>Lead Details</span>
                                        </Grid>

                                        {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Slack") &&
                                            <Grid item className={dataStyles.leadDetailSelectStyle} sx={{ backgroundColor: selectedTabField === "Slack" ? "#FFFFFF" : "", boxShadow: selectedTabField === "Slack" ? "0px 0px 2px rgba(0,0,0,0.25)" : "" }}
                                                onClick={() => setSelectedTabField("Slack")}
                                            >
                                                <span className={dataStyles.leadDetailSelectTextStyle}>Slack</span>
                                            </Grid>
                                        }

                                        {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Email") &&
                                            <Grid item className={dataStyles.leadDetailSelectStyle} sx={{ backgroundColor: selectedTabField === "Email" ? "#FFFFFF" : "", boxShadow: selectedTabField === "Email" ? "0px 0px 2px rgba(0,0,0,0.25)" : "" }}
                                                onClick={() => setSelectedTabField("Email")}
                                            >
                                                <span className={dataStyles.leadDetailSelectTextStyle}>Email</span>
                                            </Grid>
                                        }

                                        {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Spreadsheet") &&
                                            <Grid item className={dataStyles.leadDetailSelectStyle} sx={{ backgroundColor: selectedTabField === "Spreadsheet" ? "#FFFFFF" : "", boxShadow: selectedTabField === "Spreadsheet" ? "0px 0px 2px rgba(0,0,0,0.25)" : "" }}
                                                onClick={() => setSelectedTabField("Spreadsheet")}
                                            >
                                                <span className={dataStyles.leadDetailSelectTextStyle}>Spreadsheet</span>
                                            </Grid>
                                        }

                                        <Grid item className={dataStyles.leadDetailSelectStyle} sx={{ backgroundColor: selectedTabField === "Generated Leads" ? "#FFFFFF" : "", boxShadow: selectedTabField === "Generated Leads" ? "0px 0px 2px rgba(0,0,0,0.25)" : "" }}
                                            onClick={() => setSelectedTabField("Generated Leads")}
                                        >
                                            <span className={dataStyles.leadDetailSelectTextStyle}>Generated Leads</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item>
                                {!flagTabletScreen ?
                                    <GlobalButton
                                        buttonText={"New Export"}
                                        startIcon={<IconsColor iconName="plusIcon" width={"16"} height={"16"} fill={"#FFF"} />}
                                        className="primaryButtonStyle"
                                        sx={{ width: "160px" }}
                                        onClick={() => {
                                            setAddIntegrationPopup(true);
                                        }}
                                    />
                                    :
                                    <IconButton
                                        className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                        onClick={() => { setAddIntegrationPopup(true); }}
                                        sx={{backgroundColor: "#6A097D !important", color: "#FFFFFF !important"}}
                                    >
                                        <AddIcon style={{ height: "28px", width: 'auto', color: "#FFFFFF !important" }} />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    }
                    {lead_id && flagSmallScreen &&
                        <Grid container alignItems={"center"} mb={2} flexWrap={"nowrap"} gap={"15px"} sx={{width: "100%", overflow: "auto", paddingBottom: "5px"}}>
                            <Grid item className={selectedTabField === "Lead Details" ? dataStyles.selectedLeadDetailsMobileStyle : dataStyles.leadDetailSelectMobileStyle} onClick={() => setSelectedTabField("Lead Details")}>
                                <span className={dataStyles.leadDetailSelectTextMobileStyle}>Lead Details</span>
                            </Grid>

                            {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Slack") &&
                                <Grid item className={selectedTabField === "Slack" ? dataStyles.selectedLeadDetailsMobileStyle : dataStyles.leadDetailSelectMobileStyle} onClick={() => setSelectedTabField("Slack")}>
                                    <span className={dataStyles.leadDetailSelectTextMobileStyle}>Slack</span>
                                </Grid>
                            }

                            {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Email") &&
                                <Grid item className={selectedTabField === "Email" ? dataStyles.selectedLeadDetailsMobileStyle : dataStyles.leadDetailSelectMobileStyle} onClick={() => setSelectedTabField("Email")}>
                                    <span className={dataStyles.leadDetailSelectTextMobileStyle}>Email</span>
                                </Grid>
                            }

                            {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Spreadsheet") &&
                                <Grid item className={selectedTabField === "Spreadsheet" ? dataStyles.selectedLeadDetailsMobileStyle : dataStyles.leadDetailSelectMobileStyle} onClick={() => setSelectedTabField("Spreadsheet")}>
                                    <span className={dataStyles.leadDetailSelectTextMobileStyle}>Spreadsheet</span>
                                </Grid>
                            }

                            <Grid item className={selectedTabField === "Generated Leads" ? dataStyles.selectedLeadDetailsMobileStyle : dataStyles.leadDetailSelectMobileStyle} onClick={() => setSelectedTabField("Generated Leads")}>
                                <span className={dataStyles.leadDetailSelectTextMobileStyle}>Generated Leads</span>
                            </Grid>
                        </Grid>
                    }

                    {
                        <Grid container justifyContent={"space-between"} flexWrap={flagMobileScreen || tabletScreen ? "wrap" : "nowrap"} mb={(tabletScreen || flagMobileScreen) && !lead_id ? 2 : 0}>
                            {/* {(!lead_id || (leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data && leadGenerationList.chatbot_lead_data[0].chatbot_lead_integration_data.length == 0)) && */}
                            {!lead_id &&
                                <Grid item>
                                    <LeadGenerationStepContainer />
                                </Grid>
                            }
                            {stepContainer === 2 && !lead_id &&
                                <Grid item textAlign={"end"}>
                                    <Grid container direction={"row"} gap={2} justifyContent={"left"} flexWrap={"nowrap"}>
                                        <Grid item>
                                            {/* <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelLeadGeneration()}/> */}
                                            <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => lead_id ? handleCancelSelectLeadIntegration(true) : handleCancelSelectLeadIntegration()} />
                                        </Grid>
                                        <Grid item>
                                            <GlobalButton
                                                // buttonText={flagUpdate ? "Update" : "Save"}
                                                buttonText={"Save"}
                                                className='primaryButtonStyle'
                                                sx={{ minWidth: "130px" }}
                                                onClick={handleSaveLeadGeneration}
                                                disabled={isLeadIntegrationSaveDisabled()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }

                    {/* {(selectedTabField === "Lead Details" || stepContainer == 1) && */}
                    {stepContainer == 1 && selectedTabField === "Lead Details" &&
                        <LeadDetailsForm handleSaveLeadGenerationData={handleSaveLeadGenerationData} handleSaveLeadGeneration={handleSaveLeadGeneration} />
                    }

                    {stepContainer == 2 && !lead_id &&
                        <LeadGenerationIntegrationCards />
                    }

                    {selectedTabField === "Email" &&
                        <Grid item xs={12}>
                            <EmailConfig />
                        </Grid>
                    }
                    
                    {selectedTabField === "Slack" &&
                        <Grid item xs={12}>
                            <SlackConfig />
                        </Grid>
                    }

                    {selectedTabField === "Generated Leads" &&
                        <Grid item xs={12}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <span className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>Generated Lead Data</span>
                                </Grid>
                                <Grid item sx={{width: "100%"}}>
                                    <GeneratedLeadData />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Grid>

                {addNewLeadField &&
                    <GlobalPopUp
                        open={addNewLeadField}
                        onClose={() => { setAddNewLeadField(false); setLeadGenerationFieldPayload(initialLeadGenerationField); setFlagUpdateField(false) }}
                        // title={}
                        flagBackdrop
                        paperMinWidth={flagSmallScreen ? "85%" : "600px"}
                        paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setAddNewLeadField(false); setLeadGenerationFieldPayload(initialLeadGenerationField); setFlagUpdateField(false) }}
                    >
                        <AddLeadGenerationField />
                    </GlobalPopUp>
                }

                {addIntegrationPopup &&
                    <GlobalPopUp
                        paperMinWidth={flagSmallScreen ? "92%" : "85%"}
                        paperMaxwidth={flagSmallScreen ? "92%" : "85%"}
                        height={"100%"}
                        open={addIntegrationPopup}
                        onClose={() => { setAddIntegrationPopup(false) }}
                        flagShowCloseIcon={true}
                        titleAlignment={"start"}
                        handleClose={() => { setAddIntegrationPopup(false) }}
                    // className={styles.newDocumentPopup}
                    >
                        <AddNewLeadIntegration />
                    </GlobalPopUp>
                }

                {flagDeleteFieldData &&
                    <GlobalPopUp
                        open={flagDeleteFieldData}
                        handleClose={() => { setFlagDeleteFieldData(false); setLeadGenerationFieldPayload(initialLeadGenerationField) }}
                    >
                        <GlobalDeletePopup
                            deleteDescription={`Are you sure you want to delete ${leadGenerationFieldPayload.chatbot_lead_field_name}`}
                            onClose={() => { setFlagDeleteFieldData(false); setLeadGenerationFieldPayload(initialLeadGenerationField) }}
                            onDelete={() => handleDeleteFieldData(leadGenerationFieldPayload)}
                            sx={{ maxWidth: "400px" }}
                        />
                    </GlobalPopUp>
                }

            </Grid>
        </Grid>
    )
}

export default NewLeadDetails
