import { Autocomplete, Chip, Divider, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import Required from '../../utility/required'
import GlobalButton from '../../utility/globalButton/button'
import { getGenericFieldTypeDataResponseModel, leadFieldDataModel } from '../../../models/axiosModel'
import globalStyles from './../../../assets/styles/global.module.scss'
import { initialLeadGenerationField } from './leadGenerationConstants'
import CloseIcon from '@mui/icons-material/Close';
import chipStyles from './../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import { useSearchParams } from 'react-router-dom'

const AddLeadGenerationField = () => {
    const { leadGenerationFieldPayload, setLeadGenerationFieldPayload, handleChangeLeadFieldData, genericFieldDataType, handleAddFieldDataToGeneration, setAddNewLeadField,
        flagUpdateField, setFlagUpdateField } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const [enumValue, setEnumValue] = useState("")
    const [tempLeadGenerationFieldPayload, setTempLeadGenerationFieldPayload] = useState(leadGenerationFieldPayload);
    const [searchParams] = useSearchParams()
    const lead_id = searchParams.get('lead_id')

    const handleCancelNewFieldData = () => {
        if (flagUpdateField) {
            setLeadGenerationFieldPayload(tempLeadGenerationFieldPayload)
        }
        else {
            setLeadGenerationFieldPayload(initialLeadGenerationField);
            setEnumValue("")
        }
    }

    const isAddNewFieldDataDisabled = () => {
        if (leadGenerationFieldPayload.chatbot_lead_field_name.trim().length === 0 || !leadGenerationFieldPayload.chatbot_lead_field_type_id || leadGenerationFieldPayload.chatbot_lead_field_description.trim().length === 0 || enumValue.trim() !== "") {
            return true;
        }
        else if (flagUpdateField) {
            if (JSON.stringify(leadGenerationFieldPayload) === JSON.stringify(tempLeadGenerationFieldPayload)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (JSON.stringify(leadGenerationFieldPayload) === JSON.stringify(initialLeadGenerationField)) {
                return true;
            }
            else {
                return false;
            }
        }

    }

    const handleAddFieldData = () => {
        handleAddFieldDataToGeneration(leadGenerationFieldPayload, lead_id ? true : false);
        setLeadGenerationFieldPayload(initialLeadGenerationField);
        setAddNewLeadField(false);
        setFlagUpdateField(false);
    }

    return (
        <Grid container direction={"column"} gap={"20px"} className={styles.popupGlobalPadding}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                {/* <span className={styles.popupTitleStyle}>{flagUpdate ? "Update Agent" : "Add New Agent"}</span> */}
                <span className={styles.popupTitleStyle}>{flagUpdateField ? "Update Field Data" : "Add New Field"}</span>
            </Grid>
            <Grid item>
                <Divider className={styles.popupDividerStyle} />
            </Grid>
            <Grid item>
                <Grid container direction={"column"} gap={2}>
                    <Grid item>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item>
                                <span className={styles.formHeadingFontStyle}>Field Name <Required /></span>
                            </Grid>
                            <Grid item>
                                <TextField
                                    sx={{
                                        width: "100%",
                                        border: 'none',
                                        "& fieldset": { border: 'none' },
                                        boxShadow: "0px 0px 3px rgba(0,0,0,0.25)",
                                        borderRadius: "5px",
                                        '& .MuiOutlinedInput-input': {
                                            padding: "11.5px 14px !important",
                                            fontSize: "15px !important",
                                            fontWeight: "500"
                                        }
                                    }}
                                    value={leadGenerationFieldPayload.chatbot_lead_field_name}
                                    placeholder='Enter Field Name'
                                    onChange={(event) => handleChangeLeadFieldData('chatbot_lead_field_name', event.target.value, lead_id ? true : false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item>
                                <span className={styles.formHeadingFontStyle}>Data Type <Required /></span>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    value={genericFieldDataType?.find((dataType) => dataType.field_type_id == Number(leadGenerationFieldPayload.chatbot_lead_field_type_id)) ?? null}
                                    options={genericFieldDataType?.filter((type) => type.field_type_name.toLowerCase() !== "boolean") || []}
                                    getOptionLabel={(option) => option.field_type_name}
                                    renderInput={(params) => <TextField {...params} placeholder='Select Data Type' />}
                                    onChange={(event, value) => handleChangeLeadFieldData('chatbot_lead_field_type_id', value?.field_type_id.toString() || "", lead_id ? true : false)}
                                    className={`${globalStyles.autocompleteParaphrasing} ${styles.methodAutoComplete}`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item>
                                <span className={styles.formHeadingFontStyle}>Field Description <Required /></span>
                            </Grid>
                            <Grid item>
                                <TextField
                                    sx={{
                                        width: "100%",
                                        border: 'none',
                                        "& fieldset": { border: 'none' },
                                        boxShadow: "0px 0px 3px rgba(0,0,0,0.25)",
                                        borderRadius: "5px",
                                        '& .MuiOutlinedInput-input': {
                                            padding: "11.5px 14px !important",
                                            fontSize: "15px !important",
                                            fontWeight: "500"
                                        }
                                    }}
                                    value={leadGenerationFieldPayload.chatbot_lead_field_description}
                                    placeholder='Enter Description'
                                    onChange={(event) => handleChangeLeadFieldData('chatbot_lead_field_description', event.target.value, lead_id ? true : false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item>
                                <span className={styles.formHeadingFontStyle}>Field Enum</span>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    options={[]}
                                    freeSolo
                                    multiple
                                    className={`${chipStyles.autocompleteValues} ${styles.enumChipStyle}`}
                                    value={leadGenerationFieldPayload.chatbot_lead_field_enum_value ? leadGenerationFieldPayload.chatbot_lead_field_enum_value.split(',') : []}
                                    onChange={(event: any, value: any) => {
                                        handleChangeLeadFieldData('chatbot_lead_field_enum_value', value.join(', '), lead_id ? true : false);
                                        setEnumValue("")
                                    }}
                                    // @ts-ignore
                                    onInputChange={((event) => { setEnumValue(event.target.value) })}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option}
                                                deleteIcon={<CloseIcon />}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} placeholder="Enter Enum" />}
                                />
                                {enumValue &&
                                    <div style={{ fontSize: "13px", fontWeight: "500", color: "#4C4A4D", marginTop: "5px" }}>
                                        Press Enter to Save: <span style={{ fontSize: "14px", fontWeight: "600", color: "#6A097D" }}>{enumValue}</span>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item sx={{ width: "100%" }} mt={2}>
                <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                    <Grid item>
                        <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelNewFieldData()} />
                    </Grid>
                    <Grid item>
                        <GlobalButton
                            buttonText={flagUpdateField ? "Update" : "Add"}
                            className='primaryButtonStyle'
                            sx={{ minWidth: "130px" }}
                            onClick={handleAddFieldData}
                            disabled={isAddNewFieldDataDisabled()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddLeadGenerationField
