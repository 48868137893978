import { Breadcrumbs, Divider, Grid, Link, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import GlobalPageHeaderText from '../../../../utility/globalPageHeaderText'
import globalStyles from '../../../../../assets/styles/global.module.scss'
import AddIcon from '@mui/icons-material/Add'
import styles from "../../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import { IntegrationContext, IntegrationContextPayload } from '../../../../../context/integrationContext'

const SlackIntegration = () => {
    const navigate = useNavigate();
    const { integrationList, slackIntegrations } = React.useContext(IntegrationContext) as IntegrationContextPayload;
    const [searchParams] = useSearchParams()
    let id = searchParams.get('id')
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    let pathname = window.location.pathname.toLowerCase();

    return (
        <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} 
            ${styles.templateWrapper}
            `}>
                <Grid container sx={{ padding: { xs: "15px 20px 0px 20px", lg: "25px 25px 0px 27px" } }}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item xl={6} lg={5} md={9} sm={9} xs={9} sx={{ marginBottom: { xs: "0px", lg: "10px" } }}>
                                <GlobalPageHeaderText
                                    mainHeadText={
                                        pathname === '/channels/viewintegration/integration/templatemessages' && id ? "Template Messages" :
                                            pathname === '/channels/viewintegration/integration/templateinfo' && id ? "Template Info" :
                                                pathname === '/channels/viewintegration/integration/pages' && id ? "Pages" :
                                                    // id ? "WhatsApp Integration" : "New Integration"}
                                                    id ? "Account Setup" : "New Channel"}
                                    flagMainTextOnly
                                    mainSubText={
                                        !smallScreen && <Breadcrumbs
                                            aria-label="breadcrumb"
                                            className={styles.cbHeaderBreadCrumb}
                                            sx={{
                                                '& .MuiBreadcrumbs-separator': {
                                                    marginRight: '3px',
                                                    marginLeft: '3px'
                                                }
                                            }}
                                        >
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate("/channels") }}
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                            >
                                                Channels
                                            </Link>

                                            {id &&
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate(`/channels/viewintegration/slack?id=${id}`) }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    {/* @ts-ignore */}
                                                    {integrationList?.find((list) => list.slack_integration_id === Number(searchParams.get('id')))?.meta_integration_facebook_data?.instagram_username}
                                                    {`${slackIntegrations?.find((slackIntegration) => slackIntegration.slack_integration_id === Number(searchParams.get('id')))?.slack_team_name || "Slack Business"}`}
                                                </Link>
                                            }

                                            {pathname === "/channels/viewintegration/slack" &&
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                    onClick={() => { id ? navigate(`/channels/viewintegration/slack?id=${id}`) : navigate(`/channels/viewintegration/slack`) }}
                                                >
                                                    Account Setup
                                                </Link>
                                            }

                                            {pathname === "/channels/viewintegration/slack/channels" &&
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                    onClick={() => { id ? navigate(`/channels/viewintegration/integration?id=${id}`) : navigate(`/channels/viewintegration/integration`) }}
                                                >
                                                    Slack Channels
                                                </Link>
                                            }

                                        </Breadcrumbs>
                                    }
                                />
                            </Grid>
                            {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles}`} />}
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default SlackIntegration
