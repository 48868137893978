import { Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import GlobalButton from './globalButton/button'
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "../../assets/styles/componentStyles/support.module.scss";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import globalStyles from "../../assets/styles/global.module.scss";
import { UserContext, UserContextPayload } from '../../context/userContext';
export default function Support(props: any) {
    const { setFlagShowScheduleCall, handleCloseConsultationBar } = useContext(UserContext) as UserContextPayload
    return (
        <Grid container direction={"column"} className={styles.mainblock} alignItems={"start"}>
            {/* first block */}
            <Grid item className={`${styles.firstBlock} ${globalStyles.fullwidth}`}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Grid container gap="15px">
                            {/* <Grid item><ArrowBackIcon /></Grid> */}
                            <Grid item className={styles.heading}>Consult your AI Chatbot Expert</Grid>
                        </Grid></Grid>
                    <IconButton>
                        <CloseIcon className={globalStyles.clickable} onClick={handleCloseConsultationBar} />
                    </IconButton>
                </Grid></Grid>
            <Divider className={`${styles.dividerStyle} ${globalStyles.fullwidth}`} />
            {/* second block */}
            <Grid item className={styles.secondBlock}>
                Your dedicated expert will help you with chatbot setup and performance optimization
            </Grid>
            {/* third */}
            <Grid item className={styles.thirdBlock}>
                <Grid container>
                    <Grid item className={styles.thirdBlockHeading}>YOUR DEDICATED AI CHATBOT EXPERT</Grid>
                    <Grid item>
                        <Grid container gap="10px">
                            <Grid item><SupportAgentIcon /></Grid>
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Typography className={styles.nameStyle}>Krishna Desai</Typography>
                                    <Typography className={styles.emailStyle}>krishna@chronox.ai</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            {/* fourth */}
            <Grid item>
                <Grid container direction={"column"} className={styles.fourthBlock}>
                    <Typography className={styles.descriptionStyle}>Book a no-cost consultation with your dedicated AI Chatbot expert.</Typography>
                    {/* <br /> */}
                    <Typography className={`${styles.descriptionStyle} ${styles.lastBlock}`}>You can connect with them at every step of your way - whether you're looking for:
                        <ul style={{ marginTop: "15px" }}>
                            <li >Gen AI Chatbot Development</li>
                            {/* <p>Create sophisticated AI chatbots tailored to your business needs.</p> */}
                            <li >Real-Time Data Integration</li>
                            {/* <p>To integrate data with your chatbots for up-to-date information.
                            </p> */}
                            <li >Action & Tasks Automation</li>
                            {/* <p>Automate tasks and actions to enhance efficiency and reduce manual tasks.</p> */}
                            <li >WhatsApp Business API Support</li>
                            {/* <p>Get help on business profile setup and verification, support on Green Tick for whatsapp business, and implementing embedded signup flows.</p> */}
                            <li > Custom AI Chatbot Solutions</li>
                        </ul>
                    </Typography  >
                    {/* <br /> */}
                    <Typography className={`${styles.descriptionStyle} ${styles.lastBlock}`}>
                        Get personalized recommendations that align with your business goals and maximize your ROI with our AI chatbot solutions.
                    </Typography>
                </Grid>
            </Grid>
            {/* fifth */}
            <Grid item sx={{ minWidth: "180px !important", marginLeft: "20px" }}>
                <GlobalButton flagFullWidth className='primaryButtonStyle' buttonText={"Schedule a call"} sx={{ marginTop: "15px" }} onClick={() => { setFlagShowScheduleCall(true) }} />
            </Grid>
        </Grid>
    )
}
