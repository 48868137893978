import React, { useContext } from 'react'
import GlobalPopUp from '../../../../utility/globalPopUp'
import { Grid } from '@mui/material';
import ConfirmationPopover from '../../../../utility/confirmationPopover';
import { chatbotIntegrationAssignmentData } from '../../../../../models/axiosModel';
import GlobalButton from '../../../../utility/globalButton/button';
import IconsColor from '../../../../utility/iconsColor';
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext';
import FacebookInformationCard from '../../../../utility/facebookInformationCard';
import { useSearchParams } from 'react-router-dom';
import globalStyles from './../../../../../assets/styles/global.module.scss'

interface AddChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    page_id: string,
    meta_integration_type_id: string
}

interface ShowAvailablePhoneNumbersProps {
    flagShowAvailableNumbers: boolean,
    setFlagShowAvailableNumbers: React.Dispatch<React.SetStateAction<boolean>>;
    smallScreen: boolean,
    showConfirmationPopover: boolean,
    confirmationPopoverAnchorEl: null | HTMLButtonElement,
    handleCloseConfirmationPopover: () => void,
    AddChatbotIntegrationDetails: any,
    saveChatbotIntegrationData: (AddChatbotIntegrationDetails: AddChatbotIntegrationDetailsInterface) => void;
    availablePhoneNumberData: any[],
    handleRemoveChatbotIntegration: (meta_integration_id: number, meta_integration_type_id: number, page_id: string, display_phone_number: string) => void,
    displayPhoneNumber: string,
    launchWhatsAppSignup: () => void
    AddChatbotIntegration: (slack_integration_id: number, channel_id: number, display_page_name: string, event?: React.MouseEvent<HTMLButtonElement>) => void
}

function ShowAvailableSlackChannels(props: ShowAvailablePhoneNumbersProps) {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [searchParams] = useSearchParams()
    const { flagShowAvailableNumbers, setFlagShowAvailableNumbers, smallScreen, showConfirmationPopover, confirmationPopoverAnchorEl, handleCloseConfirmationPopover, AddChatbotIntegrationDetails, AddChatbotIntegration, availablePhoneNumberData, handleRemoveChatbotIntegration, launchWhatsAppSignup, displayPhoneNumber, saveChatbotIntegrationData } = props

    return (
        <div>
            <GlobalPopUp
                open={flagShowAvailableNumbers}
                handleClose={() => { setFlagShowAvailableNumbers(false) }}
                flagShowCloseIcon
                title='Add Slack Channels'
                paperMinWidth={smallScreen ? '85%' : '90vw'}
                height='90vh'
            >
                <Grid container gap="20px" direction={"column"} sx={{ padding: "30px" }} mt={{ xs: 1, lg: 0 }}>
                    <Grid item>
                        <Grid container gap="15px">
                            <ConfirmationPopover
                                open={showConfirmationPopover}
                                anchorEl={confirmationPopoverAnchorEl}
                                handleClose={handleCloseConfirmationPopover}
                                onConfirmation={() => { saveChatbotIntegrationData(AddChatbotIntegrationDetails) }}
                                descriptionText={<p>Are you sure you want to deploy <span style={{ fontWeight: "600" }}>{chatBotDetails?.chatbot_name}</span> on <span style={{ fontWeight: "600" }}>{displayPhoneNumber}</span> ?</p>}
                            />
                            {availablePhoneNumberData && availablePhoneNumberData.map((integrationData, index) => (
                                <>
                                    <Grid container direction={"column"} gap="15px" sx={{ width: "auto" }}>
                                        <Grid item className={globalStyles.globalHeaderFontStyle}>{integrationData.slack_user_real_name} - {integrationData.slack_team_name}</Grid>
                                        <Grid item>
                                            <Grid container gap="15px">
                                                {integrationData.slack_channels.map((channel: any) => {
                                                    return <FacebookInformationCard
                                                        integrationType='Slack'
                                                        key={index}
                                                        flagShowLinkedChatbot
                                                        flagDelete={false}
                                                        title={`Slack Account ${index + 1}`}
                                                        currentlySelected={channel.chatbot_slack_assignment_data
                                                            .some((chatbot: chatbotIntegrationAssignmentData) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')) || false}
                                                        showRemoveChatbotIntegration={false}
                                                        disabled={channel.chatbot_slack_assignment_data
                                                            && channel.chatbot_slack_assignment_data
                                                                .length > 0 ? true : false}
                                                        removeChatbotIntegration={() => { handleRemoveChatbotIntegration(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, integrationData.meta_integration_facebook_data?.page_id || "", integrationData.meta_integration_facebook_data?.page_name || "") }}
                                                        addChatbotIntegration={channel.chatbot_slack_assignment_data
                                                            .length == 0 ? (page_id: any, event: any) => {
                                                                if (event) {
                                                                    AddChatbotIntegration(integrationData.slack_integration_id, channel.slack_channel_id, channel.slack_channel_name, event)
                                                                } else {
                                                                    AddChatbotIntegration(integrationData.slack_integration_id, channel.slack_channel_id, channel.slack_channel_name)
                                                                }
                                                            } : undefined}
                                                        details={{
                                                            page_id: channel?.channel_id,
                                                            page_name: channel?.slack_channel_name,
                                                            page_category: channel?.slack_channel_purpose,
                                                            linked_chatbots: channel?.chatbot_slack_assignment_data

                                                        }}
                                                        flagIntegration={true}
                                                    />
                                                })
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <GlobalButton buttonText={"Slack Account"} onClick={launchWhatsAppSignup} className={'blackShadowedButton'}
                            startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                        />
                    </Grid>


                </Grid>
            </GlobalPopUp>
        </div>
    )
}

export default ShowAvailableSlackChannels