import { Divider, Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import "../../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import GlobalPageHeaderText from '../../../utility/globalPageHeaderText';
import globalStyles from "../../../../assets/styles/global.module.scss";
import styles from "../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import agent from '../../../../services/api';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import IconsColor from '../../../utility/iconsColor';
import TemplateCard from '../../../global/templateCard';
import GlobalButton from '../../../utility/globalButton/button';
import { IntegrationContext, IntegrationContextPayload } from '../../../../context/integrationContext';
import { Outlet, useNavigate } from 'react-router-dom';
import { globalIntegrationType } from '../../../../models/axiosModel';
import { GlobalTable } from '../../../utility/globalTable';
import deleteDoc from "../../../../assets/deleteDoc.svg";
import { checkForErrorCode, formatDate, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import linkedDetailsIcon from '../../../../assets/chatbotAssets/linkedDetails.svg'
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import whatsappIcon from '../../../../assets/Whatsapp.svg'
import instagramIcon from '../../../../assets/images/channels/instagram/instagram.png';
import highLevel from "../../../../assets/images/chatbot/integrations/gohighlevel.png";

interface chatsProps {
    mainSection?: boolean
}
interface integrationRowData {
    id: number,
    iconSrc: string,
    channel_icon: any,
    channel_name: string,
    channel_linked_details: any,
    channel_last_updated_timestamp: string,
}
export default function IntegrationWrapper(props: chatsProps) {
    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
            <Outlet />
        </Grid >

    )
}
