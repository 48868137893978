import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
// import styles from '../../../assets/styles/componentStyles/viewFacebookIntegration.module.scss'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
// import IntegrationDetails from './integrationDetails';
import IconsColor from '../../utility/iconsColor';
import ChatbotWhatsAppIntegrationSetup from '../../dashboard/chatbot/chatbotWhatsAppIntegrationSetup';
import GlobalButton from '../../utility/globalButton/button';
import facebookIcon from "../../../assets/facebook-logo.svg";
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import ChatbotFacebookIntegrationSetup from '../../dashboard/chatbot/chatbotFacebookIntegrationSetup';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { createMetaIntegrationPayload, globalIntegration } from '../../../models/axiosModel';
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants';
import FacebookIntegationDetails from './facebookIntegationDetails';
import messengerIcon from '../../../assets/chatbotAssets/facebookMessenger.svg'


interface checkFlag {
    flagPage?: boolean
}
const ViewFacebookIntegration = (props: checkFlag) => {
    const {
        setShowFacebookIntegrationDetails,
        showFacebookIntegrationDetails,
        metaCredentials
    } = useContext(MetaContext) as MetaContextPayload;

    const {
        getIntegrationList,
        integrationList,
        globalIntegrationTypeList,

        // FACEBOOK
        facebookIntegrationDetails,
        setFacebookIntegrationDetails,
        setIntegrationList,

    } = useContext(IntegrationContext) as IntegrationContextPayload;

    const {
        setError,
        error,
        setSuccess,
        setFlagLoading,
        userDetail
    } = useContext(UserContext) as UserContextPayload;

    // CONST
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)')
    const laptopScreen = useMediaQuery('(max-width : 1600px) and (min-width : 900px)')

    const [responsePayload, setResponsePayload] = useState<any>({
        page_id: '',
        page_name: '',
        page_category: '',
        access_token_value: ''
    })


    useLayoutEffect(() => {
        (async () => {
            await getIntegrationList()
        })()
    }, [])

    useEffect(() => {
        loadFacebookSDK();
    }, []);

    const loadFacebookSDK = () => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        let fb_init = metaCredentials?.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "facebook")?.fb_init
        window.fbAsyncInit = () => {
            window.FB.init(fb_init);
        };
    };

    useEffect(() => {
        if (searchParams.has('id')) {
            if (integrationList && integrationList.length > 0) {
                let id = searchParams.get('id')
                let integration = integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "facebook").find((integration: any) => integration.meta_integration_id == id)
                if (integration) {
                    setFacebookIntegrationDetails(integration)
                    setShowFacebookIntegrationDetails(true)
                } else {
                    navigate('/channels')
                }
            }
        } else {
            setFacebookIntegrationDetails(null)
            setShowFacebookIntegrationDetails(false)
        }
    }, [integrationList])

    useEffect(() => {
        const loadFacebookSDK = () => {
            let facebookConfig = metaCredentials?.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "facebook")
            if (window.FB) {
                console.log("FB SDK already loaded");
                return;
            }

            window.fbAsyncInit = function () {
                window.FB.init(facebookConfig?.fb_init);
                console.log("facebookConfig", facebookConfig)
                console.log("FB SDK initialized");
            };

            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            document.head.appendChild(script);
        };

        loadFacebookSDK();
    }, []);

    const loginToFacebook = () => {
        debugger;
        window.FB.login((response: any) => {
            if (response.authResponse) {
                console.log("Welcome! Fetching your information....");
                window.FB.api("/me", (res: any) => {
                    console.log("res 1:", res);
                    console.log("Good to see you, " + res.name + ".");
                    getPagesList();
                });
            } else {
                console.log("User cancelled login or did not fully authorize.");
            }
        }, { scope: "pages_show_list, pages_messaging, pages_manage_metadata, business_management" });
    };

    const getPagesList = () => {
        window.FB.api("/me/accounts", (result: any) => {
            console.log("result: ", result);
            if (result && !result.error) {
                const pages = result.data;
                if (pages.length) {
                    const transformedData = pages?.map((page: any) => ({
                        access_token: page.access_token,
                        page_id: page.id,
                        page_name: page.name,
                        page_category: page.category,
                        page_categoryList: page.category_list.map((cat: any) => ({
                            id: cat.id,
                            name: cat.name
                        }))
                    }));
                    setResponsePayload(transformedData)
                }
                else {
                    setError({ ...error, flagShowError: true, message: "No page was selected while integrating." })
                }
            }
            else {
                console.log("Error fetching page access token:", result.error);
                // setError({ ...error, flagShowError: true, message: "No page was selected while integrating." })  
            }
        });
    };

    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    console.log('User logged out successfully', logoutResponse);
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };
    console.log("showFacebookIntegration", showFacebookIntegrationDetails)
    useEffect(() => {
        (async () => {
            if (responsePayload && responsePayload.length > 0) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
                let metaIntegrationTypeId = globalIntegrationTypeList.find((type) => type.meta_integration_type_name.toLowerCase() === "facebook")?.meta_integration_type_id ?? Number(process.env.REACT_APP_FACEBOOK_META_INTEGRATION_TYPE_ID);

                if (!metaIntegrationTypeId) {
                    console.error("Meta Integration Type ID for Facebook is undefined");
                    return;
                }

                const facebookPayload: any = {
                    meta_integration_type_id: metaIntegrationTypeId,
                    meta_integration_facebook_data: responsePayload?.map((page: any) => ({
                        page_id: page?.page_id,
                        page_name: page?.page_name,
                        page_category: page?.page_category,
                        access_token_value: page?.access_token,
                    })),
                    meta_data: JSON.stringify(responsePayload)
                };


                const response = await agent.createMetaIntegration.post(facebookPayload);
                if (response.status) {
                    setIntegrationList((prevState: any) => ([...prevState, ...response.data]))
                    setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
                    let sortedData = response.data.sort((a: globalIntegration, b: globalIntegration) => new Date(b.created_at_timestamp).getTime() - new Date(a.created_at_timestamp).getTime())
                    navigate(`/channels/viewintegration/facebook?id=${sortedData[0].meta_integration_id}`)
                    setFacebookIntegrationDetails(sortedData[0])
                    setShowFacebookIntegrationDetails(true)
                }
                else {
                    let errorCode = checkForErrorCode(response)
                    if (response.message == "canceled") {
                    }
                    else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    safeLogout()
                }
                setResponsePayload({
                    page_id: '',
                    page_name: '',
                    page_category: '',
                    access_token_value: ''
                })
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

            }
        })();
    }, [responsePayload]);

    const innerHeight = smallScreen ? (window.innerHeight - 90) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 100) : (window.innerHeight - 110);
    const integrationViewInnerHeight = smallScreen ? (window.innerHeight - 110) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 140);

    return (
        <>
            <Grid item
                className={showFacebookIntegrationDetails == false ? `${globalStyles.fullwidth} ${styles.integrationDefaultView}` : `${globalStyles.fullwidth} ${styles.integrationView}`}
                height={showFacebookIntegrationDetails == false ? `${innerHeight}px !important` : `${integrationViewInnerHeight}px !important`}
            >
                <Grid container >
                    {showFacebookIntegrationDetails == false &&
                        <Grid item className={globalStyles.fullwidth}>
                            <Grid container alignItems={"start"} gap={"13px"} flexWrap={"nowrap"} flexDirection={{ xs: "row-reverse", md: "row" }}>
                                <Grid item className={styles.integrationContent}>
                                    <img alt="" src={messengerIcon} className={styles.whatsappIntegrationIcon} />
                                </Grid>
                                <Grid item>
                                    <Grid container direction={"column"} gap={{ xs: "8px", md: "4px" }}>
                                        <Grid item>
                                            <Typography className={globalStyles.whatsappIntegrationFontStyle}>Facebook Integration</Typography>
                                        </Grid>
                                        <Grid item>
                                            <span className={globalStyles.whatsappIntegrationSubFontStyle}>Integrate AI Chatbot into your Facebook Business account to boost customer engagement and efficiency.</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {(showFacebookIntegrationDetails) ?
                        <>
                            <Grid item className={globalStyles.fullwidth} xs={12} lg={12} xl={12}>
                                <Grid container direction={"column"} gap={3} flexWrap={"wrap"}>
                                    <FacebookIntegationDetails flagPage={props.flagPage} />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            {showFacebookIntegrationDetails == false &&
                                <Grid item className={globalStyles.fullwidth} xs={12} lg={8} xl={6} mt={4}>
                                    <Grid container direction={"column"} gap={2} flexWrap={"nowrap"} pt={1} className={styles.integrationWorking}>
                                        <Grid item>
                                            <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                                                <Grid item>
                                                    <span className={globalStyles.whatsappIntegrationFontStyle}>Prerequisites for Facebook Integration</span>
                                                </Grid>
                                                <Grid item className={styles.workingIconStyle}>
                                                    <IconsColor iconName="ideaIcon" height={"30"} width={"30"} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <ChatbotFacebookIntegrationSetup
                                            launch={loginToFacebook}
                                            viewIntegration={true}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item className={styles.integrateGridStyles} marginBlock={"20px"}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <GlobalButton
                                                        buttonText={"Integrate Now"}
                                                        className="primaryButtonStyle"
                                                        onClick={() => {
                                                            loginToFacebook()
                                                        }}
                                                        sx={{ padding: "6px 20px !important" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </>
                    }
                </Grid>
            </Grid>

        </>
    )
}

export default ViewFacebookIntegration