import { Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
// import { messageFormats } from '../../../utils/constants'
import FormatCard from '../../global/formatCard'
import { useNavigate, useSearchParams } from 'react-router-dom'
import imageCardCarousel from '../../../assets/images/messageFormats/imageCardCarousel.svg'
import informationCardCarousel from '../../../assets/images/messageFormats/informationCardCarousel.svg'
import doctorCard from '../../../assets/images/messageFormats/imageCard_DoctorCard.svg'
import productCard from '../../../assets/images/messageFormats/imageCard_productCard.svg'
import foodCard from '../../../assets/images/messageFormats/imageCard_FoodCard.svg'
import MessageFormatForms from './messageFormatForms'
import messageFormat1 from "../../../assets/images/messageFormats/messageFormat1.png";
import messageFormat2 from "../../../assets/images/messageFormats/messageFormat2.png";
import messageFormat3 from "../../../assets/images/messageFormats/messageFormat3.png";
import messageFormat4 from "../../../assets/images/messageFormats/messageFormat4.png";
import styles from '../../../assets/styles/componentStyles/messageFormatForms/messageFormats.module.scss';
import infoCardEg1 from "../../../assets/images/messageFormats/infoCardEg1.png";
import infoCardEg2 from "../../../assets/images/messageFormats/InfoCardEg2.png";
import infoCardEg3 from "../../../assets/images/messageFormats/InfoCardEg3.png";
import '../../../assets/styles/componentStyles/chatbotStyles/chatbot.css';
import StaticMessageFormatGlobal from './staticMessageFormatGlobal';
import { MessageFormatContext, MessageFormatContextPayload } from '../../../context/messageFormatContext'

const MessageFormat = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const chatbot_id = searchParams.get('chatbot_id')
    const message_format_id = searchParams.get('message_format_id')
    const {currentSubTab, setCurrentSubTab} = useContext(MessageFormatContext) as MessageFormatContextPayload

    const handleFormats = (message_format: any) => {
        navigate(`${window.location.pathname}?chatbot_id=${chatbot_id}&message_format_id=${message_format}`)
    }

    const imageCardCarouselFormats = [
        {
            message_format_id: 5,
            message_format_name: "Image Card Carousal",
            message_img: imageCardCarousel,
            message_format_examples: [
                {
                    message_format_sub_id: 5.1,
                    message_format_sub_name: "Doctor Card",
                    message_format_sub_img: doctorCard
                },
                {
                    message_format_sub_id: 5.2,
                    message_format_sub_name: "Product Card",
                    message_format_sub_img: productCard
                },
                {
                    message_format_sub_id: 5.3,
                    message_format_sub_name: "Food Card",
                    message_format_sub_img: foodCard
                }
            ]

        },
        {
            message_format_id: 5.1,
            message_format_name: "Image Card Carousal",
            message_sub_heading: "Lorem ipsum dolor sit amet consectetur. Quis iaculis amet massa leo amet augue a nec. Id nibh eleifend cursus eget diam neque nunc elementum.",
            message_img: messageFormat1,
            flagDisable: true,
            message_format_examples: [
                {
                    message_format_sub_id: 5.1,
                    message_format_sub_name: "Doctor Card",
                    message_format_sub_img: doctorCard
                },
                {
                    message_format_sub_id: 5.2,
                    message_format_sub_name: "Product Card",
                    message_format_sub_img: productCard
                },
                {
                    message_format_sub_id: 5.3,
                    message_format_sub_name: "Food Card",
                    message_format_sub_img: foodCard
                }
            ]

        },
        {
            message_format_id: 5.2,
            message_format_name: "Image Card Carousal",
            message_sub_heading: "Lorem ipsum dolor sit amet consectetur. Quis iaculis amet massa leo amet augue a nec. Id nibh eleifend cursus eget diam neque nunc elementum.",
            message_img: messageFormat2,
            flagDisable: true,
            message_format_examples: [
                {
                    message_format_sub_id: 5.1,
                    message_format_sub_name: "Doctor Card",
                    message_format_sub_img: doctorCard
                },
                {
                    message_format_sub_id: 5.2,
                    message_format_sub_name: "Product Card",
                    message_format_sub_img: productCard
                },
                {
                    message_format_sub_id: 5.3,
                    message_format_sub_name: "Food Card",
                    message_format_sub_img: foodCard
                }
            ]

        },
    ]
    const quoteCardFormats = [
        {
            flagDisable: true,
            message_format_id: 6,
            message_format_name: "Information Card Carousal",
            message_sub_heading: "Lorem ipsum dolor sit amet consectetur. Quis iaculis amet massa leo amet augue a nec. Id nibh eleifend cursus eget diam neque nunc elementum.",
            message_img: informationCardCarousel,
            message_format_examples: [
                {
                    message_format_sub_id: 6.1,
                    message_format_sub_name: "Insurance Quote Card",
                    message_format_sub_img: infoCardEg1
                },
                {
                    message_format_sub_id: 6.2,
                    message_format_sub_name: "Transaction Card",
                    message_format_sub_img: infoCardEg2
                },
                {
                    message_format_sub_id: 6.3,
                    message_format_sub_name: "Rating Card",
                    message_format_sub_img: infoCardEg3
                }
            ]
        },
        {
            message_format_id: 6,
            flagDisable: true,
            message_format_name: "Information Card Carousal",
            message_sub_heading: "Lorem ipsum dolor sit amet consectetur. Quis iaculis amet massa leo amet augue a nec. Id nibh eleifend cursus eget diam neque nunc elementum.",
            message_img: messageFormat3,
            message_format_examples: []
        },
        {
            message_format_id: 6,
            flagDisable: true,
            message_format_name: "Information Card Carousal",
            message_sub_heading: "Lorem ipsum dolor sit amet consectetur. Quis iaculis amet massa leo amet augue a nec. Id nibh eleifend cursus eget diam neque nunc elementum.",
            message_img: messageFormat4,
            message_format_examples: []
        }
    ]
    return (
        <>
            {currentSubTab === "Static Message Format" &&
                <StaticMessageFormatGlobal />
            }

            {currentSubTab === "Dynamic Message Format" &&
                <>
                    {!message_format_id &&
                        <Grid container direction="column" sx={{ padding: "25px" }} gap={"36px"} >
                            {/* image card carousel */}
                            <Grid item>
                                <Grid container gap="10px" direction={"column"}>
                                    {/* heading */}
                                    <Grid item className={styles.messageFormatHeadingStyle}>
                                        Image Card Carousel
                                    </Grid>
                                    {/* cards */}
                                    <Grid item>
                                        <Grid container gap="25px" sx={{ flexWrap: "nowrap" }}>
                                            {imageCardCarouselFormats.map((format) =>
                                                <FormatCard format={format} handleFormats={handleFormats} />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* quote card carousel */}
                            <Grid item>
                                <Grid container gap="10px" direction={"column"}>
                                    {/* heading */}
                                    <Grid item className={styles.messageFormatHeadingStyle}>
                                        Information Card Carousel
                                    </Grid>
                                    {/* cards */}
                                    <Grid item>
                                        <Grid container gap="25px" sx={{ flexWrap: "nowrap" }}>
                                            {quoteCardFormats.map((format) =>
                                                <FormatCard format={format} handleFormats={handleFormats} />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {message_format_id &&
                        <Grid container sx={{ paddingLeft: "25px" }}>
                            <MessageFormatForms />
                        </Grid>
                    }
                </>
            }
        </>
    )
}

export default MessageFormat
