import React from 'react'
import LazyLoad from 'react-lazyload'


const LazyLoadingImage = (props: any) => {
  return (
    <>
        <LazyLoad height={200} offset={100} once>
            {props.flagShow && <img src={props.src} className={props.className} />}
        </LazyLoad>
    </>
  )
}

export default LazyLoadingImage
