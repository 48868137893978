import { Autocomplete, Button, Checkbox, Grid, InputLabel, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles from '../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalTextField from '../../utility/globalTextfield';
import Required from '../../utility/required';
import connectorstyle from '../../../assets/styles/componentStyles/dataconnector.module.scss'
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';

interface createReqHeaderPayload {
    function_header_id?: number | null,
    function_header_key: string,
    function_header_value: string,
}
interface requestHeaderProps {
    flagKeyExistError: any;
    setFlagKeyExistError: any;
    createReqHeaderPayload: createReqHeaderPayload
    onChangePayload: (value: string, keyName: string, objType: string) => void,
    handleSavReqHeader: (objName: string) => void,
    cancelSaveReqHeader: (objName: string) => void
}
const RequestHeader = (props: requestHeaderProps) => {
    const { functionParameterTypeDataList } = useContext(GenericDataContext) as GenericDataContextPayload;

    const getSaveBtnDisableVal = () => {
        if (props.createReqHeaderPayload.function_header_key.trim() !== "" && props.createReqHeaderPayload.function_header_value.trim() !== "") {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <Grid container className={`${styles.dataConnectorPopupSpacing}`}>
                <Grid item xs={12} className={`${styles.dataConnectorFormPopup} ${styles.dataConnectorPopupBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography
                                component='span'
                                className={styles.dataConnectorHeaderText}
                            >
                                Add New Request Headers
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.dataconnectorHeaderDivider} />
                        </Grid>
                        {/* 
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <GlobalForm
                                fields={createNoteFormData.data.global_form_field}
                                submitFunction={formSubmitFunction}
                                checkFormSubmit={renderValue}
                                handleCloseSnackBar={handleCloseSnackBar}
                                pageThemeData={createNoteFormData.data.doc_page_theme[0]}
                                formData={createNoteFormData.data}
                                submissionID={submissionID}
                                setFieldValue={setFieldValue}
                                recaptchaFlag="true"
                                defaultLoader={false}
                                formEditable={true}
                            />
                        </Grid>
                    </Grid> */}

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} className={connectorstyle.createFormField} gap={"20px"} p={1}>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>New Key</InputLabel><Required /></Grid>
                                                <Grid item><GlobalTextField placeholder='Enter New Key' value={props.createReqHeaderPayload.function_header_key} onChange={(event) => { props.setFlagKeyExistError((prevState: any) => ({ ...prevState, flagHeaderKey: false })); props.onChangePayload(event.target.value, 'function_header_key', 'reqHeader') }} /></Grid>
                                                {props.flagKeyExistError.flagHeaderKey && <span className={globalStyles.validationText}>This key already exist!</span>}
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Value</InputLabel><Required /></Grid>
                                                <Grid item><GlobalTextField value={props.createReqHeaderPayload.function_header_value} onChange={(event) => { props.onChangePayload(event.target.value, 'function_header_value', 'reqHeader') }} placeholder='Enter Value' /></Grid>
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_description} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: "0px" }} mt={2}>
                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>

                                <Grid item xs={12} className={`${connectorstyle.globalHeadRightSubItem} `}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "center" }} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbCancelButton}
                                                onClick={() => { props.cancelSaveReqHeader('reqHeader') }}
                                            // disabled={chatBotDetails?.chatbot_flag_edit == true ? false : true}
                                            // sx={{ opacity: chatBotDetails?.chatbot_flag_edit ? "" : "0.5" }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ marginLeft: "15px" }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                fullWidth
                                                disabled={getSaveBtnDisableVal()}
                                                className={connectorstyle.cbSaveButton}
                                                onClick={() => { props.handleSavReqHeader('reqHeader') }}
                                                // disabled={getSaveButtonDisableFlag()}
                                                sx={{ opacity: getSaveBtnDisableVal() ? "0.5" : "" }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RequestHeader
