import { Checkbox, CircularProgress, Grid, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import style from "../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignStyle.module.scss";
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import checkboxStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createCampaignResponse } from '../../../models/axiosModel';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CampaignAnalysis from './campaignAnalysis';

interface campaignTableInterface {
    getCampaignDataApiCall: (campaign_id?: number) => Promise<void>
}

const CampaignTable = (props: campaignTableInterface) => {
    const navigate = useNavigate();
    const { getCampaignDataApiCall } = props
    const { whatsappCampaignListdData } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;
    const { flagLoading, setFlagLoading, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;
    const innerHeight = (window.innerHeight - 180);

    const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [activeFilters, setActiveFilters] = useState<string[] | null>(["draft", "in progress"])

    const [searchParams, setSearchParams] = useSearchParams();
    const campaign_id = searchParams.get('campaign_id');

    const menuItem = [
        {
            label: "Clone Campaign",
            action: (e: any) => { },
        },
        {
            label: "Delete",
            action: (e: any) => { },
        }
    ]
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
        setCurrentRowIndex(rowIndex);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setCurrentRowIndex(null);
        setAnchorEl(null);
    };

    const handleBorderColor = (executionStatus: string) => {
        let executionStatusName = executionStatus.toLowerCase();
        switch (executionStatusName) {
            case "draft":
                return "3px solid rgba(42, 165, 226, 1)"
            case "completed":
                return "3px solid rgba(15, 185, 21, 1)"
            default:
                return "3px solid rgba(226, 162, 42, 1)"
        }
    }

    const handleSortByUpdatedAt = (whatsappCampaignListdData: createCampaignResponse[]) => {
        return [...whatsappCampaignListdData].sort((a, b) => {
            const timestampA = moment(a.updated_at_timestamp ? a.updated_at_timestamp : a.created_at_timestamp).valueOf();
            const timestampB = moment(b.updated_at_timestamp ? b.updated_at_timestamp : b.created_at_timestamp).valueOf();

            if (isNaN(timestampA) || isNaN(timestampB)) return 0;
            if (timestampA === timestampB) {
                const timeA = moment(a.updated_at_timestamp).format('HH:mm:ss');
                const timeB = moment(b.updated_at_timestamp).format('HH:mm:ss');
                return timeB.localeCompare(timeA);
            }
            return timestampB - timestampA;
        });
    };

    const handleCheckBoxData = (fieldName: string) => {
        if(activeFilters?.includes(fieldName)) {
            setActiveFilters(activeFilters.filter((filter: string) => filter != fieldName))
        }
        else {
            if(activeFilters) {
                setActiveFilters([...activeFilters, fieldName])
            }
            else {
                setActiveFilters([fieldName])
            }
        }
    }

    const handleFilteredCampaignData = (whatsappCampaignListdData: createCampaignResponse[]) => {

        const filteredData = whatsappCampaignListdData.filter((campaign) =>
            activeFilters?.includes(campaign.campaign_execution_status_name.toLowerCase())
        );

        return handleSortByUpdatedAt(filteredData);
    };

    const handleViewCampaign = async (whatsappCampaignListdData: createCampaignResponse) => {
        // debugger;
        // await getCampaignDataApiCall(Number(whatsappCampaignListdData.campaign_id))
        let executionStatusName = whatsappCampaignListdData.campaign_execution_status_name.toLowerCase();
        switch (executionStatusName) {
            case "draft":
                return navigate(`/whatsappcampaign/createnewcampaign?campaign_id=${whatsappCampaignListdData.campaign_id}`)
            case "completed":
                return navigate(`${window.location.pathname}?campaign_id=${whatsappCampaignListdData.campaign_id}`)
            default:
                return navigate(`${window.location.pathname}?campaign_id=${whatsappCampaignListdData.campaign_id}`)
        }
    }

    return (
        <>
            {campaign_id ?
                <Grid item xs={12} sm={12} className={style.tableGridItem} sx={{ width: "100%" }}>
                    <CampaignAnalysis getCampaignDataApiCall={getCampaignDataApiCall} />
                </Grid>
                :
                <Grid item xs={12} sm={12} className={style.tableGridItem}>
                    <Grid container direction={"column"} gap={"12px"}>
                        <Grid item mt={{lg:1}}>
                            <Grid container direction={"row"} gap={"20px"}>
                                <Grid item>
                                    <Grid container direction={"row"} gap={1} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Checkbox
                                                className={style.checkBoxFilter}
                                                sx={{ display: "inline !important", 'path': { fill: "#6A097D" } }}
                                                // disabled={!editIntegrationData ? true : false}
                                                checked={activeFilters?.includes("draft")}
                                                disableRipple
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxData("draft")}
                                            />
                                        </Grid>
                                        <Grid item mt={"1px"}>
                                            <span className={`${checkboxStyles.audioFieldLabel} ${checkboxStyles.darkFontWeightField}`}>Draft</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"row"} gap={1} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Checkbox
                                                className={style.checkBoxFilter}
                                                sx={{ display: "inline !important", 'path': { fill: "#6A097D" } }}
                                                // disabled={!editIntegrationData ? true : false}
                                                disableRipple
                                                checked={activeFilters?.includes("in progress")}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxData("in progress")}
                                            />
                                        </Grid>
                                        <Grid item mt={"1px"}>
                                            <span className={`${checkboxStyles.audioFieldLabel} ${checkboxStyles.darkFontWeightField}`}>In Progress</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"row"} gap={1} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Checkbox
                                                className={style.checkBoxFilter}
                                                sx={{ display: "inline !important", 'path': { fill: "#6A097D" } }}
                                                // disabled={!editIntegrationData ? true : false}
                                                disableRipple
                                                checked={activeFilters?.includes("completed")}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxData("completed")}
                                            />
                                        </Grid>
                                        <Grid item mt={"1px"}>
                                            <span className={`${checkboxStyles.audioFieldLabel} ${checkboxStyles.darkFontWeightField}`}>Completed</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sx={{ width: "100%" }}>
                            <TableContainer className={style.campaignTableContainer} sx={{ maxHeight: `${innerHeight}px !important` }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell} ${style.stickeyColumn}`} sx={{ zIndex: "11 !important" }}>Campaign Title</TableCell>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Template Name</TableCell>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Total Contacts</TableCell>
                                            {/* <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Sent</TableCell>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Pending</TableCell>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Failed</TableCell> */}
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.minWidthTableCell}`}>Updated At</TableCell>
                                            {/* <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${dashboardStyles.viewButtonColumnHeadingStyle}`}></TableCell> */}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {whatsappCampaignListdData && whatsappCampaignListdData.length > 0 ?
                                            handleFilteredCampaignData(whatsappCampaignListdData).length > 0 ? (
                                                handleFilteredCampaignData(whatsappCampaignListdData).map((campaign) => {
                                                    return (
                                                        <TableRow className={dashboardStyles.cellRow} key={campaign.campaign_id}>
                                                            <TableCell
                                                                className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor} ${style.stickeyColumn}`}
                                                                sx={{
                                                                    background: "white", borderLeft: handleBorderColor(campaign.campaign_execution_status_name)
                                                                }}
                                                                onClick={() => handleViewCampaign(campaign)}
                                                            >
                                                                {campaign.campaign_title}
                                                            </TableCell>
                                                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{campaign.whatsapp_template_name}</TableCell>
                                                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{campaign.total_campaign_contact}</TableCell>
                                                            {/* <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{campaign.total_sent_campaign_contact}</TableCell>
                                                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{campaign.total_pending_campaign_contact}</TableCell>
                                                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{campaign.total_failed_campaign_contact}</TableCell> */}
                                                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.minWidthTableCell} ${style.tableCellColor}`}>{formatDateAccordingToTimezoneUsingMoment(campaign.updated_at_timestamp ? campaign.updated_at_timestamp : campaign.created_at_timestamp, userDetail.timezone_hour)}</TableCell>
                                                            {/* <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.tableCellColor} ${dashboardStyles.viewButtonColumnStyle}`} sx={{ textAlign: "end" }}>
                                                                <MoreVertIcon
                                                                    sx={{ cursor: "pointer" }}
                                                                    onClick={(e: any) => { handleMenuOpen(e, campaign.campaign_id) }}
                                                                />
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl) && campaign.campaign_id === currentRowIndex}
                                                                    onClose={handleMenuClose}
                                                                >
                                                                    {menuItem.map((item, index) => {
                                                                        return (
                                                                            <MenuItem
                                                                                className={globalStyles.menuItemStyle}
                                                                                key={index}
                                                                                sx={{ fontSize: "14px" }}
                                                                                onClick={(e) => { handleMenuClose(); item.action(campaign); }}
                                                                            >
                                                                                <span>
                                                                                    {item.label}
                                                                                </span>
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Menu>
                                                            </TableCell> */}
                                                        </TableRow>
                                                    );
                                                }))
                                                :
                                                (
                                                    <TableRow className={dashboardStyles.cellRow}>
                                                        <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                                            No Data Available
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            :
                                            <TableRow className={dashboardStyles.cellRow}>
                                                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default CampaignTable;
