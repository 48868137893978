import React, { useContext, useEffect, useState } from 'react'
import AccountInformationCard from '../../../utility/accountInformationCard';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import IconsColor from '../../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import { useSearchParams } from 'react-router-dom';
import agent from '../../../../services/api';
import { MetaContext, MetaContextPayload } from '../../../../context/metaContext';
import { chatbotIntegrationAssignmentData, CommonResponseModel, createMetaIntegrationPayload, globalIntegration, integrationData, metaIntegrationAccessTokenData, metaIntegrationPhoneNumberData, metaIntegrationPhoneNumberDataModel } from '../../../../models/axiosModel';
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import GlobalPopUp from '../../../utility/globalPopUp';
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import instagramIcon from "../../../../assets/images/channels/instagram/instagram.png";
import ChatbotWhatsAppIntegrationSetup from '../chatbotWhatsAppIntegrationSetup';
import GlobalButton from '../../../utility/globalButton/button';
import { IntegrationContext, IntegrationContextPayload } from '../../../../context/integrationContext';
import globalStyles from '../../../../assets/styles/global.module.scss'
import integrationStyles from '../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import FacebookInformationCard from '../../../utility/facebookInformationCard';
import ShowAvailableInstagramPages from './showAvailableInstagramPages';
import ChatbotInstagramIntegrationSetup from '../integrations/chatbotInstagramIntegrationSetup';

interface linkedPageData {
    chatbot_integration_assignment_data: chatbotIntegrationAssignmentData[],
    created_at_timestamp: string,
    instagram_id: string,
    instagram_username: string,
    meta_integration_access_token_data: metaIntegrationAccessTokenData,
    meta_integration_facebook_flag_webhook: boolean,
    meta_integration_facebook_id: number,
    page_category: string,
    page_id: string,
    page_name: string,
    meta_integration_id: number,
    meta_integration_type_id: number,
}
declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}

interface addChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    instagram_id: string,
    page_id: string,
    meta_integration_type_id: string
}
const ChatbotInstagramIntegration = () => {
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const mediaScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)')
    const [flagFacebookIntegrationExist, setflagFacebookIntegrationExist] = useState<boolean | null>(null)
    const [searchParams] = useSearchParams();
    const { setSuccess, success, setError, error, setFlagLoading, flagLoading, userDetail, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotIntegrationData, getChatbotIntegrationData, setGetChatbotIntegrationData, metaCredentials } = useContext(MetaContext) as MetaContextPayload;
    const { showIntegrationDetails, setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { getIntegrationList, globalIntegrationTypeList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [phoneNumberIntegrate, setPhoneNumberIntegrate] = useState<any>()
    const [linkedPagesData, setLinkedPagesData] = useState<linkedPageData[]>([])
    const [flagShowAvailablePages, setFlagShowAvailablePages] = useState(false)
    const [availablePagesData, setAvailablePagesData] = useState<globalIntegration[]>([])
    const [flagShowpopup, setFlagShowpopup] = useState(false)
    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        meta_integration_id: "",
        chatbot_id: "",
        instagram_id: "",
        page_id: "",
        meta_integration_type_id: ""
    })
    const [removeChatbotIntegrationDetails, setRemoveChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        chatbot_id: "",
        meta_integration_type_id: "",
        instagram_id: "",
        page_id: "",
        meta_integration_id: ""
    })
    const [responsePayload, setResponsePayload] = useState<any>({
        page_id: '',
        page_name: '',
        page_category: '',
        access_token_value: ''
    })
    useEffect(() => {
        getIntegrationList()
    }, [])

    useEffect(() => {
        let ids: any = []
        if (integrationList !== null && integrationList.length > 0) {
            let facebookFilter = integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name == "Instagram" && integration.meta_integration_facebook_data?.instagram_id);
            facebookFilter?.map((data) => {
                data?.meta_integration_facebook_data?.chatbot_integration_assignment_data.map((assignmentData) => {
                    ids.push(String(assignmentData.chatbot_id))
                })
            })
            let validID = ids.includes(chatBotDetails?.chatbot_id);

            if (validID && facebookFilter.length > 0 && integrationList.flatMap((integration: globalIntegration) => ({ ...integration.meta_integration_facebook_data, ...integration })).filter((data) => data).length > 0) {
                setflagFacebookIntegrationExist(true)
                setShowIntegrationDetails(true)
            } else {
                setflagFacebookIntegrationExist(false)
                setShowIntegrationDetails(false)
            }
        } else {
            setflagFacebookIntegrationExist(false)
            setShowIntegrationDetails(false)
        }

    }, [getChatbotIntegrationData, chatBotDetails, window.location.href, integrationList])

    useEffect(() => {
        if (integrationList) {
            let temp: any = integrationList.filter((data) => data.meta_integration_type_assignment_data.meta_integration_type_name === "Instagram" && data.meta_integration_facebook_data?.instagram_id).flatMap((integration) => ({ ...integration.meta_integration_facebook_data, meta_integration_id: integration.meta_integration_id, meta_integration_type_id: integration.meta_integration_type_assignment_data.meta_integration_type_id })).flatMap((facebookData) => ({ ...facebookData, meta_integration_id: facebookData.meta_integration_id, meta_integration_type_id: facebookData.meta_integration_type_id }))
            if (temp) {
                let linkedPhoneNumber: linkedPageData[] = temp.filter((phoneNumber: linkedPageData) => phoneNumber?.chatbot_integration_assignment_data.some((chatbot) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')))
                if (temp) {
                    if (linkedPhoneNumber) {
                        setLinkedPagesData(linkedPhoneNumber)
                    }
                    setAvailablePagesData(integrationList)
                }
            }
        }
    }, [integrationList])
    const [codeValue, setCodeValue] = useState({
        code: ""
    })
    const [whatsappData, setWhatsappData] = useState({
        waba_id: "",
        phone_number_id: ""
    })

    useEffect(() => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        if (metaCredentials) {
            let facebookConfig = metaCredentials.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "instagram")
            window.fbAsyncInit = function () {
                // @ts-ignore
                FB.init(facebookConfig.fb_init);
            };
        }
    }, [metaCredentials]);
    useEffect(() => {
        const sessionInfoListener = (event: MessageEvent) => {
            if (event.origin !== "https://www.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                // Handle the data from the event
                // console.log("session info data", data);


                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        setWhatsappData({ ...whatsappData, phone_number_id: phone_number_id, waba_id: waba_id })
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                    }
                }
            } catch (error) {
                // Handle errors or non-JSON messages
            }
        };
        window.addEventListener('message', sessionInfoListener);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, []);
    useEffect(() => {
        (async () => {
            if (responsePayload && responsePayload.page_id !== "" && !flagShowpopup) {
                console.log(responsePayload, "responsePayload")
                let metaIntegrationTypeId = globalIntegrationTypeList.find((type) => type.meta_integration_type_name.toLowerCase() === "instagram")?.meta_integration_type_id ?? Number(process.env.REACT_APP_FACEBOOK_META_INTEGRATION_TYPE_ID);

                if (!metaIntegrationTypeId) {
                    console.error("Meta Integration Type ID for Facebook is undefined");
                    return;
                }

                const facebookPayload: any = {
                    meta_integration_type_id: metaIntegrationTypeId,
                    meta_integration_facebook_data: [{
                        page_id: responsePayload?.page_id,
                        page_name: responsePayload?.page_name,
                        page_category: responsePayload?.page_category,
                        access_token_value: responsePayload?.access_token_value,
                        instagram_username: responsePayload.instagram_username,
                        instagram_id: responsePayload.instagram_id
                    }],
                    meta_data: JSON.stringify(responsePayload)
                };


                const response = await agent.createMetaIntegration.post(facebookPayload);
                if (response.status) {
                    setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
                    let chatbot_id = searchParams.get('chatbot_id')
                    let data = response.data[0]
                    if (chatbot_id) {
                        let payload = {
                            chatbot_id: chatbot_id,
                            meta_integration_id: data.meta_integration_id,
                            meta_integration_type_id: data.meta_integration_type_assignment_data.meta_integration_type_id,
                            page_id: data.meta_integration_facebook_data.page_id,
                            instagram_id: data.meta_integration_facebook_data.instagram_id,
                        }
                        AddChatbotIntegration(payload)
                    }
                    // setFacebookIntegrationDetails(response)
                }
                else {
                    let errorCode = checkForErrorCode(response)
                    if (response.message == "canceled") {
                    }
                    else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    safeLogout()
                }
            }
        })();
    }, [responsePayload]);

    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };

    function fetchInstagramAccountDetails(igAccountId: any, pageData: any) {
        debugger;
        window.FB.api(`/${igAccountId}?fields=id,username,biography,followers_count,follows_count,profile_picture_url,website`, function (igAccountInfo: any) {
            let payloadfinal = { ...pageData }
            payloadfinal.instagram_username = igAccountInfo.username
            payloadfinal.instagram_id = igAccountId
            setResponsePayload(payloadfinal)
        })
    }

    function getInstagramAccessToken() {
        debugger;
        window.FB.api("/me/accounts?fields=access_token,category,category_list,name,id,tasks,instagram_business_account", function (result: any) {
            if (result && !result.error) {
                const pages = result.data
                // for (let i = 0; i < pages.length; i++) {

                for (let i = 0; i < 1; i++) {
                    const page = pages[i]
                    // window.FB.api(`/${page.id}?fields=instagram_business_account`, function (pageInfo: any) {
                    //     console.log("pageInfo---", pageInfo)
                    let pageData = {
                        page_id: page.id,
                        page_name: page.name,
                        page_category: page.category,
                        access_token_value: page.access_token,
                    }
                    if (page.instagram_business_account) {
                        const igAccountId = page.instagram_business_account.id
                        // Fetch Instagram account details using igAccountId
                        fetchInstagramAccountDetails(igAccountId, pageData)
                    } else {
                        console.log(`No Instagram Business Account linked to page ${page.name}`)
                    }
                    // })
                }
            } else {
                console.log("Error fetching Instagram account access token:", result.error)
            }
        })
    }

    function loginToInstagram() {
        window.FB.login(
            function (response: any) {
                console.log("res: ", response)
                if (response.authResponse) {
                    console.log("Welcome! Fetching your information....")
                    window.FB.api("/me", function (res: any) {
                        console.log("res 1:", res)
                        console.log("Good to see you, " + res.name + ".")
                        getInstagramAccessToken()
                    })
                } else {
                    console.log("User cancelled login or did not fully authorize.")
                }
            },
            { scope: "instagram_basic, instagram_manage_messages, business_management, pages_show_list" }
        )
    }
    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbotIntegration.delete(removeChatbotIntegrationDetails.chatbot_id, removeChatbotIntegrationDetails.meta_integration_id, removeChatbotIntegrationDetails.meta_integration_type_id, removeChatbotIntegrationDetails.instagram_id);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(removeChatbotIntegrationDetails.chatbot_id, "")
            getIntegrationList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setShowDeleteConfirmation(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }


    const handleRemoveChatbotIntegration = (meta_integration_id: number, meta_integration_type_id: number, instagram_id: string, page_id: string, instagram_username: string) => {
        // debugger;
        if (integrationList) {
            let chatbot_id = searchParams.get('chatbot_id')
            if (chatbot_id) {
                setRemoveChatbotIntegrationDetails(
                    {
                        chatbot_id: chatbot_id,
                        meta_integration_type_id: meta_integration_type_id.toString(),
                        instagram_id: instagram_id,
                        page_id: page_id,
                        meta_integration_id: meta_integration_id.toString()
                    }
                )
            }
            setShowDeleteConfirmation(true)
            setPhoneNumberIntegrate(instagram_username)
        }
    }

    function addChatbotIntegration(meta_integration_id: number, meta_integration_type_id: number, instagram_id: string, page_id: string, instagram_username: string, event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            handleClick(event)
        }
        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id) {
            setAddChatbotIntegrationDetails((prevState) => ({
                ...prevState,
                instagram_id: instagram_id,
                meta_integration_type_id: meta_integration_type_id.toString(),
                chatbot_id: chatbot_id,
                page_id: page_id,
                meta_integration_id: meta_integration_id.toString()
            }))
        }
        setPhoneNumberIntegrate(instagram_username)
    }
    async function AddChatbotIntegration(addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface) {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.addChatbotIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            setFlagShowpopup(true)
            setGetChatbotIntegrationData(response.data)
            getIntegrationList()
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagShowAvailablePages(false)
        handleCloseConfirmationPopover()
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    const [showConfirmationPopover, setShowConfirmationPopover] = React.useState(false);

    const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setConfirmationPopoverAnchorEl(event.currentTarget);
        setShowConfirmationPopover(true)
    };

    const handleCloseConfirmationPopover = () => {
        setConfirmationPopoverAnchorEl(null);
        setShowConfirmationPopover(false)
    };

    return (
        <>
            {flagShowAvailablePages &&
                <ShowAvailableInstagramPages
                    flagShowAvailableNumbers={flagShowAvailablePages}
                    setFlagShowAvailableNumbers={setFlagShowAvailablePages}
                    smallScreen={smallScreen}
                    showConfirmationPopover={showConfirmationPopover}
                    confirmationPopoverAnchorEl={confirmationPopoverAnchorEl}
                    handleCloseConfirmationPopover={handleCloseConfirmationPopover}
                    addChatbotIntegrationDetails={addChatbotIntegrationDetails}
                    AddChatbotIntegration={AddChatbotIntegration}
                    availablePhoneNumberData={availablePagesData}
                    handleRemoveChatbotIntegration={handleRemoveChatbotIntegration}
                    displayPhoneNumber={phoneNumberIntegrate}
                    launchWhatsAppSignup={loginToInstagram}
                    saveChatbotIntegrationData={addChatbotIntegration}
                />

            }
            {flagFacebookIntegrationExist == false ?
                <>
                    <Grid container direction={"column"} justifyContent={"start"} alignItems={"start"} sx={{ width: "100%" }} gap={2} flexWrap={"nowrap"} pt={1}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container alignItems={"center"} gap={"13px"} mt={mediaScreen ? 2 : 1} flexWrap={"nowrap"}>
                                <Grid item sx={{ display: "flex" }}>
                                    <img alt="" src={instagramIcon} className={integrationStyles.whatsappIntegrationIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={integrationStyles.whatsappIntegrationFontStyle}>Integrate with your Instagram Account</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%" }} padding={"30px"}>
                            {
                                integrationList && integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name === "Instagram" && integration.meta_integration_facebook_data?.instagram_id).length > 0 ?
                                    <>
                                        <Grid container direction={"column"}>
                                            <Grid item>
                                                {/* <ChatbotWhatsAppIntegrationSetup launch={launchWhatsAppSignup} /> */}
                                                <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>This Chatbot is not linked with any Instagram Pages yet. </span>
                                            </Grid>
                                            <Grid item sx={{ paddingTop: "20px" }}>
                                                <GlobalButton buttonText={"Add Instagram Page"} className='primaryButtonStyle' onClick={() => { setFlagShowAvailablePages(true) }} sx={{ padding: "6px 20px !important" }} startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />} />
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            {/* <ChatbotWhatsAppIntegrationSetup launch={loginToInstagram} /> */}
                                            <ChatbotInstagramIntegrationSetup launch={loginToInstagram} />
                                        </Grid>
                                        <Grid item sx={{ borderTop: "1px solid #E1DCDC", paddingTop: "20px" }}>
                                            <GlobalButton buttonText={"Integrate Instagram Account"} className='primaryButtonStyle' onClick={loginToInstagram} />
                                        </Grid>
                                    </Grid>
                            }

                        </Grid>
                    </Grid>
                </>
                :
                <>
                    {integrationList &&
                        <Grid container gap="20px" direction={"column"} sx={{ padding: { xs: "15px 7px", lg: "15px 15px 15px 5px" } }} mt={{ xs: 1, lg: 0 }}>
                            <Grid item>
                                <Grid container gap="15px">
                                    {linkedPagesData.map((pageData, index) => (
                                        <FacebookInformationCard
                                            integrationType='Instagram'
                                            key={index}
                                            flagDelete={false}
                                            title={`WhatsApp Account ${index + 1}`}
                                            currentlySelected={true}
                                            showRemoveChatbotIntegration={true}
                                            removeChatbotIntegration={() => { handleRemoveChatbotIntegration(pageData.meta_integration_id, pageData.meta_integration_type_id, pageData.instagram_id, pageData.page_id, pageData.instagram_username) }}
                                            details={{
                                                page_id: pageData.instagram_id,
                                                page_name: pageData.instagram_username,
                                                page_category: pageData.page_category,
                                                linked_chatbots: pageData.chatbot_integration_assignment_data
                                            }}
                                            flagIntegration={true}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item>

                                <GlobalButton onClick={() => { setFlagShowAvailablePages(true) }} buttonText={"Add Instagram Pages"} className={'blackShadowedButton'}
                                    startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                                />
                            </Grid>

                        </Grid>}
                    {
                        showDeleteConfirmation &&
                        <GlobalPopUp
                            open={showDeleteConfirmation}
                            handleClose={() => { setShowDeleteConfirmation(false) }}
                        >
                            <GlobalDeletePopup
                                deleteDescription={`Are you sure you want to remove ${chatBotDetails?.chatbot_name} from ${phoneNumberIntegrate}`}
                                onClose={() => { setShowDeleteConfirmation(false) }}
                                onDelete={deleteChatbotIntegration}
                                sx={{ maxWidth: "400px" }}
                            />
                        </GlobalPopUp>
                    }
                    {
                        flagShowpopup &&
                        <GlobalPopUp
                            open={flagShowpopup}
                            flagShowCloseIcon
                            backgroundColor={"#40813F"}
                            title={"Congratulations!"}
                            handleClose={() => { setFlagShowpopup(false) }}
                        >
                            <Grid container sx={{ padding: "30px 45px 30px" }} direction={"column"} gap="7px">
                                <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}>
                                    The AI chatbot has been successfully deployed on this Instagram page!
                                </Grid>
                                <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}> you can now head to your instagram account and start chatting with the instagram page.</Grid>
                                <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}>You can also track your chats <a href={`/chatbot/viewchatbot/inbox?chatbot_id=${chatBotDetails ? chatBotDetails.chatbot_id : ""}`}>here</a> in the inbox</Grid>
                            </Grid>
                        </GlobalPopUp>
                    }
                </>
            }

        </>
    )
}

export default ChatbotInstagramIntegration;