import { Divider, Grid, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import GlobalButton from '../../utility/globalButton/button'
import { callCenterInterface, createAgentsDataPayloadModel } from '../../../models/axiosModel'
import agent from '../../../services/api'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'

const initialAgentData = {
    call_center_id: 0,
    agent_name: ""
}

interface creatAgentInterface {
    flagUpdate?: boolean
}

const CreateCallAgent = (props: creatAgentInterface) => {
    const {flagUpdate} = props
    const {setFlagLoading, setSuccess, success, setError, error} = useContext(UserContext) as UserContextPayload
    const {GetCallAgentsApi, callCenterDetails, setAddNewAgentFlag, updateAgentPayload, updateAgentFlag} = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const [createAgentPayload, setCreateAgentPayload] = useState<createAgentsDataPayloadModel>(initialAgentData)

    const handleCancelAgent = () => {
        setCreateAgentPayload(initialAgentData)
    }

    const isAgentSaveDisabled  = () => {
        if(createAgentPayload.agent_name.trim() === "") {
            return true
        }
        else {
            return false
        }
    }

    const createAgentData = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.createAgentAPI.post(createAgentPayload)
        if(response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Agent Added Successfully" })
            GetCallAgentsApi()
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setAddNewAgentFlag(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    const handleChangeAgentPayload = (value: string) => {
        const payload = {
            call_center_id: callCenterDetails?.call_center_id || 0,
            agent_name: value
        }
        setCreateAgentPayload(payload)
    }

    // useEffect(() => {
    //     if(updateAgentFlag) {
    //         setCreateAgentPayload(updateAgentPayload)
    //     }
    //     else {
    //         setCreateAgentPayload(initialAgentData)
    //     }
    // }, [])

    return (
        <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                <span className={styles.popupTitleStyle}>{flagUpdate ? "Update Agent" : "Add New Agent"}</span>
            </Grid>
            <Grid item>
                <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
            </Grid>
            <Grid item>
                <Grid container direction={"column"} gap={1}>
                    <Grid item>
                        <span style={{fontSize: "15px", fontWeight: "600"}}>Name</span>
                    </Grid>
                    <Grid item>
                        <TextField 
                            sx={{
                                width: "100%", 
                                border: 'none', 
                                "& fieldset": { border: 'none' }, 
                                boxShadow: "0px 0px 3px rgba(0,0,0,0.25)", 
                                borderRadius: "5px",
                                '& .MuiOutlinedInput-input': {
                                    padding: "11.5px 14px !important",
                                    fontSize: "15px !important",
                                    fontWeight: "500"
                                }
                            }}
                            value={createAgentPayload.agent_name}
                            placeholder='Enter Name'
                            onChange={(event) => handleChangeAgentPayload(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{ width: "100%" }} mt={2}>
                <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                    <Grid item>
                        <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelAgent()}/>
                    </Grid>
                    <Grid item>
                        <GlobalButton 
                            buttonText={flagUpdate ? "Update" : "Save"} 
                            className='primaryButtonStyle' 
                            sx={{ minWidth: "130px" }} 
                            onClick={createAgentData}
                            disabled={isAgentSaveDisabled()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateCallAgent
