import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalTable } from '../../utility/globalTable'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { getOrganizationDetailDataModel, organizationUserDataModel } from '../../../models/axiosModel';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import deleteDoc from "../../../assets/deleteDoc.svg";
import agent from '../../../services/api';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';


const OrganizationMembers = () => {
    const navigate = useNavigate()
    const { setFlagLoading, setSuccess, setError, userDetail, flagLoading, GetOrganizationDetails, organizationDetails } = useContext(UserContext) as UserContextPayload;
    const [flagDeleteIntegrationDataPopup, setFlagDeleteIntegrationDataPopup] = useState(false)
    const [memberToRemove, setIntegrationSelected] = useState<null | any>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const organization_id = searchParams.get('organization_id')

    const removeMemberFromOrganization = async (emailAddress: string) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.removeUserFromOrganization.delete(emailAddress)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: response.data.message }))
            getOrganizationDetails()
            GetOrganizationDetails()
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    const organizationValidation = () => {
        if (!organizationDetails) {
            navigate('/')
        }
        else if (organizationDetails && organizationDetails.filter((organization: getOrganizationDetailDataModel) => organization.organization_id == Number(organization_id)).length == 0) {
            navigate('/')
        }
    }

    let headerList = [
        {
            name: "First Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_first_name",
            // sx: { width: "20px !important", paddingRight: "0px !important" }
        },
        {
            name: "Last Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_last_name",
        },
        {
            name: "Email",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_email",
        },
        {
            name: "Status",
            alignment: "left",
            flagIcon: false,
            flagHtml: true,
            valueKey: "user_organization_status_name",
        },
        {
            name: "Role",
            alignment: "left",
            flagIcon: false,
            valueKey: "user_organization_role_name",
        },

    ]

    async function getOrganizationDetails() {
        // const response = await agent.getOrganizationDetails.get();
        // if (response.status) {
        // setOrganizationDetails(response.data[0])

        if (userDetail.user_organization_data && userDetail.user_organization_data.user_organization_role_name == "Organization Owner") {
            let editIcon = {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon"
            }

            headerList.push(editIcon)
            setAdjustedHeaderList(headerList)
        }
        // } else {

        // }
    }

    useEffect(() => {
        organizationValidation()
        getOrganizationDetails()
    }, [searchParams])

    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);

    const deleteIntegrationDataPopupOpen = (data: getOrganizationDetailDataModel) => {
        setFlagDeleteIntegrationDataPopup(true)
        setIntegrationSelected(data)
    }

    const menuItems = [
        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" width={"16"} height={"16"} />, action: (e: getOrganizationDetailDataModel) => deleteIntegrationDataPopupOpen(e), disabled: (rowDetails: organizationUserDataModel) => rowDetails.user_organization_role_name == "Organization Owner" ? true : false },
    ];

    return (
        <>
            <Grid container direction={"column"} className={styles.integrationTable}>
                <Grid item xs={12} className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight} ${globalStyles.globalHeadText} ${styles.subHeading}`}>Organization Members</Grid>
                <Grid item xs={12} sx={{width: "100%", overflow: "auto"}}>
                    <GlobalTable
                        headerList={adjustedHeaderList}
                        rowData={organizationDetails ? organizationDetails.find((organization: getOrganizationDetailDataModel) => Number(organization.organization_id) === Number(organization_id))?.organization_user_data : []}
                        tableHeight={"72vh"}
                        tableContainerHeight={"auto"}
                        tableContainerMinHeight={"auto"}
                        // onCellClick={(e: any, rowDetail: any) => { rowClicked(e, rowDetail) }}
                        onRowClick={(e: any) => { }}
                        deleteRowData={deleteIntegrationDataPopupOpen}
                        flagPagination
                        menuItemsFromParent={menuItems}
                    />
                </Grid>
                {/* {userDetail && userDetail.user_organization_data && userDetail.user_organization_data.user_organization_role_name == "Organization Owner" &&
                    <Grid item sx={{ maxWidth: "150px", marginTop: "15px" }}>
                        <GlobalButton buttonText={"Add member"} onClick={() => { setFlagAddMember(true) }} className="primaryButtonStyle" />
                    </Grid>} */}
            </Grid>

            {flagDeleteIntegrationDataPopup &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to remove ${memberToRemove.private_user_first_name} from this organization`}
                    open={flagDeleteIntegrationDataPopup}
                    onClose={() => { setFlagDeleteIntegrationDataPopup(false) }}
                    onDelete={(e: any) => {
                        removeMemberFromOrganization(memberToRemove.private_user_email)
                        setFlagDeleteIntegrationDataPopup(false)
                    }}
                />
            }
        </>
    )
}

export default OrganizationMembers
