export const whatsappTemplateButtonGenericData = [
    {
        "whatsapp_template_button_type_id": 2,
        "whatsapp_template_button_type_name": "Call to action",
        "whatsapp_template_button_sub_type_data": [
            {
                "whatsapp_template_button_sub_type_id": 3,
                "whatsapp_template_button_sub_type_name": "Visit Website",
                "whatsapp_template_button_sub_type_description": "(2 buttons maximum)",
                "whatsapp_template_button_sub_type_flag_active": true,
                "whatsapp_template_button_sub_type_field_data": [
                    {
                        "whatsapp_template_button_sub_type_field_id": 4,
                        "field_name": "Button Text",
                        "field_type": "Textfield",
                        "field_placeholder": "Visit website",
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": false,
                        "field_character_limit": "25",
                        "field_option_data": [],
                    },
                    {
                        "whatsapp_template_button_sub_type_field_id": 5,
                        "field_name": "URL Type",
                        "field_type": "Autocomplete",
                        "field_placeholder": null,
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": true,
                        "field_character_limit": null,
                        "field_option_data": [
                            {
                                "field_option_id": 2,
                                "field_option_value": "Static",
                                "field_option_description": null
                            }
                        ]
                    },
                    {
                        value: "",
                        "whatsapp_template_button_sub_type_field_id": 6,
                        "field_name": "Website URL",
                        "field_type": "Textfield",
                        "field_placeholder": "https://www.example.com",
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": false,
                        "field_character_limit": "2000",
                        "field_option_data": []
                    }
                ]
            },
            {
                "whatsapp_template_button_sub_type_id": 4,
                "whatsapp_template_button_sub_type_name": "Call phone number",
                "whatsapp_template_button_sub_type_description": "(1 buttons maximum)",
                "whatsapp_template_button_sub_type_flag_active": true,
                "whatsapp_template_button_sub_type_field_data": [
                    {
                        value: "",
                        "whatsapp_template_button_sub_type_field_id": 7,
                        "field_name": "Button Text",
                        "field_type": "Textfield",
                        "field_placeholder": "Call phone number",
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": false,
                        "field_character_limit": "25",
                        "field_option_data": []
                    },
                    {
                        value: "",
                        "whatsapp_template_button_sub_type_field_id": 8,
                        "field_name": "Country",
                        "field_type": "Autocomplete",
                        "field_placeholder": null,
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": true,
                        "field_character_limit": null,
                        "field_option_data": [
                            {
                                "field_option_id": 4,
                                "field_option_value": "AE (+971)",
                                "field_option_description": "United Arab Emirates"
                            },
                            {
                                "field_option_id": 5,
                                "field_option_value": "AF (+93)",
                                "field_option_description": "Afghanistan"
                            },
                            {
                                "field_option_id": 6,
                                "field_option_value": "AL (+355)",
                                "field_option_description": "Albania"
                            },
                            {
                                "field_option_id": 7,
                                "field_option_value": "AM (+374)",
                                "field_option_description": "Armenia"
                            },
                            {
                                "field_option_id": 8,
                                "field_option_value": "AO (+244)",
                                "field_option_description": "Angola"
                            },
                            {
                                "field_option_id": 9,
                                "field_option_value": "AR (+54)",
                                "field_option_description": "Argentina"
                            },
                            {
                                "field_option_id": 10,
                                "field_option_value": "AT (+43)",
                                "field_option_description": "Austria"
                            },
                            {
                                "field_option_id": 11,
                                "field_option_value": "AU (+61)",
                                "field_option_description": "Australia"
                            },
                            {
                                "field_option_id": 12,
                                "field_option_value": "AZ (+994)",
                                "field_option_description": "Azerbaijan"
                            },
                            {
                                "field_option_id": 13,
                                "field_option_value": "BD (+880)",
                                "field_option_description": "Bangladesh"
                            },
                            {
                                "field_option_id": 14,
                                "field_option_value": "BE (+32)",
                                "field_option_description": "Belgium"
                            },
                            {
                                "field_option_id": 15,
                                "field_option_value": "BF (+226)",
                                "field_option_description": "Burkina Faso"
                            },
                            {
                                "field_option_id": 16,
                                "field_option_value": "BG (+359)",
                                "field_option_description": "Bulgaria"
                            },
                            {
                                "field_option_id": 17,
                                "field_option_value": "BH (+973)",
                                "field_option_description": "Bahrain"
                            },
                            {
                                "field_option_id": 18,
                                "field_option_value": "BI (+257)",
                                "field_option_description": "Burundi"
                            },
                            {
                                "field_option_id": 19,
                                "field_option_value": "BJ (+229)",
                                "field_option_description": "Benin"
                            },
                            {
                                "field_option_id": 20,
                                "field_option_value": "BN (+673)",
                                "field_option_description": "Brunei Darussalam"
                            },
                            {
                                "field_option_id": 21,
                                "field_option_value": "BO (+591)",
                                "field_option_description": "Bolivia"
                            },
                            {
                                "field_option_id": 22,
                                "field_option_value": "BR (+55)",
                                "field_option_description": "Brazil"
                            },
                            {
                                "field_option_id": 23,
                                "field_option_value": "BW (+267)",
                                "field_option_description": "Botswana"
                            },
                            {
                                "field_option_id": 24,
                                "field_option_value": "BY (+375)",
                                "field_option_description": "Belarus"
                            },
                            {
                                "field_option_id": 25,
                                "field_option_value": "CA (+1)",
                                "field_option_description": "Canada"
                            },
                            {
                                "field_option_id": 26,
                                "field_option_value": "CG (+242)",
                                "field_option_description": "Congo"
                            },
                            {
                                "field_option_id": 27,
                                "field_option_value": "CH (+41)",
                                "field_option_description": "Switzerland"
                            },
                            {
                                "field_option_id": 28,
                                "field_option_value": "CI (+225)",
                                "field_option_description": "Ivory Coast"
                            },
                            {
                                "field_option_id": 29,
                                "field_option_value": "CL (+56)",
                                "field_option_description": "Chile"
                            },
                            {
                                "field_option_id": 30,
                                "field_option_value": "CM (+237)",
                                "field_option_description": "Cameroon"
                            },
                            {
                                "field_option_id": 31,
                                "field_option_value": "CN (+86)",
                                "field_option_description": "China"
                            },
                            {
                                "field_option_id": 32,
                                "field_option_value": "CO (+57)",
                                "field_option_description": "Colombia"
                            },
                            {
                                "field_option_id": 33,
                                "field_option_value": "CR (+506)",
                                "field_option_description": "Costa Rica"
                            },
                            {
                                "field_option_id": 34,
                                "field_option_value": "CZ (+420)",
                                "field_option_description": "Czech Republic"
                            },
                            {
                                "field_option_id": 35,
                                "field_option_value": "DE (+49)",
                                "field_option_description": "Germany"
                            },
                            {
                                "field_option_id": 36,
                                "field_option_value": "DK (+45)",
                                "field_option_description": "Denmark"
                            },
                            {
                                "field_option_id": 37,
                                "field_option_value": "DO (+1)",
                                "field_option_description": "Dominican Republic"
                            },
                            {
                                "field_option_id": 38,
                                "field_option_value": "DZ (+213)",
                                "field_option_description": "Algeria"
                            },
                            {
                                "field_option_id": 39,
                                "field_option_value": "EC (+593)",
                                "field_option_description": "Ecuador"
                            },
                            {
                                "field_option_id": 40,
                                "field_option_value": "EE (+372)",
                                "field_option_description": "Estonia"
                            },
                            {
                                "field_option_id": 41,
                                "field_option_value": "EG (+20)",
                                "field_option_description": "Egypt"
                            },
                            {
                                "field_option_id": 42,
                                "field_option_value": "ER (+291)",
                                "field_option_description": "Eritrea"
                            },
                            {
                                "field_option_id": 43,
                                "field_option_value": "ES (+34)",
                                "field_option_description": "Spain"
                            },
                            {
                                "field_option_id": 44,
                                "field_option_value": "ET (+251)",
                                "field_option_description": "Ethiopia"
                            },
                            {
                                "field_option_id": 45,
                                "field_option_value": "FI (+358)",
                                "field_option_description": "Finland"
                            },
                            {
                                "field_option_id": 46,
                                "field_option_value": "FR (+33)",
                                "field_option_description": "France"
                            },
                            {
                                "field_option_id": 47,
                                "field_option_value": "GA (+241)",
                                "field_option_description": "Gabon"
                            },
                            {
                                "field_option_id": 48,
                                "field_option_value": "GB (+44)",
                                "field_option_description": "United Kingdom"
                            },
                            {
                                "field_option_id": 49,
                                "field_option_value": "GE (+995)",
                                "field_option_description": "Georgia"
                            },
                            {
                                "field_option_id": 50,
                                "field_option_value": "GF (+594)",
                                "field_option_description": "French Guiana"
                            },
                            {
                                "field_option_id": 51,
                                "field_option_value": "GH (+233)",
                                "field_option_description": "Ghana"
                            },
                            {
                                "field_option_id": 52,
                                "field_option_value": "GM (+220)",
                                "field_option_description": "Gambia"
                            },
                            {
                                "field_option_id": 53,
                                "field_option_value": "GR (+30)",
                                "field_option_description": "Greece"
                            },
                            {
                                "field_option_id": 54,
                                "field_option_value": "GT (+502)",
                                "field_option_description": "Guatemala"
                            },
                            {
                                "field_option_id": 55,
                                "field_option_value": "GW (+245)",
                                "field_option_description": "Guinea-Bissau"
                            },
                            {
                                "field_option_id": 56,
                                "field_option_value": "HK (+852)",
                                "field_option_description": "Hong Kong"
                            },
                            {
                                "field_option_id": 57,
                                "field_option_value": "HN (+504)",
                                "field_option_description": "Honduras"
                            },
                            {
                                "field_option_id": 58,
                                "field_option_value": "HR (+385)",
                                "field_option_description": "Croatia"
                            },
                            {
                                "field_option_id": 59,
                                "field_option_value": "HT (+509)",
                                "field_option_description": "Haiti"
                            },
                            {
                                "field_option_id": 60,
                                "field_option_value": "HU (+36)",
                                "field_option_description": "Hungary"
                            },
                            {
                                "field_option_id": 61,
                                "field_option_value": "ID (+62)",
                                "field_option_description": "Indonesia"
                            },
                            {
                                "field_option_id": 62,
                                "field_option_value": "IE (+353)",
                                "field_option_description": "Ireland"
                            },
                            {
                                "field_option_id": 63,
                                "field_option_value": "IL (+972)",
                                "field_option_description": "Israel"
                            },
                            {
                                "field_option_id": 64,
                                "field_option_value": "IN (+91)",
                                "field_option_description": "India"
                            },
                            {
                                "field_option_id": 65,
                                "field_option_value": "IQ (+964)",
                                "field_option_description": "Iraq"
                            },
                            {
                                "field_option_id": 66,
                                "field_option_value": "IT (+39)",
                                "field_option_description": "Italy"
                            },
                            {
                                "field_option_id": 67,
                                "field_option_value": "JM (+1)",
                                "field_option_description": "Jamaica"
                            },
                            {
                                "field_option_id": 68,
                                "field_option_value": "JO (+962)",
                                "field_option_description": "Jordan"
                            },
                            {
                                "field_option_id": 69,
                                "field_option_value": "JP (+81)",
                                "field_option_description": "Japan"
                            },
                            {
                                "field_option_id": 70,
                                "field_option_value": "KE (+254)",
                                "field_option_description": "Kenya"
                            },
                            {
                                "field_option_id": 71,
                                "field_option_value": "KH (+855)",
                                "field_option_description": "Cambodia"
                            },
                            {
                                "field_option_id": 72,
                                "field_option_value": "KR (+82)",
                                "field_option_description": "South Korea"
                            },
                            {
                                "field_option_id": 73,
                                "field_option_value": "KW (+965)",
                                "field_option_description": "Kuwait"
                            },
                            {
                                "field_option_id": 74,
                                "field_option_value": "LA (+856)",
                                "field_option_description": "Laos"
                            },
                            {
                                "field_option_id": 75,
                                "field_option_value": "LB (+961)",
                                "field_option_description": "Lebanon"
                            },
                            {
                                "field_option_id": 76,
                                "field_option_value": "LK (+94)",
                                "field_option_description": "Sri Lanka"
                            },
                            {
                                "field_option_id": 77,
                                "field_option_value": "LR (+231)",
                                "field_option_description": "Liberia"
                            },
                            {
                                "field_option_id": 78,
                                "field_option_value": "LS (+266)",
                                "field_option_description": "Lesotho"
                            },
                            {
                                "field_option_id": 79,
                                "field_option_value": "LT (+370)",
                                "field_option_description": "Lithuania"
                            },
                            {
                                "field_option_id": 80,
                                "field_option_value": "LU (+352)",
                                "field_option_description": "Luxembourg"
                            },
                            {
                                "field_option_id": 81,
                                "field_option_value": "LV (+371)",
                                "field_option_description": "Latvia"
                            },
                            {
                                "field_option_id": 82,
                                "field_option_value": "LY (+218)",
                                "field_option_description": "Libya"
                            },
                            {
                                "field_option_id": 83,
                                "field_option_value": "MA (+212)",
                                "field_option_description": "Morocco"
                            },
                            {
                                "field_option_id": 84,
                                "field_option_value": "MC (+377)",
                                "field_option_description": "Monaco"
                            },
                            {
                                "field_option_id": 85,
                                "field_option_value": "MD (+373)",
                                "field_option_description": "Moldova"
                            },
                            {
                                "field_option_id": 86,
                                "field_option_value": "MG (+261)",
                                "field_option_description": "Madagascar"
                            },
                            {
                                "field_option_id": 87,
                                "field_option_value": "MK (+389)",
                                "field_option_description": "Macedonia"
                            },
                            {
                                "field_option_id": 88,
                                "field_option_value": "ML (+223)",
                                "field_option_description": "Mali"
                            },
                            {
                                "field_option_id": 89,
                                "field_option_value": "MM (+95)",
                                "field_option_description": "Myanmar"
                            },
                            {
                                "field_option_id": 90,
                                "field_option_value": "MN (+976)",
                                "field_option_description": "Mongolia"
                            },
                            {
                                "field_option_id": 91,
                                "field_option_value": "MR (+222)",
                                "field_option_description": "Mauritania"
                            },
                            {
                                "field_option_id": 92,
                                "field_option_value": "MT (+356)",
                                "field_option_description": "The Republic of Malta"
                            },
                            {
                                "field_option_id": 93,
                                "field_option_value": "MW (+265)",
                                "field_option_description": "Malawi"
                            },
                            {
                                "field_option_id": 94,
                                "field_option_value": "MX (+52)",
                                "field_option_description": "Mexico"
                            },
                            {
                                "field_option_id": 95,
                                "field_option_value": "MY (+60)",
                                "field_option_description": "Malaysia"
                            },
                            {
                                "field_option_id": 96,
                                "field_option_value": "MZ (+258)",
                                "field_option_description": "Mozambique"
                            },
                            {
                                "field_option_id": 97,
                                "field_option_value": "NA (+264)",
                                "field_option_description": "Namibia"
                            },
                            {
                                "field_option_id": 98,
                                "field_option_value": "NE (+227)",
                                "field_option_description": "Niger"
                            },
                            {
                                "field_option_id": 99,
                                "field_option_value": "NG (+234)",
                                "field_option_description": "Nigeria"
                            },
                            {
                                "field_option_id": 100,
                                "field_option_value": "NI (+505)",
                                "field_option_description": "Nicaragua"
                            },
                            {
                                "field_option_id": 101,
                                "field_option_value": "NL (+31)",
                                "field_option_description": "Netherlands"
                            },
                            {
                                "field_option_id": 102,
                                "field_option_value": "NO (+47)",
                                "field_option_description": "Norway"
                            },
                            {
                                "field_option_id": 103,
                                "field_option_value": "NP (+977)",
                                "field_option_description": "Nepal"
                            },
                            {
                                "field_option_id": 104,
                                "field_option_value": "NZ (+64)",
                                "field_option_description": "New Zealand"
                            },
                            {
                                "field_option_id": 105,
                                "field_option_value": "OM (+968)",
                                "field_option_description": "Oman"
                            },
                            {
                                "field_option_id": 106,
                                "field_option_value": "PA (+507)",
                                "field_option_description": "Panama"
                            },
                            {
                                "field_option_id": 107,
                                "field_option_value": "PE (+51)",
                                "field_option_description": "Peru"
                            },
                            {
                                "field_option_id": 108,
                                "field_option_value": "PG (+675)",
                                "field_option_description": "Papua New Guinea"
                            },
                            {
                                "field_option_id": 109,
                                "field_option_value": "PH (+63)",
                                "field_option_description": "Philippines"
                            },
                            {
                                "field_option_id": 110,
                                "field_option_value": "PK (+92)",
                                "field_option_description": "Pakistan"
                            },
                            {
                                "field_option_id": 111,
                                "field_option_value": "PL (+48)",
                                "field_option_description": "Poland"
                            },
                            {
                                "field_option_id": 112,
                                "field_option_value": "PR (+1)",
                                "field_option_description": "Puerto Rico"
                            },
                            {
                                "field_option_id": 113,
                                "field_option_value": "PT (+351)",
                                "field_option_description": "Portugal"
                            },
                            {
                                "field_option_id": 114,
                                "field_option_value": "PY (+595)",
                                "field_option_description": "Paraguay"
                            },
                            {
                                "field_option_id": 115,
                                "field_option_value": "QA (+974)",
                                "field_option_description": "Qatar"
                            },
                            {
                                "field_option_id": 116,
                                "field_option_value": "RO (+40)",
                                "field_option_description": "Romania"
                            },
                            {
                                "field_option_id": 117,
                                "field_option_value": "RS (+381)",
                                "field_option_description": "Serbia"
                            },
                            {
                                "field_option_id": 118,
                                "field_option_value": "RU (+7)",
                                "field_option_description": "Russia"
                            },
                            {
                                "field_option_id": 119,
                                "field_option_value": "RW (+250)",
                                "field_option_description": "Rwanda"
                            },
                            {
                                "field_option_id": 120,
                                "field_option_value": "SA (+966)",
                                "field_option_description": "Saudi Arabia"
                            },
                            {
                                "field_option_id": 121,
                                "field_option_value": "SD (+249)",
                                "field_option_description": "Sudan"
                            },
                            {
                                "field_option_id": 122,
                                "field_option_value": "SE (+46)",
                                "field_option_description": "Sweden"
                            },
                            {
                                "field_option_id": 123,
                                "field_option_value": "SG (+65)",
                                "field_option_description": "Singapore"
                            },
                            {
                                "field_option_id": 124,
                                "field_option_value": "SI (+386)",
                                "field_option_description": "Slovenia"
                            },
                            {
                                "field_option_id": 125,
                                "field_option_value": "SK (+421)",
                                "field_option_description": "Slovakia"
                            },
                            {
                                "field_option_id": 126,
                                "field_option_value": "SL (+232)",
                                "field_option_description": "Sierra Leone"
                            },
                            {
                                "field_option_id": 127,
                                "field_option_value": "SN (+221)",
                                "field_option_description": "Senegal"
                            },
                            {
                                "field_option_id": 128,
                                "field_option_value": "SO (+252)",
                                "field_option_description": "Somalia"
                            },
                            {
                                "field_option_id": 129,
                                "field_option_value": "SS (+211)",
                                "field_option_description": "South Sudan"
                            },
                            {
                                "field_option_id": 130,
                                "field_option_value": "SV (+503)",
                                "field_option_description": "El Salvador"
                            },
                            {
                                "field_option_id": 131,
                                "field_option_value": "SZ (+268)",
                                "field_option_description": "Eswatini"
                            },
                            {
                                "field_option_id": 132,
                                "field_option_value": "TD (+235)",
                                "field_option_description": "Chad"
                            },
                            {
                                "field_option_id": 133,
                                "field_option_value": "TG (+228)",
                                "field_option_description": "Togo"
                            },
                            {
                                "field_option_id": 134,
                                "field_option_value": "TH (+66)",
                                "field_option_description": "Thailand"
                            },
                            {
                                "field_option_id": 135,
                                "field_option_value": "TJ (+992)",
                                "field_option_description": "Tajikistan"
                            },
                            {
                                "field_option_id": 136,
                                "field_option_value": "TM (+993)",
                                "field_option_description": "Turkmenistan"
                            },
                            {
                                "field_option_id": 137,
                                "field_option_value": "TN (+216)",
                                "field_option_description": "Tunisia"
                            },
                            {
                                "field_option_id": 138,
                                "field_option_value": "TW (+886)",
                                "field_option_description": "Taiwan"
                            },
                            {
                                "field_option_id": 139,
                                "field_option_value": "TZ (+255)",
                                "field_option_description": "Tanzania"
                            },
                            {
                                "field_option_id": 140,
                                "field_option_value": "UA (+380)",
                                "field_option_description": "Ukraine"
                            },
                            {
                                "field_option_id": 141,
                                "field_option_value": "UG (+256)",
                                "field_option_description": "Uganda"
                            },
                            {
                                "field_option_id": 142,
                                "field_option_value": "US (+1)",
                                "field_option_description": "United States"
                            },
                            {
                                "field_option_id": 143,
                                "field_option_value": "UY (+598)",
                                "field_option_description": "Uruguay"
                            },
                            {
                                "field_option_id": 144,
                                "field_option_value": "UZ (+998)",
                                "field_option_description": "Uzbekistan"
                            },
                            {
                                "field_option_id": 145,
                                "field_option_value": "VE (+58)",
                                "field_option_description": "Venezuela"
                            },
                            {
                                "field_option_id": 146,
                                "field_option_value": "VN (+84)",
                                "field_option_description": "Vietnam"
                            },
                            {
                                "field_option_id": 147,
                                "field_option_value": "YE (+967)",
                                "field_option_description": "Yemen"
                            },
                            {
                                "field_option_id": 148,
                                "field_option_value": "ZA (+27)",
                                "field_option_description": "South Africa"
                            },
                            {
                                "field_option_id": 149,
                                "field_option_value": "ZM (+260)",
                                "field_option_description": "Zambia"
                            }
                        ]
                    },
                    {
                        value: "",
                        "whatsapp_template_button_sub_type_field_id": 9,
                        "field_name": "Phone number",
                        "field_type": "Textfield",
                        "field_placeholder": null,
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": false,
                        "field_character_limit": "20",
                        "field_option_data": []
                    }
                ]
            }
        ]
    },
    {
        "whatsapp_template_button_type_id": 1,
        "whatsapp_template_button_type_name": "Quick reply",
        "whatsapp_template_button_sub_type_data": [
            {
                "whatsapp_template_button_sub_type_id": 1,
                "whatsapp_template_button_sub_type_name": "Marketing opt-out",
                "whatsapp_template_button_sub_type_description": "(Recommended)",
                "whatsapp_template_button_sub_type_flag_active": false,
                "whatsapp_template_button_sub_type_field_data": [
                    {
                        "whatsapp_template_button_sub_type_field_id": 2,
                        "field_name": "Footer Text",
                        "field_type": "Textfield",
                        "field_placeholder": null,
                        "field_static_value_flag": true,
                        "field_static_value": "Not interested? Tap Stop promotions",
                        "field_option_flag": false,
                        "field_character_limit": null,
                        "field_option_data": []
                    },
                    {
                        "whatsapp_template_button_sub_type_field_id": 1,
                        "field_name": "Button Text",
                        "field_type": "Textfield",
                        "field_placeholder": null,
                        "field_static_value_flag": true,
                        "field_static_value": "Stop Promotion",
                        "field_option_flag": false,
                        "field_character_limit": "25",
                        "field_option_data": []
                    }
                ]
            },
            {
                "whatsapp_template_button_sub_type_id": 2,
                "whatsapp_template_button_sub_type_name": "Custom",
                "whatsapp_template_button_sub_type_description": null,
                "whatsapp_template_button_sub_type_flag_active": true,
                "whatsapp_template_button_sub_type_field_data": [
                    {
                        "whatsapp_template_button_sub_type_field_id": 3,
                        "field_name": "Button Text",
                        "field_type": "Textfield",
                        "field_placeholder": "Quick Reply",
                        "field_static_value_flag": false,
                        "field_static_value": null,
                        "field_option_flag": false,
                        "field_character_limit": "25",
                        "field_option_data": []
                    }
                ]
            }
        ]
    }
]


export const whatsappTemplateLanguages = [
    {
        "whatsapp_language_id": 1,
        "whatsapp_language_name": "Afrikaans",
        "whatsapp_language_abbr": "af"
    },
    {
        "whatsapp_language_id": 2,
        "whatsapp_language_name": "Albanian",
        "whatsapp_language_abbr": "sq"
    },
    {
        "whatsapp_language_id": 3,
        "whatsapp_language_name": "Arabic",
        "whatsapp_language_abbr": "ar"
    },
    {
        "whatsapp_language_id": 4,
        "whatsapp_language_name": "Azerbaijani",
        "whatsapp_language_abbr": "az"
    },
    {
        "whatsapp_language_id": 5,
        "whatsapp_language_name": "Bengali",
        "whatsapp_language_abbr": "bn"
    },
    {
        "whatsapp_language_id": 6,
        "whatsapp_language_name": "Bulgarian",
        "whatsapp_language_abbr": "bg"
    },
    {
        "whatsapp_language_id": 7,
        "whatsapp_language_name": "Catalan",
        "whatsapp_language_abbr": "ca"
    },
    {
        "whatsapp_language_id": 8,
        "whatsapp_language_name": "Chinese (CHN)",
        "whatsapp_language_abbr": "zh_CN"
    },
    {
        "whatsapp_language_id": 9,
        "whatsapp_language_name": "Chinese (HKG)",
        "whatsapp_language_abbr": "zh_HK"
    },
    {
        "whatsapp_language_id": 10,
        "whatsapp_language_name": "Chinese (TAI)",
        "whatsapp_language_abbr": "zh_TW"
    },
    {
        "whatsapp_language_id": 11,
        "whatsapp_language_name": "Croatian",
        "whatsapp_language_abbr": "hr"
    },
    {
        "whatsapp_language_id": 12,
        "whatsapp_language_name": "Czech",
        "whatsapp_language_abbr": "cs"
    },
    {
        "whatsapp_language_id": 13,
        "whatsapp_language_name": "Danish",
        "whatsapp_language_abbr": "da"
    },
    {
        "whatsapp_language_id": 14,
        "whatsapp_language_name": "Dutch",
        "whatsapp_language_abbr": "nl"
    },
    {
        "whatsapp_language_id": 15,
        "whatsapp_language_name": "English",
        "whatsapp_language_abbr": "en"
    },
    {
        "whatsapp_language_id": 16,
        "whatsapp_language_name": "English (UK)",
        "whatsapp_language_abbr": "en_GB"
    },
    {
        "whatsapp_language_id": 17,
        "whatsapp_language_name": "English (US)",
        "whatsapp_language_abbr": "en_US"
    },
    {
        "whatsapp_language_id": 18,
        "whatsapp_language_name": "Estonian",
        "whatsapp_language_abbr": "et"
    },
    {
        "whatsapp_language_id": 19,
        "whatsapp_language_name": "Filipino",
        "whatsapp_language_abbr": "fil"
    },
    {
        "whatsapp_language_id": 20,
        "whatsapp_language_name": "Finnish",
        "whatsapp_language_abbr": "fi"
    },
    {
        "whatsapp_language_id": 21,
        "whatsapp_language_name": "French",
        "whatsapp_language_abbr": "fr"
    },
    {
        "whatsapp_language_id": 22,
        "whatsapp_language_name": "Georgian",
        "whatsapp_language_abbr": "ka"
    },
    {
        "whatsapp_language_id": 23,
        "whatsapp_language_name": "German",
        "whatsapp_language_abbr": "de"
    },
    {
        "whatsapp_language_id": 24,
        "whatsapp_language_name": "Greek",
        "whatsapp_language_abbr": "el"
    },
    {
        "whatsapp_language_id": 25,
        "whatsapp_language_name": "Gujarati",
        "whatsapp_language_abbr": "gu"
    },
    {
        "whatsapp_language_id": 26,
        "whatsapp_language_name": "Hausa",
        "whatsapp_language_abbr": "ha"
    },
    {
        "whatsapp_language_id": 27,
        "whatsapp_language_name": "Hebrew",
        "whatsapp_language_abbr": "he"
    },
    {
        "whatsapp_language_id": 28,
        "whatsapp_language_name": "Hindi",
        "whatsapp_language_abbr": "hi"
    },
    {
        "whatsapp_language_id": 29,
        "whatsapp_language_name": "Hungarian",
        "whatsapp_language_abbr": "hu"
    },
    {
        "whatsapp_language_id": 30,
        "whatsapp_language_name": "Indonesian",
        "whatsapp_language_abbr": "id"
    },
    {
        "whatsapp_language_id": 31,
        "whatsapp_language_name": "Irish",
        "whatsapp_language_abbr": "ga"
    },
    {
        "whatsapp_language_id": 32,
        "whatsapp_language_name": "Italian",
        "whatsapp_language_abbr": "it"
    },
    {
        "whatsapp_language_id": 33,
        "whatsapp_language_name": "Japanese",
        "whatsapp_language_abbr": "ja"
    },
    {
        "whatsapp_language_id": 34,
        "whatsapp_language_name": "Kannada",
        "whatsapp_language_abbr": "kn"
    },
    {
        "whatsapp_language_id": 35,
        "whatsapp_language_name": "Kazakh",
        "whatsapp_language_abbr": "kk"
    },
    {
        "whatsapp_language_id": 36,
        "whatsapp_language_name": "Kinyarwanda",
        "whatsapp_language_abbr": "rw_RW"
    },
    {
        "whatsapp_language_id": 37,
        "whatsapp_language_name": "Korean",
        "whatsapp_language_abbr": "ko"
    },
    {
        "whatsapp_language_id": 38,
        "whatsapp_language_name": "Kyrgyz (Kyrgyzstan)",
        "whatsapp_language_abbr": "ky_KG"
    },
    {
        "whatsapp_language_id": 39,
        "whatsapp_language_name": "Lao",
        "whatsapp_language_abbr": "lo"
    },
    {
        "whatsapp_language_id": 40,
        "whatsapp_language_name": "Latvian",
        "whatsapp_language_abbr": "lv"
    },
    {
        "whatsapp_language_id": 41,
        "whatsapp_language_name": "Lithuanian",
        "whatsapp_language_abbr": "lt"
    },
    {
        "whatsapp_language_id": 42,
        "whatsapp_language_name": "Macedonian",
        "whatsapp_language_abbr": "mk"
    },
    {
        "whatsapp_language_id": 43,
        "whatsapp_language_name": "Malay",
        "whatsapp_language_abbr": "ms"
    },
    {
        "whatsapp_language_id": 44,
        "whatsapp_language_name": "Malayalam",
        "whatsapp_language_abbr": "ml"
    },
    {
        "whatsapp_language_id": 45,
        "whatsapp_language_name": "Marathi",
        "whatsapp_language_abbr": "mr"
    },
    {
        "whatsapp_language_id": 46,
        "whatsapp_language_name": "Norwegian",
        "whatsapp_language_abbr": "nb"
    },
    {
        "whatsapp_language_id": 47,
        "whatsapp_language_name": "Persian",
        "whatsapp_language_abbr": "fa"
    },
    {
        "whatsapp_language_id": 48,
        "whatsapp_language_name": "Polish",
        "whatsapp_language_abbr": "pl"
    },
    {
        "whatsapp_language_id": 49,
        "whatsapp_language_name": "Portuguese (BR)",
        "whatsapp_language_abbr": "pt_BR"
    },
    {
        "whatsapp_language_id": 50,
        "whatsapp_language_name": "Portuguese (POR)",
        "whatsapp_language_abbr": "pt_PT"
    },
    {
        "whatsapp_language_id": 51,
        "whatsapp_language_name": "Punjabi",
        "whatsapp_language_abbr": "pa"
    },
    {
        "whatsapp_language_id": 52,
        "whatsapp_language_name": "Romanian",
        "whatsapp_language_abbr": "ro"
    },
    {
        "whatsapp_language_id": 53,
        "whatsapp_language_name": "Russian",
        "whatsapp_language_abbr": "ru"
    },
    {
        "whatsapp_language_id": 54,
        "whatsapp_language_name": "Serbian",
        "whatsapp_language_abbr": "sr"
    },
    {
        "whatsapp_language_id": 55,
        "whatsapp_language_name": "Slovak",
        "whatsapp_language_abbr": "sk"
    },
    {
        "whatsapp_language_id": 56,
        "whatsapp_language_name": "Slovenian",
        "whatsapp_language_abbr": "sl"
    },
    {
        "whatsapp_language_id": 57,
        "whatsapp_language_name": "Spanish",
        "whatsapp_language_abbr": "es"
    },
    {
        "whatsapp_language_id": 58,
        "whatsapp_language_name": "Spanish (ARG)",
        "whatsapp_language_abbr": "es_AR"
    },
    {
        "whatsapp_language_id": 59,
        "whatsapp_language_name": "Spanish (SPA)",
        "whatsapp_language_abbr": "es_ES"
    },
    {
        "whatsapp_language_id": 60,
        "whatsapp_language_name": "Spanish (MEX)",
        "whatsapp_language_abbr": "es_MX"
    },
    {
        "whatsapp_language_id": 61,
        "whatsapp_language_name": "Swahili",
        "whatsapp_language_abbr": "sw"
    },
    {
        "whatsapp_language_id": 62,
        "whatsapp_language_name": "Swedish",
        "whatsapp_language_abbr": "sv"
    },
    {
        "whatsapp_language_id": 63,
        "whatsapp_language_name": "Tamil",
        "whatsapp_language_abbr": "ta"
    },
    {
        "whatsapp_language_id": 64,
        "whatsapp_language_name": "Telugu",
        "whatsapp_language_abbr": "te"
    },
    {
        "whatsapp_language_id": 65,
        "whatsapp_language_name": "Thai",
        "whatsapp_language_abbr": "th"
    },
    {
        "whatsapp_language_id": 66,
        "whatsapp_language_name": "Turkish",
        "whatsapp_language_abbr": "tr"
    },
    {
        "whatsapp_language_id": 67,
        "whatsapp_language_name": "Ukrainian",
        "whatsapp_language_abbr": "uk"
    },
    {
        "whatsapp_language_id": 68,
        "whatsapp_language_name": "Urdu",
        "whatsapp_language_abbr": "ur"
    },
    {
        "whatsapp_language_id": 69,
        "whatsapp_language_name": "Uzbek",
        "whatsapp_language_abbr": "uz"
    },
    {
        "whatsapp_language_id": 70,
        "whatsapp_language_name": "Vietnamese",
        "whatsapp_language_abbr": "vi"
    },
    {
        "whatsapp_language_id": 71,
        "whatsapp_language_name": "Zulu",
        "whatsapp_language_abbr": "zu"
    }
]

export const whatsappTemplateCategoryList = [
    {
        "whatsapp_template_category_id": 1,
        "whatsapp_template_category_name": "Marketing"
    },
    {
        "whatsapp_template_category_id": 2,
        "whatsapp_template_category_name": "Utility"
    },
    {
        "whatsapp_template_category_id": 3,
        "whatsapp_template_category_name": "Authentication"
    }
]

export const whatsappTemplateHeaderType = [
    {
        "whatsapp_template_header_type_id": 1,
        "whatsapp_template_header_type_name": "Text"
    }
]

export function getSubButtonsBasedOnCategory(category_id) {
    switch (category_id) {
        case 1: // marketing
            return [1, 2, 3, 4] //marketing opt out, custom, visit website, call phone number
        case 2: //utility
            return [2, 3, 4] //custom, visit website, call phone number
        case 3: //Authentication
            return []
        default:
            return []
    }
}