import { Button, Divider, Grid, IconButton } from '@mui/material';
import React from 'react'
import IconsColor from './iconsColor';
import { useNavigate } from 'react-router-dom';
import styles from "../../assets/styles/componentStyles/sidePanelAccountDetails.module.scss";
import globalStyles from '../../assets/styles/global.module.scss';

const BackToMainPageGlobalComponent = (props: any) => {
    const navigate = useNavigate();

    return (
        <>
            {/* <Divider sx={{marginBottom: "10px"}}/> */}
            <Grid item className={styles.backButtonStyle}>
                <Grid container sx={{ alignItems: "center" }}>
                    {!props.flagMobile &&
                        <Grid item>
                            <Button
                                variant='outlined'
                                size='large'
                                sx={{ width: "208px", fontSize: "14px !important" }}
                                fullWidth
                                startIcon={<IconsColor iconName="home" fillColor="#fff" width="15px" height="15px" />}
                                className={globalStyles.addDocumentBtn}
                                onClick={() => { navigate('/'); if (props.flagMobileScreen) props.onclose() }}
                            >
                                Home
                            </Button>
                        </Grid>
                    }
                    {props.flagMobile &&
                        <IconButton
                            className={`${styles.backMobileButton}`}
                            onClick={() => { navigate('/'); if (props.flagMobileScreen) props.onclose() }}
                        >
                            <IconsColor iconName="home" fillColor="#fff" width="15px" height="15px" />
                        </IconButton>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default BackToMainPageGlobalComponent
