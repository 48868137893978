import React, { useContext, useState } from 'react'
import { globalSlackIntegration } from '../../../models/axiosModel'
import { Divider, Grid, Tooltip } from '@mui/material'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import slackIcon from "./../../../assets/images/channels/slack/slack (3).svg";
import GlobalButton from '../../utility/globalButton/button';
import cardStyles from "./../../../assets/styles/componentStyles/accountInformationCard.module.scss";
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';

interface slackChannelInterface {
    channelIntegrationData: globalSlackIntegration | null,
    handleChangeSlackConfigPayload: (field: string, value: string | number) => void,
    setFlagSelectChannelPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const SlackConfigChannels = (props: slackChannelInterface) => {
    const {channelIntegrationData, handleChangeSlackConfigPayload, setFlagSelectChannelPopup} = props;
    const {slackConfigPayload, setSlackConfigPayload} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const { chatbotList } = useContext(ChatbotContext) as ChatbotContextPayload

    const handleChangeChannelIDs = (value: any) => {
        // handleChangeSlackConfigPayload('slack_channel_id', value.join(','))
        const existingIds = slackConfigPayload.slack_channel_id;
        let newIds;
        if (existingIds.trim() === "") {
            newIds = value;
        } 
        else if (existingIds.split(',').includes(value)) {
            newIds = existingIds
                .split(',')
                .filter(id => id !== value)
                .join(',');
        } 
        else {
            newIds = `${existingIds},${value}`;
        }
        setSlackConfigPayload({...slackConfigPayload,
            slack_channel_id: newIds
        })
    }

    return (
        <Grid container direction={"column"} gap={"20px"} className={styles.popupGlobalPadding}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                {/* <span className={styles.popupTitleStyle}>{flagUpdate ? "Update Agent" : "Add New Agent"}</span> */}
                <span className={styles.popupTitleStyle}>Select Channel</span>
            </Grid>
            <Grid item>
                <Divider className={styles.popupDividerStyle} />
            </Grid>
            <Grid item>
                <Grid container gap={2}>
                    {channelIntegrationData && channelIntegrationData.slack_channels.length > 0 && channelIntegrationData.slack_channels.map((channel) => 
                        <Grid item>
                            <Grid container direction={"row"} gap={3}>
                                <Grid item>
                                    <Grid container className={cardStyles.facebookIntegrationCard} direction={"column"}>
                                        <Grid item className={cardStyles.slackPage}>
                                            <span className={cardStyles.slackIDStyle}>ID: {channel?.channel_id}</span>
                                        </Grid>

                                        <Grid item>
                                            <Grid container justifyContent={"end"}>
                                                <Grid item className={cardStyles.slackIcon}>
                                                    <img alt="" src={slackIcon} height={"32"} width={"32"} />
                                                </Grid>

                                                <Grid item className={cardStyles.connectionStyle} sx={{ color: channel.chatbot_slack_assignment_data && channel.chatbot_slack_assignment_data.length > 0 ? "#32BB46" : "#BB3232" }}>
                                                    <span className={cardStyles.connectionTextStyle}>{channel.chatbot_slack_assignment_data && channel.chatbot_slack_assignment_data.length > 0 ? "Connected" : "Not Connected"}</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item className={cardStyles.integrationPageName}>
                                            <span className={cardStyles.pageNameStyle}>{channel.slack_channel_name}</span>
                                        </Grid>

                                        <Grid item className={cardStyles.integrationPageCategory} sx={{ maxWidth: "100% !important" }}>
                                            <Grid container direction={"column"}>
                                                <Grid item className={cardStyles.pageCategoryStyle}>
                                                    <span>{"Slack Description"}</span>
                                                </Grid>

                                                <Tooltip title={channel.slack_channel_purpose} arrow placement="right" style={{ padding: "0px", paddingRight: "5px", paddingBlock: "0px" }}>
                                                    <Grid item className={`${cardStyles.pageCategoryTextStyle} ${cardStyles.slackDescriptionTextStyle}`}>
                                                        <span>{channel.slack_channel_purpose ? channel.slack_channel_purpose : "-"}</span>
                                                    </Grid>
                                                </Tooltip>

                                            </Grid>
                                        </Grid>

                                        <Grid item className={cardStyles.integrationPageCategory}>
                                            <Grid container direction={"row"} gap={1} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container direction={"column"}>
                                                        <Grid item className={cardStyles.pageCategoryStyle}>
                                                            <span>Linked Chatbot</span>
                                                        </Grid>
                                                        <Grid item className={cardStyles.chatbotNameStyle}>
                                                            {channel.chatbot_slack_assignment_data && channel.chatbot_slack_assignment_data.length > 0 ? channel.chatbot_slack_assignment_data.map((chatbot) => {
                                                                return <span className={cardStyles.chatbotNameLink}>{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</span>
                                                            }) : <span style={{ fontWeight: "500" }}>None</span>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <GlobalButton 
                                                        buttonText={slackConfigPayload.slack_channel_id.split(',').includes(channel.slack_channel_id.toString()) ? "Remove" : "Select"} 
                                                        className={slackConfigPayload.slack_channel_id.split(',').includes(channel.slack_channel_id.toString()) ? "secondaryButtonStyle" : 'linkChatbotButtonStyle'}
                                                        // disabled={slackConfigPayload.slack_channel_id.split(',').includes(channel.slack_channel_id.toString())}
                                                        onClick={(event) => {
                                                            handleChangeChannelIDs(channel.slack_channel_id.toString())
                                                            // setFlagSelectChannelPopup(false)
                                                        }} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    
                </Grid>    
            </Grid>
        </Grid>
    )
}

export default SlackConfigChannels
