import { Grid } from '@mui/material'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import GlobalButton from '../../utility/globalButton/button';
import TemplateCard from '../../global/templateCard';
import organizationLogo from "../../../assets/organization/Workspace.png";
import { useNavigate } from 'react-router-dom';
import { getOrganizationDetailDataModel } from '../../../models/axiosModel';

interface orgaCard {
    organizationDetail: getOrganizationDetailDataModel[]
}
const OrganizationCard = (props: orgaCard) => {
    const {organizationDetail} = props
    const navigate = useNavigate()

    return (
        <Grid container direction={"column"}>
            <Grid item className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight} ${globalStyles.globalHeadText} ${styles.subHeading}`}>Organization Details</Grid>
            <Grid item>
                <Grid container>
                    {organizationDetail && 
                        organizationDetail.map((organization: getOrganizationDetailDataModel) => <Grid item>
                            <TemplateCard
                                templateStyle={styles.organizationStyle}
                                cardName={organization.organization_name}
                                cardNameStyle={styles.cardNameTextStyle}
                                activityDetails={organization.organization_status_name}
                                activityStyle={organization.organization_status_name == "Active" ? styles.activeStyle : styles.notActiveStyle}
                                extraFields={[
                                    {
                                        fieldName: "Workspace Owner",
                                        fieldValue: organization.created_by_user_name,
                                        fieldNameStyle: styles.fieldName,
                                        fieldValueStyle: styles.fieldValue
                                    },
                                    {
                                        fieldName: "# Members",
                                        fieldValue: organization.organization_user_data.length,
                                        fieldNameStyle: styles.fieldName,
                                        fieldValueStyle: styles.fieldValue
                                    },
                                ]}
                                // cardDescription={`Created by : ${organizationDetails.created_by_user_email}`}
                                cardImage={<Grid item className={styles.workspaceIconstyle}><img alt="" src={organizationLogo} style={{ width: "22px", height: "auto" }} /></Grid>}
                                cardOnClick={() => { }}
                                cardActionButton={<GlobalButton buttonText={"View Members"} className={"channelsThemeBtnStyle"} onClick={() => {navigate(`/organization?organization_id=${organization.organization_id}`)}}/>}
                                // cardActionItem={<GlobalButton buttonText={`Status: ${organizationDetails.organization_status_name}`} className={"transparentBorderedButtonStyle"} />}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrganizationCard
