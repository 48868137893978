import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chatbotPreview.module.scss";
import resetBtnIcon from '../../../assets/chatbotAssets/Chatbot-Reset-Icon.png'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import chat from '../../../assets/chatbotAssets/chaticon large.svg'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import Cookies from 'js-cookie';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import QRCode from "../../../assets/images/chatbot/whatsappQrCode.png";
import StaticChatbotPreview from './staticChatbotPreview';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import LiveChatbotPreview from './liveChatbotPreview';
import WhatsAppIntegrationTabChatbotPreview from './whatsappIntegrationTabChatbotPreview';
import StaticThemeChatbotPreview from './staticThemeChatbotPreview';
import TestIntegrationTabChatbotPreview from './testIntegrationTabChatbotPreview';

interface ChatbotPreviewSectionInterface {
    flagSmallScreen: boolean,
    openSwipableDrawer: any,
    setOpenSwipableDrawer: any,
    toggleDrawer: any,
    currentTab: string,
    flagTryIt?: boolean,
    currentSubTab?: any,
    testChatbotFlag?: boolean,
    setTestChatbotFlag?: any,
    integrationExist?: boolean,
    setIntegrationExist?: any
}

const ChatbotPreviewSection = (props: ChatbotPreviewSectionInterface) => {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { showIntegrationDetails, setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload;
    const {
        flagSmallScreen,
        openSwipableDrawer,
        setOpenSwipableDrawer,
        toggleDrawer,
        currentTab,
        currentSubTab,
        testChatbotFlag,
        setTestChatbotFlag,
        integrationExist,
        setIntegrationExist
    } = props
    const [iframeKey, setIframeKey] = useState(0);
    const smallScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)')
    const reloadIframe = () => {
        setIframeKey(prevKey => prevKey + 1); // Increment key to trigger reload
    };
    useEffect(() => {
        // Call reloadIframe when chatBotDetails changes
        reloadIframe();
    }, [chatBotDetails]);
    const openBlog = () => {
        window.open("https://blog.writewiz.ai/ai-chatbot/sample-chatbot/sample-chatbot-toygenius/", '_blank')
    }

    return (
        <>
            {(currentTab == "Settings" || currentTab == "Embed Link & Domains" || currentTab == "Calendly" || currentTab == "Slack" || props.flagTryIt) ?
                <>
                    {currentSubTab && currentSubTab == "Theme Settings" || (currentSubTab == "Settings" && currentTab == "Settings") ?
                        <>
                            <StaticThemeChatbotPreview
                                flagSmallScreen={flagSmallScreen}
                                openSwipableDrawer={openSwipableDrawer}
                                setOpenSwipableDrawer={setOpenSwipableDrawer}
                                toggleDrawer={toggleDrawer}
                            />
                        </>
                        :
                        <>
                            <LiveChatbotPreview flagTryIt={props.flagTryIt} flagSmallScreen={flagSmallScreen} openSwipableDrawer={openSwipableDrawer} setOpenSwipableDrawer={setOpenSwipableDrawer} toggleDrawer={toggleDrawer} />

                        </>
                    }
                </>
                :
                currentTab == "WhatsApp Integration" ?
                    <>
                        {!smallScreen &&
                            <TestIntegrationTabChatbotPreview
                                flagSmallScreen={flagSmallScreen}
                                openSwipableDrawer={openSwipableDrawer}
                                setOpenSwipableDrawer={setOpenSwipableDrawer}
                                toggleDrawer={toggleDrawer}
                                testChatbotFlag={testChatbotFlag}
                                setTestChatbotFlag={setTestChatbotFlag}
                                integrationExist={integrationExist}
                                setIntegrationExist={setIntegrationExist}
                            />}
                        {/* <WhatsAppIntegrationTabChatbotPreview flagSmallScreen={flagSmallScreen} openSwipableDrawer={openSwipableDrawer} setOpenSwipableDrawer={setOpenSwipableDrawer} toggleDrawer={toggleDrawer} /> */}
                    </>
                    :
                    currentTab == "Facebook Integration" || currentTab == "Instagram Integration" ?
                        <>
                            <WhatsAppIntegrationTabChatbotPreview flagSmallScreen={flagSmallScreen} openSwipableDrawer={openSwipableDrawer} setOpenSwipableDrawer={setOpenSwipableDrawer} toggleDrawer={toggleDrawer} />
                        </>
                        :
                        <>
                            <StaticChatbotPreview flagSmallScreen={flagSmallScreen} openSwipableDrawer={openSwipableDrawer} setOpenSwipableDrawer={setOpenSwipableDrawer} toggleDrawer={toggleDrawer} />
                        </>
            }

        </>
    )
}

export default ChatbotPreviewSection