import * as React from "react";
import {
    Button,
    Grid,
    Typography,
    useMediaQuery,
    Autocomplete,
    TextField,
    styled,
    Popper,
    Switch,
    Checkbox
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbot.module.scss";
import { ChatbotContext, ChatbotContextPayload } from "../../../context/chatbotContext";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GlobalPopUp from "../../utility/globalPopUp";
import { AddEditDomainPopup } from "./AddEditDomainPopup";
import agent from "../../../services/api";
import ChatbotSettings from "./chatbotSettings";
import ChatbotEmbedLinkAndDomains from "./chatbotEmbedLinkAndDomains";
import ChatbotWhatsAppIntegration from "./whatsAppIntegration/chatbotWhatsAppIntegration";
import ChatbotPreviewSection from "./chatbotPreviewSection";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import '../../../assets/styles/componentStyles/chatbotStyles/chatbot.css'
import axios from "axios";
import { chatbotThemes, checkForErrorCode, checkForMatchingTheme, emptyUserDetail, flagAccessNotGranted, flagCookieExpired, flagDataNotFound, getErrorMessage, getFileTypeImgIcon, isAdmin, isDev, primaryColor } from "../../../utils/constants";
import { MetaContext, MetaContextPayload } from "../../../context/metaContext";
import ChatbotWhatsAppIntegrationDetails from "./chatbotWhatsappIntegrationDetails";
import ChatbotFacebookIntegration from "./facebookIntegration/chatbotFacebookIntegration";
import ChatbotFacebookIntegrationDetails from "./chatbotFacebookIntegrationDetails";
import ChatbotInstagramIntegration from "./instagramIntegration/chatbotInstagramIntegration";
import ChatbotInstagramIntegrationDetails from "./integrations/chatbotInstagramIntegrationDetails";
import AppointmentSchedule from "./calendly/appointmentSchedule";
import GlobalButton from "../../utility/globalButton/button";
import IconsColor from "../../utility/iconsColor";
import ChatbotSlackIntegration from "./integrations/slack/integratedChannels";
import IntegrationList from "./integrations/integrationList";
import IntegrationWrapper from "./integrations/integrationWrapper";
interface propsViewChatbot {
    currentTab: string
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-option': {
        paddingLeft: "16px !important",
        paddingRight: "16px !important",
        minHeight: "0px !important",
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        fontSize: "14px !important"
    },
}))
export const ViewChatbot = ({ currentTab }: propsViewChatbot) => {
    const [searchParams] = useSearchParams()
    const urlParams = new URLSearchParams(window.location.search);
    const { setError, error, setFlagLoading, success, setSuccess, userDetail, setUserDetail, languageList } = useContext(UserContext) as UserContextPayload;
    const { chatBotDetails, UpdateChatbotApi, setChatBotDetails, chatbotThemeValue, setChatbotThemeValue, chatbotTheme, setChatbotTheme, GetChatbotListApi, chatbotThemeDetails, setChatbotThemeDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const { GetChatbotIntegrationData, setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload;
    const [currentSubTab, setCurrentSubTab] = React.useState(urlParams.get('currentTab') || "Settings");
    const [flagDomainPopup, setFlagDomainPopup] = React.useState(false);
    const [currentDomain, setCurrentDomain] = React.useState("");
    const [resetchatsData, setResetChatsData] = useState({
        flagShowResetPopup: false,
        flagReset: true
    })
    const ThemeOptions = ["Default Theme", "Green Theme", "Blue Theme", "Premium Theme", "Black and White Theme"]

    const [flagEditDomain, setFlagEditDomain] = React.useState({
        flag: false,
        domain_id: ""
    });
    const [files, setFiles] = useState([]);
    let navigate = useNavigate();
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1200px)')
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)')
    const flagMiniLaptop = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1400px)')
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 900px)')
    const flagLaptopScreen = useMediaQuery<boolean>('(min-width: 900px) and (max-width: 1600px)')
    const [openSwipableDrawer, setOpenSwipableDrawer] = useState<boolean>(false)
    const [testChatbotFlag, setTestChatbotFlag] = useState<boolean>(false)
    const [integrationExist, setIntegrationExist] = useState<boolean>(false)
    const toggleDrawer = (newOpen: any) => () => {
        setOpenSwipableDrawer(newOpen);
    };

    const innerHeight = flagMobileScreen ? (window.innerHeight - 120) : (window.innerHeight - 90);
    const innerWhatsappHeight = flagMobileScreen ? (window.innerHeight - 120) : (window.innerHeight - 90);

    const chatbotTabsRenderMainItemIH = flagMobileScreen ? (window.innerHeight - 120) : flagTabletScreen ? (window.innerHeight - 170) : flagLaptopScreen ? (window.innerHeight - 150) : (window.innerHeight - 130);
    const whatsappIntegratedTabsRenderMainItemIH = flagMobileScreen || flagTabletScreen ? (window.innerHeight - 100) : flagSmallScreen ? (window.innerHeight - 150) : (window.innerHeight - 125);
    const whatsappTabsRenderMainItemIH = flagMobileScreen || flagTabletScreen ? (window.innerHeight - 120) : (window.innerHeight - 110);
    const cbThemeRenderMainItemIH = flagMobileScreen ? (window.innerHeight - 220) : (window.innerHeight - 265);
    const cbTabsRenderMainItemIH = flagMobileScreen ? (window.innerHeight - 170) : (window.innerHeight - 200);
    const sampleChatbotEditThemeSettingsIH = flagMobileScreen ? (window.innerHeight - 130) : (window.innerHeight - 160);
    const sampleChatbotThemeSettingsIH = flagMobileScreen ? (window.innerHeight - 220) : flagSmallScreen ? (window.innerHeight - 240) : (window.innerHeight - 260);
    const sampleChatbotEditSettingsIH = flagMobileScreen ? (window.innerHeight - 125) : flagSmallScreen ? (window.innerHeight - 140) : (window.innerHeight - 150);
    const sampleChatbotSettingsIH = flagMobileScreen ? (window.innerHeight - 175) : flagSmallScreen ? (window.innerHeight - 190) : (window.innerHeight - 200);

    const arrayToGetFormValue = [
        {
            key: "1511_Chatbot_Name",
            field: "chatbot_name",
            isTheme: false
        },
        {
            key: "1512_Chatbot_description",
            field: "chatbot_discription",
            isTheme: false
        },
        {
            key: "1513_Chatbot_Message_Format",
            field: "chatbot_message_format",
            isTheme: false
        },
        {
            key: "1514_Chatbot_Sample_Conversation",
            field: "chatbot_sample_conversation",
            isTheme: false
        },

        {
            key: "1515_Chatbot_Scheduling_Prompt",
            field: "chatbot_scheduling_prompt ",
            isTheme: false
        },
        {
            key: "1516_Initial_Message",
            field: "chatbot_initial_message",
            isTheme: false
        },
        {
            key: "1517_Files",
            field: "chatbot_knowledge_base",
            isTheme: false
        },
        {
            key: "1518_Chatbot_Profile_Picture",
            field: "chatbot_logo_id",
            isTheme: false
        },
        {
            key: "1519_Chatbot_Favicon_Id",
            field: "chatbot_favicon_id",
            isTheme: false
        },
        {
            key: "1520_Chatbot_Detail_Message_Instruction",
            field: "chatbot_detail_message_instruction",
            isTheme: false
        },
        {
            key: "1521_Chatbot_Primary_Language",
            field: "language_id",
            isTheme: false
        },
        {
            key: "1522_Validated_JSON_Output",
            field: "response_format_json",
            isTheme: false
        }
    ]
    const arrayToGetThemeValue = [
        {
            key: "1523_System_Message_Background_Color",
            field: "system_msg_bg_color",
            isTheme: true
        },
        {
            key: "1524_User_Message_Background_Color",
            field: "user_msg_bg_color",
            isTheme: true
        },
        {
            key: "1525_System_Message_Text_Color",
            field: "system_msg_color",
            isTheme: true
        },
        {
            key: "1526_User_Message_Text_Color",
            field: "user_msg_color",
            isTheme: true
        },
        {
            key: "1527_Chat_Header_Background_Color",
            field: "chatbot_header_color",
            isTheme: true
        },
        {
            key: "1528_Chat_Header_Text_Color",
            field: "chatbot_header_font_color",
            isTheme: true
        },
        {
            key: "1529_Chat_Background_Color",
            field: "chatbot_back_ground_color",
            isTheme: true
        },
        {
            key: "1530_Send_Message_Background_Color",
            field: "chatbot_send_message_bg_color",
            isTheme: true
        },
        {
            key: "1531_Send_Message_Font_Color",
            field: "chatbot_send_message_font_color",
            isTheme: true
        },
        {
            key: "1532_Send_Message_Icon_Color",
            field: "chatbot_send_message_icon_color",
            isTheme: true
        },
        {
            key: "1533_Show_Powered_By_Chronox",
            field: "show_powered_by_chronox",
            isTheme: true
        },
    ]


    let themeFieldData: any = [
        {
            "page_field_id": 1511,
            "label": "Chatbot Name",
            "placeholder": "Enter a name for your Chatbot",
            "required": true,
            "name": "1511_Chatbot_Name",
            "type": "text",
            "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_name,
            "hidden": false,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1512,
            "label": "Purpose of Chatbot",
            "placeholder": "Provide clear and concise prompts for the best results.",
            "required": true,
            "name": "1512_Chatbot_description",
            "type": "longtext",
            max: 30000,
            min: 5,
            "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_discription ? chatBotDetails.chatbot_discription : "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            is_disabled: false,
            "textLimit": 1000
        },
        {
            "page_field_id": 1513,
            "label": "Chatbot Message Format",
            "placeholder": "Provide the message formats used in this prompt",
            "required": false,
            "name": "1513_Chatbot_Message_Format",
            "type": "longtext",
            max: 30000,
            min: 5,
            "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_message_format ? chatBotDetails.chatbot_message_format : "",
            "hidden": isAdmin(userDetail) ? false : true,
            "position": null,
            "font_color": null,
            "tooltip": "This is only for Admins & Super-Admins",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            is_disabled: false,
            "textLimit": 1000
        },
        {
            "page_field_id": 1514,
            "label": "Chatbot Sample Conversation",
            "placeholder": "Provide the sample conversation",
            "required": false,
            "name": "1514_Chatbot_Sample_Conversation",
            "type": "longtext",
            max: 30000,
            min: 5,
            "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_sample_conversation ? chatBotDetails.chatbot_sample_conversation : "",
            "hidden": isAdmin(userDetail) ? false : true,
            "position": null,
            "font_color": null,
            "tooltip": "This is only for Admins & Super-Admins",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            is_disabled: false,
            "textLimit": 1000
        },
        {
            "page_field_id": 1515,
            "label": "Chatbot Scheduling prompt",
            "placeholder": "Provide the scheduling prompt",
            "required": false,
            "name": "1515_Chatbot_Scheduling_Prompt",
            "type": "longtext",
            max: 30000,
            min: 5,
            "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_scheduling_prompt ? chatBotDetails.chatbot_scheduling_prompt : "",
            "hidden": isAdmin(userDetail) ? false : true,
            "position": null,
            "font_color": null,
            "tooltip": "This is only for Admins & Super-Admins",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            is_disabled: false,
            "textLimit": 1000
        },
        {
            "page_field_id": 1516,
            "label": "Initial Message",
            "placeholder": "Welcome! I'm your virtual assistant, here to offer support and information. What can I do for you today?",
            "required": false,
            "name": "1516_Initial_Message",
            "type": "longtext",
            min: 5,
            "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_initial_message,
            "hidden": false,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "textLimit": 1000
        },

        {
            "page_field_id": 1517,
            "label": "Knowledge Base",
            "type": "file",
            "value": [],
            "col": 12,
            "name": "1517_Files",
            "placeholder": "Drag & drop files here, or click to select files",
            "multiple_file": 30,
            "file_size": "5",
            "is_avatar": false,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false,
            "multiple_file_allow": true,
            "fileType": ".gif, .jpeg, .jpg, .png, .webp, text/json, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip, text/x-python, text/x-script.python",
            "tooltip": "Allowed File Formats (.gif, .jpeg, .jpg, .png, .webp, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip,)",
            "fileNewDesign": true,
            "is_disabled": false,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "max_no_of_files": 5
        },
        {
            "page_field_id": 1518,
            "label": "Chatbot Profile Picture",
            "type": "file",
            "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_logo_id,
            "col": 12,
            "name": "1518_Chatbot_Profile_Picture",
            "placeholder": "Drag & drop files here, or click to select files",
            "multiple_file": 1,
            "file_size": "3",
            "is_avatar": false,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "multiple_file_allow": false,
        },
        {
            "page_field_id": 1519,
            "label": "Widget Icon",
            "type": "file",
            "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_favicon_id,
            "col": 12,
            "name": "1519_Chatbot_Favicon_Id",
            "placeholder": "Drag & drop files here, or click to select files",
            "multiple_file": 1,
            "file_size": "3",
            "is_avatar": false,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "multiple_file_allow": false,
        },
        {
            "page_field_id": 1520,
            "label": "Chatbot Specific Instructions",
            "type": "longtext",
            "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_detail_message_instruction,
            "col": 12,
            "name": "1520_Chatbot_Detail_Message_Instruction",
            "placeholder": "These are the instructions that would be attached to each message",
            "is_avatar": false,
            "required": false,
            "hidden": isAdmin(userDetail) ? false : true,
            "tooltip": "This is only for Admins & Super-Admins",
            "flag_ai_suggestion": false,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1521,
            "label": "Chatbot Primary Language",
            "placeholder": null,
            "required": false,
            "name": "1521_Chatbot_Primary_Language",
            "type": "searchableDropdown",
            "value": chatBotDetails == null ? "" : languageList.find((language) => (language.language_id == chatBotDetails.language_id)),
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "options": languageList,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            optionLabel: "name",

        },
        {
            "page_field_id": 1522,
            "label": "Validated JSON Output",
            "placeholder": null,
            "required": false,
            "name": "1522_Validated_JSON_Output",
            "type": "checkbox",
            "value": chatBotDetails == null ? "" : chatBotDetails.response_format_json,
            "hidden": isAdmin(userDetail) ? false : true,
            "tooltip": "This is only for Admins & Super-Admins",
            "position": null,
            "font_color": null,
            "col": 12,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1523,
            "label": "System Message Background Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1523_System_Message_Background_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#fff",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": flagMiniLaptop ? 12 : 5.5,
            "id": "system_msg_bg_color",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1524,
            "label": "User Message Background Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1524_User_Message_Background_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F7E9FF",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": flagMiniLaptop ? 12 : 5.5,
            "id": "user_msg_bg_color",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1525,
            "label": "System Message Text Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1525_System_Message_Text_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails.chatbot_theme.system_msg_color : "#000000",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "system_msg_color"
        },

        {
            "page_field_id": 1526,
            "label": "User Message Text Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1526_User_Message_Text_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails.chatbot_theme.user_msg_bg_color : "#000000",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "user_msg_bg_color"
        },
        {
            "page_field_id": 1527,
            "label": "Chat Header Background Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1527_Chat_Header_Background_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails.chatbot_theme.chatbot_header_color : "#F7E9FF",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_header_color"
        },
        {
            "page_field_id": 1528,
            "label": "Chat Header Text Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1528_Chat_Header_Text_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails.chatbot_theme.chatbot_header_font_color : "#000000",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_header_font_color"
        },
        {
            "page_field_id": 1529,
            "label": "Chat Background Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1529_Chat_Background_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_back_ground_color"
        },
        {
            "page_field_id": 1530,
            "label": "Send Message Background Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1530_Send_Message_Background_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails.chatbot_theme.chatbot_send_message_bg_color : "#fff",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_send_message_bg_color"
        },
        {
            "page_field_id": 1531,
            "label": "Send Message Font Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1531_Send_Message_Font_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_send_message_font_color ? chatBotDetails.chatbot_theme.chatbot_send_message_font_color : "#000000",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_send_message_font_color"
        },
        {
            "page_field_id": 1532,
            "label": "Send Message Icon Color",
            "placeholder": "Color Code",
            "required": false,
            "name": "1532_Send_Message_Icon_Color",
            "type": "colorPicker",
            "value": chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails.chatbot_theme.chatbot_send_message_icon_color : "#6A097D",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "chatbot_send_message_icon_color"
        },
        {
            "page_field_id": 1533,
            "label": "Show Powered by Chronox",
            "placeholder": "Color Code",
            "required": false,
            "name": "1533_Show_Powered_By_Chronox",
            "type": "checkbox",
            "value": chatBotDetails?.chatbot_theme?.show_powered_by_chronox ? chatBotDetails.chatbot_theme.show_powered_by_chronox : true,
            "hidden": isAdmin(userDetail) ? false : true,
            "tooltip": "This is only for Admins & Super-Admins",
            "position": null,
            "font_color": null,
            "col": "5",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
            "id": "show_powered_by_chronox",
        },
    ]

    const [initialThemeSettingForm, setInitialThemeSettingForm] = useState<any>(themeFieldData);

    const getFileData = async (id: number | string, page_field_id: string,) => {
        const fileResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${id}&flag_info=true`);
        let fileType = fileResponse.data.data.original_file_name.split('.').pop()
        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
        return { ...fileResponse.data.data, page_field_id: page_field_id, file_data: fileResponse.data.data, name: fileResponse.data.data.original_file_name, lastModified: fileResponse.data.data.uploaded_at, size: fileResponse.data.data.file_size, fileNewDesign: true, previewImageSrc: fileTypeIconSrc };
    };
    useEffect(() => {
        // setTestChatbotFlag(false);
        setIntegrationExist(false);
        if (chatBotDetails != null) {
            (async () => {
                const knowledgeBaseArr = await Promise.all(chatBotDetails.chatbot_knowledge_base.map(async (knowledgeBase) => {
                    const fileResp = await getFileData(knowledgeBase.knowledge_base_id, '1517');
                    return fileResp;
                }));

                let fileArray: any = []
                if (chatBotDetails.chatbot_logo_id) {
                    let response: any = await agent.getFileDetail.get(Number(chatBotDetails?.chatbot_logo_id));
                    if (response.status) {
                        let fileTypeChatbotId = response.data.original_file_name.split('.').pop()
                        let fileTypeIconSrcChatbot = getFileTypeImgIcon(fileTypeChatbotId) ?? ""
                        let chatbotObj = {
                            ...response.data, page_field_id: 1518, file_data: response.data, name: response.data.original_file_name, lastModified: response.data.uploaded_at, previewImageSrc: fileTypeIconSrcChatbot, size: response.data.file_size, fileNewDesign: true
                        }
                        fileArray.push(chatbotObj)
                    }
                }
                if (chatBotDetails.chatbot_favicon_id) {
                    let response: any = await agent.getFileDetail.get(Number(chatBotDetails?.chatbot_favicon_id));
                    if (response.status) {
                        let fileTypeChatbotId = response.data.original_file_name.split('.').pop()
                        let fileTypeIconSrcChatbot = getFileTypeImgIcon(fileTypeChatbotId) ?? ""
                        let chatbotObj = {
                            ...response.data, page_field_id: 1519, file_data: response.data, name: response.data.original_file_name, lastModified: response.data.uploaded_at, previewImageSrc: fileTypeIconSrcChatbot, size: response.data.file_size, fileNewDesign: true
                        }
                        fileArray.push(chatbotObj)
                    }
                }
                let filesTemp = [...fileArray, ...knowledgeBaseArr]
                // @ts-ignore
                setFiles(filesTemp)
                var tempdata = { ...createChatbotFormData }
                tempdata.data.global_form_field = [
                    {
                        "page_field_id": 1511,
                        "label": "Chatbot Name",
                        "placeholder": "Enter a name for your Chatbot",
                        "required": true,
                        "name": "1511_Chatbot_Name",
                        "type": "text",
                        "value": chatBotDetails.chatbot_name ? chatBotDetails.chatbot_name : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField" : "sampleChatbotFormField",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1512,
                        "label": "Purpose of Chatbot",
                        "placeholder": "Provide clear and concise prompts for the best results.",
                        "required": true,
                        "name": "1512_Chatbot_description",
                        "type": "longtext",
                        max: 35000,
                        min: 5,
                        "value": chatBotDetails?.chatbot_discription ? chatBotDetails.chatbot_discription : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField minHeight400" : "sampleChatbotFormField minHeight300",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1513,
                        "label": "Chatbot Message Format",
                        "placeholder": "Provide the message formats used in this prompt",
                        "required": false,
                        "name": "1513_Chatbot_Message_Format",
                        "type": "longtext",
                        max: 30000,
                        min: 5,
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_message_format ? chatBotDetails.chatbot_message_format : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1514,
                        "label": "Chatbot Sample Conversation",
                        "placeholder": "Provide the sample conversation",
                        "required": false,
                        "name": "1514_Chatbot_Sample_Conversation",
                        "type": "longtext",
                        max: 30000,
                        min: 5,
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_sample_conversation ? chatBotDetails.chatbot_sample_conversation : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1515,
                        "label": "Chatbot Scheduling prompt",
                        "placeholder": "Provide the scheduling prompt",
                        "required": false,
                        "name": "1515_Chatbot_Scheduling_Prompt",
                        "type": "longtext",
                        max: 30000,
                        min: 5,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_scheduling_prompt ? chatBotDetails.chatbot_scheduling_prompt : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1520,
                        "label": "Chatbot Specific Instructions",
                        "type": "longtext",
                        "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_detail_message_instruction,
                        "col": 12,
                        "name": "1520_Chatbot_Detail_Message_Instruction",
                        "placeholder": "These are the instructions that would be attached to each message",
                        "is_avatar": false,
                        "required": false,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "flag_ai_suggestion": false,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField minHeight300" : "sampleChatbotFormField minHeight300",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1521,
                        "label": "Chatbot Primary Language",
                        "placeholder": null,
                        "required": false,
                        "name": "1521_Chatbot_Primary_Language",
                        "type": "searchableDropdown",
                        "value": chatBotDetails == null ? "" : languageList.find((language) => (language.language_id == chatBotDetails.language_id)),
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": 12,
                        "options": languageList,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        optionLabel: "name"
                    },
                    {
                        "page_field_id": 1522,
                        "label": "Validated JSON Output",
                        "placeholder": null,
                        "required": false,
                        "name": "1522_Validated_JSON_Output",
                        "type": "checkbox",
                        "value": chatBotDetails == null ? "" : chatBotDetails.response_format_json,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "position": null,
                        "font_color": null,
                        "col": 12,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                    },
                    {
                        "page_field_id": 1516,
                        "label": "Initial Message",
                        "placeholder": "Welcome! I'm your virtual assistant, here to offer support and information. What can I do for you today?",
                        "required": false,
                        "name": "1516_Initial_Message",
                        "type": "longtext",
                        min: 5,
                        "value": chatBotDetails?.chatbot_initial_message ? chatBotDetails.chatbot_initial_message : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField" : "sampleChatbotFormField",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    // commenting this so that these don't get overridden when cancel btn is pressed
                    {
                        "page_field_id": 1517,
                        "label": "Knowledge Base",
                        "type": "file",
                        "value": knowledgeBaseArr,
                        "col": 12,
                        "name": "1517_Files",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "multiple_file": 30,
                        "file_size": "5",
                        "is_avatar": false,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": true,
                        "fileType": ".gif, .jpeg, .jpg, .png, .webp, text/json, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip, text/x-python, text/x-script.python",
                        "tooltip": "Allowed File Formats (.gif, .jpeg, .jpg, .png, .webp, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip,)",
                        fileNewDesign: true,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "max_no_of_files": 5
                    },
                    {
                        "page_field_id": 1518,
                        "label": "Chatbot Profile Picture",
                        "type": "file",
                        "value": fileArray.filter((file: any) => file.page_field_id == 1518),
                        "col": 12,
                        "name": "1518_Chatbot_Profile_Picture",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "multiple_file": 1,
                        "file_size": "3",
                        "is_avatar": false,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": false,
                        "fileType": ".png, .jpg, .jpeg",
                        fileNewDesign: true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1519,
                        "label": "Widget Icon",
                        "type": "file",
                        "value": fileArray.filter((file: any) => file.page_field_id == 1519),
                        "col": 12,
                        "name": "1519_Chatbot_Favicon_Id",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "file_size": "3",
                        "is_avatar": false,
                        "multiple_file": 1,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": false,
                        "fileType": ".png, .jpg, .jpeg",
                        fileNewDesign: true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },

                    {
                        "page_field_id": 1523,
                        "label": "System Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1523_System_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#fff",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "system_msg_bg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1524,
                        "label": "User Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1524_User_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F7E9FF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "user_msg_bg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1525,
                        "label": "System Message Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1525_System_Message_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails.chatbot_theme.system_msg_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "system_msg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1526,
                        "label": "User Message Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1526_User_Message_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails.chatbot_theme.user_msg_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "user_msg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1527,
                        "label": "Chat Header Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1527_Chat_Header_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails.chatbot_theme.chatbot_header_color : "#F7E9FF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_header_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1528,
                        "label": "Chat Header Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1528_Chat_Header_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails.chatbot_theme.chatbot_header_font_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_header_font_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1529,
                        "label": "Chat Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1529_Chat_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_back_ground_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1530,
                        "label": "Send Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1530_Send_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails.chatbot_theme.chatbot_send_message_bg_color : "#fff",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_bg_color"
                    },
                    {
                        "page_field_id": 1531,
                        "label": "Send Message Font Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1531_Send_Message_Font_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_font_color ? chatBotDetails.chatbot_theme.chatbot_send_message_font_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_font_color"
                    },
                    {
                        "page_field_id": 1532,
                        "label": "Send Message Icon Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1532_Send_Message_Icon_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails.chatbot_theme.chatbot_send_message_icon_color : "#6A097D",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_icon_color"
                    },
                    {
                        "page_field_id": 1533,
                        "label": "Show Powered by Chronox",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1533_Show_Powered_By_Chronox",
                        "type": "checkbox",
                        "value": chatBotDetails?.chatbot_theme.show_powered_by_chronox !== null ? chatBotDetails.chatbot_theme.show_powered_by_chronox : true,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "position": null,
                        "font_color": null,
                        "col": "5",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "show_powered_by_chronox"
                    },
                ]
                tempdata.flagRendered = true
                setCreateChatbotFormData(tempdata)
                setChatbotThemeDetails(chatBotDetails.chatbot_theme)
                setInitialThemeSettingForm([
                    {
                        "page_field_id": 1511,
                        "label": "Chatbot Name",
                        "placeholder": "Enter a name for your Chatbot",
                        "required": true,
                        "name": "1511_Chatbot_Name",
                        "type": "text",
                        "value": chatBotDetails.chatbot_name ? chatBotDetails.chatbot_name : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField" : "sampleChatbotFormField",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1512,
                        "label": "Purpose of Chatbot",
                        "placeholder": "Provide clear and concise prompts for the best results.",
                        "required": true,
                        "name": "1512_Chatbot_description",
                        "type": "longtext",
                        max: 35000,
                        min: 5,
                        "value": chatBotDetails?.chatbot_discription ? chatBotDetails.chatbot_discription : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField minHeight400" : "sampleChatbotFormField minHeight300",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1513,
                        "label": "Chatbot Message Format",
                        "placeholder": "Provide the message formats used in this prompt",
                        "required": false,
                        "name": "1513_Chatbot_Message_Format",
                        "type": "longtext",
                        max: 30000,
                        min: 5,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_discription ? chatBotDetails.chatbot_discription : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1514,
                        "label": "Chatbot Sample Conversation",
                        "placeholder": "Provide the sample conversation",
                        "required": false,
                        "name": "1514_Chatbot_Sample_Conversation",
                        "type": "longtext",
                        max: 30000,
                        "tooltip": "This is only for Admins & Super-Admins",
                        min: 5,
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_sample_conversation ? chatBotDetails.chatbot_sample_conversation : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1515,
                        "label": "Chatbot Scheduling prompt",
                        "placeholder": "Provide the scheduling prompt",
                        "required": false,
                        "name": "1515_Chatbot_Scheduling_Prompt",
                        "type": "longtext",
                        "tooltip": "This is only for Admins & Super-Admins",
                        max: 30000,
                        min: 5,
                        "value": chatBotDetails == null ? "" : chatBotDetails?.chatbot_scheduling_prompt ? chatBotDetails.chatbot_scheduling_prompt : "",
                        "hidden": isAdmin(userDetail) ? false : true,
                        "position": null,
                        "font_color": null,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: false,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1520,
                        "label": "Chatbot Specific Instructions",
                        "type": "longtext",
                        "value": chatBotDetails == null ? "" : chatBotDetails.chatbot_detail_message_instruction,
                        "col": 12,
                        "name": "1520_Chatbot_Detail_Message_Instruction",
                        "placeholder": "These are the instructions that would be attached to each message",
                        "is_avatar": false,
                        "required": false,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "flag_ai_suggestion": false,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField minHeight300" : "sampleChatbotFormField minHeight300",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    {
                        "page_field_id": 1521,
                        "label": "Chatbot Primary Language",
                        "placeholder": null,
                        "required": false,
                        "name": "1521_Chatbot_Primary_Language",
                        "type": "searchableDropdown",
                        "value": chatBotDetails == null ? "" : languageList.find((language) => (language.language_id == chatBotDetails.language_id)),
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "position": null,
                        "font_color": null,
                        "col": 12,
                        "options": languageList,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        optionLabel: "name"
                    },
                    {
                        "page_field_id": 1522,
                        "label": "Validated JSON Output",
                        "placeholder": null,
                        "required": false,
                        "name": "1522_Validated_JSON_Output",
                        "type": "checkbox",
                        "value": chatBotDetails == null ? "" : chatBotDetails.response_format_json,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "position": null,
                        "font_color": null,
                        "col": 12,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                    },
                    {
                        "page_field_id": 1516,
                        "label": "Initial Message",
                        "placeholder": "Welcome! I'm your virtual assistant, here to offer support and information. What can I do for you today?",
                        "required": false,
                        "name": "1516_Initial_Message",
                        "type": "longtext",
                        min: 5,
                        "value": chatBotDetails?.chatbot_initial_message ? chatBotDetails.chatbot_initial_message : "",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        class2: chatBotDetails.chatbot_flag_edit ? "chatbotFormField" : "sampleChatbotFormField",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "textLimit": 1000
                    },
                    // commenting this so that these don't get overridden when cancel btn is pressed
                    {
                        "page_field_id": 1517,
                        "label": "Knowledge Base",
                        "type": "file",
                        "value": knowledgeBaseArr,
                        "col": 12,
                        "name": "1517_Files",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "multiple_file": 30,
                        "file_size": "5",
                        "is_avatar": false,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": true,
                        "fileType": ".gif, .jpeg, .jpg, .png, .webp, text/json, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip, text/x-python, text/x-script.python",
                        "tooltip": "Allowed File Formats (.gif, .jpeg, .jpg, .png, .webp, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip,)",
                        fileNewDesign: true,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "max_no_of_files": 5
                    },
                    {
                        "page_field_id": 1518,
                        "label": "Chatbot Profile Picture",
                        "type": "file",
                        "value": fileArray.filter((file: any) => file.page_field_id == 1518),
                        "col": 12,
                        "name": "1518_Chatbot_Profile_Picture",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "multiple_file": 1,
                        "file_size": "3",
                        "is_avatar": false,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": false,
                        "fileType": ".png, .jpg, .jpeg",
                        fileNewDesign: true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1519,
                        "label": "Widget Icon",
                        "type": "file",
                        "value": fileArray.filter((file: any) => file.page_field_id == 1523),
                        "col": 12,
                        "name": "1519_Chatbot_Favicon_Id",
                        "placeholder": "Drag & drop files here, or click to select files",
                        "file_size": "3",
                        "is_avatar": false,
                        "required": false,
                        "hidden": false,
                        "flag_ai_suggestion": false,
                        "multiple_file_allow": false,
                        "multiple_file": 1,
                        "fileType": ".png, .jpg, .jpeg",
                        fileNewDesign: true,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1523,
                        "label": "System Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1523_System_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#fff",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "system_msg_bg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1524,
                        "label": "User Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1524_User_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F7E9FF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "user_msg_bg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1525,
                        "label": "System Message Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1525_System_Message_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails.chatbot_theme.system_msg_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "system_msg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1526,
                        "label": "User Message Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1526_User_Message_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails.chatbot_theme.user_msg_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "user_msg_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1527,
                        "label": "Chat Header Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1527_Chat_Header_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails.chatbot_theme.chatbot_header_color : "#F7E9FF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_header_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1528,
                        "label": "Chat Header Text Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1528_Chat_Header_Text_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails.chatbot_theme.chatbot_header_font_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_header_font_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1529,
                        "label": "Chat Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1529_Chat_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "id": "chatbot_back_ground_color",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        is_disabled: chatBotDetails.chatbot_flag_edit ? false : true
                    },
                    {
                        "page_field_id": 1530,
                        "label": "Send Message Background Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1530_Send_Message_Background_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails.chatbot_theme.chatbot_send_message_bg_color : "#fff",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_bg_color"
                    },
                    {
                        "page_field_id": 1531,
                        "label": "Send Message Font Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1531_Send_Message_Font_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_font_color ? chatBotDetails.chatbot_theme.chatbot_send_message_font_color : "#000000",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_font_color"
                    },
                    {
                        "page_field_id": 1532,
                        "label": "Send Message Icon Color",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1532_Send_Message_Icon_Color",
                        "type": "colorPicker",
                        "value": chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails.chatbot_theme.chatbot_send_message_icon_color : "#6A097D",
                        "hidden": false,
                        "position": null,
                        "font_color": null,
                        "col": flagMiniLaptop ? 12 : 5.5,
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "chatbot_send_message_icon_color"
                    },
                    {
                        "page_field_id": 1533,
                        "label": "Show Powered by Chronox",
                        "placeholder": "Color Code",
                        "required": false,
                        "name": "1533_Show_Powered_By_Chronox",
                        "type": "checkbox",
                        "value": chatBotDetails?.chatbot_theme.show_powered_by_chronox !== null ? chatBotDetails.chatbot_theme.show_powered_by_chronox : true,
                        "hidden": isAdmin(userDetail) ? false : true,
                        "tooltip": "This is only for Admins & Super-Admins",
                        "position": null,
                        "font_color": null,
                        "col": "5",
                        "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
                        "id": "show_powered_by_chronox"
                    },
                ])
                // setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })()
        }
    }, [chatBotDetails])

    const [createChatbotFormData, setCreateChatbotFormData] = useState<any>({
        data: {
            global_form_field: themeFieldData,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "8px 0px",
                    "page_theme_column_spacing": 2
                }
            ]
        }
    })

    useEffect(() => {
        (async () => {
            let chatbotId = searchParams.get('chatbot_id')
            if (chatbotId) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
                let response = await GetChatbotIntegrationData(chatbotId, "");
                if (response.status) {
                    if (response.data && response.data.length > 0) {
                        setShowIntegrationDetails(true)
                    } else {
                        setShowIntegrationDetails(false)
                    }
                }
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
            }
        })()
    }, [window.location.href])

    const cancelUpdateChatbot = () => {
        if (currentSubTab === "Settings") {
            let temp = JSON.stringify([...initialThemeSettingForm])
            // setFiles(initialThemeSettingForm.find((field: any) => field.name === "1517_Files").value)
            setCreateChatbotFormData((prevState: any) => {
                const tempArray = JSON.parse(temp);

                const filteredGlobalFormField = prevState.data.global_form_field.filter(
                    (field: any) => !tempArray.some((tempField: any) => tempField.page_field_id === field.page_field_id)
                );
                let updatedGlobalFormField = [...filteredGlobalFormField, ...tempArray];
                updatedGlobalFormField = updatedGlobalFormField.sort((a, b) => (a.page_field_id - b.page_field_id))
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        global_form_field: updatedGlobalFormField
                    }
                };
            });
            // setCreateChatbotFormData({ ...createChatbotFormData, data: { ...createChatbotFormData.data, global_form_field: JSON.parse(temp) } })

        } else {
            let temp = JSON.stringify([...initialThemeSettingForm])
            setCreateChatbotFormData((prevState: any) => {
                const tempArray = JSON.parse(temp);

                const filteredGlobalFormField = prevState.data.global_form_field.filter(
                    (field: any) => !tempArray.some((tempField: any) => tempField.page_field_id === field.page_field_id)
                );
                let updatedGlobalFormField = [...filteredGlobalFormField, ...tempArray];
                updatedGlobalFormField = updatedGlobalFormField.sort((a, b) => (a.page_field_id - b.page_field_id))
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        global_form_field: updatedGlobalFormField
                    }
                };
            });
            setChatbotThemeValue(chatbotTheme.prevTheme);
            setChatbotThemeDetails(chatbotTheme.prevTheme)
        }

    }
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('currentTab', newValue);
        window.history.replaceState({}, '', '?' + urlParams.toString());
        setCurrentSubTab(newValue);
    };

    let extraFieldsPayload: string[] = [
        // "chatbot_type",
        // "parsona_id",
        // "chatbot_knowledge_base",
        "chatbot_header",
        "chatbot_domain",
    ]


    const setFieldValueOfTheme = (data: any) => {
        var tempdata = { ...createChatbotFormData }

        tempdata.data.global_form_field.map((newvalue: any) => {

            if (newvalue.options) {
                // alert('1')
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })

        const updatedThemeDetails = {
            system_msg_bg_color: tempdata.data.global_form_field.find((field: any) => field.id === 'system_msg_bg_color')?.value || '',
            user_msg_bg_color: tempdata.data.global_form_field.find((field: any) => field.id === 'user_msg_bg_color')?.value || '',

            system_msg_color: tempdata.data.global_form_field.find((field: any) => field.id === "system_msg_color")?.value || '',
            user_msg_color: tempdata.data.global_form_field.find((field: any) => field.id === "user_msg_color")?.value || '',

            chatbot_header_color: tempdata.data.global_form_field.find((field: any) => field.id === 'chatbot_header_color')?.value || '',
            chatbot_header_font_color: tempdata.data.global_form_field.find((field: any) => field.id === "chatbot_header_font_color")?.value || '',

            chatbot_back_ground_color: tempdata.data.global_form_field.find((field: any) => field.id === "chatbot_back_ground_color")?.value || '',
            chatbot_send_message_bg_color: tempdata.data.global_form_field.find((field: any) => field.id === "chatbot_send_message_bg_color")?.value || '',

            chatbot_send_message_font_color: tempdata.data.global_form_field.find((field: any) => field.id === "chatbot_send_message_font_color")?.value || '',
            chatbot_send_message_icon_color: tempdata.data.global_form_field.find((field: any) => field.id === "chatbot_send_message_icon_color")?.value || '',

        };

        setChatbotThemeDetails(updatedThemeDetails)
        // setInitialThemeSettingForm(tempdata)
        // setFlagFirstRenderDone(true)


    }

    const saveThemeSettings = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        let fields = createChatbotFormData["data"]["global_form_field"]
        let payload: any = {}
        let themeObj: any = {
            "chatbot_font_color": chatBotDetails?.chatbot_theme.chatbot_font_color ? chatBotDetails.chatbot_theme.chatbot_font_color : "#F7E9FF",
            "chatbot_back_ground_color": chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
            "system_msg_color": chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails.chatbot_theme.system_msg_color : "#000000",
            "user_msg_color": chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails.chatbot_theme.user_msg_color : "#000000",
            "system_msg_bg_color": chatBotDetails?.chatbot_theme.system_msg_bg_color ? chatBotDetails.chatbot_theme.system_msg_bg_color : "#fff",
            "user_msg_bg_color": chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F7E9FF",
            'chatbot_send_message_bg_color': chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails.chatbot_theme.chatbot_send_message_bg_color : "#fff",
            'chatbot_send_message_font_color': chatBotDetails?.chatbot_theme.chatbot_send_message_font_color ? chatBotDetails.chatbot_theme.chatbot_send_message_font_color : "#000000",
            'chatbot_send_message_icon_color': chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails.chatbot_theme.chatbot_send_message_icon_color : "#6A097D",
            'chatbot_header_color': chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails.chatbot_theme.chatbot_header_color : "#F7E9FF",
            'chatbot_header_font_color': chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails.chatbot_theme.chatbot_header_font_color : "#000000",
            'show_powered_by_chronox': chatBotDetails?.chatbot_theme?.show_powered_by_chronox !== null ? chatBotDetails?.chatbot_theme.show_powered_by_chronox : true
        }
        if (currentSubTab === "Settings") {
            for (let i = 0; i < arrayToGetFormValue.length; i++) {
                if (arrayToGetFormValue[i]?.field == "chatbot_logo_id") {
                    let id = fields.filter((singleObj: any) => singleObj.label == "Chatbot Profile Picture")[0].value[0]?.google_storage_id
                    payload[arrayToGetFormValue[i]?.field] = id ? id : null

                } else if (arrayToGetFormValue[i]?.field == "chatbot_favicon_id") {
                    let id = fields.filter((singleObj: any) => singleObj.label == "Widget Icon")[0].value[0]?.google_storage_id
                    payload[arrayToGetFormValue[i]?.field] = id ? id : null

                } else if (arrayToGetFormValue[i]?.field == "chatbot_knowledge_base") {
                    let knowledgeBaseVal = fields.filter((field: any) => field.name === "1517_Files");
                    if (knowledgeBaseVal && knowledgeBaseVal[0].value.length > 0) {
                        payload[arrayToGetFormValue[i]?.field] = knowledgeBaseVal[0].value.map((field: any) => {
                            return ({ 'knowledge_base_id': field.google_storage_id })
                        })
                    } else {
                        payload[arrayToGetFormValue[i]?.field] = []
                    }
                }
                else {
                    payload[arrayToGetFormValue[i]?.field] =
                        fields.filter((singleObj: any) => singleObj['name'] == arrayToGetFormValue[i].key)[0].value
                }
            }
            let primary_language = fields.find((field: any) => field.page_field_id == 1521).value
            payload['language_id'] = typeof primary_language === 'object' ? primary_language.language_id : primary_language
            let show_powered_by_chronox = fields.find((field: any) => field.page_field_id == 1533).value
            payload['show_powered_by_chronox'] = show_powered_by_chronox
        }
        else {
            let chatbot_knowledge_base = chatBotDetails?.chatbot_knowledge_base.map(item => ({ knowledge_base_id: item.knowledge_base_id }));
            payload['chatbot_name'] = chatBotDetails?.chatbot_name
            payload['chatbot_discription'] = chatBotDetails?.chatbot_discription
            payload['chatbot_initial_message'] = chatBotDetails?.chatbot_initial_message
            payload['chatbot_logo_id'] = chatBotDetails?.chatbot_logo_id
            payload['chatbot_favicon_id'] = chatBotDetails?.chatbot_favicon_id
            payload['chatbot_knowledge_base'] = chatbot_knowledge_base
            for (let i = 0; i < arrayToGetThemeValue.length; i++) {
                themeObj[arrayToGetThemeValue[i]?.field] =
                    fields.filter((singleObj: any) => singleObj['name'] == arrayToGetThemeValue[i].key)[0].value
            }
        }


        // payload['chatbot_theme'] = themeObj
        payload['chatbot_theme'] = chatbotThemeDetails
        let show_powered_by_chronox = fields.find((field: any) => field.page_field_id == 1533).value
        payload['chatbot_theme']['show_powered_by_chronox'] = show_powered_by_chronox
        for (let j = 0; j < extraFieldsPayload.length; j++) {
            // @ts-ignore
            payload[extraFieldsPayload[j]] = chatBotDetails[extraFieldsPayload[j]] ? chatBotDetails[extraFieldsPayload[j]] : null
        }
        let chatbotId = searchParams.get('chatbot_id')
        payload['chatbot_id'] = chatbotId
        payload['flag_reset_conversation'] = resetchatsData.flagReset
        const response: any = await UpdateChatbotApi(payload)
        if (response.status) {
            if (response?.assistant?.status == false && isAdmin(userDetail)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: "Assistant did not update" }))
            }
            if (createChatbotFormData.data.global_form_field.find((field: any) => field.page_field_id == 1511).value !== initialThemeSettingForm?.find((field: any) => field.page_field_id == 1511).value) {
                GetChatbotListApi()
            }
            setChatbotTheme({ ...chatbotTheme, savedTheme: chatbotThemeValue, prevTheme: chatbotThemeValue })
            setChatBotDetails(response.data)
            setSuccess({ ...success, flagShowSuccess: true, message: "Chatbot updated successfully!" })
        } else {
            let errorCode = checkForErrorCode(response);
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagAccessNotGranted(userDetail, errorCode) || flagDataNotFound(userDetail, errorCode)) {
                    navigate('/')
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setResetChatsData((prevState) => ({ ...prevState, flagShowResetPopup: false }))
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    const handleOpenDomainPopup = async () => {
        setFlagEditDomain({
            flag: false,
            domain_id: ""
        })
        setCurrentDomain("")
        setFlagDomainPopup(true)
    }
    const handleOpenEditDomainPopup = async (e: any) => {
        setFlagEditDomain({
            flag: true,
            domain_id: e.chatbot_domain_id
        })
        setCurrentDomain(e.chatbot_domain_name)
        setFlagDomainPopup(true)
    }

    let tabs = [
        {
            id: 1,
            tabName: "Settings"
        },
        {
            id: 2,
            tabName: "Theme Settings"
        },
        // {
        //     id: 3,
        //     tabName: "WhatsApp Integration"
        // },
        // {
        //     id: 4,
        //     tabName: "User Sessions"
        // },
    ]
    const [newTabList, setNewTabList] = useState(tabs)

    useEffect(() => {
        if (chatBotDetails?.chatbot_flag_edit) {
            setNewTabList(tabs)
        } else {
            setNewTabList([
                {
                    id: 1,
                    tabName: "Settings"
                },
                // {
                //     id: 2,
                //     tabName: "Embed Link & Domains"
                // },
                {
                    id: 2,
                    tabName: "Theme Settings"
                },
            ])
        }
    }, [window.location.pathname])
    const getSaveButtonDisableFlag = () => {
        if (!chatBotDetails?.chatbot_flag_edit) {
            return true
        } else {
            // if (JSON.stringify(createChatbotFormData.data.global_form_field) == JSON.stringify(initialThemeSettingForm)) {
            //     return true
            // } else {
            const globalFormFields = createChatbotFormData.data.global_form_field;
            let isAnyRequiredFieldEmpty = false;
            for (const field of globalFormFields) {
                if (field.required == true && (field.value === "" || field.value === null || field.value.length == 0)) {
                    isAnyRequiredFieldEmpty = true; // Found at least one empty required field
                    break;
                }
            }
            return isAnyRequiredFieldEmpty
            // }
        }
    }

    const handleChangeTheme = (value: any) => {
        var tempdata = { ...createChatbotFormData }
        tempdata.data.global_form_field.slice(6).forEach((item: any) => {
            if (value.hasOwnProperty(item.id)) {
                item.value = value[item.id]
            }
        });
        setChatbotThemeDetails(value);
        setCreateChatbotFormData(tempdata)
    }
    const getComponentBasedOnCurrentTab = (currentTab: string) => {
        switch (currentTab) {
            case "Settings":
                return <ChatbotSettings
                    flagSmallScreen={flagSmallScreen}
                    flagMobileScreen={flagMobileScreen}
                    currentTab={currentSubTab}
                    setCreateChatbotFormData={setCreateChatbotFormData}
                    createChatbotFormData={createChatbotFormData}
                    files={files}
                    setFiles={setFiles}
                    setFieldValueOfTheme={setFieldValueOfTheme}
                />
            case "Embed Link & Domains":
                return <ChatbotEmbedLinkAndDomains
                    setFlagDomainPopup={setFlagDomainPopup}
                    handleOpenEditDomainPopup={handleOpenEditDomainPopup}
                    handleOpenDomainPopup={handleOpenDomainPopup}
                />

            case "Theme Settings":
                return <ChatbotSettings
                    flagSmallScreen={flagSmallScreen}
                    flagMobileScreen={flagMobileScreen}
                    currentTab={currentSubTab}
                    setCreateChatbotFormData={setCreateChatbotFormData}
                    createChatbotFormData={createChatbotFormData}
                    files={files}
                    setFiles={setFiles}
                    setFieldValueOfTheme={setFieldValueOfTheme}
                />
            case "WhatsApp Integration":
                return <ChatbotWhatsAppIntegration />
            case "WhatsApp Integration Details":
                return <ChatbotWhatsAppIntegrationDetails />
            case "Facebook Integration":
                return <ChatbotFacebookIntegration />
            case "Facebook Integration Details":
                return <ChatbotFacebookIntegrationDetails />
            case "Instagram Integration":
                return <ChatbotInstagramIntegration />
            case "Instagram Integration Details":
                return <ChatbotInstagramIntegrationDetails />
            case "Calendly":
                return <AppointmentSchedule />
            case "Slack":
                return <ChatbotSlackIntegration />
            case "Integrations":
                return <IntegrationWrapper />
            default:
                return <></>
        }
    }
    function flagShowChatbotPreview() {
        if (currentTab == "Settings" || currentTab == "WhatsApp Integration" || currentTab == "Embed Link & Domains") {
            return true
        }
        return false
    }
    function getLeftBlockXLSize(flagSmallScreen: any, currentTab: any, integrationExist: any, testChatbotFlag: any) {
        if (flagSmallScreen || currentTab == "Integrations") {
            return 12;
        }

        if ((currentTab == "Settings" || currentTab === "WhatsApp Integration" && !integrationExist) ||
            currentTab === "Facebook Integration" ||
            currentTab === "Embed Link & Domains" ||
            currentTab === "Instagram Integration" ||
            currentTab === "Calendly" ||
            currentTab === "Slack") {
            return 7.5;
        }

        if (currentTab === "WhatsApp Integration" && !testChatbotFlag && integrationExist) {
            return 11;
        }

        if (currentTab === "WhatsApp Integration" && testChatbotFlag && integrationExist) {
            return 7.5;
        }

        return 6;
    }
    return (
        <>
            {flagDomainPopup &&
                <>
                    <GlobalPopUp
                        open={flagDomainPopup}
                        onClose={() => { setFlagDomainPopup(false) }}
                        title={flagEditDomain.flag ? "Edit Domain" : "New Domain"}
                        flagBackdrop
                        flagShowCloseIcon={true}
                        handleClose={() => { setFlagDomainPopup(false) }}
                    >
                        <AddEditDomainPopup
                            setFlagDomainPopup={setFlagDomainPopup}
                            setCurrentDomain={setCurrentDomain}
                            currentDomain={currentDomain}
                            flagEdit={flagEditDomain}
                        />
                    </GlobalPopUp>
                </>
            }

            <Grid container className={`${globalStyles.dashboardChildWrapper} ${styles.dashboardStyle}`} direction="column">

                {/* HEADER */}

                {/* TAB RENDERING */}
                <Grid item xs={12} className={flagMobileScreen ? styles.cbViewMainMobileItem : styles.cbViewMainItem}>
                    <Grid container flexWrap={"nowrap"}>
                        {/* chatbotDetails */}
                        <Grid item xs={flagSmallScreen ? 12 : 6} lg={currentTab == "Integrations" ? 12 : 6.5}
                            xl={getLeftBlockXLSize(flagSmallScreen, currentTab, integrationExist, testChatbotFlag)}
                            className={styles.cbDetailsMainItem}>
                            <Grid container>
                                <Grid item xs={12} sx={{ margin: "0px 0px 2px" }} className={styles.cbDetailsTabitem}>
                                    {currentTab === "Settings" &&
                                        <Tabs
                                            value={currentSubTab}
                                            onChange={handleChangeTab}
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            className={'chatbotTabsComponentStyle'}
                                            aria-label="secondary tabs example"
                                        >
                                            {newTabList.map((tabDetail, i) => (
                                                <Tab
                                                    className={`${styles.tabStyle} ${styles.chatbotTabStyle}`}
                                                    key={tabDetail.id}
                                                    value={tabDetail.tabName}
                                                    label={tabDetail.tabName}
                                                />
                                            ))}
                                        </Tabs>}
                                </Grid>
                                {flagSmallScreen && currentTab !== "WhatsApp Integration" && currentTab !== "WhatsApp Integration Details" && currentTab !== "Facebook Integration" && currentTab !== "Facebook Integration Details" && currentTab !== "Instagram Integration" && currentTab !== "Instagram Integration Details" && currentTab !== "Slack" && currentTab !== "Integrations" &&
                                    <Grid item className={currentTab === "Settings" ? styles.cbDetailsSettingsPreviewMainItem : styles.cbDetailsPreviewMainItem} onClick={() => { setOpenSwipableDrawer(true) }}>
                                        <Grid container sx={{ gap: "5px", cursor: "pointer", justifyContent: "end" }}>
                                            <img
                                                src={previewIcon}
                                                className={globalStyles.previewIconStyle}
                                                alt=""
                                            />
                                            <Typography
                                                component={'span'}
                                                className={globalStyles.previewIconFont}
                                            >
                                                Preview
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }

                                {chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" && currentTab === "Settings" &&
                                    <Grid item xs={12} className={styles.themeSettingsStyle}>
                                        <Grid container justifyContent={"end"} gap={"10px"}>
                                            <Grid item>
                                                <Autocomplete
                                                    PopperComponent={StyledPopper}
                                                    className={styles.setThemeAutoComplete}
                                                    disableClearable
                                                    id="combo-box-demo"
                                                    value={chatbotThemes.find((theme) => theme.chatbot_theme_name == checkForMatchingTheme(createChatbotFormData.data.global_form_field.slice(6))) ?? null}
                                                    options={chatbotThemes}
                                                    getOptionLabel={(option: any) => option.chatbot_theme_name}
                                                    defaultValue={ThemeOptions[0]}
                                                    onChange={(event, value: any) => {
                                                        handleChangeTheme(value)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} placeholder='Try Theme' />} />
                                            </Grid>

                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    fullWidth
                                                    className={styles.cbResetButton}
                                                    onClick={cancelUpdateChatbot}
                                                    // disabled={chatbotTheme.prevTheme === chatbotThemeValue}
                                                    disabled={false}
                                                    // sx={{ opacity: chatbotTheme.prevTheme === chatbotThemeValue ? "0.5" : "" }}
                                                    sx={{ opacity: "1", width: "160px !important", maxWidth: "160px !important" }}
                                                >
                                                    {/* Reset Theme */}
                                                    Discard Changes
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                }

                                {<Grid item xs={12}
                                    className={chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? styles.sampleChatbotSettings : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? styles.sampleChatbotEditSettings : chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? styles.sampleChatbotThemeSettings : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? styles.sampleChatbotEditThemeSettings : currentTab === "Settings" ? currentSubTab === "Settings" ? styles.cbTabsRenderMainItem : `${styles.cbTabsRenderMainItem} ${styles.cbThemeRenderMainItem}` : currentTab === "Facebook Integration" || currentTab === "WhatsApp Integration" || currentTab === "Instagram Integration" ? styles.whatsappTabsRenderMainItem : currentTab === "WhatsApp Integration Details" || currentTab === "Facebook Integration Details" || currentTab === "Instagram Integration Details" ? styles.whatsappIntegratedTabsRenderMainItem : styles.chatbotTabsRenderMainItem}
                                    sx={{
                                        height: chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? `${sampleChatbotSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? `${sampleChatbotEditSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? `${sampleChatbotThemeSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? `${sampleChatbotEditThemeSettingsIH}px !important` : currentTab === "Settings" ? currentSubTab === "Settings" ? `${cbTabsRenderMainItemIH}px !important` : `${cbThemeRenderMainItemIH}px !important` : currentTab === "Facebook Integration" || currentTab === "WhatsApp Integration" || currentTab === "Instagram Integration" ? `${whatsappTabsRenderMainItemIH}px !important` : currentTab === "WhatsApp Integration Details" || currentTab === "Facebook Integration Details" || currentTab === "Instagram Integration Details" ? `${styles.whatsappIntegratedTabsRenderMainItem}px !important` : `${styles.chatbotTabsRenderMainItem}px !important`,
                                        maxHeight: chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? `${sampleChatbotSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Settings" ? `${sampleChatbotEditSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? `${sampleChatbotThemeSettingsIH}px !important` : chatBotDetails?.chatbot_flag_public && !chatBotDetails?.chatbot_flag_edit && currentSubTab === "Theme Settings" ? `${sampleChatbotEditThemeSettingsIH}px !important` : currentTab === "Settings" ? currentSubTab === "Settings" ? `${cbTabsRenderMainItemIH}px !important` : `${cbThemeRenderMainItemIH}px !important` : currentTab === "Facebook Integration" || currentTab === "WhatsApp Integration" || currentTab === "Instagram Integration" ? `${whatsappTabsRenderMainItemIH}px !important` : currentTab === "WhatsApp Integration Details" || currentTab === "Facebook Integration Details" || currentTab === "Instagram Integration Details" ? `${styles.whatsappIntegratedTabsRenderMainItem}px !important` : `${styles.chatbotTabsRenderMainItem}px !important`,
                                        overflow: "auto"
                                    }}
                                >
                                    {chatBotDetails != null && createChatbotFormData.flagRendered &&
                                        getComponentBasedOnCurrentTab(currentTab)
                                    }
                                </Grid>}
                                {chatBotDetails?.chatbot_flag_edit &&
                                    (currentTab == "Settings") &&
                                    <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                                        <Grid container sx={{ justifyContent: "start" }} className={styles.cbSaveCancelContainer}>
                                            <Grid item>
                                                {/* <Button
                                                    variant="outlined"
                                                    size="medium"
                                                    fullWidth
                                                    className={styles.cbCancelButton}
                                                    onClick={cancelUpdateChatbot}
                                                    disabled={chatBotDetails?.chatbot_flag_edit == true ? false : true}
                                                    sx={{ opacity: chatBotDetails?.chatbot_flag_edit ? "" : "0.5" }}
                                                >
                                                    Cancel
                                                </Button> */}
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    fullWidth
                                                    className={styles.cbSaveButton}
                                                    // onClick={() => { saveThemeSettings() }}
                                                    onClick={() => { setResetChatsData((prevState) => ({ ...prevState, flagShowResetPopup: true })) }}
                                                    disabled={getSaveButtonDisableFlag()}
                                                    sx={{ opacity: getSaveButtonDisableFlag() ? "0.5" : "" }}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>

                        {/* chatbot preview */}
                        {flagShowChatbotPreview() &&
                            <Grid item
                                xs={flagSmallScreen ? 0 : 6}
                                lg={5.5}

                                xl={flagSmallScreen ? 12 : (currentTab === "WhatsApp Integration" && !integrationExist) || "Instagram Integration" || currentTab === "Embed Link & Domains" || currentTab === "Calendly" ? 4.5 : (currentTab === "WhatsApp Integration" && !testChatbotFlag && integrationExist) ? 1 : (currentTab === "WhatsApp Integration" && testChatbotFlag && integrationExist) ? 4.5 : 6}
                                className={currentTab === "WhatsApp Integration" ? styles.cbWPMainItem : styles.cbPreviewMainItem}
                                height={currentTab === "WhatsApp Integration" ? `${innerWhatsappHeight}px !important` : `${innerHeight}px !important`}
                                maxHeight={currentTab === "WhatsApp Integration" ? `${innerWhatsappHeight}px !important` : `${innerHeight}px !important`}
                            >
                                <ChatbotPreviewSection
                                    flagSmallScreen={flagSmallScreen}
                                    setOpenSwipableDrawer={setOpenSwipableDrawer}
                                    openSwipableDrawer={openSwipableDrawer}
                                    toggleDrawer={toggleDrawer}
                                    currentTab={currentTab}
                                    currentSubTab={currentSubTab}
                                    testChatbotFlag={testChatbotFlag}
                                    setTestChatbotFlag={setTestChatbotFlag}
                                    integrationExist={integrationExist}
                                    setIntegrationExist={setIntegrationExist}
                                />
                            </Grid>}
                        <>
                            {resetchatsData.flagShowResetPopup &&
                                <GlobalPopUp
                                    titlePadding="0px"
                                    flagShowCloseIcon
                                    open={resetchatsData.flagShowResetPopup}
                                    handleClose={() => { setResetChatsData((prevState) => ({ ...prevState, flagReset: true, flagShowResetPopup: false })) }}
                                >
                                    <Grid container direction="column" >
                                        <Grid item className={globalStyles.deleteConfirmHeaderPopup}>
                                            <Grid container >
                                                <Grid item sx={{ background: "#F7E9FF !important" }} className={globalStyles.deleteConfirmPopupStyle}>
                                                    <IconsColor iconName="reset" width="20.8px" height="20.8px" fillColor={primaryColor} />
                                                </Grid>
                                                <Grid item >
                                                    <Grid container direction="column">
                                                        <Grid item sx={{ color: "#6A097D !important" }} className={globalStyles.deleteConfirmPopupTextStyle}>
                                                            Reset Confirmation
                                                        </Grid>
                                                        <Grid item className={globalStyles.deleteConfirmPopupSubTextStyle}>
                                                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                                                <Grid item>
                                                                    <Checkbox className={styles.checkedIcon} checked={resetchatsData.flagReset} onChange={(event) => { setResetChatsData((prevState) => ({ ...prevState, flagReset: event.target.checked })) }} />

                                                                </Grid>
                                                                <Grid item sx={{ display: "flex", alignItems: "center", fontSize: "15px", fontWeight: "500" }}>Reset all chatbot conversations</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={globalStyles.deleteConfirmButtonsPopup}>
                                            <Grid container justifyContent="end">
                                                <Grid item style={{ marginLeft: "15px", width: "100px" }}>
                                                    <GlobalButton buttonText='Save' flagFullWidth className='primaryButtonStyle'
                                                        onClick={saveThemeSettings} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item>
                                        <Grid container direction={"column"} alignItems={"center"} sx={{ padding: "0px 20px 20px" }}>
                                            <Grid item>
                                                <Grid container justifyContent={"center"} alignContent={"center"}>
                                                    <Grid item>
                                                        <Checkbox checked={resetchatsData.flagReset} onChange={(event) => { setResetChatsData((prevState) => ({ ...prevState, flagReset: event.target.checked })) }} />

                                                    </Grid>
                                                    <Grid item sx={{ display: "flex", alignItems: "center", fontSize: "15px", fontWeight: "500" }}>Reset all chatbot conversations</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sx={{ marginTop: "20px" }}>
                                                <Grid container>
                                                    <Grid item>
                                                        <GlobalButton className="primaryButtonStyle" buttonText={"Reset"} onClick={saveThemeSettings} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </GlobalPopUp>

                            }

                        </>
                    </Grid>
                </Grid >

            </Grid >
        </>
    );
}
