import * as React from 'react';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import globalStyles from "../../assets/styles/global.module.scss";
import PhoneInput from 'react-phone-input-2';
import styles from "../../assets/styles/componentStyles/phoneComponent.module.scss";
import ClearIcon from '@mui/icons-material/Clear';

interface phoneComponentProps {
    defaultCountry?: string;
    value?: string;
    onChange: ({}) => void;
    disabled?: boolean,
    handleClear: ({}) => void;
    flagUpdate?: boolean;
}
export default function PhoneComponent(props: phoneComponentProps) {


    return (
        <>
            <Grid item className={`${globalStyles.fullWidth} ${styles.accDetailGlobalFieldsItem} ${styles.phoneInputWrapper}`}>
                {props.flagUpdate ? 
                    <PhoneInput country={props.defaultCountry ?? "in"} value={props.value}
                        onChange={(value) => props.onChange({ target: { value } })} disabled={props.disabled} dropdownStyle={{top: "auto", bottom: "100%", position: "absolute"}}
                    />
                    :
                    <PhoneInput country={props.defaultCountry ?? "in"} value={props.value}
                        onChange={(value) => props.onChange({ target: { value } })} disabled={props.disabled}
                    />                
                }
            </Grid>
        </>
    );
}
