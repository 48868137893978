import { Box, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import GlobalButton from '../utility/globalButton/button'
import thumbsUp from "../../assets/onboardingAssets/thumbs-up 1.png";
import styles from "../../assets/styles/componentStyles/popupFileStyles/onboardingEnd.module.scss";
import { UserContext, UserContextPayload } from '../../context/userContext';
import LazyLoadingImage from '../utility/lazyLoadingImage';
const OnboardingEnd = () => {
    const { flagShowOnboardingEndPopup, setFlagOnboardingEndPopup, setFlagShowScheduleCall } = useContext(UserContext) as UserContextPayload;
    const handleOpenScheduler = () => {
        setFlagShowScheduleCall(true);
        setFlagOnboardingEndPopup(false)
    }
    return (
        <Grid container direction="column" className={styles.upperGrid} alignItems={"center"}>
            <Grid item>
                <img src={thumbsUp} alt="numberChange" className={styles.upperLogo} />
            </Grid>
            <Grid item className={styles.confirmationText}>You are all set!</Grid>
            <Grid item className={styles.bottomContainer}>
                <Grid container justifyContent={"center"} className={styles.containerStyle} >
                    <Grid item className={styles.ppContainer}>
                        <LazyLoadingImage src={"https://storage.writewiz-staging.in/general/Profile_Krishna_1702538220929.jpg"} className={styles.ppContainer} flagShow={true} />
                    </Grid>
                    <Grid item>
                        <Grid container sx={{ maxWidth: "360px" }} direction={"column"} alignItems={"start"} justifyContent={"start"} gap="20px">
                            <Grid item className={styles.textStyle}>Krishna is your dedicated Technical Support Executive and can assist you in getting started.</Grid>
                            <Grid item>
                                <Grid container gap="15px">
                                    <Grid item sx={{ minWidth: "150px !important" }}><GlobalButton onClick={handleOpenScheduler} buttonText="Book a Meeting" flagFullWidth className='primaryButtonStyle' sx={{ fontWeight: "400 !important", fontSize: "14px" }} /></Grid>
                                    <Grid item><GlobalButton buttonText={"Remind me later"} onClick={() => { setFlagOnboardingEndPopup(false) }} className='transparentBorderedButtonStyle' sx={{ fontWeight: "400 !important", fontSize: "14px " }} /></Grid>
                                </Grid>
                            </Grid>
                        </Grid></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OnboardingEnd