import { Autocomplete, Divider, Grid, List, ListItem, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useRef } from 'react'
import { callAnalysisFeedbackDataResponse, feedback_data, feedbackKnowledgeBaseStatus, feedbackStatus, getCallAnalysisFeedbackResponseModel, getFeedbackResponse } from '../../../models/axiosModel'
import globalStyles from "../../../assets/styles/global.module.scss";
import style from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import { formatDate, formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants'
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext'
import { reverse } from 'lodash';
import { UserContext, UserContextPayload } from '../../../context/userContext';

interface UpdateFeedbackPopupProps {
    rowData: getFeedbackResponse | getCallAnalysisFeedbackResponseModel,
    setCurrenctRowData: React.Dispatch<React.SetStateAction<getFeedbackResponse | getCallAnalysisFeedbackResponseModel>>,
    updateFeedbackApiCall: (feedback_id: number, status_id: number, modification_status_id: number, reviewer_note: string | null, admin_note: string | null, isCallAnalysisFeedback: boolean) => void,
}

const UpdateFeedbackPopup: React.FC<UpdateFeedbackPopupProps> = ({ rowData, setCurrenctRowData, updateFeedbackApiCall }) => {

    const {
        feedbackStatusData,
        feedbackKnowledgeBaseStatusData, } = useContext(GenericDataContext) as GenericDataContextPayload;

    const { userDetail } = useContext(UserContext) as UserContextPayload
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const previousVal = useRef("");

    const data = [
        {
            field: "Source",
            value: "chatbot_name" in rowData ? rowData?.chatbot_name : rowData.call_record_google_storage_file_name
        },
        {
            field: "Date",
            value: formatDateAccordingToTimezoneUsingMoment(rowData?.created_at_timestamp, userDetail.timezone_hour)
        },
        {
            field: "Time Zone",
            value: userDetail.timezone_name
        },
        {
            field: "Provider Type",
            value: "feedback_provided_type_name" in rowData ? rowData?.feedback_provided_type_name : "-"
        },
        {
            field: "Provider",
            value: "feedback_provider" in rowData ? rowData?.feedback_provider : "-"
        },
        {
            field: "Category",
            value: "feedback_option_name" in rowData ? rowData?.feedback_option_name : rowData.call_analysis_feedback_option_name
        },
        {
            field: "More Detail",
            value: ""
        },
        {
            field: "View",
            value: "View"
        }
    ]

    const renderMoreDetails = (): JSX.Element | string => {
        if ("feedback_id" in rowData) {
            if (rowData?.feedback_data.length > 0 && rowData?.feedback_data[0].feedback_option_field_value != "") {
                return (
                    <Grid container direction="column">
                        <List sx={{ listStyleType: 'disc', margin: "0px", padding: "0px", listStylePosition: "unset", paddingInlineStart: "18px !important" }}>
                            {rowData?.feedback_data.map((rData: feedback_data) => {
                                return (
                                    <Grid item>
                                        <ListItem sx={{ display: 'list-item', padding: "0px" }}>
                                            <span style={{ fontWeight: "600", fontSize: "14px" }} >{rData.feedback_option_field_value}: <span style={{ fontWeight: "500", color: "#373737" }}>{rData.feedback_value}</span> </span>
                                        </ListItem>
                                    </Grid>
                                );
                            })}
                        </List>
                    </Grid>
                );
            } else {
                return "-";
            }
        } else {
            if (rowData.call_analysis_feedback_data.length > 0 && rowData.call_analysis_feedback_data[0].call_analysis_feedback_option_field_value != "") {
                return (
                    <Grid container direction="column">
                        <List sx={{ listStyleType: 'disc', margin: "0px", padding: "0px", listStylePosition: "unset", paddingInlineStart: "18px !important" }}>
                            {rowData?.call_analysis_feedback_data.map((rData: callAnalysisFeedbackDataResponse) => {
                                return (
                                    <Grid item>
                                        <ListItem sx={{ display: 'list-item', padding: "0px" }}>
                                            <span style={{ fontWeight: "600", fontSize: "14px" }} >{rData.call_analysis_feedback_option_field_value}: <span style={{ fontWeight: "500", color: "#373737" }}>{rData.call_analysis_feedback_data_value}</span> </span>
                                        </ListItem>
                                    </Grid>
                                );
                            })}
                        </List>
                    </Grid>
                );
            } else {
                return "-";
            }
        }
        return "-";
    }

    const onChangeOfFeedbackStatus = (value: feedbackStatus) => {
        if ("feedback_id" in rowData) {
            updateFeedbackApiCall(rowData.feedback_id, value.feedback_status_id, rowData.modification_status_id, rowData.reviewer_note, rowData.admin_note, false);
        } else {
            updateFeedbackApiCall(rowData.call_analysis_feedback_id, value.feedback_status_id, rowData.modification_status_id, rowData.reviewer_note, rowData.admin_note, true);
        }
    }

    const onChangeOfModificationStatus = (value: feedbackKnowledgeBaseStatus) => {
        if ("feedback_id" in rowData) {
            updateFeedbackApiCall(rowData.feedback_id, rowData.feedback_status_id, value.modification_status_id, rowData.reviewer_note, rowData.admin_note, false);
        } else {
            updateFeedbackApiCall(rowData.call_analysis_feedback_id, rowData.feedback_status_id, value.modification_status_id, rowData.reviewer_note, rowData.admin_note, true);
        }
    }

    const onChangeOfReviewerNote = (value: string) => {
        setCurrenctRowData((prevRowData) => {
            return {
                ...prevRowData,
                reviewer_note: value,
            }
        })
    }

    const onBlurOfReviewerNote = (value: string) => {
        if (value != previousVal.current) {
            if ("feedback_id" in rowData) {
                updateFeedbackApiCall(rowData.feedback_id, rowData.feedback_status_id, rowData.modification_status_id, value, rowData.admin_note, false);
            } else {
                updateFeedbackApiCall(rowData.call_analysis_feedback_id, rowData.feedback_status_id, rowData.modification_status_id, value, rowData.admin_note, true);
            }
        }
    }

    const onChangeOfAdminNote = (value: string) => {
        setCurrenctRowData((prevRowData) => {
            return {
                ...prevRowData,
                admin_note: value,
            }
        })
    }

    const onBlurOfAdminNote = (value: string) => {
        if (value != previousVal.current) {
            if ("feedback_id" in rowData) {
                updateFeedbackApiCall(rowData.feedback_id, rowData.feedback_status_id, rowData.modification_status_id, rowData.reviewer_note, value, false);
            } else {
                updateFeedbackApiCall(rowData.call_analysis_feedback_id, rowData.feedback_status_id, rowData.modification_status_id, rowData.reviewer_note, value, true);
            }
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography sx={{ color: "#6a097d", fontWeight: "600", fontSize: "16px", marginTop: "25px" }}>{"feedback_option_name" in rowData ? rowData?.feedback_option_name : rowData.call_analysis_feedback_option_name}</Typography>
                        </Grid>
                        <Divider sx={{ padding: "4px 0 !important", margin: "10px 30px 0 30px !important" }} />

                        <Grid item>
                            <Grid container sx={{ padding: "10px 30px" }}>
                                {data.map((item) => {
                                    return (
                                        <>
                                            <Grid item sm={3} xs={4.5} sx={{ marginTop: "10px" }}>
                                                <Typography sx={{ color: "#707070", fontWeight: "500", fontSize: "14px" }}>{item.field}</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={7.5} sx={{ marginTop: "10px" }}>
                                                {item.field === "More Detail" ?
                                                    <>
                                                        {renderMoreDetails()}
                                                    </>
                                                    :
                                                    item.field === "View" ?
                                                        ("feedback_id" in rowData && (rowData?.chatbot_user_id || (rowData.feedback_level_name === "Thread" && rowData.feedback_unique_id))) ?
                                                            <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                                                                <a
                                                                    href={`${window.location.origin}/inbox?chatbot_user_id=${rowData.chatbot_user_id ? rowData.chatbot_user_id : rowData.feedback_unique_id}&feedback_id=${rowData.feedback_id}`}
                                                                    target="_blank"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    View thread in Inbox
                                                                </a>
                                                            </Typography>
                                                            :
                                                            "-"
                                                        :
                                                        <Typography sx={{ fontWeight: "500", color: "black", fontSize: "14px" }}>{item.value != "" ? item.value : "-"}</Typography>
                                                }
                                            </Grid>
                                        </>
                                    );
                                })}

                            </Grid>

                        </Grid>
                        <Grid item>
                            <Grid container sx={{ padding: "10px 30px 30px" }} gap={3} justifyContent={"space-between"} direction={flagSmallScreen ? "column" : "row"}>
                                <Grid item xs={12} md={5.75} >
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <Typography sx={{ color: "#707070", fontWeight: "500", fontSize: "14px" }}>Approval Status</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                options={feedbackStatusData}
                                                value={feedbackStatusData.find((data) => data.feedback_status_id === rowData.feedback_status_id)}
                                                getOptionLabel={(option) => option.feedback_status_name}
                                                onChange={(e, value) => { onChangeOfFeedbackStatus(value); }}
                                                className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoCompletePopup}`}
                                                disableClearable={true}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.75}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <Typography sx={{ color: "#707070", fontWeight: "500", fontSize: "14px" }}>Modification Status</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                options={feedbackKnowledgeBaseStatusData}
                                                value={feedbackKnowledgeBaseStatusData.find((data) => data.modification_status_id === rowData.modification_status_id)}
                                                getOptionLabel={(option) => option.modification_status_name}
                                                onChange={(e, value) => { onChangeOfModificationStatus(value); }}
                                                className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoCompletePopup}`}
                                                disableClearable={true}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <Typography sx={{ color: "#707070", fontWeight: "500", fontSize: "14px" }}>Note By Reviewer</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                value={rowData.reviewer_note ? rowData.reviewer_note : ""}
                                                sx={{ width: "100%" }}
                                                className={`${style.feedbackPopupTextField}`}
                                                onChange={(e) => { onChangeOfReviewerNote(e.target.value); }}
                                                onBlur={(e) => { onBlurOfReviewerNote(e.target.value); }}
                                                onFocus={(e) => previousVal.current = e.target.value}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <Typography sx={{ color: "#707070", fontWeight: "500", fontSize: "14px" }}>Note By Admin</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                value={rowData.admin_note ? rowData.admin_note : ""}
                                                sx={{ width: "100%" }}
                                                className={`${style.feedbackPopupTextField}`}
                                                onChange={(e) => { onChangeOfAdminNote(e.target.value); }}
                                                onBlur={(e) => { onBlurOfAdminNote(e.target.value); }}
                                                onFocus={(e) => previousVal.current = e.target.value}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UpdateFeedbackPopup
