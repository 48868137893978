import { Autocomplete, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import React, { useContext, useEffect, useState } from 'react'
import { campaignContact, campaignContactData, campaignContactTagAssignment, createCampaignContactTagPayload, tagDataInterface, updateCampaignContactPayload } from '../../../models/axiosModel';
import GlobalButton from '../../utility/globalButton/button';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';
import AddIcon from '@mui/icons-material/Add';
import { UserContext, UserContextPayload } from '../../../context/userContext';

interface EditContactPopupProps {
    requiredCampaignContact: campaignContact[],
    setFlagEditContactDialog: React.Dispatch<React.SetStateAction<boolean>>,
    editCampaignContactApiCall: (payload: updateCampaignContactPayload) => void,
    createCampaignContactTagApiCall: (payload: createCampaignContactTagPayload) => void,

    contactFields: campaignContactData[],
    setContactFields: React.Dispatch<React.SetStateAction<campaignContactData[]>>,
    contactTagData: Omit<campaignContactTagAssignment, 'contact_tag_assignment_id'>[],
    setContactTagData: React.Dispatch<React.SetStateAction<Omit<campaignContactTagAssignment, 'contact_tag_assignment_id'>[]>>,

    flagAddTagDialog: boolean,
    setFlagAddTagDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

const EditContactPopup: React.FC<EditContactPopupProps> = ({ requiredCampaignContact, setFlagEditContactDialog, editCampaignContactApiCall, createCampaignContactTagApiCall, contactFields, setContactFields, contactTagData, setContactTagData, flagAddTagDialog, setFlagAddTagDialog }) => {

    const { campaignContactTagData, createCampaignContactTagApi } = useContext(ContactContext) as ContactContextPayload;

    // const [contactFields, setContactFields] = useState<campaignContactData[]>(requiredCampaignContact[0].contact_data);
    // const [contactTagData, setContactTagData] = useState<Omit<campaignContactTagAssignment, 'contact_tag_assignment_id'>[]>(requiredCampaignContact[0].contact_tag_assignment)


    const checkDisableSaveButton = () => {
        // for (let i = 0; i < contactFields.length; ++i) {
        //     if (contactFields[i].contact_data_value.trim() === "") {
        //         return true;
        //     }
        // }
        // return false; 
        if (contactFields.length > 0) {
            contactFields.map((field, ind) => {
                if (field.contact_field_name === "Phone Number") {
                    return true;
                }
            })
        } else return false;
    }

    const saveButtonClickHandler = () => {
        const contact_data = contactFields.map((field) => {
            return ({
                contact_data_id: field.contact_data_id,
                contact_data_value: field.contact_data_value
            });
        })
        const tag_id_array = contactTagData.map((tag) => {
            return tag.contact_tag_id
        })
        const payload: updateCampaignContactPayload = {
            contact_id: requiredCampaignContact[0].contact_id,
            contact_data: contact_data,
            contact_tag_id: tag_id_array,
        }
        editCampaignContactApiCall(payload);
        setFlagEditContactDialog(false);
    }

    const onChangeOfTag = (value: tagDataInterface[]) => {
        let flag_new_tag = false;
        for (let i = 0; i < value.length; ++i) {
            if (value[i].contact_tag_id === -1 && value[i].contact_tag_name === "New Tag") {
                flag_new_tag = true;
                break;
            }
        }
        if (flag_new_tag) {
            setFlagEditContactDialog(false);
            setFlagAddTagDialog(true);
        } else {
            setContactTagData(value);
        }
    }

    const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: tagDataInterface): React.ReactNode => {
        if (option.contact_tag_id === -1 && option.contact_tag_name === "New Tag") {
            return (
                <ul className={style.newTagNameUlElement}>
                    <Divider className={style.newTagDivider} />
                    <li {...props} style={{ minHeight: "auto", cursor: "pointer", padding: "10px 16px", display: "flex", alignItems: "center", gap: "5px", boxSizing: "border-box", outline: "none", color: "#6a097d" }} onClick={() => { setFlagEditContactDialog(false); setFlagAddTagDialog(true); }}>
                        <AddIcon />
                        <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>New Tag</Typography>
                    </li>
                </ul>
            )
        }
        return (
            <li {...props} key={option.contact_tag_id}>
                {option.contact_tag_name}
            </li>
        )
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}>Edit Contact</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={style.popupBodyContainer} spacing={2}>
                                {requiredCampaignContact[0]?.contact_data?.map((data, ind) => {
                                    return (
                                        <Grid item lg={6} xs={12}>
                                            <Grid container direction={"column"} gap={"10px"}>
                                                <Grid item xs={12}>
                                                    <Typography className={style.popupFieldTypography}>{data.contact_field_name} {data.contact_field_name === "Phone Number" && <span style={{ color: "#C60000" }}>*</span>}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        value={contactFields.find((field) => field.contact_field_name === data.contact_field_name)?.contact_data_value}
                                                        onChange={(e) => {
                                                            setContactFields((prev) => {
                                                                return prev.map((field) => {
                                                                    if (field.contact_field_name === data.contact_field_name) {
                                                                        field.contact_data_value = e.target.value;
                                                                    }
                                                                    return field;
                                                                })
                                                            })
                                                        }}
                                                        className={`${style.campaignContactPopupTextField}`}
                                                        placeholder={`Add ${data.contact_field_name}`}
                                                        sx={{ width: "100%" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}

                                <Grid item xs={12}>
                                    <Grid container direction={"column"} gap={"10px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Tag </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                className={`${style.contactFilterDropdownsInPopup}`}
                                                options={(campaignContactTagData[0].contact_tag_data) ? [...(campaignContactTagData[0].contact_tag_data), {
                                                    contact_tag_name: "New Tag",
                                                    contact_tag_id: -1,
                                                    created_at_timestamp: "",
                                                    updated_at_timestamp: "",
                                                }] : []}
                                                getOptionLabel={(option) => option.contact_tag_name || ''}
                                                multiple
                                                value={campaignContactTagData[0].contact_tag_data && campaignContactTagData[0].contact_tag_data?.length > 0
                                                    ? campaignContactTagData[0].contact_tag_data.filter((tag) =>
                                                        contactTagData.some((tagData) => tag.contact_tag_id === tagData.contact_tag_id)
                                                    ) : []
                                                }
                                                onChange={(_: React.SyntheticEvent, value: tagDataInterface[]) => { onChangeOfTag(value); }}
                                                ListboxProps={{ style: { maxHeight: '150px', overflow: 'auto', paddingBottom: "50px" } }}
                                                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: tagDataInterface) => { return renderOptions(props, option); }}
                                                renderInput={params => {
                                                    const { InputProps, ...restParams } = params;
                                                    const { startAdornment, ...restInputProps } = InputProps;
                                                    return (
                                                        <TextField
                                                            {...restParams}
                                                            placeholder='Select Tags'
                                                            InputProps={{
                                                                ...restInputProps,
                                                                startAdornment: (
                                                                    <div style={{
                                                                        maxHeight: "90px",
                                                                        overflowY: 'auto',
                                                                    }}
                                                                    >
                                                                        {startAdornment}
                                                                    </div>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"Center"} alignItems={"Center"} gap={"12px"} padding={"5px 0 25px"}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Cancel"
                                        className="borderedButtonStyle"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        onClick={() => { setFlagEditContactDialog(false); }}
                                    />
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Save"
                                        className='primaryButtonStyle'
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        disabled={checkDisableSaveButton()}
                                        onClick={() => { saveButtonClickHandler() }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default EditContactPopup
