import { Grid, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext';
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import CallAnalysisPieChart from './callAnalysisPieChart';
import CallAnalysisBarChart from './callAnalysisBarChart';
import CallAnalysisScoreList from './callAnalysisScoreList';
import CallAnalysisPagination from './callAnalysisPagination';


const CallAnalysisDashboard = () => {
    const { dashboardAnalysisData, appliedFilter } = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    const tableScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)')
    const innerHeight = appliedFilter ? (window.innerHeight - 170) : (window.innerHeight - 110);
    const innerMobileHeight = appliedFilter ? (window.innerHeight - 160) : (window.innerHeight - 100);
    const innerTabletHeight = appliedFilter ? (window.innerHeight - 195) : (window.innerHeight - 135);

    return (
        dashboardAnalysisData && 
            <Grid item sx={{width: "100%", height: smallScreen ? `${innerMobileHeight}px` : tableScreen ? `${innerTabletHeight}px` : `${innerHeight}px`, maxHeight: smallScreen ? `${innerMobileHeight}px` : tableScreen ? `${innerTabletHeight}px` : `${innerHeight}px`, overflow: "auto", padding: "5px 10px 5px 5px"}}>
                <Grid container direction={"column"} gap={"24px"}>

                    <Grid item sx={{width: "100%"}}>
                        <Grid container direction={{xs: "column", lg:"row"}} flexWrap={"nowrap"} gap={"24px"}>
                            <Grid item xs={12} lg={2} sx={{width: "100%"}}>
                                <Grid container direction={{xs: "row", lg: "column"}} gap={"15px"} flexWrap={"nowrap"}>
                                    <Grid item className={dashboardStyles.averageCallComponent} xs={12}>
                                        <Grid container direction={"column"} gap={"10px"}>
                                            <Grid item>
                                                <span className={dashboardStyles.averageHeadingName}>Average Score</span>
                                            </Grid>
                                            <Grid item>
                                                <span className={dashboardStyles.averageHeadingValue}>{dashboardAnalysisData?.average_score}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={dashboardStyles.averageCallComponent} xs={12}>
                                        <Grid container direction={"column"} gap={"10px"}>
                                            <Grid item>
                                                <span className={dashboardStyles.averageHeadingName}>Total Calls</span>
                                            </Grid>
                                            <Grid item>
                                                <span className={dashboardStyles.averageHeadingValue}>{dashboardAnalysisData?.total_calls}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4.5} sx={{width: "100%"}}>
                                <CallAnalysisPieChart fieldName={"Complexity"} fieldData={dashboardAnalysisData?.complexity_calls}/>
                            </Grid>
                            <Grid item xs={5.5} sx={{width: "100%"}}>
                                <CallAnalysisPieChart fieldName={"Categories"} fieldData={dashboardAnalysisData?.category_calls}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{width: "100%"}}>
                        <Grid container direction={{xs: "column", lg:"row"}} flexWrap={"nowrap"} gap={"24px"}>
                            <Grid item xs={5} sx={{width: "100%"}}>
                                <CallAnalysisBarChart fieldName={"Complexity"} fieldData={dashboardAnalysisData?.complexity_score}/>
                            </Grid>
                            <Grid item xs={7} sx={{width: "100%"}}>
                                <CallAnalysisBarChart fieldName={"Categories"} fieldData={dashboardAnalysisData?.category_score}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{width: "100%"}}>
                        <CallAnalysisBarChart fieldName={"Agent"} fieldData={dashboardAnalysisData?.agent_score}/>
                    </Grid>

                    <Grid item sx={{width: "100%", position: "relative"}}>
                        <Grid container direction={"column"} gap={"15px"}>
                            <Grid item>
                                <span className={dashboardStyles.pieChartHeadingName}>List of Scores</span>
                            </Grid>
                            <Grid item sx={{maxWidth: "-webkit-fill-available !important"}}>
                                <CallAnalysisScoreList />
                            </Grid>
                            <Grid item textAlign={"end"}>
                                <Grid container justifyContent={"end"}>
                                    <Grid item sx={{ padding: "0px 8px 0px 8px", boxShadow: "0px 0px 2px rgba(0,0,0,0.5)", borderRadius: "5px", marginRight: "2px"}}>
                                        <CallAnalysisPagination />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
    )
}

export default CallAnalysisDashboard
