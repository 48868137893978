
import { Checkbox, Divider, Grid, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import IconsColor from './iconsColor';
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/accountInformationCard.module.scss";
import GlobalButton from './globalButton/button';
import metaIcon from "../../assets/images/chatbot/metaIcon.png";
import { chatbotIntegrationAssignmentData } from '../../models/axiosModel';
import GlobalPopUp from './globalPopUp';
import QRCode from "react-qr-code";
import messengerIcon from '../../assets/chatbotAssets/facebookMessenger.svg'
import { ChatbotContext, ChatbotContextPayload } from '../../context/chatbotContext';
import { getIntegrationIcon } from '../../utils/getIcons';

interface Details {
    page_id?: any
    chatbot_name?: string
    chatbot_id?: string | number
    page_name?: string
    page_category?: string,
    linked_chatbots?: chatbotIntegrationAssignmentData[],
}

interface FacebookInformationCardProps {
    title: string
    details: Details
    currentlySelected: boolean
    flagDelete: boolean
    flagShowMetaIcon?: boolean
    deleteChatbotIntegration?: () => void;
    removeChatbotIntegration?: (page_id: string) => void
    addChatbotIntegration?: (page_id: string, event?: React.MouseEvent<HTMLButtonElement>) => void,
    showRemoveChatbotIntegration?: boolean,
    flagIntegration?: boolean,
    flagShowLinkedChatbot?: boolean,
    disabled?: boolean,
    integrationType: string,
    flagChatbotDeploy?: boolean,
}

// interface AccountInformationCardProps {
//     title: string,
//     details: Details,
//     currentlySelected: boolean,
//     flagDelete: boolean,
//     flagShowMetaIcon?: boolean,
//     deleteChatbotIntegration?: () => void;
//     removeChatbotIntegration?: (phone_number_id: string) => void
//     addChatbotIntegration?: (phone_number_id: string) => void
// }

const getValueStyle = (keyName: string, value: any) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        default:
            return { fontSize: "15px", fontWeight: "400", color: "#000" }
    }
}

const getDisplayKeyName = (keyName: string) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        case "page_id":
            return "Page Id";
        case "linked_chatbots":
            return "Linked Chatbots"
        case "chatbot_name":
            return "Linked Chatbots"
        case "page_category":
            return "Page Category"
        case "page_name":
            return "Page Name"
        case "meta_business_id":
            return "Meta Business ID";

        case "":
        default:
            return null
    }
}
export default function FacebookInformationCard(props: FacebookInformationCardProps) {

    const { title, details, currentlySelected, flagDelete, flagShowMetaIcon, showRemoveChatbotIntegration } = props;
    const { chatbotList } = useContext(ChatbotContext) as ChatbotContextPayload
    const [showQRCodePopUp, setShowQRCodePopUp] = useState(false)
    const [phoneNumberQR, setPhoneNumberQR] = useState<any>()
    return (
        <>
            {showQRCodePopUp &&
                <GlobalPopUp
                    open={showQRCodePopUp}
                    onClose={() => { setShowQRCodePopUp(false) }}
                    // title={"Scan QR Code to integrate"}
                    flagBackdrop
                    flagShowCloseIcon={true}
                    handleClose={() => { setShowQRCodePopUp(false) }}
                    maxWidth="lg"
                >
                    <Grid container className={styles.qrpopupCardDesign} direction={"column"} alignItems={"center"}>
                        <Grid item marginBottom={"25px"}>
                            <span className={styles.qrcodeTitleDesign}>Scan QR Code to integrate</span>
                        </Grid>
                        <Grid item sx={{ cursor: "pointer", zIndex: "1" }}>
                            <a href={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}
                                rel="noreferrer"
                                target='_blank'
                            >
                                <QRCode value={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} />
                            </a>
                        </Grid>
                    </Grid>
                </GlobalPopUp>
            }
            <Grid item className={styles.mainGrid} style={{
                //  width: props.flagIntegration ? "" : "100%",
                opacity: props.disabled ? "0.5" : "1"
            }}>
                <Grid container direction={"column"}>
                    {flagShowMetaIcon &&
                        <Grid item sx={{ background: currentlySelected ? "#FBF4FF" : "#FFFFFF" }} className={styles.header} xs={12}>
                            <Grid container justifyContent={"space-between"} direction={"row"}>

                                <Grid item className={globalStyles.displayFlex}>
                                    <Grid container>
                                        <Grid className={globalStyles.displayFlex} item>
                                            <img
                                                alt="meta"
                                                className={styles.metaIconStyle}
                                                src={metaIcon}
                                            />
                                        </Grid>
                                        <Grid item className={styles.headerText}>{title}</Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {flagDelete ?
                                        <>
                                            <GlobalButton buttonText={"Delete"} className="deleteIntegrationButton" onClick={props.deleteChatbotIntegration} startIcon={<IconsColor iconName="DeleteIcon" fillColor="#6A097D" height="16" width="16" />} />
                                            {/* {!flagShowMetaIcon &&  */}
                                            {/* } */}
                                        </>
                                        :
                                        <>
                                            <Grid container gap="10px">
                                                <Checkbox
                                                    checked={currentlySelected}
                                                    className={`${globalStyles.checkboxStyle} ${globalStyles.clickable}`}
                                                    checkedIcon={<IconsColor iconName="checkedIcon" width="22px" height="22px" />}
                                                    icon={<IconsColor iconName="uncheckedIcon" width="22px" height="22px" />}
                                                    onChange={() => {
                                                        if (props.addChatbotIntegration && details.page_id && !currentlySelected) {
                                                            props.addChatbotIntegration(details.page_id)
                                                        }
                                                    }}
                                                />
                                                {showRemoveChatbotIntegration && currentlySelected && props.removeChatbotIntegration &&
                                                    <GlobalButton buttonText={"Remove"} className='boxShadowButtonStyle' onClick={() => {
                                                        if (props.removeChatbotIntegration && details.page_id) {
                                                            props.removeChatbotIntegration(details.page_id)
                                                        }
                                                    }} />
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>}
                    {flagShowMetaIcon && <Divider style={{ opacity: "0.8" }} />}

                    {!flagShowMetaIcon &&
                        <Grid item>
                            <Grid container direction={"row"}
                                gap={3}
                            >
                                <Grid item>
                                    <Grid container className={styles.facebookIntegrationCard} direction={"column"}>
                                        <Grid item className={props.integrationType === "Instagram" ? styles.instagramPageID : props.integrationType == "Slack" ? styles.slackPage : styles.integrationPageID}>
                                            <span className={props.integrationType === "Instagram" ? styles.instagramIDStyle : props.integrationType == "Slack" ? styles.slackIDStyle : styles.pageIDStyle}>ID: {props.details.page_id}</span>
                                        </Grid>

                                        <Grid item>
                                            <Grid container justifyContent={"end"}>
                                                <Grid item className={props.integrationType === "Instagram" ? styles.instagramIcon : props.integrationType == "Slack" ? styles.slackIcon : styles.integrationIcon}>
                                                    <img alt="" src={getIntegrationIcon(props.integrationType)} height={"32"} width={"32"} />
                                                </Grid>

                                                <Grid item className={styles.connectionStyle} sx={{ color: props.details.linked_chatbots && props.details.linked_chatbots.length > 0 ? "#32BB46" : "#BB3232" }}>
                                                    <span className={styles.connectionTextStyle}>{props.details.linked_chatbots && props.details.linked_chatbots.length > 0 ? "Connected" : "Not Connected"}</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item className={styles.integrationPageName}>
                                            <span className={styles.pageNameStyle}>{props.details.page_name}</span>
                                        </Grid>

                                        <Grid item className={styles.integrationPageCategory} sx={{ maxWidth: "100% !important" }}>
                                            <Grid container direction={"column"}>
                                                <Grid item className={styles.pageCategoryStyle}>
                                                    <span>{props.integrationType == "Slack" ? "Slack Description" : "Page Category"}</span>
                                                </Grid>
                                                {props.integrationType == "Slack" ?
                                                    <Tooltip title={props.details.page_category} arrow placement="right" style={{ padding: "0px", paddingRight: "5px", paddingBlock: "0px" }}>
                                                        <Grid item className={`${styles.pageCategoryTextStyle} ${styles.slackDescriptionTextStyle}`}>
                                                            <span>{props.details.page_category ? props.details.page_category : "-"}</span>
                                                        </Grid>
                                                    </Tooltip>
                                                    :
                                                    <Grid item className={`${styles.pageCategoryTextStyle} ${styles.slackDescriptionTextStyle}`}>
                                                        <span>{props.details.page_category ? props.details.page_category : "-"}</span>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>

                                        {!props.flagShowLinkedChatbot && <Grid item className={styles.integrationPageCategory}>
                                            <Grid container direction={"row"} gap={1} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container direction={"column"}>
                                                        <Grid item className={styles.pageCategoryStyle}>
                                                            <span>Linked Chatbot</span>
                                                        </Grid>
                                                        <Grid item className={styles.chatbotNameStyle}>
                                                            {!props.flagIntegration &&
                                                                <Grid item className={styles.chatbotNameStyle}>
                                                                    {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                        return <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbot.chatbot_name}</a>
                                                                    }) : <span>-</span>}
                                                                </Grid>
                                                            }
                                                            {props.flagIntegration &&
                                                                <Grid item className={styles.chatbotNameStyle}>
                                                                    {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                        return <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</a>
                                                                    }) : <span>-</span>}
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {!props.flagShowLinkedChatbot && showRemoveChatbotIntegration && props.flagIntegration &&
                                                    currentlySelected && props.removeChatbotIntegration &&
                                                    <Grid item>
                                                        <GlobalButton buttonText={"Un-deploy"} className='delinkChatbotButtonStyle' onClick={() => {
                                                            if (props.removeChatbotIntegration && details.page_id) {
                                                                props.removeChatbotIntegration(details.page_id)
                                                            }
                                                        }} />
                                                    </Grid>
                                                }
                                                {!props.flagShowLinkedChatbot && props.flagIntegration && !showRemoveChatbotIntegration && props.removeChatbotIntegration && props.addChatbotIntegration &&
                                                    <Grid item>
                                                        <GlobalButton buttonText={"Deploy"} className='linkChatbotButtonStyle' onClick={() => {
                                                            if (props.addChatbotIntegration && details.page_id && !currentlySelected) {
                                                                props.addChatbotIntegration(details.page_id)
                                                            }
                                                        }} disabled={currentlySelected} />
                                                    </Grid>
                                                }
                                                {props.flagChatbotDeploy &&
                                                    <>
                                                        {(details.linked_chatbots && details.linked_chatbots.length > 0) ?
                                                            <Grid item>
                                                                <GlobalButton buttonText={"De-Link"} className='delinkChatbotButtonStyle' onClick={() => {
                                                                    if (props.removeChatbotIntegration && details.page_id) {
                                                                        props.removeChatbotIntegration(details.page_id)
                                                                    }
                                                                }} />
                                                            </Grid>
                                                            :
                                                            <Grid item>
                                                                <GlobalButton buttonText={"Link"} className='linkChatbotButtonStyle' onClick={() => {
                                                                    if (props.addChatbotIntegration && details.page_id) {
                                                                        props.addChatbotIntegration(details.page_id)
                                                                    }
                                                                }} />
                                                            </Grid>}
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>}

                                        {props.flagShowLinkedChatbot &&
                                            <Grid item className={styles.integrationPageCategory}>
                                                <Grid container direction={"row"} gap={1} justifyContent={props.flagShowLinkedChatbot ? "space-between" : "end"}>
                                                    <Grid item>
                                                        <Grid container direction={"column"}>
                                                            <Grid item className={styles.pageCategoryStyle}>
                                                                <span>Linked Chatbot</span>
                                                            </Grid>
                                                            {!props.flagIntegration &&
                                                                <Grid item className={styles.chatbotNameStyle}>
                                                                    {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                        return <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbot.chatbot_name}</a>
                                                                    }) : <span style={{ fontWeight: "500" }}>None</span>}
                                                                </Grid>
                                                            }
                                                            {props.flagIntegration &&
                                                                <Grid item className={styles.chatbotNameStyle}>
                                                                    {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                        return props.disabled ? <span className={styles.chatbotNameLink}>{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</span> : <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</a>
                                                                    }) : <span style={{ fontWeight: "500" }}>None</span>}
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {showRemoveChatbotIntegration && props.flagIntegration &&
                                                        currentlySelected && props.removeChatbotIntegration &&
                                                        <Grid item>
                                                            <GlobalButton buttonText={"Un-deploy"} className='delinkChatbotButtonStyle' onClick={() => {
                                                                if (props.removeChatbotIntegration && details.page_id) {
                                                                    props.removeChatbotIntegration(details.page_id)
                                                                }
                                                            }} />
                                                        </Grid>
                                                    }
                                                    {!showRemoveChatbotIntegration && props.flagIntegration && props.removeChatbotIntegration && props.addChatbotIntegration &&
                                                        <Grid item>
                                                            <GlobalButton buttonText={"Deploy"} className='linkChatbotButtonStyle' onClick={(event) => {
                                                                if (props.addChatbotIntegration && details.page_id && !currentlySelected) {
                                                                    props.addChatbotIntegration(details.page_id, event)
                                                                }
                                                            }} disabled={currentlySelected} />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                        {/* {props.flagIntegration && 
                                            <Grid item className={styles.integrationPageCategory}>
                                                <Grid container gap="10px" >
                                                    <Grid item sx={{color: details.linked_chatbots && details.linked_chatbots.length > 0 || currentlySelected ? "#32BB46" : "#BB3232"}}>
                                                        <Checkbox
                                                            checked={currentlySelected}
                                                            className={`${globalStyles.checkboxStyle} ${globalStyles.clickable} ${styles.selectedChatbotstyle}`}
                                                            checkedIcon={<IconsColor iconName="checkedIcon" width="22px" height="22px" fillColor="#32BB46"/>}
                                                            icon={<IconsColor iconName="uncheckedIcon" width="22px" height="22px" />}
                                                            onChange={() => {
                                                                if (props.addChatbotIntegration && details.page_id && !currentlySelected) {
                                                                    props.addChatbotIntegration()
                                                                }
                                                            }}
                                                        />
                                                        <span className={styles.wpintegrationTextStyle}>{props.flagIntegration ? currentlySelected ? "Connected" : "Not Connected" : details.linked_chatbots && details.linked_chatbots.length > 0 ? "Connected" : "Not Connected"}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        } */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
