import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Autocomplete, Box, Collapse, Divider, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "../../../assets/styles/componentStyles/sideNavigationPanel.module.scss";
import { Grid } from "@mui/material";
import writewizLogo from "../../../assets/images/global/Chronox Logo.svg";
import { useNavigate } from "react-router";
import { SidepanelAccountDetails } from "../../utility/sidePanelAccountDetails";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import shortWritewizLogo from "../../../assets/images/global/Chronox AI Icon.svg";
import globalStyles from "../../../assets/styles/global.module.scss";
import premiumIcon from "../../../assets/personaCrown.svg";
import { makeStyles, withStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { ConsultationComponent } from "../../utility/consult";
import SettingsWorkspace from "../../utility/settingsWorkspace";
import IconsColor from "../../utility/iconsColor";
import { getOrganizationDetailDataModel } from "../../../models/axiosModel";
import { DelegateAccess } from "../../global/delegateAccess";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BackToMainPageGlobalComponent from "../../utility/backToMainPageGlobalComponent";
import { flagShowHomeButton } from "../../../utils/constants";
const drawerWidth = 233;
const mobileDrawerWidth = "70px !important";
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    zIndex: 1,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflow: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflow: "hidden",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: mobileDrawerWidth,
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
    })
}));

export default function MiniDrawer(props: any) {
    const navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    // const [currentActiveMenu, setCurrentActiveMenu] = React.useState("home");
    const [hoveredMenu, setHoveredMenu] = React.useState("");
    const { userDetail, flagChromeDesktop, setFlagChromeDesktop, organizationDetails, currentActiveMenu, setCurrentActiveMenu, customPagesData, selectedSubMenu, setSelectedSubMenu, currentMenuName, setCurrentMenuName } = React.useContext(UserContext) as UserContextPayload;
    const [tooltipClickedMenu, setTooltipClickedMenu] = React.useState("")
    const [currentSubMenuName, setCurrentSubMenuName] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = searchParams.get("chatbot_id");
    const id = searchParams.get("id");
    const organization_id = searchParams.get('organization_id');
    const pathName = window.location.pathname.toLowerCase()

    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let urlToNavigateTo = encodeURIComponent(window.location.href)
    const handleClickWorkspace = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    React.useEffect(() => {
        if (window.location.pathname == "/" && userDetail?.user_organization_data?.organization_id != 2545) {
            setCurrentMenuName("chatbot")
            setCurrentSubMenuName("");
            setCurrentActiveMenu("/chatbot")
        }
        else if (window.location.pathname == "/" && userDetail?.user_organization_data?.organization_id == 2545) {
            setCurrentMenuName("channels")
            setCurrentSubMenuName("");
            setCurrentActiveMenu("/channels")
        }
        else if (window.location.pathname.toLowerCase() == "/createchatbot" || window.location.pathname.toLowerCase() === "/chatbot") {
            setCurrentMenuName("chatbot")
            setCurrentSubMenuName("");
            setCurrentActiveMenu("/chatbot")
        }
        else if (window.location.pathname == "/insights") {
            setCurrentMenuName("home")
            setCurrentSubMenuName("");
            setCurrentActiveMenu("/insights")
        }
        else if (window.location.pathname.toLowerCase() === "/inbox") {
            setCurrentMenuName("inbox");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/inbox`)
        }
        else if (window.location.pathname.toLowerCase() === "/feedback") {
            setCurrentMenuName("feedback");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/feedback`)
        }
        else if (window.location.pathname.toLowerCase() == "/contacts") {
            setCurrentMenuName("contacts");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/contacts`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels") {
            setCurrentMenuName("channels");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/channels`)
        }
        else if (window.location.pathname.toLowerCase() === "/whatsappcampaign" || window.location.pathname.toLowerCase() === "/whatsappcampaign/createnewcampaign") {
            setCurrentMenuName("whatsappCampaign");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/whatsappcampaign`)
        }
        else if (window.location.pathname.toLowerCase() == "/campaigns/create") {
            setCurrentActiveMenu("/campaigns")
        }
        else if (window.location.pathname.toLowerCase() == "/customtemplate") {
            setCurrentActiveMenu("/templates")
        }
        else if (window.location.pathname.toLowerCase() === "/settings-plansandbilling") {
            setCurrentActiveMenu("/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() === "/settings-accountdetails") {
            setCurrentActiveMenu("/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/basicdetail" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot") {
            setCurrentMenuName("basic_details");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration/slack" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration/customapi" || window.location.pathname == "/chatbot/viewchatbot/integration/calendly/selectCalendlyEvent" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration/whatsapp" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/integration" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/integration/calendly" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/integration/calendly" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration/createcustomapi") {
            setCurrentMenuName("data_connector");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/integration?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/persistentmenu") {
            setCurrentMenuName("persistent_menu");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/persistentmenu?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/whatsapp" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/viewwhatsappintegration") {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("whatsapp");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/facebook" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/viewfacebookintegration") {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("facebook");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/instagram" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/viewinstagramintegration") {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("instagram");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/instagram?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/channels/website") {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("website");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/website?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/channels/calendly")) {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("calendly");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/calendly?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/channels/slack")) {
            setOpen(true);
            setCurrentMenuName("channels");
            setCurrentSubMenuName("slack");
            setCurrentActiveMenu(`/chatbot/viewchatbot/channels/slack?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/messageformat" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/messageformat/staticformat") {
            setCurrentMenuName("message_format");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/messageformat?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/trychatbot") {
            setCurrentMenuName("try_chatbot");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/trychatbot?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/leadgeneration" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/leadgeneration/createleadgeneration" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/leadgeneration/email" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/leadgeneration/spreadsheet" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/leadgeneration/slack") {
            setCurrentMenuName("leadgeneration");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/products") {
            setCurrentMenuName("products");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/products?chatbot_id=${chatbot_id}`);
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/inbox") {
            setCurrentMenuName("inbox");
            setCurrentSubMenuName("");
            setCurrentActiveMenu(`/chatbot/viewchatbot/inbox?chatbot_id=${chatbot_id}`)
        }

        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp") {
            setOpen(true);
            setCurrentMenuName("whatsappintegration");
            // setCurrentSubMenuName("");
            id ? setCurrentActiveMenu(`/channels/viewintegration/whatsapp?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/whatsapp`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/phonenumbers") {
            setOpen(true);
            setCurrentMenuName("whatsappintegration");
            // setCurrentSubMenuName("");
            id ? setCurrentActiveMenu(`/channels/viewintegration/whatsapp/phonenumbers?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/whatsapp/phonenumbers`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/templateinfo" || window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/templatemessages") {
            setCurrentMenuName("whatsappintegration");
            // setCurrentSubMenuName("");
            id ? setCurrentActiveMenu(`/channels/viewintegration/whatsapp/templatemessages?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/whatsapp/templatemessages`)
        }

        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook") {
            setCurrentMenuName("accountfacebook");
            id ? setCurrentActiveMenu(`/channels/viewintegration/facebook?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/facebook`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/pages") {
            setCurrentMenuName("pages");
            id ? setCurrentActiveMenu(`/channels/viewintegration/facebook/pages?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/facebook/pages`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/templateinfo" || window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/templatemessages") {
            setCurrentMenuName("templatemessagefacebook");
            id ? setCurrentActiveMenu(`/channels/viewintegration/facebook/templatemessages?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/facebook/templatemessages`)
        }

        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/instagram") {
            setCurrentMenuName("accountInstagram");
            id ? setCurrentActiveMenu(`/channels/viewintegration/instagram?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/instagram`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack") {
            setCurrentMenuName("accountslack");
            id ? setCurrentActiveMenu(`/channels/viewintegration/slack?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/slack`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack/channels") {
            setCurrentMenuName("slackchannels");
            id ? setCurrentActiveMenu(`/channels/viewintegration/slack/channels?id=${id}`) : setCurrentActiveMenu(`/channels/viewintegration/slack/channels`)
        }
        else if (window.location.pathname.toLowerCase() === "/organization") {
            setCurrentMenuName("workspace");
            // setCurrentSubMenuName("");
            setCurrentActiveMenu(organization_id ? `/organization?organization_id=${organization_id}` : `/organization`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis") {
            setOpen(true);
            setCurrentMenuName("callanalysis");
            setCurrentSubMenuName("dashboard");
            setCurrentActiveMenu(`/callanalysis`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords") {
            setOpen(true);
            setCurrentMenuName("callanalysis");
            setCurrentSubMenuName("callanalysislist");
            setCurrentActiveMenu(`/callanalysis/callrecords`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords/newcallrecords") {
            setOpen(true);
            setCurrentMenuName("callanalysis");
            setCurrentSubMenuName("newcallrecords");
            setCurrentActiveMenu(`/callanalysis/callrecords/newcallrecords`)
        }
        // else if (window.location.pathname.toLowerCase() === "/callanalysis") {
        //     setCurrentMenuName("dashboard");
        //     // setCurrentSubMenuName("");
        //     setCurrentActiveMenu(`/callanalysis`)
        // }
        // else if (window.location.pathname.toLowerCase() === "/callanalysis/list") {
        //     setCurrentMenuName("callanalysis");
        //     // setCurrentSubMenuName("");
        //     setCurrentActiveMenu(`/callanalysis/list`)
        // }
        else {
            setCurrentMenuName("")
            setCurrentActiveMenu(window.location.pathname.toLowerCase())
        }
    }, [window.location.pathname])

    const handleClick = (keyName: string) => {
        setOpen(true)
        if (!selectedSubMenu.includes(keyName)) {
            setSelectedSubMenu([...selectedSubMenu, keyName]);
        }
        else if (selectedSubMenu.includes(keyName)) {
            setSelectedSubMenu([...selectedSubMenu.filter((data) => data !== keyName)])
        }
        if (window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/website` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/slack` || window.location.pathname.toLowerCase().includes('/chatbot/viewchatbot/channels/calendly') || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/whatsapp` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/facebook` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/viewwhatsappintegration` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/viewfacebookintegration`) {
            setOpen(true);
        }
        else if (window.location.pathname.toLowerCase() === `/callanalysis` || window.location.pathname.toLowerCase() === `/callanalysis/callrecords` || window.location.pathname.toLowerCase() === `/callanalysis/callrecords/newcallrecords`) {
            setOpen(true);
        }
        else if (customPagesData && customPagesData.length > 0 && customPagesData.filter((data) => data.custom_page_slug == currentActiveMenu).length > 0) {
            setOpen(true);
        }
        // else if(window.location.pathname.toLowerCase() === `/channels/viewintegration` || window.location.pathname.toLowerCase() === `/channels/viewintegration/templateinfo` || window.location.pathname.toLowerCase() === `/channels/viewintegration/templatemessages` ) {
        //     setOpen(true)
        // }
        else {
            // setOpen(!open);
        }
    };

    // React.useEffect(() => {
    //     setOpen(true)
    // }, [selectedSubMenu])

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const CustomTooltip = withStyles((theme: any) => ({
        tooltip: {
            backgroundColor: '#EFD7FE',
            color: '#6A097D',
            fontSize: '13px',
            padding: '8px 15px',
            borderRadius: 5,
        },
        arrow: {
            color: '#EFD7FE',
        },
    }))(Tooltip);
    const useStyles = makeStyles(() => ({
        customTooltip: {
            backgroundColor: '#EFD7FE',
            color: '#6A097D',
            fontSize: '13px',
            padding: '8px 15px',
            borderRadius: 5,
        },
        customSubTooltip: {
            backgroundColor: '#FFF',
            color: '#4C4A4D',
            boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
            fontSize: '13px',
            padding: '0px 11px',
            borderRadius: 5,
        },
        customArrow: {
            color: '#EFD7FE',
        },
        customSubArrow: {
            color: 'rgba(0,0,0,0.25)',
        },
    }));
    const classes = useStyles();

    const drawer = (
        <>
            <Grid container direction="column" justifyContent={"space-between"} className={userDetail.flagUser && !userDetail.user_delegate_access_flag ? `${styles.navigationPanelContentWrapper}` : `${styles.navigationPanelContentWrapperDelegateAccess}`} mt={1}>
                <Grid item>
                    {/* <Toolbar>
                        <img src={writewizLogo} onClick={() => { navigate('/dashboard') }} className={styles.writewizLogoStyle} />
                    </Toolbar> */}
                    {
                        (pathName == "/" || pathName == "/insights" || pathName == "/chatbot" || pathName == "/inbox" || pathName == "/channels" || pathName == "/feedback" || pathName == "/contacts" || pathName == "/whatsappcampaign" || pathName == "/whatsappcampaign/createnewcampaign") &&
                        <>
                            <Grid item sx={{ margin: "0px 15px", backgroundColor: "#DEE8F3", borderRadius: "5px", padding: "8px 15px" }}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item>
                                        <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                            <Grid item><IconsColor height={"15"} width={"15"} fillColor={"#4C4A4D"} iconName="workspace" /></Grid>
                                            <Grid item sx={{ fontSize: "12px", fontWeight: "500", color: "#4C4A4D" }}><span>Workspace</span></Grid>
                                        </Grid>
                                    </Grid>
                                    {organizationDetails && organizationDetails.length > 1 ?
                                        <Autocomplete
                                            disableClearable
                                            value={organizationDetails ? organizationDetails.find((organization) => organization.organization_status_name === "Active") : undefined}
                                            className={`${globalStyles.chatbotTitleSelect}`}
                                            options={organizationDetails ? organizationDetails : []}
                                            getOptionLabel={(option: getOrganizationDetailDataModel) => option.organization_name}
                                            // onChange={(e, value) => handleOptionChange(e, value)}
                                            renderInput={(params) => <TextField
                                                {...params} placeholder='Select' />}
                                        />
                                        :
                                        <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>{organizationDetails ? organizationDetails[0].organization_name : ""}</Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "10px 15px 0px" }} />
                        </>
                    }
                    <List sx={{ padding: '10px 10px 10px 15px' }} >
                        {props.menuItems.map((menuItem: any, index: any) => {

                            return (
                                <>
                                    {!menuItem.subMenu ?
                                        <>
                                            {menuItem.flagHeaderKey ?
                                                <Tooltip title={menuItem.menuName} arrow placement="right" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} style={{ padding: "0px", paddingRight: "15px", paddingBlock: "5px" }}>
                                                    <ListItem
                                                        sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1" }}
                                                        className={styles.headerSideItem} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                                        onClick={() => { setCurrentSubMenuName("") }}
                                                        key={menuItem.menuKey} disablePadding>

                                                        <ListItemIcon className={styles.imageNavigationMenu}>
                                                            {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName == menuItem.menuKey ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                                        </ListItemIcon>
                                                        <ListItemText primary={menuItem.menuName} className={currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName == menuItem.menuKey || menuItem.flagDisabled || menuItem.flagHeaderKey ? "listItemSelectedMenuText" : styles.listItemMenuText} />

                                                    </ListItem>
                                                </Tooltip>
                                                :
                                                <ListItem
                                                    sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1" }}
                                                    className={menuItem.flagHeaderKey ? styles.headerSideItem : currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName == menuItem.menuKey ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                                    onClick={() => {
                                                        if (menuItem.flagDisabled) {
                                                        } else if (menuItem.flagHeaderKey) {
                                                            // setOpen(false);
                                                            setCurrentSubMenuName("");
                                                        } else {
                                                            // setOpen(false);
                                                            setSelectedSubMenu([])
                                                            setCurrentSubMenuName("");
                                                            setCurrentActiveMenu(menuItem.navigationLink);
                                                            setCurrentMenuName(menuItem.menuKey)
                                                            navigate(menuItem.navigationLink)
                                                        }
                                                    }}
                                                    key={menuItem.menuKey} disablePadding>
                                                    <ListItemIcon className={styles.imageNavigationMenu}>
                                                        {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName == menuItem.menuKey ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                                    </ListItemIcon>
                                                    <ListItemText primary={menuItem.menuName} className={currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName == menuItem.menuKey || menuItem.flagDisabled || menuItem.flagHeaderKey ? "listItemSelectedMenuText" : styles.listItemMenuText} />
                                                    {/* {menuItem.flagDisabled &&
                                                    <ListItemIcon><img src={premiumIcon} /></ListItemIcon>} */}
                                                    {menuItem.flagAdmin &&
                                                        <AdminPanelSettingsIcon />
                                                    }
                                                </ListItem>
                                            }
                                        </>
                                        :
                                        <>
                                            <ListItem button onClick={() => handleClick(menuItem.menuKey)} className={currentMenuName == menuItem.menuKey && !selectedSubMenu.includes(menuItem.menuKey) ? styles.selectedListItemStyle : styles.listItemStyle}>
                                                <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                                    {menuItem.imgSrc}
                                                </ListItemIcon>
                                                <ListItemText primary={menuItem.menuName} />
                                                {open && selectedSubMenu.includes(menuItem.menuKey) ? <ExpandLess sx={{ marginRight: "10px", color: "#4C4A4D" }} /> : <ExpandMore sx={{ marginRight: "10px", color: "#4C4A4D" }} />}
                                            </ListItem>
                                            <Collapse in={open && selectedSubMenu.includes(menuItem.menuKey)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {/* Nested items */}
                                                    {menuItem.subMenu.map((subMenu: any) => {
                                                        return <ListItem
                                                            sx={{ opacity: subMenu.flagDisabled ? "0.5" : "1", paddingLeft: "10px !important" }}
                                                            className={currentActiveMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() || currentSubMenuName === subMenu.menuKey ? styles.selectedListItemStyle : styles.listItemStyle}
                                                            onMouseEnter={() => { setHoveredMenu(subMenu.menuKey) }}
                                                            onMouseLeave={() => { setHoveredMenu("") }}
                                                            onClick={() => {
                                                                if (!subMenu.flagDisabled) {
                                                                    setCurrentActiveMenu(subMenu.navigationLink);
                                                                    setCurrentSubMenuName(subMenu.menuKey);
                                                                    setCurrentMenuName(menuItem.menuKey)
                                                                    if (!subMenu.flagUserSpecific) {
                                                                        navigate(subMenu.navigationLink);
                                                                    }
                                                                    // onClose();
                                                                }
                                                            }}
                                                            key={subMenu.menuKey}
                                                            disablePadding
                                                        >
                                                            <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                                                {currentActiveMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() || currentSubMenuName === subMenu.menuKey ? subMenu.whiteImgSrc : hoveredMenu.toLowerCase() == subMenu.menuKey.toLowerCase() ? subMenu.coloredImgSrc : subMenu.imgSrc}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subMenu.menuName} className={currentActiveMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() || currentSubMenuName === subMenu.menuKey || menuItem.flagDisabled || menuItem.flagHeaderKey ? "listItemSelectedMenuText" : styles.listItemMenuText} />
                                                            {/* {subMenu.flagDisabled && <ListItemIcon><img alt="premium" src={premiumIcon} /></ListItemIcon>} */}
                                                            {menuItem.flagAdmin &&
                                                                <AdminPanelSettingsIcon />
                                                            }
                                                        </ListItem>
                                                    })
                                                    }
                                                </List>
                                            </Collapse>
                                        </>
                                    }
                                </>
                            )
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"}>
                        {
                            <Grid item className={`${styles.profileWrapper}`}>
                                <DelegateAccess />
                            </Grid>
                        }
                        <Grid item className={styles.settingsWorkspace}>
                            <SettingsWorkspace />
                        </Grid>
                        {/* {(window.location.pathname.toLowerCase() !== "/callanalysis" && window.location.pathname.toLowerCase() !== "/callanalysis/list") &&
                            <Grid item className={styles.settingsWorkspace}>
                                <SettingsWorkspace />
                            </Grid>
                        } */}
                        {flagShowHomeButton() &&
                            <Grid item
                                className={styles.backToHomeButton}
                            >
                                <BackToMainPageGlobalComponent />
                            </Grid>
                        }
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={`${styles.profileWrapper}`}>
                                <ConsultationComponent flagInsideSideNavigationPanel />
                            </Grid>
                        }
                        <Grid item className={styles.profileWrapper}>
                            <SidepanelAccountDetails />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>

    );


    const mobileDrawer = (
        <>
            {
                // (pathName == "/" || pathName == "/chatbot" || pathName == "/inbox" || pathName == "/channels") &&
                (pathName == "/" || pathName == "/insights" || pathName == "/chatbot" || pathName == "/inbox" || pathName == "/channels" || pathName == "/feedback" || pathName == "/contacts" || pathName == "/whatsappcampaign" || pathName == "/whatsappcampaign/createnewcampaign") &&
                <>
                    <Menu
                        anchorEl={anchorEl}
                        // className={MenuPaper}
                        id="workspace-menu"
                        open={openMenu}
                        onClose={handleClose}
                        onClick={handleClose}
                        className={styles.settingsStyleSidePanel}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                        {
                            organizationDetails && organizationDetails.map((organization) => <MenuItem className={organization.organization_status_name === "Active" ? styles.activeWorkspaceMenu : styles.inActiveWorkspaceMenu}>
                                {organization.organization_name}
                            </MenuItem>)
                        }
                        {/* <MenuItem onClick={() => { debugger; navigate("/login?redirect=" + urlToNavigateTo) }} className={styles.menuLogout}>
                            <ListItemIcon>
                                <AppRegistrationIcon fontSize="small" />
                            </ListItemIcon>
                            Sign In/ Register
                        </MenuItem> */}
                    </Menu>
                    <Grid item sx={{ margin: "0px 10px", backgroundColor: "#DEE8F3", borderRadius: "5px", padding: "15px 15px" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item>
                                <Grid container direction={"row"} alignItems={"center"}>
                                    <Grid item
                                        sx={{ display: "flex" }}
                                        onClick={handleClickWorkspace}
                                        aria-controls={openMenu ? 'workspace-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                    // sx={{ cursor: "pointer", fontSize: "medium !important", color: "#7A7A7A !important" }}
                                    >
                                        <IconsColor height={"20"} width={"20"} fillColor={"#4C4A4D"} iconName="workspace" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* {organizationDetails && organizationDetails.length == 1 ?
                                <Autocomplete
                                    disableClearable
                                    value={organizationDetails ? organizationDetails.find((organization) => organization.organization_status_name === "Active") : undefined}
                                    className={`${globalStyles.chatbotTitleSelect}`}
                                    options={organizationDetails ? organizationDetails : []}
                                    getOptionLabel={(option: getOrganizationDetailDataModel) => option.organization_name}
                                    // onChange={(e, value) => handleOptionChange(e, value)}
                                    renderInput={(params) => <TextField
                                        {...params} placeholder='Select' />}
                                />
                                :
                                <Grid item sx={{fontSize: "14px", fontWeight: "600", color: "#000000"}}>{organizationDetails[0].organization_name}</Grid>
                            } */}
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "10px 5px 5px" }} />
                </>
            }
            <Grid container direction="column" justifyContent={"space-between"} className={styles.navigationPanelContentWrapper}>
                <Grid item>
                    {/* <Toolbar>
                        <img src={shortWritewizLogo} onClick={() => { navigate('/dashboard') }} />
                    </Toolbar> */}
                    {/* <List sx={{ padding: '15px' }} >
                        {props.menuItems.map((menuItem: any, index: any) => {
                            return <CustomTooltip title={menuItem.menuName} arrow placement="right" disableFocusListener={true}>
                                <ListItem sx={{ display: "flex", justifyContent: "center", alignItems: "center", opacity: menuItem.flagDisabled ? "0.5" : "1", transform: "scale(1.2)" }} className={currentActiveMenu == menuItem.navigationLink ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                    onClick={() => {
                                        if (menuItem.flagDisabled) {
                                        } else {
                                            setCurrentActiveMenu(menuItem.navigationLink);
                                            navigate(menuItem.navigationLink)
                                        }
                                    }}
                                    key={menuItem.menuKey} disablePadding>
                                    <ListItemIcon className={styles.imageNavigationMenu}>
                                        {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                    </ListItemIcon>
                                </ListItem>
                            </CustomTooltip>
                        })}
                    </List> */}

                    <List sx={{ padding: '15px 10px 15px 15px' }} >
                        {props.menuItems.map((menuItem: any, index: any) => {
                            return <Tooltip
                                title={!menuItem.subMenu ? menuItem.menuName
                                    :
                                    <>
                                        {
                                            tooltipClickedMenu === menuItem.menuName ?
                                                <>
                                                    <List sx={{ padding: '10px', paddingBottom: "1px", fontSize: "10px" }} >
                                                        {menuItem.subMenu.map((menuSubItem: any, index: any) => {
                                                            return <Tooltip title={""} arrow placement="right" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} style={{ padding: "0px", paddingRight: "15px", paddingBlock: "5px" }}>
                                                                <ListItem sx={{ display: "flex", justifyContent: "center", alignItems: "center", opacity: menuSubItem.flagDisabled ? "0.5" : "1", transform: "scale(1.2)", cursor: "pointer", width: "100%" }} className={currentActiveMenu == menuSubItem.navigationLink || currentSubMenuName == menuSubItem.menuKey ? styles.SelectedListSubItemStyle : styles.listSubItemStyle} onMouseEnter={() => { setHoveredMenu(menuSubItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                                                    onClick={() => {
                                                                        if (menuSubItem.flagDisabled) {
                                                                        } else {
                                                                            setCurrentActiveMenu(menuSubItem.navigationLink);
                                                                            setCurrentMenuName(menuItem.menuKey);
                                                                            setCurrentSubMenuName(menuSubItem.menuKey)
                                                                            if (!menuSubItem.flagUserSpecific) {
                                                                                navigate(menuSubItem.navigationLink);
                                                                            }
                                                                            setTooltipClickedMenu("")
                                                                        }
                                                                    }}
                                                                    key={menuSubItem.menuKey}>
                                                                    <ListItemIcon className={styles.imageSubNavigationMenu} sx={{ minWidth: '10px' }}>
                                                                        {currentActiveMenu.toLowerCase() == menuSubItem.navigationLink.toLowerCase() || currentSubMenuName == menuSubItem.menuKey ? menuSubItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuSubItem.menuKey.toLowerCase() ? menuSubItem.coloredImgSrc : menuSubItem.imgSrc}
                                                                    </ListItemIcon>
                                                                    <ListItemText className={styles.subMenuHoverItems} primary={menuSubItem.menuName} sx={{ paddingLeft: '0px' }} />
                                                                </ListItem>
                                                            </Tooltip>
                                                        })}
                                                    </List>
                                                </>
                                                :
                                                <>
                                                    {menuItem.menuName}
                                                </>
                                        }

                                    </>
                                }
                                arrow
                                placement="right" classes={{ tooltip: tooltipClickedMenu === menuItem.menuName ? classes.customSubTooltip : classes.customTooltip, arrow: tooltipClickedMenu === menuItem.menuName ? classes.customSubArrow : classes.customArrow }}>
                                <ListItem sx={{ display: "flex", justifyContent: "center", alignItems: "center", opacity: menuItem.flagDisabled ? "0.5" : "1", transform: "scale(1.2)" }} className={menuItem.flagHeaderKey ? styles.headerSideMobileItem : currentActiveMenu == menuItem.navigationLink || currentMenuName == menuItem.menuKey ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                    onClick={() => {
                                        if (menuItem.flagDisabled) {
                                        } else if (menuItem.subMenu) {
                                            // setCurrentActiveMenu(menuItem.navigationLink);
                                            // setCurrentMenuName(menuItem.menuKey);
                                            setTooltipClickedMenu(menuItem.menuName);
                                        } else if (menuItem.flagHeaderKey) {
                                            setTooltipClickedMenu("")
                                            setCurrentSubMenuName("")
                                        } else {
                                            setCurrentActiveMenu(menuItem.navigationLink);
                                            setCurrentMenuName(menuItem.menuKey);
                                            navigate(menuItem.navigationLink);
                                            setTooltipClickedMenu("")
                                            setCurrentSubMenuName("")
                                        }
                                    }}
                                    key={menuItem.menuKey} disablePadding>
                                    <ListItemIcon className={styles.mobileImageNavigationMenu}>
                                        {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || currentMenuName === menuItem.menuKey ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"} className={globalStyles.containerflexWrapStyle}>
                        {/* {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={styles.creditUsedComponentWrapper}>
                                <CreditUsedComponent flagMobile />
                            </Grid>
                        }
                        <Grid item className={styles.downloadExtensionWrapper}>
                            <GlobalButton onClick={() => { window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en", '_blank'); }} flagMobile flagDownloadExtension flagFullWidth buttonText='' className='borderedButtonStyle' />
                        </Grid> */}
                        {
                            <Grid item className={`${styles.profileWrapper}`}>
                                <DelegateAccess flagMobile />
                            </Grid>
                        }
                        <Grid item>
                            <SettingsWorkspace flagMobile />
                        </Grid>
                        {/* {(window.location.pathname.toLowerCase() !== "/callanalysis" && window.location.pathname.toLowerCase() !== "/callanalysis/list")  &&
                            <Grid item>
                                <SettingsWorkspace flagMobile />
                            </Grid>
                        } */}
                        {flagShowHomeButton() &&
                            <Grid item>
                                <BackToMainPageGlobalComponent flagMobile />
                            </Grid>
                        }
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={`${styles.profileWrapper}`}>
                                <ConsultationComponent flagMobile flagInsideSideNavigationPanel />
                            </Grid>
                        }
                        <Grid item className={styles.profileWrapper}>
                            <SidepanelAccountDetails flagMobile />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>

    );
    return (
        <Box sx={{
            display: { xs: 'none', sm: 'block' },
        }}>
            {/* <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    sx={{
                        position: "fixed",
                        right: 0,
                        marginRight: 5,
                        ...(props.open && { display: "none" })
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar> */}
            <Drawer className={styles.sideNavigationPanelStyle} variant="permanent" open={props.open}>
                <DrawerHeader>
                    <Grid container justifyContent={"start"}>
                        <Grid item>
                            {props.open ? <img alt="logo" src={writewizLogo} style={{ cursor: "pointer" }} onClick={() => { navigate('/') }} className={styles.writewizLogoStyle} />
                                :
                                <img alt="logo" src={shortWritewizLogo} style={{ width: "35px", height: "auto", cursor: "pointer", marginLeft: "9px" }} onClick={() => { navigate('/') }} />
                            }
                        </Grid>

                    </Grid>

                </DrawerHeader>
                {props.open ? drawer : mobileDrawer}
            </Drawer>
        </Box>
    );
}
