import React, { useContext, useEffect, useState } from 'react'
import persistentMenuStyles from '../../../assets/styles/componentStyles/persistentStyles/persistent.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";

import { Button, Dialog, Divider, Grid, IconButton, Popover, Tooltip, Typography, useMediaQuery } from '@mui/material'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import IconsColor from '../../utility/iconsColor';
import AddIcon from '@mui/icons-material/Add';
import styles from "../../../assets/styles/componentStyles/dataconnector.module.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalTable } from '../../utility/globalTable';
import { chatbotPersistentMenu, chatbotPersistentPayload } from '../../../models/axiosModel';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import GlobalPopUp from '../../utility/globalPopUp';
import PersistentMenuModal from '../../popupFiles/persistentMenuModal';
import agent from '../../../services/api';
import globalStrenameDocumentyles from "../../../assets/renameDocument.svg";
import deleteDoc from "../../../assets/deleteDoc.svg";
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import moment from 'moment';
import { checkForErrorCode, formatDate, getErrorMessage, isDev } from '../../../utils/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const PersistentMenu = (props: any) => {

    let headerList = [
        {
            name: '',
            alignment: "left",
            flagIcon: false,
            valueKey: "",
            iconKey: "addIcon",
            flagHover: true,
        },
        {
            name: "#",
            alignment: "left",
            flagIcon: false,
            valueKey: "",
            iconKey: "sequenceNumber"
        },
        {
            name: "Menu Item Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "chatbot_persistent_menu_name",
            iconKey: "menu_item_name"
        },
        // {
        //     name: "Menu Item Value",
        //     alignment: "left",
        //     flagIcon: false,
        //     valueKey: "chatbot_persistent_menu_value",
        //     iconKey: "menu_item_value"
        // },
        {
            name: "Action Type",
            alignment: "left",
            flagIcon: false,
            valueKey: "",
            iconKey: "action_type"
        },
        {
            name: "Action",
            alignment: "left",
            flagIcon: false,
            valueKey: "",
            iconKey: "action"
        },
        {
            name: "Created At",
            alignment: "left",
            flagIcon: false,
            valueKey: "created_at_timestamp",
            iconKey: "created_at_timestamp"
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "editIcon"
        },
    ]

    const menuItems = [
        {
            label: 'Edit',
            icon: <img alt="" src={globalStrenameDocumentyles} />,
            action: (e: any) => {
                setHandleEdit(e)
            }
        },
        {
            label: 'Delete',
            icon: <img src={deleteDoc} alt="delete" />,
            action: (e: any) => deleteDocumentDataPopupOpen(e)
        },
    ];

    const menuItemOnClickOfAddIcon = [
        {
            label: "Add New menu Item",
            action: (e: any, rowDetail: any) => {
                setSelectedActionType("Text");
                setRequestPersistentMenuDetailsList({
                    ...requestPersistentMenuDetailsList,
                    chatbot_persistent_menu_id: null,
                    chatbot_persistent_menu_name: "",
                    chatbot_persistent_menu_value: "",
                    static_message_id: undefined,
                    parent_chatbot_persistent_menu_id: rowDetail.parent_chatbot_persistent_menu_id,
                });
                setFlagKeyExistError(false)
                setFlagOpenMenuItemDialog(true)
            }
        },
        {
            label: "Add Sub Menu Item",
            action: (e: any, rowDetail: any) => {
                if (e.iconKey === "addIcon") {
                    // setHandleEdit(rowDetail); 
                    // setParentPersistentMenuName(rowDetail.chatbot_persistent_menu_name);  
                    setSelectedActionType("Text");
                    setParentPersistentMenuName({
                        ...parentPersistentMenuName,
                        chatbot_persistent_menu_id: rowDetail.chatbot_persistent_menu_id,
                        chatbot_persistent_menu_name: rowDetail.chatbot_persistent_menu_name,
                        chatbot_persistent_menu_value: rowDetail.chatbot_persistent_menu_value
                    })

                    setFlagKeyExistError(false);
                    setFlagOpenMenuItemDialog(true);
                    setRequestPersistentMenuDetailsList({
                        ...requestPersistentMenuDetailsList,
                        chatbot_persistent_menu_id: null,
                        chatbot_persistent_menu_name: "",
                        chatbot_persistent_menu_value: "",
                        static_message_id: undefined,
                        parent_chatbot_persistent_menu_id: undefined,
                    });
                    setFlagOpenSubMenuItemDialog(true);
                }
            }
        }
    ]

    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const { chatBotDetails, setChatBotDetails, chatbotStaticMessageFormat, setChatbotStaticMessageFormat } = useContext(ChatbotContext) as ChatbotContextPayload
    const {
        flagLoading,
        setFlagLoading,
        success,
        setSuccess,
        error,
        setError,
        userDetail,
    } = useContext(UserContext) as UserContextPayload;

    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);
    const [rowAllowEdit, setRowAllowEdit] = useState(null);
    const [persistentMenuDetailsList, setPersistentMenuDetailsList] = useState<chatbotPersistentMenu[]>();
    const [requestPersistentMenuDetailsList, setRequestPersistentMenuDetailsList] = useState<any>({
        chatbot_persistent_menu_id: null,
        chatbot_persistent_menu_name: "",
        chatbot_persistent_menu_value: "",
        static_message_id: undefined,
        parent_chatbot_persistent_menu_id: undefined,
    });
    const [flagOpenMenuItemDialog, setFlagOpenMenuItemDialog] = useState(false);
    const [flagDeletePersistentMenuDataDialog, setFlagDeletePersistentMenuDataDialog] = useState(false);
    const [deletePersistentDialogId, setDeletePersistentDialogId] = useState<any>(null);
    const [deletePersistentName, setDeletePersistentName] = useState<any>(null);
    const [flagKeyExistError, setFlagKeyExistError] = useState(false);

    const [hoveredRow, setHoveredRow] = useState<chatbotPersistentMenu | null>(null);
    const [flagOpenSubMenuItemDialog, setFlagOpenSubMenuItemDialog] = useState<boolean>(false);
    const [parentPersistentMenuName, setParentPersistentMenuName] = useState<any>({
        chatbot_persistent_menu_id: null,
        chatbot_persistent_menu_name: "",
        chatbot_persistent_menu_value: "",
    });

    const [selectedActionType, setSelectedActionType] = useState<string>("Text");


    const formatPersistentMenuData = (menuData: any[]) => {
        return menuData.map(ele => {
            const formattedData = {
                ...ele,
                created_at_timestamp: formatDate(ele?.created_at_timestamp),
            };
            if (ele.sub_chatbot_persistent_menu) {
                formattedData.sub_chatbot_persistent_menu = formatPersistentMenuData(ele.sub_chatbot_persistent_menu);
            }

            return formattedData;
        });
    };

    useEffect(() => {
        // const persistentMenuData = chatBotDetails?.chatbot_persistent_menu && chatBotDetails?.chatbot_persistent_menu?.map(ele => {
        //     return {
        //         ...ele,
        //         created_at_timestamp: formatDate(ele?.created_at_timestamp),
        //         ...(ele.sub_chatbot_persistent_menu &&
        //         {
        //             sub_chatbot_persistent_menu: ele.sub_chatbot_persistent_menu?.map((submenu) => {
        //                 return {
        //                     ...submenu,
        //                     created_at_timestamp: formatDate(submenu?.created_at_timestamp),
        //                 }
        //             })
        //         }
        //         )
        //     };
        // }); 
        const persistentMenuData = chatBotDetails?.chatbot_persistent_menu ? formatPersistentMenuData(chatBotDetails.chatbot_persistent_menu) : [];
        setPersistentMenuDetailsList(persistentMenuData);

        (async () => {
            const response = await agent.getStaticMessageFormat.get(chatBotDetails?.chatbot_id);
            if (response.status) {
                console.log("getStaticMessageFormat is smtg like: ", response);
                setChatbotStaticMessageFormat(response.data);
            } else {
                console.log("Error Occured while Getting Static Message Format");
            }
        })();

    }, [chatBotDetails])


    const handleChange = (field: string, value: any) => {
        setRequestPersistentMenuDetailsList((prevState: any) => ({
            ...prevState,
            [field]: value
        }));
    };

    const addSubMenuItem = (menuList: chatbotPersistentMenu[], newItem: chatbotPersistentMenu): chatbotPersistentMenu[] => {
        return menuList.map(item => {
            if (item.chatbot_persistent_menu_id === newItem.parent_chatbot_persistent_menu_id) {
                return {
                    ...item,
                    sub_chatbot_persistent_menu: [
                        ...(item.sub_chatbot_persistent_menu || []),
                        newItem
                    ]
                };
            } else if (item.sub_chatbot_persistent_menu?.length) {
                return {
                    ...item,
                    sub_chatbot_persistent_menu: addSubMenuItem(item.sub_chatbot_persistent_menu, newItem)
                };
            } else {
                return item;
            }
        });
    };

    const updateSubMenu = (submenu: any, updatedMenu: any) => {
        return submenu.map((item: any) => {
            if (item.chatbot_persistent_menu_id === updatedMenu.chatbot_persistent_menu_id) {
                return {
                    ...updatedMenu,
                    created_at_timestamp: formatDate(updatedMenu.created_at_timestamp),
                    ...(item.sub_chatbot_persistent_menu?.length && {
                        sub_chatbot_persistent_menu: item.sub_chatbot_persistent_menu
                    })
                }
            } else if (item.sub_chatbot_persistent_menu?.length) {
                return {
                    ...item,
                    sub_chatbot_persistent_menu: updateSubMenu(item.sub_chatbot_persistent_menu, updatedMenu),
                }
            } else {
                return item;
            }
        })
    };

    // const handleSavePersistentData = async () => {

    //     let createPayload = {
    //         chatbot_id: searchParams.get('chatbot_id') ?? "",
    //         chatbot_persistent_menu_name: requestPersistentMenuDetailsList.chatbot_persistent_menu_name,
    //         chatbot_persistent_menu_value: requestPersistentMenuDetailsList.chatbot_persistent_menu_value
    //     }

    //     let updatePayload = {
    //         chatbot_id: searchParams.get('chatbot_id') ?? "",
    //         chatbot_persistent_menu_id: requestPersistentMenuDetailsList.chatbot_persistent_menu_id,
    //         chatbot_persistent_menu_name: requestPersistentMenuDetailsList.chatbot_persistent_menu_name,
    //         chatbot_persistent_menu_value: requestPersistentMenuDetailsList.chatbot_persistent_menu_value
    //     }

    //     const { chatbot_persistent_menu_name, chatbot_persistent_menu_value, chatbot_persistent_menu_id } = requestPersistentMenuDetailsList;

    //     const isDuplicate = persistentMenuDetailsList && persistentMenuDetailsList?.some((item: any) => (
    //         item?.chatbot_persistent_menu_name === chatbot_persistent_menu_name
    //     ));

    //     if (isDuplicate) {
    //         setFlagKeyExistError(true)
    //         return;
    //     }

    //     if (requestPersistentMenuDetailsList.chatbot_persistent_menu_id) {
    //         // debugger
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    //         const response = await agent.updatePersistentMenuAPI.put(updatePayload);
    //         console.log("response of updatePersistentMenuAPI", response);

    //         if (response.status) {
    //             // debugger
    //             console.log("response true of createPersistentMenuAPI", response);
    //             setPersistentMenuDetailsList((prevList: any) => prevList.map((ele: any) => {
    //                 if (ele.chatbot_persistent_menu_id == response.data[0].chatbot_persistent_menu_id) {
    //                     return {
    //                         ...response.data[0],
    //                         created_at_timestamp: formatDate(response.data[0].created_at_timestamp)
    //                     };
    //                 }
    //                 return ele;
    //             }));
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //             setSuccess({ ...success, flagShowSuccess: true, message: "Persistent details save succesfully!" })
    //             setFlagOpenMenuItemDialog(false)
    //             setFlagKeyExistError(false)
    //         }
    //         else {
    //             console.log("response false of createPersistentMenuAPI", response);
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //             setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
    //             setFlagOpenMenuItemDialog(false)
    //             setFlagKeyExistError(false)
    //         }
    //     }
    //     else {
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    //         const response = await agent.createPersistentMenuAPI.post(createPayload);

    //         if (response.status) {
    //             console.log("response true of createPersistentMenuAPI", response);
    //             if (response.data) {
    //                 const newItem = {
    //                     ...response.data[0],
    //                     created_at_timestamp: formatDate(response?.data[0]?.created_at_timestamp)
    //                 };
    //                 setPersistentMenuDetailsList((prevList: any) => [...prevList, newItem]);
    //                 setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //                 setSuccess({ ...success, flagShowSuccess: true, message: "Persistent details save succesfully!" })
    //             }
    //             setFlagOpenMenuItemDialog(false)
    //             setFlagKeyExistError(false)
    //         }
    //         else {
    //             console.log("response false of createPersistentMenuAPI", response);
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //             setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
    //             setFlagOpenMenuItemDialog(false)
    //             setFlagKeyExistError(false)
    //         }
    //     }

    // }

    const handleSavePersistentData = async () => {
        let createPayload: chatbotPersistentPayload = {
            chatbot_id: searchParams.get('chatbot_id') ?? "",
            chatbot_persistent_menu_name: requestPersistentMenuDetailsList.chatbot_persistent_menu_name,
        }

        switch (selectedActionType) {
            case "Text":
                createPayload.chatbot_persistent_menu_value = requestPersistentMenuDetailsList.chatbot_persistent_menu_value;
                break;
            case "Static Message":
                createPayload.static_message_id = requestPersistentMenuDetailsList.static_message_id;
                break;
            case "None":
                createPayload.chatbot_persistent_menu_value = "";
                break;
        }

        if (flagOpenSubMenuItemDialog) {
            createPayload.parent_chatbot_persistent_menu_id = parentPersistentMenuName.chatbot_persistent_menu_id;
        } else if (requestPersistentMenuDetailsList?.parent_chatbot_persistent_menu_id !== undefined) {
            createPayload.parent_chatbot_persistent_menu_id = requestPersistentMenuDetailsList?.parent_chatbot_persistent_menu_id
        }

        let updatePayload = {
            chatbot_id: searchParams.get('chatbot_id') ?? "",
            chatbot_persistent_menu_id: requestPersistentMenuDetailsList.chatbot_persistent_menu_id,
            chatbot_persistent_menu_name: requestPersistentMenuDetailsList.chatbot_persistent_menu_name,
            ...((selectedActionType === "Text") && { chatbot_persistent_menu_value: requestPersistentMenuDetailsList.chatbot_persistent_menu_value }),
            ...((selectedActionType === "None") && { chatbot_persistent_menu_value: "" }),
            ...((selectedActionType === "Static Message") && { static_message_id: requestPersistentMenuDetailsList.static_message_id }),
        }

        const { chatbot_persistent_menu_name, chatbot_persistent_menu_value, chatbot_persistent_menu_id } = requestPersistentMenuDetailsList;

        if (!chatbot_persistent_menu_id) {
            const isDuplicate = persistentMenuDetailsList && persistentMenuDetailsList.some((item: any) => (
                item?.chatbot_persistent_menu_name == chatbot_persistent_menu_name
            ));

            if (isDuplicate) {
                setFlagKeyExistError(true);
                return;
            }
        }

        if (chatbot_persistent_menu_id) {
            // Update flow
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const response = await agent.updatePersistentMenuAPI.put(updatePayload);

            if (response.status) {
                setPersistentMenuDetailsList((prevList) => updateSubMenu(prevList, response.data[0]))
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setSuccess({ ...success, flagShowSuccess: true, message: "Persistent details saved successfully!" });
                setFlagOpenMenuItemDialog(false);
                setFlagOpenSubMenuItemDialog(false);
                setFlagKeyExistError(false);
            } else {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(checkForErrorCode(response), isDev(userDetail)),
                });
                setFlagOpenMenuItemDialog(false);
                setFlagOpenSubMenuItemDialog(false);
                setFlagKeyExistError(false);
            }
        } else {
            // Create flow
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const response = await agent.createPersistentMenuAPI.post(createPayload);

            if (response.status) {
                if (response.data) {
                    const newItem = {
                        ...response.data[0],
                        created_at_timestamp: formatDate(response?.data[0]?.created_at_timestamp)
                    };

                    if (newItem.parent_chatbot_persistent_menu_id === null) {
                        setPersistentMenuDetailsList((prevList: any) => [...prevList, newItem]);
                    } else {
                        setPersistentMenuDetailsList((prevList = [] as chatbotPersistentMenu[]) =>
                            addSubMenuItem(prevList, newItem)
                        );

                        // Update Action Type to None for parent menu of this sub menu.  
                        if (parentPersistentMenuName.chatbot_persistent_menu_value === null || parentPersistentMenuName.chatbot_persistent_menu_value?.length > 0) {
                            const payload = {
                                chatbot_id: searchParams.get('chatbot_id') ?? "",
                                chatbot_persistent_menu_id: parentPersistentMenuName.chatbot_persistent_menu_id,
                                chatbot_persistent_menu_name: parentPersistentMenuName.chatbot_persistent_menu_name,
                                chatbot_persistent_menu_value: ""
                            }

                            const res = await agent.updatePersistentMenuAPI.put(payload);
                            if (res.status) {
                                setPersistentMenuDetailsList((prevList) => updateSubMenu(prevList, res.data[0]))
                                console.log("Response is smtg like: 2 ", res);
                            }
                        }
                    }

                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    setSuccess({ ...success, flagShowSuccess: true, message: "Persistent details saved successfully!" });
                }
                setFlagOpenMenuItemDialog(false);
                setFlagOpenSubMenuItemDialog(false);
                setFlagKeyExistError(false);
            }
            else {
                console.log("response false of createPersistentMenuAPI", response);
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(checkForErrorCode(response), isDev(userDetail)),
                });
                setFlagOpenMenuItemDialog(false);
                setFlagOpenSubMenuItemDialog(false);
                setFlagKeyExistError(false);
            }
        }
    }


    const setHandleEdit = (rowDetail: any) => {
        setFlagKeyExistError(false)
        setFlagOpenMenuItemDialog(true)
        setRequestPersistentMenuDetailsList(rowDetail)
    }

    const deleteDocumentDataPopupOpen = (data: any) => {
        setFlagDeletePersistentMenuDataDialog(true)
        setDeletePersistentDialogId(data.chatbot_persistent_menu_id)
        setDeletePersistentName(data.chatbot_persistent_menu_name)
    }

    const handleOpenEditDialog = async (e: any, rowDetail: any) => {
        if (e.iconKey !== "editIcon" && e.iconKey !== "addIcon") {
            setHandleEdit(rowDetail)
        }
    }

    // const handleDeletePersistentDetail = async (id: any) => {

    //     let chatbot_id = searchParams.get('chatbot_id') ?? ""

    //     setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    //     const removeDataResponse = await agent.removePersistentMenuDataAPI.delete(id);
    //     if (removeDataResponse.status) {
    //         const getResponse = await agent.getChatbotData.get(Number(chatbot_id));
    //         const chatbotDetailsData = getResponse.data
    //         if (getResponse.status) {
    //             const persistentDetails = chatbotDetailsData?.chatbot_persistent_menu && chatbotDetailsData?.chatbot_persistent_menu?.map((ele: any) => {
    //                 return { ...ele, created_at_timestamp: formatDate(ele?.created_at_timestamp) };
    //             })
    //             setPersistentMenuDetailsList(persistentDetails)
    //         }
    //         setFlagDeletePersistentMenuDataDialog(false)
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //         setSuccess({ ...success, flagShowSuccess: true, message: removeDataResponse?.data?.message })
    //     }
    //     else {
    //         setFlagDeletePersistentMenuDataDialog(false)
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    //         setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
    //     }
    // }

    const deletePersistentMenuById = (menuList: any, id: any) => {
        return menuList
            .filter((item: any) => item.chatbot_persistent_menu_id !== id)
            .map((item: any) => ({
                ...item,
                sub_chatbot_persistent_menu: item.sub_chatbot_persistent_menu
                    ? deletePersistentMenuById(item.sub_chatbot_persistent_menu, id)
                    : []
            }));
    };

    const handleDeletePersistentDetail = async (id: any) => {

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const removeDataResponse = await agent.removePersistentMenuDataAPI.delete(id);
        if (removeDataResponse.status) {
            // setPersistentMenuDetailsList((prevList: any) => prevList.filter((item: any) => item.chatbot_persistent_menu_id !== id));
            setPersistentMenuDetailsList((prevList: any) => deletePersistentMenuById(prevList, id));
            setFlagDeletePersistentMenuDataDialog(false)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            setSuccess({ ...success, flagShowSuccess: true, message: removeDataResponse?.data?.message });
        } else {
            setFlagDeletePersistentMenuDataDialog(false)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(checkForErrorCode(removeDataResponse), isDev(userDetail)),
            })
        }
    }

    const isActionTypeDisabled = () => {
        let sub_chatbot_persistent_menu = findSelectedMenu(persistentMenuDetailsList as chatbotPersistentMenu[]);
        if (sub_chatbot_persistent_menu != undefined && sub_chatbot_persistent_menu.length > 0 && selectedActionType === "None") {
            return true;
        }
        return false;
    }

    const findSelectedMenu = (menuList: chatbotPersistentMenu[]) => {
        for (const item of menuList) {
            if (item.chatbot_persistent_menu_id === requestPersistentMenuDetailsList.chatbot_persistent_menu_id) {
                return item.sub_chatbot_persistent_menu;
            }

            if (item.sub_chatbot_persistent_menu) {
                const foundMenu: any = findSelectedMenu(item.sub_chatbot_persistent_menu);
                if (foundMenu) {
                    return foundMenu;
                }
            }
        }
    }

    const handleRowClickOfPersistentMenuTable = (e: any) => {
        if (e.static_message_id) {
            setSelectedActionType("Static Message");
        } else if (e.chatbot_persistent_menu_value?.length > 0 && e.chatbot_persistent_menu_value != null) {
            setSelectedActionType("Text");
        } else {
            setSelectedActionType("None");
        }
    }

    const innerHeight = (window.innerHeight - 190);

    return (
        <>
            {flagOpenMenuItemDialog &&
                <Dialog
                    open={flagOpenMenuItemDialog}
                    title={""}
                    onClose={() => { setFlagOpenSubMenuItemDialog(false); setFlagOpenMenuItemDialog(false); setFlagKeyExistError(false); }}
                    maxWidth={"md"}
                >
                    <PersistentMenuModal
                        setFlagOpenMenuItemDialog={setFlagOpenMenuItemDialog}
                        flagOpenMenuItemDialog={flagOpenMenuItemDialog}

                        handlePersistentMenuDetails={handleChange}
                        handleSavePersistentData={handleSavePersistentData}

                        requestPersistentMenuDetailsList={requestPersistentMenuDetailsList}
                        setRequestPersistentMenuDetailsList={setRequestPersistentMenuDetailsList}

                        flagKeyExistError={flagKeyExistError}
                        setFlagKeyExistError={setFlagKeyExistError}

                        flagOpenSubMenuItemDialog={flagOpenSubMenuItemDialog}
                        setFlagOpenSubMenuItemDialog={setFlagOpenSubMenuItemDialog}

                        parentPersistentMenuName={parentPersistentMenuName}

                        selectedActionType={selectedActionType}
                        setSelectedActionType={setSelectedActionType}

                        isActionTypeDisabled={isActionTypeDisabled}
                    />
                </Dialog>
            }

            {flagDeletePersistentMenuDataDialog &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete ${deletePersistentName}`}
                    open={flagDeletePersistentMenuDataDialog}
                    onClose={() => { setFlagDeletePersistentMenuDataDialog(false) }}
                    onDelete={(e: any) => { handleDeletePersistentDetail(deletePersistentDialogId) }}
                />
            }

            <Grid container>
                <Grid item xl={12} xs={12}
                    className={`${globalStyles.fullwidth} ${globalStyles.dashboardChildWrapper} ${styles.dataConnectorDashboard}`}
                    sx={{ width: "100%" }}
                >
                    <Grid container alignItems={"center"} justifyContent={"end"} >
                        <Grid item xs={12} sx={{ maxWidth: persistentMenuDetailsList && persistentMenuDetailsList.length > 5 ? '305px !important' : 'auto' }} className={`${globalStyles.globalHeadRightSubItem} ${styles.globalAddConnectorButton}`}>
                            <Grid container sx={{ alignItems: "center", justifyContent: 'end' }}>
                                <Grid item>
                                    <Grid container sx={{ alignItems: 'center', flexWrap: "nowrap", gap: "10px" }}>
                                        {/* {persistentMenuDetailsList && persistentMenuDetailsList.length >= 9 &&
                                            <Tooltip title="Maximum 10 menu items are allowed" enterTouchDelay={1}>
                                                <Grid item sx={{ mr: '5px', maxWidth: '30px', textAlign: "end" }}>
                                                    <InfoOutlinedIcon style={{ color: '#6A097D', fontSize: '22px', display: 'flex' }} />
                                                </Grid>
                                            </Tooltip>
                                        } */}
                                        {window.screen.width >= 900 &&
                                            <>
                                                <Grid item textAlign={"end"}>
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        // fullWidth
                                                        startIcon={<IconsColor iconName="plusIcon" width={"16"} height={"16"} fill={"#FFF"} />}
                                                        className={styles.newConnectorBtn}
                                                        // disabled={persistentMenuDetailsList && persistentMenuDetailsList.length > 9}
                                                        sx={{
                                                            // opacity: persistentMenuDetailsList && persistentMenuDetailsList.length > 9 ? '0.5' : '1',
                                                            width: "150px"
                                                        }}
                                                        onClick={() => {
                                                            setSelectedActionType("Text");
                                                            setFlagOpenMenuItemDialog(true)
                                                            setFlagKeyExistError(false)
                                                            setRequestPersistentMenuDetailsList({
                                                                ...requestPersistentMenuDetailsList,
                                                                chatbot_persistent_menu_id: null,
                                                                chatbot_persistent_menu_name: "",
                                                                chatbot_persistent_menu_value: "",
                                                                static_message_id: undefined,
                                                                parent_chatbot_persistent_menu_id: undefined,
                                                            });
                                                        }}
                                                    >
                                                        Menu Item
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                        {window.screen.width <= 899 &&
                                            <>
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    // disabled={persistentMenuDetailsList && persistentMenuDetailsList.length > 9}
                                                    // sx={{
                                                    //     opacity: persistentMenuDetailsList && persistentMenuDetailsList.length > 9 ? '0.5' : '1'
                                                    // }}
                                                    onClick={() => {
                                                        setFlagOpenMenuItemDialog(true)
                                                        setFlagKeyExistError(false)
                                                        setRequestPersistentMenuDetailsList({
                                                            ...requestPersistentMenuDetailsList,
                                                            chatbot_persistent_menu_id: null,
                                                            chatbot_persistent_menu_name: "",
                                                            chatbot_persistent_menu_value: "",
                                                            static_message_id: undefined,
                                                            parent_chatbot_persistent_menu_id: undefined,
                                                        });
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>


                                            </>
                                        }
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.documentsTableItem}`} mt={2} height={`${innerHeight}px !important`}>
                            <GlobalTable
                                headerList={adjustedHeaderList}
                                rowData={(persistentMenuDetailsList !== undefined && persistentMenuDetailsList?.length && persistentMenuDetailsList !== null) && persistentMenuDetailsList}
                                tableHeight={"76vh"}
                                tableContainerMinHeight={"auto"}
                                menuItemsFromParent={menuItems}
                                menuItemOnClickOfAddIcon={menuItemOnClickOfAddIcon}
                                rowClicked={(e: any) => { console.log(e) }}
                                rowAllowEdit={true}
                                flagPagination
                                onCellClick={(e: any, rowDetail: any) => {
                                    handleOpenEditDialog(e, rowDetail)
                                }}
                                onRowClick={(e: any, rowDetail: any) => handleRowClickOfPersistentMenuTable(e)}
                                deleteRowData={deleteDocumentDataPopupOpen}
                                hoveredRow={hoveredRow}
                                setHoveredRow={setHoveredRow}
                                selectedActionType={selectedActionType}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PersistentMenu