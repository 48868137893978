import { Textarea, Typography } from '@mui/joy';
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

export const InputText = ({ handleOnChange, item, fieldProps, onChange, handleOnBlur }) => {

    const [value, setValue] = useState(item.value);
    const [flagValueChanged, setFlagValueChganged] = useState(false);

    useEffect(() => {
        setValue(item.value)
    }, [item.value])

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (flagValueChanged) {
                handleOnChange(item, value)
            }
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [value])

    return (
        <TextField
            {...fieldProps}
            type="text"
            dir="auto"
            value={value}
            onBlur={({ target: { value } }) => {
                handleOnBlur(item, value);
            }}
            onChange={({ target: { value } }) => {
                onChange(value);
                setFlagValueChganged(true);
                setValue(value)
            }}

            // onBlur={(e) => {
            //     // setTest(e.target.value);
            //     // onChange(e)
            // }}
            // disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
            disabled={item.is_disabled ? true : false}
            // className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
            className={(item.class2 ? item.class2 : "") + " input_class_widget_preview formWiz-input-field"}
            name={item.page_field_id}
            // onKeyDown={blockInvalidChar}
            placeholder={item.placeholder}
        />
    )
}

export const EmailText = ({ handleOnChange, item, onChange, handleOnBlur, fieldProps }) => {
    const [value, setValue] = useState(item.value);
    const [flagValueChanged, setFlagValueChganged] = useState(false);

    useEffect(() => {
        setValue(item.value)
    }, [item.value])
    useEffect(() => {
        let timeout = setTimeout(() => {
            if (flagValueChanged) {
                handleOnChange(item, value)
            }
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [value])

    return (
        <TextField
            {...fieldProps}
            type="email"
            dir="auto"
            value={value}
            onBlur={({ target: { value } }) => {
                handleOnBlur(item, value);
            }}
            onChange={({ target: { value } }) => {
                onChange(value);
                setFlagValueChganged(true);
                setValue(value)
            }}

            // onBlur={(e) => {
            //     // setTest(e.target.value);
            //     // onChange(e)
            // }}
            // disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
            disabled={item.is_disabled ? true : false}
            // className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
            className={(item.class2 ? item.class2 : "") + " input_class_widget_preview formWiz-input-field"}
            name={item.page_field_id}
            // onKeyDown={blockInvalidChar}
            placeholder={item.placeholder}
        />
    )
}

export const TextareaText = ({ handleOnChange, item, onChange, handleOnBlur, fieldProps }) => {
    const [value, setValue] = useState(item.value);
    const [flagValueChanged, setFlagValueChganged] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (flagValueChanged) {
                handleOnChange(item, value)
            }
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [value])

    return (

        <Textarea
            {...fieldProps}
            type="email"
            value={value}
            onChange={({ target: { value } }) => {
                onChange(value);
                if (item.max) {
                    if (value.length <= item.max) {
                        handleOnChange(item, value)
                    }
                } else {
                    handleOnChange(item, value)
                }
            }}
            onBlur={({ target: { value } }) => {
                handleOnBlur(item, value)
            }}
            // onBlur={(e) => {
            //     // setTest(e.target.value);
            //     // onChange(e)
            // }}
            // disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
            disabled={item.is_disabled ? true : false}
            // className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
            className={(item.class2 ? item.class2 : "") + " input_class_widget_preview formWiz-input-field"}
            name={item.page_field_id}
            // onKeyDown={blockInvalidChar}
            placeholder={item.placeholder}
            minRows={item.minRows ? item.minRows : 2}
            maxRows={item.maxRows ? item.maxRows : 2}
        />
    )
}