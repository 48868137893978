import React, { useContext } from 'react'
import GlobalPopUp from '../../../utility/globalPopUp'
import { Grid } from '@mui/material';
import ConfirmationPopover from '../../../utility/confirmationPopover';
import { globalIntegration } from '../../../../models/axiosModel';
import AccountInformationCard from '../../../utility/accountInformationCard';
import globalStyles from "../../../../assets/styles/global.module.scss";
import GlobalButton from '../../../utility/globalButton/button';
import IconsColor from '../../../utility/iconsColor';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';

interface addChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    phone_number_id: string,
    meta_integration_type_id: string
}

interface ShowAvailablePhoneNumbersProps {
    flagShowAvailableNumbers: boolean,
    setFlagShowAvailableNumbers: React.Dispatch<React.SetStateAction<boolean>>;
    smallScreen: boolean,
    showConfirmationPopover: boolean,
    confirmationPopoverAnchorEl: null | HTMLButtonElement,
    handleCloseConfirmationPopover: () => void,
    addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface,
    AddChatbotIntegration: (addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface) => void;
    availablePhoneNumberData: globalIntegration[],
    handleRemoveChatbotIntegration: (meta_integration_id: number, meta_integration_type_id: number, phone_number_id: string, display_phone_number: string) => void,
    displayPhoneNumber: string,
    launchWhatsAppSignup: () => void
    saveChatbotIntegrationData: (meta_integration_id: number, meta_integration_type_id: number, phone_number_id: string, display_phone_number: string, event?: React.MouseEvent<HTMLButtonElement>) => void
}

function ShowAvailablePhoneNumbers(props: ShowAvailablePhoneNumbersProps) {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { flagShowAvailableNumbers, setFlagShowAvailableNumbers, smallScreen, showConfirmationPopover, confirmationPopoverAnchorEl, handleCloseConfirmationPopover, addChatbotIntegrationDetails, AddChatbotIntegration, availablePhoneNumberData, handleRemoveChatbotIntegration, launchWhatsAppSignup, displayPhoneNumber, saveChatbotIntegrationData } = props
    return (
        <div>

            <GlobalPopUp
                open={flagShowAvailableNumbers}
                handleClose={() => { setFlagShowAvailableNumbers(false) }}
                flagShowCloseIcon
                title='Add WhatsApp Phone Numbers'
                paperMinWidth={smallScreen ? '85%' : '90vw'}
                height='90vh'
            >
                <Grid container gap="20px" direction={"column"} sx={{ padding: "30px" }} mt={{ xs: 1, lg: 0 }}>
                    <Grid item>
                        <Grid container gap="15px">
                            <ConfirmationPopover
                                open={showConfirmationPopover}
                                anchorEl={confirmationPopoverAnchorEl}
                                handleClose={handleCloseConfirmationPopover}
                                onConfirmation={() => { AddChatbotIntegration(addChatbotIntegrationDetails) }}
                                descriptionText={<p>Are you sure you want to deploy <span style={{ fontWeight: "600" }}>{chatBotDetails?.chatbot_name} on <span style={{ fontWeight: "600" }}>{displayPhoneNumber}</span></span> ?</p>}
                            />
                            {availablePhoneNumberData.filter((numbers) => numbers.meta_integration_type_assignment_data.meta_integration_type_name === "WhatsApp").map((integrationData, index) => (
                                <>
                                    <Grid container direction={"column"} gap="15px">
                                        <Grid item className={globalStyles.globalHeaderFontStyle}>{integrationData?.meta_integration_whatsapp_data?.waba_name}</Grid>
                                        <Grid item>
                                            <Grid container gap="15px">
                                                {integrationData?.meta_integration_whatsapp_data?.meta_integration_phone_number_data.map((phoneNumberData, inded) => (
                                                    <AccountInformationCard
                                                        key={index}
                                                        flagShowLinkedChatbot={true}
                                                        flagDelete={false}
                                                        title={`WhatsApp Account ${index + 1}`}
                                                        currentlySelected={phoneNumberData.chatbot_integration_assignment_data.length > 0}
                                                        showRemoveChatbotIntegration={false}
                                                        disabled={phoneNumberData.chatbot_integration_assignment_data.length > 0}
                                                        removeChatbotIntegration={(phone_number_id: string) => { handleRemoveChatbotIntegration(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, phone_number_id, phoneNumberData.display_phone_number) }}
                                                        addChatbotIntegration={phoneNumberData.chatbot_integration_assignment_data.length == 0 ? (phone_number_id, event) => {
                                                            if (event) {
                                                                saveChatbotIntegrationData(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, phone_number_id, phoneNumberData.display_phone_number, event)
                                                            } else {
                                                                saveChatbotIntegrationData(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, phone_number_id, phoneNumberData.display_phone_number)
                                                            }
                                                        } : undefined}
                                                        details={{
                                                            display_phone_number: phoneNumberData.display_phone_number,
                                                            quality_rating: phoneNumberData.quality_rating,
                                                            phone_number_id: phoneNumberData.phone_number_id,
                                                            linked_chatbots: phoneNumberData.chatbot_integration_assignment_data,
                                                            business_name: phoneNumberData.verified_name,
                                                        }}
                                                        flagIntegration={true}
                                                    />
                                                ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <GlobalButton buttonText={"WhatsApp Business Account"} onClick={launchWhatsAppSignup} className={'blackShadowedButton'}
                            startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                        />
                    </Grid>


                </Grid>
            </GlobalPopUp></div>
    )
}

export default ShowAvailablePhoneNumbers