import { Box, Grid, Tooltip, Typography } from '@mui/material'
import styles from "../../assets/styles/componentStyles/utility/consultation.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import agent from '../../services/api';
import { checkForErrorCode, getErrorMessage, isDev, primaryColor } from '../../utils/constants';
interface consultationComponent {
    flagInsideSideNavigationPanel?: boolean;
    flagMobile?: boolean;
    onClickConsultation?: () => void;
    onCloseConsultation?: () => void
}
export const DelegateAccess = (props: consultationComponent) => {
    const { userDetail, setError } = useContext(UserContext) as UserContextPayload

    async function exitDeletageAccess() {
        const response = await agent.removeDelegateAccess.delete()
        if (response.status) {
            window.close()
        } else {
            let errorCode = checkForErrorCode(response)
            setError((prevState) => ({
                ...prevState,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail))
            }))
        }
    }
    return (
        <>
            {userDetail.user_delegate_access_data &&
                <>
                    {(userDetail.user_delegate_access_data) &&
                        <>
                            <Box className={props.flagMobile ? styles.mainBlockMobile : styles.mainBlock} onClick={() => { exitDeletageAccess() }}>
                                <Box className={styles.mainBlockChild}>
                                    <ExitToAppIcon sx={{ color: primaryColor }} />
                                    {!props.flagMobile &&
                                        <Typography className={styles.nameStyle} sx={{ maxWidth: "125px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {userDetail.user_delegate_access_data?.user_email}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        </>
                    }
                </>
            }
        </>
    )
}
