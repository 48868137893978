import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Styles from "../../../assets/styles/componentStyles/globalSearch.module.scss"

interface GlobalSearchProps {
    placeholder?: string,
    className?: string,
    size: 'small' | 'medium'
    value?: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    fullWidth?: boolean,
    style?: React.CSSProperties
}

const GlobalSearch = (props: GlobalSearchProps) => {
    return (
        <div>
            <TextField
                id="outlined-search"
                variant='outlined'
                type="search"
                autoComplete="off"
                placeholder={props.placeholder}
                className={`${props.className} ${Styles.globalSearch}`}
                size={props.size}
                style={props.style}
                value={props.value}
                onChange={props.onChange}
                fullWidth={props.fullWidth}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon style={{ color: "#7A7A7A" }} />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default GlobalSearch;
