import { Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import StaticMessageFormatTable from './staticMessageFormatTable'
// import { MessageFormatContext, MessageFormatContextPayload } from '../../../context/messageFormatContext';
import { useSearchParams } from 'react-router-dom';


const StaticMessageFormatGlobal = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id')


    return (
        <Grid container sx={{ padding: "25px" }}>
            <StaticMessageFormatTable />
        </Grid>
    )
}

export default StaticMessageFormatGlobal
