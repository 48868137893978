import React, { useState } from 'react'
import whatsapp from '../../../../assets/CrossMark.svg'
import styles from "../../../../assets/styles/componentStyles/chatbotStyles/chatbotWhatsAppIntegrationSetup.module.scss"
import GlobalButton from '../../../utility/globalButton/button'
import { Link } from 'react-router-dom'
import whatsAppIcon from "../../../../assets/Whatsapp.svg";
import WhatsappIntegration from '../../../../assets/images/chatbot/WhatsappIntegration.png'
import ideaIcon from '../../../../assets/idea.png'
import IconsColor from '../../../utility/iconsColor'
import globalstyles from '../../../../assets/styles/global.module.scss'
import LazyLoadingImage from '../../../utility/lazyLoadingImage'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography } from '@mui/material'
import { instagramPrereq } from '../../../../utils/content'

interface launchWhatsApp {
    launch: any,
    viewIntegration?: boolean
}


const ChatbotInstagramIntegrationSetup = (props: launchWhatsApp) => {


    const [flagIntegrateWhatsApp, setFlagIntegrateWhatsApp] = useState(false);
    const [showSteps, setShowSteps] = useState(false);
    const [tipCount, setTipCount] = useState(1);
    const [needs, setNeeds] = useState(instagramPrereq)


    const handleGoBack = () => {
        if (tipCount === 1) {
            setShowSteps(false);
        }
        else if (tipCount === 2) {
            setTipCount(1);
        }
        else if (tipCount === 3) {
            setTipCount(2);
        }
    }

    const handleNextTip = () => {
        if (tipCount === 1) {
            setTipCount(2);
        }
        else if (tipCount === 2) {
            setTipCount(3);
        }
        else if (tipCount === 3) {
            props.launch();
        }
    }

    const handleSkipTips = () => {
        props.launch();
    }

    return (
        <>
            {!showSteps &&
                <>
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item style={{ width: "100%" }}>
                                <Typography component='span' className={styles.setUpValue}>A few things that are necessary to integrate with Instagram should be ready before you begin. We have compiled a collection of helpful hints that will walk you through each stage.</Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                <Typography component='span' className={styles.setUpTitle}>Steps to integrate</Typography>
                            </Grid>
                            <Grid item>
                                {needs.map((ele, index) => {
                                    return (
                                        <>
                                            <Grid container gap={2} alignItems={"start"} style={{ marginBottom: "15px" }} flexWrap={"nowrap"}>
                                                <Grid item className={styles.setUpIndexGrid}>
                                                    <Typography component='span' className={styles.setUpIndex}>{index + 1}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction={"column"} gap={"5px"}>
                                                        <Grid item>
                                                            <Typography component='span' className={styles.setUpValue}>{ele.title}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography component='span' className={styles.setUpContent}>{ele.content}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </>
            }

            {/* {showSteps &&
                <>
                    <Grid item>
                        <Grid container gap={1}>
                            <Grid item style={{ width: "100%" }}>
                                {tipCount === 1 && <Typography component='span' className={styles.tipStyle}>TIP 1 OF 3</Typography>}
                                {tipCount === 2 && <Typography component='span' className={styles.tipStyle}>TIP 2 OF 3</Typography>}
                                {tipCount === 3 && <Typography component='span' className={styles.tipStyle}>TIP 3 OF 3</Typography>}
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                {tipCount === 1 && <Typography component='span' className={styles.setUpTitle}>Access to Facebook Business Manager</Typography>}
                                {tipCount === 2 && <Typography component='span' className={styles.setUpTitle}>Phone number for WhatsApp</Typography>}
                                {tipCount === 3 && <Typography component='span' className={styles.setUpTitle}>Details of your business and legal address</Typography>}
                            </Grid>
                            <Grid item>
                                {tipCount === 1 &&
                                    <Grid container gap={2}>
                                        <Grid item>
                                            <Typography component='span' className={styles.stepsValue}>To initiate the process, you will be required to log in to your Facebook account and have access to your company's Facebook Business Manager. Without proper account it's not possible to integrate with WhatsApp.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography component='span' className={styles.stepsValue}>No account? Create a new account, but remember there are some restrictions for the first 24 hours.</Typography>
                                        </Grid>
                                    </Grid>}
                                {tipCount === 2 &&
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <Typography component='span' className={styles.stepsValue}>Once you've successfullly logged in, you'll be required to provide a phone number for your WhatsApp channel that meets the following specifications:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ul className={styles.listSteps}>
                                                <li>The phone number you provide must be registered under your company's ownership. It is essential not to use your personal phone number.</li>
                                                <li>You have the option to utilize a phone number that is already registered with either WhatsApp Messenger or the WhatsApp Business App. However, if you wish to use it, you need to delete the WhatsApp Messenger from your device.</li>
                                                <li>When proceeding, it is necessary for you to have the capability to receive phone calls or SMS to the provided phone number.</li>
                                            </ul>
                                        </Grid>
                                    </Grid>}
                                {tipCount === 3 &&
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <Typography component='span' className={styles.stepsValue}>During the integration process, it is important to have the following information available during the process:</Typography>
                                        </Grid>
                                        <Grid item sx={{ width: "100%" }}>
                                            <ul className={styles.listSteps}>
                                                <li>Name of your company, which will be visible to the customers you engage in conversations with</li>
                                                <li>Legal business name of your company</li>
                                                <li>Official address of your company</li>
                                            </ul>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                            <Grid item className={styles.integrateGridStyles}>
                                <Grid container className={styles.integrateSkipButtonGrid} gap={2}>
                                    <Grid item>
                                        <Grid container gap={1}>
                                            <Grid item>
                                                <ArrowBackIosNewIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => { handleGoBack() }} />
                                            </Grid>
                                            <Grid item>
                                                <ArrowForwardIosIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => { handleNextTip() }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            } */}
        </>
    )
}

export default ChatbotInstagramIntegrationSetup