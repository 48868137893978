import { Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import FileUpload from '../../utility/fileUpload/fileUpload';
import GlobalButton from '../../utility/globalButton/button';
import downloadCsvFileIcon from "../../../assets/images/campaignContact/downloadCsvFileIcon.png";
import { UserContext, UserContextPayload } from '../../../context/userContext';
import axios from 'axios';
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import agent from '../../../services/api';
import csv from "csvtojson";


interface ContactCardTemplateProps {
    onClickOfImportButton: (id: number) => void,
    files: any,
    setFiles: React.Dispatch<React.SetStateAction<any>>,
    progress: any,
    setProgress: React.Dispatch<React.SetStateAction<any>>,
    setFieldsOfCSV: React.Dispatch<React.SetStateAction<string[]>>,
    jsonArray: any[],
    setJsonArray: React.Dispatch<React.SetStateAction<any[]>>,
}

const ContactCardTemplate: React.FC<ContactCardTemplateProps> = ({ onClickOfImportButton, files, setFiles, progress, setProgress, setFieldsOfCSV, jsonArray, setJsonArray }) => {

    const { error, setError, setFlagLoading, flagLoading, userDetail, success, setSuccess } = useContext(UserContext) as UserContextPayload;

    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');


    const uploadFileHandler = async (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            const file: any = e.target.files[i];

            file.isUploading = true;
            setFiles([file]);

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");

            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }

            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }).then(async (res) => {
                if (res.status) {
                    file.isUploading = false;
                    file.file_data = res.data.data;


                    file.google_storage_id = res.data.data.google_storage_id;
                    await setFiles((old: any) => {
                        let temp = [...old]
                        let fileType = file.name.split('.').pop()
                        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        temp.map((rr: any) => {
                            if (rr.google_storage_id == file.google_storage_id) {
                                rr.isUploading = false;
                                rr.file_data = res.data.data;
                                rr.google_storage_id = res.data.data.google_storage_id;
                                // rr.page_field_id = id;
                                rr.previewImageSrc = fileTypeIconSrc;
                            }
                        })
                        return temp
                    })

                    const File1 = await agent.getFile.get(res.data.data.google_storage_id);
                    const file1 = new File([File1], File1.original_file_name);
                    if (File1) {
                        const reader = new FileReader();
                        reader.onload = async (e) => {
                            const csvStr = (e.target?.result) as string;
                            try {
                                const jsonArray = await csv().fromString(csvStr);
                                setFieldsOfCSV(Object.keys(jsonArray[0]));
                                setJsonArray(jsonArray);
                            } catch (error) {
                                console.log("csv to json Error Occured csv to json, ", error);
                            }
                        };
                        reader.readAsText(file1);
                    }
                }
            }).catch((err) => {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFiles([])
                // inform the user
                console.error(err)
                // removeFile(file.name)
            });

            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }
    }

    const removeFileHandler = async (data: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${files[0].file_data.google_storage_id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then(async (res) => {
            if (res.data.status) {
                setFiles([]);
                setJsonArray([]);
                setSuccess({ ...success, flagShowSuccess: true, message: "File Deleted Successfully" })
            } else {
                let errorCode = checkForErrorCode(res.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        }).catch((err) => {
            console.log(err);
        })
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        // setFiles([]);
        // setJsonArray([]);
    }

    const downloadSampleCSVTemplate = () => {
        window.open("https://docs.google.com/spreadsheets/d/14LTchOHCv31cr-sFswsM3KrTUTAeRRg9py7eAF2YDjI/edit?gid=0#gid=0", "_blank", "noopener,noreferrer");
    }

    return (
        <>
            <Grid container direction={"column"} gap={"12px"} sx={{ borderRadius: "5px", background: "#EFEFEF", padding: "15px" }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" gap={"10px"} alignItems="center">
                        <Grid item>
                            <Typography className={style.importCSVTitle}>Import Contact (Upload CSV File)</Typography>
                            <Typography className={style.importCSVLimit}>Maximum contact upload limit <span style={{ fontSize: "13px", color: "black" }}>10,000</span> contacts</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="space-between" alignItems="center" gap={"5px"} onClick={() => { downloadSampleCSVTemplate() }}>
                                <img src={downloadCsvFileIcon} alt="downloadCSVIcon" style={{ cursor: "pointer" }} />
                                <Typography className={style.downloadSampleCSVTemplateText}>View Sample CSV Template</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ width: "100%" }}>
                    <FileUpload
                        uploadedFileDetails={files}
                        multipleFileAllow={true}
                        fileSizeAllowed={3}
                        fileTypeAllowed={'.csv'}
                        fileNumberAllowed={2}
                        isDisabled={false}
                        uploadFileHandler={uploadFileHandler}
                        removeFileHandler={removeFileHandler}
                        bgColor={"#fff"}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"} alignItems={"start"}>
                        <Grid item>
                            <GlobalButton
                                buttonText={"Import"}
                                className='primaryButtonStyle'
                                sx={{ width: "120px" }}
                                disabled={files.length === 0}
                                onClick={(e) => { onClickOfImportButton(files[0].file_data.google_storage_id) }}
                            />
                        </Grid>
                        {jsonArray.length > 0 &&
                            <Grid item>
                                <Typography className={style.contactsTitleTypography}>{jsonArray.length} Contacts</Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactCardTemplate
