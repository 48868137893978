import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useContext } from 'react'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext';
import campaignStyles from './../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignAnalysisStyle.module.scss'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { contactCampaignTrackingStatus } from '../../../models/axiosModel';

const CampaignContactStatusTrack = () => {
    const { currentContactStatus } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload
    const { userDetail } = useContext(UserContext) as UserContextPayload

    return (
        <Grid container sx={{zIndex: "10 !important"}}>
            <Grid item xs={12}>
                <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"}>
                    <Grid item textAlign={"center"} sx={{width: "100%"}}>
                        <Typography className={styles.popupTitleStyle}>Contact Status</Typography>
                    </Grid>

                    <Grid item sx={{width: "100%"}}>
                        <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
                    </Grid>

                    <Grid item sx={{width: "100%"}}>
                        <Grid container gap={"12px"}>
                            <Grid item xs={12} md={6}>
                                <Grid container gap={1} flexWrap={"nowrap"}>
                                    <Grid item xs={6}>
                                        <span className={campaignStyles.statusKeyStyle}>Phone Number:</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className={campaignStyles.statusValueStyle}>{currentContactStatus?.contact_phone_number}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container gap={1} flexWrap={"nowrap"}>
                                    <Grid item xs={6}>
                                        <span className={campaignStyles.statusKeyStyle}>Name: </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className={campaignStyles.statusValueStyle}>{currentContactStatus?.contact_name}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={"6px"}>
                                <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "450px"}}>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Message Status</TableCell>
                                                <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Time</TableCell>
                                                <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Error</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {currentContactStatus?.campaign_execution_tracking_data?.sort((a: any,b: any) => new Date(a.webhook_timestamp).getTime() - new Date(b.webhook_timestamp).getTime()).map((status: contactCampaignTrackingStatus) => 
                                                <TableRow className={dashboardStyles.cellRow}>
                                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{textTransform: "capitalize"}}>{status.message_status}</TableCell>
                                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{status.webhook_timestamp ? formatDateAccordingToTimezoneUsingMoment(status.webhook_timestamp, userDetail.timezone_hour) : "-"}</TableCell>
                                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{maxWidth: "200px", whiteSpace: "wrap !important", wordBreak: "break-word !important"}}>{status.error_obj ? status.error_obj : "-"}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CampaignContactStatusTrack
