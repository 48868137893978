import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitle, IconButton, Backdrop, Divider, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { FocusTrap } from "@mui/base";
interface GlobalDialogProps extends DialogProps {
  title?: string;
  flagShowCloseIcon?: boolean;
  handleClose: () => void;
  flagBackdrop?: boolean;
  borderRadius?: string; // New prop for borderRadius
  overflow?: string;
  paperMaxwidth?: string;
  paperMinWidth?: string;
  titleAlignment?: string;
  height?: string,
  titlePadding?: string,
  flagDivider?: boolean,
  backgroundColor?: string,
  boxShadow?: string,
  margin?: string,
}
const BootstrapDialog = styled(Dialog)<{ borderRadius?: string, overflow?: string, paperMaxwidth?: string, paperMinWidth?: string, height?: string, titleAlignment?: string, titlePadding?: string, backgroundColor?: string, boxShadow?: string, margin?: string }>(({ theme, borderRadius, overflow, paperMaxwidth, paperMinWidth, height, titleAlignment, titlePadding, backgroundColor, boxShadow, margin }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: backgroundColor ?? "",
    color: backgroundColor ? "#fff" : "#000"
  },
  "& .MuiPaper-root": {
    borderRadius: borderRadius ?? "4px", // Apply borderRadius
    overflow: overflow ?? "",
    maxWidth: paperMaxwidth ?? "",
    minWidth: paperMinWidth ?? "",
    height: height ?? "",
    boxShadow: boxShadow ?? "",
    margin: margin ?? "",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },

}));



function BootstrapDialogTitle(props: GlobalDialogProps) {
  const { title, titleAlignment, flagShowCloseIcon, handleClose, flagBackdrop, titlePadding, } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textAlign: titleAlignment ? titleAlignment : "center", padding: titlePadding ? titlePadding : "", color: "#000000" }}>
      {title}
      {flagShowCloseIcon && handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute !important',
            right: title && title.length ? 8 : 0,
            top: title && title.length ? 8 : 0,
            color: "black",
            zIndex: "3"
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const GlobalPopUp: React.FC<GlobalDialogProps> = ({
  title,
  open,
  flagShowCloseIcon,
  handleClose,
  children,
  flagBackdrop,
  borderRadius,
  titleAlignment,
  titlePadding,
  flagDivider,
  ...dialogProps
}) => {
  const handleBackdropClick: React.MouseEventHandler<HTMLElement> = (event) => {
    // Prevent closing when clicking on the backdrop
    if (event.target === event.currentTarget) {
      return;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }

    // Remove the event listener when the component is unmounted or the dialog is closed
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleClose]);

  return (
    <FocusTrap open={true} disableEnforceFocus={true}>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        {...dialogProps}
        disableEscapeKeyDown
        borderRadius={borderRadius}
        titleAlignment={titleAlignment}
        disableEnforceFocus={true}
      >
        {flagBackdrop &&
          <Backdrop
            open={open}
            onClick={handleBackdropClick}
            sx={{ opacity: "-1 !important" }}
          >
            {/* Backdrop content here */}
          </Backdrop>}
        <BootstrapDialogTitle
          open={open}
          title={title}
          flagShowCloseIcon={flagShowCloseIcon}
          handleClose={handleClose}
          titleAlignment={titleAlignment}
          disableEscapeKeyDown={true}
          titlePadding={titlePadding}
        />
        {flagDivider &&
          <Grid item sx={{ maxHeight: 'fit-content !important', margin: '0px auto', padding: '0px 30px', width: '100%' }}>
            <Divider style={{ width: '100%' }} />
          </Grid>
        }
        {children}

      </BootstrapDialog>
    </FocusTrap>
  );
};

export default GlobalPopUp;
