import instagramIcon from "../assets/images/channels/instagram/instagram.png";
import fbMessengerIcon from "../assets/images/channels/facebook/facebookMessenger.svg";
import slackIcon from "../assets/images/channels/slack/slack (3).svg";
export const getIntegrationIcon = (integrationType) => {
    if (integrationType) {
        let integration = integrationType.toLowerCase()
        switch (integration) {
            case 'instagram':
                return instagramIcon
            case 'facebook':
                return fbMessengerIcon
            case 'slack':
                return slackIcon
            default:
                return "";
        }
    }
}