import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { useSearchParams } from 'react-router-dom';
import MenuCarousel from './menuCarousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import { convertKeysToLowerCase } from '../../../../utils/constants';
import GlobalPopUp from '../../../utility/globalPopUp';
import agent from '../../../../services/api';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import styles from '../../../../assets/styles/componentStyles/carouselCard.module.scss'
import VariantMenuCarousel from './variantMenuCarousel';

const CartCarouselGlobal = (props: any) => {

    let data = { ...props.data }
    data.index = 0
    const startXRef = useRef(0);
    const startYRef = useRef(0);
    const [flagShowImage, setFlagShowImage] = useState(false)
    const [currentCardData, setCurrentCardData] = useState<any | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [productsData, setProductsData] = useState(null);
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const { setFlagLoading, flagLoading } = useContext(UserContext) as UserContextPayload

    const GetProductData = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const response = await agent.getProductListData.get(chatBotDetails?.chatbot_id.toString() || "")
        if (response.status) {
            setProductsData(response.data)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }
        else {
            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }
    }

    useEffect(() => {
        if (!productsData) {
            GetProductData()
        }
    }, [productsData])

    function getActionButtonIcon(actionTypeId: any) {
        switch (actionTypeId) {
            case 14.2:
                return <AddShoppingCartOutlinedIcon style={{ fontSize: '19px', color: '#FFFFFF' }} />
            default:
                return <ReplyIcon style={{ fontSize: '19px', color: '#2E79BD' }} />
        }
    }

    function SampleNextArrow(props: any) {
        const { className, style, onClick, setCartDataLength } = props;
        return (
            data.message_format_id != 6.3 && data.message_format_id != 6.2 && <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "1",
                    width: "30px",
                    height: "30px",
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.chatbot_header_color : '#F7E9FF',
                    borderRadius: '50%',
                }}
                onClick={onClick}
            />
        );
    }
    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            data.message_format_id != 6.3 && data.message_format_id != 6.2 && <div
                className={className}
                style={{
                    ...style,
                    zIndex: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px",
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.chatbot_header_color : '#F7E9FF',
                    borderRadius: '50%',
                }}

                onClick={onClick}
            />
        );
    }

    const [searchParams] = useSearchParams()
    const [modalData, setModalData] = useState("")
    const [flagModal, setFlagModal] = useState(false)

    const isScreenBelow1000 = useMediaQuery('(max-width : 1000px) and (min-width: 0px)') || searchParams.get('screensize')

    function handleActionButton(action: any) {
        let actionTypeId = action.message_format_id
        switch (actionTypeId) {
            case 4: showModal(action.message_fields)
                return
            case 3:
                return window.open(action.message_fields.url, "_blank")
            default:
                return
        }
    }
    function showModal(data: any) {
        setModalData(data.url)
        setFlagModal(true)
    }


    const handleMouseDown = (event: any) => {
        startXRef.current = event.clientX;
        startYRef.current = event.clientY;
        setCurrentCardData(null)
    };


    const handleMouseUp = (event: any, image: any, flagVariant = false) => {
        const endX = event.clientX;
        const endY = event.clientY;

        const deltaX = Math.abs(endX - startXRef.current);
        const deltaY = Math.abs(endY - startYRef.current);

        if (deltaX < 5 && deltaY < 5) {
            setFlagShowImage(true);
            if (flagVariant) {
                setModalData(image?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value);
            }
            else {
                setModalData(image.image_url);
            }
            setFlagModal(true);
            setCurrentCardData(image);
        }
    };

    const handleNavigation = (direction: string) => {
        if (direction === "left") {
            if (data.message_format_id == 10.2) {
                setCurrentIndex((prevIndex) =>
                    prevIndex === 0 ? currentCardData.image_url.length - 1 : prevIndex - 1
                );
            }
            else {
                setCurrentIndex((prevIndex) =>
                    prevIndex === 0 ? currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value?.length - 1 : prevIndex - 1
                );
            }
        } else if (direction === "right") {
            if (data.message_format_id == 10.2) {
                setCurrentIndex((prevIndex) =>
                    prevIndex === currentCardData.image_url.length - 1 ? 0 : prevIndex + 1
                );
            }
            else {
                setCurrentIndex((prevIndex) =>
                    prevIndex === currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value?.length - 1 ? 0 : prevIndex + 1
                );
            }
        }
    };

    const renderCard = (card: any, index: number) => (
        <Grid item className={data.message_format_id == 10.2 ? styles.chat_crl_main_item : styles.chat_product_main_item} key={index}>
            {data.message_format_id == 10.2 &&
                <MenuCarousel
                    formatData={data}
                    handleMouseDown={handleMouseDown}
                    handleMouseUp={handleMouseUp}
                    image={card}
                    setCurrentCardData={setCurrentCardData}
                    getActionButtonIcon={getActionButtonIcon}
                    thread_id={props.threadId}
                    productsData={productsData}
                    chatbotTheme={props.chatbotTheme}
                    thread={props.thread}
                />
            }
            {data.message_format_id == 10.3 &&
                <VariantMenuCarousel
                    formatData={data}
                    handleMouseDown={handleMouseDown}
                    handleMouseUp={handleMouseUp}
                    image={card}
                    setCurrentCardData={setCurrentCardData}
                    getActionButtonIcon={getActionButtonIcon}
                    thread_id={props.threadId}
                    productsData={productsData}
                    thread={props.thread}
                    chatBotDetail={chatBotDetails}
                />
            }
        </Grid>
    );
    const sliderRef = useRef(null);

    return (
        <>
            {flagModal &&
                <GlobalPopUp
                    open={flagModal}
                    handleClose={() => { setFlagModal(false); setModalData(""); setFlagShowImage(false); setCurrentIndex(0) }}
                    flagShowCloseIcon
                    paperMinWidth={flagShowImage ? "" : "100%"}
                    paperMaxwidth={flagShowImage ? "100%" : "95%"}
                    height={flagShowImage ? "50%" : "70%"}
                    titlePadding={"0px"}
                    borderRadius={"10px"}
                >
                    <div style={{ position: "relative", width: "100%", height: "100%", borderRadius: "5px" }}>
                        <button
                            onClick={() => handleNavigation("left")}
                            style={{
                                position: "absolute",
                                left: "-5px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "24px",
                                transition: "transform 0.5s ease"
                            }}
                        >
                            <ChevronLeftIcon sx={{ color: "#FFFFFF" }} />
                        </button>

                        <img
                            alt=""
                            src={data.message_format_id == 10.2 ? currentCardData?.image_url[currentIndex] : Array.isArray(currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")) ? currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value[currentIndex] : currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value}
                            style={{
                                width: "100%",
                                height: "100%",
                                transition: "opacity 0.8s ease-in-out",
                                display: "block",
                            }}
                        />

                        <button
                            onClick={() => handleNavigation("right")}
                            style={{
                                position: "absolute",
                                right: "0px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "24px",
                            }}
                        >
                            <ChevronRightIcon sx={{ color: "#FFFFFF" }} />
                        </button>
                    </div>
                </GlobalPopUp>
            }

            <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
                <Slider
                    swipe={isScreenBelow1000 ? true : false}
                    arrows={true}
                    dots={false}
                    infinite={false}
                    autoplay={false}
                    variableWidth={true}
                    className={styles.chatbot_click_slider}
                    slidesToShow={1}
                    slidesToScroll={1}
                    nextArrow={<SampleNextArrow className={styles.chat_slider_next_arrow} />}
                    prevArrow={<SamplePrevArrow className={styles.chat_slider_prev_arrow} />}
                >
                    {convertKeysToLowerCase(data).message_fields.cards.map((card: any, index: number) => renderCard(card, index))}
                </Slider>
            </Grid>
        </>
    )
}

export default CartCarouselGlobal