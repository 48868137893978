import { Grid, useMediaQuery } from '@mui/material';
import React from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { PieChart } from '@mui/x-charts';


const CallAnalysisPieChart = (props: any) => {
    const {fieldName, fieldData} = props;

    const categories = fieldName === "Complexity" ? fieldData.map((item: any) => item.call_complexity_level) : fieldName === "Categories" ? fieldData.map((item: any) => item.call_category_name) : fieldData.map((item: any) => item.agent_name);
    const scores = fieldData.map((item: any) => item.number_of_calls);

    const pieData = categories.map((category: any, index: number) => ({
        id: index,
        label: category,
        value: scores[index],
    }));

    // const complexityColor = ['#EAC5FF', '#81B2EC', '#A1E3CB', '#A8E0FF'];
    const complexityColor = ['#FFB762', '#F7B7A3', '#EA5F89', '#6A097D'];
    const categoriesColor = ['#FFB762', '#EA5F89', '#F7B7A3', '#6A097D','#9B3192', '#57167E', '#2B0B3F', '#BD6D86', '#F3E0DA'];
    // const categoriesColor = ['#EAC5FF', '#81B2EC', '#A1E3CB', '#A8E0FF','#BAB3E2', '#CBD5E8', '#CCCCCC', '#F1E2CC', '#FFF2AE'];

    const pieParams = {
        slotProps: { legend: { hidden: true } }
    }

    const smallScreen = useMediaQuery('(max-width : 500px) and (min-width : 0px)');
    const tabletScreen = useMediaQuery('(max-width : 750px) and (min-width : 600px)');
    const laptopScreen = useMediaQuery('(max-width : 1450px) and (min-width : 1200px)');
    
    return (
        <Grid container direction={"row"} alignItems={"center"} className={dashboardStyles.pieChartContainer} flexWrap={"nowrap"} gap={2}>
            <Grid item xs={5.5} sm={6} sx={{width: "100%"}} className={dashboardStyles.pieContainer}>
                <Grid container direction={"column"} gap={"10px"}>
                    <Grid item>
                        <span className={dashboardStyles.pieChartHeadingName}>{`${fieldName} (# Calls)`}</span>
                    </Grid>
                    <Grid item>
                        <PieChart
                            className={dashboardStyles.pieChartsStyle}
                            height={smallScreen || tabletScreen || laptopScreen ? 300 : 200}
                            colors={fieldName === "Complexity" ? complexityColor : categoriesColor}
                            series={[
                                {
                                    data: pieData,
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 2.5,
                                    cornerRadius: 5,
                                    startAngle: 0,
                                    endAngle: 360,
                                    highlightScope: { fade: 'global', highlight: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    cx: smallScreen || tabletScreen || laptopScreen ? 40 : 100,
                                    // cy: 150,
                                }
                            ]}
                            {...pieParams}
                            />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6.5} sm={6} sx={{width: "100%", maxHeight: "230px", overflow: "auto"}} className={dashboardStyles.pieLabelContainer}>
                <Grid container direction={"column"} gap={"4px"}>
                    {categories.map((category: any, index: number) =>
                        <Grid item>
                            <Grid container direction={"row"} gap={2} alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item className={dashboardStyles.labelCircle} style={{backgroundColor: fieldName === "Complexity" ? complexityColor[index] : categoriesColor[index]}}></Grid>
                                <Grid item>
                                    <span className={dashboardStyles.labelHeading}>{category}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CallAnalysisPieChart
