import { Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import spreadsheetIcon from './../../../assets/images/leadGeneration/spreadsheet.svg'
import slackIcon from './../../../assets/images/leadGeneration/slack.svg'
import mailIcon from './../../../assets/images/leadGeneration/mail.svg'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationDataStyle.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';
import GlobalButton from '../../utility/globalButton/button';
import EditIcon from '@mui/icons-material/Edit';
import IconsColor from '../../utility/iconsColor';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import GlobalPopUp from '../../utility/globalPopUp';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';

interface configIconInterface {
    integrationName: string,
    handleSave: (flagUpdate?: boolean) => Promise<any>,
    isDisable: () => boolean,
    convertSimplifiedToOriginalFormat: (simplifiedString: string) => string,
    setCustomTemplatePromptActionData: React.Dispatch<React.SetStateAction<String>>,
    newIntegration?: () => Promise<void>
}

const ConfigIconSection = (props: configIconInterface) => {
    const {setStepContainer, leadGenerationList, setEditIntegrationData, editIntegrationData, tempEmailConfigPayload, setEmailConfigPayload, setAllowedFieldsData,
        setSlackConfigPayload, tempSlackConfigPayload, setAllowedFieldsSlackData, setChannelIntegrationData, setDeleteIntegrationPayload, deleteIntegrationPayload, 
        GetLeadGenerationList, setSelectedTabField} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const {integrationName, handleSave, isDisable, convertSimplifiedToOriginalFormat, setCustomTemplatePromptActionData} = props;
    const { slackIntegrations, setSlackIntegrations } = useContext(IntegrationContext) as IntegrationContextPayload;
    const {setFlagLoading, flagLoading, error, setError, setSuccess, success} = useContext(UserContext) as UserContextPayload;
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');
    const lead_id = searchParams.get('lead_id');
    const navigate = useNavigate();
    const [flagDeleteIntegration, setFlagDeleteIntegration] = useState(false);
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const checkIcon = (name: string) => {
        switch(name) {
            case "Email":
                return mailIcon
            case "Spreadsheet":
                return spreadsheetIcon
            case "Slack":
                return slackIcon
            default:
                return slackIcon
        }
    }

    const checkName = (name: string) => {
        switch(name) {
            case "Email":
                return "Email Integration"
            case "Spreadsheet":
                return "Spreadsheet Integration"
            case "Slack":
                return "Slack Integration"
            default:
                return "Email Integration"
        }
    }

    const handleClickBack = () => {
        setStepContainer(1)
        navigate(`/chatbot/viewchatbot/leadgeneration/createleadgeneration?chatbot_id=${chatbot_id}&lead_id=${lead_id}`)
    }

    useEffect(() => {
        if (window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/slack" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet") {
            setEditIntegrationData(true)
        }
        else {
            setEditIntegrationData(false)
        }
    }, [window.location.pathname])

    const handleEditIntegrationData = () => {
        setEditIntegrationData(true)
    }

    const handleCancelEditIntegration = () => {
        setEditIntegrationData(false)
        if(integrationName === "Email") {
            const integrationData = leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data;
            const emailIntegration = integrationData?.find(data => data.chatbot_lead_integration_type_name === "Email");
            setEmailConfigPayload(tempEmailConfigPayload)
            setAllowedFieldsData(emailIntegration?.allowed_field_data ? emailIntegration?.allowed_field_data : leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data ? leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data : null);
            setCustomTemplatePromptActionData(convertSimplifiedToOriginalFormat(tempEmailConfigPayload.email_content))
        }
        else if(integrationName === "Slack") {
            const integrationData = leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data;
            const slackIntegration = integrationData?.find(data => data.chatbot_lead_integration_type_name === "Slack");
            setSlackConfigPayload(tempSlackConfigPayload)
            setAllowedFieldsSlackData(slackIntegration?.allowed_field_data ? slackIntegration?.allowed_field_data : leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data ? leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data : null);
            setCustomTemplatePromptActionData(convertSimplifiedToOriginalFormat(tempSlackConfigPayload.slack_content))
            setChannelIntegrationData(slackIntegrations?.find((data) => data.slack_integration_id === tempSlackConfigPayload.slack_integration_id) || null)
        }
    }

    const handleSetDeleteIntegrationData = () => {
        const payload = {
            chatbot_id: leadGenerationList?.chatbot_id.toString() || "",
            chatbot_lead_id: leadGenerationList?.chatbot_lead_data[0].chatbot_lead_id.toString() || "",
            chatbot_lead_integration_id: leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.find((data) => data.chatbot_lead_integration_type_name === integrationName)?.chatbot_lead_integration_id.toString() || ""
        }
        setFlagDeleteIntegration(true);
        setDeleteIntegrationPayload(payload)
    }

    const handleDeleteIntegration = async () => {
        setFlagLoading({...flagLoading, flagRoundLoader: true});
        const response = await agent.deleteLeadIntegration.delete(deleteIntegrationPayload?.chatbot_id || "", deleteIntegrationPayload?.chatbot_lead_id || "", deleteIntegrationPayload?.chatbot_lead_integration_id || "")
        if(response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Lead Integration Deleted Successfully." })
            // navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`)
            GetLeadGenerationList(chatbot_id || "", lead_id || "")
            setSelectedTabField("Lead Details")
            setFlagLoading({...flagLoading, flagRoundLoader: false})
        }
        else {
            setError({
                ...error,
                flagShowError: true,
                message: response.error.message
            })
            setFlagLoading({...flagLoading, flagRoundLoader: false})
        }
    }
    
    return (
        <>
        <Grid container gap={"10px"} alignItems={"center"} mb={1} justifyContent={"space-between"}>
            <Grid item>
                <Grid container gap={"10px"} alignItems={"center"}>
                    {(window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/slack" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet") && 
                        <Grid item>
                            <KeyboardBackspaceIcon style={{fontSize: "24px", fontWeight: "600", color: "#6A097D", display: "flex", cursor: "pointer"}} onClick={() => handleClickBack()}/>
                        </Grid>
                    }
                    <Grid item>
                        <img src={checkIcon(integrationName)} style={{display: "flex"}}/>
                    </Grid>
                    <Grid item>
                        <span className={styles.configTextStyle}>{checkName(integrationName)}</span>
                    </Grid>
                </Grid>
            </Grid>

            {window.location.pathname === "/chatbot/viewchatbot/leadgeneration/slack" &&
                <Grid item>
                    <GlobalButton buttonText={"New Integration"} onClick={() => {if(props.newIntegration) {props.newIntegration()}}} className='primaryButtonStyle' sx={{ width: "170px" }} startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />} />
                </Grid>
            }

            {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.length > 0 && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.find((data) => data.chatbot_lead_integration_type_name === integrationName) &&
                <Grid item>
                    {!editIntegrationData && !flagSmallScreen &&
                        <Grid container gap={2}>
                            <Grid item>
                                <GlobalButton buttonText={"Delete"} onClick={() => handleSetDeleteIntegrationData()} className='borderedButtonStyle' sx={{ minWidth: "110px" }} endIcon={<IconsColor iconName="DeleteIcon" width="18" height="18" fillColor="#6A097D" />}/>
                            </Grid>
                            <Grid item>
                                <GlobalButton buttonText={"Edit"} onClick={() => handleEditIntegrationData()} className='primaryButtonStyle' sx={{ minWidth: "110px" }} endIcon={<EditIcon sx={{ color: '#FFFFFF' }} />}/>
                            </Grid>
                        </Grid>
                    }
                    {!editIntegrationData && flagSmallScreen &&
                        <Grid container gap={2} alignItems={"center"} mt={"3px"}>
                            <Grid item>
                                <span onClick={() => handleSetDeleteIntegrationData()}><IconsColor iconName="DeleteIcon" width="16" height="16" fillColor="#6A097D" /></span>
                            </Grid>
                            <Grid item>
                                <span onClick={() => handleEditIntegrationData()}><EditIcon sx={{ color: '#6A097D', fontSize: "20px" }} /></span>
                            </Grid>
                        </Grid>
                    }
                    {editIntegrationData &&
                        <Grid container gap={2} className={styles.editDeleteButtonStyle}>
                            <Grid item>
                                {/* <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelLeadGeneration()}/> */}
                                <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "110px" }} onClick={() => handleCancelEditIntegration()}/>
                            </Grid>
                            <Grid item>
                                <GlobalButton
                                    // buttonText={flagUpdate ? "Update" : "Save"}
                                    buttonText={"Save"}
                                    className='primaryButtonStyle' 
                                    sx={{ minWidth: "110px" }} 
                                    onClick={() => handleSave(true)}
                                    disabled={isDisable()}
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            }

        </Grid>
        {flagDeleteIntegration &&
            <GlobalPopUp
                open={flagDeleteIntegration}
                handleClose={() => { setFlagDeleteIntegration(false); setDeleteIntegrationPayload(null) }}
            >
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete ${integrationName} Integration`}
                    onClose={() => { setFlagDeleteIntegration(false); setDeleteIntegrationPayload(null) }}
                    onDelete={() => handleDeleteIntegration()}
                    sx={{ maxWidth: "400px" }}
                />
            </GlobalPopUp>
        }
        </>
    )
}

export default ConfigIconSection
