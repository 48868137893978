import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, createCampaignContactFieldPayload, createCampaignContactListPayload, createCampaignContactTagPayload, deleteContactPayload, getCampaignContactListResponse, getCampaignContactPayload, getCampaignContactResponse, getListOfCampaignContactFieldResponse, getListOfCampaignContactFieldTypeResponse, getListOfCampaignContactTagResopnse, importCampaignContactDataPayload, updateCampaignContactFieldPyload, updateCampaignContactListPayload, updateCampaignContactPayload, updateCampaignContactTagPayload } from "../models/axiosModel";
import agent from "../services/api";
import { defaultCampaignContactTagData } from "../components/dashboard/contacts/campaignContact";


const ContactContext = React.createContext<ContactContextPayload | undefined>(undefined);

export interface ContactContextPayload {
    getCampaignContactListApi: () => Promise<CommonResponseModel<getCampaignContactListResponse>>,
    createCampaignContactListApi: (payload: createCampaignContactListPayload) => Promise<CommonResponseModel<getCampaignContactListResponse>>,
    updateCampaignContactListApi: (payload: updateCampaignContactListPayload) => Promise<CommonResponseModel<getCampaignContactListResponse>>,
    getCampaignContactApi: (payload: getCampaignContactPayload) => Promise<CommonResponseModel<getCampaignContactResponse>>,
    getFilteredContactApi: (payload: getCampaignContactPayload) => Promise<CommonResponseModel<getCampaignContactResponse>>,
    updateCampaignContactApi: (payload: updateCampaignContactPayload) => Promise<CommonResponseModel<getCampaignContactResponse>>,
    deleteCampaignContactApi: (payload: deleteContactPayload) => Promise<any>,
    importCampaignContactDataApi: (payload: importCampaignContactDataPayload) => Promise<any>,
    getListOfCampaignContactFieldApi: (contact_list_id: number) => Promise<CommonResponseModel<getListOfCampaignContactFieldResponse>>,
    createCampaignContactFieldApi: (payload: createCampaignContactFieldPayload) => Promise<any>,
    updateCampaignContactFieldApi: (payload: updateCampaignContactFieldPyload) => Promise<any>,
    deleteCampaignContactFieldApi: (contact_field_id: number) => Promise<any>,
    getListOfCampaignFieldTypeApi: () => Promise<any>,
    getListOfCampaignContactTagApi: (contact_list_id: number) => Promise<any>,
    createCampaignContactTagApi: (payload: createCampaignContactTagPayload) => Promise<any>,
    updateCampaignContactTagApi: (payload: updateCampaignContactTagPayload) => Promise<any>,
    deleteCampaignContactTagApi: (contact_tag_id: number) => Promise<any>,

    campaignContactListData: getCampaignContactListResponse[] | null,
    setCampaignContactListData: React.Dispatch<React.SetStateAction<getCampaignContactListResponse[] | null>>,
    campaignContactData: getCampaignContactResponse[] | null,
    setCampaignContactData: React.Dispatch<React.SetStateAction<getCampaignContactResponse[] | null>>,
    filteredCampaignContactData: getCampaignContactResponse[] | null,
    setFilteredCampaignContactData: React.Dispatch<React.SetStateAction<getCampaignContactResponse[] | null>>,
    campaignContactFieldData: getListOfCampaignContactFieldResponse[],
    setCampaignContactFieldData: React.Dispatch<React.SetStateAction<getListOfCampaignContactFieldResponse[]>>,
    campaignContactFieldTypeData: getListOfCampaignContactFieldTypeResponse[],
    setCampaignContactFieldTypeData: React.Dispatch<React.SetStateAction<getListOfCampaignContactFieldTypeResponse[]>>,
    campaignContactTagData: getListOfCampaignContactTagResopnse[],
    setCampaignContactTagData: React.Dispatch<React.SetStateAction<getListOfCampaignContactTagResopnse[]>>,

    flagAddNewListDialog: boolean,
    setFlagAddNewListDialog: React.Dispatch<React.SetStateAction<boolean>>,
    flagCreateNewContactDialog: boolean,
    setFlagCreateNewContactDialog: React.Dispatch<React.SetStateAction<boolean>>,
    flagNewFieldDialog: boolean,
    setFlagNewFieldDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

const ContactProvider = ({ children }: ChildrenProps) => {
    const [campaignContactListData, setCampaignContactListData] = useState<getCampaignContactListResponse[] | null>(null);
    const [campaignContactData, setCampaignContactData] = useState<getCampaignContactResponse[] | null>(null);
    const [filteredCampaignContactData, setFilteredCampaignContactData] = useState<getCampaignContactResponse[] | null>(null);

    const [campaignContactFieldData, setCampaignContactFieldData] = useState<getListOfCampaignContactFieldResponse[]>([]);
    const [campaignContactFieldTypeData, setCampaignContactFieldTypeData] = useState<getListOfCampaignContactFieldTypeResponse[]>([]);

    const [campaignContactTagData, setCampaignContactTagData] = useState<getListOfCampaignContactTagResopnse[]>([]);

    // Flags
    const [flagAddNewListDialog, setFlagAddNewListDialog] = useState<boolean>(false);
    const [flagCreateNewContactDialog, setFlagCreateNewContactDialog] = useState<boolean>(false);
    const [flagNewFieldDialog, setFlagNewFieldDialog] = useState<boolean>(false);


    // Campaign Contact List API's
    const getCampaignContactListApi = async (): Promise<CommonResponseModel<getCampaignContactListResponse>> => {
        const response = await agent.getCampaignContactList.get();
        if (response.status) {
            setCampaignContactListData(response.data);
        } else {
            // setCampaignContactListData([]);
            setCampaignContactListData(null);
        }
        return response;
    }

    const createCampaignContactListApi = async (payload: createCampaignContactListPayload): Promise<CommonResponseModel<getCampaignContactListResponse>> => {
        const response = await agent.createCampaignContactList.post(payload);
        return response;
    }

    const updateCampaignContactListApi = async (payload: updateCampaignContactListPayload): Promise<CommonResponseModel<getCampaignContactListResponse>> => {
        const response = await agent.updateCampaignContactList.put(payload);
        return response;
    }

    // Campaign Contact API's
    const getCampaignContactApi = async (payload: getCampaignContactPayload): Promise<CommonResponseModel<getCampaignContactResponse>> => {
        const response = await agent.getCampaignContact.post(payload);
        if (response.status) {
            if (response.data && response.data[0].contact.length != 0) {
                setCampaignContactData(response.data);
                setFilteredCampaignContactData(response.data);
            } else {
                setCampaignContactData([]);
                setFilteredCampaignContactData([]);
            }
        } else {
            // setCampaignContactData([]); 
            setCampaignContactData(null);
            // setFilteredCampaignContactData([]);
            setFilteredCampaignContactData(null);
        }
        return response;
    }

    const getFilteredContactApi = async (payload: getCampaignContactPayload): Promise<CommonResponseModel<getCampaignContactResponse>> => {
        const response = await agent.getCampaignContact.post(payload);
        if (response.status) {
            if (response.data && response.data[0].contact.length != 0) {
                setFilteredCampaignContactData(response.data);
            }
        } else {
            setFilteredCampaignContactData(null);

        }
        return response;
    }

    const updateCampaignContactApi = async (payload: updateCampaignContactPayload): Promise<CommonResponseModel<getCampaignContactResponse>> => {
        const response = await agent.updateCampaignContact.put(payload);
        return response;
    }

    const deleteCampaignContactApi = async (payload: deleteContactPayload): Promise<any> => {
        const response = await agent.deleteCampaignContact.put(payload);
        return response;
    }

    const importCampaignContactDataApi = async (payload: importCampaignContactDataPayload) => {
        const response = await agent.importCampaignContactData.post(payload);
        return response;
    }

    // Campaign Contact Field API's
    const getListOfCampaignContactFieldApi = async (contact_list_id: number): Promise<CommonResponseModel<getListOfCampaignContactFieldResponse>> => {
        const response = await agent.getListOfCampaignContactField.get(contact_list_id);
        if (response.status) {
            setCampaignContactFieldData(response.data);
        } else {
            setCampaignContactFieldData([]);
        }
        return response;
    }

    const createCampaignContactFieldApi = async (payload: createCampaignContactFieldPayload) => {
        const response = await agent.createCampaignContactField.post(payload);
        return response;
    }

    const updateCampaignContactFieldApi = async (payload: updateCampaignContactFieldPyload) => {
        const response = await agent.updateCampaignContactField.put(payload);
        return response;
    }

    const deleteCampaignContactFieldApi = async (contact_field_id: number) => {
        const response = await agent.deleteCampaignContactField.delete(contact_field_id);
        return response;
    }

    // Campaign Field Type 

    const getListOfCampaignFieldTypeApi = async () => {
        const response = await agent.getListOfCampaignFieldType.get();
        if (response.status) {
            setCampaignContactFieldTypeData(response.data);
        } else {
            setCampaignContactFieldTypeData([]);
        }
        return response;
    }

    // Campaign Contact Tag API's

    const getListOfCampaignContactTagApi = async (contact_list_id: number) => {
        const response = await agent.getListOfCampaignContactTag.get(contact_list_id);
        if (response.status) {
            setCampaignContactTagData(response.data);
        } else {
            setCampaignContactTagData([]);
        }
        return response;
    }

    const createCampaignContactTagApi = async (payload: createCampaignContactTagPayload) => {
        const response = await agent.createCampaignContactTag.post(payload);
        return response;
    }

    const updateCampaignContactTagApi = async (payload: updateCampaignContactTagPayload) => {
        const response = await agent.updateCampaignContactTag.put(payload);
        return response;
    }

    const deleteCampaignContactTagApi = async (contact_tag_id: number) => {
        const response = await agent.deleteCampaignContactTag.delete(contact_tag_id);
        return response;
    }

    const payload = {
        getCampaignContactListApi,
        createCampaignContactListApi,
        updateCampaignContactListApi,
        getCampaignContactApi,
        getFilteredContactApi,
        updateCampaignContactApi,
        deleteCampaignContactApi,
        importCampaignContactDataApi,
        getListOfCampaignContactFieldApi,
        createCampaignContactFieldApi,
        updateCampaignContactFieldApi,
        deleteCampaignContactFieldApi,
        getListOfCampaignFieldTypeApi,
        getListOfCampaignContactTagApi,
        createCampaignContactTagApi,
        updateCampaignContactTagApi,
        deleteCampaignContactTagApi,

        campaignContactListData,
        setCampaignContactListData,
        campaignContactData,
        setCampaignContactData,
        filteredCampaignContactData,
        setFilteredCampaignContactData,
        campaignContactFieldData,
        setCampaignContactFieldData,
        campaignContactFieldTypeData,
        setCampaignContactFieldTypeData,
        campaignContactTagData,
        setCampaignContactTagData,

        flagAddNewListDialog,
        setFlagAddNewListDialog,
        flagCreateNewContactDialog,
        setFlagCreateNewContactDialog,
        flagNewFieldDialog,
        setFlagNewFieldDialog,
    };

    return (
        <ContactContext.Provider value={payload}>
            {children}
        </ContactContext.Provider>
    );
}

export { ContactContext, ContactProvider }