import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react'
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/extensionDrawer.module.scss'

const ExtensionTable = (props: any) => {
    const {subEntries, chatbotTheme} = props;

    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", marginTop: "5px"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableBody className={styles.extension_table_body_style}>
                    {subEntries?.map((subEntry: any) => 
                        <TableRow className={styles.extension_table_row_style}>
                            <TableCell sx={{whiteSpace: "nowrap", padding: "12px !important"}}>
                                {subEntry.extensions.length > 1 ?
                                    subEntry.extensions.map((ext: any, index: number) => 
                                        <span className={styles.extension_number_text_style}
                                            style={{
                                                color: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6A097D",
                                            }}
                                        >
                                            {index == subEntry.extensions.length - 1 ? `${ext}` : `${ext}, `}
                                        </span>
                                    )
                                    :
                                    <span className={styles.extension_number_text_style}
                                        style={{
                                            color: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6A097D",
                                        }}
                                    >
                                        {subEntry.extensions[0]}
                                    </span>
                                }
                            </TableCell>

                            <TableCell sx={{padding: "12px !important"}}>
                                <span className={styles.extension_name_text_style}>
                                    {subEntry.description}
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ExtensionTable