import { Avatar, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import IconsColor from '../../utility/iconsColor'
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from './../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignAnalysisStyle.module.scss'
import circularStyle from './../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignStyle.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import CampaignTemplatePreview from './campaignTemplatePreview';
import CampaignAnalysisPieChart from './campaignAnalysisPieChart';
import GlobalButton from '../../utility/globalButton/button';
import CampaignAnalysisContacts from './campaignAnalysisContacts';
import { campaignContactAssignmentData } from '../../../models/axiosModel';
import GlobalPopUp from '../../utility/globalPopUp';
import CampaignContactStatusTrack from './campaignContactStatusTrack';

interface campaignAnalysisInterface {
    getCampaignDataApiCall: (campaign_id?: number) => Promise<void>
}

const CampaignAnalysis = (props: campaignAnalysisInterface) => {
    const { selectedWhatsAppCampaign, setSelectedWhatsAppCampaign, messageTemplateDetail, getWhatsappTemplateDetailsApiCall, getCampaignDataApi, campaignContactStatusPopup, setCampaignContactStatusPopup } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;
    const { flagLoading, setFlagLoading, userDetail } = useContext(UserContext) as UserContextPayload
    const navigate = useNavigate();
    const { getCampaignDataApiCall } = props
    const innerHeight = window.innerHeight - 130;
    const [searchParams, setSearchParams] = useSearchParams();
    const campaign_id = searchParams.get('campaign_id')
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)');

    useEffect(() => {
        if (selectedWhatsAppCampaign) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true })
                await getWhatsappTemplateDetailsApiCall(selectedWhatsAppCampaign.meta_integration_whatsapp_id, selectedWhatsAppCampaign.whatsapp_template_id)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false })
            })()
        }
    }, [selectedWhatsAppCampaign])

    // useEffect(() => {
    //     if (campaign_id) {
    //         (async () => {
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: true })
    //             await getCampaignDataApi(Number(campaign_id) || 0)
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    //         })()
    //     }
    // }, [campaign_id])

    const getExecutionColor = (executionStatus?: string, flagLightColor?: boolean) => {
        if (executionStatus) {
            let executionStatusName = executionStatus.toLowerCase();
            switch (executionStatusName) {
                case "draft":
                    return flagLightColor ? "rgba(42, 165, 226, 0.1)" : "rgba(42, 165, 226, 1)"
                case "completed":
                    return flagLightColor ? "rgba(15, 185, 21, 0.1)" : "rgba(15, 185, 21, 1)"
                default:
                    return flagLightColor ? "rgba(226, 162, 42, 0.1)" : "rgba(226, 162, 42, 1)"
            }
        }
    }

    const handleBackCurrentCampaign = async () => {
        setSelectedWhatsAppCampaign(null)
        navigate(`${window.location.pathname}`)
    }

    
    const roundOffData = (numData: number, denData: number, flagCard?: boolean) => {
        const percentage = (numData / denData) * 100;
        const formattedPercentage = percentage % 1 === 0 ? `${percentage}%` : flagCard ? `${percentage.toFixed(1)}%` : `${percentage.toFixed(2)}%`;

        return formattedPercentage;
    }

    const AnalysisCardData = [
        {
            cardTitle: "Total Contact",
            cardValue: selectedWhatsAppCampaign?.total_campaign_contact,
            boxShadow: "0px 0px 2px rgba(106, 9, 125, 1)",
            progressValue: null
        },
        {
            cardTitle: "Sent",
            cardValue: selectedWhatsAppCampaign?.total_sent_campaign_contact,
            boxShadow: "0px 0px 2px rgba(208, 89, 4, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Pending",
            cardValue: selectedWhatsAppCampaign?.total_pending_campaign_contact,
            boxShadow: "0px 0px 2px rgba(205, 188, 0, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Failed",
            cardValue: selectedWhatsAppCampaign?.total_failed_campaign_contact,
            boxShadow: "0px 0px 2px rgba(223, 0, 0, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Delivered",
            cardValue: selectedWhatsAppCampaign?.total_delivered_campaign_contact || 0,
            boxShadow: "0px 0px 2px rgba(128, 128, 128, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Read",
            cardValue: selectedWhatsAppCampaign?.total_read_campaign_contact || 0,
            boxShadow: "0px 0px 2px rgba(79, 182, 236, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Unread",
            cardValue: (selectedWhatsAppCampaign?.total_delivered_campaign_contact - selectedWhatsAppCampaign?.total_read_campaign_contact) || 0,
            boxShadow: "0px 0px 2px rgba(19, 79, 92, 1)",
            progressValue: ""
        },
        {
            cardTitle: "Clicked",
            cardValue: 0,
            boxShadow: "0px 0px 2px rgba(120, 0, 16, 1)",
            progressValue: ""
        },
    ]

    const campaignDetailsData = [
        {
            detailsKey: "Campaign Name",
            detailsValue: selectedWhatsAppCampaign?.campaign_title
        },
        {
            detailsKey: "Created At",
            detailsValue: formatDateAccordingToTimezoneUsingMoment(selectedWhatsAppCampaign?.created_at_timestamp, userDetail.timezone_hour)
        },
        {
            detailsKey: "Updated At",
            detailsValue: selectedWhatsAppCampaign?.updated_at_timestamp ? formatDateAccordingToTimezoneUsingMoment(selectedWhatsAppCampaign?.updated_at_timestamp, userDetail.timezone_hour) : "-"
        }
    ]

    const templateDetailsData = [
        {
            templateDetailsKey: "Waba Id",
            templateDetailsValue: selectedWhatsAppCampaign?.waba_id
        },
        {
            templateDetailsKey: "Waba Name",
            templateDetailsValue: selectedWhatsAppCampaign?.waba_name
        },
        {
            templateDetailsKey: "Phone Number",
            templateDetailsValue: selectedWhatsAppCampaign?.display_phone_number
        },
        {
            templateDetailsKey: "Template Name",
            templateDetailsValue: selectedWhatsAppCampaign?.whatsapp_template_name
        },
        {
            templateDetailsKey: "Template",
            templateDetailsValue: messageTemplateDetail ? <CampaignTemplatePreview metaInformation={messageTemplateDetail} /> : <></>
        }
    ]

    const cardDetailsColor = (executionStatus?: string, flagLightColor?: boolean) => {
        if (executionStatus) {
            let executionStatusName = executionStatus.toLowerCase();
            switch (executionStatusName) {
                case "sent":
                    return flagLightColor ? "rgba(208, 89, 4, 0.1)" : "rgba(208, 89, 4, 1)"
                case "pending":
                    return flagLightColor ? "rgba(222, 204, 2, 0.1)" : "rgba(222, 204, 2, 1)"
                case "failed":
                    return flagLightColor ? "rgba(223, 0, 0, 0.1)" : "rgba(223, 0, 0, 1)"
                case "total contact":
                    return flagLightColor ? "rgba(106, 9, 125, 0.1)" : "rgba(106, 9, 125, 1)"
                case "read":
                    return flagLightColor ? "rgba(79, 182, 236, 0.1)" : "rgba(79, 182, 236, 1)"
                case "delivered":
                    return flagLightColor ? "rgba(128, 128, 128, 0.1)" : "rgba(128, 128, 128, 1)"
                case "unread":
                    return flagLightColor ? "rgba(19, 79, 92, 0.1)" : "rgba(19, 79, 92, 1)"
                case "clicked":
                    return flagLightColor ? "rgba(120, 0, 16, 0.1)" : "rgba(120, 0, 16, 1)"
                default:
                    return flagLightColor ? "rgba(0, 208, 62, 0.1)" : "rgba(0, 208, 62, 1)"
            }
        }
    }

    return (
        selectedWhatsAppCampaign &&
        <Grid container direction={"column"} flexWrap={"nowrap"} gap={2} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto", padding: "0px 5px" }}>
            <Grid item sx={{ width: "100%" }}>
                <Grid container gap={"12px"} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <Grid container gap={"12px"} alignItems={"center"}>
                            <Grid item sx={{ display: "flex" }} mt={"1px"}>
                                <KeyboardBackspaceIcon onClick={() => handleBackCurrentCampaign()} sx={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item>
                                <span className={styles.campaignNameStyles}>{selectedWhatsAppCampaign?.campaign_title}</span>
                            </Grid>
                            <Grid item mt={"4px"} className={styles.campaignStatusStyle} sx={{ boxShadow: `0px 0px 2px ${getExecutionColor(selectedWhatsAppCampaign?.campaign_execution_status_name)}`, backgroundColor: getExecutionColor(selectedWhatsAppCampaign?.campaign_execution_status_name, true) }}>
                                <span className={styles.campaignStatusTextStyle} style={{ color: getExecutionColor(selectedWhatsAppCampaign?.campaign_execution_status_name) }}>{selectedWhatsAppCampaign?.campaign_execution_status_name}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <GlobalButton className="primaryButtonStyle" buttonText={"View Campaign Details"} sx={{ width: "200px !important" }} onClick={() => { navigate(`/whatsappcampaign/createnewcampaign?campaign_id=${campaign_id}`) }} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sx={{ width: "100%" }}>
                <Grid container gap={2}>
                    <Grid item className={styles.cardComponentSectionStyle}>
                        <Grid container gap={2} alignItems={"center"}>
                            {AnalysisCardData.slice(0,4).map((cards) =>
                                <Grid item className={styles.commonCardStyles} sx={{ boxShadow: cards.boxShadow, ':hover': { backgroundColor: `${cardDetailsColor(cards.cardTitle, true)} !important` } }}>
                                    <Grid container direction={"column"} gap={"12px"}>
                                        <Grid item>
                                            <span className={styles.cardHeadingTextStyle}>{cards.cardTitle}</span>
                                        </Grid>
                                        <Grid item>
                                            <span className={styles.cardValueTextStyle}>{cards.cardValue}</span>
                                        </Grid>
                                        {cards.progressValue !== null &&
                                            <Grid item className={circularStyle.circularProgress}>
                                                <Avatar sx={{ bgcolor: "transparent !important", zIndex: 2, fontWeight: "600", fontSize: "10px", color: cardDetailsColor(cards.cardTitle), border: `3px solid ${cardDetailsColor(cards.cardTitle)}`, height: "32px", width: "32px" }}>
                                                    {roundOffData(cards.cardValue || 0,selectedWhatsAppCampaign?.total_campaign_contact || 0, true)}
                                                </Avatar>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item className={styles.cardComponentSectionStyle}>
                        <Grid container gap={2} alignItems={"center"}>
                            {AnalysisCardData.slice(4,8).map((cards) =>
                                <Grid item className={styles.commonCardStyles} sx={{ boxShadow: cards.boxShadow, ':hover': { backgroundColor: `${cardDetailsColor(cards.cardTitle, true)} !important` } }}>
                                    <Grid container direction={"column"} gap={"12px"}>
                                        <Grid item>
                                            <span className={styles.cardHeadingTextStyle}>{cards.cardTitle}</span>
                                        </Grid>
                                        <Grid item>
                                            <span className={styles.cardValueTextStyle}>{cards.cardValue}</span>
                                        </Grid>
                                        {cards.progressValue !== null &&
                                            <Grid item className={circularStyle.circularProgress}>
                                                <Avatar sx={{ bgcolor: "transparent !important", zIndex: 2, fontWeight: "600", fontSize: "10px", color: cardDetailsColor(cards.cardTitle), border: `3px solid ${cardDetailsColor(cards.cardTitle)}`, height: "32px", width: "32px" }}>
                                                    {roundOffData(cards.cardValue || 0,selectedWhatsAppCampaign?.total_campaign_contact || 0, true)}
                                                </Avatar>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>

            <Grid item sx={{ width: "100%" }} mt={1}>
                <Grid container gap={2} flexWrap={{ xs: "wrap", lg: "nowrap" }}>
                    <Grid item className={styles.campaginDetailsContainer} sx={{ width: "100%" }} paddingRight={{ lg: "16px" }}>
                        <Grid container direction={"column"} gap={2}>
                            <Grid item sx={{ width: "100%" }}>
                                <Grid container direction={"column"} gap={2}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <span className={styles.headingDetailsStyle}>Campaign Details</span>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <Grid container direction={"column"} className={styles.campaignDetailStyle}>
                                            {campaignDetailsData.map((details) =>
                                                <Grid item className={styles.detailsGridStyle} sx={{ width: "100%" }}>
                                                    <Grid container flexWrap={"nowrap"}>
                                                        <Grid item xs={5.5} className={styles.detailsKeyContainer} sx={{ width: "100%" }}>
                                                            <span className={styles.detailsKeyTextStyle}>{details.detailsKey}</span>
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.detailsValueContainer} sx={{ width: "100%" }}>
                                                            <span className={styles.detailsKeyTextStyle}>{details.detailsValue}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ width: "100%" }}>
                                <CampaignAnalysisPieChart fieldName={"Campaign Status"} fieldData={selectedWhatsAppCampaign} roundOffData={roundOffData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={2}>
                            <Grid item sx={{ width: "100%" }}>
                                <Grid container direction={"column"} gap={2}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <span className={styles.headingDetailsStyle}>Template Details</span>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <Grid container direction={"column"} className={styles.campaignDetailStyle}>
                                            {templateDetailsData.map((details, index) =>
                                                <Grid item className={styles.detailsGridStyle} sx={{ width: "100%" }}>
                                                    <Grid container flexWrap={"nowrap"} direction={{xs: index == templateDetailsData.length - 1 ? "column" : "row", md: "row"}}>
                                                        <Grid item xs={5.5} className={styles.detailsKeyContainer} sx={{ width: "100%" }}>
                                                            <span className={styles.detailsKeyTextStyle}>{details.templateDetailsKey}</span>
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.detailsValueContainer} sx={{ width: "100%" }}>
                                                            <span className={styles.detailsKeyTextStyle}>{details.templateDetailsValue}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sx={{ width: "100%" }} mt={1}>
                <Grid container gap={2} xs={12} lg={9}>
                    <Grid item sx={{width: "100%"}}>
                        <span className={styles.headingDetailsStyle}>Campaign Contacts</span>
                    </Grid>
                    <Grid item sx={{width: "100%"}} xs={12} lg={12}>
                        <CampaignAnalysisContacts statusColor={cardDetailsColor}/>
                    </Grid>
                </Grid>
            </Grid>

            {campaignContactStatusPopup &&
                <GlobalPopUp
                    open={campaignContactStatusPopup}
                    onClose={() => { setCampaignContactStatusPopup(false) }}
                    // title={}
                    flagBackdrop
                    paperMinWidth={smallScreen ? "90%" : "700px"}
                    paperMaxwidth={smallScreen ? "750px" : "750px"}
                    flagShowCloseIcon={true}
                    handleClose={() => { setCampaignContactStatusPopup(false) }}
                >
                    <CampaignContactStatusTrack />
                </GlobalPopUp>
            }
        </Grid>
    )
}

export default CampaignAnalysis
