import { Divider, Grid, Popover, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import IconsColor from './iconsColor';
import GlobalButton from './globalButton/button';
import styles from "../../assets/styles/componentStyles/utility/confirmationPopover.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
interface confirmationPopover {
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    onConfirmation: () => void;
    descriptionText: ReactElement
}

function ConfirmationPopover(props: confirmationPopover) {
    const { open, anchorEl, handleClose, onConfirmation } = props
    return (
        <Popover
            id={open ? 'simple-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            className={styles.mainBlock}
        >
            <Typography >

                <Grid container direction={"column"}>
                    <Grid item className={styles.firstBlock}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item className={`${globalStyles.primaryButtonFontStyle} ${globalStyles.primaryColor}`}>Confirmation</Grid>
                            <Grid item className={globalStyles.clickable} onClick={handleClose}><IconsColor iconName="closeIcon" width="10px" height="10px" fillColor="#000000" /></Grid>
                        </Grid>
                    </Grid>
                    <Divider className={styles.dividerStyle} />
                    <Grid item className={styles.descStyle}>{props.descriptionText}</Grid>
                    <Grid item className={styles.buttonWrapper}>
                        <Grid container gap="10px">
                            <Grid item className={styles.buttonWrapperGrid}>
                                <GlobalButton onClick={handleClose} sx={{ padding: "0px !important", height: "33px !important" }} flagFullWidth buttonText={"No"} className='borderedButtonStyle' />
                            </Grid>
                            <Grid item className={styles.buttonWrapperGrid}>
                                <GlobalButton onClick={onConfirmation} sx={{ padding: "0px !important", height: "33px !important" }} flagFullWidth buttonText={"Yes"} className="primaryButtonStyle" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Typography>
        </Popover>
    )
}

export default ConfirmationPopover
