import { Button, Grid, useMediaQuery } from '@mui/material'
import styles from '../../assets/styles/componentStyles/messageFormatForms/messageFormats.module.scss'
import formatStyles from '../../assets/styles/componentStyles/Global/formatCard.module.scss'
import GlobalPopUp from '../utility/globalPopUp'
import { useState } from 'react'
import MessageFormatPopup from '../dashboard/messageFormats/messageFormatPopup'

interface formatFields {
    // fieldNumber?: number,
    // fieldTitle?: string,
    format?: any
    handleFormats?: any
}

const FormatCard = (props: formatFields) => {
    const { format, handleFormats } = props;
    const [openExamplesPopup, setOpenExamplesPopup] = useState(false)
    const [popupData, setPopupData] = useState(format)
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)')


    const handleViewExample = (format: any) => {
        setPopupData(format);
        setOpenExamplesPopup(true);
    }

    return (
        <>
            <Grid item >
                <Grid container direction={"column"} gap={"6px"} flexWrap={"nowrap"}>
                    {/* <Grid item>
                        <span className={styles.messageFormatHeadingStyle}>{format.message_format_name}</span>
                    </Grid> */}
                    <Grid item className={styles.messageFormatImageStyle}>
                        <Grid container className={styles.messageFormatImageInsideStyle} justifyContent={"center"} alignItems={"center"}>
                            <img alt="" style={{ width: "174px", height: "214px" }} src={format.message_img} />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Button sx={{ opacity: format.flagDisable ? "0.5" : "" }} disabled={format.flagDisable} onClick={() => handleFormats(format.message_format_id)} className={styles.useThisCardButton}>Use This Card</Button>
                    </Grid>

                    <Grid item>
                        <Button sx={{ opacity: format.flagDisable ? "0.5" : "" }} disabled={format.flagDisable} className={styles.viewExamplesButton} onClick={() => handleViewExample(format)}>View Examples</Button>
                    </Grid>

                </Grid>
            </Grid>

            {openExamplesPopup &&
                <GlobalPopUp
                    open={openExamplesPopup}
                    onClose={() => { setOpenExamplesPopup(false) }}
                    flagBackdrop
                    height={"100%"}
                    paperMinWidth={flagSmallScreen ? "92%" : "85%"}
                    paperMaxwidth={flagSmallScreen ? "92%" : "85%"}
                    flagShowCloseIcon={true}
                    handleClose={() => { setOpenExamplesPopup(false) }}
                >
                    <MessageFormatPopup format={format} handleFormats={handleFormats} />
                </GlobalPopUp>
            }
        </>
    )
}

export default FormatCard
