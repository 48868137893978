import { Backdrop, Box, CircularProgress, Grid, } from '@mui/material';
import React, { useContext, } from 'react';
import globalStyles from "../../assets/styles/global.module.scss";
import { UserContext, UserContextPayload } from '../../context/userContext';

const GlobalRoundLoader = () => {
    const { loadingURL, flagLoading } = useContext(UserContext) as UserContextPayload;

    return (
        <>
            {flagLoading.flagRoundLoader &&
                <React.Fragment>
                    <Backdrop
                        className={globalStyles.backDropStyle}
                        sx={{
                            zIndex: 1500,
                            opacity: "0.8 !important"
                        }}
                        open={true}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center'
                        }}>
                            <CircularProgress color="inherit"
                                sx={{
                                    width: '50px !important',
                                    height: '50px !important'
                                }}
                            />
                        </Box>
                    </Backdrop>
                </React.Fragment>
            }
        </>

    )
};

export default GlobalRoundLoader;