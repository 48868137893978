import { Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext'
import EmptyState from '../../global/emptyState';
import emptyWhatsappCampaignImage from "../../../assets/images/whatsappCampaign/emptyWhatsappCampaignImage.png";
import GlobalButton from '../../utility/globalButton/button';
import IconsColor from '../../utility/iconsColor';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants';
import CampaignTable from './campaignTable';

const WhatsappCampaignList = () => {

    const { whatsappCampaignListdData, setWhatsappCampaignListdData, getCampaignDataApi, setSelectedWhatsAppCampaign } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;
    const { flagLoading, setFlagLoading, setError, error, userDetail, setSuccess, success } = useContext(UserContext) as UserContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    const campaign_id = searchParams.get('campaign_id')

    const navigate = useNavigate();

    const handleNewCampaignClick = () => {
        navigate('/whatsappcampaign/createnewcampaign');
        setSelectedWhatsAppCampaign(null);
    }

    const getCampaignDataApiCall = async (campaign_id?: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await getCampaignDataApi(campaign_id);
        if (!campaign_id) {
            setSelectedWhatsAppCampaign(null)
        }
        if (!(res.status)) {
            let errorCode = checkForErrorCode(res);
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    useEffect(() => {
        getCampaignDataApiCall(Number(campaign_id));
    }, [window.location.href])




    return (
        <>
            <Grid item sx={{ width: "100%", padding: "5px 10px 5px 5px" }}>
                <Grid container direction={"column"} gap={"24px"}>
                    {!whatsappCampaignListdData ?
                        <> </>
                        :
                        whatsappCampaignListdData.length === 0 ?
                            <Grid item sx={{ width: "100%" }}>
                                <EmptyState
                                    height={'calc(100vh - 125px)'}
                                    pageIcon={<img alt="icon" src={emptyWhatsappCampaignImage} style={{ width: "165px", height: "auto" }} />}
                                    pageSubText={"No Campaign Generated Yet"}
                                    primaryActionBtn={<GlobalButton
                                        buttonText={"New Campaign"}
                                        className='primaryButtonStyle'
                                        sx={{ width: "170px" }}
                                        startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                        onClick={() => { handleNewCampaignClick() }}
                                    />}
                                />
                            </Grid>
                            :
                            <>
                                <CampaignTable getCampaignDataApiCall={getCampaignDataApiCall} />
                            </>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsappCampaignList
