import { Divider, Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import "../../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import GlobalPageHeaderText from '../../../utility/globalPageHeaderText';
import globalStyles from "../../../../assets/styles/global.module.scss";
import styles from "../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import agent from '../../../../services/api';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import IconsColor from '../../../utility/iconsColor';
import TemplateCard from '../../../global/templateCard';
import GlobalButton from '../../../utility/globalButton/button';
import { IntegrationContext, IntegrationContextPayload } from '../../../../context/integrationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { globalIntegrationType } from '../../../../models/axiosModel';
import { GlobalTable } from '../../../utility/globalTable';
import deleteDoc from "../../../../assets/deleteDoc.svg";
import { checkForErrorCode, formatDate, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import linkedDetailsIcon from '../../../../assets/chatbotAssets/linkedDetails.svg'
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import whatsappIcon from '../../../../assets/Whatsapp.svg'
import instagramIcon from '../../../../assets/images/channels/instagram/instagram.png';
import highLevel from "../../../../assets/images/chatbot/integrations/gohighlevel.png";

interface chatsProps {
    mainSection?: boolean
}
interface integrationRowData {
    id: number,
    iconSrc: string,
    channel_icon: any,
    channel_name: string,
    channel_linked_details: any,
    channel_last_updated_timestamp: string,
}
export default function IntegrationList(props: chatsProps) {
    const navigate = useNavigate()
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 1200px) and (min-width : 600px)')
    const innerHeight = tabletScreen ? (window.innerHeight - 130) : (window.innerHeight - 110);
    const { setFlagLoading, setSuccess, setError, userDetail } = useContext(UserContext) as UserContextPayload;
    const [flagDeleteIntegrationDataPopup, setFlagDeleteIntegrationDataPopup] = useState(false)
    const [integrationSelected, setIntegrationSelected] = useState<null | any>(null)
    const [integrationViewData, setIntegrationViewData] = useState<integrationRowData[] | []>([]);
    const { getGlobalIntegrationTypeList, globalIntegrationTypeList, getIntegrationList, integrationList, slackIntegrations, setSlackIntegrations } = useContext(IntegrationContext) as IntegrationContextPayload
    const [searchParams, setSearchParams] = useSearchParams()
    let integrationListStatic = [
        {
            name: "Calendly",
        },
        {
            name: "HighLevel",
        },
        {
            name: "Trello",
        },
        {
            name: "WhatsApp",
        },
        {
            name: "Custom API",
        },
        {
            name: "Zapier",
        },
        {
            name: "Slack",
        },
        {
            name: "Google Chat",
        },
    ]
    useEffect(() => {
        (async () => {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
            await getGlobalIntegrationTypeList();
            await getIntegrationList();
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
        })()
    }, [])

    const deleteMetaIntegration = async (id: any) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let response: any
        if (integrationSelected.channel_feature == "slack") {
            response = await agent.deleteSlackIntegration.delete(id)
        } else {
            response = await agent.deleteMetaIntegration.delete(id)
        }
        if (response && response.status) {
            let temp = [...integrationViewData]
            temp = temp.filter((integration: any) => integration.id !== id)
            setIntegrationViewData(temp)
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Deleted Successfully!" }))
            navigate('/channels')
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    let headerList = [
        {
            name: "",
            alignment: "left",
            flagIcon: false,
            valueKey: "channel_icon",
            iconKey: "channelIcon",
            sx: { width: "20px !important", paddingRight: "0px !important" }
        },
        {
            name: "Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "channel_name",
        },
        {
            name: "Purpose",
            alignment: "left",
            flagIcon: false,
            valueKey: "channel_purpose",
        },
        {
            name: "Linked Details",
            alignment: "left",
            flagIcon: true,
            valueKey: "channel_linked_details",
        },
        {
            name: "Last Modified (UTC)",
            alignment: "left",
            flagIcon: false,
            valueKey: "channel_last_updated_timestamp",
        },
        // {
        //     name: "",
        //     alignment: "right",
        //     flagIcon: true,
        //     valueKey: "",
        //     iconKey: "editIcon"
        // },
        // {
        //     name: "feature",
        //     alignment: "right",
        //     flagIcon: true,
        //     valueKey: "channel_feature",
        //     iconKey: "editIcon",
        //     hidden: true
        // },
    ]

    useEffect(() => {
        if (integrationList !== null && slackIntegrations) {
            let updatedData = integrationList.flatMap((integrationData, index) => {
                return {
                    id: integrationData.meta_integration_id,
                    iconSrc: linkedDetailsIcon,
                    channel_feature: integrationData.meta_integration_facebook_data ? integrationData.meta_integration_facebook_data.instagram_id ? "instagram" : "facebook" : "whatsapp",
                    channel_icon: integrationData.meta_integration_facebook_data && integrationData.meta_integration_facebook_data.instagram_id ? <Grid item sx={{ display: "flex" }}><img src={instagramIcon} width={"20"} height={"20"} style={{ display: "flex" }} /></Grid> : integrationData.meta_integration_facebook_data && !integrationData.meta_integration_facebook_data.instagram_id ? <Grid item sx={{ display: "flex" }}><IconsColor iconName="facebook_messenger" height="20" width="20" /></Grid> : <Grid item sx={{ display: "flex" }}><img src={whatsappIcon} width={"22"} height={"22"} style={{ display: "flex" }} /></Grid>,
                    channel_name: integrationData.meta_integration_facebook_data && integrationData.meta_integration_facebook_data.instagram_id ? `${integrationData.meta_integration_facebook_data.instagram_username}` : integrationData.meta_integration_facebook_data && !integrationData.meta_integration_facebook_data.instagram_id ? `${integrationData.meta_integration_facebook_data.page_name}` : `${integrationData.meta_integration_whatsapp_data?.waba_name}`,
                    channel_linked_details: integrationData.meta_integration_whatsapp_data ? <span style={{ color: "#6A097D" }}>{`${integrationData.meta_integration_whatsapp_data?.meta_integration_phone_number_data?.filter((data: any) => data?.chatbot_integration_assignment_data.length > 0).length} / ${integrationData?.meta_integration_whatsapp_data?.meta_integration_phone_number_data?.length}`}</span> : <span style={{ color: "#6A097D" }}>{`${integrationData.meta_integration_facebook_data?.chatbot_integration_assignment_data?.length} / 1`}</span>,
                    channel_last_updated_timestamp: formatDate(integrationData.updated_at_timestamp),
                    channel_purpose: ""
                };
            });
            let tempupdatedData = updatedData.filter((integration) => integration.channel_feature.toLowerCase() !== "facebook" && integration.channel_feature.toLowerCase() !== "instagram")
            let slackIntegrationsTemp = slackIntegrations.map((slackIntegration) => {
                return {
                    id: slackIntegration.slack_integration_id,
                    iconSrc: linkedDetailsIcon,
                    channel_feature: "slack",
                    channel_name: `${slackIntegration.slack_user_real_name} - ${slackIntegration.slack_team_name}`,
                    channel_icon: <IconsColor iconName="colouredSlackIcon" height="20" width="20" />,
                    channel_linked_details: `${slackIntegration.slack_channels.filter((channel) => channel.chatbot_slack_assignment_data.length > 0).length} / ${slackIntegration.slack_channels.length}`,
                    channel_last_updated_timestamp: "",
                    channel_purpose: ""
                }
            })
            setIntegrationViewData([...tempupdatedData, ...slackIntegrationsTemp])
        }
    }, [integrationList, slackIntegrations])

    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);

    const rowClicked = async (e: any, rowDetail: any) => {
        if (e.iconKey !== "editIcon") {
            navigate(`/channels/viewintegration/${rowDetail.channel_feature}?id=${rowDetail.id}`)
        }
    }

    const deleteIntegrationDataPopupOpen = (data: any) => {
        setFlagDeleteIntegrationDataPopup(true)
        setIntegrationSelected(data)
    }
    const menuItems = [
        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" width={"16"} height={"16"} />, action: (e: any) => deleteIntegrationDataPopupOpen(e) },
    ];

    const pollForCallbackStatus = async (stateValue: string) => {
        const pollInterval = 8000;
        const maxPollingTime = 80000;
        let elapsedTime = 0;

        const intervalId = setInterval(async () => {
            try {
                const response = await agent.getCallbackStatus.get(stateValue);

                if (response.status) {
                    const integrationStatus = response?.data?.integration_status?.toLowerCase();

                    if (integrationStatus === 'completed' || integrationStatus === 'failed') {
                        clearInterval(intervalId);

                        if (integrationStatus === 'failed') {

                            setError((prevState) => ({ ...prevState, flagShowError: true, message: response?.data?.reason }))

                        } else {
                            const response1 = await agent.getSlackintegrations.get();
                            if (response1.status) {
                                if (slackIntegrations) {
                                    setSlackIntegrations(response1.data)
                                }
                            }
                            navigate(`viewIntegration/slack?id=${response?.data.integration_details.slack.id}`)
                        }
                    } else {
                        console.log("Callback not completed, retrying...");
                    }

                    elapsedTime += pollInterval;

                    if (elapsedTime >= maxPollingTime) {
                        console.log("Polling stopped after 1 minute.");
                        clearInterval(intervalId);
                    }
                } else {
                    let errorCode = checkForErrorCode(response.data)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            } catch (error) {
                console.error("Error polling for callback status:", error);
                clearInterval(intervalId);
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }, pollInterval);
    };
    async function handleIntegrateSlack() {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            let slackIntegration = globalIntegrationTypeList.find((integration) => integration.integration_app_name == "Slack")
            let currentUrl = window.location.href;
            if (slackIntegration && slackIntegration.integration_app_id) {
                const payload = {
                    current_url: currentUrl,
                    integration_app_id: slackIntegration.integration_app_id,
                };

                const response = await agent.calendlyAuthUrlApi.post(payload);
                if (response.status) {
                    const authUrl = response?.data?.auth_url;
                    if (authUrl) {
                        window.open(response.data.auth_url, "_blank")
                        const stateValue = new URL(authUrl).searchParams.get('state');
                        if (stateValue) {
                            pollForCallbackStatus(stateValue);
                        } else {
                            console.error('State param not found in the auth_url.');
                        }
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getIntegrationObj = (integrationType: any) => {
        let integrationTypeName = integrationType.name.toLowerCase()
        switch (integrationTypeName) {
            case "whatsapp":
                return {
                    name: "WhatsApp Business",
                    image: <img alt="whatsApp" src={whatsappIcon} height={"27"} width={"27"} />,
                    description: "Effectively communicate with customers using WhatsApp integration.",
                    actionItem: <GlobalButton buttonText={"Integrate"} onClick={() => navigate(`/chatbot/viewchatbot/integration/whatsapp?chatbot_id=${searchParams.get('chatbot_id')}`)} className="channelsThemeBtnStyle" />
                }
            case "custom api":
                return {
                    name: "Custom API",
                    image: <IconsColor iconName="customAPI" width="25px" height="25px" />,
                    description: "Add custom third-party integrations to extend your chatbot's power and meet specific needs.",
                    actionItem: <GlobalButton buttonText={"Integrate"} onClick={() => navigate(`/chatbot/viewchatbot/integration/customAPI?chatbot_id=${searchParams.get('chatbot_id')}`)} className="channelsThemeBtnStyle" />
                }
            case "highlevel":
                return {
                    name: "HighLevel",
                    image: <img alt="whatsApp" src={highLevel} height={"27"} width={"27"} />,
                    description: "Integrate with HighLevel to automate CRM tasks, nurture leads, and manage client interactions seamlessly from within your chatbot platform.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" />
                }
            case "calendly":
                return {
                    name: "Calendly",
                    image: <IconsColor iconName="calendly" width="25px" height="25px" />,
                    description: "Seamlessly sync with Calendly to schedule meetings effortlessly through your chatbot.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" onClick={() => navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${searchParams.get('chatbot_id')}`)} />
                }
            case "trello":
                return {
                    name: "Trello",
                    image: <IconsColor iconName="trello" width="25px" height="25px" />,
                    description: "Effortlessly manage tasks and projects by integrating Trello with your chatbot. Instantly create, update, and track Trello cards directly through conversations.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" />
                }
            case "zapier":
                return {
                    name: "Zapier",
                    image: <IconsColor iconName="zapier" width="25px" height="25px" />,
                    description: "Enhance automation and streamline workflows with Zapier integration for your chatbot.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" />
                }
            case "google chat":
                return {
                    name: "Google Chat",
                    image: <IconsColor iconName="googleChat" width="25px" height="25px" />,
                    description: "Seamlessly collaborate and communicate with teams through Google Chat integration. Automate notifications, streamline workflows, and stay connected within your chatbot.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" />
                }
            case "slack":
                return {
                    name: "Slack",
                    image: <IconsColor iconName="colouredSlackIcon" width="25px" height="25px" />,
                    description: "Connect Slack to receive instant updates and engage with users directly from your workspace.",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" onClick={() => navigate(`/chatbot/viewchatbot/integration/slack?chatbot_id=${searchParams.get('chatbot_id')}`)} />
                }
            default:
                return null
        }
    }

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
            <Grid container >
                <Grid item xs={12} sm={12}>
                    <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                        {props.mainSection && <><Grid item className={globalStyles.fullwidth}>
                            <GlobalPageHeaderText
                                mainHeadText={"Channels"}
                                flagMainTextOnly
                            // mainSubText={"Create Integrations to grow your business"}
                            />
                            {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${styles.dividerStyle}`} sx={{ paddingTop: "16px !important" }} />}
                        </Grid>
                        </>}
                        <Grid item className={`${globalStyles.fullwidth} ${styles.lowerBlock}`} sx={{ maxHeight: `${innerHeight}px !important`, overflowY: "auto" }}>
                            <Grid container direction={"column"}>
                                {/* upper block */}
                                <Grid item sx={{ padding: "2px" }}>
                                    <Grid container direction={"column"}>
                                        <Grid item className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight} ${globalStyles.globalHeadText} ${styles.subHeading}`}>Available Integrations</Grid>
                                        <Grid item>
                                            <Grid container>
                                                {integrationListStatic.map((integrationType: any) => {
                                                    let integration: any = getIntegrationObj(integrationType)
                                                    return <TemplateCard
                                                        cardName={integration.name}
                                                        cardDescription={integration.description}
                                                        cardImage={integration.image}
                                                        cardOnClick={() => { }}
                                                        cardActionItem={integration.actionItem}
                                                    />
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* lower block */}
                                {
                                    <Grid item xs={12} sx={{ width: "100%", overflow: "scroll", boxSizing: "border-box" }}>
                                        <Grid container direction={"column"} className={styles.integrationTable}>
                                            <Grid item xs={12} className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight} ${globalStyles.globalHeadText} ${styles.subHeading}`}>Integrated Channels</Grid>
                                            <Grid item xs={12} sx={{ paddingBottom: "25px", width: "100%" }}>
                                                <GlobalTable
                                                    headerList={adjustedHeaderList}
                                                    rowData={integrationViewData}
                                                    tableHeight={"42vh"}
                                                    tableContainerHeight={"auto"}
                                                    tableContainerMinHeight={"auto"}
                                                    onCellClick={(e: any, rowDetail: any) => { rowClicked(e, rowDetail) }}
                                                    onRowClick={(e: any) => { console.log(e) }}
                                                    deleteRowData={deleteIntegrationDataPopupOpen}
                                                    flagPagination
                                                    menuItemsFromParent={menuItems}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {flagDeleteIntegrationDataPopup &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete ${integrationSelected.channel_name} channel`}
                    open={flagDeleteIntegrationDataPopup}
                    onClose={() => { setFlagDeleteIntegrationDataPopup(false) }}
                    onDelete={(e: any) => {
                        deleteMetaIntegration(integrationSelected.id)
                        setFlagDeleteIntegrationDataPopup(false)
                    }}
                />
            }
        </Grid >

    )
}
