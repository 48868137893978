import { Autocomplete, Divider, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import globalStyles from "../../../assets/styles/global.module.scss";
import headerStyles from '../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss';
import styles from "../../../assets/styles/componentStyles/dataconnector.module.scss";
import React, { useContext, useEffect, useState } from 'react'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import GlobalButton from '../../utility/globalButton/button';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';
import EmptyState from '../../global/emptyState';
import emptyContactListImage from "../../../assets/images/campaignContact/emptyContactListImage.png";
import IconsColor from '../../utility/iconsColor';
import GlobalPopUp from '../../utility/globalPopUp';
import AddNewListPopup from './addNewListPopup';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { campaignContact, campaignContactData, campaignContactTagAssignment, createCampaignContactFieldPayload, createCampaignContactListPayload, createCampaignContactTagPayload, getCampaignContactListResponse, importCampaignContactDataPayload, updateCampaignContactFieldPyload, updateCampaignContactListPayload, updateCampaignContactPayload } from '../../../models/axiosModel';
import CreateNewContactPopup from './createNewContactPopup';
import ListCard from './listCard';
import globalStrenameDocumentyles from "../../../assets/renameDocument.svg";
import ContactTable from './contactTable';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants';
import contactStyles from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import EditContactPopup from './editContactPopup';
import AddNewTagPopup from './addNewTagPopup';
import EditFieldPopup from './editFieldPopup';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import noDataFound from "../../../assets/CampaignNoDataFound.png";

const ContactsGlobal = () => {

    const {
        campaignContactListData,
        setCampaignContactListData,
        campaignContactData,
        setCampaignContactData,
        setFilteredCampaignContactData,
        campaignContactFieldTypeData,

        flagAddNewListDialog,
        setFlagAddNewListDialog,
        flagCreateNewContactDialog,
        setFlagCreateNewContactDialog,
        flagNewFieldDialog,
        setFlagNewFieldDialog,

        getCampaignContactListApi,
        createCampaignContactListApi,
        updateCampaignContactListApi,
        getCampaignContactApi,
        updateCampaignContactApi,
        deleteCampaignContactApi,
        importCampaignContactDataApi,
        getListOfCampaignContactFieldApi,
        createCampaignContactFieldApi,
        updateCampaignContactFieldApi,
        getListOfCampaignFieldTypeApi,
        getListOfCampaignContactTagApi,
        createCampaignContactTagApi,
    } = useContext(ContactContext) as ContactContextPayload;
    const { flagLoading, setFlagLoading, setError, error, userDetail, setSuccess, success } = useContext(UserContext) as UserContextPayload;

    const [searchParams, setSearchParams] = useSearchParams();
    const list_id = searchParams.get('contact_list_id');

    // const [flagAddNewListDialog, setFlagAddNewListDialog] = useState<boolean>(false);
    // const [flagCreateNewContactDialog, setFlagCreateNewContactDialog] = useState<boolean>(false);

    const [selectedCampaignContactList, setSelectedCampaignContactList] = useState<getCampaignContactListResponse | null>(null);

    const [flagEditListDialog, setFlagEditListDialog] = useState<boolean>(false);
    const [requiredCampaignContactList, setRequiredCampaignContactList] = useState<getCampaignContactListResponse | null>(null);

    const [deleteContactString, setDeleteContactString] = useState<string>("");
    const [requiredCampaignContact, setRequiredCampaignContact] = useState<campaignContact[]>([]);

    const [filteredCampaignContactList, setFilteredCampaignContactList] = useState<getCampaignContactListResponse[] | null>(campaignContactListData);

    const [flagEditContactDialog, setFlagEditContactDialog] = useState<boolean>(false);

    const [contactFields, setContactFields] = useState<campaignContactData[]>([]);
    const [contactTagData, setContactTagData] = useState<Omit<campaignContactTagAssignment, 'contact_tag_assignment_id'>[]>([]);
    const [flagAddTagDialog, setFlagAddTagDialog] = useState<boolean>(false);

    const [flagOnOkayClick, setFlagOnOkayClick] = useState<boolean>(false);

    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    const flagTabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)');
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const innerHeight = smallScreen ? (window.innerHeight - 165) : flagTabletScreen ? (window.innerHeight - 208) : (window.innerHeight - 178);

    const navigate = useNavigate();

    const menuItem = [
        {
            label: "Edit",
            icon: <img alt="" src={globalStrenameDocumentyles} />,
            action: (list: any) => {
                handleEditList(list);
            }
        }
    ]

    const handleEditList = (list: getCampaignContactListResponse) => {
        setRequiredCampaignContactList(list);
        setFlagEditListDialog(true);
    }

    const handleAddNewList = () => {
        setFlagAddNewListDialog(true);
    }

    const handleAddNewContact = () => {
        setFlagCreateNewContactDialog(true);
    }

    const createCampaignContactListApiCall = async (payload: createCampaignContactListPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await createCampaignContactListApi(payload);
        if (response.status && response.data) {
            setSuccess({ ...success, flagShowSuccess: true, message: `List Created Successfully` });

            getCampaignContactListApiCall();
            setSearchParams({ 'contact_list_id': response.data[0]?.contact_list_id.toString() });
        }
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const handleAddNewListSaveButtonClick = (name: string) => {
        const payload = {
            contact_list_name: name,
        }
        createCampaignContactListApiCall(payload);
    }

    const updateCampaignContactListApiCall = async (payload: updateCampaignContactListPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await updateCampaignContactListApi(payload);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `List Updated Successfully` });
            getCampaignContactListApiCall();
        } else {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }
    const handleUpdateListSaveButtonClick = (name: string, id: any) => {
        const payload = {
            contact_list_id: id,
            contact_list_name: name,
        }

        updateCampaignContactListApiCall(payload);
        setFlagEditListDialog(false);
    }

    const getCampaignContactListApiCall = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await getCampaignContactListApi();
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getCampaignContactApiCall = async (list_id: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const payload = {
            contact_list_id: list_id,
        }
        const response = await getCampaignContactApi(payload);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getListOfCampaignContactTagApiCall = async (list_id: number) => {
        // setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await getListOfCampaignContactTagApi(list_id);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        // setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getListOfCampaignFieldTypeApiCall = async () => {
        const response = await getListOfCampaignFieldTypeApi();
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
    }

    const getListOfCampaignContactFieldApiCall = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const list_id = selectedCampaignContactList?.contact_list_id as Number;
        const response = await getListOfCampaignContactFieldApi(Number(list_id));
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const createCampaignContactFieldApiCall = async (payload: createCampaignContactFieldPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await createCampaignContactFieldApi(payload);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        } else {
            setSuccess({ ...success, flagShowSuccess: true, message: `Field Created Successfully` });
            await getListOfCampaignContactFieldApiCall();
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const updateCampaignContactFieldApiCall = async (payload: updateCampaignContactFieldPyload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const list_id = selectedCampaignContactList?.contact_list_id as number;
        if (campaignContactFieldTypeData.length === 0) {
            await getListOfCampaignFieldTypeApiCall();
        }
        const response = await updateCampaignContactFieldApi(payload);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        } else {
            setSuccess({ ...success, flagShowSuccess: true, message: `Field Updated Successfully` });
            await getCampaignContactApiCall(list_id);
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const createCampaignContactTagApiCall = async (payload: createCampaignContactTagPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await createCampaignContactTagApi(payload);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `Tag Created Successfully` });
            const id = selectedCampaignContactList?.contact_list_id;
            if (id) getListOfCampaignContactTagApiCall(id);
        } else {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const importCampaignContactDataApiCall = async (payload: importCampaignContactDataPayload) => {
        const response = await importCampaignContactDataApi(payload);
        setFlagCreateNewContactDialog(false);
        if (response.status) {
            const id = selectedCampaignContactList?.contact_list_id;
            if (id) {
                getCampaignContactApiCall(id);
            }
            setSuccess({ ...success, flagShowSuccess: true, message: response.uploaded_contacts === 0 ? `All the imported contacts are already in your list. No new contacts were imported.` : `${response.uploaded_contacts} new contacts have been successfully imported to your list.` });
        } else {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagOnOkayClick(false);
    }

    const handleListChange = (e: React.ChangeEvent<{}>, value: getCampaignContactListResponse) => {
        setSearchParams({ 'contact_list_id': (value.contact_list_id).toString() });
        // Get Campaign Contact Call  
        // setCampaignContactData([]);
        setCampaignContactData(null);
        // setFilteredCampaignContactData([]);
        setFilteredCampaignContactData(null);
        getCampaignContactApiCall(value.contact_list_id);
        getListOfCampaignContactTagApiCall(value.contact_list_id);
    }

    const onClickHandler = (contact_list_id: number) => {
        setSearchParams({ 'contact_list_id': (contact_list_id).toString() });
        // Get Campaign Contact Call 
        getCampaignContactApiCall(contact_list_id);
        getListOfCampaignContactTagApiCall(contact_list_id);
    }

    const deleteContactApiCall = async (contacts: campaignContact[]) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const contact_ids: number[] = contacts.map(contact => {
            return contact.contact_id
        });
        const response = await deleteCampaignContactApi({ contact_id: contact_ids });
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `Contact Deleted Successfully` });

            const list_id = selectedCampaignContactList?.contact_list_id;
            if (list_id) {
                getCampaignContactApiCall(list_id);
            }
        } else {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });

        // Close the poppup
        setDeleteContactString("");
        setRequiredCampaignContact([]);
    }

    const deleteCampaignContact = (contacts: campaignContact[]) => {
        setRequiredCampaignContact(contacts);
        if (contacts.length === 1) {
            setDeleteContactString(`this contact "${contacts[0].contact_phone_number}"`);
        } else {
            setDeleteContactString("all selected contacts");
        }
    }

    const editCampaignContact = (contacts: campaignContact[]) => {
        setRequiredCampaignContact(contacts);
        setFlagEditContactDialog(true);
    }

    const manageListClickHandler = () => {
        navigate("/contacts");
        setSelectedCampaignContactList(null);
        // setCampaignContactData([]);
        setCampaignContactData(null);
        // setFilteredCampaignContactData([]);
        setFilteredCampaignContactData(null);
        getCampaignContactListApiCall();
    }

    const editCampaignContactApiCall = async (payload: updateCampaignContactPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await updateCampaignContactApi(payload);
        if (res.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `Contact Edited Successfully` });
            const id = selectedCampaignContactList?.contact_list_id;
            if (id) {
                getCampaignContactApiCall(id);
            }
        } else {
            let errorCode = checkForErrorCode(res)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const saveAddNewTagButtonClick = (tagName: string) => {
        const payload = {
            contact_list_id: Number(list_id),
            contact_tag_data: [
                {
                    contact_tag_name: tagName,
                }
            ]
        }
        createCampaignContactTagApiCall(payload);
    }

    const handleSearch = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        if (campaignContactListData) {
            const filter_data = campaignContactListData.filter((list: getCampaignContactListResponse) => {
                return (list.contact_list_name != null && list.contact_list_name.toLowerCase().includes(query))
            })
            setFilteredCampaignContactList(filter_data);
        }
    }

    useEffect(() => {
        if (!campaignContactListData) {
            getCampaignContactListApiCall();
        }
    }, [])

    useEffect(() => {
        const campaignContactListId = searchParams.get('contact_list_id');
        if (campaignContactListId) {
            if (campaignContactListData) {
                const currentList = campaignContactListData.find((list: getCampaignContactListResponse) => list.contact_list_id === Number(campaignContactListId));
                if (currentList) {
                    setSelectedCampaignContactList(currentList);
                }
            }
        } else {
            navigate("/contacts");
            setSelectedCampaignContactList(null);
        }
    }, [searchParams])

    useEffect(() => {
        setFilteredCampaignContactList(campaignContactListData);

        if (campaignContactListData && campaignContactListData.length > 0) {
            const campaignContactListId = searchParams.get('contact_list_id');
            if (campaignContactListId && campaignContactListId.length > 0) {
                const currentList = campaignContactListData.find((list: getCampaignContactListResponse) => list.contact_list_id === Number(campaignContactListId));
                if (currentList) {
                    setSelectedCampaignContactList(currentList);
                    // Get Campaign Contact Call 
                    getCampaignContactApiCall(currentList.contact_list_id);
                    // Get Campaign Tag Call 
                    getListOfCampaignContactTagApiCall(currentList.contact_list_id);
                } else {
                    navigate("/contacts");
                }
            }
        }
    }, [campaignContactListData])

    useEffect(() => {
        if (requiredCampaignContact.length > 0 && requiredCampaignContact[0].contact_data && requiredCampaignContact[0].contact_data.length > 0) {
            let data = JSON.parse(JSON.stringify(requiredCampaignContact[0].contact_data))
            setContactFields(data);
            setContactTagData(requiredCampaignContact[0].contact_tag_assignment);
        }
    }, [requiredCampaignContact])


    return (
        <>
            <GlobalPopUp
                open={flagAddNewListDialog}
                onClose={() => { setFlagAddNewListDialog(false) }}
                handleClose={() => { setFlagAddNewListDialog(false) }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <AddNewListPopup
                    handleAddNewListSaveButtonClick={handleAddNewListSaveButtonClick}
                    flagEditList={false}
                />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagCreateNewContactDialog}
                onClose={() => { setFlagCreateNewContactDialog(false) }}
                handleClose={() => { setFlagCreateNewContactDialog(false) }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "700px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "750px"}
                borderRadius='10px'
                titlePadding='0px'
                margin={smallScreen ? "15px" : "32px"}
            >
                <CreateNewContactPopup
                    selectedCampaignContactList={selectedCampaignContactList as getCampaignContactListResponse}
                    importCampaignContactDataApiCall={importCampaignContactDataApiCall}
                    setFlagOnOkayClick={setFlagOnOkayClick}
                    getListOfCampaignFieldTypeApiCall={getListOfCampaignFieldTypeApiCall}
                    getListOfCampaignContactFieldApiCall={getListOfCampaignContactFieldApiCall}
                    createCampaignContactFieldApiCall={createCampaignContactFieldApiCall}
                />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagEditListDialog}
                onClose={() => { setFlagEditListDialog(false); setRequiredCampaignContactList(null); }}
                handleClose={() => { setFlagEditListDialog(false); setRequiredCampaignContactList(null); }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <AddNewListPopup
                    handleAddNewListSaveButtonClick={handleUpdateListSaveButtonClick}
                    flagEditList={true}
                    requiredList={requiredCampaignContactList}
                    setFlagEditListDialog={setFlagEditListDialog}
                />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagEditContactDialog}
                onClose={() => { setFlagEditContactDialog(false); }}
                handleClose={() => { setFlagEditContactDialog(false); }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "700px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "750px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <EditContactPopup
                    requiredCampaignContact={requiredCampaignContact}
                    setFlagEditContactDialog={setFlagEditContactDialog}
                    editCampaignContactApiCall={editCampaignContactApiCall}
                    createCampaignContactTagApiCall={createCampaignContactTagApiCall}

                    contactFields={contactFields}
                    setContactFields={setContactFields}
                    contactTagData={contactTagData}
                    setContactTagData={setContactTagData}
                    flagAddTagDialog={flagAddTagDialog}
                    setFlagAddTagDialog={setFlagAddTagDialog}
                />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagAddTagDialog}
                handleClose={() => { setFlagAddTagDialog(false); setFlagEditContactDialog(true); }}
                onClose={() => { setFlagAddTagDialog(false); setFlagEditContactDialog(true); }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <AddNewTagPopup setFlagAddTagDialog={setFlagAddTagDialog} setFlagEditContactDialog={setFlagEditContactDialog} saveAddNewTagButtonClick={saveAddNewTagButtonClick} />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagNewFieldDialog}
                onClose={() => setFlagNewFieldDialog(false)}
                handleClose={() => setFlagNewFieldDialog(false)}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <EditFieldPopup
                    setFlagNewFieldDialog={setFlagNewFieldDialog}
                    createCampaignContactFieldApiCall={createCampaignContactFieldApiCall}
                    getListOfCampaignFieldTypeApiCall={getListOfCampaignFieldTypeApiCall}
                    flagEdit={false}
                />
            </GlobalPopUp>

            {deleteContactString.length > 0 &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete ${deleteContactString}`}
                    open={deleteContactString.length > 0}
                    onClose={() => { setDeleteContactString(""); setRequiredCampaignContact([]); }}
                    onDelete={(e: any) => { deleteContactApiCall(requiredCampaignContact); }}
                />
            }

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper}`}>
                <Grid container sx={{ padding: { xs: "15px 0px", sm: (campaignContactListData && campaignContactListData.length === 0) ? "23px 0px" : "18px 0px" } }} direction={"column"} flexWrap={"nowrap"}>
                    <Grid item xs={12} sm={12}>
                        <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                            <Grid item className={contactStyles.fullwidth} sx={{ marginBottom: !smallScreen ? "10px" : "", padding: { xs: "0px 20px", lg: (campaignContactListData && campaignContactListData.length === 0) ? "5px 25px 0px 27px" : "0px 25px 0px 27px" } }}>
                                <GlobalPageHeaderText
                                    mainHeadText={
                                        (campaignContactListData && campaignContactListData.length === 0) ?
                                            "Contacts"
                                            :
                                            selectedCampaignContactList && campaignContactListData ?
                                                <Grid item xs={9} lg={6}>
                                                    <Autocomplete
                                                        disableClearable
                                                        value={campaignContactListData.find((list) => Number(selectedCampaignContactList.contact_list_id) == list.contact_list_id)}
                                                        className={`${styles.chatbotTitleSelect} ${globalStyles.globalHeaderFontSize} ${globalStyles.globalHeaderFontWeight}`}
                                                        options={campaignContactListData}
                                                        getOptionLabel={(option: getCampaignContactListResponse) => option.contact_list_name}
                                                        onChange={(e, value) => handleListChange(e, value)}
                                                        renderInput={(params) => <TextField
                                                            {...params} placeholder='Select' />}
                                                    />
                                                </Grid>
                                                :
                                                "List"
                                    }
                                    flagMainTextOnly
                                    actionBlock={
                                        (!smallScreen && campaignContactListData && campaignContactListData.length > 0) &&
                                        <Grid container gap={2} justifyContent={flagTabletScreen ? "start" : "end"} flexWrap={"nowrap"}>
                                            {selectedCampaignContactList &&
                                                <>
                                                    <Grid item>
                                                        <GlobalButton
                                                            className='borderedButtonStyle'
                                                            buttonText={"Manage List"}
                                                            onClick={() => { manageListClickHandler(); }}
                                                            sx={{ width: flagSmallScreen ? "100px" : "150px" }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <GlobalButton
                                                            className='borderedButtonStyle'
                                                            buttonText={flagSmallScreen ? "Field" : "New Field"}
                                                            startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#6a097d" />}
                                                            sx={{ width: flagSmallScreen ? "100px" : "150px" }}
                                                            onClick={() => setFlagNewFieldDialog(true)}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item>
                                                <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                                                    <Grid item>
                                                        {!searchParams.get('contact_list_id') ?
                                                            <GlobalButton
                                                                className='primaryButtonStyle'
                                                                buttonText={"New List"}
                                                                startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                                                sx={{ width: "150px" }}
                                                                onClick={() => { handleAddNewList(); }}
                                                            />
                                                            :
                                                            (campaignContactData && campaignContactData.length > 0) &&
                                                            <GlobalButton
                                                                className='primaryButtonStyle'
                                                                buttonText={flagSmallScreen ? "Contact" : "New Contact"}
                                                                startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                                                sx={{ width: flagSmallScreen ? "100px" : "150px" }}
                                                                onClick={() => { handleAddNewContact(); }}
                                                            />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    }
                                />
                                {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${headerStyles.dividerStyle}`} sx={{ paddingTop: "15px !important" }} />}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: "0 25px 0 27px" }}>

                        {!campaignContactListData ?
                            <></> :

                            campaignContactListData.length === 0 ?
                                <Grid item sx={{ width: "100%" }}>
                                    <EmptyState
                                        height={flagTabletScreen ? 'calc(100vh - 150px)' : 'calc(100vh - 125px)'}
                                        pageIcon={<img alt="chatbot" src={emptyContactListImage} style={{ width: "165px", height: "auto" }} />}
                                        pageSubText={"No List Generated Yet"}
                                        primaryActionBtn={<GlobalButton
                                            buttonText={"New List"}
                                            className='primaryButtonStyle'
                                            sx={{ width: "150px" }}
                                            startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                            onClick={() => { handleAddNewList() }}
                                        />}
                                    />
                                </Grid>
                                :
                                (!searchParams.get('contact_list_id')) ?
                                    <>
                                        <Grid item sx={{ width: "100%" }}>
                                            <Grid container mb={"10px"} mt={"10px"} justifyContent={{ xs: "start", sm: "end" }}>
                                                <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                    <GlobalSearch
                                                        fullWidth
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                                        size="small"
                                                        placeholder="Search"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {!filteredCampaignContactList ?
                                            <></> :
                                            filteredCampaignContactList.length > 0 ?
                                                <Grid container gap={"18px"} sx={{ padding: "15px 0 5px 5px", maxHeight: `${innerHeight}px !important`, overflow: "auto" }}>
                                                    {filteredCampaignContactList?.map((list, ind) => {
                                                        return (
                                                            <Grid item key={ind}>
                                                                <ListCard list={list} onClickHandler={onClickHandler} menuItem={menuItem} />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                                :
                                                <Grid item xs={12} className={contactStyles.noDataFoundItem}>
                                                    <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                        <Grid item>
                                                            <img src={noDataFound} alt="" />
                                                        </Grid>
                                                        <Grid item >
                                                            <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500" }}>
                                                                No Data Found!
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </>
                                    :
                                    (!campaignContactData ?
                                        <></> :

                                        (campaignContactData.length > 0 || flagOnOkayClick) ?
                                            <>
                                                <ContactTable
                                                    deleteCampaignContact={deleteCampaignContact}
                                                    editCampaignContact={editCampaignContact}
                                                    selectedCampaignContactList={selectedCampaignContactList as getCampaignContactListResponse}
                                                    getCampaignContactApiCall={getCampaignContactApiCall}
                                                    updateCampaignContactFieldApiCall={updateCampaignContactFieldApiCall}
                                                    getListOfCampaignFieldTypeApiCall={getListOfCampaignFieldTypeApiCall}
                                                />
                                            </>
                                            :
                                            <Grid item sx={{ width: "100%" }}>
                                                <EmptyState
                                                    height={flagTabletScreen ? 'calc(100vh - 150px)' : 'calc(100vh - 125px)'}
                                                    pageIcon={<img alt="chatbot" src={emptyContactListImage} style={{ width: "165px", height: "auto" }} />}
                                                    pageSubText={"No Contact Generated Yet"}
                                                    primaryActionBtn={<GlobalButton
                                                        buttonText={"New Contact"}
                                                        className='primaryButtonStyle'
                                                        sx={{ width: "150px" }}
                                                        startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                                        onClick={() => { handleAddNewContact() }}
                                                    />}
                                                />
                                            </Grid>
                                    )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactsGlobal
