import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Divider, Grid } from "@mui/material";
import GlobalButton from '../utility/globalButton/button';
import deleteConfirm from "../../assets/deleteConfirm.svg";
import globalStyles from "../../assets/styles/global.module.scss";
import agent from "../../services/api";

export default function DeleteDocumentDataComponent(props: any) {

  return (
    <div>
      <Grid container direction="column" >
        <Grid item className={globalStyles.deleteConfirmHeaderPopup}>
          <Grid container >
            <Grid item className={globalStyles.deleteConfirmPopupStyle}>
              <img src={deleteConfirm} className={globalStyles.deleteConfirmImage} />
            </Grid>
            <Grid item >
              <Grid container direction="column">
                <Grid item className={globalStyles.deleteConfirmPopupTextStyle}>
                  Delete Confirmation
                </Grid>
                <Grid item className={globalStyles.deleteConfirmPopupSubTextStyle}>
                  {props.deleteDescription}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={globalStyles.deleteConfirmButtonsPopup}>
          <Grid container justifyContent="end">
            <Grid item>
              <GlobalButton buttonText='Cancel' flagFullWidth className='cancelButton'
                onClick={props.deleteCancelDocumentDataPopupOpen} />
            </Grid>
            <Grid item style={{ marginLeft: "15px" }}>
              <GlobalButton buttonText='Delete' flagFullWidth className='deleteButton'
                onClick={() => props.handleDeleteDocument(props.documentSelected)} />
            </Grid>
          </Grid>
          {/* <Grid container justifyContent="space-evenly" sx={{ padding: "20px" }}>
              <Grid item>
              </Grid>
              <Grid item>
                <GlobalButton buttonText='DELETE' flagFullWidth className='primaryButtonStylePersona'
                  onClick={() => props.handleDeleteDocument(props.documentSelected.document_uuid)} />
              </Grid>
            </Grid> */}
        </Grid>
      </Grid>

    </div>
  );
}
