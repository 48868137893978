import { Autocomplete, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, TextField, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import GlobalButton from '../../utility/globalButton/button'
import globalStyles from '../../../assets/styles/global.module.scss'
import {DateRangePicker, FocusedInputShape} from 'react-dates'
import { Moment } from 'moment'
import 'react-dates/initialize';
import DatePresets from './datePresets'
import 'react-dates/lib/css/_datepicker.css';
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'
import { additionalFieldsValue, parentCategoryNames } from '../../../models/axiosModel'

interface categoryNames {
    call_category_id: number | null,
    call_category_name: string | null
}
interface complexityLevels {
    call_complexity_id: number | null,
    call_complexity_level: string | null
}
interface agentsNames {
    agent_id: number | null,
    agent_name: string | null
}

export const CallAnalysisFilter = () => {
    const { callCategories, callComplexity, callAgentsData, phoneNumbersData, callCenterDetails, selectedPurposeCategories, setSelectedPurposeCategories, selectedComplexityLevels, setSelectedComplexityLevels, 
        phoneNumberFilter, setPhoneNumberFilter, extensionFilter,setExtensionFilter, agentListsFilter, setAgentsListFilter, startDate, setStartDate,
        endDate, setEndDate, focusedInput, setFocusedInput, handleCancel, setFilterData, extensionListData, callParentCategories, setParentCategoriesFilter, parentCategoriesFilter,
        callAnalysisAdditionalFields, selectedAdditionalFieldsFilter, setSelectedAdditionalFieldsFilter, setFlagAnalysisData, alertsOnlyFilter, setAlertsOnlyFilter } = useContext(CallAnalysisContext) as CallAnalysisContextPayload

    const dateFormat = "DD/MM/YYYY";

    function handleChangePhoneFilter(value: string) {
        setPhoneNumberFilter({ ...phoneNumberFilter, phone_number: value });
    }

    function handleChangeExtensionFilter(value: string) {
        setExtensionFilter({ ...extensionFilter, call_record_extension: value });
    }

    const formatDate = (dateString: Moment | null) => {
        return dateString ? dateString.format('YYYY-MM-DD') : null     
    };

    const handleApplyFilter = () => {
        const filters = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            phoneNumber: phoneNumberFilter.phone_number,
            extension: extensionFilter.call_record_extension,
            category: parentCategoriesFilter,
            purpose: selectedPurposeCategories,
            complexity: selectedComplexityLevels,
            agents: agentListsFilter,
            call_record_additional_field: selectedAdditionalFieldsFilter,
            flag_alert: alertsOnlyFilter ? alertsOnlyFilter : null
        };

        setFilterData(filters)
        setFlagAnalysisData(false)
    };

    const isApplyFilterDisable = () => {
        if(startDate === null && endDate === null && phoneNumberFilter.phone_number === null && extensionFilter === null && agentListsFilter.length === 0 && selectedPurposeCategories.length === 0 && selectedComplexityLevels.length === 0) {
            return true;
        }
        else {
            return false
        }
    }

    const handleAdditionalFieldChange = (add_field_id: number, values: any[]) => {
        setSelectedAdditionalFieldsFilter((prev) => {
            const newValues = values.map(value => ({
                additional_field_value: value.organization_additional_field_option_id
            }));
        
            const existingFilter = prev.find(filter => filter.additional_field_id === add_field_id);
        
            if (existingFilter) {
                return prev.map(filter => 
                    filter.additional_field_id === add_field_id 
                    ? { ...filter, values: newValues } 
                    : filter
                );
            } else {
                return [...prev, { additional_field_id: add_field_id, values: newValues }];
            }
        });
    };

    const largeScreen = useMediaQuery('(max-width : 1600px) and (min-width : 0px)'); 

    return (
        <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"} sx={{zIndex: "1"}}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                <span className={styles.popupTitleStyle}>Call Analysis Filter</span>
            </Grid>
            <Grid item>
                <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <Grid container direction={"column"} gap={"15px"}>
                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"row"} flexWrap={{xs: "wrap", sm: "nowrap"}} gap={"12px"}>
                            <Grid item xs={12} sm={4.5}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Date</span>
                                    </Grid>
                                    <Grid item className={styles.dateRangePickerClass} sx={{width: "100%"}}>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['SingleInputDateRangeField']}>
                                                <DateRangePicker
                                                    slots={{ field: SingleInputDateRangeField }}
                                                    name="allowedRange"
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider> */}
                                        <DateRangePicker
                                            startDate={startDate}
                                            startDateId='s_id'
                                            endDate={endDate}
                                            endDateId='e_id'
                                            onDatesChange={({ startDate, endDate }) => {
                                                setStartDate(startDate);
                                                setEndDate(endDate);
                                            }}
                                            focusedInput={focusedInput}
                                            displayFormat={dateFormat}
                                            isOutsideRange={() => false}
                                            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                            renderCalendarInfo={() => (
                                                <DatePresets
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat={dateFormat}
                                                    handlePresets={(start: Moment, end: Moment) => {
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                    }}
                                                />
                                            )}                                         
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4.5}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Phone Number</span>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                            value={phoneNumbersData?.find((option) => option.phone_number === phoneNumberFilter.phone_number) || null}
                                            onChange={(event: React.SyntheticEvent, value: { phone_number: string } | null) => {
                                                handleChangePhoneFilter(value?.phone_number || '');
                                            }}
                                            getOptionLabel={(option) => option.phone_number}
                                            options={phoneNumbersData || []}
                                            renderInput={(params) => <TextField {...params} placeholder='Select Phone' />}
                                            sx={{ padding: "2px 4px !important" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Extension</span>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                            value={extensionListData?.find((option) => option.call_record_extension === extensionFilter.call_record_extension) || null}
                                            onChange={(event: React.SyntheticEvent, value: { call_record_extension: string } | null) => {
                                                handleChangeExtensionFilter(value?.call_record_extension || '');
                                            }}
                                            // value={extensionFilter || null}
                                            // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                            //     handleChangeExtensionFilter(value)
                                            // }}
                                            getOptionLabel={(option) => option.call_record_extension}
                                            options={extensionListData || []}
                                            renderInput={(params) => <TextField {...params} placeholder='Select' />}
                                            sx={{ padding: "2px 4px !important" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {callAnalysisAdditionalFields && callAnalysisAdditionalFields.map((additionalFields) => 
                        additionalFields.flag_allow_filter && 
                        <Grid item sx={{width: "100%"}} key={additionalFields.call_analysis_additional_field_id}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>{additionalFields.organization_additional_field_name}</span>
                                </Grid>
                                <Grid item>
                                    {additionalFields.organization_additional_field_data_type.toLowerCase() === "dropdown" &&
                                        <Autocomplete
                                            multiple
                                            className={styles.csf_intrested_dropdown}
                                            value={selectedAdditionalFieldsFilter.find((field) => field.additional_field_id === additionalFields.call_analysis_additional_field_id)?.values.map(value => 
                                            additionalFields.organization_additional_field_option.find(option => option.organization_additional_field_option_id === value.additional_field_value)
                                            ) || []}
                                            onChange={(event: React.SyntheticEvent, value: any) => {
                                                handleAdditionalFieldChange(additionalFields.call_analysis_additional_field_id, value);
                                            }}
                                            getOptionLabel={(option) => option.organization_additional_field_option_value || ""}
                                            options={additionalFields.organization_additional_field_option || []}
                                            renderInput={(params) => <TextField {...params} placeholder={`Select ${additionalFields.organization_additional_field_name}`} />}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item sx={{ width: "100%" }}>
                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Category</span>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="category-select"
                                        size='medium'
                                        className={styles.csf_intrested_dropdown}
                                        options={
                                            callParentCategories && callParentCategories.length > 0 && callParentCategories.filter(parentCategory => !parentCategoriesFilter.some(selected => selected.call_parent_category_id === parentCategory.call_parent_category_id))
                                            .map(parent => ({
                                                call_parent_category_id: parent.call_parent_category_id,
                                                call_parent_category_name: parent.call_parent_category_name
                                            })) || []
                                        }
                                        getOptionLabel={(option) => option.call_parent_category_name || ''}
                                        multiple
                                        value={
                                            callParentCategories && callParentCategories.length > 0 && callParentCategories.filter(category => 
                                                parentCategoriesFilter.some(filterCategory => filterCategory.call_parent_category_id === category.call_parent_category_id)
                                            ) || []
                                        }
                                        onChange={(
                                            _: React.SyntheticEvent, 
                                            value: parentCategoryNames[]
                                        ) => {
                                            setParentCategoriesFilter(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Category' />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item sx={{ width: "100%" }}>
                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Sub Category</span>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="agent-select"
                                        size='medium'
                                        className={styles.csf_intrested_dropdown}
                                        options={
                                            callCategories && callCategories.length > 0 && callCategories.filter(category => !selectedPurposeCategories.some(selected => selected.call_category_id === category.call_category_id))
                                            .map(categories => ({
                                                call_category_id: categories.call_category_id,
                                                call_category_name: categories.call_category_name
                                            })) || []
                                        }
                                        getOptionLabel={(option) => option.call_category_name || ''}
                                        multiple
                                        value={
                                            callCategories && callCategories.length > 0 && callCategories.filter(category => 
                                                selectedPurposeCategories.some(filterCategory => filterCategory.call_category_id === category.call_category_id)
                                            ) || []
                                        }
                                        onChange={(
                                            _: React.SyntheticEvent, 
                                            value: categoryNames[]
                                        ) => {
                                            setSelectedPurposeCategories(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Sub Category' />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item sx={{ width: "100%" }}>
                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Complexity</span>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="agent-select"
                                        size='medium'
                                        className={styles.csf_intrested_dropdown}
                                        options={
                                            callComplexity && callComplexity.length > 0 && callComplexity.filter(complexity => !selectedComplexityLevels.some(selected => selected.call_complexity_id === complexity.call_complexity_id))
                                            .map(complexities => ({
                                                call_complexity_id: complexities.call_complexity_id,
                                                call_complexity_level: complexities.call_complexity_level
                                            })) || []
                                        }
                                        getOptionLabel={(option) => option.call_complexity_level || ''}
                                        multiple
                                        value={
                                            callComplexity && callComplexity.length > 0 && callComplexity.filter(complexity => 
                                                selectedComplexityLevels.some(filterAgent => filterAgent.call_complexity_id === complexity.call_complexity_id)
                                            ) || []
                                        }
                                        onChange={(
                                            _: React.SyntheticEvent, 
                                            value: complexityLevels[]
                                        ) => {
                                            setSelectedComplexityLevels(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Complexity' />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item sx={{ width: "100%" }}>
                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Agent</span>
                            </Grid>
                            <Grid item>
                                {/* <Autocomplete
                                    className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                    value={callAgentList.find((agents) => agents.call_center_id === currentAudioAnalysis.call_center_id)?.call_agents.find((param) => param.agent_id == (currentAudioAnalysis.agent_id)) ?? null}
                                    // onChange={(event: React.SyntheticEvent, value: any | null) => {
                                    //     if (value) {
                                    //         props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                    //         props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                    //     } else {
                                    //         props.onChangePayload("", 'function_parameter_type_id', '');
                                    //         props.onChangePayload("", 'function_parameter_type_name', '');
                                    //     }
                                    // }}
                                    getOptionLabel={(option) => option.agent_name}
                                    options={callAgentList.find((agents) => agents.call_center_id === callCenterDetails.call_center_id)?.call_agents || []}
                                    renderInput={(params) => <TextField {...params} placeholder='Select Agent' />}
                                    sx={{ padding: "2px 4px !important" }}
                                /> */}
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="agent-select"
                                        size='medium'
                                        className={styles.csf_intrested_dropdown}
                                        options={
                                            callAgentsData && callAgentsData.length > 0 && callAgentsData.find(
                                                (agents) => agents.call_center_id === callCenterDetails?.call_center_id
                                            )?.call_agents
                                            .filter(agent => !agentListsFilter.some(selected => selected.agent_id === agent.agent_id))
                                            .map(agent => ({
                                                agent_id: agent.agent_id,
                                                agent_name: agent.agent_name
                                            })) || []
                                        }
                                        getOptionLabel={(option) => option.agent_name || ''}
                                        multiple
                                        value={
                                            callAgentsData && callAgentsData.length > 0 && callAgentsData.find(
                                                (agents) => agents.call_center_id === callCenterDetails?.call_center_id
                                            )?.call_agents.filter(agent => 
                                                agentListsFilter.some(filterAgent => filterAgent.agent_id === agent.agent_id)
                                            ) || []
                                        }
                                        onChange={(
                                            _: React.SyntheticEvent, 
                                            value: agentsNames[]
                                        ) => {
                                            setAgentsListFilter(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Agent' />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"row"} gap={1} flexWrap={"nowrap"}>
                            <Grid item>
                                <span className={`${styles.audioFieldLabel} ${styles.darkFontWeightField}`}>Alerts Only</span>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    className={styles.checkBoxFilter}
                                    sx={{display: "inline !important", 'path': {fill: "#6A097D"}}}
                                    // disabled={!editIntegrationData ? true : false}
                                    checked={alertsOnlyFilter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAlertsOnlyFilter(!alertsOnlyFilter)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }} mt={1}>
                        <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                            <Grid item>
                                <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px", fontSize: largeScreen ? "13px !important" : ""}} onClick={() => handleCancel(false)}/>
                            </Grid>
                            <Grid item>
                                <GlobalButton 
                                    buttonText={"Apply Filter"} 
                                    className='primaryButtonStyle' 
                                    sx={{ minWidth: "130px" }} 
                                    onClick={handleApplyFilter}
                                    disabled={isApplyFilterDisable()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
