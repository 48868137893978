import { Checkbox, Divider, Grid, Tooltip } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import IconsColor from './iconsColor';
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/accountInformationCard.module.scss";
import GlobalButton from './globalButton/button';
import metaIcon from "../../assets/images/chatbot/metaIcon.png";
import { chatbotIntegrationAssignmentData } from '../../models/axiosModel';
import GlobalPopUp from './globalPopUp';
import QRCode from "react-qr-code";
import whatsappIcon from '../../assets/Whatsapp.svg'
import businessName from '../../assets/chatbotAssets/businessName.svg'
import QRimage from '../../assets/chatbotAssets/QRimage.png'
import { ChatbotContext, ChatbotContextPayload } from '../../context/chatbotContext';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Details {
    phone_number_id?: string,
    linked_chatbots?: chatbotIntegrationAssignmentData[],
    display_phone_number?: string,
    waba_id?: string,
    verified_name?: string,
    quality_rating?: string,
    business_name?: string,
}
interface AccountInformationCardProps {
    title: string,
    details: Details,
    currentlySelected: boolean,
    flagDelete: boolean,
    flagShowMetaIcon?: boolean,
    deleteChatbotIntegration?: () => void;
    removeChatbotIntegration?: (phone_number_id: string) => void
    addChatbotIntegration?: (phone_number_id: string, event?: React.MouseEvent<HTMLButtonElement>) => void
    showRemoveChatbotIntegration?: boolean,
    flagIntegration?: boolean,
    flagShowLinkedChatbot?: boolean,
    disabled?: boolean
}
const getValueStyle = (keyName: string, value: string) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        case "quality_rating":
            return { color: value.toLowerCase() == "green" ? "#4AC958" : value.toLowerCase(), fontSize: "15px", fontWeight: "500" };
        // case "whatsapp_business_name":
        //     return { fontSize: "15px", fontWeight: "500", color: "#000" };
        //     break;
        // case "business_name":
        //     return { color: "#000", fontSize: "15px", fontWeight: "500" };
        //     break;
        default:
            return { fontSize: "14px", fontWeight: "400", color: "#000" }
    }
}


const getFontColor = (value: any) => {
    switch (value) {
        case "GREEN":
            return "#32BB46"
        case "RED":
            return "#BB3232"
        case "YELLOW":
            return "#D4B132"
        default:
            return "#000000"
    }
}

const getDisplayKeyName = (keyName: string) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        case "waba_id":
            return "WhatsApp Business Id";
        case "verified_name":
            return "Business Name";
        case "display_phone_number":
            return "Phone Number";
        case "quality_rating":
            return "Quality rating";
        case "phone_number_id":
            return "Phone Number ID";
        case "meta_business_id":
            return "Meta Business ID";
        case "linked_chatbots":
            return "Linked Chatbots"
        default:
            return null
    }
}

const getDisplayIcon = (keyName: string) => {
    switch (keyName.toLowerCase().replaceAll(" ", "_")) {
        case "waba_id":
            return <img alt="" src={whatsappIcon} height={"24"} width={"24"} style={{ display: "flex" }} />;
        case "verified_name":
            return <img alt="" src={businessName} height={"24"} width={"24"} />;
        case "display_phone_number":
            return "Phone Number";
        case "quality_rating":
            return "Quality rating";
        case "phone_number_id":
            return "Phone Number ID";
        case "meta_business_id":
            return "Meta Business ID";
        case "linked_chatbots":
            return "Linked Chatbots"
        default:
            return null
    }
}

export default function AccountInformationCard(props: AccountInformationCardProps) {
    const { title, details, currentlySelected, flagDelete, flagShowMetaIcon, showRemoveChatbotIntegration } = props;
    const { chatbotList } = useContext(ChatbotContext) as ChatbotContextPayload
    const [showQRCodePopUp, setShowQRCodePopUp] = useState(false)
    const [phoneNumberQR, setPhoneNumberQR] = useState<any>()
    const [copyText, setCopyText] = React.useState("Copy")
    const svgRef = useRef<HTMLDivElement | null>(null);

    const downloadQRCode = () => {
        if (!svgRef.current) return;

        const svg = svgRef.current.querySelector('svg');
        if (!svg) return;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const svgData = new XMLSerializer().serializeToString(svg);
        const img = new Image();
        const svgSize = svg.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            const downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${phoneNumberQR}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
    };

    return (
        <>
            <Grid item className={styles.mainGrid} style={{ width: props.flagIntegration ? "" : "100%", opacity: props.disabled ? "0.5" : "1" }}>
                <Grid container direction="column">
                    {flagShowMetaIcon &&
                        <Grid item sx={{ background: currentlySelected ? "#FBF4FF" : "#FFFFFF" }} className={styles.header} xs={12}>
                            <Grid container justifyContent={"space-between"}>

                                <Grid item className={globalStyles.displayFlex}>
                                    <Grid container>
                                        <Grid className={globalStyles.displayFlex} item>
                                            <img
                                                alt="meta"
                                                className={styles.metaIconStyle}
                                                src={metaIcon}
                                            />
                                        </Grid>
                                        <Grid item className={styles.headerText}>{title}</Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {flagDelete ?
                                        <>
                                            <GlobalButton buttonText={"Delete"} className="deleteIntegrationButton" onClick={props.deleteChatbotIntegration} startIcon={<IconsColor iconName="DeleteIcon" fillColor="#6A097D" height="16" width="16" />} />
                                            {/* {!flagShowMetaIcon &&  */}
                                            {/* } */}
                                        </>
                                        :
                                        <>
                                            <Grid container gap="10px">
                                                <Checkbox
                                                    checked={currentlySelected}
                                                    className={`${globalStyles.checkboxStyle} ${globalStyles.clickable}`}
                                                    checkedIcon={<IconsColor iconName="checkedIcon" width="22px" height="22px" />}
                                                    icon={<IconsColor iconName="uncheckedIcon" width="22px" height="22px" />}
                                                    onChange={(event) => {
                                                        if (props.addChatbotIntegration && details.phone_number_id && !currentlySelected) {
                                                            props.addChatbotIntegration(details.phone_number_id)
                                                        }
                                                    }}
                                                />
                                                {showRemoveChatbotIntegration && currentlySelected && props.removeChatbotIntegration &&
                                                    <GlobalButton buttonText={"Remove"} className='boxShadowButtonStyle' onClick={() => {
                                                        if (props.removeChatbotIntegration && details.phone_number_id) {
                                                            props.removeChatbotIntegration(details.phone_number_id)
                                                        }
                                                    }} />
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>}
                    {flagShowMetaIcon && <Divider style={{ opacity: "0.8" }} />}

                    {flagShowMetaIcon &&
                        <Grid item mt={2}>
                            <Grid container direction={"row"} gap={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item>
                                    <Grid container direction={"column"} gap={2}>
                                        {Object.keys(details).map((key: string) => {
                                            return getDisplayKeyName(key) !== null &&
                                                <Grid item>
                                                    <Grid container direction={"row"} alignItems={"center"} gap={{ xs: 1, sm: 1, md: 2 }}>
                                                        <Grid item>{getDisplayIcon(key)}</Grid>
                                                        <Grid item className={styles.objectKeyStyle}>{getDisplayKeyName(key)}:</Grid>
                                                    </Grid>
                                                </Grid>
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"column"} gap={{ xs: 2.5, lg: 2.6, xl: 2.1 }}>
                                        {Object.entries(details).map(([key, value]) => {
                                            if (key == "linked_chatbots") {
                                                return getDisplayKeyName(key) !== null &&
                                                    <Grid className={styles.genericValueStyle} item>
                                                        {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                            return <a rel="noreferrer" href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbot.chatbot_name}</a>
                                                        }) : <span>None</span>
                                                        }
                                                    </Grid>
                                            }

                                            else if (key == "display_phone_number") {
                                                return getDisplayKeyName(key) !== null &&
                                                    <Grid sx={getValueStyle(key, value)} className={styles.genericValueStyle} item><span style={{ color: "#6A097D", textDecoration: "underline", cursor: "pointer" }} onClick={() => { setShowQRCodePopUp(true); setPhoneNumberQR(value) }}>{String(value)}</span></Grid>
                                            }

                                            else {
                                                return getDisplayKeyName(key) !== null &&
                                                    <Grid item sx={getValueStyle(key, value)} className={styles.genericValueStyle}>{String(value)}</Grid>
                                            }
                                        })}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    }

                    {!flagShowMetaIcon &&
                        <Grid item>
                            <Grid container className={styles.facebookIntegrationCard} direction={"column"}>
                                <Grid item className={styles.integrationWabaID}>
                                    <span className={styles.pageIDStyle}>ID: {props.details.phone_number_id}</span>
                                </Grid>

                                <Grid item>
                                    <Grid container justifyContent={"end"}>
                                        <Grid item className={styles.wpintegrationIcon} sx={{ backgroundColor: "#A7E3B0" }}>
                                            <img alt="" src={whatsappIcon} height={"32"} width={"32"} />
                                        </Grid>
                                        {!props.flagIntegration &&
                                            <Grid item className={styles.connectionStyle} sx={{ color: details.linked_chatbots && details.linked_chatbots.length > 0 ? "#32BB46" : "#BB3232" }}>
                                                <span className={styles.wpconnectionTextStyle}>{details.linked_chatbots && details.linked_chatbots.length > 0 ? "Connected" : "Not Connected"}</span>
                                            </Grid>
                                        }
                                        {props.flagIntegration &&
                                            <Grid item className={styles.connectionStyle} sx={{ color: currentlySelected ? "#32BB46" : "#BB3232" }}>
                                                <span className={styles.wpconnectionTextStyle}>{currentlySelected ? "Connected" : "Not Connected"}</span>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item className={styles.integrationPageName}>
                                    <Grid container direction={"row"} gap={1} justifyContent={"space-between"}>
                                        <Grid item>
                                            <span className={styles.phoneNumberDefaultStyle}>{props.details.display_phone_number}</span>
                                        </Grid>
                                        <Grid item>
                                            {!props.disabled && <img alt='' src={QRimage} height={"26"} width={"26"} onClick={() => { setShowQRCodePopUp(true); setPhoneNumberQR(props.details.display_phone_number) }} style={{ cursor: "pointer" }} />}
                                            {props.disabled && <img alt='' src={QRimage} height={"26"} width={"26"} style={{ cursor: "pointer" }} />}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={styles.businessName}>
                                    <Grid container direction={"column"}>
                                        <Grid item className={styles.pageCategoryStyle}>
                                            <span>Business Account</span>
                                        </Grid>
                                        <Grid item className={styles.chatbotNameStyle}>
                                            <span>{props.details.business_name}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={styles.integrationPageCategory}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item className={styles.pageCategoryStyle}>
                                                    <span>Quality rating</span>
                                                </Grid>
                                                <Grid item className={styles.qualityRatingTextStyle} sx={{ color: getFontColor(props.details.quality_rating) }}>
                                                    <span>{props.details.quality_rating}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {!props.flagShowLinkedChatbot && showRemoveChatbotIntegration && props.flagIntegration &&
                                            currentlySelected && props.removeChatbotIntegration &&
                                            <Grid item>
                                                <GlobalButton buttonText={"Un-deploy"} className='delinkChatbotButtonStyle' onClick={() => {
                                                    if (props.removeChatbotIntegration && details.phone_number_id) {
                                                        props.removeChatbotIntegration(details.phone_number_id)
                                                    }
                                                }} />
                                            </Grid>
                                        }
                                        {!props.flagShowLinkedChatbot && !showRemoveChatbotIntegration && props.flagIntegration && props.removeChatbotIntegration && props.addChatbotIntegration &&
                                            <Grid item>
                                                <GlobalButton buttonText={"Deploy"} className='linkChatbotButtonStyle' onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                    if (props.addChatbotIntegration && details.phone_number_id && !currentlySelected) {
                                                        props.addChatbotIntegration(details.phone_number_id, event)
                                                    }
                                                }} disabled={currentlySelected} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>


                                {props.flagShowLinkedChatbot &&
                                    <Grid item className={styles.integrationPageCategory}>
                                        <Grid container direction={"row"} gap={1} justifyContent={props.flagShowLinkedChatbot ? "space-between" : "end"}>
                                            <Grid item>
                                                <Grid container direction={"column"}>
                                                    <Grid item className={styles.pageCategoryStyle}>
                                                        <span>Linked Chatbot</span>
                                                    </Grid>
                                                    {!props.flagIntegration &&
                                                        <Grid item className={styles.chatbotNameStyle}>
                                                            {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                return <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbot.chatbot_name}</a>
                                                            }) : <span style={{ fontWeight: "500" }}>None</span>}
                                                        </Grid>
                                                    }
                                                    {props.flagIntegration &&
                                                        <Grid item className={styles.chatbotNameStyle}>
                                                            {details.linked_chatbots && details.linked_chatbots.length > 0 ? details.linked_chatbots.map((chatbot) => {
                                                                return props.disabled ? <span className={styles.chatbotNameLink}>{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</span> : <a rel="noreferrer" className={styles.chatbotNameLink} href={`${window.location.origin}/chatbot/viewChatbot?chatbot_id=${chatbot.chatbot_id}`} target="_blank">{chatbotList?.find((list) => Number(list.chatbot_id) == chatbot.chatbot_id)?.chatbot_name}</a>
                                                            }) : <span style={{ fontWeight: "500" }}>None</span>}
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            {showRemoveChatbotIntegration && props.flagIntegration &&
                                                currentlySelected && props.removeChatbotIntegration &&
                                                <Grid item>
                                                    <GlobalButton buttonText={"Un-deploy"} className='delinkChatbotButtonStyle' onClick={() => {
                                                        if (props.removeChatbotIntegration && details.phone_number_id) {
                                                            props.removeChatbotIntegration(details.phone_number_id)
                                                        }
                                                    }} />
                                                </Grid>
                                            }
                                            {!showRemoveChatbotIntegration && props.flagIntegration && props.removeChatbotIntegration && props.addChatbotIntegration &&
                                                <Grid item>
                                                    <GlobalButton buttonText={"Deploy"} className='linkChatbotButtonStyle' onClick={(event) => {
                                                        if (props.addChatbotIntegration && details.phone_number_id && !currentlySelected) {
                                                            props.addChatbotIntegration(details.phone_number_id, event)
                                                        }
                                                    }} disabled={currentlySelected} />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>}

                                {/* {props.flagIntegration &&
                                    <Grid item className={styles.integrationPageCategory}>
                                        <Grid container gap="10px" >
                                            <Grid item sx={{ color: details.linked_chatbots && details.linked_chatbots.length > 0 || currentlySelected ? "#32BB46" : "#BB3232" }}>
                                                <Checkbox
                                                    checked={currentlySelected}
                                                    className={`${globalStyles.checkboxStyle} ${globalStyles.clickable} ${styles.selectedChatbotstyle}`}
                                                    checkedIcon={<IconsColor iconName="checkedIcon" width="22px" height="22px" fillColor="#32BB46" />}
                                                    icon={<IconsColor iconName="uncheckedIcon" width="22px" height="22px" />}
                                                    onChange={() => {
                                                        if (props.addChatbotIntegration && details.phone_number_id && !currentlySelected) {
                                                            props.addChatbotIntegration(details.phone_number_id)
                                                        }
                                                    }}
                                                />
                                                <span className={styles.wpintegrationTextStyle}>{props.flagIntegration ? currentlySelected ? "Connected" : "Not Connected" : details.linked_chatbots && details.linked_chatbots.length > 0 ? "Connected" : "Not Connected"}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                } */}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>

            {showQRCodePopUp &&
                <GlobalPopUp
                    open={showQRCodePopUp}
                    onClose={() => { setShowQRCodePopUp(false) }}
                    // title={"Scan QR Code to integrate"}
                    flagBackdrop
                    flagShowCloseIcon={true}
                    handleClose={() => { setShowQRCodePopUp(false) }}
                    maxWidth="lg"
                >
                    <Grid container className={styles.qrpopupCardDesign} direction={"column"} alignItems={"center"} >
                        <Grid item marginBottom={"25px"}>
                            <span className={styles.qrcodeTitleDesign}>Scan QR Code to view in WhatsApp</span>
                        </Grid>
                        <Grid item className={globalStyles.fullwidth} sx={{ marginBottom: "7px", maxWidth: "256px !important" }}>
                            <Grid container gap="10px" justifyContent={"end"}>
                                <CopyToClipboard text={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}>
                                    <Tooltip
                                        sx={{ zIndex: "1300" }}
                                        title={copyText}
                                        onMouseLeave={() => setCopyText("Copy!")}
                                        onClick={() => {
                                            setCopyText("Copied!");
                                        }}
                                    >
                                        <Grid item className={globalStyles.clickable}>
                                            <IconsColor iconName='Copy' fillColor="#000" width="18px" height="18px" />
                                        </Grid>
                                    </Tooltip>
                                </CopyToClipboard>
                                <Tooltip sx={{ zIndex: "1300" }} title={"Download QR"}>
                                    <Grid item className={globalStyles.clickable} onClick={downloadQRCode}>
                                        <IconsColor iconName='download' fillColor="#000" width="25px" height="25px" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ cursor: "pointer", zIndex: "1" }}>
                            <a href={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}
                                rel="noreferrer"
                                target='_blank'
                            >

                                <div ref={svgRef}>
                                    <QRCode value={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} />
                                </div>
                            </a>
                        </Grid>
                    </Grid>
                </GlobalPopUp>
            }
        </>
    )
}
