import { Box, Grid, Typography } from '@mui/material'
import styles from "../../assets/styles/componentStyles/utility/consultation.module.scss";
import IconsColor from './iconsColor';
import { primaryColor } from '../../utils/constants';
import globalStyles from "../../assets/styles/global.module.scss";
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
interface consultationComponent {
    flagInsideSideNavigationPanel?: boolean;
    flagMobile?: boolean;
    onClickConsultation?: () => void;
    onCloseConsultation?: () => void
}
export const ConsultationComponent = (props: consultationComponent) => {
    const { handleCloseConsultationBar, handleOpenConsultationBar, openConsultationBar, setOpenConsultationBar, flagHideConsultation, handleHideConsultation } = useContext(UserContext) as UserContextPayload
    const [flagMainPage, setFLagMainPage] = useState(true)
    useEffect(() => {
        if (window.location.pathname.length > 10) {
            setFLagMainPage(false)
        } else {
            setFLagMainPage(true)
        }
    }, [window.location.pathname])
    return (
        <>
            {(props.flagInsideSideNavigationPanel || flagHideConsultation) ?
                <>

                    {(!flagMainPage || flagHideConsultation) &&
                        <Box onClick={() => { setOpenConsultationBar(!openConsultationBar) }} className={props.flagMobile ? styles.mainBlockMobile : styles.mainBlock}>
                            <Box className={styles.mainBlockChild}>
                                <IconsColor iconName="consult" fillColor={primaryColor} width="23px" height="23px" />
                                {!props.flagMobile &&
                                    <Typography className={styles.nameStyle} sx={{ maxWidth: "125px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        Consult Expert
                                    </Typography>
                                }
                            </Box>
                        </Box>}
                </>
                :
                <>
                    {flagMainPage &&
                        <Box className={styles.mainPageDesign}>
                            <Box className={styles.closeIconContainer} onClick={handleHideConsultation}>
                                <IconsColor iconName="closeIcon" fillColor={"#fff"} width="10px" height="10px" />
                            </Box>
                            <Grid container gap="12px" onClick={handleOpenConsultationBar}>
                                <Grid item className={styles.logoContainer}>
                                    <IconsColor iconName="consult" fillColor={primaryColor} width="25px" height="25px" />
                                </Grid>
                                <Grid item className={globalStyles.displayFlex}>
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <Typography className={styles.heading}>Setup an Expert Consultation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={styles.subHeading}>Schedule Call
                                                <IconsColor iconName="forwardArrow" fillColor={primaryColor} width="12px" height="12px" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>}
                </>
            }
        </>
    )
}
