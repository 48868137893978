import { Pagination, Stack, TablePagination, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'

const CallAnalysisPagination = () => {
    const {applyFilters, filterDate, callRecordsDetails, callRecordPagination, page, setPage, setCurrentAudioAnalysis, waveLoading} = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        if(!waveLoading) {
            setPage(newPage);
            const paginationData = {
                records_per_page: rowsPerPage,
                page_number: newPage + 1
            }
            setCurrentAudioAnalysis(null)
        }  
        // applyFilters(filterDate, paginationData)
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const paginationData = {
            records_per_page: parseInt(event.target.value, 10),
            page_number: 1
        }
        applyFilters(filterDate, paginationData)
    };

    return (
        <TablePagination
            component="div"
            className={styles.tablePaginationStyle}
            count={callRecordsDetails?.length || 50}
            page={page}
            labelRowsPerPage="Records:"
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}

export default CallAnalysisPagination
