import { Autocomplete, Divider, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import styles from '../../../../assets/styles/componentStyles/messageFormatForms/messageFormats.module.scss'
import formStyles from '../../../../assets/styles/componentStyles/messageFormatForms/messageFormatForms.module.scss'
import Required from '../../../utility/required'
import GlobalButton from '../../../utility/globalButton/button'
import globalStyles from '../../../../assets/styles/global.module.scss'

interface ctaButtonInterface {
    setCarouselCardFormat?: any,
    setAddCTAButtonPopup?: any,
    carouselCardFormat?: any
}

interface ctaButtonActionType {
    cta_action_type_id: number,
    cta_action_type_value: string
}

interface ctaButton {
    cta_button_id: number,
    cta_button_name: string,
    cta_action_type: ctaButtonActionType,
    cta_action_example: string,
}

const initialCTAButton = {
    cta_button_id: 0,
    cta_button_name: "",
    cta_action_type: {
        cta_action_type_id: 0,
        cta_action_type_value: ""
    },
    cta_action_example: "",
}

const actionTypeList: any = [
    {
        action_type_id: 1,
        action_type_value: "URL"
    },
    {
        action_type_id: 2,
        action_type_value: "iFrame"
    },
    {
        action_type_id: 3,
        action_type_value: "Form"
    }
]

const AddCTAButton = (props: ctaButtonInterface) => {
    const [ctaButtons, setCtaButton] = useState<ctaButton>(initialCTAButton);
    const { carouselCardFormat, setCarouselCardFormat, setAddCTAButtonPopup } = props

    const handleChangeCTAButton = (value: string, field: string) => {
        setCtaButton((prevState) => ({ ...prevState, [field]: value, cta_button_id: carouselCardFormat.format_cta_buttons.length + 1 }))
    }

    const handleChangeCTAButtonType = (value: string | number, field: string) => {
        setCtaButton((prevState) => ({ ...prevState, cta_action_type: { ...prevState.cta_action_type, [field]: value }, cta_button_id: carouselCardFormat.format_cta_buttons.length + 1 }))
    }

    const handleCancelCTAButton = () => {
        setCtaButton(initialCTAButton)
    }

    const handleAddCTAButton = () => {
        setCarouselCardFormat((prevState: any) => ({ ...prevState, format_cta_buttons: [...prevState.format_cta_buttons, ctaButtons] }));
        setAddCTAButtonPopup(false);
    }

    const handleAddCTAButtonDisabled = () => {
        if (ctaButtons.cta_button_name.trim() === "" || ctaButtons.cta_action_example.trim() === "" || ctaButtons.cta_action_type.cta_action_type_value.trim() === "") {
            return true
        } else {
            return false
        }
    }


    return (
        <Grid container direction={"column"} gap={"20px"} padding={"0px 30px 30px 30px"}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                <span className={styles.popupTitleStyle}>Add New CTA Button</span>
            </Grid>
            <Grid item>
                <Divider style={{ opacity: "0.3", color: "#9D9D9D", border: "0.2px solid #9D9D9D" }} />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <Grid container direction={"column"} gap={"15px"}>
                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item className={formStyles.formFieldLabels}>Button Name <Required /></Grid>
                            <Grid item>
                                <TextField
                                    value={ctaButtons.cta_button_name}
                                    placeholder='Enter Button Name'
                                    className={formStyles.formFieldsTextField}
                                    onChange={(event) => handleChangeCTAButton(event.target.value, 'cta_button_name')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item className={formStyles.formFieldLabels}>Action Type <Required /></Grid>
                            <Grid item>
                                <Autocomplete
                                    // className={styles.formFieldMethod}
                                    className={`${globalStyles.autocompleteParaphrasing} ${formStyles.autoComplete}`}
                                    value={actionTypeList.find((param: any) => param.action_type_id == ctaButtons.cta_action_type.cta_action_type_id) ?? null}
                                    onChange={(event: React.SyntheticEvent, value: any | null) => {
                                        if (value) {
                                            handleChangeCTAButtonType(value.action_type_id, 'cta_action_type_id');
                                            handleChangeCTAButtonType(value.action_type_value, 'cta_action_type_value');
                                        } else {
                                            handleChangeCTAButtonType("", 'cta_action_type_id');
                                            handleChangeCTAButtonType("", 'cta_action_type_value');
                                        }
                                    }}
                                    getOptionLabel={(option) => option.action_type_value}
                                    options={actionTypeList}
                                    renderInput={(params) => <TextField {...params} placeholder='Select Type' />}
                                    sx={{ padding: "2px 4px !important" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Grid container direction={"column"} gap={1}>
                            <Grid item className={formStyles.formFieldLabels}>Action Example <Required /></Grid>
                            <Grid item>
                                <TextField
                                    value={ctaButtons.cta_action_example}
                                    placeholder='Enter Action Example'
                                    className={formStyles.formFieldsTextField}
                                    onChange={(event) => handleChangeCTAButton(event.target.value, 'cta_action_example')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        {/* <Grid container direction={"column"} gap={1}>
                            <Grid item className={formStyles.formFieldLabels}>Upload Button Icon <Required /></Grid>
                            <Grid item>
                                
                            </Grid>
                        </Grid> */}
                    </Grid>

                    <Grid item sx={{ width: "100%" }} mt={1}>
                        <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                            <Grid item>
                                <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' onClick={() => handleCancelCTAButton()} sx={{ minWidth: "130px" }} />
                            </Grid>
                            <Grid item>
                                <GlobalButton buttonText={"Add"} className='primaryButtonStyle' onClick={() => handleAddCTAButton()} sx={{ minWidth: "130px" }} disabled={handleAddCTAButtonDisabled()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddCTAButton
