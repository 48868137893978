import { Button, Grid, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import GlobalButton from './utility/globalButton/button'
import globalStyles from './../assets/styles/global.module.scss'
import headerStyles from './../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import onboradingStyles from './../assets/styles/componentStyles/onBoarding/onBoarding.module.scss'
import styles from './../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import chronoxLogo from './../assets/images/global/Chronox Logo.svg'
import metaProvider from './../assets/images/global/MetaVerified.svg'
import calendar from './../assets/images/global/calendar.svg'
import onBoardingAIAgent from './../assets/images/global/onBoardingAIAgent.svg'
import onBoardingAIAgentMobile from './../assets/images/global/onBoardingAIAgentMobile.svg'
import onBoardingAICopilot from './../assets/images/global/onBoardingAICopilot.svg'
import onBoardingAICopilotMobile from './../assets/images/global/onBoardingAICopilotMobile.svg'
import onBoardingAIInsights from './../assets/images/global/onBoardingAIInsights.svg'
import onBoardingAIInsightsMobile from './../assets/images/global/onBoardingAIInsightsMobile.svg'
import { UserContext, UserContextPayload } from '../context/userContext'

const OnBoardingNew = () => {
    const {setFlagShowScheduleCall, userDetail} = useContext(UserContext) as UserContextPayload;
    const innerHeight = (window.innerHeight - 10);
    const smallScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)')
    const mobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')

    const [fadeIn, setFadeIn] = React.useState(false);
    React.useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper} ${onboradingStyles.contentBackgroundStyle}`}>
            <Grid container sx={{ padding: { xs: "15px 25px", sm: "18px 25px", height: `${innerHeight}px !important`}, maxHeight: "100vh !important" }} flexWrap={"nowrap"} justifyContent={"center"}>
                <Grid item xs={12} sm={11} md={11} lg={11.5} xl={11}>
                    <Grid container direction={"column"} justifyContent={{lg:"space-between"}} gap={{xs: "20px", lg:"10px"}} mt={{xs: "10px", lg:"20px"}} sx={{height: "100%"}} flexWrap={"nowrap"}>
                        <Grid item>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item className={`${onboradingStyles.chronoxLogoAnimationStyle} ${fadeIn ? onboradingStyles.fadeLogoInClass : ''}`}>
                                    <img src={chronoxLogo} alt="" className={onboradingStyles.chronoxLogoStyle}/>
                                </Grid>
                                {smallScreen &&
                                    <Grid item className={`${onboradingStyles.metaProviderImageAnimationStyle} ${fadeIn ? onboradingStyles.fadeMetaInClass : ''}`}>
                                        <img src={metaProvider} alt="" className={onboradingStyles.metaproviderImageStyle}/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item paddingBlock={{xs: "8px 15px", sm: "10px 20px", lg: "10px 30px"}}>
                            <Grid container gap={{xs:"30px", lg: "20px", xl: "30px"}} alignItems={"center"} flexWrap={{xs:"wrap", lg:"nowrap"}} justifyContent={"center"}>
                                <Grid item xs={12} md={9} lg={5.2} xl={5.7} className={`${onboradingStyles.leftContainerBoxStyle} ${onboradingStyles.chronoxLogoAnimationStyle} ${fadeIn ? onboradingStyles.fadeLogoInClass : ''}`}>
                                    <Grid container justifyContent={"center"} gap={{xs: "20px", lg:"28px", xl: "35px"}} textAlign={"center"}>
                                        <Grid item xs={12} className={onboradingStyles.textWrtingAnimationStyle}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <span className={onboradingStyles.typingEffect}>
                                                        <span className={onboradingStyles.lightColorFontStyle}>Hello</span>
                                                        <span className={`${onboradingStyles.darkColorFontStyle}`}>{userDetail.user_first_name ? ` ${userDetail.user_first_name},` : " There,"}</span><br />
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span className={onboradingStyles.typingEffect}>
                                                        <span className={`${onboradingStyles.darkColorFontStyle}`}>Welcome to Chronox AI!</span>
                                                    </span>   
                                                </Grid>
                                            </Grid>                                                                             
                                            {/* <span className={onboradingStyles.typingEffect}><span className={onboradingStyles.lightColorFontStyle}>READY</span>
                                            <span className={`${onboradingStyles.darkColorFontStyle}`}>{` TO BEGIN?`}</span></span> */}
                                        </Grid>

                                        <Grid item xs={11.5} sm={9} lg={10}>
                                            {/* <span className={onboradingStyles.contentStyle}>Book an exclusive 30-minutes 1:1 session tailored just for you!</span> */}
                                            <span className={onboradingStyles.contentStyle}>Our onboarding team will contact you soon...</span><br/>
                                            <span className={onboradingStyles.contentStyle}>In the meantime, you’re welcome to schedule the meeting and explore more about our platform.</span>
                                        </Grid>
                                        <Grid item xs={12} className={`${onboradingStyles.chronoxLogoAnimationStyle} ${fadeIn ? onboradingStyles.fadeLogoInClass : ''}`}>
                                            <img src={calendar} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container gap={{xs: "20px", lg:"30px"}} justifyContent={"center"} flexWrap={{xs: "wrap", lg:"nowrap"}}>
                                                <Grid item>
                                                    <Button className={onboradingStyles.bookameetingButtonStyle} onClick={() => setFlagShowScheduleCall(true)}>
                                                        Book a Meeting!
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button className={onboradingStyles.chatonwhatsappButtonStyle} onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                        Chat on WhatsApp
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={10} lg={6.8} xl={6.3}>
                                    <Grid container justifyContent={"space-evenly"} gap={1} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Grid container direction={"column"} alignItems={"center"} className={`${onboradingStyles.agentImageStyles} ${fadeIn ? onboradingStyles.fadeInClass : ''}`}>
                                                <Grid item>
                                                    <img src={mobileScreen ? onBoardingAIAgentMobile : onBoardingAIAgent} className={onboradingStyles.imageMaxWidthStyle}/>
                                                </Grid>
                                                <Grid item>
                                                    <span className={`${onboradingStyles.rightContainerTextStyle} ${onboradingStyles.aiAgentColorStyle}`}>AI Agent</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"} alignItems={"center"} className={`${onboradingStyles.agentImageStyles} ${fadeIn ? onboradingStyles.fadeInClass : ''}`}>
                                                <Grid item>
                                                    <img src={mobileScreen ? onBoardingAICopilotMobile : onBoardingAICopilot} className={onboradingStyles.imageMaxWidthStyle}/>
                                                </Grid>
                                                <Grid item>
                                                    <span className={`${onboradingStyles.rightContainerTextStyle} ${onboradingStyles.aiCopilotColorStyle}`}>AI Copilot</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"} alignItems={"center"} className={`${onboradingStyles.agentImageStyles} ${fadeIn ? onboradingStyles.fadeInClass : ''}`}>
                                                <Grid item>
                                                    <img src={mobileScreen ? onBoardingAIInsightsMobile : onBoardingAIInsights} className={onboradingStyles.imageMaxWidthStyle}/>
                                                </Grid>
                                                <Grid item>
                                                    <span className={`${onboradingStyles.rightContainerTextStyle} ${onboradingStyles.aiInsightsColorStyle}`}>AI Insights</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!smallScreen &&
                            <Grid item alignSelf={"end"} mb={"20px"} className={`${onboradingStyles.metaProviderImageAnimationStyle} ${fadeIn ? onboradingStyles.fadeMetaInClass : ''}`}>
                                <img src={metaProvider} alt="" className={onboradingStyles.metaproviderImageStyle}/>
                            </Grid>
                        }
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OnBoardingNew