import { Autocomplete, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ContactCardTemplate from './contactCardTemplate'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { campaignContactFieldData, createCampaignContactFieldPayload, getCampaignContactListResponse, getListOfCampaignContactFieldResponse, importCampaignContactDataPayload } from '../../../models/axiosModel';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';
import agent from '../../../services/api';
import csv from "csvtojson";
import style from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import globalStyles from "../../../assets/styles/global.module.scss";
import mappingStyles from './../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss'
import GlobalButton from '../../utility/globalButton/button';
import uploadGif from "../../../assets/images/campaignContact/upload.gif";
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants';
import AddIcon from '@mui/icons-material/Add';
import { useSearchParams } from 'react-router-dom';

interface CreateNewContactPopupProps {
    selectedCampaignContactList: getCampaignContactListResponse,
    importCampaignContactDataApiCall: (payload: importCampaignContactDataPayload) => void,
    setFlagOnOkayClick: React.Dispatch<React.SetStateAction<boolean>>,
    getListOfCampaignFieldTypeApiCall: () => void,
    getListOfCampaignContactFieldApiCall: () => void,
    createCampaignContactFieldApiCall: (payload: createCampaignContactFieldPayload) => void,
}

const CreateNewContactPopup: React.FC<CreateNewContactPopupProps> = ({ selectedCampaignContactList, importCampaignContactDataApiCall, setFlagOnOkayClick, getListOfCampaignFieldTypeApiCall, getListOfCampaignContactFieldApiCall, createCampaignContactFieldApiCall }) => {
    const { flagLoading, setFlagLoading, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;
    const { getListOfCampaignContactFieldApi, campaignContactFieldData, setCampaignContactFieldData, importCampaignContactDataApi, getCampaignContactApi, setFlagCreateNewContactDialog, campaignContactFieldTypeData } = useContext(ContactContext) as ContactContextPayload;

    const [files, setFiles] = useState<any>([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });

    const [flagMapTheField, setFlagMapTheField] = useState<boolean>(false);
    const [flagProcessingContact, setFlagProcessingContact] = useState<boolean>(false);

    const [fieldsOfCSV, setFieldsOfCSV] = useState<string[]>([]);
    const [jsonArray, setJsonArray] = useState<any[]>([]);
    const [dummyCampaignContactFieldData, setDummyCampaignContactFieldData] = useState<getListOfCampaignContactFieldResponse[]>([]);
    const [flagTextField, setFlagTextField] = useState<boolean>(false);

    const [mapTheField, setMapTheField] = useState<(string)[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const list_id = searchParams.get('contact_list_id');

    const onClickOfImportButton = async (storage_id: number) => {
        getListOfCampaignContactFieldApiCall();
        // const file = await agent.getFile.get(storage_id);
        // const file1 = new File([file], file.original_file_name);
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onload = async (e) => {
        //         const csvStr = (e.target?.result) as string;
        //         try {
        //             const jsonArray = await csv().fromString(csvStr);
        //             setFieldsOfCSV(Object.keys(jsonArray[0]));
        //         } catch (error) {
        //             console.log("csv to json Error Occured csv to json, ", error);
        //         }
        //     };
        //     reader.readAsText(file1);
        // }
    }

    const handleChangeField = (newValue: string | null, ind: number) => {
        setMapTheField((fields) => {
            return fields.map((field, index) => {
                if (index === ind) {
                    if (newValue) return newValue;
                    else return "";
                }
                return field;
            })
        })
    }

    const checkDisableContinue = () => {
        if (mapTheField && mapTheField.length > 0) {
            // if (mapTheField.every((field) => field.length > 0)) {
            //     return false;
            // }
            // return true;  
            dummyCampaignContactFieldData[0].contact_field_data.map((field, index) => {
                if (field.contact_field_name === "Phone Number") {
                    if (mapTheField[index].length > 0) return false;
                }
            })
        }
        else return true;
    }

    const importContactDataPayload = () => {
        const mappingObj = campaignContactFieldData[0].contact_field_data.map((data, ind) => {
            if (mapTheField[ind] && mapTheField[ind].length > 0) {
                return {
                    contact_field_id: data.contact_field_id,
                    contact_import_log_mapping_value: mapTheField[ind],
                }
            }
            return null;
        }).filter((item): item is { contact_field_id: number; contact_import_log_mapping_value: string; } => item !== null);

        return {
            contact_list_id: selectedCampaignContactList.contact_list_id,
            contact_import_log_google_storage_id: files[0].file_data.google_storage_id,
            contact_import_log_mapping: mappingObj,
        }
    }

    const onClickOfContinueBtn = () => {
        const payload = importContactDataPayload();
        setFlagProcessingContact(true);
        importCampaignContactDataApiCall(payload);
    }

    const onNewFieldClick = () => {
        if (campaignContactFieldTypeData.length === 0) {
            getListOfCampaignFieldTypeApiCall();
        }
        setFlagTextField(true);
        const dummyFields = [...dummyCampaignContactFieldData];
        dummyFields[0].contact_field_data = [...dummyFields[0].contact_field_data, {
            contact_field_id: 0,
            flag_default: false,
            contact_field_name: "",
            contact_field_type_id: 0,
            contact_field_type_name: "Text",
            contact_field_seq: sortFields(dummyFields[0].contact_field_data)[dummyFields[0].contact_field_data.length - 1].contact_field_seq + 1,
            created_at_timestamp: "",
            updated_at_timestamp: "",
        }]
        setDummyCampaignContactFieldData(dummyFields);
    }

    const onBlurSave = async () => {
        setFlagTextField(false);
        if (dummyCampaignContactFieldData[0].contact_field_data[dummyCampaignContactFieldData[0].contact_field_data.length - 1].contact_field_name.trim().length > 0) {
            if (campaignContactFieldTypeData.length === 0) {
                await getListOfCampaignFieldTypeApiCall();
            }
            const payload = {
                contact_list_id: Number(list_id),
                contact_field_data: [{
                    contact_field_type_id: campaignContactFieldTypeData?.find(
                        (type) => type.contact_field_type_name === "Text"
                    )?.contact_field_type_id ?? 1,
                    contact_field_name: dummyCampaignContactFieldData[0].contact_field_data[dummyCampaignContactFieldData[0].contact_field_data.length - 1].contact_field_name,
                }]
            }
            createCampaignContactFieldApiCall(payload);
        } else {
            const dummyFields = [...dummyCampaignContactFieldData];
            dummyFields[0].contact_field_data.pop();
            setDummyCampaignContactFieldData(dummyFields);
        }
    }

    const sortFields = (fields: campaignContactFieldData[]) => {
        return fields.sort((a, b) => a.contact_field_seq - b.contact_field_seq);
    }

    useEffect(() => {
        if (campaignContactFieldData.length > 0 && fieldsOfCSV.length > 0) {
            const data = campaignContactFieldData[0].contact_field_data;
            if (data) {
                let mapFields = [];
                for (let i = 0; i < data.length; ++i) {
                    if (fieldsOfCSV.find((field) => field === data[i].contact_field_name)) {
                        mapFields.push(data[i].contact_field_name);
                    } else {
                        mapFields.push("");
                    }
                }
                setMapTheField(mapFields);
                if (!flagMapTheField && files.length > 0) setFlagMapTheField(true);
            }
        }
    }, [campaignContactFieldData])

    useEffect(() => {
        if (fieldsOfCSV.length > 0 && mapTheField.length > 0) {
            const commonFields = fieldsOfCSV.filter((field) => !mapTheField.includes(field));
            setDropdownOptions(commonFields);
        }
    }, [mapTheField])

    useEffect(() => {
        if (campaignContactFieldData.length > 0) {
            setDummyCampaignContactFieldData(campaignContactFieldData);
        }
    }, [campaignContactFieldData])


    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={mappingStyles.popupTitleTypography}> {!flagMapTheField ? "Create New Contact" : flagProcessingContact ? "Import Contacts" : "Map The Fields"}</Typography>
                        </Grid>

                        <Divider className={mappingStyles.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={mappingStyles.popupBodyGridContainer} gap={1}>
                                <Grid item xs={12}>
                                    {!flagMapTheField ?
                                        <ContactCardTemplate
                                            files={files}
                                            setFiles={setFiles}
                                            progress={progress}
                                            setProgress={setProgress}
                                            onClickOfImportButton={onClickOfImportButton}
                                            setFieldsOfCSV={setFieldsOfCSV}
                                            jsonArray={jsonArray}
                                            setJsonArray={setJsonArray}
                                        />
                                        :
                                        flagProcessingContact ?
                                            <Grid container textAlign={"center"} gap={"12px"} justifyContent={"center"} alignItems={"center"}>
                                                <Grid item xs={12}>
                                                    <img src={uploadGif} alt="upload gif" height={"120px"} width={"120px"} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>
                                                        Please wait while we process your contact. It may take a few minutes to import all contacts.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} mt={2} mb={2}>
                                                    <GlobalButton
                                                        buttonText="Okay"
                                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                                        className='primaryButtonStyle'
                                                        onClick={() => { setFlagOnOkayClick(true); setFlagCreateNewContactDialog(false); }}
                                                    >
                                                    </GlobalButton>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container>
                                                <Grid item xs={6} marginBottom={"10px"}>
                                                    <Typography sx={{ fontSize: "15px", fontWeight: "500", color: "#000" }}>Contact List Fields</Typography>
                                                </Grid>
                                                <Grid item xs={6} marginBottom={"10px"}>
                                                    <Typography sx={{ fontSize: "15px", fontWeight: "500", color: "#000" }}>CSV Template Fields</Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {sortFields(dummyCampaignContactFieldData[0].contact_field_data).map((data, ind) => {
                                                        return (
                                                            <Grid container flexWrap={"nowrap"} alignItems={"center"} className={mappingStyles.mappingFieldsContainer}>
                                                                <Grid item xs={6} sx={{ background: "#fbf4ff", padding: (ind === dummyCampaignContactFieldData[0].contact_field_data.length - 1 && flagTextField) ? "" : "12.5px 7px", minHeight: "46px" }} className={mappingStyles.fieldsLabelStyle}>
                                                                    {(ind === dummyCampaignContactFieldData[0].contact_field_data.length - 1 && flagTextField) ?
                                                                        <TextField
                                                                            className={mappingStyles.campaignContactFieldPopupTextField}
                                                                            value={data.contact_field_name}
                                                                            sx={{ background: "#F6F6F6", width: "100%" }}
                                                                            placeholder="Enter Field"
                                                                            autoFocus
                                                                            onBlur={() => { onBlurSave(); }}
                                                                            onChange={(e) => {
                                                                                const dummyFields = [...dummyCampaignContactFieldData];
                                                                                dummyFields[0].contact_field_data.map((field, index) => {
                                                                                    if (ind === index) field.contact_field_name = e.target.value;
                                                                                    return field;
                                                                                })
                                                                                setDummyCampaignContactFieldData(dummyFields);
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", display: "flex", alignItems: "center", justifyContent: "start", margin: "0", padding: "0 10px" }}>{data.contact_field_name}</Typography>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Autocomplete
                                                                        options={dropdownOptions}
                                                                        // disableClearable
                                                                        value={mapTheField && mapTheField.length > 0 ? mapTheField[ind] : undefined}
                                                                        getOptionLabel={(option) => option}
                                                                        renderInput={(params) => <TextField {...params} placeholder="Select" />}
                                                                        className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoComplete}`}
                                                                        sx={{ boxShadow: "none !important" }}
                                                                        onChange={(e, newValue) => { handleChangeField(newValue, ind); }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>

                                                <Grid item xs={12} mt={"4px"}>
                                                    <Grid container alignItems={"center"} gap={"0px"}>
                                                        <AddIcon sx={{ color: "#6a097d", display: "flex", fontSize: "20px", cursor: "pointer" }} onClick={() => onNewFieldClick()} />
                                                        <Typography component={"span"} sx={{ color: "#6a097d", fontSize: "13px", fontWeight: "600", lineHeight: "1", cursor: "pointer" }} onClick={() => onNewFieldClick()}> New Field</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} marginBlock={"15px"}>
                                                    <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"}>
                                                        <Grid item>
                                                            <GlobalButton
                                                                buttonText="Back"
                                                                className='borderedButtonStyle'
                                                                sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                                                onClick={() => { setFlagMapTheField(false); }}
                                                            >
                                                            </GlobalButton>
                                                        </Grid>
                                                        <Grid item>
                                                            <GlobalButton
                                                                buttonText="Continue"
                                                                sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                                                className='primaryButtonStyle'
                                                                disabled={checkDisableContinue()}
                                                                onClick={() => { onClickOfContinueBtn() }}
                                                            >
                                                            </GlobalButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CreateNewContactPopup
