import IconsColor from "../../utility/iconsColor";
import whatsappIcon from '../../../assets/Whatsapp.svg'

export function stripMarkdown(text) {
    if (text) {
        // Remove Markdown links and images
        text = text.replace(/!\[.*?\]\(.*?\)/g, '');
        text = text.replace(/\[.*?\]\(.*?\)/g, '');
        // Remove bold and italic formatting
        text = text.replace(/(\*\*|__)(.*?)\1/g, '$2');
        text = text.replace(/(\*|_)(.*?)\1/g, '$2');
        // Remove headings
        text = text.replace(/#+\s*(.*?)/g, '$1');
        // Remove blockquotes
        text = text.replace(/>\s*(.*?)/g, '$1');
        // Remove inline code and code blocks
        text = text.replace(/`{1,3}([^`]*)`{1,3}/g, '$1');
        text = text.replace(/```[\s\S]*?```/g, '');
        // Remove horizontal rules
        text = text.replace(/^-{3,}$/gm, '');
        return text;
    }
}
export function getIntegrationIcon(integration) {
    let integrationCase = integration.toLowerCase();
    switch (integrationCase) {
        case 'whatsapp':
            return <img alt="source" src={whatsappIcon} height={"14"} width={"14"} />
        case 'slack':
            return <IconsColor iconName="colouredSlackIcon" width="14" height="14" fill="#93A1A2" />
        default:
            return <IconsColor iconName="website" width="14" height="14" fill="#93A1A2" />
    }
}