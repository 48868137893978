import { Autocomplete, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import style from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { formatDate, formatDateAccordingToTimezone, formatDateAccordingToTimezoneUsingMoment, isAdmin } from '../../../utils/constants';
import { callAnalysisFeedbackDataResponse, feedback_data, feedbackKnowledgeBaseStatus, feedbackStatus, getCallAnalysisFeedbackResponseModel, getFeedbackResponse, updateFeedbackPayload } from '../../../models/axiosModel';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import agent from '../../../services/api';
import GlobalButton from '../../utility/globalButton/button';
import { useNavigate } from 'react-router-dom';
import IconsColor from '../../utility/iconsColor';
import chronox_icon from "../../../assets/images/global/Chronox AI Icon.png";

interface FeedbackTableProps {
    feedbackData: (getFeedbackResponse | getCallAnalysisFeedbackResponseModel)[],
    setFeedbackData: React.Dispatch<React.SetStateAction<(getFeedbackResponse | getCallAnalysisFeedbackResponseModel)[]>>,
    page: number,
    rowsPerPage: number,
    onTableRowClick: (rowData: getFeedbackResponse | getCallAnalysisFeedbackResponseModel) => void;
    updateFeedbackApiCall: (feedback_id: number, status_id: number, modification_status_id: number, reviewer_note: string | null, admin_note: string | null, isCallAnalysisFeedback: boolean) => void;
}

const FeedbackTable: React.FC<FeedbackTableProps> = ({ feedbackData, setFeedbackData, page, rowsPerPage, onTableRowClick, updateFeedbackApiCall }) => {

    const { userDetail } = useContext(UserContext) as UserContextPayload
    const { feedbackStatusData, feedbackKnowledgeBaseStatusData } = useContext(GenericDataContext) as GenericDataContextPayload;

    const previousVal = useRef("");

    const onRowClick = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel) => {
        if (isAdmin(userDetail)) {
            onTableRowClick(item);
        }
    }

    const renderType = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel): JSX.Element | string => {
        if ("feedback_id" in item) {
            switch (item.feedback_level_name.toLowerCase()) {
                case "chronox":
                    return (
                        <img src={chronox_icon} alt="chronox_logo" height={"18px"} width={"18px"} />
                    );
                case "chatbot":
                    return (
                        <IconsColor iconName="chatbot" fillColor="#6a097d" width={"18px"} height={"18px"} />
                    );
                case "thread":
                case "message":
                    return (
                        <IconsColor iconName="chat" fillColor="#6a097d" width={"18px"} height={"18px"} />
                    );
                default:
                    return "";
            }
        } else {
            return (
                <IconsColor iconName="callAnalysis" fillColor="#6a097d" width={"18px"} height={"18px"} />
            );
        }
    }

    const renderMoreDetails = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel): JSX.Element | string => {
        if ("feedback_id" in item) {
            if (item.feedback_data?.length > 0 && item.feedback_data[0].feedback_option_field_value != "") {
                return (
                    <Grid container direction="column">
                        {item.feedback_data.map((data: feedback_data) => {
                            return (
                                <Grid item>
                                    <span style={{ fontWeight: "600" }} >{data.feedback_option_field_value}: <span style={{ fontWeight: "500" }}>{data.feedback_value}</span> </span>
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            } else {
                return "-";
            }
        } else {
            if (item.call_analysis_feedback_data.length > 0 && item.call_analysis_feedback_data[0].call_analysis_feedback_option_field_value != "") {
                return (
                    <Grid container direction="column">
                        {item.call_analysis_feedback_data.map((data: callAnalysisFeedbackDataResponse) => {
                            return (
                                <Grid item>
                                    <span style={{ fontWeight: "600" }} >{data.call_analysis_feedback_option_field_value}: <span style={{ fontWeight: "500" }}>{data.call_analysis_feedback_data_value}</span> </span>
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            } else {
                return "-";
            }
        }
    }

    const onChangeOfFeedbackStatus = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, value: feedbackStatus) => {
        if ("feedback_id" in item) {
            setFeedbackData(prev =>
                prev.map((data) => {
                    if ("feedback_id" in data) {
                        return data.feedback_id === item.feedback_id ? { ...data, feedback_status_name: value.feedback_status_name, feedback_status_id: value.feedback_status_id } : data
                    }
                    return data;
                })
            )
            updateFeedbackApiCall(item.feedback_id, value.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, false);
        } else {
            setFeedbackData(prev =>
                prev.map((data) => {
                    if ("call_analysis_feedback_id" in data) {
                        return data.call_analysis_feedback_id === item.call_analysis_feedback_id ? { ...data, feedback_status_name: value.feedback_status_name, feedback_status_id: value.feedback_status_id } : data
                    }
                    return data;
                })
            )
            updateFeedbackApiCall(item.call_analysis_feedback_id, value.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, true);
        }
    }

    const onChangeOfModificationStatus = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, value: feedbackKnowledgeBaseStatus) => {
        if ("feedback_id" in item) {
            setFeedbackData(prev =>
                prev.map((data) => {
                    if ("feedback_id" in data) {
                        return data.feedback_id === item.feedback_id ? { ...data, modification_status_name: value.modification_status_name, modification_status_id: value.modification_status_id } : data
                    }
                    return data;
                })
            )
            updateFeedbackApiCall(item.feedback_id, item.feedback_status_id, value.modification_status_id, item.reviewer_note, item.admin_note, false);
        } else {
            setFeedbackData(prev =>
                prev.map((data) => {
                    if ("call_analysis_feedback_id" in data) {
                        return data.call_analysis_feedback_id === item.call_analysis_feedback_id ? { ...data, modification_status_name: value.modification_status_name, modification_status_id: value.modification_status_id } : data
                    }
                    return data;
                })
            )
            updateFeedbackApiCall(item.call_analysis_feedback_id, item.feedback_status_id, value.modification_status_id, item.reviewer_note, item.admin_note, true);
        }
    }

    const onChangeOfReviewerNote = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, value: string) => {
        if ("feedback_id" in item) {
            setFeedbackData(prev =>
                prev.map(data => {
                    if ("feedback_id" in data) {
                        return data.feedback_id === item.feedback_id ? { ...data, reviewer_note: value } : data
                    }
                    return data;
                })
            )
        } else {
            setFeedbackData(prev =>
                prev.map(data => {
                    if ("call_analysis_feedback_id" in data) {
                        return data.call_analysis_feedback_id === item.call_analysis_feedback_id ? { ...data, reviewer_note: value } : data
                    }
                    return data;
                })
            )
        }
    }

    const onBlurOfReviewerNote = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, currentVal: string) => {
        if (currentVal != previousVal.current) {
            if ("feedback_id" in item) {
                updateFeedbackApiCall(item.feedback_id, item.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, false);
            } else {
                updateFeedbackApiCall(item.call_analysis_feedback_id, item.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, true);
            }
        }
    }

    const onChangeOfAdminNote = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, value: string) => {
        if ("feedback_id" in item) {
            setFeedbackData(prev =>
                prev.map(data => {
                    if ("feedback_id" in data) {
                        return data.feedback_id === item.feedback_id ? { ...data, admin_note: value } : data
                    }
                    return data;
                })
            )
        } else {
            setFeedbackData(prev =>
                prev.map(data => {
                    if ("call_analysis_feedback_id" in data) {
                        return data.call_analysis_feedback_id === item.call_analysis_feedback_id ? { ...data, admin_note: value } : data
                    }
                    return data;
                })
            )
        }
    }

    const onBlurOfAdminNote = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, currentVal: string) => {
        if (currentVal != previousVal.current) {
            if ("feedback_id" in item) {
                updateFeedbackApiCall(item.feedback_id, item.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, false)
            } else {
                updateFeedbackApiCall(item.call_analysis_feedback_id, item.feedback_status_id, item.modification_status_id, item.reviewer_note, item.admin_note, true)
            }
        }
    }

    const navigate = useNavigate();

    const innerHeight = (window.innerHeight - 300);

    const sortFeedbackData = () => {
        return feedbackData.sort((a, b) => new Date(b.created_at_timestamp).getTime() - new Date(a.created_at_timestamp).getTime()).slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage)
    }

    useEffect(() => {
        sortFeedbackData()
    }, [page])


    return (
        <>
            <TableContainer sx={{ borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", boxSizing: "border-box", maxHeight: `${innerHeight}px !important` }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Type</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Source</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Date</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Provided Type</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Provider</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Category</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>More Detail</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Approval Status</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Modification Status</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Note By Reviewer</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Note By Admin</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>View</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortFeedbackData()?.length > 0 ?
                            sortFeedbackData().map((item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel, ind: number) => {
                                return (
                                    <>
                                        <TableRow onClick={() => { onRowClick(item) }} className={dashboardStyles.cellRow} sx={{ cursor: isAdmin(userDetail) ? "pointer" : "default"}}>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{textAlign: "center"}}>{renderType(item)}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{"chatbot_name" in item ? item.chatbot_name : item.call_record_google_storage_file_name}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formatDateAccordingToTimezoneUsingMoment(item.created_at_timestamp, userDetail.timezone_hour)}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{"feedback_provided_type_name" in item ? item.feedback_provided_type_name : "-"}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{"feedback_provider" in item ? item.feedback_provider != "" ? item.feedback_provider : "-" : "-"}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{"feedback_option_name" in item ? item.feedback_option_name : item.call_analysis_feedback_option_name}</TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}> {renderMoreDetails(item)} </TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} onClick={(e) => e.stopPropagation()}>
                                                {isAdmin(userDetail) ?
                                                    <>
                                                        <Autocomplete
                                                            options={feedbackStatusData}
                                                            value={feedbackStatusData.find((data) => data.feedback_status_id === item.feedback_status_id)}
                                                            getOptionLabel={(option) => option.feedback_status_name}
                                                            onChange={(e, value) => { onChangeOfFeedbackStatus(item, value); }}
                                                            className={`${style.feedbackTableAutoComplete}`}
                                                            disableClearable={true}
                                                            renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                                        />
                                                    </>
                                                    :
                                                    item.feedback_status_name
                                                }
                                            </TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} onClick={(e) => e.stopPropagation()}>
                                                {isAdmin(userDetail) ?
                                                    <>
                                                        <Autocomplete
                                                            options={feedbackKnowledgeBaseStatusData}
                                                            getOptionLabel={(option) => option.modification_status_name}
                                                            value={feedbackKnowledgeBaseStatusData?.find((data) => data.modification_status_id === item.modification_status_id)}
                                                            onChange={(e, value) => { onChangeOfModificationStatus(item, value); }}
                                                            className={`${style.feedbackTableAutoComplete}`}
                                                            disableClearable={true}
                                                            renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                                        />
                                                    </>
                                                    :
                                                    item.modification_status_name
                                                }
                                            </TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} onClick={(e) => e.stopPropagation()}>
                                                {isAdmin(userDetail) ?
                                                    <>  
                                                        <Tooltip title={item.reviewer_note ? item.reviewer_note : ""}>
                                                            <TextField
                                                                value={item.reviewer_note ? item.reviewer_note : ""}
                                                                className={`${style.feedbackTableTextField}`}
                                                                onChange={(e) => { onChangeOfReviewerNote(item, e.target.value); }}
                                                                onBlur={(e) => { onBlurOfReviewerNote(item, e.target.value); }}
                                                                onFocus={(e) => previousVal.current = e.target.value}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    item.reviewer_note ? item.reviewer_note : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} onClick={(e) => e.stopPropagation()}>
                                                {isAdmin(userDetail) ?
                                                    <>  
                                                        <Tooltip title={item.admin_note ? item.admin_note : ""}>
                                                            <TextField
                                                                value={item.admin_note ? item.admin_note : ""}
                                                                className={`${style.feedbackTableTextField}`}
                                                                onChange={(e) => { onChangeOfAdminNote(item, e.target.value); }}
                                                                onBlur={(e) => { onBlurOfAdminNote(item, e.target.value); }}
                                                                onFocus={(e) => previousVal.current = e.target.value}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    item.admin_note ? item.admin_note : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} onClick={(e) => e.stopPropagation()}>
                                                {"feedback_id" in item ?
                                                    (item?.chatbot_user_id || (item?.feedback_level_name === "Thread" && item?.feedback_unique_id)) ?
                                                        <GlobalButton
                                                            buttonText={"View"}
                                                            className='primaryButtonStyle'
                                                            sx={{ width: "100px" }}
                                                            onClick={() => { window.open(`${window.location.origin}/inbox?chatbot_user_id=${item.chatbot_user_id ? item.chatbot_user_id : item?.feedback_unique_id}&feedback_id=${item.feedback_id}`, "_blank") }}
                                                        />
                                                        : "NA"
                                                    : "NA"
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })
                            :
                            <TableRow className={dashboardStyles.cellRow}>
                                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default FeedbackTable
