import React, { useState } from 'react'
import { ChildrenProps } from '../models/componentModel';
import { createCampaignPayload, createCampaignResponse, getTemplateDetails, getCampaignMessageTypeResponse, runCampaignPayload, campaignContactAssignmentData, updateCampaignPayload } from '../models/axiosModel';
import agent from '../services/api';

const WhatsappCampaignContext = React.createContext<WhatsappCampaignContextPayload | undefined>(undefined);

export interface WhatsappCampaignContextPayload {
    whatsappCampaignListdData: createCampaignResponse[] | null,
    setWhatsappCampaignListdData: React.Dispatch<React.SetStateAction<createCampaignResponse[] | null>>,
    selectedWhatsAppCampaign: any
    setSelectedWhatsAppCampaign: React.Dispatch<React.SetStateAction<createCampaignResponse | null>>,
    messageTemplateDetail: getTemplateDetails | null,
    setMessageTemplateDetail: React.Dispatch<React.SetStateAction<getTemplateDetails | null>>,
    getWhatsappTemplateDetailsApiCall: (meta_integration_whatsapp_id: number, whatsapp_template_id: number) => Promise<any>,
    whatsAppCampaignMessageTypeData: getCampaignMessageTypeResponse[] | null,
    setWhatsAppCampaignMessageTypeData: React.Dispatch<React.SetStateAction<getCampaignMessageTypeResponse[] | null>>,
    campaignContactStatusPopup: boolean,
    setCampaignContactStatusPopup: React.Dispatch<React.SetStateAction<boolean>>,
    currentContactStatus: campaignContactAssignmentData | null,
    setCurrentContactStatus: React.Dispatch<React.SetStateAction<campaignContactAssignmentData | null>>,

    // API's
    getCampaignDataApi: (campaign_id?: number | null) => Promise<any>,
    createCampaignApi: (payload: createCampaignPayload) => Promise<any>,
    getCampaignMessageTypeApi: () => Promise<any>,
    runCampaignApi: (payload: runCampaignPayload) => Promise<any>,
    updateCampaignApi: (payload: updateCampaignPayload) => Promise<any>,
}

const WhatsappCampaignProvider = ({ children }: ChildrenProps) => {

    // const [whatsappCampaignListdData, setWhatsappCampaignListdData] = useState<createCampaignResponse[] | null>(null);
    const [whatsappCampaignListdData, setWhatsappCampaignListdData] = useState<createCampaignResponse[] | null>(null);
    const [selectedWhatsAppCampaign, setSelectedWhatsAppCampaign] = useState<createCampaignResponse | null>(null);
    const [messageTemplateDetail, setMessageTemplateDetail] = useState<getTemplateDetails | null>(null);
    const [whatsAppCampaignMessageTypeData, setWhatsAppCampaignMessageTypeData] = useState<getCampaignMessageTypeResponse[] | null>(null);
    const [campaignContactStatusPopup, setCampaignContactStatusPopup] = useState<boolean>(false);
    const [currentContactStatus, setCurrentContactStatus] = useState<campaignContactAssignmentData | null>(null);

    // Campaign API's
    const getCampaignDataApi = async (campaign_id?: number | null) => {
        const res = await agent.getCampaignData.get(campaign_id);
        if (res.status) {
            setWhatsappCampaignListdData(res.data);
            if (campaign_id) {
                setSelectedWhatsAppCampaign(res.data[0])
            }
        } else {
            setWhatsappCampaignListdData(null);
            // setWhatsappCampaignListdData(null);
        }
        return res;
    }

    const createCampaignApi = async (payload: createCampaignPayload) => {
        const res = await agent.createCampaign.post(payload);
        return res;
    }

    const updateCampaignApi = async (payload: updateCampaignPayload): Promise<any> => {
        const res = await agent.updateCampaign.put(payload);
        return res;
    }

    const getWhatsappTemplateDetailsApiCall = async (meta_integration_whatsapp_id: number, whatsapp_template_id: number) => {
        const res = await agent.getWhatsappTemplateDetails.get(meta_integration_whatsapp_id, whatsapp_template_id);
        if (res.status) {
            setMessageTemplateDetail(res.data[0]);
        } else {
            setMessageTemplateDetail(null);
        }
        return res;
    }

    const runCampaignApi = async (payload: runCampaignPayload): Promise<any> => {
        const res = await agent.runCampaign.post(payload);
        return res;
    }

    // Campaign Message Type API
    const getCampaignMessageTypeApi = async (): Promise<any> => {
        const res = await agent.getCampaignMessageType.get();
        if (res.status) {
            setWhatsAppCampaignMessageTypeData(res.data);
        }
        return res;
    }

    const payload = {
        whatsappCampaignListdData,
        setWhatsappCampaignListdData,
        selectedWhatsAppCampaign,
        setSelectedWhatsAppCampaign,
        messageTemplateDetail,
        setMessageTemplateDetail,
        getWhatsappTemplateDetailsApiCall,
        whatsAppCampaignMessageTypeData,
        setWhatsAppCampaignMessageTypeData,
        campaignContactStatusPopup,
        setCampaignContactStatusPopup,
        currentContactStatus,
        setCurrentContactStatus,

        // API's
        getCampaignDataApi,
        createCampaignApi,
        getCampaignMessageTypeApi,
        runCampaignApi,
        updateCampaignApi,
    }
    return (
        <WhatsappCampaignContext.Provider value={payload}>
            {children}
        </WhatsappCampaignContext.Provider>
    )
}

export { WhatsappCampaignContext, WhatsappCampaignProvider }
