import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, addChatbotDomainPayloadModel, chatbotListInterface, chatbotThreadDataInterface, chatbotThreadListInterface, createChatbotPayloadModel, getChatbotResponseModel, updateChatbotDomainPayloadModel, updateChatbotPayloadModel, chatbotStaticMessageFormat } from "../models/axiosModel";
import agent from "../services/api";

const ChatbotContext = React.createContext<ChatbotContextPayload | undefined>(undefined);

interface chatbotNames {
    chatbot_id: string,
    chatbot_name: string,
}
interface chatbotThemes {
    prevTheme: string,
    savedTheme: string,
}

export interface ChatbotContextPayload {
    CreateChatbotApi: (payload: createChatbotPayloadModel) => any;
    GetChatbotListApi: () => any;
    GetChatbotDataApi: (id: number) => Promise<CommonResponseModel<getChatbotResponseModel | null>>;
    UpdateChatbotApi: (payload: updateChatbotPayloadModel) => any;
    AddChatbotDomain: (payload: addChatbotDomainPayloadModel) => any;
    UpdateChatbotDomain: (payload: updateChatbotDomainPayloadModel) => any;
    chatBotDetails: getChatbotResponseModel | null;
    setChatBotDetails: React.Dispatch<React.SetStateAction<getChatbotResponseModel | null>>;
    chatbotThreadList: chatbotThreadListInterface[] | null;
    setChatbotThreadList: React.Dispatch<React.SetStateAction<chatbotThreadListInterface[] | null>>;
    getChatbotThreadsList: (chatbot_id: string) => Promise<CommonResponseModel<chatbotThreadListInterface[]>>;
    currentActiveChatbotThread: chatbotThreadDataInterface[] | null;
    setCurrentActiveChatbotThread: React.Dispatch<React.SetStateAction<chatbotThreadDataInterface[] | null>>;
    chatbotList: chatbotListInterface[] | null;
    setChatbotList: React.Dispatch<React.SetStateAction<chatbotListInterface[] | null>>;
    chatbotThemeValue: string;
    setChatbotThemeValue: React.Dispatch<React.SetStateAction<string>>;
    chatbotTheme: chatbotThemes;
    setChatbotTheme: React.Dispatch<React.SetStateAction<chatbotThemes>>;
    chatbotThemeDetails: any;
    setChatbotThemeDetails: any;
    chatbotStaticMessageFormat: chatbotStaticMessageFormat[];
    setChatbotStaticMessageFormat: React.Dispatch<React.SetStateAction<chatbotStaticMessageFormat[]>>;

}

const ChatbotProvider = ({ children }: ChildrenProps) => {

    const [chatBotDetails, setChatBotDetails] = useState<getChatbotResponseModel | null>(null);
    const [chatbotList, setChatbotList] = useState<chatbotListInterface[] | null>(null);
    const [chatbotTheme, setChatbotTheme] = useState({
        prevTheme: "Default Theme",
        savedTheme: ""
    })
    const [chatbotThemeValue, setChatbotThemeValue] = useState<string>("Default Theme");
    const [chatbotThreadList, setChatbotThreadList] = useState<chatbotThreadListInterface[] | null>(null)
    const [chatbotThemeDetails, setChatbotThemeDetails] = useState<any | null>(null)
    const [currentActiveChatbotThread, setCurrentActiveChatbotThread] = useState<chatbotThreadDataInterface[] | null>(
        []
    )

    const [chatbotStaticMessageFormat, setChatbotStaticMessageFormat] = useState<chatbotStaticMessageFormat[]>([]);

    const CreateChatbotApi = async (payload: createChatbotPayloadModel): Promise<any> => {
        const response = await agent.createChatbot.post(payload);
        return response;
    }
    const GetChatbotListApi = async (): Promise<any> => {
        const response = await agent.getChatbotList.get();
        if (response.status) {
            setChatbotList(response.data)
        }
        return response;
    }
    const GetChatbotDataApi = async (id: number): Promise<CommonResponseModel<getChatbotResponseModel | null>> => {
        const response = await agent.getChatbotData.get(id);
        if (response.status) {
            setChatBotDetails(response.data)
        }
        return response;
    }
    const UpdateChatbotApi = async (payload: updateChatbotPayloadModel): Promise<any> => {
        const response = await agent.updateChatbotData.put(payload);
        return response;
    }
    const AddChatbotDomain = async (payload: addChatbotDomainPayloadModel): Promise<any> => {
        const response = await agent.addChatbotDomain.post(payload);
        return response;
    }
    const UpdateChatbotDomain = async (payload: updateChatbotDomainPayloadModel): Promise<any> => {
        const response = await agent.updateChatbotDomain.put(payload);
        return response;
    }
    const getChatbotThreadsList = async (chatbot_id: string) => {
        const response = await agent.getChatbotThreadsList.get(chatbot_id)
        if (response.status) {
            setChatbotThreadList(response.data)
        } else {
            setChatbotThreadList(null)
        }
        return response;
    }
    const payload = {
        CreateChatbotApi,
        GetChatbotDataApi,
        UpdateChatbotApi,
        GetChatbotListApi,
        AddChatbotDomain,
        UpdateChatbotDomain,
        chatBotDetails,
        setChatBotDetails,
        chatbotThreadList,
        setChatbotThreadList,
        getChatbotThreadsList,
        currentActiveChatbotThread,
        setCurrentActiveChatbotThread,
        chatbotList,
        setChatbotList,
        chatbotThemeValue,
        setChatbotThemeValue,
        chatbotTheme,
        setChatbotTheme,
        setChatbotThemeDetails,
        chatbotThemeDetails,
        chatbotStaticMessageFormat,
        setChatbotStaticMessageFormat
    };

    return (
        <ChatbotContext.Provider value={payload}>
            {children}
        </ChatbotContext.Provider>
    );
};

export { ChatbotContext, ChatbotProvider };
