import { Divider, Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import globalStyles from './../../../assets/styles/global.module.scss'
import headerStyles from '../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import GlobalButton from '../../utility/globalButton/button'
import AddIcon from '@mui/icons-material/Add';
import { Outlet, useNavigate } from 'react-router-dom'
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext'


const WhatsappCampaignGlobal = () => {

    const { whatsappCampaignListdData, setSelectedWhatsAppCampaign } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;

    const navigate = useNavigate();
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    const flagTabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)');

    return (
        <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper}`}>
                <Grid container sx={{ padding: { xs: "15px 0px", sm: "23px 0px" } }} direction={"column"} flexWrap={"nowrap"}>
                    <Grid item xs={12} sm={12}>
                        <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                            <Grid item className={globalStyles.fullwidth} sx={{ padding: { xs: "0px 20px", lg: "5px 25px 0px 27px" } }}>
                                <GlobalPageHeaderText
                                    mainHeadText={window.location.pathname === "/whatsappcampaign" ? "WhatsApp Campaign" : "Create New Campaign"}
                                    flagMainTextOnly
                                    actionBlock={
                                        !smallScreen &&
                                        <Grid container gap={"12px"} justifyContent={flagTabletScreen ? "start" : "end"} flexWrap={"nowrap"}>
                                            {window.location.pathname == "/whatsappcampaign" && whatsappCampaignListdData && whatsappCampaignListdData.length > 0 &&
                                                <Grid item>
                                                    <GlobalButton
                                                        buttonText={"New Campaign"}
                                                        className='primaryButtonStyle'
                                                        startIcon={<AddIcon />}
                                                        sx={{ width: "170px" }}
                                                        onClick={() => { setSelectedWhatsAppCampaign(null); navigate('/whatsappcampaign/createnewcampaign') }}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                />
                                {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${headerStyles.dividerStyle}`} sx={{ paddingTop: "15px !important" }} />}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Outlet />
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsappCampaignGlobal
