import { Button, Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { CalendlyContext, CalendlyContextType } from '../../../../../context/calendlyIntegrationContext';
import styles from './../../../../../assets/styles/componentStyles/calendlyStyles/calendly.module.scss';
import GlobalButton from '../../../../utility/globalButton/button';
import IconsColor from '../../../../utility/iconsColor';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext';
import ChatbotDetails from '../../chatbotDetails';
import { UserContext, UserContextPayload } from '../../../../../context/userContext';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from "../../../../../utils/constants";


const StepContainer = () => {
    const { selectedEvent, setSelectedEvent, step, setStep, isPopupMode, setIsPopupMode, calendlyIntegrationData, setCalendlyIntegrationData, setEditAccDetailPopupOpen, genericChatbotCalendlyData, fieldData, PostChatbotCalendlyEventTypeData } = useContext(CalendlyContext) as CalendlyContextType;
    const { chatBotDetails, GetChatbotDataApi } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setFlagLoading, setSuccess, success, setError, userDetail } = useContext(UserContext) as UserContextPayload;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');


    useEffect(() => {
        if (calendlyIntegrationData) {
            setStep('2')
            navigate(`/chatbot/viewchatbot/integration/calendly/selectCalendlyEvent?chatbot_id=${chatbot_id}`);
        }
        else {
            setStep('1')
            navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${chatbot_id}`);
        }
    }, [chatBotDetails])

    const handleStepClick = (nextStep: string) => {
        switch (nextStep) {
            case '1':
                navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${chatbot_id}`);
                break;
            case '2':
                navigate(`/chatbot/viewchatbot/integration/calendly/selectCalendlyEvent?chatbot_id=${chatbot_id}`);
                break;
            case '3':
                navigate(`/chatbot/viewchatbot/integration/calendly/fieldSetup?chatbot_id=${chatbot_id}`);
                break;
            default:
                navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${chatbot_id}`);
        }
        setStep(nextStep);
    };
    const handleSaveBtn = async () => {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            let updatedFieldData = fieldData.filter((event: any) => event.question_description && event.question_description !== "");
            let assignment_id_while_updating = genericChatbotCalendlyData?.find((data: any) => {
                return data.calendly_integration_id === calendlyIntegrationData?.[0]?.calendly_integration_id;
            });

            const dataToBeSent = updatedFieldData.map((item: any) => {
                return {
                    chatbot_calendly_question_data_id: item.chatbot_calendly_question_data_id,
                    calendly_event_type_question_id: item.calendly_event_type_question_id,
                    question_description: item.question_description
                };
            });

            let payload = {
                // @ts-ignore
                "chatbot_calendly_assignment_id": assignment_id_while_updating ? assignment_id_while_updating?.chatbot_calendly_assignment_data?.[0]?.chatbot_calendly_assignment_id : null,
                "chatbot_id": chatbot_id,
                // @ts-ignore
                "calendly_integration_id": calendlyIntegrationData[0].calendly_integration_id,
                "calendly_event_type_id": selectedEvent[0],
                "chatbot_calendly_question_data": dataToBeSent,
                // "chatbot_scheduled_message_data": {
                //     "chatbot_scheduled_message_id": 0,
                //     "confirmation_message": ''
                // }
            }
            const response = await PostChatbotCalendlyEventTypeData(payload)
            if (response.status) {
                if (assignment_id_while_updating) {
                    setSuccess({ ...success, flagShowSuccess: true, message: 'Details Saved Successfully!' })
                } else {
                    setSuccess({ ...success, flagShowSuccess: true, message: 'Calendly Integration Successfull!' })
                }
                await GetChatbotDataApi(Number(chatbot_id))
                navigate(`/chatbot/viewchatbot/integration/calendly?chatbot_id=${chatbot_id}`);

            } else {
                let errorCode = checkForErrorCode(response.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.log(error, 'error')
        } finally {
            setIsPopupMode(false);
            setEditAccDetailPopupOpen(false)
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
    };
    let steps = [
        {
            name: "Authorise Account",
            stepNumber: '1',
            flagDisabled: calendlyIntegrationData ? calendlyIntegrationData?.length > 0 ? true : false : false,
            flagActive: step == '1' ? true : false
        },
        {
            name: "Select Calendly Event Type",
            stepNumber: '2',
            flagDisabled: calendlyIntegrationData ? calendlyIntegrationData?.length > 0 ? false : true : true,
            flagActive: step == '2' ? true : false
        },
        {
            name: "Field Setup",
            stepNumber: '3',
            flagDisabled: selectedEvent.length > 0 ? false : true,
            flagActive: step == '3' ? true : false
        }
    ]
    return (
        <>
            <Grid container sx={{ padding: '10px 0px 20px', zIndex: 1 }} flexWrap="nowrap" alignItems="center">
                <Grid item sx={{ width: '100%' }}>
                    <Grid container gap={3} flexWrap="nowrap" alignItems="center" justifyContent={"space-between"}>
                        <Grid item sx={{ zIndex: "1300" }}>
                            <Grid container direction={"row"} flexWrap={"nowrap"}>
                                {/* {!isPopupMode && */}
                                <>
                                    {steps.map((currentStep) => {
                                        return <>
                                            <Grid item>
                                                <Button
                                                    disabled={currentStep.flagDisabled}
                                                    variant='text'
                                                    className={styles.stepContainer}
                                                    onClick={() => handleStepClick(currentStep.stepNumber)}
                                                    sx={{
                                                        textTransform: "unset",
                                                        color: step === currentStep.stepNumber ? '#6A097D' : '#000',
                                                        fontWeight: step === currentStep.stepNumber ? '600' : '600',
                                                    }}
                                                >
                                                    <span
                                                        className={styles.circle}
                                                        style={{
                                                            backgroundColor: step === currentStep.stepNumber ? '#6A097D' : '',
                                                        }}
                                                    >
                                                        {currentStep.stepNumber}
                                                    </span>
                                                    <span className={styles.stepText}>{currentStep.name}</span>
                                                </Button>
                                            </Grid>
                                        </>
                                    })}
                                </>
                                {/* } */}
                                <>
                                    {/* {steps.map((currentStep) => {
                                        return <>
                                            <Grid item>
                                                <Button
                                                    disabled={currentStep.flagDisabled}
                                                    variant='text'
                                                    className={styles.stepContainer}
                                                    onClick={() => handleStepClick(currentStep.stepNumber)}
                                                    sx={{
                                                        textTransform: "unset",
                                                        color: step === currentStep.stepNumber ? '#6A097D' : '#000',
                                                        fontWeight: step === currentStep.stepNumber ? 'bold' : '',
                                                    }}
                                                >
                                                    <span
                                                        className={styles.circle}
                                                        style={{
                                                            backgroundColor: step === currentStep.stepNumber ? '#6A097D' : '',
                                                        }}
                                                    >
                                                        {currentStep.stepNumber}
                                                    </span>
                                                    <span className={styles.stepText}>{currentStep.name}</span>
                                                </Button>
                                            </Grid>
                                        </>
                                    })} */}
                                </>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {(step === '2' || step === '3') && (
                                <Grid container justifyContent="flex-end" flexWrap="nowrap" gap={2}>
                                    <Grid item>
                                        <GlobalButton
                                            onClick={() => { setIsPopupMode(false); setEditAccDetailPopupOpen(false); setCalendlyIntegrationData(null) }}
                                            sx={{ width: "130px", fontSize: "12px" }}
                                            buttonText="Cancel"
                                            className="calendlyCancelButtonStyle"
                                        />
                                    </Grid>
                                    {step === '2' && (
                                        <Grid item>
                                            <GlobalButton
                                                sx={{ width: '130px' }}
                                                buttonText="Next Step"
                                                className="primaryButtonStyle"
                                                disabled={!selectedEvent.length}
                                                endIcon={
                                                    <IconsColor
                                                        iconName="calendlyForwardArrow"
                                                        width="15px"
                                                        height="20px"
                                                        fillColor="#FFFFFF"
                                                    />
                                                }
                                                onClick={() => handleStepClick('3')}
                                            />
                                        </Grid>
                                    )}
                                    {step === '3' && (
                                        <Grid item>
                                            <GlobalButton
                                                sx={{ width: '120px', fontWeight: '400', fontSize: '12px' }}
                                                buttonText="Save"
                                                className="primaryButtonStyle"
                                                onClick={() => handleSaveBtn()}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Outlet />
        </>
    );
};

export default StepContainer;
