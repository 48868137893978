import React, { useState } from 'react'
import Prerequisites from './requisitesBlock'
import { instagramPrereq } from '../../../../utils/content'
import IntegrationSetupHeader from './integrationSetupHeader'
import { Grid } from '@mui/material'
interface launchWhatsApp {
    launch: any,
    viewIntegration?: boolean,
    heading: string,
    integrationType: string
}


const IntegrationSetupPrerequisites = (props: launchWhatsApp) => {

    const { integrationType, heading } = props
    return (
        <>
            <Grid item>
                <Grid container direction={"column"} gap="32px">
                    <IntegrationSetupHeader
                        integrationType={integrationType}
                        integrationSubHeading={heading}
                    />

                    <Prerequisites
                        integrationType={integrationType}
                        prereqSteps={instagramPrereq}
                    />
                </Grid>
            </Grid>

        </>
    )
}

export default IntegrationSetupPrerequisites;