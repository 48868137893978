import { Autocomplete, Button, Divider, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import Required from '../../../utility/required';
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/customForm.module.scss'
import globalStyles from './../../../../assets/styles/global.module.scss'

const CustomForm = (props: any) => {
    const {thread, data, chatbotTheme} = props;

    const [formValues, setFormValues] = useState(
        data?.message_fields?.fields?.reduce((acc: any, field: any) => {
            acc[field.field_name] = field.type === 'hidden' ? field.value : '';
            return acc;
        }, {})
    );


    const handleCheckFieldType = (fieldType: any) => {
        const fieldTypeLower = fieldType?.type?.toLowerCase();

        switch (fieldTypeLower) {
            case "text":
                return <TextField
                    placeholder={fieldType.placeholder}
                    required={fieldType.flag_required}
                    fullWidth
                    className={styles.custom_form_field_textfield}
                    value={formValues[fieldType.field_name]}
                />;
            case "email":
                return <TextField
                    type='email'
                    placeholder={fieldType.placeholder}
                    required={fieldType.flag_required}
                    fullWidth
                    className={styles.custom_form_field_textfield}
                    value={formValues[fieldType.field_name]}
                />;
            case "hidden":
                return <TextField
                    type='hidden'
                    required={fieldType.flag_required}
                    fullWidth
                    className={styles.custom_form_field_textfield}
                    value={formValues[fieldType.field_name]}
                />;
            case "textarea":
                return <textarea
                    placeholder={fieldType.placeholder}
                    required={fieldType.flag_required}
                    style={{width: "100%"}}
                    className={styles.custom_form_field_textarea}
                    value={formValues[fieldType.field_name]}
                />
            case "dropdown":
                return (
                    <Autocomplete
                        options={fieldType.options}
                        getOptionLabel={(option) => option.name}
                        value={fieldType.options.find((option: any) => option.value === formValues[fieldType.field_name]) || null}
                        className={`${globalStyles.autocompleteParaphrasing} ${styles.methodAutoComplete}`}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                placeholder={fieldType.placeholder} 
                            />
                        )}
                        sx={{ padding: "2px 0px !important" }}
                    />
                );
            default:
                return
        }
    }

    return (
        <Grid item sx={{backgroundColor: "white", boxShadow: "0px 0px 3px rgba(0,0,0,0.2)", borderRadius: "7px"}} mt={2}>
            <Grid container direction={"column"} className={styles.custom_form_grid_style} gap={2}>
                <Grid item textAlign={"center"}>
                    <span className={styles.custom_form_title_text_style}>{data.message_fields.title}</span>
                </Grid>
                <Divider />
                <Grid item>
                    <Grid container direction={"column"} gap={2}>
                        {data?.message_fields?.fields?.filter((fields: any) => fields.type !== "hidden")?.map((field: any) =>
                            <Grid item>
                                <Grid container direction={"column"} gap={"4px"}>
                                    <Grid item>
                                        <span className={styles.custom_form_field_heading_style}>{field.field_name} {field.flag_required && <Required />}</span>
                                    </Grid>
                                    <Grid item>
                                        {handleCheckFieldType(field)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item textAlign={"center"}>
                    <Button 
                        className={styles.custom_field_button_style} 
                        disabled
                        sx={{
                            backgroundColor: chatbotTheme.chatbot_header_color ? `${chatbotTheme.chatbot_header_color} !important` : "#6A097D", 
                            color: chatbotTheme.chatbot_header_font_color ? `${chatbotTheme.chatbot_header_font_color} !important` : "#FFF !important", 
                            '&hover': {
                                backgroundColor: chatbotTheme.chatbot_header_font_color ? `${chatbotTheme.chatbot_header_font_color} !important` : "#FFF !important",
                                color: chatbotTheme.chatbot_header_color ? `${chatbotTheme.chatbot_header_color} !important` : "#6A097D"
                            }
                        }}
                    >{data.message_fields.button}</Button>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default CustomForm
