import { Box, Button, Divider, Drawer, Grid, IconButton, Menu, MenuItem, Pagination, Stack, Switch, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import "../../../assets/styles/componentStyles/chatbotStyles/chatsMobileResponsive.module.scss";
import IconsColor from '../../utility/iconsColor';
import chatbotLogo from "../../../assets/images/chatbot/chatbotProfileAvtar.png"
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chatsMobileResponsive.module.scss";
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { convertKeysToLowerCase, formatDateAccordingToTimezoneUsingMoment, getMessage, hexToRgba, isAdmin, isJsonString } from '../../../utils/constants';
import botIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png";
import { useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Markdown from 'react-markdown';
import { feedbackLevel, feedbackProvidedType, reactionData } from '../../../models/axiosModel';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import RefreshIcon from '@mui/icons-material/Refresh';
import Carousel from '../../utility/globalCarousel/carousel';
import InsuranceCard from './chatWidgetComponents/insuranceCard';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackDialog from './chatWidgetComponents/feedbackDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactCountryFlag from "react-country-flag"
import GlobalPopUp from '../../utility/globalPopUp';
import RatingCard from './chatWidgetComponents/ratingCard';
import { stripMarkdown } from './constants';
import ChatUserThreadList from './chatUserThreadList';
import ChatInformationBlock from './chatInformationBlock';
import CartCarouselGlobal from './chatWidgetComponents/cartCarouselGlobal';
import CustomForm from './chatWidgetComponents/customForm';
import CartFormGlobal from './chatWidgetComponents/cartFormGlobal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

interface chatsProps {
    mainSection?: boolean,
    getChatLogs: any,
    currentActiveChatbotThread: any,
    setCurrentActiveChatbotThread: any,
    getMessageType: any,
    getTag: any,
    getSourceDetails: any,
    handleSearch: any,
    filteredData: any,
    setFilteredData: any,
    sourceDetails: any,
    setSourceDetails: any,
    handlePaginationChat: any,
    handleRefresh: any,
    activeChatsFiltered: any,
    closedChatsFiltered: any,
    chatInfoClicked: any,
    chatClicked: any,
    setChatClicked: any,
    setChatInfoClicked: any,
    handleSubTabs: any,
    subType: any,
    setSubType: any,
    setSearchParams: any,
    handleLastMessageValue: any,
    chatBotDetails: any,
    copyText: any,
    setCopyText: any,
    handleOpenMenu: any,
    handleCloseMenu: any,
    setFlagFeedbackDialog: any,
    initialData: any,
    setThreadId: any,
    flagTellusMoreOptionBtn: any,
    setFlagTellusMoreOptionBtn: any,
    feedbackData: any,
    setFeedbackData: any,
    buttonText: any,
    handleSubmitFeedback: any,
    flagFilterFeedback: any,
    setFilterFeedback: any,
    handleFilterFeedback: any,
    feedbackMessageNewFormat: any,
    flagOtherSelected: any,
    setFlagOtherSelected: any,
    handleTellUsMoreIssue: any,
    flagFeedbackDialog: any,
    threadId: any,
    setFeedbackMessageNewFormat: any,
    initialNewFormatMessage: any,
    handleResetChatbotThread: any,
    chatLevelMenu: any,
    setChatlevelMenu: any,
    setFeedbackDetails: any,
    setValue: any,
    feedbackDetails: any,
    getChatFeedbackKeys: any,
    value: any,
    isFeedbackFormOpen: boolean,
    setIsFeedbackFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
    feedbackProvidedTypeData: feedbackProvidedType[],
    feedbackLevelData: feedbackLevel[],
}
export default function ChatsMobileResponsive(props: chatsProps) {
    const { userDetail } = useContext(UserContext) as UserContextPayload;
    const [searchParams] = useSearchParams()
    const id = searchParams.get('chatbot_id')
    const feedback_id = searchParams.get('feedback_id')
    const [open, setOpen] = useState(true);
    const smallScreen = useMediaQuery('(max-width : 1250px) and (min-width : 1000px)')
    const mobileScreen = useMediaQuery('(max-width : 1000px) and (min-width : 0px)')
    const mediaScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const mediaMediumScreen = useMediaQuery('(max-width : 1000px) and (min-width : 601px)')
    const hasEffectRun = useRef(false);
    const innerHeight = mediaScreen ? (window.innerHeight - 90) : (window.innerHeight - 110);
    const chatsViewComponentIH = mediaScreen ? (window.innerHeight - 225) : (window.innerHeight - 245);
    const specificChatsViewComponentIH = mediaScreen ? (window.innerHeight - 215) : (window.innerHeight - 245);
    const chatbotMessagesBlockIH = mediaScreen ? (window.innerHeight - 180) : (window.innerHeight - 125);
    const {
        currentActiveChatbotThread,
        setCurrentActiveChatbotThread,
        getMessageType,
        getTag,
        handleSearch,
        filteredData,
        sourceDetails,
        handlePaginationChat,
        handleRefresh,
        activeChatsFiltered,
        closedChatsFiltered,
        chatClicked,
        chatInfoClicked,
        setChatClicked,
        setChatInfoClicked,
        handleSubTabs,
        subType,
        setSearchParams,
        handleLastMessageValue,
        chatBotDetails,
        feedbackData,
        setFeedbackData,
        setFlagFeedbackDialog,
        setThreadId,
        setFlagTellusMoreOptionBtn,
        handleOpenMenu,
        handleCloseMenu,
        handleSubmitFeedback,
        flagFilterFeedback,
        handleFilterFeedback,
        feedbackMessageNewFormat,
        flagOtherSelected,
        setFlagOtherSelected,
        handleTellUsMoreIssue,
        flagFeedbackDialog,
        threadId,
        setFeedbackMessageNewFormat,
        initialNewFormatMessage,
        setValue,
        setFeedbackDetails,
        feedbackDetails,
        getChatFeedbackKeys,
        value,
        setChatlevelMenu,
        isFeedbackFormOpen,
        setIsFeedbackFormOpen,
        feedbackProvidedTypeData,
        feedbackLevelData,
    } = props

    useEffect(() => {
        if (searchParams.get('chatbot_user_id')) {
            setChatClicked(true)
        }
        else if (searchParams.get('chatbot_user_id') === "") {
            setChatClicked(false)
        }
        else {
            setChatClicked(false)
        }

    }, [searchParams, userDetail])

    useEffect(() => {
        if (chatClicked) {
            if (!hasEffectRun.current && currentActiveChatbotThread !== null) {
                const element = document.getElementById("chatUI")
                element?.scrollTo(0, element.scrollHeight)
                hasEffectRun.current = true;
            }
        }
        else {
            hasEffectRun.current = false;
        }
    }, [currentActiveChatbotThread, chatClicked])

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
            <Grid container sx={{ padding: props.mainSection ? { xs: "0px 10px 10px 15px", sm: "25px 15px 10px 18px" } : { xs: "0px 15px", sm: "0px 25px 0px 25px" } }}>
                <Grid item xs={12}>
                    <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                        {props.mainSection ? <><Grid item className={globalStyles.fullwidth} sx={{ marginBottom: !mediaScreen ? "10px" : "" }}>
                            <GlobalPageHeaderText
                                mainHeadText={"Inbox"}
                                flagTimezone={!mediaScreen ? true : false}
                            />
                        </Grid>
                            {!mediaScreen && <Divider className={`${globalStyles.fullwidth} ${styles.dividerStyle}`} sx={{ paddingTop: mediaScreen ? "10px !important" : "0px", marginBottom: mediaScreen ? "0px" : mediaMediumScreen ? "0px" : "0px" }} />}
                        </>
                            :
                            <></>
                        }
                        <Grid item className={`${globalStyles.fullwidth} ${styles.lowerBlock}`} sx={{ position: "relative", height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important` }}>
                            <Grid item sx={{ width: "100% !important", position: "absolute" }}>
                                <Grid container
                                    flexWrap={"nowrap"}
                                    justifyContent={"unset"} sx={{ maxWidth: "calc(100%)" }}>


                                    {/* left block */}
                                    {!feedback_id && 
                                    <ChatUserThreadList
                                        id={id}
                                        flagFilterFeedback={flagFilterFeedback}
                                        subType={subType}
                                        handleFilterFeedback={handleFilterFeedback}
                                        handleSearch={handleSearch}
                                        handleSubTabs={handleSubTabs}
                                        filteredData={filteredData}
                                        currentActiveChatbotThread={currentActiveChatbotThread}
                                        handleRefresh={handleRefresh}
                                        setFeedbackDetails={setFeedbackDetails}
                                        setValue={setValue}
                                        activeChatsFiltered={activeChatsFiltered}
                                        hasEffectRun={hasEffectRun}
                                        handleLastMessageValue={handleLastMessageValue}
                                        handlePaginationChat={handlePaginationChat}
                                        closedChatsFiltered={closedChatsFiltered}
                                        flagMobile={true}
                                        chatClicked={chatClicked}
                                        chatInfoClicked={chatInfoClicked}
                                    />}

                                    {/* middle */}

                                    <Grid item>
                                        <Menu

                                            MenuListProps={{
                                                'aria-labelledby': 'long-button'
                                            }}
                                            anchorEl={props.chatLevelMenu.anchorEl ?? null}
                                            open={props.chatLevelMenu.flagOpen ?? false}
                                            onClose={(e) => setChatlevelMenu((prevState: any) => ({ ...prevState, flagOpen: false, anchorEl: null }))}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            PaperProps={{
                                                style: {
                                                    borderRadius: '10px',
                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                    padding: "0 8px"
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                onClick={(event) => {
                                                    props.handleResetChatbotThread()
                                                }
                                                }
                                                className="menu-message-item"

                                            >
                                                Reset
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                    <Grid />
                                    <Grid xs={feedback_id ? 12 : smallScreen ? 6 : 12} item className={styles.chatbotMessagesBlock} sx={{ height: `${chatbotMessagesBlockIH}px !important`, display: smallScreen && chatInfoClicked ? "none" : smallScreen ? "block" : chatClicked ? "block" : "none", paddingLeft: smallScreen ? "" : "", paddingRight: mediaScreen ? "8px" : "" }}>
                                        <Grid container direction={"column"} gap={"0px"} sx={{ flexWrap: "nowrap", boxShadow: mobileScreen ? "0px 0px 3px rgba(0,0,0,0.25)" : "", borderRadius: mobileScreen ? "10px" : "", maxWidth: feedback_id ? "100%" : "98%" }} margin={mobileScreen ? "8px 0px 0px 4px" : ""}>
                                            <Grid item mt={!mediaScreen ? 0 : 0}>
                                                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#FFFFFF", color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D", width: "100%", borderRadius: mobileScreen ? "10px 10px 0px 0px" : "" }}>
                                                    <Grid item>
                                                        <Grid container alignItems={"center"} gap={"0px"} flexWrap={"nowrap"}>
                                                            <Grid item sx={{ maxWidth: mediaScreen ? "calc(100% - 25px)" : "100%", padding: "14px 5px 14px 10px", minWidth: "250px" }}>
                                                                <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                                                                    {!smallScreen && !feedback_id && <Grid item onClick={() => {
                                                                        setChatClicked(false);
                                                                        setCurrentActiveChatbotThread(null)
                                                                        setSearchParams((prevParams: any) => {
                                                                            const newParams = new URLSearchParams(prevParams);
                                                                            newParams.set('chatbot_user_id', "");
                                                                            return newParams;
                                                                        });
                                                                    }}><ArrowBackIcon style={{ color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D", display: "flex" }} /></Grid>}
                                                                    <Grid item>
                                                                        <img src={currentActiveChatbotThread?.chatbot_logo_url ? currentActiveChatbotThread?.chatbot_logo_url : chatBotDetails?.chatbot_logo_url ? chatBotDetails?.chatbot_logo_url : chatbotLogo} style={{ width: "30px", height: "auto", borderRadius: "50%", display: "flex", aspectRatio: "1" }} alt="chatbot" />
                                                                    </Grid>
                                                                    <Grid item
                                                                        className={styles.chatbotNameHeading}
                                                                        sx={{ color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                                    >
                                                                        {currentActiveChatbotThread?.chatbot_name ? currentActiveChatbotThread?.chatbot_name : chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={styles.userNameHeading} sx={{ color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#6A097D", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {currentActiveChatbotThread?.chatbot_user_name}
                                                            </Grid>
                                                            {isAdmin(userDetail) && currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_route_source.toLowerCase() == "whatsapp" &&
                                                                <Grid item>
                                                                    <MoreVertIcon
                                                                        onClick={(event) => { setChatlevelMenu((prevState: any) => ({ ...prevState, flagOpen: true, anchorEl: event.currentTarget })) }}
                                                                        style={{ color: "#869596", cursor: "pointer" }} />
                                                                </Grid>}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container gap="25px" justifyContent={"center"} alignItems={"center"}>

                                                            <Grid item onClick={() => { setOpen(true); setChatInfoClicked(true); setChatClicked(false) }} sx={{ position: "relative", cursor: "pointer", zIndex: 5 }}>
                                                                <ChevronLeftIcon style={{ position: "absolute", top: "-13px", left: "-33px", backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#F7E9FF", color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#000000", borderRadius: "50%", aspectRatio: "1" }} /></Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Divider className={styles.dividerStyle} />

                                            <Grid item
                                                className={props.mainSection ? styles.chatsViewComponent : styles.specificChatsViewComponent}
                                                sx={{
                                                    backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
                                                    paddingLeft: "5px", paddingBlock: "10px",
                                                    height: props.mainSection ? `${chatsViewComponentIH}px !important` : `${specificChatsViewComponentIH}px !important`
                                                }}
                                            >
                                                {/*change here */}
                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Grid item className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}>
                                                        <Box
                                                            className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                                                        >
                                                            <Grid container
                                                                className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                                                            >
                                                                <Grid item xs={12}
                                                                    className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}
                                                                    sx={{
                                                                        borderRadius: "15px"
                                                                    }}>
                                                                    <Grid container className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`} sx={{ overflow: "hidden" }} >

                                                                        <>
                                                                            <Grid item xs={12} className={`${globalStyles.fullHeight} ${globalStyles.fullwidth}`}>
                                                                                <Grid container id="chatUI" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "0px 2px 0px 0px !important" }} overflow={"auto"}
                                                                                    className={`${globalStyles.fullHeight} ${globalStyles.fullwidth} ${styles.chatbotChatMainSectionItem}`}
                                                                                >

                                                                                    {(currentActiveChatbotThread !== null && currentActiveChatbotThread?.chatbot_thread_data[0].chatbot_thread_id !== "") ?


                                                                                        currentActiveChatbotThread ? currentActiveChatbotThread?.chatbot_thread_data.map((thread: any) => {
                                                                                            const isBot = thread?.flag_user_message == false
                                                                                            return (
                                                                                                <>
                                                                                                    {isBot ? (
                                                                                                        <>
                                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "10px 0px" }}>
                                                                                                                <Grid container sx={{ justifyContent: "start", flexWrap: "" }}>

                                                                                                                    <Grid item className={styles.chatbotChatIconDiv} >
                                                                                                                        <Grid container direction={"column"} gap={"2px"} alignItems={"center"}>
                                                                                                                            <Grid item>
                                                                                                                                <img src={currentActiveChatbotThread?.chatbot_logo_url ? currentActiveChatbotThread.chatbot_logo_url : botIcon}
                                                                                                                                    alt="chatbot Icona"
                                                                                                                                    style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", aspectRatio: "1", marginRight: "2px" }}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            {isAdmin(userDetail) &&
                                                                                                                                <Grid item sx={{ marginRight: "2px" }}>
                                                                                                                                    <Tooltip
                                                                                                                                        title={
                                                                                                                                            <Grid container direction={"column"}>
                                                                                                                                                <Grid item>
                                                                                                                                                    <Grid container justifyContent={"space-between"} gap={1}>
                                                                                                                                                        <Grid item><span>{`AI Response Time: `}</span></Grid>
                                                                                                                                                        <Grid item><span>{thread?.ai_response_time}</span></Grid>
                                                                                                                                                    </Grid>
                                                                                                                                                </Grid>
                                                                                                                                                <Grid item>
                                                                                                                                                    <Grid container justifyContent={"space-between"} gap={1}>
                                                                                                                                                        <Grid item><span>{`Total Response Time: `}</span></Grid>
                                                                                                                                                        <Grid item><span>{thread?.total_response_time}</span></Grid>
                                                                                                                                                    </Grid>
                                                                                                                                                </Grid>
                                                                                                                                            </Grid>
                                                                                                                                        } placement='top-end'
                                                                                                                                    >
                                                                                                                                        <ScheduleSendIcon sx={{ fontSize: "20px !important", color: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color : "#000", cursor: "pointer" }} />
                                                                                                                                    </Tooltip>
                                                                                                                                </Grid>
                                                                                                                            }
                                                                                                                        </Grid>
                                                                                                                    </Grid>

                                                                                                                    <Grid item sx={{ width: "100%", maxWidth: "calc(100% - 60px)" }}>
                                                                                                                        <Grid container direction={"column"}>
                                                                                                                            <Grid item
                                                                                                                                className={styles.parentbotMsgBoxStyle}
                                                                                                                                sx={{
                                                                                                                                    marginLeft: "6px",
                                                                                                                                    padding: "15px 10px",
                                                                                                                                    borderRadius: "0px 10px 10px 10px",
                                                                                                                                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                                    fontSize: "13px",
                                                                                                                                    fontWeight: "400",
                                                                                                                                    width: "calc(100% - 10px)",
                                                                                                                                    backgroundColor: currentActiveChatbotThread.chatbot_theme?.system_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_bg_color : chatBotDetails?.chatbot_theme.system_msg_bg_color ? chatBotDetails?.chatbot_theme.system_msg_bg_color : "#FBF4FF",
                                                                                                                                    maxWidth: "fit-content",
                                                                                                                                    position: "relative",
                                                                                                                                }}
                                                                                                                            >

                                                                                                                                <IconButton
                                                                                                                                    aria-label="more"
                                                                                                                                    id={thread?.chatbot_thread_id?.toString()}
                                                                                                                                    key={thread.chatbot_thread_id}
                                                                                                                                    aria-haspopup="true"
                                                                                                                                    disableRipple
                                                                                                                                    disableFocusRipple
                                                                                                                                    disableTouchRipple
                                                                                                                                    sx={{
                                                                                                                                        position: "absolute",
                                                                                                                                        top: "-10px",
                                                                                                                                        right: "0px",
                                                                                                                                    }}
                                                                                                                                    className="chatbotMenuIcon"
                                                                                                                                    onClick={(e) => {
                                                                                                                                        handleOpenMenu(e, thread.chatbot_thread_id)
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <KeyboardArrowDownIcon
                                                                                                                                        sx={{
                                                                                                                                            fontSize: "large",
                                                                                                                                            width: '20px',
                                                                                                                                            height: 'auto'
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </IconButton>

                                                                                                                                <pre
                                                                                                                                    className={styles.botMsgBoxStyle}
                                                                                                                                    style={{
                                                                                                                                        color: currentActiveChatbotThread.chatbot_theme?.system_msg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "black",
                                                                                                                                        overflow: "unset",
                                                                                                                                        width: "100%",
                                                                                                                                        whiteSpace: "normal",
                                                                                                                                        wordBreak: "break-word",
                                                                                                                                        maxWidth: "unset",
                                                                                                                                        background: "unset !important",
                                                                                                                                        position: "relative"
                                                                                                                                    } as CSSProperties}
                                                                                                                                >
                                                                                                                                    {thread.quote_reply_data && thread.quote_reply_data.chatbot_thread_id &&
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                background: "#E0E0E0", padding: "15px", cursor: "pointer", borderRadius: "10px",
                                                                                                                                                borderLeft: "5px solid #6A097D",
                                                                                                                                                marginBottom: "10px"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <div
                                                                                                                                                style={{ color: "#6A097D", fontWeight: "600" }}
                                                                                                                                            >
                                                                                                                                                {currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id)?.flag_user_message ? "You" : currentActiveChatbotThread.chatbot_name}
                                                                                                                                            </div>

                                                                                                                                            {
                                                                                                                                                getTag(currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id),
                                                                                                                                                    "messageReply",
                                                                                                                                                    // true,
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                    {getTag(thread, getMessageType(thread))}
                                                                                                                                </pre>

                                                                                                                                {thread.chatbot_message_feedback && thread.chatbot_message_feedback.length > 0 && thread.chatbot_message_feedback[0].chatbot_message_feedback_id != "" &&
                                                                                                                                    <p style={{ position: "absolute", right: "-35px", top: "5px" }}>
                                                                                                                                        <Tooltip title={thread.chatbot_message_feedback[0].chatbot_message_feedback_value} placement='top'>
                                                                                                                                            <InfoIcon className={styles.chatbotRatingIconStyle} sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                                                                                                                        </Tooltip>
                                                                                                                                    </p>

                                                                                                                                }

                                                                                                                                {thread.reaction_data && thread.reaction_data[0].reaction_emoji_character !== "" && thread.reaction_data.map((reaction: reactionData) => {
                                                                                                                                    return <>
                                                                                                                                        <p className={styles.reactionStyle}>{reaction.reaction_emoji_character}</p>
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                                )}

                                                                                                                                <span style={{ display: "flex", color: currentActiveChatbotThread.chatbot_theme?.system_msg_color ? currentActiveChatbotThread.chatbot_theme?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "black", fontSize: "10px", fontWeight: "500", justifyContent: "end", marginTop: "10px" }}>{formatDateAccordingToTimezoneUsingMoment(thread.timestamp, userDetail.timezone_hour, "all")}</span>
                                                                                                                            </Grid>

                                                                                                                            {isJsonString(thread.chatbot_thread_value) && typeof JSON.parse(thread.chatbot_thread_value) == "object" && (JSON.parse(thread.chatbot_thread_value).message_format_id) == 2 &&
                                                                                                                                <Grid container gap="10px" mt={1} marginLeft={1}>
                                                                                                                                    {JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.suggestions.map((ele: any, i: any) => {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <Button
                                                                                                                                                    key={i}
                                                                                                                                                    disableRipple
                                                                                                                                                    sx={{
                                                                                                                                                        backgroundColor: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color : "#fff !important",
                                                                                                                                                        borderColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color} !important` : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#000000",
                                                                                                                                                        color: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color : currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color} !important` : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#000000",
                                                                                                                                                        fontWeight: "600 !important",
                                                                                                                                                        borderRadius: "5px !important",
                                                                                                                                                        padding: "5px 10px !important",
                                                                                                                                                        textTransform: "capitalize",
                                                                                                                                                        minWidth: "unset !important",
                                                                                                                                                        '&:hover': {
                                                                                                                                                            backgroundColor: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color : "#fff !important"
                                                                                                                                                        }
                                                                                                                                                    }}
                                                                                                                                                    // sx={{ backgroundColor: "#fff !important", color: "#000000", fontWeight: "500 !important", borderRadius: "5px !important", padding: "5px 10px !important", minWidth: "unset !important", '&:hover': { color: "", backgroundColor: "" } }}
                                                                                                                                                    variant={'outlined'}
                                                                                                                                                    size='small'
                                                                                                                                                // className={` ${i == flagTellusMoreOptionBtn ? 'chat-feedback-selected-btn' : 'chat-feedback-btn'}`}
                                                                                                                                                >
                                                                                                                                                    {/* {Object.values(ele)[0]} */}
                                                                                                                                                    {ele.suggestion_name}
                                                                                                                                                </Button>

                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc((JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id)) == 5 &&
                                                                                                                                <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <Carousel suggestions={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))).message_fields.cards} threadData={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                                                                                                                                </Grid>
                                                                                                                            }


                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 6 &&
                                                                                                                                <Grid item sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <InsuranceCard data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 7 &&
                                                                                                                                <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <RatingCard thread={thread} nextThread={currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => threadData.chatbot_thread_id === (thread.chatbot_thread_id + 1))} data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 10 &&
                                                                                                                                <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <CartCarouselGlobal
                                                                                                                                        data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))}
                                                                                                                                        threadId={thread.chatbot_thread_id}
                                                                                                                                        thread={thread}
                                                                                                                                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 12 &&
                                                                                                                                <Grid item xs={6} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <CustomForm
                                                                                                                                        thread={thread}
                                                                                                                                        data={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")))}
                                                                                                                                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 15 &&
                                                                                                                                <Grid item xs={6} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                                    <CartFormGlobal
                                                                                                                                        data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))}
                                                                                                                                        thread={currentActiveChatbotThread}
                                                                                                                                        cartData={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.cards}
                                                                                                                                        ctaButtons={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.CTA_buttons}
                                                                                                                                        threadId={thread.chatbot_thread_id}
                                                                                                                                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                            }

                                                                                                                        </Grid>
                                                                                                                    </Grid>

                                                                                                                    <Grid item>
                                                                                                                        <Menu
                                                                                                                            id={thread?.chatbot_thread_id?.toString()}
                                                                                                                            MenuListProps={{
                                                                                                                                'aria-labelledby': 'long-button',
                                                                                                                            }}
                                                                                                                            anchorEl={thread.anchorEl ?? null}
                                                                                                                            key={thread.chatbot_thread_id}
                                                                                                                            open={thread.flagOpenMenu ?? false}
                                                                                                                            onClose={(e) => handleCloseMenu(e, thread.chatbot_thread_id)}
                                                                                                                            anchorOrigin={{
                                                                                                                                vertical: 'top',
                                                                                                                                horizontal: 'right',
                                                                                                                            }}
                                                                                                                            transformOrigin={{
                                                                                                                                vertical: 'top',
                                                                                                                                horizontal: 'left',
                                                                                                                            }}
                                                                                                                            PaperProps={{
                                                                                                                                style: {
                                                                                                                                    borderRadius: '10px',
                                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                                    padding: "0px 4px !important",
                                                                                                                                },
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MenuItem
                                                                                                                                disabled={thread.chatbot_message_feedback ? (thread.chatbot_message_feedback[0].chatbot_message_feedback_id == "" ? false : true) : true}
                                                                                                                                onClick={(event) => {
                                                                                                                                    setIsFeedbackFormOpen(true);
                                                                                                                                    setFeedbackData((prevState: any) => ({ ...prevState, chatbot_thread_id: thread.chatbot_thread_id, flagFeedbackDialog: true }))
                                                                                                                                    setThreadId(thread.chatbot_thread_id)
                                                                                                                                    setCurrentActiveChatbotThread((prevState: any) => ({ ...prevState, chatbot_thread_data: prevState.chatbot_thread_data.map((temp: any) => temp.chatbot_thread_id == thread.chatbot_thread_id ? ({ ...temp, flagOpenMenu: false, anchorEl: null }) : temp) }))
                                                                                                                                    setTimeout(() => {
                                                                                                                                        const container = document.getElementById('checkBlock')
                                                                                                                                        container?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                                                                                                                                    }, 500);
                                                                                                                                    setFlagFeedbackDialog(true)
                                                                                                                                    setFlagTellusMoreOptionBtn(null)
                                                                                                                                    // }
                                                                                                                                }}
                                                                                                                                className="menu-message-item"
                                                                                                                                sx={{ fontSize: "14px !important" }}

                                                                                                                            >
                                                                                                                                Feedback
                                                                                                                            </MenuItem>

                                                                                                                            <CopyToClipboard text={stripMarkdown(getMessage(thread))}>
                                                                                                                                <MenuItem
                                                                                                                                    className="menu-message-item"
                                                                                                                                    sx={{ fontSize: "14px !important" }}
                                                                                                                                    onClick={() => setCurrentActiveChatbotThread((prevState: any) => ({ ...prevState, chatbot_thread_data: prevState.chatbot_thread_data.map((temp: any) => temp.chatbot_thread_id == thread.chatbot_thread_id ? ({ ...temp, flagOpenMenu: false, anchorEl: null }) : temp) }))}
                                                                                                                                >
                                                                                                                                    Copy Text
                                                                                                                                </MenuItem>
                                                                                                                            </CopyToClipboard>

                                                                                                                        </Menu>
                                                                                                                    </Grid>

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "10px 0px" }}>
                                                                                                                <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                                                    <Grid item
                                                                                                                        sx={{
                                                                                                                            marginRight: "10px",
                                                                                                                            padding: "8px 8px",
                                                                                                                            borderRadius: "10px 10px 0px 10px",
                                                                                                                            boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                            fontSize: "13px",
                                                                                                                            fontWeight: "400",
                                                                                                                            backgroundColor: currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#F4F4F4",
                                                                                                                            position: "relative",
                                                                                                                            listStylePosition: "inside",
                                                                                                                            maxWidth: "calc(100% - 70px)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <IconButton
                                                                                                                            aria-label="more"
                                                                                                                            id={thread?.chatbot_thread_id?.toString()}
                                                                                                                            key={thread.chatbot_thread_id}
                                                                                                                            aria-haspopup="true"
                                                                                                                            disableRipple
                                                                                                                            disableFocusRipple
                                                                                                                            disableTouchRipple
                                                                                                                            sx={{
                                                                                                                                position: "absolute",
                                                                                                                                top: "-10px",
                                                                                                                                right: "0px",
                                                                                                                            }}
                                                                                                                            className="chatbotMenuIcon"
                                                                                                                            onClick={(e) => {
                                                                                                                                handleOpenMenu(e, thread.chatbot_thread_id)
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <KeyboardArrowDownIcon
                                                                                                                                sx={{
                                                                                                                                    fontSize: "large",
                                                                                                                                    width: '20px',
                                                                                                                                    height: 'auto'
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </IconButton>

                                                                                                                        <Typography
                                                                                                                            sx={{
                                                                                                                                color: currentActiveChatbotThread.chatbot_theme?.user_msg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "black",
                                                                                                                                wordBreak: 'break-word',
                                                                                                                                fontSize: "13px",
                                                                                                                                whiteSpace: "pre-line"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {thread.quote_reply_data && thread.quote_reply_data.chatbot_thread_id != null && (
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        position: 'relative',
                                                                                                                                        background: "#E0E0E0",
                                                                                                                                        padding: "10px",
                                                                                                                                        cursor: "pointer",
                                                                                                                                        borderRadius: "10px",
                                                                                                                                        borderLeft: "5px solid #6A097D",
                                                                                                                                        marginBottom: "10px",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        style={{ color: "#6A097D", fontWeight: "600" }}
                                                                                                                                    >
                                                                                                                                        {currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id)?.flag_user_message ? "You" : currentActiveChatbotThread.chatbot_name}
                                                                                                                                    </div>
                                                                                                                                    {/* {getNewTag(currentActiveChatbotThread.chatbot_thread_data.find((initialThread) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id), "messageReply", true)} */}
                                                                                                                                    {getTag(currentActiveChatbotThread.chatbot_thread_data.find((initialThread: any) => initialThread.chatbot_thread_id == thread.quote_reply_data.chatbot_thread_id), "messageReply", true)}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                            {getTag(thread, getMessageType(thread))}
                                                                                                                        </Typography>
                                                                                                                        {thread.reaction_data && thread.reaction_data[0].reaction_emoji_character !== "" && thread.reaction_data.map((reaction: reactionData) => {
                                                                                                                            return <>
                                                                                                                                <p className={styles.reactionUserStyle}>{reaction.reaction_emoji_character}</p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                        )}
                                                                                                                        <span style={{ display: "flex", color: currentActiveChatbotThread.chatbot_theme?.user_msg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "black", fontSize: "10px", fontWeight: "500", justifyContent: "end", marginTop: "10px" }}>{formatDateAccordingToTimezoneUsingMoment(thread.timestamp, userDetail.timezone_hour, "all")}</span>
                                                                                                                    </Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Menu
                                                                                                                            id={thread?.chatbot_thread_id?.toString()}
                                                                                                                            MenuListProps={{
                                                                                                                                'aria-labelledby': 'long-button',
                                                                                                                            }}
                                                                                                                            anchorEl={thread.anchorEl ?? null}
                                                                                                                            key={thread.chatbot_thread_id}
                                                                                                                            open={thread.flagOpenMenu ?? false}
                                                                                                                            onClose={(e) => handleCloseMenu(e, thread.chatbot_thread_id)}
                                                                                                                            anchorOrigin={{
                                                                                                                                vertical: 'top',
                                                                                                                                horizontal: 'right',
                                                                                                                            }}
                                                                                                                            transformOrigin={{
                                                                                                                                vertical: 'top',
                                                                                                                                horizontal: 'left',
                                                                                                                            }}
                                                                                                                            PaperProps={{
                                                                                                                                style: {
                                                                                                                                    borderRadius: '10px',
                                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                                    padding: "0px 4px !important",
                                                                                                                                    minHeight: "0px !important"
                                                                                                                                },
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <CopyToClipboard text={stripMarkdown(getMessage(thread))}>
                                                                                                                                <MenuItem
                                                                                                                                    className="menu-message-item"
                                                                                                                                    sx={{ fontSize: "14px !important", '& .MuiButtonBase-root': { minHeight: "none" } }}
                                                                                                                                    onClick={() => setCurrentActiveChatbotThread((prevState: any) => ({ ...prevState, chatbot_thread_data: prevState.chatbot_thread_data.map((temp: any) => temp.chatbot_thread_id == thread.chatbot_thread_id ? ({ ...temp, flagOpenMenu: false, anchorEl: null }) : temp) }))}
                                                                                                                                >
                                                                                                                                    Copy Text
                                                                                                                                </MenuItem>
                                                                                                                            </CopyToClipboard>

                                                                                                                        </Menu>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </>
                                                                                                    )
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }) : ""
                                                                                        :
                                                                                        <>
                                                                                            <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                                <Grid item>
                                                                                                    <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation selected yet.</Typography></Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item sx={{ opacity: "1", paddingInline: "15px", paddingBottom: "10px", backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme?.chatbot_back_ground_color : "#FFFFFF", borderRadius: "0px 0px 10px 10px" }}>
                                                <Grid container gap={1} flexWrap={"nowrap"}>
                                                    <Grid item
                                                        sx={{
                                                            width: "100%",
                                                            borderRadius: "10px",
                                                            background: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                                            border: `1px solid ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                            boxShadow: `0 1px 2px ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`
                                                        }}
                                                    >
                                                        <TextField
                                                            disabled
                                                            placeholder='Type a message...'
                                                            sx={{
                                                                border: 'none',
                                                                "& fieldset": {
                                                                    border: 'none',
                                                                },
                                                                width: "100%",
                                                                borderRadius: "10px",
                                                                backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color : "#FFFFFF",
                                                                '& .Mui-disabled': {
                                                                    '-webkit-text-fill-color': currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_font_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                                                },
                                                                '& .MuiOutlinedInput-input': {
                                                                    padding: "12.5px 14px !important",
                                                                    fontSize: "14px !important",
                                                                    fontWeight: "500"
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={styles.chatbot_preview_arrow_item} sx={{ backgroundColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color }}>
                                                        <IconButton
                                                            sx={{
                                                                border: `1px solid ${hexToRgba(currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                borderRadius: '8px',
                                                                height: '100%',
                                                                width: '100%',
                                                                padding: '5px'
                                                            }}
                                                        >
                                                            <IconsColor
                                                                fillColor={currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color}
                                                                iconName="send"
                                                                width="18px"
                                                                height="18px"
                                                            />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Divider orientation='vertical' />

                                    {/* chat information block */}
                                    {chatInfoClicked &&

                                        <ChatInformationBlock
                                            open={open}
                                            setOpen={setOpen}
                                            value={value}
                                            setValue={setValue}
                                            sourceDetails={sourceDetails}
                                            feedbackDetails={feedbackDetails}
                                            getChatFeedbackKeys={getChatFeedbackKeys}
                                            setChatInfoClicked={setChatInfoClicked}
                                            setChatClicked={setChatClicked}
                                            flagMobile
                                            chatClicked={chatClicked}
                                            chatInfoClicked={chatInfoClicked}
                                        />

                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <GlobalPopUp
                    titlePadding="0px !important"
                    // open={feedbackData.flagFeedbackDialog}
                    // handleClose={() => { setFeedbackData((prevState: any) => ({ ...prevState, flagFeedbackDialog: false })) }}  
                    open={isFeedbackFormOpen}
                    handleClose={() => setIsFeedbackFormOpen(false)}
                >
                    <FeedbackDialog
                        flagFeedbackDialog={flagFeedbackDialog}
                        setFlagFeedbackDialog={setFlagFeedbackDialog}
                        chatbot_thread_id={threadId}
                        setFlagTellusMoreOptionBtn={setFlagTellusMoreOptionBtn}
                        handleSubmitFeedback={handleSubmitFeedback}
                        setFeedbackData={setFeedbackData}
                        feedbackData={feedbackData}
                        handleTellUsMoreIssue={handleTellUsMoreIssue}
                        setFeedbackMessageNewFormat={setFeedbackMessageNewFormat}
                        feedbackMessageNewFormat={feedbackMessageNewFormat}
                        initialNewFormatMessage={initialNewFormatMessage}
                        flagOtherSelected={flagOtherSelected}
                        setFlagOtherSelected={setFlagOtherSelected}
                        currentActiveChatbotThread={currentActiveChatbotThread}
                        setCurrentActiveChatbotThread={setCurrentActiveChatbotThread}
                        feedbackProvidedTypeData={feedbackProvidedTypeData}
                        feedbackLevel={feedbackLevelData}
                        onCloseOfFeedbackForm={() => setIsFeedbackFormOpen(false)}
                        feedbackFor="message"
                        chatbotThreadId={threadId}
                    />
                </GlobalPopUp>
            </Grid>
        </Grid >

    )
}
