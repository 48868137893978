import React, { useContext, useEffect, useState } from 'react';
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext';
import { UserContext, UserContextPayload } from '../../../../../context/userContext';
import { IntegrationContext, IntegrationContextPayload } from '../../../../../context/integrationContext';
import ShowAvailableSlackChannels from './showAvailableChannels';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import GlobalButton from '../../../../utility/globalButton/button';
import IconsColor from '../../../../utility/iconsColor';
import { useSearchParams } from 'react-router-dom';
import agent from '../../../../../services/api';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../../utils/constants';
import FacebookInformationCard from '../../../../utility/facebookInformationCard';
import integrationStyles from '../../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import GlobalDeletePopup from '../../../../utility/globalDeletePopup/globalDeletePopup';
import NoAuthorisedSlackAccount from './noAuthorisedSlackAccount';

const ChatbotSlackIntegration = () => {
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const { chatBotDetails, GetChatbotDataApi } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setFlagLoading, setSuccess, setError, userDetail } = useContext(UserContext) as UserContextPayload;
    const { globalIntegrationTypeList } = useContext(IntegrationContext) as IntegrationContextPayload
    const [searchParams] = useSearchParams()
    const [flagShowAvailableChannels, setFlagShowAvailableChannels] = useState(false)
    const [availablePagesData, setAvailablePagesData] = useState<any[]>([])
    const [delinkedChannelDetails, setDelinkedChannelDetails] = useState<any>({
        flagDelink: false,
        channelDetails: null
    })
    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState<any>({
        chatbot_id: "",
    })
    const [confirmationPopover, setConfirmationPopover] = React.useState<any>({
        flagOpen: false,
        anchorEl: null
    });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setConfirmationPopover((prevState: any) => ({ ...prevState, anchorEl: event.currentTarget, flagOpen: true }));
    };

    const handleCloseConfirmationPopover = () => {
        setConfirmationPopover((prevState: any) => ({ ...prevState, anchorEl: null, flagOpen: false }));
    };
    const { slackIntegrations, getSlackIntegrations } = useContext(IntegrationContext) as IntegrationContextPayload
    useEffect(() => {
        if (!slackIntegrations) {
            getSlackIntegrations()
        }
    }, [chatBotDetails])

    useEffect(() => {
        if (slackIntegrations) {
            let temp: any = slackIntegrations.map((slack) => ({ ...slack, slack_channels: slack.slack_channels.map((channel) => ({ ...channel, slack_integration_id: slack.slack_integration_id, slack_team_name: slack.slack_team_name })) }))
            setAvailablePagesData(temp)
        }
    }, [slackIntegrations, chatBotDetails])

    async function handleRemoveChatbotIntegration(chatbot_slack_assignment_id: string) {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.deleteChatbotSlackIntegration.delete(chatbot_slack_assignment_id)
        if (response.status) {
            let chatbot_id = Number(searchParams.get('chatbot_id'));
            getSlackIntegrations()
            GetChatbotDataApi(chatbot_id)
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setDelinkedChannelDetails((prevstate: any) => ({ ...prevstate, flagDelink: false, channelDetails: null }))
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

    }
    async function saveSlackIntegrationDetails() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.createChatbotSlackIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            let chatbot_id = Number(searchParams.get('chatbot_id'))
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            // setGetChatbotIntegrationData(response.data)
            getSlackIntegrations()
            if (chatbot_id) {
                GetChatbotDataApi(chatbot_id)
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagShowAvailableChannels(false)
        handleCloseConfirmationPopover()
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    function AddChatbotSlackIntegration(slack_integration_id: number, channel_id: number, display_page_name: string, event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            handleClick(event)
        }
        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id) {
            setAddChatbotIntegrationDetails((prevState: any) => ({
                ...prevState,
                slack_integration_id: slack_integration_id,
                slack_channel_id: [channel_id],
                chatbot_id: chatbot_id,
            }))
        }
    }

    const pollForCallbackStatus = (stateValue: string) => {
        const pollInterval = 8000;
        const maxPollingTime = 80000;
        let elapsedTime = 0;

        const intervalId = setInterval(async () => {
            try {
                const response = await agent.getCallbackStatus.get(stateValue);

                if (response.status) {
                    const integrationStatus = response?.data?.integration_status?.toLowerCase();

                    if (integrationStatus === 'completed' || integrationStatus === 'failed') {
                        clearInterval(intervalId);

                        if (integrationStatus === 'failed') {
                            alert(response?.data?.reason);
                        } else {
                            // makeIntegrationDataCall(response?.data);
                            getSlackIntegrations()
                        }
                    } else {
                        console.log("Callback not completed, retrying...");
                    }

                    elapsedTime += pollInterval;

                    if (elapsedTime >= maxPollingTime) {
                        console.log("Polling stopped after 1 minute.");
                        clearInterval(intervalId);
                    }
                } else {
                    let errorCode = checkForErrorCode(response.data)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            } catch (error) {
                console.error("Error polling for callback status:", error);
                clearInterval(intervalId);
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }, pollInterval);
    };
    async function handleIntegrateSlack() {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            let slackIntegration = globalIntegrationTypeList.find((integration) => integration.integration_app_name == "Slack")
            let currentUrl = window.location.href;
            if (slackIntegration && slackIntegration.integration_app_id) {
                const payload = {
                    current_url: currentUrl,
                    integration_app_id: slackIntegration.integration_app_id,
                };

                const response = await agent.calendlyAuthUrlApi.post(payload);
                if (response.status) {
                    const authUrl = response?.data?.auth_url;
                    if (authUrl) {
                        window.open(response.data.auth_url, "_blank")
                        const stateValue = new URL(authUrl).searchParams.get('state');
                        if (stateValue) {
                            pollForCallbackStatus(stateValue);
                        } else {
                            console.error('State param not found in the auth_url.');
                        }
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <>
                {(slackIntegrations && slackIntegrations?.length > 0) ?
                    <>
                        {
                            chatBotDetails && chatBotDetails.chatbot_slack_data.length > 0 ?
                                <>
                                    <Grid container gap="20px" direction={"column"} sx={{ padding: { xs: "15px 7px", lg: "15px 15px 15px 5px" } }} mt={{ xs: 1, lg: 0 }}>
                                        <Grid item>
                                            <Grid container gap="15px">
                                                {chatBotDetails.chatbot_slack_data.flatMap((slack: any) => slack.slack_channels.map((channel: any) => ({ ...channel, username: slack.slack_user_real_name }))).map((channel: any, index: number) => {
                                                    return <>
                                                        <FacebookInformationCard
                                                            integrationType='Slack'
                                                            key={index}
                                                            flagShowLinkedChatbot
                                                            flagDelete={false}
                                                            title={`Slack Account ${index + 1}`}
                                                            currentlySelected={true}
                                                            showRemoveChatbotIntegration={true}
                                                            disabled={channel.chatbot_slack_assignment_data
                                                                && channel.chatbot_slack_assignment_data
                                                                    .length > 0 ? true : false}
                                                            removeChatbotIntegration={(chatbot_slack_assignment_id: string) => {
                                                                // handleRemoveChatbotIntegration(chatbot_slack_assignment_id)
                                                                setDelinkedChannelDetails((prevState: any) => ({ ...prevState, flagDelink: true, channelDetails: channel }))
                                                            }}

                                                            details={{
                                                                page_id: channel?.chatbot_slack_assignment_id,
                                                                page_name: `${channel?.slack_channel_name} - ${channel.username}`,
                                                                page_category: channel?.slack_channel_purpose,
                                                                linked_chatbots: [channel]
                                                            }}
                                                            flagIntegration={true}
                                                        />
                                                    </>
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ paddingTop: "0px" }}>
                                            <GlobalButton buttonText={"Add Slack Channel"} className='blackShadowedButton' onClick={() => { setFlagShowAvailableChannels(true) }} startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%" }} padding={"30px"} mt={"10px"}>
                                        <Grid container direction={"column"}>
                                            <Grid item>
                                                <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>This Chatbot is not linked with any Slack Channel Yet. </span>
                                            </Grid>
                                            <Grid item sx={{ paddingTop: "20px" }}>
                                                <GlobalButton buttonText={"Add Slack Channel"} className='primaryButtonStyle' onClick={() => { setFlagShowAvailableChannels(true) }} sx={{ padding: "6px 20px !important" }} startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                        }
                    </> :
                    <>
                        <NoAuthorisedSlackAccount handleIntegrateSlack={handleIntegrateSlack} />
                    </>}

                {flagShowAvailableChannels &&
                    <ShowAvailableSlackChannels
                        flagShowAvailableNumbers={flagShowAvailableChannels}
                        setFlagShowAvailableNumbers={setFlagShowAvailableChannels}
                        smallScreen={smallScreen}
                        showConfirmationPopover={confirmationPopover.flagOpen}
                        confirmationPopoverAnchorEl={confirmationPopover.anchorEl}
                        handleCloseConfirmationPopover={handleCloseConfirmationPopover}
                        AddChatbotIntegrationDetails={addChatbotIntegrationDetails}
                        AddChatbotIntegration={AddChatbotSlackIntegration}
                        availablePhoneNumberData={availablePagesData}
                        handleRemoveChatbotIntegration={() => { }}
                        displayPhoneNumber={'phoneNumberIntegrate'}
                        launchWhatsAppSignup={handleIntegrateSlack}
                        saveChatbotIntegrationData={saveSlackIntegrationDetails}
                    />

                }
                {delinkedChannelDetails.flagDelink &&
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to delink ${delinkedChannelDetails?.channelDetails.slack_channel_name} channel from ${chatBotDetails?.chatbot_name}`}
                        open={delinkedChannelDetails.flagDelink}
                        onClose={() => { setDelinkedChannelDetails((prevstate: any) => ({ ...prevstate, flagDelink: false, channelDetails: null })) }}
                        onDelete={(e: any) => {
                            handleRemoveChatbotIntegration(delinkedChannelDetails?.channelDetails.chatbot_slack_assignment_id)
                        }}
                    />
                }
            </>
        </>
    );
};

export default ChatbotSlackIntegration;
