import React, { useContext, useState } from 'react'
import AccountInformationCard from '../../utility/accountInformationCard';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { metaIntegrationPhoneNumberDataModel } from '../../../models/axiosModel';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import FacebookInformationCard from '../../utility/facebookInformationCard';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { Grid } from '@mui/material';
// import FacebookInformationCard from '../../utils/FacebookInformationCard';

interface checkFlag {
    flagPage?: boolean
}

function FacebookIntegationDetails(props: checkFlag) {
    const navigate = useNavigate()
    const { facebookIntegrationDetails } = useContext(IntegrationContext) as IntegrationContextPayload
    const { userDetail, setFlagLoading, setSuccess, setError } = useContext(UserContext) as UserContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const deleteMetaIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.deleteMetaIntegration.delete(facebookIntegrationDetails.meta_integration_id)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration deleted Successfully!" }))
            navigate('/channels')
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <>
            {facebookIntegrationDetails !== null &&
                <FacebookInformationCard
                    integrationType="Facebook"
                    key={facebookIntegrationDetails?.meta_integration_facebook_data?.meta_integration_facebook_id}
                    flagDelete={true}
                    title={"Meta"}
                    flagShowMetaIcon={true}
                    currentlySelected={false}
                    details={{
                        // page_id: facebookIntegrationDetails?.meta_integration_facebook_data?.page_id,
                    }}
                    deleteChatbotIntegration={() => {
                        setShowDeleteConfirmation(true)
                    }
                    }
                />
            }
            {facebookIntegrationDetails !== null &&
                <>
                    <FacebookInformationCard
                        integrationType="Facebook"
                        key={1}
                        flagDelete={false}
                        title={`Facebook Account ${1}`}
                        currentlySelected={facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data.length > 0}
                        details={{
                            // chatbot_id: integration.chatbot_id,
                            page_id: facebookIntegrationDetails?.meta_integration_facebook_data?.page_id,
                            page_name: facebookIntegrationDetails?.meta_integration_facebook_data?.page_name,
                            page_category: facebookIntegrationDetails?.meta_integration_facebook_data?.page_category,
                            linked_chatbots: facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data
                            // chatbot_name: integration.chatbot_name,
                        }}

                    />
                </>
            }

            {showDeleteConfirmation &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete this Integration`}
                    open={showDeleteConfirmation}
                    onClose={() => { setShowDeleteConfirmation(false) }}
                    onDelete={(e: any) => {
                        deleteMetaIntegration()
                        setShowDeleteConfirmation(false)
                    }}
                />
            }

        </>

    )
}

export default FacebookIntegationDetails