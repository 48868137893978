import { Box, Button, Divider, Grid, IconButton, Menu, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import profileIcon from "../../assets/profileIcon.svg";
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import styles from "../../assets/styles/componentStyles/sidePanelAccountDetails.module.scss";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import agent from '../../services/api';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { NotifyExtentionContext } from '../../context/notifyExtentionContext';
import GlobalButton from './globalButton/button';
import IconsColor from './iconsColor';
import globalStyles from '../../assets/styles/global.module.scss'

export default function SettingsWorkspace(props: any) {

    const navigate = useNavigate();
    const [hoveredMenu, setHoveredMenu] = useState("")
    const { userDetail, setFlagLoading, setUserDetail, flagLoading, setError, error, organizationDetails } = useContext(UserContext) as UserContextPayload
    const { SendPostMessageToExtention } = React.useContext(
        NotifyExtentionContext
    ) as any;
    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let urlToNavigateTo = encodeURIComponent(window.location.href)
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };
    const pathName = window.location.pathname.toLowerCase()
    const handleOnClick = () => {
        if (!organizationDetails) {
            if (props.flagMobileScreen) {
                props.onclose()
            }
            navigate('/');
        }
        else if (organizationDetails && organizationDetails.length > 1) {
            if (props.flagMobileScreen) {
                props.onclose()
            }
            navigate('/organization')
        } else {
            if (props.flagMobileScreen) {
                props.onclose()
            }
            navigate(`/organization?organization_id=${organizationDetails ? organizationDetails[0].organization_id : ""}`)
        }
    }

    return (
        !pathName.includes('organization') ?
            <>
                <Divider sx={{ marginBottom: "10px" }} />
                <Menu
                    anchorEl={anchorEl}
                    // className={MenuPaper}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    className={styles.settingsStyleSidePanel}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'center' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <MenuItem className={styles.settingsMenu} onMouseEnter={() => setHoveredMenu("worksp")} onMouseLeave={() => setHoveredMenu("")} onClick={() => { handleOnClick() }}>
                        <ListItemIcon >
                            {hoveredMenu === "worksp" ? <IconsColor height={"18"} width={"18"} fillColor={"#000000"} iconName="workspace" /> : <IconsColor height={"18"} width={"18"} fillColor={"#4C4A4D"} iconName="workspace" />}
                        </ListItemIcon>
                        Workspace
                    </MenuItem>
                    {/* <MenuItem onClick={() => { debugger; navigate("/login?redirect=" + urlToNavigateTo) }} className={styles.menuLogout}>
                    <ListItemIcon>
                        <AppRegistrationIcon fontSize="small" />
                    </ListItemIcon>
                    Sign In/ Register
                </MenuItem> */}
                </Menu>
                {userDetail.flagUser && !userDetail.flag_user_anonymous ?
                    <Box className={props.flagMobile ? styles.settingsBlockMobile : styles.settingsMainBlock}
                        sx={{ backgroundColor: openMenu ? "#F7E9FF" : "#FFFFFF", color: openMenu ? "#4C4A4D" : "#000000", borderRadius: "5px", cursor: "pointer" }}
                        // onClick={props.flagMobile ? handleClick : undefined}
                        onClick={handleClick}
                        aria-controls={openMenu ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onMouseEnter={() => setHoveredMenu("settings")}
                        onMouseLeave={() => setHoveredMenu("")}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <img src={profileIcon} className={styles.profilestyles} /> */}
                            {hoveredMenu === "settings" ? <IconsColor iconName="settings" fillColor={"#000000"} height={"18"} width={"18"} /> : <IconsColor iconName="settings" fillColor={"#4C4A4D"} height={"18"} width={"18"} />}
                            {!props.flagMobile &&
                                <Typography className={styles.nameStyle} sx={{ maxWidth: "125px", overflow: "hidden", textOverflow: "ellipsis", paddingLeft: "5px" }}>
                                    Settings
                                </Typography>
                            }
                        </Box>
                        {!props.flagMobile &&
                            <ArrowForwardIosIcon
                                // onClick={handleClick}
                                // aria-controls={openMenu ? 'account-menu' : undefined}
                                // aria-haspopup="true"
                                // aria-expanded={openMenu ? 'true' : undefined}
                                sx={{ cursor: "pointer", fontSize: "medium !important", color: "#7A7A7A !important" }}
                            />
                        }
                    </Box>
                    :
                    <>
                        {props.flagMobile ?
                            <>
                                <Box className={props.flagMobile ? styles.mainBlockMobile : styles.settingsMainBlock}
                                    onClick={props.flagMobile ? handleClick : undefined}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img src={profileIcon} style={{ width: "23px", height: "auto" }} />
                                    </Box>
                                </Box></>
                            :
                            <>
                                <GlobalButton buttonText={props.flagMobile ? <AppRegistrationIcon fontSize="small" /> : "  Sign In/ Register"} className='primaryButtonStyle' flagFullWidth onClick={() => { navigate("/login?redirect=" + urlToNavigateTo) }} />
                            </>}
                    </>
                }
            </>
            :
            <>
                {/* <Divider sx={{marginBottom: "10px"}}/>
        <Grid item className={styles.backButtonStyle}>
            <Grid container sx={{ alignItems: "center" }}>
                {!props.flagMobile &&<Grid item>
                    <Button
                        variant='outlined'
                        size='large'
                        sx={{ width: "208px", fontSize: "14px !important" }}
                        fullWidth
                        startIcon={<IconsColor iconName="back" fillColor="#fff" width="15px" height="15px"/>}
                        className={globalStyles.addDocumentBtn}
                        onClick={() => { navigate('/'); if(props.flagMobileScreen)props.onclose() }}
                    >
                        Back to Main Page
                    </Button>
                </Grid>
                }
                {props.flagMobile &&
                    <IconButton
                        className={`${styles.backMobileButton}`}
                        onClick={() => { navigate('/'); if(props.flagMobileScreen)props.onclose() }}
                    >
                        <IconsColor iconName="back" fillColor="#fff" width="15px" height="15px"/>
                    </IconButton>
                }
            </Grid>
        </Grid> */}
            </>
    )
}
