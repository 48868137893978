import React, { useContext, useState } from 'react'
import AccountInformationCard from '../../utility/accountInformationCard';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { metaIntegrationPhoneNumberDataModel } from '../../../models/axiosModel';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import FacebookInformationCard from '../../utility/facebookInformationCard';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { Grid, useMediaQuery } from '@mui/material';
import GlobalPopUp from '../../utility/globalPopUp';
import DeployChatbotDialog from './deployChatbotDialog';
// import FacebookInformationCard from '../../utils/FacebookInformationCard';

interface checkFlag {
    flagPage?: boolean
}

function InstagramIntegationDetails(props: checkFlag) {
    const navigate = useNavigate()
    const { facebookIntegrationDetails, getIntegrationList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload
    const { userDetail, setFlagLoading, setSuccess, setError, success, error } = useContext(UserContext) as UserContextPayload;
    const { setGetChatbotIntegrationData, GetChatbotIntegrationData } = useContext(MetaContext) as MetaContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [flagDialogShowAllChatBot, setFlagDialogShowAllChatBot] = useState<boolean>(false);
    const [showConfirmationPopover, setShowConfirmationPopover] = useState<boolean>(false);
    const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [flagUnDeployConfirmation, setFlagUnDeployConfirmation] = useState<boolean>(false);

    const [flagIntegrationSuccessful, setFlagIntegrationSuccessful] = useState<boolean>(false);
    const [chatbotId, setChatbotId] = useState<string>("");

    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')

    const deleteMetaIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.deleteMetaIntegration.delete(facebookIntegrationDetails.meta_integration_id)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration deleted Successfully!" }))
            navigate('/channels')
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    const onClickOfDeployBtn = () => {
        setFlagDialogShowAllChatBot(true);
    }

    const handleCloseConfirmationPopover = () => {
        setConfirmationPopoverAnchorEl(null);
        setShowConfirmationPopover(false)
    };

    const onDeployClick = async (chatbot_id: string) => {
        if (facebookIntegrationDetails !== null) {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            const payload = {
                instagram_id: facebookIntegrationDetails.meta_integration_facebook_data.instagram_id,
                meta_integration_type_id: facebookIntegrationDetails.meta_integration_type_assignment_data.meta_integration_type_id.toString(),
                chatbot_id: chatbot_id,
                page_id: facebookIntegrationDetails.meta_integration_facebook_data.page_id,
                meta_integration_id: facebookIntegrationDetails.meta_integration_id.toString(),
            }

            const res = await agent.addChatbotIntegration.post(payload);
            if (res.status) {
                handleCloseConfirmationPopover();
                setFlagDialogShowAllChatBot(false);
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }));
                setChatbotId(chatbot_id);
                setFlagIntegrationSuccessful(true);
                setGetChatbotIntegrationData(res.data);
                getIntegrationList();
            } else {
                let errorCode = checkForErrorCode(res)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
    }

    const removeChatbotIntegration = () => {
        if (integrationList) {
            setFlagUnDeployConfirmation(true);
        }
    }

    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));

        const chatbot_id = facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data[0]?.chatbot_id;
        const res = await agent.removeChatbotIntegration.delete(
            facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data[0]?.chatbot_id,
            facebookIntegrationDetails?.meta_integration_id,
            facebookIntegrationDetails?.meta_integration_type_assignment_data?.meta_integration_type_id,
            facebookIntegrationDetails?.meta_integration_facebook_data.instagram_id,
        );

        if (res.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(chatbot_id, "")
            getIntegrationList()
        } else {
            let errorCode = checkForErrorCode(res)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: res.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagUnDeployConfirmation(false);
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    return (
        <>
            {facebookIntegrationDetails !== null &&
                <FacebookInformationCard
                    integrationType='Instagram'
                    key={facebookIntegrationDetails?.meta_integration_facebook_data?.meta_integration_facebook_id}
                    flagDelete={true}
                    title={"Meta"}
                    flagShowMetaIcon={true}
                    currentlySelected={false}
                    details={{
                        // page_id: facebookIntegrationDetails?.meta_integration_facebook_data?.page_id,
                    }}
                    deleteChatbotIntegration={() => {
                        setShowDeleteConfirmation(true)
                    }
                    }
                />
            }
            {facebookIntegrationDetails !== null &&
                <>
                    <FacebookInformationCard
                        integrationType='Instagram'
                        key={1}
                        flagDelete={false}
                        title={`Facebook Account ${1}`}
                        currentlySelected={facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data.length > 0}
                        details={{
                            page_id: facebookIntegrationDetails?.meta_integration_facebook_data?.instagram_id,
                            page_name: facebookIntegrationDetails?.meta_integration_facebook_data?.instagram_username,
                            page_category: facebookIntegrationDetails?.meta_integration_facebook_data?.page_category,
                            linked_chatbots: facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data
                        }}
                        flagChatbotDeploy={true}
                        addChatbotIntegration={onClickOfDeployBtn}
                        removeChatbotIntegration={(page_id) => removeChatbotIntegration()}
                    />
                </>
            }

            {showDeleteConfirmation &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete this Integration`}
                    open={showDeleteConfirmation}
                    onClose={() => { setShowDeleteConfirmation(false) }}
                    onDelete={(e: any) => {
                        deleteMetaIntegration()
                        setShowDeleteConfirmation(false)
                    }}
                />
            }

            {flagDialogShowAllChatBot &&
                <GlobalPopUp
                    open={flagDialogShowAllChatBot}
                    onClose={() => setFlagDialogShowAllChatBot(false)}
                    handleClose={() => setFlagDialogShowAllChatBot(false)}
                    flagShowCloseIcon={true}
                    paperMinWidth={smallScreen ? '85%' : '90vw'}
                    height='90vh'
                    title='Link Chatbot'
                >
                    <DeployChatbotDialog
                        pageName={facebookIntegrationDetails?.meta_integration_facebook_data?.instagram_username}
                        showConfirmationPopover={showConfirmationPopover}
                        setShowConfirmationPopover={setShowConfirmationPopover}
                        confirmationPopoverAnchorEl={confirmationPopoverAnchorEl}
                        setConfirmationPopoverAnchorEl={setConfirmationPopoverAnchorEl}
                        handleCloseConfirmationPopover={handleCloseConfirmationPopover}
                        onDeployClick={(chatbot_id) => { onDeployClick(chatbot_id) }}
                    />
                </GlobalPopUp>
            }

            {flagIntegrationSuccessful &&
                <GlobalPopUp
                    open={flagIntegrationSuccessful}
                    flagShowCloseIcon
                    backgroundColor={"#40813F"}
                    title={"Congratulations!"}
                    handleClose={() => { setFlagIntegrationSuccessful(false) }}
                >
                    <Grid container sx={{ padding: "30px 45px 30px" }} direction={"column"} gap="7px">
                        <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}>
                            The AI chatbot has been successfully deployed on this Instagram page!
                        </Grid>
                        <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}> you can now head to your instagram account and start chatting with the instagram page.</Grid>
                        <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}>You can also track your chats <a href={`/chatbot/viewchatbot/inbox?chatbot_id=${chatbotId}`}>here</a> in the inbox</Grid>
                    </Grid>
                </GlobalPopUp>
            }

            {flagUnDeployConfirmation &&
                <GlobalPopUp
                    open={flagUnDeployConfirmation}
                    handleClose={() => { setFlagUnDeployConfirmation(false) }}
                >
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to delete ${facebookIntegrationDetails?.meta_integration_facebook_data?.chatbot_integration_assignment_data[0].chatbot_name || ""} from ${facebookIntegrationDetails?.meta_integration_facebook_data?.instagram_username || ""}`}
                        onClose={() => { setFlagUnDeployConfirmation(false) }}
                        onDelete={() => { deleteChatbotIntegration() }}
                    />
                </GlobalPopUp>
            }

        </>

    )
}

export default InstagramIntegationDetails