import { Button, Grid, IconButton, Tab, Tabs } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import IconsColor from '../../utility/iconsColor'
import tabStyle from "../../../assets/styles/componentStyles/chatbot.module.scss";
import buttonStyle from "../../../assets/styles/componentStyles/template.module.scss";
import globalStyles from '../../../assets/styles/global.module.scss';
import AddIcon from '@mui/icons-material/Add';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { MessageFormatContext, MessageFormatContextPayload } from '../../../context/messageFormatContext';
import { initialStaticMessageFormatPayload } from './messageFormatConstants';
import { UserContext, UserContextPayload } from '../../../context/userContext';

const MessageFormatGlobal = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');
    const {setFlagLoading, flagLoading} = useContext(UserContext) as UserContextPayload;
    const {currentSubTab, setCurrentSubTab, setStaticMessageFormatPayload, GetStaticMessageFormatList, setStaticMessageFormatList} = useContext(MessageFormatContext) as MessageFormatContextPayload
    
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        
        if (urlParams.has('message_format_id')) {
            urlParams.delete('message_format_id');
        }
    
        urlParams.set('currentTab', newValue);
        window.history.replaceState({}, '', '?' + urlParams.toString());

        navigate(`/chatbot/viewchatbot/messageformat?${urlParams.toString()}`);
        setStaticMessageFormatPayload(initialStaticMessageFormatPayload);
        setCurrentSubTab(newValue);
    };
    
    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            if(chatbot_id) {
                const res = await GetStaticMessageFormatList(chatbot_id);
                if(res.status) {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false })
                }
                else {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false })
                }
            }
        })()
    }, [chatbot_id])


    const newTabList = [
        {
            id: 1,
            tabName: "Dynamic Message Format"
        },
        {
            id: 2,
            tabName: "Static Message Format"
        },
    ]

    const createNewStaticFormat = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if(!searchParams.get('currentTab')) {
            urlParams.set('currentTab', "Static Message Format");
            window.history.replaceState({}, '', '?' + urlParams.toString());
        }
        navigate(`${window.location.pathname}/staticformat?${urlParams.toString()}`)
    }

    return (
        <>
            <Grid container paddingInline={"25px"} justifyContent={"space-between"} alignItems={"center"} pt={1} flexWrap={"nowrap"}>
                <Grid item sx={{width: "100%"}}>
                    <Tabs
                        value={currentSubTab}
                        // onChange={(e) => handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        className={'chatbotTabsComponentStyle'}
                        sx={{
                            '& .MuiTabs-scroller': {
                                width: window.location.pathname == "/chatbot/viewchatbot/messageformat/staticformat" ? '100% !important' : "85% !important",
                                maxWidth: window.location.pathname == "/chatbot/viewchatbot/messageformat/staticformat" ? '100% !important' : "85% !important"
                            }
                        }}
                        aria-label="secondary tabs example"
                    >
                        {newTabList.map((tabDetail, i) => (
                            <Tab
                                className={`${tabStyle.tabStyle} ${tabStyle.chatbotTabStyle}`}
                                onClick={(e) => handleChangeTab(e, tabDetail.tabName)}
                                key={tabDetail.id}
                                value={tabDetail.tabName}
                                label={tabDetail.tabName}
                            />
                        ))}
                    </Tabs>
                </Grid>
                {currentSubTab === "Static Message Format" && window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/messageformat" &&
                    <Grid item>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item className={`${buttonStyle.addChatbotBtn}`}>
                                <Button
                                    variant='outlined'
                                    size='large'
                                    sx={{ width: "218px" }}
                                    fullWidth
                                    startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                    className={globalStyles.addDocumentBtn}
                                    onClick={() => { createNewStaticFormat() }}
                                >
                                    New Message Format
                                </Button>
                            </Grid>
                            <IconButton
                                className={`${globalStyles.cmpGlobalMobileAddIcon} ${buttonStyle.addChatbotIcon}`}
                                onClick={() => { createNewStaticFormat() }}
                            >
                                <AddIcon style={{ height: "28px", width: 'auto' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
            </Grid>

            <Outlet />
        
        </>
    )
}

export default MessageFormatGlobal
