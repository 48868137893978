import { Box, Grid, InputAdornment, SwipeableDrawer, TextField, Typography } from '@mui/material'
import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/staticChatbotPreview.module.scss";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import userIcon from "../../../assets/images/chatbot/userAvatar.png";
import botIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png";
import chatbotStyle from "../../../assets/styles/componentStyles/chatbotStyles/chatbotPreview.module.scss"
import conversation from "../../../assets/images/chatbot/conversation.png";
import globalStyles from "../../../assets/styles/global.module.scss";
import IconsColor from '../../utility/iconsColor';
import GlobalPopUp from '../../utility/globalPopUp';
import { getChatbotMessageTag } from '../../../utils/constants';
interface ChatbotPreviewSectionInterface {
    flagSmallScreen: boolean,
    openSwipableDrawer: any,
    setOpenSwipableDrawer: any,
    toggleDrawer: any
}
function StaticChatbotPreview(props: ChatbotPreviewSectionInterface) {
    const {
        flagSmallScreen,
        openSwipableDrawer,
        setOpenSwipableDrawer,
        toggleDrawer
    } = props
    const { chatBotDetails, currentActiveChatbotThread } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [previewImageDetails, setPreviewImageDetails] = useState({
        value: ""
    })
    const [iframeKey, setIframeKey] = useState(0);
    const reloadIframe = () => {
        setIframeKey(prevKey => prevKey + 1); // Increment key to trigger reload
    };
    useEffect(() => {
        reloadIframe();
    }, [chatBotDetails]);

    function convertDateTime(datetimeStr: string) {
        // Convert string to Date object
        var dtObject = new Date(datetimeStr);

        // Convert Date object to desired format
        var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'UTC' };
        // @ts-ignore
        var formattedDateTime = dtObject.toLocaleString('en-US', options);
        return formattedDateTime;
    }
    return (
        <>
            {showImagePreview &&
                <GlobalPopUp
                    open={showImagePreview}
                    handleClose={() => { setShowImagePreview(false) }}
                    flagShowCloseIcon
                >
                    <img src={previewImageDetails.value} alt="imgLoaded" />
                </GlobalPopUp>

            }
            <Grid item xs={12} className={chatbotStyle.cbPreviewMainItem}>
                <Grid container direction={"column"} sx={{ position: "relative" }}>

                    {flagSmallScreen ?
                        (<>
                            <SwipeableDrawer
                                anchor="right"
                                open={openSwipableDrawer}
                                onClose={toggleDrawer(false)}
                                onOpen={toggleDrawer(true)}
                                PaperProps={{
                                    className: chatbotStyle.chatbotSwipeableDrawer,
                                    style: {
                                        overflow: 'auto',
                                    },
                                }}
                            >
                                <Grid container sx={{ padding: "0px" }} className={chatbotStyle.chatbotPreviewMainContainer}>
                                    <Grid item xs={12} className={chatbotStyle.chatbotPreviewMainItem}>
                                        <Grid container className={chatbotStyle.chatbotPreviewSubContainer}>
                                            <Grid item xs={12}
                                                className={styles.headerMobile}
                                            //  className={chatbotStyle.cbPreviewHeadMainItem}
                                            >
                                                <Grid container sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap" }}>
                                                    {/* <Grid item xs={2}></Grid> */}
                                                    <Grid item xs={11} className={chatbotStyle.cbPreviewHeadItem}>
                                                        <Grid container justifyContent={"space-between"}>
                                                            <>
                                                                <Grid item>
                                                                    <Typography component={'span'} className={chatbotStyle.cbPreviewHead}>
                                                                        Messages
                                                                    </Typography>
                                                                </Grid>
                                                                {currentActiveChatbotThread !== null && currentActiveChatbotThread[0].chatbot_route_source &&
                                                                    <Grid item className={globalStyles.displayFlex}>
                                                                        <Grid item className={`${globalStyles.displayFlex} ${styles.sourceStyle}`}> Source: {currentActiveChatbotThread[0].chatbot_route_source == "WhatsApp" ? <span className={styles.svgStyle}> <IconsColor iconName="whatsapp" className={styles.svgStyle} width="15px" height="15px" /></span> : <span className={styles.svgStyle} > <IconsColor iconName="website" width="15px" height="15px" /></span>} {currentActiveChatbotThread[0].chatbot_route_source === "WhatsApp" ? `+${currentActiveChatbotThread[0].receiver_phone_number}` : currentActiveChatbotThread[0].ip_address}</Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ textAlign: "end", position: "absolute", top: "12px", right: "10px", zIndex: "10000 !important" }}>
                                                        <img
                                                            src={previewIcon}
                                                            style={{ width: "23px", height: "auto", objectFit: "fill", verticalAlign: "middle", transform: "rotate(180deg)" }}
                                                            alt=""
                                                            onClick={() => { setOpenSwipableDrawer(false) }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={chatbotStyle.cbPreviewIframeItem}>
                                                <div
                                                    key={iframeKey}
                                                    style={{ minHeight: "calc(100vh - 220px) !important" }}
                                                    className={chatbotStyle.cbPreviewIframeParentDiv}
                                                >
                                                    <Box
                                                        className={globalStyles.fullHeight}
                                                        sx={{
                                                            borderRadius: "20px 20px 0px 0px !important",
                                                            border: "3px solid rgb(247, 233, 255) !important",
                                                            minHeight: "auto !important",
                                                            // height: "480px !important"
                                                        }}
                                                    >
                                                        <Grid container
                                                            className={globalStyles.fullHeight}
                                                            sx={{
                                                                bgcolor: chatBotDetails?.chatbot_theme?.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme?.chatbot_back_ground_color : "white",
                                                                borderRadius: "20px 20px 0px 0px"
                                                            }}
                                                        >
                                                            <Grid item xs={12}
                                                                className={globalStyles.fullHeight}
                                                                sx={{
                                                                    borderRadius: "15px"
                                                                }}>
                                                                <Grid container direction={"column"}
                                                                    justifyContent={"space-between"}
                                                                    className={`${globalStyles.fullHeight} ${globalStyles.containerFlexWrap}`}
                                                                    sx={{ overflow: "hidden" }}>

                                                                    <Grid item xs={12}
                                                                        sx={{ maxHeight: "61px !important" }}
                                                                        className={styles.chatbotNavbarMainItem}>
                                                                        <Grid container
                                                                            sx={{
                                                                                bgcolor: chatBotDetails?.chatbot_theme?.chatbot_font_color ? chatBotDetails?.chatbot_theme?.chatbot_font_color : "#F7E9FF",
                                                                                p: "0px 0px 0px 20px",
                                                                                height: '60px',
                                                                                alignItems: "center",
                                                                                borderRadius: "15px 15px 0px 0px",
                                                                                border: 'none'
                                                                            }}
                                                                        >
                                                                            <Grid item xs={11}>
                                                                                <Grid container alignItems={"center"}>
                                                                                    <Grid item sx={{ margin: "0px 10px 0px 0px" }}>
                                                                                        <img
                                                                                            src={chatBotDetails?.chatbot_logo_url ?
                                                                                                chatBotDetails.chatbot_logo_url : botIcon
                                                                                            }
                                                                                            alt="chatbot Icon"
                                                                                            style={{ height: "auto", width: "40px", objectFit: "fill", display: 'flex', borderRadius: "50%" }}
                                                                                        />
                                                                                    </Grid>

                                                                                    <Grid item sx={{ display: "flex" }}>
                                                                                        <Typography
                                                                                            component={'span'}
                                                                                            sx={{ fontWeight: 600, fontSize: "15px", display: "flex" }}
                                                                                        >
                                                                                            {chatBotDetails?.chatbot_name}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                    <>
                                                                        <Grid item xs={12} className={globalStyles.fullHeight} sx={{ maxHeight: "calc(100% - 125px)" }}>
                                                                            <Grid className={globalStyles.fullHeight} container id="messageSenderDiv" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "20px !important" }} overflow={"auto"}
                                                                            //   ref={containerRef} 
                                                                            // className={styles.chatbotChatMainSectionItem}
                                                                            // height={"calc(100vh - 475px)"} 
                                                                            >

                                                                                {(currentActiveChatbotThread !== null && currentActiveChatbotThread[0].chatbot_thread_id !== "") ?


                                                                                    currentActiveChatbotThread?.length ? currentActiveChatbotThread?.map((thread) => {
                                                                                        const isBot = thread?.flag_user_message == false
                                                                                        return (
                                                                                            <>
                                                                                                {isBot ? (
                                                                                                    <>
                                                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "15px 0px" }}>
                                                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "start", flexWrap: "nowrap" }}>

                                                                                                                <Grid item sx={{ background: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "rgba(250, 240, 255, 1)" }} className={styles.chatbotChatIconDiv} >
                                                                                                                    <img src={chatBotDetails?.chatbot_logo_url ? chatBotDetails.chatbot_logo_url : botIcon}
                                                                                                                        alt="chatbot Icona"
                                                                                                                        style={{ width: chatBotDetails?.chatbot_logo_id ? "44px" : "30px", height: "auto", objectFit: 'fill', borderRadius: "50%" }} />
                                                                                                                </Grid>

                                                                                                                <Grid item
                                                                                                                    className={styles.parentbotMsgBoxStyle}
                                                                                                                    sx={{
                                                                                                                        marginLeft: "6px",
                                                                                                                        padding: "10px 10px",
                                                                                                                        borderRadius: "0px 8px 8px 8px",
                                                                                                                        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                        fontSize: "15px",
                                                                                                                        fontWeight: "500",
                                                                                                                        width: "100%",
                                                                                                                        maxWidth: "fit-content",
                                                                                                                        backgroundColor: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#F7E9FF",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <pre
                                                                                                                        className={styles.botMsgBoxStyle}
                                                                                                                        style={{
                                                                                                                            color: chatBotDetails?.chatbot_theme?.system_msg_color ? chatBotDetails?.chatbot_theme?.system_msg_color : "black",
                                                                                                                            overflow: "unset",
                                                                                                                            width: "100%",
                                                                                                                            // textWrap: "pretty",
                                                                                                                            whiteSpace: "normal",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            maxWidth: "unset",
                                                                                                                            background: "unset !important",
                                                                                                                        } as CSSProperties}
                                                                                                                    >
                                                                                                                        {thread?.chatbot_thread_value}
                                                                                                                    </pre>
                                                                                                                    <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)} (UTC)</span>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "15px 0px" }}>
                                                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                                                <Grid item
                                                                                                                    sx={{
                                                                                                                        marginRight: "6px",
                                                                                                                        padding: "8px 8px",
                                                                                                                        borderRadius: "8px 8px 0px 8px",
                                                                                                                        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                        fontSize: "15px",
                                                                                                                        fontWeight: "500",
                                                                                                                        backgroundColor: chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F3F5F9",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography
                                                                                                                        sx={{
                                                                                                                            color: chatBotDetails?.chatbot_theme?.user_msg_color ? chatBotDetails?.chatbot_theme?.user_msg_color : "black",
                                                                                                                            wordBreak: 'break-all',
                                                                                                                            fontSize: "14px",
                                                                                                                            fontWeight: "500"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {thread?.chatbot_thread_value}
                                                                                                                    </Typography>
                                                                                                                    <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)} (UTC)</span>

                                                                                                                </Grid>
                                                                                                                <Grid item sx={{ marginLeft: "6px", alignSelf: "end" }}>
                                                                                                                    <img alt="User Icon" src={userIcon} style={{ width: "40px", height: "auto", objectFit: "fill", display: 'flex' }} />
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                )
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    }) : ""
                                                                                    :
                                                                                    <>
                                                                                        <Grid item className={globalStyles.fullHeight}>
                                                                                            <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                                <Grid item>
                                                                                                    <img src={conversation} alt="conversation" />
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography></Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xs={12} className={styles.chatbotPreviewMessageboxMainItem} sx={{ maxHeight: "60px" }}>
                                                                            <Grid container sx={{ background: chatBotDetails?.chatbot_theme?.chatbot_send_message_bg_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_bg_color : "#fff" }} className={styles.chatbotPreviewMessageboxMainContainer}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    className={styles.sendMsgTextField}
                                                                                    // sx={{ background: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "rgba(250, 240, 255, 1)" }} 
                                                                                    sx={{ border: 'none', "& fieldset": { border: 'none' }, color: chatBotDetails?.chatbot_theme?.chatbot_send_message_font_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_font_color : "#000" }}
                                                                                    placeholder="Send a message..."
                                                                                    disabled={true}
                                                                                    fullWidth
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end" sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)" }}>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SwipeableDrawer>
                        </>)
                        :

                        (
                            <>
                                <Grid item xs={12}
                                    className={styles.header}
                                >
                                    <Grid container className={chatbotStyle.cbPreviewIframeItem} sx={{ alignItems: "center", position: "relative" }} justifyContent={"space-between"}>
                                        <Grid
                                            // item xs={8} 
                                            sx={{ alignItems: "center", display: "flex", justifyContent: "center", width: "100%" }}
                                            className={chatbotStyle.cbPreviewHeadItem} mb={1}>
                                            <Grid container gap="10px" justifyContent={"space-between"}>

                                                <>
                                                    <Grid item>
                                                        <Typography component={'span'} className={chatbotStyle.cbPreviewHead}>
                                                            Messages
                                                        </Typography>
                                                    </Grid>
                                                    {currentActiveChatbotThread !== null && currentActiveChatbotThread[0].chatbot_route_source &&
                                                        <Grid item className={globalStyles.displayFlex}>
                                                            <Grid item className={`${globalStyles.displayFlex} ${styles.sourceStyle}`}> Source: {currentActiveChatbotThread[0].chatbot_route_source == "WhatsApp" ? <span className={styles.svgStyle}> <IconsColor iconName="whatsapp" className={styles.svgStyle} width="15px" height="15px" /></span> : <span className={styles.svgStyle} > <IconsColor iconName="website" width="15px" height="15px" /></span>} {currentActiveChatbotThread[0].chatbot_route_source === "WhatsApp" ? `+${currentActiveChatbotThread[0].receiver_phone_number}` : currentActiveChatbotThread[0].ip_address}</Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={chatbotStyle.cbPreviewIframeItem}>


                                    <div
                                        key={iframeKey}
                                        // style={{ minHeight: "calc(100vh - 320px)" }}
                                        style={{ minHeight: "calc(100vh - 220px) !important" }}
                                        className={chatbotStyle.cbPreviewIframeParentDiv}
                                    // dangerouslySetInnerHTML={{ __html: chatBotDetails?.chatbot_iframe ? chatBotDetails.chatbot_iframe : "" }}
                                    >
                                        <Box
                                        >
                                            <Grid container
                                                sx={{
                                                    bgcolor: chatBotDetails?.chatbot_theme?.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme?.chatbot_back_ground_color : "white",
                                                    borderRadius: "20px 20px 0px 0px"
                                                }}
                                            >
                                                <Grid item xs={12} sx={{
                                                    borderRadius: "15px"
                                                }}>
                                                    <Grid container sx={{ overflow: "hidden" }}>

                                                        <Grid item xs={12} className={styles.chatbotNavbarMainItem}>
                                                            <Grid container
                                                                sx={{
                                                                    bgcolor: chatBotDetails?.chatbot_theme?.chatbot_font_color ? chatBotDetails?.chatbot_theme?.chatbot_font_color : "#F7E9FF",
                                                                    p: "0px 0px 0px 20px",
                                                                    height: '60px',
                                                                    alignItems: "center",
                                                                    borderRadius: "15px 15px 0px 0px",
                                                                    border: 'none'
                                                                }}
                                                            >
                                                                <Grid item xs={11}>
                                                                    <Grid container alignItems={"center"}>
                                                                        <Grid item sx={{ margin: "0px 10px 0px 0px" }}>
                                                                            <img
                                                                                src={chatBotDetails?.chatbot_logo_url ?
                                                                                    chatBotDetails.chatbot_logo_url : botIcon
                                                                                }
                                                                                alt="chatbot Icon"
                                                                                style={{ height: "auto", width: "40px", objectFit: "fill", display: 'flex', borderRadius: "50%" }}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item sx={{ display: "flex" }}>
                                                                            <Typography
                                                                                component={'span'}
                                                                                sx={{ fontWeight: 600, fontSize: "15px", display: "flex" }}
                                                                            >
                                                                                {chatBotDetails?.chatbot_name}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container id="messageSenderDiv" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "20px !important" }} overflow={"auto"}
                                                                    //   ref={containerRef} 
                                                                    className={styles.chatbotChatMainSectionItem}
                                                                    height={"calc(100vh - 305px)"}
                                                                >

                                                                    {(currentActiveChatbotThread !== null && currentActiveChatbotThread[0].chatbot_thread_id !== "") ?


                                                                        currentActiveChatbotThread?.length ? currentActiveChatbotThread?.map((thread) => {
                                                                            const isBot = thread?.flag_user_message == false
                                                                            return (
                                                                                <>
                                                                                    {isBot ? (
                                                                                        <>
                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "15px 0px" }}>
                                                                                                <Grid container sx={{ alignItems: "center", justifyContent: "start", flexWrap: "nowrap" }}>

                                                                                                    <Grid item sx={{ background: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "rgba(250, 240, 255, 1)" }} className={styles.chatbotChatIconDiv} >
                                                                                                        <img src={chatBotDetails?.chatbot_logo_url ? chatBotDetails.chatbot_logo_url : botIcon}
                                                                                                            alt="chatbot Icona"
                                                                                                            style={{ width: chatBotDetails?.chatbot_logo_id ? "44px" : "30px", height: "auto", objectFit: 'fill', borderRadius: "50%" }} />
                                                                                                    </Grid>

                                                                                                    <Grid item
                                                                                                        className={styles.parentbotMsgBoxStyle}
                                                                                                        sx={{
                                                                                                            marginLeft: "6px",
                                                                                                            padding: "10px 10px",
                                                                                                            borderRadius: "0px 8px 8px 8px",
                                                                                                            boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                            fontSize: "15px",
                                                                                                            fontWeight: "500",
                                                                                                            width: "100%",
                                                                                                            maxWidth: "fit-content",
                                                                                                            backgroundColor: chatBotDetails?.chatbot_theme?.system_msg_bg_color ? chatBotDetails?.chatbot_theme?.system_msg_bg_color : "#F7E9FF",
                                                                                                        }}
                                                                                                    >

                                                                                                        <pre
                                                                                                            className={styles.botMsgBoxStyle}
                                                                                                            style={{
                                                                                                                color: chatBotDetails?.chatbot_theme?.system_msg_color ? chatBotDetails?.chatbot_theme?.system_msg_color : "black",
                                                                                                                overflow: "unset",
                                                                                                                width: "100%",
                                                                                                                // textWrap: "pretty",
                                                                                                                whiteSpace: "normal",
                                                                                                                wordBreak: "break-word",
                                                                                                                maxWidth: "unset",
                                                                                                                background: "unset !important"
                                                                                                            } as CSSProperties}
                                                                                                        >
                                                                                                            {getChatbotMessageTag(thread, thread.message_type,)}
                                                                                                        </pre>
                                                                                                        {thread.reaction_code && <p className={styles.reactionStyle}>&#x{thread.reaction_code};</p>}
                                                                                                        <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)} (UTC)</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "15px 0px" }}>
                                                                                                <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                                    <Grid item
                                                                                                        sx={{
                                                                                                            marginRight: "6px",
                                                                                                            padding: "8px 8px",
                                                                                                            borderRadius: "8px 8px 0px 8px",
                                                                                                            boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                            fontSize: "15px",
                                                                                                            fontWeight: "500",
                                                                                                            backgroundColor: chatBotDetails?.chatbot_theme?.user_msg_bg_color ? chatBotDetails?.chatbot_theme?.user_msg_bg_color : "#F3F5F9",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                color: chatBotDetails?.chatbot_theme?.user_msg_color ? chatBotDetails?.chatbot_theme?.user_msg_color : "black",
                                                                                                                wordBreak: 'break-all',
                                                                                                                fontSize: "14px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {getChatbotMessageTag(thread, thread.message_type,)}
                                                                                                        </Typography>
                                                                                                        {thread.reaction_code && <p className={styles.reactionStyle}>&#x{thread.reaction_code};</p>}
                                                                                                        <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)} (UTC)</span>
                                                                                                    </Grid>
                                                                                                    <Grid item sx={{ marginLeft: "6px", alignSelf: "end" }}>
                                                                                                        <img alt="User Icon" src={userIcon} style={{ width: "40px", height: "auto", objectFit: "fill", display: 'flex' }} />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }) : ""
                                                                        :
                                                                        <>
                                                                            <Grid item className={globalStyles.fullHeight}>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <img src={conversation} alt="conversation" />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography></Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} className={styles.chatbotPreviewMessageboxMainItem}>
                                                                <Grid container sx={{ background: chatBotDetails?.chatbot_theme?.chatbot_send_message_bg_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_bg_color : "#fff" }} className={styles.chatbotPreviewMessageboxMainContainer}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        className={styles.sendMsgTextField}
                                                                        sx={{ border: 'none', "& fieldset": { border: 'none', color: chatBotDetails?.chatbot_theme?.chatbot_send_message_font_color ? chatBotDetails?.chatbot_theme?.chatbot_send_message_font_color : "#000" } }}
                                                                        placeholder="Send a message..."
                                                                        disabled={true}
                                                                        fullWidth
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end" sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)" }}>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </div>
                                </Grid>

                            </>
                        )
                    }



                </Grid>
            </Grid >
        </>
    )
}

export default StaticChatbotPreview;