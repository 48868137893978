import { Button, ListItemIcon, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { MessageFormatContext, MessageFormatContextPayload } from '../../../context/messageFormatContext'
import { getStaticMessageFormatResponse } from '../../../models/axiosModel'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { initialStaticMessageFormatPayload } from './messageFormatConstants'
import IconsColor from '../../utility/iconsColor'
import EditIcon from '@mui/icons-material/Edit';
import agent from '../../../services/api'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GlobalPopUp from '../../utility/globalPopUp'
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationCard.module.scss'
import globalStyles from './../../../assets/styles/global.module.scss'

const StaticMessageFormatTable = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const chatbot_id = searchParams.get('chatbot_id')
    const { setFlagLoading, setSuccess, flagLoading, success, setError, error } = useContext(UserContext) as UserContextPayload;
    const {staticMessageFormatList, setStaticMessageFormatList, setStaticMessageFormatPayload, GetStaticMessageFormatList} = useContext(MessageFormatContext) as MessageFormatContextPayload;
    const [headerListMenu, setHeaderListMenu] = useState<null | HTMLElement>(null);
    const open = Boolean(headerListMenu);
    const [flagDeleteStaticMessagePopup, setFlagDeleteStaticMessagePopup] = useState(false);
    const [staticMessageToDeleted, setStaticMessageToDeleted] = useState<getStaticMessageFormatResponse>();

    const handleClickStaticMessageListMenu = (event: React.MouseEvent<HTMLButtonElement>, message_id: number) => {
        setHeaderListMenu(event.currentTarget);
        setStaticMessageToDeleted(staticMessageFormatList?.find((data) => data.chatbot_static_message_id === message_id))
    };

    const handleCloseStaticMessageListMenu = () => {
        setHeaderListMenu(null);
    };

    const handleMessageFormatData = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if(!searchParams.get('currentTab')) {
            urlParams.set('currentTab', "Static Message Format");
            window.history.replaceState({}, '', '?' + urlParams.toString());
        }
        navigate(`${window.location.pathname}/staticformat?${urlParams.toString()}&message_format_id=${staticMessageToDeleted?.chatbot_static_message_id}`)
        setStaticMessageFormatPayload(staticMessageFormatList?.find((list) => list.chatbot_static_message_id === staticMessageToDeleted?.chatbot_static_message_id) || initialStaticMessageFormatPayload)
    }

    const deleteStaticMessagePopupOpen = () => {
        setFlagDeleteStaticMessagePopup(true)
    }

    const handleRemoveStaticMessage = async (message_id?: number) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.deleteStaticMessageFormat.delete(chatbot_id || "", message_id?.toString() || "");
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Static Message Format Deleted Successfully" })
            setStaticMessageFormatPayload(initialStaticMessageFormatPayload)
            await GetStaticMessageFormatList(chatbot_id || "")
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFlagDeleteStaticMessagePopup(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    const menuItems = [
        { label: 'Update', icon: <EditIcon sx={{ fontSize: "18px", color: "#7A7A7A" }} />, action: (e: any) => handleMessageFormatData() },
        { label: 'Delete', icon: <IconsColor iconName="DeleteIcon" width="16" height="16" fillColor="#7A7A7A" />, action: (e: any) => deleteStaticMessagePopupOpen() },
    ];

    return (
        <>
            <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "calc(100vh - 250px)"}}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>ID</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Name</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>When to Use</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Dynamic Params?</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Static Message JSON</TableCell>
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Params Mapping</TableCell>
                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${dashboardStyles.viewButtonColumnHeadingStyle}`}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staticMessageFormatList && staticMessageFormatList.length > 0 ?
                            staticMessageFormatList.sort((a, b) => b.chatbot_static_message_id - a.chatbot_static_message_id).map((formats: getStaticMessageFormatResponse) => 
                                <TableRow className={dashboardStyles.cellRow}>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formats.chatbot_static_message_id}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formats.chatbot_static_message_name}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formats.chatbot_static_message_when_to_use}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formats.flag_allow_edit ? "Yes" : "No"}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{formats.chatbot_static_message_value}</TableCell>
                                    <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} sx={{maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{formats.field_mapping ? formats.field_mapping : "-"}</TableCell>
                                    <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${dashboardStyles.viewButtonColumnStyle}`} sx={{textAlign: "end"}}>
                                        {/* <MoreVertIcon style={{fontSize: "20px", color: "#7A7A7A"}} /> */}
                                        <Button
                                            id="basic-button"
                                            className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            disableRipple
                                            onClick={(event) => { event.stopPropagation(); handleClickStaticMessageListMenu(event, formats.chatbot_static_message_id) }}
                                        >
                                            <MoreVertIcon />
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={headerListMenu}
                                            open={open}
                                            className={globalStyles.globalMenuStyle}
                                            onClose={handleCloseStaticMessageListMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {menuItems.map((item: any, index: any) => (
                                                <MenuItem
                                                    className={globalStyles.menuItemStyle}
                                                    key={index}
                                                    onClick={(e) => { e.stopPropagation(); item.action(); handleCloseStaticMessageListMenu(); }}
                                                // disabled={item.disabled ? item.disabled(rowDetail) : false}
                                                >
                                                    <ListItemIcon>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                    <span>
                                                        {item.label}
                                                    </span>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            )
                            :
                            <TableRow className={dashboardStyles.cellRow}>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} colSpan={6} sx={{textAlign: "center"}}>No Data Available</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {flagDeleteStaticMessagePopup &&
                <GlobalPopUp
                    open={flagDeleteStaticMessagePopup}
                    handleClose={() => { setFlagDeleteStaticMessagePopup(false) }}
                >
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to delete ${staticMessageToDeleted?.chatbot_static_message_name}`}
                        onClose={() => { setFlagDeleteStaticMessagePopup(false) }}
                        onDelete={() => handleRemoveStaticMessage(staticMessageToDeleted?.chatbot_static_message_id)}
                        sx={{ maxWidth: "400px" }}
                    />
                </GlobalPopUp>
            }
        </>
    )
}

export default StaticMessageFormatTable
