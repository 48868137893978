import React from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SomethingWentWrong = () => {

    const navigate = useNavigate()

    return (
        <>
            <Grid container className='ww-pnf-main-container'>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='ww-pnf-text-item'>
                            <Typography component='span' className='ww-pnf-text'>Something went wrong!</Typography>
                        </Grid>
                        <Grid item xs={12} className='ww-pnf-subtext-item'>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xs={2.4}>
                                    <Typography component='span' className='ww-pnf-subtext-text'>
                                        Some error occurred, please try again!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='ww-pnf-home-page-btn'>
                            <Button
                                variant='contained'
                                fullWidth
                                size='large'
                                sx={{
                                    color: "white",
                                    textTransform: "none",
                                    background: "#6A097D",
                                    borderRadius: "5px",
                                    fontWeight: "500",
                                    padding: "12px 25px !important",
                                    '&.MuiButton-root:hover': { bgcolor: '#6A097D' },
                                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                                    fontSize: "17px",
                                }}
                                onClick={() => { navigate('/dashboard') }}
                            >
                                Back to homepage
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SomethingWentWrong