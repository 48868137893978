import React, { useContext, useEffect, useState } from 'react'
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import agent from '../../../../services/api';
import { MetaContext, MetaContextPayload } from '../../../../context/metaContext';
import { chatbotIntegrationAssignmentData, globalIntegration, metaIntegrationPhoneNumberData } from '../../../../models/axiosModel';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import GlobalPopUp from '../../../utility/globalPopUp';
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import Confirmation from '../../../popupFiles/confirmation';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import FacebookInformationCard from '../../../utility/facebookInformationCard';

const ChatbotInstagramIntegrationDetails = () => {
    const navigate = useNavigate()
    const [currentActiveFacebookIntegration, setCurrentActiveFacebookIntegration] = useState<any>(null)
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const [searchParams] = useSearchParams()
    const { setSuccess, success, setError, error, setFlagLoading, userDetail } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotIntegrationData, getChatbotIntegrationData, setGetChatbotIntegrationData } = useContext(MetaContext) as MetaContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [flagAddIntegration, setFlagAddIntegration] = useState(false)
    const [phoneNumberIntegrate, setPhoneNumberIntegrate] = useState<any>()

    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState({
        meta_integration_id: "",
        chatbot_id: "",
        page_id: "",
        meta_integration_type_id: ""
    })
    const [removeChatbotIntegrationDetails, setRemoveChatbotIntegrationDetails] = useState({
        chatbot_id: "",
        meta_integration_type_id: "",
        page_id: "",
        meta_integration_id: ""
    })

    useEffect(() => {
        if (getChatbotIntegrationData !== null && getChatbotIntegrationData.length > 0 && getChatbotIntegrationData.filter((data: globalIntegration) => data.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "facebook")) {
            let assignment_id = Number(searchParams.get('assignment_id'))
            if (assignment_id) {
                let facebookIntegrationData = getChatbotIntegrationData.filter((integrationData) => integrationData.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() === "facebook")
                let currentActiveWhatsappData = facebookIntegrationData.filter((data) => data?.meta_integration_facebook_data?.meta_integration_facebook_id == assignment_id)
                if (currentActiveWhatsappData && currentActiveWhatsappData.length > 0) {
                    setCurrentActiveFacebookIntegration(currentActiveWhatsappData)
                } else {
                    let chatbot_id = searchParams.get('chatbot_id')
                    navigate(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`)
                }
            } else {
                let chatbot_id = searchParams.get('chatbot_id')
                navigate(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`)
            }
        } else {
            let chatbot_id = searchParams.get('chatbot_id')
            navigate(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`)
        }
    }, [getChatbotIntegrationData, window.location.href])


    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbotIntegration.delete(removeChatbotIntegrationDetails.chatbot_id, removeChatbotIntegrationDetails.meta_integration_id, removeChatbotIntegrationDetails.meta_integration_type_id, removeChatbotIntegrationDetails.page_id);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(removeChatbotIntegrationDetails.chatbot_id, "")
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setShowDeleteConfirmation(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }
    const handleRemoveChatbotIntegration = () => {
        if (getChatbotIntegrationData && currentActiveFacebookIntegration) {
            let chatbot_id = searchParams.get('chatbot_id')
            if (chatbot_id) {
                setRemoveChatbotIntegrationDetails(
                    {
                        chatbot_id: chatbot_id,
                        meta_integration_type_id: currentActiveFacebookIntegration[0].meta_integration_type_assignment_data.meta_integration_type_id,
                        page_id: currentActiveFacebookIntegration[0].meta_integration_facebook_data.page_id,
                        meta_integration_id: currentActiveFacebookIntegration[0].meta_integration_id
                    }
                )
            }
            setShowDeleteConfirmation(true)
            setPhoneNumberIntegrate(currentActiveFacebookIntegration[0].meta_integration_facebook_data.page_name)
        }
    }

    function addChatbotIntegration() {

        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id && currentActiveFacebookIntegration) {
            setAddChatbotIntegrationDetails((prevState) => ({
                ...prevState,
                page_id: currentActiveFacebookIntegration[0].meta_integration_facebook_data.page_id,
                meta_integration_type_id: currentActiveFacebookIntegration[0].meta_integration_type_id,
                chatbot_id: chatbot_id,
                meta_integration_id: currentActiveFacebookIntegration[0].meta_integration_id
            }))
        }
        setFlagAddIntegration(true)
        setPhoneNumberIntegrate(currentActiveFacebookIntegration[0].meta_integration_facebook_data.page_name)
    }
    async function AddChatbotIntegration() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.addChatbotIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            setGetChatbotIntegrationData(response.data)
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagAddIntegration(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <>
            {currentActiveFacebookIntegration &&
                <FacebookInformationCard
                    integrationType="facebook"
                    key={currentActiveFacebookIntegration ? currentActiveFacebookIntegration[0]?.meta_integration_facebook_data?.meta_integration_facebook_id : 1}
                    flagDelete={false}
                    title={"Meta"}
                    // flagShowMetaIcon={true}
                    currentlySelected={currentActiveFacebookIntegration[0].meta_integration_facebook_data.chatbot_integration_assignment_data.some((chatbot: chatbotIntegrationAssignmentData) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id'))}
                    removeChatbotIntegration={() => { handleRemoveChatbotIntegration() }}
                    addChatbotIntegration={() => { addChatbotIntegration() }}
                    details={{
                        page_id: currentActiveFacebookIntegration[0]?.meta_integration_facebook_data?.page_id,
                        page_name: currentActiveFacebookIntegration[0]?.meta_integration_facebook_data?.page_name,
                        page_category: currentActiveFacebookIntegration[0]?.meta_integration_facebook_data?.page_category,
                        linked_chatbots: currentActiveFacebookIntegration[0]?.meta_integration_facebook_data?.chatbot_integration_assignment_data
                    }}
                    deleteChatbotIntegration={() => {
                        setShowDeleteConfirmation(true)
                    }
                    }
                    flagIntegration={true}
                />
            }
            {flagAddIntegration &&
                <GlobalPopUp
                    open={flagAddIntegration}
                    handleClose={() => { setFlagAddIntegration(false) }}
                    flagShowCloseIcon
                >
                    <Confirmation
                        integration='whatsapp'
                        cancel={() => { setFlagAddIntegration(false) }}
                        continue={AddChatbotIntegration}
                        confirmationMessage={`Are you sure you want to integrate ${phoneNumberIntegrate} to ${chatBotDetails?.chatbot_name}`}
                    />
                </GlobalPopUp>
            }
            {
                showDeleteConfirmation &&
                <GlobalPopUp
                    open={showDeleteConfirmation}
                    handleClose={() => { setShowDeleteConfirmation(false) }}
                >
                    <GlobalDeletePopup
                        deleteDescription={`Are you sure you want to remove ${chatBotDetails?.chatbot_name} from ${phoneNumberIntegrate}`}
                        onClose={() => { setShowDeleteConfirmation(false) }}
                        onDelete={deleteChatbotIntegration}
                        sx={{ maxWidth: "400px" }}
                    />
                </GlobalPopUp>
            }
        </>
    )
}

export default ChatbotInstagramIntegrationDetails;