import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Divider, Grid, useMediaQuery, } from '@mui/material'
import globalStyles from "../../../../../assets/styles/global.module.scss";
import styles from "../../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import { MetaContext, MetaContextPayload } from '../../../../../context/metaContext';
import { IntegrationContext, IntegrationContextPayload } from '../../../../../context/integrationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../../../context/userContext';
import { globalSlackIntegration } from '../../../../../models/axiosModel';
import SlackIntegrationDetails from './slackIntegrationDetails';
import FacebookInformationCard from '../../../../utility/facebookInformationCard';
import cardStyles from './../../../../../assets/styles/componentStyles/accountInformationCard.module.scss'
import GlobalButton from '../../../../utility/globalButton/button';
import IconsColor from '../../../../utility/iconsColor';
import tempStyles from "../../../../../assets/styles/componentStyles/accountInformationCard.module.scss";
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev, primaryColor } from '../../../../../utils/constants';
import agent from '../../../../../services/api';
import GlobalDeletePopup from '../../../../utility/globalDeletePopup/globalDeletePopup';

interface checkFlag {
    flagPage?: boolean
}
const ViewSlackIntegration = (props: checkFlag) => {
    const {
        setShowFacebookIntegrationDetails,
        showFacebookIntegrationDetails,
        metaCredentials
    } = useContext(MetaContext) as MetaContextPayload;

    const {
        getIntegrationList,
        integrationList,
        slackIntegrations,
    } = useContext(IntegrationContext) as IntegrationContextPayload;



    // CONST
    const navigate = useNavigate()
    const { setFlagLoading, setSuccess, setError, userDetail } = useContext(UserContext) as UserContextPayload
    const [slackIntegrationDetails, setSlackIntegrationDetails] = useState<any>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)')
    const laptopScreen = useMediaQuery('(max-width : 1600px) and (min-width : 900px)')

    const [responsePayload, setResponsePayload] = useState<any>({
        page_id: '',
        page_name: '',
        page_category: '',
        access_token_value: '',
        instagram_id: '',
        instagram_username: ''
    })

    console.log("slackIntegrationDetails", slackIntegrationDetails)
    useLayoutEffect(() => {
        (async () => {
            await getIntegrationList()
        })()
    }, [])


    useEffect(() => {
        if (searchParams.has('id')) {
            if (slackIntegrations && slackIntegrations.length > 0) {
                let id = Number(searchParams.get('id'))
                let integration = slackIntegrations.filter((slackIntegration: globalSlackIntegration) => slackIntegration.slack_integration_id == id)
                if (integration.length > 0) {
                    setSlackIntegrationDetails(integration)
                } else {
                    navigate('/channels')
                }
            }
        } else {
            navigate('/channels')
        }
    }, [slackIntegrations])

    async function deleteSlackIntegration() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let slack_integration_id = slackIntegrationDetails[0].slack_integration_id
        const response = await agent.deleteSlackIntegration.delete(slack_integration_id)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration deleted successfully!" }))
            navigate('/channels')
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

    }

    const innerHeight = smallScreen ? (window.innerHeight - 90) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 100) : (window.innerHeight - 110);
    const integrationViewInnerHeight = smallScreen ? (window.innerHeight - 110) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 140);

    return (
        <>
            <Grid item 
                className={showFacebookIntegrationDetails == false ? `${globalStyles.fullwidth} ${styles.integrationDefaultView}` : `${globalStyles.fullwidth} ${styles.integrationView}`}
                height={showFacebookIntegrationDetails == false ? `${innerHeight}px !important` : `${integrationViewInnerHeight}px !important`}
            >
                <Grid container>
                    {slackIntegrationDetails &&
                        <>
                            <Grid item className={globalStyles.fullwidth} xs={12} lg={12} xl={12}>
                                <Grid container direction={"row"} gap={3} flexWrap={"wrap"}>
                                    {props.flagPage ?
                                        <>
                                            {slackIntegrationDetails && slackIntegrationDetails[0].slack_channels.map((channel: any, index: number) => {
                                                return <>
                                                    <FacebookInformationCard
                                                        integrationType='Slack'
                                                        key={index}
                                                        flagDelete={false}
                                                        title={`WhatsApp Account ${index + 1}`}
                                                        currentlySelected={true}
                                                        showRemoveChatbotIntegration={true}
                                                        flagShowLinkedChatbot={true}
                                                        //   removeChatbotIntegration={() => { handleRemoveChatbotIntegration(pageData.meta_integration_id, pageData.meta_integration_type_id, pageData.instagram_id, pageData.page_id, pageData.instagram_username) }}
                                                        details={{
                                                            page_id: channel.channel_id,
                                                            page_name: channel.slack_channel_name,
                                                            page_category: channel.slack_channel_purpose,
                                                            linked_chatbots: channel.chatbot_slack_assignment_data
                                                        }}
                                                        flagIntegration={false}
                                                    />
                                                </>
                                            })

                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                <Grid item sx={{ width: "100%" }}>
                                                    <Grid container direction={"column"} gap="20px">
                                                        <Grid item xs={12}>
                                                            <Grid container direction={"column"}>
                                                                <Grid item className={tempStyles.header}>
                                                                    <Grid container justifyContent={"space-between"}>

                                                                        <Grid item className={globalStyles.displayFlex}>
                                                                            <Grid container>
                                                                                <Grid sx={{ marginRight: "5px" }} className={globalStyles.displayFlex} item>
                                                                                    <IconsColor iconName="colouredSlackIcon" width="20px" height="20px" />

                                                                                </Grid>
                                                                                <Grid item className={tempStyles.headerText}>{'Slack'}</Grid>
                                                                            </Grid>

                                                                        </Grid>

                                                                        <Grid item>
                                                                            <>
                                                                                <GlobalButton buttonText={"Delete"} className="deleteIntegrationButton" onClick={() => { setShowDeleteConfirmation(true) }} startIcon={<IconsColor iconName="DeleteIcon" fillColor="#6A097D" height="16" width="16" />} />
                                                                            </>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider style={{ opacity: "0.8" }} />
                                                            </Grid>

                                                        </Grid>
                                                        {/* slack workspace */}
                                                        <Grid item>
                                                            <Grid container alignItems={"center"} gap={"4px"}>
                                                                <Grid item className={cardStyles.objectKeyStyle}>
                                                                    <Grid container gap="16px" alignItems={"center"}>
                                                                        <IconsColor iconName="workspace" width="24px" height="24px" fillColor={primaryColor} />
                                                                        Workspace:&nbsp;</Grid>
                                                                </Grid>

                                                                <Grid item className={cardStyles.genericValueStyle}>{slackIntegrationDetails[0].slack_team_name}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* slack name */}
                                                        <Grid item>
                                                            <Grid container alignItems={"center"} gap={"4px"}>

                                                                <Grid item className={cardStyles.objectKeyStyle}>
                                                                    <Grid container gap="16px" alignItems={"center"}>
                                                                        <IconsColor iconName="profile" width="24px" height="24px" fillColor={primaryColor} />
                                                                        UserName:&nbsp;</Grid>
                                                                </Grid>
                                                                <Grid item className={cardStyles.genericValueStyle}>{slackIntegrationDetails[0].slack_user_real_name}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* no of channels */}
                                                        <Grid item>
                                                            <Grid container alignItems={"center"} gap={"4px"}>
                                                                <Grid item className={cardStyles.objectKeyStyle}>
                                                                    <Grid container gap="16px" alignItems={"center"}>
                                                                        <IconsColor iconName="slackChannel" width="24px" height="24px" fillColor={primaryColor} />
                                                                        No. of Channels:&nbsp;</Grid>
                                                                </Grid>
                                                                <Grid item className={cardStyles.genericValueStyle}>{slackIntegrationDetails[0].slack_channels.length}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>

                            {showDeleteConfirmation &&
                                <GlobalDeletePopup
                                    deleteDescription={`Are you sure you want to delete ${slackIntegrationDetails[0].slack_user_real_name} - ${slackIntegrationDetails[0].slack_team_name}`}
                                    open={showDeleteConfirmation}
                                    onClose={() => { setShowDeleteConfirmation(false) }}
                                    onDelete={(e: any) => {
                                        deleteSlackIntegration()
                                        setShowDeleteConfirmation(false)
                                    }}
                                />
                            }
                        </>}
                </Grid>
            </Grid>

        </>
    )
}

export default ViewSlackIntegration