import { Grid, Typography } from '@mui/material'
import React from 'react'
import styles from "../../../../assets/styles/componentStyles/channels/global/requisites.module.scss";
import globalStyles from "../../../../assets/styles/global.module.scss";
import IconsColor from '../../../utility/iconsColor';

interface prereqSteps {
    title: string,
    content: string
}
interface prerequisitesProps {
    integrationType: string,
    prereqSteps: prereqSteps[]
}

function Prerequisites(props: prerequisitesProps) {
    const { integrationType, prereqSteps } = props
    return (
        <Grid item className={styles.mainBlock}>
            <Grid container direction={"column"} gap="16px">
                <Grid item>
                    <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                        <Grid item>
                            <span className={globalStyles.whatsappIntegrationFontStyle}>{`Prerequisites for ${integrationType} Integration`}</span>
                        </Grid>
                        <Grid item className={styles.workingIconStyle}>
                            <IconsColor iconName="ideaIcon" height={"30"} width={"30"} />
                        </Grid>
                    </Grid>
                </Grid>

                <>
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item style={{ width: "100%" }}>
                                <Typography component='span' className={styles.setUpValue}>A few things that are necessary to integrate with Facebook should be ready before you begin. We have compiled a collection of helpful hints that will walk you through each stage.</Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                <Typography component='span' className={styles.setUpTitle}>Steps to integrate</Typography>
                            </Grid>
                            <Grid item>
                                {prereqSteps.map((ele, index) => {
                                    return (
                                        <>
                                            <Grid container gap={2} alignItems={"start"} style={{ marginBottom: "15px" }} flexWrap={"nowrap"}>
                                                <Grid item className={styles.setUpIndexGrid}>
                                                    <Typography component='span' className={styles.setUpIndex}>{index + 1}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction={"column"} gap={"5px"}>
                                                        <Grid item>
                                                            <Typography component='span' className={styles.setUpValue}>{ele.title}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography component='span' className={styles.setUpContent}>{ele.content}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>

                        </Grid>
                    </Grid>
                </>

            </Grid>
        </Grid>
    )
}

export default Prerequisites