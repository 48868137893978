import { Drawer, Grid, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TabComponent from '../../templateComponents/tabComponent';
import { isAdmin } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";

interface ChatInformationBlockInterface {
    open: boolean,
    setOpen: any,
    value: any,
    setValue: any,
    sourceDetails: any,
    feedbackDetails: any,
    getChatFeedbackKeys: any,
    setChatClicked?: any,
    setChatInfoClicked?: any,
    flagMobile?: true,
    chatClicked?: any,
    chatInfoClicked?: any
}
function ChatInformationBlock(props: ChatInformationBlockInterface) {
    const smallScreen = useMediaQuery('(max-width : 1250px) and (min-width : 1000px)')
    const { userDetail } = useContext(UserContext) as UserContextPayload
    const { chatInfoClicked, chatClicked, open, setOpen, value, setValue, sourceDetails, feedbackDetails, getChatFeedbackKeys, setChatClicked, setChatInfoClicked, flagMobile } = props
    return (
        <Grid item
            xs={flagMobile ? smallScreen ? 6 : 12 : undefined}
            sx={{
                maxWidth: !flagMobile ? open ? "250px" : "0px" : "100%",
                padding: flagMobile ? smallScreen ? "0px 15px" : "" : "", height: flagMobile ? smallScreen ? "calc(100vh - 110px)" : "auto" : "auto"
            }}
        >

            <Grid container sx={{ position: "relative", cursor: "pointer", zIndex: 5 }} onClick={() => {
                setOpen(!open)
                setChatInfoClicked(!chatInfoClicked);
                setChatClicked(!chatClicked)
            }}>
                {open ?
                    <ChevronRightIcon style={{ position: "absolute", top: "15px", left: flagMobile ? "0px" : "-13px", backgroundColor: "#F7E9FF", borderRadius: "50%" }} />
                    :
                    <ChevronLeftIcon style={{ position: "absolute", top: "15px", left: flagMobile ? "0px" : "-13px", backgroundColor: "#F7E9FF", borderRadius: "50%" }} />
                }
            </Grid>
            <Drawer sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    position: "relative",
                    borderLeft: 0,
                    zIndex: 1
                },
            }} variant='persistent' open={open} anchor="right" style={{ position: "relative", display: !open ? "none" : "", transform: "none", transition: "transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms" }}>
                <Grid container direction={"column"} gap={"12px"} p={2} paddingBlock={1}>
                    <TabComponent
                        value={value}
                        setValue={setValue}
                        tabHeaderClassname="settingsTabStyle" className="settingsTabStyle" tabs={
                            [
                                {
                                    label: 'Info', content: <>
                                        {Object.entries(sourceDetails).length > 0 && Object.entries(sourceDetails).map(([key, value]: [string, any]) => {
                                            return (
                                                <>
                                                    {key == "playground_url" ?
                                                        isAdmin(userDetail) &&
                                                        <>
                                                            <Grid item mt={1} className={styles.userLastMessageEntries}>
                                                                <a style={{ fontSize: "15px", fontWeight: "600" }} href={value} target="_blank">View thread in OpenAI</a>
                                                            </Grid>
                                                        </>
                                                        :
                                                        (key == "User_input_token" || key == "User_output_token") ?
                                                            isAdmin(userDetail) &&
                                                            <>
                                                                {value ?
                                                                    <Grid item mt={1} className={styles.userLastMessageEntries}>
                                                                        <Grid container direction={"column"}>
                                                                            <Grid item>
                                                                                <Grid container direction={"column"}>
                                                                                    <Grid item className={styles.messageEntryTitle}>{key.split('_').join(' ')}&nbsp;</Grid>
                                                                                    <Grid item className={styles.messageEntryValue}> {`${value}`}</Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <></>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {value ?
                                                                    <Grid item mt={1} className={styles.userLastMessageEntries}>
                                                                        <Grid container direction={"column"}>
                                                                            <Grid item>
                                                                                <Grid container direction={"column"}>
                                                                                    <Grid item className={styles.messageEntryTitle}>{key.split('_').join(' ')}&nbsp;</Grid>
                                                                                    <Grid item className={styles.messageEntryValue}> {`${value}`}</Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <></>
                                                                }
                                                            </>
                                                    }

                                                </>)
                                        })
                                        }</>, tabVisibility: true, flagInitiallyActive: false
                                },
                                {
                                    label: 'Feedback', content: <>
                                        {Object.entries(feedbackDetails).length > 0 ?
                                            Object.entries(feedbackDetails).map(([key, value]: [string, any]) => {
                                                return (
                                                    <>
                                                        {key == "playground_url" ?
                                                            isAdmin(userDetail) &&
                                                            <>
                                                                <a href={value} target="_blank">View thread in OpenAI</a>
                                                            </>
                                                            :
                                                            <>
                                                                {/* // key !== "chatbot_message_feedback_id" && key !== "user_assignment_id" && key !== "timestamp" && 
                                                                    // key !== "feedback_id" && key !== "feedback_option_id" &&  */}
                                                                {value && (
                                                                    <>
                                                                        {(key == "feedback_option_name" || key == "feedback_provider") &&
                                                                            <Grid item mt={1} className={styles.userLastMessageEntries} >
                                                                                <Grid container direction={"column"}>
                                                                                    <Grid item>
                                                                                        <Grid container direction={"column"}>
                                                                                            <Grid item className={styles.messageEntryTitle}>{getChatFeedbackKeys(key)}&nbsp;</Grid>
                                                                                            <Grid item className={styles.messageEntryValue}> {`${value}`}</Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>}

                                                                        {key === "feedback_data" && Array.isArray(value) && (
                                                                            value.map((field: any, ind: number) => (
                                                                                <Grid
                                                                                    key={ind}
                                                                                    item
                                                                                    mt={1}
                                                                                    className={styles.userLastMessageEntries}
                                                                                >
                                                                                    <Grid container direction={"column"}>
                                                                                        <Grid item>
                                                                                            <Grid container direction={"column"}>
                                                                                                <Grid item className={styles.messageEntryTitle}>
                                                                                                    {field.feedback_option_field_value}
                                                                                                </Grid>
                                                                                                <Grid item className={styles.messageEntryValue}>
                                                                                                    {field.feedback_value}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        }

                                                    </>)
                                            })
                                            :
                                            <div style={{ fontSize: "15px", fontWeight: "500", display: "flex", justifyContent: "center", alignItems: "center" }}>No Feedback available</div>
                                        }</>, tabVisibility: true, flagInitiallyActive: false
                                }
                            ]} />

                </Grid>
            </Drawer>
        </Grid>
    )
}

export default ChatInformationBlock