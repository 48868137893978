export const defaultNoneTag = {
    contact_tag_id: -1,
    contact_tag_name: "None",
    created_at_timestamp: "",
    updated_at_timestamp: "",
}

export const defaultCreateTemplatePayload = {
    "whatsapp_template_id": null,
    "meta_integration_whatsapp_id": 0,
    "whatsapp_template_data": {
        "whatsapp_template_data_id": null,
        "whatsapp_template_category_id": 2,
        "whatsapp_language_id": 0,
        "whatsapp_template_name": "",
        "whatsapp_template_description": "",
        "whatsapp_template_header": {
            "whatsapp_template_header_type_id": "",
            "whatsapp_template_header_type_name": "",
            "whatsapp_template_header_data": [
                {
                    "whatsapp_template_header_data_value": "", 
                    "whatsapp_template_header_data_id": 0,
                    "whatsapp_template_header_data_google_storage_id": 0, 
                    "whatsapp_template_header_variable_data": []
                }
            ]
        },
        "whatsapp_template_body": {
            "whatsapp_template_body_value": "",
            "whatsapp_template_body_description": "",
            "whatsapp_template_body_variable_data": []
        },
        "whatsapp_template_button": [],
        "whatsapp_template_footer": {
            "whatsapp_template_footer_type_id": "",
            "whatsapp_template_footer_data": []
        }
    }
}