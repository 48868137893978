import { Grid, TextField, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import Required from '../../utility/required'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import LeadGenerationFieldTable from './leadGenerationFieldTable'
import GlobalButton from '../../utility/globalButton/button'
import globalStyles from './../../../assets/styles/global.module.scss'
import addIcon from './../../../assets/chatbotAssets/addCircularReqIcon.svg'
import { createLeadGenerationPayloadModel, getGenericFieldTypeDataResponseModel, leadFieldDataModel } from '../../../models/axiosModel'
import { useSearchParams } from 'react-router-dom'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'

interface newLeadFormInterface {
    handleSaveLeadGenerationData: () => Promise<any>,
    handleSaveLeadGeneration: () => Promise<any>
}

const LeadDetailsForm = (props: newLeadFormInterface) => {
    const { handleSaveLeadGenerationData, handleSaveLeadGeneration } = props;
    const {createLeadGenerationPayload, leadGenerationList, tempLeadGenerationPayload, handleCancelLeadGeneration, handleChangeLeadGenerationPayload, setAddNewLeadField} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    
    const [searchParams] = useSearchParams();
    let lead_id = searchParams.get('lead_id');
    let chatbot_id = searchParams.get('chatbot_id');

    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 750px)');
    
    const isSaveLeadGenerationDataDisabled = () => {
        if(lead_id) {
            if(JSON.stringify(createLeadGenerationPayload) === JSON.stringify(tempLeadGenerationPayload)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if(createLeadGenerationPayload.chatbot_lead_name.trim().length === 0 || createLeadGenerationPayload.chatbot_lead_description.trim().length === 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    const innerHeight = flagMobileScreen? (window.innerHeight - 260) : flagSmallScreen ? (window.innerHeight - 270) : (window.innerHeight - 240);

    return (
        <>
            <Grid container direction={"column"} gap={"20px"} className={styles.newLeadDataStyle} sx={{height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto", padding: "5px", boxSizing: "border-box"}}>
                <Grid item sx={{width: "100%"}}>
                    <Grid container gap={2}>

                        <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <span className={styles.formHeadingFontStyle}>Lead Name <Required /></span>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF !important",
                                            border: 'none', 
                                            "& fieldset": { border: 'none' },
                                            boxShadow: "0px 0px 3px rgba(0,0,0,0.25)", 
                                            borderRadius: "5px",
                                            '& .MuiOutlinedInput-input': {
                                                padding: "11.5px 14px !important",
                                                fontSize: flagSmallScreen ? "14px !important" : "15px !important",
                                                fontWeight: "500"
                                            }
                                        }}
                                        value={createLeadGenerationPayload.chatbot_lead_name}
                                        placeholder='Enter Lead Name'
                                        onChange={(event) => handleChangeLeadGenerationPayload(Number(chatbot_id), 'chatbot_lead_name', event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={6} sx={{width: "100%"}}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <span className={styles.formHeadingFontStyle}>Lead Description <Required /></span>
                                </Grid>
                                <Grid item>
                                    <textarea
                                        placeholder='Enter Description'
                                        value={createLeadGenerationPayload.chatbot_lead_description}
                                        onChange={(event) => handleChangeLeadGenerationPayload(Number(chatbot_id), 'chatbot_lead_description', event.target.value)}
                                        dir="auto"
                                        className={`${styles.text_area_style} ${styles.minHeight300} ${styles.leadGenerationDescription}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{width: "100%"}}>
                            <Grid container gap={1}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            <span className={styles.formHeadingFontStyle}>Fields <Required /></span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container className={globalStyles.clickable} gap='10px' onClick={() => { setAddNewLeadField(true) }}>
                                                <Grid item sx={{ display: "flex" }}><img src={addIcon} alt="add" /></Grid>
                                                <Grid item className={styles.newFieldTextStyle}>New Field</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <LeadGenerationFieldTable />                           
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>       
                </Grid>
            </Grid>

            <Grid container className={styles.buttonPositionStyle}>
                <Grid item sx={{ width: "100%" }} mt={2}>
                    <Grid container direction={"row"} gap={2} justifyContent={"left"}>
                        <Grid item>
                            {/* <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelLeadGeneration()}/> */}
                            <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px" }} onClick={() => handleCancelLeadGeneration(lead_id ? true : false)}/>
                        </Grid>
                        <Grid item>
                            <GlobalButton 
                                // buttonText={flagUpdate ? "Update" : "Save"}
                                buttonText={lead_id ? "Update" : "Save & Next"}
                                className='primaryButtonStyle' 
                                sx={{ minWidth: "150px" }} 
                                // onClick={lead_id && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data && leadGenerationList.chatbot_lead_data[0].chatbot_lead_integration_data.length > 0 ? handleSaveLeadGeneration : handleSaveLeadGenerationData}
                                onClick={lead_id ? handleSaveLeadGeneration : handleSaveLeadGenerationData}
                                disabled={isSaveLeadGenerationDataDisabled()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LeadDetailsForm
