import { Button, Grid } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react'

interface DatePresetsProps {
    startDate: Moment | null;
    endDate: Moment | null;
    dateFormat: string;
    handlePresets: (start: Moment, end: Moment) => void;
}

const DatePresets = (props: DatePresetsProps) => {
    const {startDate, endDate, dateFormat, handlePresets} = props;
    const today = moment();
    const presets = [
        {
            text: "Last Week",
            start: moment().subtract(1, "week"),
            end: today
        },
        {
            text: "Last Month",
            start: moment().subtract(1, "month"),
            end: today
        },
        {
            text: "Last 3 Months",
            start: moment().subtract(3, "month"),
            end: today
        },
    ]
    return (
        <Grid item sx={{marginLeft: "20px", paddingBottom: "15px"}}>
            {presets.map(({ text, start, end }) => {
                const isChosen =
                    startDate &&
                    endDate &&
                    moment(start).format(dateFormat) === moment(startDate).format(dateFormat) &&
                    moment(end).format(dateFormat) === moment(endDate).format(dateFormat);

                return (
                    <Button
                        key={text}
                        sx={{ backgroundColor: isChosen ? "#6A097D" : "black", color: isChosen ? "#FFFFFF" : "white", padding: "4px 15px", textTransform: "capitalize", 
                            '&:hover': {
                                backgroundColor: "#F7E9FF", color: "#6A097D", boxShadow: "0px 0px 4px rgba(106,9,25,0.5)"
                            } }}
                        style={{ marginRight: "12px" }}
                        onClick={() => handlePresets(start, end)}
                    >
                        {text}
                    </Button>
                );
            })}
        </Grid>
    )
}

export default DatePresets
