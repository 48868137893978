import { Grid, Typography } from '@mui/material'
import React from 'react'
import IconsColor from '../../../../utility/iconsColor'
import GlobalButton from '../../../../utility/globalButton/button'
import integrationStyles from '../../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'

interface noAuthorisedSlackAccountProps {
    handleIntegrateSlack: () => void
}
function NoAuthorisedSlackAccount(props: noAuthorisedSlackAccountProps) {
    const { handleIntegrateSlack } = props
    return (
        <Grid container direction={"column"} justifyContent={"start"} alignItems={"start"} sx={{ width: "100%" }} gap={2} flexWrap={"nowrap"} pt={1}>
            <Grid item style={{ width: "100%" }}>
                <Grid container alignItems={"center"} gap={"13px"} mt={1} flexWrap={"nowrap"}>
                    <Grid item sx={{ display: "flex" }}>
                        <IconsColor iconName="colouredSlackIcon" width="30px" height="30px" />
                    </Grid>
                    <Grid item>
                        <Typography className={integrationStyles.whatsappIntegrationFontStyle}>Integrate with your Slack Account</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%" }} padding={"30px"}>
                <Grid container direction={"column"} gap={"20px"}>
                    <Grid item>
                        <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>Kindly integrate your Slack account to begin the process of linking your Slack channels. </span>
                    </Grid>
                    <Grid item sx={{ paddingTop: "0px" }} textAlign={"end"}>
                        <GlobalButton buttonText={"Integrate Slack Account"} className='primaryButtonStyle' onClick={() => { handleIntegrateSlack() }} sx={{ padding: "6px 20px !important" }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NoAuthorisedSlackAccount