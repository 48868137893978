
export const facebookPrereq = [
    {
        title: "Connect Facebook",
        content: "Navigate to Channels section and Click on the Integrate button of Facebook Messenger to initiate the integration process."
    },
    {
        title: "Authenticate with Facebook Sign-in",
        content: "Log in to your Facebook account using your credentials to authenticate the integration between your social media management platform and Facebook."
    },
    {
        title: "Select the Facebook Business Page",
        content: "Choose the Facebook business page associated with your brand or organization from the list of available pages."
    },
    {
        title: "Complete the Setup",
        content: "Follow the prompts to complete the setup process, ensuring that all necessary permissions and settings are configured correctly for seamless integration."
    }
]

export const instagramPrereq = [
    {
        title: "Connect Instagram",
        content: "Navigate to Channels section and Click on the Integrate button of Facebook Messenger to initiate the integration process."
    },
    {
        title: "Authenticate with Facebook Sign-in",
        content: "Log in to your Facebook account using your credentials to authenticate the integration between your social media management platform and Facebook."
    },
    {
        title: "Select the Facebook Business Page",
        content: "Choose the Facebook business page associated with your brand or organization from the list of available pages."
    },
    {
        title: "Complete the Setup",
        content: "Follow the prompts to complete the setup process, ensuring that all necessary permissions and settings are configured correctly for seamless integration."
    }
]
export const feedbackOptions = ["Factually incorrect response", "Out of context response", "Didn't fully follow instruction", "Unsafe or problematic", "Other"]
export const feedbackOptionsUpdated = ["Factually incorrect response", "Out of context response", "Didn't fully follow instruction", "Unsafe or problematic", "Other", "More..."]
