import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ExtensionFields from './extensionFields';
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/extensionDrawer.module.scss'

const ExtensionDrawer = (props: any) => {
    const { ref, thread, threadData, chatbotTheme } = props;

    return (
        <>
            <Grid container direction={"column"}>
                <Grid item className={styles.extension_drawer_popup_style}>
                    <ExtensionFields data={threadData} chatbotTheme={chatbotTheme} />
                </Grid>
            </Grid>
        </>

    )
}

export default ExtensionDrawer