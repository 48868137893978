import { Checkbox, FormControlLabel, FormGroup, Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import Required from '../../utility/required'
import { chatbotLeadFieldDataModel, leadFieldDataModel } from '../../../models/axiosModel'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import { findIndex } from 'lodash'

interface selectFieldInterface {
    integrationName: string,
    customTemplatePromptActionData: String,
    setCustomTemplatePromptActionData: React.Dispatch<React.SetStateAction<String>>
}

const SelectFieldsPopup = (props: selectFieldInterface) => {
    const {integrationName, customTemplatePromptActionData, setCustomTemplatePromptActionData} = props;
    const {selectFieldData, allowedFieldsData, setAllowedFieldsData, allowedFieldsSlackData, setAllowedFieldsSlackData, editIntegrationData} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const handleChangeAllowFieldData = (event: React.ChangeEvent<HTMLInputElement>, field: any) => {
        const { checked } = event.target;
        let tempStr = customTemplatePromptActionData.trim();
    
        if(integrationName === "Email") {
            if (allowedFieldsData) {
                if (!checked) {
                    let fieldIndex = tempStr.indexOf(`"value":"${field.chatbot_lead_field_name}"`);
        
                    if (fieldIndex > -1) {
    
                        let startOfEntry = tempStr.lastIndexOf('[[{"id"', fieldIndex);
                        let endOfEntry = tempStr.indexOf('}]]', fieldIndex) + 3;
        
                        tempStr = tempStr.slice(0, startOfEntry) + tempStr.slice(endOfEntry);
        
                        setCustomTemplatePromptActionData(tempStr.trim());
                    } else {
    
                    }
                } else {
                    let fieldString = `{"id":"${field.chatbot_lead_field_id}","value":"${field.chatbot_lead_field_name}"}`;
        
                    setCustomTemplatePromptActionData(tempStr.trim());
                }
    
                setAllowedFieldsData(prev =>
                    prev
                        ? checked
                            ? [...prev, field]
                            : prev.filter(item => item.chatbot_lead_field_name !== field.chatbot_lead_field_name)
                        : null
                );
            }
        }

        else if(integrationName === "Slack") {
            if (allowedFieldsSlackData) {
                if (!checked) {
                    let fieldIndex = tempStr.indexOf(`"value":"${field.chatbot_lead_field_name}"`);
        
                    if (fieldIndex > -1) {
    
                        let startOfEntry = tempStr.lastIndexOf('[[{"id"', fieldIndex);
                        let endOfEntry = tempStr.indexOf('}]]', fieldIndex) + 3;
        
                        tempStr = tempStr.slice(0, startOfEntry) + tempStr.slice(endOfEntry);
        
                        setCustomTemplatePromptActionData(tempStr.trim());
                    } else {
    
                    }
                } else {
                    let fieldString = `{"id":"${field.chatbot_lead_field_id}","value":"${field.chatbot_lead_field_name}"}`;
        
                    setCustomTemplatePromptActionData(tempStr.trim());
                }
    
                setAllowedFieldsSlackData(prev =>
                    prev
                        ? checked
                            ? [...prev, field]
                            : prev.filter(item => item.chatbot_lead_field_name !== field.chatbot_lead_field_name)
                        : null
                );
            }
        }
        
    };

    return (
        // <Grid item sx={{width: "100%"}} className={styles.selectFieldCardStyle}>
        <Grid item sx={{width: "100%"}}>
            <Grid container direction={"column"} gap={1}>
                <Grid item>
                    <span className={styles.selectFieldHeadingTextStyle}>{`Select Fields for ${integrationName}`} <Required /></span>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"} gap={3}>
                        <FormGroup sx={{flexDirection: "row !important"}} className={styles.formGroupStyles}>
                            {integrationName === "Email" &&
                                allowedFieldsData && selectFieldData && selectFieldData.length > 0 && selectFieldData.map(field => (
                                    <FormControlLabel
                                        key={field.chatbot_lead_field_name}
                                        className={styles.checkBoxStyle}
                                        // sx={{marginRight: flagSmallScreen ? "15px !important" : "50px !important"}}
                                        control={
                                            <Checkbox
                                                className={styles.checkBoxFilter}
                                                // disabled={(field.chatbot_lead_field_name === "Browser Name" || field.chatbot_lead_field_name === "Geo Location" || field.chatbot_lead_field_name == "Timestamp") || !editIntegrationData ? true : false}
                                                disabled={!editIntegrationData ? true : false}
                                                checked={allowedFieldsData?.some(item => item.chatbot_lead_field_name === field.chatbot_lead_field_name)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAllowFieldData(event, field)}
                                                name={field.chatbot_lead_field_name || ''}
                                            />
                                        }
                                        label={field.chatbot_lead_field_name || ''}
                                    />
                                ))
                            }
                            {integrationName === "Slack" &&
                                allowedFieldsSlackData && selectFieldData && selectFieldData.length > 0 && selectFieldData.map(field => (
                                    <FormControlLabel
                                        key={field.chatbot_lead_field_name}
                                        className={styles.checkBoxStyle}
                                        sx={{marginRight: "50px"}}
                                        control={
                                            <Checkbox
                                                className={styles.checkBoxFilter}
                                                // disabled={(field.chatbot_lead_field_name === "Browser Name" || field.chatbot_lead_field_name === "Geo Location" || field.chatbot_lead_field_name == "Timestamp") || !editIntegrationData ? true : false}
                                                disabled={!editIntegrationData ? true : false}
                                                checked={allowedFieldsSlackData?.some(item => item.chatbot_lead_field_name === field.chatbot_lead_field_name)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAllowFieldData(event, field)}
                                                name={field.chatbot_lead_field_name || ''}
                                            />
                                        }
                                        label={field.chatbot_lead_field_name || ''}
                                    />
                                ))
                            }
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SelectFieldsPopup
