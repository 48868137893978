import { Grid } from '@mui/material'
import React from 'react'
import GlobalButton from '../utility/globalButton/button'
import numberChangeIcon from "../../assets/images/chatbot/numberChangeIcon.png";
import styles from "../../assets/styles/componentStyles/planHasExhausted.module.scss";
import whatsappIntegrationConfirmation from "../../assets/images/chatbot/whatsappIntegrationConfirmation.png"
interface confirmationProps {
    integration: string,
    continue: () => void,
    cancel: () => void,
    confirmationMessage: string
}

const Confirmation = (props: confirmationProps) => {

    return (
        <Grid container direction="column" className={styles.upperGrid} alignItems={"center"} mt={3}>
            <Grid item>
                <img src={props.integration === "whatsapp" ? whatsappIntegrationConfirmation : numberChangeIcon} alt="numberChange" style={{ width: "135px", height: "auto" }} />
            </Grid>
            <Grid item className={styles.confirmationText}>Confirmation</Grid>
            <Grid item className={styles.subText}>
                <Grid container direction={"column"}>
                    <Grid item>{props.confirmationMessage}</Grid>
                    {/* <Grid item>  +91 8923123432 with +91 8234532456 ?</Grid> */}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item className={styles.buttonWrapper}><GlobalButton onClick={props.cancel} flagFullWidth buttonText={"Cancel"} className='transparentBorderedButtonStyle' /></Grid>
                    <Grid item className={styles.buttonWrapper}><GlobalButton onClick={props.continue} buttonText={"Continue"} className='primaryButtonStyle' /></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Confirmation