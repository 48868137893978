import { Divider, Grid, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import RoundLoader from '../../utility/globalLoader'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import globalStyles from './../../../assets/styles/global.module.scss'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import headerStyles from './../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import styles from './../../../assets/styles/componentStyles/userAnalyticsStyles/userAnalytics.module.scss'
import UserAnalyticsDetail from './userAnalyticsDetail'
import { userAnalyticsDataResponseModel } from '../../../models/axiosModel'
import { initialAnalyticsData } from './userAnalyticsConstants'
import { isDev } from '../../../utils/constants'
import GlobalButton from '../../utility/globalButton/button'
import { useNavigate } from 'react-router-dom'
import errorStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import IconsColor from '../../utility/iconsColor'

const UserAnalytics = () => {
    const {flagLoading, userDetail} = useContext(UserContext) as UserContextPayload;
    const navigate = useNavigate();
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const innerHeight = window.innerHeight - 130;

    const [appliedFilter, setAppliedFilter] = useState(false);
    const [flagAddFilter, setFlagAddFilter] = useState(false);
    const [userAnalyticsData, setUserAnalyticsData] = useState<userAnalyticsDataResponseModel | null>(null);

    useEffect(() => {
        setUserAnalyticsData(initialAnalyticsData)
    }, [])

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${headerStyles.templateWrapper}`}>
            {flagLoading.flagRoundLoader && <RoundLoader />}
            <Grid container sx={{ padding: { xs: "15px 0px", sm: "23px 0px" } }} direction={"column"} flexWrap={"nowrap"}>
                <Grid item xs={12} sm={12}>
                    <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                        <Grid item className={globalStyles.fullwidth} sx={{ marginBottom: !smallScreen ? "10px" : "", padding: { xs: "0px 20px", lg: "5px 25px 0px 27px" } }}>
                            <GlobalPageHeaderText
                                mainHeadText={"AI Insights"}
                                flagMainTextOnly
                                // actionBlock={
                                //     !smallScreen && <Grid item>
                                //         <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                                //             <Grid item>
                                //                 <IconButton
                                //                     className={`${globalStyles.cmpGlobalMobileAddIcon} ${styles.addFilterIcon}`}
                                //                     onClick={() => { setFlagAddFilter(true) }}
                                //                 >
                                //                     <FilterAltOutlinedIcon style={{ height: "28px", width: 'auto' }} />
                                //                 </IconButton>
                                //             </Grid>
                                //         </Grid>
                                //     </Grid>
                                // }
                            />
                            {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${headerStyles.dividerStyle}`} sx={{ paddingTop: "15px !important" }} />}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{padding: flagSmallScreen ? "5px 0px 5px 20px" : "5px 28px"}}>
                    <Grid container direction={"column"}>
                        {userAnalyticsData && isDev(userDetail) &&
                            <UserAnalyticsDetail appliedFilter={appliedFilter} userAnalyticsData={userAnalyticsData}/>
                        }

                        {userAnalyticsData && !isDev(userDetail) &&
                            <Grid item>
                                <Grid container sx={{ padding: { xs: "15px 25px", sm: "18px 25px", height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto" } }} flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                                    <Grid item xs={12} md={11} lg={8} xl={6}>
                                        <Grid container direction={"column"} alignItems={"center"} sx={{boxShadow: "0px 0px 6px rgba(0,0,0,0.15)", height: "100%", padding: "40px", borderRadius: "15px", background: "linear-gradient(to right bottom, #e1e9ff, #ffffff)"}} justifyContent={"center"}>
                                            {/* <Grid item>
                                                <img src={emptyPageImage} alt="" className={styles.errorImage}/>
                                            </Grid> */}
                                            <Grid item textAlign={"center"}>
                                                <span className={errorStyles.errorTextStyle}>We currently do not have enough data available to generate meaningful insights.</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Grid>

    )
}

export default UserAnalytics
