import React, { forwardRef, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import styles from "../../../../assets/styles/componentStyles/mobileDropdownDrawer.module.scss";
import globalStyles from "../../../../assets/styles/global.module.scss";
import GlobalSearch from '../../../utility/GlobalSearch/GlobalSearch';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


const CustomDrawer = forwardRef(({
    chatbotTheme,
    handleRecursion,
    drawerOpen,
    handleDrawerClose,
    searchValue,
    setSearchValue,
    dropdown_options,
    option_key_name,
    image_url,
    flag_multiple,
    dropdown_name,
    setFlagSubmit,
    flagSubmit
}: any, targetRef: any) => {

    // const { setDrawerProps, chatBotDetail } = useContext(SocketContext)
    return (
        <Drawer
            container={targetRef ? targetRef.current : null}
            variant="temporary"
            anchor="bottom"
            elevation={0}
            open={drawerOpen}
            onClose={handleDrawerClose}
            PaperProps={{
                style: {
                    position: "absolute",
                    height: "auto", // Set to auto to allow smooth resizing
                    bottom: 0,
                    overflow: "hidden", // Important for smooth animation
                    maxHeight: dropdown_options ? `${dropdown_options.length * 15 + 50}px` : "50px", // Dynamic max height
                },
            }}
            SlideProps={{
                appear: false,
                container: targetRef ? targetRef.current : null,
                direction: "up",
                easing: {
                    enter: "cubic-bezier(0.25, 0.8, 0.25, 1)",  // Custom easing for enter (open)
                    exit: "cubic-bezier(0.42, 0, 0.58, 1)",    // Custom easing for exit (close)
                },
                timeout: 300, // Match the transition duration for smoothness
            }}
            BackdropProps={{
                style: { position: "absolute" },
            }}
            ModalProps={{
                container: targetRef ? targetRef.current : null,
            }}
            sx={{
                overflow: "hidden",
                position: "absolute",
                transition: 'max-height 0.3s ease-in-out',
                '& .MuiDrawer-paper': {
                    transition: 'max-height 0.3s ease-in-out',
                    position: "absolute",
                    height: "auto", // Keep height auto
                    bottom: 0,
                    maxHeight: dropdown_options ? `${dropdown_options.length * 15 + 50}px` : "50px",
                },
            }}
        >
            <div style={{ padding: '16px', position: 'sticky', top: 0, zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <IconButton
                        sx={{ color: chatbotTheme?.chatbot_send_message_icon_color ?? "#6A097D" }}
                        className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}  ${styles.dropdownTitleStyle}`} onClick={handleDrawerClose}>
                        {dropdown_name}
                    </IconButton>
                    {dropdown_options && dropdown_options.length > 6 ?
                        <>
                            <GlobalSearch
                                fullWidth
                                onChange={(e) => setSearchValue(e.target.value)}
                                size="small"
                                placeholder={'Search'}
                            />
                        </>
                        :
                        <>


                        </>
                    }
                    {flag_multiple == "true" &&
                        <IconButton className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}  ${globalStyles.primaryColor} ${styles.dropdownTitleStyle}`} onClick={handleDrawerClose}>
                            Done
                        </IconButton>}
                </div>
            </div>
            <div style={{
                overflowY: 'auto', flex: 1,
                maxHeight: `${window.innerHeight - 200}px`,
                // minHeight: drawerOpen ? `${window.innerHeight - 200}px` : ""
            }}
            >
                {dropdown_options && dropdown_options.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter((option: any) => {
                    const optionKeyValue = option['name'];

                    if (typeof optionKeyValue === 'string') {
                        return optionKeyValue.toLowerCase().includes(searchValue.toLowerCase());
                    }
                    return true;
                }).map((option: any, index: any) => (
                    flag_multiple == "true" ?
                        <>
                            <div style={{ display: "flex", padding: "10px", gap: "15px" }} key={index} >
                                {
                                    <Checkbox
                                        className={styles.checkboxStyle}
                                        key={index}
                                        checked={true}

                                    // onChange={(e) => handleCheckboxChange(option[option_key_name], e.target.checked)}
                                    />}
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {image_url !== null &&
                                            <img
                                                src={option[image_url]}
                                                alt="icon"
                                                style={{ width: '17px', height: '17px', marginRight: '8px' }}
                                            />}
                                    </div>
                                    <div style={{ fontSize: "15px", fontWeight: "500" }}> {option[option_key_name]}</div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <ListItemButton
                                disabled={true}
                                onClick={() => {
                                    handleRecursion({ suggestion_name: option[option_key_name] })
                                    // setDrawerProps((prevState: any) => ({ ...prevState, openDrawer: false }));
                                    setFlagSubmit(true)
                                }
                                    // handleSelectOption(option[option_key_name])
                                }
                                key={index}
                                selected={false}
                            >
                                {image_url !== null &&
                                    <ListItemIcon sx={{ minWidth: "30px !important" }}>
                                        <img src={option[image_url]} style={{ width: '17px', height: '17px' }} alt="icon" />
                                    </ListItemIcon>
                                }
                                <ListItemText className={styles.listItemStyle} primary={
                                    option[option_key_name]} />
                            </ListItemButton>
                        </>
                ))}
            </div>
        </Drawer>
    );
});

export default CustomDrawer;

;
