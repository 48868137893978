import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { forwardRef, useContext, useRef, useState } from 'react'
import styles from '../../../../assets/styles/componentStyles/insuranceCard.module.scss'
import logo from '../../../assests/icons/Insurance-Logo.svg'
import Slider from 'react-slick'
import { useSearchParams } from 'react-router-dom'
import { convertKeysToLowerCase } from '../../../../utils/constants'


const InsuranceCard = (props: any) => {

    let data = { ...props.data }
    // console.log("data", data)
    data.index = 0



    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    padding: '3px 3px 2px',
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.user_msg_bg_color : '#F7E9FF',
                    borderRadius: '50%',
                    boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
                }}
                onClick={onClick}
            />
        );
    }
    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.user_msg_bg_color : '#F7E9FF',
                    padding: '3px 3px 2px',
                    borderRadius: '50%',
                    boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
                }}

                onClick={onClick}
            />
        );
    }
    const [visibleBlocks, setVisibleBlocks] = useState([data.message_fields.cards.slice(0, convertKeysToLowerCase(data)?.showmore?.initialcards ?? 1)]);
    const [currentIndex, setCurrentIndex] = useState(convertKeysToLowerCase(data)?.showmore?.initialcards ?? 1);
    const [searchParams] = useSearchParams()

    const handleViewMore = () => {
        const nextIndex = currentIndex + convertKeysToLowerCase(data).showmore.cardsnexttime;
        const nextCards = data.message_fields.cards?.slice(currentIndex, nextIndex);
        setVisibleBlocks([...visibleBlocks, nextCards]);
        setCurrentIndex(nextIndex);
    };
    const isScreenBelow1000 = useMediaQuery('(max-width : 1200px) and (min-width: 0px)') || searchParams.get('screensize')
    const sliderRef = useRef(null);

    const goToSlide = (index: number) => {
        if (sliderRef.current) {
            // @ts-ignore
            sliderRef.current.slickGoTo(index);
        }
    };
    const renderCard = (card: any, index: any) => (
        <>
            {data.message_format_id == 6.1 ?
                <Grid item key={index} className={styles.ic_card_main_item} style={{ maxWidth: "100%" }} sx={{ cursor: "pointer" }} onClick={() => { goToSlide(index) }}>
                    <Grid container>
                        <Grid item xs={12} className={styles.ic_card_sub_item} sx={{ background: props.chatbotTheme?.system_msg_bg_color ? props.chatbotTheme?.system_msg_bg_color : "rgba(250, 240, 255, 1)" }}>
                            <Grid container>
                                <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                                    <Grid container sx={{ alignItems: 'center', flexWrap: "nowrap", gap: "5px", minHeight: "48px" }}>
                                        <Grid item>
                                            <img
                                                src={card.data.field1.field_icon}
                                                alt=""
                                                className={styles.ic_main_logo}
                                                style={{
                                                    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ ml: '5px' }}>
                                            <Typography component='span' className={styles.ic_head_label}>
                                                {card.data.field1.field_value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ margin: '8px 0px 0px 0px' }}>
                                    <Typography component='span' className={styles.ic_plan_label}>
                                        {card.data.field2.field_name}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ margin: '0px 0px 8px 0px' }}>
                                    <Typography component='span' className={styles.ic_plan_price_label}>
                                        {card.data.field2.field_value}
                                    </Typography>
                                </Grid>

                                <Divider sx={{ width: '100%', borderColor: '#9D9D9D' }} />

                                <Grid item xs={12} className={styles.ic_card_details_main_item}>
                                    <Grid container>
                                        {Object.values(card.data)?.slice(2).map((field: any) => {
                                            return <>
                                                <Grid item xs={12} className={styles.ic_component_fields}>
                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Grid item className={styles.ic_logo_item}
                                                            style={{
                                                                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                                                                background: "#fff",
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "50%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <img
                                                                src={field.field_icon}
                                                                style={{ objectFit: "cover", borderRadius: "50%", width: "25px" }}
                                                                alt=""
                                                            />
                                                        </Grid>
                                                        <Grid item className={styles.ic_details_label_item}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Typography component='span' sx={{ fontSize: '14px', fontWeight: '500', color: '#7A7A7A' }}>
                                                                        {field.field_name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography component='span' sx={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>
                                                                        {field.field_value}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        })
                                        }
                                    </Grid>
                                </Grid>

                                <Divider sx={{ width: '100%', borderColor: '#9D9D9D' }} />

                                <Grid item xs={12} sx={{ margin: "15px 0px 0px" }}>
                                    <Grid container gap="7px" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        {convertKeysToLowerCase(card)?.cta_buttons && convertKeysToLowerCase(card).cta_buttons.map((button: any) => {
                                            return <>
                                                <Grid item >
                                                    <Button
                                                        variant="outlined"
                                                        size='medium'
                                                        fullWidth
                                                        className={styles.ic_details_btn}
                                                        // onClick={() => { handleActionButton(button) }}
                                                        sx={{
                                                            backgroundColor: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                            color: props.chatbotTheme?.chatbot_back_ground_color ? props.chatbotTheme?.chatbot_back_ground_color : "white",
                                                            '&:hover': {
                                                                backgroundColor: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                            }
                                                        }}
                                                    // sx={{
                                                    //     backgroundColor: "transparent",
                                                    //     color: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    //     borderColor: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    //     borderWidth: '1px'
                                                    // }}
                                                    >
                                                        {button.message_fields?.url_text}
                                                    </Button>
                                                </Grid>
                                            </>
                                        })
                                        }
                                        {/* <Grid item xs={8}>
                                            <Button
                                                variant="contained"
                                                size='medium'
                                                fullWidth
                                                className='ic-details-btn'
                                                onClick={() => { handleActionButton(card.CTA_buttons[1]) }}
                                                sx={{
                                                    backgroundColor: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    color: props.chatbotTheme?.chatbot_back_ground_color ? props.chatbotTheme?.chatbot_back_ground_color : "white",
                                                    '&:hover': {
                                                        backgroundColor: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                                    }
                                                }}
                                            >
                                                {card.CTA_buttons[1].message_fields.url_text}
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                :
                data.message_format_id == 6.2 ?
                    <Grid item key={index} className={styles.ic_card_main_item} sx={{ cursor: "pointer" }} onClick={() => { goToSlide(index) }} justifySelf={"end"}>
                        <Grid container>
                            <Grid item xs={12} className={styles.ic_card_sub_new_item}
                                sx={{
                                    // maxHeight: "380px",
                                    padding: "15px 0px 15px 0px",
                                    background: props.chatbot_theme?.system_msg_bg_color ? props.chatbot_theme?.system_msg_bg_color : "#fff",
                                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important",
                                    borderRadius: "0px 10px 10px 10px !important",
                                }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ margin: '0px 0px 15px 12px' }}>
                                        <Grid container sx={{ alignItems: 'center', flexWrap: "nowrap" }}>
                                            <Grid item sx={{ ml: '5px' }}>
                                                <Typography component='span' className={styles.ic_head_label}>
                                                    {card.data.heading}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ width: '100%', borderColor: '#E2E2E2' }} />
                                    {card?.image_url &&
                                        <Grid item>
                                            <img alt="" style={{ maxWidth: "100%" }} src={card?.image_url} />
                                        </Grid>
                                    }

                                    <Grid item xs={12} sx={{ paddingRight: "15px !important", overflowY: "scroll", maxHeight: "300px", marginTop: "5px" }} className={styles.ic_card_details_main_new_item}>
                                        <Grid container>
                                            {Object.values(card.data).slice(1).map((field: any) => {
                                                return <>
                                                    <Grid item xs={12} sx={{ margin: "2px 0px 12px 15px" }}>
                                                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Grid item className={styles.ic_logo_item}
                                                                style={{
                                                                    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                                                                    background: "#fff",
                                                                    width: "34px",
                                                                    height: "40px",
                                                                    borderRadius: "50%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <img
                                                                    src={field.field_icon}
                                                                    style={{
                                                                        objectFit: "cover",
                                                                        // borderRadius: "50%", 
                                                                        width: "25px"
                                                                    }}
                                                                    alt=""
                                                                />
                                                            </Grid>
                                                            <Grid item className={styles.ic_details_label_item}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Typography component='span' sx={{ fontSize: '14px', fontWeight: '500', color: '#7A7A7A' }}>
                                                                            {field.field_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography component='span' sx={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>
                                                                            {field.flag_multiple_value ?
                                                                                <Grid container direction="column">
                                                                                    <ul className={styles.insurance_Card_bullet_points}>
                                                                                        {field.field_value.map((ele: any) => {
                                                                                            return <Grid item><li>{ele}</li></Grid>
                                                                                        })
                                                                                        }
                                                                                    </ul>
                                                                                </Grid>
                                                                                :
                                                                                field.field_value
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            })
                                            }


                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                :
                    <Grid item key={index} className={styles.ic_card_main_item} sx={{ cursor: "pointer" }} onClick={() => { goToSlide(index) }}>
                        <Grid container>
                            <Grid item xs={12} className={styles.ic_card_sub_item}
                                sx={{
                                    maxHeight: "364px",
                                    padding: "15px 0px 15px 0px !important",
                                    marginBottom: "5px",
                                    background: props.chatbot_theme?.system_msg_bg_color ? props.chatbot_theme?.system_msg_bg_color : "#fff",
                                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important",
                                    borderRadius: "0px 10px 10px 10px !important"
                                }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ marginBottom: '15px', paddingLeft: "15px" }}>
                                        <Grid container sx={{ alignItems: 'center', flexWrap: "nowrap" }}>
                                            <Grid item sx={{ ml: '5px' }}>
                                                <Typography component='span' className={styles.ic_head_label}>
                                                    {card.data.heading}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ width: '100%', borderColor: '#E2E2E2' }} />

                                    <Grid item xs={12} sx={{ paddingRight: "15px !important", overflowY: "scroll", maxHeight: "290px", fontSize: "14px" }} className={styles.ic_card_details_main_item}>
                                        <Grid container>
                                            <ul className={styles.insurance_Card_bullet_points_update}>
                                                {Object.values(card.data).slice(1).map((field: any, fieldIndex) => (
                                                    <Grid item xs={12} key={fieldIndex} sx={{ marginBottom: '10px' }}>
                                                        <li>{field.field_name}</li>
                                                    </Grid>
                                                ))}
                                            </ul>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </>
    );
    return (
        <>
            {convertKeysToLowerCase(data).showmore.flagvertical ?
                <>
                    <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
                        {visibleBlocks.map((block, blockIndex) => (
                            <Grid item key={blockIndex} sx={{alignSelf: "end"}}>
                                {blockIndex === 0 ? (
                                    block.map((card: any, index: number) => renderCard(card, index))
                                ) : (
                                    <div>
                                        <Grid container direction={"column"}>
                                            {block.map((card: any, index: number) => renderCard(card, index))}
                                        </Grid>
                                    </div>
                                )}
                            </Grid>
                        ))}

                        {currentIndex < data.message_fields.cards.length && (
                            <Grid item>
                                <Button
                                    variant='text'
                                    size='medium'
                                    disableElevation={false}
                                    disableRipple
                                    disableTouchRipple
                                    onClick={handleViewMore}
                                    sx={{
                                        color: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                        textTransform: "none",
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        '&:hover': {
                                            boxShadow: "none",
                                        }
                                    }}
                                >
                                    {convertKeysToLowerCase(data)?.showmore?.textforviewmore}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </>
                :
                <>
                    {convertKeysToLowerCase(data).showmore.flagview ?

                        <Grid container direction="column" sx={{ flexWrap: "nowrap", justifyContent: "start" }}>
                            {visibleBlocks.map((block, blockIndex) => (
                                <Grid item key={blockIndex}>
                                    {blockIndex === 0 && convertKeysToLowerCase(data)?.showmore?.initialcards === 1 ? (
                                        block.map((card: any, index: number) => renderCard(card, index))
                                    ) : (
                                        <div>
                                            <Slider
                                                ref={sliderRef}
                                                swipe={isScreenBelow1000 ? true : false}
                                                arrows={true}
                                                dots={false}
                                                infinite={false}
                                                autoplay={false}
                                                variableWidth={true}
                                                className={styles.chatbot_click_slider}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                nextArrow={<SampleNextArrow
                                                    goToSlide={goToSlide}
                                                // className={"chat-slider-next-arrow"}
                                                />}
                                                prevArrow={<SamplePrevArrow
                                                // className={"chat-slider-prev-arrow"} 

                                                />}
                                            >
                                                {block.map((card: any, index: number) => renderCard(card, index))}
                                            </Slider>
                                        </div>
                                    )}
                                </Grid>
                            ))}

                            {currentIndex < data.message_fields.cards.length && (
                                <Grid item>
                                    <Button
                                        variant='text'
                                        size='medium'
                                        disableElevation={false}
                                        disableRipple
                                        disableTouchRipple
                                        onClick={handleViewMore}
                                        sx={{
                                            color: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6a097d",
                                            textTransform: "none",
                                            fontSize: '15px',
                                            fontWeight: '600',
                                            '&:hover': {
                                                boxShadow: "none",
                                            }
                                        }}
                                    >
                                        {convertKeysToLowerCase(data)?.showmore?.textforviewmore}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        :
                        <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
                            <Slider
                                swipe={isScreenBelow1000 ? true : false}
                                arrows={true}
                                dots={false}
                                infinite={false}
                                autoplay={false}
                                variableWidth={true}
                                className={styles.chatbot_click_slider}
                                slidesToShow={1}
                                slidesToScroll={1}
                                nextArrow={<SampleNextArrow className={styles.chat_slider_next_arrow} />}
                                prevArrow={<SamplePrevArrow className={styles.chat_slider_prev_arrow} />}
                            >
                                {convertKeysToLowerCase(data).message_fields.cards.map((card: any, index: number) => renderCard(card, index))}
                            </Slider>
                        </Grid>}
                </>

            }
        </>
    )
}

export default InsuranceCard