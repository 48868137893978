import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react';
import style from "../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignStyle.module.scss";
import contactStyle from '../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss'
import { campaignContact, campaignContactData, getCampaignContactListResponse } from '../../../models/axiosModel';
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';


interface ContactListTableProps {
    selectedContacts: campaignContact[],
    selectedContactList: getCampaignContactListResponse,
}
const ContactListTable: React.FC<ContactListTableProps> = ({ selectedContacts, selectedContactList }) => {

    const sortFields = (fields: campaignContactData[]) => {
        return fields.sort((a, b) => a.contact_field_id - b.contact_field_id);
    }

    const innerHeight = (window.innerHeight - 280);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}>{selectedContactList.contact_list_name} Contact List</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item sx={{ width: "100%" }}>
                            <Grid container className={style.popupBodyContainer}>
                                {selectedContacts && selectedContacts.length > 0 &&
                                    <TableContainer sx={{ borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", boxSizing: "border-box", maxHeight: `${innerHeight}px !important` }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {sortFields(selectedContacts[0].contact_data).map((contact) => {
                                                        return (
                                                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>{contact.contact_field_name}</TableCell>
                                                        );
                                                    })}
                                                    <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Tags</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedContacts && selectedContacts.length > 0 ?
                                                    selectedContacts.map((contact) => {
                                                        return (
                                                            <TableRow className={dashboardStyles.cellRow}>
                                                                {sortFields(contact.contact_data).map((field) => {
                                                                    return (
                                                                        <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{field.contact_data_value.length > 0 ? field.contact_data_value : "-"}</TableCell>
                                                                    );
                                                                })}
                                                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>
                                                                    <Grid item>
                                                                        <Grid container gap={1} flexWrap={"nowrap"}>
                                                                            {contact.contact_tag_assignment.length > 0 ?
                                                                                contact.contact_tag_assignment.map((tag) => {
                                                                                    return (
                                                                                        <Grid item className={contactStyle.tagContainerGrid}>
                                                                                            <Typography className={contactStyle.tagNameTypography}>{tag.contact_tag_name}</Typography>
                                                                                        </Grid>
                                                                                    );
                                                                                })
                                                                                :
                                                                                "-"
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    :
                                                    <TableRow className={dashboardStyles.cellRow}>
                                                        <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                                            No Data Available
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactListTable
