import React, { useContext, useEffect, useState } from 'react'
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import agent from '../../../services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import ConfigTopSection from './configTopSection';
import ConfigIconSection from './configIconSection';
import SelectFieldsPopup from './selectFieldsPopup';
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';
import { initialSlackConfigPayload } from './leadGenerationConstants';
import SlackConfigForm from './slackConfigForm';

interface masterValueInterface {
    id: number,
    value: string
}

const SlackConfig = () => {
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;
    const { globalIntegrationTypeList, slackIntegrations, setSlackIntegrations } = useContext(IntegrationContext) as IntegrationContextPayload;
    const navigate = useNavigate();
    const {slackConfigPayload, setSlackConfigPayload, allowedFieldsSlackData, setAllowedFieldsSlackData, tempLeadGenerationPayload, leadIntegrationDataType, setAddIntegrationPopup, 
        leadGenerationList, setEditIntegrationData, setStepContainer, GetLeadGenerationList} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload;
    const [masterValues, setMasterValues] = useState<masterValueInterface[] | []>([]);
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');
    const lead_id = searchParams.get('lead_id');
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 750px)');

    const innerHeight = flagMobileScreen ? (window.innerHeight - 230) : flagSmallScreen ? (window.innerHeight - 240) : (window.innerHeight - 220);

    const convertSimplifiedToOriginalFormat = (simplifiedString: string) => {
        const regex = /\{\{(.*?)\}\}/g;

        let uniquePlaceholders: string[] = [];
        const originalString = simplifiedString.replace(regex, (match, value) => {
            if (!uniquePlaceholders.includes(value)) {
                uniquePlaceholders.push(value);
            }

            // const id = uniquePlaceholders.indexOf(value);
            const id = allowedFieldsSlackData?.find((data) => data.chatbot_lead_field_name === value)?.chatbot_lead_field_id?.toString();
            return `[[{"id":"${id}","value":"${value}","prefix":"@"}]]`;
        });

        return originalString;
    };

    const [customTemplatePromptActionSlackData, setCustomTemplatePromptActionSlackData] = useState<String>(convertSimplifiedToOriginalFormat(slackConfigPayload.slack_content));

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            const response = await agent.getSlackintegrations.get();
            if (response.status) {
                setSlackIntegrations(response.data)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false })
            }
            else {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false })
            }
        })()
    }, [])

    const pollForCallbackStatus = async (stateValue: string) => {
        const pollInterval = 8000;
        const maxPollingTime = 80000;
        let elapsedTime = 0;

        const intervalId = setInterval(async () => {
            try {
                const response = await agent.getCallbackStatus.get(stateValue);

                if (response.status) {
                    const integrationStatus = response?.data?.integration_status?.toLowerCase();

                    if (integrationStatus === 'completed' || integrationStatus === 'failed') {
                        clearInterval(intervalId);

                        if (integrationStatus === 'failed') {
                            setError((prevState) => ({ ...prevState, flagShowError: true, message: response?.data?.reason }))

                        } else {
                            const response1 = await agent.getSlackintegrations.get();
                            if (response1.status) {
                                if (slackIntegrations) {
                                    setSlackIntegrations(response1.data)
                                }
                                setSuccess({...success, message: "Slack Account Integrated Successfully."})
                            }
                            // navigate(`viewIntegration/slack?id=${response?.data.integration_details.slack.id}`)
                        }
                    } else {
                        console.log("Callback not completed, retrying...");
                    }

                    elapsedTime += pollInterval;

                    if (elapsedTime >= maxPollingTime) {
                        console.log("Polling stopped after 1 minute.");
                        clearInterval(intervalId);
                    }
                } else {
                    let errorCode = checkForErrorCode(response.data)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            } catch (error) {
                console.error("Error polling for callback status:", error);
                clearInterval(intervalId);
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }, pollInterval);
    };

    async function handleIntegrateSlack() {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
            let slackIntegration = globalIntegrationTypeList.find((integration) => integration.integration_app_name == "Slack")
            let currentUrl = window.location.href;
            if (slackIntegration && slackIntegration.integration_app_id) {
                const payload = {
                    current_url: currentUrl,
                    integration_app_id: slackIntegration.integration_app_id,
                };

                const response = await agent.calendlyAuthUrlApi.post(payload);
                if (response.status) {
                    const authUrl = response?.data?.auth_url;
                    if (authUrl) {
                        window.open(response.data.auth_url, "_blank")
                        const stateValue = new URL(authUrl).searchParams.get('state');
                        if (stateValue) {
                            pollForCallbackStatus(stateValue);
                        } else {
                            console.error('State param not found in the auth_url.');
                        }
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    
    useEffect(() => {
        const payload = allowedFieldsSlackData ? allowedFieldsSlackData.map((data) => ({
            id: data.chatbot_lead_field_id,
            value: data.chatbot_lead_field_name
        })) : [];
        //@ts-ignore
        setMasterValues(payload);
    }, [allowedFieldsSlackData]);

    const handleChangeSlackConfigPayload = (field: string, value: string | number) => {
        setSlackConfigPayload((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }

    const handleSaveSlackConfig = async (flagUpdate?: boolean) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let tempFormattedPromptEmailData = customTemplatePromptActionSlackData


        const regex = /{"id":"\d+","value":"(.*?)","prefix":"@"}|{"id":\d+,"value":"(.*?)","prefix":"@"}/g;
        let formattedPromptActionData = tempFormattedPromptEmailData.replace(regex, (_, value1, value2) => {
            const value = value1 || value2;
            return `${value}`;
        }).replace(/\[\[/g, '{{').replace(/\]\]/g, '}}');

        const payload = { ...slackConfigPayload }
        payload.slack_config_id =
            flagUpdate &&
            leadGenerationList?.chatbot_lead_data?.[0]?.chatbot_lead_integration_data?.find((data) =>
                data.chatbot_lead_integration_type_name?.toLowerCase() === "slack"
            )?.chatbot_lead_integration_additional_data?.slack_config_id
            || payload.slack_config_id;
        payload.slack_content = formattedPromptActionData;

        const tempPayload = {
            chatbot_lead_integration_id: flagUpdate && leadGenerationList?.chatbot_lead_data?.[0]?.chatbot_lead_integration_data?.find((data) =>
                data.chatbot_lead_integration_type_name?.toLowerCase() === "slack"
            )?.chatbot_lead_integration_id || null,

            chatbot_lead_id: tempLeadGenerationPayload.chatbot_lead_id || 0,

            chatbot_lead_integration_type_id: leadIntegrationDataType?.find((data) => data.lead_integration_type_name.toLowerCase() === "slack")?.lead_integration_type_id || 0,

            lead_integration_allowed_field_data: allowedFieldsSlackData?.map((field) => ({
                lead_integration_allowed_field_id: flagUpdate && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.filter((data) =>
                    data.chatbot_lead_integration_type_name?.toLowerCase() === "slack")[0].allowed_field_data.find((data) => data.chatbot_lead_field_name === field.chatbot_lead_field_name)?.lead_integration_allowed_field_id || null,
                chatbot_lead_field_id: Number(field.chatbot_lead_field_id)
            })) || [],
            chatbot_lead_integration_additional_data: payload,
        }
        const response = await agent.manageLeadGeneration.post(tempPayload);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: flagUpdate ? "Lead Updated Successfully" : "Lead Integrated Successfully!" });
            setEditIntegrationData(false);
            setAddIntegrationPopup(false);
            setStepContainer(1);
            if (!flagUpdate) {
                navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`)
            }
            if (flagUpdate) {
                GetLeadGenerationList(chatbot_id || "", lead_id || "")
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        else {
            setError({
                ...error,
                flagShowError: true,
                message: response.error.message
            })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        return response;
    }
    
    const isSlackConfigDisabled = () => {
        if(JSON.stringify(slackConfigPayload) === JSON.stringify(initialSlackConfigPayload) || slackConfigPayload.slack_integration_id === null || slackConfigPayload.slack_channel_id === "" || customTemplatePromptActionSlackData?.trim() === "" || allowedFieldsSlackData?.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleCancelSlackConfig = () => {
        setSlackConfigPayload(initialSlackConfigPayload);
        setCustomTemplatePromptActionSlackData(convertSimplifiedToOriginalFormat(slackConfigPayload.slack_content))
        setAllowedFieldsSlackData(leadGenerationList?.chatbot_lead_data[0]?.chatbot_lead_field_data || null)
    }

    return (
        <Grid item sx={{padding: (window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/slack" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet") ? "0px 25px 0px 27px" : ""}}>
            <Grid container direction={"column"}>
                <Grid item padding={"4px"}>

                    <Grid container direction={"column"} gap={"30px"} className={styles.newLeadDataStyle}>
                        {(window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/slack" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration/spreadsheet") &&
                            <Grid item>
                                <ConfigTopSection handleSave={handleSaveSlackConfig} isDisable={isSlackConfigDisabled} handleCancel={handleCancelSlackConfig}/>
                            </Grid>
                        }

                        <Grid item className={styles.selectFieldCardStyle} sx={{height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto"}}>
                            <Grid container gap={2}>
                                <Grid item sx={{width: "100%"}}>
                                    <ConfigIconSection 
                                        integrationName='Slack' 
                                        isDisable={isSlackConfigDisabled} 
                                        handleSave={handleSaveSlackConfig}
                                        convertSimplifiedToOriginalFormat={convertSimplifiedToOriginalFormat}
                                        setCustomTemplatePromptActionData={setCustomTemplatePromptActionSlackData}
                                        newIntegration={handleIntegrateSlack}
                                    />
                                </Grid>
                                <Grid item sx={{width: "100%"}}>
                                    <SelectFieldsPopup integrationName='Slack' customTemplatePromptActionData={customTemplatePromptActionSlackData} setCustomTemplatePromptActionData={setCustomTemplatePromptActionSlackData}/>
                                </Grid>
                                <Grid item sx={{width: "100%"}}>
                                    <Divider className={styles.popupDividerStyle} />
                                </Grid>
                                <Grid item sx={{width: "100%"}} mt={flagSmallScreen ? "4px" : 2}>
                                    <SlackConfigForm 
                                        customTemplatePromptActionData={customTemplatePromptActionSlackData} 
                                        setCustomTemplatePromptActionData={setCustomTemplatePromptActionSlackData} 
                                        handleChangeSlackConfigPayload={handleChangeSlackConfigPayload}
                                        masterValues={masterValues}
                                        setMasterValues={setMasterValues}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default SlackConfig
