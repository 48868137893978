import { Breadcrumbs, Divider, Grid, Link, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import whatsAppIcon from "../../../assets/Whatsapp.svg";
import { UserContext, UserContextPayload } from '../../../context/userContext';
import GlobalButton from '../../utility/globalButton/button';
import ChatbotWhatsAppIntegrationSetup from '../chatbot/chatbotWhatsAppIntegrationSetup';
import IconsColor from '../../utility/iconsColor';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import IntegrationDetails from './integrationDetails';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import agent from '../../../services/api';
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';
import { createMetaIntegrationPayload, globalIntegration } from '../../../models/axiosModel';
import businessIcon from '../../../assets/chatbotAssets/whatsappBusiness.svg'

interface checkFlag {
    flagPhone?: boolean
}
const ViewIntegration = (props: checkFlag) => {
    const pathname = window.location.pathname.toLowerCase()
    const [searchParams] = useSearchParams()
    const { getChatbotIntegrationData, showIntegrationDetails, setShowIntegrationDetails, metaCredentials } = useContext(MetaContext) as MetaContextPayload;
    const { integrationDetails, globalIntegrationTypeList, setIntegrationDetails, getIntegrationList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload;
    const [responsePayload, setResponsePayload] = useState({
        response1: "",
        response2: ""
    })
    const [whatsappData, setWhatsappData] = useState({
        waba_id: "",
        phone_number_id: ""
    })

    useLayoutEffect(() => {
        (async () => {
            await getIntegrationList()
        })()
    }, [])
    useEffect(() => {
        if (searchParams.has('id')) {
            if (integrationList && integrationList.length > 0) {
                let id = searchParams.get('id')
                let integration = integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "whatsapp").find((integration: any) => integration.meta_integration_id == id)
                if (integration) {
                    setIntegrationDetails(integration)
                    setShowIntegrationDetails(true)
                } else {
                    navigate('/channels')
                }
            }
        } else {
            setIntegrationDetails(null)
            setShowIntegrationDetails(false)
        }
    }, [integrationList])
    const [codeValue, setCodeValue] = useState({
        code: ""
    })
    const { setError, error, setSuccess, setFlagLoading, userDetail } = useContext(UserContext) as UserContextPayload;
    useEffect(() => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        if (metaCredentials) {
            window.fbAsyncInit = function () {
                let facebookConfig = metaCredentials.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "whatsapp")
                // @ts-ignore
                FB.init(facebookConfig?.fb_init);
                // FB.init({
                //     appId: '1070588974171145',
                //     autoLogAppEvents: true,
                //     xfbml: true,
                //     version: 'v19.0',
                //     cookie: true,
                // });
            };
        }
        // @ts-ignore

    }, [metaCredentials]);
    const navigate = useNavigate()
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)')
    const laptopScreen = useMediaQuery('(max-width : 1600px) and (min-width : 900px)')

    const launchWhatsAppSignup = () => {
        let whatsappConfiguration = metaCredentials?.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "whatsapp")

        // @ts-ignore
        window.FB.login(function (response: any) {
            if (response.authResponse) {
                const accessToken = response.authResponse.accessToken;
                if (response.authResponse.code) {
                    setCodeValue({ ...codeValue, code: response.authResponse.code })
                    setResponsePayload((prevState) => ({ ...prevState, response1: response }))
                }
                // Further actions after successful login
            } else {
                setError({ ...error, flagShowError: true, message: "User cancelled login or did not fully authorize." })
            }
        },
            whatsappConfiguration?.signup_config
            // {
            //     config_id: '770031065005404',
            //     response_type: 'code',
            //     override_default_response_type: true,
            //     extras: {
            //         "sessionInfoVersion": 2
            //     }
            // }
        );
    };
    useEffect(() => {
        const sessionInfoListener = (event: MessageEvent) => {
            if (event.origin !== "https://www.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                // Handle the data from the event
                // console.log("session info data", data);


                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        setWhatsappData({ ...whatsappData, phone_number_id: phone_number_id, waba_id: waba_id })
                        setResponsePayload((prevState) => ({ ...prevState, response2: data }))
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                    }
                }
            } catch (error) {
                // Handle errors or non-JSON messages
            }
        };
        window.addEventListener('message', sessionInfoListener);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, []);

    // useEffect(() => {
    //     if (integrationDetails !== null) {
    //         setShowIntegrationDetails(true)
    //     } else {
    //         setShowIntegrationDetails(false)
    //     }
    // }, [getChatbotIntegrationData])

    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    console.log('User logged out successfully', logoutResponse);
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };

    useEffect(() => {
        (async () => {
            let chatbot_id = searchParams.get('chatbot_id') ?? ""
            if (codeValue.code !== "" && whatsappData.waba_id !== "" && whatsappData.phone_number_id !== "" && Number(chatbot_id) !== -1 && getChatbotIntegrationData == null) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
                let whatsappObj = {
                    waba_id: whatsappData.waba_id,
                    phone_number_id: whatsappData.phone_number_id,
                    code: codeValue.code
                }
                let payload: createMetaIntegrationPayload = {
                    meta_integration_type_id: globalIntegrationTypeList.find((integrationType) => integrationType.meta_integration_type_name.toLowerCase() == "whatsapp")?.meta_integration_type_id ?? Number(process.env.REACT_APP_WHATSAPP_META_INTEGRATION_TYPE_ID),
                    meta_integration_whatsapp_data: whatsappObj,
                    meta_data: JSON.stringify(responsePayload)
                }
                const response = await agent.createMetaIntegration.post(payload);
                if (response.status) {
                    setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
                    let sortedData = response.data.sort((a: globalIntegration, b: globalIntegration) => new Date(b.created_at_timestamp).getTime() - new Date(a.created_at_timestamp).getTime())
                    navigate(`/channels/viewintegration/whatsapp?id=${sortedData[0].meta_integration_id}`)
                    setIntegrationDetails(response)
                    // const response = await GetChatbotIntegrationData(chatbotId, "")
                    // if (response.status) {
                    //     if (response.data && response.data.length > 0) {
                    //         setShowIntegrationDetails(true)
                    //     }
                    // }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (response.message == "canceled") {
                    }
                    else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    setCodeValue({ code: "" });
                    setWhatsappData({ waba_id: "", phone_number_id: "" });
                    setResponsePayload({
                        response1: "",
                        response2: ""
                    })
                    safeLogout();
                }
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

            }
        })()
    }, [codeValue, whatsappData])

    const innerHeight = smallScreen ? (window.innerHeight - 90) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 100) : (window.innerHeight - 110);
    const innerPhoneHeight = smallScreen ? (window.innerHeight - 110) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 140);
    const integrationViewInnerHeight = smallScreen ? (window.innerHeight - 110) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 140);

    return (
        <Grid item 
            className={showIntegrationDetails == false ? `${globalStyles.fullwidth} ${styles.integrationDefaultView}` : pathname.includes('phonenumbers') ? `${globalStyles.fullwidth} ${styles.integrationPhoneView}` : `${globalStyles.fullwidth} ${styles.integrationView}`}
            height={showIntegrationDetails == false ? `${innerHeight}px !important` : pathname.includes('phonenumbers') ? `${innerPhoneHeight}px !important` :`${integrationViewInnerHeight}px !important`}
        >
            <Grid container>
                {showIntegrationDetails == false &&
                    <Grid item className={globalStyles.fullwidth}>
                        <Grid container alignItems={"start"} gap={"13px"} flexWrap={"nowrap"} flexDirection={{ xs: "row-reverse", md: "row" }}>
                            <Grid item className={styles.integrationContent}>
                                <img alt="" src={whatsAppIcon} className={styles.whatsappIntegrationIcon} />
                            </Grid>
                            <Grid item>
                                <Grid container direction={"column"} gap={{ xs: "8px", md: "4px" }}>
                                    <Grid item>
                                        <Typography className={globalStyles.whatsappIntegrationFontStyle}>WhatsApp Integration</Typography>
                                    </Grid>
                                    <Grid item>
                                        <span className={globalStyles.whatsappIntegrationSubFontStyle}>Integrate AI Chatbot into your WhatsApp Business account to boost customer engagement and efficiency.</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {(showIntegrationDetails) ?
                    <>
                        <Grid item className={globalStyles.fullwidth} xs={12} lg={12} xl={12}>
                            <Grid container direction={"row"} gap={3} flexWrap={"wrap"}>
                                <IntegrationDetails flagPhone={props.flagPhone} />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        {showIntegrationDetails == false &&
                            <Grid item className={globalStyles.fullwidth} xs={12} lg={8} xl={6} mt={4}>
                                <Grid container direction={"column"} gap={2} flexWrap={"nowrap"} pt={1} className={styles.integrationWorking}>
                                    <Grid item>
                                        <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                                            <Grid item>
                                                <span className={globalStyles.whatsappIntegrationFontStyle}>Prerequisites for WhatsApp Integration</span>
                                            </Grid>
                                            <Grid item className={styles.workingIconStyle}>
                                                <IconsColor iconName="ideaIcon" height={"30"} width={"30"} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <ChatbotWhatsAppIntegrationSetup launch={launchWhatsAppSignup} viewIntegration={true} />
                                </Grid>
                                <Grid container>
                                    <Grid item className={styles.integrateGridStyles} marginBlock={"20px"}>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <GlobalButton
                                                    buttonText={"Integrate Now"}
                                                    className="primaryButtonStyle"
                                                    onClick={() => { launchWhatsAppSignup() }}
                                                    sx={{ padding: "6px 20px !important" }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default ViewIntegration
