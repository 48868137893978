import { Grid, SwipeableDrawer, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chatbotPreview.module.scss";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
// import QRCode from "../../../assets/images/chatbot/whatsappQrCode.png";
import QRCode from "react-qr-code";
interface ChatbotPreviewSectionInterface {
    flagSmallScreen: boolean,
    openSwipableDrawer: any,
    setOpenSwipableDrawer: any,
    toggleDrawer: any
}
function WhatsAppIntegrationTabChatbotPreview(props: ChatbotPreviewSectionInterface) {
    const {
        flagSmallScreen,
        openSwipableDrawer,
        setOpenSwipableDrawer,
        toggleDrawer
    } = props
    const { showIntegrationDetails, getChatbotIntegrationData } = useContext(MetaContext) as MetaContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [iframeKey, setIframeKey] = useState(0);
    const reloadIframe = () => {
        setIframeKey(prevKey => prevKey + 1); // Increment key to trigger reload
    };
    useEffect(() => {
        // Call reloadIframe when chatBotDetails changes
        reloadIframe();
    }, [chatBotDetails]);
    const openBlog = () => {
        window.open("https://blog.writewiz.ai/ai-chatbot/sample-chatbot/sample-chatbot-toygenius/", '_blank')
    }
    return (
        <Grid item xs={12} className={styles.cbPreviewMainItem}>
            <Grid container sx={{ position: "relative" }}>

                {flagSmallScreen ?
                    (<>
                        <SwipeableDrawer
                            anchor="right"
                            open={openSwipableDrawer}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            PaperProps={{
                                className: styles.chatbotSwipeableDrawer,
                                style: {
                                    overflow: 'auto',
                                },
                            }}
                        >
                            <Grid container sx={{ padding: "0px" }} className={styles.chatbotPreviewMainContainer}>
                                <Grid item xs={12} className={styles.chatbotPreviewMainItem}>
                                    <Grid container className={styles.chatbotPreviewSubContainer}>
                                        <Grid item xs={11} className={styles.cbPreviewHeadMainItem}>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                {/* <Grid item xs={2}></Grid>
                                                <Grid item xs={8} className={styles.cbPreviewHeadItem}>
                                                    <Typography component={'span'} className={styles.cbPreviewHead}>
                                                        Try it
                                                    </Typography>
                                                </Grid> */}
                                                <Grid item xs={12} sx={{ textAlign: "end", position: "absolute", top: "12px", right: "10px", zIndex: "10000 !important" }}>
                                                    <img
                                                        src={previewIcon}
                                                        style={{ width: "23px", height: "auto", objectFit: "fill", verticalAlign: "middle", transform: "rotate(180deg)" }}
                                                        alt=""
                                                        onClick={() => { setOpenSwipableDrawer(false) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={11} className={styles.cbChatBtnIconItem}>
                                            <Grid container className={styles.cbChatBtnIconContainer}>
                                                <Grid item >
                                                    {showIntegrationDetails ?
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            {chatBotDetails?.chatbot_id == (process.env.REACT_APP_TOY_GENIUS_CHATBOT_ID) &&
                                                                <>
                                                                    <a
                                                                        style={{
                                                                            //  position: "absolute", right: "13px", top: "10px", 
                                                                            fontSize: "14px", fontWeight: "600"
                                                                        }} target="_blank"
                                                                        rel="noreferrer"
                                                                        href="https://blog.writewiz.ai/ai-chatbot/sample-chatbot/sample-chatbot-toygenius/">Know more about this chatbot</a>
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {/* <img src={chat} alt="" className={styles.cbChatIconsitem} /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item className={styles.cbPreviewIframeItem}>
                                            {(showIntegrationDetails && getChatbotIntegrationData !== null && getChatbotIntegrationData.length > 0) ?
                                                <>
                                                    {/* <a href={`https://wa.me/%2B${getChatbotIntegrationData[0]?.meta_integration_phone_number_data.find((phoneNumber) => phoneNumber.chatbot_integration_assignment_data.length > 0).display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} target='_blank'
                                                        rel="noreferrer"
                                                        style={{ display: "flex", justifyContent: "center" }}>
                                                        <QRCode value={`https://wa.me/%2B${getChatbotIntegrationData[0].meta_integration_phone_number_data.find((phoneNumber) => phoneNumber.chatbot_integration_assignment_data.length > 0).display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} />
                                                    </a> */}
                                                </>
                                                :
                                                <div
                                                    className={styles.cbPreviewIframeParentDiv}
                                                    dangerouslySetInnerHTML={{ __html: chatBotDetails?.chatbot_iframe ? chatBotDetails.chatbot_iframe : "" }}
                                                />}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SwipeableDrawer>
                    </>)
                    :

                    (
                        <>
                            {showIntegrationDetails && 
                            <Grid item xs={11} className={styles.cbPreviewHeadMainItem}>
                                <Grid container className={styles.cbPreviewIframeItem} sx={{ alignItems: "center", position: "relative" }} justifyContent={"space-between"}>
                                    <Grid
                                        // item xs={8} 
                                        sx={{ alignItems: "center", display: "flex", justifyContent: "center", width: "100%" }}
                                        className={styles.cbPreviewHeadItem}>
                                        <Typography component={'span'} className={styles.cbPreviewHead}>
                                            {showIntegrationDetails ? "Start Chat in WhatsApp" : "Try it"}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ position: "absolute", right: "0", bottom: "-32px" }}>
                                        {showIntegrationDetails ?
                                            <></>
                                            :
                                            <>
                                                {chatBotDetails?.chatbot_id == (process.env.REACT_APP_TOY_GENIUS_CHATBOT_ID) &&
                                                    <a

                                                        style={{
                                                            //  position: "absolute", right: "13px", top: "10px", 
                                                            fontSize: "14px", fontWeight: "600"
                                                        }} target="_blank"
                                                        rel="noreferrer"
                                                        href="https://blog.writewiz.ai/ai-chatbot/sample-chatbot/sample-chatbot-toygenius/">Know more about this chatbot</a>}
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            }

                            <Grid item className={styles.cbPreviewIframeItem} sx={{ display: showIntegrationDetails ? "flex" : "", alignItems: showIntegrationDetails ? "center" : "", justifyContent: showIntegrationDetails ? "center" : "" }}>
                                {(showIntegrationDetails && getChatbotIntegrationData !== null && getChatbotIntegrationData.length > 0) ?
                                    <>
                                        {/* <a href={`https://wa.me/%2B${getChatbotIntegrationData[0]?.meta_integration_phone_number_data.find((phoneNumber) => phoneNumber.chatbot_integration_assignment_data.length > 0).display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} target='_blank'
                                            rel="noreferrer"
                                            style={{ display: "flex", justifyContent: "center" }}>
                                            <QRCode value={`https://wa.me/%2B${getChatbotIntegrationData[0].meta_integration_phone_number_data.find((phoneNumber) => phoneNumber.chatbot_integration_assignment_data.length > 0).display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} />
                                        </a> */}
                                    </>
                                    :

                                    <div
                                        key={iframeKey}
                                        // style={{ minHeight: "calc(100vh - 320px)" }}
                                        style={{ minHeight: "calc(100vh - 220px) !important" }}
                                        className={styles.cbPreviewIframeParentDiv}
                                        dangerouslySetInnerHTML={{ __html: chatBotDetails?.chatbot_iframe ? chatBotDetails.chatbot_iframe : "" }}
                                    />}
                            </Grid>

                        </>
                    )
                }



            </Grid>
        </Grid>
    )
}

export default WhatsAppIntegrationTabChatbotPreview