import React, { useContext, useState } from 'react'
import AccountInformationCard from '../../utility/accountInformationCard';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { metaIntegrationPhoneNumberDataModel } from '../../../models/axiosModel';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { Grid } from '@mui/material';


interface checkFlag {
    flagPhone?: boolean
}
function IntegrationDetails(props: checkFlag) {
    const navigate = useNavigate()
    const { setSuccess, setError, userDetail, setFlagLoading } = useContext(UserContext) as UserContextPayload
    const { integrationDetails } = useContext(IntegrationContext) as IntegrationContextPayload
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const deleteMetaIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.deleteMetaIntegration.delete(integrationDetails.meta_integration_id)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration deleted Successfully!" }))
            navigate('/channels')
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <>
            {integrationDetails !== null && !props.flagPhone &&
                <AccountInformationCard
                    key={integrationDetails.meta_integration_whatsapp_data.meta_integration_whatsapp_id}
                    flagDelete={true}
                    title={"Meta"}
                    flagShowMetaIcon={true}
                    currentlySelected={false}
                    details={{
                        waba_id: integrationDetails.meta_integration_whatsapp_data.waba_id,
                        verified_name: integrationDetails.meta_integration_whatsapp_data.meta_integration_phone_number_data.length > 0 ? integrationDetails.meta_integration_whatsapp_data.meta_integration_phone_number_data[0].verified_name : "Testing",
                        // meta_business_id: "419089917245626"
                    }}
                    deleteChatbotIntegration={() => { setShowDeleteConfirmation(true) }}
                />
            }
            {integrationDetails !== null && props.flagPhone && integrationDetails.meta_integration_whatsapp_data.meta_integration_phone_number_data.length > 0 && integrationDetails.meta_integration_whatsapp_data.meta_integration_phone_number_data.map((integration: metaIntegrationPhoneNumberDataModel, index: number) => {
                return <Grid item>
                    <AccountInformationCard
                        flagShowLinkedChatbot
                        key={index}
                        flagDelete={false}
                        title={`WhatsApp Account ${index + 1}`}
                        currentlySelected={integration.chatbot_integration_assignment_data.length > 0}
                        // currentlySelected={false}
                        details={{
                            display_phone_number: integration.display_phone_number,
                            quality_rating: integration.quality_rating,
                            phone_number_id: integration.phone_number_id,
                            linked_chatbots: integration.chatbot_integration_assignment_data,
                            business_name: integration.verified_name
                        }} />
                </Grid>
            })}
            {showDeleteConfirmation &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure you want to delete this Integration`}
                    open={showDeleteConfirmation}
                    onClose={() => { setShowDeleteConfirmation(false) }}
                    onDelete={(e: any) => {
                        deleteMetaIntegration()
                        setShowDeleteConfirmation(false)
                    }}
                />
            }
        </>

    )
}

export default IntegrationDetails