import React, { useContext, useState } from 'react'
import { callRecordsInterface } from '../../../models/axiosModel'
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getRatingColor } from './callAnalysisConstants'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { formatCallAnalysisTimeAccordingToTimezoneUsingMoment } from '../../../utils/constants'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { CallAnalysisContext, CallAnalysisContextPayload } from '../../../context/callAnalysisContext'

const CallAnalysisScoreList = () => {
    const {userDetail} = useContext(UserContext) as UserContextPayload;
    const {callRecordsDetails, setViewRecordDetail, GetCallRecordDetailApi, page, setPage} = useContext(CallAnalysisContext) as CallAnalysisContextPayload

    const handleSelectAudio = (record: callRecordsInterface) => {
        GetCallRecordDetailApi(record.call_record_id);
        setViewRecordDetail(true);
    }

    const recordsPerPage = 100;
    const startIndex = page * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const currentRecords = callRecordsDetails?.slice(startIndex, endIndex);

    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "calc(100vh - 250px)"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Score</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>File Name</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Date</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Agent</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Complexity</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Category</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Sub Category</TableCell>
                        <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${dashboardStyles.viewButtonColumnHeadingStyle}`}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentRecords?.map((records: callRecordsInterface) => 
                        <>
                            <TableRow className={dashboardStyles.cellRow}>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}><Avatar sx={{ bgcolor: getRatingColor(records.call_record_eval_score), fontSize: "10px", width: "30px", height: "28px" }}>{Math.round(Number(records.call_record_eval_score))}</Avatar></TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{records.call_record_google_storage_file_name}</TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formatCallAnalysisTimeAccordingToTimezoneUsingMoment(records.created_at_timestamp,userDetail.timezone_hour)}</TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{records.agent_name ? records.agent_name : "-"}</TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{records.call_complexity_level}</TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{records.call_parent_category_name ? records.call_parent_category_name : "-"}</TableCell>
                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{records.call_category_name}</TableCell>
                                <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${dashboardStyles.viewButtonColumnStyle}`} sx={{textAlign: "end"}}>
                                    <span className={dashboardStyles.viewButtonStyle} onClick={() => handleSelectAudio(records)}>View</span>
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CallAnalysisScoreList
