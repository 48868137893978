import { Autocomplete, Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import Required from '../utility/required';
import GlobalTextField from '../utility/globalTextfield';
// import globalStyles from "../../../assets/styles/global.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import connectorstyle from '../../assets/styles/componentStyles/dataconnector.module.scss'
import styles from '../../assets/styles/componentStyles/dataconnectorform.module.scss'
import dataConnectorMainstyles from '../../assets/styles/componentStyles/dataconnector.module.scss'
import { Close } from '@mui/icons-material';
import { ChatbotContext, ChatbotContextPayload } from '../../context/chatbotContext';
import persistentMenuStyles from '../../assets/styles/componentStyles/persistentStyles/persistent.module.scss';



const PersistentMenuModal = (props: any) => {

    const {
        handlePersistentMenuDetails,
        requestPersistentMenuDetailsList,
        setRequestPersistentMenuDetailsList,
        handleSavePersistentData,
        setFlagOpenMenuItemDialog,
        flagKeyExistError,
        setFlagKeyExistError,

        flagOpenSubMenuItemDialog,
        setFlagOpenSubMenuItemDialog,

        parentPersistentMenuName,
        selectedActionType,
        setSelectedActionType,
        isActionTypeDisabled,
    } = props

    const getHandleSavePersistentDataDisable = () => {
        if (selectedActionType === "Text") {
            if (requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== null &&
                requestPersistentMenuDetailsList.chatbot_persistent_menu_value !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_value !== null
            ) {
                return false;
            } else { return true; }
        }
        else if (selectedActionType === "Static Message") {
            if (requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== null &&
                requestPersistentMenuDetailsList.static_message_id !== undefined
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            if (requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== null) {
                return false;;
            } else return true;
        }
        // if (
        //     requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_name !== null &&

        //     ((requestPersistentMenuDetailsList.chatbot_persistent_menu_value !== "" && requestPersistentMenuDetailsList.chatbot_persistent_menu_value !== null) ||
        //         (requestPersistentMenuDetailsList.static_message_id !== undefined)
        //     )
        // ) {
        //     return false
        // } else {
        //     return true
        // }
    }

    const { chatbotStaticMessageFormat } = useContext(ChatbotContext) as ChatbotContextPayload

    return (
        <>
            <Grid container className={`${styles.dataConnectorPopupSpacing}`} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <Grid container className={connectorstyle.createFormField} p={1}>
                        <Grid item sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                            <Close
                                style={{ fontSize: '22px', cursor: 'pointer' }}
                                onClick={() => {
                                    setFlagOpenMenuItemDialog(false);
                                    setFlagOpenSubMenuItemDialog(false);
                                    setFlagKeyExistError(false);
                                    setRequestPersistentMenuDetailsList({
                                        ...requestPersistentMenuDetailsList,
                                        chatbot_persistent_menu_id: null,
                                        chatbot_persistent_menu_name: "",
                                        chatbot_persistent_menu_value: "",
                                        static_message_id: undefined,
                                        parent_chatbot_persistent_menu_id: undefined,
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography component='span' sx={{ fontSize: '16px', fontWeight: '600', color: '#6A097D' }}>
                                {flagOpenSubMenuItemDialog ? `Add Sub Menu Item for ${parentPersistentMenuName.chatbot_persistent_menu_name}` : (requestPersistentMenuDetailsList?.chatbot_persistent_menu_name === "") ? "Add Menu Item" : "Edit Menu Item"}
                            </Typography>
                            <Divider style={{ width: "100%", marginTop: '10px' }} />
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '25px 0px 10px' }}>
                            <Grid container direction={"column"}>
                                <Grid item mb={1}>
                                    <InputLabel
                                        className={connectorstyle.formFieldTitle}
                                    >
                                        Name
                                    </InputLabel><Required />
                                </Grid>
                                <Grid item>
                                    <GlobalTextField
                                        placeholder='Enter New Key'
                                        value={requestPersistentMenuDetailsList?.chatbot_persistent_menu_name}
                                        onChange={(e) => {
                                            setFlagKeyExistError(false)
                                            handlePersistentMenuDetails('chatbot_persistent_menu_name', e.target.value)
                                        }}
                                        className={persistentMenuStyles.persistentMenuNameTextField}
                                    />
                                </Grid>
                                {flagKeyExistError &&
                                    <span className={globalStyles.validationText}>
                                        This Menu Name already exist!
                                    </span>
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: "20px 0px" }}>
                            <Grid container direction={"column"}>
                                <Grid item mb={1}>
                                    <InputLabel
                                        className={connectorstyle.formFieldTitle}
                                    >
                                        Action Type
                                    </InputLabel><Required />
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={selectedActionType}
                                        style={{ display: 'flex', flexDirection: "row" }}
                                        className={persistentMenuStyles.actionTypeRadioButton}
                                    >
                                        <FormControlLabel value="Text" control={<Radio color='secondary' />} disabled={isActionTypeDisabled()} label="Text" onChange={(e) => { setSelectedActionType("Text"); }} />
                                        <FormControlLabel value="Static Message" control={<Radio color='secondary' />} disabled={isActionTypeDisabled()} label="Static Message" onChange={(e) => { setSelectedActionType("Static Message"); }} />
                                        <FormControlLabel value="None" control={<Radio color='secondary' />} label="None" onChange={(e) => { setSelectedActionType("None"); }} />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                        {selectedActionType != "None" &&
                            <Grid item xs={12} sx={{ margin: '0px 0px' }}>
                                <Grid container direction={"column"}>
                                    <Grid item mb={1}>
                                        <InputLabel
                                            className={connectorstyle.formFieldTitle}
                                        >
                                            {selectedActionType}
                                        </InputLabel><Required />
                                    </Grid>
                                    <Grid item>
                                        {/* <GlobalTextField
                                            value={requestPersistentMenuDetailsList?.chatbot_persistent_menu_value}
                                            placeholder='Enter Value'
                                            onChange={(e) => {
                                                handlePersistentMenuDetails('chatbot_persistent_menu_value', e.target.value)
                                            }}
                                        /> */}

                                        {selectedActionType === "Text" ?
                                            <>
                                                <textarea
                                                    rows={3}
                                                    placeholder='Enter Value'
                                                    className={persistentMenuStyles.textTextArea}
                                                    value={requestPersistentMenuDetailsList.chatbot_persistent_menu_value}
                                                    onChange={(e) => {
                                                        handlePersistentMenuDetails('chatbot_persistent_menu_value', e.target.value)
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Autocomplete
                                                    options={chatbotStaticMessageFormat}
                                                    getOptionLabel={(option) => option.chatbot_static_message_name}
                                                    value={chatbotStaticMessageFormat.find((format) => format.chatbot_static_message_id === requestPersistentMenuDetailsList.static_message_id)}
                                                    className={`${globalStyles.autocompleteParaphrasing} ${connectorstyle.methodAutoComplete}`}
                                                    onChange={(event, newValue) => { handlePersistentMenuDetails('static_message_id', newValue?.chatbot_static_message_id) }}
                                                    renderInput={(params) => <TextField {...params} placeholder="Select Static Message" />}
                                                />
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12} sx={{ margin: '15px 0px 0px' }}>
                            <Grid container sx={{ justifyContent: 'center', gap: '15px' }}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        fullWidth
                                        className={dataConnectorMainstyles.cbCancelButton}
                                        onClick={() => {
                                            setFlagOpenMenuItemDialog(false);
                                            setFlagOpenSubMenuItemDialog(false);
                                            setFlagKeyExistError(false);
                                            setRequestPersistentMenuDetailsList({
                                                ...requestPersistentMenuDetailsList,
                                                chatbot_persistent_menu_id: null,
                                                chatbot_persistent_menu_name: "",
                                                chatbot_persistent_menu_value: "",
                                                static_message_id: undefined,
                                                parent_chatbot_persistent_menu_id: undefined,
                                            });
                                        }}
                                    // disabled={cancelBtnOnClickDisableCond()}
                                    // sx={{ opacity: cancelBtnOnClickDisableCond() ? "0.5" : "" }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        fullWidth
                                        className={dataConnectorMainstyles.cbSaveButton}
                                        onClick={() => { handleSavePersistentData() }}
                                        disabled={getHandleSavePersistentDataDisable()}
                                        sx={{ opacity: getHandleSavePersistentDataDisable() ? "0.5" : "" }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PersistentMenuModal