import { Autocomplete, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import globalStyles from "../../../assets/styles/global.module.scss";
import style from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import datePickerStyles from "../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss";
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import DatePresets from '../callAnalysis/datePresets';
import { Moment } from 'moment';
import GlobalButton from '../../utility/globalButton/button'; 
import { filterData } from './feedbackGlobal';

export interface FeedbackFilterPopupProps {
    filterData: filterData, 
    setFilterData: React.Dispatch<React.SetStateAction<filterData>>,
    focusedInput: FocusedInputShape | null, 
    setFocusedInput: React.Dispatch<React.SetStateAction<FocusedInputShape | null>>, 
    handleFetchData: () => void, 
    handleResetFilter: () => void, 
    setFlagFeedbackFilterPopup: React.Dispatch<React.SetStateAction<boolean>>,
}

const FeedbackFilterPopup: React.FC<FeedbackFilterPopupProps> = (props) => {

    const { filterData, setFilterData, focusedInput, setFocusedInput, handleFetchData, handleResetFilter, setFlagFeedbackFilterPopup } = props

    const { feedbackStatusData, feedbackKnowledgeBaseStatusData } = useContext(GenericDataContext) as GenericDataContextPayload;
    const dateFormat = "DD/MM/YYYY";

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography sx={{ color: "#6a097d", fontWeight: "600", fontSize: "16px", marginTop: "25px" }}>Feedback Filter</Typography>
                        </Grid>
                        <Divider sx={{ padding: "4px 0 !important", margin: "10px 30px 0 30px !important" }} />

                        <Grid item>
                            <Grid container sx={{ padding: "10px 30px", width: "100%" }} gap={1}>

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                            <Typography sx={{ color: "#000000", fontWeight: "500", fontSize: "14px" }}>
                                                Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginTop: "10px", zIndex: "5", width: "100%" }} className={`${datePickerStyles.dateRangePickerClass} ${style.dateRangePickerClass} ${style.dateRangePickerInPopup}`}>
                                            <DateRangePicker
                                                startDate={filterData.startDate}
                                                startDateId='s_id'
                                                endDate={filterData.endDate}
                                                endDateId='e_id'
                                                onDatesChange={({ startDate, endDate }) => {
                                                    setFilterData((prev) => ({
                                                        ...prev, 
                                                        startDate: startDate, 
                                                        endDate: endDate,
                                                    }))
                                                }}
                                                focusedInput={focusedInput}
                                                displayFormat={dateFormat}
                                                isOutsideRange={() => false}
                                                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                renderCalendarInfo={() => (
                                                    <DatePresets
                                                        startDate={filterData.startDate}
                                                        endDate={filterData.endDate}
                                                        dateFormat={dateFormat}
                                                        handlePresets={(start: Moment, end: Moment) => {
                                                            setFilterData((prev) => ({
                                                                ...prev, 
                                                                startDate: start, 
                                                                endDate: end,
                                                            }))
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                            <Typography sx={{ color: "#000000", fontWeight: "500", fontSize: "14px" }}>
                                                Approval Status
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                            <Autocomplete
                                                options={feedbackStatusData}
                                                value={feedbackStatusData.find((data) => data.feedback_status_id === filterData.selectedApprovalStatus?.feedback_status_id) || null}
                                                getOptionLabel={(option) => option.feedback_status_name}
                                                onChange={(e, value) => {
                                                    setFilterData((prev) => ({
                                                        ...prev, 
                                                        selectedApprovalStatus: value,
                                                    })); 
                                                }}
                                                className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoComplete}`}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                            <Typography sx={{ color: "#000000", fontWeight: "500", fontSize: "14px" }}>
                                                Knowledgebase Status
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                            <Autocomplete
                                                options={feedbackKnowledgeBaseStatusData}
                                                value={feedbackKnowledgeBaseStatusData.find((data) => data.modification_status_id === filterData.selectedKnowledgeBaseStatus?.modification_status_id) || null}
                                                getOptionLabel={(option) => option.modification_status_name}
                                                onChange={(e, value) => { 
                                                    setFilterData((prev) => ({
                                                        ...prev, 
                                                        selectedKnowledgeBaseStatus: value,
                                                    }));
                                                }}
                                                className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoComplete}`}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"} sx={{ padding: "20px 0" }}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Reset"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='borderedButtonStyle'
                                        onClick={() => { setFlagFeedbackFilterPopup(false); handleResetFilter(); }}
                                    >

                                    </GlobalButton>
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Fetch Data"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        onClick={() => { setFlagFeedbackFilterPopup(false); handleFetchData(); }}
                                    >
                                    </GlobalButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FeedbackFilterPopup
