import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CommonResponseSecondaryModel,
    getMetaCredentialsDataResponseModel,
    globalIntegration,
} from "../models/axiosModel";
import agent from "../services/api";

const MetaContext = React.createContext<MetaContextPayload | undefined>(
    undefined
);

export interface MetaContextPayload {
    metaCredentials: null | getMetaCredentialsDataResponseModel[];
    setMetaCredentials: React.Dispatch<React.SetStateAction<getMetaCredentialsDataResponseModel[] | null>>;
    getChatbotIntegrationData: globalIntegration[] | null;
    setGetChatbotIntegrationData: React.Dispatch<React.SetStateAction<globalIntegration[] | null>>;
    GetMetaCredentials: () => Promise<CommonResponseSecondaryModel<getMetaCredentialsDataResponseModel[]>>;
    GetChatbotIntegrationData: (chatbot_id: string, chatbot_integration_id: string) => Promise<CommonResponseModel<globalIntegration>>;
    showIntegrationDetails: boolean | null,
    setShowIntegrationDetails: React.Dispatch<React.SetStateAction<boolean | null>>;

    showFacebookIntegrationDetails: boolean | null,
    setShowFacebookIntegrationDetails: React.Dispatch<React.SetStateAction<boolean | null>>
}

const MetaProvider = ({ children }: ChildrenProps) => {
    const [metaCredentials, setMetaCredentials] = useState<getMetaCredentialsDataResponseModel[] | null>(null);
    const [getChatbotIntegrationData, setGetChatbotIntegrationData] = useState<globalIntegration[] | null>(null)
    const [showIntegrationDetails, setShowIntegrationDetails] = useState<boolean | null>(null)

    const [showFacebookIntegrationDetails, setShowFacebookIntegrationDetails] = useState<boolean | null>(null)

    const GetMetaCredentials = async () => {
        const response: CommonResponseSecondaryModel<getMetaCredentialsDataResponseModel[]> = await agent.getMetaCredentials.get();
        if (response.status && response.data) {
            setMetaCredentials(response.data)
        } else {
            setMetaCredentials(null)
        }
        return response;
    }
    const GetChatbotIntegrationData = async (chatbot_id: string, chatbot_integration_id: string) => {
        const response: CommonResponseModel<globalIntegration> = await agent.getChatbotIntegrationData.get(chatbot_id, chatbot_integration_id);
        if (response.status && response.data) {
            if (response.data.length > 0) {
                setGetChatbotIntegrationData(response.data)
            } else {
                setGetChatbotIntegrationData(null)
            }
        } else {
            setGetChatbotIntegrationData(null)
        }
        return response;
    }

    const payload = {
        metaCredentials,
        setMetaCredentials,
        getChatbotIntegrationData,
        setGetChatbotIntegrationData,
        GetMetaCredentials,
        GetChatbotIntegrationData,
        showIntegrationDetails,
        setShowIntegrationDetails,
        showFacebookIntegrationDetails,
        setShowFacebookIntegrationDetails
    };

    return (
        <MetaContext.Provider value={payload}>{children}</MetaContext.Provider>
    );
};

export { MetaContext, MetaProvider };
