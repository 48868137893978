import { Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import Required from '../../utility/required';
import GlobalButton from '../../utility/globalButton/button';
import { useSearchParams } from 'react-router-dom';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';
import { campaignContact, campaignContactData, createCampaignContactFieldPayload, updateCampaignContactFieldPyload } from '../../../models/axiosModel';

interface EditFieldPopupProps {
    setFlagNewFieldDialog: React.Dispatch<React.SetStateAction<boolean>>,
    createCampaignContactFieldApiCall?: (payload: createCampaignContactFieldPayload) => void,
    updateCampaignContactFieldApiCall?: (payload: updateCampaignContactFieldPyload) => void,
    requiredField?: campaignContactData,
    getListOfCampaignFieldTypeApiCall: () => void,
    flagEdit: boolean,
}

const EditFieldPopup: React.FC<EditFieldPopupProps> = ({ setFlagNewFieldDialog, createCampaignContactFieldApiCall, updateCampaignContactFieldApiCall, getListOfCampaignFieldTypeApiCall, requiredField, flagEdit }) => {

    const { campaignContactFieldTypeData } = useContext(ContactContext) as ContactContextPayload;

    const [fieldName, setFieldName] = useState<string>(requiredField ? requiredField.contact_field_name : "");

    const [searchParams, setSearchParams] = useSearchParams();
    const list_id = searchParams.get('contact_list_id');

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}> {flagEdit ? "Edit Field" : "Add New Field"}</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={style.popupBodyGridContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Field Name <Required /></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={fieldName}
                                                onChange={(e) => { setFieldName(e.target.value) }}
                                                placeholder='Enter Field Name'
                                                className={`${style.campaignContactPopupTextField}`}
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"} sx={{ padding: "20px 0" }}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Cancel"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='borderedButtonStyle'
                                        onClick={() => { setFlagNewFieldDialog(false) }}
                                    />
                                </Grid>

                                <Grid item>
                                    <GlobalButton
                                        buttonText={flagEdit ? "Save" : "Add"}
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        disabled={fieldName.trim().length === 0}
                                        onClick={async () => {
                                            setFlagNewFieldDialog(false);

                                            if (campaignContactFieldTypeData.length === 0) {
                                                await getListOfCampaignFieldTypeApiCall();
                                            }
                                            if (!flagEdit) {
                                                const payload = {
                                                    contact_list_id: Number(list_id),
                                                    contact_field_data: [{
                                                        contact_field_type_id: campaignContactFieldTypeData?.find(
                                                            (type) => type.contact_field_type_name === "Text"
                                                        )?.contact_field_type_id ?? 1,
                                                        contact_field_name: fieldName,
                                                    }]
                                                }
                                                if (createCampaignContactFieldApiCall) createCampaignContactFieldApiCall(payload);
                                            } else {
                                                if (requiredField && updateCampaignContactFieldApiCall) {
                                                    const payload = {
                                                        contact_list_id: Number(list_id),
                                                        contact_field_id: requiredField.contact_field_id,
                                                        contact_field_type_id: campaignContactFieldTypeData?.find(
                                                            (type) => type.contact_field_type_name === "Text"
                                                        )?.contact_field_type_id ?? 1,
                                                        contact_field_name: fieldName,
                                                    }
                                                    updateCampaignContactFieldApiCall(payload);
                                                }
                                            }
                                        }}
                                    >
                                    </GlobalButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default EditFieldPopup
