export function getCTAButtonLimitValidation (message_format_id) {
    switch(message_format_id) {
        case 5:
            return 3;
        default:
            return 1;
    }
}

export const initialStaticMessageFormatPayload = {
    chatbot_id: 0,
    chatbot_static_message_name: "",
    chatbot_static_message_value: "",
    chatbot_static_message_when_to_use: "",
    message_format_template_id: null,
    flag_allow_edit: true,
    field_mapping: ""
}