import { Autocomplete, Button, Divider, Grid, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import calender from "../../../../assets/chatbotAssets/calender.png";
import clock from "../../../../assets/chatbotAssets/clock.png";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IconsColor from '../../../utility/iconsColor';
import Required from '../../../utility/required';
import styles from "../../../../assets/styles/global.module.scss";
import { extractTime, hexToRgba } from '../../../../utils/constants';
import { truncate } from 'lodash';

const AppointmentSchedule = (props: any) => {
    const { thread } = props
    const [value, setValue] = React.useState(0);
    const [payload, setPayload] = useState(props.data)
    const [flagSubmit, setFlagSubmit] = React.useState(false);
    const [currentStep, setCurrentStep] = useState(1)
    function changePayloadData(flagForm: any, key: any, value: any) {
        if (flagForm) {
            setPayload((prevState: any) => ({
                ...prevState, form_fields: prevState?.form_fields?.map((question: any) => {
                    return question.title == key ? { ...question, value: value } : question
                })
            }))
        } else {
            setPayload((prevState: any) => ({ ...prevState, [key]: value }))
        }
    }
    function handleSubmitPayload() {
        // let tt = ''
        // payload.form_fields.forEach((question: any) => {
        //     tt = tt + (`${question.title}: ${question.value}\n`)
        // })
        // let payload1 = `Please book an appointment for following details:\nselected_appointment_date: ${payload.selected_appointment_date}\nselected_slot_start_time: ${payload.selected_slot_start_time}\nselected_slot_end_time: ${payload.selected_slot_end_time}\n${tt}
        // `
        // console.log("payloaddd=====", payload1)
        // props.sendHiddenMessageToSocket({
        //     suggestion_name: payload
        // })
        // props.closePopup()
    }

    function getInputType(question: any) {

        switch (question.type) {
            case 'dropdown':
                return <Autocomplete
                    value={question.options.find((option: any) => option.name == question.value)}
                    getOptionLabel={(option) => option.name}
                    className={styles.globalAutocompleteStyles}
                    options={question.options}
                    onChange={(event, newValue) => { changePayloadData(true, question.title, newValue.name) }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            border: "none !important",
                            boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
                        },
                        'fieldset': {
                            border: "none"
                        }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder={question.place_holder} />}
                    disabled
                />
            case 'date':
                return <>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker

                            value={question.value || null}
                            onChange={(value) => { changePayloadData(true, question.title, value) }}
                            className={styles.globalTextfieldStyle}
                            disabled
                        />
                    </LocalizationProvider>
                </>
            default:
                return <TextField
                    placeholder={question.place_holder}
                    value={question.value}
                    onChange={(event) => { changePayloadData(true, question.title, event.target.value) }}
                    className={styles.globalTextfieldStyle} 
                    disabled
                />
        }
    }
    function getScheduleButtonDisableCond() {
        let fields = payload.form_fields
        for (const field of fields) {
            if (field.flag_required && field.value == "") {
                return true
            }
        }
        return false
    }
    function getStepComponent() {
        switch (currentStep) {
            case 1:
                return <>
                    <Divider sx={{ marginTop: "15px" }} />
                    <Grid item sx={{ margin: "15px 0px", padding: "0px 20px" }}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>Días disponibles</Grid>
                            {/* <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color : "#6a097d" }}>Next 7 days</Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item sx={{ maxHeight: "calc(100% - 100px)", overflowY: "auto", padding: "5px 20px" }}>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container gap="10px" direction={"column"}>
                                    {props.data?.available_date_times?.map((appointment_date: any) => {
                                        return <>
                                            <Grid item
                                                onClick={() => { setCurrentStep((prevState) => prevState + 1); changePayloadData(false, 'selected_appointment_date', appointment_date.date) }}
                                                sx={{
                                                    borderRadius: "5px",
                                                    boxShadow: `0px 0px 2px 0px ${props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6A097D"}`,
                                                    // border: `1px solid ${hexToRgba(props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6A097D")}`,
                                                    padding: "10px", fontSize: "14px",
                                                    fontWeight: "400",
                                                    background: payload?.selected_appointment_date == appointment_date.date ? props.chatbotTheme.chatbot_send_message_bg_color ? props.chatbotTheme.chatbot_send_message_bg_color : "#F7E9FF" : "#fff",
                                                    color: payload?.selected_appointment_date == appointment_date.date ? props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6A097D" : "#000000",
                                                    cursor: "pointer"
                                                }}>
                                                {appointment_date.date}
                                            </Grid>
                                        </>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            case 2:
                return <>
                    <div style={{ padding: "0px 20px" }}>
                        <Grid item sx={{
                            boxShadow: "0px 0px 1.5px 0px #999999",
                            borderRadius: "18px", padding: "9px",
                            marginTop: "15px",
                            maxWidth: "fit-content",

                        }}>
                            <Grid container gap="7px" alignItems={"center"}>
                                <Grid item sx={{ display: "flex" }}>
                                    <img alt='' src={calender} style={{ width: "14px", height: "14px" }} />
                                </Grid>
                                <Grid item sx={{ fontSize: "12px", fontWeight: "500", color: "#212121" }}>{payload.selected_appointment_date}</Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider sx={{ marginTop: "15px" }} />
                    <Grid item sx={{ margin: "15px 0px", padding: "0px 20px" }}>
                        <Grid container gap="8px" alignItems={"center"}>
                            <Grid item sx={{ display: "flex", cursor: "pointer" }} onClick={() => { setCurrentStep((prevState) => prevState - 1) }}>
                                <IconsColor iconName="back" fillColor="#000" width="15px" height='15px' />
                            </Grid>
                            <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>Ranuras disponibles</Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ maxHeight: "calc(100% - 150px)", overflowY: "auto", padding: "5px 20px" }}>
                        <Grid container direction={"column"}>

                            <Grid item >
                                <Grid container gap="12px" >
                                    {props.data.available_date_times.find((appointment_date: any) => appointment_date.date == payload.selected_appointment_date)?.available_slots?.map((slot: any) => {
                                        return <>
                                            <Grid item
                                                onClick={() => { setCurrentStep((prevState) => prevState + 1); changePayloadData(false, 'selected_slot_start_time', slot.start_time); changePayloadData(false, 'selected_slot_end_time', slot.end_time) }}
                                                sx={{
                                                    cursor: "pointer",
                                                    boxShadow: `0px 0px 2px 0px ${props.chatbotTheme.chatbot_send_message_icon_color ? props.chatbotTheme.chatbot_send_message_icon_color : "#6A097D"}`,
                                                    // boxShadow: "0px 0px 2px 0px #6A097D80"
                                                    // border: `1px solid ${hexToRgba(props.chatbotTheme.chatbot_send_message_icon_color ? props.chatbotTheme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                    padding: "10px", fontSize: "14px", fontWeight: "400", borderRadius: "5px",
                                                    background: payload.selected_slot_start_time == slot.start_time ? props.chatbotTheme.chatbot_send_message_bg_color ? props.chatbotTheme.chatbot_send_message_bg_color : "#F7E9FF" : "#fff",
                                                    color: payload.selected_slot_start_time == slot.start_time ? props.chatbotTheme.chatbot_send_message_icon_color ? props.chatbotTheme.chatbot_send_message_icon_color : "#6A097D" : "#000000",
                                                }}>
                                                {extractTime(slot.start_time)}
                                            </Grid>
                                        </>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            case 3:
                return <>
                    <div style={{ padding: "0px 20px" }}>
                        <Grid item>
                            <Grid container gap="10px">
                                <Grid item sx={{
                                    boxShadow: "0px 0px 1.5px 0px #999999",
                                    borderRadius: "18px", padding: "9px",
                                    marginTop: "15px"
                                }}>
                                    <Grid container gap="7px" alignItems={"center"}>
                                        <Grid item>
                                            <img alt='' src={calender} style={{ width: "14px", height: "14px" }} />
                                        </Grid>
                                        <Grid item sx={{ fontSize: "12px", fontWeight: "500", color: "#212121" }}>{payload.selected_appointment_date}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{
                                    boxShadow: "0px 0px 1.5px 0px #999999",
                                    borderRadius: "18px", padding: "9px",
                                    marginTop: "15px"
                                }}>
                                    <Grid container gap="7px" alignItems={"center"}>
                                        <Grid item>
                                            <img alt='' src={clock} style={{ width: "14px", height: "14px" }} />
                                        </Grid>
                                        <Grid item sx={{ fontSize: "12px", fontWeight: "500", color: "#212121" }}>{payload.selected_slot_start_time}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider sx={{ marginTop: "15px" }} />
                    <Grid item sx={{ margin: "15px 0px", padding: "0px 20px" }}>
                        <Grid container gap="8px" alignItems={"center"}>
                            <Grid item sx={{ display: "flex", cursor: "pointer" }} onClick={() => { setCurrentStep((prevState) => prevState - 1) }}>
                                <IconsColor iconName="back" fillColor="#000" width="15px" height='15px' />
                            </Grid>
                            <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>Detalles</Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item sx={{ maxHeight: "calc(100% - 225px)", overflowY: "auto", padding: "5px 20px" }}> */}
                    <Grid item sx={{ maxHeight: "250px", overflowY: "auto", padding: "5px 20px" }}>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container gap="12px" direction={"column"} >
                                    {payload?.form_fields?.map((question: any) => {
                                        return <>
                                            <Grid item>
                                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                    <Grid item xs={5} sx={{ fontSize: "14px", color: "#000000", fontWeight: "400", }}>{question.title}
                                                        {question.flag_required && <Required />}
                                                    </Grid>
                                                    <Grid item xs={7}
                                                    >{getInputType(question)}</Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ padding: "10px" }}>
                        <Grid container justifyContent={"center"}>
                            <Button
                                // disabled={getScheduleButtonDisableCond()}
                                disabled={true}
                                onClick={handleSubmitPayload}
                                sx={{
                                    boxShadow: `0px 0px 2px 0px ${props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6A097D"}`,
                                    background: props.chatbotTheme.chatbot_send_message_bg_color ? props.chatbotTheme.chatbot_send_message_bg_color : "#F7E9FF",
                                    color: props.chatbotTheme?.chatbot_send_message_icon_color ? props.chatbotTheme?.chatbot_send_message_icon_color : "#6A097D",
                                    padding: "10px",
                                    textTransform: "unset"
                                }}>Cronograma</Button>
                        </Grid>

                    </Grid>
                </>
            default:
                return <></>
        }
    }
    return (
        <>
            <div style={{ padding: "20px 0px", overflow: "hidden", maxHeight: "100%" }}>
                {/* header */}
                <Grid container gap="10px" sx={{ flexWrap: "nowrap", padding: "0px 20px" }}>
                    <Grid item sx={{ borderRadius: "50%", height: "40px", width: "40px" }}>
                        <img alt="" src={props.data.item_image_URL || "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"} style={{ borderRadius: "50%", height: "40px", width: "40px" }} />
                    </Grid>
                    <Grid item>
                        <Grid container gap="5px" direction={"column"}>
                            <Grid item sx={{ fontSize: "15px", fontWeight: "600", color: "#000" }}>{props?.data?.item_heading}</Grid>
                            <Grid item sx={{ fontSize: "12px", fontWeight: "600", color: "#707070" }}>{props?.data?.item_subheading}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {getStepComponent()}
            </div>
        </>
    )
}

export default AppointmentSchedule