import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext';
import { campaignContactAssignmentData } from '../../../models/axiosModel';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import InfoIcon from '@mui/icons-material/Info';
import styles from '../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss'
import IconsColor from '../../utility/iconsColor';

interface contactsInterface {
    statusColor: any,
}

const CampaignAnalysisContacts = (props: contactsInterface) => {
    const { selectedWhatsAppCampaign, setCampaignContactStatusPopup, setCurrentContactStatus } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;
    const { userDetail } = useContext(UserContext) as UserContextPayload;
    const { statusColor } = props;

    const handleClickStatus = (contactStatus: campaignContactAssignmentData) => {
        setCampaignContactStatusPopup(true);
        setCurrentContactStatus(contactStatus)
    }
    
    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "450px"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Phone Number</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Full Name</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Updated At</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Status</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Failure Reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedWhatsAppCampaign?.campaign_contact_assignment_data?.sort((a: any,b: any) => (a.updated_at_timestamp ? a.updated_at_timestamp : a.created_at_timestamp) - (b.updated_at_timestamp ? b.updated_at_timestamp : b.created_at_timestamp)).map((contacts: campaignContactAssignmentData) => 
                        <TableRow className={dashboardStyles.cellRow}>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{contacts.contact_phone_number}</TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{contacts.contact_name}</TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{contacts.updated_at_timestamp ?formatDateAccordingToTimezoneUsingMoment(contacts?.updated_at_timestamp, userDetail.timezone_hour) : "-"}</TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>
                                <span 
                                    style={{
                                        padding: "2px 15px 4px", 
                                        borderRadius: "5px", 
                                        boxShadow: `0px 0px 2px ${statusColor(contacts.campaign_execution_tracking_status ? contacts.campaign_execution_tracking_status : contacts.campaign_execution_status_name)}`, 
                                        backgroundColor: statusColor(contacts.campaign_execution_tracking_status ? contacts.campaign_execution_tracking_status : contacts.campaign_execution_status_name, true), 
                                        color: statusColor(contacts.campaign_execution_tracking_status ? contacts.campaign_execution_tracking_status : contacts.campaign_execution_status_name),
                                        textTransform: "capitalize",
                                        fontSize: "12px"
                                    }}
                                >
                                    {contacts.campaign_execution_tracking_status ? contacts.campaign_execution_tracking_status : contacts.campaign_execution_status_name}
                                </span>
                                <span style={{margin: "8px", verticalAlign: "sub", cursor: "pointer"}} onClick={() => handleClickStatus(contacts)}>
                                    <IconsColor iconName="info" fillColor="#6A097D" height="16" width="16"/>
                                </span>
                            </TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{contacts.campaign_execution_status_name.toLowerCase() == "failed" ? contacts.failure_reason : ""}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CampaignAnalysisContacts
