import { Grid, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { chatbotLeadDataModel, createLeadGenerationPayloadModel, createLeadGenerationResponseModel, getGenericFieldTypeDataResponseModel, getLeadIntegrationTypeDataResponse, leadFieldDataModel } from '../../../models/axiosModel'
import EmptyState from '../../global/emptyState'
import GlobalButton from '../../utility/globalButton/button'
import IconsColor from '../../utility/iconsColor'
import emptyStateLeadGeneration from './../../../assets/images/leadGeneration/leadGenerationEmptyState.svg'
import GlobalPopUp from '../../utility/globalPopUp'
import NewLeadDetails from './newLeadDetails'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { initialLeadGeneraionData, initialLeadGenerationField, leadFieldsData, leadIntegrationDataTypeStatic } from './leadGenerationConstants'
import agent from '../../../services/api'
import EmailConfig from './emailConfig'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext'
import LeadGenerationList from './leadGenerationList'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'

const LeadGenerationGlobal = () => {
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const {chatBotDetails} = useContext(ChatbotContext) as ChatbotContextPayload
    const {setFlagLoading, flagLoading, setSuccess, success, setError, error} = useContext(UserContext) as UserContextPayload;
    const {AddNewLeadGeneration, GetLeadGenerationList, leadGenerationList, setLeadGenerationList, GetGenericFieldDataType, GetLeadIntegrationDataType,
        createLeadGenerationPayload, setCreateLeadGenerationPayload, setCreateLeadGenerationResponse, setSelectFieldData, setTempLeadGenerationPayload, setStepContainer, tempLeadGenerationPayload,
        selectedIntegrationType} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const [searchParams] = useSearchParams();
    let chatbot_id = searchParams.get("chatbot_id");
    let lead_id = searchParams.get("lead_id");
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            if(chatbot_id) {
                if(lead_id) {
                    await GetLeadGenerationList(chatbot_id, lead_id)
                }
                else {
                    await GetLeadGenerationList(chatbot_id)
                }
            }
            setFlagLoading({...flagLoading, flagRoundLoader: false})
        })()
        
    }, [chatbot_id, lead_id])

    const handleSaveLeadGenerationData = async (): Promise<any> => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const tempPayload = {
            chatbot_id: createLeadGenerationPayload.chatbot_id,
            chatbot_lead_name: createLeadGenerationPayload.chatbot_lead_name,
            chatbot_lead_description: createLeadGenerationPayload.chatbot_lead_description,
            chatbot_lead_field_data: createLeadGenerationPayload.chatbot_lead_field_data.map((field) => ({
                chatbot_lead_field_type_id: field.chatbot_lead_field_type_id,
                chatbot_lead_field_name: field.chatbot_lead_field_name,
                chatbot_lead_field_description: field.chatbot_lead_field_description,
                chatbot_lead_field_enum_value: field.chatbot_lead_field_enum_value,
            }))
        };
        const tempUpdatePayload = {
            chatbot_lead_id: createLeadGenerationPayload.chatbot_lead_id || 0,
            chatbot_id: createLeadGenerationPayload.chatbot_id,
            chatbot_lead_name: createLeadGenerationPayload.chatbot_lead_name,
            chatbot_lead_description: createLeadGenerationPayload.chatbot_lead_description,
            chatbot_lead_field_data: createLeadGenerationPayload.chatbot_lead_field_data.map((field) => (
            field.chatbot_lead_field_id ?
                {
                    chatbot_lead_field_id: field.chatbot_lead_field_id,
                    chatbot_lead_field_type_id: Number(field.chatbot_lead_field_type_id),
                    chatbot_lead_field_name: field.chatbot_lead_field_name,
                    chatbot_lead_field_description: field.chatbot_lead_field_description,
                    chatbot_lead_field_enum_value: field.chatbot_lead_field_enum_value,
                }
                :
                {
                    chatbot_lead_field_type_id: Number(field.chatbot_lead_field_type_id),
                    chatbot_lead_field_name: field.chatbot_lead_field_name,
                    chatbot_lead_field_description: field.chatbot_lead_field_description,
                    chatbot_lead_field_enum_value: field.chatbot_lead_field_enum_value,
                }
            ))
        }
        if(lead_id) {
            // const response = await agent.updateLeadGeneration.put(tempUpdatePayload);
            // if (response.status) {
                // setCreateLeadGenerationResponse(response.data[0])
                setSelectFieldData(tempUpdatePayload.chatbot_lead_field_data)
                // setSuccess({ ...success, flagShowSuccess: true, message: "Lead Details Updated Successfully!" });
                // GetLeadGenerationList(tempUpdatePayload.chatbot_id.toString(), tempUpdatePayload.chatbot_lead_id.toString())
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            // }
            // else {
            //     setError({
            //         ...error,
            //         flagShowError: true,
            //         message: response.error.message
            //     })
            //     setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            // }
            // return response;
        }
        else {
            setStepContainer(2)
            setTempLeadGenerationPayload(tempPayload)
            // const response = await agent.createLeadGeneration.post(tempPayload);
            // if (response.status) {
                // setCreateLeadGenerationResponse(response.data[0])
                // setCreateLeadGenerationPayload({...createLeadGenerationPayload, 
                //     chatbot_lead_id: response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel) => data.chatbot_lead_name === tempPayload.chatbot_lead_name).chatbot_lead_id,
                //     chatbot_lead_field_data: response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel) => data.chatbot_lead_name === tempPayload.chatbot_lead_name).chatbot_lead_field_data
                // })
                // setSuccess({ ...success, flagShowSuccess: true, message: "Lead Details Added Successfully!" });
                // navigate(`${window.location.pathname}?chatbot_id=${chatbot_id}&lead_id=${response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel)=>data.chatbot_lead_name === tempPayload.chatbot_lead_name).chatbot_lead_id}`)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            // }
            // else {
            //     setError({
            //         ...error,
            //         flagShowError: true,
            //         message: response.error.message
            //     })
            //     setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            // }
            // return response;
        }
        
    }

    const handleSaveLeadGeneration = async (): Promise<any> => {
        const tempUpdatePayload = {
            chatbot_lead_id: createLeadGenerationPayload.chatbot_lead_id || 0,
            chatbot_id: createLeadGenerationPayload.chatbot_id,
            chatbot_lead_name: createLeadGenerationPayload.chatbot_lead_name,
            chatbot_lead_description: createLeadGenerationPayload.chatbot_lead_description,
            chatbot_lead_field_data: createLeadGenerationPayload.chatbot_lead_field_data.map((field) => (
            field.chatbot_lead_field_id ?
                {
                    chatbot_lead_field_id: field.chatbot_lead_field_id,
                    chatbot_lead_field_type_id: Number(field.chatbot_lead_field_type_id),
                    chatbot_lead_field_name: field.chatbot_lead_field_name,
                    chatbot_lead_field_description: field.chatbot_lead_field_description,
                    chatbot_lead_field_enum_value: field.chatbot_lead_field_enum_value,
                }
                :
                {
                    chatbot_lead_field_type_id: Number(field.chatbot_lead_field_type_id),
                    chatbot_lead_field_name: field.chatbot_lead_field_name,
                    chatbot_lead_field_description: field.chatbot_lead_field_description,
                    chatbot_lead_field_enum_value: field.chatbot_lead_field_enum_value,
                }
            ))
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        if(lead_id) {
            const response = await agent.updateLeadGeneration.put(tempUpdatePayload);
            if (response.status) {
                setCreateLeadGenerationResponse(response.data[0])
                setSelectFieldData(tempUpdatePayload.chatbot_lead_field_data)
                setSuccess({ ...success, flagShowSuccess: true, message: "Lead Details Updated Successfully!" });
                GetLeadGenerationList(tempUpdatePayload.chatbot_id.toString(), tempUpdatePayload.chatbot_lead_id.toString())
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            else {
                setError({
                    ...error,
                    flagShowError: true,
                    message: response.error.message
                })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            return response;
        }
        else {
            const response = await agent.createLeadGeneration.post(tempLeadGenerationPayload);
            if (response.status) {
                setCreateLeadGenerationResponse(response.data[0])
                setCreateLeadGenerationPayload({...createLeadGenerationPayload, 
                    chatbot_lead_id: response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel) => data.chatbot_lead_name === tempLeadGenerationPayload.chatbot_lead_name).chatbot_lead_id,
                    chatbot_lead_field_data: response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel) => data.chatbot_lead_name === tempLeadGenerationPayload.chatbot_lead_name).chatbot_lead_field_data
                })
                setSuccess({ ...success, flagShowSuccess: true, message: "Lead Details Added Successfully!" });
                navigate(`/chatbot/viewchatbot/leadgeneration/${selectedIntegrationType.lead_integration_type_name.toLowerCase()}?chatbot_id=${chatbot_id}&lead_id=${response.data[0].chatbot_lead_data.find((data: chatbotLeadDataModel)=>data.chatbot_lead_name === tempLeadGenerationPayload.chatbot_lead_name).chatbot_lead_id}`)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            else {
                setError({
                    ...error,
                    flagShowError: true,
                    message: response.error.message
                })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            return response;
        }
        
    }

    useEffect(() => {
        GetGenericFieldDataType()
        GetLeadIntegrationDataType()
    }, [])

    const innerHeight = (window.innerHeight - 170);
    
    return (
        <>
            <Grid item xs={12} paddingBlock={flagSmallScreen ? 1 : 2}>
                {leadGenerationList === null && window.location.pathname === "/chatbot/viewchatbot/leadgeneration" &&
                    <EmptyState
                        height={`${innerHeight}px !important`}
                        maxHeight={`${innerHeight}px !important`}
                        pageIcon={<img alt="chatbot" src={emptyStateLeadGeneration} style={{ width: "165px", height: "auto" }} />}
                        pageSubText={"No Leads Generated Yet"}
                        primaryActionBtn={<GlobalButton
                            buttonText={"New Lead"}
                            sx={{width: "170px"}}
                            className='primaryButtonStyle'
                            onClick={() => {navigate(`/chatbot/viewchatbot/leadgeneration/createleadgeneration?chatbot_id=${chatbot_id}`);AddNewLeadGeneration()}}
                            startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                        />}
                    />
                }
                {window.location.pathname === "/chatbot/viewchatbot/leadgeneration/createleadgeneration" || window.location.pathname === "/chatbot/viewchatbot/leadgeneration" ?
                    window.location.pathname === "/chatbot/viewchatbot/leadgeneration/createleadgeneration" ?
                        <NewLeadDetails handleSaveLeadGenerationData={handleSaveLeadGenerationData} handleSaveLeadGeneration={handleSaveLeadGeneration}/>
                    :
                    leadGenerationList && !lead_id &&
                        <LeadGenerationList />
                    :
                    <Outlet />
                }

            </Grid>
        </>
    )
}

export default LeadGenerationGlobal
