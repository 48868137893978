import { Breadcrumbs, Button, Divider, Grid, IconButton, Link, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import IconsColor from '../../utility/iconsColor';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getOrganizationDetailDataModel, organizationUserDataModel } from '../../../models/axiosModel';
import deleteDoc from "../../../assets/deleteDoc.svg";
import { formatDate } from '../../../utils/constants';
import linkedDetailsIcon from '../../../assets/chatbotAssets/linkedDetails.svg'
import { string } from 'yup';
import GlobalPopUp from '../../utility/globalPopUp';
import AddMember from './addMember';
import validator from 'validator';
import AddIcon from '@mui/icons-material/Add';
import templateStyles from '../../../assets/styles/componentStyles/template.module.scss'
import OrganizationMembers from './organizationMembers';
import OgranizationCard from './organizationCard';

interface chatsProps {
    mainSection?: boolean
}
interface integrationRowData {
    id: number,
    iconSrc: string,
    channel_icon: any,
    channel_name: string,
    channel_linked_details: any,
    channel_last_updated_timestamp: string,
}
let initialMemberDetails = {
    user_email: "",
    private_user_first_name: "",
    private_user_last_name: ""
}
export default function ViewOrganizationDetails(props: chatsProps) {

    const navigate = useNavigate()
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const { setFlagLoading, setSuccess, setError, userDetail, organizationDetails, GetOrganizationDetails } = useContext(UserContext) as UserContextPayload;
    // const [organizationDetails, setOrganizationDetails] = useState<getOrganizationDetailDataModel | null>(null)
    const [flagDeleteIntegrationDataPopup, setFlagDeleteIntegrationDataPopup] = useState(false)
    const [memberToRemove, setIntegrationSelected] = useState<null | any>(null)
    const [integrationViewData, setIntegrationViewData] = useState<integrationRowData[] | []>([]);
    const { getGlobalIntegrationTypeList, globalIntegrationTypeList, getIntegrationList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload
    const [flagAddMember, setFlagAddMember] = useState(false);
    const [memberDetails, setMemberDetails] = useState(initialMemberDetails)
    const [flagInvalidValue, setFlagInvalidValue] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const organization_id = searchParams.get('organization_id')
    useEffect(() => {
        (async () => {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
            await getGlobalIntegrationTypeList();
            await getIntegrationList();
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
        })()
    }, [])


    let headerList = [
        {
            name: "First Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_first_name",
            // sx: { width: "20px !important", paddingRight: "0px !important" }
        },
        {
            name: "Last Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_last_name",
        },
        {
            name: "Email",
            alignment: "left",
            flagIcon: false,
            valueKey: "private_user_email",
        },
        {
            name: "Status",
            alignment: "left",
            flagIcon: false,
            flagHtml: true,
            valueKey: "user_organization_status_name",
        },
        {
            name: "Role",
            alignment: "left",
            flagIcon: false,
            valueKey: "user_organization_role_name",
        },

    ]

    useEffect(() => {
        if (integrationList !== null && integrationList.length > 0) {
            let updatedData = integrationList.flatMap((integrationData, index) => {
                return {
                    id: integrationData.meta_integration_id,
                    iconSrc: linkedDetailsIcon,
                    channel_icon: integrationData.meta_integration_facebook_data ? <Grid item sx={{ display: "flex" }}><IconsColor iconName="facebook_messenger" height="20" width="20" /></Grid> : <Grid item sx={{ display: "flex" }}><IconsColor iconName="whatsapp_business_channel" height="20" width="20" style={{ display: "flex" }} /></Grid>,
                    channel_name: integrationData.meta_integration_facebook_data ? `${integrationData.meta_integration_facebook_data.page_name}` : `${integrationData.meta_integration_whatsapp_data?.waba_name}`,
                    channel_linked_details: integrationData.meta_integration_whatsapp_data ? <span style={{ color: "#6A097D" }}>{`${integrationData.meta_integration_whatsapp_data?.meta_integration_phone_number_data?.filter((data: any) => data?.chatbot_integration_assignment_data.length > 0).length} / ${integrationData?.meta_integration_whatsapp_data?.meta_integration_phone_number_data?.length}`}</span> : <span style={{ color: "#6A097D" }}>{`${integrationData.meta_integration_facebook_data?.chatbot_integration_assignment_data?.length} / 1`}</span>,
                    channel_last_updated_timestamp: formatDate(integrationData.updated_at_timestamp),
                };
            });
            setIntegrationViewData(updatedData)
        }
    }, [integrationList])

    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);

    const rowClicked = async (e: any, rowDetail: any) => {
        if (e.iconKey !== "editIcon") {
            if (rowDetail.channel_name === integrationList?.find((list) => list.meta_integration_id === rowDetail.id)?.meta_integration_facebook_data?.page_name) {
                navigate(`/channels/viewintegration/facebook?id=${rowDetail.id}`)
            } else {
                navigate(`/channels/viewintegration/whatsapp?id=${rowDetail.id}`)
            }
        } else {

        }
    }

    const deleteIntegrationDataPopupOpen = (data: any) => {
        setFlagDeleteIntegrationDataPopup(true)
        setIntegrationSelected(data)
    }

    useEffect(() => {
        if (organizationDetails == null) {
            navigate('/')
        }
    }, [searchParams])

    const menuItems = [
        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" width={"16"} height={"16"} />, action: (e: any) => deleteIntegrationDataPopupOpen(e), disabled: (rowDetails: organizationUserDataModel) => rowDetails.user_organization_role_name == "Organization Owner" ? true : false },
    ];

    const handleChangeMemberDetails = (value: string, keyName: string) => {
        setMemberDetails((prevState) => ({ ...prevState, [keyName]: value.trim() }))
    }
    async function getOrganizationDetails() {
        // const response = await agent.getOrganizationDetails.get();
        // if (response.status) {
        // setOrganizationDetails(response.data[0])
        if (userDetail.user_organization_data && userDetail.user_organization_data.user_organization_role_name == "Organization Owner") {
            let editIcon = {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon"
            }

            headerList.push(editIcon)
            setAdjustedHeaderList(headerList)
        }
        // } else {

        // }
    }

    useEffect(() => {
        getOrganizationDetails()
    }, [])


    const handleCancelAddMember = () => {
        setFlagAddMember(false)
        setMemberDetails(initialMemberDetails)
    }
    const handleShareInvitation = async () => {
        if (validator.isEmail(memberDetails.user_email.trim())) {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
            const response = await agent.shareOrganizationInvitation.post(memberDetails);
            if (response.status) {
                GetOrganizationDetails()
                getOrganizationDetails()
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: response.data.message }))
                setFlagAddMember(false);
                setMemberDetails(initialMemberDetails)
            }
        } else {
            setFlagInvalidValue(true)
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

    }
    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
            <Grid container sx={{ padding: { xs: "20px 0px", sm: "25px 0px" } }}>
                <Grid item xs={12} sm={12}>
                    <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                        {<><Grid item className={globalStyles.fullwidth} sx={{ marginBottom: !smallScreen ? "10px" : "", padding: { xs: "0px 20px", lg: "0px 25px 0px 27px" } }}>
                            <GlobalPageHeaderText
                                mainHeadText={organizationDetails && organizationDetails.length > 1 && !organization_id ? "Workspace" : `${organizationDetails?.find((organization) => organization.organization_id === Number(organization_id))?.organization_name}`}
                                flagMainTextOnly
                                actionBlock={
                                    (organizationDetails && (organization_id || organizationDetails.length == 1)) && (userDetail && userDetail.user_organization_data && userDetail.user_organization_data.user_organization_role_name == "Organization Owner") &&
                                    <Grid item>
                                        <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                                            <Grid item>
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    <Grid item className={`${templateStyles.addChatbotBtn}`}>
                                                        <Button
                                                            variant='outlined'
                                                            size='large'
                                                            sx={{ width: "178px" }}
                                                            fullWidth
                                                            startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                                            className={globalStyles.addDocumentBtn}
                                                            onClick={() => { setFlagAddMember(true) }}
                                                        >
                                                            New Member
                                                        </Button>
                                                    </Grid>
                                                    <IconButton
                                                        className={`${globalStyles.cmpGlobalMobileAddIcon} ${templateStyles.addChatbotIcon}`}
                                                        onClick={() => { setFlagAddMember(true) }}
                                                    >
                                                        <AddIcon style={{ height: "28px", width: 'auto' }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                mainSubText={
                                    !smallScreen && <Breadcrumbs
                                        aria-label="breadcrumb"
                                        className={styles.cbHeaderBreadCrumb}
                                        sx={{
                                            '& .MuiBreadcrumbs-separator': {
                                                marginRight: '3px',
                                                marginLeft: '3px'
                                            }
                                        }}
                                    >
                                        <Link
                                            underline="hover"
                                            onClick={() => { navigate("/") }}
                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                        >
                                            Dashboard
                                        </Link>

                                        {(!organization_id && organizationDetails && organizationDetails.length > 1) &&
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate(`/organization`) }}
                                                className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                            >
                                                Workspace
                                            </Link>
                                        }

                                        {(organization_id || (organizationDetails && organizationDetails.length === 1)) &&
                                            <Breadcrumbs>
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { organizationDetails && organizationDetails.length > 1 ? navigate(`/organization`) : navigate(`/organization?organization_id=${organization_id}`) }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                >
                                                    Workspace
                                                </Link>
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { organizationDetails && organizationDetails.length > 1 ? navigate(`/organization`) : navigate(`/organization?organization_id=${organization_id}`) }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                                >
                                                    {organizationDetails?.find((organization) => organization.organization_id === Number(organization_id))?.organization_name}
                                                </Link>
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                                >
                                                    Members
                                                </Link>
                                            </Breadcrumbs>
                                        }
                                    </Breadcrumbs>
                                }
                            />
                            {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles} ${styles.dividerStyle}`} sx={{ paddingTop: "16px !important" }} />}
                        </Grid>
                        </>}
                        <Grid item className={`${globalStyles.fullwidth} ${styles.lowerBlock}`}>
                            <Grid container direction={"column"}>
                                {/* upper block */}
                                {organizationDetails && organizationDetails.length > 1 && !organization_id &&
                                    <Grid item className={styles.upperBlock}>
                                        <OgranizationCard organizationDetail={organizationDetails} />
                                    </Grid>
                                }
                                {/* lower block */}
                                {((organizationDetails && organizationDetails.length == 1) || organization_id) &&
                                    <Grid item xs={12} sx={{ padding: { xs: "10px 20px", lg: "0px 25px 0px 25px" }, width: "100%", overflow: "scroll", boxSizing: "border-box" }}>
                                        <OrganizationMembers />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {
                flagAddMember &&
                <GlobalPopUp
                    open={flagAddMember}
                    titlePadding='0px'
                    flagShowCloseIcon
                    handleClose={() => { setFlagAddMember(false) }}>
                    <AddMember
                        onChangePayload={handleChangeMemberDetails}
                        addMemberPayload={memberDetails}
                        handleShareInvitation={handleShareInvitation}
                        cancel={handleCancelAddMember}
                        setFlagKeyExistError={setFlagInvalidValue}
                        flagKeyExistError={flagInvalidValue}
                    />
                </GlobalPopUp>
            }
        </Grid >

    )
}
