import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import style from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import globalStyles from "../../../assets/styles/global.module.scss";
// import connectorstyle from '../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss';
import datePickerStyles from "../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisAudio.module.scss";
import GlobalButton from '../../utility/globalButton/button';
import { Padding } from '@mui/icons-material';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import DatePresets from '../callAnalysis/datePresets';
import { Moment } from 'moment';
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { filterData } from './feedbackGlobal';

export interface FetchFeedbackDataProps {
    filterData: filterData, 
    setFilterData: React.Dispatch<React.SetStateAction<filterData>>,
    focusedInput: FocusedInputShape | null, 
    setFocusedInput: React.Dispatch<React.SetStateAction<FocusedInputShape | null>>, 
    handleFetchData: () => void, 
    handleResetFilter: () => void, 
    setFlagFeedbackFilterPopup: React.Dispatch<React.SetStateAction<boolean>>,
} 

const FetchFeedbackData: React.FC<FetchFeedbackDataProps> = (props) => {

    const {filterData, setFilterData ,focusedInput, setFocusedInput, handleFetchData, handleResetFilter, setFlagFeedbackFilterPopup } = props

    const { feedbackStatusData, feedbackKnowledgeBaseStatusData } = useContext(GenericDataContext) as GenericDataContextPayload;
    const dateFormat = "DD/MM/YYYY";

    const mobileScreen = useMediaQuery('(min-width : 1350px)')
    const below1300Screen = useMediaQuery('(min-width: 0px) and (max-width: 1300px)');
    const screen600to1300 = useMediaQuery('(min-width: 600px) and (max-width: 1300px)');

    return (
        <Grid item sx={{ width: "100%", padding: "12px 3px 0px" }}>
            <Grid container flexWrap={mobileScreen ? "nowrap" : "wrap"} alignItems={"end"} gap={2}>

                {!below1300Screen ?
                    <>
                        <Grid item className={style.fetchFeedbackDataFields}>
                            <Grid container direction={"column"} gap={"10px"}>
                                <Grid item>
                                    <Typography className={style.headerComponentTitle}>Approval Status</Typography>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={feedbackStatusData}
                                        value={feedbackStatusData.find((data) => data.feedback_status_id === filterData.selectedApprovalStatus?.feedback_status_id) || null}
                                        getOptionLabel={(option) => option.feedback_status_name}
                                        onChange={(e, value) => { 
                                            setFilterData((prev) => ({
                                                ...prev,
                                                selectedApprovalStatus: value,
                                            })); 
                                        }}
                                        className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoComplete}`}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item className={style.fetchFeedbackDataFields}>
                            <Grid container direction={"column"} gap={"10px"}>
                                <Grid item>
                                    <Typography className={style.headerComponentTitle}>Modification Status</Typography>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={feedbackKnowledgeBaseStatusData}
                                        value={feedbackKnowledgeBaseStatusData.find((data) => data.modification_status_id === filterData.selectedKnowledgeBaseStatus?.modification_status_id) || null}
                                        getOptionLabel={(option) => option.modification_status_name}
                                        onChange={(e, value) => { 
                                            setFilterData((prev) => ({
                                                ...prev,
                                                selectedKnowledgeBaseStatus: value,
                                            })); 
                                        }}
                                        className={`${globalStyles.autocompleteParaphrasing} ${style.methodAutoComplete}`}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sx={{ width: "100%", maxWidth: "228px" }}>
                            <Grid container direction={"column"} gap={"10px"}>
                                <Grid item>
                                    <Typography className={style.headerComponentTitle}>Date</Typography>
                                </Grid>
                                <Grid item className={`${datePickerStyles.dateRangePickerClass} ${style.dateRangePickerClass}`} sx={{ zIndex: "5" }}>
                                    <DateRangePicker
                                        startDate={filterData.startDate}
                                        startDateId='s_id'
                                        endDate={filterData.endDate}
                                        endDateId='e_id'
                                        onDatesChange={({ startDate, endDate }) => {
                                            setFilterData((prev) => ({
                                                ...prev,
                                                startDate: startDate, 
                                                endDate: endDate,
                                            }))
                                        }}
                                        focusedInput={focusedInput}
                                        displayFormat={dateFormat}
                                        isOutsideRange={() => false}
                                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                        renderCalendarInfo={() => (
                                            <DatePresets
                                                startDate={filterData.startDate}
                                                endDate={filterData.endDate}
                                                dateFormat={dateFormat}
                                                handlePresets={(start: Moment, end: Moment) => {
                                                    setFilterData((prev) => ({
                                                        ...prev, 
                                                        startDate: start, 
                                                        endDate: end,
                                                    }))
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Fetch Data"
                                        sx={{ width: "130px", padding: "8.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        onClick={() => handleFetchData()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Reset"
                                        sx={{ width: "130px", padding: "8.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        onClick={() => handleResetFilter()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container justifyContent={"space-between"} alignItems={"Center"}>
                            <Grid item>
                                <Typography sx={{ fontSize: screen600to1300 ? "15px" : "14px", fontWeight: "600" }}>Feedback List</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    className={`${globalStyles.cmpGlobalMobileAddIcon} ${datePickerStyles.addFilterIcon}`}
                                    onClick={() => { setFlagFeedbackFilterPopup(true) }}
                                >
                                    <FilterAltOutlinedIcon style={{ height: "28px", width: 'auto' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                }


            </Grid>

        </Grid>
    )
}

export default FetchFeedbackData
