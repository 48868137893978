import { Autocomplete, Breadcrumbs, Button, Divider, Grid, IconButton, InputLabel, Link, MenuItem, Select, TextField, TextareaAutosize, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/templates.module.scss'
import AddIcon from '@mui/icons-material/Add';
import { getSubButtonsBasedOnCategory, whatsappTemplateButtonGenericData, whatsappTemplateCategoryList, whatsappTemplateLanguages } from '../../../utils/whatsappTemplate';
import { Delete } from '@mui/icons-material';
import globalStyles from "../../../assets/styles/global.module.scss";
import agent from '../../../services/api';
import TemplatePreview from './templatePreview';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import integrationStyles from '../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import IconsColor from '../../utility/iconsColor';
import { createWhatsappTemplateHeaderVariable, createWhatsappTemplatePayload, getTemplateDetails, whatsappTemplateBodyVariableData, whatsappTemplateFooterResponseModel, whatsappTemplateHeaderResponseModel, whatsappTemplateHeaderVariable } from '../../../models/axiosModel';
import Required from '../../utility/required';
import validator from 'validator';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import TemplatePreviewMobile from './templatePreviewMobile';
import FileUpload from '../../utility/fileUpload/fileUpload';
import axios from 'axios';
const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const Template = () => {
    const navigate = useNavigate()
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const headerTextareaRef = useRef<HTMLTextAreaElement>(null);
    const { setFlagLoading, setSuccess, setError, flagLoading, error, success, userDetail } = useContext(UserContext) as UserContextPayload
    const { setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload
    const { getIntegrationList, integrationList, setIntegrationDetails } = useContext(IntegrationContext) as IntegrationContextPayload
    const [addBtnOpenMenu, setAddBtnOpenMenu] = useState(false)
    const [templateStatusDetails, setTemplateStatusDetails] = useState<getTemplateDetails | null>(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [openSwipableDrawer, setOpenSwipableDrawer] = useState<boolean>(false)
    const [files, setFiles] = useState<any>([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1200px)')
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 760px) and (min-width : 600px)')
    const [whastappTemplateHeaderTypes, setWhatsappTemplateHeaderTypes] = useState<whatsappTemplateHeaderResponseModel[] | null>(null)
    const [whatsappTemplateFooterTypes, setWhatsappTemplateFooterTypes] = useState<whatsappTemplateFooterResponseModel[] | null>(null)
    const [whatsappTemplateDynamicVarTypes, setWhatsappTemplateDynamicVaribaleTypes] = useState()

    const innerHeight = smallScreen ? (window.innerHeight - 145) : tabletScreen ? (window.innerHeight - 170) : (window.innerHeight - 160); 

    const fileRef = useRef<any[]>([]);

    const toggleDrawer = (newOpen: any) => () => {
        setOpenSwipableDrawer(newOpen);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [searchParamsId, setSearchParamsId] = useSearchParams()
    let id = searchParamsId.get('id')
    const [inValidId, setInvalidId] = useState<any>([])
    const handleClose = () => {
        setAnchorEl(null);
    };
    const initialWhatsappTemplateObject = {
        whatsapp_template_id: null,
        "meta_integration_whatsapp_id": 0,
        "whatsapp_template_data": {
            "whatsapp_template_data_id": null,
            "whatsapp_template_category_id": 2,
            "whatsapp_language_id": 0,
            "whatsapp_template_name": "",
            "whatsapp_template_description": "",
            "whatsapp_template_header": {
                "whatsapp_template_header_type_id": 0,
                "whatsapp_template_header_type_name": "None",
                "whatsapp_template_header_data": [
                    {
                        "whatsapp_template_header_data_value": "", 
                        "whatsapp_template_header_data_id": 0,
                        "whatsapp_template_header_data_google_storage_id": 0,
                        "whatsapp_template_header_variable_data": []
                    }
                ]
            },
            "whatsapp_template_body": {
                "whatsapp_template_body_value": "",
                "whatsapp_template_body_description": "",
                "whatsapp_template_body_variable_data": []
            },
            "whatsapp_template_button": [],
            "whatsapp_template_footer": {
                "whatsapp_template_footer_type_id": "",
                "whatsapp_template_footer_data": [{ 
                    "whatsapp_template_footer_data_id": 0,
                    "whatsapp_template_footer_data_value": "",
                }]
            }
        }
    }
    const [createTemplatePayload, setCreateTemplatePayload] = useState<createWhatsappTemplatePayload>(initialWhatsappTemplateObject)
    const [existingTemplateData, setExistingPayloadData] = useState<createWhatsappTemplatePayload>(initialWhatsappTemplateObject)
    const initialHeaderType = {
        whatsapp_template_header_type_id: 0,
        whatsapp_template_header_type_name: ""
    } 

    const [existingHeaderTemplateVariableData, setExistingHeaderTemplateVariableData] = useState<(whatsappTemplateHeaderVariable | createWhatsappTemplateHeaderVariable)[] | null>([]);

    const [selectedHeaderType, setSelectedHeaderType] = useState<whatsappTemplateHeaderResponseModel>(initialHeaderType)
    const handleAddBodyVariableManually = (value: string, type: string) => {
        let bodyVariableData = type.toLowerCase() === "body" ? [...createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data] : [...createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data]
        let headerVaribaleData = [...createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data]
        let replacementString = `{{${bodyVariableData.length + 1}}}`
        const newValue = value.slice(0, -2) + replacementString;
        const newFieldId = bodyVariableData.length + 1;
        let fieldObj = {
            // "whatsapp_template_dynamic_variable_id": getExistingVariableDataId(`{{${newFieldId}}}`, type),
            "whatsapp_template_dynamic_variable_key": `{{${newFieldId}}}`,
            "whatsapp_template_dynamic_variable_sample_value": ""
        }
        //@ts-ignore
        bodyVariableData.push(fieldObj)
        if (type.toLowerCase() === "body") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prevState.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_value: newValue,
                        whatsapp_template_body_variable_data: bodyVariableData
                    },
                },
            }));
        } else if (type.toLowerCase() === "header") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_header: {
                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                        whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((data) => {
                            return {
                                ...data,
                                whatsapp_template_header_data_value: newValue,
                                whatsapp_template_header_variable_data: bodyVariableData,
                            }
                        })
                    }
                },
            }));
        }
    }
    const getExistingVariableDataId = (Key: string, type: string) => {
        if (type.toLowerCase() === "body") {
            if (existingTemplateData && existingTemplateData.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.length > 0) {
                // @ts-ignore
                let whatsapp_template_body_variable_data = existingTemplateData.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.find((data) => data.whatsapp_template_dynamic_variable_key == Key)
                if (whatsapp_template_body_variable_data) {
                    return whatsapp_template_body_variable_data?.whatsapp_template_dynamic_variable_key
                } else {
                    return null
                }
            } else {
                return null
            }
        } else if (type.toLowerCase() === "header") {
            if (existingTemplateData && existingTemplateData.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0) {
                // @ts-ignore
                let whatsapp_template_body_variable_data = existingTemplateData.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.find((data) => data.whatsapp_template_dynamic_variable_key == Key)
                if (whatsapp_template_body_variable_data) {
                    return whatsapp_template_body_variable_data?.whatsapp_template_dynamic_variable_key
                } else {
                    return null
                }
            } else {
                return null
            }
        }

    }
    const removeVariableData = (value: string, type: string) => {
        interface whatsappTemplateBodyVariableDataNew {
            whatsapp_template_dynamic_variable_id: number;
            whatsapp_template_dynamic_variable_key: string;
            whatsapp_template_dynamic_variable_sample_value: string;
        }

        const regex: RegExp = /{{\d+}}/g;
        const matches: string[] | null = value.match(regex);
        let val = value
        const uniqueMatches: string[] = matches ? Array.from(new Set(matches)) : [];
        let variableData = type.toLowerCase() === "body" ? 
            [...createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data] :  
            createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data ?
            [...createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data] : [];
        uniqueMatches.map((x: any, index: number) => {

            let current = Number((x.replaceAll('{{', '')).replaceAll('}}', ''))
            if (current > 1) {
                let prevIndex = Number((uniqueMatches[index - 1].replaceAll('{{', '')).replaceAll('}}', ''))
                if (current == prevIndex + 1) {
                } else {
                    val = val.replaceAll(`{{${current}}}`, `{{${current - 1}}}`)
                }
            }
            return val
        })  

        const result = uniqueMatches.map((key, index) => ({
            // whatsapp_template_dynamic_variable_id: getExistingVariableDataId(key, type),          
            whatsapp_template_dynamic_variable_key: `{{${index + 1}}}`,
            whatsapp_template_dynamic_variable_sample_value: type.toLowerCase() === "body" ?
            createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.find((data) => data.whatsapp_template_dynamic_variable_key == key)?.whatsapp_template_dynamic_variable_sample_value ?? ""
            :
            createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.find((data) => data.whatsapp_template_dynamic_variable_key == key)?.whatsapp_template_dynamic_variable_sample_value ?? ""
        }))
        let finalValue = {
            textAreaValue: value,
            variableData: variableData
        }
        if (uniqueMatches.length > 0) {
            finalValue.textAreaValue = val
            //@ts-ignore
            finalValue.variableData = result
        } else {
            finalValue.variableData = []
        } 
        return finalValue
        // console.log(result);
        // setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_body: { ...prevState.whatsapp_template_data.whatsapp_template_body, whatsapp_template_body_variable_data: result } } }))
    } 

    const [searchParams] = useSearchParams()

    const getWhatsappTemplateHeaderTypes = async () => {
        const response = await agent.whatsappTemplateHeaderType.get();
        if (response.status) {
            setWhatsappTemplateHeaderTypes(response.data)
        }
    }

    const getWhstappTemplateFooterTypes = async () => {
        const response = await agent.whatsappTemplateFooterType.get();
        if (response.status) {
            setWhatsappTemplateFooterTypes(response.data)
        }
    }

    const getWhstappTemplateDynamicVarTypes = async () => {
        const response = await agent.whatsappTemplateDynamicVariableType.get();
        if (response.status) {
            setWhatsappTemplateDynamicVaribaleTypes(response.data)
        }
    }

    useLayoutEffect(() => {
        (async () => {
            await getIntegrationList()
            await getWhatsappTemplateHeaderTypes()
            await getWhstappTemplateFooterTypes()
            await getWhstappTemplateDynamicVarTypes()
        })()
    }, []);
    const updateCreateTemplatepayload = (updatedVal: any) => {
        setTemplateStatusDetails(updatedVal)
        let templateButtonData = updatedVal.whatsapp_template_data.whatsapp_template_button.map((btn: any) => (
            {
                ...btn, whatsapp_template_button_data: btn.whatsapp_template_button_data.map((data: any) => {
                    return {
                        ...data, whatsapp_template_button_sub_type_field_data: data.whatsapp_template_button_sub_type_field_data.map((field: any) => {
                            //   @ts-ignore
                            let subFieldData = whatsappTemplateButtonGenericData.find((genericBtn) => genericBtn.whatsapp_template_button_type_id == btn.whatsapp_template_button_type_id).whatsapp_template_button_sub_type_data.find((subBtn) => subBtn.whatsapp_template_button_sub_type_id == data.whatsapp_template_button_sub_type_id).whatsapp_template_button_sub_type_field_data.find((genericField) => genericField.whatsapp_template_button_sub_type_field_id == field.whatsapp_template_button_sub_type_field_id)

                            return {
                                ...subFieldData,
                                ...field,
                            }
                        })
                    }
                })
            }
        )) 

        let updatedHeader = updatedVal.whatsapp_template_data.whatsapp_template_header;
        if(updatedVal.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name === null){
            updatedHeader.whatsapp_template_header_type_name = "None";
        }
        setCreateTemplatePayload((prevState: any) => ({
            whatsapp_template_id: updatedVal.whatsapp_template_id,
            "meta_integration_whatsapp_id": updatedVal.meta_integration_whatsapp_id,
            "whatsapp_template_data": {
                whatsapp_template_data_id: updatedVal.whatsapp_template_data.whatsapp_template_data_id,
                "whatsapp_template_category_id": updatedVal.whatsapp_template_data.whatsapp_template_category_id,
                "whatsapp_language_id": updatedVal.whatsapp_template_data.whatsapp_language_data.whatsapp_language_id,
                "whatsapp_template_name": updatedVal.whatsapp_template_data.whatsapp_template_name,
                "whatsapp_template_description": updatedVal.whatsapp_template_data.whatsapp_template_description,
                "whatsapp_template_header": updatedVal.whatsapp_template_data.whatsapp_template_header,
                "whatsapp_template_body": updatedVal.whatsapp_template_data.whatsapp_template_body,
                "whatsapp_template_button": templateButtonData,
                "whatsapp_template_footer": updatedVal.whatsapp_template_data.whatsapp_template_footer
            }
        }))
        setExistingPayloadData((prevState: any) => ({
            whatsapp_template_id: updatedVal.whatsapp_template_id,
            "meta_integration_whatsapp_id": updatedVal.meta_integration_whatsapp_id,
            "whatsapp_template_data": {
                whatsapp_template_data_id: updatedVal.whatsapp_template_data.whatsapp_template_data_id,
                "whatsapp_template_category_id": updatedVal.whatsapp_template_data.whatsapp_template_category_id,
                "whatsapp_language_id": updatedVal.whatsapp_template_data.whatsapp_language_data.whatsapp_language_id,
                "whatsapp_template_name": updatedVal.whatsapp_template_data.whatsapp_template_name,
                "whatsapp_template_description": updatedVal.whatsapp_template_data.whatsapp_template_description,
                "whatsapp_template_header": updatedVal.whatsapp_template_data.whatsapp_template_header,
                "whatsapp_template_body": updatedVal.whatsapp_template_data.whatsapp_template_body,
                "whatsapp_template_button": templateButtonData,
                "whatsapp_template_footer": updatedVal.whatsapp_template_data.whatsapp_template_footer
            }
        }));
        setText(updatedVal.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value);   
        setFooterText(updatedVal.whatsapp_template_data.whatsapp_template_footer?.whatsapp_template_footer_data?.[0]?.whatsapp_template_footer_data_value || ""); 
        setExistingHeaderTemplateVariableData(updatedVal.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data);
        const headerType = updatedVal.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name; 
        if(headerType?.toLowerCase() === "image" || headerType?.toLowerCase() === "video"){
            getHeaderFiles(updatedVal.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0]?.whatsapp_template_header_data_google_storage_id, 
                updatedVal.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0]?.whatsapp_template_header_data_value, 
                headerType
            );
        } 
    } 

    const getHeaderFiles = async (id: string, file_path: string, headerType: string) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true })); 
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${id}&flag_info=true`); 
        if(res.status){
            const file = {
                file_data: {
                    file_path: file_path, 
                    flag_deleted: false, 
                    flag_saved: false, 
                    google_storage_file_name: res.data.data.file_name, 
                    google_storage_file_self_link: res.data.data.self_link, 
                    google_storage_flag_public: true, 
                    google_storage_id: res.data.data.google_storage_id, 
                    google_storage_original_file_id: res.data.data.storage_bucket_id, 
                    google_storage_original_file_name: res.data.data.original_file_name, 
                    goolge_storage_file_size: res.data.data.file_size, 
                    goolge_storage_file_type: headerType.toLowerCase() === "image" ? ".jpg" : ".mp4", 
                    history_id: null, 
                    timestamp: res.data.data.uploaded_at, 
                    user_assignment_id: res.data.data.user_assignment_id,
                }, 
                google_storage_id: res.data.data.google_storage_id, 
                isUploading: false, 
                previewImageSrc: getFileTypeImgIcon(headerType.toLowerCase() === "image" ? "jpg" : "mp4"), 
                name: res.data.data.original_file_name, 
                size:  Number(res.data.data.file_size),
            } 
            setFiles([file]); 
            fileRef.current = [file];
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    } 

    const getTemplateDetails = async (whatsapp_meta_integration_id: number, template_id: number, meta_integration_id: number | string) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getWhatsappTemplateDetails.get(whatsapp_meta_integration_id, template_id)
        if (response.status) {
            updateCreateTemplatepayload(response.data[0])
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
        } else {
            navigate(`/channels/viewintegration/whatsapp/templateinfo?id=${meta_integration_id}`)
        }
    }

    useEffect(() => {
        if (searchParams.has('id')) {
            if (integrationList && integrationList.length > 0) {
                let id = searchParams.get('id')
                let meta_integration_id = id ?? 1
                let integration = integrationList.find((integration: any) => integration.meta_integration_id == id)
                if (integration && integration.meta_integration_whatsapp_data) {
                    let whatsapp_meta_integration_id = integration.meta_integration_whatsapp_data.meta_integration_whatsapp_id
                    setCreateTemplatePayload((prevState: any) => ({ ...prevState, meta_integration_whatsapp_id: whatsapp_meta_integration_id }))
                    setIntegrationDetails(integration)
                    if (searchParams.has('template_id')) {
                        let template_id = Number(searchParams.get('template_id')) ?? 1;
                        getTemplateDetails(whatsapp_meta_integration_id, template_id, meta_integration_id)
                    }
                }
            }
        } else {
            setIntegrationDetails(null)
            setShowIntegrationDetails(false)
        }
    }, [integrationList])

    const [text, setText] = useState('');
    const [footerText, setFooterText] = useState('');
    const maxChars = 1024;
    const maxFooterChars = 60;
    const [selectedOption, setSelectedOption] = useState('none');
    const handleChangeBtnOption = (val: string) => {
        if (val.toLowerCase() == "none") {
            setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_button: [] } }))
        }
    }
    const [actions, setActions] = useState<any>([]);
    const [quickReply, setQuickReply] = useState<any>([]);

    const handleChangeText = (event: any, type: any) => {
        if (type == "format") {
            if (event.target.value.length <= maxChars) {
                setText(event.target.value);
            }
        }
        else if (type == "footer") {
            if (event.target.value.length <= maxFooterChars)
                setFooterText(event.target.value)
        }
    };

    const handleQuickReplyChange = (id: any, value: any) => {
        const newQuickReply = quickReply.map((ele: any) => {
            if (id == ele.id) {
                return { ...ele, value }
            }
            return ele
        })
        setQuickReply(newQuickReply)
    }
    const getExistingActionButtonId = (buttonTypeId: number) => {
        let existingButton = existingTemplateData.whatsapp_template_data.whatsapp_template_button.find((button) => Number(button.whatsapp_template_button_type_id) == buttonTypeId)
        if (existingButton) {
            let buttonId = existingButton.whatsapp_template_button_id
            return buttonId
        } else {
            return null
        }
    }
    const getExistingActionButtonDataId = (buttonTypeId: number, subTypeId: number, index?: number) => {
        let existingButton = existingTemplateData.whatsapp_template_data.whatsapp_template_button.find((button) => Number(button.whatsapp_template_button_type_id) == buttonTypeId)
        if (existingButton) {
            let subButtonData = existingButton.whatsapp_template_button_data.filter((subBtn) => subBtn.whatsapp_template_button_sub_type_id == subTypeId)
            if (subButtonData && subButtonData.length > 0)
                var currentSubBtnData = [];
            let currentButtonData = createTemplatePayload.whatsapp_template_data.whatsapp_template_button.find((btn) => Number(btn.whatsapp_template_button_type_id) == buttonTypeId)
            let subBtnId = null
            if (index !== undefined) {
                let subBtndata = subButtonData[index]
                if (subBtndata && subBtndata.whatsapp_template_button_data_id) {
                    subBtnId = subButtonData[index].whatsapp_template_button_data_id
                } else {
                    subBtnId = subButtonData[0].whatsapp_template_button_data_id ?? null
                }
            } else {
                if (currentButtonData) {
                    currentSubBtnData = currentButtonData.whatsapp_template_button_data.filter((subBtn) => subBtn.whatsapp_template_button_sub_type_id == subTypeId)
                    let subBtndata = subButtonData[currentSubBtnData.length]
                    if (subBtndata) {
                        subBtnId = subButtonData[currentSubBtnData.length].whatsapp_template_button_data_id
                    }
                } else {
                    subBtnId = subButtonData[0].whatsapp_template_button_data_id
                }
            }
            return subBtnId
        } else {
            return null
        }
    }
    const handleAddActionsButton = (buttonTypeId: number, subTypeId: number) => {
        setCreateTemplatePayload((prevState: any) => {
            const newButtonType = {
                whatsapp_template_button_id: getExistingActionButtonId(buttonTypeId),
                "whatsapp_template_button_type_id": buttonTypeId,
                "whatsapp_template_button_data": [
                    {
                        whatsapp_template_button_data_id: getExistingActionButtonDataId(buttonTypeId, subTypeId),
                        "whatsapp_template_button_sub_type_id": subTypeId,
                        // @ts-ignore
                        "whatsapp_template_button_sub_type_field_data": whatsappTemplateButtonGenericData.find((btn: any) => btn.whatsapp_template_button_type_id == buttonTypeId).whatsapp_template_button_sub_type_data.find((subType: any) => subType.whatsapp_template_button_sub_type_id == subTypeId).whatsapp_template_button_sub_type_field_data.map((field) => ({
                            ...field,
                            "whatsapp_template_button_sub_type_field_value": "",
                            "whatsapp_template_button_sub_type_field_option_id": null
                        }))
                    }
                ]
            };

            const existingButtonIndex = prevState.whatsapp_template_data.whatsapp_template_button.findIndex(
                (button: any) => button.whatsapp_template_button_type_id == buttonTypeId
            );

            if (existingButtonIndex !== -1) {
                // If the Call to action button already exists, add the new sub-type data to it
                const updatedButtons = [...prevState.whatsapp_template_data.whatsapp_template_button];
                updatedButtons[existingButtonIndex].whatsapp_template_button_data.push(
                    newButtonType.whatsapp_template_button_data[0]
                );

                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: updatedButtons
                    }
                };
            } else {
                // If the Call to action button does not exist, add it to the array
                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: [
                            ...prevState.whatsapp_template_data.whatsapp_template_button,
                            newButtonType
                        ]
                    }
                };
            }
        });
    };
    const handleAddActions = () => {
        setCreateTemplatePayload((prevState: any) => {
            const newButtonType = {
                "whatsapp_template_button_type_id": 2,
                "whatsapp_template_button_data": [
                    {
                        "whatsapp_template_button_sub_type_id": null,
                        "whatsapp_template_button_sub_type_field_data": [
                        ]
                    }
                ]
            };

            const existingButtonIndex = prevState.whatsapp_template_data.whatsapp_template_button.findIndex(
                (button: any) => button.whatsapp_template_button_type_id === 2
            );

            if (existingButtonIndex !== -1) {
                // If the Call to action button already exists, add the new sub-type data to it
                const updatedButtons = [...prevState.whatsapp_template_data.whatsapp_template_button];
                updatedButtons[existingButtonIndex].whatsapp_template_button_data.push(
                    newButtonType.whatsapp_template_button_data[0]
                );

                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: updatedButtons
                    }
                };
            } else {
                // If the Call to action button does not exist, add it to the array
                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: [
                            ...prevState.whatsapp_template_data.whatsapp_template_button,
                            newButtonType
                        ]
                    }
                };
            }
        });
    };
    const handleChangeBtn = (whatsapp_template_button_type_id: any) => {

        // let ctaBtnObj: any = whatsappTemplateButtonGenericData.find((genericBtn) => genericBtn.whatsapp_template_button_type_id == whatsapp_template_button_type_id)
        // let updatedCallToAction: any = [...createTemplatePayload.whatsapp_template_data.whatsapp_template_button]
        // if (updatedCallToAction.some((action: any) => action.whatsapp_template_button_type_id == whatsapp_template_button_type_id)) {
        //     updatedCallToAction.map((action: any) => action.whatsapp_template_button_type_id == whatsapp_template_button_type_id ? {
        //         ...action, whatsapp_template_button_sub_type_data:
        //             ctaBtnObj.whatsapp_template_button_sub_type_data
        //     } : action)
        // } else {
        //     updatedCallToAction.push(ctaBtnObj)
        // }
        // setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_button: updatedCallToAction } }))
    }
    const handleAddQuickReply = () => {
        const newQuickReply = {
            id: new Date().getTime(),
            value: ''
        }

        setQuickReply([...quickReply, newQuickReply])
    }

    const handleChangeCallField = (event: any, field: any, id: any) => {
        const temp = actions.map((ele: any) => {
            if (id == ele.id) {
                return {
                    ...ele,
                    [field]: event
                }
            }
            return ele
        })
        setActions(temp)
    }
    const handleChangeFieldVal = (value: number, index: number) => {
        // @ts-ignore
        let val = [...createTemplatePayload.whatsapp_template_data.whatsapp_template_button[0].whatsapp_template_button_data]
        let updatedVal = val.map((btn: any, i: any) => i == index ? {
            // @ts-ignore
            ...btn, whatsapp_template_button_sub_type_id: value, whatsapp_template_button_sub_type_field_data: whatsappTemplateButtonGenericData[0].whatsapp_template_button_sub_type_data.find((subType: any) => subType.whatsapp_template_button_sub_type_id == value).whatsapp_template_button_sub_type_field_data.map((field: any) => ({
                ...field,
                "whatsapp_template_button_sub_type_field_value": "",
                "whatsapp_template_button_sub_type_field_option_id": null
            }))
        } : btn)
        setCreateTemplatePayload((prevState: any) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_button: prevState.whatsapp_template_data.whatsapp_template_button.map((btn: any, index: number) => index == 0 ? { ...btn, whatsapp_template_button_data: updatedVal } : btn) } }))
    }

    const handleDeleteAction = (id: any) => {
        if (actions.length === 1) {
            setActions(actions.map((action: any) => action.id === id ? { ...action, title: '', value: '', urlType: '' } : action));
        } else {
            setActions(actions.filter((action: any) => action.id !== id));
        }
    }

    const handleDeleteQuickReply = (id: any) => {
        if (quickReply.length == 1) {
            setQuickReply(quickReply.map((action: any) => action.id == id ? { ...action, value: '' } : action))
        }
        else {
            setQuickReply(quickReply.filter((action: any) => action.id !== id))
        }
    } 

    const handleSubmitTemplate = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))

        let payload: any = { ...createTemplatePayload };   
        const headerType = createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name; 
        if(headerType?.toLowerCase() === "none"){ 
            payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_id = null; 
            payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_id = null;
            payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_id = null; 
            setExistingHeaderTemplateVariableData(null);
        }

        // payload.whatsapp_template_data.whatsapp_template_button = payload.whatsapp_template_data.whatsapp_template_button.map((button: any) => {
        //     return {
        //         ...button,
        //         whatsapp_template_button_data: button.whatsapp_template_button_data.map((data: any) => {
        //             return {
        //                 ...data,
        //                 whatsapp_template_button_sub_type_field_data: data.whatsapp_template_button_sub_type_field_data.map((field: any) => {
        //                     if (field.field_type === "Autocomplete" && field.whatsapp_template_button_sub_type_field_option_id) {
        //                         return {
        //                             ...field,
        //                             whatsapp_template_button_sub_type_field_option_id: field.whatsapp_template_button_sub_type_field_option_id.field_option_id
        //                         };
        //                     }
        //                     return field;
        //                 })
        //             };
        //         })
        //     };
        // });
        let response
        if(headerType?.toLowerCase() === "image" || headerType?.toLowerCase() === "video"){ 

            let dynamic_variable_id = null, unique_id = 0, history_id = null, added_by_change_log_id = 0, variable_flag_deleted = false;
            if(existingHeaderTemplateVariableData && existingHeaderTemplateVariableData.length > 0 && existingHeaderTemplateVariableData[0]){
                dynamic_variable_id = "whatsapp_template_dynamic_variable_id" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].whatsapp_template_dynamic_variable_id  : 0; 
                unique_id = "whatsapp_template_dynamic_variable_unique_id" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].whatsapp_template_dynamic_variable_unique_id : 0; 
                history_id = "history_id" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].history_id : null; 
                added_by_change_log_id = "added_by_change_log_id" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].added_by_change_log_id : 0; 
                variable_flag_deleted = "whatsapp_template_dynamic_variable_flag_deleted" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].whatsapp_template_dynamic_variable_flag_deleted : false; 
            } 

            payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data = [{
                whatsapp_template_dynamic_variable_id: createTemplatePayload.whatsapp_template_id ? dynamic_variable_id : 0,
                whatsapp_template_dynamic_variable_type_id: whastappTemplateHeaderTypes?.find((hType) => hType.whatsapp_template_header_type_name == headerType)?.whatsapp_template_header_type_id,
                whatsapp_template_dynamic_variable_unique_id: createTemplatePayload.whatsapp_template_id ? unique_id : 0,
                whatsapp_template_dynamic_variable_key: `{{Header${headerType}}}`,
                whatsapp_template_dynamic_variable_sample_value: payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value,
                history_id: createTemplatePayload.whatsapp_template_id ? history_id : null,
                added_by_change_log_id: createTemplatePayload.whatsapp_template_id ? added_by_change_log_id: 0,
                whatsapp_template_dynamic_variable_flag_deleted: createTemplatePayload.whatsapp_template_id ?  variable_flag_deleted: false,
                whatsapp_template_id: createTemplatePayload.whatsapp_template_id ? payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data?.[0]?.whatsapp_template_id : 0,
                flag_header: createTemplatePayload.whatsapp_template_id ? payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data?.[0]?.flag_header : null,
                flag_button: createTemplatePayload.whatsapp_template_id ? payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data?.[0]?.flag_button : null,
                whatsapp_template_dynamic_variable_type_name: "Header"
            }]
        } else if(headerType?.toLowerCase() === "text" && payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0){ 
            let dynamic_variable_id = null; 
            if(existingHeaderTemplateVariableData && existingHeaderTemplateVariableData.length > 0 && existingHeaderTemplateVariableData[0]){
                dynamic_variable_id = "whatsapp_template_dynamic_variable_id" in existingHeaderTemplateVariableData[0] ? existingHeaderTemplateVariableData[0].whatsapp_template_dynamic_variable_id : null;
            }
            payload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data[0].whatsapp_template_dynamic_variable_id = dynamic_variable_id;
        }

        if (createTemplatePayload.whatsapp_template_id) { 
            response = await agent.updateWhatsappTemplate.put(payload)
        } else { 
            response = await agent.createWhatsappTemplate.post(payload)
        }
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: createTemplatePayload.whatsapp_template_id ? "Template Updated Successfully!" : "Template Created Successfully!" }))
            updateCreateTemplatepayload(response.data[0])
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, false) }))
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    } 

    const checkIfHeaderDataIsFillOrNot = () => { 
        const headerType = createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name; 
        if(createTemplatePayload.whatsapp_template_data.whatsapp_template_header && 
            (headerType?.toLowerCase() === "none" 
            || 
            ( headerType?.toLowerCase() === "text" && 
                createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data[0]?.whatsapp_template_header_data_value.trim().length > 0 && 
                createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data[0]?.whatsapp_template_header_variable_data.filter((variable) => variable.whatsapp_template_dynamic_variable_sample_value.trim() == "").length === 0
            ) 
            || 
            ((headerType?.toLowerCase() === "image" || headerType?.toLowerCase() === "video") && files.length > 0)
        )){
            return true;
        }
        return false;
    }

    const handleSubmitTemplateDisabled = () => {
        if (createTemplatePayload.whatsapp_template_data.whatsapp_template_category_id === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_language_id === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_template_name.trim().length === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_template_description.trim().length === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value.trim().length === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_description.trim().length === 0 ||
            createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.filter((data) => data.whatsapp_template_dynamic_variable_sample_value.trim() == "").length != 0 ||
            checkFlagError("both") || 
            !checkIfHeaderDataIsFillOrNot()
        ) {
            return true;
        }
        for (const button of createTemplatePayload.whatsapp_template_data.whatsapp_template_button) {
            for (const buttonData of button.whatsapp_template_button_data) {
                for (const fieldData of buttonData.whatsapp_template_button_sub_type_field_data) {
                    if (fieldData.field_static_value_flag === true) {
                        return false;
                    }
                    if (fieldData.field_type.toLowerCase() == "textfield" && (!fieldData.whatsapp_template_button_sub_type_field_value || fieldData.whatsapp_template_button_sub_type_field_value.trim() === '')
                    ) {
                        return true;
                    }
                    if (fieldData.field_type.toLowerCase() == "autocomplete" && (fieldData.whatsapp_template_button_sub_type_field_option_id == null || Number(fieldData.whatsapp_template_button_sub_type_field_option_id) === 0)
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    const handleValidWebsite = (website_value: any, field: any, index: any) => {
        if (field === "Website URL" && website_value.trim().length > 0) {
            if (validator.isURL(website_value)) {
                if (inValidId.includes(index)) {
                    setInvalidId(inValidId.filter((ele: any) => ele != index))
                } else {
                }
            } else {
                if (inValidId.includes(index)) {
                } else {
                    setInvalidId((prev: any) => [...prev, index])
                }
            }
        } else if (field === "Website URL" && website_value.trim().length == 0) {
            if (inValidId.includes(index)) {
                setInvalidId(inValidId.filter((ele: any) => ele != index))
            } else {
            }
        }
    }
    const getInput = (inputTypeName: string, genericData: any, btnTypeId: number, finalIndex: number, index: number, subTypeId: number) => {
        switch (inputTypeName) {
            case "Autocomplete":
                return <Grid item xs={12}>
                    <Grid container direction={"column"} gap="15px">
                        <Grid item>
                            <InputLabel className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>
                                {genericData.field_name}
                                {!genericData.field_static_value_flag ?
                                    <Required />
                                    :
                                    <Tooltip title={"This is default text. It cannot be edited."} placement='top'>
                                        <InfoIcon sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                    </Tooltip>
                                }
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={genericData.field_static_value_flag}
                                fullWidth
                                size='small'
                                disablePortal
                                className={styles.callToActionAutocomplete}

                                sx={{
                                    '& .MuiInputBase-sizeSmall': {
                                        paddingBlock: "9px !important",
                                        borderRadius: "10px"
                                    }
                                }}
                                getOptionLabel={(option: any) => option.field_option_value}
                                options={genericData.field_option_data}
                                // @ts-ignore
                                value={genericData.field_static_value_flag ? genericData.field_static_value : whatsappTemplateButtonGenericData.find((btn) => btn.whatsapp_template_button_type_id == btnTypeId).whatsapp_template_button_sub_type_data.find((subType) => subType.whatsapp_template_button_sub_type_id == subTypeId).whatsapp_template_button_sub_type_field_data.find((field) => field.whatsapp_template_button_sub_type_field_id == genericData.whatsapp_template_button_sub_type_field_id).field_option_data.find((option) => option.field_option_id == genericData.whatsapp_template_button_sub_type_field_option_id) ?? null}
                                renderInput={(params) => <TextField {...params} placeholder={genericData.field_placeholder}

                                />}
                                onChange={(event, value) => {
                                    setCreateTemplatePayload((prevState: any) => ({
                                        ...prevState,
                                        whatsapp_template_data: {
                                            ...prevState.whatsapp_template_data,
                                            whatsapp_template_button: prevState.whatsapp_template_data.whatsapp_template_button.map((btn: any, btnIndex: any) => {
                                                if (btn.whatsapp_template_button_type_id == btnTypeId) {
                                                    return {
                                                        ...btn,
                                                        whatsapp_template_button_data: btn.whatsapp_template_button_data.map((buttonData: any, buttonDataIndex: any) => {
                                                            if (buttonDataIndex == finalIndex) {
                                                                return {
                                                                    ...buttonData,
                                                                    whatsapp_template_button_sub_type_field_data: buttonData.whatsapp_template_button_sub_type_field_data.map((field: any, fieldIndex: any) => {
                                                                        if (fieldIndex == index) {
                                                                            return {
                                                                                ...field,
                                                                                whatsapp_template_button_sub_type_field_option_id: value ? value.field_option_id : null
                                                                            };
                                                                        }
                                                                        return field;
                                                                    })
                                                                };
                                                            }
                                                            return buttonData;
                                                        })
                                                    };
                                                }
                                                return btn;
                                            })
                                        }
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            case "Textfield":
                return <Grid item xs={12}>
                    <Grid container direction={"column"} gap="15px">
                        <Grid item>
                            <InputLabel className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>
                                {genericData.field_name}
                                {!genericData.field_static_value_flag ?
                                    <Required />
                                    :
                                    <Tooltip title={"This is default text. It cannot be edited."} placement='top'>
                                        <InfoIcon sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                    </Tooltip>
                                }
                            </InputLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                                disabled={genericData.field_static_value_flag}
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                className={styles.templateGlobalTextfield}

                                placeholder={genericData.field_placeholder}
                                // helperText={genericData.whatsapp_template_button_sub_type_field_value ? `${genericData.whatsapp_template_button_sub_type_field_value.length}/${genericData.field_character_limit}` : ""}
                                size='medium'
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    border: genericData.field_name === "Website URL" && inValidId.includes(finalIndex) ? "1px solid red" : ""
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        textAlign: 'right',
                                        fontSize: '12px',
                                        fontWeight: '600'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <span style={{ fontSize: '12px', fontWeight: '600', paddingLeft: "5px" }}>{genericData.whatsapp_template_button_sub_type_field_value ? `${genericData.whatsapp_template_button_sub_type_field_value.length}/${genericData.field_character_limit}` : ""}</span>
                                    ),
                                }}
                                onChange={(event) => {
                                    if (event.target.value.length <= genericData.field_character_limit) {
                                        if (genericData.field_name == "Phone number") {
                                            setCreateTemplatePayload((prevState: any) => ({
                                                ...prevState,
                                                whatsapp_template_data: {
                                                    ...prevState.whatsapp_template_data,
                                                    whatsapp_template_button: prevState.whatsapp_template_data.whatsapp_template_button.map((btn: any, btnIndex: any) => {
                                                        if (btn.whatsapp_template_button_type_id === btnTypeId) {
                                                            return {
                                                                ...btn,
                                                                whatsapp_template_button_data: btn.whatsapp_template_button_data.map((buttonData: any, buttonDataIndex: any) => {
                                                                    if (buttonDataIndex === finalIndex) {
                                                                        return {
                                                                            ...buttonData,
                                                                            whatsapp_template_button_sub_type_field_data: buttonData.whatsapp_template_button_sub_type_field_data.map((field: any, fieldIndex: any) => {
                                                                                if (fieldIndex === index) {
                                                                                    return {
                                                                                        ...field,
                                                                                        whatsapp_template_button_sub_type_field_value: event.target.value.replace(/[^0-9_]/g, '')
                                                                                    };
                                                                                }
                                                                                return field;
                                                                            })
                                                                        };
                                                                    }
                                                                    return buttonData;
                                                                })
                                                            };
                                                        }
                                                        return btn;
                                                    })
                                                }
                                            }));
                                        } else {
                                            setCreateTemplatePayload((prevState: any) => ({
                                                ...prevState,
                                                whatsapp_template_data: {
                                                    ...prevState.whatsapp_template_data,
                                                    whatsapp_template_button: prevState.whatsapp_template_data.whatsapp_template_button.map((btn: any, btnIndex: any) => {
                                                        if (btn.whatsapp_template_button_type_id === btnTypeId) {
                                                            return {
                                                                ...btn,
                                                                whatsapp_template_button_data: btn.whatsapp_template_button_data.map((buttonData: any, buttonDataIndex: any) => {
                                                                    if (buttonDataIndex === finalIndex) {
                                                                        return {
                                                                            ...buttonData,
                                                                            whatsapp_template_button_sub_type_field_data: buttonData.whatsapp_template_button_sub_type_field_data.map((field: any, fieldIndex: any) => {
                                                                                if (fieldIndex === index) {
                                                                                    return {
                                                                                        ...field,
                                                                                        whatsapp_template_button_sub_type_field_value: event.target.value
                                                                                    };
                                                                                }
                                                                                return field;
                                                                            })
                                                                        };
                                                                    }
                                                                    return buttonData;
                                                                })
                                                            };
                                                        }
                                                        return btn;
                                                    })
                                                }
                                            }));
                                        }
                                    }
                                }}
                                onBlur={() => handleValidWebsite(genericData.whatsapp_template_button_sub_type_field_value, genericData.field_name, finalIndex)}
                                value={genericData.field_static_value_flag ? genericData.field_static_value : genericData.whatsapp_template_button_sub_type_field_value ?? null}
                                autoComplete='off'
                            // helperText={genericData.field_name === "Website URL" && inValidId.includes(finalIndex) &&<span>Please Enter Valid URL</span>}
                            />
                            {genericData.field_name === "Website URL" && inValidId.includes(finalIndex) && <span style={{ fontSize: "13px", color: "red" }}>Please Enter Valid URL</span>}
                        </Grid>
                    </Grid>
                </Grid>
        }
    }
    const handleDeleteActionBtn = (buttonTypeId: number, subButtonId: number, fieldIndex: number) => {
        setCreateTemplatePayload((prevState: any) => {
            const existingButtonIndex = prevState.whatsapp_template_data.whatsapp_template_button.findIndex(
                (button: any) => button.whatsapp_template_button_type_id == buttonTypeId
            );

            if (existingButtonIndex !== -1 && prevState.whatsapp_template_data.whatsapp_template_button[existingButtonIndex].whatsapp_template_button_data.length == 1) {
                // If the Call to action button already exists, add the new sub-type data to it
                const updatedButtons = [...prevState.whatsapp_template_data.whatsapp_template_button];
                let filteredActionButton = updatedButtons.filter((btn: any) => btn.whatsapp_template_button_type_id !== buttonTypeId)

                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: filteredActionButton
                    }
                };
            } else {
                // If the Call to action button does not exist, add it to the array
                return {
                    ...prevState,
                    whatsapp_template_data: {
                        ...prevState.whatsapp_template_data,
                        whatsapp_template_button: prevState.whatsapp_template_data.whatsapp_template_button.map((btn: any) => btn.whatsapp_template_button_type_id == buttonTypeId ? ({
                            ...btn,
                            whatsapp_template_button_data: prevState.whatsapp_template_data.whatsapp_template_button[0].whatsapp_template_button_data.filter((subBtn: any, index: number) => (!(subBtn.whatsapp_template_button_sub_type_id === subButtonId && index === fieldIndex))).map((btn: any, index: number) => ({ ...btn, whatsapp_template_button_data_id: getExistingActionButtonDataId(buttonTypeId, btn.whatsapp_template_button_sub_type_id, index) }))
                        }) : btn)
                    }
                };
            }
        });
    }
    // --------------------VARIABLE-------------------------
    const [fields, setFields] = useState<any>([]);

    const handleAddField = (type: string) => {
        let fields: any = type.toLowerCase() === "body" ?
            createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data
            :
            createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data
        const newFieldId = fields.length + 1; 

        let fieldObj = {
            // "whatsapp_template_dynamic_variable_id": getExistingVariableDataId(`{{${newFieldId}}}`, type),
            "whatsapp_template_dynamic_variable_key": `{{${newFieldId}}}`,
            "whatsapp_template_dynamic_variable_sample_value": ""
        }
        fields.push(fieldObj)
        if (type.toLowerCase() === "body") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prevState.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_value: `${prevState.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value} {{${newFieldId}}}`,
                        whatsapp_template_body_variable_data: fields
                    }
                }
            }
            ))
        } else if (type.toLowerCase() === "header") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_header: {
                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                        whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((variable) => {
                            return {
                                ...variable,
                                whatsapp_template_header_data_value: `${prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} {{${newFieldId}}}`,
                                whatsapp_template_header_variable_data: fields
                            }
                        })
                    }
                }
            }
            ))
        }
        // const newFields = [...fields, { id: newFieldId, value: '' }];
        // setFields(newFields);
        // setText(prevText => `${prevText} {{ ${newFieldId}}}`);
    };

    const checkDisableAddVarButton = () => {
        const fields = createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data;
        if (fields?.length >= 1) {
            return true;
        }
        return false;
    }

    const handleFormatting = (format: 'bold' | 'italic' | 'strikethrough' | 'monospace', type: string) => {
        if (!textareaRef.current && type.toLowerCase() === "body") return;
        if (!headerTextareaRef.current && type.toLowerCase() === "header") return;


        const textarea = type.toLowerCase() === "body" ? textareaRef.current : headerTextareaRef.current;
        const value = type.toLowerCase() === "body" ? createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value : createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value;
        const start = (textarea as HTMLTextAreaElement).selectionStart;
        const end = (textarea as HTMLTextAreaElement).selectionEnd;

        let formattedText = '';
        let cursorAdjustment = 0;

        switch (format) {
            case 'bold':
                formattedText = `*${value.substring(start, end)}*`;
                cursorAdjustment = 1;
                break;
            case 'italic':
                formattedText = `_${value.substring(start, end)}_`;
                cursorAdjustment = 1;
                break;
            case 'strikethrough':
                formattedText = `~${value.substring(start, end)}~`;
                cursorAdjustment = 1;
                break;
            case 'monospace':
                formattedText = `\`\`\`${value.substring(start, end)}\`\`\``;
                cursorAdjustment = 3;
                break;
            default:
                formattedText = value.substring(start, end);
        }

        const newValue = value.substring(0, start) + formattedText + value.substring(end);

        if (type.toLowerCase() === "body") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prevState.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_value: newValue
                    }
                }
            }));
        } else if (type.toLowerCase() === "header") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_header: {
                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                        whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((variable) => {
                            return {
                                ...variable,
                                whatsapp_template_header_data_value: newValue
                            }
                        })
                    }
                }
            }));
        }

        // Reset cursor position
        setTimeout(() => {
            (textarea as HTMLTextAreaElement).setSelectionRange(start + cursorAdjustment, start + cursorAdjustment);
            (textarea as HTMLTextAreaElement).focus();
        }, 0);
    };

    const handleRemoveField = (id: any, type: string) => {
        // const newFields = fields.filter((field: any) => field.id !== id);
        // setFields(newFields);
        // setText(prevText => prevText.replace(`{{ ${id}}}`, '').trim());
        let fields: any = type.toLowerCase() === "body" ? createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data : createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data
        let newFields = fields.filter((field: any) => field.whatsapp_template_dynamic_variable_key !== id)
        if (type.toLowerCase() === "body") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prevState.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_value: `${prevState.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value.replaceAll(`${id}`, '').trim()}`,
                        whatsapp_template_body_variable_data: newFields
                    }
                }
            }));
        } else if (type.toLowerCase() === "header") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_header: {
                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                        whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((variable, ind) => {
                            return {
                                ...variable,
                                whatsapp_template_header_data_value: `${prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[ind].whatsapp_template_header_data_value.replaceAll(`${id}`, '').trim()}`,
                                whatsapp_template_header_variable_data: newFields,
                            }
                        })
                    }
                }
            }));
        }
        // const newFields = [...fields, { id: newFieldId, value: '' }];
        // setFields(newFields);
        // setText(prevText => `${prevText} {{ ${newFieldId}}}`);
    };

    const handleChange = (id: any, event: any, type: string) => {
        let fields = type.toLowerCase() === "body" ? createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data : createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data
        const newFields = fields.map((field: any) => {
            if (field.whatsapp_template_dynamic_variable_key === id) {
                return { ...field, whatsapp_template_dynamic_variable_sample_value: event.target.value };
            }
            return field;
        });
        // @ts-ignore 
        if (type.toLowerCase() === "body") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prevState.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_variable_data: newFields
                    }
                }
            }));
        } else if (type.toLowerCase() === "header") {
            setCreateTemplatePayload((prevState) => ({
                ...prevState,
                whatsapp_template_data: {
                    ...prevState.whatsapp_template_data,
                    whatsapp_template_header: {
                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                        whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((variable) => {
                            return {
                                ...variable,
                                whatsapp_template_header_variable_data: newFields,
                            }
                        })
                    }
                }
            }));
        }
        // const newFields = fields.map((field: any) => {
        //     if (field.id === id) {
        //         return { ...field, value: event.target.value };
        //     }
        //     return field;
        // });
        // setFields(newFields);
    };
    function extractUniqueVariables(inputString: string) {
        if (!inputString) {
            return []
        }
        const regex = /{{\d+}}/g
        const uniqueVariables = new Set(inputString.match(regex))
        return Array.from(uniqueVariables)
    }

    const checkErrorFromWord = (word: string) => {
        if (word.trim().length > 0) {
            let uniqueVariable = extractUniqueVariables(word)
            if (!(word.trim().split(/\s+/).filter((st: any) => st != '').length >= 3 * uniqueVariable.length + 1)) {
                return true;
            }
            return false
        }
    }

    function checkFlagError(type: string) {
        let wordForBody = createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value;
        let wordForHeader = createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_data_value || "";
        if (type.toLowerCase() === "body") {
            return checkErrorFromWord(wordForBody);
        } else if (type.toLowerCase() === "header") {
            return checkErrorFromWord(wordForHeader);
        } else if (type.toLowerCase() === "both") {
            if (wordForHeader) return checkErrorFromWord(wordForBody) || checkErrorFromWord(wordForHeader);
            return checkErrorFromWord(wordForBody);
        }
    }
    const getMenuDisableFlag = (btnId: number, subType: any) => {
        let subTypeID = subType.whatsapp_template_button_sub_type_id
        if (createTemplatePayload.whatsapp_template_data.whatsapp_template_button.length > 0) {
            switch (subTypeID) {
                case 3: //view website
                    {
                        let btnIndex = createTemplatePayload.whatsapp_template_data.whatsapp_template_button.findIndex((btn) => Number(btn.whatsapp_template_button_type_id) == btnId)
                        if (btnIndex !== -1) {
                            if (createTemplatePayload.whatsapp_template_data.whatsapp_template_button[btnIndex].whatsapp_template_button_data.filter((btn: any) => btn.whatsapp_template_button_sub_type_id == 3).length == 2) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    }
                default:
                    {
                        let btnIndex = createTemplatePayload.whatsapp_template_data.whatsapp_template_button.findIndex((btn) => Number(btn.whatsapp_template_button_type_id) == btnId)
                        if (btnIndex !== -1) {
                            if (createTemplatePayload.whatsapp_template_data.whatsapp_template_button[btnIndex].whatsapp_template_button_data.filter((btn: any) => btn.whatsapp_template_button_sub_type_id == subTypeID).length == 1) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    }
            }
        } else {
            return false

        }
    }

    const flagCategoryEditable = (templateStatusDetails: getTemplateDetails | null, category_id: number) => {
        if (category_id == 3) {
            return false
        } else {
            if (templateStatusDetails && templateStatusDetails.whatsapp_template_metadata.meta_whatsapp_template_status_name == "Approved") {
                return false
            } else {
                return true
            }
        }
    }

    const headerOptionsSelection = [
        {
            header_option_id: 0,
            header_option_value: "None"
        },
    ]

    const uploadFileHandler = async (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            const file: any = e.target.files[i];

            file.isUploading = true;
            setFiles([file]);

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");

            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }

            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }).then(async (res) => {
                if (res.status) {
                    file.isUploading = false;
                    file.file_data = res.data.data;

                    file.google_storage_id = res.data.data.google_storage_id;
                    await setFiles((old: any) => {
                        let temp = [...old]
                        let fileType = file.name.split('.').pop()
                        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        temp.map((rr: any) => {
                            if (rr.google_storage_id == file.google_storage_id) {
                                rr.isUploading = false;
                                rr.file_data = res.data.data;
                                rr.google_storage_id = res.data.data.google_storage_id;
                                // rr.page_field_id = id;
                                rr.previewImageSrc = fileTypeIconSrc;
                            }
                        })
                        return temp
                    })
                    setIsFileUploaded(false);
                    setCreateTemplatePayload((prevState) => ({
                        ...prevState,
                        whatsapp_template_data: {
                            ...prevState.whatsapp_template_data,
                            whatsapp_template_header: {
                                ...prevState.whatsapp_template_data.whatsapp_template_header,
                                whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((headerData) => {
                                    return {
                                        ...headerData,
                                        whatsapp_template_header_data_value: res.data.data.file_path,
                                        whatsapp_template_header_data_google_storage_id: res.data.data.google_storage_id,
                                    }
                                })
                            },
                        },
                    })); 

                    if(fileRef.current.length > 0){
                        for(let i = 0; i < fileRef.current.length; ++i){
                            if(fileRef.current[i].file_data.google_storage_id != res.data.data.google_storage_id){
                                removeUploadedFile(fileRef.current[i].file_data.google_storage_id, false);
                            }
                        } 
                        fileRef.current = fileRef.current.filter((file) => {
                            return file.file_data.google_storage_id == res.data.data.google_storage_id;
                        });
                    }

                    let images = [];

                    for (let i = 0; i < e.target.files.length; i++) {
                        images.push(URL.createObjectURL(e.target.files[i]))
                    }
                }
            }).catch((err) => {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFiles([])
                // inform the user
                console.error(err)
                // removeFile(file.name)
            });

            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }

    } 

    const removeUploadedFile = async (storage_id: any, showSuccessMessage: boolean) => {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${storage_id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then(async (res) => {
            if (res.data.status) {
                // setFiles([]);
                if(showSuccessMessage){
                    setSuccess({ ...success, flagShowSuccess: true, message: "File Deleted Successfully" })
                }
            } else {
                let errorCode = checkForErrorCode(res.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const removeFileHandler = async (storage_id: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true }) 
        if(!(createTemplatePayload.whatsapp_template_id)){
            removeUploadedFile(storage_id, true);
        } 
        setFiles(() => {
            return files.filter((file: any) => {
                return file.file_data.google_storage_id != storage_id;
            });
        }); 
        setCreateTemplatePayload((prevState) => ({
            ...prevState,
            whatsapp_template_data: {
                ...prevState.whatsapp_template_data,
                whatsapp_template_header: {
                    ...prevState.whatsapp_template_data.whatsapp_template_header,
                    whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((headerData) => {
                        return {
                            ...headerData,
                            whatsapp_template_header_data_value: "",
                            whatsapp_template_header_data_google_storage_id: 0,
                        }
                    })
                },
            },
        })); 
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    const headerFileTypes = (headerTypeName: string) => {
        const headerType = headerTypeName.toLowerCase();
        switch (headerType) {
            case "image":
                return '.jpg, .jpeg';
            case "video":
                return '.mp4';
            case "document":
                return '.pdf';
            default:
                return '';
        }
    }
    // --------------------VARIABLE-------------------------

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>

                        <Grid item xs={12} sx={{ mb: "0px", padding: { xs: '20px 20px 0px 20px', lg: '25px 25px 0px 27px' }, position: "sticky", top: { xs: "50px", sm: "0" }, backgroundColor: "white", zIndex: { xs: 2, md: 1 } }} >
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item xs={12}>
                                    <GlobalPageHeaderText
                                        mainHeadText={"Create Template"}
                                        flagMainTextOnly
                                        mainSubText={
                                            !smallScreen && <Breadcrumbs
                                                aria-label="breadcrumb"
                                                className={integrationStyles.cbHeaderBreadCrumb}
                                                sx={{
                                                    '& .MuiBreadcrumbs-separator': {
                                                        marginRight: '3px',
                                                        marginLeft: '3px'
                                                    }
                                                }}
                                            >
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate("/channels") }}
                                                    className={`${integrationStyles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    Channels
                                                </Link>

                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate(`/channels/viewintegration/whatsapp?id=${id}`) }}
                                                    className={`${integrationStyles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    {integrationList?.find((list) => list.meta_integration_id === Number(searchParams.get('id')))?.meta_integration_whatsapp_data?.waba_name}
                                                </Link>

                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate(`/channels/viewintegration/whatsapp/templatemessages?id=${id}`) }}
                                                    className={`${integrationStyles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    Template Messages
                                                </Link>

                                                <Link
                                                    underline="hover"
                                                    className={`${integrationStyles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                >
                                                    Create Template
                                                </Link>

                                            </Breadcrumbs>
                                        }
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <Button
                                        variant='contained'
                                        size='medium'
                                        className={styles.templateSubmitBtn}
                                        onClick={handleSubmitTemplate}
                                        disabled={handleSubmitTemplateDisabled()}
                                        style={{ opacity: handleSubmitTemplateDisabled() ? "0.5" : "1" }}
                                    >
                                        {createTemplatePayload.whatsapp_template_id ? "Edit" : "Create"}
                                    </Button>
                                </Grid> */}
                            </Grid>
                            {!smallScreen && <Divider className={globalStyles.fullwidth} style={{ opacity: "0.8", marginTop: "10px" }} />}
                        </Grid>



                        <Grid item xs={12} className={styles.templateDropdownItem} sx={{ padding: { xs: "0px 20px 0px 20px", lg: "0px 0px 0px 22px" } }}>
                            <Grid container sx={{ justifyContent: 'space-between' }} gap={{ xs: 0, lg: 1, xl: 2 }} flexWrap={"nowrap"} direction={{ xs: "column", lg: "row" }}>
                                <Grid item xs={12} lg={7.5} xl={8.5} p={1} className={styles.leftBlock} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important` }}>
                                    <Grid container style={{ position: "relative" }}>

                                        {flagSmallScreen &&
                                            <Grid item className={styles.cbDetailsPreviewMainItem} onClick={() => { setOpenSwipableDrawer(true) }}>
                                                <Grid container sx={{ gap: "5px", cursor: "pointer" }}>
                                                    <img
                                                        src={previewIcon}
                                                        className={globalStyles.previewIconStyle}
                                                        alt=""
                                                    />
                                                    <Typography
                                                        component={'span'}
                                                        className={globalStyles.previewIconFont}
                                                    >
                                                        Preview
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid item className={styles.templateSubmitBtnGrid}>
                                            <Button
                                                variant='contained'
                                                size='medium'
                                                className={styles.templateSubmitBtn}
                                                onClick={handleSubmitTemplate}
                                                disabled={handleSubmitTemplateDisabled()}
                                                style={{ opacity: handleSubmitTemplateDisabled() ? "0.5" : "1" }}
                                            >
                                                {createTemplatePayload.whatsapp_template_id ? "Update Template Message" : "Create Template Message"}
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Category
                                                    </Typography>
                                                    <Required />
                                                    {!flagCategoryEditable(templateStatusDetails, 1) &&
                                                        <Tooltip title={"You can only edit the template's category id the staus is not APPROVED"}>
                                                            <InfoIcon sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                                        </Tooltip>}
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        Your template should fail under one of these categories.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <Grid container gap={{ xs: "12px", lg: 2 }}>
                                                        {whatsappTemplateCategoryList.map((option: any) => (
                                                            // <MenuItem value={option.whatsapp_template_category_id} key={option.whatsapp_template_category_id}>
                                                            //     {option.whatsapp_template_category_name}
                                                            // </MenuItem>
                                                            <Grid item
                                                                key={option.whatsapp_template_category_id}
                                                                className={styles.categoryFields}
                                                                style={{ cursor: flagCategoryEditable(templateStatusDetails, option.whatsapp_template_category_id) ? "pointer" : "unset", opacity: flagCategoryEditable(templateStatusDetails, option.whatsapp_template_category_id) ? "" : "0.5", backgroundColor: option.whatsapp_template_category_id === createTemplatePayload.whatsapp_template_data.whatsapp_template_category_id ? "#FBF4FF" : "#FFFFFF" }}
                                                                onClick={() => {
                                                                    if (flagCategoryEditable(templateStatusDetails, option.whatsapp_template_category_id)) {
                                                                        setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_category_id: option.whatsapp_template_category_id } }))
                                                                    } else {

                                                                    }
                                                                }}>
                                                                <span>{option.whatsapp_template_category_name}</span>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    {/* <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        placeholder='Select Message Categories'
                                                        className={styles.templateGlobalDropdown}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_category_id}
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            '& .MuiSelect-select': {
                                                                padding: "12px 14px"
                                                            }

                                                        }}
                                                        onChange={(e: any) => {
                                                            console.log("value", e.target.value);
                                                            setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_category_id: e.target.value } }))
                                                            setTemplateCategory(e.target.value);
                                                        }}
                                                    >
                                                        {whatsappTemplateCategoryList.map((option: any) => (
                                                            <MenuItem value={option.whatsapp_template_category_id} key={option.whatsapp_template_category_id}>
                                                                {option.whatsapp_template_category_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Name
                                                    </Typography>
                                                    <Required />
                                                    {createTemplatePayload.whatsapp_template_id &&
                                                        <Tooltip title={"You can not edit a template name once created"}>
                                                            <InfoIcon sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                                        </Tooltip>}
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        Name can only be in lowercase alphanumeric characters and underscores. Special characters and white-space are not allowed
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        e.g app_verification_code
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <TextField
                                                        disabled={createTemplatePayload.whatsapp_template_id ? true : false}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        fullWidth
                                                        className={styles.templateGlobalTextfield}
                                                        placeholder='Enter message template name...'
                                                        size='medium'
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 512) {

                                                            } else {
                                                                const inputValue = e.target.value;
                                                                const sanitizedValue = inputValue.replace(/ /g, '_').replace(/[^a-z0-9_]/g, '');
                                                                setCreateTemplatePayload((prevState) => ({
                                                                    ...prevState,
                                                                    whatsapp_template_data: {
                                                                        ...prevState.whatsapp_template_data,
                                                                        whatsapp_template_name: sanitizedValue
                                                                    }
                                                                }));
                                                            }
                                                        }}
                                                        FormHelperTextProps={{
                                                            style: {
                                                                textAlign: 'right',
                                                                fontSize: '13px',
                                                                fontWeight: '600'
                                                            }
                                                        }}
                                                        helperText={`${createTemplatePayload.whatsapp_template_data.whatsapp_template_name.length}/${512}`}
                                                        // onChange={(e) => { setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_name: e.target.value } })) }}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_name}
                                                        autoComplete='off'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Language
                                                    </Typography>
                                                    <Required />
                                                    {createTemplatePayload.whatsapp_template_id &&
                                                        <Tooltip title={"You can not edit a template language once created"}>
                                                            <InfoIcon sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                                        </Tooltip>}
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        You will need to specify the language in which message template is submitted
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <Select
                                                        disabled={createTemplatePayload.whatsapp_template_id ? true : false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        placeholder='Select message language'
                                                        fullWidth
                                                        size='medium'
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_language_id}
                                                        onChange={(e: any) => {
                                                            setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_language_id: e.target.value } }))
                                                        }}
                                                        className={styles.templateGlobalDropdown}
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            '& .MuiSelect-select': {
                                                                padding: "12px 14px"
                                                            },
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            },
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 250,
                                                                },
                                                            },
                                                        }}
                                                    >

                                                        {whatsappTemplateLanguages.map((option: any) => (
                                                            <MenuItem value={option.whatsapp_language_id} key={option.whatsapp_language_id}>
                                                                {option.whatsapp_language_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }} >
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Description
                                                    </Typography>
                                                    <Required />
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        fullWidth
                                                        className={styles.templateGlobalTextfield}
                                                        placeholder='Enter template desctiption..'
                                                        size='medium'
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            }
                                                        }}
                                                        autoComplete='off'
                                                        onChange={(e) => {
                                                            setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_description: e.target.value } }))
                                                        }}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_description}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }} >
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Header (optional)
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <Select
                                                        // disabled={createTemplatePayload.whatsapp_template_id ? true : false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        placeholder='Select Header Type'
                                                        fullWidth
                                                        size='medium'
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name}
                                                        // value={selectedHeaderType.whatsapp_template_header_type_name || headerOptionsSelection[0].header_option_value}
                                                        onChange={(e: any) => {
                                                            // setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_language_id: e.target.value } })) 
                                                            setFiles([])
                                                            setSelectedHeaderType({
                                                                "whatsapp_template_header_type_id": whastappTemplateHeaderTypes?.find((hType) => hType.whatsapp_template_header_type_name == e.target.value)?.whatsapp_template_header_type_id || 0,
                                                                "whatsapp_template_header_type_name": e.target.value
                                                            }) 

                                                            if(e.target.value.toLowerCase() === "none" && createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() != "none"){ 
                                                                setExistingHeaderTemplateVariableData(createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data 
                                                                    ? createTemplatePayload.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data 
                                                                    : null
                                                                );
                                                            }
                                                            setCreateTemplatePayload((prevState) => ({
                                                                ...prevState,
                                                                whatsapp_template_data: {
                                                                    ...prevState.whatsapp_template_data,
                                                                    whatsapp_template_header: {
                                                                        ...prevState.whatsapp_template_data.whatsapp_template_header,
                                                                        whatsapp_template_header_data: [{
                                                                            "whatsapp_template_header_data_value": "", 
                                                                            "whatsapp_template_header_data_id": prevState.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_data_id,
                                                                            "whatsapp_template_header_data_google_storage_id": 0, 
                                                                            "whatsapp_template_header_variable_data": [],
                                                                            // "whatsapp_template_header_variable_data": (e.target.value.toLowerCase() === "none") ? [] : prevState.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data,
                                                                        }],
                                                                        whatsapp_template_header_type_id: whastappTemplateHeaderTypes?.find((hType) => hType.whatsapp_template_header_type_name == e.target.value)?.whatsapp_template_header_type_id || 0,
                                                                        whatsapp_template_header_type_name: e.target.value
                                                                    },
                                                                },
                                                            }));
                                                        }}
                                                        // defaultValue={headerOptionsSelection[0].header_option_value}
                                                        className={styles.templateGlobalDropdown}
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            '& .MuiSelect-select': {
                                                                padding: "12px 14px"
                                                            },
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            },
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 250,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {headerOptionsSelection.map((option: any) => (
                                                            <MenuItem value={option.header_option_value} key={option.header_option_id}>
                                                                {option.header_option_value}
                                                            </MenuItem>
                                                        ))}
                                                        {whastappTemplateHeaderTypes?.map((headers) => (
                                                            <MenuItem value={headers.whatsapp_template_header_type_name} key={headers.whatsapp_template_header_type_id}>
                                                                {headers.whatsapp_template_header_type_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* {(selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "text") && */}
                                        {(createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name?.toLowerCase() === "text") &&
                                            <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }}>
                                                <Grid container>
                                                    <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                        <Typography component={'span'} className={styles.templateHeadtext}>
                                                            Header Text
                                                        </Typography>
                                                        <Required />
                                                    </Grid>
                                                    <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                        <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                            Use text formatting - *bold*, _italic_ & ~strikerthrough~ Your message content.
                                                            e.g -Hello {`{{1}}`}, your code will expire in {`{{2}}`} mins.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                        <TextareaAutosize
                                                            ref={headerTextareaRef}
                                                            className={styles.templateGlobalTextarea}
                                                            value={createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value}
                                                            onChange={(event: any) => {
                                                                const { value } = event.target;
                                                                // const replacementString = `{{${createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.length}}}`

                                                                if (value.endsWith('{{')) {
                                                                    handleAddBodyVariableManually(value, "header")
                                                                } else {
                                                                    // If no double curly braces, update state normally
                                                                    // if (value.length <= 1024) {
                                                                    let finalValues = removeVariableData(value, "header")
                                                                    handleChangeText(event, 'header');
                                                                    setCreateTemplatePayload((prevState) => ({
                                                                        ...prevState,
                                                                        whatsapp_template_data: {
                                                                            ...prevState.whatsapp_template_data,
                                                                            whatsapp_template_header: {
                                                                                ...prevState.whatsapp_template_data.whatsapp_template_header,
                                                                                whatsapp_template_header_data: prevState.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map((variable) => {
                                                                                    return {
                                                                                        ...variable,
                                                                                        whatsapp_template_header_data_value: finalValues.textAreaValue,
                                                                                        whatsapp_template_header_variable_data: finalValues.variableData,
                                                                                    }
                                                                                })
                                                                            }
                                                                        },
                                                                    }));
                                                                    // }
                                                                }
                                                            }}
                                                            autoComplete='off'
                                                            placeholder='Select message type'
                                                        />
                                                    </Grid>
                                                    {checkFlagError("header") &&
                                                        <Grid item>
                                                            <Grid container flexWrap={"nowrap"} gap={1} p={2} sx={{ backgroundColor: "#fff0c7", borderRadius: "5px" }} >
                                                                <Grid item>
                                                                    <ReportProblemOutlinedIcon className={styles.variableDisclaimerIcon} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <span className={styles.variableDisclaimerText} >This template contains too many variable parameters relative to the message length. You need to decrease the number of variable parameters or increase the message length.</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} sx={{ textAlign: 'end', margin: '15px 0px 10px' }}>
                                                        <Grid container alignItems={"center"} gap={{ xs: "10px", md: 2 }}>
                                                            {/* <Grid item>
                                                                <SentimentSatisfiedAltIcon style={{ display: "flex", cursor: "pointer" }} />
                                                            </Grid> */}
                                                            {/* <Grid item>
                                                                <FormatBoldIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('bold', 'header')} />
                                                            </Grid>
                                                            <Grid item>
                                                                <FormatItalicIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('italic', 'header')} />
                                                            </Grid>
                                                            <Grid item>
                                                                <StrikethroughSIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('strikethrough', 'header')} />
                                                            </Grid>
                                                            <Grid item style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('monospace', 'header')}>
                                                                <IconsColor iconName="code" />
                                                            </Grid> */}

                                                            <Grid item>
                                                                <Button
                                                                    variant="outlined"
                                                                    size='small'
                                                                    onClick={() => handleAddField("header")}
                                                                    className={styles.callToActionButton}
                                                                    disabled={checkDisableAddVarButton()} 
                                                                    sx={{
                                                                        ...(checkDisableAddVarButton() && {
                                                                            opacity: "0.5"
                                                                        })
                                                                    }}
                                                                >
                                                                    Add Variable
                                                                </Button>
                                                            </Grid>
                                                            {/* <Grid item>
                                                                <Tooltip title={"Template body formatting text"}>
                                                                    <InfoIcon className={styles.variableInfoIcon} />
                                                                </Tooltip>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/* {(selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "image" || selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "video" || selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "document") &&  */}
                                        {((
                                            createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "image" 
                                        || createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "video" 
                                        || createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "document") 
                                        ) &&
                                            <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }} >
                                                <Grid container>
                                                    <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                        <Typography component={'span'} className={styles.templateHeadtext}>
                                                            {/* {`Upload Header ${selectedHeaderType.whatsapp_template_header_type_name}`}  */}
                                                            {`Upload Header ${createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name}`}
                                                        </Typography> 
                                                        <Required />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                        <FileUpload
                                                            uploadedFileDetails={files}
                                                            multipleFileAllow={true}
                                                            // fileSizeAllowed={selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "image" ? 3 : selectedHeaderType.whatsapp_template_header_type_name.toLowerCase() === "video" ? 16 : 2} 
                                                            fileSizeAllowed={createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "image" ? 3 : createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "video" ? 16 : 2}
                                                            // fileTypeAllowed={headerFileTypes(selectedHeaderType.whatsapp_template_header_type_name)}
                                                            fileTypeAllowed={headerFileTypes(createTemplatePayload?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name || "")}
                                                            fileNumberAllowed={2}
                                                            isDisabled={false}
                                                            uploadFileHandler={uploadFileHandler}
                                                            removeFileHandler={(storage_id: number) => removeFileHandler(storage_id)}
                                                            isDeleteFileIconVisible={isFileUploaded} 
                                                            bgColor='#ffffff'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/* {createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.map((field: any) => ( */}
                                        {createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data && 
                                        createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 &&  
                                        createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "text" && 
                                        createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.[0]?.whatsapp_template_dynamic_variable_key.toLowerCase() != "{{headerimage}}" && 
                                        createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.[0]?.whatsapp_template_dynamic_variable_key.toLowerCase() != "{{headervideo}}" &&
                                        createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_data[0]?.whatsapp_template_header_variable_data?.map((field: any) => (
                                            <>
                                                <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                    <Grid container sx={{ alignItems: 'center' }} flexWrap={"nowrap"}>
                                                        <Grid item sx={{ marginRight: '15px' }}>
                                                            <Typography
                                                                component='span'
                                                                sx={{ fontSize: '15px', fontWeight: '500', color: 'black' }}
                                                            >
                                                                {`${field.whatsapp_template_dynamic_variable_key}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} key={field.whatsapp_template_dynamic_variable_id}>
                                                            <TextField
                                                                size='small'
                                                                variant="outlined"
                                                                className={styles.templateGlobalTextfield}
                                                                // value={field.value}
                                                                onChange={(e) => handleChange(field.whatsapp_template_dynamic_variable_key, e, "header")}
                                                                fullWidth
                                                                autoComplete='off'
                                                                sx={{
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        border: 'none'
                                                                    }
                                                                }}
                                                                value={field.whatsapp_template_dynamic_variable_sample_value}
                                                            />
                                                        </Grid>
                                                        <Grid item sx={{ marginLeft: '5px' }}>
                                                            <IconButton onClick={() => handleRemoveField(field.whatsapp_template_dynamic_variable_key, "header")}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </>
                                        ))}
                                        {/* {createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.filter((data) => data.whatsapp_template_dynamic_variable_sample_value.trim() == "").length != 0 && */}
                                        {createTemplatePayload.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() === "text" 
                                         && createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data && 
                                         createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 &&  
                                         createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data && 
                                         createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0 &&
                                         createTemplatePayload.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data?.filter((data) => data.whatsapp_template_dynamic_variable_sample_value.trim() == "").length != 0 &&
                                            <Grid item marginBlock={"12px"} xs={12}>
                                                <Grid container flexWrap={"nowrap"} gap={1} sx={{ backgroundColor: "#faede8", borderRadius: "5px", padding: "12px 15px" }} alignItems={"center"}>
                                                    <Grid item>
                                                        <ReportProblemOutlinedIcon className={styles.variableDisclaimerIcon} />
                                                    </Grid>
                                                    <Grid item>
                                                        <span className={styles.variableDisclaimerText} >Add sample text</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}

                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Body
                                                    </Typography>
                                                    <Required />
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        Use text formatting - *bold*, _italic_ & ~strikerthrough~ Your message content. Upto 1024 characters are allowed.
                                                        e.g -Hello {`{{1}}`}, your code will expire in {`{{2}}`} mins.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    {/* <TextField
                                                        variant="outlined"
                                                        rows={4}
                                                        multiline
                                                        className={styles.templateGlobalTextfield}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value}
                                                        onChange={(event: any) => {
                                                            if (event.target.value.length > 1024) {

                                                            } else {

                                                                handleChangeText(event, 'format'); setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_body: { ...prevState.whatsapp_template_data.whatsapp_template_body, whatsapp_template_body_value: event.target.value } } }))
                                                            }
                                                        }}
                                                        helperText={`${text.length}/${maxChars}`}
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            }
                                                        }}
                                                        autoComplete='off'
                                                        placeholder='Select message type'
                                                        FormHelperTextProps={{
                                                            style: {
                                                                textAlign: 'right',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }
                                                        }}
                                                    /> */}
                                                    <TextareaAutosize
                                                        ref={textareaRef}
                                                        className={styles.templateGlobalTextarea}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value}
                                                        onChange={(event: any) => {
                                                            const { value } = event.target;
                                                            const replacementString = `{{${createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.length}}}`

                                                            if (value.endsWith('{{')) {
                                                                handleAddBodyVariableManually(value, "body")
                                                            } else {
                                                                // If no double curly braces, update state normally
                                                                if (value.length <= 1024) {
                                                                    let finalValues = removeVariableData(value, "body")
                                                                    handleChangeText(event, 'format');
                                                                    setCreateTemplatePayload((prevState) => ({
                                                                        ...prevState,
                                                                        whatsapp_template_data: {
                                                                            ...prevState.whatsapp_template_data,
                                                                            whatsapp_template_body: {
                                                                                ...prevState.whatsapp_template_data.whatsapp_template_body,
                                                                                whatsapp_template_body_value: finalValues.textAreaValue,
                                                                                whatsapp_template_body_variable_data: finalValues.variableData
                                                                            },
                                                                        },
                                                                    }));
                                                                }
                                                            }
                                                        }}


                                                        // onChange={(event: any) => {
                                                        //     if (event.target.value.length > 1024) {

                                                        //     } else {

                                                        //         handleChangeText(event, 'format'); setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_body: { ...prevState.whatsapp_template_data.whatsapp_template_body, whatsapp_template_body_value: event.target.value } } }))
                                                        //     }
                                                        // }}
                                                        autoComplete='off'
                                                        placeholder='Select message type'
                                                    />
                                                </Grid>
                                                {checkFlagError("body") &&
                                                    <Grid item>
                                                        <Grid container flexWrap={"nowrap"} gap={1} p={2} sx={{ backgroundColor: "#fff0c7", borderRadius: "5px" }} >
                                                            <Grid item>
                                                                <ReportProblemOutlinedIcon className={styles.variableDisclaimerIcon} />
                                                            </Grid>
                                                            <Grid item>
                                                                <span className={styles.variableDisclaimerText} >This template contains too many variable parameters relative to the message length. You need to decrease the number of variable parameters or increase the message length.</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sx={{ textAlign: 'end', margin: '15px 0px 20px' }}>
                                                    <Grid container justifyContent={"space-between"} alignItems={{ xs: "start", md: "center" }} direction={{ xs: "column", md: "row" }} gap={1}>
                                                        <Grid item>
                                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>Characters: {`${text.length}/${maxChars}`}</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems={"center"} gap={{ xs: "10px", md: 2 }}>
                                                                {/* <Grid item>
                                                                    <SentimentSatisfiedAltIcon style={{ display: "flex", cursor: "pointer" }} />
                                                                </Grid> */}
                                                                <Grid item>
                                                                    <FormatBoldIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('bold', 'body')} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormatItalicIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('italic', 'body')} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <StrikethroughSIcon style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('strikethrough', 'body')} />
                                                                </Grid>
                                                                <Grid item style={{ display: "flex", cursor: "pointer" }} onClick={() => handleFormatting('monospace', 'body')}>
                                                                    <IconsColor iconName="code" />
                                                                </Grid>

                                                                <Grid item>
                                                                    <Button
                                                                        variant="outlined"
                                                                        size='small'
                                                                        onClick={() => handleAddField("body")}
                                                                        className={styles.callToActionButton}
                                                                    >
                                                                        Add Variable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title={"Template body formatting text"}>
                                                                        <InfoIcon className={styles.variableInfoIcon} />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                                {createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.map((field: any) => (
                                                    <>
                                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                            <Grid container sx={{ alignItems: 'center' }} flexWrap={"nowrap"}>
                                                                <Grid item sx={{ marginRight: '15px' }}>
                                                                    <Typography
                                                                        component='span'
                                                                        sx={{ fontSize: '15px', fontWeight: '500', color: 'black' }}
                                                                    >
                                                                        {`${field.whatsapp_template_dynamic_variable_key}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={9} key={field.whatsapp_template_dynamic_variable_id}>
                                                                    <TextField
                                                                        size='small'
                                                                        variant="outlined"
                                                                        className={styles.templateGlobalTextfield}
                                                                        // value={field.value}
                                                                        onChange={(e) => handleChange(field.whatsapp_template_dynamic_variable_key, e, "body")}
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        sx={{
                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                border: 'none'
                                                                            }
                                                                        }}
                                                                        value={field.whatsapp_template_dynamic_variable_sample_value}
                                                                    />
                                                                </Grid>
                                                                <Grid item sx={{ marginLeft: '5px' }}>
                                                                    <IconButton onClick={() => handleRemoveField(field.whatsapp_template_dynamic_variable_key, "body")}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </>
                                                ))}
                                                {createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.filter((data) => data.whatsapp_template_dynamic_variable_sample_value.trim() == "").length != 0 &&
                                                    <Grid item marginBlock={"12px"} xs={12}>
                                                        <Grid container flexWrap={"nowrap"} gap={1} sx={{ backgroundColor: "#faede8", borderRadius: "5px", padding: "12px 15px" }} alignItems={"center"}>
                                                            <Grid item>
                                                                <ReportProblemOutlinedIcon className={styles.variableDisclaimerIcon} />
                                                            </Grid>
                                                            <Grid item>
                                                                <span className={styles.variableDisclaimerText} >Add sample text</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '10px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Body Description
                                                    </Typography>
                                                    <Required />
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        // value={createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_description}
                                                        rows={4}
                                                        multiline
                                                        className={styles.templateGlobalTextfield}
                                                        value={createTemplatePayload.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_description}
                                                        onChange={(event: any) => { setCreateTemplatePayload((prevState) => ({ ...prevState, whatsapp_template_data: { ...prevState.whatsapp_template_data, whatsapp_template_body: { ...prevState.whatsapp_template_data.whatsapp_template_body, whatsapp_template_body_description: event.target.value } } })) }}
                                                        // helperText={`${text.length}/${maxChars}`}
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            }
                                                        }}
                                                        autoComplete='off'
                                                        placeholder='Select message type'
                                                        FormHelperTextProps={{
                                                            style: {
                                                                textAlign: 'right',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* footer */}
                                        <Grid item xs={12} sx={{ margin: '20px 0px 0px 0px' }} >
                                            <Grid container>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Footer (optional)
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        Your message content. Upto 60 characters are allowed.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={footerText}
                                                        size='small'
                                                        className={styles.templateGlobalTextfield}
                                                        onChange={(event: any) => { 
                                                            handleChangeText(event, 'footer'); 
                                                            setCreateTemplatePayload((prevState) => ({ 
                                                                ...prevState, 
                                                                whatsapp_template_data: { 
                                                                    ...prevState.whatsapp_template_data, 
                                                                    whatsapp_template_footer: { 
                                                                        ...prevState.whatsapp_template_data.whatsapp_template_footer, 
                                                                        whatsapp_template_footer_type_id: whatsappTemplateFooterTypes && whatsappTemplateFooterTypes[0]?.whatsapp_template_footer_type_id || 0, 
                                                                        // whatsapp_template_footer_data: [{ whatsapp_template_footer_data_value: event.target.value }]  
                                                                        whatsapp_template_footer_data: prevState.whatsapp_template_data.whatsapp_template_footer.whatsapp_template_footer_data.map((variable) => {
                                                                            return {
                                                                                ...variable, 
                                                                                whatsapp_template_footer_data_value: event.target.value,
                                                                            }
                                                                        }) 
                                                                    } 
                                                                } 
                                                            })) }}
                                                        helperText={`${footerText.length}/${maxFooterChars}`}
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            margin: '10px 0px'
                                                        }}
                                                        autoComplete='off'
                                                        placeholder='Enter footer text here'
                                                        FormHelperTextProps={{
                                                            style: {
                                                                textAlign: 'right',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* 
                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography component={'span'} className={styles.templateHeadtext}>
                                                        Template Footer (Optional)
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                                    <Typography component={'span'} className={styles.templateSubHeadtext}>
                                                        Your message content. Upto 60 characters are allowed.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={footerText}
                                                        size='small'
                                                        className={styles.templateGlobalTextfield}
                                                        onChange={(event: any) => handleChangeText(event, 'footer')}
                                                        helperText={`${footerText.length}/${maxFooterChars}`}
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            margin: '10px 0px'
                                                        }}
                                                        autoComplete='off'
                                                        placeholder='Enter footer text here'
                                                        FormHelperTextProps={{
                                                            style: {
                                                                textAlign: 'right',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container sx={{ marginTop: "20px" }}>
                                        <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                            <Typography component={'span'} className={styles.templateHeadtext}>
                                                Interactive Actions
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={styles.templateFieldBetweenSpacing}>
                                            <Typography component='span' className={styles.templateSubHeadtext}>
                                                Create buttons that let customers respond to your message or take action.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '15px 0px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Button
                                                        id="demo-customized-button"
                                                        aria-controls={addBtnOpenMenu ? 'demo-customized-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={addBtnOpenMenu ? 'true' : undefined}
                                                        variant="contained"
                                                        disableElevation
                                                        onClick={handleClick}
                                                        className={styles.addButtonStyles}
                                                        startIcon={<IconsColor iconName="plus" width="14px" height="14px" fillColor="#6A097D" />}
                                                    >
                                                        Add a button
                                                    </Button>
                                                    <StyledMenu
                                                        id="demo-customized-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'demo-customized-button',
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                    >
                                                        {whatsappTemplateButtonGenericData.map((btnOption) => {
                                                            return <>
                                                                <div style={{ padding: "10px", fontWeight: "600", fontSize: "18px" }}>{btnOption.whatsapp_template_button_type_name}</div>
                                                                {btnOption.whatsapp_template_button_sub_type_data.map((subType) => {

                                                                    return <>
                                                                        {getSubButtonsBasedOnCategory(createTemplatePayload.whatsapp_template_data.whatsapp_template_category_id).includes(subType.whatsapp_template_button_sub_type_id) &&
                                                                            <MenuItem
                                                                                disabled={getMenuDisableFlag(btnOption.whatsapp_template_button_type_id, subType)}
                                                                                onClick={() => { handleAddActionsButton(btnOption.whatsapp_template_button_type_id, subType.whatsapp_template_button_sub_type_id); handleClose() }} disableRipple>
                                                                                <Grid container direction={"column"}>
                                                                                    <Grid item sx={{ fontSize: "15px", fontWeight: "500" }}> {subType.whatsapp_template_button_sub_type_name}</Grid>
                                                                                    <Grid item sx={{ fontSize: "12px", fontWeight: "500" }}>{subType.whatsapp_template_button_sub_type_description}</Grid>
                                                                                </Grid>

                                                                            </MenuItem>}
                                                                    </>
                                                                })
                                                                }
                                                                <Divider sx={{ my: 0.5, opacity: "3.2" }} />
                                                            </>
                                                        })
                                                        }

                                                    </StyledMenu>
                                                    {/* 
                                                    <FormControl component="fieldset">
                                                        <RadioGroup row aria-label="option" name="row-radio-buttons-group" value={selectedOption} onChange={(event: any) => { setSelectedOption(event?.target.value); handleChangeBtnOption(event.target.value) }}>
                                                            <FormControlLabel value="none" control={<Radio />} label="None" />
                                                            {whatsappTemplateButtonGenericData.map((btnGenericData: any) => {
                                                                return <FormControlLabel value={btnGenericData.whatsapp_template_button_type_id} control={<Radio />} label={btnGenericData.whatsapp_template_button_type_name} />
                                                            })
                                                            }
                                                        </RadioGroup>
                                                    </FormControl> */}
                                                </Grid>
                                                {createTemplatePayload.whatsapp_template_data.whatsapp_template_button.length > 0 &&
                                                    <Grid item mt={2}>
                                                        <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                                                            <Grid item>
                                                                <IconsColor iconName="ideaIcon" width={"20"} height={"20"} />
                                                            </Grid>
                                                            <Grid item>
                                                                <span className={styles.tipButtonStle}>If you add more than three buttons, they will appear in a list.</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}

                                                <Grid item xs={12} sx={{ margin: '5px 0px', position: "relative" }}>
                                                    <Grid container>
                                                        {/* @ts-ignore */}
                                                        {createTemplatePayload.whatsapp_template_data.whatsapp_template_button.length > 0 && createTemplatePayload.whatsapp_template_data.whatsapp_template_button.map((action: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <Grid item xs={12} className={styles.callToActionGridStyle}>
                                                                        <Grid container direction={"column"}>
                                                                            <Grid item className={styles.callToActionTextStyle}>
                                                                                {/* @ts-ignore */}
                                                                                {whatsappTemplateButtonGenericData?.find((btn) => btn.whatsapp_template_button_type_id == action.whatsapp_template_button_type_id).whatsapp_template_button_type_name ?? ""}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container sx={{ justifyContent: 'start', gap: '15px', alignItems: 'center' }} flexWrap={"nowrap"}>
                                                                            <Grid item xs={12}>
                                                                                {action.whatsapp_template_button_data.map((field: any, fieldIndex: number) => {
                                                                                    return <Grid container direction={{ xs: "column", lg: "row" }} gap={"15px"} alignItems={{ xs: "start", lg: "center" }} justifyContent={"start"} marginBottom={2} flexWrap={{ xl: "nowrap" }} className={styles.mobileViewActions}>
                                                                                        <Grid item className={`${globalStyles.displayFlex} ${styles.closeActionButtonMobile}`} onClick={() => { handleDeleteActionBtn(action.whatsapp_template_button_type_id, field.whatsapp_template_button_sub_type_id, fieldIndex) }}>
                                                                                            <CloseIcon onClick={() => { handleDeleteActionBtn(action.whatsapp_template_button_type_id, field.whatsapp_template_button_sub_type_id, fieldIndex) }} style={{ fontSize: "20px", cursor: "pointer" }} />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} lg={5.5} sx={{ width: "100%" }}>
                                                                                            <Grid container direction={"column"} gap="15px" flexWrap={"nowrap"}>
                                                                                                <Grid item xs={12}><InputLabel className={`${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`}>Type of action</InputLabel></Grid>
                                                                                                <Grid item xs={12}>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-label"
                                                                                                        id="demo-simple-select"
                                                                                                        fullWidth
                                                                                                        size='small'
                                                                                                        placeholder='Select action type'
                                                                                                        className={styles.templateGlobalDropdown}
                                                                                                        sx={{
                                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                                border: 'none'
                                                                                                            },
                                                                                                            '& .MuiOutlinedInput-input': {
                                                                                                                padding: "12px 14px"
                                                                                                            }
                                                                                                        }}
                                                                                                        value={field.whatsapp_template_button_sub_type_id}
                                                                                                        // @ts-ignore
                                                                                                        onChange={(event: any) => { handleChangeCallField(event.target.value, 'type', action.id); handleChangeFieldVal(event.target.value, index) }}
                                                                                                    >
                                                                                                        {/* @ts-ignore */}
                                                                                                        {whatsappTemplateButtonGenericData?.find((btn) => btn.whatsapp_template_button_type_id == action.whatsapp_template_button_type_id).whatsapp_template_button_sub_type_data.map((ele) => (
                                                                                                            // @ts-ignore
                                                                                                            <MenuItem disabled={ele.whatsapp_template_button_sub_type_id == 3 ? createTemplatePayload.whatsapp_template_data.whatsapp_template_button.find((btn) => btn.whatsapp_template_button_type_id == action.whatsapp_template_button_type_id).whatsapp_template_button_data.filter((btn: any) => btn.whatsapp_template_button_sub_type_id == 3).length == 2 ? true : false : createTemplatePayload.whatsapp_template_data.whatsapp_template_button.find((btn) => btn.whatsapp_template_button_type_id == action.whatsapp_template_button_type_id).whatsapp_template_button_data.filter((btn: any) => btn.whatsapp_template_button_sub_type_id == 4).length == 1 ? true : false} value={ele.whatsapp_template_button_sub_type_id} key={ele.whatsapp_template_button_sub_type_id}>
                                                                                                                {ele.whatsapp_template_button_sub_type_name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                        {field.whatsapp_template_button_sub_type_field_data.sort((a: any, b: any) => a.whatsapp_template_button_sub_type_field_id - b.whatsapp_template_button_sub_type_field_id).map((subField: any, subFieldIndex: any) => {
                                                                                            return <>
                                                                                                <Grid item xs={12} lg={5.5} sx={{ width: "100%" }}>
                                                                                                    {getInput(subField.field_type, subField, action.whatsapp_template_button_type_id, fieldIndex, subFieldIndex, field.whatsapp_template_button_sub_type_id)}
                                                                                                </Grid>
                                                                                            </>
                                                                                        })
                                                                                        }
                                                                                        <Grid item className={`${globalStyles.displayFlex} ${styles.closeActionButton}`} >
                                                                                            <CloseIcon onClick={() => { handleDeleteActionBtn(action.whatsapp_template_button_type_id, field.whatsapp_template_button_sub_type_id, fieldIndex) }} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                })
                                                                                }
                                                                            </Grid>

                                                                            {/* <Grid item xs={2}>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        fullWidth
                                                                                        size='small'
                                                                                        placeholder='Select action type'
                                                                                        className={styles.templateGlobalDropdown}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none'
                                                                                            },
                                                                                        }}
                                                                                        value={action.type}
                                                                                        onChange={(event: any) => handleChangeCallField(event.target.value, 'urlType', action.id)}
                                                                                    >
                                                                                        {urlType.map((ele) => (
                                                                                            <MenuItem value={ele.name} key={ele.id}>
                                                                                                {ele.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        variant="outlined"
                                                                                        autoComplete='off'
                                                                                        value={action.title}
                                                                                        size='small'
                                                                                        placeholder='Button title'
                                                                                        className={styles.templateGlobalDropdown}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none'
                                                                                            },
                                                                                            '& .MuiSelect-select': {
                                                                                                padding: "12px 14px"
                                                                                            }
                                                                                        }}
                                                                                        onChange={(event: any) => handleChangeCallField(event.target.value, 'title', action.id)}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    <Grid container>
                                                                                        {action.type === 'Phone Number' ? (
                                                                                            <Grid item xs={10} className={`${styles.tempContactItem}`}>
                                                                                                <Autocomplete
                                                                                                    fullWidth
                                                                                                    size='small'
                                                                                                    disablePortal
                                                                                                    options={[
                                                                                                        { label: 'The Shawshank Redemption', year: 1994 },
                                                                                                        { label: 'The Godfather', year: 1972 },
                                                                                                        { label: 'The Godfather: Part II', year: 1974 },
                                                                                                        { label: 'The Dark Knight', year: 2008 },
                                                                                                        { label: '12 Angry Men', year: 1957 },
                                                                                                    ]}
                                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                                />
                                                                                            </Grid>
                                                                                        ) : (
                                                                                            <Grid item xs={12}>
                                                                                                <TextField
                                                                                                    placeholder='Button Value'
                                                                                                    variant="outlined"
                                                                                                    autoComplete='off'
                                                                                                    value={action.value}
                                                                                                    size='small'
                                                                                                    className={styles.templateGlobalDropdown}
                                                                                                    sx={{
                                                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                                                            border: 'none'
                                                                                                        },
                                                                                                        '& .MuiSelect-select': {
                                                                                                            padding: "12px 14px"
                                                                                                        }
                                                                                                    }}
                                                                                                    // onChange={(event) => handleChangeCallField(event.target.value, 'value', action.id)}
                                                                                                    fullWidth
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    <IconButton onClick={() => handleDeleteAction(action.id)}>
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                </Grid> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}

                                                        {/* <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                <Button
                                                                    variant="outlined"
                                                                    className={styles.callToActionButton}
                                                                    onClick={() => { handleAddActions(); handleChangeBtn(2) }}
                                                                    startIcon={<AddIcon sx={{ verticalAlign: 'sub' }} />}
                                                                >
                                                                    Call to Action
                                                                </Button>
                                                            </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                {selectedOption === 'quickReply' && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                {quickReply.map((action: any) => {
                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                                    <Grid item xs={3}>
                                                                                        <span style={{ fontSize: "14px", fontWeight: '500' }}>Quick Reply Action</span>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            autoComplete='off'
                                                                                            size='medium'
                                                                                            variant="outlined"
                                                                                            placeholder='Button title' value={action.value}
                                                                                            onChange={(event: any) => handleQuickReplyChange(action.id, event.target.value)}
                                                                                            className={styles.templateGlobalTextfield}
                                                                                            sx={{
                                                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                                                    border: 'none'
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ ml: '10px' }}>
                                                                                        <IconButton onClick={(event: any) => handleDeleteQuickReply(action.id)}>
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>)
                                                                })}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                            <Button
                                                                variant="outlined"
                                                                className={styles.callToActionButton}
                                                                onClick={handleAddQuickReply}
                                                                startIcon={<AddIcon sx={{ verticalAlign: 'sub' }} />}
                                                            >
                                                                Quick Reply
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {!flagSmallScreen &&
                                    <Grid item xs={12} lg={4} xl={3}>
                                        <Grid container direction={"column"} justifyContent={'center'}>
                                            <Grid item>
                                                <TemplatePreview metaInformation={createTemplatePayload} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }


                                {flagSmallScreen &&
                                    <Grid item xs={flagSmallScreen ? 0 : 6}>
                                        <TemplatePreviewMobile
                                            metaInformation={createTemplatePayload}
                                            flagSmallScreen={flagSmallScreen}
                                            setOpenSwipableDrawer={setOpenSwipableDrawer}
                                            openSwipableDrawer={openSwipableDrawer}
                                            toggleDrawer={toggleDrawer}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}


export default Template