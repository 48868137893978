import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss";
import GlobalButton from '../../utility/globalButton/button';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { globalIntegration } from '../../../models/axiosModel';
import { checkForErrorCode, getErrorMessage, isDev } from '../../../utils/constants';
import InstagramIntegationDetails from './instagramIntegrationDetails';
import IntegrationSetupPrerequisites from '../channels/global/integrationSetup';
import FacebookInformationCard from '../../utility/facebookInformationCard';
import GlobalPopUp from '../../utility/globalPopUp';
import TempInstagramCard from './tempInstagramCard';



interface checkFlag {
    flagPage?: boolean
}
const ViewInstagramIntegration = (props: checkFlag) => {
    const {
        setShowFacebookIntegrationDetails,
        showFacebookIntegrationDetails,
        metaCredentials
    } = useContext(MetaContext) as MetaContextPayload;

    const {
        getIntegrationList,
        integrationList,
        globalIntegrationTypeList,

        // FACEBOOK
        facebookIntegrationDetails,
        setFacebookIntegrationDetails,
        setIntegrationList,
    } = useContext(IntegrationContext) as IntegrationContextPayload;

    const {
        setError,
        error,
        setSuccess,
        setFlagLoading,
        userDetail
    } = useContext(UserContext) as UserContextPayload;


    // CONST
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const [instagramPagesData, setInstagramPagesData] = useState({
        flagShowPopup: false,
        instagramPages: []
    })
    console.log("instagramPagesData", instagramPagesData)
    const [responsePayload, setResponsePayload] = useState<any>({
        page_id: '',
        page_name: '',
        page_category: '',
        access_token_value: '',
        instagram_id: '',
        instagram_username: ''
    })

    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 900px) and (min-width : 600px)')
    const laptopScreen = useMediaQuery('(max-width : 1600px) and (min-width : 900px)')

    useLayoutEffect(() => {
        (async () => {
            await getIntegrationList()
        })()
    }, [])

    useEffect(() => {
        loadFacebookSDK();
    }, []);

    const loadFacebookSDK = () => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        let fb_init = metaCredentials?.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "instagram")?.fb_init
        console.log("fb_init-----", fb_init)
        window.fbAsyncInit = () => {
            window.FB.init(fb_init);
        };
    };

    useEffect(() => {
        if (searchParams.has('id')) {
            if (integrationList && integrationList.length > 0) {
                let id = searchParams.get('id')
                let integration = integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "instagram").find((integration: any) => integration.meta_integration_id == id)
                if (integration) {
                    setFacebookIntegrationDetails(integration)
                    setShowFacebookIntegrationDetails(true)
                } else {
                    navigate('/channels')
                }
            }
        } else {
            setFacebookIntegrationDetails(null)
            setShowFacebookIntegrationDetails(false)
        }
    }, [integrationList])

    useEffect(() => {
        const loadFacebookSDK = () => {
            if (window.FB) {
                console.log("FB SDK already loaded");
                return;
            }
            let fb_init = metaCredentials?.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "instagram")?.fb_init

            window.fbAsyncInit = function () {
                window.FB.init(fb_init);
                console.log("FB SDK initialized");
            };

            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            document.head.appendChild(script);
        };

        loadFacebookSDK();
    }, []);

    function loginToInstagram() {
        window.FB.login(
            function (response: any) {
                console.log("res: ", response)
                if (response.authResponse) {
                    console.log("Welcome! Fetching your information....")
                    window.FB.api("/me", function (res: any) {
                        console.log("res 1:", res)
                        console.log("Good to see you, " + res.name + ".")

                        // Now get the Instagram account access token
                        getInstagramAccessToken()
                    })
                } else {
                    console.log("User cancelled login or did not fully authorize.")
                }
            },
            { scope: "instagram_basic, instagram_manage_messages, business_management, pages_show_list" }
        )
    }

    function fetchInstagramAccountDetails(igAccountId: any, pageData: any) {
        window.FB.api(`/${igAccountId}?fields=id,username,biography,followers_count,follows_count,profile_picture_url,website`, function (igAccountInfo: any) {
            console.log("Instagram Account Info: ", igAccountInfo)
            setInstagramPagesData((prevState: any) => ({ ...prevState, instagramPages: [igAccountInfo] }))
            let payloadfinal = { ...pageData }
            payloadfinal.instagram_username = igAccountInfo.username
            payloadfinal.instagram_id = igAccountId
            setResponsePayload(payloadfinal)
            // Use the Instagram account info for your application needs
        })
    }

    function getInstagramAccessToken() {
        window.FB.api("/me/accounts?fields=access_token,category,category_list,name,id,tasks,instagram_business_account", function (result: any) {
            console.log("result: ", result)
            if (result && !result.error) {
                const pages = result.data
                console.log("pages: ", pages)
                for (let i = 0; i < 1; i++) {
                    const page = pages[i]
                    // window.FB.api(`/${page.id}?fields=instagram_business_account`, function (pageInfo: any) {
                    // console.log("pageInfo: ", pageInfo)
                    let pageData = {
                        page_id: page.id,
                        page_name: page.name,
                        page_category: page.category,
                        access_token_value: page.access_token,
                    }

                    if (page.instagram_business_account) {
                        const igAccountId = page.instagram_business_account.id
                        console.log("Instagram Business Account ID: " + igAccountId)
                        // Fetch Instagram account details using igAccountId
                        fetchInstagramAccountDetails(igAccountId, pageData)
                    } else {
                        console.log(`No Instagram Business Account linked to page ${page.name}`)
                    }
                    // })
                }
            } else {
                console.log("Error fetching Instagram account access token:", result.error)
            }
        })
    }
    console.log("Responsedata", responsePayload)


    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    console.log('User logged out successfully', logoutResponse);
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };
    useEffect(() => {
        (async () => {
            if (responsePayload && responsePayload.instagram_id && responsePayload.instagram_id !== "") {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
                let metaIntegrationTypeId = globalIntegrationTypeList.find((type) => type.meta_integration_type_name.toLowerCase() === "instagram")?.meta_integration_type_id ?? Number(process.env.REACT_APP_FACEBOOK_META_INTEGRATION_TYPE_ID);
                console.log("metaIntegrationTypeId----", metaIntegrationTypeId)
                if (!metaIntegrationTypeId) {
                    console.error("Meta Integration Type ID for Facebook is undefined");
                    return;
                }

                const facebookPayload: any = {
                    meta_integration_type_id: metaIntegrationTypeId,
                    meta_integration_facebook_data: [responsePayload],
                    meta_data: JSON.stringify(responsePayload)
                };

                setInstagramPagesData((prevState) => ({ ...prevState, flagShowPopup: true }))
                const response = await agent.createMetaIntegration.post(facebookPayload);
                if (response.status) {
                    setIntegrationList((prevState: any) => ([...prevState, ...response.data]))
                    setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
                    let sortedData = response.data.sort((a: globalIntegration, b: globalIntegration) => new Date(b.created_at_timestamp).getTime() - new Date(a.created_at_timestamp).getTime())
                    navigate(`/channels/viewintegration/instagram?id=${sortedData[0].meta_integration_id}`)
                    setFacebookIntegrationDetails(sortedData[0])
                    setShowFacebookIntegrationDetails(true)
                }
                else {
                    let errorCode = checkForErrorCode(response)
                    if (response.message == "canceled") {
                    }
                    else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    safeLogout()
                }
                setResponsePayload({
                    page_id: '',
                    page_name: '',
                    page_category: '',
                    access_token_value: ''
                })
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

            }
        })();
    }, [responsePayload]);

    const innerHeight = smallScreen ? (window.innerHeight - 90) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 100) : (window.innerHeight - 110);
    const integrationViewInnerHeight = smallScreen ? (window.innerHeight - 110) : tabletScreen ? (window.innerHeight - 100) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 140);

    return (
        <>
            {instagramPagesData.flagShowPopup &&
                <div>
                    <GlobalPopUp
                        open={instagramPagesData.flagShowPopup}
                        onClose={() => { setInstagramPagesData((prevState) => ({ ...prevState, flagShowPopup: false })) }}
                        title={"Thank you for giving us access to your pages. We now have access to the following Instagram pages:"}
                        flagBackdrop
                        height='70%'
                        paperMinWidth={"70%"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setInstagramPagesData((prevState) => ({ ...prevState, flagShowPopup: false })) }}
                    >
                        <Grid item>
                            <Grid container direction={"column"} gap="25px" sx={{ padding: "25px" }}>
                                <Grid item>
                                    {instagramPagesData.instagramPages.map((data: any, index: number) => (
                                        <>
                                            <TempInstagramCard
                                                integrationType='Instagram'
                                                key={index}
                                                flagDelete={false}
                                                title={`Instgram Page ${index + 1}`}
                                                currentlySelected={false}
                                                details={{
                                                    page_id: data?.id,
                                                    page_name: data?.username,
                                                    page_category: data?.category,
                                                    biography: data?.biography,
                                                    profile_picture_url: data?.profile_picture_url,
                                                    followers_count: data?.followers_count,
                                                    follows_count: data?.follows_count
                                                }}

                                            />
                                        </>
                                    ))}
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        onClick={() => { setInstagramPagesData((prevState) => ({ ...prevState, flagShowPopup: false })) }}
                                        buttonText={"Continue"}
                                        className='primaryButtonStyle'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </GlobalPopUp>
                </div>
            }
            <Grid item
                className={showFacebookIntegrationDetails == false ? `${globalStyles.fullwidth} ${styles.integrationDefaultView}` : `${globalStyles.fullwidth} ${styles.integrationView}`}
                height={showFacebookIntegrationDetails == false ? `${innerHeight}px !important` : `${integrationViewInnerHeight}px !important`}
            >
                <Grid container>

                    {(showFacebookIntegrationDetails) ?
                        <>
                            <Grid item className={globalStyles.fullwidth} xs={12} lg={12} xl={12}>
                                <Grid container direction={"column"} gap={3} flexWrap={"wrap"}>
                                    <InstagramIntegationDetails flagPage={props.flagPage} />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            {showFacebookIntegrationDetails == false &&
                                <Grid item className={globalStyles.fullwidth} xs={12} lg={8} xl={6}>
                                    <Grid container direction={"column"} gap={2} flexWrap={"nowrap"} pt={1} >
                                        <IntegrationSetupPrerequisites
                                            launch={loginToInstagram}
                                            viewIntegration={true}
                                            heading={"Integrate AI Chatbot into your Instagram Business account to boost customer engagement and efficiency."}
                                            integrationType={'Instagram'}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item className={styles.integrateGridStyles} marginBlock={"20px"}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <GlobalButton
                                                        buttonText={"Integrate Now"}
                                                        className="primaryButtonStyle"
                                                        onClick={() => {
                                                            loginToInstagram()
                                                        }}
                                                        sx={{ padding: "6px 20px !important" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </>
                    }
                </Grid>
            </Grid>

        </>
    )
}

export default ViewInstagramIntegration