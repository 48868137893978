import { Grid } from '@mui/material';
import React, { ReactElement } from 'react'
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/Global/emptyState.module.scss";
interface templateCardProps {
    height: string,
    maxHeight?: string,
    pageIcon: ReactElement,
    pageSubText: string,
    primaryActionBtn: ReactElement,
    secondaryActionBtn?: ReactElement,
}

function EmptyState(props: templateCardProps) {
    return (
        <Grid item xs={12} sx={{ height: props.height, maxHeight: props.maxHeight ? props.maxHeight : "auto", overflow: "auto" }} >
            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} className={globalStyles.fullHeight}>
                <Grid item className={styles.pageIconStyle}>{props.pageIcon}</Grid>
                <Grid item className={`${globalStyles.descriptionText}`}>{props.pageSubText}</Grid>
                <Grid item className={styles.primaryActionBtnSpacing}>{props.primaryActionBtn}</Grid>
                {props.secondaryActionBtn &&
                    <Grid item>{props.secondaryActionBtn}</Grid>}
            </Grid>
        </Grid>
    )
}

export default EmptyState