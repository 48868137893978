import { Breadcrumbs, Button, Divider, Grid, IconButton, Link, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import globalStyles from '../../../assets/styles/global.module.scss'
import styles from '../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import AddIcon from '@mui/icons-material/Add'
import createPersonaIcon from "../../../assets/createPersona.png";
import templateStyles from '../../../assets/styles/componentStyles/template.module.scss'
import IconsColor from '../../utility/iconsColor'
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext'

const FacebookIntegration = () => {
    const navigate = useNavigate();
    const { integrationList } = React.useContext(IntegrationContext) as IntegrationContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    let id = searchParams.get('id')
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    let pathname = window.location.pathname.toLowerCase();

    return (
        <>{pathname !== '/channels/viewintegration/facebook/templateinfo' &&
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                <Grid container sx={{ padding: { xs: "15px 20px 0px 20px", lg: "25px 25px 0px 27px" } }}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item xl={6} lg={5} md={9} sm={9} xs={9} sx={{ marginBottom: { xs: "0px", lg: "10px" } }}>
                                <GlobalPageHeaderText
                                    mainHeadText={
                                        pathname === '/channels/viewintegration/facebook/templatemessages' && id ? "Template Messages" :
                                            pathname === '/channels/viewintegration/facebook/templateinfo' && id ? "Template Info" :
                                                pathname === '/channels/viewintegration/facebook/pages' && id ? "Pages" :
                                                    // id ? "WhatsApp Integration" : "New Integration"}
                                                    id ? "Account Setup" : "New Channel"}
                                    flagMainTextOnly
                                    mainSubText={
                                        !smallScreen && <Breadcrumbs
                                            aria-label="breadcrumb"
                                            className={styles.cbHeaderBreadCrumb}
                                            sx={{
                                                '& .MuiBreadcrumbs-separator': {
                                                    marginRight: '3px',
                                                    marginLeft: '3px'
                                                }
                                            }}
                                        >
                                            {(pathname !== "/" && pathname !== "/chatbot") &&
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate("/chatbot") }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    Home
                                                </Link>}
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate("/channels") }}
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                            >
                                                Channels
                                            </Link>

                                            {id &&
                                                <Link
                                                    underline="hover"
                                                    onClick={() => { navigate(`/channels/viewintegration/facebook?id=${id}`) }}
                                                    className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                                >
                                                    {integrationList?.find((list) => list.meta_integration_id === Number(searchParams.get('id')))?.meta_integration_facebook_data?.page_name}
                                                </Link>
                                            }

                                            {pathname === "/channels/viewintegration/facebook" &&
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                    onClick={() => { id ? navigate(`/channels/viewintegration/facebook?id=${id}`) : navigate(`/channels/viewintegration/facebook`) }}
                                                >
                                                    Account Setup
                                                </Link>
                                            }

                                            {pathname === "/channels/viewintegration/facebook/pages" &&
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                    onClick={() => { id ? navigate(`/channels/viewintegration/facebook?id=${id}`) : navigate(`/channels/viewintegration/facebook`) }}
                                                >
                                                    Pages
                                                </Link>
                                            }

                                            {pathname === "/channels/viewintegration/facebook/templatemessages" &&
                                                <Link
                                                    underline="hover"
                                                    className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                                                >
                                                    Template Messages
                                                </Link>
                                            }

                                        </Breadcrumbs>
                                    }
                                />
                            </Grid>
                            {pathname === '/channels/viewintegration/facebook/templatemessages' &&
                                <Grid item xl={6} lg={7} md={2.5} sm={2.5} xs={2.5} paddingRight={{ xs: "5px", lg: "0px" }}>
                                    <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} justifyContent={{ lg: "end", xs: "end" }}>
                                        <Grid item xl={4} lg={5} md={4.5} sm={2.5} xs={2.5} className={`${globalStyles.globalHeadRightSubItem}`}>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                <Grid item className={`${globalStyles.globalNewAddBtnItem} ${templateStyles.addChatbotBtn}`}>
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        fullWidth
                                                        startIcon={<IconsColor iconName="plus" fillColor="#fff" width="15px" height="15px" />}
                                                        className={globalStyles.addDocumentBtn}
                                                        onClick={() => navigate(`/channels/viewintegration/facebook/templateinfo?id=${id}`)}
                                                    >
                                                        Create Template
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        className={`${globalStyles.cmpGlobalMobileAddIcon} ${templateStyles.addChatbotIcon}`}
                                                        onClick={() => navigate(`/channels/viewintegration/facebook/templatemessages?id=${id}`)}
                                                        style={{ zIndex: "1" }}
                                                    >
                                                        <AddIcon style={{ height: "28px", width: 'auto' }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {!smallScreen && <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyles}`} />}
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
            {pathname === '/channels/viewintegration/facebook/templateinfo' && <Outlet />}
        </>
    )
}

export default FacebookIntegration
