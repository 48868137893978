import { Grid, Button, Menu, MenuItem, ClickAwayListener } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/templateCard.module.scss";
import { GridMoreVertIcon } from '@mui/x-data-grid';

interface templateCardProps {
    class?: any;
    templateStyle?: any;
    cardName: string;
    cardDescription?: string;
    cardOnClick: (e?: React.MouseEvent<HTMLElement>) => void;
    cardImage: ReactElement;
    cardActionItem?: ReactElement;
    cardActionButton?: ReactElement;
    badgeIntegratedDetails?: number | string;
    badgeDetails?: number | string;
    cardNameStyle?: any;
    activityDetails?: any;
    activityStyle?: any;
    extraFields?: any;
    flagFieldCount?: any;
    MoreVertIcon?: any;
    fieldText?: any;
    onMoreVertClick?: any;
    menuItems?: any;
}

function TemplateCard(props: templateCardProps) {

    const [moreVertMenu, setMoreVertMenu] = useState<null | HTMLElement>(null);

    const handleMoreVertClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault()
        setMoreVertMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMoreVertMenu(null);
    };
    const open = Boolean(moreVertMenu);

    return (
        <>
            <Grid item sx={{ position: "relative" }} xl={2.8} lg={4} md={5.5} sm={12} xs={12}
                onClick={(e) => {
                    props.cardOnClick();
                }}
                className={`${globalStyles.fullwidth} ${styles.templateTileStyle} ${globalStyles.clickable} ${props.class ? props.class : ''}`}>
                {props.badgeDetails &&
                    <span className={styles.badgeStyle}>{props.badgeIntegratedDetails}/{props.badgeDetails}</span>}

                <Grid container direction="column">
                    <Grid item className={styles.templateIconStyle}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                {props.cardImage}
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"} gap={1} >
                                    {props.flagFieldCount?.length > 0 &&
                                        <Grid item className={styles.fieldCountStyle}>
                                            {props.fieldText}
                                        </Grid>
                                    }
                                    {props.MoreVertIcon && props.menuItems && props.menuItems.length > 0 &&
                                        <Grid item>
                                            <span onClick={handleMoreVertClick}>
                                                <GridMoreVertIcon sx={{ color: "#7A7A7A", fontSize: "20px" }} />
                                            </span>

                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item className={props.cardNameStyle ? props.cardNameStyle : styles.templateTitleTextstyle} >
                        {props.cardName}
                    </Grid>

                    {props.activityDetails &&
                        <Grid item className={styles.activityGrid}>
                            <span className={props.activityStyle ? props.activityStyle : styles.activityStyle}>{props.activityDetails}</span>
                        </Grid>
                    }

                    {props.cardDescription &&
                        <Grid item className={`${styles.templateDescriptionTextStyle} ${styles.templateDescOverflowStyle}`}>
                            {props.cardDescription}
                        </Grid>
                    }

                    {props.extraFields &&
                        props.extraFields.map((fields: any, index: number) => (
                            <Grid item key={index}>
                                <Grid container direction={"column"}>
                                    <Grid item className={fields.fieldNameStyle || styles.fieldName}>
                                        {fields.fieldName}
                                    </Grid>
                                    <Grid item className={fields.fieldValueStyle || styles.fieldValue}>
                                        {fields.fieldValue}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }

                    {props.cardActionItem &&
                        <Grid item sx={{ position: "absolute", bottom: "20px" }}>
                            {props.cardActionItem}
                        </Grid>
                    }

                    {props.cardActionButton &&
                        <Grid item sx={{ position: "absolute", bottom: "20px", right: "20px" }}>
                            {props.cardActionButton}
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Menu
                variant='menu'
                anchorEl={moreVertMenu}
                open={open}
                onClose={handleCloseMenu}
            >
                {props.MoreVertIcon && props.menuItems && props.menuItems.map((item: any, index: any) => (
                    <MenuItem className={globalStyles.menuItemStyle} key={index} onClick={(e) => { item.action(e); handleCloseMenu(); }}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>

    );
}

export default TemplateCard;
