import React, { useContext } from 'react'
import { Autocomplete, Box, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import globalStyles from '../../assets/styles/global.module.scss'
import { GenericDataContext, GenericDataContextPayload } from '../../context/genericDataContext';
import { UserContext, UserContextPayload } from '../../context/userContext';
import timezoneImg from "../../assets/images/chatbot/timezone.png";
import { timezoneList } from '../../models/axiosModel';
import agent from '../../services/api';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const GlobalPageHeaderText = (props: any) => {
    const navigate = useNavigate()
    const mobileScreen: boolean = useMediaQuery('(min-width: 0px) and (max-width: 599px)');
    const { genericTimezoneList } = useContext(GenericDataContext) as GenericDataContextPayload
    const { userDetail, setUserDetail, setError, error, setSuccess, setFlagLoading } = useContext(UserContext) as UserContextPayload;
    const updateUserTimezone = async (value: timezoneList) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let payload = {
            timezone_id: value.timezone_id
        }
        const response = await agent.updateUserTimezone.put(payload)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Timezone updated Successfully!" }))
            setUserDetail((prevState) => ({ ...prevState, timezone_id: value.timezone_id, timezone_hour: value.timezone_hour, timezone_name: value.timezone_name, timezone_standard_name: value.timezone_standard_name }))
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    return (
        <>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }} gap={{ xs: "0px", lg: '15px' }} flexWrap={{md: "nowrap"}}>
                {/* left block */}
                <Grid item xs={11} sm={11} md={11} lg={10} xl={5.5} sx={{display: props.flagMainTextOnly ? "flex" : "", alignItems: props.flagMainTextOnly ? "center" : ""}}>
                    <Grid container>
                        {mobileScreen ? (<></>) : (
                            <Grid item xs={12}>
                                <Typography
                                    component={'span'}
                                    className={`${globalStyles.globalHeadText} ${globalStyles.globalHeaderFontSize} ${globalStyles.globalHeaderFontWeight}`}
                                >
                                    {props.mainHeadText}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ marginBottom: { xs: !props.flagMainTextOnly ? "10px" : "0px", sm: "0px" } }}>
                            <Typography
                                component={'span'}
                                className={`${globalStyles.globalDecsText}`}
                            >
                                {props.mainSubText}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} sm={11} md={props.actionBlock ? 11 : 3} lg={4} xl={5.5}>
                    <Grid container sx={{ justifyContent: { sm: "start", xs: "start", md: "end", lg: "end", xl: "end" }, alignContent: "center", height: "100%", gap: "15px" }}>
                        {props.actionBlock}
                        {props.flagTimezone &&
                            <Grid item sx={{ minWidth: "270px" }} padding={props.flagMainTextOnly ? "0px" : ""}>
                                <Grid container flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                                    {/* <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={timezoneImg} alt="timezone" style={{ width: "25px", height: "25px" }} />
                                    </Box> */}
                                    <Autocomplete
                                        className={globalStyles.textVariantAutocompleteStyle}
                                        options={genericTimezoneList}
                                        getOptionLabel={(option) => option.timezone_name}
                                        renderInput={(params) => (<TextField {...params} />)}
                                        value={genericTimezoneList.find((timezone) => timezone.timezone_id == userDetail.timezone_id)}
                                        // getOptionDisabled={(option) => option.timezone_id !== userDetail.timezone_id}
                                        onChange={(event, value) => {
                                            if (value) {
                                                updateUserTimezone(value)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default GlobalPageHeaderText