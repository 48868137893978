import { Grid } from '@mui/material'
import React from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { BarChart } from '@mui/x-charts/BarChart';

const CallAnalysisBarChart = (props: any) => {
    const {fieldName, fieldData } = props;

    const categories = fieldName === "Complexity" ? fieldData.map((item: any) => item.call_complexity_level) : fieldName === "Categories" ? fieldData.map((item: any) => item.call_category_name) : fieldData.map((item: any) => item.agent_name);
    const scores = fieldData.map((item: any) => parseFloat(item.average_score));

    const barColors = ['#6A097D'];

    return (
        <Grid container direction={"column"} alignItems={"start"} className={dashboardStyles.pieChartContainer}>
            <Grid item className={dashboardStyles.barContainer} sx={{zIndex: 2}}>
                <span className={dashboardStyles.pieChartHeadingName}>{`Average Score Breakdown of ${fieldName}`}</span>
            </Grid>

            <Grid item style={{ width: '100%', overflow: "auto" }}>
                {/* Bar Chart Component */}
                <div style={{ width: '100%'}}>
                    <BarChart
                        grid={{ horizontal: true}}
                        className={fieldName === "Agent" ? `${dashboardStyles.customBarChart} ${dashboardStyles.agentGraphStyle}` : `${dashboardStyles.customBarChart}`}
                        // margin={{bottom: 30, left: 60}}
                        xAxis={[
                            {
                                data: categories,
                                scaleType: 'band',
                                disableTicks: true,
                                disableLine: true,
                                tickLabelStyle: {
                                    angle: fieldName === "Agent" ? -65 : -50,
                                    textAnchor: 'end',
                                    fontSize: 11,
                                    overflowWrap: "break-word",
                                    width: 10,
                                    height: 10,
                                    maxWidth: 10,
                                    padding: "5px",
                                },
                                //@ts-ignore
                                categoryGapRatio: 0.2,
                            },
                        ]}
                        yAxis={[
                            {
                                min: 0,
                                max: 100,
                                disableTicks: true,
                                disableLine: true,
                                tickLabelStyle: {
                                    fontSize: 13,
                                }
                            },
                        ]}
                        series={[
                            {
                                data: scores,
                                type: "bar",
                            },
                        ]}
                        sx={{
                            '& .MuiChartsAxisHighlight-root': {
                                stroke: "none",
                                strokeDasharray: "none",
                                fill: "rgba(106,9,125,0.25)",
                                maxWidth: 50
                            },
                            '& .MuiChartsAxis-line': {
                                stroke: "transparent",
                                strokeDasharray: "none",
                            },
                            '& .MuiChartsAxis-tick': {
                                stroke: "#F2F2F2",
                                strokeDasharray: "none",
                            },
                            '& .MuiBarElement-root': {
                                x: fieldName === "Complexity" ? "2vw" : fieldName === "Categories" ? "1.5vw" : "0.25vw",
                            }
                        }}
                        borderRadius={0}
                        height={fieldName === "Agent" ? 360 : 300}
                        colors={fieldName === "Complexity" ? ['#6A097D'] : fieldName === "Categories" ? ['#0A6ADD'] : ['#FFB762']}
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default CallAnalysisBarChart
