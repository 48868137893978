import React, { useCallback, useEffect, useState } from "react";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import { useContext } from "react";
import styles from "./../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss";

const MixedModeTagify = (props) => {
    const { value, setValue, whiteListOptions, editIntegrationData } = props;

    const [settings, setSettings] = useState({
        pattern: /@/,
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: whiteListOptions
    });

    const [inputValue, setInputValue] = useState("");
    const [key, setKey] = useState(1);

    useEffect(() => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            whitelist: whiteListOptions
        }));
        setKey(key + 1);
    }, [whiteListOptions]);

    useEffect(() => {
        if (value) {
            let temp = value;
            temp = temp.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');
            if (temp !== value) {
                setValue(temp)
            }
        }
    }, [value])

    const onChange = useCallback((e) => {
        e.preventDefault && e.preventDefault();
        console.log("CHANGED:", e.detail.value);
        const inputString = "This is a test string with @fir\n and some other text.";
        const regex = /@[^[\]{}]*\n/
        if (regex.test(e.detail.value)) {
            // console.log("String contains '@' followed by something up to '\\n'");
        } else {
            // console.log("else block", e.detail.value)

            // console.log("String does not contain '@' followed by something up to '\\n'");
        }

    }, []);

    const handlingOnBlur = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        // let val = e.detail.tagify.state.lastOriginalValueReported
        let temp = e.detail.tagify.DOM.originalInput.tagifyValue
        if (temp) {
            setValue(temp.replace(/\n+$/, ''))
        }
        // if (e.detail.tagify.state.lastOriginalValueReported) {
        //     setValue(temp.replace(/\n+$/, ''))
        // }
    }
    

    return (
        <>
            <MixedTags
                placeholder="select allowed fields using @ and select field"
                key={key}
                showDropdown
                settings={settings}
                // className={editIntegrationData ? `${styles.templatePromp}` : `${styles.templatePrompDisble}`}
                className={`${styles.templatePromp}` }
                readOnly={!editIntegrationData ? true : false}
                onChange={onChange}
                value={value}
                onBlur={handlingOnBlur}
                onKeyDown={(e) => {
                    alert("hkjh")
                    e && e.preventDefault && e.preventDefault();
                }}
            // value={props.value}
            />
        </>
    );
};
export default MixedModeTagify;