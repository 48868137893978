import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import React, { useContext } from 'react'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'

const GeneratedLeadData = () => {
    const {leadGenerationList} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload

    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "calc(100vh - 250px)"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data.sort((a: any, b: any) => a.chatbot_lead_field_id - b.chatbot_lead_field_id).map((data) => 
                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle} sx={{whiteSpace: "nowrap"}}>{data.chatbot_lead_field_name}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {leadGenerationList?.chatbot_generated_lead_data && leadGenerationList.chatbot_generated_lead_data.length > 0 ?
                        leadGenerationList.chatbot_generated_lead_data.map((data) => 
                            <TableRow className={dashboardStyles.cellRow}>
                                {leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_data.map((obj) => {
                                    const filteredData = data.chatbot_lead_data.filter((leadData) => 
                                        Number(leadData.chatbot_lead_field_id) === Number(obj.chatbot_lead_field_id)
                                    );

                                    if (filteredData.length > 0) {
                                        return (
                                            filteredData.map((lead) => (
                                                <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} key={lead.chatbot_lead_field_id}>
                                                    {lead.chatbot_lead_field_value}
                                                </TableCell>
                                            ))
                                        );
                                    } else {
                                        return (
                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle} key={obj.chatbot_lead_field_id}>
                                                <span>-</span>
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        )
                        :
                        <TableRow>
                            <TableCell colSpan={leadGenerationList?.chatbot_lead_data[0].chatbot_lead_field_count} sx={{textAlign: "center"}} className={dashboardStyles.tableBodySubHeadingFontStyle}>No Lead Data Generated Yet.</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GeneratedLeadData
