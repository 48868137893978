import React, { useContext, useEffect, useState } from 'react'
import AccountInformationCard from '../../../utility/accountInformationCard';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import IconsColor from '../../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import { useSearchParams } from 'react-router-dom';
import agent from '../../../../services/api';
import { MetaContext, MetaContextPayload } from '../../../../context/metaContext';
import { chatbotIntegrationAssignmentData, CommonResponseModel, createMetaIntegrationPayload, globalIntegration, integrationData, metaIntegrationAccessTokenData, metaIntegrationPhoneNumberData, metaIntegrationPhoneNumberDataModel } from '../../../../models/axiosModel';
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import GlobalPopUp from '../../../utility/globalPopUp';
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import facebookIcon from "../../../../assets/images/channels/facebook/facebookMessenger.svg";
import ChatbotWhatsAppIntegrationSetup from '../chatbotWhatsAppIntegrationSetup';
import GlobalButton from '../../../utility/globalButton/button';
import { IntegrationContext, IntegrationContextPayload } from '../../../../context/integrationContext';
import globalStyles from '../../../../assets/styles/global.module.scss'
import integrationStyles from '../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import ShowAvailablePages from './showAvailablePages';
import FacebookInformationCard from '../../../utility/facebookInformationCard';
import ChatbotFacebookIntegrationSetup from '../chatbotFacebookIntegrationSetup';
interface linkedPageData {
    chatbot_integration_assignment_data: chatbotIntegrationAssignmentData[],
    created_at_timestamp: string,
    instagram_id: string,
    instagram_username: string,
    meta_integration_access_token_data: metaIntegrationAccessTokenData,
    meta_integration_facebook_flag_webhook: boolean,
    meta_integration_facebook_id: number,
    page_category: string,
    page_id: string,
    page_name: string,
    meta_integration_id: number,
    meta_integration_type_id: number,
}
declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}

interface addChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    page_id: string,
    meta_integration_type_id: string
}
const ChatbotFacebookIntegration = () => {
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const mediaScreen = useMediaQuery('(max-width : 1200px) and (min-width : 0px)')
    const [flagFacebookIntegrationExist, setflagFacebookIntegrationExist] = useState<boolean | null>(null)
    const [searchParams] = useSearchParams();
    const { setSuccess, success, setError, error, setFlagLoading, flagLoading, userDetail, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotIntegrationData, getChatbotIntegrationData, setGetChatbotIntegrationData, metaCredentials } = useContext(MetaContext) as MetaContextPayload;
    const { showIntegrationDetails, setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { getIntegrationList, globalIntegrationTypeList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [phoneNumberIntegrate, setPhoneNumberIntegrate] = useState<any>()
    const [linkedPagesData, setLinkedPagesData] = useState<linkedPageData[]>([])
    const [flagShowAvailablePages, setFlagShowAvailablePages] = useState(false)
    const [availablePagesData, setAvailablePagesData] = useState<globalIntegration[]>([])
    const [addIntegrationDetails, setAddIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface | null>(null)
    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        meta_integration_id: "",
        chatbot_id: "",
        page_id: "",
        meta_integration_type_id: ""
    })
    const [removeChatbotIntegrationDetails, setRemoveChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        chatbot_id: "",
        meta_integration_type_id: "",
        page_id: "",
        meta_integration_id: ""
    })
    const [responsePayload, setResponsePayload] = useState<any>({
        page_id: '',
        page_name: '',
        page_category: '',
        access_token_value: ''
    })
    useEffect(() => {
        (async () => {
            if (responsePayload && responsePayload.length > 0) {

                let metaIntegrationTypeId = globalIntegrationTypeList.find((type) => type.meta_integration_type_name.toLowerCase() === "facebook")?.meta_integration_type_id ?? Number(process.env.REACT_APP_FACEBOOK_META_INTEGRATION_TYPE_ID);

                if (!metaIntegrationTypeId) {
                    console.error("Meta Integration Type ID for Facebook is undefined");
                    return;
                }

                const facebookPayload: any = {
                    meta_integration_type_id: metaIntegrationTypeId,
                    meta_integration_facebook_data: responsePayload?.map((page: any) => ({
                        page_id: page?.page_id,
                        page_name: page?.page_name,
                        page_category: page?.page_category,
                        access_token_value: page?.access_token,
                    })),
                    meta_data: JSON.stringify(responsePayload)
                };
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))

                const response = await agent.createMetaIntegration.post(facebookPayload);
                if (response.status) {
                    setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
                    let data = response.data[0]
                    let chatbot_id = searchParams.get('chatbot_id')
                    if (chatbot_id) {
                        let payload = {
                            chatbot_id: chatbot_id,
                            meta_integration_id: data.meta_integration_id,
                            meta_integration_type_id: data.meta_integration_type_assignment_data.meta_integration_type_id,
                            page_id: data.meta_integration_facebook_data.page_id
                        }
                        AddChatbotIntegration(payload)
                    }
                }
                else {
                    let errorCode = checkForErrorCode(response)
                    if (response.message == "canceled") {
                    }
                    else {
                        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    safeLogout()
                }
            }
        })();
    }, [responsePayload]);

    // function handleOpenFacebookIntegrationDetails(integration_assignment_id: number) {
    //     let chatbot_id = searchParams.get('chatbot_id')
    //     navigate(`/chatbot/viewchatbot/channels/viewFacebookIntegration?chatbot_id=${chatbot_id}&assignment_id=${integration_assignment_id}`)
    // }
    // const addChatbotIntegration = async () => {
    //     setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
    //     let payload = {
    //         "meta_integration_id": "",
    //         "chatbot_id": "",
    //         "page_id": "",
    //         "meta_integration_type_id": ""
    //     }
    //     let chatbot_id = searchParams.get('chatbot_id')
    //     if (addIntegrationDetails && chatbot_id) {
    //         payload = {
    //             "meta_integration_id": addIntegrationDetails.meta_integration_id.toString(),
    //             "chatbot_id": chatbot_id.toString(),
    //             "page_id": addIntegrationDetails.page_id,
    //             "meta_integration_type_id": addIntegrationDetails.meta_integration_type_id.toString()
    //         }
    //     }
    //     const response = await agent.addChatbotIntegration.post(payload)
    //     if (response.status) {
    //         setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration created successfully" }))
    //         setGetChatbotIntegrationData(response.data)
    //     } else {
    //         let errorCode = checkForErrorCode(response);
    //         if (ifUserMessageShouldBeDynamic(errorCode)) {
    //             setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
    //         } else {
    //             setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
    //         }
    //     }
    //     setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    //     // handleCloseAddIntegrationPopup()
    // }
    function addChatbotIntegration(meta_integration_id: number, meta_integration_type_id: number, page_id: string, display_page_name: string, event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            handleClick(event)
        }
        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id) {
            setAddChatbotIntegrationDetails((prevState) => ({
                ...prevState,
                page_id: page_id,
                meta_integration_type_id: meta_integration_type_id.toString(),
                chatbot_id: chatbot_id,
                meta_integration_id: meta_integration_id.toString()
            }))
        }
        setPhoneNumberIntegrate(display_page_name)
    }
    useEffect(() => {
        getIntegrationList()
    }, [])

    useEffect(() => {
        let ids: any = []
        if (integrationList !== null && integrationList.length > 0) {
            let facebookFilter = integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name == "Facebook" && !integration.meta_integration_facebook_data?.instagram_id);
            facebookFilter?.map((data) => {
                data?.meta_integration_facebook_data?.chatbot_integration_assignment_data.map((assignmentData) => {
                    ids.push(String(assignmentData.chatbot_id))
                })
            })
            let validID = ids.includes(chatBotDetails?.chatbot_id);

            if (validID && facebookFilter.length > 0 && integrationList.flatMap((integration: globalIntegration) => ({ ...integration.meta_integration_facebook_data, ...integration })).filter((data) => data).length > 0) {
                setflagFacebookIntegrationExist(true)
                setShowIntegrationDetails(true)
            } else {
                setflagFacebookIntegrationExist(false)
                setShowIntegrationDetails(false)
            }
        } else {
            setflagFacebookIntegrationExist(false)
            setShowIntegrationDetails(false)
        }

    }, [getChatbotIntegrationData, chatBotDetails, window.location.href, integrationList])

    useEffect(() => {
        if (integrationList) {
            let temp: any = integrationList.filter((data) => data.meta_integration_type_assignment_data.meta_integration_type_name === "Facebook" && !data.meta_integration_facebook_data?.instagram_id).flatMap((integration) => ({ ...integration.meta_integration_facebook_data, meta_integration_id: integration.meta_integration_id, meta_integration_type_id: integration.meta_integration_type_assignment_data.meta_integration_type_id })).flatMap((facebookData) => ({ ...facebookData, meta_integration_id: facebookData.meta_integration_id, meta_integration_type_id: facebookData.meta_integration_type_id }))
            if (temp) {
                let linkedPhoneNumber: linkedPageData[] = temp.filter((phoneNumber: linkedPageData) => phoneNumber?.chatbot_integration_assignment_data.some((chatbot) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')))
                if (temp) {
                    if (linkedPhoneNumber) {
                        setLinkedPagesData(linkedPhoneNumber)
                    }
                    setAvailablePagesData(integrationList)
                }
            }
        }
    }, [integrationList])


    useEffect(() => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        if (metaCredentials) {
            let facebookConfig = metaCredentials.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "facebook")
            window.fbAsyncInit = function () {
                // @ts-ignore
                FB.init(facebookConfig.fb_init);
            };
        }
    }, [metaCredentials]);


    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };


    const getPagesList = () => {
        window.FB.api("/me/accounts", (result: any) => {
            if (result && !result.error) {
                const pages = result.data;
                if (pages.length) {
                    const transformedData = pages?.map((page: any) => ({
                        access_token: page.access_token,
                        page_id: page.id,
                        page_name: page.name,
                        page_category: page.category,
                        page_categoryList: page.category_list.map((cat: any) => ({
                            id: cat.id,
                            name: cat.name
                        }))
                    }));
                    setResponsePayload(transformedData)
                }
                else {
                    setError({ ...error, flagShowError: true, message: "No page was selected while integrating." })
                }
            }
            else {
                console.log("Error fetching page access token:", result.error);
                // setError({ ...error, flagShowError: true, message: "No page was selected while integrating." })  
            }
        });
    };
    const loginToFacebook = () => {
        window.FB.login((response: any) => {
            if (response.authResponse) {
                window.FB.api("/me", (res: any) => {
                    getPagesList();
                });
            } else {
                console.log("User cancelled login or did not fully authorize.");
                // handleCloseAddIntegrationPopup()
            }
        }, { scope: "pages_show_list, pages_messaging, pages_manage_metadata, business_management" });
    };
    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbotIntegration.delete(removeChatbotIntegrationDetails.chatbot_id, removeChatbotIntegrationDetails.meta_integration_id, removeChatbotIntegrationDetails.meta_integration_type_id, removeChatbotIntegrationDetails.page_id);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(removeChatbotIntegrationDetails.chatbot_id, "")
            getIntegrationList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setShowDeleteConfirmation(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }


    const handleRemoveChatbotIntegration = (meta_integration_id: number, meta_integration_type_id: number, page_id: string, display_phone_number: string) => {
        // debugger;
        if (integrationList) {
            let chatbot_id = searchParams.get('chatbot_id')
            if (chatbot_id) {
                setRemoveChatbotIntegrationDetails(
                    {
                        chatbot_id: chatbot_id,
                        meta_integration_type_id: meta_integration_type_id.toString(),
                        page_id: page_id,
                        meta_integration_id: meta_integration_id.toString()
                    }
                )
            }
            setShowDeleteConfirmation(true)
            setPhoneNumberIntegrate(display_phone_number)
        }
    }

    // const addChatbotIntegration = async () => {
    //     setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
    //     let payload = {
    //         "meta_integration_id": "",
    //         "chatbot_id": "",
    //         "page_id": "",
    //         "meta_integration_type_id": ""
    //     }
    //     let chatbot_id = searchParams.get('chatbot_id')
    //     if (addIntegrationDetails && chatbot_id && addIntegrationDetails.metaData.meta_integration_facebook_data) {
    //         payload = {
    //             "meta_integration_id": addIntegrationDetails.metaData.meta_integration_id.toString(),
    //             "chatbot_id": chatbot_id.toString(),
    //             "page_id": addIntegrationDetails.metaData.meta_integration_facebook_data.page_id,
    //             "meta_integration_type_id": addIntegrationDetails.metaData.meta_integration_type_assignment_data.meta_integration_type_id.toString()
    //         }
    //     }
    //     const response = await agent.addChatbotIntegration.post(payload)
    //     if (response.status) {
    //         setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration created successfully" }))
    //         setGetChatbotIntegrationData(response.data)
    //     } else {
    //         let errorCode = checkForErrorCode(response);
    //         if (ifUserMessageShouldBeDynamic(errorCode)) {
    //             setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
    //         } else {
    //             setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
    //         }
    //     }
    //     setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    //     handleCloseAddIntegrationPopup()
    // }
    async function AddChatbotIntegration(addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface) {
        const response = await agent.addChatbotIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            setGetChatbotIntegrationData(response.data)
            getIntegrationList()
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagShowAvailablePages(false)
        handleCloseConfirmationPopover()
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    const [showConfirmationPopover, setShowConfirmationPopover] = React.useState(false);

    const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setConfirmationPopoverAnchorEl(event.currentTarget);
        setShowConfirmationPopover(true)
    };

    const handleCloseConfirmationPopover = () => {
        setConfirmationPopoverAnchorEl(null);
        setShowConfirmationPopover(false)
    };
    return (
        <>

            {flagShowAvailablePages &&
                <ShowAvailablePages
                    flagShowAvailableNumbers={flagShowAvailablePages}
                    setFlagShowAvailableNumbers={setFlagShowAvailablePages}
                    smallScreen={smallScreen}
                    showConfirmationPopover={showConfirmationPopover}
                    confirmationPopoverAnchorEl={confirmationPopoverAnchorEl}
                    handleCloseConfirmationPopover={handleCloseConfirmationPopover}
                    addChatbotIntegrationDetails={addChatbotIntegrationDetails}
                    AddChatbotIntegration={AddChatbotIntegration}
                    availablePhoneNumberData={availablePagesData}
                    handleRemoveChatbotIntegration={handleRemoveChatbotIntegration}
                    displayPhoneNumber={phoneNumberIntegrate}
                    launchWhatsAppSignup={loginToFacebook}
                    saveChatbotIntegrationData={addChatbotIntegration}
                />

            }
            {flagFacebookIntegrationExist == false ?
                <>
                    <Grid container direction={"column"} justifyContent={"start"} alignItems={"start"} sx={{ width: "100%" }} gap={2} flexWrap={"nowrap"} pt={1}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container alignItems={"center"} gap={"13px"} mt={mediaScreen ? 2 : 1} flexWrap={"nowrap"}>
                                <Grid item sx={{ display: "flex" }}>
                                    <img alt="" src={facebookIcon} className={integrationStyles.whatsappIntegrationIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={integrationStyles.whatsappIntegrationFontStyle}>Integrate with your Facebook Account</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {integrationList &&
                            <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%" }} padding={"30px"}>

                                <>
                                    {
                                        integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name === "Facebook").length > 0 ?
                                            <>
                                                <Grid container direction={"column"}>
                                                    <Grid item>
                                                        {/* <ChatbotWhatsAppIntegrationSetup launch={launchWhatsAppSignup} /> */}
                                                        <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>This Chatbot is not linked with any Facebook Pages yet. </span>
                                                    </Grid>
                                                    <Grid item sx={{ paddingTop: "20px" }}>
                                                        <GlobalButton buttonText={"Add Facebook Page"} className='primaryButtonStyle' onClick={() => { setFlagShowAvailablePages(true) }} sx={{ padding: "6px 20px !important" }} startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <Grid container direction={"column"}>
                                                <Grid item>
                                                    <ChatbotFacebookIntegrationSetup launch={loginToFacebook} />
                                                </Grid>
                                                <Grid item sx={{ borderTop: "1px solid #E1DCDC", paddingTop: "20px" }}>
                                                    <GlobalButton buttonText={"Integrate Facebook Account"} className='primaryButtonStyle' onClick={loginToFacebook} />
                                                </Grid>
                                            </Grid>
                                    }
                                </>
                            </Grid>}
                    </Grid>
                </>
                :
                <>
                    {integrationList &&
                        <Grid container gap="20px" direction={"column"} sx={{ padding: { xs: "15px 7px", lg: "15px 15px 15px 5px" } }} mt={{ xs: 1, lg: 0 }}>
                            <Grid item>
                                <Grid container gap="15px">
                                    {linkedPagesData.map((pageData, index) => (
                                        <FacebookInformationCard
                                            integrationType='Facebook'
                                            key={index}
                                            flagDelete={false}
                                            title={`WhatsApp Account ${index + 1}`}
                                            currentlySelected={true}
                                            showRemoveChatbotIntegration={true}
                                            removeChatbotIntegration={() => { handleRemoveChatbotIntegration(pageData.meta_integration_id, pageData.meta_integration_type_id, pageData.page_id, pageData.page_name) }}
                                            details={{
                                                page_id: pageData.page_id,
                                                page_name: pageData.page_name,
                                                page_category: pageData.page_category,
                                                linked_chatbots: pageData.chatbot_integration_assignment_data
                                            }}
                                            flagIntegration={true}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item>

                                <GlobalButton onClick={() => { setFlagShowAvailablePages(true) }} buttonText={"Add Facebook Pages"} className={'blackShadowedButton'}
                                    startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                                />
                            </Grid>

                        </Grid>}
                    {
                        showDeleteConfirmation &&
                        <GlobalPopUp
                            open={showDeleteConfirmation}
                            handleClose={() => { setShowDeleteConfirmation(false) }}
                        >
                            <GlobalDeletePopup
                                deleteDescription={`Are you sure you want to remove ${chatBotDetails?.chatbot_name} from ${phoneNumberIntegrate}`}
                                onClose={() => { setShowDeleteConfirmation(false) }}
                                onDelete={deleteChatbotIntegration}
                                sx={{ maxWidth: "400px" }}
                            />
                        </GlobalPopUp>
                    }
                </>
            }

        </>
    )
}

export default ChatbotFacebookIntegration;