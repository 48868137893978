import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import styles from './../../../../../assets/styles/componentStyles/calendlyStyles/fieldSetup.module.scss';
import IconsColor from '../../../../utility/iconsColor';
import { CalendlyContext, CalendlyContextType } from '../../../../../context/calendlyIntegrationContext';
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext';

const FieldSetup = () => {
    const { selectedEvent, fieldData, setFieldData, calendlyIntegrationData, genericChatbotCalendlyData, genericCalendlyData, setGenericCalendlyData } = useContext(CalendlyContext) as CalendlyContextType;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;

    const [questions, setQuestions] = useState<any[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { value } = e.target;
        if (fieldData) {
            let updatedFieldData = fieldData.map((field: any) => field.calendly_event_type_question_id == id ? { ...field, question_description: value } : field)
            setFieldData(updatedFieldData)
        }
    };

    const [selectedEventName, setSelectedEventName] = useState<any>(null)
    useEffect(() => {
        if (calendlyIntegrationData && selectedEvent) {
            const selectedEventData = calendlyIntegrationData
                .flatMap((integration) => integration.calendly_event_type_data)
                .find(event => selectedEvent.includes(event.calendly_event_type_id));
            if (selectedEventData) {
                setSelectedEventName(selectedEventData.event_type_name)
                setQuestions(selectedEventData.event_type_custom_question_data
                    || []);
                if (chatBotDetails && chatBotDetails?.chatbot_calendly_data.length > 0 && chatBotDetails?.chatbot_calendly_data?.[0].calendly_integration_id == calendlyIntegrationData?.[0].calendly_integration_id) {
                    if (chatBotDetails?.chatbot_calendly_data?.[0]?.chatbot_calendly_assignment_data?.[0]?.calendly_event_type_id == selectedEvent[0]) {
                        let questionData: any = chatBotDetails?.chatbot_calendly_data?.[0]?.chatbot_calendly_assignment_data?.[0]?.chatbot_calendly_question_data
                        if (questionData) {
                            let fields = selectedEventData.event_type_custom_question_data.map((question: any) => {
                                return (
                                    {
                                        ...question, question_description: questionData.find((question1: any) => question1.calendly_event_type_question_id == question.calendly_event_type_question_id)?.question_description,
                                        chatbot_calendly_question_data_id: questionData.find((question1: any) => question1.calendly_event_type_question_id == question.calendly_event_type_question_id)?.chatbot_calendly_question_data_id
                                    }
                                )
                            })
                            setFieldData(fields)
                        }
                    } else {
                        setFieldData(selectedEventData.event_type_custom_question_data)
                    }
                } else {
                    setFieldData(selectedEventData.event_type_custom_question_data)
                }
            } else {
                setQuestions([]);
            }
        } else {

        }
    }, [selectedEvent, calendlyIntegrationData]);

    return (
        <>
            <>
                <Grid container direction="column" gap="15px" sx={{ paddingLeft: "10px" }}>
                    <Grid item className={styles.headerContainer}>
                        <span className={styles.headerText}>Calendly Event Type</span>
                        <span style={{ verticalAlign: "middle" }}>
                            <IconsColor iconName="forwardArrow" width="17px" fillColor="#000000" />
                        </span>
                        {selectedEventName && <span style={{ fontSize: '15px', fontWeight: '600' }}>{selectedEventName}</span>}
                    </Grid>

                    <Grid item sx={{ marginTop: '5px' }}>
                        <Grid container gap="15px" flexWrap="nowrap" >
                            <Grid item className={styles.fieldColumn} xs={3}>
                                <span className={styles.fieldName}>Fields</span>
                            </Grid>
                            <Grid item className={styles.descriptionColumn} xs={9}>
                                <span className={styles.fieldName}>Description</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            {fieldData && fieldData.length > 0 ? (
                                fieldData.map((question: any) => (
                                    <Grid item key={question.calendly_event_type_question_id} width={"100%"}>
                                        <Grid container gap="15px" flexWrap="nowrap">
                                            <Grid item className={styles.fieldColumn} xs={3}>
                                                <div className={styles.fieldItem}>{question.question_value}</div>
                                            </Grid>
                                            <Grid item className={styles.descriptionColumn} xs={9} sx={{ zIndex: "1300" }}>
                                                <input
                                                    placeholder={`Enter Description`}
                                                    type="text"
                                                    value={question.question_description || ''}
                                                    className={styles.descriptionItem}
                                                    onChange={(e) => handleChange(e, question.calendly_event_type_question_id.toString())}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Grid container className={styles.setupDiv} style={{ justifyContent: "center" }}>
                                    <Grid item className={styles.setupDivText}>
                                        No Field Data Available
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                </Grid>
            </>
        </>
    );
};

export default FieldSetup;
