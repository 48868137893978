import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/carouselCard.module.scss'
import messageFormatStyles from '../../../assets/styles/componentStyles/messageFormatForms/messageFormatForms.module.scss'
import ReplyIcon from '@mui/icons-material/Reply';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LaunchIcon from '@mui/icons-material/Launch';
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { useSearchParams } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { convertKeysToLowerCase } from '../../../utils/constants';
import AppointmentSchedule from '../../dashboard/chats/chatWidgetComponents/appointmentSchedule';
import GlobalPopUp from '../globalPopUp';
import DoctorCardFormatCarousel from './doctorCardFormatCarousel';
import RealEstateCardFormat from './realEstateCardFormat';
import { ImageCardCarousel } from './imageCardCarousel';
import { AmenitiesCardCarousel } from './amenitiesCardCarousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CardCarouselDynamicFormat from './cardCarouselDynamicFormat';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import carouselStyles from '../../../assets/styles/componentStyles/widgetComponentStyles/dynamicCarousel.module.scss'
import CardCarouselDynamicFormatCTA from './cardCarouselDynamicFormatCTA';

const Carousel = forwardRef((props: any, ref: any) => {
    // console.log("props----", props)
    const { suggestions, threadData } = props
    const [searchParams] = useSearchParams()
    const isScreenBelow1000 = useMediaQuery('(max-width : 1200px) and (min-width: 0px)') || searchParams.get('screensize')
    const [flagModal, setFlagModal] = useState(false)
    const [modalData, setModalData] = useState<any>("")
    const startXRef = useRef(0);
    const startYRef = useRef(0);
    const [flagShowImage, setFlagShowImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentCardData, setCurrentCardData] = useState<any>(null);

    function getActionButtonIcon(actionTypeId: number) {
        switch (actionTypeId) {
            case 4:
                return <EventAvailableIcon style={{ fontSize: '19px', color: '#2E79BD' }} />
            case 3:
                return <LaunchIcon style={{ fontSize: '19px', color: '#2E79BD' }} />
            case 8.2:
                return <EventAvailableIcon style={{ fontSize: '19px', color: '#2E79BD' }} />
            default:
                return <ReplyIcon style={{ fontSize: '19px', color: '#2E79BD' }} />
        }
    }
    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    // background: '#F3F5F9',
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.user_msg_bg_color : '#F7E9FF',
                    padding: '3px 3px 2px',
                    borderRadius: '50%',
                    boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
                    // '--user-msg-bg-color': chatBotDetail?.chatbot_theme?.user_msg_bg_color || '#F3F5F9'
                }}
                onClick={onClick}
            />
        );
    }
    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    padding: '3px 3px 1px',
                    borderRadius: '50%',
                    background: props.chatbotTheme?.user_msg_bg_color ? props.chatbotTheme?.user_msg_bg_color : '#F7E9FF',
                    boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
                }}

                onClick={onClick}
            />
        );
    }
    function showModal(data: any, actionTypeId: any) {
        if (actionTypeId == 8.2) {
            setModalData({
                actionTypeId: 8.2,
                data: data
            })
        } else {
            setModalData(data.url)
        }
        setFlagModal(true)
    }
    function handleActionButton(action: any) {
        let actionTypeId = action.message_format_id
        switch (actionTypeId) {
            case 4:
                return showModal(action.message_fields, actionTypeId)
            case 3:
                return window.open(action.message_fields.url, "_blank");
            case 8.2:
                return showModal(action.message_fields, actionTypeId)
            default:
                return
        }
    }

    const handleMouseDown = (event:any) => {
        startXRef.current = event.clientX;
        startYRef.current = event.clientY;
    };

    const handleMouseUp = (event:any, image:any, flagVariant = false) => {
        const endX = event.clientX;
        const endY = event.clientY;

        const deltaX = Math.abs(endX - startXRef.current);
        const deltaY = Math.abs(endY - startYRef.current);

        if (deltaX < 5 && deltaY < 5) {
            setFlagModal(true);
            setFlagShowImage(true);
            setCurrentIndex(suggestions.findIndex((sug: any) => sug?.image_url == image?.image_url) || 0)
            // setModalData(image.image_url);
            if(flagVariant) {
                setModalData(image?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value);
            }
            else {
                setModalData(image.image_url);
            }
            setCurrentCardData(image);
        }
    };
    // const resizeFunction = () => {
    //     document.querySelectorAll(".chat_crl_img_captions_item").forEach((element) => {
    //         const textarea = element;
    //         // console.log("++++++++++++++++++++++++++++", textarea.style.height)
    //         // textarea.style.height = 'unset !important';
    //         // textarea.style.minHeight = 'fit-content';
    //     });
    // }
    // useEffect(() => {
    //     if (suggestions.length > 0) {
    //         resizeFunction()
    //     }
    // }, [suggestions])
    function closePopup() {
        setModalData("")
        setFlagModal(false)
    }

    const handleNavigation = (direction: string) => {
        if (direction === "left") {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
            );
        } else if (direction === "right") {
            setCurrentIndex((prevIndex) =>
                prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
            );
        }
    };

    const handleSwipe = (oldIndex: any, newIndex: any) => {
        if (newIndex > oldIndex) {
            handleNavigation("right");
        } else {
            handleNavigation("left");
        }
    };

    return (
        <>
            {flagModal &&
                <GlobalPopUp
                    open={flagModal}
                    handleClose={() => { setFlagModal(false); setModalData(""); setFlagShowImage(false); setCurrentIndex(0) }}
                    flagShowCloseIcon
                    paperMinWidth={flagShowImage ? "" : "100%"}
                    paperMaxwidth={flagShowImage ? "" : "95%"}
                    height={flagShowImage ? "" : "70%"}
                    ref={ref}
                    titlePadding={"0px"}
                    borderRadius={"10px 10px 10px 10px"}
                    backgroundColor={props.threadData.message_format_id === 5.4 ? "transparent !important" : ""}
                    boxShadow='none !important'
                >
                    {flagShowImage ?
                        props.threadData && props.threadData.message_format_id === 5.4 || props.threadData.message_format_id === 5.6 ?
                            <div style={{ position: "relative", width: "100%", height: "100%", borderRadius: "5px" }}>
                                <Slider
                                    swipe={isScreenBelow1000 ? true : false}
                                    arrows={true}
                                    dots={false}
                                    infinite={true}
                                    autoplay={false}
                                    variableWidth={false}
                                    className={carouselStyles.chatbot_click_slider_image_carousel}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    initialSlide={currentIndex}
                                    nextArrow={<SampleNextArrow className={styles.chat_slider_next_arrow} />}
                                    prevArrow={<SamplePrevArrow className={styles.chat_slider_prev_arrow} />}
                                    beforeChange={(oldIndex, newIndex) => handleSwipe(oldIndex, newIndex)}
                                // speed={400}

                                >
                                    {props.threadData.message_format_id === 5.6 ?
                                        Array.isArray(currentCardData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value) ? 
                                            currentCardData?.variant_data?.find((varData: any) => varData.field_name === "image_url")?.field_value?.map((item: any, index: any) => (
                                                <div key={index} className={carouselStyles.carousel_container_style}>
                                                    <OpenInNewIcon
                                                        className={carouselStyles.open_image_icon_style}
                                                        onClick={() => window.open(item, "_blank")}
                                                    />
                                                    <img
                                                        alt=""
                                                        src={item}
                                                        className={carouselStyles.new_image_carousel_image_style}
                                                        // style={{aspectRatio: "1"}}
                                                        style={{width: "-webkit-fill-available !important"}}
                                                    />
                                                </div>
                                            ))
                                            :
                                            <div className={carouselStyles.carousel_container_style}>
                                                <OpenInNewIcon 
                                                    className={carouselStyles.open_image_icon_style}
                                                    onClick={() => window.open(currentCardData?.variant_data?.find((varData: any) => varData.field_name === "image_url")?.field_value, "_blank")}
                                                />
                                                <img
                                                    alt=""
                                                    src={currentCardData?.variant_data?.find((varData: any) => varData.field_name === "image_url")?.field_value}
                                                    className={carouselStyles.new_image_carousel_image_style}
                                                    style={{width: "-webkit-fill-available !important"}}
                                                />
                                            </div>
                                    :
                                        suggestions.map((item: any, index: any) => (
                                            <div key={index} style={{ padding: "0 10px", position:"relative" }}>
                                                <OpenInNewIcon 
                                                    className={carouselStyles.open_image_icon_style}
                                                    onClick={() => window.open(item.image_url, "_blank")}
                                                />
                                                <img
                                                    alt=""
                                                    src={item.image_url}
                                                    style={{
                                                        // aspectRatio: "1.75",
                                                        borderRadius: "10px",
                                                        maxHeight: "600px",
                                                        minHeight: "600px",
                                                        minWidth: "600px",
                                                        maxWidth: "100%",
                                                        objectFit: "cover",
                                                        display: "block",
                                                        position: "relative",
                                                        zIndex: "-1",
                                                        width: "-webkit-fill-available !important"
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </Slider>
                            </div>
                            :
                            <>
                                <div>
                                    <OpenInNewIcon 
                                        className={carouselStyles.open_image_icon_style}
                                        onClick={() => window.open(modalData, "_blank")}
                                    />
                                    <img alt='' src={modalData} style={{ width: "100%", height: "100%", borderRadius: "10px", display: "flex" }} />
                                </div>
                            </>
                        :
                        <>
                            {modalData?.actionTypeId ?
                                <>
                                    {modalData?.data &&
                                        <AppointmentSchedule data={modalData.data ?? {}} sendHiddenMessageToSocket={props.sendHiddenMessageToSocket} closePopup={closePopup} chatbotTheme={props.chatbotTheme} />}
                                </>
                                :
                                <iframe className='iframeStyles' src={modalData} title="W3Schools Free Online Web Tutorials" style={{ width: "auto%", height: "100%" }}></iframe>}
                        </>
                    }
                </GlobalPopUp>
            }
            <div className={styles.slider_container} style={{ maxWidth: "100%" }}>
                <Slider
                    swipe={isScreenBelow1000 ? true : false}
                    arrows={true}
                    dots={false}
                    infinite={false}
                    autoplay={false}
                    variableWidth={true}
                    className={styles.chatbot_click_slider}
                    slidesToShow={1}
                    slidesToScroll={1}
                    nextArrow={<SampleNextArrow className={styles.chat_slider_next_arrow} />}
                    prevArrow={<SamplePrevArrow className={styles.chat_slider_prev_arrow} />}
                // speed={400}
                >
                    {suggestions.map((image: any, index: any) => {
                        return <>
                            <Grid item className={messageFormatStyles.chat_crl_main_item} key={index}>
                                {props.threadData && props.threadData.message_format_id === 5.2 &&
                                    <DoctorCardFormatCarousel image={image} handleActionButton={handleActionButton} getActionButtonIcon={getActionButtonIcon} handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp}/>
                                }
                                {props.threadData && props.threadData.message_format_id === 5.3 &&
                                    <RealEstateCardFormat image={image} handleActionButton={handleActionButton} getActionButtonIcon={getActionButtonIcon} handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp}/>
                                }
                                {props.threadData && props.threadData.message_format_id === 5.4 &&
                                    <ImageCardCarousel image={image} handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp}/>
                                }
                                {props.threadData && props.threadData.message_format_id === 5.5 &&
                                    <AmenitiesCardCarousel image={image} handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp}/>
                                }
                                {props.threadData && props.threadData.message_format_id === 5.6 &&
                                    <CardCarouselDynamicFormat image={image} handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp} getActionButtonIcon={getActionButtonIcon} handleActionButton={handleActionButton} chatbotTheme={props.chatbotTheme}/>
                                }
                                {props.threadData && props.threadData.message_format_id === 5.7 &&
                                    <CardCarouselDynamicFormatCTA handleMouseDown={handleMouseDown} handleMouseUp={handleMouseUp} image={image} handleActionButton={handleActionButton} getActionButtonIcon={getActionButtonIcon} handleRecursion={props.handleRecursion} chatbotTheme={props.chatbotTheme} threadData={threadData}/>
                                }
                            </Grid>
                        </>
                    })
                    }
                </Slider>
            </div>
        </>
    )
})

export default Carousel