import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import '@smastrom/react-rating/style.css'
import CustomDrawer from './mobileDropdownDrawer';



const DropdownTemplate = (props: any) => {
    console.log("props", props)
    const { thread, data, chatbotTheme, setDrawerProps, drawerProps } = props
    const [value, setValue] = React.useState(null);
    const [flagSubmit, setFlagSubmit] = React.useState(false)
    const [searchValue, setSearchValue] = useState("")
    // const { chatBotDetail, setDrawerProps, drawerProps, searchValue, setSearchValue } = useContext(SocketContext)


    function isDisable() {
        if (thread?.seq || flagSubmit) {
            return true
        } else {
            return false
        }
    }
    const handleSetMobileDrawer = () => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdown_options: data.message_fields.dropdown_options,
            option_key_name: "name",
            flag_multiple: data.message_fields.flag_multiple,
            image_url: null,
            dropdown_name: data.message_fields.list_name
        })
    }

    return (
        <>
            <Grid item className={'cr-main-item'}>
                <Grid container>
                    <Grid item xs={12}
                    // sx={{ background: chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.user_msg_bg_color : "#F6F6F6" }} className='cr-sub-item'
                    >
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'start', marginTop: "10px" }}>
                                    {/* <Grid item xs={12}> */}
                                    <Button
                                        sx={{
                                            textTransform: "unset",
                                            backgroundColor: chatbotTheme?.chatbot_send_message_icon_color ? chatbotTheme?.chatbot_send_message_icon_color : "#6A097D",
                                            color: chatbotTheme?.chatbot_header_color ? `${chatbotTheme?.chatbot_header_color} !important` : "",
                                            fontWeight: "500 !important", borderRadius: "5px !important", padding: "5px 10px !important",
                                            minWidth: "unset !important",
                                            '&:hover': { color: chatbotTheme?.chatbot_header_color ? `${chatbotTheme?.chatbot_header_color} !important` : "", backgroundColor: chatbotTheme?.chatbot_send_message_icon_color ? `${chatbotTheme.chatbot_send_message_icon_color} !important` : "" }
                                        }}
                                        variant={'outlined'}
                                        disabled={isDisable()}
                                        size='small'
                                        onClick={(e) => {
                                            handleSetMobileDrawer()
                                        }}
                                    >
                                        {data.message_fields.list_name}
                                        {/* {Object.values(ele)[0]} */}
                                    </Button>
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>

                            {/* {!isDisable() &&
                                <Grid item
                                    sx={{ textAlign: 'start', margin: '12px 0px 0px 0px' }}>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            props.handleRecursion({ suggestion_name: props.data.message_fields.rating_names[value - 1] })
                                            setFlagSubmit(true)
                                        }}
                                        className='rating-submit-btn'
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            color: chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color : "#6a097d",
                                            background: chatbotTheme.chatbot_send_message_bg_color ? chatbotTheme.chatbot_send_message_bg_color : "",
                                            border: `1px solid ${hexToRgba(chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color : "#6A097D")}`,
                                            borderRadius: "5px",
                                            boxShadow: `0px 0px 1px 0px ${hexToRgba(chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color ? chatBotDetail?.chatbot_theme?.chatbot_send_message_icon_color : "#6A097D")}`
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            } */}
                            <CustomDrawer
                                chatbotTheme={chatbotTheme}
                                drawerOpen={drawerProps.openDrawer}
                                handleDrawerClose={() => {
                                    setDrawerProps({ ...drawerProps, openDrawer: false });
                                    setTimeout(() => {
                                        document.getElementById('paraphraseInput')?.blur();
                                        document.getElementById('toneOfVoice')?.blur()
                                        document.getElementById('languageList')?.blur()
                                        document.getElementById('noOfOutput')?.blur();
                                        document.getElementById('length')?.blur()
                                    }, 200)
                                }}
                                dropdown_options={drawerProps.dropdown_options}
                                option_key_name={drawerProps.option_key_name}
                                flag_multiple={drawerProps.flag_multiple}
                                image_url={drawerProps.image_url}
                                dropdown_name={drawerProps.dropdown_name}
                                handleRecursion={props.handleRecursion}
                                setFlagSubmit={setFlagSubmit}
                                flagSubmit={flagSubmit}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DropdownTemplate