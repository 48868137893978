import { Box, Button, Grid, Pagination, Stack, Switch, Typography, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import ReactCountryFlag from 'react-country-flag'
import Markdown from 'react-markdown'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import botIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png";
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import whatsappIcon from '../../../assets/Whatsapp.svg'
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import IconsColor from '../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import globalStyles from "../../../assets/styles/global.module.scss";
import mobileStyles from "../../../assets/styles/componentStyles/chatbotStyles/chatsMobileResponsive.module.scss";
import { getIntegrationIcon } from './constants';
import { useSearchParams } from 'react-router-dom';
interface ChatUserThreadListInterface {
    id: string | null,
    flagFilterFeedback: boolean,
    subType: string,
    handleFilterFeedback: (e: any) => void,
    handleSearch: (e: any) => void,
    handleSubTabs: any,
    filteredData: any,
    currentActiveChatbotThread: any,
    handleRefresh: any,
    setFeedbackDetails: any,
    setValue: any,
    activeChatsFiltered: any,
    hasEffectRun: any,
    handleLastMessageValue: any,
    handlePaginationChat: any,
    closedChatsFiltered: any,
    flagMobile?: boolean,
    chatClicked?: any,
    chatInfoClicked?: any
}

function ChatUserThreadList(props: ChatUserThreadListInterface) {
    const smallScreen = useMediaQuery('(max-width : 1250px) and (min-width : 1000px)')
    const mobileScreen = useMediaQuery('(max-width : 1000px) and (min-width : 0px)')
    const flagMobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tempScreen = useMediaQuery('(max-width : 1220px) and (min-width : 1200px)')
    const { userDetail } = useContext(UserContext) as UserContextPayload
    const { chatInfoClicked, chatClicked, flagMobile, id, flagFilterFeedback, subType, handleFilterFeedback, handleSearch, handleSubTabs, filteredData, currentActiveChatbotThread, handleRefresh, setFeedbackDetails, setValue, activeChatsFiltered, hasEffectRun, handleLastMessageValue, handlePaginationChat, closedChatsFiltered } = props

    const activeChatsListIH = (window.innerHeight - 270);
    const chatBotsListsIH = (window.innerHeight - 310);
    const activeChatbotsListIH = (window.innerHeight - 315);
    const allchatBotsListIH = (window.innerHeight - 360);
    const activeChatbotsListMIH = flagMobileScreen ? (window.innerHeight - 250) : (window.innerHeight - 300);
    const chatBotsListsMIH = flagMobileScreen ? (window.innerHeight - 280) : tempScreen ? (window.innerHeight - 345) : (window.innerHeight - 295);
    const chatsEmptyListsMIH = flagMobileScreen ? (window.innerHeight - 240) : (window.innerHeight - 250);
    const allActiveChatbotListMIH = flagMobileScreen ? (window.innerHeight - 290) : (window.innerHeight - 315);
    const allchatBotsListMIH = flagMobileScreen ? (window.innerHeight - 330) : (window.innerHeight - 365);
    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <Grid item
            xs={flagMobile ? smallScreen ? 6 : 12 : undefined}
            sx={{
                borderRight: mobileScreen ? "" : "0.5px solid #DEDEDE", maxWidth: flagMobile ? "100%" : "345px", paddingLeft: "3px",
                display: flagMobile ? smallScreen ? "block" : chatClicked || chatInfoClicked ? "none" : "block" : "unset", width: "100%",
                paddingRight: flagMobile ? smallScreen ? "20px" : "" : ""
            }}>
            <Grid container direction={"column"} gap={"12px"} sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, paddingRight: flagMobile ? "5px" : "20px" }} >
                {!id &&
                    <Grid item mt={2}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item className={flagMobile ? mobileStyles.allChatbotSTyle : styles.allChatbotSTyle}>
                                All Chatbots
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item sx={{ display: "flex", alignItems: "center", fontSize: "15px", fontWeight: "600" }}>Feedback Filter</Grid>
                        <Grid item>
                            <Switch value={flagFilterFeedback} onChange={(e) => { handleFilterFeedback(e.target.checked) }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={0}>
                    <GlobalSearch onChange={(event) => { handleSearch(event.target.value) }} size="medium" placeholder='Search Chat' fullWidth />
                </Grid>
                <Grid item>
                    <Grid container justifyContent={"start"} direction={"row"} alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                        <Grid item xs={11}>
                            <Box className={flagMobile ? mobileStyles.maskBox : styles.maskBox}>
                                <Box
                                    className={flagMobile ? mobileStyles.mask : styles.mask}
                                    style={{
                                        transform: flagMobile ? `translateX(${subType === "Active" ? 0 : "100%"})` : `translateX(${subType === "Active" ? 0 : "120px"})`
                                    }}
                                />
                                <Button
                                    disableRipple
                                    variant="text"
                                    endIcon={<Grid className={subType === 'Active' ? flagMobile ? mobileStyles.activeSelectedAvatar : styles.activeSelectedAvatar : flagMobile ? mobileStyles.activeAvatar : styles.activeAvatar} style={{ marginRight: "0px" }}><span className={flagMobile ? mobileStyles.closedCount : styles.closedCount}>{filteredData.activeChats.length}</span></Grid>}
                                    onClick={() => {
                                        handleSubTabs("Active")
                                    }}
                                    className={subType === 'Active' ? 'selected-monthly-button' : 'not-active-button'}
                                >
                                    Active
                                </Button>
                                <Button
                                    disableRipple
                                    variant="text"
                                    endIcon={<Grid className={subType === 'Closed' ? flagMobile ? mobileStyles.activeSelectedAvatar : styles.activeSelectedAvatar : flagMobile ? mobileStyles.activeAvatar : styles.activeAvatar} style={{ marginRight: "0px" }}><span className={styles.closedCount}>{filteredData.pagination.total_chats ? filteredData.pagination.total_chats : 0}</span></Grid>}
                                    onClick={() => {
                                        handleSubTabs("Closed");
                                    }}
                                    className={subType === 'Closed' ? 'selected-yearly-button' : 'not-active-button'}
                                >
                                    Closed
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item className={flagMobile ? mobileStyles.refreshIconStyle : styles.refreshIconStyle}
                            onClick={handleRefresh}

                        >
                            <RefreshIcon style={{ display: "flex" }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"12px"} gap={"2px"} container className={
                !flagMobile ?
                    id ? subType == "Active" ? styles.activeChatsList : styles.chatBotsLists : subType == "Active" ? styles.activeChatbotsList : styles.allchatBotsList
                    :
                    (id && subType == "Active" && filteredData.activeChatsFiltered.length > 0) ? mobileStyles.activeChatbotsList : (id && subType == "Closed" && filteredData.closeChatsFiltered.length > 0) ? mobileStyles.chatBotsLists : (subType == "Active" && filteredData.activeChatsFiltered.length == 0 || subType == "Closed" && filteredData.closeChatsFiltered.length == 0) ? mobileStyles.chatsEmptyLists : (!id && subType == "Active") ? mobileStyles.allActiveChatbotList : mobileStyles.allchatBotsList
            }
                sx={{
                    maxHeight: !flagMobile ?
                        id ? subType == "Active" ? `${activeChatsListIH}px !important` : `${chatBotsListsIH}px !important` : subType == "Active" ? `${activeChatbotsListIH}px !important` : `${allchatBotsListIH}px !important`
                        :
                        (id && subType == "Active" && filteredData.activeChatsFiltered.length > 0) ? `${activeChatbotsListMIH}px !important` : (id && subType == "Closed" && filteredData.closeChatsFiltered.length > 0) ? `${chatBotsListsMIH}px !important` : (subType == "Active" && filteredData.activeChatsFiltered.length == 0 || subType == "Closed" && filteredData.closeChatsFiltered.length == 0) ? `${chatsEmptyListsMIH}px !important` : (!id && subType == "Active") ? `${allActiveChatbotListMIH}px !important` : `${allchatBotsListMIH}px !important`,
                }}
            >

                {subType == "Active" ?
                    <>
                        {filteredData.activeChatsFiltered.length > 0 ?
                            filteredData.activeChatsFiltered.map((activeChat: any) => {
                                return <>
                                    <Grid item
                                        id={activeChat.chatbot_user_id}
                                        className={(currentActiveChatbotThread && currentActiveChatbotThread?.chatbot_user_id == activeChat?.chatbot_user_id) ? flagMobile ? mobileStyles.selectedChat : styles.selectedChat : flagMobile ? mobileStyles.notSelectedChat : styles.notSelectedChat}
                                        onClick={(e) => {
                                            setFeedbackDetails({})
                                            setValue(0)
                                            activeChatsFiltered(activeChat);
                                            hasEffectRun.current = false;
                                            if (e.ctrlKey) {
                                                window.open(`${window.location.href}`, "_blank")
                                            }
                                        }} sx={{ width: "100%" }}>
                                        <Grid container alignItems={"center"} gap={"10px"} sx={{ boxSizing: "border-box" }} flexWrap={"nowrap"}>
                                            <Grid item> <img src={activeChat?.chatbot_logo_url ? activeChat.chatbot_logo_url : botIcon}
                                                alt="chatbot Icona"
                                                style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", aspectRatio: "1" }} /></Grid>
                                            <Grid item sx={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <Grid container justifyContent={"space-between"} flexWrap={"nowrap"} gap={"10px"}>
                                                    <Grid item sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <Grid container direction="column" gap={"4px"} flexWrap={"nowrap"}>
                                                            <Grid item className={flagMobile ? mobileStyles.chatbotName : styles.chatbotName}>{activeChat.chatbot_name ? activeChat.chatbot_name : ""}</Grid>
                                                            <Grid item className={flagMobile ? mobileStyles.userName : styles.userName}>{activeChat?.chatbot_user_name ? activeChat?.chatbot_user_name : "UNKNOWN"}</Grid>
                                                            <Grid item className={flagMobile ? mobileStyles.userLastMessage : styles.userLastMessage}>
                                                                {activeChat.last_message_value ? activeChat.last_message_value.length > 30 ?
                                                                    <Markdown
                                                                        remarkPlugins={[remarkGfm]}
                                                                        rehypePlugins={[
                                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                                        ]}
                                                                        className={`${styles.reactMarkdownStyle} ${styles.lastMessageMarkdownStyle}`}
                                                                    >
                                                                        {handleLastMessageValue(activeChat.last_message_value)}
                                                                    </Markdown>
                                                                    :
                                                                    <Markdown
                                                                        remarkPlugins={[remarkGfm]}
                                                                        rehypePlugins={[
                                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                                        ]}
                                                                        className={`${styles.reactMarkdownStyle} ${styles.lastMessageMarkdownStyle}`}
                                                                    >
                                                                        {handleLastMessageValue(activeChat.last_message_value)}
                                                                    </Markdown>
                                                                    :
                                                                    ""
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container direction="column" sx={{ textAlign: "end", width: "55px" }} justifyContent={"space-between"} flexWrap={"nowrap"} gap={"25px"}>
                                                            <Grid item>
                                                                <Grid container gap="10px" justifyContent={"space-between"}>
                                                                    <Grid item sx={{ display: "flex", alignContent: "start", paddingTop: "2px" }}>
                                                                        {activeChat.chatbot_user_country && activeChat.chatbot_user_route_source.toLowerCase() == "website" &&
                                                                            <ReactCountryFlag
                                                                                countryCode={activeChat.chatbot_user_country_code}
                                                                                svg
                                                                                title={activeChat.chatbot_user_country}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {getIntegrationIcon(activeChat.chatbot_user_route_source)}</Grid>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ width: "100%" }} className={flagMobile ? mobileStyles.userLastMessageTime : styles.userLastMessageTime}>{formatDateAccordingToTimezoneUsingMoment(activeChat.last_message_time, userDetail.timezone_hour, "active")}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </>
                            })
                            :
                            mobileScreen && <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "calc(100vh - 300px)" }}>
                                <Grid item>
                                    <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                </Grid>
                                <Grid item>
                                    <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography>
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <>
                        {filteredData.closeChatsFiltered.length > 0 ?
                            filteredData.closeChatsFiltered.map((closedChat: any) => {
                                return <>
                                    <Grid item className={(currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_id == closedChat.chatbot_user_id) ? styles.selectedChat : styles.notSelectedChat}
                                        // onClick={() => { setCurrentActiveChatbotThread(closedChat) }}
                                        id={closedChat.chatbot_user_id}
                                        onClick={(e) => {
                                            setValue(0)
                                            closedChatsFiltered(closedChat); hasEffectRun.current = false;
                                            setFeedbackDetails({})
                                            if (e.ctrlKey) {
                                                window.open(`${window.location.href}`, "_blank")
                                            }
                                        }}
                                        sx={{ width: "100%" }}
                                    >
                                        <Grid container alignItems={"center"} gap={"10px"} sx={{ boxSizing: "border-box" }} flexWrap={"nowrap"}>
                                            <Grid item><img src={closedChat?.chatbot_logo_url ? closedChat.chatbot_logo_url : botIcon}
                                                alt="chatbot Icona"
                                                style={{ width: "30px", height: "auto", objectFit: 'fill', borderRadius: "50%", aspectRatio: "1" }} /></Grid>
                                            <Grid item sx={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >
                                                <Grid container justifyContent={"space-between"} flexWrap={"nowrap"} gap={"10px"}>
                                                    <Grid item sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <Grid container direction="column" gap={"4px"} flexWrap={"nowrap"}>
                                                            <Grid item className={styles.chatbotName}>{closedChat.chatbot_name ? closedChat.chatbot_name : ""}</Grid>
                                                            <Grid item className={styles.userName}>{closedChat?.chatbot_user_name ? closedChat?.chatbot_user_name : "UNKNOWN"}</Grid>
                                                            <Grid item className={styles.userLastMessage}>
                                                                {closedChat.last_message_value ? closedChat.last_message_value.length > 30 ?
                                                                    <Markdown
                                                                        remarkPlugins={[remarkGfm]}
                                                                        rehypePlugins={[
                                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                                        ]}
                                                                        className={`${styles.reactMarkdownStyle} ${styles.lastMessageMarkdownStyle}`}
                                                                    >
                                                                        {handleLastMessageValue(closedChat.last_message_value)}
                                                                    </Markdown>
                                                                    :
                                                                    <Markdown
                                                                        remarkPlugins={[remarkGfm]}
                                                                        rehypePlugins={[
                                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                                        ]}
                                                                        className={`${styles.reactMarkdownStyle} ${styles.lastMessageMarkdownStyle}`}
                                                                    >
                                                                        {handleLastMessageValue(closedChat.last_message_value)}
                                                                    </Markdown>
                                                                    :
                                                                    ""
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" sx={{ textAlign: "end", width: "55px" }} justifyContent={"space-between"} flexWrap={"nowrap"} gap={"25px"}>
                                                            <Grid item>
                                                                <Grid container gap="10px" justifyContent={"space-between"}>
                                                                    <Grid item sx={{ display: "flex", alignContent: "start", paddingTop: "2px" }}>
                                                                        {closedChat.chatbot_user_country && closedChat.chatbot_user_route_source.toLowerCase() == "website" &&
                                                                            <ReactCountryFlag
                                                                                countryCode={closedChat.chatbot_user_country_code}
                                                                                svg
                                                                                title={closedChat.chatbot_user_country}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {getIntegrationIcon(closedChat.chatbot_user_route_source)}</Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item sx={{ width: "100%" }} className={styles.userLastMessageTime}>{formatDateAccordingToTimezoneUsingMoment(closedChat.last_message_time, userDetail.timezone_hour, "closed")}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </>
                            })
                            :
                            mobileScreen && <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                <Grid item>
                                    <IconsColor iconName="chats" width="62px" height="62px" fillColor="#7A7A7A" />
                                </Grid>
                                <Grid item>
                                    <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`}>No Conversation here yet.</Typography>
                                </Grid>
                            </Grid>
                        }
                    </>
                }


            </Grid>
            {
                (subType == "Active" && filteredData.activeChatsFiltered.length > 0) || (subType == "Closed" && filteredData.closeChatsFiltered.length > 0) &&
                <Grid container justifyContent={"start"} paddingTop={"15px"} sx={{ position: flagMobile ? "fixed" : "absolute", bottom: "10px", left: flagMobile ? "" : "3px", width: flagMobile ? "315px" : "335px", maxWidth: flagMobile ? "315px" : "335px" }} flexWrap={"nowrap"}>
                    <Grid item sx={{ padding: "8px 8px 8px 0px", boxShadow: "0px 0px 2px rgba(0,0,0,0.25)", borderRadius: "5px" }}>
                        <Stack spacing={1} direction={"row"} sx={{ paddingInline: "5px" }} flexWrap={"nowrap"} alignItems={"center"}>
                            <Pagination
                                siblingCount={0}
                                boundaryCount={0}
                                page={Number(searchParams.get('page_no')) ?? 1}
                                onChange={(event, value) => handlePaginationChat(event, value)}
                                count={filteredData?.pagination?.total_chats ? (Math.floor(filteredData.pagination.total_chats / Number(filteredData.pagination.records_per_page))) + 1 : 1}
                                shape='rounded'
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        height: "26px !important",
                                        padding: "0px 0px !important",
                                        fontWeight: "500",
                                        minWidth: "22px !important"
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: "#F1D9FF !important"
                                    }
                                }}
                            />
                            <Typography component='span' sx={{ color: "rgba(0,0,0,0.7)", fontSize: "14px", fontWeight: "500" }}>{`${filteredData.pagination?.start_index} - ${filteredData.pagination.end_index} of ${filteredData.pagination.total_chats}`}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            }
        </Grid >
    )
}

export default ChatUserThreadList