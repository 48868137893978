import React, { useContext, useEffect, useState } from 'react'
import AccountInformationCard from '../../../utility/accountInformationCard';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import IconsColor from '../../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import { useSearchParams } from 'react-router-dom';
import agent from '../../../../services/api';
import { MetaContext, MetaContextPayload } from '../../../../context/metaContext';
import { chatbotIntegrationAssignmentData, CommonResponseModel, createMetaIntegrationPayload, globalIntegration, integrationData, linkedPhoneNumberData, metaIntegrationPhoneNumberData, metaIntegrationPhoneNumberDataModel } from '../../../../models/axiosModel';
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../utils/constants';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import GlobalPopUp from '../../../utility/globalPopUp';
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import whatsAppIcon from "../../../../assets/images/chatbot/whatsApp/Whatsapp.svg";
import ChatbotWhatsAppIntegrationSetup from '../chatbotWhatsAppIntegrationSetup';
import GlobalButton from '../../../utility/globalButton/button';
import { IntegrationContext, IntegrationContextPayload } from '../../../../context/integrationContext';
import globalStyles from '../../../../assets/styles/global.module.scss'
import integrationStyles from '../../../../assets/styles/componentStyles/chatbotStyles/integrations.module.scss'
import ShowAvailablePhoneNumbers from './showAvailablePhoneNumbers';

declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}

interface addChatbotIntegrationDetails {
    metaData: globalIntegration,
    phoneNumberData: metaIntegrationPhoneNumberData
}
interface addChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    phone_number_id: string,
    meta_integration_type_id: string
}
const ChatbotWhatsAppIntegration = () => {
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const [flagWhatsappIntegrationExist, setflagWhatsappIntegrationExist] = useState<boolean | null>(null)
    const [searchParams] = useSearchParams();
    const { setSuccess, success, setError, error, setFlagLoading, flagLoading, userDetail, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { GetChatbotIntegrationData, getChatbotIntegrationData, setGetChatbotIntegrationData, metaCredentials } = useContext(MetaContext) as MetaContextPayload;
    const { showIntegrationDetails, setShowIntegrationDetails } = useContext(MetaContext) as MetaContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { getIntegrationList, globalIntegrationTypeList, integrationList } = useContext(IntegrationContext) as IntegrationContextPayload;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [phoneNumberIntegrate, setPhoneNumberIntegrate] = useState<any>()
    const [linkedPhoneNumberData, setLinkedPhoneNumberData] = useState<linkedPhoneNumberData[]>([])
    const [flagShowAvailableNumbers, setFlagShowAvailableNumbers] = useState(false)
    const [availablePhoneNumberData, setAvailablePhoneNumberData] = useState<globalIntegration[]>([])
    const [addChatbotIntegrationDetails, setAddChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        meta_integration_id: "",
        chatbot_id: "",
        phone_number_id: "",
        meta_integration_type_id: ""
    })
    const [removeChatbotIntegrationDetails, setRemoveChatbotIntegrationDetails] = useState<addChatbotIntegrationDetailsInterface>({
        chatbot_id: "",
        meta_integration_type_id: "",
        phone_number_id: "",
        meta_integration_id: ""
    })
    const [responsePayload, setResponsePayload] = useState({
        response1: "",
        response2: ""
    })
    async function getIntegrationListData() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        await getIntegrationList()
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    useEffect(() => {
        getIntegrationListData()
    }, [])

    useEffect(() => {
        let ids: any = []
        if (integrationList !== null) {
            if (integrationList.length > 0) {
                let whatsappFilter = integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "whatsapp");
                whatsappFilter?.map((data) => {
                    data?.meta_integration_whatsapp_data?.meta_integration_phone_number_data.map((phoneData) => {
                        phoneData.chatbot_integration_assignment_data.map((assignmentData) => {
                            ids.push(String(assignmentData.chatbot_id))
                        })
                    })
                });
                let validID = ids.includes(chatBotDetails?.chatbot_id);

                if (validID && integrationList.length > 0 && integrationList.flatMap((integration: globalIntegration) => ({ ...integration.meta_integration_whatsapp_data, ...integration })).filter((data) => data).length > 0) {
                    setflagWhatsappIntegrationExist(true)
                    setShowIntegrationDetails(true)
                } else {
                    setflagWhatsappIntegrationExist(false)
                    setShowIntegrationDetails(false)
                }
            } else {
                setflagWhatsappIntegrationExist(false)
                setShowIntegrationDetails(false)
            }

        }

    }, [getChatbotIntegrationData, chatBotDetails, integrationList])

    useEffect(() => {
        if (integrationList) {
            let temp: any = integrationList.flatMap((integration) => ({ ...integration.meta_integration_whatsapp_data, meta_integration_id: integration.meta_integration_id, meta_integration_type_id: integration.meta_integration_type_assignment_data.meta_integration_type_id })).flatMap((whatsAppData) => (whatsAppData.meta_integration_phone_number_data?.map((phone) => ({ ...phone, meta_integration_id: whatsAppData.meta_integration_id, meta_integration_type_id: whatsAppData.meta_integration_type_id }))))
            if (temp) {
                let linkedPhoneNumber: linkedPhoneNumberData[] = temp.filter((phoneNumber: linkedPhoneNumberData) => phoneNumber?.chatbot_integration_assignment_data.some((chatbot) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')))
                if (temp) {
                    if (linkedPhoneNumber) {
                        setLinkedPhoneNumberData(linkedPhoneNumber)
                    }
                    setAvailablePhoneNumberData(integrationList)
                }
            }
        }
    }, [integrationList, chatBotDetails])
    const [codeValue, setCodeValue] = useState({
        code: ""
    })
    const [whatsappData, setWhatsappData] = useState({
        waba_id: "",
        phone_number_id: ""
    })
    const [chatbotIntegrationPayload, setChatbotIntegrationPayload] = useState({
        chatbot_id: -1,
        phone_number_id: "",
        waba_id: "",
        code: ""
    })
    useEffect(() => {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        if (metaCredentials) {
            let whatsappConfig = metaCredentials.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "whatsapp")
            window.fbAsyncInit = function () {
                // @ts-ignore
                FB.init(whatsappConfig.fb_init);
            };
        }
    }, [metaCredentials]);
    useEffect(() => {
        const sessionInfoListener = (event: MessageEvent) => {
            if (event.origin !== "https://www.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                // Handle the data from the event
                // console.log("session info data", data);


                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        setWhatsappData({ ...whatsappData, phone_number_id: phone_number_id, waba_id: waba_id })
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                    }
                }
            } catch (error) {
                // Handle errors or non-JSON messages
            }
        };
        window.addEventListener('message', sessionInfoListener);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, [metaCredentials]);

    useEffect(() => {
        (async () => {
            let chatbot_id = searchParams.get('chatbot_id') ?? ""
            if (codeValue.code !== "" && whatsappData.waba_id !== "" && whatsappData.phone_number_id !== "" && Number(chatbot_id) !== -1) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true, flagContentGenerating: false });
                let whatsappObj = {
                    waba_id: whatsappData.waba_id,
                    phone_number_id: whatsappData.phone_number_id,
                    code: codeValue.code
                }
                let payload: createMetaIntegrationPayload = {
                    meta_integration_type_id: globalIntegrationTypeList.find((integrationType) => integrationType.meta_integration_type_name.toLowerCase() == "whatsapp")?.meta_integration_type_id ?? Number(process.env.REACT_APP_WHATSAPP_META_INTEGRATION_TYPE_ID),
                    meta_integration_whatsapp_data: whatsappObj,
                    meta_data: JSON.stringify(responsePayload)
                }
                const response: CommonResponseModel<integrationData> = await agent.createMetaIntegration.post(payload);

                setCodeValue((prevState) => ({ ...prevState, code: "" }))
                setWhatsappData((prevState) => ({
                    ...prevState, waba_id: "",
                    phone_number_id: ""
                }))
                setResponsePayload({
                    response1: "",
                    response2: ""
                })
                if (response.status && response.data) {
                    let createdIntegration = response.data[response.data.length - 1]
                    let payload = {
                        meta_integration_id: createdIntegration.meta_integration_id.toString(),
                        chatbot_id: searchParams.get('chatbot_id') ?? "",
                        phone_number_id: whatsappData.phone_number_id,
                        meta_integration_type_id: createdIntegration.meta_integration_type_assignment_data.meta_integration_type_id.toString()
                    }
                    AddChatbotIntegration(payload)
                    setSuccess({ ...success, flagShowSuccess: true, message: "Integration Successfull!" })
                    let chatbotId = searchParams.get('chatbot_id') ?? chatBotDetails?.chatbot_id.toString()
                    if (chatbotId) {
                        const response = await GetChatbotIntegrationData(chatbotId, "")
                        if (response.status) {
                            if (response.data && response.data.length > 0) {
                                setShowIntegrationDetails(true)
                            }
                        }
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                    setCodeValue({ code: "" });
                    setWhatsappData({ waba_id: "", phone_number_id: "" });
                    safeLogout();
                }

                setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: false });

            }
        })()
    }, [codeValue, whatsappData])

    const safeLogout = (): void => {
        // Check the user's current login status
        window.FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                // User is logged in to your app and Facebook.
                // It's safe to call logout
                window.FB.logout((logoutResponse: any) => {
                    // Handle post-logout logic here
                });
            } else {
                console.log('User was not logged in via Facebook SDK. No need to log out.');
                // Handle scenarios where the user was not logged in
            }
        });
    };

    // Ensure FB is initialized before calling this function
    const launchWhatsAppSignup = () => {
        if (metaCredentials) {
            let whatsappConfig = metaCredentials.find((configuration) => configuration.meta_integration_type_name.toLowerCase() == "whatsapp")
            if (!window.FB) {
                console.error("Facebook SDK not loaded");
                return;
            }
            // @ts-ignore
            window.FB.login(function (response: any) {
                if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken;
                    if (response.authResponse.code) {
                        setCodeValue({ ...codeValue, code: response.authResponse.code })
                    }
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: "User cancelled login or did not fully authorize." }))
                    setFlagShowAvailableNumbers(false)
                }
            },

                whatsappConfig?.signup_config
            );
        }
    };
    const deleteChatbotIntegration = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
        const response = await agent.removeChatbotIntegration.delete(removeChatbotIntegrationDetails.chatbot_id, removeChatbotIntegrationDetails.meta_integration_id, removeChatbotIntegrationDetails.meta_integration_type_id, removeChatbotIntegrationDetails.phone_number_id);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Integration Removed Successfully" })
            await GetChatbotIntegrationData(removeChatbotIntegrationDetails.chatbot_id, "")
            await getIntegrationList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }
        setShowDeleteConfirmation(false)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }


    const handleRemoveChatbotIntegration = (meta_integration_id: number, meta_integration_type_id: number, phone_number_id: string, display_phone_number: string) => {
        if (integrationList) {
            let chatbot_id = searchParams.get('chatbot_id')
            if (chatbot_id) {
                setRemoveChatbotIntegrationDetails(
                    {
                        chatbot_id: chatbot_id,
                        meta_integration_type_id: meta_integration_type_id.toString(),
                        phone_number_id: phone_number_id,
                        meta_integration_id: meta_integration_id.toString()
                    }
                )
            }
            setShowDeleteConfirmation(true)
            setPhoneNumberIntegrate(display_phone_number)
        }
    }
    function addChatbotIntegration(meta_integration_id: number, meta_integration_type_id: number, phone_number_id: string, display_phone_number: string, event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            handleClick(event)
        }
        let chatbot_id = searchParams.get('chatbot_id')
        if (chatbot_id) {
            setAddChatbotIntegrationDetails((prevState) => ({
                ...prevState,
                phone_number_id: phone_number_id,
                meta_integration_type_id: meta_integration_type_id.toString(),
                chatbot_id: chatbot_id,
                meta_integration_id: meta_integration_id.toString()
            }))
        }
        setPhoneNumberIntegrate(display_phone_number)
    }
    async function AddChatbotIntegration(addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface) {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.addChatbotIntegration.post(addChatbotIntegrationDetails)
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Integration Successfull!" }))
            setGetChatbotIntegrationData(response.data)
            await getIntegrationList()
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagShowAvailableNumbers(false)
        handleCloseConfirmationPopover()
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    const [showConfirmationPopover, setShowConfirmationPopover] = React.useState(false);

    const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // debugger
        setConfirmationPopoverAnchorEl(event.currentTarget);
        setShowConfirmationPopover(true)
    };

    const handleCloseConfirmationPopover = () => {
        setConfirmationPopoverAnchorEl(null);
        setShowConfirmationPopover(false)
    };

    return (
        <>
            {flagShowAvailableNumbers &&
                <ShowAvailablePhoneNumbers
                    flagShowAvailableNumbers={flagShowAvailableNumbers}
                    setFlagShowAvailableNumbers={setFlagShowAvailableNumbers}
                    smallScreen={smallScreen}
                    showConfirmationPopover={showConfirmationPopover}
                    confirmationPopoverAnchorEl={confirmationPopoverAnchorEl}
                    handleCloseConfirmationPopover={handleCloseConfirmationPopover}
                    addChatbotIntegrationDetails={addChatbotIntegrationDetails}
                    AddChatbotIntegration={AddChatbotIntegration}
                    availablePhoneNumberData={availablePhoneNumberData}
                    handleRemoveChatbotIntegration={handleRemoveChatbotIntegration}
                    displayPhoneNumber={phoneNumberIntegrate}
                    launchWhatsAppSignup={launchWhatsAppSignup}
                    saveChatbotIntegrationData={addChatbotIntegration}
                />

            }
            {(flagWhatsappIntegrationExist == false) ?
                <>
                    <Grid container direction={"column"} justifyContent={"start"} alignItems={"start"} sx={{ width: "100%" }} gap={2} flexWrap={"nowrap"} pt={1}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container alignItems={"center"} gap={"13px"} mt={1} flexWrap={"nowrap"}>
                                <Grid item sx={{ display: "flex" }}>
                                    <img alt="" src={whatsAppIcon} className={integrationStyles.whatsappIntegrationIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={integrationStyles.whatsappIntegrationFontStyle}>Integrate with your WhatsApp Business Account</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)", borderRadius: "5px", width: "100%" }} padding={"30px"}>
                            {
                                (!getChatbotIntegrationData && (integrationList && integrationList?.filter((integration) => integration.meta_integration_type_assignment_data.meta_integration_type_name === "WhatsApp").length > 0)) ?
                                    <>
                                        <Grid container direction={"column"}>
                                            <Grid item>
                                                {/* <ChatbotWhatsAppIntegrationSetup launch={launchWhatsAppSignup} /> */}
                                                <span style={{ fontSize: "15px", fontWeight: "600", color: "#353535" }}>This Chatbot is not linked with any WhatsApp Business Number yet. </span>
                                            </Grid>
                                            <Grid item sx={{ paddingTop: "20px" }}>
                                                <GlobalButton buttonText={"Add WhatsApp Number"} className='primaryButtonStyle' onClick={() => { setFlagShowAvailableNumbers(true) }} sx={{ padding: "6px 20px !important" }} startIcon={<IconsColor fillColor="#FFFFFF" iconName="plus" width="15px" height="15px" />} />
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <ChatbotWhatsAppIntegrationSetup launch={launchWhatsAppSignup} />
                                        </Grid>
                                        <Grid item sx={{ borderTop: "1px solid #E1DCDC", paddingTop: "20px" }}>
                                            <GlobalButton buttonText={"Integrate WhatsApp Business Account"} className='primaryButtonStyle' onClick={launchWhatsAppSignup} />
                                        </Grid>
                                    </Grid>
                            }

                        </Grid>
                    </Grid>
                </>
                :
                <>
                    {linkedPhoneNumberData.length > 0 &&
                        <Grid container gap="20px" direction={"column"} sx={{ padding: { xs: "15px 7px", lg: "15px 15px 15px 5px" } }} mt={{ xs: 1, lg: 0 }}>
                            <Grid item>
                                <Grid container gap="15px">
                                    {linkedPhoneNumberData.map((phoneNumberData, index) => (
                                        <AccountInformationCard
                                            key={index}
                                            flagDelete={false}
                                            title={`WhatsApp Account ${index + 1}`}
                                            currentlySelected={true}
                                            showRemoveChatbotIntegration={true}
                                            removeChatbotIntegration={(phone_number_id: string) => { handleRemoveChatbotIntegration(phoneNumberData.meta_integration_id, phoneNumberData.meta_integration_type_id, phone_number_id, phoneNumberData.display_phone_number) }}
                                            details={{
                                                display_phone_number: phoneNumberData.display_phone_number,
                                                quality_rating: phoneNumberData.quality_rating,
                                                phone_number_id: phoneNumberData.phone_number_id,
                                                linked_chatbots: phoneNumberData.chatbot_integration_assignment_data,
                                                business_name: phoneNumberData.verified_name,
                                            }}
                                            flagIntegration={true}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item>

                                <GlobalButton onClick={() => { setFlagShowAvailableNumbers(true) }} buttonText={"Add WhatsApp Number"} className={'blackShadowedButton'}
                                    startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                                />
                            </Grid>

                        </Grid>}
                    {
                        showDeleteConfirmation &&
                        <GlobalPopUp
                            open={showDeleteConfirmation}
                            handleClose={() => { setShowDeleteConfirmation(false) }}
                        >
                            <GlobalDeletePopup
                                deleteDescription={`Are you sure you want to remove ${chatBotDetails?.chatbot_name} from ${phoneNumberIntegrate}`}
                                onClose={() => { setShowDeleteConfirmation(false) }}
                                onDelete={deleteChatbotIntegration}
                                sx={{ maxWidth: "400px" }}
                            />
                        </GlobalPopUp>
                    }
                </>
            }

        </>
    )
}

export default ChatbotWhatsAppIntegration;