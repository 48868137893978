import React from 'react'
import { useSearchParams } from 'react-router-dom'
import DoctorCardCarousel from './MessageFormatForm/doctorCardCarousel';
import { Grid } from '@mui/material';

const MessageFormatForms = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const message_format_id = Number(searchParams.get('message_format_id'))

    return (
        <Grid item sx={{width: "100%"}}>
            {message_format_id == 5 &&
                <DoctorCardCarousel message_format_id={message_format_id}/>
            }
        </Grid>
    )
}

export default MessageFormatForms
