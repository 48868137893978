import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import writewizLogo from "../../../assets/images/global/Chronox Logo.svg";
import styles from "../../../assets/styles/componentStyles/sideNavigationPanel.module.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreditUsedComponent } from '../../utility/creditUsed';
import { Autocomplete, Collapse, Divider, Grid, TextField } from '@mui/material';
import GlobalButton from '../../utility/globalButton/button';
import { SidepanelAccountDetails } from '../../utility/sidePanelAccountDetails';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import premiumIcon from "../../../assets/personaCrown.svg";
import MiniDrawer from './test';
import globalStyles from "../../../assets/styles/global.module.scss";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ConsultationComponent } from '../../utility/consult';
import IconsColor from '../../utility/iconsColor';
import { getOrganizationDetailDataModel } from '../../../models/axiosModel';
import SettingsWorkspace from '../../utility/settingsWorkspace';
import { DelegateAccess } from '../../global/delegateAccess';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BackToMainPageGlobalComponent from '../../utility/backToMainPageGlobalComponent';
import { flagShowHomeButton } from '../../../utils/constants';
const drawerWidth = 233;
const mobileDrawerWidth = 96;
interface menuItem {
    menuName: string,
    menuKey: string,
    imgSrc: any,
    whiteImgSrc: any,
    coloredImgSrc: any,
    navigationLink: string,
    flagDisabled?: boolean
    subMenu?: [],
    flagHeaderKey?: boolean,
    flagAdmin?: boolean
}
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    open: boolean;
    onClose: () => void;
    menuItems: menuItem[]
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    openDesktopDrawer: boolean
}
export default function SideNavigationPanel(props: Props) {
    const { userDetail, flagChromeDesktop, organizationDetails, currentActiveMobileMenu, setCurrentActiveMobileMenu, customPagesData, selectedSubMenu, setSelectedSubMenu, currentMenuName, setCurrentMenuName } = React.useContext(UserContext) as UserContextPayload
    // const [currentActiveMobileMenu, setCurrentActiveMobileMenu] = React.useState("home");
    const [hoveredMenu, setHoveredMenu] = React.useState("");
    const [openSubMenu, setOpenSubMenu] = React.useState(true);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = searchParams.get("chatbot_id");
    const id = searchParams.get("id");
    const organization_id = searchParams.get('organization_id');
    const pathName = window.location.pathname.toLowerCase()

    React.useEffect(() => {
        if (window.location.pathname == "/" && userDetail?.user_organization_data?.organization_id != 2545) {
            setCurrentActiveMobileMenu("/chatbot")
        }
        else if (window.location.pathname == "/" && userDetail?.user_organization_data?.organization_id == 2545) {
            setCurrentActiveMobileMenu("/channels")
        }
        else if (window.location.pathname.toLowerCase() == "/createchatbot" || window.location.pathname == "/chatbot") {
            setCurrentActiveMobileMenu("/chatbot")
        }
        else if (window.location.pathname == "/insights") {
            setCurrentActiveMobileMenu("/insights")
        }
        else if (window.location.pathname.toLowerCase() == "/inbox") {
            setCurrentActiveMobileMenu(`/inbox`)
        }
        else if (window.location.pathname.toLowerCase() == "/feedback") {
            setCurrentActiveMobileMenu(`/feedback`)
        }
        else if (window.location.pathname.toLowerCase() == "/contacts") {
            setCurrentActiveMobileMenu(`/contacts`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels") {
            setCurrentActiveMobileMenu(`/channels`)
        }
        else if (window.location.pathname.toLowerCase() == "/whatsappcampaign" || window.location.pathname.toLowerCase() === "/whatsappcampaign/createnewcampaign") {
            setCurrentActiveMobileMenu(`/whatsappcampaign`)
        }
        else if (window.location.pathname.toLowerCase() == "/settings-plansandbilling") {
            setCurrentActiveMobileMenu("/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() == "/settings-accountdetails") {
            setCurrentActiveMobileMenu("/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/basicdetail" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/createintegration") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/integration?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/persistentmenu") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/persistentmenu?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/whatsapp" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/viewwhatsappintegration") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/whatsapp?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/facebook" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/viewfacebookintegration") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/facebook?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/instagram" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/viewinstagramintegration") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/instagram?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/website") {
            setOpenSubMenu(true);
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/website?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/channels/calendly")) {
            setOpenSubMenu(true);
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/calendly?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/channels/slack")) {
            setOpenSubMenu(true);
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/channels/slack?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/inbox") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/inbox?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/leadgeneration")) {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/products") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/products?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/messageformat" || window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/messageformat/staticformat") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/messageformat?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/trychatbot") {
            setCurrentActiveMobileMenu(`/chatbot/viewchatbot/trychatbot?chatbot_id=${chatbot_id}`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/whatsapp") {
            setOpenSubMenu(true);
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/whatsapp/phonenumbers") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp/phonenumbers?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp/phonenumbers`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/whatsapp/templateinfo" || window.location.pathname.toLowerCase() == "/channels/viewintegration/whatsapp/templatemessages") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp/templatemessages?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/whatsapp/templatemessages`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/facebook") {
            setOpenSubMenu(true);
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/facebook?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/facebook`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/facebook/pages") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/facebook/pages?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/facebook/pages`)
        }
        else if (window.location.pathname.toLowerCase() == "/channels/viewintegration/facebook/templateinfo" || window.location.pathname.toLowerCase() == "/channels/viewintegration/facebook/templatemessages") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/facebook/templatemessages?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/facebook/templatemessages`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/instagram") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/instagram?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/instagram`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/slack?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/slack`)
        }
        else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack/channels") {
            id ? setCurrentActiveMobileMenu(`/channels/viewintegration/slack/channels?id=${id}`) : setCurrentActiveMobileMenu(`/channels/viewintegration/slack/channels`)
        }
        else if (window.location.pathname.toLowerCase() === "/organization") {
            organization_id ? setCurrentActiveMobileMenu(`/organization?organization_id=${organization_id}`) : setCurrentActiveMobileMenu(`/organization`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/callanalysis`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords/newcallrecords") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/callanalysis`)
        }
        else if (window.location.pathname.toLowerCase() === "/callanalysis") {
            setOpenSubMenu(true)
            setCurrentActiveMobileMenu(`/callanalysis`)
        }
        else {
            setCurrentActiveMobileMenu(window.location.pathname.toLowerCase())
        }
    }, [window.location.pathname])


    const handleClick = (keyName: string) => {
        if (!selectedSubMenu.includes(keyName)) {
            setSelectedSubMenu([...selectedSubMenu, keyName]);
        }
        else if (selectedSubMenu.includes(keyName)) {
            setSelectedSubMenu([...selectedSubMenu.filter((data) => data !== keyName)])
        }
        if (window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/website` || window.location.pathname.toLowerCase().includes('/chatbot/viewchatbot/channels/calendly') || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/whatsapp` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/facebook` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/instagram` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/viewwhatsappintegration` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/viewfacebookintegration` || window.location.pathname.toLowerCase() === `/chatbot/viewchatbot/channels/viewinstagramintegration`) {
            setOpenSubMenu(true);
        }
        else if (window.location.pathname.toLowerCase() === `/callanalysis` || window.location.pathname.toLowerCase() === `/callanalysis/callrecords`) {
            setOpenSubMenu(true);
        }
        else if (customPagesData && customPagesData.length > 0 && customPagesData.filter((data) => data.custom_page_slug == currentActiveMobileMenu).length > 0) {
            setOpenSubMenu(true);
        }
        else if (window.location.pathname.toLowerCase() === `/channels/viewintegration` || window.location.pathname.toLowerCase() === `/channels/viewintegration/templateinfo` || window.location.pathname.toLowerCase() === `/channels/viewintegration/templatemessages`) {
            setOpenSubMenu(true)
        }
        else {
            // setOpenSubMenu(!openSubMenu);
        }
    };

    // React.useEffect(() => {
    //     setOpenSubMenu(true)
    // }, [selectedSubMenu])

    const drawer = (
        <>
            <Grid container direction="column" justifyContent={"space-between"} className={userDetail.flagUser && !userDetail.user_delegate_access_flag ? `${styles.navigationPanelContentWrapper}` : `${styles.navigationPanelContentWrapperDelegateAccess}`}>
                <Grid item>
                    <Toolbar>
                        <img src={writewizLogo} onClick={() => { navigate('/') }} className={styles.writewizLogoStyle} />
                    </Toolbar>
                    {
                        (pathName == "/" || pathName == "/insights" || pathName == "/chatbot" || pathName == "/inbox" || pathName == "/channels" || pathName == "/feedback" || pathName == "/contacts" || pathName == "/whatsappcampaign" || pathName == "/whatsappcampaign/createnewcampaign") &&
                        <>
                            <Grid item sx={{ margin: "0px 15px", backgroundColor: "#DEE8F3", borderRadius: "5px", padding: "8px 15px" }}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item>
                                        <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                            <Grid item><IconsColor height={"15"} width={"15"} fillColor={"#4C4A4D"} iconName="workspace" /></Grid>
                                            <Grid item sx={{ fontSize: "12px", fontWeight: "500", color: "#4C4A4D" }}><span>Workspace</span></Grid>
                                        </Grid>
                                    </Grid>
                                    {organizationDetails && organizationDetails.length > 1 ?
                                        <Autocomplete
                                            disableClearable
                                            value={organizationDetails ? organizationDetails.find((organization) => organization.organization_status_name === "Active") : undefined}
                                            className={`${globalStyles.chatbotTitleSelect}`}
                                            options={organizationDetails ? organizationDetails : []}
                                            getOptionLabel={(option: getOrganizationDetailDataModel) => option.organization_name}
                                            // onChange={(e, value) => handleOptionChange(e, value)}
                                            renderInput={(params) => <TextField
                                                {...params} placeholder='Select' />}
                                        />
                                        :
                                        <Grid item sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>{organizationDetails ? organizationDetails[0].organization_name : ""}</Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "10px 20px 0px" }} />
                        </>
                    }
                    <List sx={{ padding: '10px' }} >
                        {props.menuItems.map((menuItem, index) => {
                            return (
                                <>
                                    {!menuItem.subMenu ?
                                        <>
                                            <ListItem
                                                sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1" }}
                                                className={menuItem.flagHeaderKey ? styles.headerSideItem : (currentActiveMobileMenu.toLowerCase() == menuItem.navigationLink.toLowerCase()) ? styles.selectedListItemStyle : styles.listItemStyle}
                                                onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                                onClick={() => {
                                                    if (menuItem.flagDisabled) {
                                                    } else if (menuItem.flagHeaderKey) {
                                                        // setOpenSubMenu(false);
                                                    } else {
                                                        setCurrentActiveMobileMenu(menuItem.navigationLink);
                                                        // setOpenSubMenu(false);
                                                        setSelectedSubMenu([])
                                                        navigate(menuItem.navigationLink)
                                                        props.onClose()
                                                    }
                                                }
                                                }
                                                key={menuItem.menuKey} disablePadding>
                                                <ListItemIcon sx={{ transform: "scale(1.1)" }} className={styles.imageNavigationMenu}>
                                                    {currentActiveMobileMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                                </ListItemIcon>
                                                <ListItemText primary={menuItem.menuName} className={currentActiveMobileMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() || menuItem.flagDisabled || menuItem.flagHeaderKey ? "listItemSelectedMenuText" : styles.listItemMenuText} />
                                                {menuItem.flagAdmin &&
                                                    <AdminPanelSettingsIcon />
                                                }
                                                {/* {menuItem.flagDisabled &&
                                                    <ListItemIcon><img src={premiumIcon} /></ListItemIcon>} */}

                                            </ListItem>
                                        </>
                                        :
                                        <>
                                            <ListItem button onClick={() => handleClick(menuItem.menuKey)} className={currentMenuName == menuItem.menuKey && !selectedSubMenu.includes(menuItem.menuKey) ? styles.selectedListItemStyle : styles.listItemStyle}>
                                                <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                                    {menuItem.imgSrc}
                                                </ListItemIcon>
                                                <ListItemText primary={menuItem.menuName} />
                                                {openSubMenu && selectedSubMenu.includes(menuItem.menuKey) ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={openSubMenu && selectedSubMenu.includes(menuItem.menuKey)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {/* Nested items */}
                                                    {menuItem.subMenu.map((subMenu: any) => {
                                                        return <ListItem
                                                            sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1", paddingLeft: "20px !important" }}
                                                            className={currentActiveMobileMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() ? styles.selectedListItemStyle : styles.listItemStyle}
                                                            onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }}
                                                            onMouseLeave={() => { setHoveredMenu("") }}
                                                            onClick={() => {
                                                                if (!subMenu.flagDisabled) {
                                                                    setCurrentActiveMobileMenu(subMenu.navigationLink);
                                                                    if (!subMenu.flagUserSpecific) {
                                                                        navigate(subMenu.navigationLink);
                                                                    }
                                                                    props.onClose();
                                                                }
                                                            }}
                                                            key={subMenu.menuKey}
                                                            disablePadding
                                                        >
                                                            <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                                                {currentActiveMobileMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() ? subMenu.whiteImgSrc : hoveredMenu.toLowerCase() == subMenu.menuKey.toLowerCase() ? subMenu.coloredImgSrc : subMenu.imgSrc}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subMenu.menuName} className={currentActiveMobileMenu.toLowerCase() == subMenu.navigationLink.toLowerCase() || menuItem.flagDisabled || menuItem.flagHeaderKey ? "listItemSelectedMenuText" : styles.listItemMenuText} />
                                                            {/* {subMenu.flagDisabled && <ListItemIcon><img alt="premium" src={premiumIcon} /></ListItemIcon>} */}
                                                            {subMenu.flagAdmin && <AdminPanelSettingsIcon />}

                                                        </ListItem>
                                                    })
                                                    }
                                                </List>
                                            </Collapse>
                                        </>
                                    }
                                </>
                            )
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"}>
                        {
                            <Grid item className={`${styles.profileWrapper}`} onClick={() => props.onClose()}>
                                <DelegateAccess />
                            </Grid>
                        }
                        <Grid item className={styles.settingsWorkspace} >
                            <SettingsWorkspace onclose={() => props.onClose()} flagMobileScreen />
                        </Grid>
                        {/* {(window.location.pathname.toLowerCase() !== "/callanalysis" && window.location.pathname.toLowerCase() !== "/callanalysis/list") &&
                            <Grid item className={styles.settingsWorkspace} >
                                <SettingsWorkspace onclose={() => props.onClose()} flagMobileScreen />
                            </Grid>
                        } */}
                        {flagShowHomeButton() &&
                            <Grid item className={styles.settingsWorkspace} >
                                <BackToMainPageGlobalComponent onclose={() => props.onClose()} />
                            </Grid>
                        }
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={`${styles.profileWrapper}`} onClick={() => props.onClose()}>
                                <ConsultationComponent flagInsideSideNavigationPanel />
                            </Grid>
                        }

                        <Grid item className={` ${styles.AccountDetailsWrapper}`}>
                            <SidepanelAccountDetails />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                component="nav"
                sx={{ width: { sm: props.open ? drawerWidth : mobileDrawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
                className={styles.sideBarIcon}
            >
                <Drawer
                    className={styles.sideNavigationPanelStyle}
                    // container={container}
                    variant="temporary"
                    open={props.open}
                    onClose={props.onClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflow: "hidden" },
                    }}
                >
                    {drawer}
                </Drawer>
                <MiniDrawer
                    menuItems={props.menuItems}
                    open={props.openDesktopDrawer}
                    handleDrawerClose={props.handleDrawerClose}
                    handleDrawerOpen={props.handleDrawerOpen}
                />
            </Box>
        </Box>
    );
}