import { Button, Divider, Grid, SwipeableDrawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chatbotPreview.module.scss";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
// import QRCode from "../../../assets/images/chatbot/whatsappQrCode.png";
import whatsappIcon from '../../../assets/Whatsapp.svg'
import QRCode from "react-qr-code";
import PhoneComponent from '../../utility/phoneComponent';
import IconsColor from '../../utility/iconsColor';
import GlobalButton from '../../utility/globalButton/button';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { useSearchParams } from 'react-router-dom';
import { addChatbotTryNumberPayloadModel, getChatbotTryNowNumbersResponseModel, getTryNowPhoneNumbers, linkedPhoneNumberData, tryNowPhoneNumber, whatsappTryNowNumbersResponse } from '../../../models/axiosModel';
import { checkForErrorCode, formatDateAccordingToTimezoneUsingMoment, getErrorMessage, isDev } from '../../../utils/constants';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { MetaContext, MetaContextPayload } from '../../../context/metaContext';
import CloseIcon from '@mui/icons-material/Close';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import globalStyles from "../../../assets/styles/global.module.scss";

const initialWhatsappNumberTry = {
    meta_integration_try_now_phone_number_id: "",
    waba_id: "",
    waba_name: "",
    phone_number_id: "",
    verified_name: "",
    display_phone_number: "",
    created_at_timestamp: "",
}

const initialPhoneNumberData = [
    {
        chatbot_try_now_phone_number_id: 0,
        phone_number_value: ""
    }
]

interface ChatbotPreviewSectionInterface {
    flagSmallScreen: boolean,
    openSwipableDrawer: any,
    setOpenSwipableDrawer: any,
    toggleDrawer: any,
    testChatbotFlag?: boolean,
    setTestChatbotFlag?: any,
    integrationExist?: boolean,
    setIntegrationExist?: any
}
function TestIntegrationTabChatbotPreview(props: ChatbotPreviewSectionInterface) {
    const {
        flagSmallScreen,
        openSwipableDrawer,
        setOpenSwipableDrawer,
        toggleDrawer,
        testChatbotFlag,
        setTestChatbotFlag,
        integrationExist,
        setIntegrationExist
    } = props
    const { setFlagLoading, setSuccess, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;

    const { integrationList } = useContext(IntegrationContext) as IntegrationContextPayload
    const [phoneCount, setPhoneCount] = useState(1);

    const emptyData = {
        chatbot_try_now_phone_number_id: 0,
        phone_number_value: ""
    }

    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    const [iframeKey, setIframeKey] = useState(0);
    const [whatsappNumberTry, setWhatsappNumberTry] = useState<whatsappTryNowNumbersResponse>(initialWhatsappNumberTry);
    const [existingPhoneNumbers, setExistingPhoneNumbers] = useState<getTryNowPhoneNumbers[]>()
    const [flagPhoneComponent, setFlagPhoneComponent] = useState(false)
    const [updateNumberFlag, setUpdateNumberFlag] = useState(false)
    const [updatePhoneNumber, setUpdatePhoneNumber] = useState<getTryNowPhoneNumbers>()
    const [flagRenderDone, setFlagRenderDone] = useState(false)
    const [copyText, setCopyText] = React.useState("Copy")

    const reloadIframe = () => {
        setIframeKey(prevKey => prevKey + 1); // Increment key to trigger reload
    };
    const chatbot_id = Number(searchParams.get('chatbot_id'))
    // const [payloadTryNumber, setPayloadTryNumber] = useState<addChatbotTryNumberPayloadModel>();
    const [chatbotTryData, setChatbotTryData] = useState<getChatbotTryNowNumbersResponseModel>();
    const [phoneNumberData, setPhoneNumberData] = useState<tryNowPhoneNumber[]>(initialPhoneNumberData);
    const { getChatbotIntegrationData} = useContext(MetaContext) as MetaContextPayload;

    useEffect(() => {
        if (integrationList) {
            let temp: any = integrationList.flatMap((integration) => ({ ...integration.meta_integration_whatsapp_data, meta_integration_id: integration.meta_integration_id, meta_integration_type_id: integration.meta_integration_type_assignment_data.meta_integration_type_id })).flatMap((whatsAppData) => (whatsAppData.meta_integration_phone_number_data?.map((phone) => ({ ...phone, meta_integration_id: whatsAppData.meta_integration_id, meta_integration_type_id: whatsAppData.meta_integration_type_id }))))
            if (temp) {
                let linkedPhoneNumber: linkedPhoneNumberData[] = temp.filter((phoneNumber: linkedPhoneNumberData) => phoneNumber?.chatbot_integration_assignment_data.some((chatbot) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')))
                if (temp) {
                    if (linkedPhoneNumber && linkedPhoneNumber.length > 0) {
                        setIntegrationExist(true)
                    }
                }
            }
        }
    }, [integrationList])


    const getChatbotTryNowNumbers = async (chatbot_id: number) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getChatbotTryNowPhoneNumbers.get(chatbot_id)
        if (response.status && response.data && response.data.length > 0) {
            setExistingPhoneNumbers(response.data[0].chatbot_try_now_phone_number)
            setPhoneCount(response.data[0].chatbot_try_now_phone_number.length)
            const phoneData = response.data[0].chatbot_try_now_phone_number.map((data: any) => ({ chatbot_try_now_phone_number_id: data.chatbot_try_now_phone_number_id, phone_number_value: data.phone_number_value }))
            // setPhoneNumberData(response.data[0].chatbot_try_now_phone_number.map((data)=>{chatbot_try_now_phone_number_id: data.chatbot_try_now_phone_number_id}))
            setPhoneNumberData(phoneData)
        }
        setFlagRenderDone(true)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    const getTryNowWhatsAppNumbers = async () => {
        const response = await agent.whatsappTryNowNumbers.get()
        if (response.status) {
            setWhatsappNumberTry(response.data[0]);
        }
    }

    const handleTryPhoneNumber = (event: any, index: any) => {
        const newPhoneNumbersData = phoneNumberData.map((data, i) =>
            i === index ? { ...data, phone_number_value: event.target.value } : data
        );
        setPhoneNumberData(newPhoneNumbersData)
    }

    const handleUpdatePhoneNumber = (event: any, id: any) => {
        const newPhoneNumbersData = phoneNumberData.map((data, i) =>
            id === data.chatbot_try_now_phone_number_id ? { ...data, phone_number_value: event.target.value } : data
        );
        setPhoneNumberData(newPhoneNumbersData)
    }

    const handleClearNumber = (event: any, index: any) => {
        const newPhoneNumbersData = phoneNumberData.map((data, i) =>
            i === index ? { ...data, phone_number_value: "" } : data
        );
        setPhoneNumberData(newPhoneNumbersData)
    }

    const testWhatsAppNumbers = async (flagUpdate?: boolean) => {
        // setPayloadTryNumber({chatbot_id: chatbot_id, chatbot_try_now_phone_number: phoneNumberData})
        if (phoneNumberData.filter((data) => data.phone_number_value.trim().length < 8).length > 0) {

        } else {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
            const addWhatsappNumberPayload: addChatbotTryNumberPayloadModel = {
                chatbot_id: chatbot_id,
                chatbot_try_now_phone_number: phoneNumberData
            };

            const response = await agent.addTryNowPhoneNumbers.post(addWhatsappNumberPayload);
            if (response.status) {
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: flagUpdate ? "WhatsApp Number Updated Successfully!" : "WhatsApp Number Added Successfully!" }))
                setChatbotTryData(response.data[0])
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
                setFlagPhoneComponent(false);
                setUpdateNumberFlag(false)
                setPhoneNumberData([...phoneNumberData])
            }
            else {
                let errorCode = checkForErrorCode(response)
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(errorCode, isDev(userDetail))
                });
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
            }
        }
    }

    useEffect(() => {
        if (chatbot_id) {
            setFlagRenderDone(false)
            getChatbotTryNowNumbers(chatbot_id)
            setExistingPhoneNumbers([])
            setPhoneNumberData(initialPhoneNumberData)
        }
        else {
            setExistingPhoneNumbers([])
            setPhoneNumberData(initialPhoneNumberData)
            setFlagRenderDone(true)
        }
    }, [chatBotDetails, chatbotTryData]);


    const handleRestartTryNumber = async (flagActiveValue: boolean, phoneNumber: any) => {

        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let phoneNumbers = phoneNumberData.map(item => {
            if (item.phone_number_value === phoneNumber.phone_number_value) {
                return {
                    ...item,
                    flag_restart: true
                };
            }
            return item;
        });

        const addWhatsappNumberPayload: addChatbotTryNumberPayloadModel = {
            chatbot_id: chatbot_id,
            chatbot_try_now_phone_number: phoneNumbers
        };

        const deleteWhatsappNumberPayload: addChatbotTryNumberPayloadModel = {
            chatbot_id: chatbot_id,
            chatbot_try_now_phone_number: phoneNumberData.filter((data) => data.phone_number_value !== phoneNumber.phone_number_value)
        }

        if (flagActiveValue) {
            const response = await agent.addTryNowPhoneNumbers.post(deleteWhatsappNumberPayload);
            if (response.status) {
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "WhatsApp Number Removed Successfully!" }))
                setChatbotTryData(response.data[0])
                setPhoneNumberData([...phoneNumberData])
            }
            else {
                let errorCode = checkForErrorCode(response)
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(errorCode, isDev(userDetail))
                });
            }
        } else {
            const response = await agent.addTryNowPhoneNumbers.post(addWhatsappNumberPayload);
            if (response.status) {
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "WhatsApp Number Activated Successfully!" }))
                setChatbotTryData(response.data[0])
                setPhoneNumberData([...phoneNumberData])
            }
            else {
                let errorCode = checkForErrorCode(response)
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(errorCode, isDev(userDetail))
                });
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    useEffect(() => {
        reloadIframe()
        getTryNowWhatsAppNumbers()
    }, []);

    const handleButtonColor = (statusName: boolean) => {
        switch (statusName) {
            case true:
                return "#B40404"
            case false:
                return "#0EA848"
        }
    }

    const handleAddPhoneNumber = () => {
        if (phoneCount < 3) {
            setPhoneCount(phoneCount + 1);
            // setPhoneNumbersData([...phoneNumbersData,emptyPhoneData])
            setPhoneNumberData([...phoneNumberData, emptyData])
        }
    }

    const handleAddNewPhoneNumber = () => {
        if (phoneCount < 3 && !flagPhoneComponent) {
            setFlagPhoneComponent(true)
            setPhoneCount(phoneCount + 1);
            setPhoneNumberData([...phoneNumberData, emptyData])
            setTimeout(() => {
                const container = document.getElementById('numberBlock')
                container?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }, 500);
        }
    }

    const handleRemoveNewPhoneNumber = () => {
        if (phoneCount > 1) {
            setPhoneCount(phoneCount - 1);
            const phone_data = phoneNumberData.filter((data) => data.chatbot_try_now_phone_number_id !== 0 || data.phone_number_value != "");
            setPhoneNumberData(phone_data);
            setFlagPhoneComponent(false);
        }
    }

    const handleUpdateNumber = (phoneNumber: any) => {
        setUpdateNumberFlag(true);
        setUpdatePhoneNumber(phoneNumber);
        setTimeout(() => {
            const container = document.getElementById('updateBlock')
            container?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 500);
    }
    const svgRef = useRef<HTMLDivElement | null>(null);

    const downloadQRCode = () => {
        if (!svgRef.current) return;

        const svg = svgRef.current.querySelector('svg');
        if (!svg) return;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const svgData = new XMLSerializer().serializeToString(svg);
        const img = new Image();
        const svgSize = svg.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            const downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${whatsappNumberTry.display_phone_number}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
    };
    return (
        <>
            {flagRenderDone &&
                <Grid item sx={{ backgroundColor: "#FFFFFF !important", width: "100%", paddingRight: !testChatbotFlag && getChatbotIntegrationData ? "0px" : "25px"}}>
                    <Grid container direction={"row"} flexWrap={"nowrap"} sx={{ position: "relative" }}>
                        {(!integrationExist && !getChatbotIntegrationData) &&
                            <Grid item>
                                <span style={{ fontSize: "22px", fontWeight: "600", color: "#129C82", position: "absolute", left: "-15px", top: "10px" }}>OR</span>
                                <Divider style={{ color: "#000000", opacity: "0.8", height: "calc(100% - 55px)", marginTop: "55px" }} orientation='vertical' />
                            </Grid>
                        }

                        {(!testChatbotFlag) && (getChatbotIntegrationData) &&
                            <Grid item sx={{ backgroundColor: "#E7F6F4", width: "175px", padding: "10px 20px", position: "absolute", right: "-63px", top: "63px", transform: "rotate(270deg)", cursor: "pointer" }} onClick={() => setTestChatbotFlag(true)}>
                                <Grid container gap={1} flexWrap={"nowrap"}>
                                    <Grid item>
                                        <img alt="" src={whatsappIcon} height={""} width={""} style={{ transform: "rotate(90deg)" }} />
                                    </Grid>
                                    <Grid item>
                                        <span style={{ fontSize: "15px", fontWeight: "600" }}>Test Chatbot</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {(testChatbotFlag || !getChatbotIntegrationData) && existingPhoneNumbers && whatsappNumberTry && 
                            <Grid item className={styles.cbPreviewMainItem} sx={{ width: "100%" }} marginLeft={3}>
                                <Grid container direction={"column"} alignItems={"start"}>
                                    {testChatbotFlag && getChatbotIntegrationData &&
                                        <Grid item sx={{ position: "absolute", right: "10px", cursor: "pointer" }} onClick={() => setTestChatbotFlag(false)}>
                                            <CloseIcon />
                                        </Grid>
                                    }
                                    <Grid item paddingLeft={"30px"}>
                                        <span style={{ fontSize: "17px", fontWeight: "600", color: "#000000" }}>Test with Chronox AI WhatsApp Number</span>
                                    </Grid>

                                    <Grid item sx={{ backgroundColor: "rgba(220, 242, 238, 0.7)", padding: "30px", width: "100%", height: "calc(100vh - 160px)", borderRadius: "5px", overflow: "auto" }} mt={2}>
                                        {
                                            existingPhoneNumbers.length > 0 &&
                                            <Grid container direction={"column"} gap={"20px"}>
                                                <Grid item sx={{ backgroundColor: "#FFFFFF", padding: "20px", boxShadow: "0px 0px 4px rgba(0,0,0,0.25) inset", borderRadius: "5px" }}>
                                                    <Grid container direction={"column"} gap={"15px"}>
                                                        <Grid item>
                                                            <span style={{ fontSize: "16px", fontWeight: "600", color: "#353535" }}>Chronox WhatsApp Testing Details
                                                                <Tooltip title={`Scan this QR or click the link to start conversation with ${chatBotDetails?.chatbot_name}`} placement='top'>
                                                                    <InfoIcon className={styles.chatbotRatingIconStyle} sx={{ fontSize: "17px", marginLeft: "7px", cursor: "pointer" }} />
                                                                </Tooltip>
                                                            </span>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                                                <Grid item sx={{ display: "flex" }}><img src={whatsappIcon} height={"20"} width={"20"} /></Grid>
                                                                <Grid item><a href="#" style={{ textDecoration: "none", color: "#4E9FFF", fontSize: "14px" }}>{whatsappNumberTry.display_phone_number}</a></Grid>
                                                            </Grid>
                                                            <Grid container justifyContent={"space-between"}>
                                                                <Grid item>
                                                                    <Grid container direction={"row"} gap={1} alignItems={"center"} mt={1}>
                                                                        <Grid item sx={{ display: "flex" }}><IconsColor iconName="linked_details" height={"18"} width={"18"} /></Grid>
                                                                        <Grid item>
                                                                            {/* <a href="#" style={{textDecoration: "none", color: "#4E9FFF", fontSize: "14px"}}>wa.number/919854425421</a> */}
                                                                            <a href={`https://wa.me/%2B${whatsappNumberTry.display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}
                                                                                rel="noreferrer"
                                                                                target='_blank'
                                                                                style={{ textDecoration: "none", color: "#4E9FFF", fontSize: "14px" }}
                                                                            >
                                                                                {`https://wa.me/%2B${whatsappNumberTry.display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}
                                                                                {/* <QRCode value={`https://wa.me/%2B${phoneNumberQR.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} /> */}
                                                                            </a>
                                                                        </Grid>
                                                                    </Grid >
                                                                </Grid>
                                                                <Grid item className={`${globalStyles.displayFlex} ${globalStyles.centerAlignment}`}>
                                                                    <CopyToClipboard text={`https://wa.me/%2B${whatsappNumberTry.display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`}>
                                                                        <Tooltip
                                                                            sx={{ zIndex: "1300" }}
                                                                            title={copyText}
                                                                            onMouseLeave={() => setCopyText("Copy!")}
                                                                            onClick={() => {
                                                                                setCopyText("Copied!");
                                                                            }}
                                                                        >
                                                                            <Grid item className={globalStyles.clickable}>
                                                                                <IconsColor iconName='Copy' fillColor="#000" width="15px" height="15px" />
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    </CopyToClipboard>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Divider style={{ color: "#000000", fontWeight: "600", opacity: "0.8" }}>OR</Divider>
                                                        </Grid>
                                                        <Grid item alignSelf={"center"}>
                                                            <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                                                <Grid item>
                                                                    <div ref={svgRef}>
                                                                        <QRCode value={`https://wa.me/%2B${whatsappNumberTry.display_phone_number.replaceAll("-", "").replaceAll(" ", "").replaceAll("+", "")}`} />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip sx={{ zIndex: "1300" }} title={"Download QR"}>
                                                                        <Grid item sx={{ display: "flex", justifyContent: "end", marginBottom: "7px" }} className={globalStyles.clickable} onClick={downloadQRCode}>
                                                                            <IconsColor iconName='download' fillColor="#000" width="25px" height="25px" />
                                                                        </Grid>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>                                                           
                                                            {/* <img src={QRCode} height={"200"} width={"200"} /> */}                                                           
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container gap={1} direction={"column"}>
                                                        <Grid item>
                                                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                                <Grid item>
                                                                    <span style={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>Your Phone Numbers</span>
                                                                </Grid>
                                                                <Grid item sx={{ cursor: "pointer", display: phoneCount >= 3 || flagPhoneComponent || updateNumberFlag ? "none" : "inline" }} onClick={() => handleAddNewPhoneNumber()}>
                                                                    {phoneCount < 3 ? <IconsColor fillColor="#000000" width="16" height="16" iconName="plus" />
                                                                        : <IconsColor fillColor="#7A7A7A" width="16" height="16" iconName="plus" />}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <TableContainer sx={{ borderRadius: "5px" }}>
                                                                <Table aria-label="simple table" stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell style={{ backgroundColor: "#ADDED3", fontSize: "14px", fontWeight: "600", padding: "12px", borderRight: "2px solid #E7F6F4" }}>Phone Number</TableCell>
                                                                            <TableCell style={{ backgroundColor: "#ADDED3", fontSize: "14px", fontWeight: "600", padding: "12px" }}>Status</TableCell>
                                                                            <TableCell style={{ backgroundColor: "#ADDED3", fontSize: "14px", fontWeight: "600", padding: "12px" }}>Action</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {existingPhoneNumbers.map((phoneNumber: any) =>
                                                                            <TableRow>
                                                                                <TableCell style={{ backgroundColor: "#FFFFFF", minWidth: "140px", fontSize: "14px", fontWeight: "500", padding: "12px", borderRight: "2px solid #E7F6F4", borderBottom: "2px solid #E7F6F4", borderLeft: `4px solid ${handleButtonColor(!phoneNumber.phone_number_flag_active)}` }}>
                                                                                    {phoneNumber.phone_number_value} <span style={{ marginLeft: "15px", cursor: "pointer" }} onClick={() => handleUpdateNumber(phoneNumber)}><IconsColor iconName="editNumber" width="14" height="14" fillColor="#10C052" /></span>
                                                                                </TableCell>
                                                                                <TableCell style={{ backgroundColor: "#FFFFFF", fontSize: "14px", fontWeight: "500", padding: "12px", borderBottom: "2px solid #E7F6F4" }}>{`${phoneNumber.phone_number_flag_active ? "Active" : "Expired"} (Expiring on ${formatDateAccordingToTimezoneUsingMoment(phoneNumber.phone_number_expire_timestamp, userDetail.timezone_hour, "closed")})`}</TableCell>
                                                                                <TableCell style={{ backgroundColor: "#FFFFFF", fontSize: "14px", fontWeight: "500", padding: "12px", borderBottom: "2px solid #E7F6F4" }}>
                                                                                    <GlobalButton
                                                                                        buttonText={phoneNumber.phone_number_flag_active ? "Stop" : "Restart"}
                                                                                        className='phoneNumberButtonStyle'
                                                                                        startIcon={<IconsColor iconName={phoneNumber.phone_number_flag_active ? "stopNumber" : "restartNumber"} fillColor={handleButtonColor(phoneNumber.phone_number_flag_active)} height={"14"} width={"14"} />}
                                                                                        sx={{ color: handleButtonColor(phoneNumber.phone_number_flag_active) }}
                                                                                        onClick={() => handleRestartTryNumber(phoneNumber.phone_number_flag_active, phoneNumber)}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                        {updateNumberFlag &&
                                                            <Grid item id="updateBlock">
                                                                {
                                                                    <Grid container alignItems={"center"} gap={2} flexWrap={"nowrap"} marginBottom={1}>
                                                                        <Grid item sx={{ width: "100%" }}>
                                                                            {/* <PhoneComponent value={data.phone_number} onChange={(event) => handleChangePhoneNumber(event, index)}/> */}
                                                                            <PhoneComponent value={updatePhoneNumber?.phone_number_value} onChange={(event) => handleUpdatePhoneNumber(event, updatePhoneNumber?.chatbot_try_now_phone_number_id)} handleClear={(event) => handleClearNumber(event, phoneCount - 1)} flagUpdate />
                                                                        </Grid>
                                                                        <Grid item sx={{ cursor: "pointer" }} onClick={() => testWhatsAppNumbers(true)}>
                                                                            {/* <IconsColor fillColor="#000000" width="16" height="16" iconName="plus" /> */}
                                                                            <AddIcon sx={{ color: phoneNumberData.filter((data) => data.phone_number_value.trim().length < 8).length > 0 ? "red" : "#000000", width: "24px", height: "24px", fontWeight: "700" }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                        {flagPhoneComponent &&
                                                            <Grid item id="numberBlock">
                                                                {
                                                                    phoneCount <= 3 && <Grid container alignItems={"center"} gap={2} flexWrap={"nowrap"} marginBottom={1}>
                                                                        <Grid item sx={{ width: "100%" }}>
                                                                            {/* <PhoneComponent value={data.phone_number} onChange={(event) => handleChangePhoneNumber(event, index)}/> */}
                                                                            <PhoneComponent value={phoneNumberData[phoneNumberData.length - 1].phone_number_value} onChange={(event) => handleTryPhoneNumber(event, phoneCount - 1)} handleClear={(event) => handleClearNumber(event, phoneCount - 1)} flagUpdate />
                                                                        </Grid>
                                                                        {phoneNumberData[phoneNumberData.length - 1].phone_number_value.length > 0 ?
                                                                            <Grid item sx={{ cursor: "pointer" }} onClick={() => testWhatsAppNumbers()}>
                                                                                {/* <IconsColor fillColor="#000000" width="16" height="16" iconName="plus" /> */}
                                                                                <AddIcon sx={{ color: phoneNumberData.filter((data) => data.phone_number_value.trim().length < 8).length > 0 ? "red" : "#000000", width: "24px", height: "24px", fontWeight: "700" }} />
                                                                            </Grid>
                                                                            :
                                                                            <Grid item sx={{ cursor: "pointer" }} onClick={() => handleRemoveNewPhoneNumber()}>
                                                                                <ClearIcon sx={{ color: "#D90303", width: "24px", height: "24px", fontWeight: "700" }} />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }

                                        {
                                            existingPhoneNumbers.length === 0 &&
                                            <Grid container direction={"column"} gap={"15px"}>
                                                <Grid item>
                                                    <span style={{ fontSize: "16px", fontWeight: "600", color: "#353535" }}>Test your chatbot '{`${chatBotDetails?.chatbot_name}`}' on WhatsApp test number of Chronox AI</span>
                                                </Grid>
                                                <Grid item mt={1}>
                                                    <span style={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>Enter phone number with which you would be testing</span>
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        phoneNumberData.map((data, index) =>
                                                            phoneCount <= 3 && <Grid container alignItems={"center"} gap={2} flexWrap={"nowrap"} marginBottom={1}>
                                                                <Grid item sx={{ width: "100%" }}>
                                                                    {/* <PhoneComponent value={data.phone_number} onChange={(event) => handleChangePhoneNumber(event, index)}/> */}
                                                                    <PhoneComponent value={data.phone_number_value} onChange={(event) => handleTryPhoneNumber(event, index)} handleClear={(event) => handleClearNumber(event, index)} />
                                                                </Grid>
                                                                {phoneCount <= 3 ?
                                                                    <Grid item sx={{ cursor: "pointer" }} onClick={() => handleAddPhoneNumber()}>
                                                                        <AddIcon sx={{ color: "#000000", width: "24px", height: "24px", fontWeight: "700" }} />
                                                                    </Grid>
                                                                    :
                                                                    <Grid item sx={{ cursor: "pointer" }} onClick={() => handleAddPhoneNumber()}>
                                                                        <AddIcon sx={{ color: "#7A7A7A", width: "24px", height: "24px", fontWeight: "700" }} />
                                                                    </Grid>
                                                                }
                                                                {/* {phoneCount <= 3 ?
                                                                data.phone_number_value.length > 0 ?
                                                                    <Grid item sx={{cursor: "pointer"}} onClick={() => handleAddPhoneNumber()}>
                                                                        <AddIcon sx={{color: "#000000", width: "24px", height: "24px", fontWeight: "700"}}/>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item sx={{cursor: "pointer"}} onClick={(event) => handleRemoveNewPhoneNumber(index)}>
                                                                        <ClearIcon sx={{color: "#D90303", width: "24px", height: "24px", fontWeight: "700"}}/>
                                                                    </Grid>
                                                                :
                                                                <Grid item sx={{cursor: "pointer"}} onClick={() => handleAddPhoneNumber()}>
                                                                    <AddIcon sx={{color: "#7A7A7A", width: "24px", height: "24px", fontWeight: "700"}}/>
                                                                </Grid>
                                                            } */}
                                                            </Grid>
                                                        )
                                                    }
                                                    {/* <PhoneComponent value={approvedUser.phone_number} onChange={(event) => { handleUpdateApprovedUserData('phone_number', index, event) }} /> */}
                                                    {/* <PhoneComponent /> */}
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        style={{
                                                            backgroundColor: "#10C052", boxShadow: "0px 0px 4px rgba(0,0,0,0.25)", borderRadius: "5px", padding: " 6px 20px", color: "#FFFFFF", textTransform: "capitalize",
                                                            //  opacity: phoneNumbersData.filter((data) => data.phone_number.length === 0).length > 0 ? "0.5" : "1"}} 
                                                            opacity: phoneNumberData.filter((data) => data.phone_number_value.trim().length < 8).length > 0 ? "0.5" : "1"
                                                        }}
                                                        // onClick={() => setFlagTest(true)}
                                                        onClick={() => testWhatsAppNumbers()}
                                                        disabled={phoneNumberData.filter((data) => data.phone_number_value.trim().length < 8).length > 0}
                                                    // disabled={phoneNumbersData.filter((data) => data.phone_number.length === 0).length > 0}
                                                    >
                                                        Let's Test
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>}
        </>

    )
}

export default TestIntegrationTabChatbotPreview
