import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon, TextField, InputLabel, Grid, Button, RadioGroup, Radio, FormControlLabel, Snackbar, Alert, CircularProgress, Autocomplete, Chip, Stack, Tooltip, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Rating, Box, Checkbox } from "@mui/material";
import { Textarea, Typography } from '@mui/joy';
import { NumericFormat, PatternFormat } from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import { styled } from '@mui/material/styles';
import 'react-phone-input-2/lib/style.css'
import './globalForm.css';
import FileUpload from './File/fileUpload';
import Loader from "./Loader/loader";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReCAPTCHA from "react-google-recaptcha";
// const GlobalForm = ({ fields, submitFunction, checkFormSubmit, pageThemeData, handleCloseSnackBar, formData, setFieldValue, submissionID, file_field_id }) => {
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { DataGrid } from "@mui/x-data-grid";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { InputText, EmailText } from "./formFieldSet";
import { ColorPicker, } from "material-ui-color";
import "../../../assets/styles/parentFormwizStyles.css";
const GlobalForm = (props) => {
    const { fields, submitFunction, checkFormSubmit, pageThemeData, handleCloseSnackBar, formData, setFieldValue, submissionID, file_field_id, getAIsuggestionList, defaultLoader, formEditable, uploadFileHandler, disableFinalSubmitBtn, filesInParent, removeFile, progressInParent } = props;
    const [unsavedChipVal, setUnsavedChipVal] = React.useState({})
    // fields = [
    //     {
    //         "page_field_id": 1666,
    //         "label": "First Name",
    //         "placeholder": "Enter Your First Name",
    //         "required": true,
    //         "name": "1666_First_Name ",
    //         "type": "text",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1667,
    //         "label": "Upload Image",
    //         "placeholder": "Enter Placeholder",
    //         "required": true,
    //         "name": "1667_Upload_Image ",
    //         "type": "file",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "multiple_file": false,
    //         "multiple_file_allow": "1", (change the name to maximum_number_of_files)
    //         "file_size": "4",(change the name to maximum_size_per_file_mb)
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1669,
    //         "label": "Gender",
    //         "placeholder": null,
    //         "required": false,
    //         "name": "1669_Gender ",
    //         "type": "radio",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12,
    //         "options": [
    //             {
    //                 "field_option_data_id": 7398,
    //                 "field_option_value": "Male"
    //             },
    //             {
    //                 "field_option_data_id": 7399,
    //                 "field_option_value": "Female"
    //             }
    //         ]
    //     },
    //     {
    //         "page_field_id": 1670,
    //         "label": "Terms and Conditions",
    //         "placeholder": null,
    //         "required": false,
    //         "name": "1670_Terms_and_Conditions ",
    //         "type": "checkbox",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "terms_and_conditions_link": "www.instagram.com",
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1671,
    //         "label": "Email",
    //         "placeholder": "Enter email",
    //         "required": false,
    //         "name": "1671_Email ",
    //         "type": "email",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1673,
    //         "label": "Date",
    //         "placeholder": "Enter date",
    //         "required": false,
    //         "name": "1673_Date ",
    //         "type": "date",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1674,
    //         "label": "Address",
    //         "placeholder": "Enter address",
    //         "required": false,
    //         "name": "1674_Address ",
    //         "type": "longtext",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1675,
    //         "label": "Select",
    //         "placeholder": "Select city",
    //         "required": false,
    //         "name": "1675_Select ",
    //         "type": "dropdown",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12,
    //         "options": [
    //             {
    //                 "field_option_data_id": 7429,
    //                 "field_option_value": "Rajkot"
    //             },
    //             {
    //                 "field_option_data_id": 7430,
    //                 "field_option_value": "Jamnagar"
    //             }
    //         ]
    //     },
    //     {
    //         "page_field_id": 1676,
    //         "label": "Phone",
    //         "placeholder": "Enter phone",
    //         "required": false,
    //         "name": "1676_Phone ",
    //         "type": "number",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1677,
    //         "label": "Select",
    //         "placeholder": null,
    //         "required": false,
    //         "name": "1677_Select ",
    //         "type": "multiple checkbox",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12,
    //         "options": [
    //             {
    //                 "field_option_data_id": 7438,
    //                 "field_option_value": "1"
    //             },
    //             {
    //                 "field_option_data_id": 7439,
    //                 "field_option_value": "2"
    //             }
    //         ]
    //     },
    //     {
    //         "page_field_id": 1678,
    //         "label": "Hide",
    //         "placeholder": null,
    //         "required": false,
    //         "name": "1678_Hide ",
    //         "type": "hidden",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1679,
    //         "label": "Number",
    //         "placeholder": "Enter number",
    //         "required": false,
    //         "name": "1679_Number ",
    //         "type": "number",
    //         "value": "",
    //         "hidden": false,
    //         "position": null,
    //         "font_color": null,
    //         "col": 12
    //     },
    //     {
    //         "page_field_id": 1668,
    //         "label": "Submit",
    //         "placeholder": null,
    //         "required": false,
    //         "name": "1668_Submit ",
    //         "type": "submit",
    //         "value": "",
    //         "hidden": false,
    //         "position": "center",
    //         "font_color": "#FFFFFF",
    //         "col": 12
    //     }
    // ]

    // submitFunction-----
    // Submit api call function

    // checkFormSubmit-----
    // Set the status of submission api call response in checkFormSubmit. If the status of submission api call is true then the form data will be reset.
    // setRenderValue({ status: true, message: res.data.data })

    // fileFlagProps-----
    // Set the status of submission api call response in checkFormSubmit.(fileFlagProps(true))

    // handleCloseSnackBar
    // set the rendervalue state as setRenderValue({ status: null, message: "" })

    // pageThemeData = [
    //     {
    //         "page_id": "667",
    //         "page_theme_font_type": "Open Sans",
    //         "page_theme_font_style": "normal",
    //         "page_theme_font_align": "left",
    //         "page_theme_font_weight": "bold",
    //         "page_theme_font_size": "16",
    //         "page_theme_background_color": "#FFFFFF",
    //         "page_theme_heading_color": "#EFE4F1",
    //         "page_theme_text_decoration": "unset"
    //     }
    // ]
    // console.log("formData : ", formData);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [formFields, setFormFields] = useState(JSON.parse(JSON.stringify(fields)));
    const [googleIdFinal, setGoogleIdFinal] = useState([]);
    const [onchangeLoader, setOnchangeLoader] = useState(true);
    const [textfieldClicked, setTextfieldClicked] = useState(false);
    const [onchangeField, setOnchangeField] = useState("");
    const [aiLoader, setAILoader] = useState({});
    const [hoverImage, setHoverImage] = useState(0);
    const recaptchaRef = React.useRef(null);
    const [reCAPTCHADetails, setreCAPTCHADetails] = useState({
        showreCAPTCHAError: false
    });
    const [formDetails, setFormDetails] = useState({
        logo: {},
        heading: {}
    });

    // const [onchangeField, setOnchangeField] = useState();
    var submitGoogleId = file_field_id;

    const schema = yup.object(
        fields.filter(rr => rr.hidden != true).reduce((final, current) => {
            // current.name = current.name.trim();
            if (current.type === "email") {
                if (current.required)
                    return { ...final, [current.name]: yup.string().email("Enter valid email").required("This field is required") };
                else
                    return { ...final, [current.name]: yup.string().email("Enter valid email") };
            }
            else if (current.type === "submit") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string()
                    };
                else
                    return {
                        ...final,
                        [current.name]: yup.string()
                    };
            }
            else if (current.type === "forgot_password_email") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().email("Please Enter valid email address").required("This field is required")
                    };
                else
                    return {
                        ...final,
                        [current.name]: yup.string().email("Please Enter valid email address")
                    };
            }
            else if (current.type === "password" || current.type === "confirm_password") {
                if (current.name == "password")
                    return {
                        ...final,
                        password: yup.string().required("This field is required").min(6, "Password must be at least 6 characters").max(15, "Password must be at most 15 characters").matches(/[a-z]/, "Password must contain atleast one lowercase letter").matches(/[A-Z]/, "Password must contain atleast one uppercase letter").matches(/[!@#\$%\^&\*]/, "Password must contain a special character")
                    };
                else if (current.name === "confirmpassword") {
                    return {
                        ...final,
                        confirmpassword: yup.string().oneOf([yup.ref("password")], "Confirm password do not match")
                    }
                }
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "login_password") {
                if (current.name == "password")
                    return {
                        ...final,
                        password: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "text") {
                if (current.required) {
                    if (current.min && current.max) {
                        return { ...final, [current.name]: yup.string().required("This field is required").min(current.min, 'This field should contain at least ' + current.min + ' characters').max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else if (current.min) {
                        return { ...final, [current.name]: yup.string().required("This field is required").min(current.min, 'This field should contain at least ' + current.min + ' characters') }
                    } else if (current.max) {
                        return { ...final, [current.name]: yup.string().required("This field is required").max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else {
                        return { ...final, [current.name]: yup.string().required("This field is required") }
                    }
                }
                else {
                    if (current.min && current.max) {
                        return { ...final, [current.name]: yup.string().min(current.min, 'This field should contain at least ' + current.min + ' characters').max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else if (current.min) {
                        return { ...final, [current.name]: yup.string().min(current.min, 'This field should contain at least ' + current.min + ' characters') }
                    } else if (current.max) {
                        return { ...final, [current.name]: yup.string().max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else {
                        return { ...final, [current.name]: yup.string() }
                    }
                }

            }
            else if (current.type === "longtext") {
                if (current.required) {
                    if (current.min && current.max) {
                        current.min = Number(current.min)
                        current.max = Number(current.max)
                        return { ...final, [current.name]: yup.string().required("This field is required.").min(current.min, 'This field should contain at least ' + current.min + ' characters').max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else if (current.min) {
                        return { ...final, [current.name]: yup.string().required("This field is required..").min(current.min, 'This field should contain at least ' + current.min + ' characters') }
                    } else if (current.max) {
                        return { ...final, [current.name]: yup.string().required("This field is required...").max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else {
                        return { ...final, [current.name]: yup.string().required("This field is required....") }
                    }
                } else {
                    if (current.min && current.max) {
                        return { ...final, [current.name]: yup.string().min(current.min, 'This field should contain at least ' + current.min + ' characters').max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else if (current.min) {
                        return { ...final, [current.name]: yup.string().min(current.min, 'This field should contain at least ' + current.min + ' characters') }
                    } else if (current.max) {
                        return { ...final, [current.name]: yup.string().max(current.max, 'This field should contain less than ' + current.max + ' characters') }
                    } else {
                        return { ...final, [current.name]: yup.string() }
                    }
                }
            }
            else if (current.type === "date") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "number") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "number format") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "phone") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "file") {
                if (current.required)
                    if (current.multiple_file && !isNaN(current.multiple_file)) {
                        return {
                            ...final,
                            [current.name]: yup.array().min(1, current.label + " field must have at least 1 items").max(Number(current.multiple_file), current.label + " field must have less than or equal to 2 items").required("This field is required"),
                        }

                    } else {
                        return {
                            ...final,
                            [current.name]: yup.array().min(1, current.label + " field must have at least 1 items").required("This field is required"),
                            // [current.name]: yup.array().of(yup.number().required("This field is required")).required("This field is required"),
                            // [current.name]: yup.array().required("This field is required")
                        }
                    }
                else return { ...final, [current.name]: yup.array() };
            }
            else if (current.type === "dropdown") {
                if (current.required) {
                    if (current.is_multiple) {
                        if (current.min.min && current.max) {
                            return { ...final, [current.name]: yup.array().of(yup.string().min(current.min, 'Must be exactly ' + current.min + ' select').max(current.max, 'Must be exactly ' + current.max + ' select')).required("This field is required") }
                        } else if (current.min) {
                            return { ...final, [current.name]: yup.array().of(yup.string()).min(current.min, 'Must be exactly ' + current.min + ' select').required("This field is required") }
                        } else if (current.max) {
                            return { ...final, [current.name]: yup.array().of(yup.string()).max(current.max, 'Must be exactly ' + current.max + ' select').required("This field is required") }
                        } else {
                            return { ...final, [current.name]: yup.array().required("This field is required") }
                        }
                    } else {
                        return { ...final, [current.name]: yup.string().required("This field is required") };
                    }
                }
                else {
                    return { ...final, [current.name]: yup.string() };
                }
            }
            else if (current.type === "hidden") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required")
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "checkbox") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.bool().oneOf([true], current.label + " is required")
                    };
                else return { ...final, [current.name]: yup.bool() };
            }
            else if (current.type === "multiple checkbox") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.array().of(yup.number().required("This field is required")).required("This field is required"),
                        // [current.label]: yup.array().of(yup.number().oneOf([true], 'Terms must be accepted')).min(1),
                        // [current.name]: yup.string().oneOf([true], current.label + " is required")
                    };
                else return { ...final, [current.name]: yup.array() };
            }
            else if (current.type === "multiple chip") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.array().of(yup.number().required('This field is required')).required('This field is required'),
                    };
                else return { ...final, [current.name]: yup.array() };
            }
            else if (current.type === "chip") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.array().of(yup.number().required('This field is required')).required('This field is required'),
                    };
                else return { ...final, [current.name]: yup.array() };
            }
            else if (current.type === "radio") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required("This field is required"),
                    };
                else return
                // { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "recaptcha") {
                return { ...final, [current.name]: yup.string() };
                // if (current.required)
                //     return {
                //         ...final,
                //         [current.name]: yup.string().required("Please verify you are a human"),
                //     };
                // else return { ...final, [current.name]: yup.string() };
            }
            else if (current.type === "rating") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.number().required('This field is required').min(1, "Minimum " + current.label + " is 1")
                    };
                else return { ...final, [current.name]: yup.number() };
            }
            else if (current.type === "opinion scale") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.number().required('This field is required').min(1, "Minimum " + current.label + " is 1")
                    };
                else return { ...final, [current.name]: yup.number() };
            }
            else if (current.type === "select button") {
                if (current.required)
                    return {
                        ...final,
                        [current.name]: yup.string().required('This field is required')
                    };
                else return { ...final, [current.name]: yup.string() };
            }
            else {
                return { ...final, [current.name]: yup.string() };
            }
        }, {}),
    )
    const addMaterialIcontoReact = () => {
        var link = document.createElement('link');

        // Set the attributes for the link element
        link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        link.rel = 'stylesheet';

        // Append the link element to the head of the document
        document.head.prepend(link);
    }
    useEffect(() => {
        addMaterialIcontoReact()
    }, [])

    useEffect(() => {
        if (fields.length) {
            fields.forEach(field => {
                const { name, value } = field;
                setValue(name, value);
            });
        }
    }, [JSON.stringify(fields)])



    useEffect(() => {
        // Iterate through formData array to set default values
        if (fields.length) {
            fields.forEach(field => {
                const { name, value } = field;
                setValue(name, value);
            });
        }

        setFormFields([...fields])
        var obj = {
            logo: {},
            heading: {}
        };
        var formLogo = fields.filter(rr => rr.type == "logo");
        if (formLogo.length) {
            obj['logo'] = formLogo[0]
        }
        var formHeadign = fields.filter(rr => (rr.type == "Title" || rr.type == "title"));
        if (formHeadign.length) {
            obj['heading'] = formHeadign[0]
        }
        setFormDetails(obj)
    }, [fields])

    useEffect(() => {

        if (formData.widget_favicon_id)
            document.getElementById('formWith-backgroundImageid').style.backgroundImage = "url('https://api.filemaster.io/api/storage/file?file_id=" + formData.widget_favicon_id + "&flag_info=false')";
        if (formData.widget_theme_color) {
            document.querySelector(':root').style.setProperty('--formWiz-widget_theme_color', formData.widget_theme_color)
        } else if (pageThemeData.page_theme_heading_color) {
            document.querySelector(':root').style.setProperty('--formWiz-widget_theme_color', pageThemeData.page_theme_heading_color)
        }
        // formData.widget_flag_auto_save = true
        // formData.widget_flag_hide_button = false
    }, [formData])

    useEffect(() => {
        document.body.style.fontFamily = (pageThemeData.page_theme_font_type) ? pageThemeData.page_theme_font_type : "Open Sans,sans-serif";
        document.body.style.fontSize = ((pageThemeData.page_theme_font_size) ? pageThemeData.page_theme_font_size : "13") + "px";
        document.body.style.textTransform = "initial";
    }, [pageThemeData])


    useEffect(() => {
        var loaderArrAI = [];
        formFields.forEach(element => {
            if (element) {
                let { name, value } = element;
                setValue(name, value);
                if (element['flag_ai_suggestion']) {
                    if (element['ai_suggestions_loader'] == undefined) {
                        element['showAISuggestionList'] = false
                    } else {
                        element['showAISuggestionList'] = !element['ai_suggestions_loader'];
                    }
                    loaderArrAI[element['name']] = (element['ai_suggestions_loader']) ? element['ai_suggestions_loader'] : false;
                }
            }
        });
        setAILoader(loaderArrAI);
    }, [formFields])

    useEffect(() => {
        if (checkFormSubmit.status == true || checkFormSubmit.status == false) {
            recaptchaRef.current?.reset();
        }
        if (checkFormSubmit.status == true || checkFormSubmit.status == false && !submissionID) {
            reset()
        }
        if (checkFormSubmit.status == true || checkFormSubmit.status == false) {
            defaultLoader && setLoaderFlag(false)
            setOnchangeLoader(false)
        }
        if (fields.length) {
            fields.forEach(field => {
                const { name, value } = field;
                setValue(name, value);
            });
        }
    }, [checkFormSubmit])


    const {
        register,
        setValue,
        handleSubmit,
        reset,
        control,
        trigger,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
    });

    const blockInvalidChar = (e) => ['+', '-', '{', '}', '[', ']', ';', ':', '"', "'", '|', '=', '_', '(', ')', '*', '&', '^', '%', '$', '#', '@', '!', '~', '.', '<', '>', '?', '/'].includes(e.key) && e.preventDefault();
    const disablewhitespace = (e) => [' '].includes(e.key) && e.preventDefault();

    const handlesubmitFunction = async (data) => {
        var checkreCAPTCHAExist = fields.filter(rr => rr.type == "recaptcha")
        if (checkreCAPTCHAExist.length && reCAPTCHADetails.showreCAPTCHAError != true) {
            setreCAPTCHADetails({ showreCAPTCHAError: "error" });
            return false
        } else {
            data['submit_button'] = true
            defaultLoader && setLoaderFlag(true)
            submitFunction(data);
        }
    }

    const handleOnChange = async (item, page_field_value, field_option_data_id) => {
        // to remove the chip name when cleared
        if (item && page_field_value && item.value && item.value.length > page_field_value.length) {
            setUnsavedChipVal({
                ...unsavedChipVal, [item.name]: ""
            })
        }
        if (item.type == "chip" && item.is_multiple) {
            if (page_field_value && (!page_field_value.length || (page_field_value[page_field_value.length - 1] && page_field_value[page_field_value.length - 1].trim()))) {

            } else {
                return true
            }
        }
        // var valid = await trigger(item.name);
        // console.log("handleOnChange event valid:-", valid, "---item", item, "----value", page_field_value);
        if (item.isBlurEventExecute && formData.widget_flag_auto_save) {
            var valid = await trigger(item.name);
            // if(valid){
            //     var finalTrigger = await trigger();
            //     console.log("handleOnChange finalTrigger finalTrigger:-", finalTrigger);
            //     if(finalTrigger){
            //         disableFinalSubmitBtn(true)
            //     }

            // }
        }

        var finalData = {}
        if (item.label == "Text Direction") {
            finalData['page_field_id'] = item.page_field_id
            finalData['page_field_value'] = page_field_value ? field_option_data_id : item.filter((option) => option.field_option_data_id !== field_option_data_id)[0].field_option_data_id
            finalData['field_option_data_id'] = field_option_data_id
        } else {
            finalData['page_field_id'] = item.page_field_id
            finalData['page_field_value'] = page_field_value
            finalData['field_option_data_id'] = field_option_data_id
        }
        setFieldValue(finalData)
    }
    const handleOnBlurAutocomplete = async (item, valueToStore, isMultiple) => {
        // to store multiple value on blur event
        if (isMultiple) {
            handleOnChange(item, [...item.value, ...valueToStore])
        }
    }

    const handleOnBlur = async (item, value) => {
        // item.value = []
        // var tt = [...formFields].map(element => {
        //     if (element['flag_ai_suggestion']) {
        //         element['showAISuggestionList'] = false
        //     }
        //     return element;
        // });
        //     return schema.validate(formFields, { abortEarly: false })
        //   .then(() => {})
        //   .catch((err) => {
        //     throw err
        //   })
        // debugger;
        if (formData.widget_flag_auto_save) {
            var valid = await trigger(item.name);
            item.isBlurEventExecute = true;
            // if(valid){
            //     var finalTrigger = await trigger();
            //     console.log("handleOnBlur finalTrigger finalTrigger:-", finalTrigger);
            //     if(finalTrigger){
            //         disableFinalSubmitBtn(true)
            //     }
            // }
        }

        // setFormFields(tt)
    }

    const getAIsuggestionFunction = (page_field_id) => {
        getAIsuggestionList(page_field_id)
    }

    const MultiValueRemove = (props) => {
        return (
            <Tooltip truncate>
                <components.MultiValueRemove className="removeMultipleCustom" {...props}>
                    <CloseIcon className="closeicon" />
                </components.MultiValueRemove>
            </Tooltip>
        );
    };

    // const customMultiValue = ({ props, data }) => {
    //     console.log("customMultiValue",props,data);
    //     return (
    //       <div className='input-select'>
    //         <div className='select__multi-value'>
    //           <span>{data.label}</span>
    //           <Icon>{"close"}</Icon>
    //         </div>
    //       </div>

    //     );
    //   };

    const formLabelHTML = (item) => {
        return (
            <>
                <InputLabel
                    sx={{
                        width: "auto",
                        display: "flex",
                        alignItems: "center",
                        fontSize: ((pageThemeData.page_theme_font_size) ? (Number(pageThemeData.page_theme_font_size) + 1).toString() : "13") + "px",
                        margin: pageThemeData.margin ? pageThemeData.margin : "0 0 5px 0",
                        // color: (pageThemeData.page_theme_font_color?formData.page_theme_font_color:"black")+" !important" ,
                        // fontSize: (pageThemeData.page_theme_font_size?pageThemeData.page_theme_font_size:"13")+"px !important" ,
                        // fontStyle: (pageThemeData.page_theme_font_style?pageThemeData.page_theme_font_style:"normal")+" !important" ,
                        // justifyContent: (pageThemeData.page_theme_font_align?pageThemeData.page_theme_font_align:"start")+" !important" ,
                        // fontFamily: (pageThemeData.page_theme_font_type?pageThemeData.page_theme_font_type:"Arial, Helvetica, sans-serif")+" !important" ,
                        // fontWeight: (pageThemeData.page_theme_font_weight?pageThemeData.page_theme_font_weight:"normal")+" !important" ,
                        // textDecoration: (pageThemeData.page_theme_text_decoration?pageThemeData.page_theme_text_decoration:"auto")+" !important" ,
                    }}
                    className={item.class1 ? item.class1 : "label_class_widget_preview"}>
                    {item.label}
                    &nbsp;
                    <span style={{ color: "red" }}>{(item.required) ? '*' : ''}</span>
                    {item.tooltip &&
                        <>
                            {/* <Tooltip sx={{ color: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "#6a097d", fontSize: item.tooltipSize ? item.tooltipSize : "20px" }} title={item.tooltip} placement="top-start"> */}
                            <Tooltip className="formWiz-tooltipLabel" title={item.tooltip} placement="top-start">
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </>
                    }
                    {item.isLoading == true ?
                        (<CircularProgress style={{ color: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "black", width: "15px", height: "15px", padding: "3px" }} />)
                        :
                        ((onchangeLoader && onchangeField == item.page_field_id) ? <CircularProgress style={{ color: (pageThemeData.widget_theme_color) ? pageThemeData.page_theme_font_size : "black", width: "15px", height: "15px", padding: "3px" }} /> : "")
                    }
                    {/* {(item.max &&item.value == "string" && ((item.value.length) >= (Number(item.max) * 0.9))) && */}
                    {(item.max && item.type === "longtext" && ((item.value.length) >= (Number(item.max) * 0.9))) &&
                        <Typography level="body-xs" sx={{ color: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "black", ml: 'auto' }}>
                            {item.value.length}/{item.max ? item.max : 1000}
                        </Typography>
                    }
                </InputLabel>
                {/* {item.helper_text &&
                    <Typography fontSize={10}>
                        {item.helper_text}
                    </Typography>
                } */}

            </>)
    }

    const FormView = () => {
        var ignoreArrType = ["recaptcha", "submit"]
        return (
            <div className="form-container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow style={{ background: pageThemeData.page_theme_heading_color ? pageThemeData.page_theme_heading_color : "#fff" }}>
                                <TableCell>Label</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                fields.map((field) => {
                                    if (ignoreArrType.indexOf(field.type) == -1) {
                                        return (<TableRow>
                                            <TableCell component="th" scope="row">
                                                {field.label}
                                            </TableCell>
                                            <TableCell>{(typeof field.value == "object") ? ((field.value.length > 1) ? field.value.join(", ") : field.value[0]) : field.value}</TableCell>
                                        </TableRow>)
                                    }
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    const RatingLikert = ({ type, ratingData }) => {
        var ratingLength = ratingData.rating_length ? ratingData.rating_length : 5;

        const StyledRating = styled(Rating)(({ theme }) => ({
            '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
                color: theme.palette.action.disabled,
            },
        }));
        const NumberRating = ({ value, onChange, flagStartScale }) => {

            const numberArray = [];
            var finalRatingLength = (flagStartScale) ? ratingLength : ratingLength - 1
            for (let i = (flagStartScale) ? 1 : 0; i <= finalRatingLength; i++) {
                numberArray.push(i);
            }
            return (
                <>
                    <Box display="flex" alignItems="center" justifyContent="space-around">
                        {numberArray.map((number) => {

                            return <Grid container>
                                <Grid item
                                    key={number}
                                    onClick={() => onChange(number)}
                                    className={(value === number ? "selectedScaleRatingClass" : "unSelectedScaleRatingClass") + " " + "scaleRatingClass"}
                                >
                                    {number}
                                </Grid>
                            </Grid>
                        })}
                    </Box>
                </>
            );
        };
        return (
            <>
                <Grid container>
                    {type == "rating" &&
                        <Grid item>
                            {ratingData.rating_style == "Heart" ?
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={ratingData.name}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <StyledRating
                                                {...field}
                                                name="star-rating"
                                                value={isNaN(ratingData.value) ? 0 : Number(ratingData.value)}
                                                onChange={(event, newValue) => {
                                                    onChange(newValue);
                                                    handleOnChange(ratingData, newValue)
                                                }}
                                                icon={<FavoriteIcon fontSize="inherit" />}
                                                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                                max={parseInt(ratingLength)}
                                                size="large"
                                                precision={ratingData.flag_partial_rating ? 0.5 : 1}
                                            />
                                        )}
                                    />
                                </Grid> :
                                <Grid item>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        // defaultValue={0} // Set the default value for the Rating component
                                        name={ratingData.name}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <Rating
                                                {...field}
                                                name="star-rating"
                                                value={Number(ratingData.value)}
                                                onChange={(event, newValue) => {
                                                    onChange(newValue);
                                                    handleOnChange(ratingData, newValue)
                                                }}
                                                max={parseInt(ratingLength)}
                                                size="large"
                                                precision={ratingData.flag_partial_rating ? 0.5 : 1}
                                            />
                                        )}
                                    />
                                </Grid>
                            }
                        </Grid>
                    }
                    {type == "opinion scale" &&
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name={ratingData.name}
                                    render={({ field: { value, onChange, ...field } }) => (
                                        <NumberRating
                                            {...field}
                                            value={ratingData.value}
                                            flagStartScale={ratingData.flag_start_scale}
                                            onChange={(newValue) => {
                                                onChange(newValue);
                                                handleOnChange(ratingData, newValue)
                                            }}
                                        />
                                    )}
                                />

                                <Grid container justifyContent="space-between" style={{ paddingTop: "3px", fontSize: "12px" }} >
                                    {(ratingData.flag_show_scale_labels && ratingData.labels) && ratingData.labels.split(',').map(rr => {
                                        return <Grid item>{rr}</Grid>
                                    })}
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>

            </>
        )
    }

    const SelectionMatrix = ({ formPreview }) => {

        // var columns = formPreview.selection_matrix_column
        // var row = formPreview.selection_matrix_row

        const columns = ([
            {
                field: "id",
                headerName: "ID",
                flex: 2,
                sortable: false,
                width: 195,
                headerClassName: "title_first_column_grid_table",
                cellClassName: (params) => {
                    return "first_row_class";
                },
                renderHeader: (params) => {
                    return (
                        <></>
                    )
                },
                renderCell: params => {
                    return (
                        <Grid container>
                            <Grid item md={9} lg={9} sm={9} xs={9} >
                                <Typography>{params.row.name}</Typography>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                field: "name",
                headerName: "Name",
                flex: 2,
                headerClassName: "title_column_grid_table",
                sortable: false,
                cellClassName: (params) => {
                    return "second_row_class";
                },
                renderHeader: (params) => {
                    return (
                        <Grid container>
                            < Grid md={9} lg={9} sm={9} xs={9} >
                                <Typography >ABC</Typography>
                            </Grid >
                        </Grid >
                    )
                },
                renderCell: params => {
                    return (
                        <>
                            <Checkbox />
                        </>
                    )
                }
            }
        ]);

        const [rows, setRows] = React.useState([
            { id: 1, name: "Item 1", age: 34 },
            { id: 2, name: "Item 2", age: 36 }
        ]);

        return (
            <>
                <Grid item>

                    <DataGrid style={{ width: "100%", overflowX: "auto" }}
                        className='data_grid_rating'
                        rows={rows}
                        columns={columns}
                        autoHeight
                        columnHeaderHeight={() => 'auto'}
                        disableColumnMenu
                        hideFooter
                    // getRowClassName={ (rowData) => 'row_data_data_grid'}
                    />
                </Grid>
            </>
        );
    };
    return (
        <>
            {loaderFlag ? <Loader /> : ""}

            {(1 !== 1 && (checkFormSubmit.status || checkFormSubmit.status == false)) &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleCloseSnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert severity={checkFormSubmit.status == true ? "success" : "error"} onClose={handleCloseSnackBar} sx={{ width: '100%' }}>
                        {checkFormSubmit.message}
                    </Alert>
                </Snackbar>
            }
            <Grid container className={((formData.widget_form_full_width ? "formWiz-fullWidthParent" : "formWiz-smallWidthParent") + " formWiz-fullform")} id="formWith-backgroundImageid" sx={{ background: (!formData.widget_favicon_id && formData.widget_preview_color) ? formData.widget_preview_color : "#fff", minHeight: props.customTemplate ? "400px" : "", maxHeight: props.customTemplate ? "400px" : "" }}>
                <Grid item className={(formData.widget_form_full_width ? "formWiz-fullWidth" : "formWiz-smallWidth")} >
                    <Grid container sx={{ flexWrap: "nowrap" }} direction="column" className={formData.widget_favicon_id ? "mainContainerTest" : "" + " formWiz-fullformChildDiv"}>
                        <Grid item style={{ width: "100%", background: "transparent" }}>
                            <Grid container className="widget_preview_header_container"
                                style={{ background: pageThemeData.page_theme_heading_color ? pageThemeData.page_theme_heading_color : "#fff" }}>
                                {(formDetails.logo && formDetails.logo.file && !formDetails.logo.hidden) &&
                                    <>
                                        <Grid item className="company_logo_" style={{ width: "100%" }} justifyContent={formDetails.logo.position ? formDetails.logo.position : "left"}>
                                            <img src={"https://api.filemaster.io/api/storage/file?file_id=" + formDetails.logo.file + "&flag_info=false"} className="company_logo_image" style={{ width: `${formDetails.logo.file_size ? formDetails.logo.file_size : "15"}px` }} />
                                        </Grid>
                                    </>
                                }
                                {(formDetails.heading && formDetails.heading.label && !formDetails.heading.hidden) ?

                                    <>
                                        <div style={{ paddingLeft: "20px" }}>
                                            <Grid item className="widget_title_text" sx={{
                                                display: "flex",
                                                color: (formData.widget_font_color ? formData.widget_font_color : "black") + " !important",
                                                fontSize: formDetails.heading.heading_size == 'Small' ? '20px' : formDetails.heading.heading_size == "Large" ? '30px' : "26px",
                                                justifyContent: (formDetails.heading.position ? formDetails.heading.position : "start") + " !important",
                                            }}>
                                                {formDetails.heading.label}
                                            </Grid>
                                            <Grid item className="widget_subtitle_text" sx={{
                                                color: (formData.widget_font_color ? formData.widget_font_color : "black") + " !important",
                                                fontSize: formDetails.heading.heading_size == 'Small' ? '12px' : formDetails.heading.heading_size == "Large" ? '16px' : "14px",
                                                justifyContent: (formDetails.heading.position ? formDetails.heading.position : "start") + " !important",
                                            }}>
                                                {formDetails.heading.form_description}
                                            </Grid>
                                        </div>
                                    </> : ""
                                }
                            </Grid>
                        </Grid>
                        {/* <Grid item style={{ padding: "20px", background: formData.widget_preview_color?formData.widget_preview_color:"#fff", borderRadius: "0px 0px 18px 18px" }}> */}
                        <Grid item className={(formDetails.heading && formDetails.heading.label && !formDetails.heading.hidden) ? " mainContainerTestFormwithPadding" : " mainContainerTestForm"}>
                            {!formEditable ?
                                <FormView />
                                :
                                <>
                                    <form onSubmit={handleSubmit(handlesubmitFunction)}>
                                        <Grid container direction="row" justifyContent="space-between" padding={0.5} sx={{ paddingBottom: "20px" }}>
                                            {formFields.filter(rr => rr.hidden != true).map((item, index) => {
                                                if (item.type === "text") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item style={{ display: "flex" }}>
                                                                            <Controller
                                                                                control={control}
                                                                                rules={{
                                                                                    required: true,
                                                                                }}
                                                                                name={item.name}
                                                                                render={({ field: { value, onBlur, onChange, ...field } }) => (
                                                                                    <InputText
                                                                                        fieldProps={field}
                                                                                        item={item}
                                                                                        onChange={onChange}
                                                                                        handleOnChange={handleOnChange}
                                                                                        handleOnBlur={handleOnBlur}
                                                                                    />
                                                                                    // <TextField
                                                                                    //     {...field}
                                                                                    //     type="text"
                                                                                    //     value={item.value}
                                                                                    //     // onChange={
                                                                                    //     //     (e) => {
                                                                                    //     //         handleOnChange(item, e.target.value)
                                                                                    //     //         // onChange(e)
                                                                                    //     //     }
                                                                                    //     // }
                                                                                    //     onChange={({ target: { value } }) => {
                                                                                    //         onChange(value);
                                                                                    //         handleOnChange(item, value)
                                                                                    //     }}
                                                                                    //     onBlur={({ target: { value } }) => {
                                                                                    //         onBlur(value);
                                                                                    //         handleOnBlur(item, value)
                                                                                    //     }}
                                                                                    //     disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                                    //     // className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
                                                                                    //     className={(item.class2 ? item.class2 : "") + " input_class_widget_preview formWiz-input-field"}
                                                                                    //     name={item.page_field_id}
                                                                                    //     // onKeyDown={blockInvalidChar}
                                                                                    //     placeholder={item.placeholder}
                                                                                    // />
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "heading") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item sx={{
                                                                    fontSize: item.heading_size == 'Small' ? '12px' : item.heading_size == "Large" ? '16px' : (pageThemeData.page_theme_font_size ? (Number(pageThemeData.page_theme_font_size) + 1).toString() + "px" : "14px"),
                                                                    justifyContent: (item.position ? item.position : "start") + " !important",
                                                                    fontWeight: "bold"
                                                                }}>
                                                                    {item.label}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                else if (item.type === "email") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Controller
                                                                        control={control}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                        name={item.name}
                                                                        render={({ field: { value, onBlur, onChange, ...field } }) => (
                                                                            <EmailText
                                                                                fieldProps={field}
                                                                                // {...field}
                                                                                item={item}
                                                                                onChange={onChange}
                                                                                handleOnChange={handleOnChange}
                                                                                handleOnBlur={handleOnBlur}
                                                                            />
                                                                            // <TextField
                                                                            //     {...field}
                                                                            //     type="email"
                                                                            //     className={(item.class2 ? item.class2 : "") + " formWiz-input-field"}
                                                                            //     disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                            //     // onKeyDown={disablewhitespace}
                                                                            //     value={item.value}
                                                                            //     onBlur={({ target: { value } }) => {
                                                                            //         onBlur(value);
                                                                            //         handleOnBlur(item, value)
                                                                            //     }}
                                                                            //     onChange={({ target: { value } }) => {
                                                                            //         onChange(value);
                                                                            //         handleOnChange(item, value)
                                                                            //         // if (formData.widget_flag_auto_save == true) {
                                                                            //         //         handleOnBlur(item.page_field_id, event.target.value)
                                                                            //         //     }
                                                                            //     }}
                                                                            //     // className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
                                                                            //     name={item.name}
                                                                            //     placeholder={item.placeholder}
                                                                            // // onBlur={(event) => {
                                                                            // //     if (formData.widget_flag_auto_save == true)
                                                                            // //         handleOnBlur(item.page_field_id, event.target.value)
                                                                            // // }}
                                                                            // />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "longtext") {
                                                    item.textLimit = (item.textLimit) ? item.textLimit : 1000;
                                                    return (
                                                        <Grid item xs={12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container dir="auto" direction=" column">
                                                                        <Grid item dir="auto" style={{ display: "flex" }}>
                                                                            <Controller
                                                                                dir="auto"
                                                                                control={control}
                                                                                rules={{
                                                                                    required: true,
                                                                                }}
                                                                                name={item.name}
                                                                                render={({ field: { value, onBlur, onChange, ...field } }) => (
                                                                                    <textarea
                                                                                        dir="auto"
                                                                                        style={{
                                                                                            '&::placeholder': {
                                                                                                opacity: '0.35',
                                                                                                color: "black",
                                                                                                border: "2px solid red"
                                                                                                // Change placeholder color here
                                                                                            },
                                                                                        }}
                                                                                        {...field}
                                                                                        // autoFocus
                                                                                        placeholder={item.placeholder ? item.placeholder : ""}
                                                                                        value={item.value || ""}
                                                                                        disabled={(item.is_disabled) ? true : false}
                                                                                        className={(item.class2 ? (item.class2 + " text_area_style") : "text_area_style") + " long_text_input formWiz-input-field"}
                                                                                        onChange={({ target: { value } }) => {
                                                                                            onChange(value);
                                                                                            if (item.max) {
                                                                                                if (value.length <= item.max) {
                                                                                                    handleOnChange(item, value)
                                                                                                }
                                                                                            } else {
                                                                                                handleOnChange(item, value)
                                                                                            }
                                                                                        }}
                                                                                        onBlur={({ target: { value } }) => {
                                                                                            onBlur(value);
                                                                                            // debugger;
                                                                                            if (formData.widget_flag_auto_save == true)
                                                                                                handleOnBlur(item, value)
                                                                                        }}
                                                                                        rows={item.minRows ? item.minRows : 3}
                                                                                        maxRows={item.maxRows ? item.maxRows : 10}
                                                                                    // startDecorator={(item.startDecorator) &&
                                                                                    //     (
                                                                                    //     <>
                                                                                    //         <Typography level="body-xs" sx={{ ml: 'auto' }}>
                                                                                    //             {item.value.length}/{item.textLimit?item.textLimit:1000}
                                                                                    //         </Typography>
                                                                                    //     </>
                                                                                    //     )}
                                                                                    // endDecorator={(item.endDecorator) &&
                                                                                    //     (
                                                                                    //     <>
                                                                                    //         <Typography level="body-xs" sx={{ ml: 'auto' }}>
                                                                                    //             {item.value.length}/{item.textLimit?item.textLimit:1000}
                                                                                    //         </Typography>
                                                                                    //     </>
                                                                                    //     )}

                                                                                    />
                                                                                    // <TextField
                                                                                    //     {...register(`${item.page_field_id}`)}
                                                                                    //     type="text"
                                                                                    //     className={item.class2 ? item.class2 : "long_text_input"}
                                                                                    //     value={item.value}
                                                                                    //     onChange={(e) => {
                                                                                    //         handleOnChange(item.page_field_id, e.target.value)
                                                                                    //         onChange(e)
                                                                                    //     }}
                                                                                    //     multiline
                                                                                    //     disabled={onchangeLoader && onchangeField == item.page_field_id ? true : false}
                                                                                    //     minRows={3}
                                                                                    //     maxRows={3}
                                                                                    //     name={item.page_field_id} onKeyDown={blockInvalidChar}
                                                                                    //     placeholder={item.placeholder}
                                                                                    // />
                                                                                )} />
                                                                        </Grid>

                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "chip" || item.type === "multiple chip") {
                                                    let gg = item;
                                                    var hideAddAllButton = false;
                                                    if (item.ai_suggestions_list) {
                                                        if (item.value.length >= item.ai_suggestions_list.length) {
                                                            var findUnmatchValue = item.ai_suggestions_list.map(rr => {
                                                                if (item.value.indexOf(rr.value) == -1) {
                                                                    return true;
                                                                } else {
                                                                    return false;
                                                                }
                                                            }).filter(rr => rr == true).length
                                                            if (findUnmatchValue == 0)
                                                                hideAddAllButton = true;
                                                        }

                                                    }
                                                    const filteredData = (item.ai_suggestions_list) ? (item.ai_suggestions_list.filter(ele => !item.value.includes(ele.value))) : [];
                                                    item.is_multiple = (item.is_multiple) ? item.is_multiple : true;
                                                    return (
                                                        <Grid item
                                                            sx={{ display: "flex", flexDirection: "column", alignSelf: "start !important" }} //added this to remove unnessary space below the component bec of grid item
                                                            xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}
                                                        >
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                                                                    <Grid item>
                                                                        {formLabelHTML(item)}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    sx={{
                                                                        display: "flex",
                                                                        // added this style to fix chip design issue
                                                                        maxWidth: "100% !important"
                                                                    }}>
                                                                    <Grid container direction="column"
                                                                        sx={{ flexWrap: "nowrap" }} //added this to improve the seo chip design
                                                                    >
                                                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                                            {/* <Grid container rowSpacing={(item.flag_ai_suggestion && (item.ai_suggestions_list && item.ai_suggestions_list.length>0)) && 1} direction="row"> */}
                                                                            <Grid container direction="row">
                                                                                {(item.is_multiple && !hideAddAllButton && item.is_disabled != true && item.flag_ai_suggestion && item.ai_suggestions_list && item.showAISuggestionList) &&
                                                                                    <>
                                                                                        <Grid item xs={10} margin={"5px 0"}>
                                                                                            <Stack direction="row" className="stack_chip">
                                                                                                <Grid container columnSpacing={1} rowSpacing={1} direction="row">
                                                                                                    {/* <Grid item lg={2} md={2} sm={2} xs={12}> */}
                                                                                                    <Grid item >
                                                                                                        {/* <div class="-lS-461"><div class="--Ef-462"></div><div class="--sg-668"></div></div> */}
                                                                                                        <Chip
                                                                                                            className="stack_inside_chip AddAll draw"
                                                                                                            key={123132}
                                                                                                            variant="outlined"
                                                                                                            clickable
                                                                                                            label={"Add All"}
                                                                                                            sx={{ width: "100%" }}
                                                                                                            onClick={() => {
                                                                                                                const allValuesArray = item.ai_suggestions_list.map(item => item.value);
                                                                                                                const combinedData1 = item.value.concat(allValuesArray);
                                                                                                                handleOnChange(item, [...new Set(combinedData1)])
                                                                                                            }}
                                                                                                        >
                                                                                                            Add All
                                                                                                        </Chip>
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        filteredData.map((option, index) => {
                                                                                                            return (
                                                                                                                <Grid item>
                                                                                                                    <Chip
                                                                                                                        className="stack_inside_chip btn-11"
                                                                                                                        sx={{
                                                                                                                            color: item.ai_suggestions_chip_color ? item.ai_suggestions_chip_color : "#6A097D",
                                                                                                                            border: "1px solid" + item.ai_suggestions_chip_color ? item.ai_suggestions_chip_color : "#6A097D",
                                                                                                                            width: "100%",

                                                                                                                        }}
                                                                                                                        key={index}
                                                                                                                        variant="outlined"
                                                                                                                        clickable
                                                                                                                        label={option.value}
                                                                                                                        onClick={() => {
                                                                                                                            var selectedVal = [option.value];
                                                                                                                            handleOnChange(item, [...item.value, selectedVal[0]])
                                                                                                                            // handleOnBlur("chipClicked")
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {option.value}
                                                                                                                    </Chip>
                                                                                                                </Grid>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Stack>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                                {(item.showAISuggestionList && item.is_disabled != true) &&
                                                                                    <>
                                                                                        <Grid item
                                                                                            sx={{ marginBottom: "10px" }} //added this to improve the seo chip design
                                                                                            xs={((item.ai_suggestions_list) && item.ai_suggestions_list.length > 0) ? ((hideAddAllButton) ? 12 : 2) : 12} justifyContent={"right"}>
                                                                                            <span className={aiLoader[item.name] ? "Loadercircle" : "circle"}>
                                                                                                <div className={aiLoader[item.name] && "flashLoader flashIcon"} >
                                                                                                    <FlashOnIcon style={{ color: "#6A097D", padding: "3px", verticalAlign: "middle" }}
                                                                                                        onClick={() => {
                                                                                                            var fieldLoaderArr = { ...aiLoader }
                                                                                                            fieldLoaderArr[item.name] = true
                                                                                                            setAILoader(fieldLoaderArr)
                                                                                                            getAIsuggestionFunction(item.page_field_id)
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </>
                                                                                }

                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid item style={{ display: "flex", flexDirection: "column" }} className="multiple_chips_item" >
                                                                            {
                                                                                //  <CreatableSelect
                                                                                //     name={item.name}
                                                                                //     components={{DropdownIndicator: null}}
                                                                                //     {...register(`${item.name}`)}
                                                                                //     className="dropdown_label_widget_preview basic-single"
                                                                                //     classNamePrefix="select"
                                                                                //     disabled={onchangeLoader && onchangeField == item.page_field_id ? true : false}
                                                                                //     isLoading={aiLoader[item.name]?true:false}
                                                                                //     options={[]}
                                                                                //     isSearchable={true}
                                                                                //     menuIsOpen={false}
                                                                                //     is_disabled={(item.is_disabled)?true:false}
                                                                                //     isMulti={item.is_multiple?item.is_multiple:false}
                                                                                //     value={item.value?item.value.map(rr=>({'value':rr,'label':rr})):[]}
                                                                                //     onFocus={()=>{
                                                                                //         if(item.flag_ai_suggestion)
                                                                                //         {
                                                                                //             if(!item.ai_suggestions_list || item.ai_suggestions_list.length==0){
                                                                                //                 var fieldLoaderArr = {...aiLoader}
                                                                                //                 fieldLoaderArr[item.name]=true
                                                                                //                 setAILoader(fieldLoaderArr)
                                                                                //                 getAIsuggestionFunction(item.page_field_id)
                                                                                //             }

                                                                                //         }
                                                                                //     }}
                                                                                //     onChange={(e) => {
                                                                                //         // if(item.is_multiple){
                                                                                //             handleOnChange(item.page_field_id, e.map(rr=>(rr.value)))
                                                                                //         // }else{
                                                                                //         //     handleOnChange(item.page_field_id, e.label)
                                                                                //         // }
                                                                                //         // if (formData.widget_flag_auto_save == true) {
                                                                                //         //     handleOnBlur(item.page_field_id, true, e.target.value)
                                                                                //         // }
                                                                                //     }}
                                                                                // />
                                                                            }

                                                                            {<Autocomplete
                                                                                name={item.name}
                                                                                {...register(`${item.name}`)}
                                                                                multiple={(item.is_multiple) ? item.is_multiple : false}
                                                                                // className={(item.class2 ? item.class2 : "") + " multiple_chips__autocomplete formWiz-input-field"}
                                                                                className={(item.class2 ? item.class2 : "") + " modifiedAutocompleteStyle" + " chipMaxWidth"}

                                                                                id="tags-filled"
                                                                                options={[]}
                                                                                // sx={{
                                                                                //     '& input::placeholder': {
                                                                                //         color: '#DFE1E1', /* Change the placeholder text color */
                                                                                //         opacity: "1"
                                                                                //     },
                                                                                //     maxWidth: "100%"
                                                                                // }}
                                                                                clearOnBlur
                                                                                onBlur={(e, value) => {
                                                                                    let value1 = e.target.value
                                                                                    let valueToSend = value1 ? [value1] : []
                                                                                    handleOnBlurAutocomplete(item, valueToSend, item.is_multiple)
                                                                                    // handleOnChange(item, valueToSend)
                                                                                }}
                                                                                onInputChange={(e) => {
                                                                                    setUnsavedChipVal({
                                                                                        ...unsavedChipVal, [item.name]: e.target.value
                                                                                    });
                                                                                }}
                                                                                defaultValue={[]}
                                                                                ChipProps={{ deleteIcon: <CloseIcon />, style: { maxWidth: '100%' }, }}
                                                                                freeSolo
                                                                                // onBlur={() => {
                                                                                //     handleOnBlur("onblur")
                                                                                //     // item.showAISuggestionList=false;
                                                                                //     // console.log(fields);
                                                                                //     // item.showAISuggestionList=false;
                                                                                //     // fields.forEach(element => {
                                                                                //     //         if(item.){
                                                                                //     //             if(element['flag_ai_suggestion']){
                                                                                //     //                 element['showAISuggestionList'] = false
                                                                                //     //             }
                                                                                //     //         }
                                                                                //     //     });
                                                                                //     // }
                                                                                // }}
                                                                                onOpen={() => {
                                                                                    if (item.flag_ai_suggestion) {
                                                                                        if (!item.ai_suggestions_list || item.ai_suggestions_list.length == 0) {
                                                                                            item.showAISuggestionList = true;
                                                                                            var fieldLoaderArr = { ...aiLoader }
                                                                                            fieldLoaderArr[item.name] = true
                                                                                            setAILoader(fieldLoaderArr)
                                                                                            getAIsuggestionFunction(item.page_field_id)
                                                                                        }

                                                                                    }
                                                                                }}
                                                                                value={item.value}
                                                                                onChange={(e, value) => {
                                                                                    handleOnChange(item, value)
                                                                                }}
                                                                                disabled={(item.is_disabled) ? true : false}
                                                                                renderInput={(params) =>
                                                                                (
                                                                                    <TextField
                                                                                        className={"test"}
                                                                                        {...params}
                                                                                        placeholder={item.value.length ? "" : item.placeholder}
                                                                                        multiline
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.keyCode == 13) {
                                                                                                e.preventDefault()
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )
                                                                                }
                                                                            />}
                                                                            {unsavedChipVal && unsavedChipVal !== undefined && unsavedChipVal[item.name] && (unsavedChipVal[item.name]?.length > 0) && (item.value.length > 0 ? (unsavedChipVal[item.name] !== (item.value[item.value.length - 1])) : true) && (unsavedChipVal[item.name].trim() !== "") &&
                                                                                <div style={{ fontSize: "12px", fontWeight: "500", color: "#4C4A4D", marginTop: "3px" }}>Press Enter to Save {unsavedChipVal[item.name]}
                                                                                </div>
                                                                            }
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {errors[item.page_field_id] && <span className="show_error_in_form">{errors[item.page_field_id].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                else if (item.type === "searchableDropdown") {

                                                    item.is_multiple = (item.is_multiple) ? item.is_multiple : true;
                                                    return (
                                                        <Grid item
                                                            sx={{ display: "flex", flexDirection: "column", alignSelf: "start !important" }} //added this to remove unnessary space below the component bec of grid item
                                                            xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}
                                                        >
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                                                                    <Grid item>
                                                                        {formLabelHTML(item)}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    sx={{
                                                                        display: "flex",
                                                                        maxWidth: "100% !important"
                                                                    }}>
                                                                    <Grid container direction="column"
                                                                        sx={{ flexWrap: "nowrap" }}
                                                                    >
                                                                        <Grid item style={{ display: "flex", flexDirection: "column" }} className="multiple_chips_item" >
                                                                            {<Autocomplete
                                                                                name={item.name}
                                                                                value={item.value}
                                                                                getOptionLabel={(option) => option[item.optionLabel]}
                                                                                // {...register(`${item.name}`)}
                                                                                className={(item.class2 ? item.class2 : "") + " modifiedAutocompleteStyle" + " chipMaxWidth"}
                                                                                id="tags-filled"
                                                                                options={item.options}
                                                                                onChange={(e, value) => {
                                                                                    handleOnChange(item, value)
                                                                                }}
                                                                                disabled={(item.is_disabled) ? true : false}
                                                                                renderInput={(params) =>
                                                                                (
                                                                                    <TextField
                                                                                        className={"test"}
                                                                                        {...params}
                                                                                        placeholder={item.placeholder}
                                                                                        multiline
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.keyCode == 13) {
                                                                                                e.preventDefault()
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )
                                                                                }
                                                                            />}
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {errors[item.page_field_id] && <span className="show_error_in_form">{errors[item.page_field_id].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                else if (item.type === "hidden") {
                                                    return (
                                                        <Grid item xs={12} style={{ display: "none" }} >
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex" }}>
                                                                    <TextField
                                                                        {...register(`${item.name}`)}
                                                                        type="text"
                                                                        value={item.label}
                                                                        className={item.class2 ? item.class2 : " input_class_widget_preview"}
                                                                        name={item.name}
                                                                    // onBlur={(event) => {
                                                                    //     if (formData.widget_flag_auto_save == true)
                                                                    //         handleOnBlur(item.page_field_id, event.target.value)
                                                                    // }}
                                                                    // inputProps={{ style: { textTransform: 'capitalize' } }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "phone") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <PhoneInput
                                                                        {...register(item.name)}
                                                                        country={item.countryCode ? item.countryCode : "us"}
                                                                        countryCodeEditable={(item.countryCodeEditable) ? item.countryCodeEditable : false}
                                                                        placeholder={item.placeholder}
                                                                        className={item.class2 ? item.class2 : " formWiz-input-field"}
                                                                        name={item.name}
                                                                        value={item.value}
                                                                        enableSearch="true"
                                                                        error={Boolean(errors[item.name])}
                                                                        onChange={(value) => {
                                                                            var zerosReg = /[1-9]/g;
                                                                            if (zerosReg.test(value)) {
                                                                                handleOnChange(item, value)
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "number") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item style={{ display: "flex" }}>
                                                                    <NumericFormat
                                                                        {...register(`${item.page_field_id}`)}
                                                                        customInput={TextField}
                                                                        value={item.value}
                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                        prefix={item.prefix ? item.prefix : ""}
                                                                        suffix={item.suffix ? item.suffix : ""}
                                                                        allowNegative={item.allowNegative ? item.allowNegative : false}
                                                                        thousandSeparator={item.thousandSeparator ? item.thousandSeparator : ","}
                                                                        fixedDecimalScale={item.fixedDecimalScale ? item.fixedDecimalScale : false}
                                                                        className={item.class2 ? item.class2 : " input_class_widget_preview formWiz-input-field"}
                                                                        name={item.page_field_id}
                                                                        placeholder={item.placeholder}
                                                                        onValueChange={(values, sourceInfo) => {
                                                                            const { floatValue } = values;
                                                                            if (item.max) {
                                                                                if (floatValue < Number(item.max)) {
                                                                                    handleOnChange(item, values.value)
                                                                                }
                                                                            } else {
                                                                                handleOnChange(item, values.value)
                                                                            }
                                                                        }}
                                                                    />
                                                                    {/* <TextField
                                                                        {...register(`${item.page_field_id}`)}
                                                                        type="number"
                                                                        value={item.value}
                                                                        onChange={
                                                                            (e) => {
                                                                                // var zerosReg = /[1-9|]/g;
                                                                                // console.log(zerosReg.test(e.target.value));
                                                                                // if (zerosReg.test(e.target.value))
                                                                                {
                                                                                    handleOnChange(item,e.target.value)
                                                                                }
                                                                            }
                                                                        }
                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                        className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
                                                                        name={item.page_field_id}
                                                                        // onKeyDown={blockInvalidChar}
                                                                        placeholder={item.placeholder}
                                                                        helperText={item.helper_text ? item.helper_text : ""}
                                                                    /> */}
                                                                    {/* )} /> */}
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "colorPicker") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item style={{ display: "flex" }} className={"colorpickerTestDemo " + (item.class2 || "") + ((item.is_disabled == true || item.is_disabled == "true") ? " disableColorPicker" : "")}>
                                                                    <ColorPicker
                                                                        name='color'
                                                                        value={typeof item.value === 'string' ? item.value : ''}
                                                                        onChange={(event) => {
                                                                            if (item.is_disabled == true || item.is_disabled == "true") return;
                                                                            if (event.error) {
                                                                                handleOnChange(item, event.raw);
                                                                            }
                                                                            else if (event.css && event.css.backgroundColor) {
                                                                                handleOnChange(item, event.css.backgroundColor);
                                                                            } else if (event.target && event.target.value) {
                                                                                handleOnChange(item, event.target.value);
                                                                            } else {
                                                                                handleOnChange(item, event);
                                                                                return;
                                                                            }

                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "number format") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item style={{ display: "flex" }}>
                                                                    <PatternFormat
                                                                        {...register(`${item.page_field_id}`)}
                                                                        customInput={TextField}
                                                                        value={item.value}
                                                                        onValueChange={(values, sourceInfo) => {
                                                                            const { floatValue } = values;
                                                                            if (item.max) {
                                                                                if (floatValue < Number(item.max)) {
                                                                                    handleOnChange(item, values.value)
                                                                                }
                                                                            } else {
                                                                                handleOnChange(item, values.value)
                                                                            }
                                                                        }}
                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                        mask={item.mask ? item.mask : ""}
                                                                        format={item.format ? item.format : ""}
                                                                        className={item.class2 ? item.class2 : " input_class_widget_preview formWiz-input-field"}
                                                                        name={item.page_field_id}
                                                                        placeholder={item.placeholder}
                                                                    />
                                                                    {/* <TextField
                                                                        {...register(`${item.page_field_id}`)}
                                                                        type="number"
                                                                        value={item.value}
                                                                        onChange={
                                                                            (e) => {
                                                                                // var zerosReg = /[1-9|]/g;
                                                                                // console.log(zerosReg.test(e.target.value));
                                                                                // if (zerosReg.test(e.target.value))
                                                                                {
                                                                                    handleOnChange(item,e.target.value)
                                                                                }
                                                                            }
                                                                        }
                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                        className={item.class2 ? item.class2 : "input_class_widget_preview formWiz-input-field"}
                                                                        name={item.page_field_id}
                                                                        // onKeyDown={blockInvalidChar}
                                                                        placeholder={item.placeholder}
                                                                        helperText={item.helper_text ? item.helper_text : ""}
                                                                    /> */}
                                                                    {/* )} /> */}
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "checkbox") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item
                                                                // style={{ marginTop: "18px" }}
                                                                >
                                                                    <label className="radio_option_label">
                                                                        {item.value == "" ?
                                                                            <input {...register(`${item.name}`)}
                                                                                className="radio_option_input_widget_preview"
                                                                                type="checkbox"
                                                                                disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                                onChange={(e) => {
                                                                                    handleOnChange(item, e.target.checked)
                                                                                    // if (formData.widget_flag_auto_save == true) {
                                                                                    //     handleOnBlur(item.page_field_id, e.target.checked)
                                                                                    // }
                                                                                }}
                                                                            // onBlur={(event) => {
                                                                            //     if (formData.widget_flag_auto_save == true)
                                                                            //         handleOnBlur(item.page_field_id, event.target.checked)
                                                                            // }}
                                                                            /> : <input {...register(`${item.name}`)}
                                                                                className="radio_option_input_widget_preview"
                                                                                type="checkbox"
                                                                                onChange={(e) => {
                                                                                    handleOnChange(item, e.target.checked)
                                                                                    // if (formData.widget_flag_auto_save == true) {
                                                                                    //     handleOnBlur(item.page_field_id, e.target.checked)
                                                                                    // }
                                                                                }}
                                                                                disabled={onchangeLoader && onchangeField == item.page_field_id ? true : false}
                                                                            // onBlur={(event) => {
                                                                            //     console.log("checkbox event", event);
                                                                            //     if (formData.widget_flag_auto_save == true)
                                                                            //         handleOnBlur(item.page_field_id, event.target.checked)
                                                                            // }}
                                                                            />}
                                                                        {item.terms_and_conditions_link ? <a href={item.terms_and_conditions_link} >{item.label}</a> : item.label}
                                                                        &nbsp;
                                                                        <span style={{ color: "red" }}>{(item.required) ? '*' : ''}</span>
                                                                        {item.tooltip &&
                                                                            <>
                                                                                <Tooltip title={item.tooltip} className="formWiz-tooltipLabel" placement="top-start">
                                                                                    <InfoOutlinedIcon />
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                        {onchangeLoader && onchangeField == item.page_field_id ? <CircularProgress style={{ width: "15px", height: "15px", padding: "3px" }} /> : ""}
                                                                    </label>
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "multiple checkbox") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item style={{ display: "flex", flexDirection: "column" }}>
                                                                    {item.options.map((ele) => {
                                                                        return <>
                                                                            <label className="radio_option_label">
                                                                                <input {...register(`${item.name}`)}
                                                                                    className="radio_option_input_widget_preview"
                                                                                    type="checkbox"
                                                                                    disabled={onchangeLoader && onchangeField == item.page_field_id && item.value == ele.field_option_value ? true : false}
                                                                                    value={ele.field_option_value}
                                                                                    checked={item.value == ele.field_option_data_id ? true : false}
                                                                                    onChange={(e) => {
                                                                                        handleOnChange(item, e.target.checked, ele.field_option_data_id)
                                                                                    }}
                                                                                />
                                                                                {ele.field_option_value}
                                                                                {onchangeLoader && onchangeField == item.page_field_id && item.value == ele.field_option_value ? <CircularProgress style={{ width: "15px", height: "15px", padding: "3px" }} /> : ""}
                                                                            </label>
                                                                        </>
                                                                    })}
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "dropdown1") {
                                                    let option = (item.options.length) ? item.options.map(rr => ({
                                                        'label': rr.field_option_value,
                                                        'value': rr.field_option_data_id
                                                    })) : []
                                                    if (item.ai_suggestions_list) {
                                                        let aiSuggestArr = item.ai_suggestions_list.map(ele => ({
                                                            'label': ele.value,
                                                            'value': ele.id
                                                        }))
                                                        option = [...option, ...aiSuggestArr]
                                                    }
                                                    let value = (item.value) ? item.options.filter(rr => (rr.field_option_flag == true))[0] : []
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                                                                    <Grid item>
                                                                        {formLabelHTML(item)}
                                                                    </Grid>
                                                                    {(item.flag_ai_suggestion == true) &&
                                                                        <Grid item>
                                                                            <span className="circle" style={{ cursor: "pointer", display: "flex", alignItems: "space", marginLeft: "8px", border: "1px solid #6a097d", borderRadius: "50%" }}>
                                                                                <FlashOnIcon className={aiLoader[item.name] && "border"} style={{ color: "#6a097d", padding: "5px", height: "18px", width: "18px" }}
                                                                                    onClick={() => {
                                                                                        if (item.showAISuggestionList) {
                                                                                            var fieldLoaderArr = { ...aiLoader }
                                                                                            fieldLoaderArr[item.name] = true
                                                                                            setAILoader(fieldLoaderArr)
                                                                                            getAIsuggestionFunction(item.page_field_id)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item style={{ display: "flex" }}>

                                                                            <Select
                                                                                name={item.name}
                                                                                {...register(`${item.name}`)}
                                                                                className="dropdown_label_widget_preview basic-single"
                                                                                classNamePrefix="select"
                                                                                disabled={onchangeLoader && onchangeField == item.page_field_id ? true : false}
                                                                                isLoading={false}
                                                                                options={option}
                                                                                isSearchable={true}
                                                                                isMulti={item.is_multiple ? item.is_multiple : false}
                                                                                value={value}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: 0,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary25: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "primary",
                                                                                        primary: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "primary",
                                                                                    },
                                                                                })}
                                                                                // isLoading={true}
                                                                                // value={item.options.map((ele) => {
                                                                                //     if (ele.field_option_flag == true) {
                                                                                //         return ele.field_option_data_id
                                                                                //     }
                                                                                // })}
                                                                                onChange={(e) => {
                                                                                    handleOnChange(item.page_field_id, true, e.label)
                                                                                    // if (formData.widget_flag_auto_save == true) {
                                                                                    //     handleOnBlur(item.page_field_id, true, e.target.value)
                                                                                    // }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        {/* <Grid item style={{ display: "flex" }}>
                                                                                <select
                                                                                    name={item.name}
                                                                                    {...register(`${item.name}`)}
                                                                                    value={item.options.map((ele) => {
                                                                                        if (ele.field_option_flag == true) {
                                                                                            return ele.field_option_data_id
                                                                                        }
                                                                                    })}
                                                                                    disabled={onchangeLoader && onchangeField == item.page_field_id ? true : false}
                                                                                    className="dropdown_label_widget_preview"
                                                                                    onChange={(e) => {
                                                                                        handleOnChange(item.page_field_id, true, e.target.value)
                                                                                        if (formData.widget_flag_auto_save == true) {
                                                                                            handleOnBlur(item.page_field_id, e.target.value, e.target.value)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <option value="" className="dropdown_option_placeholder">{item.placeholder}</option>
                                                                                    {item.options.map((ele, ind) => {
                                                                                        return <option className="dropdown_options" value={ele.field_option_data_id}>{ele.field_option_value}</option>
                                                                                    })}
                                                                                </select>
                                                                            </Grid> */}
                                                                        <Grid item>
                                                                            {errors[`${item.name}`] && <span className="show_error_in_form">{errors[`${item.name}`].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "dropdown") {
                                                    //please change the item type dropdown to  CREATABLE DROPDOWN
                                                    // console.log("item", { ...item })
                                                    let option = (item.options.length) ? item.options.map(rr => ({
                                                        'label': rr.field_option_value,
                                                        'value': rr.field_option_value
                                                    })) : []

                                                    let value = undefined;

                                                    if (item.value && item.value.length) {
                                                        if (item.is_multiple) {
                                                            value = item.value.map(ele => ({
                                                                'label': ele,
                                                                'value': ele
                                                            }))
                                                        } else {
                                                            value = {
                                                                'label': item.value,
                                                                'value': item.value
                                                            }
                                                        }
                                                    }
                                                    // console.log("value-----", value);
                                                    // item.value = []
                                                    // if (item.is_multiple) {
                                                    //     value = (item.value) ? item.options.filter(rr => (item.value.indexOf(rr.field_option_value) > -1)) : [];
                                                    // } else {
                                                    //     value = (item.value) ? item.options.filter(rr => (rr.field_option_value == item.value)) : [];
                                                    // }
                                                    // value = value.map(ele => ({
                                                    //     'label': ele.field_option_value,
                                                    //     'value': ele.field_option_value
                                                    // }))
                                                    item.is_valid_new_option = item.is_valid_new_option == true ? true : false
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                                                                    <Grid item>
                                                                        {formLabelHTML(item)}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item style={{ display: "flex" }}>
                                                                            <CreatableSelect
                                                                                dir="auto"
                                                                                name={item.name}
                                                                                {...register(item.name)}
                                                                                isValidNewOption={() => item.is_valid_new_option}
                                                                                // className={item.class2?item.class2:"" + "dropdown_label_widget_preview basic-single"}
                                                                                className={(item.class2 ? item.class2 : "") + " formWiz-input-field"}
                                                                                classNamePrefix="select"
                                                                                is_disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                                isLoading={(item.isLoading || aiLoader[item.name]) ? true : false}
                                                                                options={option}
                                                                                placeholder={(item.placeholder) ? item.placeholder : ""}
                                                                                isSearchable={(item.is_searchable) ? item.is_searchable : true}
                                                                                isMulti={item.is_multiple ? item.is_multiple : false}
                                                                                // components={{ MultiValue: customMultiValue } }
                                                                                components={{ MultiValueRemove }}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: 0,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary25: (formData.widget_theme_color) ? formData.widget_theme_color : '#c7daf3',
                                                                                        primary: (formData.widget_theme_color) ? formData.widget_theme_color : '#c7daf3',
                                                                                    },
                                                                                })}
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    // console.log("onchange in dropdown", e);
                                                                                    if (item.is_multiple) {
                                                                                        handleOnChange(item, e.map(rr => (rr.value)))
                                                                                    } else {
                                                                                        handleOnChange(item, e.label)
                                                                                    }
                                                                                }}
                                                                                aria-multiline={true}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "date") {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Grid container direction="column">
                                                                <Grid item xs={12}>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container direction="column">
                                                                        <Grid item xs={12}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs} className="date_picker_container_class_preview_">
                                                                                <DemoContainer components={['DatePicker']}>
                                                                                    <DateTimePicker
                                                                                        {...register(`${item.name}`)}
                                                                                        // defaultValue={defaultDate[0].field_option_value == "NONE" ? "" : defaultDate[0].field_option_value == "CURRENT" ? dayjs(new Date()) : ""}
                                                                                        className="date_picker_class_preview"
                                                                                        // format={formatDate[0].field_option_value}
                                                                                        // onBlur={(event) => {
                                                                                        //     console.log("date event blur", event);
                                                                                        //     handleOnBlur(item.page_field_id, event)
                                                                                        // }}
                                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                                        onChange={(newValue) => {
                                                                                            setValue(`${item.name}`, newValue.$d)
                                                                                            handleOnChange(item.page_field_id, newValue.$d)
                                                                                            // if (formData.widget_flag_auto_save == true) {
                                                                                            //     handleOnBlur(item.page_field_id, newValue.$d)
                                                                                            // }
                                                                                        }}
                                                                                        ampm={true}
                                                                                    />
                                                                                </DemoContainer>
                                                                            </LocalizationProvider>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type == "file") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item className="file_preview_input" style={{ pointerEvents: onchangeLoader && onchangeField == item.page_field_id ? "none" : "all" }}>
                                                                    {item.is_avatar ?
                                                                        <>
                                                                            <Grid container style={{ marginTop: "5px", flexWrap: "nowrap" }}>
                                                                                <Grid item sx={{ marginRight: "20px" }} className="avatar_file_upload" onMouseEnter={() => { setHoverImage(-1) }} onMouseLeave={() => { setHoverImage(0) }}>
                                                                                    <img src={item.avatar_Image ? item.avatar_Image : "https://api.filemaster.io/api/storage/file?file_id=2516&flag_info=false"} style={{ height: "46px", width: "46px", border: item.avatar_Image ? "1px solid #e9bdf2" : "0px", borderRadius: "50%", objectFit: "contain" }} />
                                                                                    {item.avatar_Image && hoverImage == -1 ? <span class="close-btn" onClick={(e) => uploadFileHandler("", item.page_field_id, "RemoveFile")}>
                                                                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clip-path="url(#clip0_5934_6772)">
                                                                                                <rect width="15" height="15" rx="7.5" fill="white" />
                                                                                                <path d="M6.95812 0.647614C3.90246 0.896637 1.36242 3.14664 0.764765 6.13785C0.483515 7.54996 0.638788 8.96207 1.22473 10.3039C1.87805 11.798 3.18176 13.1046 4.68762 13.7697C7.005 14.7951 9.62707 14.4962 11.6573 12.9728C12.1905 12.5714 12.8263 11.8976 13.2071 11.3322C14.757 9.0148 14.757 5.99722 13.2101 3.6857C12.9347 3.27261 12.7296 3.02652 12.3224 2.62222C11.6368 1.94254 10.9308 1.47672 10.0548 1.12515C9.38683 0.855622 8.71301 0.700348 7.96887 0.644684C7.52941 0.612458 7.38586 0.612458 6.95812 0.647614ZM5.53723 4.71988C5.63391 4.76382 5.91223 5.02164 6.58312 5.69254L7.50012 6.60367L8.42883 5.68082C9.46008 4.65543 9.48937 4.63199 9.79113 4.66714C10.1896 4.71402 10.4386 5.12418 10.298 5.49918C10.254 5.61636 10.0958 5.79214 9.31945 6.56851L8.3966 7.50015L9.31945 8.42886C10.0958 9.20816 10.254 9.38394 10.298 9.50113C10.4386 9.87613 10.1896 10.2863 9.79113 10.3332C9.48937 10.3683 9.46008 10.3449 8.42883 9.31949L7.50012 8.39664L6.58312 9.30777C5.97668 9.91715 5.63391 10.2365 5.56066 10.2687C5.32336 10.3654 5.00109 10.3039 4.84582 10.131C4.68762 9.95816 4.63781 9.66519 4.73156 9.43961C4.76379 9.36636 5.08312 9.02359 5.6925 8.41422L6.60656 7.50015L5.6925 6.58316C5.0216 5.91226 4.76379 5.63394 4.71984 5.53726C4.60851 5.28824 4.65246 5.04214 4.84875 4.84586C5.04211 4.65543 5.29113 4.60855 5.53723 4.71988Z" fill="#A5A5A5" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_5934_6772">
                                                                                                    <rect width="15" height="15" rx="7.5" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                    </span> : ""}
                                                                                </Grid>
                                                                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                                                                    <Grid container className="file-inputs-avatar">
                                                                                        <input
                                                                                            id="image"
                                                                                            accept='image/*'
                                                                                            type="file"
                                                                                            className="file-inputs-avatar"
                                                                                            onChange={(e) => {
                                                                                                uploadFileHandler(e.target.files, item.page_field_id)
                                                                                            }}
                                                                                        >
                                                                                        </input>
                                                                                        <button className="file-inputs-avatar" style={{ color: "#4C4A4D", boxShadow: "none !important" }}>
                                                                                            <FileUploadOutlinedIcon />
                                                                                            <span>
                                                                                                {item.placeholder}
                                                                                            </span>
                                                                                        </button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <FileUpload
                                                                            // saveData={(file_data) => {
                                                                            //     submitGoogleId = [];
                                                                            //     file_data.map((g_id) => {
                                                                            //         submitGoogleId.push(g_id.google_storage_id)
                                                                            //     })
                                                                            //     setGoogleIdFinal(file_data)
                                                                            //     setValue(item.name, submitGoogleId)
                                                                            //     // if (formData.widget_flag_auto_save == true && submitGoogleId.length)
                                                                            //     // if (submitGoogleId.length)
                                                                            //     {
                                                                            //         handleOnChange(item, submitGoogleId)
                                                                            //         props.uploadFileHandler(file_data)
                                                                            //     }
                                                                            // }}
                                                                            // final_g_id={submitGoogleId}
                                                                            fileDetails={item}
                                                                            // fileOnChange={submitGoogleId}
                                                                            // fileFlag={checkFormSubmit}
                                                                            filesFromParent={filesInParent}
                                                                            progressFromParent={progressInParent}
                                                                            removeFile={(google_ID) => { removeFile(google_ID, item.page_field_id) }}
                                                                            fileUploadhandler={(event) => uploadFileHandler(event, item.page_field_id)}
                                                                            fileType={item.fileType ? item.fileType : ".pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .rtf, .zip, .mp3, .mp4, .wma, .mpg, .flv, .avi, .jpg, .jpeg, .png, .gif"}
                                                                        />
                                                                    }
                                                                </Grid>

                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>);
                                                }
                                                else if (item.type === "radio") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item className="option_margin_class">
                                                                    <Grid container className="option_direction_class">
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            defaultValue={item.value}
                                                                            className={item.direction_column ? "radiogroupColumn_class" : "radiogroupRow_class"}
                                                                        >
                                                                            {item.options.map((ele, ind) => {
                                                                                return <Grid item className="option_space_class" >
                                                                                    <FormControlLabel {...register(item.name)}
                                                                                        value={ele.field_option_value}
                                                                                        className="radio_option_label_widget_preview"
                                                                                        control={<Radio />}
                                                                                        disabled={item.is_disabled ? true : (onchangeLoader && onchangeField == item.page_field_id ? true : false)}
                                                                                        label={ele.field_option_value}
                                                                                        sx={{
                                                                                            color: (pageThemeData.widget_theme_color) ? pageThemeData.widget_theme_color : "black",
                                                                                            '&.Mui-checked': {
                                                                                                color: "red",
                                                                                            },
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            handleOnChange(item, ele.field_option_value)
                                                                                            // if (formData.widget_flag_auto_save == true) {
                                                                                            //     handleOnBlur(item.page_field_id, e.target.checked, ele.field_option_data_id)
                                                                                            // }
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            })}
                                                                        </RadioGroup>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item marginTop={0.4}>
                                                                    {item.helper_text &&
                                                                        <Typography className="helper_text_input">
                                                                            {item.helper_text}
                                                                        </Typography>
                                                                    }
                                                                    {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                </Grid>
                                                                {/* <Grid item>
                                                                        {item.options.map((opt, i) => {
                                                                            return (
                                                                                <Grid container className={item.class2}>
                                                                                    <Grid item>
                                                                                        <label >
                                                                                            <input  {...register(`${item.name}`)} type="radio" value={opt.field_option_value} />
                                                                                            {opt.field_option_value}
                                                                                        </label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        })}
                                                                    </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "submit") {
                                                    return (
                                                        formData.widget_flag_hide_button == false ?
                                                            <Grid item xs={12} className={item.class1 ? item.class1 : "" + " widget_preview_form_save_item"} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                                <Button
                                                                    sx={{
                                                                        "&:hover": {
                                                                            background: item.buttonBackgroundColor ? item.buttonBackgroundColor : "#4C4A4D" + ' !important',
                                                                        },
                                                                        color: item.buttonFontColor ? item.buttonFontColor : '#fff' + ' !important',
                                                                        border: item.buttonBorder ? item.buttonBorder : '1px solid #fff' + ' !important',
                                                                        justifyContent: (item.buttonJustifyContent ? item.buttonJustifyContent : (pageThemeData.page_theme_font_size ? pageThemeData.page_theme_font_size : "start") + "px !important"),
                                                                        fontStyle: (item.buttonFontStyle ? item.buttonFontStyle : (pageThemeData.page_theme_font_style ? pageThemeData.page_theme_font_style : "normal") + "px !important"),
                                                                        fontSize: (item.buttonFontSize ? item.buttonFontSize : (pageThemeData.page_theme_font_size ? pageThemeData.page_theme_font_size : "13") + "px !important"),
                                                                        fontFamily: (pageThemeData.page_theme_font_type ? pageThemeData.page_theme_font_type : "Arial, Helvetica, sans-serif") + " !important",
                                                                        fontWeight: (item.buttonFontWeight ? item.buttonFontWeight : (pageThemeData.page_theme_font_weight ? pageThemeData.page_theme_font_weight : "regular") + "px !important"),
                                                                        textDecoration: (item.buttonTextDecoration ? item.buttonTextDecoration : "auto") + " !important",
                                                                        background: item.buttonBackgroundColor ? item.buttonBackgroundColor : "#4C4A4D" + ' !important',
                                                                        width: item.buttonWidth ? item.buttonWidth : '100%' + ' !important',
                                                                        height: item.buttonHeight ? item.buttonHeight : '40px' + ' !important',
                                                                        textTransform: item.textTransform ? item.textTransform : 'capitalize' + ' !important',
                                                                        margin: item.marginButton ? item.marginButton : '',
                                                                        padding: item.paddingButton ? item.paddingButton : '',
                                                                    }}
                                                                    // startIcon={item.startIcon?item.startIcon:""}
                                                                    startIcon={<Icon>{item.startIcon}</Icon>}
                                                                    endIcon={<Icon>{item.endIcon}</Icon>}
                                                                    type="submit"
                                                                >
                                                                    {item.label}
                                                                </Button>
                                                            </Grid>
                                                            : ""
                                                    );
                                                }
                                                else if (item.type === "recaptcha") {
                                                    return (
                                                        <Grid item xs={item.col ? item.col : 12}>
                                                            <Grid container direction="column" className={item.container_class_recaptcha ? item.container_class_recaptcha : ""} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                                <Grid item className={item.class1}>
                                                                    <ReCAPTCHA
                                                                        {...register(item.name)}
                                                                        sitekey={item.reCAPTCHAsitekey ? item.reCAPTCHAsitekey : "6Le4A_IgAAAAAHnXX3ExvO1BTACDIPbwP7ds0vAM"}
                                                                        name={item.name}
                                                                        className={item.class2}
                                                                        ref={recaptchaRef}
                                                                        onChange={(e) => {
                                                                            setreCAPTCHADetails({ showreCAPTCHAError: (e) ? true : false })
                                                                            handleOnChange(item, e)
                                                                            // if (formData.widget_flag_auto_save == true) {
                                                                            //     handleOnBlur(item.page_field_id, e.target.checked, ele.field_option_data_id)
                                                                            // }
                                                                        }}
                                                                    />
                                                                    {/* {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>} */}
                                                                    {reCAPTCHADetails.showreCAPTCHAError == "error" && <span className="show_error_in_form">Please verify you are a human</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "rating") {
                                                    return (
                                                        <Grid item xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item style={{ display: "flex", flexDirection: "column" }}>
                                                                            <RatingLikert type="rating" ratingData={item} />
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "selection matrix") {
                                                    return (
                                                        <Grid item xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item style={{ width: "100%", minWidth: "100%" }}>
                                                                    <Grid container direction="column" style={{ width: "100%", minWidth: "100%" }}>
                                                                        <Grid item style={{ display: "flex", flexDirection: "column", marginTop: "5px", width: "100%", minWidth: "100%" }}>
                                                                            <SelectionMatrix />
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "select button") {
                                                    return (
                                                        <Grid item xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item>
                                                                            <Box display="flex" alignItems="center" justifyContent="space-around">
                                                                                {item.options.length && item.options.map((opt) => {

                                                                                    return (<Grid item
                                                                                        key={opt.field_option_data_id}
                                                                                        onClick={() => handleOnChange(item, opt.field_option_value)}
                                                                                        className={(item.value === opt.field_option_value ? "selectedScaleRatingClass" : "unSelectedScaleRatingClass") + " " + "scaleRatingClass"}
                                                                                    >
                                                                                        {opt.field_option_value}
                                                                                    </Grid>)
                                                                                })}
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                else if (item.type === "opinion scale") {
                                                    return (
                                                        <Grid item xs={12} md={item.col ? item.col : 12} margin={pageThemeData.page_theme_row_spacing ? pageThemeData.page_theme_row_spacing : "2px 0 0 0"}>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    {formLabelHTML(item)}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                                                                            <RatingLikert type="opinion scale" ratingData={item} />
                                                                        </Grid>
                                                                        <Grid item marginTop={0.4}>
                                                                            {item.helper_text &&
                                                                                <Typography className="helper_text_input">
                                                                                    {item.helper_text}
                                                                                </Typography>
                                                                            }
                                                                            {errors[item.name] && <span className="show_error_in_form">{errors[item.name].message}</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            }
                                            )}

                                        </Grid>
                                    </form>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}


export default GlobalForm;