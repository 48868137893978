import { Breadcrumbs, Button, Divider, Grid, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/template.module.scss'
import templateStyles from '../../../assets/styles/componentStyles/templatemessages.module.scss'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import AddIcon from '@mui/icons-material/Add'
import createPersonaIcon from "../../../assets/createPersona.png";
import globalStyles from '../../../assets/styles/global.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import agent from '../../../services/api'
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext'
import { formatDate } from '../../../utils/constants'
import EmptyState from '../../global/emptyState'
import emptyStateWhatsappTemplate from "../../../assets/images/whatsapp/emptyWhatsappTemplateState.png";
import GlobalButton from '../../utility/globalButton/button'
import IconsColor from '../../utility/iconsColor'
import { getTemplateDetails } from '../../../models/axiosModel'

const TemplateMessages = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [templateList, setTemplateList] = useState<getTemplateDetails[] | null>(null)
    const { setFlagLoading } = useContext(UserContext) as UserContextPayload;
    const { getIntegrationList, integrationList, setIntegrationDetails, integrationDetails } = useContext(IntegrationContext) as IntegrationContextPayload;
    let id = searchParams.get("id");
    const innerHeight = (window.innerHeight - 170);

    const getTemplateList = async (meta_integration_whatsapp_id: number) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getWhatsappTemplateList.get(meta_integration_whatsapp_id)
        if (response.status) {
            setTemplateList(response.data)
        } else {

        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    useLayoutEffect(() => {
        (async () => {
            if (!integrationList) {
                await getIntegrationList()
            }
        })()
    }, []);
    useEffect(() => {
        if (searchParams.has('id')) {
            if (integrationList && integrationList.length > 0) {
                let id = searchParams.get('id')
                let integration = integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name.toLowerCase() == "whatsapp").find((integration: any) => integration.meta_integration_id == id)
                if (integration && integration.meta_integration_whatsapp_data) {
                    let whatsapp_meta_integration_id = integration.meta_integration_whatsapp_data.meta_integration_whatsapp_id
                    getTemplateList(whatsapp_meta_integration_id)
                    setIntegrationDetails(integration)
                } else {
                    navigate('/channels/viewintegration/whatsapp')
                }
            }
        } else {
            setIntegrationDetails(null)
        }
    }, [integrationList])
    const columns = [
        { field: 'whatsapp_template_name', headerName: 'Template Name', width: 200 },
        { field: 'whatsapp_template_category', headerName: 'Category', width: 150 },
        { field: 'whatsapp_language', headerName: 'Language', width: 150 },
        { field: 'whatsapp_template_status', headerName: 'Status', width: 150 },
        { field: 'updated_at_timestamp', headerName: 'Last Updated', width: 180 },
    ];


    const handleRowClick = (template_id: any) => {
        navigate(`/channels/viewintegration/whatsapp/templateinfo?id=${id}&template_id=${template_id}`)
    }

    const renderTableRow = (row: any) => {
        return <>
            <TableRow className={templateStyles.tableBodyRow} onClick={() => { handleRowClick(row.whatsapp_template_id) }} style={{ cursor: "pointer" }}>
                <TableCell sx={{ minWidth: "200px" }} className={templateStyles.tableBodyCell}>{row.whatsapp_template_data.whatsapp_template_name}</TableCell>
                <TableCell sx={{ minWidth: "150px" }} className={templateStyles.tableBodyCell}>{row.whatsapp_template_data.whatsapp_template_category_name}</TableCell>
                <TableCell sx={{ minWidth: "150px" }} className={templateStyles.tableBodyCell}>{row.whatsapp_template_data.whatsapp_language_data.whatsapp_language_name}</TableCell>
                <TableCell sx={{ minWidth: "150px" }} className={templateStyles.tableBodyCell}><span className={row.whatsapp_template_metadata.meta_whatsapp_template_status_name === "Approved" ? globalStyles.approvedButtonStyle : row.whatsapp_template_metadata.meta_whatsapp_template_status_name === "Pending" ? globalStyles.pendingButtonStyle : globalStyles.rejectedButtonStyle}>{row.whatsapp_template_metadata.meta_whatsapp_template_status_name}</span></TableCell>
                <TableCell sx={{ minWidth: "180px" }} className={templateStyles.tableBodyCell}>{formatDate(row.updated_at_timestamp)}</TableCell>
            </TableRow>
        </>
    }
    const handleCreateWhatsappTemplate = () => {
        const integration_id = searchParams.get('id')
        navigate(`/channels/viewintegration/whatsapp/templateinfo?id=${integration_id}`)
    }

    return (
        <>
            <Grid item xs={12} paddingBlock={2}>
                {templateList && templateList.length > 0 ?
                    <>
                        <TableContainer className={templateStyles.tableContainer}>
                            <Table aria-label='simple table' className={templateStyles.tableDesign}>
                                <TableHead className={templateStyles.tableHead}>
                                    <TableRow className={templateStyles.tableHeadRow}>
                                        {columns?.map(column =>
                                            <TableCell key={column.field} className={templateStyles.tableHeadCell} sx={{ minWidth: column.width, cursor: "pointer" }}>
                                                <Grid container direction={"row"} justifyContent={"space-between"} flexWrap={"nowrap"} alignItems={"center"}>
                                                    <Grid item>
                                                        {column.headerName}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={templateStyles.tableBody}>
                                    {(templateList && templateList.length > 0) ? templateList.map((row: any) => renderTableRow(row)) :
                                        <TableRow className={templateStyles.tableBodyRow}>
                                            <TableCell colSpan={5} sx={{ textAlign: "center", padding: "16px 16px 32px" }}>
                                                <span style={{ position: "fixed" }}>No Data Available</span>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <>
                        {templateList &&
                            <EmptyState
                                height={`${innerHeight}px !important`}
                                maxHeight={`${innerHeight}px !important`}
                                pageIcon={<img alt="chatbot" src={emptyStateWhatsappTemplate} style={{ width: "165px", height: "auto" }} />}
                                pageSubText={"No Template Created Yet! Start With a New Template"}
                                primaryActionBtn={<GlobalButton
                                    buttonText={"Template"}
                                    sx={{width: "130px"}}
                                    className='primaryButtonStyle'
                                    onClick={handleCreateWhatsappTemplate}
                                    startIcon={<IconsColor iconName="plus" width="15px" height="15px" fillColor="#fff" />}
                                />}
                            />}
                    </>
                }
            </Grid>
        </>
    )
}

export default TemplateMessages
