import { Divider, Grid, Typography } from '@mui/material'
import React from 'react';
import mappingStyles from './../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss';
import uploadGif from "../../../assets/images/campaignContact/upload.gif";
import GlobalButton from '../../utility/globalButton/button';
import { useNavigate } from 'react-router-dom';

interface RunCampaignPopupProps {
    setFlagProcessingCampaign: React.Dispatch<React.SetStateAction<boolean>>,
}

const RunCampaignPopup: React.FC<RunCampaignPopupProps> = ({ setFlagProcessingCampaign }) => {

    const navigate = useNavigate();

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={mappingStyles.popupTitleTypography}>Execute Campaign</Typography>
                        </Grid>

                        <Divider className={mappingStyles.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={mappingStyles.popupBodyGridContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container textAlign={"center"} gap={"12px"} justifyContent={"center"} alignItems={"center"}>
                                        <Grid item xs={12}>
                                            <img src={uploadGif} alt="upload gif" height={"120px"} width={"120px"} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>
                                                Please wait while we execute your campaign. It may take a few minutes to execute your campaign.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} mt={2} mb={2}>
                                            <GlobalButton
                                                buttonText="Okay"
                                                sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                                className='primaryButtonStyle'
                                                onClick={() => { setFlagProcessingCampaign(false); navigate('/whatsappcampaign'); }}
                                            >
                                            </GlobalButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RunCampaignPopup
