import { Button, Grid, InputLabel, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import styles from '../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalTextField from '../../utility/globalTextfield';
import Required from '../../utility/required';
import connectorstyle from '../../../assets/styles/componentStyles/dataconnector.module.scss'
import validator from 'validator';

interface addMemberPayload {
    user_email: string,
    private_user_first_name: string,
    private_user_last_name: string,
}
interface AddMemberProps {
    flagKeyExistError: boolean;
    setFlagKeyExistError: Dispatch<SetStateAction<boolean>>;
    addMemberPayload: addMemberPayload
    onChangePayload: (value: string, keyName: string) => void,
    handleShareInvitation: (objName: string) => void,
    cancel: (objName: string) => void
}
const AddMember = (props: AddMemberProps) => {

    const getSaveBtnDisableVal = () => {
        if (props.addMemberPayload.user_email.trim() !== "" && props.addMemberPayload.private_user_first_name.trim() !== "" && props.addMemberPayload.private_user_last_name.trim() !== "") {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <Grid container className={`${styles.dataConnectorPopupSpacing}`}>
                <Grid item xs={12} className={`${styles.dataConnectorFormPopup} ${styles.dataConnectorPopupBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography
                                component='span'
                                className={styles.dataConnectorHeaderText}
                            >
                                Member Details
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.dataconnectorHeaderDivider} />
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} className={connectorstyle.createFormField} gap={"20px"} p={1}>
                                        <Grid item>
                                            <Grid container direction={{xs: "column", sm:"row"}} gap={2} flexWrap={"nowrap"}>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container direction={"column"}>
                                                        <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>First Name</InputLabel><Required /></Grid>
                                                        <Grid item><GlobalTextField
                                                            value={props.addMemberPayload.private_user_first_name}
                                                            onChange={(event) => { props.onChangePayload(event.target.value, 'private_user_first_name') }}
                                                            placeholder='Enter first Name' /></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container direction={"column"}>
                                                        <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Last Name</InputLabel><Required /></Grid>
                                                        <Grid item>
                                                            <GlobalTextField placeholder='Enter Last Name'
                                                                value={props.addMemberPayload.private_user_last_name}
                                                                onChange={(event) => {
                                                                    props.onChangePayload(event.target.value, 'private_user_last_name')
                                                                }} /></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Email Address</InputLabel><Required /></Grid>
                                                <Grid item><GlobalTextField
                                                    placeholder='Enter Email Address'
                                                    value={props.addMemberPayload.user_email}
                                                    onChange={(event) => {
                                                        props.setFlagKeyExistError(false);
                                                        props.onChangePayload(event.target.value, 'user_email')
                                                    }} /></Grid>
                                                {props.flagKeyExistError &&
                                                    !validator.isEmail(props.addMemberPayload.user_email.trim()) &&
                                                    <span className={globalStyles.validationText}>Please Enter a valid Email Address.</span>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: "0px" }} mt={2}>
                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>

                                <Grid item xs={12} className={`${connectorstyle.globalHeadRightSubItem} `}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "center" }} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbCancelButton}
                                                onClick={() => { props.cancel('reqHeader') }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ marginLeft: "15px" }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                fullWidth
                                                disabled={getSaveBtnDisableVal()}
                                                className={connectorstyle.cbSaveButton}
                                                onClick={() => { props.handleShareInvitation('reqHeader') }}
                                                sx={{ opacity: getSaveBtnDisableVal() ? "0.5" : "" }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddMember
