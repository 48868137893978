import { Autocomplete, Button, Chip, Grid, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';
import MixedModeTagify from '../../global/mixedTag';
import Required from '../../utility/required';
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import chipStyles from './../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import globalStyles from './../../../assets/styles/global.module.scss'
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import GlobalPopUp from '../../utility/globalPopUp';
import { globalSlackIntegration } from '../../../models/axiosModel';
import SlackConfigChannels from './slackConfigChannels';

interface masterValueInterface {
    id: number,
    value: string
}

interface contentType {
    field_id: number,
    field_name: string
}

interface emailFormInterface {
    customTemplatePromptActionData: String,
    setCustomTemplatePromptActionData: React.Dispatch<React.SetStateAction<String>>,
    handleChangeSlackConfigPayload: (field: string, value: string | number) => void,
    masterValues: [] | masterValueInterface[],
    setMasterValues: React.Dispatch<React.SetStateAction<[] | masterValueInterface[]>>,
}

const SlackConfigForm = (props: emailFormInterface) => {
    const {customTemplatePromptActionData, setCustomTemplatePromptActionData, handleChangeSlackConfigPayload, masterValues, setMasterValues} = props;
    const {slackConfigPayload, editIntegrationData, setSlackConfigPayload, channelIntegrationData, setChannelIntegrationData} = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload;
    const { slackIntegrations, setSlackIntegrations } = useContext(IntegrationContext) as IntegrationContextPayload;
    const [flagSelectChannelPopup, setFlagSelectChannelPopup] = useState(false);
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');

    const [searchParams] = useSearchParams();

    const handleSelectChannel = () => {
        setFlagSelectChannelPopup(true);
    }

    useEffect(() => {
        if(slackIntegrations) {
            setChannelIntegrationData(slackIntegrations.find((data) => data.slack_integration_id === slackConfigPayload.slack_integration_id) || null)
        }
    }, [slackIntegrations])

    return (
        <Grid container gap={2}>

            <Grid item xs={12} lg={8} xl={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Slack Integration <Required /></span>
                    </Grid>
                    <Grid item xs={9.5}>
                        <Autocomplete
                            disabled={!editIntegrationData}
                            value={slackIntegrations?.find((integ) => integ.slack_integration_id == Number(slackConfigPayload.slack_integration_id)) ?? null}
                            options={slackIntegrations || []}
                            getOptionLabel={(option) => `${option.slack_team_name} - ${option.slack_user_real_name}`}
                            renderInput={(params) => <TextField {...params} placeholder='Select Team Name' />}
                            onChange={(event, value) => {setSlackConfigPayload({...slackConfigPayload, slack_channel_id: ""}); handleChangeSlackConfigPayload('slack_integration_id', value?.slack_integration_id || ""); setChannelIntegrationData(value);}}
                            className={`${globalStyles.autocompleteParaphrasing} ${styles.methodAutoComplete}`}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={8} xl={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Select Channel <Required /></span>
                    </Grid>
                    <Grid item xs={9.5}>
                        <Autocomplete
                            options={[]}
                            freeSolo={false}
                            multiple={true}
                            open={false}
                            disableClearable
                            sx={{
                                '& .MuiAutocomplete-endAdornment': {
                                    display: "none"
                                }
                            }}
                            className={`${chipStyles.autocompleteValues} ${styles.enumChipStyle}`}
                            value={
                                slackConfigPayload.slack_channel_id.split(',').map((id) => {
                                    const channel = slackIntegrations?.flatMap((integration) => integration.slack_channels).find((channel) => channel.slack_channel_id.toString() == id);
                                    return channel?.slack_channel_name || ""
                                }).filter((name) => name)
                            }
                            disabled={!editIntegrationData}
                            renderTags={(value: any, getTagProps: any) =>
                                value.map((option: any, index: any) => (
                                    <Chip
                                        key={index}
                                        label={option}
                                        deleteIcon={<CloseIcon sx={{display: "none"}}/>}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    placeholder="Select Channel"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                <InputAdornment position="end">
                                                    <Button 
                                                        className={styles.selectChannelButtonStyle} 
                                                        disabled={!slackConfigPayload.slack_integration_id || !editIntegrationData}
                                                        onClick={() => handleSelectChannel()}
                                                    >
                                                        Select
                                                    </Button>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={8} xl={6} sx={{width: "100%"}}>
                <Grid container direction={{xs: "column", md:"row"}} gap={1} flexWrap={"nowrap"}>
                    <Grid item xs={2.5}>
                        <span className={styles.formHeadingFontStyle}>Raw Content <Required /></span>
                    </Grid>
                    <Grid item xs={9.5} className={styles.emailContentLabelSpacing}>
                        <MixedModeTagify
                            // key={new Date().getTime()}
                            value={customTemplatePromptActionData}
                            setValue={setCustomTemplatePromptActionData}
                            whiteListOptions={masterValues}
                            editIntegrationData={editIntegrationData}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {flagSelectChannelPopup &&
                <GlobalPopUp
                    paperMinWidth={flagSmallScreen ? "92%" : "85%"}
                    paperMaxwidth={flagSmallScreen ? "92%" : "85%"}
                    height={"100%"}
                    open={flagSelectChannelPopup}
                    onClose={() => { setFlagSelectChannelPopup(false) }}
                    flagShowCloseIcon={true}
                    titleAlignment={"start"}
                    handleClose={() => { setFlagSelectChannelPopup(false) }}
                >
                    <SlackConfigChannels 
                        channelIntegrationData={channelIntegrationData} 
                        handleChangeSlackConfigPayload={handleChangeSlackConfigPayload}
                        setFlagSelectChannelPopup={setFlagSelectChannelPopup}
                    />
                </GlobalPopUp>
            }
        </Grid>
    )
}

export default SlackConfigForm