import './App.css';
import { Fragment } from 'react';
import Routes from './routes/routes';
import { UserProvider } from './context/userContext';
import { NotifyExtentionProvider } from './context/notifyExtentionContext';
import { ChatbotProvider } from './context/chatbotContext';
import { GenericDataProvider } from './context/genericDataContext';
import { MetaProvider } from './context/metaContext';
import { IntegrationProvider } from './context/integrationContext';
import { CallAnalysisProvider } from './context/callAnalysisContext';
import { CalendlyProvider } from './context/calendlyIntegrationContext';
import { LeadIntegrationProvider } from './context/leadIntegrationContext';
import { MessageFormatProvider } from './context/messageFormatContext';
import { WhatsappCampaignProvider } from './context/whatsappCampaignContext';
import { ContactProvider } from './context/contactContext';
function App() {
  return (
    <Fragment>
      <CalendlyProvider>
        <CallAnalysisProvider>
          <WhatsappCampaignProvider>
            <ContactProvider>
              <MessageFormatProvider>
                <LeadIntegrationProvider>
                  <IntegrationProvider>
                    <MetaProvider>
                      <GenericDataProvider>
                        <ChatbotProvider>
                          <NotifyExtentionProvider>
                            <UserProvider>
                              <Routes />
                            </UserProvider>
                          </NotifyExtentionProvider>
                        </ChatbotProvider>
                      </GenericDataProvider>
                    </MetaProvider>
                  </IntegrationProvider>
                </LeadIntegrationProvider>
              </MessageFormatProvider>
            </ContactProvider>
          </WhatsappCampaignProvider>
        </CallAnalysisProvider>
      </CalendlyProvider>
    </Fragment>
  );
}

export default App;
