import { Divider, Grid } from '@mui/material';
import React from 'react'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import GlobalButton from '../../utility/globalButton/button';
import LeadGenerationIntegrationCards from './leadGenerationIntegrationCards';
import { createLeadGenerationPayloadModel, getLeadIntegrationTypeDataResponse } from '../../../models/axiosModel';

const AddNewLeadIntegration = () => {
    
    return (
        <Grid container direction={"column"} gap={"20px"} className={styles.popupGlobalPadding}>
            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                {/* <span className={styles.popupTitleStyle}>{flagUpdate ? "Update Agent" : "Add New Agent"}</span> */}
                <span className={styles.popupTitleStyle}>Add New Export</span>
            </Grid>
            <Grid item>
                <Divider className={styles.popupDividerStyle} />
            </Grid>
            <Grid item>
                <Grid container direction={"column"} gap={2}>
                    <Grid item>
                        <Grid container direction={"column"} gap={1}>
                            <LeadGenerationIntegrationCards />
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>
        </Grid>
    )
}

export default AddNewLeadIntegration
