import { Grid } from '@mui/material'
import React from 'react'
import { createWhatsappTemplatePayload, getTemplateDetails } from '../../../models/axiosModel'
import styles from './../../../assets/styles/componentStyles/templatemessages.module.scss'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import ListIcon from '@mui/icons-material/List';
import CallIcon from '@mui/icons-material/Call';

interface TemplatePreviewInterface {
    metaInformation: getTemplateDetails,
}

const CampaignTemplatePreview = (props: TemplatePreviewInterface) => {

    const messageText = (type: string) => {
        let data, template: string = "";
        if (type.toLowerCase() === "body") {
            data = props.metaInformation.whatsapp_template_data.whatsapp_template_body
            template = data.whatsapp_template_body_value
            // Replacing placeholders with sample values
            data.whatsapp_template_body_variable_data.forEach((variable: any) => {
                const placeholder = variable.whatsapp_template_dynamic_variable_key
                const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
                template = template.replaceAll(placeholder, sampleValue);
            });
        } else if(type.toLowerCase() === "header"){
            data = props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0];
            template = data.whatsapp_template_header_data_value
            // Replacing placeholders with sample values
            data.whatsapp_template_header_variable_data.forEach((variable: any) => {
                const placeholder = variable.whatsapp_template_dynamic_variable_key
                const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
                template = template.replaceAll(placeholder, sampleValue);
            });
        }else{
            data = props.metaInformation.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data[0];
            template = data?.whatsapp_template_footer_data_value || ""
            // Replacing placeholders with sample values
            // data.whatsapp_template_header_variable_data.forEach((variable: any) => {
            //     const placeholder = variable.whatsapp_template_dynamic_variable_key
            //     const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
            //     template = template.replaceAll(placeholder, sampleValue);
            // });
        }

        template = template.replace(/\*(.*?)\*/g, '<b>$1</b>');  // Bold
        template = template.replace(/_(.*?)_/g, '<i>$1</i>');    // Italic
        template = template.replace(/~(.*?)~/g, '<s>$1</s>');    // Strikethrough
        template = template.replace(/```(.*?)```/g, '<code>$1</code>');  // Monospace
        template = template.replace(/\\n/g, '<br>');

        return template;
    }


    const getSubButtonIcon = (whatsapp_template_button_sub_type_id: number | string) => {
        let subId = Number(whatsapp_template_button_sub_type_id)
        switch (subId) {
            case 3:
                return <OpenInNewOutlinedIcon style={{ display: "flex" }} className={styles.previewSubButton} />;
            case 4:
                return <CallIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case 2:
                return <ReplyIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case 1:
                return <ReplyIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case null:
                return ""
            default:
                return <ListIcon style={{ display: "flex" }} className={styles.previewSubButton} />
        }

    }

    const getSubButtonText = (subBtnData: any) => {
        let fieldVal = subBtnData.whatsapp_template_button_sub_type_field_data.filter((field: any) => field.field_name == "Button Text")[0]

        switch (fieldVal.field_static_value_flag) {
            case true:
                return fieldVal.field_static_value
            case false:
                return fieldVal.whatsapp_template_button_sub_type_field_value
            default:
                return ""
        }
    }

    return (
        <Grid item padding={"0px"} className={styles.previewSectionCardBlock}>
            <Grid container direction={"column"}>
                {props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name.toLowerCase() == "image" &&
                    <Grid item>
                        <img src={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} style={{width: "100%", maxHeight: "300px"}}/>
                    </Grid>
                }
                {props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name.toLowerCase() == "video" &&
                    <Grid item>
                        <video key={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} style={{ width: "100%", maxHeight: "300px" }} controls autoPlay className='video-tag'>
                            <source src={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} type="video/mp4" />
                        </video>
                    </Grid>
                }
                {props.metaInformation?.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() == "text" &&
                    <Grid item sx={{padding: "14px 10px 5px 10px"}}>
                        <pre
                            className={styles.preTextStyle}
                            dangerouslySetInnerHTML={{ __html: messageText("header") }}
                        />
                    </Grid>
                }
                <Grid item sx={{padding: "14px 10px"}}>
                    <pre
                        className={styles.preTextStyle}
                        dangerouslySetInnerHTML={{ __html: messageText("body") }}
                    />
                </Grid> 
                {props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer && props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data?.[0]?.whatsapp_template_footer_data_value && props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data?.[0].whatsapp_template_footer_data_value?.length > 0 && 
                        <Grid item sx={{padding: "5px 10px 14px 10px"}}>
                        <pre
                            className={styles.preTextStyle}
                            dangerouslySetInnerHTML={{ __html: messageText("footer") }}
                        />
                    </Grid> 
                }
                {
                    props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).length <= 3 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).map((subBtnData: any) => {
                        return <>
                            <Grid item paddingBlock={"12px"} style={{ borderTop: "1px solid #CFCFCF", color: "#6A097D", cursor: "pointer" }}>
                                <Grid container justifyContent={"center"} gap={1} alignItems={"center"}>
                                    <Grid item>
                                        {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                    </Grid>
                                    <Grid item>
                                        {getSubButtonText(subBtnData)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    })
                }
                {
                    props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).length > 3 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).splice(0, 2).map((subBtnData: any) => {
                        return <>
                            <Grid item paddingBlock={"12px"} style={{ borderTop: "1px solid #CFCFCF", color: "#6A097D", cursor: "pointer" }}>
                                <Grid container justifyContent={"center"} gap={1} alignItems={"center"}>
                                    <Grid item>
                                        {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                    </Grid>
                                    <Grid item>
                                        {getSubButtonText(subBtnData)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    })
                }
            </Grid>
        </Grid>
    )
}

export default CampaignTemplatePreview
