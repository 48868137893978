export const initialAnalyticsData = {
    total_conversations: "15,213",
    unique_users: "164",
    csat_score: 82,
    action_data: [
        {
            action_id: 1, 
            action_name: "Doctor Appointment Booking",
            number_of_conversation: 6487
        },
        {
            action_id: 2, 
            action_name: "Send feedback to Slack",
            number_of_conversation: 4419
        },
        {
            action_id: 3, 
            action_name: "Export leads to Google Spreadsheet",
            number_of_conversation: 4307
        },
       
    ],
    purpose_data: [
        {
            purpose_id: 1, 
            purpose_name: "Lead Generation",
            number_of_conversation: 3810
        },
        {
            purpose_id: 2, 
            purpose_name: "Resolving customer query",
            number_of_conversation: 5998
        },
        {
            purpose_id: 3, 
            purpose_name: "Feedback collection",
            number_of_conversation: 5405
        },
    ],
    channel_data: [
        {
            channel_id: 1, 
            channel_name: "Website",
            number_of_conversation: 4680
        },
        {
            channel_id: 2, 
            channel_name: "WhatsApp Business",
            number_of_conversation: 3990
        },
        {
            channel_id: 3, 
            channel_name: "Facebook Messenger",
            number_of_conversation: 6543
        },
    ],
    channel_csat_score: [
        {
            channel_id: 1, 
            channel_name: "Website",
            csat_score: 78
        },
        {
            channel_id: 2, 
            channel_name: "WhatsApp Business",
            csat_score: 89
        },
        {
            channel_id: 3, 
            channel_name: "Facebook Messenger",
            csat_score: 79
        },
       
    ],
    purpose_csat_score: [
        {
            purpose_id: 1, 
            purpose_name: "Lead Generation",
            csat_score: 92
        },
        {
            purpose_id: 2, 
            purpose_name: "Resolving customer query",
            csat_score: 78
        },
        {
            purpose_id: 3, 
            purpose_name: "Feedback collection",
            csat_score: 76
        },
    ]
}