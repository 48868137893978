import { Box, Button, Grid, IconButton, InputAdornment, SwipeableDrawer, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styles from "../../../assets/styles/componentStyles/chatbotStyles/staticChatbotPreview.module.scss";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import userIcon from "../../../assets/images/chatbot/userAvatar.png";
import botIcon from "../../../assets/images/chatbot/chatbotProfileAvtar.png";
import chatbotStyle from "../../../assets/styles/componentStyles/chatbotStyles/chatbotPreview.module.scss"
import conversation from "../../../assets/images/chatbot/conversation.png";
import globalStyles from "../../../assets/styles/global.module.scss";
import IconsColor from '../../utility/iconsColor';
import GlobalPopUp from '../../utility/globalPopUp';
import { getChatbotMessageTag, getTag, hexToRgba, isJsonString } from '../../../utils/constants';
import powerdByLogo from "../../../assets/images/global/Chronox Logo.svg";
import chronoxWhiteLogo from "../../../assets/images/global/Chronox Logo - White.svg";
import Carousel from '../../utility/globalCarousel/carousel';
import InsuranceCard from '../chats/chatWidgetComponents/insuranceCard';
import ChatbotDetails from './chatbotDetails';

interface ChatbotPreviewSectionInterface {
    flagSmallScreen: boolean,
    openSwipableDrawer: any,
    setOpenSwipableDrawer: any,
    toggleDrawer: any
}



function StaticThemeChatbotPreview(props: ChatbotPreviewSectionInterface) {
    const {
        flagSmallScreen,
        openSwipableDrawer,
        setOpenSwipableDrawer,
        toggleDrawer
    } = props

    const {
        chatBotDetails,
        currentActiveChatbotThread,
        chatbotThemeDetails,
        setChatbotThemeDetails,
        setCurrentActiveChatbotThread
    } = useContext(ChatbotContext) as ChatbotContextPayload;
    console.log("chatbotThemeDetails", chatbotThemeDetails)
    // useEffect(() => {
    //     const object = {
    //         message: "Hey! Welcome to Test. How can I help you?",
    //         flag_user: false,
    //         flag_loader: false,
    //         anchorEl: null,
    //         flagOpenMenu: false,
    //         chatbot_thread_id: 0
    //     };
    //     // setCurrentActiveChatbotThread((prevState: any) => [...prevState, object]);
    //     setCurrentActiveChatbotThread([...currentActiveChatbotThread, object]);

    // }, [currentActiveChatbotThread])


    // export interface chatbotThreadDataInterface {
    //     chatbot_thread_id: number | string,
    //     chatbot_thread_value: string,
    //     chatbot_thread_seq: string,
    //     flag_user_message: boolean,
    //     chatbot_route_source: string,
    //     receiver_phone_number: string,
    //     timestamp: string,
    //     ip_address: string,
    //     message_type?: string,
    //     message_qoute_id?: messageQuoteObj,
    //     contact_information?: contactInfo[],
    //     reaction_code?: string
    // }

    const [showImagePreview, setShowImagePreview] = useState(false)
    const [previewImageDetails, setPreviewImageDetails] = useState({
        value: ""
    })
    const [iframeKey, setIframeKey] = useState(0);
    const smallScreenHeight = useMediaQuery('(max-height : 750px) and (min-height : 0px)');

    useEffect(() => {
        if (chatBotDetails?.chatbot_initial_message) {
            const isInitialMessage = isJsonString(chatBotDetails?.chatbot_initial_message) && typeof JSON.parse(chatBotDetails?.chatbot_initial_message) == "object"
            if (isInitialMessage) {
                const parseInitialMessage = JSON.parse(chatBotDetails?.chatbot_initial_message.replace("```json", "").replace("```", ""))
                const parseInitialMessageArray = Array.isArray(JSON.parse(chatBotDetails?.chatbot_initial_message.replace("```json", "").replace("```", "")))
                if (parseInitialMessageArray) {
                    let threads = parseInitialMessage.map((thrData: any) => (
                        {
                            "chatbot_thread_id": 1298,
                            "chatbot_thread_value": JSON.stringify(thrData),
                            "chatbot_thread_seq": "1",
                            "flag_user_message": false,
                            "chatbot_route_source": "Website",
                            "receiver_phone_number": "",
                            "timestamp": "2024-03-27 05:46:33",
                            "ip_address": "110.227.201.47",
                            "message_type": "text",
                            "reaction_code": "",
                            "chatbot_theme": chatBotDetails?.chatbot_theme
                        })
                    )
                    let userThread = {
                        "chatbot_thread_id": 1299,
                        "chatbot_thread_value": `${chatBotDetails?.chatbot_initial_message ? "hi" : ""}`,
                        "chatbot_thread_seq": "1",
                        "flag_user_message": true,
                        "chatbot_route_source": "Website",
                        "receiver_phone_number": "",
                        "timestamp": "2024-03-27 05:46:33",
                        "ip_address": "110.227.201.47",
                        "message_type": "text",
                        "reaction_code": "",
                        "chatbot_theme": chatBotDetails?.chatbot_theme
                    }

                    setCurrentActiveChatbotThread([...threads, userThread]);
                }
                else {
                    setCurrentActiveChatbotThread([
                        {
                            "chatbot_thread_id": 1298,
                            "chatbot_thread_value": `${chatBotDetails?.chatbot_initial_message ? chatBotDetails?.chatbot_initial_message : ""}`,
                            "chatbot_thread_seq": "1",
                            "flag_user_message": false,
                            "chatbot_route_source": "Website",
                            "receiver_phone_number": "",
                            "timestamp": "2024-03-27 05:46:33",
                            "ip_address": "110.227.201.47",
                            "message_type": "text",
                            "reaction_code": "",
                            "chatbot_theme": chatBotDetails?.chatbot_theme
                        },
                        {
                            "chatbot_thread_id": 1299,
                            "chatbot_thread_value": `${chatBotDetails?.chatbot_initial_message ? "hi" : ""}`,
                            "chatbot_thread_seq": "1",
                            "flag_user_message": true,
                            "chatbot_route_source": "Website",
                            "receiver_phone_number": "",
                            "timestamp": "2024-03-27 05:46:33",
                            "ip_address": "110.227.201.47",
                            "message_type": "text",
                            "reaction_code": "",
                            "chatbot_theme": chatBotDetails?.chatbot_theme
                        }]
                    )
                }
            }
            else {
                setCurrentActiveChatbotThread([
                    {
                        "chatbot_thread_id": 1298,
                        "chatbot_thread_value": `${chatBotDetails?.chatbot_initial_message ? chatBotDetails?.chatbot_initial_message : ""}`,
                        "chatbot_thread_seq": "1",
                        "flag_user_message": false,
                        "chatbot_route_source": "Website",
                        "receiver_phone_number": "",
                        "timestamp": "2024-03-27 05:46:33",
                        "ip_address": "110.227.201.47",
                        "message_type": "text",
                        "reaction_code": "",
                        "chatbot_theme": chatBotDetails?.chatbot_theme
                    },
                    {
                        "chatbot_thread_id": 1299,
                        "chatbot_thread_value": `${chatBotDetails?.chatbot_initial_message ? "hi" : ""}`,
                        "chatbot_thread_seq": "1",
                        "flag_user_message": true,
                        "chatbot_route_source": "Website",
                        "receiver_phone_number": "",
                        "timestamp": "2024-03-27 05:46:33",
                        "ip_address": "110.227.201.47",
                        "message_type": "text",
                        "reaction_code": "",
                        "chatbot_theme": chatBotDetails?.chatbot_theme
                    }]
                )
            }
        }
        else {
            setCurrentActiveChatbotThread(null)
        }

    }, [chatBotDetails])

    const reloadIframe = () => {
        setIframeKey(prevKey => prevKey + 1); // Increment key to trigger reload
    };

    useEffect(() => {
        reloadIframe();
        setChatbotThemeDetails(chatBotDetails?.chatbot_theme)
    }, [chatBotDetails]);

    function convertDateTime(datetimeStr: string) {
        // Convert string to Date object
        var dtObject = new Date(datetimeStr);

        // Convert Date object to desired format
        var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: false, timeZone: 'UTC' };
        // @ts-ignore
        var formattedDateTime = dtObject.toLocaleString('en-US', options);
        return formattedDateTime;
    }


    return (
        <>
            {showImagePreview &&
                <GlobalPopUp
                    open={showImagePreview}
                    handleClose={() => { setShowImagePreview(false) }}
                    flagShowCloseIcon
                >
                    <img src={previewImageDetails.value} alt="imgLoaded" />
                </GlobalPopUp>

            }
            <Grid item xs={12} className={chatbotStyle.cbPreviewMainItem}>
                <Grid container direction={"column"} sx={{ position: "relative" }}>

                    {flagSmallScreen ?
                        (<>
                            <SwipeableDrawer
                                anchor="right"
                                open={openSwipableDrawer}
                                onClose={toggleDrawer(false)}
                                onOpen={toggleDrawer(true)}
                                PaperProps={{
                                    className: chatbotStyle.chatbotSwipeableDrawer,
                                    style: {
                                        overflow: 'auto',
                                    },
                                }}
                            >
                                <Grid container sx={{ padding: "0px" }} className={chatbotStyle.chatbotPreviewMainContainer}>
                                    <Grid item xs={12} className={chatbotStyle.chatbotPreviewMainItem}>
                                        <Grid container className={chatbotStyle.chatbotPreviewSubContainer}>
                                            <Grid item xs={12}
                                                className={styles.headerMobile}
                                            //  className={chatbotStyle.cbPreviewHeadMainItem}
                                            >
                                                <Grid container sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap" }}>
                                                    {/* <Grid item xs={2}></Grid> */}
                                                    <Grid item xs={11} sx={{ marginTop: "5px" }}>
                                                        <Grid container justifyContent={"space-between"}>
                                                            <>
                                                                <Grid item>
                                                                    <Typography component={'span'} className={chatbotStyle.cbPreviewHead}>
                                                                        Preview
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ textAlign: "end", position: "absolute", top: "12px", right: "10px", zIndex: "10000 !important" }}>
                                                        <img
                                                            src={previewIcon}
                                                            style={{ width: "23px", height: "auto", objectFit: "fill", verticalAlign: "middle", transform: "rotate(180deg)" }}
                                                            alt=""
                                                            onClick={() => { setOpenSwipableDrawer(false) }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={chatbotStyle.cbPreviewIframeItem}>
                                                <div
                                                    key={iframeKey}
                                                    style={{ minHeight: "calc(100vh - 220px) !important" }}
                                                    className={chatbotStyle.cbPreviewIframeParentDiv}
                                                >
                                                    <Box className={globalStyles.fullHeight}>
                                                        <Grid container
                                                            className={globalStyles.fullHeight}
                                                            sx={{
                                                                bgcolor: chatbotThemeDetails?.chatbot_back_ground_color ? chatbotThemeDetails?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
                                                                borderRadius: "15px",
                                                                position: "relative",
                                                                boxShadow: `0px 0px 5px 0px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : "#6A097D")}`,

                                                            }}
                                                        >
                                                            <Grid item xs={12}
                                                                className={globalStyles.fullHeight}
                                                                sx={{
                                                                    borderRadius: "15px"
                                                                }}>
                                                                <Grid container direction={"column"}
                                                                    justifyContent={"space-between"}
                                                                    className={`${globalStyles.fullHeight} ${globalStyles.containerFlexWrap}`}
                                                                    sx={{ overflow: "auto" }}>

                                                                    <Grid item xs={12}
                                                                        sx={{ maxHeight: "61px !important" }}
                                                                        className={styles.chatbotNavbarMainItem}>
                                                                        <Grid container
                                                                            sx={{
                                                                                bgcolor: chatbotThemeDetails?.chatbot_header_color ? chatbotThemeDetails?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#F7E9FF",
                                                                                p: "0px 10px",
                                                                                height: '60px',
                                                                                alignItems: "center",
                                                                                borderRadius: "15px 15px 0px 0px",
                                                                                border: 'none',
                                                                                direction: chatBotDetails ? chatBotDetails.chatbot_text_direction : "auto"
                                                                            }}
                                                                        >
                                                                            <Grid item xs={11}>
                                                                                <Grid container alignItems={"center"}>
                                                                                    <Grid item
                                                                                    // sx={{ margin: "0px 10px 0px 0px" }}
                                                                                    >
                                                                                        <img
                                                                                            src={chatBotDetails?.chatbot_logo_url ?
                                                                                                chatBotDetails.chatbot_logo_url : botIcon
                                                                                            }
                                                                                            alt="chatbot Icon"
                                                                                            style={{ height: "28px", width: "28px", objectFit: "fill", display: 'flex', borderRadius: "50%" }}
                                                                                        />
                                                                                    </Grid>

                                                                                    <Grid item sx={{ display: "flex" }}>
                                                                                        <Typography
                                                                                            component={'span'}
                                                                                            sx={{ fontWeight: 600, fontSize: "15px", display: "flex", color: chatbotThemeDetails?.chatbot_header_font_color ? chatbotThemeDetails?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#000000" }}
                                                                                        >
                                                                                            {/* {chatbotThemeDetails?.chatbot_name} */}
                                                                                            {chatBotDetails?.chatbot_name}
                                                                                            {/* Test Chatbot */}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                    <>
                                                                        <Grid item xs={12} className={globalStyles.fullHeight} >
                                                                            <Grid container id="messageSenderDiv" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "10px !important" }} overflow={"auto"}
                                                                                //   ref={containerRef} 
                                                                                // className={styles.chatbotChatMainSectionItem}
                                                                                // height={"calc(100vh - 475px)"} 
                                                                                className={styles.chatbotChatMainSectionItem}
                                                                                height={smallScreenHeight ? "335px" : "385px"}
                                                                            >

                                                                                {(currentActiveChatbotThread !== null && currentActiveChatbotThread.length > 0 && currentActiveChatbotThread[0].chatbot_thread_id && currentActiveChatbotThread[0].chatbot_thread_id !== "") ?


                                                                                    currentActiveChatbotThread?.length ? currentActiveChatbotThread?.map((thread) => {
                                                                                        const isBot = thread?.flag_user_message == false
                                                                                        return (
                                                                                            <>
                                                                                                {isBot ? (
                                                                                                    <>
                                                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "5px 0px" }}>
                                                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "start", flexWrap: "nowrap" }}>

                                                                                                                <Grid item sx={{ background: chatbotThemeDetails?.chatbot_back_ground_color ? chatbotThemeDetails?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF" }} className={styles.chatbotChatIconDiv} >
                                                                                                                    <img src={chatBotDetails?.chatbot_logo_url ? chatBotDetails.chatbot_logo_url : botIcon}
                                                                                                                        alt="chatbot Icona"
                                                                                                                        style={{ width: "30px", height: "30px", objectFit: 'fill', borderRadius: "50%" }} />
                                                                                                                </Grid>

                                                                                                                <Grid item
                                                                                                                    className={styles.parentbotMsgBoxStyle}
                                                                                                                    sx={{
                                                                                                                        direction: "initial",
                                                                                                                        marginLeft: "6px",
                                                                                                                        padding: "10px 10px",
                                                                                                                        borderRadius: chatBotDetails ? chatBotDetails.chatbot_text_direction == "rtl" ? "8px 0px 8px 8px" : "0px 8px 8px 8px" : "0px 8px 8px 8px",
                                                                                                                        // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                                                        fontSize: "15px",
                                                                                                                        fontWeight: "500",
                                                                                                                        width: "100%",
                                                                                                                        maxWidth: "calc(100% - 75px)",
                                                                                                                        backgroundColor: chatbotThemeDetails?.system_msg_bg_color ? chatbotThemeDetails?.system_msg_bg_color : chatBotDetails?.chatbot_theme.system_msg_bg_color ? chatBotDetails?.chatbot_theme.system_msg_bg_color : "#fff",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <pre
                                                                                                                        className={styles.botMsgBoxStyle}
                                                                                                                        style={{
                                                                                                                            color: chatbotThemeDetails?.system_msg_color ? chatbotThemeDetails?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "#000000",
                                                                                                                            overflow: "unset",
                                                                                                                            width: "100%",
                                                                                                                            // textWrap: "pretty",
                                                                                                                            whiteSpace: "normal",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            maxWidth: "unset",
                                                                                                                            background: "unset !important",
                                                                                                                        } as CSSProperties}
                                                                                                                    >
                                                                                                                        {getTag(thread, 'text')}
                                                                                                                        {/* {thread?.chatbot_thread_value} */}
                                                                                                                    </pre>
                                                                                                                    {/* <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)}</span> */}
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "5px 0px" }}>
                                                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                                                <Grid item
                                                                                                                    sx={{
                                                                                                                        marginRight: "6px",
                                                                                                                        padding: "8px 8px",
                                                                                                                        borderRadius: chatBotDetails ? chatBotDetails.chatbot_text_direction == "rtl" ? "8px 8px 8px 0px" : "8px 8px 0px 8px" : "8px 8px 0px 8px",
                                                                                                                        // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                                                        fontSize: "15px",
                                                                                                                        fontWeight: "500",
                                                                                                                        backgroundColor: chatbotThemeDetails?.user_msg_bg_color ? chatbotThemeDetails?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#F7E9FF",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography
                                                                                                                        sx={{
                                                                                                                            color: chatbotThemeDetails?.user_msg_color ? chatbotThemeDetails?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "#000000",
                                                                                                                            wordBreak: 'break-all',
                                                                                                                            fontSize: "14px",
                                                                                                                            fontWeight: "500"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {getTag(thread, "text")}
                                                                                                                        {/* {thread?.chatbot_thread_value} */}
                                                                                                                    </Typography>
                                                                                                                    {/* <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)}</span> */}

                                                                                                                </Grid>
                                                                                                                {/* <Grid item sx={{ marginLeft: "6px", alignSelf: "end" }}>
                                                                                                                    <img alt="User Icon" src={userIcon} style={{ width: "40px", height: "auto", objectFit: "fill", display: 'flex' }} />
                                                                                                                </Grid> */}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                )
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    }) : ""
                                                                                    :
                                                                                    <>
                                                                                        <Grid item className={globalStyles.fullHeight}>
                                                                                            <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                                <Grid item>
                                                                                                    <img src={conversation} alt="conversation" />
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`} sx={{ color: chatbotThemeDetails?.chatbot_header_color ? chatbotThemeDetails?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color }}>No Conversation here yet.</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xs={12} padding={"0px 10px"} sx={{ marginBottom: chatbotThemeDetails?.show_powered_by_chronox == false ? "20px !important" : "" }}>
                                                                            <Grid container
                                                                                sx={{ direction: chatBotDetails ? chatBotDetails.chatbot_text_direction : "auto" }}
                                                                                gap="10px" direction={"row"} flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                                                                                <Grid item xs={12} className={styles.chatbotPreviewMessageboxMainItem}
                                                                                // sx={{ marginBottom: chatbotThemeDetails.show_powered_by_chronox !== null || chatbotThemeDetails.show_powered_by_chronox ? "20px !important" : "" }}
                                                                                >
                                                                                    <Grid container
                                                                                        sx={{
                                                                                            background: chatbotThemeDetails?.chatbot_send_message_bg_color ? chatbotThemeDetails?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                                                                            border: `1px solid ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                            boxShadow: `0 1px 2px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`
                                                                                        }}
                                                                                        className={styles.chatbotPreviewMessageboxMainContainer}
                                                                                    >
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            className={styles.sendMsgTextField}
                                                                                            sx={{
                                                                                                border: 'none',
                                                                                                "& fieldset": {
                                                                                                    border: 'none',
                                                                                                },
                                                                                                '& .MuiInputBase-input': {
                                                                                                    color: chatbotThemeDetails?.chatbot_send_message_font_color ? `${chatbotThemeDetails?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                                                                                },
                                                                                                '& .Mui-disabled': {
                                                                                                    '-webkit-text-fill-color': chatbotThemeDetails?.chatbot_send_message_font_color ? `${chatbotThemeDetails?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                            placeholder="Type a message..."
                                                                                            disabled={true}
                                                                                            fullWidth
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="end" sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)" }}>
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item className={styles.chatbot_preview_arrow_item} sx={{
                                                                                    backgroundColor: chatbotThemeDetails?.chatbot_send_message_bg_color ? chatbotThemeDetails?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                                                                    rotate: chatBotDetails ? chatBotDetails.chatbot_text_direction === "rtl" ? "180deg" : "" : ""
                                                                                }}>
                                                                                    <IconButton
                                                                                        sx={{
                                                                                            border: `1px solid ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                            // boxShadow: `0px 0px 1px ${chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color}`,
                                                                                            borderRadius: '8px',
                                                                                            height: '100%',
                                                                                            width: '100%',
                                                                                            padding: '5px'
                                                                                        }}
                                                                                    >
                                                                                        <IconsColor
                                                                                            // style={{
                                                                                            //     opacity: ((input == "" || input == null || flagInputDisable) && (uploadedFiles.length == 0)) ? 0.3 : 1,
                                                                                            //     cursor: ((input == "" || input == null || flagInputDisable) && (uploadedFiles.length == 0)) ? "auto" : "pointer",
                                                                                            // }}
                                                                                            fillColor={chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color}
                                                                                            iconName="send"
                                                                                            width="18px"
                                                                                            height="18px"
                                                                                        // onClick={() => {
                                                                                        //     handleSend()
                                                                                        // }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {(chatBotDetails?.chatbot_theme.show_powered_by_chronox !== null ? chatBotDetails?.chatbot_theme.show_powered_by_chronox : true) &&
                                                                            <Grid item xs={12} sx={{ padding: '0px 10px', margin: '10px 10px 10px', direction: chatBotDetails?.chatbot_text_direction ?? "ltr" }}>
                                                                                <Typography
                                                                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: " 13px !important",
                                                                                            fontWeight: "500 !important",
                                                                                            color: "#989696 !important",
                                                                                            margin: "2px 10px 0px !important",
                                                                                            display: "flex !important",
                                                                                        }}
                                                                                        className="chat-poweredby-text">
                                                                                        Powered by
                                                                                    </Typography>
                                                                                    <a href={process.env.REACT_APP_CHRONOX_LANDING_PAGE_URL} target="_blank" style={{ cursor: 'pointer' }}>
                                                                                        <img
                                                                                            src={chatBotDetails?.chatbot_theme?.chatbot_theme_mode ? chatBotDetails?.chatbot_theme?.chatbot_theme_mode == "dark" ? chronoxWhiteLogo : powerdByLogo : powerdByLogo}
                                                                                            className="chat-powered-logo"
                                                                                            style={{
                                                                                                width: "100px",
                                                                                                height: "auto",
                                                                                                objectFit: "fill",
                                                                                                verticalAlign: "sub",
                                                                                            }}
                                                                                            alt="Chronox AI"
                                                                                        />
                                                                                    </a>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SwipeableDrawer>
                        </>
                        ) : (
                            <>
                                <Grid item xs={12}
                                    className={styles.header}
                                >
                                    <Grid container className={chatbotStyle.cbPreviewIframeItem} sx={{ alignItems: "center", position: "relative" }} justifyContent={"space-between"}>
                                        <Grid
                                            // item xs={8} 
                                            sx={{ alignItems: "center", display: "flex", justifyContent: "center", width: "100%" }}
                                            className={chatbotStyle.cbPreviewHeadItem} mb={1}>
                                            <Grid container gap="10px" justifyContent={"space-between"}
                                            >

                                                <>
                                                    <Grid item>
                                                        <Typography component={'span'} className={chatbotStyle.cbPreviewHead}>
                                                            Preview
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={chatbotStyle.cbPreviewIframeItem}>
                                    <div
                                        key={iframeKey}
                                        // style={{ minHeight: "calc(100vh - 320px)" }}
                                        style={{ minHeight: "calc(100vh - 220px) !important" }}
                                        className={chatbotStyle.cbPreviewIframeParentDiv}
                                    // dangerouslySetInnerHTML={{ __html: chatbotThemeDetails?.chatbot_iframe ? chatbotThemeDetails.chatbot_iframe : "" }}
                                    >
                                        <Box
                                        >
                                            <Grid container
                                                sx={{
                                                    bgcolor: chatbotThemeDetails?.chatbot_back_ground_color ? chatbotThemeDetails?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF",
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                    boxShadow: `0px 0px 5px 0px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : "#6A097D")}`,
                                                }}
                                            >
                                                <Grid item xs={12} sx={{
                                                    borderRadius: "15px"
                                                }}>
                                                    <Grid container sx={{ overflow: "hidden" }}>

                                                        <Grid item xs={12} className={styles.chatbotNavbarMainItem}>
                                                            <Grid container
                                                                sx={{
                                                                    bgcolor: chatbotThemeDetails?.chatbot_header_color ? chatbotThemeDetails?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#F7E9FF",
                                                                    p: "0px 10px",
                                                                    height: '60px',
                                                                    alignItems: "center",
                                                                    borderRadius: "15px 15px 0px 0px",
                                                                    border: 'none',
                                                                    direction: chatBotDetails ? chatBotDetails.chatbot_text_direction : "auto"
                                                                }}
                                                            >
                                                                <Grid item xs={11}>
                                                                    <Grid container alignItems={"center"} gap="10px">
                                                                        <Grid item
                                                                        //  sx={{ margin: "0px 10px 0px 0px" }}
                                                                        >
                                                                            <img
                                                                                src={chatBotDetails?.chatbot_logo_url ?
                                                                                    chatBotDetails.chatbot_logo_url : botIcon
                                                                                }
                                                                                alt="chatbot Icon"
                                                                                style={{ width: "28px", height: "28px", objectFit: "cover", display: 'flex', borderRadius: "50%" }}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item sx={{ display: "flex" }}>
                                                                            <Typography
                                                                                component={'span'}
                                                                                sx={{
                                                                                    fontWeight: 600,
                                                                                    fontSize: "15px",
                                                                                    display: "flex",
                                                                                    color: chatbotThemeDetails?.chatbot_header_font_color ? chatbotThemeDetails?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? chatBotDetails?.chatbot_theme.chatbot_header_font_color : "#000000"
                                                                                }}
                                                                            >
                                                                                {/* {chatbotThemeDetails?.chatbot_name} */}
                                                                                {chatBotDetails?.chatbot_name}
                                                                                {/* Test Chatbot */}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={1} sx={{ textAlign: "center" }}>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container id="messageSenderDiv" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "10px !important", direction: chatBotDetails ? chatBotDetails.chatbot_text_direction : "auto" }} overflow={"auto"}
                                                                    //   ref={containerRef} 
                                                                    className={styles.chatbotChatMainSectionItem}
                                                                    height={"calc(100vh - 320px)"}
                                                                >

                                                                    {(currentActiveChatbotThread !== null && currentActiveChatbotThread.length > 0 && currentActiveChatbotThread[0].chatbot_thread_id && currentActiveChatbotThread[0].chatbot_thread_id !== "") ?

                                                                        currentActiveChatbotThread?.length ? currentActiveChatbotThread?.map((thread) => {
                                                                            const isBot = thread?.flag_user_message == false
                                                                            // const isBot = !thread?.flag_user_message
                                                                            return (
                                                                                <>
                                                                                    {isBot ? (
                                                                                        <>
                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "5px 0px" }}>
                                                                                                <Grid container sx={{ alignItems: "center", justifyContent: "start", flexWrap: "nowrap" }}>

                                                                                                    <Grid item
                                                                                                        sx={{
                                                                                                            background: chatbotThemeDetails?.chatbot_back_ground_color ? chatbotThemeDetails?.chatbot_back_ground_color : chatBotDetails?.chatbot_theme.chatbot_back_ground_color ? chatBotDetails?.chatbot_theme.chatbot_back_ground_color : "#FFFFFF"
                                                                                                        }}
                                                                                                        className={styles.chatbotChatIconDiv}
                                                                                                    >
                                                                                                        <img src={chatBotDetails?.chatbot_logo_url ? chatBotDetails.chatbot_logo_url : botIcon}
                                                                                                            alt="chatbot Icona"
                                                                                                            style={{ width: "24px", height: "24px", objectFit: 'fill', borderRadius: "50%" }} />
                                                                                                    </Grid>

                                                                                                    <Grid item sx={{ maxWidth: "calc(100% - 75px)" }}>
                                                                                                        <Grid container direction={"column"}>
                                                                                                            <Grid item
                                                                                                                className={styles.parentbotMsgBoxStyle}
                                                                                                                sx={{
                                                                                                                    direction: "initial",
                                                                                                                    marginLeft: "6px",
                                                                                                                    padding: "10px 10px",
                                                                                                                    borderRadius: chatBotDetails ? chatBotDetails.chatbot_text_direction == "rtl" ? "8px 0px 8px 8px" : "0px 8px 8px 8px" : "0px 8px 8px 8px",
                                                                                                                    // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                                                    fontSize: "15px",
                                                                                                                    fontWeight: "500",
                                                                                                                    width: "100%",
                                                                                                                    maxWidth: "calc(100% - 75px)",
                                                                                                                    backgroundColor: chatbotThemeDetails?.system_msg_bg_color ? chatbotThemeDetails?.system_msg_bg_color : chatBotDetails?.chatbot_theme.system_msg_bg_color ? chatBotDetails?.chatbot_theme.system_msg_bg_color : "#fff",
                                                                                                                }}
                                                                                                            >
                                                                                                                <pre
                                                                                                                    className={styles.botMsgBoxStyle}
                                                                                                                    style={{
                                                                                                                        color: chatbotThemeDetails?.system_msg_color ? chatbotThemeDetails?.system_msg_color : chatBotDetails?.chatbot_theme.system_msg_color ? chatBotDetails?.chatbot_theme.system_msg_color : "#000000",
                                                                                                                        overflow: "unset",
                                                                                                                        width: "100%",
                                                                                                                        // textWrap: "pretty",
                                                                                                                        whiteSpace: "normal",
                                                                                                                        wordBreak: "break-word",
                                                                                                                        maxWidth: "unset",
                                                                                                                        background: "unset !important"
                                                                                                                    } as CSSProperties}
                                                                                                                >
                                                                                                                    {getTag(thread, "text")}
                                                                                                                </pre>
                                                                                                                {thread.reaction_code && <p className={styles.reactionStyle}>&#x{thread.reaction_code};</p>}
                                                                                                                {/* <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)}</span> */}

                                                                                                            </Grid>

                                                                                                            {isJsonString(thread.chatbot_thread_value) && typeof JSON.parse(thread.chatbot_thread_value) == "object" && (JSON.parse(thread.chatbot_thread_value).message_format_id) == 2 &&
                                                                                                                <Grid container gap="10px" mt={1} marginLeft={1}>
                                                                                                                    {JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.suggestions.map((ele: object, i: any) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <Button
                                                                                                                                    key={i}
                                                                                                                                    disableRipple
                                                                                                                                    sx={{
                                                                                                                                        color: chatbotThemeDetails.chatbot_header_font_color ? chatbotThemeDetails.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? `${chatBotDetails.chatbot_theme.chatbot_header_font_color} !important` : "",
                                                                                                                                        backgroundColor: chatbotThemeDetails.chatbot_header_color ? chatbotThemeDetails.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? `${chatBotDetails?.chatbot_theme.chatbot_header_color} !important` : "",
                                                                                                                                        boxShadow: `0px 0px 5px 0px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                                                                        border: "none !important",
                                                                                                                                        //  backgroundColor: "#fff", 
                                                                                                                                        //  color: chatbotThemeDetails.chatbot_header_color ? chatbotThemeDetails.chatbot_header_color : chatBotDetails?.chatbot_theme?.chatbot_header_color ? `${chatBotDetails?.chatbot_theme?.chatbot_header_color} !important` : "", 
                                                                                                                                        // borderColor: chatbotThemeDetails.chatbot_header_color ? chatbotThemeDetails.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails.chatbot_theme.chatbot_header_color : "",
                                                                                                                                        fontWeight: "600 !important",
                                                                                                                                        borderRadius: "5px !important",
                                                                                                                                        padding: "5px 10px !important",
                                                                                                                                        minWidth: "unset !important",
                                                                                                                                        '&:hover': {
                                                                                                                                            boxShadow: `0px 0px 5px 0px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                                                                            border: "none !important",
                                                                                                                                            color: chatbotThemeDetails.chatbot_header_font_color ? chatbotThemeDetails.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color ? `${chatBotDetails.chatbot_theme.chatbot_header_font_color} !important` : "",
                                                                                                                                            backgroundColor: chatbotThemeDetails.chatbot_header_color ? chatbotThemeDetails.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color ? `${chatBotDetails?.chatbot_theme.chatbot_header_color} !important` : ""
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    variant={'outlined'}
                                                                                                                                    size='small'
                                                                                                                                >
                                                                                                                                    {Object.values(ele)[0]}
                                                                                                                                </Button>

                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </Grid>
                                                                                                            }

                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc((JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id)) == 5 &&
                                                                                                                <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                    <Carousel suggestions={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.Cards} />
                                                                                                                </Grid>
                                                                                                            }


                                                                                                            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 6 &&
                                                                                                                <Grid item sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                                    <InsuranceCard data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={chatBotDetails?.chatbot_theme} />
                                                                                                                </Grid>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Grid item sx={{ width: '100%', textAlign: 'start', margin: "5px 0px" }}>
                                                                                                <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                                    <Grid item
                                                                                                        sx={{
                                                                                                            marginRight: "6px",
                                                                                                            padding: "8px 8px",
                                                                                                            borderRadius: chatBotDetails ? chatBotDetails.chatbot_text_direction == "rtl" ? "8px 8px 8px 0px" : "8px 8px 0px 8px" : "8px 8px 0px 8px",
                                                                                                            // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                                            fontSize: "15px",
                                                                                                            fontWeight: "500",
                                                                                                            backgroundColor: chatbotThemeDetails?.user_msg_bg_color ? chatbotThemeDetails?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color ? chatBotDetails?.chatbot_theme.user_msg_bg_color : "#F7E9FF",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                color: chatbotThemeDetails?.user_msg_color ? chatbotThemeDetails?.user_msg_color : chatBotDetails?.chatbot_theme.user_msg_color ? chatBotDetails?.chatbot_theme.user_msg_color : "#000000",
                                                                                                                wordBreak: 'break-all',
                                                                                                                fontSize: "14px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {getChatbotMessageTag(thread, thread.message_type,)}
                                                                                                        </Typography>
                                                                                                        {thread.reaction_code && <p className={styles.reactionStyle}>&#x{thread.reaction_code};</p>}
                                                                                                        {/* <span style={{ display: "flex", color: "#7A7A7A", fontSize: "12px", fontWeight: "500", justifyContent: "end" }}>{convertDateTime(thread.timestamp)}</span> */}
                                                                                                    </Grid>
                                                                                                    {/* <Grid item sx={{ marginLeft: "6px", alignSelf: "end" }}>
                                                                                                        <img alt="User Icon" src={userIcon} style={{ width: "40px", height: "auto", objectFit: "fill", display: 'flex' }} />
                                                                                                    </Grid> */}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }) : ""
                                                                        :
                                                                        <>
                                                                            <Grid item className={globalStyles.fullHeight}>
                                                                                <Grid container className={`${globalStyles.fullHeight} ${globalStyles.centerAlignment}`} direction={"column"}>
                                                                                    <Grid item>
                                                                                        <img src={conversation} alt="conversation" />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}`} sx={{ color: chatbotThemeDetails?.chatbot_header_color ? chatbotThemeDetails?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color }}>No Conversation here yet.</Typography></Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} padding={"0px 10px"} sx={{ marginBottom: chatbotThemeDetails?.show_powered_by_chronox == false ? "20px !important" : "" }}>
                                                                <Grid container
                                                                    sx={{ direction: chatBotDetails ? chatBotDetails.chatbot_text_direction : "auto" }}
                                                                    gap="10px" direction={"row"} flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                                                                    <Grid item xs={12} className={styles.chatbotPreviewMessageboxMainItem}
                                                                    // sx={{ marginBottom: chatbotThemeDetails.show_powered_by_chronox !== null || chatbotThemeDetails.show_powered_by_chronox ? "20px !important" : "" }}
                                                                    >
                                                                        <Grid container
                                                                            sx={{
                                                                                background: chatbotThemeDetails?.chatbot_send_message_bg_color ? chatbotThemeDetails?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                                                                border: `1px solid ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                boxShadow: `0 1px 2px ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`
                                                                            }}
                                                                            className={styles.chatbotPreviewMessageboxMainContainer}
                                                                        >
                                                                            <TextField
                                                                                variant="outlined"
                                                                                className={styles.sendMsgTextField}
                                                                                sx={{
                                                                                    border: 'none',
                                                                                    "& fieldset": {
                                                                                        border: 'none',
                                                                                    },
                                                                                    '& .MuiInputBase-input': {
                                                                                        color: chatbotThemeDetails?.chatbot_send_message_font_color ? `${chatbotThemeDetails?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                                                                    },
                                                                                    '& .Mui-disabled': {
                                                                                        '-webkit-text-fill-color': chatbotThemeDetails?.chatbot_send_message_font_color ? `${chatbotThemeDetails?.chatbot_send_message_font_color} !important` : `${chatBotDetails?.chatbot_theme.chatbot_send_message_font_color} !important`,
                                                                                    }
                                                                                }
                                                                                }
                                                                                placeholder="Type a message..."
                                                                                disabled={true}
                                                                                fullWidth
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end" sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)" }}>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item className={styles.chatbot_preview_arrow_item} sx={{
                                                                        backgroundColor: chatbotThemeDetails?.chatbot_send_message_bg_color ? chatbotThemeDetails?.chatbot_send_message_bg_color : chatBotDetails?.chatbot_theme.chatbot_send_message_bg_color,
                                                                        rotate: chatBotDetails ? chatBotDetails.chatbot_text_direction === "rtl" ? "180deg" : "" : ""
                                                                    }}>
                                                                        <IconButton
                                                                            sx={{
                                                                                border: `1px solid ${hexToRgba(chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color ? chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color : "#6A097D")}`,
                                                                                // boxShadow: `0px 0px 1px ${chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color}`,
                                                                                borderRadius: '8px',
                                                                                height: '100%',
                                                                                width: '100%',
                                                                                padding: '5px'
                                                                            }}
                                                                        >
                                                                            <IconsColor
                                                                                // style={{
                                                                                //     opacity: ((input == "" || input == null || flagInputDisable) && (uploadedFiles.length == 0)) ? 0.3 : 1,
                                                                                //     cursor: ((input == "" || input == null || flagInputDisable) && (uploadedFiles.length == 0)) ? "auto" : "pointer",
                                                                                // }}
                                                                                fillColor={chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme?.chatbot_send_message_icon_color}
                                                                                iconName="send"
                                                                                width="18px"
                                                                                height="18px"
                                                                            // onClick={() => {
                                                                            //     handleSend()
                                                                            // }}
                                                                            />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {(chatBotDetails?.chatbot_theme.show_powered_by_chronox !== null ? chatBotDetails?.chatbot_theme.show_powered_by_chronox : true) &&
                                                                <Grid item xs={12} sx={{ padding: '0px 10px', margin: '10px 10px 10px', direction: chatBotDetails?.chatbot_text_direction ?? "ltr" }}>
                                                                    <Typography
                                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: " 13px !important",
                                                                                fontWeight: "500 !important",
                                                                                color: "#989696 !important",
                                                                                margin: "2px 10px 0px !important",
                                                                                display: "flex !important",
                                                                            }}
                                                                            className="chat-poweredby-text">
                                                                            Powered by
                                                                        </Typography>
                                                                        <a href={process.env.REACT_APP_CHRONOX_LANDING_PAGE_URL} target="_blank" style={{ cursor: 'pointer' }}>
                                                                            <img
                                                                                src={chatBotDetails?.chatbot_theme?.chatbot_theme_mode ? chatBotDetails?.chatbot_theme?.chatbot_theme_mode == "dark" ? chronoxWhiteLogo : powerdByLogo : powerdByLogo}
                                                                                className="chat-powered-logo"
                                                                                style={{
                                                                                    width: "100px",
                                                                                    height: "auto",
                                                                                    objectFit: "fill",
                                                                                    verticalAlign: "sub",
                                                                                }}
                                                                                alt="Chronox AI"
                                                                            />
                                                                        </a>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                        </>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </div>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Grid >
        </>
    )
}

export default StaticThemeChatbotPreview;