import { Autocomplete, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from '@mui/material'
import style from "../../../assets/styles/componentStyles/whatsappCampaignStyles/whatsappCampaignStyle.module.scss";
import globalStyles from "../../../assets/styles/global.module.scss";
import previewIcon from '../../../assets/chatbotAssets/Chatbot-Preview-Arrow.svg'
import React, { useContext, useEffect, useState } from 'react'
import GlobalButton from '../../utility/globalButton/button';
import Preview from './preview';
import PreviewMobile from './previewMobile';
import Required from '../../utility/required';
import AddIcon from '@mui/icons-material/Add';
import IconsColor from '../../utility/iconsColor';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContactContext, ContactContextPayload } from '../../../context/contactContext';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { campaignContact, campaignContactTagAssignment, createCampaignPayload, createWhatsappTemplatePayload, getCampaignContactListResponse, getTemplateDetails, globalIntegration, metaIntegrationPhoneNumberData, runCampaignPayload, tagDataInterface, updateCampaignPayload, whatsappTemplateBodyVariableData, whatsappTemplateBodyVariableDataNew } from '../../../models/axiosModel';
import GlobalPopUp from '../../utility/globalPopUp';
import ContactListTable from './contactListTable';
import agent from '../../../services/api';
import { defaultCreateTemplatePayload, defaultNoneTag } from './whatsappCampaign';
import { IntegrationContext, IntegrationContextPayload } from '../../../context/integrationContext';
import TemplatePreview from '../templateMessages/templatePreview';
import TemplatePreviewMobile from '../templateMessages/templatePreviewMobile';
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from '../../../context/whatsappCampaignContext';
import FileUpload from '../../utility/fileUpload/fileUpload';
import axios from 'axios';
import RunCampaignPopup from './runCampaignPopup';

const CreateWhatsappCampaign = () => {

    const { campaignContactListData, getCampaignContactListApi, getCampaignContactApi, campaignContactTagData, getListOfCampaignContactTagApi } = useContext(ContactContext) as ContactContextPayload;
    const { flagLoading, setFlagLoading, setError, error, userDetail, setSuccess, success } = useContext(UserContext) as UserContextPayload;
    const { integrationList, setIntegrationList } = useContext(IntegrationContext) as IntegrationContextPayload;
    const { whatsappCampaignListdData, createCampaignApi, getCampaignMessageTypeApi, whatsAppCampaignMessageTypeData, selectedWhatsAppCampaign, runCampaignApi, getCampaignDataApi, setSelectedWhatsAppCampaign, updateCampaignApi } = useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;

    const navigate = useNavigate();

    const [campaignTitle, setCampaignTitle] = useState<string>(selectedWhatsAppCampaign ? selectedWhatsAppCampaign.campaign_title : "");
    const [selectedContactList, setSelectedContactList] = useState<getCampaignContactListResponse | null>(selectedWhatsAppCampaign ? campaignContactListData && campaignContactListData.length > 0 && campaignContactListData.find((list) => list.contact_list_id === selectedWhatsAppCampaign?.contact_list_id) || null : null);
    const [selectedContacts, setSelectedContacts] = useState<campaignContact[]>([]);
    const [totalContacts, setTotalContacts] = useState<campaignContact[]>([]);
    const [selectedWBA, setSelectedWBA] = useState<any>(null);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<metaIntegrationPhoneNumberData | null>(null);
    const [messageTemplateList, setMessageTemplateList] = useState<getTemplateDetails[] | null>(null);
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<getTemplateDetails | null | any>(null);
    const [messageTemplateDetail, setMessageTemplateDetail] = useState<getTemplateDetails | null>(null);
    const [fallbackValue, setFallbackValue] = useState<string[]>([]);
    const [files, setFiles] = useState<any>([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);


    const [mapTheFields, setMapTheFields] = useState<string[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);
    const [createTemplatePayload, setCreateTemplatePayload] = useState<createWhatsappTemplatePayload>(defaultCreateTemplatePayload);

    //  Uncomment this line for filter out contacts using tags.
    const [selectedTag, setSelectedTag] = useState<campaignContactTagAssignment[]>([]);
    // const [selectedTag, setSelectedTag] = useState<tagDataInterface[]>([defaultNoneTag]);
    const [tagOptions, setTagOptions] = useState<campaignContactTagAssignment[]>([]);

    // const [subscriptionOptions, setSubscriptionOptions] = useState<any[]>([]);
    // const [selectedSubscription, setSelectedSubscription] = useState<any[]>([]);


    const [flagContactListDialog, setFlagContactListDialog] = useState<boolean>(false);
    const [openSwipableDrawer, setOpenSwipableDrawer] = useState<boolean>(false);
    const [flagProcessingCampaign, setFlagProcessingCampaign] = useState<boolean>(false);

    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1200px)')
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 760px) and (min-width : 600px)')
    const flagTabletScreen = useMediaQuery('(max-width : 768px) and (min-width : 0px)')

    const innerHeight = smallScreen ? (window.innerHeight - 185) : tabletScreen ? (window.innerHeight - 180) : (window.innerHeight - 175);

    const [searchParams, setSearchParams] = useSearchParams();

    const isCampaignIdValid = async () => {
        let campaign_id = Number(searchParams.get('campaign_id'))
        if (campaign_id) {
            const response = await getCampaignDataApi(campaign_id)
            if (response.status && response.data.length > 0) {
            } else {
                navigate('/whatsappcampaign/createnewcampaign')
            }
        } else {
            navigate('/whatsappcampaign/createnewcampaign')
        }
    }

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenSwipableDrawer(newOpen);
    };

    const uploadFileHandler = async (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            const file: any = e.target.files[i];

            file.isUploading = true;
            setFiles([file]);

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");

            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }

            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }).then(async (res) => {
                if (res.status) {
                    file.isUploading = false;
                    file.file_data = res.data.data;


                    file.google_storage_id = res.data.data.google_storage_id;
                    await setFiles((old: any) => {
                        let temp = [...old]
                        let fileType = file.name.split('.').pop()
                        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        temp.map((rr: any) => {
                            if (rr.google_storage_id == file.google_storage_id) {
                                rr.isUploading = false;
                                rr.file_data = res.data.data;
                                rr.google_storage_id = res.data.data.google_storage_id;
                                // rr.page_field_id = id;
                                rr.previewImageSrc = fileTypeIconSrc;
                            }
                        })
                        return temp
                    })
                    setFallbackValue((prev) => {
                        return prev.map((val, ind) => {
                            if (ind === 0) {
                                val = res.data.data.file_path;
                            }
                            return val;
                        })
                    });
                    setHeaderVariableSampleValue(res.data.data.file_path);
                    setIsFileUploaded(false);

                    // let images = [];

                    // for (let i = 0; i < e.target.files.length; i++) {
                    //     images.push(URL.createObjectURL(e.target.files[i]))
                    // }
                }
            }).catch((err) => {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFiles([])
                // inform the user
                console.error(err)
                // removeFile(file.name)
            });

            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }

    }

    const removeFileHandler = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${files[0].file_data.google_storage_id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then(async (res) => {
            if (res.data.status) {
                setFiles([]);
                setSuccess({ ...success, flagShowSuccess: true, message: "File Deleted Successfully" })
            } else {
                let errorCode = checkForErrorCode(res.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        }).catch((err) => {
            console.log(err);
        })
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        setFiles([]);
    }

    const setHeaderVariableSampleValue = (value: string) => {
        setCreateTemplatePayload((prev) => ({
            ...prev,
            whatsapp_template_data: {
                ...prev.whatsapp_template_data,
                whatsapp_template_header: {
                    ...prev.whatsapp_template_data.whatsapp_template_header,
                    whatsapp_template_header_data: prev.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.map(headerData => {
                        return {
                            ...headerData,
                            // whatsapp_template_header_variable_data: headerData.whatsapp_template_header_variable_data.map((variable, indexxx) => {
                            //     if (indexxx == 0) {
                            //         return {
                            //             ...variable,
                            //             whatsapp_template_dynamic_variable_sample_value: value.trim().length > 0 ? value : variable.whatsapp_template_dynamic_variable_sample_value
                            //         }
                            //     }
                            //     return variable;
                            // }),
                            whatsapp_template_header_data_value: value.trim().length > 0 ? value : headerData.whatsapp_template_header_variable_data[0].whatsapp_template_dynamic_variable_sample_value,
                        }
                    })
                }
            }
        }))
    }

    const getCampaignContactListApiCall = async () => {
        // setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await getCampaignContactListApi();
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        // setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getCampaignContactApiCall = async (list_id: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const payload = {
            contact_list_id: list_id,
        }
        const response = await agent.getCampaignContact.post(payload);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        } else {
            if (response.data && response.data[0].contact.length) {
                setSelectedContacts(response.data[0].contact);
                setTotalContacts(response.data[0].contact);

                if (selectedTag.length > 0) {
                    if (selectedTag[0].contact_tag_name.toLowerCase() === "all") {
                        setSelectedContacts(response.data[0].contact);
                    } else {
                        const filteredContacts = response.data[0].contact.filter((contact: any) =>
                            selectedTag.some((tag) => contact.contact_tag_assignment.some((tags: any) => tag.contact_tag_id === tags.contact_tag_id))
                        )
                        setSelectedContacts(filteredContacts);
                    }
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getIntegrationListApiCall = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await agent.getMetaIntegration.get('');
        if (res.status) {
            setIntegrationList(res.data)
        } else {
            let errorCode = checkForErrorCode(res)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getMessageTemplateListApiCall = async (meta_integration_whatsapp_id: number) => {
        const res = await agent.getWhatsappTemplateList.get(meta_integration_whatsapp_id);
        if (res.status) {
            setMessageTemplateList(res.data);
            if (selectedWhatsAppCampaign) {
                let selectedWhatsappTemplate = res.data?.find((template: any) => template.whatsapp_template_data.whatsapp_template_name == selectedWhatsAppCampaign.whatsapp_template_name)
                setSelectedMessageTemplate(selectedWhatsappTemplate);
            }
        } else {
            let errorCode = checkForErrorCode(res)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
    }

    const getWhatsappTemplateDetailsApiCall = async (meta_integration_whatsapp_id: number, whatsapp_template_id: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await agent.getWhatsappTemplateDetails.get(meta_integration_whatsapp_id, whatsapp_template_id);
        if (res.status) {
            setMessageTemplateDetail(res.data[0]);
        } else {
            let errorCode = checkForErrorCode(res)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const getCampaignMessageTypeApiCall = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await getCampaignMessageTypeApi();
        if (!(res.status)) {
            let errorCode = checkForErrorCode(res);
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const createCampaignApiCall = async (payload: createCampaignPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await createCampaignApi(payload);
        if (res.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `WhatsApp Campaign Saved Successfully` });
            navigate(`/whatsappcampaign/createnewcampaign?campaign_id=${res.data[0].campaign_id}`);
            setSelectedWhatsAppCampaign(res.data[0]);
        } else {
            let errorCode = checkForErrorCode(res);
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const updateCampaignApiCall = async (payload: updateCampaignPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const res = await updateCampaignApi(payload);
        if (res.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: `WhatsApp Campaign Updated Successfully` });
            setSelectedWhatsAppCampaign(res.data[0]);
        } else {
            let errorCode = checkForErrorCode(res);
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    //  Uncomment this line for filter out contacts using tags.

    const getListOfCampaignContactTagApiCall = async (list_id: number) => {
        const response = await agent.getListOfCampaignContactTag.get(list_id);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        } else {
            // setTagOptions([defaultNoneTag, ...response.data[0].contact_tag_data]);
            setTagOptions(response.data[0].contact_tag_data);
        }
    }

    const onClickOfInfo = () => {
        setFlagContactListDialog(true);
    }

    const handleSaveClick = async () => {
        if (!whatsAppCampaignMessageTypeData) {
            await getCampaignMessageTypeApiCall();
        }
        let campaign_id = Number(searchParams.get('campaign_id'))
        if (!campaign_id) {
            const payload: createCampaignPayload = {
                campaign_title: campaignTitle,
                contact_list_id: selectedContactList?.contact_list_id as number,
                campaign_message_type_id: whatsAppCampaignMessageTypeData?.find((type) => type.campaign_message_type_name.toLowerCase() === "campaign")?.campaign_message_type_id || 1,
                meta_integration_whatsapp_phone_number_id: selectedPhoneNumber?.meta_integration_whatsapp_phone_number_id as number,
                flag_template: true,
                whatsapp_template_id: selectedMessageTemplate?.whatsapp_template_id,
                campaign_variable: [
                    ...(messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.map((variable, ind) => ({
                        campaign_variable_name: variable.whatsapp_template_dynamic_variable_key,
                        whatsapp_template_dynamic_variable_id: variable.whatsapp_template_dynamic_variable_id as number,
                        contact_field_id: selectedContacts[0].contact_data.find((contact) => contact.contact_field_name === mapTheFields[ind])?.contact_field_id as number || "",
                        campaign_variable_fallback_value: fallbackValue[ind],
                    })) || []),
                    ...(messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_body?.whatsapp_template_body_variable_data?.map((variable, ind) => {
                        const offsetIndex = ind + (messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.length || 0);
                        return {
                            campaign_variable_name: variable.whatsapp_template_dynamic_variable_key,
                            whatsapp_template_dynamic_variable_id: variable.whatsapp_template_dynamic_variable_id as number,
                            contact_field_id: selectedContacts[0].contact_data.find(
                                (contact) => contact.contact_field_name === mapTheFields[offsetIndex]
                            )?.contact_field_id as number || "",
                            campaign_variable_fallback_value: fallbackValue[offsetIndex],
                        };
                    }) || [])
                ],
                contact_tag_id: selectedTag.length > 0 ? selectedTag.map((tag) => {
                    return tag.contact_tag_id;
                }) : [],
            }
            createCampaignApiCall(payload);
        } else {
            const payload: updateCampaignPayload = {
                campaign_id: selectedWhatsAppCampaign.campaign_id,
                campaign_title: campaignTitle,
                contact_list_id: selectedContactList?.contact_list_id as number,
                campaign_message_type_id: whatsAppCampaignMessageTypeData?.find((type) => type.campaign_message_type_name.toLowerCase() === "campaign")?.campaign_message_type_id || 1,
                meta_integration_whatsapp_phone_number_id: selectedPhoneNumber?.meta_integration_whatsapp_phone_number_id as number,
                flag_template: true,
                whatsapp_template_id: selectedMessageTemplate?.whatsapp_template_id,
                campaign_variable: [
                    ...(messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.map((variable, ind) => ({
                        campaign_variable_name: variable.whatsapp_template_dynamic_variable_key,
                        whatsapp_template_dynamic_variable_id: variable.whatsapp_template_dynamic_variable_id as number,
                        contact_field_id: selectedContacts[0].contact_data.find((contact) => contact.contact_field_name === mapTheFields[ind])?.contact_field_id as number || "",
                        campaign_variable_fallback_value: fallbackValue[ind],
                        campaign_variable_id: selectedWhatsAppCampaign.campaign_variable_data.find((data: any) => variable.whatsapp_template_dynamic_variable_id === data.whatsapp_template_dynamic_variable_id).campaign_variable_id || null,
                    })) || []),
                    ...(messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_body?.whatsapp_template_body_variable_data?.map((variable, ind) => {
                        const offsetIndex = ind + (messageTemplateDetail?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.length || 0);
                        return {
                            campaign_variable_name: variable.whatsapp_template_dynamic_variable_key,
                            whatsapp_template_dynamic_variable_id: variable.whatsapp_template_dynamic_variable_id as number,
                            contact_field_id: selectedContacts[0].contact_data.find(
                                (contact) => contact.contact_field_name === mapTheFields[offsetIndex]
                            )?.contact_field_id as number || "",
                            campaign_variable_fallback_value: fallbackValue[offsetIndex],
                            campaign_variable_id: selectedWhatsAppCampaign.campaign_variable_data.find((data: any) => variable.whatsapp_template_dynamic_variable_id === data.whatsapp_template_dynamic_variable_id).campaign_variable_id || null,
                        };
                    }) || [])
                ],
                contact_tag_id: selectedTag.length > 0 ? selectedTag.map((tag) => {
                    return tag.contact_tag_id;
                }) : [],
            }
            updateCampaignApiCall(payload);
        }
    }

    const onClickOfExecute = async () => {
        if (selectedWhatsAppCampaign) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const payload: runCampaignPayload = {
                campaign_id: selectedWhatsAppCampaign?.campaign_id,
            }
            const res = await runCampaignApi(payload);
            setFlagProcessingCampaign(true);
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }

    const handleCancelClick = () => {
        if (!selectedWhatsAppCampaign) {
            setCampaignTitle("");
            setSelectedContactList(null);
            setSelectedContacts([]);
            setTotalContacts([]);
            setSelectedWBA(null);
            setSelectedPhoneNumber(null);
            setMessageTemplateList(null);
            setSelectedMessageTemplate(null);
            setMessageTemplateDetail(null);
            setFallbackValue([]);
            setMapTheFields([]);
            setDropdownOptions([]);
            setCreateTemplatePayload(defaultCreateTemplatePayload);
        } else {
            navigate('/whatsappcampaign');
        }
    }

    const checkDisableSaveButton = (isExecuteBtn?: boolean) => {
        // if (campaignTitle.trim().length > 0 && selectedContactList && selectedWBA && selectedPhoneNumber && selectedMessageTemplate && messageTemplateDetail && !mapTheFields.some((field) => field.length === 0)) {
        //     return false;
        // }
        // return true;   
        if (selectedWhatsAppCampaign && !isExecuteBtn) {
            if (selectedWhatsAppCampaign.campaign_execution_status_name.toLowerCase() === "draft") {
                return false;
            }
            return true;
        }
        if (selectedWhatsAppCampaign && isExecuteBtn && selectedWhatsAppCampaign.campaign_execution_status_name.toLowerCase() === "completed") {
            return true;
        }
        if (campaignTitle.trim().length > 0 && selectedContactList && selectedWBA && selectedPhoneNumber && selectedMessageTemplate && messageTemplateDetail) {
            for (let i = 0; i < mapTheFields.length; ++i) {
                if (mapTheFields[i]?.length <= 0 && fallbackValue[i].trim().length <= 0) {
                    return true;
                }
            }
            if (isExecuteBtn) {
                if (selectedWhatsAppCampaign) {
                    return false;
                }
                return true;
            }
            return false;
        }
        return true;
    }

    const checkDisableCancelButton = () => {
        if (selectedWhatsAppCampaign && selectedWhatsAppCampaign.campaign_execution_status_name.toLowerCase() === "completed") {
            return true;
        }
        return false;
    }

    const checkDisableAllFields = () => {
        if (selectedWhatsAppCampaign && selectedWhatsAppCampaign.campaign_execution_status_name.toLowerCase() != "draft") {
            return true;
        }
        return false;
    }

    const onChangeOfWBA = (value: any) => {
        if (selectedPhoneNumber) {
            setSelectedPhoneNumber(null);
        }
        if (selectedMessageTemplate) {
            setSelectedMessageTemplate(null);
        }
        if (messageTemplateDetail) {
            setMessageTemplateDetail(null);
            setCreateTemplatePayload(defaultCreateTemplatePayload);
        }
        setSelectedWBA(value);
    }

    const onChangeOfPhoneNumber = (value: any) => {
        if (selectedMessageTemplate) {
            setSelectedMessageTemplate(null);
        }
        if (messageTemplateDetail) {
            setMessageTemplateDetail(null);
        }
        setSelectedPhoneNumber(value);
    }

    const handleChangeField = (value: any, ind: number, indexx: number, isHeaderVariable: boolean) => {
        setMapTheFields((fields) => {
            return fields.map((field, index) => {
                if (ind === index) {
                    if (value) return value;
                    return "";
                }
                return field;
            })
        })

        if (!isHeaderVariable) {
            setCreateTemplatePayload((prev) => ({
                ...prev,
                whatsapp_template_data: {
                    ...prev.whatsapp_template_data,
                    whatsapp_template_body: {
                        ...prev.whatsapp_template_data.whatsapp_template_body,
                        whatsapp_template_body_variable_data: prev.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.map(
                            (variable, index) => {
                                if (indexx === index) {
                                    return {
                                        ...variable,
                                        whatsapp_template_dynamic_variable_sample_value: value ? value : fallbackValue[ind].length > 0 ? fallbackValue[ind] : variable.whatsapp_template_dynamic_variable_key,
                                    };
                                }
                                return variable;
                            }
                        ),
                    },
                },
            }));
        } else {
            if (value) {
                setHeaderVariableSampleValue("");
            } else {
                setHeaderVariableSampleValue(fallbackValue[ind]);
            }
        }
    }

    const handleChangeFallbackValue = (value: any, ind: number, indexx: number, isHeaderVariable: boolean) => {
        setFallbackValue((prev) => {
            return prev.map((val, index) => {
                if (ind === index) {
                    if (isHeaderVariable && files.length > 0) {
                        removeFileHandler();
                        return value;
                    }
                    return value;
                }
                return val;
            });
        });

        if (mapTheFields.length > 0 && mapTheFields[ind].length <= 0) {
            if (!isHeaderVariable) {
                setCreateTemplatePayload((prev) => ({
                    ...prev,
                    whatsapp_template_data: {
                        ...prev.whatsapp_template_data,
                        whatsapp_template_body: {
                            ...prev.whatsapp_template_data.whatsapp_template_body,
                            whatsapp_template_body_variable_data: prev.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.map(
                                (variable, index) => {
                                    if (indexx === index) {
                                        return {
                                            ...variable,
                                            // whatsapp_template_body_variable_key_sample_value: value ? value : fallbackValue[ind].length > 0 ? fallbackValue[ind] : variable.whatsapp_template_dynamic_variable_key,
                                            whatsapp_template_dynamic_variable_sample_value: value.trim().length > 0 ? value : variable.whatsapp_template_dynamic_variable_key,
                                        };
                                    }
                                    return variable;
                                }
                            ),
                        },
                    },
                }));
            }
        }
    }

    const mappingOfFields = (data: whatsappTemplateBodyVariableDataNew[], contacts: campaignContact[]) => {
        if (data && !selectedWhatsAppCampaign) {
            if (contacts.length > 0) {
                let mapFields: string[] = [];
                if (messageTemplateDetail && messageTemplateDetail.whatsapp_template_data && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data &&
                    messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 &&
                    messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data &&
                    messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0) {
                    if (contacts[0].contact_data.find((contact) => contact.contact_field_name === messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data[0].whatsapp_template_dynamic_variable_key)) {
                        mapFields.push(messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data[0].whatsapp_template_dynamic_variable_key);
                    } else {
                        mapFields.push("");
                    }
                }

                for (let i = 0; i < data.length; ++i) {
                    if (contacts[0].contact_data.find((contact) => contact.contact_field_name === data[i].whatsapp_template_dynamic_variable_key)) {
                        mapFields.push(data[i].whatsapp_template_dynamic_variable_key);
                    } else {
                        mapFields.push("");
                    }
                }
                setMapTheFields(mapFields);
            } else {
                setMapTheFields([]);
            }
        }
    }

    //  Uncomment this line for filter out contacts using tags.
    const onChangeTag = (value: campaignContactTagAssignment[]) => {
        // if (value.find((tag) => tag.contact_tag_id === -1 && tag.contact_tag_name === "None")) {
        //     if (value.length > 1) {
        //         if (selectedTag.find((tag) => tag.contact_tag_id == -1 && tag.contact_tag_name === "None")) {
        //             setSelectedTag(value.filter((tag) => tag.contact_tag_id != -1));
        //         } else {
        //             setSelectedTag(value.filter((tag) => tag.contact_tag_id === -1 && tag.contact_tag_name == "None"));
        //         }
        //     } else {
        //         setSelectedTag(value);
        //     }
        // } else {
        //     if (value.length === 0) {
        //         setSelectedTag([defaultNoneTag]);
        //     } else setSelectedTag(value);
        // }    
        let newVal = value;
        if (value.length > 0) {
            if (value[value.length - 1].contact_tag_name.toLowerCase() === "all") {
                newVal = value.filter((tag) => tag.contact_tag_name.toLowerCase() === "all");
            } else {
                newVal = value.filter((tag) => tag.contact_tag_name.toLowerCase() != "all");
            }
        }
        setSelectedTag(newVal);
    }
    useEffect(() => {
        if (selectedWhatsAppCampaign && integrationList) {
            setSelectedWBA(integrationList ? integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name === "WhatsApp").filter((business) => business.meta_integration_whatsapp_data?.waba_name == selectedWhatsAppCampaign.waba_name
            )[0] : null)
            let selectedWba: any = integrationList ? integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name === "WhatsApp").filter((business) => business.meta_integration_whatsapp_data?.waba_name == selectedWhatsAppCampaign.waba_name
            )[0] : null
            setSelectedPhoneNumber(selectedWba ? selectedWba?.meta_integration_whatsapp_data ? selectedWba?.meta_integration_whatsapp_data?.meta_integration_phone_number_data.find((phoneNumber: any) => phoneNumber.phone_number_id == selectedWhatsAppCampaign.phone_number_id) : null : null)
            setSelectedContactList(campaignContactListData && campaignContactListData.length > 0 && campaignContactListData.find((list) => list.contact_list_id === selectedWhatsAppCampaign?.contact_list_id) || null)
            setCampaignTitle(selectedWhatsAppCampaign.campaign_title)
            setSelectedTag(selectedWhatsAppCampaign.campaign_contact_tag_filter)

            let headerData = selectedWhatsAppCampaign.campaign_variable_data?.filter((variable: any) => variable.whatsapp_template_dynamic_variable_type_name.toLowerCase() == "header") || []
            let bodyData = selectedWhatsAppCampaign.campaign_variable_data?.filter((variable: any) => variable.whatsapp_template_dynamic_variable_type_name.toLowerCase() !== "header").sort((a: any, b: any) => a.whatsapp_template_dynamic_variable_id - b.whatsapp_template_dynamic_variable_id) || []
            let mapFields = []
            let fallbackVal = []
            mapFields.push((headerData.length > 0 && headerData[0].contact_field_name) ? headerData[0].contact_field_name : "")
            fallbackVal.push((headerData.length > 0 && headerData[0].campaign_variable_fallback_value) ? headerData[0].campaign_variable_fallback_value : "")
            for (let i = 0; i < bodyData.length; ++i) {
                mapFields.push(bodyData[i].contact_field_name ? bodyData[i].contact_field_name : "");
                fallbackVal.push(bodyData[i].campaign_variable_fallback_value ? bodyData[i].campaign_variable_fallback_value : "");

            }
            setMapTheFields(mapFields);
            setFallbackValue(fallbackVal);
        }
    }, [selectedWhatsAppCampaign, integrationList])

    useEffect(() => {
        (async () => {
            isCampaignIdValid()
            await getCampaignContactListApiCall();
            await getIntegrationListApiCall();
        })()
    }, [])

    useEffect(() => {
        //  Uncomment this line for filter out contacts using tags.
        // setSelectedTag([defaultNoneTag]); 
        (async () => {
            if (selectedContactList) {
                //  Uncomment this line for filter out contacts using tags.
                // getListOfCampaignContactTagApiCall(selectedContactList.contact_list_id);
                await getCampaignContactApiCall(selectedContactList?.contact_list_id);
            } else {
                setSelectedContacts([]);
                setTotalContacts([]);
            }
        })();
    }, [selectedContactList])
    useEffect(() => {
        if (selectedContacts.length > 0 && mapTheFields.length > 0) {
            const commonFields = selectedContacts[0].contact_data.filter((data) => !mapTheFields.includes(data.contact_field_name)).map((data) => data.contact_field_name);
            setDropdownOptions(commonFields);
        }
        if (messageTemplateDetail) {
            mappingOfFields(messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data, selectedContacts);
        }
    }, [selectedContacts])

    useEffect(() => {
        if (totalContacts && totalContacts.length > 0) {
            let tag: campaignContactTagAssignment[] = [];
            for (let i = 0; i < totalContacts.length; ++i) {
                for (let j = 0; j < totalContacts[i].contact_tag_assignment.length; ++j) {
                    if (!tag.find((tg) => tg.contact_tag_id === totalContacts[i].contact_tag_assignment[j].contact_tag_id)) {
                        tag.push(totalContacts[i].contact_tag_assignment[j]);
                    }
                }
            }
            setTagOptions(tag);
        }
    }, [totalContacts])

    useEffect(() => {
        (async () => {
            if (selectedWBA) {
                if (selectedWBA.meta_integration_whatsapp_data) {
                    await getMessageTemplateListApiCall(selectedWBA.meta_integration_whatsapp_data.meta_integration_whatsapp_id);

                }
            }
        })()
    }, [selectedWBA])


    useEffect(() => {
        if (selectedMessageTemplate) {
            getWhatsappTemplateDetailsApiCall(Number(selectedMessageTemplate.meta_integration_whatsapp_id), Number(selectedMessageTemplate.whatsapp_template_id));
        } else {
            setMessageTemplateDetail(null);
        }
    }, [selectedMessageTemplate])

    useEffect(() => {
        if (messageTemplateDetail) {
            let fallBackVal = selectedWhatsAppCampaign ? fallbackValue : [];
            if (!selectedWhatsAppCampaign) {
                const len = messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.length;
                const headerLen = messageTemplateDetail.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.length || 0;
                fallBackVal = Array(len + headerLen).fill("")
                setFallbackValue(fallBackVal);
            }

            const data = messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data;
            mappingOfFields(data, selectedContacts);

            // const obj = {
            //     "whatsapp_template_id": messageTemplateDetail.whatsapp_template_id,
            //     "meta_integration_whatsapp_id": messageTemplateDetail.meta_integration_whatsapp_id,
            //     "whatsapp_template_data": {
            //         "whatsapp_template_data_id": null,
            //         "whatsapp_template_category_id": Number(messageTemplateDetail.whatsapp_template_data.whatsapp_template_category_id),
            //         "whatsapp_language_id": messageTemplateDetail.whatsapp_template_data.whatsapp_language_data.whatsapp_language_id,
            //         "whatsapp_template_name": messageTemplateDetail.whatsapp_template_data.whatsapp_template_name,
            //         "whatsapp_template_description": messageTemplateDetail.whatsapp_template_data.whatsapp_template_description,
            //         "whatsapp_template_button": messageTemplateDetail.whatsapp_template_data.whatsapp_template_button,
            //         "whatsapp_template_header": {
            //             "whatsapp_template_header_type_id": messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_id,
            //             "whatsapp_template_header_type_name": messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name,
            //             "whatsapp_template_header_data": messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.flatMap((header) => {
            //                 return {
            //                     "whatsapp_template_header_data_value": fallbackValue.length > 0 ? fallbackValue[0] : header.whatsapp_template_header_data_value,
            //                     "whatsapp_template_header_variable_data": header.whatsapp_template_header_variable_data.flatMap((hVaribale) => {
            //                         return {
            //                             "whatsapp_template_dynamic_variable_id": hVaribale.whatsapp_template_dynamic_variable_id,
            //                             "whatsapp_template_dynamic_variable_type_id": hVaribale.whatsapp_template_dynamic_variable_type_id,
            //                             "whatsapp_template_dynamic_variable_unique_id": hVaribale.whatsapp_template_dynamic_variable_unique_id,
            //                             "whatsapp_template_dynamic_variable_key": hVaribale.whatsapp_template_dynamic_variable_key,
            //                             "whatsapp_template_dynamic_variable_sample_value": hVaribale.whatsapp_template_dynamic_variable_sample_value,
            //                             "history_id": hVaribale.history_id,
            //                             "added_by_change_log_id": hVaribale.added_by_change_log_id,
            //                             "whatsapp_template_dynamic_variable_flag_deleted": hVaribale.whatsapp_template_dynamic_variable_flag_deleted,
            //                             "whatsapp_template_id": hVaribale.whatsapp_template_id,
            //                             "flag_header": hVaribale.flag_header,
            //                             "flag_button": hVaribale.flag_button,
            //                             "whatsapp_template_dynamic_variable_type_name": hVaribale.whatsapp_template_dynamic_variable_type_name
            //                         }
            //                     })
            //                 }
            //             })
            //         },
            //         "whatsapp_template_body": {
            //             "whatsapp_template_body_value": messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_value,
            //             "whatsapp_template_body_description": messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_description,
            //             "whatsapp_template_body_variable_data": messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data && messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.flatMap((variable, ind) => {
            //                 return {
            //                     "whatsapp_template_dynamic_variable_id": variable.whatsapp_template_dynamic_variable_id,
            //                     "whatsapp_template_dynamic_variable_type_id": variable.whatsapp_template_dynamic_variable_type_id,
            //                     "whatsapp_template_id": variable.whatsapp_template_id,
            //                     "whatsapp_template_dynamic_variable_unique_id": variable.whatsapp_template_dynamic_variable_unique_id,
            //                     "whatsapp_template_dynamic_variable_key": variable.whatsapp_template_dynamic_variable_key,
            //                     "whatsapp_template_dynamic_variable_sample_value": (mapTheFields.length > 0 && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data && mapTheFields[ind + messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length].length > 0) ?
            //                         mapTheFields[ind + messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length]
            //                         : (fallbackValue.length > 0 && fallbackValue[ind + messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length].length > 0)
            //                             ? fallbackValue[ind + messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length]
            //                             : variable.whatsapp_template_dynamic_variable_key,
            //                     "flag_header": variable.flag_header,
            //                     "flag_button": variable.flag_button,
            //                     "whatsapp_template_dynamic_variable_type_name": variable.whatsapp_template_dynamic_variable_type_name
            //                 }
            //             })
            //         },
            //     }
            // } 
            const obj = {
                "whatsapp_template_id": messageTemplateDetail.whatsapp_template_id,
                "meta_integration_whatsapp_id": messageTemplateDetail.meta_integration_whatsapp_id,
                "whatsapp_template_data": {
                    "whatsapp_template_data_id": null,
                    "whatsapp_template_category_id": Number(messageTemplateDetail.whatsapp_template_data?.whatsapp_template_category_id ?? 0),
                    "whatsapp_language_id": messageTemplateDetail.whatsapp_template_data?.whatsapp_language_data?.whatsapp_language_id ?? null,
                    "whatsapp_template_name": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_name ?? "",
                    "whatsapp_template_description": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_description ?? "",
                    "whatsapp_template_button": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_button?.map((button) => ({
                        "whatsapp_template_button_type_id": Number(button.whatsapp_template_button_type_id),
                        "whatsapp_template_button_id": button.whatsapp_template_button_id ?? null,
                        "whatsapp_template_button_index": button.whatsapp_template_button_index,
                        "whatsapp_template_button_data": button.whatsapp_template_button_data?.map((buttonData) => ({
                            ...buttonData,
                            "whatsapp_template_button_sub_type_field_data": buttonData.whatsapp_template_button_sub_type_field_data?.map((subField) => ({
                                ...subField,
                                "whatsapp_template_button_field_data_variable": subField.whatsapp_template_button_field_data_variable ?? null, 
                            })),
                        })),
                    })) ?? [],
                    "whatsapp_template_header": {
                        "whatsapp_template_header_type_id": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_id ?? null,
                        "whatsapp_template_header_type_name": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name ?? "",
                        "whatsapp_template_header_data": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.flatMap((header) => {
                            const fallbackHeaderValue = (fallBackVal && fallBackVal.length > 0 && fallBackVal[0].length > 0) ? fallBackVal[0] : header.whatsapp_template_header_data_value;
                            return {
                                "whatsapp_template_header_data_value": fallbackHeaderValue,  
                                "whatsapp_template_header_data_id": header.whatsapp_template_header_data_id, 
                                "whatsapp_template_header_data_google_storage_id": header.whatsapp_template_header_data_google_storage_id,
                                "whatsapp_template_header_variable_data": header.whatsapp_template_header_variable_data?.flatMap((hVariable) => ({ 
                                    "whatsapp_template_dynamic_variable_id": hVariable?.whatsapp_template_dynamic_variable_id,
                                    "whatsapp_template_dynamic_variable_type_id": hVariable?.whatsapp_template_dynamic_variable_type_id,
                                    "whatsapp_template_dynamic_variable_unique_id": hVariable?.whatsapp_template_dynamic_variable_unique_id,
                                    "whatsapp_template_dynamic_variable_key": hVariable?.whatsapp_template_dynamic_variable_key,
                                    "whatsapp_template_dynamic_variable_sample_value": hVariable?.whatsapp_template_dynamic_variable_sample_value,
                                    "history_id": hVariable?.history_id,
                                    "added_by_change_log_id": hVariable?.added_by_change_log_id,
                                    "whatsapp_template_dynamic_variable_flag_deleted": hVariable?.whatsapp_template_dynamic_variable_flag_deleted,
                                    "whatsapp_template_id": hVariable?.whatsapp_template_id,
                                    "flag_header": hVariable?.flag_header,
                                    "flag_button": hVariable?.flag_button,
                                    "whatsapp_template_dynamic_variable_type_name": hVariable?.whatsapp_template_dynamic_variable_type_name
                                })) ?? []
                            }
                        }) ?? []
                    },
                    "whatsapp_template_body": {
                        "whatsapp_template_body_value": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_body?.whatsapp_template_body_value ?? "",
                        "whatsapp_template_body_description": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_body?.whatsapp_template_body_description ?? "",
                        "whatsapp_template_body_variable_data": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_body?.whatsapp_template_body_variable_data?.flatMap((variable, ind) => {
                            const headerVariableLength = messageTemplateDetail.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_data?.[0]?.whatsapp_template_header_variable_data?.length ?? 0;
                            const mappedValue = mapTheFields?.[ind + headerVariableLength]?.length > 0
                                ? mapTheFields[ind + headerVariableLength]
                                : fallBackVal?.[ind + headerVariableLength]?.length > 0
                                    ? fallBackVal[ind + headerVariableLength]
                                    : variable.whatsapp_template_dynamic_variable_key;

                            return {
                                "whatsapp_template_dynamic_variable_id": variable?.whatsapp_template_dynamic_variable_id,
                                "whatsapp_template_dynamic_variable_type_id": variable?.whatsapp_template_dynamic_variable_type_id,
                                "whatsapp_template_id": variable?.whatsapp_template_id,
                                "whatsapp_template_dynamic_variable_unique_id": variable?.whatsapp_template_dynamic_variable_unique_id,
                                "whatsapp_template_dynamic_variable_key": variable?.whatsapp_template_dynamic_variable_key,
                                "whatsapp_template_dynamic_variable_sample_value": mappedValue,
                                "flag_header": variable?.flag_header,
                                "flag_button": variable?.flag_button,
                                "whatsapp_template_dynamic_variable_type_name": variable?.whatsapp_template_dynamic_variable_type_name
                            };
                        }) ?? []
                    },
                    "whatsapp_template_footer": {
                        "whatsapp_template_footer_type_id": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_footer.whatsapp_template_footer_type_id,
                        "whatsapp_template_footer_data": messageTemplateDetail.whatsapp_template_data?.whatsapp_template_footer.whatsapp_template_footer_data.flatMap((footerData, ind) => {
                            return { 
                                ...footerData,
                                "whatsapp_template_footer_data_value": footerData.whatsapp_template_footer_data_value,
                            }
                        })
                    }, 
                }
            }; 
            setCreateTemplatePayload(obj);
        } else {
            setCreateTemplatePayload(defaultCreateTemplatePayload);
        }
    }, [messageTemplateDetail])

    useEffect(() => {
        if (mapTheFields.length > 0 && selectedContacts.length > 0 && selectedContacts[0].contact_data.length > 0) {
            const commonFields = selectedContacts[0].contact_data.filter((data) => !mapTheFields.includes(data.contact_field_name)).map((data) => data.contact_field_name);
            setDropdownOptions(commonFields);
        } else {
            setDropdownOptions([]);
        }
    }, [mapTheFields])

    //  Uncomment this line for filter out contacts using tags.
    useEffect(() => {
        // if (selectedTag.length > 0) {
        //     if (!selectedTag.find((tag) => tag.contact_tag_id == -1 && tag.contact_tag_name == "None")) {
        //         if (selectedContacts.length > 0) {
        //             const filteredContacts = totalContacts.filter((contact) =>
        //                 selectedTag.some((tag) => contact.contact_tag_assignment.some((tags) => tag.contact_tag_id === tags.contact_tag_id))
        //             )
        //             setSelectedContacts(filteredContacts);
        //         }
        //     } else {
        //         setSelectedContacts(totalContacts);
        //     } 
        // }  

        if (selectedTag.length > 0) {

            if (selectedTag[0].contact_tag_name.toLowerCase() === "all") {
                setSelectedContacts(totalContacts);
            } else {
                const filteredContacts = totalContacts.filter((contact) =>
                    selectedTag.some((tag) => contact.contact_tag_assignment.some((tags) => tag.contact_tag_id === tags.contact_tag_id))
                )
                setSelectedContacts(filteredContacts);
            }
        } else {
            setSelectedContacts(totalContacts);
        }
    }, [selectedTag])

    useEffect(() => {
        if (flagProcessingCampaign) {
            setTimeout(() => {
                setFlagProcessingCampaign(false);
                navigate('/whatsappcampaign');
            }, 5000);
        }
    }, [flagProcessingCampaign])

    return (
        <>
            <GlobalPopUp
                open={flagContactListDialog}
                onClose={() => { setFlagContactListDialog(false); }}
                handleClose={() => { setFlagContactListDialog(false); }}
                flagShowCloseIcon={true}
                borderRadius='10px'
                paperMinWidth='60%'
                paperMaxwidth='90%'
                titlePadding='0px'
            >
                <ContactListTable selectedContacts={selectedContacts} selectedContactList={selectedContactList as getCampaignContactListResponse} />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagProcessingCampaign}
                onClose={() => { setFlagProcessingCampaign(false); navigate('/whatsappcampaign'); }}
                handleClose={() => { setFlagProcessingCampaign(false); navigate('/whatsappcampaign'); }}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "700px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "750px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <RunCampaignPopup setFlagProcessingCampaign={setFlagProcessingCampaign} />
            </GlobalPopUp>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className={style.createWhatsappCampaignContainer} sx={{ padding: { xs: "0px 20px 0px 20px", lg: "0px 0px 0px 22px", position: "relative" } }}>
                            <Grid container sx={{ justifyContent: "space-between" }} gap={{ xs: 0, lg: 1, xl: 2 }} flexWrap={"nowrap"} direction={{ xs: "column", lg: "row" }}>
                                {flagSmallScreen &&
                                    <Grid item className={style.whatsappCampaignBackArrow} onClick={() => navigate('/whatsappcampaign')}>
                                        <Grid container direction={"row"} alignItems={"center"} gap={1} flexWrap={"nowrap"}>
                                            <Grid item sx={{ display: "flex" }}>
                                                <IconsColor iconName="backAudio" height="18" width="18" fillColor="#444444" />
                                            </Grid>
                                            <Grid item>
                                                <span className={style.backFontStyle}>Back</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {flagSmallScreen &&
                                    <Grid item className={style.whatsappCampaignPreviewItem} onClick={() => { setOpenSwipableDrawer(true) }}>
                                        <Grid container alignItems={"center"} sx={{ gap: "5px", cursor: "pointer" }}>
                                            <img
                                                src={previewIcon}
                                                className={globalStyles.previewIconStyle}
                                                alt=""
                                            />
                                            <Typography
                                                component={'span'}
                                                className={globalStyles.previewIconFont}
                                            >
                                                Preview
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }


                                {/* <Grid item xs={12} lg={6.5} xl={7.5} p={1} className={style.whatsappCampaignLeftBox} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important` }}> */}
                                <Grid item xs={12} lg={7.8} xl={8} p={1} className={style.whatsappCampaignLeftBox} mt={flagSmallScreen ? "40px" : "10px"} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, position: "relative", overflow: "auto" }}>
                                    <Grid container>
                                        <Grid item xs={12} padding={"0px 5px 5px 5px"}>
                                            <Grid container direction={"column"} gap={"16px"}>

                                                <Grid item xs={12}>
                                                    <Grid container direction={"column"} gap={"10px"}>
                                                        <Grid item xs={12}>
                                                            <Typography className={style.whatsappCampaignTitleTypography}>Campaign Title <Required /></Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                value={campaignTitle}
                                                                placeholder='Enter Campaign Title'
                                                                onChange={(e) => setCampaignTitle(e.target.value)}
                                                                className={style.whatsappCampaignTextField}
                                                                disabled={checkDisableAllFields()}
                                                                sx={{ width: "100%" }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container direction={"column"} gap={"10px"}>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                                <Grid item>
                                                                    <Typography className={style.whatsappCampaignTitleTypography}>Contact List <Required /></Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container gap={"8px"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => navigate("/contacts")}>
                                                                        <IconsColor iconName="plus" width="14" height="14" fillColor="#6A097D" />
                                                                        {!flagTabletScreen &&
                                                                            <Grid item> <Typography className={style.whatsappCampaignPurpleTitleTypography}>New Contact List</Typography></Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                className={style.whatsappCampaignDropDowns}
                                                                options={campaignContactListData ? campaignContactListData : []}
                                                                getOptionLabel={(option) => option.contact_list_name + " (" + option.total_contact + " Contacts)"}
                                                                value={campaignContactListData && campaignContactListData.length > 0 && campaignContactListData.find((list) => list.contact_list_id === selectedContactList?.contact_list_id) || null}
                                                                onChange={(e, value) => { setSelectedContactList(value); }}
                                                                renderInput={(params) => <TextField {...params} placeholder="Select Contact List" />}
                                                                disabled={checkDisableAllFields()}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* Uncomment this line for filter out contacts using tags. */}
                                                {selectedContactList &&
                                                    <Grid item xs={12}>
                                                        <Grid container direction={"column"} gap={"10px"}>
                                                            <Grid item xs={12}>
                                                                <Typography className={style.whatsappCampaignTitleTypography}>Filter by Tags (OR condition if multiple tags are selected)</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    className={style.whatsappCampaignDropDowns}
                                                                    options={tagOptions}
                                                                    multiple
                                                                    getOptionLabel={(option) => option.contact_tag_name}
                                                                    disabled={checkDisableAllFields()}
                                                                    value={tagOptions.filter((tag) => selectedTag.some((selected) => tag.contact_tag_id === selected.contact_tag_id)) || null}
                                                                    onChange={(_: React.SyntheticEvent, value: campaignContactTagAssignment[]) => { onChangeTag(value); }}
                                                                    renderInput={params => {
                                                                        const { InputProps, ...restParams } = params;
                                                                        const { startAdornment, ...restInputProps } = InputProps;
                                                                        return (
                                                                            <TextField
                                                                                {...restParams}
                                                                                placeholder='Select Tags'
                                                                                InputProps={{
                                                                                    ...restInputProps,
                                                                                    startAdornment: (
                                                                                        <div style={{
                                                                                            maxHeight: "90px",
                                                                                            overflowY: 'auto',
                                                                                        }}
                                                                                        >
                                                                                            {startAdornment}
                                                                                        </div>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }


                                                {/* {selectedContactList &&
                                                    <Grid item xs={12}>
                                                        <Grid container direction={"column"} gap={"10px"}>
                                                            <Grid item xs={12}>
                                                                <Typography className={style.whatsappCampaignTitleTypography}>Filter by Subscription (OR condition if multiple subscriptions are selected)</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    className={style.whatsappCampaignDropDowns}
                                                                    options={subscriptionOptions}
                                                                    multiple
                                                                    getOptionLabel={(option) => option.subscription_name}
                                                                    // selectedSubscription 
                                                                    value={subscriptionOptions.filter((subs) => selectedSubscription.some((selected) => subs.subscription_id === selected.subscription_id)) || null}
                                                                    onChange={(_: React.SyntheticEvent, value: any[]) => { setSelectedSubscription(value) }}
                                                                    renderInput={params => {
                                                                        const { InputProps, ...restParams } = params;
                                                                        const { startAdornment, ...restInputProps } = InputProps;
                                                                        return (
                                                                            <TextField
                                                                                {...restParams}
                                                                                placeholder='Select Subscription'
                                                                                InputProps={{
                                                                                    ...restInputProps,
                                                                                    startAdornment: (
                                                                                        <div style={{
                                                                                            maxHeight: "90px",
                                                                                            overflowY: 'auto',
                                                                                        }}
                                                                                        >
                                                                                            {startAdornment}
                                                                                        </div>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                } */}

                                                {selectedContactList &&
                                                    <Grid item xs={12} className={style.campaignSelectedContactBox}>
                                                        <Grid container direction={"column"} gap={"2px"}>
                                                            <Grid item>
                                                                <Typography className={style.selectedContactsTitle}>Selected Contacts</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container alignItems={"center"} gap={1}>
                                                                    <Grid item>
                                                                        <Typography className={style.selectedContactValue}>{selectedContacts.length} Contacts</Typography>
                                                                    </Grid>
                                                                    <Grid item sx={{ display: "flex", cursor: "pointer" }} onClick={() => { onClickOfInfo() }}>
                                                                        <IconsColor fillColor="#6a097d" iconName="info" height={"18"} width={"18"} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                <Grid item xs={12}>
                                                    <Grid container direction={"column"} gap={"10px"}>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                                <Grid item>
                                                                    <Typography className={style.whatsappCampaignTitleTypography}>WhatsApp Business Account <Required /></Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container gap={"8px"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => navigate("/channels/viewintegration/whatsapp")}>
                                                                        <IconsColor iconName="plus" width="14" height="14" fillColor="#6A097D" />
                                                                        {!flagTabletScreen &&
                                                                            <Grid item> <Typography className={style.whatsappCampaignPurpleTitleTypography}>New Business Account</Typography></Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                className={style.whatsappCampaignDropDowns}
                                                                options={integrationList ? integrationList.filter((list) => list.meta_integration_type_assignment_data.meta_integration_type_name === "WhatsApp") : []}
                                                                getOptionLabel={(option) => option.meta_integration_whatsapp_data ? option.meta_integration_whatsapp_data.waba_name ? option.meta_integration_whatsapp_data.waba_name : "WhatsApp Account" : ""}
                                                                value={selectedWBA}
                                                                onChange={(_, value: any) => { onChangeOfWBA(value); }}
                                                                disabled={checkDisableAllFields()}
                                                                renderInput={(params) => <TextField {...params} placeholder="Select WhatsApp Business Account" />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {selectedWBA &&
                                                    <Grid item xs={12}>
                                                        <Grid container direction={"column"} gap={"10px"}>
                                                            <Grid item xs={12}>
                                                                <Typography className={style.whatsappCampaignTitleTypography}>WhatsApp Number <Required /></Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    className={style.whatsappCampaignDropDowns}
                                                                    options={selectedWBA ? selectedWBA.meta_integration_whatsapp_data ? selectedWBA.meta_integration_whatsapp_data.meta_integration_phone_number_data : [] : []}
                                                                    getOptionLabel={(option) => option.display_phone_number}
                                                                    value={selectedPhoneNumber}
                                                                    onChange={(_, value: any) => { onChangeOfPhoneNumber(value); }}
                                                                    disabled={checkDisableAllFields()}
                                                                    renderInput={(params) => <TextField {...params} placeholder="Select WhatsApp Number" />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {selectedPhoneNumber &&
                                                    <Grid item xs={12}>
                                                        <Grid container direction={"column"} gap="10px">
                                                            <Grid item xs={12}>
                                                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                                    <Grid item>
                                                                        <Typography className={style.whatsappCampaignTitleTypography}>Message Template <Required /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container gap={"8px"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => { navigate(`/channels/viewintegration/whatsapp/templatemessages?id=${selectedWBA?.meta_integration_id}`) }}>
                                                                            <IconsColor iconName="plus" width="14" height="14" fillColor="#6A097D" />
                                                                            {!flagTabletScreen &&
                                                                                <Grid item> <Typography className={style.whatsappCampaignPurpleTitleTypography}>New Message Template</Typography></Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    className={style.whatsappCampaignDropDowns}
                                                                    options={messageTemplateList ? messageTemplateList.filter((template) => template.whatsapp_template_metadata.meta_whatsapp_template_status_name.toLowerCase() === "approved") : []}
                                                                    getOptionLabel={(option) => option.whatsapp_template_data.whatsapp_template_name}
                                                                    value={selectedMessageTemplate}
                                                                    onChange={(_, value: any) => { setSelectedMessageTemplate(value) }}
                                                                    renderInput={(params) => <TextField {...params} placeholder="Select Message Template" />}
                                                                    disabled={checkDisableAllFields()}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {selectedMessageTemplate &&
                                                    <Grid item xs={12} className={style.campaignMessageTypeBox} sx={{ width: "100%" }}>
                                                        <Grid container direction={"column"} gap={"10px"}>
                                                            <Grid item>
                                                                <Typography className={style.whatsappCampaignTitleTypography}>Message Type <Required /></Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction={"column"} gap={"5px"} className={style.messageTemplateCategoryBody}>
                                                                    <Grid item>
                                                                        <Typography className={style.messageTemplateCategoryTitle}>{selectedMessageTemplate.whatsapp_template_data.whatsapp_template_category_name}</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={style.messageTemplateCategoryDiscription}>{selectedMessageTemplate.whatsapp_template_data.whatsapp_template_category_name === "Marketing" ? "Send one-time, bulk broadcast messages to a multiple contacts at once" : "Send message to specific users when triggered from a 3rd party app"}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            {messageTemplateDetail && messageTemplateDetail.whatsapp_template_data && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data &&
                                                                messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 &&
                                                                messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data &&
                                                                messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0 &&
                                                                <>
                                                                    <Grid item>
                                                                        <Typography className={style.assignValuesTitle}>Assign Values to Template Header Variables</Typography>
                                                                    </Grid>

                                                                    <Grid item sx={{ width: "100%" }}>
                                                                        <TableContainer sx={{ boxSizing: "border-box", maxWidth: "100%", width: "100%", overflow: "auto" }}>
                                                                            <Table>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell className={style.tableHeadingFontStyle}></TableCell>
                                                                                        <TableCell className={style.tableHeadingFontStyle}>Value</TableCell>
                                                                                        <TableCell className={style.tableHeadingFontStyle}>Fallback Value</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.map((variable, ind) => {
                                                                                        return (
                                                                                            <TableRow className={style.tableRow}>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>{variable.whatsapp_template_dynamic_variable_key}</TableCell>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>
                                                                                                    <Autocomplete
                                                                                                        className={style.whatsappCampaignDropDowns}
                                                                                                        sx={{ backgroundColor: "white", width: "100%", minWidth: "200px" }}
                                                                                                        options={dropdownOptions}
                                                                                                        value={mapTheFields && mapTheFields.length > 0 ? mapTheFields[ind] : null}
                                                                                                        getOptionLabel={(option) => option}
                                                                                                        onChange={(e, value) => { handleChangeField(value, ind, ind, true) }}
                                                                                                        renderInput={(params) => <TextField {...params} placeholder="Select" />}
                                                                                                        disabled={checkDisableAllFields()}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>
                                                                                                    {(() => {

                                                                                                        const headerType = messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_type_name.toLowerCase();
                                                                                                        let fileTypeAllowed = '';

                                                                                                        switch (headerType) {
                                                                                                            case "text":
                                                                                                                return <>
                                                                                                                    <TextField
                                                                                                                        value={fallbackValue[ind]}
                                                                                                                        placeholder='Enter Value'
                                                                                                                        onChange={(e) => { handleChangeFallbackValue(e.target.value, ind, ind, false); }}
                                                                                                                        className={style.whatsappCampaignTextField}
                                                                                                                        sx={{ width: "100%", minWidth: "200px", backgroundColor: "white" }}
                                                                                                                        disabled={checkDisableAllFields()}
                                                                                                                    />
                                                                                                                </>;
                                                                                                            case "image":
                                                                                                                fileTypeAllowed = '.jpg, .jpeg';
                                                                                                                break;
                                                                                                            case "video":
                                                                                                                fileTypeAllowed = '.mp4';
                                                                                                                break;
                                                                                                            case "document":
                                                                                                                fileTypeAllowed = '.pdf';
                                                                                                                break;
                                                                                                            default:
                                                                                                                return <></>;
                                                                                                        }

                                                                                                        if (headerType === "image" || headerType === "video" || headerType === "document") {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <Grid container gap={"12px"} flexWrap={"nowrap"}>
                                                                                                                        <Grid item xs={10.5}>
                                                                                                                            <TextField
                                                                                                                                value={fallbackValue[ind]}
                                                                                                                                placeholder='Enter URL'
                                                                                                                                onChange={(e) => { handleChangeFallbackValue(e.target.value, ind, ind, true); }}
                                                                                                                                onBlur={(e) => { setHeaderVariableSampleValue(e.target.value) }}
                                                                                                                                className={style.whatsappCampaignTextField}
                                                                                                                                sx={{ width: "100%", minWidth: "200px", backgroundColor: "white" }}
                                                                                                                                disabled={checkDisableAllFields()}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                        <Grid item>
                                                                                                                            <FileUpload
                                                                                                                                uploadedFileDetails={files}
                                                                                                                                multipleFileAllow={true}
                                                                                                                                fileSizeAllowed={headerType === "video" ? 16 : 3}
                                                                                                                                fileTypeAllowed={fileTypeAllowed}
                                                                                                                                fileNumberAllowed={2}
                                                                                                                                uploadFileHandler={uploadFileHandler}
                                                                                                                                removeFileHandler={removeFileHandler}
                                                                                                                                isDeleteFileIconVisible={isFileUploaded}
                                                                                                                                flagShowJustIcon={true}
                                                                                                                                isDisabled={checkDisableAllFields()}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </>
                                                                                                            );
                                                                                                        }
                                                                                                    })()}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    })}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Grid>
                                                                </>
                                                            }
                                                            <Grid item>
                                                                <Typography className={style.assignValuesTitle}>Assign Values to Template Body Variables</Typography>
                                                            </Grid>
                                                            <Grid item sx={{ width: "100%" }}>
                                                                {messageTemplateDetail &&
                                                                    <TableContainer sx={{ boxSizing: "border-box", maxWidth: "100%", width: "100%", overflow: "auto" }}>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell className={style.tableHeadingFontStyle}></TableCell>
                                                                                    <TableCell className={style.tableHeadingFontStyle}>Value</TableCell>
                                                                                    <TableCell className={style.tableHeadingFontStyle}>Fallback Value</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data &&
                                                                                    messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.length > 0
                                                                                    ?
                                                                                    (messageTemplateDetail.whatsapp_template_data.whatsapp_template_body.whatsapp_template_body_variable_data.sort((a, b) => a.whatsapp_template_dynamic_variable_id - b.whatsapp_template_dynamic_variable_id)).map((variable, ind) => {
                                                                                        const updatedIndex = (messageTemplateDetail.whatsapp_template_data && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header && messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data &&
                                                                                            messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 &&
                                                                                            messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data &&
                                                                                            messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length > 0)
                                                                                            ? ind + messageTemplateDetail.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_variable_data.length
                                                                                            : ind;
                                                                                        return (
                                                                                            <TableRow className={style.tableRow}>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>{variable.whatsapp_template_dynamic_variable_key}</TableCell>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>
                                                                                                    <Autocomplete
                                                                                                        className={style.whatsappCampaignDropDowns}
                                                                                                        sx={{ backgroundColor: "white", width: "100%", minWidth: "200px" }}
                                                                                                        options={dropdownOptions}
                                                                                                        value={mapTheFields && mapTheFields.length > 0 ? mapTheFields[updatedIndex] : null}
                                                                                                        getOptionLabel={(option) => option}
                                                                                                        onChange={(e, value) => { handleChangeField(value, updatedIndex, ind, false) }}
                                                                                                        renderInput={(params) => <TextField {...params} placeholder="Select" />}
                                                                                                        disabled={checkDisableAllFields()}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={style.tableSubHeadingFontStyle}>
                                                                                                    <TextField
                                                                                                        value={fallbackValue[updatedIndex]}
                                                                                                        placeholder='Enter Value'
                                                                                                        onChange={(e) => { handleChangeFallbackValue(e.target.value, updatedIndex, ind, false); }}
                                                                                                        className={style.whatsappCampaignTextField}
                                                                                                        sx={{ width: "100%", minWidth: "200px", backgroundColor: "white" }}
                                                                                                        disabled={checkDisableAllFields()}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        );
                                                                                    })
                                                                                    :
                                                                                    <TableRow className={style.tableRow}>
                                                                                        <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                                                                            No Data Available
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={style.whatsappCampaignButtonGrid}>
                                    <Grid container gap={"12px"} justifyContent={"start"} alignItems={"center"}>
                                        <Grid item>
                                            <GlobalButton
                                                buttonText={"Cancel"}
                                                sx={{ width: flagTabletScreen ? "100px" : "140px" }}
                                                className='borderedButtonStyle'
                                                disabled={checkDisableCancelButton()}
                                                onClick={() => handleCancelClick()}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <GlobalButton
                                                buttonText={selectedWhatsAppCampaign ? "Update" : "Save"}
                                                sx={{ width: flagTabletScreen ? "100px" : "140px" }}
                                                disabled={checkDisableSaveButton()}
                                                className='primaryButtonStyle'
                                                onClick={() => handleSaveClick()}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <GlobalButton
                                                buttonText={"Execute"}
                                                sx={{ width: flagTabletScreen ? "100px" : "140px" }}
                                                disabled={checkDisableSaveButton(true)}
                                                className='primaryButtonStyle'
                                                onClick={() => onClickOfExecute()}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {!flagSmallScreen &&
                                    <Grid item xs={12} lg={4} xl={3.5}>
                                        <Grid container direction={"column"} justifyContent={"center"}>
                                            <Grid item>
                                                {/* <Preview createTemplatePayload={createTemplatePayload} /> */}
                                                <TemplatePreview metaInformation={createTemplatePayload} flagCampaign={true} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {flagSmallScreen &&
                                    <Grid item xs={flagSmallScreen ? 0 : 6}>
                                        {/* <PreviewMobile
                                            openSwipableDrawer={openSwipableDrawer}
                                            setOpenSwipableDrawer={setOpenSwipableDrawer}
                                            toggleDrawer={toggleDrawer}
                                        /> */}
                                        <TemplatePreviewMobile
                                            metaInformation={createTemplatePayload}
                                            flagSmallScreen={flagSmallScreen}
                                            setOpenSwipableDrawer={setOpenSwipableDrawer}
                                            openSwipableDrawer={openSwipableDrawer}
                                            toggleDrawer={toggleDrawer}
                                            flagCampaign={true}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default CreateWhatsappCampaign
