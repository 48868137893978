import { esES } from "@mui/x-date-pickers";
import ContactCard from "../components/utility/contactCard";
import globalStyles from "../assets/styles/global.module.scss";
import textTypeFileIcon from "../assets/images/icons/txtFileType.png";
import csvTypeFileIcon from "../assets/images/icons/csvFileType.png";
import mdTypeFileIcon from "../assets/images/icons/mdFileType.png";
import xmlTypeFileIcon from "../assets/images/icons/xmlFileType.png";
import pngTypeFileIcon from "../assets/images/icons/pngFileType.png";
import jpgTypeFileIcon from "../assets/images/icons/jpgFileType.png";
import jsonTypeFileIcon from "../assets/images/icons/jsonFileType.png";
import jpegTypeFileIcon from "../assets/images/icons/jpegFileType.png";
import genericFileType from "../assets/images/icons/genericFileType.png";
import IconsColor from "../components/utility/iconsColor";
import moment from "moment-timezone";
import Markdown from "react-markdown";
import { Button, Divider, Grid, Typography } from "@mui/material";
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styles from '../assets/styles/componentStyles/chatbotStyles/chats.module.scss'
import mobileStyles from '../assets/styles/componentStyles/chatbotStyles/chatsMobileResponsive.module.scss'
import Carousel from "../components/utility/globalCarousel/carousel";
import InsuranceCard from "../components/dashboard/chats/chatWidgetComponents/insuranceCard";


export const primaryColor = "#6A097D";
export const SiteKey = "6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx";
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const userNameRegex = /^[a-z ,.'-]+$/i;
export const flagUseGemini = false;
export const emptyUserDetail = {
    flagUser: null,
    timezone_id: 0,
    timezone_name: "",
    timezone_hour: "",
    timezone_standard_name: "",
    flag_user_anonymous: null,
    flag_email_verified: null,
    user_id: 0,
    user_type: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_assignment_id: 0,
    user_phone_number: "",
    user_status: {
        user_status_id: 0,
        user_status_name: ""
    },
    user_popup_actions: [],
    plan_detail: null,
    user_role: {
        user_role_id: 0,
        user_role_name: ""
    },
    user_organization_data: null
};
export const publicMenuItems = [
    {
        menuName: "AI Chatbot",
        menuKey: "chatbot",
        navigationLink: "/chatbot",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="chatbot" />,
    },
    {
        menuName: "Inbox",
        menuKey: "inbox",
        navigationLink: "/inbox",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chat" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chat" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="chat" />,
    },
    {
        menuName: "Feedback",
        menuKey: "feedback",
        navigationLink: "/feedback",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="feedback" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="feedback" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="feedback" />,
    },
    {
        menuName: "Channels",
        menuKey: "channels",
        navigationLink: `/channels`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="integration" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="integration" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="integration" />,
    },
    {
        menuName: "AI Insights",
        menuKey: "home",
        navigationLink: "/insights",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="insights" height={"18"} width={"18"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="insights" height={"18"} width={"18"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="insights" height={"18"} width={"18"} />,
    },
    {
        menuName: "Contacts",
        menuKey: "contacts",
        navigationLink: "/contacts",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="contacts" height={"18"} width={"18"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="contacts" height={"18"} width={"18"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="contacts" height={"18"} width={"18"} />,
    },
    {
        menuName: "WhatsApp Campaign",
        menuKey: "whatsappCampaign",
        navigationLink: "/whatsappcampaign",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="whatsappcampaign" height={"20"} width={"20"} />,
    },
    {
        menuName: "Call Analysis",
        menuKey: "callanalysis",
        navigationLink: `/callanalysis`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callAnalysis" height="16" width="16" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callAnalysis" height="16" width="16" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="callAnalysis" height="16" width="16" />,
    }
    // {(window.location.pathname.toLowerCase() !== "/callanalysis" && window.location.pathname.toLowerCase() !== "/callanalysis/list") && callRecordsData && callRecordsData.call_center && callRecordsData.call_center.length > 0 &&
    //     <MenuItem
    //         className={styles.menuLogout}
    //         onMouseEnter={() => setHoveredMenu("callanalysis")}
    //         onMouseLeave={() => setHoveredMenu("")}
    //         onClick={() => { handleOnClick() }}
    //     >
    //         <ListItemIcon>
    //             {hoveredMenu === "callanalysis" ? <IconsColor height={"17"} width={"17"} fillColor={"#000000"} iconName="callAnalysis" /> : <IconsColor height={"17"} width={"17"} fillColor={"#6A097D"} iconName="callAnalysis" />}
    //         </ListItemIcon>
    //         Call Analysis
    //     </MenuItem>
    // }

    // {
    //     menuName: "Organization",
    //     menuKey: "organization",
    //     navigationLink: `/organization`,
    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="organization" />,
    //     whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="organization" />,
    //     coloredImgSrc: <IconsColor fillColor="#000000" iconName="organization" />,
    // },
    // {
    //     menuName: "Settings",
    //     menuKey: "settings",
    //     navigationLink: "/settings-accountDetails",
    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="settings" />,
    //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="settings" />,
    //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="settings" />,
    // }

]
export const menuItemsJunagadh = [
    {
        menuName: "Channels",
        menuKey: "channels",
        navigationLink: `/channels`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="integration" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="integration" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="integration" />,
    },
    {
        menuName: "Contacts",
        menuKey: "contacts",
        navigationLink: "/contacts",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="contacts" height={"18"} width={"18"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="contacts" height={"18"} width={"18"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="contacts" height={"18"} width={"18"} />,
    },
    {
        menuName: "WhatsApp Campaign",
        menuKey: "whatsappCampaign",
        navigationLink: "/whatsappcampaign",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="whatsappcampaign" height={"20"} width={"20"} />,
    }
]
export const publicMenuNewItems = [
    {
        menuName: "AI Chatbot",
        menuKey: "chatbot",
        navigationLink: "/chatbot",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="chatbot" />,
    },
    {
        menuName: "Inbox",
        menuKey: "inbox",
        navigationLink: "/inbox",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chat" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="chat" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="chat" />,
    },
    {
        menuName: "Feedback",
        menuKey: "feedback",
        navigationLink: "/feedback",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="feedback" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="feedback" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="feedback" />,
    },
    {
        menuName: "Channels",
        menuKey: "channels",
        navigationLink: `/channels`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="integration" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="integration" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="integration" />,
    },
    {
        menuName: "AI Insights",
        menuKey: "home",
        navigationLink: "/insights",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="insights" height={"18"} width={"18"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="insights" height={"18"} width={"18"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="insights" height={"18"} width={"18"} />,
    },
    {
        menuName: "Contacts",
        menuKey: "contacts",
        navigationLink: "/contacts",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="contacts" height={"18"} width={"18"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="contacts" height={"18"} width={"18"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="contacts" height={"18"} width={"18"} />,
    },
    {
        menuName: "WhatsApp Campaign",
        menuKey: "whatsappCampaign",
        navigationLink: "/whatsappcampaign",
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="whatsappcampaign" height={"20"} width={"20"} />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="whatsappcampaign" height={"20"} width={"20"} />,
    },
    {
        menuName: "Call Analysis",
        menuKey: "callanalysis",
        navigationLink: `/callanalysis`,
        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callAnalysis" height="16" width="16" />,
        whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callAnalysis" height="16" width="16" />,
        coloredImgSrc: <IconsColor fillColor="#000000" iconName="callAnalysis" height="16" width="16" />,
        subMenu: [
            {
                menuName: "Dashboard",
                menuKey: "dashboard",
                // navigationLink: organization_id ? `/organization?organization_id=${organization_id}` : '/organization',
                navigationLink: '/callanalysis',
                imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callanalysisdashboard" height={"16"} width={"16"} />,
                whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callanalysisdashboard" height={"16"} width={"16"} />,
                coloredImgSrc: <IconsColor fillColor="#000000" iconName="callanalysisdashboard" height={"16"} width={"16"} />,
            },
            {
                menuName: "Call Records",
                menuKey: "callanalysislist",
                // navigationLink: organization_id ? `/organization?organization_id=${organization_id}` : '/organization',
                navigationLink: '/callanalysis/callrecords',
                imgSrc: <IconsColor fillColor="#4C4A4D" iconName="callAnalysis" height={"16"} width={"16"} />,
                whiteImgSrc: <IconsColor fillColor="#6A097D" iconName="callAnalysis" height={"16"} width={"16"} />,
                coloredImgSrc: <IconsColor fillColor="#000000" iconName="callAnalysis" height={"16"} width={"16"} />,
            }
        ]
    }
]
export const flagUseSocketForParaphrase = true;
// export const getCreditsURLWS = "wss://api.writewiz-staging.in"
export const prompts = {
    AISuggestions: [
        {
            prompt_id: 1,
            prompt_name: "SEO Keyword Generation",
            prompt_key: "seo",
            template_form_field_generic_name: "blog_seo"
        },
        {
            prompt_id: 2,
            prompt_name: "Targeted Audience Generation",
            prompt_key: "target_audience",
            template_form_field_generic_name: "blog_targeted_audience"
        },
        {
            prompt_id: 3,
            prompt_name: "Tone of Voice Generation",
            prompt_key: "tone_of_voice",
            template_form_field_generic_name: "blog_tone_of_voice"
        },
        {
            prompt_id: 18,
            prompt_name: "Blog Outline Section Header",
            prompt_key: "1::blog_outline_section_header"
        },
        {
            prompt_id: 19,
            prompt_name: "Blog Outline Sub Section",
            prompt_key: "1::blog_outline_sub_section"
        },
        {
            prompt_id: 20,
            prompt_name: "Blog Editor Generation",
            prompt_key: "1::blog_editor_generation"
        },
        {
            prompt_id: 4,
            prompt_name: "Blog Title Generation",
            template_name: "Blog Post Creation",
            prompt_key: "1::blog_title",
            prompt_output_key_name: "title"
        },
        {
            prompt_id: 261,
            prompt_name: "Basic Paraphrasing",
            prompt_output_key_name: "paraphrasing"
        },

    ],
}
export const formWizPageThemeStyle = [
    {
        "page_id": "682",
        "page_theme_font_type": "Open Sans",
        "page_theme_font_style": "normal",
        "page_theme_font_align": "left",
        "page_theme_font_weight": "bold",
        "page_theme_font_size": "14",
        "page_theme_background_color": "#4C4A4D !important",
        "page_theme_heading_color": "#EFE4F1",
        "page_theme_text_decoration": "unset",
        "page_hide_button": false,
        "page_auto_save": false,
        "page_theme_row_spacing": "10px 0px 1px 0px",
        "page_theme_column_spacing": 0,
        "margin": "0px 0px 8px 0px"
    }
]

export const errorCodes = {
    "ERR_GENERAL": {
        "code": 9000,
        "message": "Something went wrong with the server.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_BODY": {
        "code": 9001,
        "message": "Invalid body!",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_QUERY_PARAMETER": {
        "code": 9002,
        "message": "ERROR! Invalid query parameter.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_BAD_REQUEST": {
        "code": 9003,
        "message": "ERROR! bad request found.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_RECAPTCHA": {
        "code": 9004,
        "message": "ERROR! Invalid re-Captcha found.",
        "userMessage": "ERROR! Invalid re-Captcha found."
    },
    "USER_CREATED_SUCCESSFULLY": {
        "code": 9005,
        "message": "User created successfully!",
        "userMessage": "User created successfully!"
    },
    "ERR_USER_ALREADY_EXIST": {
        "code": 9006,
        "message": "This user already exist. Please try using another email address.",
        "userMessage": "This user already exist. Please try using another email address."
    },
    "ERR_USER_NOT_VERIFIED": {
        "code": 9007,
        "message": "ERROR! User not verified.",
        "userMessage": "ERROR! User not verified."
    },
    "ERR_USER_ALREADY_ACTIVE": {
        "code": 9008,
        "message": "ERROR! This user is already active.",
        "userMessage": "ERROR! This user is already active."
    },
    "ERR_USER_NOT_FOUND": {
        "code": 9009,
        "message": "ERROR! User not found.",
        "userMessage": "ERROR! User not found."
    },
    "OTP_SENT_SUCCESSFULLY": {
        "code": 9010,
        "message": "We have successfully sent a verfication code to your email address.",
        "userMessage": "We have successfully sent a verfication code to your email address."
    },
    "USER_VERIFIED_SUCCESSFULLY": {
        "code": 9011,
        "message": "Congratulations! You've been verified successfully.",
        "userMessage": "Congratulations! You've been verified successfully."
    },
    "ERR_INVALID_OTP": {
        "code": 9012,
        "message": "ERROR! Invalid code. Please input valid code.",
        "userMessage": "ERROR! Invalid code. Please input valid code."
    },
    "ERR_INVALID_EMAIL": {
        "code": 9013,
        "message": "This user does not exist in our system. Please try using another email address.",
        "userMessage": "This user does not exist in our system. Please try using another email address."
    },
    "USER_LOGGED_IN_SUCCESSFULLY": {
        "code": 9014,
        "message": "You are Logged in Successfully!",
        "userMessage": "You are Logged in Successfully!"
    },
    "USER_LOGGED_OUT_SUCCESSFULLY": {
        "code": 9015,
        "message": "You are Logged out Successfully!",
        "userMessage": "You are Logged out Successfully!"
    },
    "ERR_PUBLIC_USER_ALREADY_EXIST": {
        "code": 9016,
        "message": "ERROR! Anonymous user already exist.",
        "userMessage": "ERROR! Anonymous user already exist."
    },
    "ERR_REQ_VALUE_NOT_FOUND": {
        "code": 9017,
        "message": "ERROR! Please provide '{{field}}' for {{prompt_name}}.",
        "userMessage": "Something went wrong! Please try again later!"
    },
    "ERR_COUNT_LIMIT_EXCEEDS": {
        "code": 9018,
        "message": "ERROR! Count limit exceeds.",
        "userMessage": "ERROR! Credit limit exceeded!"
    },
    "SOCKET_CONNECT_SUCCESSFULLY": {
        "code": 9019,
        "message": "Socket connection established successfully.",
        "userMessage": ""
    },
    "ERR_ACCESS_NOT_GRANTED": {
        "code": 9020,
        "message": "ERROR! Access not granted.",
        "userMessage": "ERROR! Access not granted."
    },
    "NO_CHANGE_FOUND": {
        "code": 9021,
        "message": "No change found!",
        "userMessage": "No change found!"
    },
    "DATA_CHANGED_SUCCESSFULLY": {
        "code": 9022,
        "message": "Data updated successfully.",
        "userMessage": "Data updated successfully."
    },
    "ERR_NO_MORE_CREDIT_LEFT": {
        "code": 9023,
        "message": "Sorry!! No more credit left.",
        "userMessage": "Sorry!! No more credit left."
    },
    "CREDITS_WITHDRAWN_SUCCESSFULLY": {
        "code": 9024,
        "message": "Credits withdrawn successfully.",
        "userMessage": "Credits withdrawn successfully."
    },
    "ERR_INVALID_PROMPT_PROPERTY": {
        "code": 9025,
        "message": "ERROR! Invalid prompt property!",
        "userMessage": "ERROR! Invalid prompt property!"
    },
    "ERR_TEMPLATE_FIELD_ID_NOT_FOUND": {
        "code": 9026,
        "message": "ERROR! Template Form Field Specified is Invalid!",
        "userMessage": "ERROR! Template Form Field Specified is Invalid!"
    },
    "DATA_CREATED_SUCCESSFULLY": {
        "code": 9027,
        "message": "Data Created Successfully!",
        "userMessage": "Data Created Successfully!"
    },
    "ERR_WHILE_EXECUTING_MSSQL_QUERY": {
        "code": 9028,
        "message": "Server error occurred while executing Database query.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_WHILE_UPLODING_FILE": {
        "code": 9029,
        "message": "Error while uploding file.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_DOWNLOAD_FILE_FROM_GOOGLE": {
        "code": 9030,
        "message": "Error occurred while downloading file.",
        "userMessage": "Error occurred while downloading file."
    },
    "ERR_DELETE_FILE_FROM_GOOGLE": {
        "code": 9031,
        "message": "Error occurred while deleting file.",
        "userMessage": "Error occurred while deleting file."
    },
    "ERR_DATA_NOT_FOUND": {
        "code": 9032,
        "message": "Data not found.",
        "userMessage": "Data not found."
    },
    "ERR_DOMAIN_NOT_FOUND": {
        "code": 9033,
        "message": "Domain not found.",
        "userMessage": "Domain not found."
    },
    "ERR_INVALID_DOMAIN_NAME": {
        "code": 9034,
        "message": "Invalid domain name.",
        "userMessage": "Invalid domain name."
    },
    "ERR_ORGANIZATION_NOT_FOUND": {
        "code": 9035,
        "message": "ERROR! Organization not found.",
        "userMessage": "ERROR! Organization not found."
    },
    "ERR_USER_ALREADY_EXIST_IN_ORGANIZATION": {
        "code": 9036,
        "message": "ERROR! User is already associate with another organization.",
        "userMessage": "ERROR! User is already associate with another organization."
    },
    "ERR_EMAIL_ADDRESS_NOT_FOUND": {
        "code": 9037,
        "message": "Please provide your email address.",
        "userMessage": "Please provide your email address."
    },
    "ERR_USERNAME_NOT_FOUND": {
        "code": 9038,
        "message": "Please provide your name.",
        "userMessage": "Please provide your name."
    },
    "ERR_MESSAGE_NOT_FOUND": {
        "code": 9039,
        "message": "Please provide appropriate message.",
        "userMessage": "Please provide appropriate message."
    },
    "MESSAGE_RECIEVED_SUCCESSFULLY": {
        "code": 9040,
        "message": "Your message has been received successfully, and we will be in touch with you shortly.",
        "userMessage": "Your message has been received successfully, and we will be in touch with you shortly."
    },
    "ERR_AI_MODULE": {
        "code": 9041,
        "message": "ERROR! creating persona. Please try again after sometime.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_NO_ORGANIZATION_FOUND": {
        "code": 9042,
        "message": "ERROR! No organization found for this user.",
        "userMessage": "ERROR! No organization found for this user."
    },
    "TEMPLATE_DELETED_SUCCESSFULLY": {
        "code": 9043,
        "message": "Template deleted successfully.",
        "userMessage": "Template deleted successfully."
    },
    "ERR_EMAIL_ADDRESS_BLACKLIST": {
        "code": 9044,
        "message": "ERROR! Please provide a valid email address.",
        "userMessage": "ERROR! Please provide a valid email address."
    },
    "ERR_HEAVY_LOAD_TO_SERVER": {
        "code": 9045,
        "message": "Please try again later. Our system is currently experiencing a high server load.",
        "userMessage": "Please try again later. Our system is currently experiencing a high server load."
    },
    "ERR_INVALID_TOKEN": {
        "code": 9046,
        "message": "Token expired or invalid. Please try again later.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_ORGANIZATION": {
        "code": 9047,
        "message": "Invalid organization found.",
        "userMessage": "Invalid organization found."
    },
    "DOCUMENT_DELETED_SUCCESSFULLY": {
        "code": 9048,
        "message": "Document deleted successfully.",
        "userMessage": "Document deleted successfully."
    },
    "ERR_PERSONA_DELETED_SUCCESSFULLY": {
        "code": 9049,
        "message": "Persona deleted successfully.",
        "userMessage": "Persona deleted successfully."
    },
    "DATA_SAVED_SUCCESSFULLY": {
        "code": 9050,
        "message": "Data Saved Successfully!",
        "userMessage": "Data Saved Successfully!"
    },
    "ERR_AI_EXECUTION": {
        "code": 9051,
        "message": "Sorry, we encountered an unexpected issue processing your input. Please try again later.",
        "userMessage": "Sorry, we encountered an unexpected issue processing your input. Please try again later."
    },
    "ERR_CANNOT_DELETE": {
        "code": 9052,
        "message": "Cannot delete all documents.",
        "userMessage": "Sorry, we encountered an unexpected issue processing your input. Please try again later."
    },
    "ERR_INVALID_FILE_TYPE": {
        "code": 9053,
        "message": "Invalid file type.",
        "userMessage": "Invalid file type."
    },
    "ERR_INVALID_AUTHENTICATION_FOUND": {
        "code": 9054,
        "message": "Invalid authentication found.",
        "userMessage": "Invalid authentication found."
    },
    "ERR_ALREADY_EXISTS": {
        "code": 9055,
        "message": "Already exists",
        "userMessage": "Already exists"
    },
    "CAMPAIGN_DELETED_SUCCESSFULLY": {
        "code": 9056,
        "message": "Campaign deleted successfully.",
        "userMessage": "Campaign deleted successfully."
    },
    "ERR_SOMTHING_WANT_WRONG": {
        "code": 9057,
        "message": "Something went wrong.",
        "userMessage": "Something went wrong."
    },
    "CAMPAIGN_DOCUMNET_BEING_CREATED": {
        "code": 9058,
        "message": "Campaign documents are currently being generated. Please wait for their completion.",
        "userMessage": "Campaign documents are currently being generated. Please wait for their completion."
    },
    "DEFAULT_PERSONA_REMOVED": {
        "code": 9059,
        "message": "Persona removed from default successfully.",
        "userMessage": "Persona removed from default successfully."
    },
    "ERR_DIRECTORY_FOUND": {
        "code": 9060,
        "message": "ERROR! It looks like the directory you're trying to access doesn't exist or may have been removed.",
        "userMessage": "ERROR! It looks like the directory you're trying to access doesn't exist or may have been removed."
    },
    "ERR_CHAT_THREAD_NOT_FOUND": {
        "code": 9061,
        "message": "ERROR! It looks like the chat thread you're trying to access doesn't exist or may have been removed.",
        "userMessage": "ERROR! It looks like the chat thread you're trying to access doesn't exist or may have been removed."
    },
    "ERR_USER_CHAT_LIMIT_EXCEED": {
        "code": 9062,
        "message": "Chat Limit Reached: Please start a new conversation thread with Chat, as this one has reached the limit and won't retain past context.",
        "userMessage": "Chat Limit Reached: Please start a new conversation thread with Chat, as this one has reached the limit and won't retain past context."
    },
    "ERR_STREAM_NOT_SUPPORTED": {
        "code": 9063,
        "message": "Sorry! Stream is not supported.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "DATA_DELETED_SUCCESSFULLY": {
        "code": 9064,
        "message": "{{deleted_data_name}} deleted successfully.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_CANNOT_DELETE_PROMPT": {
        "code": 9065,
        "message": "Before deleting prompt, first delete all prompt variant & prompt property.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_PROMPT_PROPERTY_ALREADY_EXISTS": {
        "code": 9066,
        "message": "ERROR! prompt property that you are trying to add that already exists.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_CANNOT_DELTE_PROMPT_PROPERTY": {
        "code": 9067,
        "message": "ERROR! Before deleting prompt property remove property from each variant",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_INVALID_MODEL": {
        "code": 9068,
        "message": "ERROR! Invalid Model.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_PROMPT_LOG_FIELD_ALREADY_EXISTS": {
        "code": 9069,
        "message": "ERROR! prompt log field that you are trying to add that already exists.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_INVALID_PROMPT_LOG_FIELD": {
        "code": 9070,
        "message": "Invalid prompt log field found.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_USER_HEAVY_LOAD_TO_SERVER": {
        "code": 9071,
        "message": "Sorry, you've reached your daily usage limit.",
        "userMessage": "Sorry, you've reached your daily usage limit."
    },
    "ERR_FILE_SIZE_EXCEED": {
        "code": 9072,
        "message": "ERROR! Your file size is too large to upload.",
        "userMessage": "ERROR! Your file size is too large to upload."
    },
    "ERR_UPLOADING_FILE": {
        "code": 9073,
        "message": "Error occurred while uploading file.",
        "userMessage": "Error occurred while uploading file."
    },
    "ERR_INVALID_JSON_FOUND": {
        "code": 9074,
        "message": "ERROR! Invalid JSON Found.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_ALREADY_INTEGRATED": {
        "code": 9075,
        "message": "ERROR! Your account is already integrated.",
        "userMessage": "ERROR! Your account is already integrated."
    },
    "ERR_PHONE_NUMBER_NOT_FOUND": {
        code: 9076,
        message: "ERROR! Please add atleast one phone number to your WhatsApp Business Account to continue integration.",
        userMessage: "ERROR! Please add atleast one phone number to your WhatsApp Business Account to continue integration."
    },
    "ERR_PHONE_NUMBER_ALREADY_INTEGRATED": {
        code: 9077,
        message: "ERROR! This phone number is already integrated to one of the chatbot.",
        userMessage: "ERROR! This phone number is already integrated to one of the chatbot."
    },
    "ERR_TRAVEL_AGENT_ALREADY_EXIST": {
        code: 9078,
        message: "ERROR! This travel agent already exist in our system.",
        userMessage: "Something went wrong! Please try again later."
    },
    "ERR_USER_PHONE_NUMBER_ALREADY_EXIST": {
        code: 9079,
        message: "Error! The phone number linked to another Agent already exists in our system.",
        userMessage: "Error! The phone number linked to another Agent already exists in our system."
    },

    ERR_INVALID_USER_CHATBOT_ACCESS: {
        code: 9080,
        message: "Hello {{username}}, your access to this service is currently restricted. Please reach out to your service provider to continue using this service.",
        userMessage: "Hello, your access to this service is currently restricted. Please reach out to your service provider to continue using this service."
    },
    ERR_CHATBOT_SERVICE_PAUSED: {
        code: 9081,
        message: "Hello {{username}}, this service has been deactivated and is currently unavailable. For further assistance, please reach out to the service provider.",
        userMessage: "Hello, this service has been deactivated and is currently unavailable. For further assistance, please reach out to the service provider."
    },
    ERR_CHATBOT_SERVICE_EXPIRED: {
        code: 9082,
        message: "Hello {{username}}, this service is currently unavailable as it has expired. Please reach out to the service provider for further assistance."
    },
    ERR_TEMPLATE_ALREADY_EXIST: {
        code: 9083,
        message: "ERROR! Template Name Already Exist",
        userMessage: "ERROR! Template Name Already Exist"
    },
    ERR_INVALID_FUNCTION_METHOD: {
        code: 9084,
        message: "ERROR! Invalid Method found.",
        userMessage: "ERROR! Invalid Method found.",
    },
    ERR_FUNCTION_URL_NOT_FOUND: {
        code: 9085,
        message: "ERROR! Invalid URL found.",
        userMessage: "ERROR! Invalid URL found.",
    },
    ERR_DATA_CONNECTOR_FOUND_INVALID: {
        code: 9086,
        message: "ERROR! Invalid data connector found.",
        userMessage: "ERROR! Invalid data connector found.",
    },
    ERR_DC_FUNCTION_NAME_NF: {
        code: 9087,
        message: "ERROR! Data connector function name not found.",
        userMessage: "ERROR! Data connector function name not found."
    },
    ERR_DC_FUNCTION_DESC_NF: {
        code: 9088,
        message: "ERROR! Data connector function description not found.",
        userMessage: "ERROR! Data connector function description not found."
    },
    ERR_DATA_CONNECTOR_FUNCTION_FOUND_INVALID: {
        code: 9089,
        message: "ERROR! Invalid data connector function found.",
        userMessage: "ERROR! Invalid data connector function found."
    },
    ERR_HEADER_KEY_NOT_FOUND: {
        code: 9090,
        message: "Header key is missing.",
        userMessage: "Header key is missing."
    },
    ERR_HEADER_KEY_VALUE_NOT_FOUND: {
        code: 9091,
        message: "Value for the given header key is missing.",
        userMessage: "Value for the given header key is missing."
    },
    ERR_HEADER_FOUND_INVALID: {
        code: 9092,
        message: "ERROR! Invalid header.",
        userMessage: "ERROR! Invalid header."
    },
    ERR_HEADER_KEY_ALREADY_EXIST: {
        code: 9093,
        message: "The provided Header Key already exists. Please provide a unique one.",
        userMessage: "The provided Header Key already exists. Please provide a unique one."
    },
    ERR_URL_METHOD_ALREADY_EXIST: {
        code: 9094,
        message: "The provided URL '{{url}}' with method '{{method_name}}' already exists.",
        userMessage: "The provided URL already exists."
    },
    ERR_DATA_CONNECTOR_FUNCTION_NAME_ALREADY_EXIST: {
        code: 9095,
        message: "The provided function '{{function_name}}' already exists.",
        userMessage: "The provided function already exists."
    },
    ERR_FUNCTION_RESPONSE_FOUND_EMPTY: {
        code: 9096,
        message: "The provided response of the function founds to be empty.",
        userMessage: "The provided response of the function founds to be empty."
    },
    ERR_FUNCTION_PARAMS_INVALID: {
        code: 9097,
        message: "ERROR! Invalid parameter.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ERR_FUNCTION_PARAMETER_FOUND_EMPTY: {
        code: 9098,
        message: "The provided parameter of the function founds to be empty.",
        userMessage: "The provided parameter of the function founds to be empty."
    },
    ERR_PARAMS_ALREADY_EXIST: {
        code: 9099,
        message: "The provided parameter in function {{function_name}} already exist.",
        userMessage: "The provided parameter in function already exist."
    },
    ERR_PARAMS_NAME_NOT_FOUND: {
        code: 9100,
        message: "Parameter name not found in function {{function_name}}.",
        userMessage: "Parameter name not found in function"
    },
    ERR_PARAMS_DESC_NOT_FOUND: {
        code: 9101,
        message: "Parameter description not found in function {{function_name}}.",
        userMessage: "Parameter description not found in function"
    },
    ERR_PARAMS_TYPE_INVALID: {
        code: 9102,
        message: "The provided parameter type found to be invalid.",
        userMessage: "The provided parameter type found to be invalid."
    },
    ERR_FUNCTION_RESPONSE_INVALID: {
        code: 9103,
        message: "ERROR! Invalid response.",
        userMessage: "ERROR! Invalid response."
    },
    ERR_RES_NAME_NOT_FOUND: {
        code: 9104,
        message: "Response name not found in function {{function_name}}.",
        userMessage: "Response name not found in function"
    },
    ERR_RES_DESC_NOT_FOUND: {
        code: 9105,
        message: "Response description not found in function {{function_name}}.",
        userMessage: "Response description not found in function!"
    },
    ERR_RES_ALREADY_EXIST: {
        code: 9106,
        message: "The provided response in function {{function_name}} already exist.",
        userMessage: "The provided response already exist."
    },
    ERR_NOTES_CATEGORY_ALREADY_EXIST: {
        code: 9107,
        message: "ERROR! Notes Category Already Exist",
        userMessage: "ERROR! Notes Category Already Exist"
    },
    ERR_CHABOT_SUBSCRIPTION_ALREADY_EXIST: {
        code: 9108,
        message: "ERROR! The User Has Already Subscribed To The Chatbot",
        userMessage: "ERROR! The User Has Already Subscribed To The Chatbot"
    },
    CHATBOT_SUBSCRIPTION_STARTED_SUCCESSFULLY: {
        code: 9109,
        message: `Chatbot {{plan_name}} Plan Subscription Started Successfully!`,
        userMessage: "Chatbot Plan Subscription Started Successfully!"
    },
    ERR_NO_INTEGRATION_FOUND: {
        code: 9110,
        message: "ERROR! No Integration found with this chatbot.",
        userMessage: "ERROR! No Integration found with this chatbot."
    },
    WA_TEMPLATE_CREATED: {
        code: 9111,
        message: "WhatsApp template created successfully.",
        userMessage: "WhatsApp template created successfully."
    },
    ERR_INVALID_PARAMS: {
        code: 9112,
        message: "ERROR! Invalid params.",
        userMessage: "Somthing went wrong. Please try again later!"
    },
    ERR_PAGE_ALREADY_INTEGRATED: {
        code: 9113,
        message: "The Facebook page you are attempting to integrate is already connected to our system.",
        userMessage: "The Facebook page you are attempting to integrate is already connected to our system."
    },
    ERR_CHATBOT_SYSTEM_VARIABLE_ALREADY_EXISTS: {
        code: 9114,
        message: "ERROR! Chatbot system variable already exist.",
        userMessage: "ERROR! Chatbot system variable already exist."
    },
    USER_INVITED_ORG_SUCCESSFULLY: {
        code: 9115,
        message: "User invited to organization successfully.",
        userMessage: "User invited to organization successfully."
    },
    ERR_TOKEN_INVALID_EXPIRE: {
        code: 9116,
        message: "ERROR! Invitation token is invalid or expired.",
        userMessage: "ERROR! Invitation token is invalid or expired."
    },
    USER_REMOVED_SUCCESSFULLY: {
        code: 9117,
        message: "User removed successfully.",
        userMessage: "User removed successfully."
    },
    ERR_USER_ALREADY_IN_ORG: {
        code: 9118,
        message: "ERROR! User already exists in the organization.",
        userMessage: "ERROR! User already exists in the organization."
    },
    DELEGATE_ACCESS_GRANTED: {
        code: 9119,
        message: "Delegate access granted successfully.",
        userMessage: "Delegate access granted successfully."
    },
    DELEGATE_ACCESS_REVOKED: {
        code: 9120,
        message: "Delegate access revoked successfully.",
        userMessage: "Delegate access revoked successfully."
    },
    ERR_DELEGATE_ACCESS_ALREADY_GRANTED: {
        code: 9121,
        message: "ERROR! Delegate access is already granted.",
        userMessage: "ERROR! Delegate access is already granted."
    },
    ERR_DELEGATE_ACCESS_ALREADY_REVOKED: {
        code: 9122,
        message: "ERROR! Delegate access is already revoked.",
        userMessage: "ERROR! Delegate access is already revoked."
    },
    ERR_DELEGATE_ACCESS_INVALID: {
        code: 9123,
        message: "ERROR! Invalid user, delegate access can not be granted.",
        userMessage: "ERROR! Invalid user, delegate access can not be granted."
    },
    CALENDLY_INTEGRATED_SUCCESSSFULLY: {
        code: 9124,
        message: "Calendly integrated successfully.",
        userMessage: "Calendly integrated successfully."
    },
    ERR_PERSISTENT_MENU_ALREADY_EXISTS: {
        code: 9125,
        message: "ERROR! Persistent menu already exist.",
        userMessage: "ERROR! Persistent menu already exist."
    },
    ERR_DC_FUNCTION_RESPONSE: {
        code: 9126,
        message: "Invalid response. Reason: {{reason}}.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ERR_DC_FUNCTION_PARAMS: {
        code: 9127,
        message: "Invalid parameters. Reason: {{reason}}.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ACUITY_INTEGRATED_SUCCESSSFULLY: {
        code: 9128,
        message: "Acuity integrated successfully.",
        userMessage: "Acuity integrated successfully."
    },
    ERR_INVALID_INTEGRATION_TYPE: {
        code: 9129,
        message: "ERROR! Invalid integration type.",
        userMessage: "Something went wrong! Please try again later."
    },
    "INVALID_JSON_FROM_BE": {
        "code": 1110,
        "message": "Oops! Something went wrong! Please try again later.",
        "userMessage": "Oops! Something went wrong! Please try again later."
    },
    Nothing_MATCHED: {
        code: 1111,
        message: "No code matched!!",
        userMessage: "Something went wrong! Please try again later."
    },

}
export function isDev(userDetail) {
    if (userDetail && userDetail.user_role) {
        return (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Super Admin" || userDetail.user_role.user_role_name === "Admin");
    }
    return false;
}
export function isDelegateAccessUserAdmin(userDetail) {
    if (userDetail?.user_delegate_access_flag && isAdmin(userDetail?.user_delegate_access_data)) {
        return true
    }
    return false
}
export function isAdmin(userDetail) {
    if (isDelegateAccessUserAdmin(userDetail)) {
        return true
    } else if (userDetail && userDetail.user_role) {
        return (userDetail.user_role.user_role_name === "Super Admin" || userDetail.user_role.user_role_name === "Admin");
    }
    return false;
}
export function getStripeProduct(userDetail) {
    if (userDetail?.plan_detail?.credit_usage_data?.stripe_product_name === "Premium" && userDetail?.plan_detail?.credit_usage_data?.stripe_customer_subscription_interval === "10 days") {
        return "Free Premium"
    }
    else if (userDetail?.plan_detail?.credit_usage_data?.stripe_product_name === "Free") {
        return "Free"
    }
    else if (userDetail?.plan_detail?.credit_usage_data?.stripe_product_name === "Premium") {
        return "Premium"
    }
    else {
        return "Anonymous"
    }
}
export function getErrorMessage(code, flagDeveloper) {
    for (const key in errorCodes) {
        if (errorCodes[key].code === code) {
            const errorCode = errorCodes[key];
            return flagDeveloper
                ? errorCode.message : errorCode.userMessage
        }
    }
    // If code is not found, default to Nothing_MATCHED
    const nothingMatched = errorCodes.Nothing_MATCHED;
    return flagDeveloper ? nothingMatched.message : nothingMatched.userMessage;
}
export function flagCreditsExhausted(errorCode) {
    return (errorCode === 9023 || errorCode === 9071) ? true : false
}
export function flagCookieExpired(userDetail, errorCode) {
    if (userDetail.flagUser) {
        if (errorCode === -999) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
export function checkForErrorCode(response) {
    let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.response?.data?.error?.statusCode || response?.data?.error?.code || response?.data?.error?.statusCode || response) : response) : (response?.error?.code || response?.error.statusCode);
    return errorCode;
}
export function ifUserMessageShouldBeDynamic(errorCode) {
    let dynamicErrorCode = [9017, 9064, 9080, 9081, 9082, 9094, 9095, 9099, 9100, 9101, 9104, 9105, 9106, 9109, 9126, 9127, 100]
    if (dynamicErrorCode.includes(errorCode)) {
        return true
    } else {
        return false
    }
}
export function invalidQueryParameter(response) {
    let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.data?.error?.code || response) : response) : response?.error?.code;
    if (errorCode == 9002 || errorCode == 9032) {
        return true
    }
}
export function takeUserToPricingPlan(userDetail) {
    if (!userDetail.flag_user_anonymous && userDetail.flag_email_verified) {
        if (
            userDetail.user_role.user_role_name == "Developer" ||
            userDetail.user_role.user_role_name == "Super Admin" ||
            userDetail.user_role.user_role_name == "Admin"
        ) {
            return { url: "https://writewiz.ai/pricing", toNavigate: false }
        } else {
            return { url: "/dashboard/settings-plansAndBilling", toNavigate: true }
        }
    } else {
        return { url: "https://writewiz.ai/pricing", toNavigate: false }
    }
}
export function getChatbotMessageTag(chatbotThread, textType) {
    switch (textType) {
        case "text":
            return chatbotThread.chatbot_thread_value
        case "image":
            return <img className={globalStyles.clickable}
                src={chatbotThread?.chatbot_thread_value} alt="img" style={{}} />
        case "contact":
            return <ContactCard contactInfo={chatbotThread.contact_information} />
        case "location":
            return <div style={{ display: "flex", flexDirection: "column" }}><iframe src={chatbotThread?.chatbot_thread_value} title="location" style={{ border: 0 }} allowFullScreen loading="lazy" ></iframe><span><a rel="noreferrer" target='blank' href={chatbotThread?.chatbot_thread_value}>{chatbotThread?.chatbot_thread_value}</a></span></div>
        default:
            return chatbotThread.chatbot_thread_value
    }

}

export const patternToRemoveSource = /【\d+†source】/g;

export function isJsonString(str) {
    let replaceStr = str.replace(/\n(?=\s*[{[(])/g, '').replace(/(?<=[}\])])\n/g, '') // Remove \n before and after starting brackets
    try {
        JSON.parse(replaceStr);
    } catch (e) {
        return false;
    }
    return true;
}

export function getParseData(data) {
    let replaceStr = data.replace(/\n(?=\s*[{[(])/g, '').replace(/(?<=[}\])])\n/g, '') // Remove \n before and after starting brackets
    try {
        return JSON.parse(replaceStr);
    } catch (e) {
        return "";
    }
}
// export function hexToRgba(hex) {
//     let alpha = 0.2
//     // Remove the hash at the start if it's there
//     hex = hex.replace(/^#/, '');
//     // Parse r, g, b values
//     let r = parseInt(hex.substring(0, 2), 16);
//     let g = parseInt(hex.substring(2, 4), 16);
//     let b = parseInt(hex.substring(4, 6), 16);
//     // Ensure alpha is between 0 and 1
//     if (alpha < 0) alpha = 0;
//     if (alpha > 1) alpha = 1;
//     return `rgba(${r}, ${g}, ${b}, ${alpha})`;
// }
export function hexToRgba(hex, alpha = 0.2) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Check if the hex value is 3 characters and expand to 6 characters
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
    }

    // Parse r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Ensure alpha is between 0 and 1
    if (alpha < 0) alpha = 0;
    if (alpha > 1) alpha = 1;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
// export function getTag(chatbotThread, textType) {
//     switch (textType) {
//         case "text":
//             return <Markdown
//                 remarkPlugins={[remarkGfm]}
//                 rehypePlugins={[
//                     [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
//                 ]}
//                 className={styles.reactMarkdownStyle}>
//                 {chatbotThread?.chatbot_thread_value?.replace(patternToRemoveSource, "")}
//             </Markdown>
//         case "media":
//             return (
//                 <>
//                     {
//                         chatbotThread.media_data.map((media) => {
//                             return (
//                                 <>
//                                     <div style={{ position: "relative" }}>
//                                         <span style={{
//                                             position: "absolute", top: "0", right: "0", background: "#ECEFF1", borderRadius: "20%", padding: "7px"
//                                         }}>
//                                             <a href={media.aws_storage_media_url} download="">
//                                                 <IconsColor iconName='download' width="30px" height="30px" fillColor="#000" />
//                                             </a>
//                                         </span>
//                                         <div style={{ color: "#7A7A7A", marginBottom: "10px" }}><Markdown
//                                             remarkPlugins={[remarkGfm]}
//                                             rehypePlugins={[
//                                                 [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
//                                             ]}
//                                         >{media.aws_storage_file_name}</Markdown></div>
//                                         <img className={"clickable imgAWSStyle"}
//                                             loading={"lazy"}
//                                             src={media.aws_storage_media_url} alt="img"
//                                             style={{ width: '100%', height: '100%', borderRadius: "10px" }} />
//                                         <Divider sx={{ margin: "5px 0px", opacity: "0.7", color: "#D5D7DB" }} />
//                                     </div>
//                                 </>
//                             )
//                         })
//                     }
//                     <div><Markdown>{chatbotThread.chatbot_thread_value?.replace(patternToRemoveSource, "")}</Markdown></div>
//                 </>
//     )}}
export function getMessage(threadData) {
    threadData.chatbot_thread_value = threadData?.chatbot_thread_value?.replace("```json", "").replace("```", "")
    if (isJsonString(threadData.chatbot_thread_value)) {
        let thread = JSON.parse(threadData.chatbot_thread_value)
        if (thread.response) {
            thread = thread.response
        }
        if (typeof (thread) == "object") {
            if (thread.message_format_id) {
                if (thread.message_format_id == 1) {
                    if (thread?.message_fields?.message) {
                        if (Array.isArray(thread?.message_fields?.message)) {
                            return JSON.stringify(thread?.message_fields?.message.join("&nbsp;"))
                        } else {
                            return thread?.message_fields?.message ?? threadData.chatbot_thread_value
                        }
                    } else {
                        return threadData.chatbot_thread_value
                    }
                }
                else if (thread.message_format_id == 4 || thread.message_format_id == 3) {
                    return thread.message_fields?.url_text ?? threadData.chatbot_thread_value
                }
                else if (Math.trunc(thread.message_format_id) == 5 || Math.trunc(thread.message_format_id) == 6 || Math.trunc(thread.message_format_id) == 7 || Math.trunc(thread.message_format_id) == 8 || Math.trunc(thread.message_format_id) == 9 || Math.trunc(thread.message_format_id) == 10 || Math.trunc(thread.message_format_id) == 12 || Math.trunc(thread.message_format_id) == 13 || Math.trunc(thread.message_format_id) == 15 || Math.trunc(thread.message_format_id) == 16 || Math.trunc(thread.message_format_id) == 17) {
                    return thread.message_reply_text ?? threadData.chatbot_thread_value
                }
                else {
                    return ((!thread?.message && !thread?.additional_text_message && !thread?.message_fields?.additional_text_message) ? threadData?.chatbot_thread_value : ((thread?.message) ? thread?.message : "") + " " + ((thread?.additional_text_message) ? thread?.additional_text_message : "" + " " + ((thread?.message_fields?.additional_text_message ? thread?.message_fields?.additional_text_message : ""))))
                }
            }
            else {
                return threadData.chatbot_thread_value
            }
        }
        else {
            return threadData.chatbot_thread_value
        }
    }
    else {
        return threadData.chatbot_thread_value
    }
}

export function getTag(chatbotThread, textType, initialMessagePreivewOnField) {
    if (chatbotThread) {
        let message_format_id = 1
        let thread = chatbotThread.chatbot_thread_value
        if (chatbotThread.chatbot_route_source && chatbotThread.chatbot_route_source.toLowerCase() == "whatsapp") {
            if (isJsonString(chatbotThread.chatbot_thread_value)) {
                let parsedJson = JSON.parse(chatbotThread.chatbot_thread_value);
                if (typeof (parsedJson) === "object") {
                    if (parsedJson.message_format_id) {
                        if (parsedJson.message_format_id === 1) {
                            return (
                                <>
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[
                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                        ]}
                                        className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                                    >
                                        {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                    </Markdown>
                                </>
                            );
                        }
                        return thread;
                    } else {
                        return (
                            <>
                                <Markdown
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[
                                        [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                    ]}
                                    className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                                >
                                    {parsedJson?.message ? (parsedJson.message)?.replace(patternToRemoveSource, "") : thread}
                                </Markdown>
                            </>
                        )
                    }
                }
                return thread;
            }
            return thread
        } else {
            if (isJsonString(chatbotThread.chatbot_thread_value)) {
                thread = JSON.parse(chatbotThread.chatbot_thread_value)
                if (thread.response) {
                    thread = thread.response
                }
                if (typeof (thread) == "object" && thread.message_format_id === 4) {
                    message_format_id = 4
                } else {
                    message_format_id = Math.trunc(thread.message_format_id)
                }
            }
            if (chatbotThread) {
                switch (textType) {
                    case "text":
                        return (
                            <>
                                {
                                    message_format_id !== 4
                                        ?
                                        <>
                                            <Markdown
                                                remarkPlugins={[remarkGfm]}
                                                rehypePlugins={[
                                                    [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                                ]}
                                                className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                                            >
                                                {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                            </Markdown>
                                        </>
                                        :
                                        <>
                                            <div style={{ fontStyle: "italic", textDecoration: "underline", cursor: "pointer", color: "blue" }}>
                                                {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                            </div>
                                        </>
                                }
                            </>
                        )
                    case "media":
                        return (
                            <>
                                {chatbotThread.media_data.map((media, index) => {
                                    return (
                                        <>
                                            {initialMessagePreivewOnField ? (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                                                <Grid item className={styles.chat_message_reply_left_item} sx={{ mt: '2px' }}>
                                                                    <Typography component="span" sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}>
                                                                        {index === 0 && (
                                                                            <span>
                                                                                {getMessage(chatbotThread) ? (
                                                                                    <span>{getMessage(chatbotThread)}</span>
                                                                                ) : (
                                                                                    <span>
                                                                                        <CameraAltIcon
                                                                                            style={{
                                                                                                color: 'rgba(0, 0, 0, 0.5)',
                                                                                                verticalAlign: 'middle'
                                                                                            }}
                                                                                        />
                                                                                        &nbsp;Photo
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item className={styles.chat_img_reply_item}>
                                                                    <img
                                                                        className={"clickable chat-reply-img"}    //here
                                                                        loading={"lazy"}
                                                                        src={chatbotThread.media_data.length > 1 ? chatbotThread.media_data[0].aws_storage_media_url : media.aws_storage_media_url}
                                                                        alt="img"
                                                                        style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    {/* OLD CODE */}
                                                    <div style={{ position: "relative", cursor: "pointer" }} className="chat-reply-main-wrapper">   {/* here*/}
                                                        <div
                                                            style={{
                                                                color: "#7A7A7A",
                                                                marginBottom: "0px",
                                                                fontSize: initialMessagePreivewOnField ? "14px" : '',
                                                                fontWeight: initialMessagePreivewOnField ? "500" : ''
                                                            }}
                                                        >
                                                            <Markdown
                                                                remarkPlugins={[remarkGfm]}
                                                                rehypePlugins={[
                                                                    [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                                ]}
                                                            >{media.aws_storage_file_name}</Markdown>
                                                        </div>

                                                        <div className={"chat-response-div"}> {/* here */}
                                                            <img src={media.aws_storage_media_url}
                                                                alt="uploadedimage"
                                                                className={"clickable imgAWSStyle test"}
                                                                style={{ margin: '5px 0px 5px', width: "100%" }} />
                                                        </div>

                                                        <Divider sx={{ margin: "5px 0px" }} />
                                                    </div>

                                                    <div className={initialMessagePreivewOnField ? styles.chat_reply_meadia_text : ''}>
                                                        <Markdown
                                                            remarkPlugins={[remarkGfm]}
                                                            rehypePlugins={[
                                                                [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                            ]}
                                                        >
                                                            {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                                        </Markdown>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )
                                })
                                }
                            </>

                        )

                    case "messageReply": return (
                        <>
                            {chatbotThread.media_data.map((media) => {
                                return (
                                    <>
                                        {media?.aws_storage_id ?
                                            (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                                                <Grid item xs={10} className={styles.chat_media_reply_text_item}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Markdown
                                                                                remarkPlugins={[remarkGfm]}
                                                                                rehypePlugins={[
                                                                                    [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                                                                ]}
                                                                                className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text} ${styles.chat_chatbot_text_hidden_label}`}
                                                                            >
                                                                                {chatbotThread?.chatbot_thread_value?.replace(patternToRemoveSource, "")}
                                                                            </Markdown>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={1.5} className={styles.chat_media_reply_img_item}>
                                                                    <img
                                                                        className={"clickable"}
                                                                        loading={"lazy"}
                                                                        src={media.aws_storage_media_url}
                                                                        alt="img"
                                                                        style={{
                                                                            borderRadius: '0px 10px 10px 0px',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Markdown
                                                                remarkPlugins={[remarkGfm]}
                                                                rehypePlugins={[
                                                                    [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                                                ]}
                                                                className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text} ${styles.chat_chatbot_text_hidden_label}`}
                                                            >
                                                                {/* {getMessage(chatbotThread)?.chatbot_thread_value?.replace(patternToRemoveSource, "")} */}
                                                                {chatbotThread?.chatbot_thread_value?.replace(patternToRemoveSource, "")}
                                                            </Markdown>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            })}
                        </>)

                    case "contact":
                        return <ContactCard contactInfo={chatbotThread.contact_information} />
                    case "location":
                        return <div style={{ display: "flex", flexDirection: "column" }}><iframe src={`${chatbotThread?.chatbot_thread_value}`} title="location" style={{ border: 0 }} allowFullScreen loading="lazy" ></iframe><span><a rel="noreferrer" target='blank' href={chatbotThread?.chatbot_thread_value}>{chatbotThread?.chatbot_thread_value}</a></span></div>
                    default:
                        return <Markdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[
                                [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                            ]}
                        >{chatbotThread?.chatbot_thread_value}</Markdown>
                }
            }
        }

    }

}

// export function getTag(chatbotThread, textType) {
//     switch (textType) {
//         case "text":
//             return <Markdown
//                 remarkPlugins={[remarkGfm]}
//                 rehypePlugins={[
//                     [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
//                 ]}
//                 className={styles.reactMarkdownStyle}>
//                 {chatbotThread?.chatbot_thread_value.replace(patternToRemoveSource, "")}
//             </Markdown>
//         case "media":
//             return (
//                 <>
//                     {
//                         chatbotThread.media_data.map((media) => {
//                             return (
//                                 <>
//                                     <div style={{ position: "relative" }}>
//                                         <span style={{
//                                             position: "absolute", top: "0", right: "0", background: "#ECEFF1", borderRadius: "20%", padding: "7px"
//                                         }}>
//                                             <a href={media.aws_storage_media_url} download="">
//                                                 <IconsColor iconName='download' width="30px" height="30px" fillColor="#000" />
//                                             </a>
//                                         </span>
//                                         <div style={{ color: "#7A7A7A", marginBottom: "10px" }}><Markdown
//                                             remarkPlugins={[remarkGfm]}
//                                             rehypePlugins={[
//                                                 [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
//                                             ]}
//                                         >{media.aws_storage_file_name}</Markdown></div>
//                                         <img className={"clickable imgAWSStyle"}
//                                             loading={"lazy"}
//                                             src={media.aws_storage_media_url} alt="img"
//                                             style={{ width: '100%', height: '100%', borderRadius: "10px" }} />
//                                         <Divider sx={{ margin: "5px 0px", opacity: "0.7", color: "#D5D7DB" }} />
//                                     </div>
//                                 </>
//                             )
//                         })
//                     }
//                     <div><Markdown>{chatbotThread.chatbot_thread_value.replace(patternToRemoveSource, "")}</Markdown></div>
//                 </>

//             )
//         case "messageReply":
//             return (
//                 <Grid container direction={"column"}>
//                     <Grid item marginBottom={1}>
//                         <Grid container direction={"row"} gap={1} flexWrap={"wrap"}>
//                             {chatbotThread && chatbotThread.media_data && chatbotThread.media_data.map((media) => {
//                                 return (
//                                     <Grid item>
//                                         {media?.aws_storage_id &&
//                                             <img
//                                                 className={"clickable"}
//                                                 loading={"lazy"}
//                                                 src={media.aws_storage_media_url}
//                                                 alt="img"
//                                                 style={{
//                                                     borderRadius: '10px 10px 10px 10px',
//                                                     maxWidth: '100%',
//                                                     height: "100%",
//                                                     objectFit: 'fill'
//                                                 }}
//                                             />
//                                         }
//                                     </Grid>
//                                 )
//                             })}
//                         </Grid>
//                     </Grid>
//                     <Grid item>
//                         <Grid container>
//                             <Grid item xs={12}>
//                                 <Markdown
//                                     remarkPlugins={[remarkGfm]}
//                                     rehypePlugins={[
//                                         [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
//                                     ]}
//                                     className={styles.reactMarkdownStyle}
//                                 >
//                                     {chatbotThread?.chatbot_thread_value.replace(patternToRemoveSource, "")}
//                                 </Markdown>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             )

//         case "contact":
//             return <ContactCard contactInfo={chatbotThread.contact_information} />
//         //   case "form":
//         //     return <ChatbotFormCard />
//         //   case "question": 
//         //     return <>
//         //         <Markdown className={styles.reactMarkdownStyle}>{chatbotThread?.chatbot_thread_value.replace(patternToRemoveSource, "")}</Markdown>
//         //         <ChatbotMessageTemplate questiondata={chatbotThread?.chatbot_question_data}/>
//         //     </>
//         case "location":
//             return <div style={{ display: "flex", flexDirection: "column" }}><iframe src={`${chatbotThread?.chatbot_thread_value}`} title="location" style={{ border: 0 }} allowFullScreen loading="lazy" ></iframe><span><a rel="noreferrer" target='blank' href={chatbotThread?.chatbot_thread_value}>{chatbotThread?.chatbot_thread_value}</a></span></div>
//         default:
//             return <Markdown
//                 remarkPlugins={[remarkGfm]}
//                 rehypePlugins={[
//                     [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
//                 ]}
//                 className={styles.reactMarkdownStyle}>{chatbotThread?.chatbot_thread_value}</Markdown>
//     }
// }

export function formatTime(dateString, current) {
    const date = new Date(dateString);

    if (current === "active") {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        return `${formattedTime}`;
    }
    else {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().substring(2);

        return `${day}/${month}/${year}`;
    }

}
export function formatDate(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().substring(2);

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${day}-${month}-${year} ${formattedTime}`;
}
export function formatDateAccordingToTimezoneUsingMoment(dateString, timeZone, flag) {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    let formattedDate = moment.utc(dateString, dateFormat);

    if (timeZone) {
        const offsetMatch = timeZone.match(/^UTC([+-]\d{1,2}):?(\d{2})?$/);
        if (offsetMatch) {
            const offsetHours = parseInt(offsetMatch[1], 10);
            const offsetMinutes = parseInt(offsetMatch[2] || 0, 10);
            const totalOffsetMinutes = offsetHours * 60 + offsetMinutes;
            formattedDate = formattedDate.utcOffset(totalOffsetMinutes);
        }
    }
    if (flag === "closed") {
        return formattedDate.format("DD-MMM-YY");
    } else if (flag === "active") {
        return formattedDate.format("HH:mm");
    } else {
        return formattedDate.format("DD-MMM-YY HH:mm");
    }
}
export function formatDateAccordingToTimezone(dateString, timeZone, flag) {
    if (dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart ? datePart.split('-') : [];
        const [hours, minutes, seconds] = timePart ? timePart.split(':') : [];

        if (!year || !month || !day || !hours || !minutes) {
            console.log(`Invalid date passed: ${dateString}`);
            return "-";
        }

        const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

        if (isNaN(date.getTime())) {
            console.log(`Invalid date passed: ${dateString}`);
            return "-";
        }

        const options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        const locale = 'default';
        const formattedString = date.toLocaleString(locale, options);
        const dateTimeParts = formattedString ? formattedString.split(', ') : [];
        const formattedDate = dateTimeParts.length > 1 ? dateTimeParts[0] + ' ' + dateTimeParts[1] : "";
        const formattedTime = dateTimeParts.length > 2 ? dateTimeParts[2] : "";
        const [formattedMonth, formattedDay, formattedYear] = formattedDate ? formattedDate.split(' ') : [];
        const [formattedHour, formattedMinuteAndAmPm] = formattedTime ? formattedTime.split(':') : [];
        const [formattedMinute, ampm] = formattedMinuteAndAmPm ? formattedMinuteAndAmPm.split(' ') : [];

        if (flag === "closed") {
            return `${formattedDay || '00'}/${formattedMonth || 'MMM'}/${formattedYear || '00'}`;
        } else if (flag === "active") {
            return `${formattedHour || '00'}:${formattedMinute || '00'} ${ampm || 'AM/PM'}`;
        } else {
            return `${formattedDay || '00'}-${formattedMonth || 'MMM'}-${formattedYear || '00'} ${formattedHour || '00'}:${formattedMinute || '00'} ${ampm || 'AM/PM'}`;
        }
    } else {
        console.log(`Invalid date passed: ${dateString}`);
        return "-";
    }
}
export const getFileTypeImgIcon = (fileType) => {
    switch (fileType) {
        case 'txt':
            return textTypeFileIcon
        case 'md':
            return mdTypeFileIcon
        case 'json':
            return jsonTypeFileIcon;
        case 'csv':
            return csvTypeFileIcon;
        case 'png':
            return pngTypeFileIcon;
        case 'jpg':
            return jpgTypeFileIcon;
        case 'xml':
            return xmlTypeFileIcon;
        case 'jpeg':
            return jpegTypeFileIcon;
        default:
            return genericFileType;
    }
}
export const chatbotThemes = [
    {
        "chatbot_theme_name": "Default",
        "chatbot_font_color": "#F7E9FF",
        "chatbot_back_ground_color": "#FFFFFF",
        "system_msg_color": "#000000",
        "user_msg_color": "#000000",
        "system_msg_bg_color": "#FFF",
        "user_msg_bg_color": "#F7E9FF",
        // 'chatbot_send_message_bg_color': "#f7e9ff80",
        'chatbot_send_message_bg_color': "#FFF",
        'chatbot_send_message_font_color': "#000000",
        'chatbot_send_message_icon_color': "#6A097D",
        'chatbot_header_color': "#F7E9FF",
        'chatbot_header_font_color': "#000000"

    },
    {
        "chatbot_theme_name": "Dark",
        "chatbot_font_color": "#fff",
        "chatbot_back_ground_color": "#FFF",
        "system_msg_color": "#000",
        "user_msg_color": "#fff",
        "system_msg_bg_color": "#fff",
        "user_msg_bg_color": "#000",
        'chatbot_send_message_bg_color': "#fff",
        'chatbot_send_message_font_color': "#000",
        'chatbot_send_message_icon_color': "#000",
        'chatbot_header_color': "#202C33",
        'chatbot_header_font_color': "#F7E9FF"
    },
    {
        "chatbot_theme_name": "Green",
        "chatbot_font_color": "#000000",
        "chatbot_back_ground_color": "#FFFFFF",
        "system_msg_color": "#000000",
        "user_msg_color": "#000000",
        "system_msg_bg_color": "#fff",
        "user_msg_bg_color": "#D9F1DF",
        'chatbot_send_message_bg_color': "#fff",
        'chatbot_send_message_font_color': "#000000",
        'chatbot_send_message_icon_color': "#11BB4B",
        'chatbot_header_color': "rgba(217, 241, 223, 1)",
        'chatbot_header_font_color': "rgba(14, 174, 69, 1)"
    },
    {
        "chatbot_theme_name": "Blue",
        "chatbot_font_color": "#000000",
        "chatbot_back_ground_color": "#FFFFFF",
        "system_msg_color": "#000000",
        "user_msg_color": "#000000",
        "system_msg_bg_color": "#fff",
        "user_msg_bg_color": "#DAE9FA",
        'chatbot_send_message_bg_color': "#FFFFFF",
        'chatbot_send_message_font_color': "#7A7A7A",
        'chatbot_send_message_icon_color': "#0A6ADD",
        'chatbot_header_color': "#DAE9FA",
        'chatbot_header_font_color': "#0A6ADD"
    },
    {
        "chatbot_theme_name": "Gray",
        "chatbot_font_color": "#000000",
        "chatbot_back_ground_color": "#FFFFFF",
        "system_msg_color": "#000000",
        "user_msg_color": "#000000",
        "system_msg_bg_color": "#fff",
        "user_msg_bg_color": "#F3F3F3",
        'chatbot_send_message_bg_color': "#FFFFFF",
        'chatbot_send_message_font_color': "#000000",
        'chatbot_send_message_icon_color': "#5C5C5C",
        'chatbot_header_color': "#F3F3F3",
        'chatbot_header_font_color': "#5E5E5E"
    },
    {
        "chatbot_theme_name": "Yellow",
        "chatbot_font_color": "#000000",
        "chatbot_back_ground_color": "#FFFFFF",
        "system_msg_color": "#000000",
        "user_msg_color": "#000000",
        "system_msg_bg_color": "#FFF",
        "user_msg_bg_color": "#F9F7E4",
        'chatbot_send_message_bg_color': "#FFFFFF",
        'chatbot_send_message_font_color': "#7A7A7A",
        'chatbot_send_message_icon_color': "#E2BD3A",
        'chatbot_header_color': "#F9F7E4",
        'chatbot_header_font_color': "#E5B91C"
    },
]
// export const chatbotThemes = [
//     {
//         "chatbot_theme_name": "Default",
//         "chatbot_font_color": "#F7E9FF",
//         "chatbot_back_ground_color": "#FFFFFF",
//         "system_msg_color": "#000000",
//         "user_msg_color": "#000000",
//         "system_msg_bg_color": "#F7E9FF",
//         "user_msg_bg_color": "#F3F5F9",
//         'chatbot_send_message_bg_color': "#f7e9ff80",
//         'chatbot_send_message_font_color': "#000000",
//         'chatbot_send_message_icon_color': "#6A097D",
//         'chatbot_header_color': "#F7E9FF",
//         'chatbot_header_font_color': "#000000"

//     },
//     {
//         "chatbot_theme_name": "Dark",
//         "chatbot_font_color": "#202C33",
//         "chatbot_back_ground_color": "#0B141A",
//         "system_msg_color": "#FFFFFF",
//         "user_msg_color": "#FFFFFF",
//         "system_msg_bg_color": "#202C33",
//         "user_msg_bg_color": "#005C4B",
//         'chatbot_send_message_bg_color': "#2A3942",
//         'chatbot_send_message_font_color': "#FFFFFF",
//         'chatbot_send_message_icon_color': "#F7E9FF",
//         'chatbot_header_color': "#202C33",
//         'chatbot_header_font_color': "#F7E9FF"
//     },
//     {
//         "chatbot_theme_name": "Green",
//         "chatbot_font_color": "#F0F2F5",
//         "chatbot_back_ground_color": "#EFEAE2",
//         "system_msg_color": "#000000",
//         "user_msg_color": "#000000",
//         "system_msg_bg_color": "#FFFFFF",
//         "user_msg_bg_color": "#D9FDD3",
//         'chatbot_send_message_bg_color': "#FFFFFF",
//         'chatbot_send_message_font_color': "#000000",
//         'chatbot_send_message_icon_color': "#64737C",
//         'chatbot_header_color': "#F0F2F5",
//         'chatbot_header_font_color': "#000000"
//     },
//     {
//         "chatbot_theme_name": "Blue",
//         "chatbot_font_color": "#FFFFFF",
//         "chatbot_back_ground_color": "#FFFFFF",
//         "system_msg_color": "#000000",
//         "user_msg_color": "#FFFFFF",
//         "system_msg_bg_color": "#F0F0F0",
//         "user_msg_bg_color": "#2D5EFF",
//         'chatbot_send_message_bg_color': "#F0F0F0",
//         'chatbot_send_message_font_color': "#000000",
//         'chatbot_send_message_icon_color': "#0F75FF",
//         'chatbot_header_color': "#FFFFFF",
//         'chatbot_header_font_color': "#000000"
//     },
//     {
//         "chatbot_theme_name": "Premium",
//         "chatbot_font_color": "#B97B58",
//         "chatbot_back_ground_color": "#010001",
//         "system_msg_color": "#000000",
//         "user_msg_color": "#000000",
//         "system_msg_bg_color": "#D9D8D6",
//         "user_msg_bg_color": "#B97B58",
//         'chatbot_send_message_bg_color': "#FAFAFA",
//         'chatbot_send_message_font_color': "#000000",
//         'chatbot_send_message_icon_color': "#B97B58",
//         'chatbot_header_color': "#B97B58",
//         'chatbot_header_font_color': "#000000"
//     },
//     {
//         "chatbot_theme_name": "Black & White",
//         "chatbot_font_color": "#FFFFFF",
//         "chatbot_back_ground_color": "#000000",
//         "system_msg_color": "#000000",
//         "user_msg_color": "#000000",
//         "system_msg_bg_color": "#FFFFFF",
//         "user_msg_bg_color": "#FFFFFF",
//         'chatbot_send_message_bg_color': "#FFFFFF",
//         'chatbot_send_message_font_color': "#000000",
//         'chatbot_send_message_icon_color': "#000000",
//         'chatbot_header_color': "#FFFFFF",
//         'chatbot_header_font_color': "#000000"
//     }

// ]
function normalizeColor(color) {
    if (color && typeof color == "string") {
        return color.toLowerCase().trim();
    }
}

export function checkForMatchingTheme(state) {
    for (const theme of chatbotThemes) {
        let allMatch = true;

        for (const item of state) {
            if (theme.hasOwnProperty(item.id) && item.id !== "chatbot_theme_name") {
                if (normalizeColor(theme[item.id]) !== normalizeColor(item.value)) {
                    // console.log(`Mismatch found for theme: ${theme.chatbot_theme_name}`);
                    // console.log(`Checking property: ${item.id}, Theme value: ${theme[item.id]}, State value: ${item.value}`);
                    allMatch = false;
                    break;
                }
            }
        }

        if (allMatch) {
            return theme.chatbot_theme_name;
        }
    }
    return null;
}
export function isValidWebsite(website) {
    return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(website);
}

export function flagAccessNotGranted(userDetail, errorCode) {
    if (userDetail.flagUser) {
        if (errorCode === 9020) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
export function flagDataNotFound(userDetail, errorCode) {
    if (userDetail.flagUser) {
        if (errorCode === 9032) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export const messageFormats = [
    {
        message_format_id: 2,
        message_format_name: "Suggestion for Quick Replies"
    },
    {
        message_format_id: 3,
        message_format_name: "Open URL in new Tab"
    },
    {
        message_format_id: 4,
        message_format_name: "Open URL in iframe in Popup Modal"
    },
    {
        message_format_id: 5,
        message_format_name: "Doctor Cards Carousel"
    },
]

export function formatCallAnalysisTimeAccordingToTimezoneUsingMoment(dateString, timeZone) {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    let formattedDate = moment.utc(dateString, dateFormat);

    // if (timeZone) {
    //     const offsetMatch = timeZone.match(/^UTC([+-]\d{1,2}):?(\d{2})?$/);
    //     if (offsetMatch) {
    //         const offsetHours = parseInt(offsetMatch[1], 10);
    //         const offsetMinutes = parseInt(offsetMatch[2] || 0, 10);
    //         const totalOffsetMinutes = offsetHours * 60 + offsetMinutes;
    //         formattedDate = formattedDate.utcOffset(totalOffsetMinutes);
    //     }
    // }

    return formattedDate.format("DD-MM-YY HH:mm");
}
export function convertKeysToLowerCase(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertKeysToLowerCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
        const lowerCaseKey = key.toLowerCase();
        acc[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
        return acc;
    }, {});
}
export function extractTime(key) {
    // Check if the key is in the format "2024-09-13T10:00:00.000Z"
    if (typeof key === 'string' && key.includes('T') && key.includes('Z')) {
        // Split the string and extract the time part "10:00"
        return key.split('T')[1].substring(0, 5);
    }
    // If the key is already in "10:00" format or doesn't match the first pattern, return "10:00"
    return key;
}
export function flagShowHomeButton() {
    if (window.location.pathname.toLowerCase() !== "/" && window.location.pathname.toLowerCase() !== "/chatbot" && window.location.pathname.toLowerCase() !== "/inbox" && window.location.pathname.toLowerCase() !== "/feedback" && window.location.pathname.toLowerCase() !== "/channels" && window.location.pathname.toLowerCase() !== "/insights" && window.location.pathname.toLowerCase() !== "/contacts" && window.location.pathname.toLowerCase() !== "/whatsappcampaign" && window.location.pathname.toLowerCase() !== "/callanalysis" && window.location.pathname.toLowerCase() !== "/callanalysis") {
        return true
    }
    return false
}