import { Autocomplete, Checkbox, Grid, IconButton, ListItemIcon, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { campaignContact, campaignContactData, campaignContactTagAssignment, getCampaignContactListResponse, getCampaignContactPayload, updateCampaignContactFieldPyload } from '../../../models/axiosModel'
import { ContactContext, ContactContextPayload } from '../../../context/contactContext'
import style from '../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss'
import GlobalButton from '../../utility/globalButton/button';
import RefreshIcon from '@mui/icons-material/Refresh';
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss';
import { checkForErrorCode, formatDateAccordingToTimezoneUsingMoment, getErrorMessage, isDev } from '../../../utils/constants'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import globalStrenameDocumentyles from "../../../assets/renameDocument.svg";
import deleteDoc from "../../../assets/deleteDoc.svg";
import globalStyles from "../../../assets/styles/global.module.scss";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GlobalPopUp from '../../utility/globalPopUp'
import ContactFilterPopup from './contactFilterPopup'
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import EditFieldPopup from './editFieldPopup';
import DeleteIcon from '@mui/icons-material/Delete';
import IconsColor from '../../utility/iconsColor'

interface ContactTableProps {
    deleteCampaignContact: (contacts: campaignContact[]) => void,
    editCampaignContact: (contacts: campaignContact[]) => void,
    selectedCampaignContactList: getCampaignContactListResponse,
    getCampaignContactApiCall: (list_id: number) => void;
    updateCampaignContactFieldApiCall: (payload: updateCampaignContactFieldPyload) => void,
    getListOfCampaignFieldTypeApiCall: () => void,
}

export interface nameData {
    first_name_field_id: number,
    last_name_field_id: number,
    first_name: string,
    last_name: string,
    full_name: string,
}

export interface sortConfig {
    key: string,
    direction: string,
}

const ContactTable: React.FC<ContactTableProps> = ({ deleteCampaignContact, editCampaignContact, selectedCampaignContactList, getCampaignContactApiCall, updateCampaignContactFieldApiCall, getListOfCampaignFieldTypeApiCall }) => {

    const { campaignContactData, setCampaignContactData, deleteCampaignContactApi, getFilteredContactApi, filteredCampaignContactData, setFilteredCampaignContactData } = useContext(ContactContext) as ContactContextPayload;
    const { flagLoading, setFlagLoading, setError, error, userDetail } = useContext(UserContext) as UserContextPayload;


    const [phoneNumberData, setPhoneNumberData] = useState<campaignContactData[]>([]);
    const [nameData, setNameData] = useState<nameData[]>([]);
    const [tagData, setTagData] = useState<campaignContactTagAssignment[]>([]);

    const [selectedNumberData, setSelectedNumberData] = useState<campaignContactData[]>([]);
    const [selectedNameData, setSelectedNameData] = useState<nameData[]>([]);
    const [selectedTagData, setSelectedTagData] = useState<campaignContactTagAssignment[]>([]);

    const [selectContact, setSelectContact] = useState<campaignContact[]>([]);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);

    const [flagFilterDialog, setFlagFilterDialog] = useState<boolean>(false);
    const [flagApplyFilter, setFlagApplyFilter] = useState<boolean>(false);

    const [hoveredHeader, setHoveredHeader] = useState<number | null>(null);
    const [flagEditFieldDialog, setFlagEditFieldDialog] = useState<boolean>(false);
    const [requiredField, setRequiredField] = useState<campaignContactData | null>(null);

    const [sortConfig, setSortConfig] = useState<sortConfig>({ key: "", direction: '' });
    const [changeField, setChangeField] = useState("");

    const innerHeight = (window.innerHeight - 280);
    const flagBelow1400Screen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1400px)');
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const screen600to1300 = useMediaQuery('(min-width: 600px) and (max-width: 1300px)');
    const mobileScreen = useMediaQuery('(min-width: 0px) and (max-width: 600px)');


    const menuItem = [
        {
            label: "Edit",
            icon: <img alt="" src={globalStrenameDocumentyles} />,
            action: (contact: campaignContact) => {
                const contactArray: campaignContact[] = Array(1).fill(contact);
                editCampaignContact(contactArray);
            }
        },
        {
            label: "Delete",
            icon: <img alt="" src={deleteDoc} />,
            action: (contact: campaignContact) => {
                const contactArray: campaignContact[] = Array(1).fill(contact);
                deleteCampaignContact(contactArray);
            }
        }
    ]

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
        setCurrentRowIndex(rowIndex);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setCurrentRowIndex(null);
        setAnchorEl(null);
    };

    const getFilteredCampaignContactPayload = () => {

        const campaign_data = [];
        const tag_data: number[] = [];
        const phoneNumberData = selectedNumberData.map((number) => {
            return ({
                contact_data_value: number.contact_data_value,
            })
        });
        const firstNameData = selectedNameData.map((name) => {
            return (
                { contact_data_value: name.first_name, }
            );
        })
        const lastNameData = selectedNameData.map((name) => {
            return (
                { contact_data_value: name.last_name, }
            );
        })
        const tagsData = selectedTagData.map((tag) => {
            tag_data.push(tag.contact_tag_id);
        })

        if (phoneNumberData.length > 0) {
            const obj = {
                contact_field_id: selectedNumberData[0].contact_field_id,
                contact_field_value: phoneNumberData,
            }
            campaign_data.push(obj);
        }
        if (firstNameData.length > 0) {
            const obj_firstName = {
                contact_field_id: selectedNameData[0].first_name_field_id,
                contact_field_value: firstNameData,
            }
            const obj_lastName = {
                contact_field_id: selectedNameData[0].first_name_field_id,
                contact_field_value: lastNameData,
            }
            campaign_data.push(obj_firstName);
            campaign_data.push(obj_lastName);
        }
        const payload: getCampaignContactPayload = {
            contact_list_id: selectedCampaignContactList.contact_list_id,
            contact_data: campaign_data,
            contact_tag_id: tag_data,
        }
        return payload;
    }

    const getFilteredContactApiCall = async (payload: getCampaignContactPayload) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await getFilteredContactApi(payload);
        if (!(response.status)) {
            let errorCode = checkForErrorCode(response)
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail)),
            });
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const filterCampaignContact = () => {
        if (campaignContactData) {
            const filtered_contacts = campaignContactData.map((campaignResponse) => {
                const filteredContacts = campaignResponse.contact.filter((contact) => {
                    // return (
                    //     contact.contact_data.some((data) =>
                    //         selectedNumberData.some((phone) => phone.contact_data_value === data.contact_data_value) || selectedNameData.some((name) => name.first_name === data.contact_data_value) || selectedNameData.some((name) => name.last_name === data.contact_data_value)
                    //     )
                    //     ||
                    //     contact.contact_tag_assignment.some((tag) =>
                    //         selectedTagData.some((tags) => tag.contact_tag_name === tags.contact_tag_name)
                    //     )
                    // ); 
                    const matchesNumberData =
                        selectedNumberData.length === 0 ||
                        selectedNumberData.some((phone) =>
                            contact.contact_data.some(
                                (data) => phone.contact_data_value === data.contact_data_value
                            )
                        );
                    const matchesNameData =
                        selectedNameData.length === 0 ||
                        selectedNameData.some((name) =>
                            contact.contact_data.some(
                                (data) => {
                                    const isFirstNameMatch = data.contact_field_name === "First Name" && selectedNameData.some((name) => name.first_name === data.contact_data_value);
                                    const isLastNameMatch = data.contact_field_name === "Last Name" && selectedNameData.some((name) => name.last_name === data.contact_data_value);
                                    return isFirstNameMatch || isLastNameMatch;
                                })
                        );
                    const matchesTagData =
                        selectedTagData.length === 0 ||
                        selectedTagData.some((tags) =>
                            contact.contact_tag_assignment.some(
                                (tag) => tag.contact_tag_name === tags.contact_tag_name
                            )
                        );
                    return matchesNumberData && matchesNameData && matchesTagData;
                });
                return {
                    ...campaignResponse,
                    contact: filteredContacts,
                };
            });
            setFilteredCampaignContactData(filtered_contacts);

            if (filtered_contacts?.[0]?.contact) {
                const filteredContactIds = new Set(
                    filtered_contacts[0].contact.map((contact) => contact.contact_id)
                );
                setSelectContact((prevSelected) =>
                    prevSelected.filter((contact) => filteredContactIds.has(contact.contact_id))
                );
            }
        }
    }

    useEffect(() => {
        if (selectedNumberData.length > 0 || selectedNameData.length > 0 || selectedTagData.length > 0) {
            filterCampaignContact();
        }
        if (campaignContactData && campaignContactData.length > 0) {
            let phoneNumber = [], name = [], tag: campaignContactTagAssignment[] = [];

            for (let i = 0; i < campaignContactData.length; ++i) {
                for (let j = 0; j < campaignContactData[i].contact.length; ++j) {
                    let nameObj: nameData = {
                        first_name_field_id: 0,
                        last_name_field_id: 0,
                        first_name: "",
                        last_name: "",
                        full_name: "",
                    }
                    for (let k = 0; k < campaignContactData[i].contact[j].contact_data.length; ++k) {
                        let data = campaignContactData[i].contact[j].contact_data[k];
                        if (data.contact_field_name === "Phone Number") {
                            phoneNumber.push(data);
                        }
                        else if (data.contact_field_name === "First Name") {
                            nameObj.first_name = data.contact_data_value;
                            nameObj.first_name_field_id = data.contact_field_id;
                        }
                        else if (data.contact_field_name === "Last Name") {
                            nameObj.last_name = data.contact_data_value;
                            nameObj.last_name_field_id = data.contact_field_id;
                        }
                    }
                    if (nameObj.first_name === "" && nameObj.last_name != "") nameObj.full_name = nameObj.last_name;
                    else if (nameObj.first_name != "" && nameObj.last_name === "") nameObj.full_name = nameObj.first_name;
                    else if (nameObj.first_name != "" && nameObj.last_name != "") nameObj.full_name = nameObj.first_name + " " + nameObj.last_name;
                    else nameObj.full_name = "";

                    if (nameObj.full_name.length > 0) {
                        name.push(nameObj)
                    };

                    for (let k = 0; k < campaignContactData[i].contact[j].contact_tag_assignment.length; ++k) {
                        if (!tag.find((tg) => tg.contact_tag_id === campaignContactData[i].contact[j].contact_tag_assignment[k].contact_tag_id)) {
                            tag.push(campaignContactData[i].contact[j].contact_tag_assignment[k]);
                        }
                    }
                }
            }
            setPhoneNumberData(phoneNumber);
            setNameData(name);
            setTagData(tag);
        }
    }, [campaignContactData])


    useEffect(() => {
        if (selectedNumberData.length > 0 || selectedNameData.length > 0 || selectedTagData.length > 0) {
            // const payload = getFilteredCampaignContactPayload();
            // getFilteredContactApiCall(payload);  
            if (flagApplyFilter) {
                filterCampaignContact();
                setFlagApplyFilter(false);
            }
        } else {
            if (campaignContactData) {
                if (flagApplyFilter) {
                    setFilteredCampaignContactData(campaignContactData);
                    setFlagApplyFilter(false);
                }
            }
        }
    }, [selectedNumberData, selectedNameData, selectedTagData])

    useEffect(() => {
        if (filteredCampaignContactData) {
            if (sortConfig.key.length > 0 && sortConfig.direction.length > 0) {
                sortContactData(sortConfig.key, sortConfig.direction);
            }
        }
    }, [filteredCampaignContactData])

    const sortContactData = (key: string, direction: string) => {
        if (filteredCampaignContactData) {
            const filtered_contacts = filteredCampaignContactData.map((item) => {
                const filter_contact = item.contact.sort((a, b) => {
                    let aValue, bValue;
                    if (key === "Created At") {
                        aValue = a.created_at_timestamp || "";
                        bValue = b.created_at_timestamp || "";
                    } else {
                        aValue = a.contact_data.find((it) => it.contact_field_name === key)?.contact_data_value || "";
                        bValue = b.contact_data.find((it) => it.contact_field_name === key)?.contact_data_value || "";
                    }
                    if (aValue === "" && bValue === "") {
                        return 0;
                    }
                    if (aValue === "") {
                        return direction === 'ascending' ? -1 : 1;
                    }
                    if (bValue === "") {
                        return direction === 'ascending' ? 1 : -1;
                    }
                    if (aValue < bValue) {
                        return direction === 'ascending' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
                return {
                    ...item,
                    contact: filter_contact,
                };
            })
            setFilteredCampaignContactData(filtered_contacts);
        }
    }

    const handleSelectContact = (data: campaignContact) => {
        let contact = selectContact;
        if (contact.find((item) => item.contact_id === data.contact_id)) {
            let updatedContact = contact.filter((item) => item.contact_id != data.contact_id);
            setSelectContact(updatedContact);
        } else {
            setSelectContact([...selectContact, data]);
        }
    }

    const handleAllChanges = () => {
        if (filteredCampaignContactData) {
            if (selectContact.length === filteredCampaignContactData[0].contact.length) {
                setSelectContact([]);
            } else {
                setSelectContact(filteredCampaignContactData[0].contact);
            }
        }
    }

    const sortFields = (fields: campaignContactData[]) => {
        return fields.sort((a, b) => a.contact_field_id - b.contact_field_id);
    }

    const handleRefreshButtonClick = () => {
        const id = selectedCampaignContactList?.contact_list_id;
        if (id) {
            getCampaignContactApiCall(id);
            setSortConfig({ key: "", direction: "" });
        }
    }

    const downloadCSVFile = () => {
        // if (filteredCampaignContactData && filteredCampaignContactData.length > 0 && filteredCampaignContactData[0].contact && filteredCampaignContactData[0].contact.length > 0) {
        if (campaignContactData && campaignContactData[0].contact.length > 0) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const headersSet = new Set<string>();
            campaignContactData.forEach(list =>
                list.contact.forEach(contact =>
                    contact.contact_data.forEach(field =>
                        headersSet.add(field.contact_field_name)
                    )
                )
            );
            const headers = Array.from(headersSet);

            const rows = campaignContactData.flatMap(list =>
                list.contact.map(contact => {
                    const row: string[] = [];
                    headers.forEach(header => {
                        const field = contact.contact_data.find(
                            field => field.contact_field_name === header
                        );
                        row.push(field?.contact_data_value || "");
                    });
                    return row;
                })
            );
            const list_name = selectedCampaignContactList.contact_list_name;

            const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
            const blob = new Blob([csvContent], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `campaign_contact_of_${list_name}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        // else {
        //     setError({
        //         ...error,
        //         flagShowError: true,
        //         message: "No data found for the selected filters.",
        //     });
        // }
    }

    const sortData = (key: string) => {
        setChangeField(key);
        let dir = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            dir = 'descending';
        }
        if (filteredCampaignContactData) {
            sortContactData(key, dir);
            setSortConfig({ key: key, direction: dir });
        }
    }

    return (
        <>

            <GlobalPopUp
                open={flagFilterDialog}
                onClose={() => setFlagFilterDialog(false)}
                handleClose={() => setFlagFilterDialog(false)}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <ContactFilterPopup
                    setFlagFilterDialog={setFlagFilterDialog}
                    setFlagApplyFilter={setFlagApplyFilter}
                    filterCampaignContact={filterCampaignContact}
                    phoneNumberData={phoneNumberData}
                    selectedNumberData={selectedNumberData}
                    setSelectedNumberData={setSelectedNumberData}
                    nameData={nameData}
                    selectedNameData={selectedNameData}
                    setSelectedNameData={setSelectedNameData}
                    tagData={tagData}
                    selectedTagData={selectedTagData}
                    setSelectedTagData={setSelectedTagData}
                />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagEditFieldDialog}
                onClose={() => setFlagEditFieldDialog(false)}
                handleClose={() => setFlagEditFieldDialog(false)}
                flagShowCloseIcon={true}
                paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <EditFieldPopup
                    setFlagNewFieldDialog={setFlagEditFieldDialog}
                    getListOfCampaignFieldTypeApiCall={getListOfCampaignFieldTypeApiCall}
                    updateCampaignContactFieldApiCall={updateCampaignContactFieldApiCall}
                    requiredField={requiredField as campaignContactData}
                    flagEdit={true}
                />
            </GlobalPopUp>
            <Grid container>
                <Grid container justifyContent={"space-between"} alignItems={"center"} gap={2}>
                    <Grid item>
                        <Grid container gap={2}>
                            {flagBelow1400Screen ?
                                <Grid item>
                                    <Typography sx={{ fontSize: screen600to1300 ? "15px" : "14px", fontWeight: "600" }}>Contact List</Typography>
                                </Grid>
                                :
                                <>
                                    <Grid item>
                                        <Autocomplete
                                            className={style.contactFilterDropDowns}
                                            options={phoneNumberData}
                                            getOptionLabel={(option) => option.contact_data_value}
                                            multiple
                                            value={phoneNumberData && phoneNumberData.length > 0 && phoneNumberData.filter((data) => selectedNumberData.some(number => number.contact_data_id === data.contact_data_id)) || []}
                                            onChange={(_: React.SyntheticEvent, value: campaignContactData[]) => { setFlagApplyFilter(true); setSelectedNumberData(value); }}
                                            renderInput={params => {
                                                const { InputProps, ...restParams } = params;
                                                const { startAdornment, ...restInputProps } = InputProps;
                                                return (
                                                    <TextField
                                                        {...restParams}
                                                        placeholder='Search by Phone Number'
                                                        InputProps={{
                                                            ...restInputProps,
                                                            startAdornment: (
                                                                <div style={{
                                                                    maxHeight: "79px",
                                                                    overflowY: 'auto',
                                                                }}
                                                                >
                                                                    {startAdornment}
                                                                </div>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            className={style.contactFilterDropDowns}
                                            options={nameData}
                                            getOptionLabel={(option) => option.full_name}
                                            multiple
                                            value={nameData && nameData.length > 0 && nameData.filter((data) => selectedNameData.some(fullName => (fullName.full_name === data.full_name))) || []}
                                            onChange={(_: React.SyntheticEvent, value: nameData[]) => { setFlagApplyFilter(true); setSelectedNameData(value) }}
                                            renderInput={params => {
                                                const { InputProps, ...restParams } = params;
                                                const { startAdornment, ...restInputProps } = InputProps;
                                                return (
                                                    <TextField
                                                        {...restParams}
                                                        placeholder='Search by Name'
                                                        InputProps={{
                                                            ...restInputProps,
                                                            startAdornment: (
                                                                <div style={{
                                                                    maxHeight: "79px",
                                                                    overflowY: 'auto',
                                                                }}
                                                                >
                                                                    {startAdornment}
                                                                </div>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            className={style.contactFilterDropDowns}
                                            options={tagData}
                                            getOptionLabel={(option) => option.contact_tag_name}
                                            multiple
                                            value={tagData && tagData.length > 0 && tagData.filter((data) => selectedTagData.some(tag => tag.contact_tag_id === data.contact_tag_id)) || []}
                                            onChange={(_: React.SyntheticEvent, value: campaignContactTagAssignment[]) => { setFlagApplyFilter(true); setSelectedTagData(value) }}
                                            renderInput={params => {
                                                const { InputProps, ...restParams } = params;
                                                const { startAdornment, ...restInputProps } = InputProps;
                                                return (
                                                    <TextField
                                                        {...restParams}
                                                        placeholder='Search by Tags'
                                                        InputProps={{
                                                            ...restInputProps,
                                                            startAdornment: (
                                                                <div style={{
                                                                    maxHeight: "79px",
                                                                    overflowY: 'auto',
                                                                }}
                                                                >
                                                                    {startAdornment}
                                                                </div>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container gap={1}>
                            {selectContact.length > 0 &&
                                <Grid item>
                                    {mobileScreen ?
                                        <GlobalButton
                                            buttonText={`(${selectContact.length})`}
                                            className='deleteButton'
                                            startIcon={<DeleteIcon sx={{ color: "white" }} />}
                                            sx={{ padding: "6px 10px !important" }}
                                            onClick={() => deleteCampaignContact(selectContact)}
                                        />
                                        :
                                        <GlobalButton
                                            buttonText={`Delete (${selectContact.length})`}
                                            className='deleteButton'
                                            sx={{ padding: "6px 20px !important" }}
                                            onClick={() => deleteCampaignContact(selectContact)}
                                        />
                                    }
                                </Grid>
                            }
                            {flagBelow1400Screen &&
                                <Grid item>
                                    <Tooltip title="Filter" placement="bottom">
                                        <IconButton
                                            className={style.headerButtonStyle}
                                            onClick={() => { setFlagFilterDialog(true); }}
                                        >
                                            <FilterAltOutlinedIcon sx={{ color: "#6a097d" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid item>
                                <Tooltip title="Download" placement="bottom">
                                    <IconButton
                                        className={style.headerButtonStyle}
                                        onClick={() => downloadCSVFile()}
                                    >
                                        <DownloadIcon sx={{ color: "#6a097d" }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Refresh" placement="bottom">
                                    <IconButton
                                        className={style.headerButtonStyle}
                                        onClick={() => { handleRefreshButtonClick(); }}
                                    >
                                        <RefreshIcon sx={{ color: "#6a097d" }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container mt={2}>
                    <Grid item xs={12}>
                        {campaignContactData && campaignContactData.length > 0 &&
                            <TableContainer sx={{ borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", boxSizing: "border-box", maxHeight: `${innerHeight}px !important` }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>
                                                <Checkbox
                                                    checked={(filteredCampaignContactData && filteredCampaignContactData.length > 0 && filteredCampaignContactData[0].contact.length > 0) ? (selectContact.length === filteredCampaignContactData[0]?.contact.length ? true : false) : false}
                                                    onChange={(e) => handleAllChanges()}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={(filteredCampaignContactData && filteredCampaignContactData.length === 0) ? true : false}
                                                    color="secondary"
                                                    className={style.checkBoxContact}
                                                />
                                            </TableCell>
                                            {campaignContactData && campaignContactData.length > 0 && campaignContactData[0].contact[0].contact_data &&
                                                sortFields(campaignContactData[0].contact[0].contact_data).map((field) => (
                                                    <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}
                                                        key={field.contact_field_id}
                                                        onMouseEnter={() => { if (!field.flag_default && !flagSmallScreen) setHoveredHeader(field.contact_field_id) }}
                                                        onMouseLeave={() => { if (!field.flag_default && !flagSmallScreen) setHoveredHeader(null) }}
                                                        onClick={() => { sortData(field.contact_field_name) }}
                                                        sx={{ cursor: "pointer" }}
                                                    >
                                                        <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={"8px"} sx={{ width: "100% !important" }}>
                                                            <Grid item>
                                                                {field.contact_field_name}
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction={"column"}>
                                                                    <Typography style={{ color: sortConfig.direction === "ascending" && field.contact_field_name === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "10px", cursor: "pointer", height: "12px" }}>▲</Typography>
                                                                    <Typography style={{ color: sortConfig.direction === "descending" && field.contact_field_name === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "10px", cursor: "pointer", height: "12px" }}>▼</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                {(hoveredHeader === field.contact_field_id || (!field.flag_default && flagSmallScreen)) &&
                                                                    <EditIcon className={style.headerEditIcon} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setRequiredField(field); setFlagEditFieldDialog(true) }} />
                                                                }
                                                            </Grid>

                                                        </Grid>
                                                    </TableCell>
                                                ))
                                            }
                                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Tags</TableCell>
                                            <TableCell className={dashboardStyles.tableBodyHeadingFontStyle} sx={{ cursor: "pointer" }} onClick={() => sortData("Created At")}>
                                                <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={"8px"} sx={{ width: "100% !important" }}>
                                                    <Grid item>
                                                        Created At
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={"column"}>
                                                            <Typography style={{ color: sortConfig.direction === "ascending" && "Created At" === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "10px", cursor: "pointer", height: "12px" }}>▲</Typography>
                                                            <Typography style={{ color: sortConfig.direction === "descending" && "Created At" === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "10px", cursor: "pointer", height: "12px" }}>▼</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${style.lastColumnSticky}`} sx={{ background: "#fbf4ff !important" }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(filteredCampaignContactData && filteredCampaignContactData.length > 0 && filteredCampaignContactData[0].contact && filteredCampaignContactData[0].contact.length > 0) ?
                                            filteredCampaignContactData[0].contact.map((data, ind) => {
                                                return (
                                                    <TableRow className={dashboardStyles.cellRow}>
                                                        <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>
                                                            <Checkbox
                                                                color='secondary'
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                checked={selectContact.find((contact) => contact.contact_id === data.contact_id) ? true : false}
                                                                onChange={(e) => handleSelectContact(data)}
                                                                className={style.checkBoxContact}
                                                            />
                                                        </TableCell>
                                                        {sortFields(data.contact_data).map((field) => (
                                                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{field.contact_data_value.length > 0 ? field.contact_data_value : "-"}</TableCell>
                                                        ))}
                                                        <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>
                                                            <Grid item>
                                                                <Grid container gap={1} flexWrap={"nowrap"}>
                                                                    {data.contact_tag_assignment.length > 0 ? data.contact_tag_assignment.map((tag) => {
                                                                        return (
                                                                            <Grid item className={style.tagContainerGrid}>
                                                                                <Typography className={style.tagNameTypography}>{tag.contact_tag_name}</Typography>
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                        :
                                                                        "-"
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{formatDateAccordingToTimezoneUsingMoment(data.created_at_timestamp, userDetail.timezone_hour)}</TableCell>
                                                        <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${style.lastColumnSticky}`} sx={{ background: "white !important" }}>
                                                            <MoreVertIcon
                                                                sx={{ cursor: "pointer" }}
                                                                onClick={(e: any) => handleMenuOpen(e, data.contact_id)}
                                                            />
                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                keepMounted
                                                                open={Boolean(anchorEl) && data.contact_id === currentRowIndex}
                                                                onClose={handleMenuClose}
                                                            >
                                                                {menuItem.map((item, index) => {
                                                                    return (
                                                                        <MenuItem
                                                                            className={globalStyles.menuItemStyle}
                                                                            key={index}
                                                                            sx={{ fontSize: "14px" }}
                                                                            onClick={(e) => { handleMenuClose(); item.action(data); }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                {item.icon}
                                                                            </ListItemIcon>
                                                                            <span>
                                                                                {item.label}
                                                                            </span>
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Menu>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            :
                                            <TableRow className={dashboardStyles.cellRow}>
                                                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactTable
