import { Grid, useMediaQuery } from '@mui/material';
import React from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { PieChart } from '@mui/x-charts';
import { campaignContactAssignmentData } from '../../../models/axiosModel';


const CampaignAnalysisPieChart = (props: any) => {
    const { fieldName, fieldData, roundOffData } = props;

    const campaignStatusData = [
        {
            campaign_status_key: "Sent",
            campaign_status_show_value: "Sent Contacts:",
            campaign_status_value: fieldData?.total_sent_campaign_contact || 0,
            campaign_status_percentage: roundOffData(fieldData?.total_sent_campaign_contact || 0, fieldData?.total_campaign_contact || 1)
        },
        {
            campaign_status_key: "Pending",
            campaign_status_show_value: "Pending Contacts:",
            campaign_status_value: fieldData?.total_pending_campaign_contact || 0,
            campaign_status_percentage: roundOffData(fieldData?.total_pending_campaign_contact || 0, fieldData?.total_campaign_contact || 1)
        },
        {
            campaign_status_key: "Failed",
            campaign_status_show_value: "Failed Contacts:",
            campaign_status_value: fieldData?.total_failed_campaign_contact || 0,
            campaign_status_percentage: roundOffData(fieldData?.total_failed_campaign_contact || 0, fieldData?.total_campaign_contact || 1)
        }
    ]

    const clickedReplyStatus = [
        {
            campaign_status_key: "Clicked",
            campaign_status_value: 0
        },
        {
            campaign_status_key: "Replied",
            campaign_status_value: 0
        },
    ]

    const categories = fieldName === "Campaign Status" ? campaignStatusData.map((item: any) => `${item.campaign_status_show_value}`) : clickedReplyStatus.map((item: any) => `${item.campaign_status_show_value}`);
    const scores = fieldName === "Campaign Status" ? campaignStatusData.map((item: any) => item.campaign_status_value) : clickedReplyStatus.map((item: any) => item.campaign_status_value);

    const pieData = categories.map((category: any, index: number) => ({
        id: index,
        label: category,
        value: scores[index],
    }));

    // const complexityColor = ['#EAC5FF', '#81B2EC', '#A1E3CB', '#A8E0FF'];
    const campaignStatusColor = [
        {
            key: "Sent",
            value: fieldData?.total_sent_campaign_contact || 0,
            color: '#00A54A'
        },
        {
            key: "Pending",
            value: fieldData?.total_pending_campaign_contact || 0,
            color: '#DECC00'
        },
        {
            key: "Failed",
            value: fieldData?.total_failed_campaign_contact || 0,
            color: '#DF0000'
        },
    ];
    const clickedRepliedColor = ['#C55EFF', '#348AF0'];
    // const categoriesColor = ['#EAC5FF', '#81B2EC', '#A1E3CB', '#A8E0FF','#BAB3E2', '#CBD5E8', '#CCCCCC', '#F1E2CC', '#FFF2AE'];

    const pieParams = {
        slotProps: { legend: { hidden: true } }
    }

    const smallScreen = useMediaQuery('(max-width : 500px) and (min-width : 0px)');
    const tabletScreen = useMediaQuery('(max-width : 750px) and (min-width : 600px)');
    const laptopScreen = useMediaQuery('(max-width : 1450px) and (min-width : 1200px)');

    return (
        <Grid container direction={"row"} alignItems={"center"} className={dashboardStyles.pieChartContainer} flexWrap={"nowrap"} gap={2}>
            <Grid item xs={5.5} sm={6} sx={{ width: "100%" }} className={dashboardStyles.pieContainer}>
                <Grid container direction={"column"} gap={"10px"}>
                    <Grid item sx={{ width: "100%" }}>
                        <span className={dashboardStyles.pieChartHeadingName} style={{ whiteSpace: "nowrap" }}>{`${fieldName} (Contacts - ${fieldData?.total_campaign_contact})`}</span>
                    </Grid>
                    <Grid item mt={{ lg: 2 }}>
                        <PieChart
                            className={dashboardStyles.pieChartsStyle}
                            height={smallScreen || tabletScreen || laptopScreen ? 300 : 200}
                            colors={fieldName === "Campaign Status" ? campaignStatusColor.filter((status) => status.value > 0).map((data) => data.color) : clickedRepliedColor}
                            series={[
                                {
                                    data: pieData.filter((pie) => pie.value > 0),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 2.5,
                                    cornerRadius: 5,
                                    startAngle: 0,
                                    endAngle: 360,
                                    highlightScope: { fade: 'global', highlight: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    cx: smallScreen || tabletScreen || laptopScreen ? 40 : 100,
                                    // cy: 150,
                                }
                            ]}
                            {...pieParams}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6.5} sm={6} sx={{ width: "100%", maxHeight: "230px", overflow: "auto" }} className={dashboardStyles.pieLabelContainer}>
                <Grid container direction={"column"} gap={"4px"}>
                    {categories.map((category: any, index: number) =>
                        <Grid item>
                            <Grid container direction={"row"} gap={2} alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item className={dashboardStyles.labelCircle} style={{ backgroundColor: fieldName === "Campaign Status" ? campaignStatusColor[index].color : clickedRepliedColor[index] }}></Grid>
                                <Grid item>
                                    <span className={dashboardStyles.labelHeading}>{fieldName === "Campaign Status" ? `${campaignStatusData.find((data) => data.campaign_status_show_value == category)?.campaign_status_key} (${campaignStatusData.find((data) => data.campaign_status_show_value == category)?.campaign_status_percentage})` : category}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CampaignAnalysisPieChart
