import { Button, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { convertKeysToLowerCase } from '../../../../utils/constants';
import carouselStyles from './../../../../assets/styles/componentStyles/carouselCard.module.scss'
import cardStyles from './../../../../assets/styles/componentStyles/insuranceCard.module.scss'
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/variantMenuCarousel.module.scss'

const VariantMenuCarousel = (props: any) => {
    const {handleMouseDown, handleMouseUp, image, chatBotDetail, formatData, thread } = props;
    const [count, setCount] = useState<any>();
    const [currentCartData, setCurrentCardData] = useState(image?.variants?.find((data: any) => data.flag_default === true) || image?.variants ? image?.variants[0] : null)

    useEffect(() => {
        if(chatBotDetail) {
            setCount(Number(thread?.cart_compact_data?.find((cart: any) => cart.variant_id === currentCartData.variant_id)?.quantity) || 0)
        }
    }, [chatBotDetail])

    const combinedFields = image.variants.flatMap((variant: any) =>
        variant?.variant_data?.filter((field: any) => field.flag_combined === true)
    ).filter((value: any, index: any, self: any) => 
        self.findIndex((field: any) => field.field_name === value.field_name) === index
    );
      
    const getUniqueFieldValues = (fieldName: any) => {
        return [
            //@ts-ignore
          ...new Set(
            image.variants
                .map((variant: any) => {
                    const field = variant?.variant_data?.find(
                    (f: any) => f.field_name === fieldName
                    );
                    return field ? field.field_value : null;
                })
                .filter((value: any) => value !== null && value !== ""),
          ),
        ];
    };
 
    return (
        <Grid container marginRight={1}>
            {currentCartData && <Grid item xs={12} sx={{ padding: '0px', position: "relative", height: "100%" }}>
                <Grid container>
                    <Grid item xs={12} className={carouselStyles.chat_crl_img_item} sx={{position: "relative"}}>
                        <img
                            onMouseDown={handleMouseDown}
                            onMouseUp={(event) => handleMouseUp(event, currentCartData, true)}
                            //@ts-ignore
                            src={(Array.isArray(currentCartData?.variant_data?.find((data: any) => data.field_name === "image_url"))?.field_value ? currentCartData?.variant_data?.find((data: any) => data.field_name === "image_url")?.field_value[0] : currentCartData?.variant_data?.find((data: any) => data.field_name === "image_url"))?.field_value || "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"}
                            alt=""
                            loading='lazy'
                            style={{ borderRadius: "10px", cursor: "pointer", width: '100%', height: '100%', maxHeight: '100%', objectFit: image?.flag_image_compact ? "scale-down" : "cover", objectPosition: "top" }}
                        />
                        {/* </LazyLoad> */}
                    </Grid>

                    <Grid item xs={12} sx={{ cursor: "pointer", height: "100%", paddingTop: "5px" }}>
                        <Grid container>
                            <Grid item xs={12}
                                sx={{
                                    maxHeight: "200px",
                                    overflow: "auto",
                                    padding: "0px 0px 0px 5px",
                                    background: chatBotDetail?.chatbot_theme?.system_msg_bg_color ? chatBotDetail?.chatbot_theme?.system_msg_bg_color : "#fff",
                                    borderRadius: "0px 0px 10px 10px !important",
                                    '&::-webkit-scrollbar': {
                                        width: "4px !important"
                                    }
                                }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ paddingRight: "0px !important", overflowY: "scroll", margin: "0 !important" }} className={cardStyles.ic_card_details_main_item}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ margin: '5px 0px 10px', padding: '10px 9px 5px', width: "100%" }}>
                                                <Grid container direction={"column"} alignItems={"start"}>
                                                    <Grid item>
                                                        <Typography
                                                            component="span"
                                                            className={`${styles.globalVariantTextField} ${styles.productNameStyles}`}
                                                        >
                                                            {`${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_name")?.field_value} `} 
                                                            {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_gender")?.field_value ? `(${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_gender")?.field_value})` : ""}
                                                        </Typography>
                                                    </Grid>
                                                    {currentCartData?.variant_data?.find((data: any) => data.field_name === "country") &&
                                                        <Grid item mt={"5px"}>
                                                            <Typography
                                                                component="span"
                                                                className={`${styles.globalVariantTextField} ${styles.productCountryStyle}`}
                                                            >
                                                                {currentCartData?.variant_data?.find((data: any) => data.field_name === "country")?.field_value ? `${currentCartData?.variant_data?.find((data: any) => data.field_name === "country")?.field_value}` : ""}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_brand") &&
                                                        <Grid item mt={"5px"}>
                                                            <Typography
                                                                component="span"
                                                                className={`${styles.globalVariantTextField} ${styles.productCountryStyle}`}
                                                            >
                                                                {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_brand")?.field_value}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_note") &&
                                                        <Grid item mt={"10px"}>
                                                            <Typography
                                                                component="span"
                                                                className={`${styles.globalVariantTextField} ${styles.productCountryStyle}`}
                                                            >
                                                                {`${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_note")?.field_value}`}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    <Grid item mt={"12px"} sx={{width: "100%"}}>
                                                        <Grid container flexWrap={"nowrap"}>
                                                            <Grid item xs={currentCartData?.variant_data?.find((data: any) => data.field_name === "product_category") ? 4 : 12}>
                                                                <Grid container direction={"column"} gap={"0px"}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            component="span"
                                                                            className={`${styles.globalVariantTextField} ${styles.productNameStyles}`}
                                                                        >
                                                                            {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_price")?.field_label}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <span className={styles.primaryVariantFontStyle} style={{color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"}`}}>
                                                                            {`${formatData.currency} ${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_price")?.field_value}`}
                                                                        </span>
                                                                        {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_weight") &&
                                                                            <span className={styles.secondaryVariantFontStyle}
                                                                                style={{
                                                                                    color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"}`, opacity: "0.8", overflow: "hidden"
                                                                                }}
                                                                            >
                                                                                {` / ${currentCartData?.variant_data?.find((data: any) => data.field_name === "product_weight")?.field_value}`}
                                                                            </span>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_category") &&
                                                                <Grid item xs={8.5} textAlign={"end"}>
                                                                    <Grid container direction={"column"} gap={"6px"}>
                                                                        <Grid item>
                                                                            <Typography
                                                                                component="span"
                                                                                className={`${styles.productNameStyles}`}
                                                                            >
                                                                                {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_category")?.field_label}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <span 
                                                                                className={`${styles.secondaryVariantFontStyle} ${styles.productCategoryStyles}`}
                                                                                style={{
                                                                                    color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"}`, 
                                                                                    boxShadow: `0px 0px 2px ${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"}`,
                                                                                    backgroundColor: `${chatBotDetail?.chatbot_theme?.chatbot_header_font_color ? chatBotDetail?.chatbot_theme?.chatbot_header_font_color : "#FFF"}`}}
                                                                                >
                                                                                    {currentCartData?.variant_data?.find((data: any) => data.field_name === "product_category")?.field_value}
                                                                            </span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sx={{width: "100%"}}>
                                                        <Grid container direction={"column"}>
                                                            {combinedFields?.map((field: any) => {
                                                                const uniqueValues = getUniqueFieldValues(field.field_name);
                                                                return uniqueValues.length > 0 ? (
                                                                    <Grid item key={field.field_name} mt={"10px"}>
                                                                        <Grid container direction={"column"} gap={"6px"}>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    component="span"
                                                                                    className={`${styles.globalVariantTextField} ${styles.productNameStyles}`}
                                                                                >
                                                                                    {field.field_label}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container gap={"6px"}>
                                                                                    {uniqueValues.map((value, index) => (
                                                                                        <Grid item
                                                                                            key={index}
                                                                                            sx={{
                                                                                                padding: "6px 12px",
                                                                                                backgroundColor: currentCartData?.variant_data?.find((data: any) => data.field_name === field.field_name).field_value === value ? chatBotDetail?.chatbot_theme?.chatbot_header_color ? `${chatBotDetail?.chatbot_theme?.chatbot_header_color} !important` : "#E8E8E8 !important" : "#E8E8E8",
                                                                                                borderRadius: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: "12px",
                                                                                                    fontWeight: "600",
                                                                                                    color: currentCartData?.variant_data?.find((data: any) => data.field_name === field.field_name).field_value === value ? chatBotDetail?.chatbot_theme?.chatbot_header_font_color ? chatBotDetail?.chatbot_theme?.chatbot_header_font_color : "#000" : "#000",
                                                                                                }}
                                                                                            >
                                                                                                {value}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    ))}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : null;
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{width: "100%", padding: "10px 15px 15px"}}>
                        <Divider />
                        <Grid container flexWrap={"nowrap"} justifyContent={"space-between"} gap={2}>
                            {convertKeysToLowerCase(image)?.cta_buttons?.map((button: any) => {
                                return (
                                    <Grid item xs={12} sx={{ margin: '5px 0px 0px', padding: '5px 0px 0px', width: "100%" }}>
                                        <Grid container direction={"row"} alignItems={"end"} flexWrap={"nowrap"}>
                                            <Grid item sx={{ width: "100%" }}>
                                                {button.message_format_id === 11 && (
                                                    <Button
                                                        className={styles.ctaButtonStyles}
                                                        sx={{
                                                            backgroundColor: chatBotDetail?.chatbot_theme?.chatbot_header_font_color ? `${chatBotDetail?.chatbot_theme?.chatbot_header_font_color} !important` : "#FFFFFF !important",
                                                            color: chatBotDetail?.chatbot_theme?.chatbot_header_color ? `${chatBotDetail?.chatbot_theme?.chatbot_header_color} !important` : "#6A097D !important",                                                          
                                                            boxShadow: `0px 0px 2px ${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`,
                                                            // width: "125px !important",
                                                        }}
                                                    >
                                                        {button.message_fields.url_text}
                                                    </Button>
                                                )}

                                                {button.message_format_id === 14.2 && count === 0 && (
                                                    <Button
                                                        className={styles.ctaButtonStyles}
                                                        sx={{
                                                            backgroundColor: chatBotDetail?.chatbot_theme?.chatbot_header_color ? `${chatBotDetail?.chatbot_theme?.chatbot_header_color} !important` : "#6A097D !important",
                                                            color: chatBotDetail?.chatbot_theme?.chatbot_header_font_color ? `${chatBotDetail?.chatbot_theme?.chatbot_header_font_color} !important` : "#FFFFFF !important",
                                                            // width: "125px !important",
                                                        }}
                                                        disabled
                                                    >
                                                        {button.message_fields.button_name}
                                                    </Button>
                                                )}

                                                {count > 0 && button.message_format_id === 14.2 && (
                                                    <Grid item xs={12} sx={{ margin: '0px', padding: '5px 0px 0px', width: "100%" }}>
                                                        <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                                                            <Grid item xs={3} sx={{border: `1px solid ${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`, borderRadius: "5px"}}>
                                                                <Button className={styles.addRemoveVarientsButton}>
                                                                    <RemoveIcon sx={{color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`}}/>
                                                                </Button>    
                                                            </Grid>
                                                            <Grid item xs={6} sx={{border: `1px solid ${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`, borderRadius: "5px", minWidth: "0 !important", padding: "6px 20px !important", textAlign: "center"}}>
                                                                <span style={{fontSize: "15px", fontWeight: "600", color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"}`}}>
                                                                    {`${count}`}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={3} sx={{border: `1px solid ${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`, borderRadius: "5px"}}>
                                                                <Button className={styles.addRemoveVarientsButton}>
                                                                    <AddIcon sx={{color: `${chatBotDetail?.chatbot_theme?.chatbot_header_color ? chatBotDetail?.chatbot_theme?.chatbot_header_color : "#6A097D"} !important`}}/>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
    )
}

export default VariantMenuCarousel