import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import tableStyle from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationTableStyle.module.scss'
import { createLeadGenerationPayloadModel, getGenericFieldTypeDataResponseModel, leadFieldDataModel } from '../../../models/axiosModel'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext';

const LeadGenerationFieldTable = () => {
    const { createLeadGenerationPayload, genericFieldDataType, setAddNewLeadField, setFlagUpdateField, setLeadGenerationFieldPayload, setFlagDeleteFieldData } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload

    const handleEditFieldData = (fieldData: leadFieldDataModel) => {
        if(fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp") {

        }
        else {
            setAddNewLeadField(true);
            setFlagUpdateField(true);
            setLeadGenerationFieldPayload(fieldData)
        }
    }

    const handleDeleteFieldData = (fieldData: leadFieldDataModel) => {
        if(fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp") {

        }
        else {
            setFlagDeleteFieldData(true);
            setLeadGenerationFieldPayload(fieldData);
        }  
    }

    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "calc(100vh - 250px)"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={tableStyle.tableBodyHeadingFontStyle}>Name</TableCell>
                        <TableCell className={tableStyle.tableBodyHeadingFontStyle}>Type</TableCell>
                        <TableCell className={tableStyle.tableBodyHeadingFontStyle}>Description</TableCell>
                        <TableCell className={tableStyle.tableBodyHeadingFontStyle}>Enum</TableCell>
                        <TableCell className={`${tableStyle.tableBodyHeadingFontStyle} ${tableStyle.viewButtonColumnHeadingStyle}`}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {createLeadGenerationPayload && createLeadGenerationPayload.chatbot_lead_field_data.length > 0 ? createLeadGenerationPayload.chatbot_lead_field_data.sort((a: any, b: any) => a.chatbot_lead_field_id - b.chatbot_lead_field_id).map((fieldData: leadFieldDataModel) => 
                        <TableRow className={tableStyle.cellRow}>
                            <TableCell className={tableStyle.tableBodySubHeadingFontStyle}>{fieldData.chatbot_lead_field_name}</TableCell>
                            <TableCell className={tableStyle.tableBodySubHeadingFontStyle}>{genericFieldDataType?.find((types) => types.field_type_id === Number(fieldData.chatbot_lead_field_type_id))?.field_type_name}</TableCell>
                            <TableCell className={tableStyle.tableBodySubHeadingFontStyle}>{fieldData.chatbot_lead_field_description}</TableCell>
                            <TableCell className={tableStyle.tableBodySubHeadingFontStyle}>{fieldData.chatbot_lead_field_enum_value}</TableCell>
                            <TableCell className={`${tableStyle.tableBodySubHeadingFontStyle} ${tableStyle.viewButtonColumnStyle}`} sx={{textAlign: "end"}}>
                                {!(fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp") &&
                                    <EditIcon 
                                        style={{color: "#7A7A7A", cursor: fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp" ? "no-drop" : "pointer", fontSize: "18px", marginRight: "10px"}} 
                                        onClick={() => handleEditFieldData(fieldData)}
                                    />
                                }
                                {!(fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp") &&
                                    <DeleteIcon 
                                        style={{color: "#7A7A7A", cursor: fieldData.chatbot_lead_field_name === "Browser Name" || fieldData.chatbot_lead_field_name === "Geo Location" || fieldData.chatbot_lead_field_name === "Timestamp" ? "no-drop" : "pointer", fontSize: "18px"}} 
                                        onClick={() => handleDeleteFieldData(fieldData)}
                                    />
                                }
                            </TableCell>
                        </TableRow>          
                    ) :
                    <TableRow className={tableStyle.cellRow}>
                        <TableCell className={tableStyle.tableBodySubHeadingFontStyle} colSpan={5} style={{textAlign:"center"}}>No Fields Added</TableCell>
                    </TableRow>
                }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LeadGenerationFieldTable
