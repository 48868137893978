import { Close, Height } from '@mui/icons-material'
import { Box, Button, Divider, Grid, InputLabel, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../../assets/styles/componentStyles/feedbackDialog.module.scss'
import { feedbackOptions } from '../../../../utils/content'
import Required from '../../../utility/required'
import { chatbotFeedbackOptionData, chatBotSelectedFeedbackOptionData, feedbackLevel, feedbackProvidedType } from '../../../../models/axiosModel'
import agent from '../../../../services/api'
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext'
import { UserContext, UserContextPayload } from '../../../../context/userContext'



const FeedbackDialog = (props: any) => {

    const { setFlagFeedbackDialog, setFlagTellusMoreOptionBtn, handleSubmitFeedback, chatbot_thread_id, setFeedbackData, feedbackData, handleTellUsMoreIssue, feedbackMessageNewFormat, setFeedbackMessageNewFormat, initialNewFormatMessage, setFlagOtherSelected, flagOtherSelected, feedbackProvidedTypeData, currentActiveChatbotThread, feedbackLevel, onCloseOfFeedbackForm, feedbackFor, chatbotThreadId, setCurrentActiveChatbotThread, getSpecificChat } = props

    const [feedbackOptionsData, setFeedbackOptionsData] = useState<chatbotFeedbackOptionData[] | null>(null);

    const [selectedIssue, setSelectedIssue] = useState<chatBotSelectedFeedbackOptionData | null>(null);
    const { setFlagLoading, setError, setSuccess } = useContext(UserContext) as UserContextPayload;

    useEffect(() => {
        if (feedbackOptionsData === null) {
            getListOfFeedbackOptionAPiCall();
        }
    }, [])

    const getListOfFeedbackOptionAPiCall = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getListOfFeedbackOption.get(currentActiveChatbotThread?.chatbot_id, currentActiveChatbotThread?.chatbot_token);
        if (response.status) {
            if (response.data) {
                setFeedbackOptionsData(response.data.chatbot_feedback_option_data);
            }
        } else {
            console.log("Error Occured while getting list of feedback options");
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    const handleOnChangeOfFeedbackOptions = (value: string, feedback_option_field_id: number) => {
        setSelectedIssue((prev: chatBotSelectedFeedbackOptionData | null) => {
            if (!prev) return null;
            const updatedValue = prev?.feedback_option_field.map((field) =>
                field.feedback_option_field_id === feedback_option_field_id ? { ...field, feedback_value: value } : field
            );
            return { ...prev, feedback_option_field: updatedValue };
        })
    }

    const checkIsSubmitBtnIsDisabled = (selectedIssue: chatBotSelectedFeedbackOptionData | null) => {
        if (selectedIssue) {
            return !(selectedIssue.feedback_option_field.every((field) => {
                if (field.flag_required) {
                    return field.feedback_value.trim() != "";
                }
                return true;
            }));
        } else {
            return true;
        }
    }

    const getUniqueId = () => {
        switch (String(feedbackFor).toLowerCase()) {
            case "chronox":
                return null;
            case "chatbot":
                return Number(currentActiveChatbotThread?.chatbot_id);
            case "thread":
                return Number(currentActiveChatbotThread?.chatbot_user_id);
            case "message":
                return chatbotThreadId;
        }
    }

    const getProvidedTypeId = () => {
        return feedbackProvidedTypeData?.find((data: feedbackProvidedType) => data.feedback_provided_type_name === "Admin").feedback_provided_type_id
    }

    const addFeedbackPayload = () => {
        return {
            chatbot_id: Number(currentActiveChatbotThread?.chatbot_id),
            feedback_option_id: selectedIssue?.feedback_option_id,
            feedback_level_id: feedbackLevel.find((item: feedbackLevel) => (item.feedback_level_name).toLowerCase() === String(feedbackFor).toLowerCase()).feedback_level_id,
            feedback_unique_id: getUniqueId(),
            feedback_provided_type_id: getProvidedTypeId(),
            feedback_option_field_data: selectedIssue?.feedback_option_field?.map((field) => {
                return {
                    feedback_option_field_id: field.feedback_option_field_id,
                    feedback_value: field.feedback_value,
                }
            })
        }
    }

    const handleSubmitClick = async () => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.addFeedback.post(addFeedbackPayload(), currentActiveChatbotThread?.chatbot_token);
        if (response.status) {
            if (response.data) {
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Feedback submitted successfully!" }))
                setSelectedIssue(null);
                onCloseOfFeedbackForm();
                if (currentActiveChatbotThread && currentActiveChatbotThread?.chatbot_user_id) {
                    getSpecificChat(currentActiveChatbotThread?.chatbot_user_id);
                }
                // if (chatbotThreadId && feedbackFor.toLowerCase() === "message") {
                //     let temp = { ...currentActiveChatbotThread };
                //     let feedbackData = response.data;

                //     temp = temp.chatbot_thread_data?.map((item: any) =>
                //         item.chatbot_thread_id === chatbotThreadId ? { ...item, chatbot_message_feedback: feedbackData } : item
                //     )

                //     setCurrentActiveChatbotThread((prev: any) => {
                //         return { ...prev, chatbot_thread_data: temp }
                //     })
                // }
            }
        } else {
            setError((prevState) => ({ ...prevState, flagShowError: true, message: "Something went wrong! Please try again later" }))
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    return (
        <>
            {/* <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className={styles.chat_header_item} sx={{ padding: '20px' }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={11}>
                                    <Typography sx={{ color: '15px', fontWeight: '500' }}>
                                        Provide your feedback
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end' }}>
                                    <Close
                                        sx={{ cursor: 'pointer', display: 'flex', justifyContent: "end", fontSize: '20px' }}
                                        onClick={() => {
                                            setFlagFeedbackDialog(false)
                                            setFlagTellusMoreOptionBtn(null)
                                            setFeedbackData({
                                                ...feedbackData,
                                                flagFeedbackDialog: false
                                            })
                                            setFlagOtherSelected(false)
                                            setFeedbackMessageNewFormat(initialNewFormatMessage)
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Divider style={{ width: '100%' }} />

                        <Grid item xs={12} sx={{ padding: '10px 20px 15px' }}>
                            <Grid container direction={"column"} >
                                <Grid item><InputLabel sx={{ fontSize: '14px', fontWeight: '500', color: '#000', marginBottom: "2px" }}>Category of Issue</InputLabel></Grid>
                                <Grid item>
                                    <Grid container>
                                        {feedbackOptions.map((ele, index) => {
                                            return (
                                                <>
                                                    <Grid item className={styles.chat_feedback_btn_item}>
                                                        <Button
                                                            variant='outlined'
                                                            size='medium'
                                                            className={`${styles.chatbot_submit_btn_hover} ${ele === feedbackMessageNewFormat.chatbot_message_feedback ? styles.chat_feedback_selected_btn : styles.chat_feedback_btn}`}
                                                            onClick={(e) => {
                                                                if (ele === "Other") {
                                                                    setFlagOtherSelected(true)
                                                                    handleTellUsMoreIssue(ele, "chatbot_message_feedback")
                                                                } else {
                                                                    setFlagOtherSelected(false)
                                                                    handleTellUsMoreIssue(ele, "chatbot_message_feedback")
                                                                }
                                                            }}
                                                        >
                                                            {ele}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sx={{ margin: '5px 0px 10px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Describe the Issue {flagOtherSelected && <Required />}</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className={styles.chat_feedback_textfield}
                                            placeholder='Briefly describe the issue you encountered'
                                            autoComplete='off'
                                            multiline
                                            value={feedbackMessageNewFormat.chatbot_message_feedback_issue_description}
                                            onChange={(e) => { handleTellUsMoreIssue(e.target.value, "chatbot_message_feedback_issue_description") }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '10px 0px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Proposed Correct Response</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className={styles.chat_feedback_textfield}
                                            placeholder='Suggest what the correct response should have been'
                                            autoComplete='off'
                                            multiline
                                            value={feedbackMessageNewFormat.chatbot_message_feedback_correct_response}
                                            onChange={(e) => { handleTellUsMoreIssue(e.target.value, "chatbot_message_feedback_correct_response") }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '10px 0px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Which file would have provided the correct response</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className={styles.chat_feedback_textfield}
                                            placeholder='Write file description here...'
                                            autoComplete='off'
                                            multiline
                                            value={feedbackMessageNewFormat.chatbot_message_feedback_correct_response_file}
                                            onChange={(e) => { handleTellUsMoreIssue(e.target.value, "chatbot_message_feedback_correct_response_file") }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'start' }}>
                                <Button
                                    variant='contained'
                                    size='small'
                                    className={styles.feedback_submit_btn}
                                    sx={{ opacity: (feedbackMessageNewFormat.chatbot_message_feedback.trim() === "") || (flagOtherSelected && feedbackMessageNewFormat.chatbot_message_feedback_issue_description.trim() === "") ? "0.5" : "1" }}
                                    disabled={(feedbackMessageNewFormat.chatbot_message_feedback.trim() === "") || (flagOtherSelected && feedbackMessageNewFormat.chatbot_message_feedback_issue_description.trim() === "") ? true : false}
                                    onClick={() => { handleSubmitFeedback(chatbot_thread_id, feedbackMessageNewFormat) }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid> */}

            {feedbackOptionsData != null &&
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sx={{ padding: '15px 20px' }}>
                                <Grid container sx={{ alignItems: 'center' }}>

                                    <Grid item xs={11}>
                                        <Typography sx={{ color: '15px', fontWeight: '500' }}>
                                            Provide your feedback
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end' }}>
                                        <Close
                                            sx={{ cursor: 'pointer', display: 'flex', justifyContent: "end", fontSize: '20px' }}
                                            onClick={() => { onCloseOfFeedbackForm() }}
                                        // onClick={() => {
                                        //     setFlagFeedbackDialog(false)
                                        //     setFlagTellusMoreOptionBtn(null)
                                        //     setFeedbackData({
                                        //         ...feedbackData,
                                        //         flagFeedbackDialog: false
                                        //     })
                                        //     setFlagOtherSelected(false)
                                        //     setFeedbackMessageNewFormat(initialNewFormatMessage)
                                        // }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Divider style={{ width: '100%' }} />

                            <Grid item xs={12} sx={{ padding: '10px 20px 15px', maxHeight: "705px", overflowY: "auto" }}>
                                <Grid container direction={"column"} >
                                    {feedbackOptionsData != null &&
                                        <Grid item><InputLabel sx={{ fontSize: '14px', fontWeight: '500', color: '#000', marginBottom: "2px" }}>Category of Issue <span style={{ color: "#C60000" }}>*</span> </InputLabel></Grid>
                                    }
                                    <Grid item>
                                        <Grid container>
                                            {/* {feedbackOptions.map((ele, index) => {
                                            return (
                                                <>
                                                    <Grid item className={chat_feedback_btn_item}>
                                                        <Button
                                                            variant='outlined'
                                                            size='medium'
                                                            className={`${chatbot_submit_btn_hover} ${ele === feedbackMessageNewFormat.chatbot_message_feedback ? chat_feedback_selected_btn : chat_feedback_btn}`}
                                                            onClick={(e) => {
                                                                if (ele === "Other") {
                                                                    setFlagOtherSelected(true)
                                                                    handleTellUsMoreIssue(ele, "chatbot_message_feedback")
                                                                } else {
                                                                    setFlagOtherSelected(false)
                                                                    handleTellUsMoreIssue(ele, "chatbot_message_feedback")
                                                                }
                                                            }}
                                                        >
                                                            {ele}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )
                                        })} */}
                                            {feedbackOptionsData?.map((item, ind) => {
                                                return (
                                                    <>
                                                        <Grid item className={`${styles.chat_feedback_btn_item}`}>
                                                            <Button
                                                                variant='outlined'
                                                                size='medium'
                                                                className={`${styles.chatbot_submit_btn_hover} ${item.feedback_option_name === selectedIssue?.feedback_option_name ? `${styles.chat_feedback_selected_btn}` : `${styles.chat_feedback_btn}`}`}
                                                                onClick={() => {
                                                                    const updatedData = {
                                                                        ...item,
                                                                        feedback_option_field: item.feedback_option_field.map((field) => ({
                                                                            ...field,
                                                                            feedback_value: "",
                                                                        }))
                                                                    }
                                                                    setSelectedIssue(updatedData);
                                                                }}
                                                            >
                                                                {item.feedback_option_name}
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {/* <Grid item xs={12} sx={{ margin: '5px 0px 10px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Describe the Issue
                                             </span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className="chat_feedback_textfield"
                                            placeholder='Briefly describe the issue you encountered'
                                            autoComplete='off'
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '10px 0px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Proposed Correct Response</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className="chat_feedback_textfield"
                                            placeholder='Suggest what the correct response should have been'
                                            autoComplete='off'
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '10px 0px ' }}>
                                <Grid container direction={"column"} gap={"7px"}>
                                    <Grid item>
                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Which file would have provided the correct response</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            className="chat_feedback_textfield"
                                            placeholder='Write file description here...'
                                            autoComplete='off'
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> */}

                                {selectedIssue && selectedIssue?.feedback_option_field && selectedIssue?.feedback_option_field?.map((item, ind) => {
                                    return (
                                        <>
                                            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                <Grid container direction='column' gap='7px'>
                                                    <Grid item>
                                                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>{item.feedback_option_field_value}
                                                            {item.flag_required &&
                                                                <span style={{ color: "#C60000", paddingLeft: '3px' }}>*</span>
                                                            }
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        {item.field_input_type_name === "Long Text" ?
                                                            <>
                                                                <textarea
                                                                    rows={3}
                                                                    placeholder={item.feedback_option_field_placeholder}
                                                                    className={`${styles.longTextFeedbackInputField}`}
                                                                    style={{ width: "100%", boxSizing: "border-box", padding: "10px", position: "relative", border: "none", boxShadow: "0px 0px 2px rgba(0,0,0,0.25)", borderRadius: "5px", resize: "vertical", outline: "none", minHeight: "4em", maxHeight: "8em", fontSize: "14px", background: "transparent" }}
                                                                    value={item.feedback_value}
                                                                    onChange={(e) => {
                                                                        handleOnChangeOfFeedbackOptions(e.target.value, item.feedback_option_field_id)
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <TextField
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                size='small'
                                                                fullWidth
                                                                className={`${styles.chat_feedback_textfield}`}
                                                                placeholder={item.feedback_option_field_placeholder}
                                                                // required={item.flag_required}
                                                                autoComplete='off'
                                                                multiline
                                                                value={item.feedback_value}
                                                                onChange={(e) => { handleOnChangeOfFeedbackOptions(e.target.value, item.feedback_option_field_id) }}
                                                            />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    );
                                })}

                                {/* <Grid item xs={6} sx={{textAlign: "start"}}>
                                
                            </Grid> */}

                                {feedbackOptionsData != null &&
                                    <Grid item xs={12} sx={{ textAlign: 'start' }}>
                                        <Box sx={{ display: 'flex', gap: '10px', alignItems: "center" }}>
                                            <Button
                                                variant='outlined'
                                                size='small'
                                                className={`${styles.feedback_reset_btn}`}
                                                sx={{ marginTop: "5px" }}
                                                onClick={() => setSelectedIssue(null)}
                                                disabled={selectedIssue === null}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant='contained'
                                                size='small'
                                                className={`${styles.feedback_submit_btn}`}
                                                sx={{ marginTop: "5px" }}
                                                disabled={checkIsSubmitBtnIsDisabled(selectedIssue)}
                                                onClick={handleSubmitClick}
                                            // sx={{ opacity: (feedbackMessageNewFormat.chatbot_message_feedback.trim() === "") || (flagOtherSelected && feedbackMessageNewFormat.chatbot_message_feedback_issue_description.trim() === "") ? "0.5" : "1" }}
                                            // disabled={(feedbackMessageNewFormat.chatbot_message_feedback.trim() === "") || (flagOtherSelected && feedbackMessageNewFormat.chatbot_message_feedback_issue_description.trim() === "") ? true : false}
                                            // onClick={() => { handleSubmitFeedback(chatbot_thread_id, feedbackMessageNewFormat) }}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default FeedbackDialog