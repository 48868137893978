import { Divider, Grid, Menu, MenuItem, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { checkForErrorCode, formatDateAccordingToTimezoneUsingMoment, getErrorMessage, getTag, isJsonString, patternToRemoveSource } from '../../../utils/constants';
import { useSearchParams } from 'react-router-dom';
import ChatsMobileResponsive from './chatsMobileResponsive';
import { chatbotUserThreads, feedbackLevel, feedbackProvidedType, } from '../../../models/axiosModel';
import moment from 'moment';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import GlobalPopUp from '../../utility/globalPopUp';
import FeedbackDialog from './chatWidgetComponents/feedbackDialog';
import { feedbackOptionsUpdated } from '../../../utils/content';
import ChatUserThreadList from './chatUserThreadList';
import ChatUserThreadLogView from './chatUserThreadLogView';
import ChatInformationBlock from './chatInformationBlock';
interface chatsProps {
    mainSection?: boolean
}

const initialNewFormatMessage = {
    chatbot_message_feedback: "",
    chatbot_message_feedback_issue_description: "",
    chatbot_message_feedback_correct_response: "",
    chatbot_message_feedback_correct_response_file: ""
}

export default function Chats(props: chatsProps) {
    let initialData = {
        flagFeedbackDialog: false,
        flagTellusMoreOption: null,
        selectedFeedbackButton: null,
        flagFeedbackSubmitted: false,
        chatbot_thread_id: null
    }
    let chatLogsIntialData = {
        activeChats: [],
        closedChats: [],
        activeChatsFiltered: [],
        closeChatsFiltered: [],
        pagination: {
            start_index: null,
            end_index: null,
            total_chats: null,
            records_per_page: ""
        }
    }
    const [feedbackData, setFeedbackData] = React.useState({
        flagFeedbackDialog: false,
        flagTellusMoreOption: null,
        selectedFeedbackButton: null,
        flagFeedbackSubmitted: false,
        chatbot_thread_id: null
    })
    const hasEffectRun = useRef(false);
    const [flagFeedbackDialog, setFlagFeedbackDialog] = React.useState<any>(false);
    const [flagTellusMoreOptionBtn, setFlagTellusMoreOptionBtn] = React.useState<any>(null);
    const [threadId, setThreadId] = React.useState(null)
    const [value, setValue] = useState(0)
    const { setFlagLoading, userDetail, setError, setSuccess } = useContext(UserContext) as UserContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload
    const [currentActiveChatbotThread, setCurrentActiveChatbotThread] = useState<chatbotUserThreads | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get('chatbot_id')
    const feedback_id = searchParams.get('feedback_id')
    const [filteredData, setFilteredData] = useState(chatLogsIntialData)
    const [drawerProps, setDrawerProps] = useState({
        openDrawer: false,
        dropdown_options: [],
        option_key_name: "",
        image_url: null,
        flag_multiple: false,
        dropdown_name: ""
    })
    const smallScreen = useMediaQuery('(max-width : 1220px) and (min-width : 0px)')
    const [sourceDetails, setSourceDetails] = useState({});
    const [feedbackDetails, setFeedbackDetails] = useState({})
    const [subType, setSubType] = useState<string>(searchParams.get("page_no") ? "Closed" : "Active");
    const [open, setOpen] = useState(true);
    const [chatClicked, setChatClicked] = useState(false);
    const [chatInfoClicked, setChatInfoClicked] = useState(false);
    const [copyText, setCopyText] = React.useState("Copy")
    const [chatbotMessageCopyHover, setChatbotMessageCopyHover] = useState(null)

    const [feedbackMessageNewFormat, setFeedbackMessageNewFormat] = useState(initialNewFormatMessage)
    const [flagOtherSelected, setFlagOtherSelected] = useState(false)
    const [flagFilterFeedback, setFilterFeedback] = useState(false)
    const [chatLevelMenu, setChatlevelMenu] = useState<any>({
        flagOpen: false,
        anchorEl: null
    })

    const [feedbackProvidedTypeData, setFeedbackProvidedTypeData] = useState<feedbackProvidedType[]>([]);
    const [feedbackLevelData, setFeedbackLevelData] = useState<feedbackLevel[]>([]);

    const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState<boolean>(false);

    const innerHeight = (window.innerHeight - 90);
    const handleTellUsMoreIssue = (value: string, keyName: string) => {
        setFeedbackMessageNewFormat((prevState) => ({ ...prevState, [keyName]: value }))
    }

    async function handleSubmitFeedback(chatbot_thread_id: any, feedbackMessageNewFormat: any, chatbotToken?: any) {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        setFeedbackData((prevState) => ({ ...prevState, flagFeedbackDialog: false }))
        let data;
        data = {
            token: null,
            payload: {
                chatbot_thread_id: chatbot_thread_id,
                chatbot_message_feedback: feedbackMessageNewFormat.chatbot_message_feedback,
                chatbot_message_feedback_issue_description: feedbackMessageNewFormat.chatbot_message_feedback_issue_description,
                chatbot_message_feedback_correct_response: feedbackMessageNewFormat.chatbot_message_feedback_correct_response,
                chatbot_message_feedback_correct_response_file: feedbackMessageNewFormat.chatbot_message_feedback_correct_response_file
            }
        }

        const response = await agent.submitFeedback.post(data);
        if (response.status) {
            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Feedback submitted successfully!" }))
            let temp = { ...currentActiveChatbotThread }
            let feedbackdata = response.data
            // @ts-ignore
            temp = temp?.chatbot_thread_data?.map((thread) => (
                thread.chatbot_thread_id == chatbot_thread_id ? { ...thread, chatbot_message_feedback: feedbackdata } : thread
            ))
            if (currentActiveChatbotThread && currentActiveChatbotThread.chatbot_user_id) {
                getSpecificChat(currentActiveChatbotThread.chatbot_user_id, true)
            }
            setFeedbackData((prevState) => ({ ...prevState, flagFeedbackSubmitted: true }))
            setTimeout(() => {
                setFeedbackData(initialData)
            }, 2000)
            setFlagOtherSelected(false)
            setFeedbackMessageNewFormat(initialNewFormatMessage)
        } else {
            setError((prevState) => ({ ...prevState, flagShowError: true, message: "Something went wrong! Please try again later" }))
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    async function handleResetChatbotThread() {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        let chatbotId = currentActiveChatbotThread?.chatbot_id.toString() ?? ""
        let chatbot_id = searchParams.get('chatbot_id') ?? chatbotId
        let chatbot_user_id = searchParams.get('chatbot_user_id')
        if (chatbot_id && chatbot_user_id) {
            const response = await agent.resetChatbotThreadByAdmin.get(chatbot_id, chatbot_user_id)
            if (response.status) {
                setSearchParams(prevParams => {
                    const newParams = new URLSearchParams(prevParams);
                    newParams.set('chatbot_user_id', "");
                    return newParams;
                });
                await getChatLogs(flagFilterFeedback, Number(searchParams.get('page_no')) || 1, 100, Number(chatbot_id))
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Chat Reset successfully!" }))
            } else {
                let errorCode = checkForErrorCode(response)
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, true) }))
            }
        }
        setChatlevelMenu((prevState: any) => ({ ...prevState, flagOpen: false, anchorEl: null }))
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))

    }


    const handleOpenMenu = (event: any, chatbot_thread_id: any) => {
        let temp = currentActiveChatbotThread?.chatbot_thread_data.map(
            (thread: any) => thread.chatbot_thread_id === chatbot_thread_id ?
                {
                    ...thread,
                    flagOpenMenu: !thread.flagOpenMenu,
                    anchorEl: event.currentTarget.parentElement
                } : thread
        )
        setCurrentActiveChatbotThread((prevState: any) => {
            return { ...prevState, chatbot_thread_data: temp }
        })
        // setFlagNotScrollingContainer(true)
        // setFlagPressArrowButton(true)
    }
    const handleCloseMenu = (event: any, chatbot_thread_id: any) => {
        let temp = currentActiveChatbotThread?.chatbot_thread_data.map((thread) => thread.chatbot_thread_id == chatbot_thread_id ? { ...thread, flagOpenMenu: false, anchorEl: null } : thread)
        setCurrentActiveChatbotThread((prevState: any) => {
            return { ...prevState, chatbot_thread_data: temp }
        })
    }

    const getChatLogs = async (chatbot_flag_message_feedback: boolean, pageNumber: Number, recordsPerPage: Number, chatbot_id?: Number | null, flagChangePage?: boolean) => {
        setSourceDetails({});
        setFilteredData(chatLogsIntialData)
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = chatbot_id ? await agent.getChatLogs.get(chatbot_flag_message_feedback, pageNumber, recordsPerPage, chatbot_id) : await agent.getAllChatLogs.get(chatbot_flag_message_feedback, pageNumber, recordsPerPage);
        if (response.status && response.data && response.data.length > 0) {
            let chatbot_id = searchParams.get('chatbot_id')
            let activeChats: any
            let closedChats: any
            let pagination: any
            if (window.location.pathname.toLocaleLowerCase().includes('viewchatbot')) {
                activeChats = response.data[0].active_chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                })
                closedChats = response.data[0].closed_chats.chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_theme, chatbot_logo_url, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                })
                pagination = response.data[0].closed_chats.pagination
            } else {
                activeChats = response.data[0].active_chats.flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                })
                closedChats = response.data[0].closed_chats.chats.flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id }))
                })
                pagination = response.data[0].closed_chats.pagination
            }
            activeChats = activeChats.sort((a: any, b: any) => {
                const timeA = moment(a.last_message_time).format('YYYY-MM-DD HH:mm:ss');
                const timeB = moment(b.last_message_time).format('YYYY-MM-DD HH:mm:ss');
                return timeB.localeCompare(timeA)
            })
            closedChats = closedChats.sort((a: any, b: any) => {
                const timeA = moment(a.last_message_time).format('YYYY-MM-DD HH:mm:ss');
                const timeB = moment(b.last_message_time).format('YYYY-MM-DD HH:mm:ss');
                return timeB.localeCompare(timeA)
            })
            setFilteredData((prevState) => ({ ...prevState, activeChats: activeChats, closedChats: closedChats, closeChatsFiltered: closedChats, activeChatsFiltered: activeChats, pagination: pagination }))
            if (!flagChangePage) {
                if (!currentActiveChatbotThread) {
                    let chatbot_thread_id = searchParams.get('chatbot_user_id')
                    if (chatbot_thread_id) {
                        getSpecificChat(Number(chatbot_thread_id))
                    }
                }
                else {
                    getSpecificChat(Number(currentActiveChatbotThread.chatbot_user_id))
                }
            }
        }
        setTimeout(() => {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
        }, 200)
    }

    useEffect(() => {
        (async () => {
            props.mainSection ? await getChatLogs(false, Number(searchParams.get('page_no')) || 1, 100) : await getChatLogs(false, Number(searchParams.get('page_no')) || 1, 100, Number(searchParams.get('chatbot_id')))
        })()
        setCurrentActiveChatbotThread(null)
    }, [userDetail, chatBotDetails])

    useEffect(() => {
        if (!hasEffectRun.current && currentActiveChatbotThread !== null) {
            const element = document.getElementById("chatUI")
            element?.scrollTo(0, element.scrollHeight)
            hasEffectRun.current = true;
        }
    }, [currentActiveChatbotThread])

    useEffect(() => {
        if (feedbackProvidedTypeData.length === 0) {
            getFeedbackProvidedTypeApiCall();
        }
        if (feedbackLevelData.length === 0) {
            getFeedbackLevelApiCall();
        }
    }, [])

    const getFeedbackProvidedTypeApiCall = async () => {
        const response = await agent.getFeedbackProvidedType.get();
        if (response.status) {
            if (response.data) {
                setFeedbackProvidedTypeData(response.data);
            }
        } else {
            console.log("Error Occured while getting feedback provided type data");
        }
    }

    const getFeedbackLevelApiCall = async () => {
        const response = await agent.getFeedbackLevel.get();
        if (response.status) {
            if (response.data) {
                setFeedbackLevelData(response.data);
            }
        } else {
            console.log("Error Occured while getting Feedback level data");
        }
    }

    const getMessageType = (thread: any) => {
        if (thread && thread.media_data && thread.media_data.length > 0 && thread.media_data[0].aws_storage_id !== "") {
            return "media"
        } else {
            return "text"
        }
    }

    const getSourceDetails = async (ipAddress: string, source: string, phone_number: any, user_name: any, started_at: string, last_message_at: string, city: any, country: any, chatbot_user_input_token: any, chatbot_user_output_token: any, openai_assistant_id: any, openai_thread_id: any) => {
        if (source === "Website") {
            setSourceDetails((prevState) => ({
                Source: source,
                User_name: user_name,
                Phone_number: phone_number,
                Country: country,
                City: city,
                Ip_address: ipAddress,
                started_at: started_at ? formatDateAccordingToTimezoneUsingMoment(started_at, userDetail.timezone_hour, "all") : null,
                Last_message_at: last_message_at ? formatDateAccordingToTimezoneUsingMoment(last_message_at, userDetail.timezone_hour, "all") : null,
                User_input_token: chatbot_user_input_token,
                User_output_token: chatbot_user_output_token,
                playground_url: `https://platform.openai.com/playground/assistants?assistant=${openai_assistant_id}&thread=${openai_thread_id}`
            }))
        } else {
            setSourceDetails((prevState) => ({
                Source: source,
                User_name: user_name,
                Phone_number: phone_number,
                started_at: started_at ? formatDateAccordingToTimezoneUsingMoment(started_at, userDetail.timezone_hour, "all") : null,
                Last_message_at: last_message_at ? formatDateAccordingToTimezoneUsingMoment(last_message_at, userDetail.timezone_hour, "all") : null,
                User_input_token: chatbot_user_input_token,
                User_output_token: chatbot_user_output_token,
                playground_url: `https://platform.openai.com/playground/assistants?assistant=${openai_assistant_id}&thread=${openai_thread_id}`
            }))
        }
        // }
    }
    const handleSearch = (searchValue: string) => {
        const query = searchValue.toLowerCase()
        if (filteredData.closedChats.length > 0) {
            const filteredActiveChatsTemp = filteredData.activeChats.filter((chatbot: any) => {
                return (
                    JSON.stringify(chatbot)?.toLowerCase().includes(query)
                )
            })
            const filteredClosedChatsTemp = filteredData.closedChats.filter((chatbot: any) => {
                return (
                    JSON.stringify(chatbot)?.toLowerCase().includes(query)
                )
            })
            setFilteredData((prevState) => ({ ...prevState, activeChatsFiltered: filteredActiveChatsTemp, closeChatsFiltered: filteredClosedChatsTemp }))
        }
    }

    async function getSpecificChat(chatbot_user_id: number, flagChatInView?: boolean) {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getChatThreadData.get(chatbot_user_id)
        if (response.status && response.data && response.data.length > 0) {
            let chatbot_id = searchParams.get('chatbot_id')
            let activeChats: any
            let closedChats: any
            let pagination: any
            if (window.location.pathname.toLocaleLowerCase().includes('viewchatbot')) {
                activeChats = response.data[0].active_chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id, feedback } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id, feedback }))
                })
                closedChats = response.data[0].closed_chats.chats.filter((chatbot: any) => chatbot.chatbot_id == Number(chatbot_id)).flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id, feedback } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id, feedback }))
                })
                pagination = response.data[0].closed_chats.pagination
            } else {
                activeChats = response.data[0].active_chats.flatMap((chatbot: any) => {
                    const { chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id, chatbot_id, feedback } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id, feedback }))
                })
                closedChats = response.data[0].closed_chats.chats.flatMap((chatbot: any) => {
                    const { chatbot_id, chatbot_name, chatbot_logo_url, chatbot_theme, chatbot_user_threads, openai_assistant_id, feedback } = chatbot
                    return chatbot_user_threads.filter((thread: any) => thread.chatbot_thread_data.length !== 0).map((thread: any) => ({ ...thread, chatbot_name, chatbot_logo_url, chatbot_theme, last_message_value: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].chatbot_thread_value, last_message_time: thread.chatbot_thread_data[thread.chatbot_thread_data.length - 1].timestamp, flag_whatsapp: thread.chatbot_user_route_source == "Website" ? false : true, chatbot_token: chatbot.chatbot_token, openai_assistant_id: openai_assistant_id, chatbot_id, feedback }))
                })
                pagination = response.data[0].closed_chats.pagination
            }
            if (activeChats.length > 0) {
                setSearchParams(prevParams => {
                    const newParams = new URLSearchParams(prevParams);
                    newParams.set('chatbot_user_id', activeChats[0].chatbot_user_id);
                    return newParams;
                });
                let activeChat = activeChats[0]
                let started_date = activeChat.chatbot_thread_data[0].timestamp ?? null
                let end_date = activeChat.chatbot_thread_data[activeChat.chatbot_thread_data.length - 1].timestamp ?? null
                getSourceDetails(
                    activeChat.chatbot_user_ip_address,
                    activeChat.chatbot_user_route_source,
                    activeChat.chatbot_phone_number,
                    activeChat.chatbot_user_name,
                    started_date,
                    end_date,
                    activeChat.chatbot_user_city,
                    activeChat.chatbot_user_country,
                    activeChat.chatbot_user_input_token,
                    activeChat.chatbot_user_output_token,
                    activeChat.openai_assistant_id,
                    activeChat.openai_thread_id
                )
                setCurrentActiveChatbotThread(activeChats[0])
            } else {
                if (closedChats.length > 0) {
                    setSubType('Closed')
                    setSearchParams(prevParams => {
                        const newParams = new URLSearchParams(prevParams);
                        newParams.set('chatbot_user_id', closedChats[0].chatbot_user_id);
                        return newParams;
                    });
                    setCurrentActiveChatbotThread(closedChats[0])
                    let activeChat = closedChats[0]
                    let started_date = activeChat.chatbot_thread_data[0].timestamp ?? null
                    let end_date = activeChat.chatbot_thread_data[activeChat.chatbot_thread_data.length - 1].timestamp ?? null
                    getSourceDetails(activeChat.chatbot_user_ip_address,
                        activeChat.chatbot_user_route_source,
                        activeChat.chatbot_phone_number,
                        activeChat.chatbot_user_name,
                        started_date,
                        end_date,
                        activeChat.chatbot_user_city,
                        activeChat.chatbot_user_country,
                        activeChat.chatbot_user_input_token,
                        activeChat.chatbot_user_output_token,
                        activeChat.openai_assistant_id,
                        activeChat.openai_thread_id
                    )
                }
            }
            if (!flagChatInView) {
                setTimeout(() => {
                    let container = document.getElementById(chatbot_user_id.toString())
                    if (container) {
                        container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                    }
                }, 800)
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    const handlePaginationChat = async (event: any, value: any) => {
        setCurrentActiveChatbotThread(null)
        setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('chatbot_user_id', "");
            newParams.set('page_no', value);
            return newParams;
        });
        props.mainSection ? await getChatLogs(flagFilterFeedback, value, 100, null, true) : await getChatLogs(flagFilterFeedback, value, 100, Number(searchParams.get('chatbot_id')), true)
    }
    async function handleRefresh() {
        if (props.mainSection) {
            getChatLogs(flagFilterFeedback, Number(searchParams.get('page_no')), 100)
        } else {
            getChatLogs(flagFilterFeedback, Number(searchParams.get('page_no')), 100, Number(searchParams.get('chatbot_id')))
        }
    }
    const activeChatsFiltered = (activeChat: any, flagMobile?: boolean) => {
        getSpecificChat(activeChat.chatbot_user_id, true)
        // setSearchParams(prevParams => {
        //     const newParams = new URLSearchParams(prevParams);
        //     newParams.set('chatbot_user_id', activeChat.chatbot_user_id);
        //     return newParams;
        // });
        // if (flagMobile) {
        //     setChatClicked(true);
        //     setChatInfoClicked(false);
        // }
        // setCurrentActiveChatbotThread(activeChat);
        // let started_date = activeChat.chatbot_thread_data[0].timestamp ?? null
        // let end_date = activeChat.chatbot_thread_data[activeChat.chatbot_thread_data.length - 1].timestamp ?? null
        // getSourceDetails(
        //     activeChat.chatbot_user_ip_address,
        //     activeChat.chatbot_user_route_source,
        //     activeChat.chatbot_phone_number,
        //     activeChat.chatbot_user_name,
        //     started_date,
        //     end_date,
        //     activeChat.chatbot_user_city,
        //     activeChat.chatbot_user_country,
        //     activeChat.chatbot_user_input_token,
        //     activeChat.chatbot_user_output_token,
        //     activeChat.openai_assistant_id,
        //     activeChat.openai_thread_id
        // )
    }
    const closedChatsFiltered = (closedChat: any) => {
        getSpecificChat(closedChat.chatbot_user_id, true)
        // let started_date = closedChat.chatbot_thread_data[0].timestamp ?? null
        // let end_date = closedChat.chatbot_thread_data[closedChat.chatbot_thread_data.length - 1].timestamp ?? null
        // setSearchParams(prevParams => {
        //     const newParams = new URLSearchParams(prevParams);
        //     newParams.set('chatbot_user_id', closedChat.chatbot_user_id);
        //     return newParams;
        // });
        // setCurrentActiveChatbotThread(closedChat);
        // getSourceDetails(
        //     closedChat.chatbot_user_ip_address,
        //     closedChat.chatbot_user_route_source,
        //     closedChat.chatbot_phone_number,
        //     closedChat.chatbot_user_name,
        //     started_date,
        //     end_date,
        //     closedChat.chatbot_user_city,
        //     closedChat.chatbot_user_country,
        //     closedChat.chatbot_user_input_token,
        //     closedChat.chatbot_user_output_token,
        //     closedChat.openai_assistant_id,
        //     closedChat.openai_thread_id
        // )
    }
    const handleSubTabs = (tabName: string) => {
        setSubType(tabName);
        setCurrentActiveChatbotThread(null)
        setSourceDetails({})
        if (tabName === "Active") {
            setSearchParams(prevParams => {
                const newParams = new URLSearchParams(prevParams);
                newParams.delete('page_no');
                newParams.set('chatbot_user_id', "");
                return newParams;
            });
        }
        if (tabName === "Closed") {
            setSearchParams(prevParams => {
                const newParams = new URLSearchParams(prevParams);
                newParams.set('chatbot_user_id', "");
                newParams.set('page_no', String(filteredData?.pagination?.start_index ? (Math.floor(filteredData?.pagination?.start_index / Number(filteredData?.pagination?.records_per_page))) + 1 : 1));
                return newParams;
            });
        }
        hasEffectRun.current = false;
    }
    const handleLastMessageValue = (lastMessage: any) => {
        lastMessage = lastMessage.replace("```json", "").replace("```", "")
        if (isJsonString(lastMessage)) {
            let thread = JSON.parse(lastMessage)
            if (typeof (thread) == "object") {
                if (thread.message_format_id) {
                    if (thread.message_format_id == 1) {
                        if (thread?.message_fields?.message) {
                            if (Array.isArray(thread?.message_fields?.message)) {
                                return JSON.stringify(thread?.message_fields?.message.join("&nbsp;"))
                            } else {
                                return thread?.message_fields?.message ?? lastMessage
                            }
                        } else {
                            return lastMessage
                        }
                        // return thread?.message_fields ? thread?.message_fields?.message ? thread?.message_fields?.message : lastMessage : lastMessage
                    }
                    else if (thread.message_format_id == 4 || thread.message_format_id == 3) {
                        return thread?.message_fields?.url_text ?? lastMessage.replace(patternToRemoveSource, "")
                    }
                    else if (Math.trunc(thread.message_format_id) == 5 || Math.trunc(thread.message_format_id) == 6 || Math.trunc(thread.message_format_id) == 7 || Math.trunc(thread.message_format_id) == 8 || Math.trunc(thread.message_format_id) == 9 || Math.trunc(thread.message_format_id) == 10 || Math.trunc(thread.message_format_id) == 12 || Math.trunc(thread.message_format_id) == 13 || Math.trunc(thread.message_format_id) == 15 || Math.trunc(thread.message_format_id) == 16 || Math.trunc(thread.message_format_id) == 17) {
                        return thread?.error_message? thread?.error_message : thread?.message_reply_text ?? lastMessage.replace(patternToRemoveSource, "")
                    }
                    else {
                        return ((!thread?.message && !thread?.additional_text_message && !thread?.message_fields?.additional_text_message) ? lastMessage?.replace(patternToRemoveSource, "") : ((thread?.message) ? thread?.message : "") + " " + ((thread?.additional_text_message) ? thread?.additional_text_message : "" + " " + ((thread?.message_fields?.additional_text_message ? thread?.message_fields?.additional_text_message : ""))))
                    }
                }
                else {
                    return thread?.message ? (thread.message)?.replace(patternToRemoveSource, "") : lastMessage?.replace(patternToRemoveSource, "");
                }
            }
            else {
                return lastMessage?.replace(patternToRemoveSource, "")
            }
        }
        else {
            return lastMessage?.replace(patternToRemoveSource, "")
        }
    }
    function handleFilterFeedback(checked: boolean) {
        setFilterFeedback(checked)
        let chatbot_id = Number(searchParams.get('chatbot_id'))
        getChatLogs(checked, 1, 100, chatbot_id)
    }
    function getChatFeedbackKeys(key: string) {
        switch (key) {
            case "chatbot_message_feedback_value":
                return "Issue category"
            case "chatbot_message_feedback_issue_description":
                return "Issue description"
            case "chatbot_message_feedback_correct_response":
                return "Correct response"
            case "chatbot_message_feedback_correct_response_file":
                return "File reference for correct response"
            case "created_by_username":
                return "User name"
            case "created_by_user_email":
                return "User email"
            case "feedback_option_name":
                return "Issue category"
            case "feedback_provider":
                return "Feedback Provider"
        }
    }
    function getThread(thread: any) {
        if (currentActiveChatbotThread) {
            let index = currentActiveChatbotThread.chatbot_thread_data.findIndex((threadData) => threadData.chatbot_thread_id === (thread.chatbot_thread_id))
            return currentActiveChatbotThread.chatbot_thread_data[index + 1]
        }
    }
    return (
        <>
            {smallScreen ?
                <ChatsMobileResponsive
                    mainSection={props.mainSection}
                    getChatLogs={getChatLogs}
                    currentActiveChatbotThread={currentActiveChatbotThread}
                    setCurrentActiveChatbotThread={setCurrentActiveChatbotThread}
                    getMessageType={getMessageType}
                    getTag={getTag}
                    getSourceDetails={getSourceDetails}
                    handleSearch={handleSearch}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    sourceDetails={sourceDetails}
                    setSourceDetails={setSourceDetails}
                    handlePaginationChat={handlePaginationChat}
                    handleRefresh={handleRefresh}
                    activeChatsFiltered={activeChatsFiltered}
                    closedChatsFiltered={closedChatsFiltered}
                    chatClicked={chatClicked}
                    setChatClicked={setChatClicked}
                    chatInfoClicked={chatInfoClicked}
                    setChatInfoClicked={setChatInfoClicked}
                    handleSubTabs={handleSubTabs}
                    subType={subType}
                    setSubType={setSubType}
                    setSearchParams={setSearchParams}
                    handleLastMessageValue={handleLastMessageValue}
                    chatBotDetails={chatBotDetails}
                    copyText={copyText}
                    setCopyText={setCopyText}
                    handleOpenMenu={handleOpenMenu}
                    handleCloseMenu={handleCloseMenu}
                    setFlagFeedbackDialog={setFlagFeedbackDialog}
                    initialData={initialData}
                    setThreadId={setThreadId}
                    flagTellusMoreOptionBtn={flagTellusMoreOptionBtn}
                    setFlagTellusMoreOptionBtn={setFlagTellusMoreOptionBtn}
                    feedbackData={feedbackData}
                    setFeedbackData={setFeedbackData}
                    buttonText={feedbackOptionsUpdated}
                    handleSubmitFeedback={handleSubmitFeedback}
                    flagFilterFeedback={flagFilterFeedback}
                    setFilterFeedback={setFilterFeedback}
                    handleFilterFeedback={handleFilterFeedback}
                    feedbackMessageNewFormat={feedbackMessageNewFormat}
                    flagOtherSelected={flagOtherSelected}
                    setFlagOtherSelected={setFlagOtherSelected}
                    handleTellUsMoreIssue={handleTellUsMoreIssue}
                    flagFeedbackDialog={flagFeedbackDialog}
                    threadId={threadId}
                    setFeedbackMessageNewFormat={setFeedbackMessageNewFormat}
                    initialNewFormatMessage={initialNewFormatMessage}
                    handleResetChatbotThread={handleResetChatbotThread}
                    chatLevelMenu={chatLevelMenu}
                    setChatlevelMenu={setChatlevelMenu}
                    setValue={setValue}
                    setFeedbackDetails={setFeedbackDetails}
                    feedbackDetails={feedbackDetails}
                    getChatFeedbackKeys={getChatFeedbackKeys}
                    value={value}
                    isFeedbackFormOpen={isFeedbackFormOpen}
                    setIsFeedbackFormOpen={setIsFeedbackFormOpen}
                    feedbackLevelData={feedbackLevelData}
                    feedbackProvidedTypeData={feedbackProvidedTypeData}
                />

                :
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                    <Grid container sx={{ padding: props.mainSection ? "20px 25px 0px 27px" : "0px 0px 0px 27px" }}>
                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }} direction={"column"}>
                                {props.mainSection && <><Grid item className={globalStyles.fullwidth} sx={{ marginBottom: "15px" }}>
                                    <GlobalPageHeaderText
                                        flagTimezone={true}
                                        mainHeadText={"Inbox"}
                                        flagMainTextOnly
                                    />
                                </Grid>
                                    <Divider className={`${globalStyles.fullwidth} ${styles.dividerStyle}`} sx={{ paddingTop: "4px !important" }} /></>}
                                <Grid item className={`${globalStyles.fullwidth} ${styles.lowerBlock}`} sx={{ position: "relative", height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important` }}>
                                    <Grid item>
                                        <Grid container
                                            flexWrap={"nowrap"}
                                            justifyContent={"unset"}>
                                            {/* left block */}
                                            {!feedback_id &&
                                                <ChatUserThreadList id={id}
                                                    flagFilterFeedback={flagFilterFeedback}
                                                    subType={subType}
                                                    handleFilterFeedback={handleFilterFeedback}
                                                    handleSearch={handleSearch}
                                                    handleSubTabs={handleSubTabs}
                                                    filteredData={filteredData}
                                                    currentActiveChatbotThread={currentActiveChatbotThread}
                                                    handleRefresh={handleRefresh}
                                                    setFeedbackDetails={setFeedbackDetails}
                                                    setValue={setValue}
                                                    activeChatsFiltered={activeChatsFiltered}
                                                    hasEffectRun={hasEffectRun}
                                                    handleLastMessageValue={handleLastMessageValue}
                                                    handlePaginationChat={handlePaginationChat}
                                                    closedChatsFiltered={closedChatsFiltered}
                                                />}
                                            <Divider orientation='vertical' />

                                            <Grid item>
                                                <Menu

                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={chatLevelMenu.anchorEl ?? null}
                                                    open={chatLevelMenu.flagOpen ?? false}
                                                    onClose={(e) => setChatlevelMenu((prevState: any) => ({ ...prevState, flagOpen: false, anchorEl: null }))}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            borderRadius: '10px',
                                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                            padding: "0 8px"
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={(event) => {
                                                            handleResetChatbotThread()
                                                        }
                                                        }
                                                        className="menu-message-item"

                                                    >
                                                        Reset
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>

                                            <Divider orientation='vertical' />
                                            {/* middle */}
                                            <ChatUserThreadLogView

                                                currentActiveChatbotThread={currentActiveChatbotThread}
                                                open={open}
                                                setChatlevelMenu={setChatlevelMenu}
                                                setChatbotMessageCopyHover={setChatbotMessageCopyHover}
                                                handleOpenMenu={handleOpenMenu}
                                                getMessageType={getMessageType}
                                                copyText={copyText}
                                                setCopyText={setCopyText}
                                                chatbotMessageCopyHover={chatbotMessageCopyHover}
                                                setFeedbackDetails={setFeedbackDetails}
                                                drawerProps={drawerProps}
                                                setDrawerProps={setDrawerProps}
                                                subType={subType}
                                                handleCloseMenu={handleCloseMenu}
                                                filteredData={filteredData}
                                                setFeedbackData={setFeedbackData}
                                                setValue={setValue}
                                                getThread={getThread}
                                                setCurrentActiveChatbotThread={setCurrentActiveChatbotThread}
                                                setFlagFeedbackDialog={setFlagFeedbackDialog}
                                                setFlagTellusMoreOptionBtn={setFlagTellusMoreOptionBtn}
                                                setThreadId={setThreadId}
                                                getSourceDetails={getSourceDetails}
                                                setIsFeedbackFormOpen={setIsFeedbackFormOpen}
                                            />
                                            {/* chat information block */}
                                            <ChatInformationBlock
                                                open={open}
                                                setOpen={setOpen}
                                                value={value}
                                                setValue={setValue}
                                                sourceDetails={sourceDetails}
                                                feedbackDetails={feedbackDetails}
                                                getChatFeedbackKeys={getChatFeedbackKeys}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid >
                </Grid >}
            <GlobalPopUp
                titlePadding="0px !important"
                // open={feedbackData.flagFeedbackDialog}
                // handleClose={() => { setFeedbackData((prevState) => ({ ...prevState, flagFeedbackDialog: false })) }}
                open={isFeedbackFormOpen}
                handleClose={() => setIsFeedbackFormOpen(false)}
                overflow='hidden'
            >
                <FeedbackDialog
                    flagFeedbackDialog={flagFeedbackDialog}
                    setFlagFeedbackDialog={setFlagFeedbackDialog}
                    chatbot_thread_id={threadId}
                    setFlagTellusMoreOptionBtn={setFlagTellusMoreOptionBtn}
                    handleSubmitFeedback={handleSubmitFeedback}
                    setFeedbackData={setFeedbackData}
                    feedbackData={feedbackData}
                    handleTellUsMoreIssue={handleTellUsMoreIssue}
                    setFeedbackMessageNewFormat={setFeedbackMessageNewFormat}
                    feedbackMessageNewFormat={feedbackMessageNewFormat}
                    initialNewFormatMessage={initialNewFormatMessage}
                    flagOtherSelected={flagOtherSelected}
                    setFlagOtherSelected={setFlagOtherSelected}
                    currentActiveChatbotThread={currentActiveChatbotThread}
                    setCurrentActiveChatbotThread={setCurrentActiveChatbotThread}
                    feedbackProvidedTypeData={feedbackProvidedTypeData}
                    feedbackLevel={feedbackLevelData}
                    onCloseOfFeedbackForm={() => setIsFeedbackFormOpen(false)}
                    feedbackFor="message"
                    chatbotThreadId={threadId}
                    getSpecificChat={getSpecificChat}
                />
            </GlobalPopUp>
        </>
    )
}
