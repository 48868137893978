import { Autocomplete, Divider, Grid, TextField, Typography } from '@mui/material';
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import React from 'react'
import { campaignContactData, campaignContactTagAssignment } from '../../../models/axiosModel';
import GlobalButton from '../../utility/globalButton/button';
import { nameData } from './contactTable';


interface ContactFilterPopupProps {
    setFlagFilterDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setFlagApplyFilter: React.Dispatch<React.SetStateAction<boolean>>,
    filterCampaignContact: () => void,
    phoneNumberData: campaignContactData[],
    selectedNumberData: campaignContactData[],
    setSelectedNumberData: React.Dispatch<React.SetStateAction<campaignContactData[]>>,
    nameData: nameData[],
    selectedNameData: nameData[],
    setSelectedNameData: React.Dispatch<React.SetStateAction<nameData[]>>,
    tagData: campaignContactTagAssignment[],
    selectedTagData: campaignContactTagAssignment[],
    setSelectedTagData: React.Dispatch<React.SetStateAction<campaignContactTagAssignment[]>>,
}
const ContactFilterPopup: React.FC<ContactFilterPopupProps> = ({ setFlagFilterDialog, setFlagApplyFilter, filterCampaignContact, phoneNumberData, selectedNumberData, setSelectedNumberData, nameData, selectedNameData, setSelectedNameData, tagData, selectedTagData, setSelectedTagData }) => {

    const handleCancelClick = () => {
        setFlagApplyFilter(true);
        setSelectedNumberData([]);
        setSelectedNameData([]);
        setSelectedTagData([]);
        setFlagFilterDialog(false);
    }
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}> Contact Filter</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={style.filterPopupBodyContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Phone Number</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                className={`${style.contactFilterDropDowns} ${style.contactFilterDropdownsInPopup}`}
                                                options={phoneNumberData}
                                                getOptionLabel={(option) => option.contact_data_value}
                                                multiple
                                                value={phoneNumberData && phoneNumberData.length > 0 && phoneNumberData.filter((data) => selectedNumberData.some(number => number.contact_data_id === data.contact_data_id)) || []}
                                                onChange={(_: React.SyntheticEvent, value: campaignContactData[]) => setSelectedNumberData(value)}
                                                renderInput={params => {
                                                    const { InputProps, ...restParams } = params;
                                                    const { startAdornment, ...restInputProps } = InputProps;
                                                    return (
                                                        <TextField
                                                            {...restParams}
                                                            placeholder='Search by Phone Number'
                                                            InputProps={{
                                                                ...restInputProps,
                                                                startAdornment: (
                                                                    <div style={{
                                                                        maxHeight: "90px",
                                                                        overflowY: 'auto',
                                                                    }}
                                                                    >
                                                                        {startAdornment}
                                                                    </div>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container className={style.filterPopupBodyContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Name</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                className={`${style.contactFilterDropDowns} ${style.contactFilterDropdownsInPopup}`}
                                                options={nameData}
                                                getOptionLabel={(option) => option.full_name}
                                                multiple
                                                value={nameData && nameData.length > 0 && nameData.filter((data) => selectedNameData.some(fullName => fullName === data)) || []}
                                                onChange={(_: React.SyntheticEvent, value: nameData[]) => setSelectedNameData(value)}
                                                renderInput={params => {
                                                    const { InputProps, ...restParams } = params;
                                                    const { startAdornment, ...restInputProps } = InputProps;
                                                    return (
                                                        <TextField
                                                            {...restParams}
                                                            placeholder='Search by Name'
                                                            InputProps={{
                                                                ...restInputProps,
                                                                startAdornment: (
                                                                    <div style={{
                                                                        maxHeight: "90px",
                                                                        overflowY: 'auto',
                                                                    }}
                                                                    >
                                                                        {startAdornment}
                                                                    </div>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container className={style.filterPopupBodyContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Tag</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                className={`${style.contactFilterDropDowns} ${style.contactFilterDropdownsInPopup}`}
                                                options={tagData}
                                                getOptionLabel={(option) => option.contact_tag_name}
                                                multiple
                                                value={tagData && tagData.length > 0 && tagData.filter((data) => selectedTagData.some(tag => tag.contact_tag_id === data.contact_tag_id)) || []}
                                                onChange={(_: React.SyntheticEvent, value: campaignContactTagAssignment[]) => setSelectedTagData(value)}
                                                renderInput={params => {
                                                    const { InputProps, ...restParams } = params;
                                                    const { startAdornment, ...restInputProps } = InputProps;
                                                    return (
                                                        <TextField
                                                            {...restParams}
                                                            placeholder='Search by Tags'
                                                            InputProps={{
                                                                ...restInputProps,
                                                                startAdornment: (
                                                                    <div style={{
                                                                        maxHeight: "90px",
                                                                        overflowY: 'auto',
                                                                    }}
                                                                    >
                                                                        {startAdornment}
                                                                    </div>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"} sx={{ padding: "20px 0" }}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Cancel"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='borderedButtonStyle'
                                        onClick={() => { handleCancelClick(); }}
                                    />
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Apply Filter"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        onClick={() => { setFlagFilterDialog(false); filterCampaignContact(); }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactFilterPopup
