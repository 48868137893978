import React from 'react'
import globalStyles from "../../../../assets/styles/global.module.scss";
import { getIntegrationIcon } from '../../../../utils/getIcons';
import { Grid, Typography } from '@mui/material';
import styles from "../../../../assets/styles/componentStyles/channels/global/integrationSetupHeader.module.scss";
interface integrationSetupHeaderProps {
    integrationType: string,
    integrationSubHeading: string
}

function IntegrationSetupHeader(props: integrationSetupHeaderProps) {
    const { integrationType, integrationSubHeading } = props
    return (
        <Grid item className={globalStyles.fullwidth}>
            <Grid container alignItems={"start"} gap={"13px"} flexWrap={"nowrap"} flexDirection={{ xs: "row-reverse", md: "row" }}>
                <Grid item className={styles.integrationContent}>
                    <img alt="" src={getIntegrationIcon(props.integrationType)} className={styles.integrationIcon} />
                </Grid>
                <Grid item>
                    <Grid container direction={"column"} gap={{ xs: "8px", md: "4px" }}>
                        <Grid item>
                            <Typography className={globalStyles.whatsappIntegrationFontStyle}>{`${integrationType} Integration`}</Typography>
                        </Grid>
                        <Grid item>
                            <span className={globalStyles.whatsappIntegrationSubFontStyle}>{integrationSubHeading}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default IntegrationSetupHeader