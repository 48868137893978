import { Autocomplete, Breadcrumbs, Button, Divider, Grid, InputLabel, Link, TextField, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import styles from '../../../assets/styles/componentStyles/dataconnector.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { GlobalTable } from '../../utility/globalTable';
import addIcon from '../../../assets/chatbotAssets/addCircularReqIcon.svg'
import agent from '../../../services/api';
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import Required from '../../utility/required';
import GlobalTextField from '../../utility/globalTextfield';
import GlobalPopUp from '../../utility/globalPopUp';
import RequestParameter from './requestParameter';
import RequestHeader from './requestHeader';
import AddResponse from './addResponse';
import validator from 'validator';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import globalStrenameDocumentyles from "../../../assets/renameDocument.svg";
import deleteDoc from "../../../assets/deleteDoc.svg";
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isJsonString } from '../../../utils/constants';
import DynamicLoaderForm from './dynamicLoaderForm';
let initialResponseData = {
    id: 0,
    function_response_data_id: null,
    function_response_name: "",
    function_parameter_type_id: null,
    function_response_description: "",
    function_response_default: "",
    function_response_required_flag: false,
    function_response_enum_value: ''
}
let initialReqData = {
    id: 0,
    function_header_id: null,
    function_header_key: "",
    function_header_value: ""
}
let initialReqParamsData = {
    id: 0,
    function_parameter_data_id: null,
    function_parameter_name: "",
    function_parameter_type_id: null,
    function_parameter_description: "",
    function_parameter_default: "",
    function_parameter_required_flag: false,
    function_parameter_enum_value: ''
}
let initialLoaderData = {
    id: 0,
    function_loader_id: null,
    function_loader_icon: "",
    function_loader_text: '',
}
let initalFlagData = {
    flagHeaderKey: false,
    flagParamsKey: false,
    flagResponseKey: false
}
const Createdataconnector = () => {

    const navigate = useNavigate()
    const {
        setFlagLoading,
        success,
        setSuccess,
        error,
        setError,
    } = useContext(UserContext) as UserContextPayload;

    const [manageDataConnectorPayload, setManageDataConnectorPayload] = useState({
        chatbot_id: 0,
        chatbot_data_connectors: [
            {
                chatbot_data_connector_id: null,
                chatbot_data_connector_url: "",
                function_method_id: null,
                data_connector_functions: {
                    data_connector_function_id: null,
                    data_connector_function_name: "",
                    data_connector_function_description: "",
                    data_connector_function_headers: [],
                    data_connector_function_loader: [],
                    // data_connector_function_parameters: "",
                    // data_connector_function_response: ""
                    data_connector_function_parameters: {
                        function_parameter_id: null,
                        function_parameter_arr_of_obj_flag: false,
                        function_parameter_metadata: null,
                        function_parameter_json_data: "",
                        function_parameter_data: [],
                    },
                    data_connector_function_response: {
                        function_response_id: null,
                        function_Response_arr_of_obj_flag: false,
                        function_parameter_metadata: null,
                        function_response_json_data: "",
                        function_response_data: []
                    }
                }
            }]
    })
    const [manageDataConnectorPayloadOriginal, setManageDataConnectorPayloadOriginal] = useState({
        chatbot_id: 0,
        chatbot_data_connectors: [
            {
                chatbot_data_connector_id: null,
                chatbot_data_connector_url: "",
                function_method_id: null,
                data_connector_functions: {
                    data_connector_function_id: null,
                    data_connector_function_name: "",
                    data_connector_function_description: "",
                    data_connector_function_headers: [],
                    data_connector_function_loader: [],
                    data_connector_function_parameters: {
                        function_parameter_id: null,
                        function_parameter_arr_of_obj_flag: false,
                        function_parameter_metadata: null,
                        function_parameter_json_data: "",
                        function_parameter_data: [

                        ]
                    },
                    data_connector_function_response: {
                        function_response_id: null,
                        function_Response_arr_of_obj_flag: false,
                        function_parameter_metadata: null,
                        function_response_json_data: "",
                        function_response_data: []
                    }
                }
            }]
    })
    const [flagShowValidation, setFlagShowValidation] = useState({
        flagInvalidURL: false,
        flagReqParamInvalidJSON: false,
        flagResParamInvalidJSON: false
    })
    const [requestParamsCreatePayload, setRequestParamsCreatePayload] = useState<any>(initialReqParamsData)
    const [requestHeaderCreatePayload, setRequestHeaderCreatePayload] = useState(initialReqData)
    const [responseCreatePayload, setResponseCreatePayload] = useState(initialResponseData)
    const [dynamicLoaderCreatePayload, setDynamicLoaderCreatePayload] = useState(initialLoaderData)
    const onChangeDynamicLoaderDetails = (value: any, keyName: string) => {
        setDynamicLoaderCreatePayload((prevState) => ({ ...prevState, [keyName]: value }))
    }
    const onChangeReqHeaderPayload = (value: any, keyName: string, objType: string) => {
        if (objType == "reqHeader") {
            setRequestHeaderCreatePayload((prevState) => ({ ...prevState, [keyName]: value }))
        } else if (objType == "reqParams") {
            setRequestParamsCreatePayload((prevState: any) => ({ ...prevState, [keyName]: value }))
        } else {
            setResponseCreatePayload((prevState) => ({ ...prevState, [keyName]: value }))
        }
    }
    const [flagKeyExistError, setFlagKeyExistError] = useState(initalFlagData)
    const { functionMethodsList } = useContext(GenericDataContext) as GenericDataContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [flagRequestParameter, setFlagRequestParameter] = useState(false);
    const [flagDynamicLoader, setFlagDynamicLoader] = useState(false);
    const [flagRequestHeader, setFlagRequestHeader] = useState(false);
    const [flagResponse, setFlagResponse] = useState(false);
    const [searchParams] = useSearchParams()
    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    let chatbotId = searchParams.get("chatbot_id")
    const [flagDeletePopup, setFlagDeletePopup] = useState(false)
    const [listOfHeaders, setListOfHeaders] = useState([
        [
            {
                name: "Name - Key",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_parameter_name",
                iconKey: "documentIco",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Type",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_parameter_type_name",
                iconKey: "featureIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Description",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_parameter_description",
                iconKey: "featureIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
        ],
        [
            {
                name: "New Key",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_header_key",
                iconKey: "documentIco",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Value",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_header_value",
                iconKey: "featureIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
        ],
        [
            {
                name: "Name - Key",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_response_name",
                iconKey: "documentIco",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Type",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_parameter_type_name",
                iconKey: "featureIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Description",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_response_description",
                iconKey: "featureIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },

        ],
        [
            {
                name: "Loader-Text",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_loader_text",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "Loader GIF",
                alignment: "left",
                flagIcon: false,
                valueKey: "function_loader_icon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
            {
                name: "",
                alignment: "right",
                flagIcon: true,
                valueKey: "",
                iconKey: "editIcon",
                sx: {
                    padding: "5px  16px !important"
                }
            },
        ]
    ])
    const [requestHeadersRowData, setRequestHeadersRowData] = useState<any>([]);
    const [requestParamsRowData, setRequestParamsRowData] = useState<any>([]);
    const [responseRowData, setResponseRowData] = useState<any>([])
    const [toBeDeletedDetails, setToBeDeletedDetails] = useState<any>(null)
    const getDataConnectorDetails = async (chatbot_id: string, connector_id: string) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        const response = await agent.getChatbotDataConnectorDetail.get(connector_id, chatbot_id)
        if (response.status) {
            setManageDataConnectorPayload({ ...response.data[0] })
            setManageDataConnectorPayloadOriginal({ ...response.data[0] })
            // let functionParamsData = response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters ? response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_data : []
            let functionParamsData = response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters ? response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_data : ""
            let responseData = response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_response ? response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_response_data : ""
            let reqHeaders = response.data[0].chatbot_data_connectors[0].data_connector_functions.data_connector_function_headers
            setRequestHeadersRowData(reqHeaders);
            setRequestParamsRowData(functionParamsData);
            setResponseRowData(responseData);
        } else {
            navigate(`/chatbot/viewchatbot/createintegration?chatbot_id=${chatbot_id}`)
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }
    useEffect(() => {
        // debugger;
        let functionParamsData = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters : ""
        let responseData = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response
        let reqHeaders = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_headers
        setRequestHeadersRowData(reqHeaders);
        setRequestParamsRowData(functionParamsData);
        setResponseRowData(responseData);


    }, [manageDataConnectorPayload])

    useEffect(() => {
        let chatbot_id = searchParams.get('chatbot_id')
        let data_connector_id = searchParams.get('connector_id')
        setManageDataConnectorPayload((prevState) => ({ ...prevState, chatbot_id: Number(chatbot_id) }))
        if (chatbot_id && data_connector_id) {
            getDataConnectorDetails(chatbot_id, data_connector_id)
        }
    }, [])

    const rowClicked = async (e: any) => {
    }
    console.log("manag", manageDataConnectorPayload)
    const handleChangePayload = (value: any, keyName: string, parentKey?: string, subKey?: string) => {
        if (parentKey == "data_connector_functions" && !subKey) {
            setManageDataConnectorPayload((prevState) => ({ ...prevState, chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector) => ({ ...dataConnector, data_connector_functions: { ...dataConnector.data_connector_functions, [keyName]: value } })) }))
        }
        else if (parentKey == "data_connector_functions" && subKey == "function_parameter_json_data") {
            setManageDataConnectorPayload((prevState) => ({
                ...prevState, chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector) => {
                    return {
                        ...dataConnector, data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_parameters: {
                                ...dataConnector.data_connector_functions.data_connector_function_parameters,
                                [subKey]: value
                            }
                        }
                    }
                })
            }))
        }
        else if (parentKey == "data_connector_functions" && subKey == "function_response_json_data") {
            setManageDataConnectorPayload((prevState) => ({
                ...prevState, chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector) => {
                    return {
                        ...dataConnector, data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_response: {
                                ...dataConnector.data_connector_functions.data_connector_function_response,
                                [subKey]: value
                            }
                        }
                    }
                })
            }))
        }
        else if (parentKey == "chatbot_data_connectors") {
        } else {
            setManageDataConnectorPayload((prevState) => ({ ...prevState, chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector) => ({ ...dataConnector, [keyName]: value })) }))
        }
    }
    function checkFlagValidJson() {
        if ((!manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters || manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_json_data == "") && (!manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response || manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_response_json_data == "")) {
            return true
        } else {
            if (manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters && manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_json_data !== "" && isJsonString(manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters?.function_parameter_json_data)) {
                if (manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response && manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_response_json_data !== "" && isJsonString(manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response?.function_response_json_data)) {
                    return true
                } else {
                    if (!manageDataConnectorPayload.chatbot_data_connectors[0]?.data_connector_functions.data_connector_function_response || manageDataConnectorPayload.chatbot_data_connectors[0]?.data_connector_functions.data_connector_function_response?.function_response_json_data == "") {
                        return true
                    } else {
                        setFlagShowValidation((prevState) => ({ ...prevState, flagResParamInvalidJSON: true }))
                        return false
                    }
                }
            } else {
                if (manageDataConnectorPayload.chatbot_data_connectors[0]?.data_connector_functions.data_connector_function_parameters?.function_parameter_json_data == "") {
                    return true
                } else {
                    setFlagShowValidation((prevState) => ({ ...prevState, flagReqParamInvalidJSON: true }))
                    return false
                }
            }
        }
    }
    const handleSaveDynamicLoader = () => {
        setManageDataConnectorPayload((prevState: any) => ({
            ...prevState,
            chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                let updatedResponseFlag = false
                const updatedResponse = dataConnector.data_connector_functions.data_connector_function_loader.map((loader: any) => {
                    if (loader.function_loader_id ? (dynamicLoaderCreatePayload.function_loader_id == loader.function_loader_id) : (dynamicLoaderCreatePayload.id == loader.id)) {
                        updatedResponseFlag = true
                        return dynamicLoaderCreatePayload
                    } else {
                        return loader
                    }
                })
                if (!updatedResponseFlag) {
                    updatedResponse.push(dynamicLoaderCreatePayload)
                }
                return ({
                    ...dataConnector,
                    data_connector_functions: {
                        ...dataConnector.data_connector_functions,
                        data_connector_function_loader: updatedResponse
                    }
                })
            })
        }))
        setDynamicLoaderCreatePayload((prevState) => ({ ...initialLoaderData, id: prevState.id + 1 }))
        setFlagDynamicLoader(false)
    }
    const handleSave = (objName: string) => {
        if (objName == "reqHeader") {
            let alreadyExistingKeys: any = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_headers;
            let keyAlreadyMatches = false
            for (let i = 0; i < alreadyExistingKeys.length; i++) {
                if (requestHeaderCreatePayload.function_header_key == alreadyExistingKeys[i].function_header_key && (requestHeaderCreatePayload.function_header_id ? requestHeaderCreatePayload.function_header_id !== alreadyExistingKeys[i].function_header_id : requestHeaderCreatePayload.id !== alreadyExistingKeys[i].id)) {
                    keyAlreadyMatches = true
                }
            }
            if (keyAlreadyMatches) {
                setFlagKeyExistError((prevState) => ({ ...prevState, flagHeaderKey: true }))
            } else {
                setManageDataConnectorPayload((prevState: any) => ({
                    ...prevState,
                    chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                        let headersUpdated = false;
                        const updatedHeaders = dataConnector.data_connector_functions.data_connector_function_headers.map((header: any) => {
                            if (requestHeaderCreatePayload?.function_header_id
                                ? (header.function_header_id === requestHeaderCreatePayload.function_header_id)
                                : (requestHeaderCreatePayload.id == header.id)) {
                                headersUpdated = true;
                                return requestHeaderCreatePayload;
                            } else {
                                return header;
                            }
                        });

                        if (!headersUpdated) {
                            updatedHeaders.push(requestHeaderCreatePayload);
                        }

                        return {
                            ...dataConnector,
                            data_connector_functions: {
                                ...dataConnector.data_connector_functions,
                                data_connector_function_headers: updatedHeaders
                            }
                        };
                    })
                }))
                setRequestHeaderCreatePayload((prevState) => ({ ...initialReqData, id: prevState.id + 1 }))
                setFlagRequestHeader(false)
            }
        } else if (objName == "reqParams") {
            let alreadyExistingKeys: any = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters : "";
            let keyAlreadyMatches = false
            if (alreadyExistingKeys.length > 0) {
                for (let i = 0; i < alreadyExistingKeys.length; i++) {
                    if (requestParamsCreatePayload.function_parameter_name == alreadyExistingKeys[i].function_parameter_name && (requestParamsCreatePayload.function_parameter_data_id ? requestParamsCreatePayload.function_parameter_data_id !== alreadyExistingKeys[i].function_parameter_data_id : requestParamsCreatePayload.id !== alreadyExistingKeys[i].id)) {
                        keyAlreadyMatches = true
                    }
                }
            }

            if (keyAlreadyMatches) {
                setFlagKeyExistError((prevState) => ({ ...prevState, flagParamsKey: true }))
            } else {
                setManageDataConnectorPayload((prevState: any) => ({
                    ...prevState,
                    chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                        let paramsUpdated = false;

                        if (dataConnector.data_connector_functions.data_connector_function_parameters) {
                            const updatedParams = dataConnector.data_connector_functions.data_connector_function_parameters.function_parameter_data.map((param: any) => {
                                if (requestParamsCreatePayload.function_parameter_data_id ? (requestParamsCreatePayload.function_parameter_data_id == param.function_parameter_data_id) : (requestParamsCreatePayload.id == param.id)) {
                                    paramsUpdated = true
                                    return requestParamsCreatePayload
                                } else {
                                    return param
                                }
                            })
                            if (!paramsUpdated) {
                                updatedParams.push(requestParamsCreatePayload)
                            }
                            return {
                                ...dataConnector,
                                data_connector_functions: {
                                    ...dataConnector.data_connector_functions,
                                    data_connector_function_parameters: {

                                        ...dataConnector.data_connector_functions.data_connector_function_parameters,
                                        function_parameter_data: updatedParams
                                    }
                                }
                            }
                        } else {
                            let data_connector_function_paramter: any = {
                                "function_parameter_id": null,
                                "function_parameter_arr_of_obj_flag": true,
                                "function_parameter_data": []
                            }
                            data_connector_function_paramter.function_parameter_data.push(requestParamsCreatePayload)
                            return {
                                ...dataConnector,
                                data_connector_functions: {
                                    ...dataConnector.data_connector_functions,
                                    data_connector_function_parameters: data_connector_function_paramter
                                }
                            }
                        }
                    })
                }))
                setRequestParamsCreatePayload((prevState: any) => ({ ...initialReqParamsData, id: prevState.id + 1 }))
                setFlagRequestParameter(false)
            }
        } else {
            let alreadyExistingKeys: any = manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response;
            let keyAlreadyMatches = false
            for (let i = 0; i < alreadyExistingKeys.length; i++) {
                if (responseCreatePayload.function_response_name == alreadyExistingKeys[i].function_response_name && (responseCreatePayload.function_response_data_id ? responseCreatePayload.function_response_data_id !== alreadyExistingKeys[i].function_response_data_id : responseCreatePayload.id !== alreadyExistingKeys[i].id)) {
                    keyAlreadyMatches = true
                }
            }
            if (keyAlreadyMatches) {
                setFlagKeyExistError((prevState) => ({ ...prevState, flagResponseKey: true }))
            } else {
                setManageDataConnectorPayload((prevState: any) => ({
                    ...prevState,
                    chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                        let updatedResponseFlag = false
                        const updatedResponse = dataConnector.data_connector_functions.data_connector_function_response.function_response_data.map((response: any) => {
                            if (responseCreatePayload.function_response_data_id ? (responseCreatePayload.function_response_data_id == response.function_response_data_id) : (responseCreatePayload.id == response.id)) {
                                updatedResponseFlag = true
                                return responseCreatePayload
                            } else {
                                return response
                            }
                        })
                        if (!updatedResponseFlag) {
                            updatedResponse.push(responseCreatePayload)
                        }
                        return ({
                            ...dataConnector,
                            data_connector_functions: {
                                ...dataConnector.data_connector_functions,
                                data_connector_function_response: {
                                    ...dataConnector.data_connector_functions.data_connector_function_response,
                                    function_response_data: updatedResponse
                                }
                            }
                        })
                    })
                }))
                setResponseCreatePayload((prevState) => ({ ...initialResponseData, id: prevState.id + 1 }))
                setFlagResponse(false)
            }
        }
    }
    const handleCancel = (objName: string) => {
        setFlagDynamicLoader(false);
        setDynamicLoaderCreatePayload(initialLoaderData)
        if (objName == "reqHeader") {
            setFlagRequestHeader(false)
            setRequestHeaderCreatePayload(initialReqData)
        } else if (objName == "reqParams") {
            setFlagRequestParameter(false)
            setRequestParamsCreatePayload(initialReqParamsData)
        } else {
            setFlagResponse(false)
            setResponseCreatePayload(initialResponseData)
        }
        setFlagKeyExistError(initalFlagData)
    }
    const handleSaveDataConnector = async () => {
        if (validator.isURL(manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_url)) {
            if (checkFlagValidJson()) {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));
                let temp = JSON.parse(JSON.stringify(manageDataConnectorPayload))
                const payload = { ...temp }
                if (payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters) {
                    // @ts-ignore
                    payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters = payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_json_data
                }
                if (payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response) {
                    // @ts-ignore
                    payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response = payload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_response_json_data
                }
                const response = await agent.manageChatbotDataConnector.post(payload);
                if (response.status) {
                    if (Array.isArray(response.data)) {
                        let data = { ...response.data[0] }
                        setManageDataConnectorPayload(data)
                        setManageDataConnectorPayloadOriginal(data)
                        if (manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_id) {
                            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "DataConnector updated successfully!" }))
                        } else {
                            setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "DataConnector created successfully!" }))
                        }
                    } else {
                        setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: response.data.message }))
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, false) }))
                    }
                }
            }
        } else {
            setFlagShowValidation((prevState) => ({ ...prevState, flagInvalidURL: true }))
        }

        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }
    const getHandleSaveDataConnectorDisableCond = () => {
        if (manageDataConnectorPayload.chatbot_data_connectors[0].function_method_id !== null && manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_url !== "" && manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_description !== "" && manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name !== ""
        ) {
            return false
        } else {
            return true
        }
    }
    const setHandleEdit = (e: any) => {
        if (e.function_response_description) {
            setResponseCreatePayload(e)
            setFlagResponse(true)
        } else if (e.function_parameter_description) {
            setRequestParamsCreatePayload(e)
            setFlagRequestParameter(true)
        } else if (e.function_loader_text) {
            setDynamicLoaderCreatePayload(e)
            setFlagDynamicLoader(true)
        }
        else {
            setRequestHeaderCreatePayload(e)
            setFlagRequestHeader(true)
        }
    }
    const handleOpenEditSection = (e: any, rowDetail: any) => {
        if (e.iconKey !== "editIcon") {
            setHandleEdit(rowDetail)
        }
    }

    const setChangeDataStructureResp = (val: boolean) => {
        // function_Response_arr_of_obj_flag
        setManageDataConnectorPayload((prevState: any) => ({
            ...prevState,
            chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                return ({
                    ...dataConnector,
                    data_connector_functions: {
                        ...dataConnector.data_connector_functions,
                        data_connector_function_response: {
                            ...dataConnector.data_connector_functions.data_connector_function_response,
                            function_Response_arr_of_obj_flag: val
                        }
                    }
                })
            })
        }))
    }
    const cancelBtnOnClick = () => {
        if (manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_id) {
            setManageDataConnectorPayload(manageDataConnectorPayloadOriginal)
        } else {
            let searchParams = window.location.search
            navigate(`/chatbot/viewchatbot/integration${window.location.search}`)
        }
    }
    const cancelBtnOnClickDisableCond = () => {
        if (manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_id) {
            if (JSON.stringify(manageDataConnectorPayload) == JSON.stringify(manageDataConnectorPayloadOriginal)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    const setHandleDelete = (e: any) => {
        setToBeDeletedDetails(e)
        setFlagDeletePopup(true)
    }
    const handleDeleteConfirmation = () => {
        if (toBeDeletedDetails.function_response_description) {
            setManageDataConnectorPayload((prevState: any) => ({
                ...prevState,
                chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                    let updatedResponse = dataConnector.data_connector_functions.data_connector_function_response.function_response_data.map((response: any) => {
                        if (toBeDeletedDetails.function_response_data_id ? (toBeDeletedDetails.function_response_data_id == response.function_response_data_id) : (toBeDeletedDetails.id == response.id)) {
                            return null
                        } else {
                            return response
                        }
                    })
                    updatedResponse = updatedResponse.filter((response: any) => response !== null)
                    return ({
                        ...dataConnector,
                        data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_response: {
                                ...dataConnector.data_connector_functions.data_connector_function_response,
                                function_response_data: updatedResponse
                            }
                        }
                    })
                })
            }))

        } else if (toBeDeletedDetails.function_parameter_description) {
            setManageDataConnectorPayload((prevState: any) => ({
                ...prevState,
                chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                    let updatedParams = dataConnector.data_connector_functions.data_connector_function_parameters.function_parameter_data.map((param: any) => {
                        if (toBeDeletedDetails.function_parameter_data_id ? (toBeDeletedDetails.function_parameter_data_id == param.function_parameter_data_id) : (toBeDeletedDetails.id == param.id)) {
                            return null
                        } else {
                            return param
                        }
                    })
                    updatedParams = updatedParams.filter((param: any) => param !== null)
                    return {
                        ...dataConnector,
                        data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_parameters: {
                                ...dataConnector.data_connector_functions.data_connector_function_parameters,
                                function_parameter_data: updatedParams
                            }
                        }
                    }
                })
            }))
            // setRequestParamsCreatePayload(e)
            // setFlagRequestParameter(true)
        } else if (toBeDeletedDetails.function_loader_text) {
            setManageDataConnectorPayload((prevState: any) => ({
                ...prevState,
                chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                    let updatedLoaderDetails = dataConnector.data_connector_functions.data_connector_function_loader.map((loader: any) => {
                        if (toBeDeletedDetails?.function_loader_id
                            ? (loader.function_loader_id === toBeDeletedDetails.function_loader_id)
                            : (toBeDeletedDetails.id == loader.id)) {
                            return null;
                        } else {
                            return loader;
                        }
                    });
                    updatedLoaderDetails = updatedLoaderDetails.filter((header: any) => header !== null)

                    return {
                        ...dataConnector,
                        data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_loader: updatedLoaderDetails
                        }
                    };
                })
            }))
        }
        else {
            setManageDataConnectorPayload((prevState: any) => ({
                ...prevState,
                chatbot_data_connectors: prevState.chatbot_data_connectors.map((dataConnector: any) => {
                    let updatedHeaders = dataConnector.data_connector_functions.data_connector_function_headers.map((header: any) => {
                        if (toBeDeletedDetails?.function_header_id
                            ? (header.function_header_id === toBeDeletedDetails.function_header_id)
                            : (toBeDeletedDetails.id == header.id)) {
                            return null;
                        } else {
                            return header;
                        }
                    });
                    updatedHeaders = updatedHeaders.filter((header: any) => header !== null)

                    return {
                        ...dataConnector,
                        data_connector_functions: {
                            ...dataConnector.data_connector_functions,
                            data_connector_function_headers: updatedHeaders
                        }
                    };
                })
            }))
        }
        setFlagDeletePopup(false)
    }
    const menuItems = [
        {
            label: 'Edit', icon: <img alt="" src={globalStrenameDocumentyles} />, action: (e: any) => {
                setHandleEdit(e)
            }
        },
        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" />, action: (e: any) => setHandleDelete(e) },
    ];
    const getDeleteConfirmationSentence = () => {
        if (toBeDeletedDetails) {
            if (toBeDeletedDetails.function_response_description) {
                return `Are you sure you want to delete ${toBeDeletedDetails.function_response_name} Response Key`
            } else if (toBeDeletedDetails.function_parameter_description) {
                return `Are you sure you want to delete ${toBeDeletedDetails.function_parameter_name} Parameter Key`
            } else if (toBeDeletedDetails.function_loader_text) {
                return `Are you sure you want to delete this Loader`
            }
            else {
                return `Are you sure you want to delete ${toBeDeletedDetails.function_header_key} Header Key`
            }
        } else {
            return "Are you sure you want to delete"
        }
    }
    return (
        <>
            {flagDeletePopup &&
                <GlobalDeletePopup
                    deleteDescription={getDeleteConfirmationSentence()}
                    open={flagDeletePopup}
                    onClose={() => { setFlagDeletePopup(false) }}
                    onDelete={(e: any) => {
                        handleDeleteConfirmation()
                    }}
                />}

            <Grid item xl={12} xs={12} className={`${globalStyles.fullwidth}
             ${globalStyles.dashboardChildWrapper} ${styles.createDataConnectorBlock}
             `} sx={{ width: "100%", padding: "15px 2px !important" }}>
                <Grid container alignItems={"center"} justifyContent={"start"} >
                    <Grid item xs={12}>
                        <Grid container flexWrap={"nowrap"} direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={{ xs: "start", md: "center" }} gap={"5px"}>

                            <Grid item xs={12} md={10} sx={{ marginBottom: { xs: "0px", md: "10px" } }} alignItems={"center"}>
                                <GlobalPageHeaderText
                                    mainHeadText={window.location.search.includes("connector_id") ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name : "Create Integration"}
                                    flagMainTextOnly
                                    mainSubText={
                                        !smallScreen && <Breadcrumbs
                                            aria-label="breadcrumb"
                                            className={styles.cbHeaderBreadCrumb}
                                            sx={{
                                                '& .MuiBreadcrumbs-separator': {
                                                    marginRight: '3px',
                                                    marginLeft: '3px'
                                                }
                                            }}
                                        >
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate("/chatbot") }}
                                                // href="/dashboard/chatbot"
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                            >
                                                AI Chatbot
                                            </Link>
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbotId}`) }}
                                                // href="/"
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                            >
                                                {/* {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"} */}
                                                {chatBotDetails?.chatbot_name}
                                            </Link>
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate(`/chatbot/viewchatbot/integration?chatbot_id=${chatbotId}`) }}
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                            >
                                                Integrations
                                            </Link>
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate(`/chatbot/viewchatbot/integration/customAPI?chatbot_id=${chatbotId}`) }}
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                            >
                                                Custom API
                                            </Link>
                                            <Link
                                                underline="hover"
                                                // href="/"
                                                className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                            >
                                                {window.location.search.includes("connector_id") ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name : "Create Custom API"}
                                            </Link>
                                        </Breadcrumbs>
                                    }
                                />
                            </Grid>
                        </Grid>

                        {!smallScreen && <Divider style={{ width: "100%", opacity: "0.8", marginBottom: "15px", marginTop: "5px" }} />}

                        <Grid item xs={12} className={styles.dataConnectorLowerBlock}>
                            <Grid container>
                                <Grid item xl={5} lg={7} md={10} sm={12} xs={12} className={`${styles.cmpCreateFormHeight} ${styles.cmpGlobalComponentBorderItem}`}>
                                    <Grid container direction={"column"} className={styles.createFormField} gap={3} p={1}>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={styles.formFieldTitle}>Name</InputLabel><Required /></Grid>
                                                <Grid item><GlobalTextField placeholder='Enter Name' value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name} onChange={(event) => { handleChangePayload(event.target.value, 'data_connector_function_name', 'data_connector_functions') }} /></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={styles.formFieldTitle}>Description</InputLabel><Required /></Grid>
                                                <Grid item><textarea
                                                    placeholder='Write Description'
                                                    value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_description}
                                                    onChange={(event) => { handleChangePayload(event.target.value, 'data_connector_function_description', 'data_connector_functions'); }}
                                                    dir="auto"
                                                    className={`${styles.text_area_style} ${styles.minHeight300} ${styles.dataConnectorDescription}`}
                                                />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container gap="15px" flexWrap={"nowrap"}>
                                                <Grid item xs={12} md={4}>
                                                    <Grid container direction={"column"}>
                                                        <Grid item mb={1}><InputLabel className={styles.formFieldTitle}>Method</InputLabel><Required /></Grid>
                                                        <Grid item>
                                                            <Autocomplete
                                                                value={functionMethodsList.find((method) => method.function_method_id == manageDataConnectorPayload.chatbot_data_connectors[0].function_method_id) ?? null}
                                                                options={functionMethodsList}
                                                                getOptionLabel={(option) => option.function_method_name}
                                                                renderInput={(params) => <TextField {...params} placeholder='Select Type' />}
                                                                onChange={(event, value) => {
                                                                    if (value) {
                                                                        handleChangePayload(value.function_method_id, 'function_method_id');
                                                                        handleChangePayload(value.function_method_name, 'function_method_name');
                                                                    } else {
                                                                        handleChangePayload("", 'function_method_id',);
                                                                        handleChangePayload("", 'function_method_name');

                                                                    }
                                                                }}
                                                                className={`${globalStyles.autocompleteParaphrasing} ${styles.methodAutoComplete}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Grid container direction={"column"}>
                                                        <Grid item mb={1}><InputLabel className={styles.formFieldTitle}>URL</InputLabel><Required /></Grid>
                                                        <Grid item><GlobalTextField placeholder='Link' value={manageDataConnectorPayload.chatbot_data_connectors[0].chatbot_data_connector_url} onChange={(event) => { handleChangePayload(event.target.value, 'chatbot_data_connector_url'); setFlagShowValidation((prevState) => ({ ...prevState, flagInvalidURL: false })) }} /></Grid>
                                                        {flagShowValidation.flagInvalidURL && <div className={styles.validationText}>Please enter a valid URL</div>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <GlobalForm
                                            fields={createNoteFormData.data.global_form_field}
                                            submitFunction={formSubmitFunction}
                                            checkFormSubmit={renderValue}
                                            handleCloseSnackBar={handleCloseSnackBar}
                                            pageThemeData={createNoteFormData.data.doc_page_theme[0]}
                                            formData={createNoteFormData.data}
                                            submissionID={submissionID}
                                            setFieldValue={setFieldValue}
                                            recaptchaFlag="true"
                                            defaultLoader={false}
                                            formEditable={true}
                                        /> */}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: "100%", opacity: "0.8", marginBlock: "15px" }} />
                            {/* dynamic loader */}
                            <Grid item xs={12} className={`${styles.reqSectionTextStyle} ${globalStyles.fullwidth}`}>
                                <Grid container justifyContent={"space-between"} flexWrap={"nowrap"} gap={"5px"}>
                                    <Grid item>Loader Details</Grid>
                                    {manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_loader.length == 0 &&
                                        <Grid item>
                                            <Grid container alignItems={"center"} className={globalStyles.clickable} gap={1} onClick={() => { setFlagDynamicLoader(true) }}>
                                                <Grid item sx={{ display: "flex" }}><img src={addIcon} alt="add" /></Grid>

                                                <Grid item>Add Loader</Grid>
                                            </Grid>
                                        </Grid>}
                                </Grid>
                                <Grid item mt={2}>
                                    <GlobalTable
                                        headerList={listOfHeaders[3]}
                                        rowData={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_loader}
                                        tableContainerMinHeight={"auto"}
                                        onCellClick={(e: any, rowDetail: any) => { handleOpenEditSection(e, rowDetail) }}
                                        onRowClick={(e: any) => { console.log("hello", e) }}
                                        rowAllowEdit={false}
                                        flagPagination
                                        rowClicked={rowClicked}
                                        menuItemsFromParent={menuItems}
                                    />
                                </Grid>
                            </Grid>
                            <Divider style={{ width: "100%", opacity: "0.8", marginBlock: "20px" }} />

                            <Grid item xs={12} className={`${styles.reqSectionTextStyle} ${globalStyles.fullwidth}`}>
                                <Grid container justifyContent={"space-between"} flexWrap={"nowrap"} gap={"5px"}>
                                    <Grid item>
                                        Request Parameters
                                    </Grid>
                                    {/* <Grid item>
                                        <Grid container alignItems={"center"} gap={1} className={globalStyles.clickable} flexWrap={"nowrap"} onClick={() => { setFlagRequestParameter(true) }}>
                                            <Grid item sx={{ display: "flex" }}><img src={addIcon} alt="add" /></Grid>
                                            <Grid item>Request Parameters</Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>

                            {flagDynamicLoader &&
                                <GlobalPopUp
                                    open={flagDynamicLoader}
                                    onClose={() => { setFlagDynamicLoader(false) }}
                                    flagBackdrop
                                    flagShowCloseIcon={true}
                                    handleClose={() => { handleCancel('reqParams') }}
                                    maxWidth="lg"
                                    titlePadding='0px'
                                >
                                    <DynamicLoaderForm
                                        flagKeyExistError={flagKeyExistError}
                                        setFlagKeyExistError={setFlagKeyExistError}
                                        createReqParamsPayload={dynamicLoaderCreatePayload}
                                        onChangePayload={onChangeDynamicLoaderDetails}
                                        handleSaveReqParams={() => handleSaveDynamicLoader()}
                                        handleCancelSaveParams={(objName: string) => handleCancel(objName)}
                                    />
                                </GlobalPopUp>
                            }

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.reqTableHeaders}`} mt={2}>
                                <textarea
                                    placeholder='Enter Request parameters here'
                                    value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_parameters.function_parameter_json_data : ""}
                                    onChange={(event) => { handleChangePayload(event.target.value, 'data_connector_function_parameters', 'data_connector_functions', 'function_parameter_json_data'); setFlagShowValidation((prevState) => ({ ...prevState, flagReqParamInvalidJSON: false })) }}
                                    dir="auto"
                                    className={`${styles.text_area_style} ${styles.minHeight300} ${styles.dataConnectorDescription}`}
                                />
                                {flagShowValidation.flagReqParamInvalidJSON && <div className={styles.validationText}>Invalid JSON</div>}

                            </Grid>
                            <>
                                {/* request header */}
                                <Divider style={{ width: "100%", opacity: "0.8", marginBlock: "20px" }} />

                                <Grid item xs={12} className={`${styles.reqSectionTextStyle} ${globalStyles.fullwidth}`}>
                                    <Grid container justifyContent={"space-between"} flexWrap={"nowrap"} gap={"5px"}>
                                        <Grid item>
                                            Request Headers
                                        </Grid>
                                        <Grid item>
                                            <Grid container alignItems={"center"} gap={1} className={globalStyles.clickable} flexWrap={"nowrap"} onClick={() => { setFlagRequestHeader(true) }}>
                                                <Grid item sx={{ display: "flex" }}><img src={addIcon} alt="add" /></Grid>
                                                <Grid item>Request Headers</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {flagRequestHeader &&
                                    <GlobalPopUp
                                        open={flagRequestHeader}
                                        onClose={() => { setFlagRequestHeader(false) }}
                                        flagBackdrop
                                        flagShowCloseIcon={true}
                                        handleClose={() => { handleCancel('reqHeader') }}
                                        maxWidth="lg"
                                    >
                                        <RequestHeader flagKeyExistError={flagKeyExistError} setFlagKeyExistError={setFlagKeyExistError} createReqHeaderPayload={requestHeaderCreatePayload} onChangePayload={onChangeReqHeaderPayload} handleSavReqHeader={handleSave} cancelSaveReqHeader={() => { handleCancel('reqHeader') }} />
                                    </GlobalPopUp>
                                }

                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.reqTableHeaders}`} mt={2}>
                                    <GlobalTable
                                        headerList={listOfHeaders[1]}
                                        rowData={requestHeadersRowData}
                                        // tableHeight={"26.5vh"}
                                        // tableContainerHeight={"26.5vh"}
                                        tableContainerMinHeight={"auto"}
                                        onRowClick={(e: any) => { console.log("Hello", e) }}
                                        // deleteRowData={ }
                                        // renameDocumentName={renameDocumentName}
                                        rowAllowEdit={true}
                                        flagPagination
                                        rowClicked={rowClicked}
                                        onCellClick={(e: any, rowDetail: any) => { handleOpenEditSection(e, rowDetail) }}
                                        menuItemsFromParent={menuItems}

                                    />
                                    {/* ) : (<></>)
                            } */}
                                </Grid>
                            </>
                            <>
                                {/* res header */}
                                <Divider style={{ width: "100%", opacity: "0.8", marginBlock: "20px" }} />

                                <Grid item xs={12} className={`${styles.reqSectionTextStyle} ${globalStyles.fullwidth}`}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            Response
                                        </Grid>
                                        {/* <Grid item>
                                            <Grid container alignItems={"center"} gap={1} className={globalStyles.clickable} flexWrap={"nowrap"} onClick={() => { setFlagResponse(true) }}>
                                                <Grid item sx={{ display: "flex" }}><img src={addIcon} alt="add" /></Grid>
                                                <Grid item>Response</Grid>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} className={`${styles.selectOptionTextStyle} ${globalStyles.fullwidth}`}>
                                    <Grid container flexWrap={"nowrap"} gap={"15px"} alignItems={"center"}>
                                        <Grid item>
                                            Select Any One <Required />
                                        </Grid>
                                        <Grid item>
                                            <Grid container alignItems={"center"} gap={2} className={globalStyles.clickable} flexWrap={"nowrap"}>
                                                <Grid item onClick={() => { setChangeDataStructureResp(false) }} className={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_Response_arr_of_obj_flag ? styles.nonSelectedOption : styles.selectedOption}>Object</Grid>
                                                <Grid item onClick={() => { setChangeDataStructureResp(true) }} className={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_Response_arr_of_obj_flag ? styles.selectedOption : styles.nonSelectedOption}>Array of Objects</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}

                                {flagResponse &&
                                    <GlobalPopUp
                                        open={flagResponse}
                                        onClose={() => { setFlagResponse(false) }}
                                        flagBackdrop
                                        flagShowCloseIcon={true}
                                        handleClose={() => { handleCancel('') }}
                                        maxWidth="lg"
                                    >
                                        <AddResponse flagKeyExistError={flagKeyExistError} setFlagKeyExistError={setFlagKeyExistError} createResponsePayload={responseCreatePayload} onChangePayload={onChangeReqHeaderPayload} handleSaveResponse={(objName: string) => { handleSave(objName) }} handleCancelSaveResponse={(objName: string) => { handleCancel(objName) }} />
                                    </GlobalPopUp>
                                }
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.reqTableHeaders}`} mt={2}>
                                    <textarea
                                        placeholder='Enter Response here'
                                        value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response ? manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_response.function_response_json_data : ""}
                                        onChange={(event) => { handleChangePayload(event.target.value, 'data_connector_function_response', 'data_connector_functions', 'function_response_json_data'); setFlagShowValidation((prevState) => ({ ...prevState, flagResParamInvalidJSON: false })) }}
                                        dir="auto"
                                        className={`${styles.text_area_style} ${styles.minHeight300} ${styles.dataConnectorDescription}`}
                                    />
                                    {flagShowValidation.flagResParamInvalidJSON && <div className={styles.validationText}>Invalid JSON</div>}

                                    {/* <GlobalTable
                                        headerList={listOfHeaders[2]}
                                        rowData={responseRowData}
                                        tableHeight={"26vh"}
                                        tableContainerHeight={"26vh"}
                                        tableContainerMinHeight={"auto"}
                                        onRowClick={(e: any) => { console.log("Hello", e) }}
                                        // deleteRowData={deleteDocumentDataPopupOpen}
                                        // renameDocumentName={renameDocumentName}
                                        rowAllowEdit={true}F
                                        flagPagination
                                        rowClicked={rowClicked}
                                        onCellClick={(e: any, rowDetail: any) => { handleOpenEditSection(e, rowDetail) }}
                                        menuItemsFromParent={menuItems}

                                    /> */}
                                </Grid>
                            </>

                        </Grid>

                    </Grid>

                    <Grid container sx={{ position: "relative", bottom: "0px", zIndex: "10", marginLeft: "15px", width: "100%" }}>
                        <Grid item xs={12} sx={{ position: "absolute", top: { xs: "-55px", xl: "-58px" }, backgroundColor: "white", paddingBlock: "10px", width: "100%", borderRadius: "0px 0px 10px 0px" }}>
                            <Grid container sx={{ justifyContent: "start" }} className={styles.cbSaveCancelContainer} gap={2}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        fullWidth
                                        className={styles.cbCancelButton}
                                        onClick={cancelBtnOnClick}
                                        disabled={cancelBtnOnClickDisableCond()}
                                        // disabled={chatBotDetails?.chatbot_flag_edit == true ? false : true}
                                        sx={{ opacity: cancelBtnOnClickDisableCond() ? "0.5" : "" }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        fullWidth
                                        className={styles.cbSaveButton}
                                        disabled={getHandleSaveDataConnectorDisableCond()}
                                        onClick={handleSaveDataConnector}
                                        // onClick={() => { saveThemeSettings() }}
                                        // disabled={getSaveButtonDisableFlag()}
                                        sx={{ opacity: getHandleSaveDataConnectorDisableCond() ? "0.5" : "" }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Createdataconnector
