import { Divider, Grid, useMediaQuery } from '@mui/material'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss'
import SelectFieldsPopup from './selectFieldsPopup'
import ConfigTopSection from './configTopSection'
import EmailConfigForm from './emailConfigForm'
import ConfigIconSection from './configIconSection'
import { useContext, useEffect, useState } from 'react'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import agent from '../../../services/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { initialContentType, initialEmailConfigPayload } from './leadGenerationConstants'

interface masterValueInterface {
    id: number,
    value: string
}

interface contentType {
    field_id: number,
    field_name: string
}

const EmailConfig = () => {
    const { emailConfigPayload, setEmailConfigPayload, allowedFieldsData, tempLeadGenerationPayload, leadIntegrationDataType, setAddIntegrationPopup, setAllowedFieldsData,
        leadGenerationList, setEditIntegrationData, setStepContainer, GetLeadGenerationList } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload;
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error } = useContext(UserContext) as UserContextPayload;
    const [masterValues, setMasterValues] = useState<masterValueInterface[] | []>([]);
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');
    const lead_id = searchParams.get('lead_id');
    const navigate = useNavigate();
    const [contentType, setContentType] = useState<contentType>(initialContentType);
    const [enumValue, setEnumValue] = useState("");
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)');
    const flagMobileScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)');
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 750px)');

    const innerHeight = flagMobileScreen ? (window.innerHeight - 230) : flagSmallScreen ? (window.innerHeight - 240) : (window.innerHeight - 220);


    const handleChangeEmailConfigPayload = (field: string, value: string) => {
        setEmailConfigPayload((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }

    useEffect(() => {
        const payload = allowedFieldsData ? allowedFieldsData.map((data) => ({
            id: data.chatbot_lead_field_id,
            value: data.chatbot_lead_field_name
        })) : [];
        //@ts-ignore
        setMasterValues(payload);
    }, [allowedFieldsData]);

    const contentTypeFields = [
        {
            field_id: 1,
            field_name: "Raw Content"
        },
        {
            field_id: 2,
            field_name: "HTML"
        }
    ]

    const handleChangeContentType = (value: contentType | null) => {
        if (value) {
            setContentType(value)
        }
        else {
            setContentType(initialContentType);
        }
    }

    const convertSimplifiedToOriginalFormat = (simplifiedString: string) => {
        const regex = /\{\{(.*?)\}\}/g;

        let uniquePlaceholders: string[] = [];
        const originalString = simplifiedString.replace(regex, (match, value) => {
            if (!uniquePlaceholders.includes(value)) {
                uniquePlaceholders.push(value);
            }

            // const id = uniquePlaceholders.indexOf(value);
            const id = allowedFieldsData?.find((data) => data.chatbot_lead_field_name === value)?.chatbot_lead_field_id?.toString();
            return `[[{"id":"${id}","value":"${value}","prefix":"@"}]]`;
        });

        return originalString;
    };

    const [customTemplatePromptActionData, setCustomTemplatePromptActionData] = useState<String>(convertSimplifiedToOriginalFormat(emailConfigPayload.email_content));

    const unescapeHtml = (text: String) => {
        return text
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
    };

    const handleSaveEmailConfig = async (flagUpdate?: boolean) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let tempFormattedPromptEmailData = customTemplatePromptActionData


        const regex = /{"id":"\d+","value":"(.*?)","prefix":"@"}|{"id":\d+,"value":"(.*?)","prefix":"@"}/g;
        let formattedPromptActionData = tempFormattedPromptEmailData.replace(regex, (_, value1, value2) => {
            const value = value1 || value2;
            return `${value}`;
        }).replace(/\[\[/g, '{{').replace(/\]\]/g, '}}');

        const payload = { ...emailConfigPayload }
        payload.sg_email_config_id =
            flagUpdate &&
            leadGenerationList?.chatbot_lead_data?.[0]?.chatbot_lead_integration_data?.find((data) =>
                data.chatbot_lead_integration_type_name?.toLowerCase() === "email"
            )?.chatbot_lead_integration_additional_data?.sg_email_config_id
            || payload.sg_email_config_id;
        payload.email_content = formattedPromptActionData;

        const tempPayload = {
            chatbot_lead_integration_id: flagUpdate && leadGenerationList?.chatbot_lead_data?.[0]?.chatbot_lead_integration_data?.find((data) =>
                data.chatbot_lead_integration_type_name?.toLowerCase() === "email"
            )?.chatbot_lead_integration_id || null,

            chatbot_lead_id: tempLeadGenerationPayload.chatbot_lead_id || 0,

            chatbot_lead_integration_type_id: leadIntegrationDataType?.find((data) => data.lead_integration_type_name.toLowerCase() === "email")?.lead_integration_type_id || 0,

            lead_integration_allowed_field_data: allowedFieldsData?.map((field) => ({
                lead_integration_allowed_field_id: flagUpdate && leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data.filter((data) =>
                    data.chatbot_lead_integration_type_name?.toLowerCase() === "email")[0].allowed_field_data.find((data) => data.chatbot_lead_field_name === field.chatbot_lead_field_name)?.lead_integration_allowed_field_id || null,
                chatbot_lead_field_id: Number(field.chatbot_lead_field_id)
            })) || [],
            chatbot_lead_integration_additional_data: payload,
        }
        const response = await agent.manageLeadGeneration.post(tempPayload);
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: flagUpdate ? "Lead Updated Successfully" : "Lead Integrated Successfully!" });
            setEditIntegrationData(false);
            setAddIntegrationPopup(false);
            setStepContainer(1);
            // GetLeadGenerationList(chatbot_id || "", lead_id || "");
            if (!flagUpdate) {
                navigate(`/chatbot/viewchatbot/leadgeneration?chatbot_id=${chatbot_id}`)
            }
            if (flagUpdate) {
                GetLeadGenerationList(chatbot_id || "", lead_id || "")
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        else {
            setError({
                ...error,
                flagShowError: true,
                message: response.error.message
            })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        return response;
    }

    const isEmailConfigDisabled = () => {
        if (JSON.stringify(emailConfigPayload) === JSON.stringify(initialEmailConfigPayload) || emailConfigPayload.email_name?.trim() === "" || emailConfigPayload.email_to?.trim() === "" || emailConfigPayload.email_to === null || emailConfigPayload.email_subject?.trim() === "" || customTemplatePromptActionData?.trim() === "" || allowedFieldsData?.length === 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleCancelEmailConfig = () => {
        setEmailConfigPayload(initialEmailConfigPayload);
        setContentType(initialContentType);
        setCustomTemplatePromptActionData(convertSimplifiedToOriginalFormat(emailConfigPayload.email_content))
        setAllowedFieldsData(leadGenerationList?.chatbot_lead_data[0]?.chatbot_lead_field_data || null)
    }

    return (
        <Grid item sx={{ padding: window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" ? "0px 25px 0px 27px" : "" }}>
            <Grid container direction={"column"}>
                <Grid item padding={"4px"}>

                    <Grid container direction={"column"} gap={"30px"} className={styles.newLeadDataStyle}>
                        {window.location.pathname === "/chatbot/viewchatbot/leadgeneration/email" &&
                            <Grid item>
                                <ConfigTopSection handleSave={handleSaveEmailConfig} isDisable={isEmailConfigDisabled} handleCancel={handleCancelEmailConfig} />
                            </Grid>
                        }

                        <Grid item className={styles.selectFieldCardStyle} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto" }}>
                            <Grid container gap={2}>
                                <Grid item sx={{ width: "100%" }}>
                                    <ConfigIconSection 
                                        integrationName='Email' 
                                        isDisable={isEmailConfigDisabled} 
                                        handleSave={handleSaveEmailConfig}
                                        convertSimplifiedToOriginalFormat={convertSimplifiedToOriginalFormat}
                                        setCustomTemplatePromptActionData={setCustomTemplatePromptActionData}
                                    />
                                </Grid>
                                <Grid item sx={{ width: "100%" }}>
                                    <SelectFieldsPopup integrationName='Email' customTemplatePromptActionData={customTemplatePromptActionData} setCustomTemplatePromptActionData={setCustomTemplatePromptActionData} />
                                </Grid>
                                <Grid item sx={{ width: "100%" }}>
                                    <Divider className={styles.popupDividerStyle} />
                                </Grid>
                                <Grid item sx={{ width: "100%" }} mt={flagSmallScreen ? "4px" : 2}>
                                    <EmailConfigForm
                                        customTemplatePromptActionData={customTemplatePromptActionData}
                                        setCustomTemplatePromptActionData={setCustomTemplatePromptActionData}
                                        handleChangeEmailConfigPayload={handleChangeEmailConfigPayload}
                                        masterValues={masterValues}
                                        setMasterValues={setMasterValues}
                                        contentType={contentType}
                                        contentTypeFields={contentTypeFields}
                                        handleChangeContentType={handleChangeContentType}
                                        enumValue={enumValue}
                                        setEnumValue={setEnumValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>

    )
}

export default EmailConfig
