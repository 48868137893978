import React, { useContext } from 'react'
import CircularProgress from '@mui/joy/CircularProgress';
import { Grid, Box, Typography, ListItem, ListItemButton, ListItemText, TextField, Chip } from '@mui/material';
import { motion } from "framer-motion";
import axios from 'axios';
import { questionModel } from '../../models/axiosModel';
import { answerModel } from '../../models/axiosModel';
import styles from "../../assets/styles/componentStyles/welcome.module.scss";
import WriteWizLogo from "../../assets/images/global/Chronox Logo.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext, UserContextPayload } from '../../context/userContext';
import WzStarIcon from '../../assets/writewiz-star-white.png';
import shine from "../../assets/images/onboarding/shine.png";
import PhoneInput from 'react-phone-input-2';
import phoneStyles from "../../assets/styles/componentStyles/accountDetails.module.scss";
import selectedIcon from "../../assets/checkedCircularIcon.svg";
import GlobalButton from '../utility/globalButton/button';
import globalStyles from "../../assets/styles/global.module.scss";
import handWave from "../../assets/onboardingAssets/handWave.png";
import { ThemeProvider, extendTheme } from '@mui/joy/styles';
import LazyLoadingImage from '../utility/lazyLoadingImage';


const Welcome = () => {
    const theme = extendTheme({
        components: {
            JoyCircularProgress: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.size === 'md' && {
                            '--_root-size': '47px',
                            '--_inner-size': '47px',
                            '--_outlined-inset': '0.1rem',
                            'width': '47px',
                            'height': '47px'
                        }),
                    }),
                },
            },
        },
    });
    const { countryCode, setCountryCode } = useContext(UserContext) as UserContextPayload
    const [questions, setQuestions] = React.useState<questionModel[]>([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(0);
    const [subCurrentQuestion, setSubCurrentQuestion] = React.useState<number>(0)
    const [selectedAnswer, setSelectedAnswer] = React.useState<any>([]);
    const [answers, setAnswers] = React.useState<answerModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [alreadyAnswered, setAlreadyAnswered] = React.useState<answerModel[]>([]);
    const [flagShowIntro, setFlagShowIntro] = React.useState(true);
    const { userDetail,
        setFlagOnboardingSkip, setFlagOnboardingEndPopup } = useContext(UserContext) as UserContextPayload;

    const fetchUserAdditionalInfo = async () => {
        try {
            // REACT_APP_USER_ADDITIONAL_INFO_URL
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
            // const userAdditionalInfo = data.data.data;
            let userAdditionalInfo = response.data.data.filter((module: any) => module.user_additional_information_type_name == "Chatbot")[0].user_additional_information

            let index;
            const ans: answerModel[] = [];
            // let selectedAnswer = []
            for (index = 0; index <= userAdditionalInfo.length - 1; index++) {
                let value = (userAdditionalInfo[index].user_additional_information_data_value)
                let additionalQuesVal = userAdditionalInfo[index].user_additional_information_data_other_question_value
                if ((Array.isArray(value) && value.length == 0) || (!Array.isArray(value) && value == null && (additionalQuesVal == null || additionalQuesVal == ""))) {
                    if (index == 4) {
                        setSubCurrentQuestion(4)
                        setCurrentQuestion(1);
                    } else if (index == 3) {
                        setSubCurrentQuestion(3)
                        setCurrentQuestion(2)
                    } else {
                        setCurrentQuestion(0)
                    }
                    const answer = {
                        user_additional_information_id: userAdditionalInfo[index].user_additional_information_id,
                        user_additional_information_data_value: userAdditionalInfo[index].user_additional_information_data_value,
                        user_additional_information_other_question_value: userAdditionalInfo[index].user_additional_information_other_question_value
                    }
                    // selectedAnswer.push(answer)
                    // break;
                }
                else {
                    const answer = {
                        user_additional_information_id: userAdditionalInfo[index].user_additional_information_id,
                        user_additional_information_data_value: userAdditionalInfo[index].user_additional_information_data_value,
                        user_additional_information_data_other_question_value: userAdditionalInfo[index].user_additional_information_data_other_question_value
                    }

                    ans.push(answer);
                }
            }
            setAlreadyAnswered(ans);
            // setSelectedAnswer(selectedAnswer)
            setAnswers(ans);
        }
        catch (error) {
            console.log(error);
        }
    }
    const fetchQuestions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-generic-data`);
            let questionsForChatbot = response.data.data.filter((module: any) => module.user_additional_information_type_name == "Chatbot")[0].user_additional_information
            setQuestions(questionsForChatbot);
            let selectedAnswers: any = []
            for (let i = 0; i <= questionsForChatbot.length - 1; i++) {
                selectedAnswers[i] = {
                    user_additional_information_id: questionsForChatbot[i].user_additional_information_id,
                    user_additional_information_data_value: "",
                }
                if (questionsForChatbot[i].user_additional_information_other_question_flag) {
                    selectedAnswers[i].user_additional_information_data_other_question_value = ""
                }
            }
            setSelectedAnswer(selectedAnswers)
            setIsLoading(false);
        }
        catch (error) {
            console.log("error");
        }
    }

    React.useEffect(() => {
        (async () => {
            await fetchQuestions();
            fetchUserAdditionalInfo();
            setTimeout(() => {
                setFlagShowIntro(false)
            }, 4000)
        })()
    }, []);
    const updateSelectedAnswers = (answers: any) => {
        let selected = [...selectedAnswer]
        if (answers.length > 0) {
            selected.forEach((answer: any) => {
                const temp = answers.find((ans: any) => ans.user_additional_information_id == answer.user_additional_information_id)
                if (temp) {
                    if (temp && temp.user_additional_information_id == 7 || temp.user_additional_information_id == 10) {
                        answer.user_additional_information_data_value = typeof (temp.user_additional_information_data_value) == 'string' ? temp.user_additional_information_data_value.split(',') : temp.user_additional_information_data_value;
                        answer.user_additional_information_data_other_question_value = temp.user_additional_information_data_other_question_value

                    } else {
                        answer.user_additional_information_data_value = temp.user_additional_information_data_value;
                        answer.user_additional_information_data_other_question_value = temp.user_additional_information_data_other_question_value
                    }
                }
            })
        }
        setSelectedAnswer(selected)
    }
    React.useEffect(() => {
        if (answers.length > 0) {
            storeUserAdditionalData(false);
            setAlreadyAnswered(answers);
            updateSelectedAnswers(answers)
        }

        // setSelectedAnswer(answers)
    }, [answers]);

    const storeUserAdditionalData = async (send_email_flag: boolean, data?: answerModel[],) => {
        if (data) {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/add-user-additional-information-data`, { user_additional_information_type_id: 2, user_additional_information_data: data, send_email_flag: send_email_flag });
            if (response.status) {
                return true
            } else {
                return false
            }
        }
        else {

            try {
                if (answers.length > 0) {
                    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/add-user-additional-information-data`, { user_additional_information_type_id: 2, user_additional_information_data: selectedAnswer });
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const handleListItemClick = async (value: string, question: any) => {
        const answer = {
            user_additional_information_id: question.user_additional_information_id,
            user_additional_information_data_value: value,
            user_additional_information_data_other_question_value: ""
        }
        let temp = [...selectedAnswer]
        let tempIndex = temp.findIndex((ans) => ans.user_additional_information_id == answer.user_additional_information_id)
        temp[tempIndex].user_additional_information_data_value = value
        if (tempIndex) {
            answer.user_additional_information_data_other_question_value = selectedAnswer[tempIndex].user_additional_information_data_other_question_value
        }
        setSelectedAnswer(temp)
        if (answers.length - 1 >= currentQuestion) {

            const ans = selectedAnswer;
            let currentQueIndex = ans.findIndex((answer: any) => answer.user_additional_information_id == question.user_additional_information_id)
            if (currentQueIndex == -1) {
                setAnswers([...answers, answer]);
            } else {
                ans[currentQueIndex] = selectedAnswer;
                setAnswers([...ans]);
            }
        }
        else {
            setAnswers([...answers, answer]);
        }

        if (subCurrentQuestion !== 4 && subCurrentQuestion !== 3 && currentQuestion !== 1 && currentQuestion !== 0) {
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1);
            })
        }
    };
    const handleSkip = async () => {

        const skip = {
            user_additional_information_id: questions[currentQuestion].user_additional_information_id,
            user_additional_information_data_value: selectedAnswer[currentQuestion].user_additional_information_data_value,
            user_additional_information_data_other_question_value: selectedAnswer[currentQuestion].user_additional_information_data_other_question_value

        }
        const subCurrentQueVal = {
            user_additional_information_id: questions[subCurrentQuestion].user_additional_information_id,
            user_additional_information_data_value: selectedAnswer[subCurrentQuestion].user_additional_information_data_value,
            user_additional_information_data_other_question_value: selectedAnswer[subCurrentQuestion].user_additional_information_data_other_question_value
        }
        const data = [skip, subCurrentQueVal];
        const response = await storeUserAdditionalData(true, data);


        if (response) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
                if (response.status) {
                    const userAdditionalInfo = response.data.data.filter((module: any) => module.user_additional_information_type_name == "Chatbot")[0].user_additional_information
                    if (!userAdditionalInfo.length || userAdditionalInfo[userAdditionalInfo.length - 1].user_additional_information_data_value === "") {
                        setFlagOnboardingSkip(false);
                    }
                    else {
                        setFlagOnboardingSkip(true);
                        setTimeout(() => {
                            setFlagOnboardingEndPopup(true)
                        }, 500)
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const goToPreviousQuestion = () => {
        if (currentQuestion == 2) {
            setSubCurrentQuestion(4)
        } else {
            setSubCurrentQuestion(0)
        }
        setCurrentQuestion(currentQuestion - 1);

    }

    const goToNextQuestion = () => {
        if (currentQuestion == 0) {
            setSubCurrentQuestion(4)
            let data = [selectedAnswer.find((answer: any) => answer.user_additional_information_id == 7)]
            storeUserAdditionalData(false, data)
        } else if (currentQuestion == 1) {
            let data = [selectedAnswer.find((answer: any) => answer.user_additional_information_id == 8)]
            storeUserAdditionalData(false, data)
            setSubCurrentQuestion(3)
        } else {
            setSubCurrentQuestion(0)
        }
        setCurrentQuestion(currentQuestion + 1);
    }

    const getNextBtnDisableCond = () => {
        if (currentQuestion == 0) {
            if (selectedAnswer[currentQuestion].user_additional_information_data_value && selectedAnswer[currentQuestion].user_additional_information_data_value.length !== 0 || (selectedAnswer[currentQuestion].user_additional_information_data_other_question_value && selectedAnswer[currentQuestion].user_additional_information_data_other_question_value.trim() !== "")) {
                return false
            } else {
                return true
            }
        } else if (currentQuestion == 1) {
            if ((selectedAnswer[currentQuestion].user_additional_information_data_value !== "" || (selectedAnswer[currentQuestion].user_additional_information_data_other_question_value && selectedAnswer[currentQuestion].user_additional_information_data_other_question_value.trim() !== "")) && selectedAnswer[4].user_additional_information_data_value !== "") {
                return false
            } else {
                return true
            }
        } else {
            if (selectedAnswer[currentQuestion].user_additional_information_data_value !== "" && selectedAnswer[currentQuestion].user_additional_information_data_value.length > 6) {
                if ((Array.isArray(selectedAnswer[subCurrentQuestion].user_additional_information_data_value) && selectedAnswer[subCurrentQuestion].user_additional_information_data_value.length !== 0 && selectedAnswer[subCurrentQuestion].user_additional_information_data_value[0] !== "") || (selectedAnswer[subCurrentQuestion].user_additional_information_data_other_question_value && selectedAnswer[subCurrentQuestion].user_additional_information_data_other_question_value !== "")) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    }

    const handlePhoneInputChange = (event: any, data: any, question: any) => {
        let temp = [...selectedAnswer]
        let tempIndex = temp.findIndex((ans) => ans.user_additional_information_id == question.user_additional_information_id)
        temp[tempIndex].user_additional_information_data_value = event
        setSelectedAnswer(temp)
    }
    const handleChangeOtherVal = (value: string, question: any) => {
        let temp = [...selectedAnswer]
        let tempIndex = temp.findIndex((ans) => ans.user_additional_information_id == question.user_additional_information_id)
        temp[tempIndex].user_additional_information_data_other_question_value = value
        // setAnswers(temp)
        setSelectedAnswer(temp)
    }
    const getClassName = (selectedAnswer: any, question: any, option: any) => {
        let temp = selectedAnswer.find((ans: any) => ans.user_additional_information_id == question.user_additional_information_id);
        if (temp && temp.user_additional_information_data_value && temp.user_additional_information_data_value.includes(option)) {
            return true
        } else {
            return false
        }
    }
    const getInputType = (index: number, question: any, currentQuestion: any) => {
        if (currentQuestion == index) {
            switch (index) {
                case 0:
                    return <>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Grid container gap="15px" mt={2}>
                                        {question.user_additional_information_option.map((option: any) =>
                                        (
                                            <Chip
                                                className={getClassName(selectedAnswer, question, option) ? styles.chipStyleSelected : styles.chipStyle}
                                                label={<div style={{ display: "flex", gap: "7px" }}>{option} {getClassName(selectedAnswer, question, option) ? <img src={selectedIcon} alt="" /> : ""}</div>}
                                                onClick={(e) => {
                                                    let val = []
                                                    let temp = [...selectedAnswer]
                                                    let tempIndex = temp.findIndex((ans) => ans.user_additional_information_id == question.user_additional_information_id)
                                                    if (getClassName(selectedAnswer, question, option)) {
                                                        temp[tempIndex].user_additional_information_data_value = temp[tempIndex].user_additional_information_data_value.filter((ans: any) => ans !== option)
                                                        val = temp[tempIndex].user_additional_information_data_value.filter((ans: any) => ans !== option)
                                                        setSelectedAnswer(temp)
                                                    } else {
                                                        if (selectedAnswer.find((answer: any) => answer.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value == "" || !Array.isArray(selectedAnswer.find((answer: any) => answer.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value)) {
                                                            let value = [option]
                                                            temp[tempIndex].user_additional_information_data_value = value
                                                            val = value
                                                            setSelectedAnswer(temp)
                                                        } else {
                                                            temp[tempIndex].user_additional_information_data_value.push(option)
                                                            setSelectedAnswer(temp)
                                                            val = [...temp[tempIndex].user_additional_information_data_value]
                                                        }
                                                    }
                                                    handleListItemClick(val, question)
                                                }
                                                } />
                                        )
                                        )}

                                    </Grid>
                                </Grid>
                                {question.user_additional_information_other_question_flag &&
                                    <Grid item>
                                        <Grid container direction={"column"}>
                                            <Grid item className={styles.subQueStyle}>{question.user_additional_information_other_question_value}</Grid>
                                            <Grid item><TextField id='textfield1' className={globalStyles.globalTextfieldStyle} onChange={(event) => { event.target.value.length < 95 && handleChangeOtherVal(event.target.value, question) }} value={selectedAnswer.find((ans: any) => ans.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_other_question_value} placeholder='Describe your role' /></Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </Grid>

                    </>

                case 1:
                    return <>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Grid container gap="15px" mt={2}>
                                        {question.user_additional_information_option.map((option: any) => (
                                            <Chip
                                                className={getClassName(selectedAnswer, question, option) ? styles.chipStyleSelected : styles.chipStyle}
                                                label={option} onClick={(e) => {
                                                    if (getClassName(selectedAnswer, question, option)) {
                                                        handleListItemClick("", question)
                                                    } else {
                                                        handleListItemClick(option, question)
                                                    }
                                                }} />
                                        ))}
                                    </Grid>
                                </Grid>
                                {question.user_additional_information_other_question_flag &&
                                    <Grid item>
                                        <Grid container direction={"column"}>
                                            <Grid item className={styles.subQueStyle}>{question.user_additional_information_other_question_value}</Grid>
                                            <Grid item><TextField id='textfield1' className={globalStyles.globalTextfieldStyle} onChange={(event) => { event.target.value.length < 95 && handleChangeOtherVal(event.target.value, question) }} value={selectedAnswer.find((ans: any) => ans.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_other_question_value} placeholder='Describe your business' /></Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </Grid>

                    </>
                case 2:
                    return <>
                        <Grid item className={phoneStyles.accDetailsGlobalFieldsItem} mt={2}>
                            <Grid container>
                                <Grid item className={`${phoneStyles.accDetailsFullWithStyle} ${phoneStyles.accDetailGlobalFieldsItem} ${phoneStyles.phoneInputWrapper}`}>
                                    <PhoneInput country={countryCode ?? "in"}
                                        value={selectedAnswer.find((answer: any) => answer.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value}
                                        onChange={(event, data) => { handlePhoneInputChange(event, data, question) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                case 3:
                    return <>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Grid container gap="15px" mt={2}>
                                        {question.user_additional_information_option.map((option: any) =>
                                        (
                                            <Chip
                                                className={getClassName(selectedAnswer, question, option) ? styles.chipStyleSelected : styles.chipStyle}
                                                label={<div style={{ display: "flex", gap: "7px" }}>{option} {getClassName(selectedAnswer, question, option) ? <img src={selectedIcon} alt="" /> : ""}</div>}
                                                onClick={(e) => {
                                                    let val = []
                                                    let temp = [...selectedAnswer]
                                                    let tempIndex = temp.findIndex((ans) => ans.user_additional_information_id == question.user_additional_information_id)
                                                    if (getClassName(selectedAnswer, question, option)) {
                                                        temp[tempIndex].user_additional_information_data_value = temp[tempIndex].user_additional_information_data_value.filter((ans: any) => ans !== option)
                                                        val = temp[tempIndex].user_additional_information_data_value.filter((ans: any) => ans !== option)
                                                        setSelectedAnswer(temp)
                                                    } else {
                                                        if (selectedAnswer.find((answer: any) => answer.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value == "" || !Array.isArray(selectedAnswer.find((answer: any) => answer.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value)) {
                                                            let value = [option]
                                                            temp[tempIndex].user_additional_information_data_value = value
                                                            val = value
                                                            setSelectedAnswer(temp)
                                                        } else {
                                                            temp[tempIndex].user_additional_information_data_value.push(option)
                                                            setSelectedAnswer(temp)
                                                            val = [...temp[tempIndex].user_additional_information_data_value]
                                                        }
                                                    }
                                                    handleListItemClick(val, question)
                                                }
                                                } />
                                        )
                                        )}

                                    </Grid>
                                </Grid>
                                {question.user_additional_information_other_question_flag &&
                                    <Grid item>
                                        <Grid container direction={"column"}>
                                            <Grid item className={styles.subQueStyle}>{question.user_additional_information_other_question_value}</Grid>
                                            <Grid item><TextField id='textfield3' className={globalStyles.globalTextfieldStyle} onChange={(event) => { event.target.value.length < 95 && handleChangeOtherVal(event.target.value, question) }} value={selectedAnswer.find((ans: any) => ans.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_other_question_value} placeholder='Write your own area of interest' /></Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </Grid>
                    </>
                case 4:
                    return <>
                        <Grid container mt={2} gap={"15px"}>
                            {question.user_additional_information_option.map((option: any, index: number) => (
                                <>
                                    <ListItem
                                        className={styles.memberCountWrapper} disablePadding key={index}>
                                        <ListItemButton
                                            sx={{ borderRadius: "5px" }}
                                            // sx={{ borderRadius: (index == 0 || index == question.user_additional_information_option.length - 1) ? index == 0 ? "10px 0px 0px 10px" : "0px 10px 10px 0px" : "" }}
                                            className={(selectedAnswer.find((ans: any) => ans.user_additional_information_id == question.user_additional_information_id).user_additional_information_data_value == option) ? styles.selectedMemberCount : styles.memberCount}
                                            onClick={() => {
                                                let temp = [...selectedAnswer]
                                                temp.splice(currentQuestion, currentQuestion, option)
                                                setSelectedAnswer(temp)
                                                handleListItemClick(option, question)
                                            }}
                                        >
                                            <ListItemText primary={option} />
                                        </ListItemButton>
                                    </ListItem>
                                </>))}
                        </Grid>
                    </>
            }
        } else {

        }
    }
    const getStartWritingBtnDisableCond = () => {
        for (let i = 0; i <= selectedAnswer.length - 1; i++) {
            let val = selectedAnswer[i].user_additional_information_data_value
            if (Array.isArray(val) && val.length == 0) {
                return true
            } else if (val.trim() == "") {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <>
            {!isLoading && (
                <Box>
                    <Grid container className={styles.navContainer}>
                        <Grid item>
                            <img src={WriteWizLogo} alt="Logo" className={styles.welcome_pg_wz_logo} />
                        </Grid>

                    </Grid>

                    {flagShowIntro &&
                        <>
                            <LazyLoadingImage flagShow={false} />
                            <Grid container direction="column" sx={{ padding: "50px" }} className={styles.introGrid}>
                                <div className={styles.overlay}>
                                    <Grid item className={styles.welcomeTextStyle}>
                                        <Grid container direction={"column"} gap="15px">

                                            <Typography className={styles.welcomeText}>
                                                <span className={styles.confirmationText}>Welcome {userDetail.user_first_name ? `${userDetail.user_first_name}` : ``}</span>  <img src={handWave} alt='welcome' style={{ width: "50px", height: "auto" }} />
                                            </Typography>
                                        </Grid> </Grid>
                                    <Grid item className={styles.introText}>
                                        <span className={styles.subHeadingStyle} style={{ paddingBottom: "7px" }}>
                                            We're thrilled to have you on board!
                                        </span>
                                        <p className={styles.introTextDescriptionStyle}>
                                            Let's personalize your journey by answering a few quick questions.
                                        </p></Grid>
                                </div>
                            </Grid>
                        </>
                    }
                    {!flagShowIntro &&
                        <>
                            <Grid container className={styles.questionsContainer}>
                                <Grid item className={globalStyles.fullwidth}>
                                    <Grid container justifyContent={"space-between"} sx={{ position: "relative" }}>
                                        <Grid item>
                                            <Box className={styles.staticDataWrapper}>
                                                <Grid item
                                                    className={globalStyles.displayFlex}
                                                >
                                                    <Grid container flexWrap={"nowrap"} gap="13px">
                                                        <Grid item>  <ThemeProvider theme={theme}>
                                                            <CircularProgress color='primary' determinate value={(currentQuestion + 1) * 100 / (3)} className={styles.welcomePageProgress} sx={{ color: "#6A097D !important", fontWeight: "600", fontSize: '11px', stroke: "#6A097D !important" }}>
                                                                <span style={{ color: "black", paddingRight: "2px" }}>{currentQuestion + 1}</span> / <span style={{ paddingLeft: "2px" }}>{3}</span>
                                                            </CircularProgress>
                                                        </ThemeProvider></Grid>
                                                        <Grid item>
                                                            <Typography variant='h3' fontSize={"26px"} fontWeight={"600"} sx={{ alignItems: "center", color: "#1F384C" }}>
                                                                <span style={{ color: "#6A097D" }}>Personalize </span> your experience{userDetail.user_first_name ? `, ${userDetail.user_first_name}` : ""} <img alt="" src={shine} style={{ width: "23px", height: "auto" }} />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <motion.div
                                    key={currentQuestion}
                                    initial={{ opacity: 0, y: "45vh" }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ type: 'tween', ease: 'easeInOut', duration: 0.4 }}
                                    className={styles.questionsWrapper}
                                >
                                    <Grid item>
                                        <Typography variant='h6' fontWeight={"600px"} fontSize={"18px"}>{questions[currentQuestion]?.user_additional_information_question}</Typography>
                                        <Typography className={styles.subTextStyle}>{questions[currentQuestion]?.user_additional_information_description}</Typography>
                                        {
                                            <Grid container
                                            >
                                                {currentQuestion === 0 &&
                                                    getInputType(0, questions[0], currentQuestion)
                                                    // questions && questions.map((option: any, index: number) => {
                                                    //     if (index == 0) {
                                                    //         return getInputType(index, option, currentQuestion)
                                                    //     } else if (index == 1) {
                                                    //         return <>
                                                    //             <div style={{ display: "flex", flexDirection: "column" }}>
                                                    //                 <div style={{ marginBottom: "30px" }}>{getInputType(index, option, currentQuestion)}</div>
                                                    //                 <Typography variant='h6' fontWeight={"600px"} fontSize={"18px"}>{questions[4]?.user_additional_information_question}</Typography>
                                                    //                 <Typography className={styles.subTextStyle}>{questions[4]?.user_additional_information_description}</Typography>
                                                    //                 <div>{getInputType(4, questions[4], 4)}</div>
                                                    //             </div>
                                                    //         </>


                                                    //     }

                                                    // }
                                                    // )
                                                }
                                                {currentQuestion === 1 &&
                                                    <>
                                                        {getInputType(1, questions[1], currentQuestion)}
                                                        <div style={{ marginTop: "30px" }}>
                                                            <Typography variant='h6' fontWeight={"600px"} fontSize={"18px"}>{questions[4]?.user_additional_information_question}</Typography>
                                                            <Typography className={styles.subTextStyle}>{questions[4]?.user_additional_information_description}</Typography>
                                                            {getInputType(4, questions[4], 4)}
                                                        </div>
                                                    </>
                                                }
                                                {currentQuestion === 2
                                                    &&
                                                    <>
                                                        {getInputType(2, questions[2], currentQuestion)}
                                                        <div style={{ marginTop: "30px" }}>
                                                            <Typography variant='h6' fontWeight={"600px"} fontSize={"18px"}>{questions[3]?.user_additional_information_question}</Typography>
                                                            <Typography className={styles.subTextStyle}>{questions[3]?.user_additional_information_description}</Typography>
                                                            {getInputType(3, questions[3], 3)}
                                                        </div>
                                                    </>
                                                }
                                            </Grid>
                                        }


                                    </Grid>
                                </motion.div>
                            </Grid>

                            <Box className={styles.questionsContainerButton} display={"flex"} gap="15px" justifyContent={"end"}>
                                {currentQuestion !== 0 &&
                                    <div style={{ width: "130px" }}>
                                        <GlobalButton
                                            flagFullWidth
                                            buttonText={"Previous"}
                                            startIcon={<ArrowBackIcon />} onClick={goToPreviousQuestion}
                                            className={'transparentBorderedButtonStyle'}
                                        >
                                        </GlobalButton>
                                    </div>
                                }

                                {(currentQuestion !== 2) &&
                                    <div style={{ width: "130px" }}>
                                        {
                                            // alreadyAnswered[currentQuestion])
                                            <GlobalButton
                                                flagFullWidth
                                                buttonText={"Next"}
                                                disabled={getNextBtnDisableCond()}
                                                endIcon={<ArrowBackIcon sx={{ transform: 'rotate(180deg)' }} />} onClick={goToNextQuestion}
                                                //  className={styles.skipBackBtn} 
                                                className={'primaryButtonStyle'}
                                            // sx={{ color: "black !important" }}
                                            >
                                            </GlobalButton>
                                        }
                                    </div>}
                                {currentQuestion === 2 &&
                                    <div style={{ width: "151px" }}>
                                        <GlobalButton buttonText={"Get Started"} className="primaryButtonStyle" endIcon={<img src={WzStarIcon} style={{ width: "20px", height: "auto" }} alt="" />}
                                            // className={styles.startWritingBtn}
                                            // disabled={getStartWritingBtnDisableCond()}
                                            disabled={getNextBtnDisableCond()}
                                            flagFullWidth
                                            onClick={handleSkip}>

                                        </GlobalButton>
                                    </div>
                                }
                                {/* {currentQuestion !== questions.length - 2 && <Button variant='outlined' endIcon={<ArrowForwardIosIcon fontSize='small' sx={{ width: "80%" }} />} onClick={handleSkip} className={styles.skipBackBtn}>
                            Skip
                        </Button>} */}
                            </Box>
                        </>}

                </Box>
            )}
        </>
    )
}

export default Welcome;