import { Button, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import { useSearchParams } from 'react-router-dom'
import { initialLeadGeneraionData } from './leadGenerationConstants'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationGlobal.module.scss';

const LeadGenerationStepContainer = () => {
    const { stepContainer, tempLeadGenerationPayload, setStepContainer } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const [searchParams] = useSearchParams()
    let lead_id = searchParams.get('lead_id')

    const handleChangeStepContainer = (stepNumber: number) => {
        setStepContainer(stepNumber)
    }

    let steps = [
        {
            name: "Add Fields",
            stepNumber: 1,
            flagDisabled: false,
            flagActive: stepContainer == 1 ? true : false
        },
        {
            name: "Export",
            stepNumber: 2,
            flagDisabled: JSON.stringify(tempLeadGenerationPayload) === JSON.stringify(initialLeadGeneraionData) ? true : false,
            flagActive: stepContainer == 2 ? true : false
        },
    ]

    return (
        <Grid container gap={"12px"} mb={2}>
            {steps.map((currentStep) => {
                return <Grid item>
                    <Button
                        disabled={currentStep.flagDisabled}
                        variant='text'
                        className={styles.stepContainer}
                        onClick={() => handleChangeStepContainer(currentStep.stepNumber)}
                        sx={{
                            textTransform: "unset",
                            color: stepContainer === currentStep.stepNumber ? '#6A097D' : 'rgba(0, 0, 0, 0.26)',
                            fontWeight: '600',
                            cursor: currentStep.flagDisabled ? "not-allowed !important" : "pointer !important"
                        }}
                    >
                        <span
                            className={styles.circle}
                            style={{
                                backgroundColor: stepContainer === currentStep.stepNumber ? '#6A097D' : '',
                            }}
                        >
                            {currentStep.stepNumber}
                        </span>
                        <span className={styles.stepText}>{currentStep.name}</span>
                    </Button>
                </Grid>
            })}
        </Grid>
    )
}

export default LeadGenerationStepContainer
