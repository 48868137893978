import { Divider, Grid, TablePagination, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbotStyles/chats.module.scss";
import feedbackStyles from "../../../assets/styles/componentStyles/feedbackStyles/feedbackStyle.module.scss";
import FetchFeedbackData from './fetchFeedbackData';
import moment, { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';
import FeedbackTable from './feedbackTable';
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import agent from '../../../services/api';
import { feedbackKnowledgeBaseStatus, feedbackStatus, getCallAnalysisFeedbackResponseModel, getFeedbackResponse, updateCallAnalysisFeedbackPayload, updateFeedbackPayload } from '../../../models/axiosModel';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GlobalPopUp from '../../utility/globalPopUp';
import UpdateFeedbackPopup from './updateFeedbackPopup';
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import FeedbackFilterPopup from './feedbackFilterPopup';

export interface filterData {
  selectedApprovalStatus: feedbackStatus | null;
  selectedKnowledgeBaseStatus: feedbackKnowledgeBaseStatus | null;
  startDate: Moment | null;
  endDate: Moment | null;
}

const FeedbackGlobal = () => {

    const {
        feedbackStatusData,
        setFeedbackStatusData,
        feedbackKnowledgeBaseStatusData,
        setFeedbackKnowledgeBaseStatusData } = useContext(GenericDataContext) as GenericDataContextPayload;
    const { flagLoading, setFlagLoading, setError, userDetail, success, setSuccess } = useContext(UserContext) as UserContextPayload;

    const [searchParams, setSearchParams] = useSearchParams(); 
    
    const [filterData, setFilterData] = useState<filterData>({
        selectedApprovalStatus: null, 
        selectedKnowledgeBaseStatus: null, 
        startDate: moment().subtract(1, "month"), 
        endDate: moment(),
    })
    
    const [flagFetchTrigger, setFlagFetchTrigger] = useState<boolean>(false);
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [flagUpdateFeedbackPopup, setFlagUpdateFeedbackPopup] = useState<boolean>(false);
    const [flagFeedbackFilterPopup, setFlagFeedbackFilterPopup] = useState<boolean>(false);
    const [currenctRowData, setCurrenctRowData] = useState<getFeedbackResponse | getCallAnalysisFeedbackResponseModel | null>(null);

    const breakPoints = {
        flagSmallScreen: useMediaQuery<boolean>('(min-width: 0px) and (max-width: 1000px)'),
        below600Screen: useMediaQuery<boolean>('(min-width: 0px) and (max-width: 600px)'),
        flagLargeScreen: useMediaQuery<boolean>('(min-width: 1600px)'),
    }

    const innerHeight = !(breakPoints.flagLargeScreen) ? (window.innerHeight - 60) : (window.innerHeight - 10);

    const [feedbackData, setFeedbackData] = useState<(getFeedbackResponse | getCallAnalysisFeedbackResponseModel)[]>([]);
    const [chatbotFeedbackData, setChatbotFeedbackData] = useState<getFeedbackResponse[] | null>(null);
    const [callAnalysisFeedbackData, setCallAnalysisFeedbackData] = useState<getCallAnalysisFeedbackResponseModel[] | null>(null);

    const formatDate = (dateString: Moment | null) => {
        return dateString ? dateString.format('YYYY-MM-DD') : null
    };

    const getFeedbackApiCall = async (feedback_status_id?: number | null, modification_status_id?: number | null, startDate?: string | null, endDate?: string | null) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const response = await agent.getFeedback.get(feedback_status_id, modification_status_id, startDate, endDate);
        if (response.status) {
            if (response.data) {
                setChatbotFeedbackData(response.data);
                setPage(0);
            }
        } else {
            setChatbotFeedbackData([]);
            let errorCode = checkForErrorCode(response);
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    const getCallAnalysisFeedbackApiCall = async (feedback_status_id?: number | null, modification_status_id?: number | null, startDate?: string | null, endDate?: string | null) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const res = await agent.getCallAnalysisFeedback.get(feedback_status_id, modification_status_id, startDate, endDate);
        if (res.status) {
            if (res.data) {
                setCallAnalysisFeedbackData(res.data);
                setPage(0);
            }
        } else {
            setCallAnalysisFeedbackData([]);
            let errorCode = checkForErrorCode(res)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: res.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    const handleFetchData = () => {
        setCallAnalysisFeedbackData(null);
        setChatbotFeedbackData(null);
        const currentParams = Object.fromEntries(searchParams.entries());
        const updatedParams = {
            ...currentParams,
            feedback_status_id: filterData.selectedApprovalStatus?.feedback_status_id ? (filterData.selectedApprovalStatus?.feedback_status_id).toString() : "",
            modification_status_id: filterData.selectedKnowledgeBaseStatus?.modification_status_id ? (filterData.selectedKnowledgeBaseStatus?.modification_status_id).toString() : "",
            start_date: formatDate(filterData.startDate) || "",
            end_date: formatDate(filterData.endDate) || "",
        } 
        setSearchParams(updatedParams); 
        setFlagFetchTrigger(prev => !prev);
    }

    const handleResetFilter = () => {
        setFilterData((prev) => ({
            ...prev, 
            selectedApprovalStatus: feedbackStatusData.find((status) => status.feedback_status_name.toLowerCase() === "pending") || null, 
            selectedKnowledgeBaseStatus: feedbackKnowledgeBaseStatusData.find((status) => status.modification_status_name.toLowerCase() === "not started") || null, 
            startDate: moment().subtract(1, "month"), 
            endDate: moment(),
        }))

        const currentParams = Object.fromEntries(searchParams.entries());
        const updatedParams = {
            ...currentParams,
            feedback_status_id: feedbackStatusData.find((status) => status.feedback_status_name.toLowerCase() === "pending")?.feedback_status_id.toString() || "",
            modification_status_id: feedbackKnowledgeBaseStatusData.find((status) => status.modification_status_name.toLowerCase() === "not started")?.modification_status_id.toString() || "",
            start_date: formatDate(moment().subtract(1, "month")) || "",
            end_date: formatDate(moment()) || "",
        }
        setSearchParams(updatedParams); 
        setFlagFetchTrigger(prev => !prev);
    }

    const onTableRowClick = (item: getFeedbackResponse | getCallAnalysisFeedbackResponseModel) => {
        setCurrenctRowData(item);
        setFlagUpdateFeedbackPopup(true);
    }

    const updateFeedbackApiCall = async (feedback_id: number, status_id: number, modification_status_id: number, reviewer_note: string | null, admin_note: string | null, isCallAnalysisFeedback: boolean) => {
        if (!isCallAnalysisFeedback) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const payload: updateFeedbackPayload = {
                feedback_id: feedback_id,
                feedback_status_id: status_id,
                modification_status_id: modification_status_id,
                reviewer_note: reviewer_note ? reviewer_note : null,
                admin_note: admin_note ? admin_note : null,
            };
            const res = await agent.updateFeedback.put(payload);
            if (res.status) {
                if (res.data) {
                    // const filtered_data = res.data.find((data: any) => data.feedback_id === feedback_id);
                    setFeedbackData((prev) =>
                        prev.map((feedback) => {
                            if ("feedback_id" in feedback) {
                                return feedback.feedback_id === feedback_id ? res.data[0] : feedback
                            }
                            return feedback;
                        })
                    );
                    setCurrenctRowData(res.data[0]);
                    setSuccess({ ...success, flagShowSuccess: true, message: `Feedback Updated Successfully` });
                }
            } else {
                let errorCode = checkForErrorCode(res)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        } else {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const payload: updateCallAnalysisFeedbackPayload = {
                feedback_status_id: status_id,
                reviewer_note: reviewer_note ? reviewer_note : null,
                admin_note: admin_note ? admin_note : null,
                call_analysis_feedback_id: feedback_id,
                modification_status_id: modification_status_id,
            }

            const res = await agent.updateCallAnalysisFeedback.put(payload);
            if (res.status) {
                if (res.data) {
                    const filtered_data = res.data.find((data: any) => data.call_analysis_feedback_id === feedback_id);
                    setFeedbackData((prev) =>
                        prev.map((feedback) => {
                            if ("call_analysis_feedback_id" in feedback) {
                                return feedback.call_analysis_feedback_id === feedback_id ? filtered_data : feedback
                            }
                            return feedback;
                        })
                    );
                    setCurrenctRowData(filtered_data);
                    setSuccess({ ...success, flagShowSuccess: true, message: `Feedback Updated Successfully` });
                }
            } else {
                let errorCode = checkForErrorCode(res)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }

    useEffect(() => {
        if (chatbotFeedbackData && callAnalysisFeedbackData) {
            setFeedbackData([...chatbotFeedbackData, ...callAnalysisFeedbackData]);
        }
    }, [chatbotFeedbackData, callAnalysisFeedbackData])

    const getDefaultFeedbackStatus = () => {
        const defaultId = feedbackStatusData.find((status) => {
            return status.feedback_status_name.toLowerCase() === "pending";
        }) 
        if(defaultId){
            return (defaultId.feedback_status_id).toString();
        } 
        return "";
    } 

    const getDefaultModificationStatus = () => {
        const defaultId = feedbackKnowledgeBaseStatusData.find((status) => {
            return status.modification_status_name.toLowerCase() === "not started";
        }); 
        if(defaultId){
            return (defaultId.modification_status_id).toString();
        } 
        return "";
    }

    const setInitialParamValue = () => { 
        let params = Object.fromEntries(searchParams.entries())
        let feedbackStatusId =  params.feedback_status_id || getDefaultFeedbackStatus();
        let modificationStatus =  params.modification_status_id || getDefaultModificationStatus();
        params.feedback_status_id =feedbackStatusId
        params.modification_status_id =modificationStatus
        params.start_date = params.start_date || formatDate(moment().subtract(1, "month")) || formatDate(filterData.startDate)|| "";
        params.end_date = params.end_date || formatDate(moment()) || formatDate(filterData.endDate) || "";
        setSearchParams(params) 

        setFilterData((prev) => ({
            ...prev, 
            selectedApprovalStatus: (() => {
                if (feedbackStatusId) {
                    const res = feedbackStatusData.find((status) => {
                        return status.feedback_status_id == Number(feedbackStatusId)
                    });
                    return res || null;
                }else{
                    const res = feedbackStatusData.find((status) => {
                        return status.feedback_status_name.toLowerCase() == "pending";
                    });
                    return res || null;
                }
            })(), 
            selectedKnowledgeBaseStatus: (() => {
                if (modificationStatus) {
                    const res = feedbackKnowledgeBaseStatusData.find((status) => {
                        return status.modification_status_id.toString() === modificationStatus;
                    })
                    return res || null;
                }else{
                    const res =  feedbackKnowledgeBaseStatusData.find((status) => {
                        return status.modification_status_name.toLowerCase() === "not started";
                    })
                    return res || null;
                }
            })(),
        }));
    }  

    const getFeedbackStatusDataApiCall = async () => {
        const response = await agent.getFeedbackStatus.get();
        if (response.status) {
            if (response.data) {
                setFeedbackStatusData(response.data);
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
    }  

    const getFeedbackKnowledgeBaseStatusDataApiCall = async () => {
        const response = await agent.getFeedbackModificationStatus.get();
        if (response.status) {
            if (response.data) {
                setFeedbackKnowledgeBaseStatusData(response.data); 
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (ifUserMessageShouldBeDynamic(errorCode)) {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
            } else {
                setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
            }
        }
    }

    useEffect(() => { 
        if (feedbackStatusData.length === 0) {
            getFeedbackStatusDataApiCall();
        }
        if (feedbackKnowledgeBaseStatusData.length === 0) {
            getFeedbackKnowledgeBaseStatusDataApiCall();
        }
        

        const feedback_status_id = Number(searchParams.get('feedback_status_id'));
        const modification_status_id = Number(searchParams.get('modification_status_id'));
        const start_date = searchParams.get('start_date');
        const end_date = searchParams.get('end_date'); 

        if(searchParams.size){ 
            getFeedbackApiCall(feedback_status_id, modification_status_id, start_date, end_date);
            getCallAnalysisFeedbackApiCall(feedback_status_id, modification_status_id, start_date, end_date);
        }

        let updatedStartDate: Moment | null = moment().subtract(1, "month"); 
        let updatedEndDate: Moment | null = moment(); 
        if(start_date) updatedStartDate = moment(start_date, 'YYYY-MM-DD'); 
        if(end_date) updatedEndDate = moment(end_date, 'YYYY-MM-DD'); 

        setFilterData((prev) => ({
            ...prev, 
            startDate: updatedStartDate, 
            endDate: updatedEndDate,
        }))
    }, [searchParams, flagFetchTrigger]) 

    useEffect(() => {
        if(feedbackStatusData.length > 0 && feedbackKnowledgeBaseStatusData.length > 0){
            setInitialParamValue();
        }
    }, [feedbackStatusData, feedbackKnowledgeBaseStatusData])

    return (
        <>
            <GlobalPopUp
                open={flagUpdateFeedbackPopup}
                onClose={() => setFlagUpdateFeedbackPopup(false)}
                handleClose={() => setFlagUpdateFeedbackPopup(false)}
                flagShowCloseIcon={true}
                paperMinWidth={breakPoints.flagSmallScreen ? "310px" : "700px"}
                paperMaxwidth={breakPoints.flagSmallScreen ? "610px" : "750px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <UpdateFeedbackPopup
                    rowData={currenctRowData as getFeedbackResponse | getCallAnalysisFeedbackResponseModel}
                    setCurrenctRowData={setCurrenctRowData as React.Dispatch<React.SetStateAction<getFeedbackResponse | getCallAnalysisFeedbackResponseModel>>}
                    updateFeedbackApiCall={updateFeedbackApiCall}
                />
            </GlobalPopUp>
            <GlobalPopUp
                open={flagFeedbackFilterPopup}
                onClose={() => setFlagFeedbackFilterPopup(false)}
                handleClose={() => setFlagFeedbackFilterPopup(false)}
                flagShowCloseIcon={true}
                paperMinWidth={breakPoints.flagSmallScreen ? "210px" : "600px"}
                paperMaxwidth={breakPoints.flagSmallScreen ? "510px" : "650px"}
                borderRadius='10px'
                titlePadding='0px'
            >
                <FeedbackFilterPopup
                    filterData={filterData} 
                    setFilterData={setFilterData}
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                    handleFetchData={handleFetchData}
                    handleResetFilter={handleResetFilter}
                    setFlagFeedbackFilterPopup={setFlagFeedbackFilterPopup}
                />
            </GlobalPopUp>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important`, overflow: "auto" }}>
                <Grid container sx={{ padding: "20px 25px 0px 27px", height: "100% !important" }}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: "center", height: "100% !important", flexWrap: "nowrap !important" }} direction={"column"}>
                            {!(breakPoints.below600Screen) &&
                                <>
                                    <Grid item className={globalStyles.fullwidth} sx={{ marginBottom: "15px" }}>
                                        <GlobalPageHeaderText
                                            flagTimezone={true}
                                            mainHeadText={"Feedback"}
                                            flagMainTextOnly
                                        />
                                    </Grid>
                                    <Divider className={`${globalStyles.fullwidth} ${styles.dividerStyle}`} sx={{ paddingTop: "4px !important" }} />
                                </>
                            }

                            <Grid item className={`${globalStyles.fullwidth} ${styles.lowerBlock}`} sx={{ position: "relative", height: "100% !important" }}>
                                <Grid container direction={"column"} gap={"12px"}>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FetchFeedbackData
                                            filterData={filterData} 
                                            setFilterData={setFilterData}
                                            focusedInput={focusedInput}
                                            setFocusedInput={setFocusedInput}
                                            handleFetchData={handleFetchData}
                                            handleResetFilter={handleResetFilter}
                                            setFlagFeedbackFilterPopup={setFlagFeedbackFilterPopup}
                                        />
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FeedbackTable
                                            feedbackData={feedbackData}
                                            setFeedbackData={setFeedbackData}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            onTableRowClick={onTableRowClick}
                                            updateFeedbackApiCall={updateFeedbackApiCall}
                                        />
                                    </Grid>
                                    {feedbackData?.length > 0 &&
                                        <Grid item textAlign={"end"}>
                                            <Grid container justifyContent={"end"}>
                                                <Grid item sx={{ padding: "0 8px 0 8px", boxShadow: "0 0 2px rgba(0,0,0,0.5)", borderRadius: "5px", margin: "0 2px 5px 2px" }}>
                                                    <TablePagination
                                                        component="div"
                                                        className={`${feedbackStyles.feedbackTablePaginationStyle}`}
                                                        count={feedbackData?.length}
                                                        labelRowsPerPage="Rows per page:"
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                                                        onPageChange={(e, newPage) => setPage(newPage)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default FeedbackGlobal
