import { Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import GlobalButton from '../../../../utility/globalButton/button'
import globalStyles from './../../../../../assets/styles/global.module.scss'
import styles from './../../../../../assets/styles/componentStyles/calendlyStyles/calendly.module.scss'
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext'
import { CalendlyContext, CalendlyContextType } from '../../../../../context/calendlyIntegrationContext'
import { UserContext, UserContextPayload } from '../../../../../context/userContext'
import { checkForErrorCode, getErrorMessage, ifUserMessageShouldBeDynamic, isDev } from '../../../../../utils/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'

const AuthoriseAccount = () => {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setFlagLoading, setError, userDetail } = useContext(UserContext) as UserContextPayload;
    const { calendlyAuthUrlApi, calendlyAuthData, GetCallbackStatus, callbackSuccessData, GetCalendlyIntegrationData, setStep } = useContext(CalendlyContext) as CalendlyContextType;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id');

    const schedule = async () => {
        try {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }));

            let currentUrl = window.location.href;
            const payload = {
                current_url: currentUrl,
                integration_app_id: 1,
            };

            const response = await calendlyAuthUrlApi(payload);

            if (response.status) {
                const authUrl = response?.data?.auth_url;
                if (authUrl) {
                    window.open(response.data.auth_url, "_blank")
                    const stateValue = new URL(authUrl).searchParams.get('state');
                    if (stateValue) {
                        pollForCallbackStatus(stateValue);
                    } else {
                        console.error('State param not found in the auth_url.');
                    }
                }
            } else {
                let errorCode = checkForErrorCode(response)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleCallbackSuccess = (data: any) => {
        const { integration_type_name, integration_details } = data;
        switch (integration_type_name.toLowerCase()) {
            case 'calendly':
                makeIntegrationDataCall(integration_details.calendly.id);
                break;
            case 'acuity':
                makeIntegrationDataCall(integration_details.acuity.id);
                break;
            default:
                console.error('Unknown integration type:', integration_type_name);
        }
    };

    const pollForCallbackStatus = (stateValue: string) => {
        const pollInterval = 8000;
        const maxPollingTime = 80000;
        let elapsedTime = 0;

        const intervalId = setInterval(async () => {
            try {
                const response = await GetCallbackStatus(stateValue);

                if (response.status) {
                    const integrationStatus = response?.data?.integration_status?.toLowerCase();

                    if (integrationStatus === 'completed' || integrationStatus === 'failed') {
                        clearInterval(intervalId);

                        if (integrationStatus === 'failed') {
                            alert(response?.data?.reason);
                        } else {
                            handleCallbackSuccess(response?.data);
                        }
                    } else {
                        console.log("Callback not completed, retrying...");
                    }

                    elapsedTime += pollInterval;

                    if (elapsedTime >= maxPollingTime) {
                        console.log("Polling stopped after 1 minute.");
                        clearInterval(intervalId);
                    }
                } else {
                    let errorCode = checkForErrorCode(response.data)
                    if (ifUserMessageShouldBeDynamic(errorCode)) {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                    } else {
                        setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                    }
                }
            } catch (error) {
                console.error("Error polling for callback status:", error);
                clearInterval(intervalId);
            } finally {
                setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
            }
        }, pollInterval);
    };


    const makeIntegrationDataCall = async (id: any) => {
        try {
            const response = await GetCalendlyIntegrationData(id);
            if (response.status) {
                setStep('2')
                navigate(`/chatbot/viewchatbot/integration/calendly/selectCalendlyEvent?chatbot_id=${chatbot_id}`);
            } else {
                let errorCode = checkForErrorCode(response.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: response.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        } catch (error) {
            console.error("Error fetching integration data:", error);
        } finally {
            setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
        }
    };

    return (
        <>
            <Grid container direction={'column'} gap={"15px"}>
                <Grid item>
                    <span className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight}`}>
                        Authorise your chatbot with Calendly
                    </span>
                </Grid>
                <Grid item>
                    <Grid container direction={'column'} gap={"20px"} className={styles.setupDiv}>
                        <Grid item className={styles.setupDivText}>
                            <span style={{ color: "#353535" }}>This Chatbot is not linked with any calendly event type</span>
                        </Grid>
                        <Grid item>
                            <GlobalButton
                                buttonText={"Setup Calendly"}
                                className='primaryButtonStyle'
                                onClick={() => { schedule(); }}
                                sx={{ width: "170px", fontSize: "18px", fontWeight: "600" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default AuthoriseAccount;
