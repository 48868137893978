import React, { useContext, useEffect, useState } from 'react'
import styles from './../../../../assets/styles/componentStyles/widgetComponentStyles/cartForm.module.scss'
import { Button, Grid, Typography } from '@mui/material'

const CartMenu = (props: any) => {
    const {formatData, cart, setTotalPrice, thread, numberWithCommas, chatbotTheme} = props;
    const [count, setCount] = useState<number>();
    const initialProductPrice = () => {
        const itemData = thread.cart_compact_data?.find((coData: any) => coData.variant_id === cart.variant_id);
        const quantity = Number(itemData?.quantity) || 0;
        const productPrice = Number(itemData?.product_price) || 0;
        const totalPrice = quantity * productPrice;
        return parseFloat(totalPrice.toFixed(2));
    };
    const [productPrice, setProductPrice] = useState(initialProductPrice);
    const [cartCompact, setCartCompact] = useState(thread?.cart_compact_data)

    useEffect(() => {
        const total = cartCompact?.reduce((acc: any, data: any) => {
            const price = Number(data.product_price);
            const quantity = Number(data.quantity);
            return acc + (isNaN(price) || isNaN(quantity) ? 0 : price * quantity);
        }, 0);
    
        setTotalPrice(isNaN(total) ? 0 : parseFloat(total.toFixed(2)));
    }, [cartCompact]);

    useEffect(() => {
        if(thread) {
            if(thread.cart_compact_data?.find((coData:any) => coData.variant_id === cart.variant_id)) {
                setCount(Number(thread.cart_compact_data.find((coData: any) => coData.variant_id === cart.variant_id).quantity))
            }
            else {
                setCount(0)
            }
        }
    }, [thread])

    return (
        <Grid container gap={1} flexWrap={"nowrap"} mb={"0px"} className={styles.cart_form_details_card}>
            <Grid item xs={2.5} sx={{width: "100%"}}>
                <img src={cart.data[0].field_value} height={"70"} width={"70"} style={{display: "flex", objectFit: "cover"}}/>
            </Grid>

            <Grid item xs={6} sx={{width: "100%"}}>
                <Grid container direction={"column"} gap={"2px"} justifyContent={"space-evenly"}>
                    <Grid item>
                        <span className={styles.cart_form_product_name_style}>
                            {cart?.data[1]?.field_value} {cart?.metadata && cart?.metadata.product_gender ? `(${cart?.metadata.product_gender})` : ""}
                        </span>
                    </Grid>
                    {cart?.metadata &&
                        <Grid item sx={{display: "flex"}}>
                            {cart.metadata.product_size &&
                                <span className={styles.metadataStylesVariants}>
                                    {cart?.metadata?.product_size}
                                </span>
                            }
                            {cart.metadata.product_color &&
                                <ul className={styles.cart_product_variant_list}>
                                    <li className={styles.metadataStylesVariants}>
                                        {`${cart?.metadata?.product_color}`}
                                    </li>
                                </ul>
                            }                          
                        </Grid>
                    }
                    <Grid item>
                        <Typography
                            component="span"
                            className={styles.cart_form_product_initial_price_sytle}
                            sx={{color: `${chatbotTheme?.chatbot_header_color ? chatbotTheme?.chatbot_header_color : "#6A097D"}`}}
                        >
                            {formatData.currency}&nbsp;{cart.data[2].field_value}
                            <Typography component='span' className={styles.cart_form_product_unit_style} sx={{opacity: "0.8 !important"}}>
                                &nbsp;{cart.data[3]?.field_value ? `/ ${cart.data[3]?.field_value}` : "/ Item"}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* {productPrice && */}
                            <span className={styles.cart_form_product_price_style}>
                                {formatData.currency}&nbsp;{numberWithCommas(productPrice)}
                            </span>
                        {/* } */}
                    </Grid>
                    
                </Grid>
            </Grid>

            <Grid item xs={3.5} sx={{width: "100%", display: "flex", justifyContent: "end", alignSelf:"center"}}>
                <Grid container direction={"column"} gap={"4px"} justifyContent={"space-evenly"} alignItems={"end"}>                       
                    <Grid item xs={12} sx={{ margin: '0px', padding: '0px', width: "100%" }}>
                        <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={"4px"} justifyContent={"end"}>
                            {/* <Grid item className={styles.cart_add_remove_item_style}>
                                <Button className={styles.cart_form_add_remove_button} disableRipple>
                                    <RemoveIcon className={styles.cart_add_remove_icons} />
                                </Button>    
                            </Grid> */}
                            <Grid item className={styles.cart_count_item_style}>
                                <span className={styles.cart_count_text_style}>
                                    {`${count}`}
                                </span>
                            </Grid>
                            {/* <Grid item className={styles.cart_add_remove_item_style}>
                                <Button className={styles.cart_form_add_remove_button} disableRipple>
                                    <AddIcon className={styles.cart_add_remove_icons} />
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CartMenu
