import { Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import style from "../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss";
import GlobalButton from '../../utility/globalButton/button';

interface AddNewTagPopupProps {
    setFlagAddTagDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setFlagEditContactDialog: React.Dispatch<React.SetStateAction<boolean>>,
    saveAddNewTagButtonClick: (name: string) => void,
}

const AddNewTagPopup: React.FC<AddNewTagPopupProps> = ({ setFlagAddTagDialog, setFlagEditContactDialog, saveAddNewTagButtonClick }) => {

    const [tagNameValue, setTagNameValue] = useState<string>("");
    return (
        <>
            <Grid container>
                <Grid xs={12}>
                    <Grid container direction={"column"} justifyContent={"center"}>
                        <Grid item textAlign={"center"}>
                            <Typography className={style.popupTitleTypography}>Add New Tag</Typography>
                        </Grid>

                        <Divider className={style.popupTitleDivider} />

                        <Grid item>
                            <Grid container className={style.popupBodyGridContainer} gap={1}>
                                <Grid item xs={12}>
                                    <Grid container gap={"12px"}>
                                        <Grid item xs={12}>
                                            <Typography className={style.popupFieldTypography}>Tag Name <span style={{ color: "#C60000" }}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={tagNameValue}
                                                onChange={(e) => { setTagNameValue(e.target.value) }}
                                                className={`${style.campaignContactPopupTextField}`}
                                                placeholder='Enter New Tag'
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"} gap={"12px"} sx={{ padding: "20px 0" }}>
                                <Grid item>
                                    <GlobalButton
                                        buttonText="Cancel"
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='borderedButtonStyle'
                                        onClick={() => { setFlagAddTagDialog(false); setFlagEditContactDialog(true); }}
                                    >
                                    </GlobalButton>
                                </Grid>
                                <Grid item>
                                    <GlobalButton
                                        buttonText={"Add"}
                                        sx={{ width: "130px", padding: "6.5px 20px !important", fontWeight: "500 !important" }}
                                        className='primaryButtonStyle'
                                        disabled={tagNameValue.trim().length === 0}
                                        onClick={() => { setFlagAddTagDialog(false); setFlagEditContactDialog(true); saveAddNewTagButtonClick(tagNameValue) }}
                                    >
                                    </GlobalButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddNewTagPopup
