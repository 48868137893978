import { Autocomplete, Button, Checkbox, Chip, Grid, InputLabel, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/dataconnectorform.module.scss'
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalTextField from '../../utility/globalTextfield';
import Required from '../../utility/required';
import connectorstyle from '../../../assets/styles/componentStyles/dataconnector.module.scss'
import { GenericDataContext, GenericDataContextPayload } from '../../../context/genericDataContext';
import CloseIcon from '@mui/icons-material/Close';
interface createResponsePayload {
    function_response_data_id?: number | null,
    function_response_name: string,
    function_parameter_type_id: null | number,
    function_response_description: string,
    function_response_default: string,
    function_response_required_flag: boolean,
    function_response_enum_value: any
}
interface responseProps {
    flagKeyExistError: any;
    setFlagKeyExistError: any;
    createResponsePayload: createResponsePayload
    onChangePayload: (value: any, keyName: string, objName: string) => void,
    handleSaveResponse: (objName: string) => void;
    handleCancelSaveResponse: (objName: string) => void
}
const AddResponse = (props: responseProps) => {
    const [enumValue, setEnumValue] = useState("")
    const { functionParameterTypeDataList } = useContext(GenericDataContext) as GenericDataContextPayload;
    const getSaveButtonDisableFlag = () => {
        if (props.createResponsePayload.function_response_name !== "" && props.createResponsePayload.function_parameter_type_id !== null && props.createResponsePayload.function_response_description !== "") {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <Grid container className={`${styles.dataConnectorPopupSpacing}`}>
                <Grid item xs={12} className={`${styles.dataConnectorFormPopup} ${styles.dataConnectorPopupBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography
                                component='span'
                                className={styles.dataConnectorHeaderText}
                            >
                                Add Response
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.dataconnectorHeaderDivider} />
                        </Grid>

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} className={connectorstyle.createFormField} gap={"20px"} p={1}>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Name - Key</InputLabel><Required /></Grid>
                                                <Grid item><GlobalTextField placeholder='Enter Name - Key' value={props.createResponsePayload.function_response_name} onChange={(event) => { props.setFlagKeyExistError((prevState: any) => ({ ...prevState, flagResponseKey: false })); props.onChangePayload(event.target.value, 'function_response_name', '') }} /></Grid>
                                                {props.flagKeyExistError.flagResponseKey && <span className={globalStyles.validationText}>This key already exist!</span>}
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Type</InputLabel><Required /></Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        // className={styles.formFieldMethod}
                                                        className={`${globalStyles.autocompleteParaphrasing} ${styles.autoComplete}`}
                                                        value={functionParameterTypeDataList.find((param) => param.function_parameter_type_id == props.createResponsePayload.function_parameter_type_id) ?? null}
                                                        onChange={(event: React.SyntheticEvent, value: any | null) => {
                                                            if (value) {
                                                                props.onChangePayload(value.function_parameter_type_id, 'function_parameter_type_id', '');
                                                                props.onChangePayload(value.function_parameter_type_name, 'function_parameter_type_name', '');
                                                            } else {
                                                                props.onChangePayload("", 'function_parameter_type_id', '');
                                                                props.onChangePayload("", 'function_parameter_type_name', '');
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => option.function_parameter_type_name}
                                                        options={functionParameterTypeDataList}
                                                        renderInput={(params) => <TextField {...params} placeholder='Select Type' />}
                                                        sx={{ padding: "7px 4px !important" }}
                                                    />
                                                </Grid>
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_name} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Description</InputLabel><Required /></Grid>
                                                <Grid item sx={{ position: "relative" }}>
                                                    <TextareaAutosize
                                                        placeholder='Enter Description'
                                                        className={styles.ctTextarea}
                                                        minRows={1}
                                                        value={props.createResponsePayload.function_response_description}
                                                        onChange={((event) => { props.onChangePayload(event.target.value, 'function_response_description', '') })}
                                                    />
                                                </Grid>
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_description} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Default</InputLabel></Grid>
                                                <Grid item><GlobalTextField placeholder='Enter Default' value={props.createResponsePayload.function_response_default} onChange={(event) => { props.onChangePayload(event.target.value, 'function_response_default', '') }} /></Grid>
                                                {/* <Grid item><GlobalTextField value={manageDataConnectorPayload.chatbot_data_connectors[0].data_connector_functions.data_connector_function_description} /></Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"}>
                                                <Grid item mb={1}><InputLabel className={connectorstyle.formFieldTitle}>Enum</InputLabel></Grid>
                                                <Autocomplete
                                                    options={[]}
                                                    freeSolo
                                                    multiple
                                                    className={styles.autocompleteValues}
                                                    value={(props.createResponsePayload.function_response_enum_value && props.createResponsePayload.function_response_enum_value.length > 0) ? props.createResponsePayload.function_response_enum_value.split(',') : []}
                                                    onChange={(event: any, value: any) => {
                                                        props.onChangePayload(value.join(','), 'function_response_enum_value', '');
                                                        setEnumValue("")
                                                    }}
                                                    // @ts-ignore
                                                    onInputChange={((event) => { setEnumValue(event.target.value) })}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                label={option}
                                                                deleteIcon={<CloseIcon />}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => <TextField {...params} placeholder="Select Values" />}
                                                />
                                                {enumValue && <div style={{ fontSize: "13px", fontWeight: "500", color: "#4C4A4D", marginTop: "5px" }}>Press Enter to Save: <span style={{ fontSize: "14px", fontWeight: "600", color: "#6A097D" }}>{enumValue}</span> </div>}
                                            </Grid>
                                        </Grid>
                                        <Grid item className={styles.checkBox}>
                                            <InputLabel className={connectorstyle.formFieldTitle}>Required</InputLabel><Checkbox checked={props.createResponsePayload.function_response_required_flag} onChange={(event) => { props.onChangePayload(event.target.checked, 'function_response_required_flag', '') }} />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: "0px" }} mt={2}>
                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>

                                <Grid item xs={12} className={`${connectorstyle.globalHeadRightSubItem} `}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "center" }} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbCancelButton}
                                                onClick={() => { props.handleCancelSaveResponse('') }}
                                            // onClick={cancelUpdateChatbot}
                                            // disabled={chatBotDetails?.chatbot_flag_edit == true ? false : true}
                                            // sx={{ opacity: chatBotDetails?.chatbot_flag_edit ? "" : "0.5" }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ marginLeft: "15px" }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                fullWidth
                                                className={connectorstyle.cbSaveButton}
                                                onClick={() => { props.handleSaveResponse('') }}
                                                // onClick={() => { saveThemeSettings() }}
                                                disabled={getSaveButtonDisableFlag()}
                                                sx={{ opacity: getSaveButtonDisableFlag() ? "0.5" : "" }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddResponse
