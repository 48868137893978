import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/userAnalyticsStyles/userAnalyticsDashboard.module.scss'
import { BarChart } from '@mui/x-charts/BarChart';

const UserAnalyticsBarChart = (props: any) => {
    const {fieldName, fieldData } = props;

    const categories = fieldName === "Channel & CSAT Score" ? fieldData.map((item: any) => item.channel_name) : fieldData.map((item: any) => item.purpose_name);
    const scores = fieldData.map((item: any) => item.csat_score);
    const flagTabletScreen = useMediaQuery<boolean>('(min-width: 600px) and (max-width: 1200px)');


    const barColors = ['#6A097D'];

    return (
        <Grid container direction={"column"} alignItems={"start"} className={dashboardStyles.pieChartContainer}>
            <Grid item className={dashboardStyles.barContainer} sx={{zIndex: 2}}>
                <span className={dashboardStyles.pieChartHeadingName}>{`${fieldName}`}</span>
            </Grid>

            <Grid item style={{ width: '100%', overflow: "auto" }}>
                {/* Bar Chart Component */}
                <div style={{ width: '100%'}}>
                    <BarChart
                        grid={{ horizontal: true}}
                        // className={fieldName === "Agent" ? `${dashboardStyles.customBarChart} ${dashboardStyles.agentGraphStyle}` : `${dashboardStyles.customBarChart}`}
                        className={`${dashboardStyles.customBarChart}`}
                        // margin={{bottom: 30, left: 60}}
                        xAxis={[
                            {
                                data: categories,
                                scaleType: 'band',
                                disableTicks: true,
                                disableLine: true,
                                tickLabelStyle: {
                                    angle: -60,
                                    textAnchor: 'end',
                                    fontSize: 11,
                                    overflowWrap: "break-word",
                                    width: 10,
                                    height: 10,
                                    maxWidth: 10,
                                    padding: "5px",
                                },
                                //@ts-ignore
                                categoryGapRatio: 0.2,
                            },
                        ]}
                        yAxis={[
                            {
                                min: 0,
                                max: 100,
                                disableTicks: true,
                                disableLine: true,
                                tickLabelStyle: {
                                    fontSize: 13,
                                }
                            },
                        ]}
                        series={[
                            {
                                data: scores,
                                type: "bar",
                            },
                        ]}
                        sx={{
                            '& .MuiChartsAxisHighlight-root': {
                                stroke: "none",
                                strokeDasharray: "none",
                                fill: "rgba(106,9,125,0.25)",
                                maxWidth: 50
                            },
                            '& .MuiChartsAxis-line': {
                                stroke: "transparent",
                                strokeDasharray: "none",
                            },
                            '& .MuiChartsAxis-tick': {
                                stroke: "#F2F2F2",
                                strokeDasharray: "none",
                            },
                            '& .MuiBarElement-root': {
                                // x: fieldName === "Complexity" ? "3vw" : fieldName === "Categories" ? "1.5vw" : "0.5vw",
                                // x: "1vw",
                                x: flagTabletScreen ? "4.5vw" : "3.8vw"
                            }
                        }}
                        borderRadius={1}
                        height={350}
                        colors={fieldName === "Purpose & CSAT Score" ? ['#6A097D'] : ['#0A6ADD']}
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default UserAnalyticsBarChart
