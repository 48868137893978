import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import CallAnalysisPieChart from '../callAnalysis/callAnalysisPieChart'
import UserAnalyticsPieChart from './userAnalyticsPieChart'
import UserAnalyticsBarChart from './userAnalyticsBarChart'
import { userAnalyticsDataResponseModel } from '../../../models/axiosModel'
import dashboardStyles from './../../../assets/styles/componentStyles/userAnalyticsStyles/userAnalyticsDashboard.module.scss'
import CircularProgress, { CircularProgressProps, } from '@mui/material/CircularProgress';

interface userAnalyticsInterface {
    appliedFilter: boolean;
    userAnalyticsData: userAnalyticsDataResponseModel | null;
}


const UserAnalyticsDetail = (props: userAnalyticsInterface) => {
    // const mobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const tabletScreen = useMediaQuery('(max-width : 750px) and (min-width : 0px)')
    const innerHeight = window.innerHeight - 120; 
    const {appliedFilter, userAnalyticsData} = props
    return (
        // <Grid item sx={{width: "100%", height: appliedFilter ? "calc(100vh - 170px)" : "calc(100vh - 110px)", maxHeight: appliedFilter ? "calc(100vh - 160px)" : "calc(100vh - 110px)", overflow: "auto", padding: "5px 10px 5px 5px"}}>
        <Grid item sx={{width: "100%", height: `${innerHeight}px`, maxHeight: `${innerHeight}px`, overflow: "auto", padding: "5px 10px 5px 5px"}}>
            <Grid container direction={"column"} gap={"24px"}>
                <Grid item sx={{width: "100%"}}>
                    <Grid container direction={{xs: "column", lg:"row"}} flexWrap={"nowrap"} gap={"24px"}>
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <Grid container gap={"24px"} direction={{xs: "column", sm: "row"}} flexWrap={"nowrap"}>
                                <Grid item xs={12} lg={8} sx={{width: "100%"}}>
                                    <Grid container direction={{xs: "row", sm: "column"}} gap={"15px"} flexWrap={"nowrap"}>
                                        <Grid item className={dashboardStyles.averageCallComponent} xs={12} sx={{backgroundColor: "rgba(247, 233, 255, 0.6)"}}>
                                            <Grid container direction={"column"} gap={"10px"}>
                                                <Grid item>
                                                    <span className={dashboardStyles.averageHeadingName}>Conversation</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className={dashboardStyles.averageHeadingValue}>{userAnalyticsData?.total_conversations}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={dashboardStyles.averageCallComponent} xs={12} sx={{backgroundColor: "rgba(227, 245, 255, 1)"}}>
                                            <Grid container direction={"column"} gap={"10px"}>
                                                <Grid item>
                                                    <span className={dashboardStyles.averageHeadingName}>Unique Users</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className={dashboardStyles.averageHeadingValue}>{userAnalyticsData?.unique_users}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={dashboardStyles.csatScoreStyle} sx={{width: "100%", position: "relative"}}>
                                    <Grid container direction={"column"} gap={2} justifyContent={"space-between"}>
                                        <Grid item sx={{width: "100%"}}>
                                            <span className={dashboardStyles.pieChartHeadingName}>CSAT Score</span>
                                        </Grid>
                                        <Grid item className={dashboardStyles.circularProgressStyle}>                  
                                            <CircularProgress
                                                variant="determinate"
                                                value={userAnalyticsData?.csat_score}
                                                size={tabletScreen ? 150 : 180}
                                                thickness={6}
                                                sx={{
                                                    color: '#0A6ADD',
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                }}
                                            />
                                            <CircularProgress
                                                variant="determinate"
                                                value={100}
                                                size={tabletScreen ? 150 : 180}
                                                thickness={6}
                                                sx={{
                                                    color: '#C3D4F5',
                                                    position: 'absolute',
                                                }}
                                            />
                                            {/* Score in the center */}
                                            <Typography
                                                variant="h4"
                                                component="span"
                                                color="rgba(10, 106, 221, 1)"
                                                sx={{ zIndex: 2, fontWeight: "600" }}
                                            >{`${userAnalyticsData?.csat_score}%`}</Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <UserAnalyticsPieChart fieldName={"Action"} fieldData={userAnalyticsData?.action_data}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{width: "100%"}}>
                    <Grid container direction={{xs: "column", lg:"row"}} flexWrap={"nowrap"} gap={"24px"}>
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <UserAnalyticsPieChart fieldName={"Purpose"} fieldData={userAnalyticsData?.purpose_data}/>
                        </Grid>
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <UserAnalyticsPieChart fieldName={"Channel"} fieldData={userAnalyticsData?.channel_data}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{width: "100%"}}>
                    <Grid container direction={{xs: "column", lg:"row"}} flexWrap={"nowrap"} gap={"24px"}>
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <UserAnalyticsBarChart fieldName={"Channel & CSAT Score"} fieldData={userAnalyticsData?.channel_csat_score}/>
                        </Grid>
                        <Grid item xs={6} sx={{width: "100%"}}>
                            <UserAnalyticsBarChart fieldName={"Purpose & CSAT Score"} fieldData={userAnalyticsData?.purpose_csat_score}/>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default UserAnalyticsDetail
