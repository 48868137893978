import { Button, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { convertKeysToLowerCase } from '../../../../utils/constants';
import carouselStyles from './../../../../assets/styles/componentStyles/carouselCard.module.scss'
import cardStyles from './../../../../assets/styles/componentStyles/insuranceCard.module.scss'

const MenuCarousel = (props: any) => {
    const {handleMouseDown, handleMouseUp, image, getActionButtonIcon, formatData, chatbotTheme, thread } = props;
    const [count, setCount] = useState<any>();

    useEffect(() => {
        if(thread) {
            setCount(Number(thread?.cart_compact_data?.find((cart: any) => cart.variant_id === image.variant_id)?.quantity) || 0)
        }
    }, [thread])

    return (
        <Grid container marginRight={1}>
            <Grid item xs={12} sx={{ padding: '0px' }}>
                <Grid container>
                    <Grid item xs={12} className={carouselStyles.chat_crl_img_item} sx={{position: "relative"}}>
                        <img
                            onMouseDown={handleMouseDown}
                            onMouseUp={(event) => handleMouseUp(event, image)}
                            src={image.image_url[0] || "https://storage.googleapis.com/storage.writewiz-staging.in/chronox/general/medical_(3)_1726065948494.png"}
                            alt=""
                            style={{ borderRadius: "5px", cursor: "pointer", width: '100%', height: '100%', maxHeight: '100%', objectFit: "contain", objectPosition: "top", paddingTop: "5px" }}
                        />
                        {/* </LazyLoad> */}
                    </Grid>

                    <Grid item xs={12} sx={{ cursor: "pointer" }}>
                        <Grid container>
                            <Grid item xs={12}
                                sx={{
                                    maxHeight: "364px",
                                    padding: "0px 0px 5px 5px",
                                    background: chatbotTheme.system_msg_bg_color ? chatbotTheme.system_msg_bg_color : "#fff",
                                    // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important",
                                    borderRadius: "0px 0px 10px 10px !important"
                                }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ paddingRight: "0px !important", overflowY: "scroll", maxHeight: "290px", margin: "0 !important" }} className={cardStyles.ic_card_details_main_item}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ margin: '5px 0px 10px', padding: '10px 9px 5px', width: "100%" }}>
                                                <Grid container direction={"column"} alignItems={"start"}>
                                                    <Grid item>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontSize: "13px !important", fontWeight: "600 !important", color: "#7A7A7A !important", overflow: "hidden !important", textAlign: "left !important",
                                                                textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.3px"
                                                            }}
                                                        >
                                                            {image.data[0].field_value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item mt={"0px"}>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontSize: "15px !important", fontWeight: "600 !important", color: "#rgba(51,51,51) !important", overflow: "hidden !important", textAlign: "left !important",
                                                                textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.3px"
                                                            }}
                                                        >
                                                            {image.data[1].field_value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item mt={"10px"}>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontSize: "12px !important", fontWeight: "500 !important", color: "#000000 !important", overflow: "hidden !important", textAlign: "left !important",
                                                                textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.6px"
                                                            }}
                                                        >
                                                            {image.data[2].field_value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item mt={"10px"}>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontSize: "16px !important", fontWeight: "600 !important", color: "#40B87B !important", overflow: "hidden !important", textAlign: "left !important",
                                                                textOverflow: "ellipsis !important", display: "-webkit-box !important", "-webkit-line-clamp": "3 !important", letterSpacing: "0.6px"
                                                            }}
                                                        >
                                                            {formatData.currency? `${formatData.currency} ` : ""}{image.data[3].field_value} 
                                                            <Typography component='span' sx={{fontSize: "14px !important", fontWeight: "500 !important", color: "#7A7A7A !important", overflow: "hidden !important", textAlign: "left !important"}}>
                                                                &nbsp;/ {image.data[4].field_value} 
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>

                                                    {count === 0 && convertKeysToLowerCase(image)?.cta_buttons?.map((button: any) => {
                                                        return <Grid item xs={12} sx={{ margin: '10px 0px 0px', padding: '5px 0px 0px', width: "100%" }}>
                                                            <Grid container direction={"column"} alignItems={"start"}>
                                                                <Grid item sx={{width: "100%"}}>
                                                                    <Button
                                                                        disableRipple
                                                                        disabled
                                                                        sx={{
                                                                            backgroundColor: "#40B87B !important",
                                                                            color: "#FFFFFF !important",
                                                                            fontSize: "14px !important",
                                                                            fontWeight: "500 !important",
                                                                            width: "100% !important",
                                                                            borderRadius: "5px !important",
                                                                            textTransform: "none !important",
                                                                            opacity: "0.5 !important"
                                                                        }}
                                                                        startIcon={getActionButtonIcon(button.message_format_id)}
                                                                    >
                                                                        {button.message_fields.button_name}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    })}

                                                    {count > 0 &&
                                                        <Grid item xs={12} sx={{ margin: '10px 0px 0px', padding: '5px 0px 0px', width: "100%" }}>
                                                            <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={1}>
                                                                <Grid item xs={3} sx={{border: "1px solid #40B87B !important", borderRadius: "5px"}}>
                                                                    <Button
                                                                        sx={{minWidth: "0 !important", padding: "5px 18px !important", textAlign: "center"}}
                                                                        disabled
                                                                    >
                                                                        <RemoveIcon sx={{color: "#40B87B !important"}}/>
                                                                    </Button>    
                                                                </Grid>
                                                                <Grid item xs={6} sx={{border: "1px solid #40B87B !important", borderRadius: "5px", minWidth: "0 !important", padding: "6.5px 20px !important", textAlign: "center"}}>
                                                                    <span style={{fontSize: "15px", fontWeight: "600", color: "#40B87B !important"}}>
                                                                        {`${count}`}
                                                                    </span>
                                                                </Grid>
                                                                <Grid item xs={3} sx={{border: "1px solid #40B87B !important", borderRadius: "5px"}}>
                                                                    <Button
                                                                        sx={{minWidth: "0 !important", padding: "5px 18px !important", textAlign: "center"}}
                                                                        disabled
                                                                    >
                                                                        <AddIcon sx={{color: "#40B87B !important"}}/>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MenuCarousel
