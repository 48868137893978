import { createContext, useState, ReactNode, useContext } from 'react';
import agent from '../services/api';
import { chatbotLeadFieldDataModel, createLeadGenerationPayloadModel, createLeadGenerationResponseModel, deleteIntegrationPayloadModel, emailConfigModel, getGenericFieldTypeDataResponseModel, getLeadIntegrationTypeDataResponse, globalSlackIntegration, leadFieldDataModel, leadIntegrationAdditionalDataModel, slackConfigModel } from '../models/axiosModel';
import { initialEmailConfigPayload, initialIntegrationPayload, initialLeadGeneraionData, initialLeadGenerationField, initialSlackConfigPayload, leadIntegrationDataTypeStatic } from '../components/dashboard/leadGeneration/leadGenerationConstants';

export interface LeadIntegrationContextPayload {

    leadGenerationList: createLeadGenerationResponseModel | null,
    setLeadGenerationList: React.Dispatch<React.SetStateAction<createLeadGenerationResponseModel | null>>,

    createLeadGenerationPayload: createLeadGenerationPayloadModel,
    setCreateLeadGenerationPayload: React.Dispatch<React.SetStateAction<createLeadGenerationPayloadModel>>,
    tempLeadGenerationPayload: createLeadGenerationPayloadModel,
    setTempLeadGenerationPayload: React.Dispatch<React.SetStateAction<createLeadGenerationPayloadModel>>,
    createLeadGenerationResonse: createLeadGenerationResponseModel | null,
    setCreateLeadGenerationResponse: React.Dispatch<React.SetStateAction<createLeadGenerationResponseModel | null>>,

    addNewLeadField: boolean,
    setAddNewLeadField: React.Dispatch<React.SetStateAction<boolean>>,
    flagUpdateField: boolean,
    setFlagUpdateField: React.Dispatch<React.SetStateAction<boolean>>,
    flagDeleteFieldData: boolean,
    setFlagDeleteFieldData: React.Dispatch<React.SetStateAction<boolean>>,

    leadGenerationFieldPayload: leadFieldDataModel,
    setLeadGenerationFieldPayload: React.Dispatch<React.SetStateAction<leadFieldDataModel>>,
    flagDetailsAdd: boolean,
    setFlagDetailsAdd: React.Dispatch<React.SetStateAction<boolean>>,
    genericFieldDataType: getGenericFieldTypeDataResponseModel[] | null,
    setGenericFieldDataType: React.Dispatch<React.SetStateAction<getGenericFieldTypeDataResponseModel[] | null>>,
    leadIntegrationDataType: getLeadIntegrationTypeDataResponse[] | null,
    setLeadIntegrationDataType: React.Dispatch<React.SetStateAction<getLeadIntegrationTypeDataResponse[] | null>>,

    selectedIntegrationType: getLeadIntegrationTypeDataResponse,
    setSelectedIntegrationType: React.Dispatch<React.SetStateAction<getLeadIntegrationTypeDataResponse>>,

    addIntegrationPopup: boolean,
    setAddIntegrationPopup: React.Dispatch<React.SetStateAction<boolean>>,
    selectFieldData: chatbotLeadFieldDataModel[] | null,
    setSelectFieldData: React.Dispatch<React.SetStateAction<chatbotLeadFieldDataModel[] | null>>,

    allowedFieldsData: chatbotLeadFieldDataModel[] | null,
    setAllowedFieldsData: React.Dispatch<React.SetStateAction<chatbotLeadFieldDataModel[] | null>>,

    allowedFieldsSlackData: chatbotLeadFieldDataModel[] | null,
    setAllowedFieldsSlackData: React.Dispatch<React.SetStateAction<chatbotLeadFieldDataModel[] | null>>,

    emailConfigPayload: emailConfigModel,
    setEmailConfigPayload: React.Dispatch<React.SetStateAction<emailConfigModel>>,
    tempEmailConfigPayload: emailConfigModel,
    setTempEmailConfigPayload: React.Dispatch<React.SetStateAction<emailConfigModel>>,

    slackConfigPayload: slackConfigModel,
    setSlackConfigPayload: React.Dispatch<React.SetStateAction<slackConfigModel>>,
    tempSlackConfigPayload: slackConfigModel,
    setTempSlackConfigPayload: React.Dispatch<React.SetStateAction<slackConfigModel>>,

    stepContainer: Number,
    setStepContainer: React.Dispatch<React.SetStateAction<Number>>,

    editIntegrationData: boolean,
    setEditIntegrationData: React.Dispatch<React.SetStateAction<boolean>>,

    channelIntegrationData: globalSlackIntegration | null,
    setChannelIntegrationData: React.Dispatch<React.SetStateAction<globalSlackIntegration | null>>,

    deleteIntegrationPayload: deleteIntegrationPayloadModel | null,
    setDeleteIntegrationPayload: React.Dispatch<React.SetStateAction<deleteIntegrationPayloadModel | null>>,

    selectedTabField: string,
    setSelectedTabField: React.Dispatch<React.SetStateAction<string>>

    //functions
    AddNewLeadGeneration: () => void,
    GetLeadGenerationList: (chatbotId: string, leadId?: string) => Promise<void>,
    handleCancelLeadGeneration: (flagLead?: boolean) => void,
    handleChangeLeadGenerationPayload: (chatbot_id: number, field: string, value: string) => void,
    handleChangeLeadFieldData: (field: string, value: string, flagLead?: boolean) => void,
    handleAddFieldDataToGeneration: (fieldData: leadFieldDataModel, flagLead?: boolean) => void,
    handleDeleteFieldData: (fieldData: leadFieldDataModel) => void,
    GetGenericFieldDataType: () => Promise<any>,
    GetLeadIntegrationDataType: () => Promise<any>,
    handleCancelSelectLeadIntegration: (flagLead?: boolean) => void
}

const LeadIntegrationContext = createContext<LeadIntegrationContextPayload | undefined>(undefined);

const LeadIntegrationProvider = ({ children }: { children: ReactNode }) => {
    const [leadGenerationList, setLeadGenerationList] = useState<createLeadGenerationResponseModel | null>(null);

    const [createLeadGenerationPayload, setCreateLeadGenerationPayload] = useState<createLeadGenerationPayloadModel>(initialLeadGeneraionData);
    const [tempLeadGenerationPayload, setTempLeadGenerationPayload] = useState<createLeadGenerationPayloadModel>(initialLeadGeneraionData);
    const [createLeadGenerationResonse, setCreateLeadGenerationResponse] = useState<createLeadGenerationResponseModel | null>(null);

    const [addNewLeadField, setAddNewLeadField] = useState<boolean>(false);
    const [flagUpdateField, setFlagUpdateField] = useState<boolean>(false);
    const [flagDeleteFieldData, setFlagDeleteFieldData] = useState<boolean>(false);

    const [leadGenerationFieldPayload, setLeadGenerationFieldPayload] = useState<leadFieldDataModel>(initialLeadGenerationField);
    const [flagDetailsAdd, setFlagDetailsAdd] = useState(false);
    const [genericFieldDataType, setGenericFieldDataType] = useState<getGenericFieldTypeDataResponseModel[] | null>(null)
    const [leadIntegrationDataType, setLeadIntegrationDataType] = useState<getLeadIntegrationTypeDataResponse[] | null>(null)

    const [selectedIntegrationType, setSelectedIntegrationType] = useState<getLeadIntegrationTypeDataResponse>(initialIntegrationPayload)

    const [addIntegrationPopup, setAddIntegrationPopup] = useState(false);

    const [selectFieldData, setSelectFieldData] = useState<chatbotLeadFieldDataModel[] | null>(null)
    const [allowedFieldsData, setAllowedFieldsData] = useState<chatbotLeadFieldDataModel[] | null>(null);
    const [allowedFieldsSlackData, setAllowedFieldsSlackData] = useState<chatbotLeadFieldDataModel[] | null>(null);

    const [emailConfigPayload, setEmailConfigPayload] = useState<emailConfigModel>(initialEmailConfigPayload);
    const [tempEmailConfigPayload, setTempEmailConfigPayload] = useState<emailConfigModel>(initialEmailConfigPayload);
    const [slackConfigPayload, setSlackConfigPayload] = useState<slackConfigModel>(initialSlackConfigPayload);
    const [tempSlackConfigPayload, setTempSlackConfigPayload] = useState<slackConfigModel>(initialSlackConfigPayload);

    const [stepContainer, setStepContainer] = useState<Number>(1);
    const [editIntegrationData, setEditIntegrationData] = useState(true);
    const [channelIntegrationData, setChannelIntegrationData] = useState<globalSlackIntegration | null>(null)

    const [deleteIntegrationPayload, setDeleteIntegrationPayload] = useState<deleteIntegrationPayloadModel | null>(null)
    const [selectedTabField, setSelectedTabField] = useState<string>("Lead Details");

    //functions    
    const AddNewLeadGeneration = () => {
        setStepContainer(1)
        setCreateLeadGenerationPayload(initialLeadGeneraionData)
        setTempLeadGenerationPayload(initialLeadGeneraionData)
        setEmailConfigPayload(initialEmailConfigPayload)
        setSlackConfigPayload(initialSlackConfigPayload)
        setSelectedIntegrationType(initialIntegrationPayload)
        setAddIntegrationPopup(false);
    }

    const GetLeadGenerationList = async (chatbotId: string, leadId?: string) => {
        setStepContainer(1);
        setSelectedTabField("Lead Details")
        const response = await agent.getLeadGenerationData.get(chatbotId || "", leadId || "");
        if (response.status) {
            if(response.data && response.data.length > 0) {
                setLeadGenerationList(response.data[0])
                if(leadId) {
                    setCreateLeadGenerationPayload({...createLeadGenerationPayload,
                        chatbot_id: Number(chatbotId),
                        chatbot_lead_id: response.data[0].chatbot_lead_data[0].chatbot_lead_id,
                        chatbot_lead_name: response.data[0].chatbot_lead_data[0].chatbot_lead_name,
                        chatbot_lead_description: response.data[0].chatbot_lead_data[0].chatbot_lead_description,
                        chatbot_lead_field_data: response.data[0].chatbot_lead_data[0].chatbot_lead_field_data
                    })
                    setTempLeadGenerationPayload({...tempLeadGenerationPayload,
                        chatbot_id: Number(chatbotId),
                        chatbot_lead_id: response.data[0].chatbot_lead_data[0].chatbot_lead_id,
                        chatbot_lead_name: response.data[0].chatbot_lead_data[0].chatbot_lead_name,
                        chatbot_lead_description: response.data[0].chatbot_lead_data[0].chatbot_lead_description,
                        chatbot_lead_field_data: response.data[0].chatbot_lead_data[0].chatbot_lead_field_data
                    })
                    setSelectFieldData(response.data[0].chatbot_lead_data[0].chatbot_lead_field_data)
                    setAllowedFieldsData((response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data && response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.length > 0 && response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "email")) ?
                        response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "email").allowed_field_data : response.data[0].chatbot_lead_data[0].chatbot_lead_field_data)
    
                    setAllowedFieldsSlackData((response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data && response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.length > 0 && response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "slack")) ?
                        response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "slack").allowed_field_data : response.data[0].chatbot_lead_data[0].chatbot_lead_field_data)
    
                    if(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "email")) {
                        setEmailConfigPayload(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "email").chatbot_lead_integration_additional_data);
                        setTempEmailConfigPayload(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "email").chatbot_lead_integration_additional_data);
                    }
                    else {
                        setEmailConfigPayload(initialEmailConfigPayload)
                        setTempEmailConfigPayload(initialEmailConfigPayload)
                    }
                    if(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "slack")) {
                        setSlackConfigPayload(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "slack").chatbot_lead_integration_additional_data);
                        setTempSlackConfigPayload(response.data[0].chatbot_lead_data[0].chatbot_lead_integration_data.find((data: any) => data.chatbot_lead_integration_type_name.toLowerCase() === "slack").chatbot_lead_integration_additional_data);
                    }
                    else {
                        setSlackConfigPayload(initialSlackConfigPayload)
                        setTempSlackConfigPayload(initialSlackConfigPayload)
                    }
                }
                else {
                    setEmailConfigPayload(initialEmailConfigPayload)
                    setSlackConfigPayload(initialSlackConfigPayload)
                    setSelectedIntegrationType(initialIntegrationPayload)
                }
                setFlagDetailsAdd(false)
                setAddIntegrationPopup(false)
            }
            else {
                setLeadGenerationList(null)
            }
        }
        else {
            setLeadGenerationList(null)
        }
    }

    const handleCancelLeadGeneration = (flagLead?: boolean) => {
        if(flagLead) {
            setCreateLeadGenerationPayload(tempLeadGenerationPayload);
        }
        else {
            setCreateLeadGenerationPayload(initialLeadGeneraionData);
            setTempLeadGenerationPayload(initialLeadGeneraionData)
        }     
    }

    const handleChangeLeadGenerationPayload = (chatbot_id: number, field: string, value: string) => {
        setCreateLeadGenerationPayload((prevState) => ({
            ...prevState,
            [field]: value,
            chatbot_id: chatbot_id,
        }));
    };

    const handleChangeLeadFieldData = (field: string, value: string, flagLead?: boolean) => {
        if(flagLead) {
            setLeadGenerationFieldPayload((prevState) => ({
                ...prevState,
                [field]: value,
            }));
        }
        else {
            setLeadGenerationFieldPayload((prevState) => ({
                ...prevState,
                [field]: value,
                field_id: flagUpdateField ? prevState.field_id : createLeadGenerationPayload.chatbot_lead_field_data.length
            }));
        }    
    }

    const handleAddFieldDataToGeneration = (fieldData: leadFieldDataModel, flagLead?: boolean) => {
        if(flagLead) {
            setCreateLeadGenerationPayload((prevState) => ({
                ...prevState,
                chatbot_lead_field_data: prevState.chatbot_lead_field_data.some((field) => field.chatbot_lead_field_id === fieldData.chatbot_lead_field_id)
                ? prevState.chatbot_lead_field_data.map((field) => (field.chatbot_lead_field_id === fieldData.chatbot_lead_field_id ? fieldData : field))
                : [...prevState.chatbot_lead_field_data, fieldData],
            }));
        }
        else {
            setCreateLeadGenerationPayload((prevState) => ({
                ...prevState,
                chatbot_lead_field_data: prevState.chatbot_lead_field_data.some((field) => field.field_id === fieldData.field_id)
                ? prevState.chatbot_lead_field_data.map((field) => (field.field_id === fieldData.field_id ? fieldData : field))
                : [...prevState.chatbot_lead_field_data, fieldData],
            }));
        }
    }

    const handleDeleteFieldData = (fieldData: leadFieldDataModel) => {
        setCreateLeadGenerationPayload((prevState) => ({
            ...prevState,
            chatbot_lead_field_data: [...prevState.chatbot_lead_field_data.filter((data) => data.chatbot_lead_field_name !== fieldData.chatbot_lead_field_name)],
        }));
        setFlagDeleteFieldData(false);
        setLeadGenerationFieldPayload(initialLeadGenerationField)
    }

    const GetGenericFieldDataType = async (): Promise<any> => {
        const response = await agent.getGenericFieldTypeData.get();
        if (response.status) {
            setGenericFieldDataType(response.data)
        }
        else {
            setGenericFieldDataType(null)
        }
        return response;
    }

    const GetLeadIntegrationDataType = async (): Promise<any> => {
        const response = await agent.getLeadIntegrationTypeData.get();
        if (response.status) {
            setLeadIntegrationDataType(response.data)
        }
        else {
            setLeadIntegrationDataType(leadIntegrationDataTypeStatic)
        }
        return response;
    }

    const handleCancelSelectLeadIntegration = (flagLead?: boolean) => {
        setStepContainer(1)
        if(flagLead) {
            
        }
        else {
            setSelectedIntegrationType(initialIntegrationPayload)
        }
    }

    const payload: LeadIntegrationContextPayload = {

        leadGenerationList,
        setLeadGenerationList,

        createLeadGenerationPayload,
        setCreateLeadGenerationPayload,
        tempLeadGenerationPayload,
        setTempLeadGenerationPayload,
        createLeadGenerationResonse,
        setCreateLeadGenerationResponse,

        addNewLeadField,
        setAddNewLeadField,
        flagUpdateField,
        setFlagUpdateField,
        flagDeleteFieldData,
        setFlagDeleteFieldData,

        leadGenerationFieldPayload,
        setLeadGenerationFieldPayload,
        flagDetailsAdd,
        setFlagDetailsAdd,
        genericFieldDataType,
        setGenericFieldDataType,
        leadIntegrationDataType,
        setLeadIntegrationDataType,

        addIntegrationPopup,
        setAddIntegrationPopup,
        selectFieldData,
        setSelectFieldData,

        allowedFieldsData,
        setAllowedFieldsData,
        allowedFieldsSlackData,
        setAllowedFieldsSlackData,

        stepContainer,
        setStepContainer,

        editIntegrationData,
        setEditIntegrationData,

        selectedIntegrationType,
        setSelectedIntegrationType,

        AddNewLeadGeneration,
        GetLeadGenerationList,
        handleCancelLeadGeneration,
        handleChangeLeadGenerationPayload,
        handleChangeLeadFieldData,
        handleAddFieldDataToGeneration,
        handleDeleteFieldData,

        GetGenericFieldDataType,
        GetLeadIntegrationDataType,
        handleCancelSelectLeadIntegration,

        emailConfigPayload,
        setEmailConfigPayload,
        tempEmailConfigPayload,
        setTempEmailConfigPayload,
        slackConfigPayload,
        setSlackConfigPayload,
        tempSlackConfigPayload,
        setTempSlackConfigPayload,
        channelIntegrationData,
        setChannelIntegrationData,
        deleteIntegrationPayload,
        setDeleteIntegrationPayload,
        selectedTabField,
        setSelectedTabField
    };

    return (
        <LeadIntegrationContext.Provider value={payload}>
            {children}
        </LeadIntegrationContext.Provider>
    );
};

export { LeadIntegrationContext, LeadIntegrationProvider };
