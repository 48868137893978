import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "../../../assets/styles/componentStyles/header.module.scss";
import GlobalButton from "../../utility/globalButton/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import agent from "../../../services/api";
import { bottomNavigationActionClasses, Breadcrumbs, Collapse, Grid, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import writeWizLogo from "../../../assets/writewizLogo.svg";
import globalStyles from "../../../assets/styles/global.module.scss"
import { NotifyExtentionContext } from "../../../context/notifyExtentionContext";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import wIcon from './../../../assets/shortWritewizLogo.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ChatbotContext, ChatbotContextPayload } from "../../../context/chatbotContext";
import timezoneImg from "../../../assets/images/chatbot/timezone.png";
import { IntegrationContext, IntegrationContextPayload } from "../../../context/integrationContext";
import TimeZoneComponent from "../../utility/timeZoneComponent";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CallAnalysisContext, CallAnalysisContextPayload } from "../../../context/callAnalysisContext";
import { ContactContext, ContactContextPayload } from "../../../context/contactContext";
import IconsColor from "../../utility/iconsColor";
import AddIcon from '@mui/icons-material/Add';
import { WhatsappCampaignContext, WhatsappCampaignContextPayload } from "../../../context/whatsappCampaignContext";

interface menuItem {
  menuName: string;
  menuKey: string;
  navigationLink: string;
  imgSrc: React.ReactElement;
  whiteImgSrc: React.ReactElement;
  coloredImgSrc: React.ReactElement;
  flagDisabled?: boolean
}
interface Props {
  flagShowLogo: boolean,
  menuItems?: menuItem[]
}

export default function Header(props: Props) {

  // const ITEM_HEIGHT = 15;
  let urlToNavigateTo = encodeURIComponent(window.location.href)
  const navigate = useNavigate();
  const { userDetail, setFlagLoading, setUserDetail, setError, error, flagLoading, resetOrganizationContext, setFlagOnboardingSkip } = React.useContext(
    UserContext
  ) as UserContextPayload;
  const { campaignContactListData, setCampaignContactData, setFilteredCampaignContactData, campaignContactData, setFlagAddNewListDialog, setFlagCreateNewContactDialog, flagNewFieldDialog, setFlagNewFieldDialog } = React.useContext(ContactContext) as ContactContextPayload;
  const { setSelectedWhatsAppCampaign } = React.useContext(WhatsappCampaignContext) as WhatsappCampaignContextPayload;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const [showTimeZone, setShowTimeZone] = React.useState(false)
  const { SendPostMessageToExtention } = React.useContext(
    NotifyExtentionContext
  ) as any;

  const chatbot_id = searchParams.get('chatbot_id')
  const id = searchParams.get('id')
  const { chatBotDetails } = React.useContext(ChatbotContext) as ChatbotContextPayload
  const { integrationList } = React.useContext(IntegrationContext) as IntegrationContextPayload
  const { setFlagAddFilter } = React.useContext(CallAnalysisContext) as CallAnalysisContextPayload

  const [pendingUpdate, setPendingUpdate] = React.useState<NodeJS.Timeout | null>(null);
  const getCurrentActiveMenuName = () => {
    if (window.location.pathname.toLowerCase() == "/chatbot" || window.location.pathname.toLowerCase() == "/") {
      return "AI Chatbot"
    }
    else if (window.location.pathname.toLowerCase() == "/feedback") {
      return "Feedback"
    }
    else if (window.location.pathname.toLowerCase() == "/insights") {
      return "AI Insights"
    }
    else if (window.location.pathname.toLowerCase() == "/contacts") {
      const list_id = searchParams.get('contact_list_id');
      let headerText = "Contacts";
      if (list_id) {
        headerText = campaignContactListData && campaignContactListData?.find((list) => list.contact_list_id === Number(list_id))?.contact_list_name || "Contacts";
      } else {
        if (campaignContactListData && campaignContactListData.length > 0) headerText = "List";
      }
      return headerText
    }
    else if (window.location.pathname.toLowerCase() == "/createchatbot") {
      return "Create Chatbot"
    }
    else if (window.location.pathname.toLowerCase() == "/whatsappcampaign") {
      return "WhatsApp Campaign"
    }
    else if (window.location.pathname.toLowerCase() === "/whatsappcampaign/createnewcampaign") {
      return "Create New Campaign"
    }
    else if (window.location.pathname.toLowerCase() == "/settings-accountdetails" || window.location.pathname.toLowerCase() == "/settings-plansandbilling") {
      return "Settings"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/basicdetail" || window.location.pathname.toLowerCase() == "/chatbot/viewchatbot") {
      return "Basic Details"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/integration") {
      return "Integrations"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/createintegration") {
      return "Create Integration"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/whatsapp") {
      return "WhatsApp"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/facebook") {
      return "FB Messenger"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/instagram") {
      return "Instagram"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/website") {
      return "Website"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/channels/slack") {
      return "Slack"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/trychatbot") {
      return "Try Chatbot"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/inbox" || window.location.pathname.toLowerCase() == "/inbox") {
      return "Inbox"
    }
    else if (window.location.pathname.toLowerCase() == "/chatbot/viewchatbot/persistentmenu") {
      return "Persistent Menu"
    }
    else if (window.location.pathname.toLowerCase().includes("/chatbot/viewchatbot/leadgeneration")) {
      return "Lead Generation"
    }
    else if (window.location.pathname.toLowerCase() === "/chatbot/viewchatbot/products") {
      return "Products"
    }
    else if (window.location.pathname.toLowerCase() == "/channels") {
      return "Channels"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp") {
      return "Account Setup"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/phonenumbers") {
      return "Phone Numbers"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/templatemessages") {
      return "Template Messages"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/whatsapp/templateinfo") {
      return "New Template Message"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook") {
      return "Account Setup"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/pages") {
      return "Pages"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/templateinfo" || window.location.pathname.toLowerCase() === "/channels/viewintegration/facebook/templatemessages") {
      return "Template Messages"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/instagram") {
      return "Account Setup"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack") {
      return "Account Setup"
    }
    else if (window.location.pathname.toLowerCase() === "/channels/viewintegration/slack/channels") {
      return "Slack Channels"
    }
    else if (window.location.pathname.toLowerCase() === "/callanalysis") {
      return "Dashboard"
    }
    else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords") {
      return "Call Records"
    }
    else if (window.location.pathname.toLowerCase() === "/callanalysis/callrecords/newcallrecords") {
      return "New Call Records"
    }
    else if (window.location.pathname.toLowerCase() === "/organization") {
      return "Workspace"
    }
    else {
      return "AI Chatbot"
    }
  }
  const getCurrentActiveMenuOnclickFun = () => {
    if (window.location.pathname.toLowerCase() == "/chatbot" || window.location.pathname.toLowerCase() == "/chatbot/" || window.location.pathname == "/") {
      return navigate("/chatbot")
    }
    else if (window.location.pathname.toLowerCase() == "/createchatbot") {
      return navigate("/createChatbot")
    }
    else if (window.location.pathname.toLowerCase() == "/inbox") {
      return navigate("/inbox")
    }
    else if (window.location.pathname.toLowerCase() == "/channels") {
      return navigate("/channels")
    }
    else if (window.location.pathname.toLowerCase() == "/settings-accountDetails" || window.location.pathname.toLowerCase() == "/settings-plansAndBilling") {
      return "Settings"
    }
    else {
      return navigate("/")
    }
  }



  React.useEffect(() => {
    // Cleanup the pending update when the component unmounts
    return () => {
      if (pendingUpdate !== null) {
        clearTimeout(pendingUpdate);
      }
    };
  }, [pendingUpdate]);

  const [headerListMenu, setHeaderListMenu] = React.useState<null | HTMLElement>(null);
  const [headerStaticListMenu, setHeaderStaticListMenu] = React.useState<null | HTMLElement>(null);
  const [openList, setOpenList] = React.useState(true);

  // Boolean(openSection1Anchor);
  const open = Boolean(headerListMenu);
  const openStaticMenu = Boolean(headerStaticListMenu)


  const handleClickHeaderListMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderListMenu(event.currentTarget);
  };

  const handleCloseHeaderListMenu = () => {
    setHeaderListMenu(null);
  };

  const handleClick = () => {
    setOpenList(!openList);
  };

  const handleClickStatictMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderStaticListMenu(event.currentTarget);
  };

  const handleCloseStaticMenuList = () => {
    setHeaderStaticListMenu(null);
  };

  const recordsMenuItems = [
    { label: 'View Dashboard', icon: <IconsColor iconName="callanalysisdashboard" width="16" height="16" fillColor="#000" />, action: (e: any) => navigate("/callanalysis") },
    { label: 'New Record', icon: <AddIcon sx={{ padding: "0", color: "#000", fontSize: "24px", marginLeft: "-4px" }} />, action: (e: any) => navigate("/callanalysis/callrecords/newcallrecords") },
  ];

  const dashboardMenuItems = [
    { label: 'View Records', icon: <IconsColor iconName="callAnalysis" width="16" height="16" fillColor="#000" />, action: (e: any) => navigate("/callanalysis/callrecords") },
  ]

  const contactMenuItem = [
    {
      label: 'Manage List',
      action: (e: any) => {
        navigate("/contacts");
        // setCampaignContactData([]); 
        setCampaignContactData(null);
        // setFilteredCampaignContactData([]);
        setFilteredCampaignContactData(null);
      }
    },
    {
      label: 'Add New Field',
      action: (e: any) => {
        setFlagNewFieldDialog(true);
      }
    },
    {
      label: 'Add New Contact',
      action: (e: any) => {
        setFlagCreateNewContactDialog(true);
      }
    }
  ]

  const listMenuItems = [
    {
      label: 'Add New List',
      action: (e: any) => {
        setFlagAddNewListDialog(true);
      }
    }
  ]

  return (
    <Box sx={{
      flexGrow: 1,
    }}
    >
      <AppBar
        component="nav"
        sx={{
          paddingLeft: { xs: "45px", sm: "0px" },
          position: { xs: "", sm: "static" },
          background: { xs: '#fbf4ff', sm: "#fff !important" },
          // boxShadow: "none",
          boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)"
        }}
      >
        <Toolbar className={styles.headerToolBar}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item className={styles.headerWrapper}>
              <Grid container sx={{ flexWrap: "nowrap" }}>
                {/* logo */}
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "#6A097D" }}
                  >
                    {
                      props.flagShowLogo &&
                      <img src={writeWizLogo} style={{ width: "128px", height: "22px" }} className={`${globalStyles.clickable} ${styles.writewizLogo}`} onClick={() => { navigate("/dashboard") }} />
                    }
                  </Typography>
                </Grid>

                {/* current active menu name */}
                <Grid item className={styles.documentNameAreaContainer}>
                  <Grid container justifyContent={chatbot_id || id ? "center" : "space-between"} alignItems={"start"} direction={"column"} gap={"4px"}>
                    <Grid item xs={6} sx={{ cursor: "pointer", display: { xs: "block", sm: "none" } }}>
                      {getCurrentActiveMenuName()}
                    </Grid>
                    {(chatbot_id || id) &&
                      <Grid item xs={6} sx={{ cursor: "pointer", fontSize: "12px", fontWeight: "500", display: { xs: "block", sm: "none" } }}>
                        <Breadcrumbs className={styles.breadcrumbsStyle}>
                          <Link
                            underline="hover"
                            onClick={() => { navigate(`/chatbot`) }}
                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                          >
                            {"AI Chatbot"}
                          </Link>
                          <Link
                            underline="hover"
                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}
                          >
                            {chatbot_id ? chatBotDetails?.chatbot_name : integrationList?.find((integration) => integration.meta_integration_id == Number(id))?.meta_integration_whatsapp_data?.waba_name || "Facebook Messenger"}
                          </Link>
                        </Breadcrumbs>
                      </Grid>
                    }
                    {
                      (getCurrentActiveMenuName() == "Inbox" || getCurrentActiveMenuName() == "Feedback") &&
                      <Grid item sx={{ position: "absolute", right: "20px" }}>
                        <Box
                          sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                          onClick={(event) => { setShowTimeZone(true); setAnchorEl(event.currentTarget) }}
                          aria-controls={showTimeZone ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={showTimeZone ? 'true' : undefined}
                        >
                          <img src={timezoneImg} alt="timezone" style={{ width: "25px", height: "25px" }} />
                        </Box>
                        {showTimeZone && <TimeZoneComponent showTimeZone={showTimeZone} setShowTimeZone={setShowTimeZone} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
                      </Grid>
                    }
                    {getCurrentActiveMenuName() == "WhatsApp Campaign" &&
                      <Grid item sx={{ position: "absolute", right: "20px", bottom: "10px" }}>
                        <IconButton
                          className={`${globalStyles.cmpGlobalMobileAddIconDark} ${styles.addChatbotIcon}`}
                          onClick={() => { navigate('/whatsappcampaign/createnewcampaign'); setSelectedWhatsAppCampaign(null); }}
                        >
                          <AddIcon style={{ height: "24px", width: 'auto' }} />
                        </IconButton>
                      </Grid>
                    }
                    {(getCurrentActiveMenuName() == "Dashboard" || getCurrentActiveMenuName() == "Call Records" || getCurrentActiveMenuName() == "New Call Records") &&
                      <Grid item sx={{ position: "absolute", right: "20px", bottom: "11px" }}>
                        <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="10px" justifyContent={{ lg: "end" }}>
                          {
                            (getCurrentActiveMenuName() == "Dashboard" || getCurrentActiveMenuName() == "Call Records") &&
                            <Grid item>
                              <IconButton
                                className={`${globalStyles.cmpGlobalMobileAddIcon} ${styles.addFilterIcon}`}
                                onClick={() => { setFlagAddFilter(true) }}
                              >
                                <FilterAltOutlinedIcon style={{ height: "24px", width: 'auto' }} />
                              </IconButton>
                            </Grid>
                          }
                          <Grid item>
                            <Button
                              id="basic-button"
                              className={openStaticMenu ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                              aria-controls={openStaticMenu ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openStaticMenu ? 'true' : undefined}
                              disableRipple
                              onClick={(event) => { event.stopPropagation(); handleClickStatictMenuList(event) }}
                            >
                              <MoreVertIcon />
                            </Button>
                            <Menu
                              id="basic-menu"
                              anchorEl={headerStaticListMenu}
                              open={openStaticMenu}
                              className={globalStyles.globalMenuStyle}
                              onClose={handleCloseStaticMenuList}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              sx={{
                                "& .MuiMenuItem-root": { margin: "0 !important", minHeight: "0 !important" }
                              }}
                            >
                              {window.location.pathname !== "/callanalysis/callrecords" ?
                                dashboardMenuItems.map((item: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    onClick={(e) => { e.stopPropagation(); item.action(); handleCloseStaticMenuList(); }}
                                    sx={{
                                      // borderBottom: "1px solid #EAEAEA",
                                      paddingBlock: "8px !important"
                                    }}
                                  >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.label}</span>
                                  </MenuItem>
                                ))
                                :
                                recordsMenuItems.map((item: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    onClick={(e) => { e.stopPropagation(); item.action(); handleCloseStaticMenuList(); }}
                                    sx={{
                                      // borderBottom: "1px solid #EAEAEA",
                                      paddingBlock: "8px !important"
                                    }}
                                  >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.label}</span>
                                  </MenuItem>
                                ))
                              }
                            </Menu>
                          </Grid>
                        </Grid >
                      </Grid >
                    }
                    {
                      (window.location.pathname.toLowerCase() == "/contacts" && campaignContactListData && campaignContactListData.length > 0) &&
                      <Grid item sx={{ position: "absolute", right: "20px", bottom: "11px" }}>
                        <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="10px" justifyContent={{ lg: "end" }}>

                          <Grid item>
                            <Button
                              id="basic-button"
                              className={openStaticMenu ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                              aria-controls={openStaticMenu ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openStaticMenu ? 'true' : undefined}
                              disableRipple
                              style={{ marginBottom: "5px" }}
                              onClick={(event) => { event.stopPropagation(); handleClickStatictMenuList(event) }}
                            >
                              <MoreVertIcon />
                            </Button>
                            <Menu
                              id="basic-menu"
                              anchorEl={headerStaticListMenu}
                              open={openStaticMenu}
                              className={globalStyles.globalMenuStyle}
                              onClose={handleCloseStaticMenuList}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              sx={{
                                "& .MuiMenuItem-root": { margin: "0 !important", minHeight: "0 !important" }
                              }}
                            >
                              {!searchParams.get('contact_list_id') ?
                                listMenuItems.map((item: any, ind: number) => (
                                  <MenuItem
                                    key={ind}
                                    onClick={(e) => { e.stopPropagation(); item.action(); handleCloseStaticMenuList(); }}
                                    sx={{ paddingBlock: "10px !important" }}
                                  >
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.label}</span>
                                  </MenuItem>
                                ))
                                :
                                contactMenuItem.map((item: any, ind: number) => {
                                  if (item.label === "Add New Contact") {
                                    if (campaignContactData && campaignContactData.length === 0) {
                                      return <></>;
                                    }
                                  }
                                  return <MenuItem
                                    key={ind}
                                    onClick={(e) => { e.stopPropagation(); item.action(); handleCloseStaticMenuList(); }}
                                    sx={{ paddingBlock: "10px !important" }}
                                  >
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.label}</span>
                                  </MenuItem>
                                })
                              }
                            </Menu>
                          </Grid>

                          {/* {searchParams.get('contact_list_id') &&
                            <Grid item>
                              <GlobalButton
                                className='primaryButtonStyle'
                                buttonText={"Manage List"}
                                onClick={() => {
                                  navigate("/contacts");
                                  setCampaignContactData(null);
                                  setFilteredCampaignContactData(null);
                                }}
                                sx={{ width: "130px" }}
                              />
                            </Grid>
                          }
                          <Grid item>
                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} gap="15px" justifyContent={{ lg: "end" }}>
                              <Grid item>
                                {!searchParams.get('contact_list_id') ?
                                  <IconButton
                                    sx={{ display: "flex", background: "white", borderRadius: "5px", boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)", padding: "6px 8px" }}
                                    onClick={() => { setFlagAddNewListDialog(true); }}
                                  >
                                    <AddIcon sx={{ color: "#6a097d" }} />
                                  </IconButton>
                                  :
                                  (campaignContactData && campaignContactData.length > 0) &&
                                  <IconButton
                                    sx={{ display: "flex", background: "white", borderRadius: "5px", boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)", padding: "6px 8px" }}
                                    onClick={() => { setFlagCreateNewContactDialog(true); }}
                                  >
                                    <AddIcon sx={{ color: "#6a097d" }} />
                                  </IconButton>
                                }
                              </Grid>
                            </Grid>
                          </Grid> */}

                        </Grid>
                      </Grid>
                    }
                  </Grid >
                </Grid >
              </Grid >
            </Grid >

            <Grid item className={styles.creditWrapper}>
              <Grid container>
                {/* logout/signup buttons for above 650px */}
                <Grid item className={styles.headerButtonWrapperSM}>
                  {userDetail.flag_user_anonymous && (
                    <GlobalButton
                      className={"primaryButtonStyle"}
                      buttonText="Sign Up"
                      onClick={() => {
                        navigate("/login?redirect=" + urlToNavigateTo);
                      }}
                    />
                  )}


                  {/* logout button */}
                  <Grid item className={styles.exploreMenuGrid}>
                    <Button
                      id="basic-button"
                      className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickHeaderListMenu}
                    >
                      <Grid className={styles.moreVerticalIcon}><MoreVertIcon /></Grid>
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={headerListMenu}
                      open={open}
                      className={styles.moreVerticalIconMenu}
                      onClose={handleCloseHeaderListMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={styles.menuItemList}
                      >

                        <ListItemButton className={`${styles.listItemButtonComponent} ${styles.emailListComponent}`}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={userDetail.flag_user_anonymous ? "Guest User" : userDetail.user_email} />
                        </ListItemButton>

                        <ListItemButton onClick={handleClick} className={`${styles.listItemButtonComponent} ${styles.exploreMenuButtonWidth}`}>
                          <ListItemIcon>
                            <img src={wIcon} alt="W" height={"22"} width={"22"} />
                          </ListItemIcon>
                          <ListItemText primary="Explore" />
                          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>

                        <Collapse in={openList} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className={styles.listItemButtonComponent}>
                            {props.menuItems?.map((menuItem, index) => {
                              return (
                                <ListItemButton className={styles.subListMenuItem} key={index} onClick={() => navigate(menuItem.navigationLink)}>
                                  <ListItemIcon>
                                    {menuItem.imgSrc}
                                  </ListItemIcon>
                                  <ListItemText primary={menuItem.menuName} />
                                </ListItemButton>
                              )
                            })}

                          </List>
                        </Collapse>

                        {userDetail.flag_user_anonymous === false && (
                          <ListItemButton className={styles.listItemButtonComponent}
                            onClick={async () => {
                              setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                              const response = await agent.LogoutAPI.post();
                              if (response.status) {
                                setFlagOnboardingSkip(null)
                                resetOrganizationContext()
                                SendPostMessageToExtention(null)
                                setUserDetail({ ...userDetail, flagUser: false });
                                navigate("/login")
                              } else {
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                              }
                              setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                            }}
                          >
                            <ListItemIcon>
                              <PowerSettingsNewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        )}

                      </List>
                    </Menu>
                  </Grid>
                </Grid>


                {/* logout/signup buttons for below 650px and above 599 */}
                <Grid item className={styles.headerButtonWrapperXS}>
                  {userDetail.flag_user_anonymous && (
                    <GlobalButton
                      className={"primaryButtonStyleIcon"}
                      buttonText={<AppRegistrationIcon />}
                      onClick={() => {
                        navigate("/login?redirect=" + urlToNavigateTo);
                      }}
                    />
                  )}

                  <Grid item className={styles.expandMoreGridMobile} mx={1}>
                    <Button
                      id="basic-button"
                      className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickHeaderListMenu}
                    >
                      <Grid className={styles.moreVerticalIcon}><MoreVertIcon /></Grid>
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={headerListMenu}
                      open={open}
                      className={styles.moreVerticalIconMenu}
                      onClose={handleCloseHeaderListMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={styles.menuItemList}
                      >

                        <ListItemButton className={styles.listItemButtonComponent}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={userDetail.flag_user_anonymous ? "Guest User" : userDetail.user_email} />
                        </ListItemButton>

                        <ListItemButton onClick={handleClick} className={`${styles.listItemButtonComponent} ${styles.exploreMenuButtonWidth}`}>
                          <ListItemIcon>
                            <img src={wIcon} alt="W" height={"22"} width={"22"} />
                          </ListItemIcon>
                          <ListItemText primary="Explore" />
                          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>

                        <Collapse in={openList} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className={styles.listItemButtonComponent}>
                            {props.menuItems?.map((menuItem, index) => {
                              return (
                                <ListItemButton className={styles.subListMenuItem} key={index} onClick={() => navigate(menuItem.navigationLink)}>
                                  <ListItemIcon>
                                    {menuItem.imgSrc}
                                  </ListItemIcon>
                                  <ListItemText primary={menuItem.menuName} />
                                </ListItemButton>
                              )
                            })}

                          </List>
                        </Collapse>

                        {userDetail.flag_user_anonymous === false && (
                          <ListItemButton className={styles.listItemButtonComponent}
                            onClick={async () => {
                              setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                              const response = await agent.LogoutAPI.post();
                              if (response.status) {
                                setFlagOnboardingSkip(null)
                                resetOrganizationContext()
                                SendPostMessageToExtention(null)
                                setUserDetail({ ...userDetail, flagUser: false });
                                navigate("/login")
                              } else {
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                              }
                              setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                            }}
                          >
                            <ListItemIcon>
                              <PowerSettingsNewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        )}

                      </List>
                    </Menu>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>




          </Grid >


        </Toolbar >
      </AppBar >
    </Box >
  );
}
