import { Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/chatbotStyles/chatbotEmbedLinkAndDomains.module.scss'
import copy from '../../../assets/chatbotAssets/Cb-Copy-Icon.png'
import IconsColor from '../../utility/iconsColor';
import { GlobalTable } from '../../utility/globalTable';
import addIcon from '../../../assets/chatbotAssets/Cb-Domain-Add-Icon.svg'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import copyIcon from '../../../assets/copyIcon.png'
import { UserContext, UserContextPayload } from '../../../context/userContext';
import GlobalPopUp from '../../utility/globalPopUp';
import DeleteDocumentDataComponent from '../deleteDocumentDataComponent';
import agent from '../../../services/api';
import globalStyles from "../../../assets/styles/global.module.scss";
const ChatbotEmbedLinkAndDomains = (props: any) => {

    const [copyText, setCopyText] = React.useState("Copy")

    const { handleOpenEditDomainPopup, setFlagDomainPopup, handleOpenDomainPopup } = props
    const { chatBotDetails, UpdateChatbotApi, GetChatbotListApi, setChatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const { setError, error, setFlagLoading, flagLoading } = React.useContext(UserContext) as UserContextPayload;
    const [flagDeleteDomainDataPopup, setFlagDeleteDomainDataPopup] = useState(false)
    const [domainSelected, setDomainSelected] = useState({
        "chatbot_domain_name": "",
        "chatbot_domain_id": null
    })
    const addDomainPopup = () => {
        if (chatBotDetails?.chatbot_flag_edit) {
            handleOpenDomainPopup()
        } else {
        }
    }
    let headerList = [
        {
            name: "Whitelisted Domains",
            alignment: "left",
            flagIcon: false,
            valueKey: "chatbot_domain_name",
            iconKey: "documentIcon",
            subHeaderFlag: true,
            subHeaderText: "Domains where this chatbot would run",
            subHeaderClassName: "subheaderGlobalStyle",
        },
        {
            name: <Grid justifyContent="end" container className={chatBotDetails?.chatbot_flag_edit ? globalStyles.clickable : ""} sx={{ opacity: chatBotDetails?.chatbot_flag_edit ? "" : "0.5" }} onClick={() => { addDomainPopup() }}>
                <img src={addIcon} style={{ width: "17px", height: "auto" }} alt="" />&nbsp;
                <Typography
                    component={'span'}
                    className={styles.cbNewDomainText}
                // onClick={() => { handleOpenDomainPopup() }}
                >New Domain</Typography>
            </Grid>,
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "deleteIcon"
        },
    ]

    const deleteDocumentDataPopupOpen = (event: any, data: any,) => {
        event.stopPropagation()
        setFlagDeleteDomainDataPopup(true)
        setFlagDomainPopup(false)
        setDomainSelected(data)
    }

    const deleteCancelDomainDataPopupOpen = () => {
        setFlagDeleteDomainDataPopup(false)
    }

    const deleteDomain = async (id: number) => {

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });

        let response = await agent.deleteChatbotDomain.delete(id)
        if (response.status) {
            // UpdateChatbotApi(response.data)
            setFlagDeleteDomainDataPopup(false)
            setChatBotDetails(response.data)
            // GetChatbotListApi()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    return (
        <>
            {flagDeleteDomainDataPopup &&
                <GlobalPopUp
                    open={flagDeleteDomainDataPopup}
                    onClose={() => { setFlagDeleteDomainDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDomainDataPopup(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={domainSelected.chatbot_domain_id}
                        deleteCancelDocumentDataPopupOpen={deleteCancelDomainDataPopupOpen}
                        handleDeleteDocument={deleteDomain}
                        deleteDescription="Are you sure you want to delete this domain." />
                </GlobalPopUp>
            }

            <Grid container>
                <Grid item xs={12} mt={2}>
                    <Grid container>

                        <Grid item xs={12} className={styles.cbEmbedIframeItem}>
                            <Grid container>
                                <Grid item xs={12} className={styles.cbEmbedIframeHeadMainItem}>
                                    <Grid container>
                                        <Grid item xs={12} className={styles.cbEmbedIframeHeadItem}>
                                            <Typography component='span' className={styles.cbEmbedCopyFrameHead}>
                                                Iframe
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component='span' className={styles.cbEmbedCopyFrameSubHead}>
                                                To place the chatbot anywhere on your website, include this iframe in your HTML code
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={styles.cbFrameMainItem}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography component={'span'} className={styles.cbEmbedFrameText}>
                                                {chatBotDetails?.chatbot_iframe}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={styles.cbEmbedFrameCopyItem}>
                                            <Grid container sx={{ justifyContent: "end" }}>
                                                <Grid item>
                                                    <CopyToClipboard text={chatBotDetails?.chatbot_iframe ? chatBotDetails?.chatbot_iframe : "error"}>
                                                        <Tooltip
                                                            title={copyText}
                                                            onMouseLeave={() => setCopyText("Copy!")}
                                                            onClick={() => {
                                                                setCopyText("Copied!");
                                                            }}
                                                        >
                                                            <Grid container sx={{ alignItems: "center", cursor: 'pointer' }}>
                                                                <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />&nbsp;
                                                                <Typography
                                                                    component={'span'}
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "500",
                                                                        color: "#6A097D"
                                                                    }}
                                                                >Copy</Typography>
                                                            </Grid>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.cbEmbedWidgetItem}>
                            <Grid container>
                                <Grid item xs={12} className={styles.cbEmbedIframeHeadMainItem}>
                                    <Grid container>
                                        <Grid item xs={12} className={styles.cbEmbedIframeHeadItem}>
                                            <Typography component='span' className={styles.cbEmbedCopyFrameHead}>
                                                Widget
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component='span' className={styles.cbEmbedCopyFrameSubHead}>
                                                To place the chatbot anywhere on your website, include this iframe in your HTML code
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={styles.cbWidgetMainItem}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography component={'span'} className={styles.cbEmbedFrameText}>
                                                {chatBotDetails?.chatbot_script}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={styles.cbEmbedFrameCopyItem}>
                                            <Grid container sx={{ justifyContent: "end" }}>
                                                <Grid item>
                                                    <CopyToClipboard text={chatBotDetails?.chatbot_script ? chatBotDetails?.chatbot_script : "error"}>
                                                        <Tooltip
                                                            title={copyText}
                                                            onMouseLeave={() => setCopyText("Copy!")}
                                                            onClick={() => {
                                                                setCopyText("Copied!");
                                                            }}
                                                        >
                                                            <Grid container sx={{ alignItems: "center", cursor: 'pointer' }}>
                                                                <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />&nbsp;
                                                                <Typography
                                                                    component={'span'}
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "500",
                                                                        color: "#6A097D"
                                                                    }}
                                                                >
                                                                    Copy
                                                                </Typography>
                                                            </Grid>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: "20px 0px" }}>
                            <hr className={styles.cbDividerStyle} />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} className={styles.chatbotEmbedFormItem}>
                                    <GlobalTable
                                        headerList={headerList}
                                        rowData={chatBotDetails != null && chatBotDetails.chatbot_domain}
                                        tableHeight={"22vh"}
                                        onRowClick={(e: any) => { handleOpenEditDomainPopup(e) }}
                                        deleteRowData={deleteDocumentDataPopupOpen}
                                        tableContainerMinHeight={"auto"}
                                        flagPagination
                                    // tableContainerHeight={"calc(100vh - 180px)"}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} className={styles.cbAddDomainItem}>
                                <Grid container className={globalStyles.clickable} onClick={() => { handleOpenDomainPopup() }}>
                                    <img src={addIcon} style={{ width: "17px", height: "auto" }} alt="" />&nbsp;
                                    <Typography
                                        component={'span'}
                                        className={styles.cbNewDomainText}
                                    // onClick={() => { handleOpenDomainPopup() }}
                                    >New Domain</Typography>
                                </Grid>
                            </Grid> */}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotEmbedLinkAndDomains