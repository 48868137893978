import { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Route, Routes as RouterRoutes, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext, UserContextPayload } from "../context/userContext";
import Login from "../components/pages/login";
import TermsAndConditions from "../components/pages/terms&condition";
import PrivacyCookiePolicy from "../components/pages/privacy&Cookie";
import GlobalSnackbar from "../components/utility/globalSnackbar";
import Dashboard from "../components/dashboard/dashboard";
import { NotifyExtentionContext } from "../context/notifyExtentionContext";
import SomethingWentWrong from "../components/pages/somethingWentWrong";
import ChatbotList from "../components/dashboard/chatbot/chatbotList";
import CreateChatbot from "../components/dashboard/chatbot/createChatbot";
import { ViewChatbot } from "../components/dashboard/chatbot/viewChatbot";
import Welcome from "../components/dashboard/welcome";
import axios from "axios";
import GlobalPopUp from "../components/utility/globalPopUp";
import PlanHasExhausted from "../components/popupFiles/planHasExhausted";
import GetStartedPopUpData from "../components/utility/getStartedPopUpData";
import GlobalRoundLoader from "../components/utility/globalRoundLoader";
import Chats from "../components/dashboard/chats/chats";
import OnboardingEnd from "../components/popupFiles/onboardingEnd";
import Dataconnector from "../components/dashboard/dataconnector/dataconnector";
import Createdataconnector from "../components/dashboard/dataconnector/createdataconnector";
import ChatbotDetails from "../components/dashboard/chatbot/chatbotDetails";
import { GenericDataContext, GenericDataContextPayload } from "../context/genericDataContext";
import TryUserChatbot from "../components/dashboard/tryChatbot/tryUserChatbot";
import Integration from "../components/pages/integration";
import ViewIntegration from "../components/dashboard/templateMessages/viewIntegration";
import Template from "../components/dashboard/templateMessages/template";
import TemplateMessages from "../components/dashboard/templateMessages/templateMessages";
import TemplateView from "../components/dashboard/templateMessages/templateView";
import ViewFacebookIntegration from "../components/dashboard/facebookIntegration/viewFaceBookIntegration";
import FacebookIntegration from "../components/dashboard/facebookIntegration/facebookIntegration";
import ViewOrganizationDetails from "../components/dashboard/organization/viewOrganizationDetails";
import { MetaContext, MetaContextPayload } from "../context/metaContext";
import InstagramIntegration from "../components/dashboard/instagramIntegration/instagramIntegration";
import ViewInstagramIntegration from "../components/dashboard/instagramIntegration/viewInstagramIntegration";
import MessageFormat from "../components/dashboard/messageFormats/messageFormat";
import { isAdmin } from "../utils/constants";
import CallAnalysisDetails from "../components/dashboard/callAnalysis/callAnalysisDetails";
import PersistentMenu from "../components/dashboard/persistentMenu/persistentMenu";
import { CallAnalysisContext, CallAnalysisContextPayload } from "../context/callAnalysisContext";
import AppointmentSchedule from "../components/dashboard/chatbot/calendly/appointmentSchedule";
import AuthoriseAccount from "../components/dashboard/chatbot/calendly/global/authoriseAccount";
import CallAnalysisDashboard from "../components/dashboard/callAnalysis/callAnalysisDashboard";
import SelectCalendlyEvent from "../components/dashboard/chatbot/calendly/global/selectCalendlyEvent";
import FieldSetup from "../components/dashboard/chatbot/calendly/global/fieldSetup";
import CallAnalysisGlobal from "../components/dashboard/callAnalysis/callAnalysisGlobal";
import StepContainer from "../components/dashboard/chatbot/calendly/global/stepContainer";
import LeadGenerationGlobal from "../components/dashboard/leadGeneration/leadGenerationGlobal";
import SlackIntegration from "../components/dashboard/chatbot/slack/global/slackIntegration";
import ViewSlackIntegration from "../components/dashboard/chatbot/slack/global/viewSlackIntegration";
import { IntegrationContext, IntegrationContextPayload } from "../context/integrationContext";
import UserAnalytics from "../components/dashboard/userAnalytics/userAnalytics";
import IntegrationList from "../components/dashboard/chatbot/integrations/integrationList";
import ChatbotWhatsAppIntegration from "../components/dashboard/chatbot/whatsAppIntegration/chatbotWhatsAppIntegration";
import ChatbotSlackIntegration from "../components/dashboard/chatbot/integrations/slack/integratedChannels";
import EmailConfig from "../components/dashboard/leadGeneration/emailConfig";
import SlackConfig from "../components/dashboard/leadGeneration/slackConfig";
import OnBoarding from "../components/onBoarding";
import MessageFormatGlobal from "../components/dashboard/messageFormats/messageFormatGlobal";
import StaticMessageFormatForm from "../components/dashboard/messageFormats/MessageFormatForm/staticMessageFormatForm";
import FeedbackGlobal from "../components/dashboard/feedback/feedbackGlobal";
import DataListGlobal from "../components/dashboard/dynamicData/dataListGlobal";
import ContactsGlobal from "../components/dashboard/contacts/contactsGlobal";
import NewCallRecord from "../components/dashboard/callAnalysis/newCallRecord";
import WhatsappCampaignGlobal from "../components/dashboard/whatsappCampaign/whatsappCampaignGlobal";
import WhatsappCampaignList from "../components/dashboard/whatsappCampaign/whatsappCampaignList";
import CreateWhatsappCampaign from "../components/dashboard/whatsappCampaign/createWhatsappCampaign";
import OnBoardingNew from "../components/onBoardingNewDesign";
declare global {
    interface Window {
        googleTranslateElementInit: () => void;
    }
}
const Routes = () => {
    const { getGlobalIntegrationTypeList } = useContext(IntegrationContext) as IntegrationContextPayload
    const { UserMe, setFlagChromeDesktop, flagLoading, error, success, setError, showInfo, setShowInfo, setSuccess, setCreditUsed, userDetail, setFlagLoading, flagOnboardingSkip, setFlagOnboardingSkip, GetStripeCurrencyTypeGenericList, GetPromptLogFieldGenericList, flagShowCreditsExhausted, setFlagShowCreditsExhausted, showLoginPopup, setShowLoginPopup, setLoaderDetails, loaderDetails, setLoaderFilterDetails, getLoaderDetails, setUserDetail, setCountryCode, flagShowScheduleCall, setFlagShowScheduleCall, flagShowOnboardingEndPopup,
        setFlagOnboardingEndPopup, GetOrganizationDetails, organizationDetails, getLanguagesList, customPagesData, GetCustomPages } = useContext(
            UserContext
        ) as UserContextPayload;
    const { GetCallRecordsApi, callRecordsData } = useContext(CallAnalysisContext) as CallAnalysisContextPayload
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            // {
            //   pageLanguage: "en",
            //   autoDisplay: true
            // },
            "google_translate_element"
        );
    };
    useEffect(() => {
        if (isAdmin(userDetail)) {
            // Check if the script already exists
            const existingScript = document.querySelector<HTMLScriptElement>(
                'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
            );

            if (!existingScript) {
                const addScript = document.createElement("script");
                addScript.setAttribute(
                    "src",
                    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                );
                document.body.appendChild(addScript);

                // Ensure googleTranslateElementInit is assigned properly
                window.googleTranslateElementInit = googleTranslateElementInit;
            }
        }
    }, [userDetail]);
    // useEffect(() => {
    //     const errorHandler = (e: any) => {
    //         if (
    //             e.message.includes(
    //                 "ResizeObserver loop completed with undelivered notifications" ||
    //                 "ResizeObserver loop limit exceeded"
    //             )
    //         ) {
    //             const resizeObserverErr = document.getElementById(
    //                 "webpack-dev-server-client-overlay"
    //             );
    //             if (resizeObserverErr) {
    //                 resizeObserverErr.style.display = "none";
    //             }
    //         }
    //     };
    //     window.addEventListener("error", errorHandler);
    //     return () => {
    //         window.removeEventListener("error", errorHandler);
    //     };
    // }, [])

    const { GetMetaCredentials, metaCredentials } = useContext(MetaContext) as MetaContextPayload
    const { getFunctioMethodList, getFunctionParamsList, getGenericTimezoneList, GetRouteSourceList } = useContext(GenericDataContext) as GenericDataContextPayload
    const { SendPostMessageToExtention } = useContext(
        NotifyExtentionContext
    ) as any;
    const [flagUserMeCompleted, setFlagUserMeCompleted] = useState(false);
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const lead_id = searchParam.get('lead_id')

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (userDetail.flagUser == false || userDetail.flag_user_anonymous) {
            if (searchParams.has('chatbot_id')) {
                let sampleChatbotPath = window.location.pathname + window.location.search
                let url = encodeURIComponent(sampleChatbotPath)
                navigate("/chatbot?redirect=" + url);
            }
        } else {
            if (userDetail?.plan_detail !== null && userDetail?.plan_detail?.credit_usage_data && userDetail?.plan_detail?.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data?.stripe_product_name === "Premium" || userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin") {
                if (searchParams.has('redirect')) {
                    let url = searchParams.get('redirect') ?? window.location.pathname + window.location.search
                    let urlToNavigate = decodeURIComponent(url)
                    navigate(urlToNavigate);
                }
            }
        }
    }, [userDetail])
    // to reload app once tab is not active for half an hour
    useEffect(() => {
        let hiddenTime: number;

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                hiddenTime = Date.now();
            } else if (document.visibilityState === 'visible') {
                const currentTime = Date.now();
                if (hiddenTime && (currentTime - hiddenTime > 1800000)) { // 600000 ms = 10 minutes
                    window.location.reload(); // Reload the page
                }
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    // for userMe call
    useEffect(() => {
        (async () => {
            await UserMe();
            setFlagUserMeCompleted(true);
            localStorage.setItem('testCase', 'Testing storing in local storage');

        })();
    }, []);

    useEffect(() => {
        if (!callRecordsData && window.location.pathname.toLowerCase() !== '/callanalysis' && window.location.pathname.toLowerCase() !== '/callanalysis/callrecords') {
            GetCallRecordsApi();
        }
    }, [callRecordsData, userDetail])

    useEffect(() => {
        if (!organizationDetails) {
            GetOrganizationDetails();
        }
    }, [organizationDetails, userDetail])

    useEffect(() => {
        if (!customPagesData) {
            GetCustomPages();
        }
    }, [customPagesData, userDetail])

    useEffect(() => {
        if (!metaCredentials) {
            GetMetaCredentials();
        }
    }, [metaCredentials, userDetail])

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isEdge = /Edg/.test(userAgent);
        const isMobile = /Mobi/.test(userAgent);
        if (isMobile) {
            setFlagChromeDesktop(false)
        } else {
            if (isEdge) {
                // Your Edge-specific code here
                setFlagChromeDesktop(false)
            } else if (/Chrome/.test(userAgent)) {
                setFlagChromeDesktop(true)
                // Your Chrome-specific code here
            } else {
                setFlagChromeDesktop(false)
                // Your code for other browsers here
            }
        }
    }, []); // Empty dependency array because navigator.userAgent is constant

    useEffect(() => {
        GetRouteSourceList();
        getFunctioMethodList();
        getFunctionParamsList();
        getGenericTimezoneList();
        getLanguagesList();
        getGlobalIntegrationTypeList()
    }, [userDetail])

    useEffect(() => {
        if (!callRecordsData && window.location.pathname.toLowerCase() !== '/callanalysis' && window.location.pathname.toLowerCase() !== '/callanalysis/callrecords') {
            GetCallRecordsApi();
        }
    }, [callRecordsData])

    useEffect(() => {
        if (!organizationDetails) {
            GetOrganizationDetails();
        }
    }, [organizationDetails])

    useEffect(() => {
        if (!customPagesData) {
            GetCustomPages();
        }
    }, [customPagesData])

    useEffect(() => {
        if (!metaCredentials) {
            GetMetaCredentials();
        }
    }, [metaCredentials])
    useEffect(() => {
        if (userDetail.flagUser && flagOnboardingSkip !== true) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
                    if (response.status) {
                        const userAdditionalInfo = response?.data?.data.filter((module: any) => module.user_additional_information_type_name == "Chatbot")[0].user_additional_information;
                        // ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
                        let userCreatedTime = userDetail.user_registered_timestamp;
                        let creationTime = new Date(userCreatedTime as string);
                        const timeDifference = new Date().getTime() - creationTime.getTime();
                        const is24HoursPassed = timeDifference > 24 * 60 * 60 * 1000;
                        //  ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
                        if ((!userAdditionalInfo.length || userAdditionalInfo.some((ans: any) => ans.user_additional_information_data_value == "" && (ans.user_additional_information_data_other_question_value == "" || ans.user_additional_information_data_other_question_value == null)) || userAdditionalInfo.some((ans: any) => (ans.user_additional_information_data_value == null && (ans.user_additional_information_data_other_question_value == "" || ans.user_additional_information_data_other_question_value == null))))) {
                            setFlagOnboardingSkip(false);
                        }
                        else {
                            setFlagOnboardingSkip(true);
                        }
                    }
                    // GetPromptList();
                    let tempUserDetail = {
                        flaguserAnonymous: userDetail.flag_user_anonymous,
                        userfirstName: userDetail.user_first_name,
                        userLastName: userDetail.user_last_name,
                        userFullName: ` ${userDetail.user_first_name} ${userDetail.user_last_name}`,
                        userStatus: userDetail.flagUser
                    }
                    SendPostMessageToExtention(tempUserDetail)
                }
                catch (error) {
                    // console.log(error);
                }
                if (userDetail.flagUserFromParaphrase) {
                } else {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
                // const response = await axios.put("https://api.writewiz-staging.in/api/user/add-user-additional-information-data", { user_additional_information_data: [{user_additional_information_id: 5, user_additional_information_data_value: ""}, {user_additional_information_id: 6, user_additional_information_data_value: ""}] });
            })()
        }
    }, [userDetail]);

    // useeffect for adding variable in mouseflow
    useEffect(() => {
        // if (userDetail && userDetail.flagUser && process.env["REACT_APP_ENV"] === 'production') {
        if (userDetail.flag_user_anonymous) {
            // @ts-ignore
            window._mfq.push(["setVariable", "user_assignment_id", userDetail.user_assignment_id])
        } else {
            // console.log("script running")
            // @ts-ignore
            window._mfq.push(["setVariable", "user_email", userDetail.user_email])
        }
        // }
    }, [userDetail])


    // for fetching country code
    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch('https://ipinfo.io?token');
                if (!response.ok) {
                    // throw new Error('Network response was not ok');
                    console.log("response was not ok")
                }
                const jsonData = await response.json();
                if (jsonData && jsonData.country) {
                    setCountryCode(jsonData.country.toLowerCase())
                }
            }
            catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, []);

    useLayoutEffect(() => {
        if (!window.location.search.includes('redirect') && window.location.pathname !== "/login" && window.location.pathname !== "/privacy-policy" && window.location.pathname !== "/terms") {

            if (userDetail.flagUser == false || (userDetail.flag_user_anonymous && !userDetail.flag_email_verified)) {
                let urlToNavigateTo = encodeURIComponent(window.location.pathname + window.location.search)
                navigate(`/login?redirect=${urlToNavigateTo}`)
            }
        }
    }, [userDetail])

    if (flagUserMeCompleted == false) {
        return <GlobalRoundLoader />;
    }
    if (userDetail.flagUser && !userDetail.flag_user_anonymous && userDetail.flag_email_verified && flagOnboardingSkip == null) {
        return <GlobalRoundLoader />
    }
    return (

        <Fragment>
            {/* <div id="google_translate_element"></div> */}
            {flagShowOnboardingEndPopup &&
                <GlobalPopUp
                    paperMaxwidth={"100%"}
                    open={flagShowOnboardingEndPopup}
                    borderRadius="10px"
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagOnboardingEndPopup(false) }}>
                    <OnboardingEnd />
                </GlobalPopUp>}
            {flagShowScheduleCall &&
                <GlobalPopUp
                    paperMaxwidth={"100%"}
                    open={flagShowScheduleCall}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagShowScheduleCall(false) }}>
                    <iframe title="Meeting" src="https://calendly.com/birud-chronox/30min?preview_source=et_card&month=2025-01" style={{ width: "80vw", height: "80vh", minWidth: "500px" }}></iframe>
                </GlobalPopUp>}
            {showLoginPopup &&
                <GlobalPopUp
                    open={showLoginPopup}
                    onClose={() => { setShowLoginPopup(false) }}
                    handleClose={() => { setShowLoginPopup(false) }}
                    flagShowCloseIcon={true}
                    borderRadius="10px"
                >
                    <GetStartedPopUpData />
                </GlobalPopUp>
            }
            {flagShowCreditsExhausted &&
                <GlobalPopUp
                    open={flagShowCreditsExhausted}
                    onClose={() => { setFlagShowCreditsExhausted(false) }}
                    handleClose={() => { setFlagShowCreditsExhausted(false) }}
                    flagShowCloseIcon={true}
                    borderRadius="10px"
                    paperMaxwidth="450px !important"
                >
                    <PlanHasExhausted />
                </GlobalPopUp>}
            {flagLoading.flagRoundLoader && <GlobalRoundLoader />}
            {error.flagShowError && (
                <GlobalSnackbar
                    type="error"
                    message={
                        error.message == undefined
                            ? "Something went wrong, Please try again!"
                            : error.message
                    }
                    open={error.flagShowError}
                    handleClose={() => {
                        setError({ ...error, flagShowError: false });
                    }}
                />
            )}
            {success.flagShowSuccess && (
                <GlobalSnackbar
                    type="success"
                    message={
                        success.message == undefined
                            ? "Success"
                            : success.message
                    }
                    open={success.flagShowSuccess}
                    handleClose={() => {
                        setSuccess({ ...success, flagShowSuccess: false });
                    }}
                />
            )}
            {showInfo.flagShowInfo && (
                <GlobalSnackbar
                    type="info"
                    message={
                        showInfo.message == undefined
                            ? "Success"
                            : showInfo.message
                    }
                    open={showInfo.flagShowInfo}
                    handleClose={() => {
                        setShowInfo({ ...showInfo, flagShowInfo: false });
                    }}
                />
            )}
            <RouterRoutes>
                <>
                    <Route path="/privacy-policy" element={<PrivacyCookiePolicy />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    {!userDetail?.flagUser || (userDetail.flag_user_anonymous) ? (
                        <>
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<Login />} />
                        </>
                    ) : (
                        <>
                            {userDetail.flag_email_verified ?
                                <>
                                    {
                                        (flagOnboardingSkip == false) ?
                                            <>
                                                <Route path="/welcome" element={<Welcome />} />
                                                <Route path="*" element={<Navigate to="/welcome" />} />
                                            </>
                                            :
                                            //@ts-ignore
                                            (userDetail.flag_onboarded ?? false) == false && userDetail.user_delegate_access_data === null ?
                                                <>
                                                    <Route path="/" element={<OnBoardingNew />} />
                                                    <Route path="/welcome" element={<OnBoardingNew />} />
                                                    {/* <Route path="/login" element={<Login />} /> */}
                                                    <Route path="*" element={<Login />} />
                                                </>
                                                :
                                                //@ts-ignore
                                                userDetail.user_delegate_access_data && userDetail.user_delegate_access_data.flag_onboarded === false ?
                                                    <>
                                                        <Route path="/" element={<OnBoardingNew />} />
                                                        <Route path="/welcome" element={<OnBoardingNew />} />
                                                        {/* <Route path="/login" element={<Login />} /> */}
                                                        <Route path="*" element={<Login />} />
                                                    </>
                                                    :
                                                    userDetail?.user_organization_data?.organization_id === 2545 ?
                                                    <>
                                                        <Route path="/" element={<Dashboard />}>
                                                            <Route index element={<Integration mainSection={true} />} />
                                                            <Route path="/channels" element={<Integration mainSection={true} />} />
                                                            <Route path="/contacts" element={<ContactsGlobal />} />
                                                            <Route path="/organization" element={<ViewOrganizationDetails />} />
                                                            <Route path="/whatsappcampaign" element={<WhatsappCampaignGlobal />}>
                                                                <Route index element={<WhatsappCampaignList />} />
                                                                <Route path="/whatsappcampaign/createnewcampaign" element={<CreateWhatsappCampaign />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/whatsapp" element={<TemplateView />}>
                                                                <Route index element={<ViewIntegration flagPhone={false} />} />
                                                                <Route path="/channels/viewintegration/whatsapp/phonenumbers" element={<ViewIntegration flagPhone={true} />} />
                                                                <Route path="/channels/viewintegration/whatsapp/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/whatsapp/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/facebook" element={<FacebookIntegration />}>
                                                                <Route index element={<ViewFacebookIntegration flagPage={true} />} />
                                                                <Route path="/channels/viewintegration/facebook/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/facebook/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/instagram" element={<InstagramIntegration />}>
                                                                <Route index element={<ViewInstagramIntegration flagPage={true} />} />
                                                                <Route path="/channels/viewintegration/instagram/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/instagram/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/slack" element={<SlackIntegration />}>
                                                                <Route index element={<ViewSlackIntegration flagPage={false} />} />
                                                                <Route path="/channels/viewintegration/slack/channels" element={<ViewSlackIntegration flagPage={true} />} />
                                                            </Route>
                                                        </Route>
                                                        <Route path="/welcome" element={<Navigate to="/" />} />

                                                        <Route path="/error" element={<SomethingWentWrong />} />
                                                        <Route path="*" element={<Navigate to="/" />} />
                                                    </>
                                                    :
                                                    <>
                                                        <Route path="/" element={<Dashboard />}>
                                                            <Route index element={<ChatbotList />} />
                                                            {/* <Route path="/onboard" element={<OnBoarding />} /> */}
                                                            <Route path="/chatbot" element={<ChatbotList />} />
                                                            <Route path="/inbox" element={<Chats mainSection={true} />} />
                                                            <Route path="/feedback" element={<FeedbackGlobal />} />
                                                            <Route path="/channels" element={<Integration mainSection={true} />} />
                                                            <Route path="/insights" element={<UserAnalytics />} />
                                                            <Route path="/contacts" element={<ContactsGlobal />} />
                                                            <Route path="/organization" element={<ViewOrganizationDetails />} />
                                                            <Route path="/whatsappcampaign" element={<WhatsappCampaignGlobal />}>
                                                                <Route index element={<WhatsappCampaignList />} />
                                                                <Route path="/whatsappcampaign/createnewcampaign" element={<CreateWhatsappCampaign />} />
                                                            </Route>
                                                            <Route path="/callanalysis" element={<CallAnalysisGlobal />}>
                                                                <Route index element={<CallAnalysisDashboard />} />
                                                                <Route path="/callanalysis/callrecords" element={<CallAnalysisDetails />} />
                                                                <Route path="/callanalysis/callrecords/newcallrecords" element={<NewCallRecord />} />
                                                            </Route>
                                                            {/* <Route path="/callanalysis" element={<CallAnalysisDashboard />} /> */}
                                                            <Route path="/channels/viewintegration/whatsapp" element={<TemplateView />}>
                                                                <Route index element={<ViewIntegration flagPhone={false} />} />
                                                                <Route path="/channels/viewintegration/whatsapp/phonenumbers" element={<ViewIntegration flagPhone={true} />} />
                                                                <Route path="/channels/viewintegration/whatsapp/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/whatsapp/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/facebook" element={<FacebookIntegration />}>
                                                                <Route index element={<ViewFacebookIntegration flagPage={true} />} />
                                                                <Route path="/channels/viewintegration/facebook/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/facebook/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/instagram" element={<InstagramIntegration />}>
                                                                <Route index element={<ViewInstagramIntegration flagPage={true} />} />
                                                                <Route path="/channels/viewintegration/instagram/templatemessages" element={<TemplateMessages />} />
                                                                <Route path="/channels/viewintegration/instagram/templateinfo" element={<Template />} />
                                                            </Route>
                                                            <Route path="/channels/viewintegration/slack" element={<SlackIntegration />}>
                                                                <Route index element={<ViewSlackIntegration flagPage={false} />} />
                                                                <Route path="/channels/viewintegration/slack/channels" element={<ViewSlackIntegration flagPage={true} />} />
                                                            </Route>

                                                            <Route path="/chatbot/viewchatbot" element={<ChatbotDetails />}>
                                                                <Route index element={<ViewChatbot currentTab="Settings" />} />
                                                                <Route path="/chatbot/viewchatbot/basicdetail" element={<ViewChatbot currentTab="Settings" />} />
                                                                <Route path="/chatbot/viewchatbot/integration" element={<ViewChatbot currentTab="Integrations" />} >
                                                                    <Route index element={<IntegrationList />} />
                                                                    <Route path="/chatbot/viewchatbot/integration/customAPI" element={<Dataconnector />} />
                                                                    <Route path="/chatbot/viewchatbot/integration/createCustomAPI" element={<Createdataconnector />} />
                                                                    <Route path="/chatbot/viewchatbot/integration/slack" element={<ChatbotSlackIntegration />} />
                                                                    <Route path="/chatbot/viewchatbot/integration/calendly" element={<AppointmentSchedule />} >
                                                                        <Route index element={<AuthoriseAccount />} />
                                                                        <Route path="/chatbot/viewchatbot/integration/calendly/selectCalendlyEvent" element={<SelectCalendlyEvent />} />
                                                                        <Route path="/chatbot/viewchatbot/integration/calendly/fieldSetup" element={<FieldSetup />} />
                                                                    </Route>
                                                                    <Route path="/chatbot/viewchatbot/integration/whatsapp" element={<ChatbotWhatsAppIntegration />} />
                                                                    <Route path="/chatbot/viewchatbot/integration/slack" element={<ChatbotSlackIntegration />} />
                                                                </Route>
                                                                <Route path="/chatbot/viewchatbot/createintegration" element={<Createdataconnector />} />
                                                                {/* <Route path="/chatbot/viewchatbot/integration" element={<Dataconnector />} />
                                                        <Route path="/chatbot/viewchatbot/createintegration" element={<Createdataconnector />} /> */}
                                                                <Route path="/chatbot/viewchatbot/inbox" element={<Chats />} />
                                                                {isAdmin(userDetail) &&
                                                                    <Route path="/chatbot/viewchatbot/messageformat" element={<MessageFormatGlobal />}>
                                                                        <Route index element={<MessageFormat />} />
                                                                        <Route path="/chatbot/viewchatbot/messageformat/staticformat" element={<StaticMessageFormatForm />} />
                                                                    </Route>
                                                                }
                                                                <Route path="/chatbot/viewchatbot/channels/viewWhatsappIntegration" element={<ViewChatbot currentTab="WhatsApp Integration Details" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/viewFacebookIntegration" element={<ViewChatbot currentTab="Facebook Integration Details" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/viewInstagramIntegration" element={<ViewChatbot currentTab="Instagram Integration Details" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/whatsapp" element={<ViewChatbot currentTab="WhatsApp Integration" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/facebook" element={<ViewChatbot currentTab="Facebook Integration" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/instagram" element={<ViewChatbot currentTab="Instagram Integration" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/website" element={<ViewChatbot currentTab="Embed Link & Domains" />} />
                                                                <Route path="/chatbot/viewchatbot/channels/calendly" element={<ViewChatbot currentTab="Calendly" />} >
                                                                    <Route index element={<AuthoriseAccount />} />
                                                                    <Route path="/chatbot/viewchatbot/channels/calendly/selectCalendlyEvent" element={<SelectCalendlyEvent />} />
                                                                    <Route path="/chatbot/viewchatbot/channels/calendly/fieldSetup" element={<FieldSetup />} />
                                                                </Route>
                                                                <Route path="/chatbot/viewchatbot/channels/slack" element={<ViewChatbot currentTab="Slack" />} >
                                                                </Route>
                                                                <Route path="/chatbot/viewchatbot/trychatbot" element={<TryUserChatbot currentTab="tryChatbot" />} />
                                                                <Route path="/chatbot/viewchatbot/persistentmenu" element={<PersistentMenu />} />
                                                                <Route path="/chatbot/viewchatbot/leadgeneration" element={<LeadGenerationGlobal />}>
                                                                    <Route index element={<LeadGenerationGlobal />} />
                                                                    <Route path="/chatbot/viewchatbot/leadgeneration/createleadgeneration" element={<LeadGenerationGlobal />} />
                                                                    {lead_id && <Route path="/chatbot/viewchatbot/leadgeneration/email" element={<EmailConfig />} />}
                                                                    {lead_id && <Route path="/chatbot/viewchatbot/leadgeneration/spreadsheet" element={<EmailConfig />} />}
                                                                    {lead_id && <Route path="/chatbot/viewchatbot/leadgeneration/slack" element={<SlackConfig />} />}
                                                                </Route>

                                                                <Route path="/chatbot/viewchatbot/products" element={<DataListGlobal />} />

                                                            </Route>
                                                            {/* } */}
                                                            <>
                                                                <Route path="/createChatbot" element={<CreateChatbot />} />
                                                            </>
                                                        </Route>
                                                        <Route path="/welcome" element={<Navigate to="/" />} />

                                                        <Route
                                                            path="/error"
                                                            element={<SomethingWentWrong />}
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={<Navigate to="/" />}
                                                        />
                                                    </>
                                    }

                                </>
                                :
                                <>
                                    <Route
                                        path="*"
                                        element={<Login />}
                                    />
                                </>
                            }
                        </>

                    )

                    }
                </>
            </RouterRoutes>
        </Fragment>
    );
};

export default Routes;
