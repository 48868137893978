import { Button, Grid, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/dataconnector.module.scss";
import { GlobalTable } from '../../utility/globalTable';
import { DocumentListDataModel, dataConnectorList } from '../../../models/axiosModel';
import moment from 'moment';
import GlobalPopUp from '../../utility/globalPopUp';
import DeleteDocumentDataComponent from './../deleteDocumentDataComponent';
import agent from '../../../services/api';
import AddIcon from '@mui/icons-material/Add';
import IconsColor from '../../utility/iconsColor';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import deleteDoc from "../../../assets/deleteDoc.svg";

const Dataconnector = () => {

    let headerList = [
        {
            name: "Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "data_connector_function_name",
        },
        {
            name: "Description",
            alignment: "left",
            flagIcon: false,
            valueKey: "data_connector_function_description",
        },
        {
            name: "URL",
            alignment: "left",
            flagIcon: false,
            valueKey: "chatbot_data_connector_url",
        },
        {
            name: "Method Name",
            alignment: "left",
            flagIcon: false,
            valueKey: "function_method_name",
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "editIcon"
        },
    ]

    const navigate = useNavigate()

    const { userDetail, flagLoading, setFlagLoading, setError, error, setSuccess, success, } = useContext(UserContext) as UserContextPayload;
    const [docDetailForTable, setDocDetailForTable] = useState<DocumentListDataModel[]>([])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);
    const [adjustedDocDetailForTable, setAdjustedDocDetailForTable] = useState(docDetailForTable);
    const [flagDeleteDocumentDataPopup, setFlagDeleteDocumentDataPopup] = useState(false)
    const [documentSelected, setDocumentSelected] = useState<null | any>(null)
    const [rowAllowEdit, setRowAllowEdit] = useState(null);
    const [flagRenderData, setFlagRenderData] = useState<Boolean | null>(null);
    const [dataConnectorList, setDataConnectorList] = useState<dataConnectorList[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { chatBotDetails, chatbotList, setChatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const chatbotId = searchParams.get("chatbot_id") ?? (chatBotDetails ? chatBotDetails.chatbot_id : "")

    const getChatbotDataConnectorList = async (id: any) => {
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: true }))
        if (id) {
            const response = await agent.getChatbotDataConnectorList.get(id)
            if (response.status) {
                let temp = response.data.map((dataConnector: dataConnectorList) => ({ ...dataConnector, ...dataConnector.data_connector_functions }))
                setDataConnectorList(temp)
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }))
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const isMobile = screenWidth <= 1200;

    useEffect(() => {
        if (userDetail.flagUser) {
            (async () => {
                setFlagRenderData(false)
                setFlagRenderData(true)
            })()
        }
    }, [])

    useEffect(() => {
        getChatbotDataConnectorList(chatbotId)
    }, [searchParams]);

    useEffect(() => {
        if (isMobile) {
            // For mobile view
            const filteredHeaderList = headerList.filter(header => header.name !== "Category");
            const modifiedHeaderList = filteredHeaderList.map(header => {
                if (header.name === "Documents") {
                    return { ...header, flagIcon: false };
                }
                if (header.name === "Date and time") {
                    return { ...header, name: "Last Modified" };
                }
                return header;
            });

            setAdjustedHeaderList(modifiedHeaderList);
            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        } else {
            // For desktop view
            setAdjustedHeaderList(headerList);
            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        }
    }, [isMobile, docDetailForTable]);

    const deleteDocumentDataPopupOpen = (data: any) => {
        // debugger;
        setFlagDeleteDocumentDataPopup(true)
        setDocumentSelected(data)
    }
    const deleteCancelDocumentDataPopupOpen = () => {
        setFlagDeleteDocumentDataPopup(false)

    }

    const rowClicked = async (e: any, rowDetail: any) => {
        if (e.iconKey !== "editIcon") {
            let chatbot_id = searchParams.get('chatbot_id')
            navigate(`/chatbot/viewchatbot/integration/createCustomAPI?chatbot_id=${chatbot_id}&connector_id=${rowDetail.chatbot_data_connector_id}`)
        } else {

        }
    }

    const handleDeleteDataConnector = async (data: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let chatbot_id = searchParams.get('chatbot_id') ?? ""
        const response = await agent.deleteChatbotDataConnector.delete(data, chatbot_id)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Data Connector Deleted Successfully!" })
            await getChatbotDataConnectorList(chatbotId)
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again!" })
        }
        deleteCancelDocumentDataPopupOpen()
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }
    const menuItems = [

        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" />, action: (e: any) => deleteDocumentDataPopupOpen(e) },
    ];

    const innerHeight = (window.innerHeight - 190);
    
    return (
        <>
            {flagDeleteDocumentDataPopup &&
                <GlobalPopUp
                    open={flagDeleteDocumentDataPopup}
                    onClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={documentSelected != null && documentSelected.chatbot_data_connector_id}
                        deleteCancelDocumentDataPopupOpen={deleteCancelDocumentDataPopupOpen}
                        handleDeleteDocument={handleDeleteDataConnector}
                        deleteDescription="Are you sure you want to delete this Data Connector?" />
                </GlobalPopUp>
            }
            <Grid item xl={12} xs={12} className={`${globalStyles.fullwidth} 
         
             `} sx={{ width: "100%", paddingTop: "20px" }}>
                <Grid container alignItems={"center"} justifyContent={"end"} >
                    {/* ${globalStyles.dashboardChildWrapper}
                 ${styles.dataConnectorDashboard} */}

                    {/* title header */}

                    {/* <Grid item xs={12}>
                        <Grid container xs={12} sx={{ alignItems: "center" }}>
                            <Grid item sx={{ marginBottom: "10px" }}>
                                <GlobalPageHeaderText
                                    mainHeadText={
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                disableClearable
                                                value={chatBotDetails?.chatbot_name}
                                                className={styles.chatbotTitleSelect}
                                                options={chatbotList ? chatbotList.map((chatbot: any)=>chatbot.chatbot_name) : []}
                                                onChange={(e, value)=>handleOptionChange(value)}
                                                renderInput={(params: any) => <TextField
                                                    {...params} placeholder='Select' />}
                                            />
                                            
                                        </Grid>
                                    }
                                    mainSubText={
                                        <Breadcrumbs
                                            aria-label="breadcrumb"
                                            className={styles.cbHeaderBreadCrumb}
                                            sx={{
                                                '& .MuiBreadcrumbs-separator': {
                                                    marginRight: '3px',
                                                    marginLeft: '3px'
                                                }
                                            }}
                                        >
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate("/chatbot") }}
                                                // href="/dashboard/chatbot"
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                            >
                                                AI Chatbot
                                            </Link>
                                            <Link
                                                underline="hover"
                                                onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbotId}`) }}
                                                // href="/"
                                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                            >
                                                {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"}
                                            </Link>
                                            <Link
                                                underline="hover"
                                                // href="/"
                                                className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                            >
                                                Data Connectors
                                            </Link>
                                        </Breadcrumbs>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: "100%", opacity: "0.8", marginBlock: "15px" }} /> */}

                    <Grid item xs={12} className={`${globalStyles.globalHeadRightSubItem} ${styles.globalAddConnectorButton}`}>
                        <Grid container sx={{ alignItems: "center" }} justifyContent={"end"}>
                            {window.screen.width >= 900 &&
                                <Grid item className={`${globalStyles.globalNewAddBtnItem}`} sx={{ textAlign: "end" }}>
                                    <Button
                                        variant='outlined'
                                        size='large'
                                        fullWidth
                                        sx={{ maxWidth: "160px" }}
                                        // startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                        startIcon={<IconsColor iconName="plusIcon" width={"16"} height={"16"} fill={"#FFF"} />}
                                        className={styles.newConnectorBtn}
                                        onClick={() => { navigate(`/chatbot/viewchatbot/integration/createCustomAPI${window.location.search}`) }}
                                    >
                                        Integration
                                    </Button>
                                </Grid>
                            }
                            {window.screen.width <= 899 &&
                                <IconButton
                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                    onClick={() => { navigate(`/chatbot/viewchatbot/integration/createCustomAPI${window.location.search}`) }}
                                >
                                    <AddIcon />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.documentsTableItem}`} mt={2} height={`${innerHeight}px !important`}>
                        {flagRenderData == true ?
                            (
                                <GlobalTable
                                    headerList={adjustedHeaderList}
                                    rowData={dataConnectorList}
                                    tableHeight={"76vh"}
                                    // tableContainerHeight={"calc(100vh - 210px)"}
                                    tableContainerMinHeight={"auto"}
                                    onCellClick={(e: any, rowDetail: any) => { rowClicked(e, rowDetail) }}
                                    onRowClick={(e: any) => { console.log(e) }}
                                    deleteRowData={deleteDocumentDataPopupOpen}
                                    rowAllowEdit={rowAllowEdit}
                                    flagPagination
                                    menuItemsFromParent={menuItems}
                                // rowClicked={rowClicked}
                                />
                            ) : (<></>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Dataconnector;
