import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ReCAPTCHA from "react-google-recaptcha";
import { Box, Divider, Grid, InputLabel } from "@mui/material";
import GlobalButton from "./globalButton/button";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../context/userContext";
import styles from "../../assets/styles/componentStyles/registration.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import googleIcon from "../../assets/google.svg";
import { NotifyExtentionContext } from "../../context/notifyExtentionContext";
import { emailRegex, getErrorMessage, isDev, SiteKey } from "../../utils/constants";
import VerificationComponent from "../utility/verificationComponent";
import edit from "../../assets/edit.png";
import agent from "../../services/api";
import writewizLogo from "../../assets/writewizLogo.svg"

import {
  CommonResponseModel,
  CommonResponseSecondaryModel,
  RegisterDataResponseModel,
  SendLoginOTPPayloadModel,
  SendOTPResponseDataModel,
} from "../../models/axiosModel";
import GlobalTextField from "./globalTextfield";
import IconsColor from "./iconsColor";

interface SuccessObj {
  flagShowSuccess: boolean;
  message: string;
}

const LogoComponent = () => (
  <img src={edit} alt="Logo" width="17px" height="17px" />
);

export default function GetStartedPopUpData() {
  const {
    SendLoginOTP,
    Login,
    Register,
    UserMe,
    setUserDetail,
    userDetail,
    setError,
    error,
    setFlagLoading,
    setCreditUsed,
    OneTapLoginAuthCallback,
    SendAccountOTP,
    setShowLoginPopup,
    setSuccess,
    success
  } = React.useContext(UserContext) as UserContextPayload;

  const navigate = useNavigate();

  const { SendPostMessageToExtention } = React.useContext(
    NotifyExtentionContext
  ) as any;
  const [user, setUser] = useState({
    email: "",
    google_recaptcha_token: "",
  });

  const emptyErrorObj = {
    flagShowError: false,
    message: "",
  };
  const emptySuccessObj = {
    flagShowSuccess: false,
    message: "",
  };

  const [flagEmailValid, setFlagEmailValid] = useState(true);
  const [flagShowVerificationBlock, setFlagShowVerificationBlock] =
    useState(false);
  const [flagTimerActive, setFlagTimerActive] = useState(false);
  const [flagShowRecaptchaLogin, setFlagShowRecaptchaLogin] = useState(false)
  const [seconds, setSeconds] = useState(60);
  const [flagContinueAsGuest, setFlagContinueAsGuest] = React.useState(false);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;


  const sendAccountOtp = async () => {
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: true
      }
    })
    const response = await SendAccountOTP();
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: false
      }
    })
    if (response.status) {
      handleResetTimer();
      handleStartTimer();
      setFlagShowVerificationBlock(true);
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
      }
    } else {
      if (response.data) {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      } else {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      }
    }
  };
  const handleSendOtp = async () => {
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: true
      }
    })
    const payload = {
      user_email: user.email,
      google_recaptcha_token: user.google_recaptcha_token,
      resend_flag: true
    };
    const response = await SendLoginOTP(payload);
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: false
      }
    })
    if (response.status) {
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }

    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
      setUser({ ...user, google_recaptcha_token: "" })
    }
    // }else{
    //   recaptchaRef.current?.reset();
    //   setError({...error,flagShowError:true,message:"Please write a valid Email Address!"})
    //   setUser({...user,google_recaptcha_token:""})
    // }
  };
  const handleCreateUser = async (value: any) => {
    const payload = {
      google_recaptcha_token: value,
      user_email: user.email
    };
    const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
      await Register(payload);
    if (response.status) {
      if (response.data && 'message' in response.data) {
        let message = response.data.message
        setSuccess((prevState) => {
          return { ...prevState, flagShowSuccess: true, message: message }
        })
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
    }
    // if (response.status) {
    //   if (response.data) {
    //     setUserDetail({ ...response.data[0], flagUser: response.status })
    //   }
    //   let redirectionURL = window.location.pathname
    //   navigate(redirectionURL)
    // } else {
    //   setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
    // }
  };

  useEffect(() => {
    let timerId: any;
    if (flagTimerActive && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setFlagTimerActive(false);
    }
    return () => clearInterval(timerId);
  }, [flagTimerActive, seconds]);

  const handleReSendOtp = async () => {
    // let flagvalidEmail = emailRegex.test(user.email)
    // if(flagvalidEmail){
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: true
      }
    })
    const payload = {
      user_email: user.email,
      google_recaptcha_token: user.google_recaptcha_token,
      resend_flag: true
    };
    const response = await SendLoginOTP(payload);
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: false
      }
    })
    if (response.status) {
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: response.data.message,
        });
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: response?.error?.message,
      });
      setUser({ ...user, google_recaptcha_token: "" })
    }
  };

  const handleLogin = async (otp: any) => {
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: true
      }
    })
    const payload = {
      user_email: user.email,
      authentication_code: otp,
    };
    const response = await Login(payload);
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: false
      }
    })
    if (response.status) {
      if (response.data) {
        setUserDetail({ ...userDetail, flagUserFromParaphrase: false });
        const responseOfUserMe = await UserMe();

        let currentUrl: string
        const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
        if (redirect) {
          currentUrl = decodeURIComponent(redirect);
          setTimeout(() => {
            navigate(currentUrl)
          }, 100)
        }
        let tempUserDetail = {
          flaguserAnonymous: response.data[0].flag_user_anonymous,
          userfirstName: response.data[0].user_first_name,
          userLastName: response.data[0].user_last_name,
          userFullName: ` ${response.data[0].user_first_name} ${response.data[0].user_last_name}`,
          userStatus: response.status
        }
        if (!response.data[0].flag_user_anonymous) {
          if (response.data[0]?.plan_detail.credit_usage_data !== null) {
            // const temp = {
            //   remaining_credit_usage: response.data[0].plan_detail.credit_usage_data.remaining_credit_usage,
            //   total_credit_usage: response.data[0].plan_detail.credit_usage_data.total_credit_usage
            // }
            // setCreditUsed(temp);
          }
        }
        SendPostMessageToExtention(tempUserDetail)
        localStorage.setItem('writeWizUserDetail', JSON.stringify(tempUserDetail));
      }
      setShowLoginPopup(false)
    } else {
      setError({
        ...error,
        flagShowError: true,
        message: response?.error?.message,
      });
      recaptchaRef.current?.reset();
      setUser({ ...user, google_recaptcha_token: "" })
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('originalUrl');
    };
  }, []);

  const handleStartTimer = () => setFlagTimerActive(true);

  const handleStopTimer = () => setFlagTimerActive(false);

  const handleResetTimer = () => {
    setFlagTimerActive(false);
    setSeconds(60);
  };

  const handleFlagOtp = () => {
    setFlagShowVerificationBlock(!flagShowVerificationBlock);
  };

  const contWithGoogle = async () => {
    setFlagLoading((prevState) => {
      return {
        ...prevState,
        flagRoundLoader: true
      }
    })
    let currentUrl = window.location.href
    const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
    if (redirect) {
      currentUrl = window.location.origin + decodeURIComponent(redirect);
    }
    const response = await agent.contWithGoogle.get(currentUrl, 1);
    if (response.status) {
      if (response.data) {
        let url = response.data
        window.location.href = url;
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: response?.error?.message,
      });
    }
    setTimeout(() => {
      setFlagLoading((prevState) => {
        return {
          ...prevState,
          flagRoundLoader: false
        }
      })
    }, 500)
  };


  useEffect(() => {
    // Load the Google Sign-In client
    const script = document.createElement("script");
    script.src = "client.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      console.log("$$$$$$script.onload called")
      window.google.accounts.id.initialize({
        client_id:
          "361992322546-k97tfp20831fuavdabsl5tei4gc2g0a9.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      console.log("$$$$$$script initialize")

      window.google.accounts.id.prompt((notification: any) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log("try next provider if One Tap failed.");
        }
      });
    };

    const dialogContent = document.getElementById("get-started-dialog-content");

    console.log("Dialog Content", dialogContent)

    const handleResize = () => {
      const parent = dialogContent?.parentElement;
      if (window.innerWidth >= 550) {
        parent!.style!.minWidth = '500px';
      }
      else if (window.innerWidth >= 450) {
        parent!.style!.minWidth = '450px';
      }
      else if (window.innerWidth >= 400) {
        parent!.style!.minWidth = '365px';
      }
      else {
        parent!.style!.minWidth = '330px';
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      // Cleanup script element
      document.head.removeChild(script);
    };
  }, []);

  const handleCredentialResponse = async (response: any, er: any) => {
    let apiResponse = await OneTapLoginAuthCallback(response)
    if (apiResponse?.status) {
      window.location.reload()
    }
  };

  return (
    <div>
      <DialogContent className={!(flagContinueAsGuest || flagShowRecaptchaLogin && !flagShowVerificationBlock) ? styles.initialDialogStyle : styles.dialogStyle} id="get-started-dialog-content" >

        {flagShowVerificationBlock == false ?
          <>
            <Grid container direction={"column"}>
              <Grid item>
                <Grid container sx={{ justifyContent: "center", pb: "40px" }}>
                  <img src={writewizLogo} className={styles.wzLogo} alt="logo" />
                </Grid>
              </Grid>
              <Grid item className={styles.mainBlockStyle}>
                <Grid container direction={"column"} sx={{ width: "100%", marginInline: "auto" }}>

                  {!flagContinueAsGuest && !flagShowRecaptchaLogin &&
                    <>
                      <Grid item width={"100%"}>
                        <Button onClick={contWithGoogle} className={styles.continueWithButton}>
                          <img src={googleIcon} alt="G" />
                          <div style={{ flex: 1, textAlign: 'center', marginLeft: "-12px" }}>
                            Continue with Google
                          </div>
                        </Button>
                      </Grid>
                      {/* 
                      <Grid item width={"100%"}>
                        <Button
                          sx={{ border: flagContinueAsGuest ? '1px solid #6A097D !important' : "#CDCDCD" }}
                          onClick={() => {
                            setFlagContinueAsGuest(true);
                          }}
                          className={styles.continueWithButton}>
                          <img src={guestIcon} />
                          <div style={{ flex: 1, textAlign: 'center', marginLeft: "-18px" }}>
                            Continue as Guest
                          </div>
                        </Button>
                      </Grid> */}
                    </>
                  }

                  {(flagContinueAsGuest || flagShowRecaptchaLogin) && (
                    <>

                      <Grid item>
                        <Grid container direction="column">


                          <Grid item>
                            <Typography sx={{ fontSize: "15px", fontWeight: "600", marginBottom: "10px", textAlign: "center" }}>
                              Solve recaptcha to continue
                            </Typography>
                          </Grid>
                          <Grid item sx={{ justifyContent: "center", display: "flex" }}>
                            <Button
                              className={styles.recaptchaBlockPopup}
                            >
                              {/* <ReCAPTCHA
                              className={styles.mainRecaptchaBlock}
                              ref={recaptchaRef}
                              sitekey={SiteKey}
                              onChange={(value: any) => {
                                setUser({ ...user, google_recaptcha_token: value });
                              }}
                            /> */}
                              <ReCAPTCHA
                                className={styles.mainRecaptchaBlock}
                                ref={recaptchaRef}
                                sitekey={SiteKey}
                                onChange={(value: any) => {
                                  // if (flagContinueAsGuest) {
                                  handleCreateUser(value);
                                  // } else {
                                  //   setUser({ ...user, google_recaptcha_token: value });
                                  //   handleSendOtp(value)
                                  // }
                                }}
                              />
                            </Button>
                          </Grid>
                          <Grid item className={!(flagContinueAsGuest || flagShowRecaptchaLogin && !flagShowVerificationBlock) ? "" : styles.backBtnWrapper}>
                            <Grid container className={globalStyles.clickable} onClick={() => { setFlagContinueAsGuest(false); setFlagShowRecaptchaLogin(false) }} alignItems={"center"} justifyContent={"start"} sx={{ position: "relative", marginTop: "20px" }}>
                              <Grid item sx={{ position: "absolute", top: "10%", left: 0 }}><IconsColor iconName="back" fillColor="#7A7A7A" width="15px" height="15px" /></Grid>
                              <Grid item><Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#7A7A7A", marginLeft: "25px" }}>Back</Typography></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {
                    !(flagContinueAsGuest || flagShowRecaptchaLogin) &&
                    <Grid item width={"100%"}>
                      <Divider sx={{ pb: "20px" }} className={`${globalStyles.fullwidth} ${globalStyles.dividerStyle}`}>OR</Divider>
                    </Grid>
                  }
                  <Grid item>
                    <Grid container direction={"column"} className={styles.loginUsingEmailContainer}>
                      {/* user email textfield */}
                      {!(flagContinueAsGuest || flagShowRecaptchaLogin) &&
                        <>
                          <Grid item>
                            <InputLabel sx={{ fontWeight: "600", color: "#4C4A4D", fontSize: "15px", marginBottom: "10px" }}>Email</InputLabel>
                            <Grid item className={styles.emailInputContainer}>
                              <GlobalTextField
                                className={globalStyles.globalTextfield}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                autoComplete="email"
                                autoFocus
                                value={user.email}
                                onChange={(e) => {
                                  setUser({ ...user, email: e.target.value });
                                  setError(emptyErrorObj);
                                  setFlagEmailValid(true)
                                }}
                                onBlur={() => {
                                  user.email.length > 0 &&
                                    setFlagEmailValid(emailRegex.test(user.email));
                                }}
                                error={!flagEmailValid} // Apply error styling if user_email is invalid
                                helperText={
                                  !flagEmailValid && "Please enter a valid Email Address"
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid item>
                          </Grid>
                          {/* recaptcha block */}
                          {/* <Grid item className={styles.recaptchaBlock}>
                            <ReCAPTCHA
                              className={styles.mainRecaptchaBlock}
                              ref={recaptchaRef}
                              sitekey={SiteKey}
                              onChange={(value: any) => {
                                setUser({ ...user, google_recaptcha_token: value });
                              }}
                            />
                          </Grid> */}
                        </>
                      }
                      {/* cont button */}
                      {!(flagContinueAsGuest || flagShowRecaptchaLogin) &&
                        <Grid item className={`${globalStyles.fullwidth} ${styles.contBtnWrapper}`}>
                          <GlobalButton
                            flagFullWidth
                            buttonText="Continue"
                            disabled={
                              user.email !== "" &&
                                // user.google_recaptcha_token !== "" && 
                                flagEmailValid
                                ? false
                                : true
                            }
                            className="authButtonStyle"
                            onClick={() => {
                              setFlagShowRecaptchaLogin(true)
                              // handleSendOtp();
                            }}
                          />
                        </Grid>}
                    </Grid>
                  </Grid>
                  {/* {!(flagContinueAsGuest || flagShowRecaptchaLogin) &&
                    <Grid item className={globalStyles.authPromptTextStyleLogin} sx={{ textAlign: "center", zIndex: '1', width: '100%' }}>
                      Don't have an account? <span onClick={() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url);
                      }} className={`${globalStyles.authPromptSubTextStyle} ${globalStyles.clickable}`}>Register</span>
                    </Grid>} */}
                  {/* {!flagContinueAsGuest && !flagShowRecaptchaLogin &&
                    <Grid item sx={{ margin: "10px 0px 0px" }}>
                      <Grid container className={globalStyles.clickable} onClick={() => {
                        setFlagContinueAsGuest(true);
                      }} alignItems={"center"} justifyContent={"end"} sx={{ position: "relative", marginTop: "20px" }}>
                        <Grid item><Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#6A097D" }}>Skip</Typography></Grid>
                        <Grid item
                          sx={{ transform: "rotate(180deg)", marginLeft: "7px" }}
                        ><IconsColor iconName="back" fillColor="#6A097D" width="15px" height="15px" /></Grid>
                      </Grid>
                    </Grid>} */}
                </Grid>
              </Grid>
            </Grid>

          </>
          :
          <>
            <Box sx={{ overflow: "hidden" }}>
              <Grid container direction={"column"}
                className={`animate__animated animate__fadeIn ${globalStyles.fullwidth} ${styles.verificationBlockWrapper}`}


              >
                <Grid item>
                  <Grid container sx={{ justifyContent: "center", pb: "40px" }}>
                    <img src={writewizLogo} className={styles.wzLogo} />
                  </Grid>
                </Grid>

                <Grid item

                  sx={{ width: '100%', margin: 'auto', zIndex: "1" }}
                >
                  <VerificationComponent
                    value={user.email}
                    onChange={() => { setFlagShowVerificationBlock(!flagShowVerificationBlock) }}
                    disabled={flagShowVerificationBlock}
                    onClick={handleLogin}
                    handleResendOTP={handleReSendOtp}
                    handleStartTimer={handleStartTimer}
                    handleStopTimer={handleStopTimer}
                    handleResetTimer={handleResetTimer}
                    handleLogin={handleLogin}
                    seconds={seconds}
                    minutes={minutes}
                    remainingSeconds={remainingSeconds}
                    flagTimerActive={flagTimerActive}
                    InputProps={{
                      endAdornment: flagShowVerificationBlock && (
                        <IconButton className={styles.editIconStyle} onClick={handleFlagOtp}>
                          {flagShowVerificationBlock ? (
                            <LogoComponent />
                          ) : (
                            ""
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className={!(flagContinueAsGuest || flagShowRecaptchaLogin && !flagShowVerificationBlock) ? "" : styles.backBtnWrapper}>
                  <Grid container className={globalStyles.clickable} onClick={() => { setFlagShowVerificationBlock(false) }} alignItems={"center"} justifyContent={"start"} sx={{ position: "relative", marginTop: "20px" }}>
                    <Grid item sx={{ position: "absolute", top: "10%", left: 0 }}><IconsColor iconName="back" fillColor="#7A7A7A" width="15px" height="15px" /></Grid>
                    <Grid item><Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#7A7A7A", marginLeft: "25px" }}>Back</Typography></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      </DialogContent>
      <DialogActions></DialogActions>
    </div>
  );
}
