import { Breadcrumbs, Button, Divider, Grid, IconButton, Link, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/dataconnector.module.scss";
import FileUpload from '../../utility/fileUpload/fileUpload';
import GlobalButton from '../../utility/globalButton/button';
import axios from 'axios';
import { checkForErrorCode, getErrorMessage, getFileTypeImgIcon, ifUserMessageShouldBeDynamic, isDev } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import { getCartItemResopnse, uploadCartItemsPayload } from '../../../models/axiosModel';
import DataListTable from './dataListTable';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const DataListGlobal = () => {
    const navigate = useNavigate();
    const { error, setError, setFlagLoading, flagLoading, userDetail, success, setSuccess } = useContext(UserContext) as UserContextPayload;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;

    const [files, setFiles] = useState<any>([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [cartItemData, setCartItemData] = useState<getCartItemResopnse[]>([]);

    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

    const smallScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)');
    const below800screen = useMediaQuery('(max-width : 800px) and (min-width : 0px)');

    const [searchParams] = useSearchParams();
    let chatbotId = searchParams.get("chatbot_id");

    const uploadFileHandler = async (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            const file: any = e.target.files[i];

            file.isUploading = true;
            setFiles([file]);

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");

            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }

            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }).then(async (res) => {
                if (res.status) {
                    file.isUploading = false;
                    file.file_data = res.data.data;


                    file.google_storage_id = res.data.data.google_storage_id;
                    await setFiles((old: any) => {
                        let temp = [...old]
                        let fileType = file.name.split('.').pop()
                        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        temp.map((rr: any) => {
                            if (rr.google_storage_id == file.google_storage_id) {
                                rr.isUploading = false;
                                rr.file_data = res.data.data;
                                rr.google_storage_id = res.data.data.google_storage_id;
                                // rr.page_field_id = id;
                                rr.previewImageSrc = fileTypeIconSrc;
                            }
                        })
                        return temp
                    })

                    setIsFileUploaded(false);

                    // let images = [];

                    // for (let i = 0; i < e.target.files.length; i++) {
                    //     images.push(URL.createObjectURL(e.target.files[i]))
                    // }
                }
            }).catch((err) => {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFiles([])
                // inform the user
                console.error(err)
                // removeFile(file.name)
            });

            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        }

    }

    const removeFileHandler = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${files[0].file_data.google_storage_id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then(async (res) => {
            if (res.data.status) {
                setFiles([]);
                setSuccess({ ...success, flagShowSuccess: true, message: "File Deleted Successfully" })
            } else {
                let errorCode = checkForErrorCode(res.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        }).catch((err) => {
            console.log(err);
        })
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        setFiles([]);
    }

    const uploadCartItemCall = async () => {
        const payload: uploadCartItemsPayload = {
            chatbot_id: Number(chatBotDetails?.chatbot_id),
            type: "product",
            file_id: files[0].file_data.google_storage_id,
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const response = await agent.uploadCartItems.post(payload);
        if (response.status) {
            if (response.data) {
                getCartItemCall();
                setIsFileUploaded(true);
                setSuccess({ ...success, flagShowSuccess: true, message: "File Uploaded Successfully" })
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    const getFileInfoCall = async (storage_id: number) => {
        const response = await agent.getFileDetail.get(storage_id);
        if (response.status) {
            if (response.data) {
                let fileNew = { ...response.data }
                fileNew.name = fileNew.original_file_name
                let fileType = fileNew.name.split('.').pop()
                let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                fileNew.file_data = { ...response.data }
                fileNew.previewImageSrc = fileTypeIconSrc
                setFiles([fileNew]);
            }
        }
    }

    const getCartItemCall = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        const response = await agent.getProductListData.get(chatBotDetails?.chatbot_id.toString() || "")
        if (response.status) {
            if (response.data) {
                setCartItemData(response.data);
                if (files.length === 0) {
                    getFileInfoCall(response?.cart_item_file_id);
                    setIsFileUploaded(true);
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false })
    }

    useEffect(() => {
        console.log("files is smtg like: ", files);
    }, [files])

    useEffect(() => {
        if (cartItemData.length === 0) {
            getCartItemCall();
        }
    }, [])

    return (
        <>
            <Grid container direction={"column"} className={`${globalStyles.fullwidth} ${globalStyles.dashboardChildWrapper} ${styles.dataConnectorDashboard}`}>
                <Grid item xs={12}>
                    <Grid container flexWrap={"nowrap"} direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={{ xs: "start", md: "center" }} gap={"5px"}>

                        <Grid item xs={12} md={10} sx={{ marginBottom: { xs: "0px", md: "10px" } }} alignItems={"center"}>
                            <GlobalPageHeaderText
                                mainHeadText={"Products"}
                                flagMainTextOnly
                                mainSubText={
                                    !smallScreen && <Breadcrumbs
                                        aria-label="breadcrumb"
                                        className={styles.cbHeaderBreadCrumb}
                                        sx={{
                                            '& .MuiBreadcrumbs-separator': {
                                                marginRight: '3px',
                                                marginLeft: '3px'
                                            }
                                        }}
                                    >
                                        <Link
                                            underline="hover"
                                            onClick={() => { navigate("/chatbot") }}
                                            // href="/dashboard/chatbot"
                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                                        >
                                            AI Chatbot
                                        </Link>
                                        <Link
                                            underline="hover"
                                            onClick={() => { navigate(`/chatbot/viewchatbot/basicdetail?chatbot_id=${chatbotId}`) }}
                                            // href="/"
                                            className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}

                                        >
                                            {/* {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"} */}
                                            {chatBotDetails?.chatbot_name}
                                        </Link>
                                        <Link
                                            underline="hover"
                                            // href="/"
                                            className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                                        >
                                            {"Products"}
                                        </Link>
                                    </Breadcrumbs>
                                }
                            />
                        </Grid>
                    </Grid>

                    {!smallScreen && <Divider style={{ width: "100%", opacity: "0.8", marginBottom: "15px", marginTop: "5px" }} />}
                </Grid>

                <Grid item xl={12} xs={12}
                    sx={{ width: "100%" }}
                >
                    <Grid container gap={"12px"}>
                        <Grid item xs={12} lg={7} xl={6}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item>
                                    <Typography component={"span"} sx={{ fontSize: "14px", fontWeight: "600" }} >Upload File</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container gap={"12px"} flexWrap={"nowrap"}>
                                        <Grid item sx={{ width: "100%" }}>
                                            <FileUpload
                                                uploadedFileDetails={files}
                                                multipleFileAllow={true}
                                                fileSizeAllowed={3}
                                                fileTypeAllowed={'.csv'}
                                                fileNumberAllowed={2}
                                                isDisabled={false}
                                                uploadFileHandler={uploadFileHandler}
                                                removeFileHandler={removeFileHandler}
                                                isDeleteFileIconVisible={isFileUploaded}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {below800screen ?
                                                <Tooltip title="Upload">
                                                    <IconButton
                                                        sx={{ display: "flex", background: "white", borderRadius: "5px", boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.25)", padding: "6px 8px" }}
                                                        onClick={() => { uploadCartItemCall() }}
                                                        disabled={isFileUploaded}
                                                    >
                                                        <CloudUploadIcon sx={{ color: isFileUploaded ? "#cccccc" : "#6a097d" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                :

                                                <GlobalButton
                                                    buttonText="Upload"
                                                    className='primaryButtonStyle'
                                                    sx={{ width: "110px" }}
                                                    disabled={isFileUploaded}
                                                    onClick={() => { uploadCartItemCall() }}
                                                >
                                                </GlobalButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <DataListTable
                                cartItemData={cartItemData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DataListGlobal
