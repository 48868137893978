import { Grid } from '@mui/material'
import React from 'react'
import waveLoader from '../../../assets/images/callAnalysis/audio_loader.gif'

const CallAnalysisWaveLoader = () => {
    return (
        <img src={waveLoader} height={"120"} width={"120"} style={{marginTop: "-25px"}}/>
    )
}

export default CallAnalysisWaveLoader
