import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import dashboardStyles from './../../../assets/styles/componentStyles/callAnalysisStyles/callAnalysisDashboard.module.scss'
import { callRecordAlertInterface } from '../../../models/axiosModel'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface alertsTableInterface {
    setTimeToScroll: React.Dispatch<React.SetStateAction<number | null>>,
    alertsList: callRecordAlertInterface[] | undefined
}

const AlertsTable = (props: alertsTableInterface) => {
    const {setTimeToScroll, alertsList} = props;

    const handleSetScrollTime = (time: string) => {
        // let seconds = Number(time.split(':')[0])*60 + Number(time.split(':')[1]);
        let seconds;
        if (time.includes(':')) {
            seconds = Number(time.split(':')[0]) + Number(time.split(':')[1])/100;
        }
        else if (time.includes('.')) {
            seconds = Number(time.split('.')[0]) + Number(time.split('.')[1])/100;
        }
        else {
            seconds = Number(time.split(':')[0]) + Number(time.split(':')[1])/100;
        }
        setTimeToScroll(seconds);
    }

    return (
        <TableContainer sx={{borderRadius: "5px", border: "1px solid #E9E9E9", maxWidth: "100%", overflow: "auto", maxHeight: "187px"}}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Phrase</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>Duration</TableCell>
                        <TableCell className={dashboardStyles.tableBodyHeadingFontStyle}>By</TableCell>
                        <TableCell className={`${dashboardStyles.tableBodyHeadingFontStyle} ${dashboardStyles.viewButtonColumnHeadingStyle}`}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alertsList?.map((alerts) => 
                        <TableRow className={dashboardStyles.cellRow}>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{alerts.call_record_alert_phrase}</TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{`${alerts.call_record_alert_start_time} - ${alerts.call_record_alert_end_time}`}</TableCell>
                            <TableCell className={dashboardStyles.tableBodySubHeadingFontStyle}>{alerts.call_record_alert_speaker}</TableCell>
                            <TableCell className={`${dashboardStyles.tableBodySubHeadingFontStyle} ${dashboardStyles.viewButtonColumnStyle}`} sx={{cursor: "pointer"}} onClick={() => handleSetScrollTime(alerts.call_record_alert_start_time)}>
                                <PlayArrowIcon sx={{display: "flex", color: "#6A097D", fontSize: "20px"}}/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AlertsTable
