import * as React from "react";
import {
    Grid,
    Divider,
    useMediaQuery,
    Button
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import GlobalForm from "../../utility/formWiz/globalForm";
import GlobalButton from "../../utility/globalButton/button";
import { ChatbotContext, ChatbotContextPayload } from "../../../context/chatbotContext";
import styles from "../../../assets/styles/componentStyles/createPersona.module.scss";
import axios from "axios";
import GlobalPageHeaderText from "../../utility/globalPageHeaderText";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, ifUserMessageShouldBeDynamic, isAdmin, isDev } from "../../../utils/constants";
import textTypeFileIcon from "../../../assets/images/icons/txtFileType.png";
import csvTypeFileIcon from "../../../assets/images/icons/csvFileType.png";
import mdTypeFileIcon from "../../../assets/images/icons/mdFileType.png";
import xmlTypeFileIcon from "../../../assets/images/icons/xmlFileType.png";
import pngTypeFileIcon from "../../../assets/images/icons/pngFileType.png";
import jpgTypeFileIcon from "../../../assets/images/icons/jpgFileType.png";
import jsonTypeFileIcon from "../../../assets/images/icons/jsonFileType.png";
import jpegTypeFileIcon from "../../../assets/images/icons/jpegFileType.png";
import genericFileType from "../../../assets/images/icons/genericFileType.png";
import globalStyles from "../../../assets/styles/global.module.scss";

export default function CreateChatbot() {
    const navigate = useNavigate()

    const { setFlagLoading, setError, error, flagLoading, setSuccess, success, setFlagShowCreditsExhausted, userDetail, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const { CreateChatbotApi } = useContext(ChatbotContext) as ChatbotContextPayload
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });

    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const laptopScreen = useMediaQuery('(max-width : 1600px) and (min-width : 900px)')

    const CreateChatbotForm = [
        {
            "page_field_id": 1510,
            "label": "Chatbot Name",
            "placeholder": "Enter a name for your Chatbot",
            "required": true,
            "name": "1510_Chatbot_Name",
            "type": "text",
            max: "100",
            min: "5",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1511,
            "label": "Purpose Of Chatbot",
            max: 35000,
            min: 5,
            "placeholder": "Provide clear and concise prompts for the best results.",
            "required": true,
            "name": "1511_Purpose_Of_Chatbot",
            "type": "longtext",
            "maxRows": "15",
            "minRows": "5",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "class2": "minHeight400",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1514,
            "label": "Chatbot Specific Instructions",
            "placeholder": "These are the instructions that would be attached to each message",
            "required": false,
            "name": "1514_Chatbot_Specific_Instructions",
            "type": "longtext",
            "maxRows": "15",
            "minRows": "5",
            "value": "",
            "hidden": isAdmin(userDetail) ? false : true,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1512,
            "label": "Initial Message",
            "placeholder": "Welcome! I'm your virtual assistant, here to offer support and information. What can I do for you today?",
            "required": false,
            "name": "1511_Initial_Message",
            "type": "longtext",
            min: "5",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
        {
            "page_field_id": 1513,
            "label": `Knowledge Base`,
            "tooltip": "Allowed File Formats (.gif, .jpeg, .jpg, .png, .webp, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip,)",
            "type": "file",
            "value": [],
            "col": 12,
            "name": "1513_Files",
            "placeholder": "Drag & drop files here, or click to select files",
            "multiple_file": 30,
            "file_size": "5",
            "is_avatar": false,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false,
            "multiple_file_allow": true,
            "fileType": ".gif, .jpeg, .jpg, .png, .webp, text/json, .c, .css, .csv, .cpp, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip, text/x-python, text/x-script.python",
            "class1": `chatbotFieldLabel ${globalStyles.formLabelFontSize} ${globalStyles.formLabelFontWeight}`,
        },
    ]
    const [createChatbotFormData, setCreateChatbotFormData] = useState<any>({
        data: {
            global_form_field: CreateChatbotForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px",
                    "page_theme_column_spacing": 0
                }
            ]
        }
    })

    const getFileTypeImg = (fileType: string) => {
        switch (fileType) {
            case '.txt':
                return textTypeFileIcon;
            case '.md':
                return mdTypeFileIcon;
            case '.json':
                return jsonTypeFileIcon;
            case '.csv':
                return csvTypeFileIcon;
            case '.png':
                return pngTypeFileIcon;
            case '.jpg':
                return jpgTypeFileIcon;
            case '.xml':
                return xmlTypeFileIcon;
            case '.jpeg':
                return jpegTypeFileIcon;
            default:
                return genericFileType;
        }
    }

    const [submissionID, setsubmissionID] = useState(null);
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })


    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createChatbotFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCreateChatbotFormData(tempdata)
        setFlagFirstRenderDone(true)
    }

    const formSubmitFunction = (data: any) => {
        var finalData = data;

    }

    useEffect(() => {
        if (createChatbotFormData.data.global_form_field[0].value || createChatbotFormData.data.global_form_field[1].value.length) {
            const handleBeforeUnload = (event: any) => {
                event.preventDefault();
                event.returnValue = "return";
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }

    }, [createChatbotFormData]);

    const getCreatePersonaButtonDisableCondition = () => {
        let fields = createChatbotFormData["data"]["global_form_field"]
        for (const field of fields) {
            if ((field.required && field.value === "") || (field.required && (Array.isArray(field.value) ? field.value.length : field.value?.trim().length) == 0)) {
                return true
            }
        }
        return false
    }

    const handleSubmitChatbot = async () => {
        let fields = createChatbotFormData["data"]["global_form_field"]

        let googleIds: any = []
        fields[4].value?.map((id: number) => {
            let obj: any = {}
            obj['knowledge_base_id'] = id
            googleIds.push(obj)
        })

        const payload = {
            chatbot_name: fields[0]?.value,
            chatbot_discription: fields[1]?.value,
            chatbot_initial_message: fields[3]?.value,
            chatbot_specific_instruction: fields[2]?.value,
            chatbot_knowledge_base: googleIds
        }


        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response: any = await CreateChatbotApi(payload)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Chatbot created successfully!" })
            navigate(`/chatbot/viewChatbot?chatbot_id=${response?.data?.chatbot_id}`)
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
    }

    const uploadFileHandler = async (event: any, id: any, removeFile: any) => {

        for (var i = 0; i < event.target.files.length; i++) {
            const file: any = event.target.files[i];
            file.isUploading = true;
            setFiles(old => {
                let temp: any = [...old]
                temp.push(file)
                return temp
            })

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            );
            formData.append("chatbot_flag", "true");
            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }
            ).then(async (res) => {
                file.goolge_storage_file_type = res.data.data.goolge_storage_file_type;
                file.previewImageSrc = getFileTypeImg(file.goolge_storage_file_type)
                file.isUploading = false;
                file.file_data = res.data.data;
                file.google_storage_id = res.data.data.google_storage_id;
                await setFiles(old => {
                    let temp = [...old]
                    temp.map((rr: any) => {
                        if (rr.lastModified == file.lastModified) {
                            rr.isUploading = false;
                            rr.file_data = res.data.data;
                            rr.google_storage_id = res.data.data.google_storage_id;
                            rr.page_field_id = id;
                            rr.previewImageSrc = getFileTypeImg(res.data.data.goolge_storage_file_type)
                        }
                    })
                    var tempdata = { ...createChatbotFormData }
                    const googleStorageIds = temp.map((item: any) => item.google_storage_id);

                    tempdata.data.global_form_field.map((newvalue: any) => {
                        if (newvalue.page_field_id === id) {
                            // debugger
                            newvalue.value = googleStorageIds
                        }
                    })
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    setCreateChatbotFormData(tempdata)
                    return temp
                })

                let images = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    images.push(URL.createObjectURL(event.target.files[i]))
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            ).catch((err) => {
                // inform the user
                console.error(err)
                removeFile(file.name)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            });
        }
    }

    const removeFile = (google_id: any, page_field_id: any) => {
        var google_s_id: any = {};
        google_s_id['file_id'] = google_id
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${google_id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }
        ).then(async (res) => {
            if (res.data.status) {
                const fileSet: any = files.filter((file: any) => file.google_storage_id !== google_id)
                var finalFiles: any = [...fileSet];
                var tempdata = { ...createChatbotFormData }
                const googleStorageIds = finalFiles.map((item: any) => item.google_storage_id);

                tempdata.data.global_form_field.map((newvalue: any) => {
                    if (newvalue.page_field_id === page_field_id) {
                        newvalue.value = googleStorageIds
                    }
                })
                setCreateChatbotFormData(tempdata)
                setFiles(finalFiles)
            } else {
                let errorCode = checkForErrorCode(res.data)
                if (ifUserMessageShouldBeDynamic(errorCode)) {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: res.data.error.message }))
                } else {
                    setError((prevState) => ({ ...prevState, flagShowError: true, message: getErrorMessage(errorCode, isDev(userDetail)) }))
                }
            }
        }
        ).catch((err) => {
            console.error(err)
        });
    }

    const innerHeight = smallScreen ? (window.innerHeight - 150) : laptopScreen ? (window.innerHeight - 120) : (window.innerHeight - 115);

    return (
        <Grid container className={styles.chatbotMainContainer} >

            <Grid item xs={12} sx={{ mb: "0px" }}>
                <Grid container>
                    <Grid item xs={smallScreen ? 12 : 9}>
                        <GlobalPageHeaderText
                            mainHeadText={"Chatbot"}
                            mainSubText={"Create chatbot for your data that matches your brand’s personality"}
                        />
                    </Grid>
                    {smallScreen ? (<></>) :
                        (<>
                            <Grid item xs={3} sx={{ textAlign: "end" }}>
                                <GlobalButton
                                    buttonText="Create Chatbot"
                                    className="primaryButtonStyle"
                                    disabled={getCreatePersonaButtonDisableCondition()}
                                    onClick={handleSubmitChatbot}
                                />
                            </Grid>
                        </>)
                    }

                </Grid>

            </Grid>
            <Grid item xs={12} sx={{ margin: "0px 0px", }}>
                <Divider className={styles.dividerStyle} />
            </Grid>

            <Grid item xs={12} className={styles.chatbotCreateMainItem} sx={{ height: `${innerHeight}px !important`, maxHeight: `${innerHeight}px !important` }}>
                <Grid container >
                    {/* <Grid item xs={12}>
                        <Typography component={'span'} sx={{ fontSize: "18px", fontWeight: "600" }}>
                            Create your chatbot
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ margin: "10px 0px", }}>
                        <Divider sx={{ color: "#B3B3B3" }} />
                    </Grid> */}

                    <Grid item xs={12} >
                        <Grid container>
                            <Grid item xl={5} lg={7} md={10} sm={12} xs={12}>
                                <GlobalForm
                                    fields={createChatbotFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createChatbotFormData.data.doc_page_theme[0]}
                                    formData={createChatbotFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                    filesInParent={files}
                                    removeFile={(e: any, page_field_id: any) => { removeFile(e, page_field_id) }}
                                />
                            </Grid>

                            {smallScreen ?
                                (
                                    <>
                                        <Grid item xs={12} >
                                            <GlobalButton buttonText="Create Chatbot" className="primaryButtonStyle"
                                                disabled={getCreatePersonaButtonDisableCondition()}
                                                onClick={handleSubmitChatbot}
                                            />
                                        </Grid>
                                    </>
                                ) : (<></>)
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
