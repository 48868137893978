import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, ListItemIcon, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import styles from '../../../../assets/styles/componentStyles/messageFormatForms/messageFormatForms.module.scss'
import Required from '../../../utility/required'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import GlobalButton from '../../../utility/globalButton/button';
import IconsColor from '../../../utility/iconsColor';
import GlobalPopUp from '../../../utility/globalPopUp';
import AddCTAButton from './addCTAButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import demoCarouselImage from '../../../../assets/images/messageFormats/demoCarouselImage.svg';
import demoCarouselImage from '../../../../assets/images/messageFormats/testpersonimage.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import globalStyles from '../../../../assets/styles/global.module.scss';
import deleteDoc from "../../../../assets/deleteDoc.svg";
import GlobalDeletePopup from '../../../utility/globalDeletePopup/globalDeletePopup';
import { getCTAButtonLimitValidation } from '../messageFormatConstants';
import FileUpload from '../../../utility/fileUpload/fileUpload';
import axios from 'axios';
import { getFileTypeImgIcon } from '../../../../utils/constants';

interface ctaButtonActionType {
    cta_action_type_id: number,
    cta_action_type_value: string
}

interface ctaButton {
    cta_button_id: number,
    cta_button_name: string,
    cta_action_type: ctaButtonActionType,
    cta_action_example: string,
}

interface cardInterface {
    format_name: string,
    heading_information_content: string,
    heading_sample_value: string,
    subheading_information_content: string,
    subheading_sample_value: string,
    format_cta_buttons: ctaButton[]
}

const initialCardPayload = {
    format_name: "",
    heading_information_content: "",
    heading_sample_value: "",
    subheading_information_content: "",
    subheading_sample_value: "",
    format_cta_buttons: []
}

const imagePreviews = [
    {
        id: 1,
        aspectRatio: 1,
        // height: 49,
        width: 30
    },
    {
        id: 2,
        aspectRatio: 1.25,
        // height: 49,
        width: 36
    },
    {
        id: 3,
        aspectRatio: 1.35,
        // height: 49,
        width: 43
    },
    {
        id: 4,
        aspectRatio: 1.60,
        // height: 49,
        width: 48.92
    },
    {
        id: 5,
        aspectRatio: 1.70,
        // height: 49,
        width: 37
    },
    {
        id: 6,
        aspectRatio: 1.85,
        // height: 49.09,
        width: 43.1
    },
    {
        id: 7,
        aspectRatio: 2.15,
        // height: 49,
        width: 45.77
    },
]

const initialImagePreview = {
    id: 1,
    aspectRatio: 1,
    // height: 49,
    width: 30
}

interface messageFormatProps {
    message_format_id: number
}

const DoctorCardCarousel = (props: messageFormatProps) => {
    const { message_format_id } = props
    const [carouselCardFormat, setCarouselCardFormat] = useState<cardInterface>(initialCardPayload);
    const [addCTAButtonPopup, setAddCTAButtonPopup] = useState(false);
    const flagSmallScreen = useMediaQuery<boolean>('(min-width: 0px) and (max-width: 900px)');
    const [imagePreview, setImagePreview] = useState(initialImagePreview);
    const [headerListMenu, setHeaderListMenu] = React.useState<null | HTMLElement>(null);
    const [flagDeleteCTADataPopup, setFlagDeleteCTADataPopup] = useState(false);
    const [ctaButtonToDeleted, setCTAButtonToDeleted] = useState<ctaButton>();
    const [imageFiles, setImageFiles] = useState({
        cardImage: [],
        CTAButton: []
    })
    const open = Boolean(headerListMenu);

    const handleChangeCarouselFormat = (value: string, fieldName: string) => {
        setCarouselCardFormat((prevState) => ({ ...prevState, [fieldName]: value }))
    }

    const handleAddNewCTAButton = () => {
        setAddCTAButtonPopup(true)
    }

    const handleRemoveCTAButton = (ctaButtonID?: number) => {
        setCarouselCardFormat((prevState: any) => ({ ...prevState, format_cta_buttons: [...prevState.format_cta_buttons.filter((buttonData: ctaButton) => buttonData.cta_button_id !== ctaButtonID)] }));
        setFlagDeleteCTADataPopup(false)
    }

    const handleClickCTAListMenu = (event: React.MouseEvent<HTMLButtonElement>, button_ID: number) => {
        setHeaderListMenu(event.currentTarget);
        setCTAButtonToDeleted(carouselCardFormat.format_cta_buttons.find((format) => format.cta_button_id === button_ID))
    };

    const handleCloseCTAListMenu = () => {
        setHeaderListMenu(null);
    };

    const deleteCTAButtonPopupOpen = () => {
        setFlagDeleteCTADataPopup(true)
    }

    const handleCTAButtonValidation = () => {
        if (carouselCardFormat.format_cta_buttons.length >= getCTAButtonLimitValidation(message_format_id)) {
            return true;
        } else {
            return false;
        }
    }

    const handleCancelFormatData = () => {
        setCarouselCardFormat(initialCardPayload)
    }

    const handleSaveFormatData = () => {
        //save conditions
    }

    const handleSaveFormatDataDisabled = () => {
        if (JSON.stringify(carouselCardFormat) === JSON.stringify(initialCardPayload)) {
            return true;
        } else {
            return false;
        }
    }

    const menuItems = [
        // { label: 'Edit', icon: <img src={deleteDoc} alt="edit" />, action: (e: any) => deleteDocumentDataPopupOpen(e) },
        { label: 'Delete', icon: <img src={deleteDoc} alt="delete" />, action: (e: any) => deleteCTAButtonPopupOpen() },
    ];

    async function uploadFileHandler(event: any, keyName: string) {
        for (var i = 0; i < event.target.files.length; i++) {
            const file: any = event.target.files[i];
            file.isUploading = true;
            // @ts-ignore
            // setImageFiles((prevState) => ({ ...prevState, [keyName]: [...prevState[keyName], file] }))

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            )
            formData.append("chatbot_flag", "true");
            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    // setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }
            )
                .then(async (res) => {
                    if (res.status) {
                        file.isUploading = false;
                        file.file_data = res.data.data;


                        file.google_storage_id = res.data.data.google_storage_id;
                        // setImageFiles((prevState) => ({
                        //     // @ts-ignore
                        //     ...prevState, [keyName]: prevState[keyName].map((file) => {
                        //         let fileType = file.name.split('.').pop()
                        //         let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
                        //         if (file.google_storage_id == file.google_storage_id) {
                        //             file.isUploading = false;
                        //             file.file_data = res.data.data;
                        //             file.google_storage_id = res.data.data.google_storage_id;
                        //             file.previewImageSrc = fileTypeIconSrc;
                        //         }
                        //     })
                        // }))
                        setImageFiles((prevState) => ({
                            ...prevState,
                            // @ts-ignore
                            [keyName]: prevState[keyName].map((file: any) => {
                                let fileType = file.name.split('.').pop();
                                let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? "";

                                // Assuming you want to compare file.google_storage_id with some other value, e.g., res.data.data.google_storage_id
                                if (file.google_storage_id === res.data.data.google_storage_id) {
                                    return {
                                        ...file,
                                        isUploading: false,
                                        file_data: res.data.data,
                                        google_storage_id: res.data.data.google_storage_id,
                                        previewImageSrc: fileTypeIconSrc,
                                        name: res.data.data.google_storage_original_file_name,
                                        size: res.data.data.goolge_storage_file_size
                                    };
                                }

                                return file;
                            })
                        }));
                        let images = [];

                        for (let i = 0; i < event.target.files.length; i++) {
                            images.push(URL.createObjectURL(event.target.files[i]))
                        }
                    } else {
                        // setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        // setImageFiles()
                    }
                })
                .catch((err) => {
                    // setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                    // setFiles([])
                    console.error(err)
                });
        }

    }
    function removeFileHandler() {

    }
    return (
        <>
            <Grid item sx={{ width: "100%" }}>
                <Grid container direction={"row"} flexWrap={"nowrap"} gap={4}>
                    <Grid item xs={7} sx={{ width: "100%", padding: "15px 15px 15px 5px", maxHeight: "calc(100vh - 90px)", overflow: "auto" }}>
                        <Grid container direction={"column"} gap={"15px"}>

                            <Grid item sx={{ width: "100%" }} marginBottom={1}>
                                <span className={styles.formatTypeFontStyle}>Format Type</span> <ArrowRightAltIcon style={{ verticalAlign: "middle" }} /> <span className={styles.formatFontStyle}>Image Card Carousal</span>
                            </Grid>

                            <Grid item sx={{ width: "100%" }} marginBottom={1}>
                                <Grid container direction={"column"} gap={"8px"}>
                                    <Grid item className={styles.formFieldLabels}>Format Name <Required /></Grid>
                                    <Grid item>
                                        <TextField
                                            placeholder='Enter Format Name'
                                            className={styles.formFieldsTextField}
                                            onChange={(event) => handleChangeCarouselFormat(event.target.value, 'format_name')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: "100%" }} marginBottom={1}>
                                <Grid container direction={"column"} gap={"15px"}>
                                    <Grid item className={styles.formFieldLabels}>Heading <Required /></Grid>
                                    <Grid item>
                                        <Grid container direction={"column"} gap={1}>
                                            <Grid item>
                                                <span className={styles.subClassName}>What Kind of Information Should This Field Contain?</span>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    placeholder='Information Regarding This Field'
                                                    className={styles.formFieldsTextField}
                                                    onChange={(event) => handleChangeCarouselFormat(event.target.value, 'heading_information_content')}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"column"} gap={1} mt={2}>
                                            <Grid item>
                                                <span className={styles.subClassName}>Sample Value</span>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    placeholder='Enter Heading Name'
                                                    className={styles.formFieldsTextField}
                                                    onChange={(event) => handleChangeCarouselFormat(event.target.value, 'heading_sample_value')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: "100%" }} marginBottom={1}>
                                <Grid container direction={"column"} gap={"15px"}>
                                    <Grid item className={styles.formFieldLabels}>Sub Heading <Required /></Grid>
                                    <Grid item>
                                        <Grid container direction={"column"} gap={1}>
                                            <Grid item>
                                                <span className={styles.subClassName}>What Kind of Information Should This Field Contain?</span>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    placeholder='Information Regarding This Field'
                                                    className={styles.formFieldsTextField}
                                                    onChange={(event) => handleChangeCarouselFormat(event.target.value, 'subheading_information_content')}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"column"} gap={1} mt={2}>
                                            <Grid item>
                                                <span className={styles.subClassName}>Sample Value</span>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    placeholder='Enter Sub Heading Name'
                                                    className={styles.formFieldsTextField}
                                                    onChange={(event) => handleChangeCarouselFormat(event.target.value, 'subheading_sample_value')}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: "100%" }} marginBottom={1}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item className={styles.formFieldLabels}>Upload Card Image <Required /></Grid>
                                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid container
                                        // className={styles.file_inputs_avatar}
                                        >
                                            <FileUpload
                                                uploadedFileDetails={imageFiles.cardImage}
                                                multipleFileAllow={false}
                                                fileSizeAllowed={5}
                                                fileTypeAllowed={'.png, .jpeg, .jpg'}
                                                fileNumberAllowed={1}
                                                uploadFileHandler={(event) => { uploadFileHandler(event, 'cardImage') }}
                                                removeFileHandler={removeFileHandler}
                                                isDisabled={false}
                                            />
                                            {/* <input
                                                id="image"
                                                accept='image/*'
                                                type="file"
                                                className={styles.file_inputs_avatar}
                                                onChange={(e) => {
                                                    // uploadFileHandler(e.target.files, item.page_field_id)
                                                }}
                                            >
                                            </input>
                                            <button className={styles.file_inputs_avatar} style={{ color: "#4C4A4D", boxShadow: "none !important" }}>
                                                <FileUploadOutlinedIcon />
                                                <span>
                                                    {"Upload file"}
                                                </span>
                                            </button> */}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: "100%" }}>
                                <Grid container direction={"column"} gap={1}>
                                    <Grid item className={styles.formFieldLabels}>CTA Button <Required /></Grid>
                                    <Grid item>
                                        <GlobalButton
                                            className="ctaButtonStyle"
                                            buttonText={"New CTA Button"}
                                            startIcon={<IconsColor fillColor="#6A097D" iconName="plus" width="17px" height="17px" />}
                                            onClick={() => handleAddNewCTAButton()}
                                            disabled={handleCTAButtonValidation()}
                                        />
                                    </Grid>
                                    {carouselCardFormat.format_cta_buttons && carouselCardFormat.format_cta_buttons.length > 0 &&
                                        <Grid item className={styles.tableCTAStyles}>
                                            <TableContainer sx={{ paddingInline: "20px", boxSizing: "border-box" }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={styles.tableHeadCellStyle} >Icon</TableCell>
                                                            <TableCell className={styles.tableHeadCellStyle} >Button Name</TableCell>
                                                            <TableCell className={styles.tableHeadCellStyle} >Action Type</TableCell>
                                                            <TableCell className={styles.tableHeadCellStyle} >Action Example</TableCell>
                                                            <TableCell className={styles.tableHeadCellStyle} ></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {carouselCardFormat.format_cta_buttons.map((buttons, index) =>
                                                            <TableRow key={index}>
                                                                <TableCell className={styles.tableCellStyles}>{buttons.cta_action_type.cta_action_type_id}</TableCell>
                                                                <TableCell className={styles.tableCellStyles}>{buttons.cta_button_name}</TableCell>
                                                                <TableCell className={styles.tableCellStyles}>{buttons.cta_action_type.cta_action_type_value}</TableCell>
                                                                <TableCell className={styles.tableCellStyles}>{buttons.cta_action_example}</TableCell>
                                                                {/* <TableCell className={styles.tableCellStyles} onClick={() => handleRemoveCTAButton(buttons.cta_button_id)}><MoreVertIcon /> </TableCell> */}
                                                                <TableCell className={styles.tableCellStyles}>

                                                                    <Button
                                                                        id="basic-button"
                                                                        className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open ? 'true' : undefined}
                                                                        onClick={(event) => handleClickCTAListMenu(event, buttons.cta_button_id)}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </Button>

                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={headerListMenu}
                                                                        open={open}
                                                                        className={styles.moreVerticalIconMenu}
                                                                        onClose={handleCloseCTAListMenu}
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'basic-button',
                                                                        }}
                                                                    >
                                                                        {menuItems.map((item: any, index: any) => (
                                                                            <MenuItem
                                                                                className={globalStyles.menuItemStyle}
                                                                                key={index}
                                                                                onClick={(e) => { item.action(); handleCloseCTAListMenu(); }}
                                                                            // disabled={item.disabled ? item.disabled(rowDetail) : false}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {item.icon}
                                                                                </ListItemIcon>
                                                                                <span>
                                                                                    {item.label}
                                                                                </span>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Menu>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: "100%" }} mt={1}>
                                <Grid container direction={"row"} gap={2} justifyContent={"start"}>
                                    <Grid item>
                                        <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' onClick={() => handleCancelFormatData()} sx={{ minWidth: "130px" }} />
                                    </Grid>
                                    <Grid item>
                                        <GlobalButton buttonText={"Save"} className='primaryButtonStyle' onClick={() => handleSaveFormatData()} sx={{ minWidth: "130px" }} disabled={handleSaveFormatDataDisabled()} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={5} sx={{ backgroundColor: "#F5F5F5", height: "calc(100vh - 90px)", maxHeight: "calc(100vh - 90px)", position: "relative" }}>
                        <Grid container direction={"column"} alignItems={"center"} padding={"20px"} gap={3}>
                            <Grid item sx={{ width: "100%" }} textAlign={"center"}>
                                <span className={styles.previewHeadingTitle}>{carouselCardFormat.format_name} Preview</span>
                            </Grid>

                            <Grid item className={styles.chat_crl_main_item}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: '0px' }}>
                                        <Grid container>
                                            <Grid item xs={12} className={styles.chat_crl_img_item}>
                                                <img
                                                    src={demoCarouselImage}
                                                    alt=""
                                                    style={{ width: '100%', aspectRatio: imagePreview.aspectRatio, objectPosition: "top", borderRadius: "5px", objectFit: "cover" }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} className={styles.chat_crl_img_captions_item} sx={{ margin: '8px 0px', padding: '0px 4px', }}>
                                                <Grid container direction={"column"} alignItems={"start"}>
                                                    <Typography
                                                        component="span"
                                                        className={styles.chat_crl_img_caption_text}
                                                    >
                                                        {carouselCardFormat?.heading_sample_value}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        className={styles.chat_crl_img_caption_text_sub}
                                                    >
                                                        {carouselCardFormat?.subheading_sample_value}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            {carouselCardFormat.format_cta_buttons.map((button: any) => {
                                                return <>
                                                    <Divider style={{ width: '100%' }} />
                                                    <Grid item xs={12} className={styles.chat_crl_media_item} sx={{ cursor: "pointer" }}>
                                                        <Grid container sx={{ justifyContent: 'center', alignItems: "center" }}>
                                                            {/* {getActionButtonIcon(button.message_format_id)} */}
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: '500',
                                                                    color: '#2E79BD',
                                                                    marginLeft: "5px !important",
                                                                }}
                                                            >
                                                                {button.cta_button_name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            })}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ position: "absolute", bottom: "25px", paddingInline: "25px", width: "100%" }}>
                                <Grid container>
                                    <Grid item sx={{ width: "100%" }}>
                                        <Accordion className={styles.accordionImagePreview}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ backgroundColor: "#F7E9FF", borderRadius: "50%", boxShadow: "0px 0px 2px rgba(106, 9, 125, 0.5)", color: "#000000" }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <span className={styles.formFieldLabels}>Image Previews</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container direction={"row"} sx={{ padding: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", borderRadius: "5px", backgroundColor: "#FFFFFF" }} gap={1}>
                                                    {imagePreviews.map((preview) =>
                                                        <Grid item sx={{ backgroundColor: imagePreview.id === preview.id ? "#F6F6F6" : "#FFFFFF", boxShadow: imagePreview.id === preview.id ? "0px 0px 2px rgba(0,0,0,0.25)" : "", padding: "8px", borderRadius: "1px", cursor: "pointer" }}
                                                            onClick={() => setImagePreview(preview)}
                                                        >
                                                            <Grid container direction={"row"} gap={2}>
                                                                <Grid item sx={{ height: "49px", width: preview.width, padding: "1px", borderRadius: "1px", boxShadow: "0px 0px 2px rgba(0,0,0,0.25)", backgroundColor: "#FFFFFF" }}>
                                                                    <Grid container direction={"column"}>
                                                                        <Grid item sx={{ backgroundColor: "#D9D9D9", borderRadius: "1px" }}>
                                                                            <img src={demoCarouselImage} style={{ width: "100%", aspectRatio: preview.aspectRatio, objectPosition: "top" }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {addCTAButtonPopup &&
                    <GlobalPopUp
                        open={addCTAButtonPopup}
                        onClose={() => { setAddCTAButtonPopup(false) }}
                        // title={popupData?.message_format_name ? popupData?.message_format_name : ""}
                        flagBackdrop
                        paperMinWidth={flagSmallScreen ? "310px" : "600px"}
                        paperMaxwidth={flagSmallScreen ? "610px" : "650px"}
                        flagShowCloseIcon={true}
                        handleClose={() => { setAddCTAButtonPopup(false) }}
                    >
                        <AddCTAButton carouselCardFormat={carouselCardFormat} setCarouselCardFormat={setCarouselCardFormat} setAddCTAButtonPopup={setAddCTAButtonPopup} />
                    </GlobalPopUp>
                }

                {flagDeleteCTADataPopup &&
                    <GlobalPopUp
                        open={flagDeleteCTADataPopup}
                        handleClose={() => { setFlagDeleteCTADataPopup(false) }}
                    >
                        <GlobalDeletePopup
                            deleteDescription={`Are you sure you want to delete ${ctaButtonToDeleted?.cta_button_name} button ?`}
                            onClose={() => { setFlagDeleteCTADataPopup(false) }}
                            onDelete={() => handleRemoveCTAButton(ctaButtonToDeleted?.cta_button_id)}
                            sx={{ maxWidth: "400px" }}
                        />
                    </GlobalPopUp>
                }
            </Grid>
        </>
    )
}

export default DoctorCardCarousel
