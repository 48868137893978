import { Grid, Tab, Tabs, } from '@mui/material';
import React, { useEffect } from 'react';
import styles from "../../assets/styles/componentStyles/tab.module.scss";
import { useNavigate } from 'react-router-dom';
interface DynamicTabProps {
    tabs: { label: string; content: React.ReactNode, tabVisibility: boolean, flagInitiallyActive: boolean, flagDisabled?: boolean }[];
    defaultTab?: number;
    tabClassName?: string;
    tabPanelClassName?: string;
    conditionalStyling?: string
    className: string;
    tabHeaderClassname: string;
    value: any,
    setValue: any
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: "100%" }}
            // style={value == 0 ? { height: "100%" } : {}}
            {...other}
        >
            {value === index && (
                // <Box sx={{ paddingTop: "23px" }}>
                <Grid item className={value == 0 ? styles.tabFormWrapper : styles.tabAIOutputWrapper}>{children}</Grid>
                // </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function TabComponent({

    tabs,
    defaultTab = 0,
    tabClassName = '',
    tabPanelClassName = '',
    tabHeaderClassname = '',
    conditionalStyling,
    className,
    value,
    setValue
}: DynamicTabProps) {
    const navigate = useNavigate()

    useEffect(() => {
        if (window.location.pathname == "/dashboard/settings-accountDetails" || window.location.pathname == "/dashboard/settings-plansAndBilling") {
            let tempPath = window.location.pathname
            let tempPathArray = tempPath.split("/")
            if (tempPathArray[tempPathArray.length - 1] == "settings-accountDetails") {
            } else if (tempPathArray[tempPathArray.length - 1] == "settings-plansAndBilling") {
            }
        }
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        if (window.location.pathname == "/dashboard/settings-accountDetails" || window.location.pathname == "/dashboard/settings-plansAndBilling") {
            // if (newValue == 0) {
            //     navigate("/dashboard/settings-accountDetails")
            //     // setTabValuePanel(0)
            // } else if (newValue == 1) {
            //     navigate("/dashboard/settings-plansAndBilling")
            //     // setTabValuePanel(1)
            // }
        } else {
            // setTabValuePanel(newValue);
        }
    };


    return (
        // <Box className={globalStyles.fullwidth} sx={{ height: "100%" }}>
        <>
            <Tabs
                value={value}
                // value={0}
                onChange={handleChange}
                aria-label="dynamic tabs example"
                className={styles[tabHeaderClassname]}
                sx={conditionalStyling ? JSON.parse(conditionalStyling) : {}}
            >
                {tabs.map((tab, index) => (
                    tab.tabVisibility &&
                    <Tab
                        key={index}
                        label={tab.label}
                        disabled={tab.flagDisabled}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>

            <Grid item
                className={styles[className]}
            >
                {tabs.map((tab, index) => (
                    tab.tabVisibility &&
                    <TabPanel
                        key={index}
                        value={value}
                        index={index}
                    >
                        {tab.content}
                    </TabPanel>
                ))}
            </Grid>
        </>
        // </Box>
    );
}
