import React, { useContext, useEffect } from 'react'
import { convertKeysToLowerCase, isJsonString } from '../../../utils/constants'
import { Button, Grid } from '@mui/material'
import Carousel from '../../utility/globalCarousel/carousel';
import InsuranceCard from './chatWidgetComponents/insuranceCard'
import RatingCard from './chatWidgetComponents/ratingCard'
import DropdownTemplate from './chatWidgetComponents/dropdown'
import { ChatbotContext, ChatbotContextPayload } from '../../../context/chatbotContext';
import CustomForm from './chatWidgetComponents/customForm';
import CartFormGlobal from './chatWidgetComponents/cartFormGlobal';
import CartCarouselGlobal from './chatWidgetComponents/cartCarouselGlobal';
import ExtensionDrawer from './chatWidgetComponents/extensionDrawer';
import AppointmentSchedule from './chatWidgetComponents/appointmentSchedule';

function MessageFormatComponents(props: any) {
    const { thread, currentActiveChatbotThread, getThread, drawerProps, setDrawerProps } = props;
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload

    return (
        <>
            {isJsonString(thread.chatbot_thread_value) && typeof JSON.parse(thread.chatbot_thread_value) == "object" && (JSON.parse(thread.chatbot_thread_value).message_format_id) == 2 &&
                <Grid item xs={12}>
                    <Grid container gap="10px" mt={1} marginLeft={1} justifyContent={"end"}>
                        {JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.suggestions.map((ele: any, i: any) => {
                            return (
                                <>
                                    <Button
                                        key={i}
                                        disableRipple
                                        sx={{
                                            backgroundColor: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? currentActiveChatbotThread.chatbot_theme?.chatbot_header_color : chatBotDetails?.chatbot_theme.chatbot_header_color : "#fff !important",
                                            borderColor: currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color} !important` : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#000000",
                                            color: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color ? currentActiveChatbotThread?.chatbot_theme?.chatbot_header_font_color : chatBotDetails?.chatbot_theme.chatbot_header_font_color : currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color ? `${currentActiveChatbotThread?.chatbot_theme?.chatbot_header_color} !important` : chatBotDetails?.chatbot_theme.chatbot_header_color ? chatBotDetails?.chatbot_theme.chatbot_header_color : "#000000",
                                            fontWeight: "600 !important",
                                            borderRadius: "5px !important",
                                            padding: "5px 10px !important",
                                            textTransform: "capitalize",
                                            minWidth: "unset !important",
                                            '&:hover': {
                                                backgroundColor: currentActiveChatbotThread.chatbot_thread_data.find((threadData: any) => Number(threadData.chatbot_thread_seq) === (Number(thread.chatbot_thread_seq) + 1))?.chatbot_thread_value === ele.suggestion_name ? currentActiveChatbotThread?.chatbot_theme?.user_msg_bg_color ? currentActiveChatbotThread.chatbot_theme?.user_msg_bg_color : chatBotDetails?.chatbot_theme.user_msg_bg_color : "#fff !important"
                                            }
                                        }}
                                        // sx={{ backgroundColor: "#fff !important", color: "#000000", fontWeight: "500 !important", borderRadius: "5px !important", padding: "5px 10px !important", minWidth: "unset !important", '&:hover': { color: "", backgroundColor: "" } }}
                                        variant={'outlined'}
                                        size='small'
                                    // className={` ${i == flagTellusMoreOptionBtn ? 'chat-feedback-selected-btn' : 'chat-feedback-btn'}`}
                                    >
                                        {/* {Object.values(ele)[0]} */}
                                        {ele.suggestion_name}
                                    </Button>

                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc((JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id)) == 5 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                    <Carousel suggestions={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))).message_fields.cards} threadData={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} currentActiveChatbotThread={currentActiveChatbotThread} />
                </Grid>
            }


            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 6 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                    <InsuranceCard data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 7 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12}>
                    <RatingCard thread={thread} nextThread={getThread(thread)} data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 8 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12} sx={{boxShadow: "0px 0px 2px rgba(0,0,0,0.25)", borderRadius: "10px"}} mt={1}>
                    <AppointmentSchedule thread={thread} nextThread={getThread(thread)} data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields} chatbotTheme={currentActiveChatbotThread.chatbot_theme} />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 9 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12}>
                    <DropdownTemplate thread={thread} nextThread={getThread(thread)} data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={currentActiveChatbotThread.chatbot_theme} drawerProps={drawerProps} setDrawerProps={setDrawerProps} />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 10 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12}>
                    <CartCarouselGlobal 
                        data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} 
                        threadId={thread.chatbot_thread_id}
                        thread={thread}
                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                    />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 12 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={6}>
                    <CustomForm
                        thread={thread}
                        data={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")))}
                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                    />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 15 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12} justifyItems={"end"}>
                    <CartFormGlobal
                        data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))}
                        thread={currentActiveChatbotThread}
                        cartData={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.cards}
                        ctaButtons={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.CTA_buttons}
                        threadId={thread.chatbot_thread_id}
                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                    />
                </Grid>
            }

            {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 17 && !JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))?.error_message &&
                <Grid item xs={12}>
                    <ExtensionDrawer
                        thread={thread} 
                        threadData={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")))} 
                        fields={convertKeysToLowerCase(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))).message_fields} 
                        threadId={thread.chatbot_thread_id} 
                        chatbotTheme={currentActiveChatbotThread.chatbot_theme}
                    />
                </Grid>
            }
        </>
    )
}

export default MessageFormatComponents