import React, { useContext, useState } from 'react'
import { createLeadGenerationPayloadModel, getLeadIntegrationTypeDataResponse } from '../../../models/axiosModel'
import { Grid } from '@mui/material'
import TemplateCard from '../../global/templateCard'
import spreadsheetIcon from './../../../assets/images/leadGeneration/spreadsheet.svg'
import slackIcon from './../../../assets/images/leadGeneration/slack.svg'
import mailIcon from './../../../assets/images/leadGeneration/mail.svg'
import IconsColor from '../../utility/iconsColor'
import GlobalButton from '../../utility/globalButton/button'
import styles from './../../../assets/styles/componentStyles/leadGenerationStyles/leadGenerationCard.module.scss'
import { LeadIntegrationContext, LeadIntegrationContextPayload } from '../../../context/leadIntegrationContext'
import { useNavigate, useSearchParams } from 'react-router-dom'

const LeadGenerationIntegrationCards = () => {
    const { leadIntegrationDataType, selectedIntegrationType, setAddIntegrationPopup, setSelectedIntegrationType, leadGenerationList } = useContext(LeadIntegrationContext) as LeadIntegrationContextPayload
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const chatbot_id = searchParams.get('chatbot_id');
    const lead_id = searchParams.get('lead_id');

    const handleLeadIntegration = (integration: getLeadIntegrationTypeDataResponse) => {
        // setAddIntegrationPopup(false);
        setSelectedIntegrationType(integration)
        if (lead_id) {
            navigate(`/chatbot/viewchatbot/leadgeneration/${integration.lead_integration_type_name.toLowerCase()}?chatbot_id=${chatbot_id}&lead_id=${lead_id}`)
        }
    }

    const checkDisabled = (name: string) => {
        if (name === "Email") {
            if (leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Email")) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (name === "Slack") {
            if (leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Slack")) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (leadGenerationList?.chatbot_lead_data[0].chatbot_lead_integration_data?.find((data) => data.chatbot_lead_integration_type_name === "Spreadsheet")) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    const getIntegrationObj = (integrations: getLeadIntegrationTypeDataResponse) => {
        const integrationName = integrations.lead_integration_type_name.toLowerCase();
        switch (integrationName) {
            case "spreadsheet":
                return {
                    image: <img alt="spreadsheet" src={spreadsheetIcon} height={"24"} width={"24"} />,
                    description: "Effectively communicate with customers using WhatsApp integration.",
                    actionItem: <GlobalButton buttonText={"Integrate"} sx={{ width: "120px" }} disabled={checkDisabled(integrations.lead_integration_type_name)} onClick={() => handleLeadIntegration(integrations)} className={selectedIntegrationType.lead_integration_type_id === integrations.lead_integration_type_id ? "primaryButtonStyle" : "channelsThemeBtnStyle"} />
                }
            case "slack":
                return {
                    image: <img alt="slack" src={slackIcon} height={"24"} width={"24"} />,
                    description: "Connect Slack to receive instant updates and engage with users directly from your workspace.",
                    actionItem: <GlobalButton buttonText={"Integrate"} sx={{ width: "120px" }} disabled={checkDisabled(integrations.lead_integration_type_name)} onClick={() => handleLeadIntegration(integrations)} className={selectedIntegrationType.lead_integration_type_id === integrations.lead_integration_type_id ? "primaryButtonStyle" : "channelsThemeBtnStyle"} />
                }
            case "email":
                return {
                    image: <img alt="Email" src={mailIcon} height={"24"} width={"24"} />,
                    description: "Integrate email for instant updates and direct engagement with users.",
                    actionItem: <GlobalButton buttonText={"Integrate"} sx={{ width: "120px" }} disabled={checkDisabled(integrations.lead_integration_type_name)} onClick={() => handleLeadIntegration(integrations)} className={selectedIntegrationType.lead_integration_type_id === integrations.lead_integration_type_id ? "primaryButtonStyle" : "channelsThemeBtnStyle"} />
                }
            default:
                return {
                    image: <IconsColor iconName="" width="25px" height="25px" />,
                    description: "",
                    actionItem: <GlobalButton buttonText={"Integrate"} className="channelsThemeBtnStyle" />
                }
        }
    }

    return (
        <Grid container>
            {leadIntegrationDataType?.map((datatype) => {
                let data = getIntegrationObj(datatype);
                return (
                    data && (
                        <TemplateCard
                            key={datatype.lead_integration_type_id} // Add a unique key prop
                            cardName={datatype.lead_integration_type_name}
                            cardDescription={data.description}
                            cardImage={data.image}
                            cardOnClick={() => { }}
                            cardActionItem={data.actionItem}
                            class={selectedIntegrationType.lead_integration_type_id === datatype.lead_integration_type_id ? styles.selectedLeadIntegration : styles.nonSelectedLeadIntegration}
                        />
                    )
                );
            })}
        </Grid>
    )
}

export default LeadGenerationIntegrationCards
