import { Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import style from '../../../assets/styles/componentStyles/campaignContactStyles/campaignContactStyle.module.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import globalStyles from "../../../assets/styles/global.module.scss";
import { getCampaignContactListResponse } from '../../../models/axiosModel';
import { formatDateAccordingToTimezoneUsingMoment } from '../../../utils/constants';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import ContactListIcon from "../../../assets/images/campaignContact/ContactListIcon.png";

interface ListCardProps {
    list: getCampaignContactListResponse,
    onClickHandler: (id: number) => void,
    menuItem: any,
}

const ListCard: React.FC<ListCardProps> = ({ list, onClickHandler, menuItem }) => {

    const { userDetail } = useContext(UserContext) as UserContextPayload;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentCardIndex, setCurrentCardIndex] = useState<number | null>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
        setCurrentCardIndex(id);
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setCurrentCardIndex(null);
        setAnchorEl(null);
    }

    const onClickOfMoreVertIcon = (e: any) => {
        e.stopPropagation();
        handleMenuOpen(e, list.contact_list_id);
    }

    const onCloseOfMenu = (e: any) => {
        e.stopPropagation();
        handleMenuClose();
    }

    return (
        <Grid container gap={1} onClick={() => { onClickHandler(list.contact_list_id) }} className={style.contactListContainer}>
            <Grid item xs={12}>
                <Grid container gap={1} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <img src={ContactListIcon} style={{ fontSize: "24px" }} />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"} gap={1}>
                            <Grid item className={style.contactBoxGrid}>
                                <Typography className={style.contactTitleTypography}>Contacts: {list.total_contact}</Typography>
                            </Grid>
                            <Grid item className={style.morevertIconGridItem}>
                                <MoreVertIcon
                                    className={style.contactListMoreVertIcon}
                                    onClick={(e: any) => { onClickOfMoreVertIcon(e); }}
                                />
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl) && currentCardIndex === list.contact_list_id}
                                    onClose={(e: any) => { onCloseOfMenu(e) }}
                                >
                                    {menuItem && menuItem.map((item: any, ind: number) => {
                                        return (
                                            <MenuItem
                                                className={globalStyles.menuItemStyle}
                                                key={ind}
                                                onClick={(e) => { e.stopPropagation(); handleMenuClose(); item.action(list) }}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <span>{item.label}</span>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography className={style.contactListNameTitle}>{list.contact_list_name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Typography className={style.updatedAtTypography}>Updated At</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={style.timezoneTitleTypography}>{formatDateAccordingToTimezoneUsingMoment(list.updated_at_timestamp != null ? list.updated_at_timestamp : list.created_at_timestamp, userDetail.timezone_hour)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ListCard
