import React, { useContext } from 'react'
import GlobalPopUp from '../../../utility/globalPopUp'
import { Grid } from '@mui/material';
import ConfirmationPopover from '../../../utility/confirmationPopover';
import { chatbotIntegrationAssignmentData, globalIntegration } from '../../../../models/axiosModel';
import GlobalButton from '../../../utility/globalButton/button';
import IconsColor from '../../../utility/iconsColor';
import { ChatbotContext, ChatbotContextPayload } from '../../../../context/chatbotContext';
import FacebookInformationCard from '../../../utility/facebookInformationCard';
import { useSearchParams } from 'react-router-dom';

interface addChatbotIntegrationDetailsInterface {
    meta_integration_id: string,
    chatbot_id: string,
    instagram_id: string,
    page_id: string,
    meta_integration_type_id: string
}

interface ShowAvailableInstagramProps {
    flagShowAvailableNumbers: boolean,
    setFlagShowAvailableNumbers: React.Dispatch<React.SetStateAction<boolean>>;
    smallScreen: boolean,
    showConfirmationPopover: boolean,
    confirmationPopoverAnchorEl: null | HTMLButtonElement,
    handleCloseConfirmationPopover: () => void,
    addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface,
    AddChatbotIntegration: (addChatbotIntegrationDetails: addChatbotIntegrationDetailsInterface) => void;
    availablePhoneNumberData: globalIntegration[],
    handleRemoveChatbotIntegration: (meta_integration_id: number, meta_integration_type_id: number, instagram_id: string, page_id: string, display_page_name: string) => void,
    displayPhoneNumber: string,
    launchWhatsAppSignup: () => void
    saveChatbotIntegrationData: (meta_integration_id: number, meta_integration_type_id: number, instagram_id: string, page_id: string, display_page_name: string, event?: React.MouseEvent<HTMLButtonElement>) => void
}

function ShowAvailableInstagramPages(props: ShowAvailableInstagramProps) {
    const { chatBotDetails } = useContext(ChatbotContext) as ChatbotContextPayload;
    const [searchParams, setSearchParams] = useSearchParams()
    const { flagShowAvailableNumbers, setFlagShowAvailableNumbers, smallScreen, showConfirmationPopover, confirmationPopoverAnchorEl, handleCloseConfirmationPopover, addChatbotIntegrationDetails, AddChatbotIntegration, availablePhoneNumberData, handleRemoveChatbotIntegration, launchWhatsAppSignup, displayPhoneNumber, saveChatbotIntegrationData } = props

    return (
        <div>
            <GlobalPopUp
                open={flagShowAvailableNumbers}
                handleClose={() => { setFlagShowAvailableNumbers(false) }}
                flagShowCloseIcon
                title='Add Instagram Pages'
                paperMinWidth={smallScreen ? '85%' : '90vw'}
                height='90vh'
            >
                <Grid container gap="20px" direction={"column"} sx={{ padding: "30px" }} mt={{ xs: 1, lg: 0 }}>
                    <Grid item>
                        <Grid container gap="15px">
                            <ConfirmationPopover
                                open={showConfirmationPopover}
                                anchorEl={confirmationPopoverAnchorEl}
                                handleClose={handleCloseConfirmationPopover}
                                onConfirmation={() => { AddChatbotIntegration(addChatbotIntegrationDetails) }}
                                descriptionText={<p>Are you sure you want to deploy <span style={{ fontWeight: "600" }}>{chatBotDetails?.chatbot_name}</span> on <span style={{ fontWeight: "600" }}>{displayPhoneNumber}</span> ?</p>}
                            />
                            {availablePhoneNumberData.filter((data) => data.meta_integration_type_assignment_data.meta_integration_type_name === "Instagram" && data.meta_integration_facebook_data?.instagram_id).length > 0 && availablePhoneNumberData.filter((data) => data.meta_integration_type_assignment_data.meta_integration_type_name === "Instagram" && data.meta_integration_facebook_data?.instagram_id).map((integrationData, index) => (
                                <>
                                    <Grid container direction={"column"} gap="15px" sx={{ width: "auto" }}>
                                        {/* <Grid item className={globalStyles.globalHeaderFontStyle}>{integrationData?.meta_integration_facebook_data?.page_name}</Grid> */}
                                        <Grid item>
                                            <Grid container gap="15px">
                                                <FacebookInformationCard
                                                    integrationType='Instagram'
                                                    key={index}
                                                    flagShowLinkedChatbot
                                                    flagDelete={false}
                                                    title={`WhatsApp Account ${index + 1}`}
                                                    currentlySelected={integrationData?.meta_integration_facebook_data?.chatbot_integration_assignment_data.some((chatbot: chatbotIntegrationAssignmentData) => chatbot.chatbot_id.toString() == searchParams.get('chatbot_id')) || false}
                                                    showRemoveChatbotIntegration={false}
                                                    disabled={integrationData?.meta_integration_facebook_data?.chatbot_integration_assignment_data && integrationData?.meta_integration_facebook_data?.chatbot_integration_assignment_data.length > 0 ? true : false}
                                                    removeChatbotIntegration={() => { handleRemoveChatbotIntegration(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, integrationData.meta_integration_facebook_data?.instagram_id || "", integrationData.meta_integration_facebook_data?.page_id || "", integrationData.meta_integration_facebook_data?.instagram_username || "") }}
                                                    addChatbotIntegration={integrationData.meta_integration_facebook_data?.chatbot_integration_assignment_data.length == 0 ? (instagram_id: any, event: any) => {
                                                        if (event) {
                                                            saveChatbotIntegrationData(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, instagram_id || "", integrationData.meta_integration_facebook_data?.page_id || "", integrationData.meta_integration_facebook_data?.instagram_username || "", event)
                                                        } else {
                                                            saveChatbotIntegrationData(integrationData.meta_integration_id, integrationData.meta_integration_type_assignment_data.meta_integration_type_id, instagram_id || "", integrationData.meta_integration_facebook_data?.page_id || "", integrationData.meta_integration_facebook_data?.instagram_username || "")
                                                        }
                                                    } : undefined}
                                                    details={{
                                                        page_id: integrationData?.meta_integration_facebook_data?.instagram_id,
                                                        page_name: integrationData?.meta_integration_facebook_data?.instagram_username,
                                                        page_category: integrationData?.meta_integration_facebook_data?.page_category,
                                                        linked_chatbots: integrationData?.meta_integration_facebook_data?.chatbot_integration_assignment_data
                                                    }}
                                                    flagIntegration={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <GlobalButton buttonText={"Instagram Account"} onClick={launchWhatsAppSignup} className={'blackShadowedButton'}
                            startIcon={<IconsColor fillColor="#A5A5A5" iconName="plus" width="19px" height="19px" />}
                        />
                    </Grid>


                </Grid>
            </GlobalPopUp>
        </div>
    )
}

export default ShowAvailableInstagramPages