import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { flagUseSocketForParaphrase } from '../../utils/constants';

const RoundLoader = () => {
    const { loadingURL, flagLoading, setFlagLoading } = useContext(UserContext) as UserContextPayload;


    // @ts-ignore
    const handleAbortAPI = () => {
        if (flagUseSocketForParaphrase) {
            // @ts-ignore
            socketAPIBuffer[socketAPIBuffer.length - 1].flagCancel = true
            setFlagLoading((prevState) => ({ ...prevState, flagContentGenerating: false }))
        } else {
            // @ts-ignore 
            let test = apiBuffer.filter((api: any) => api.name == loadingURL)
            if (test.length > 0) {
                // @ts-ignore
                apiBuffer[apiBuffer.length - 1].controller.abort();
            }
        }
    }
    return (
        <>
            {flagLoading.flagContentGenerating &&
                <React.Fragment>
                    <Box
                        id="stickyBottom"
                        sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                            height: "100%",
                            width: "100%",
                        }}>
                        {/* <ParaphraserLoader
                            stopGenerating={handleAbortAPI}
                        /> */}

                    </Box>
                </React.Fragment>
            }
        </>

    )
};

export default RoundLoader;