export const initialLeadGeneraionData = {
    chatbot_id: 0,
    chatbot_lead_name: "",
    chatbot_lead_description: "",
    chatbot_lead_field_data: [
        {
            field_id: 0,
            chatbot_lead_field_type_id: "1",
            chatbot_lead_field_name: "Browser Name",
            chatbot_lead_field_description: "Browser Description",
            chatbot_lead_field_enum_value: ""
        },
        {
            field_id: 1,
            chatbot_lead_field_type_id: "1",
            chatbot_lead_field_name: "Geo Location",
            chatbot_lead_field_description: "Geo Location Description",
            chatbot_lead_field_enum_value: ""
        },
        {
            field_id: 2,
            chatbot_lead_field_type_id: "1",
            chatbot_lead_field_name: "Timestamp",
            chatbot_lead_field_description: "Timestamp Description",
            chatbot_lead_field_enum_value: ""
        }
    ]
}

export const initialLeadGenerationField = {
    field_id: 0,
    chatbot_lead_field_type_id: "",
    chatbot_lead_field_id: 0,
    chatbot_lead_field_name: "",
    chatbot_lead_field_description: "",
    chatbot_lead_field_enum_value: ""
}

// export const genericFieldDataTypeStatic = [
//     {
//         field_type_id: 1,
//         field_type_name: "string"
//     },
//     {
//         field_type_id: 2,
//         field_type_name: "number"
//     },
//     {
//         field_type_id: 3,
//         field_type_name: "boolean"
//     }
// ]

export const leadIntegrationDataTypeStatic = [
    {
        lead_integration_type_id: 1,
        lead_integration_type_name: "Spreadsheet"
    },
    {
        lead_integration_type_id: 2,
        lead_integration_type_name: "Slack"
    },
    {
        lead_integration_type_id: 3,
        lead_integration_type_name: "Email"
    }
]

export const leadFieldsData = [
    {
        chatbot_lead_field_id: 0,
        chatbot_lead_field_name: "Browser Name",
    },
    {
        chatbot_lead_field_id: 1,
        chatbot_lead_field_name: "Timestamp",
    },
    {
        chatbot_lead_field_id: 2,
        chatbot_lead_field_name: "Geo Location",
    },
    {
        chatbot_lead_field_id: 3,
        chatbot_lead_field_name: "Phone",
    },
    {
        chatbot_lead_field_id: 4,
        chatbot_lead_field_name: "Name",
    },
    {
        chatbot_lead_field_id: 5,
        chatbot_lead_field_name: "Age",
    },
    {
        chatbot_lead_field_id: 6,
        chatbot_lead_field_name: "Gender",
    },
]

export const initialEmailConfigPayload = {
    sg_email_config_id: null,
    email_name: "",
    email_to: "",
    email_subject: "",
    email_content: ""
}

export const initialSlackConfigPayload = {
    slack_config_id: null,
    slack_integration_id: null,
    slack_channel_id: "",
    slack_content: ""
}

export const initialIntegrationPayload = {
    lead_integration_type_id: 0,
    lead_integration_type_name: ""
}

export const initialContentType = {
    field_id: 0,
    field_name: ""
}