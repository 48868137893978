import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import globalStyles from '../../../../../assets/styles/global.module.scss';
import calenderIcon from '../../../../../assets/images/channels/calendly/calendarIcon.svg';
import TemplateCard from '../../../../global/templateCard';
import GlobalButton from '../../../../utility/globalButton/button';
import { CalendlyContext } from '../../../../../context/calendlyIntegrationContext';
import ConfirmationPopover from '../../../../utility/confirmationPopover';
import { ChatbotContext, ChatbotContextPayload } from '../../../../../context/chatbotContext';
import styles from './../../../../../assets/styles/componentStyles/calendlyStyles/calendly.module.scss';

const SelectCalendlyEvent = () => {
    const calendlyContext = useContext(CalendlyContext);
    const { selectedEvent, setSelectedEvent, calendlyIntegrationData, genericChatbotCalendlyData } = calendlyContext || {};
    const [showConfirmationPopover, setShowConfirmationPopover] = useState(false);
    const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = useState<any>(null);
    const [eventToToggle, setEventToToggle] = useState<number | null>(null);
    const [initialSelection, setInitialSelection] = useState<number[]>([]);

    useEffect(() => {
        if (genericChatbotCalendlyData && genericChatbotCalendlyData.length > 0 && calendlyIntegrationData) {
            const storedEventIds = genericChatbotCalendlyData.flatMap((account: any) => {
                if (account.calendly_integration_id == calendlyIntegrationData[0].calendly_integration_id) {
                    return account.chatbot_calendly_assignment_data.map((assignment: any) => assignment.calendly_event_type_id)
                } else {
                    return []
                }
            }
            );
            if (storedEventIds.length > 0) {
                setSelectedEvent([storedEventIds[0]]);
                // setInitialSelection([storedEventIds[0]]);
            }
        }
    }, [genericChatbotCalendlyData, setSelectedEvent]);

    const handleSelectEvent = (cardId: number, event: React.MouseEvent<HTMLElement>) => {
        setEventToToggle(cardId);

        if (selectedEvent && selectedEvent.length > 0) {
            // Show confirmation only if there's an initial selection
            setConfirmationPopoverAnchorEl(event.currentTarget);
            setShowConfirmationPopover(true);
        } else {
            // If there's no initial selection, directly update the selection
            setSelectedEvent([cardId]);
        }
    };

    const handleConfirmEvent = () => {
        setShowConfirmationPopover(false);
        if (eventToToggle !== null) {
            if (selectedEvent?.includes(eventToToggle)) {
                setSelectedEvent([]);
            } else {
                setSelectedEvent([eventToToggle]);
            }
        }
        setConfirmationPopoverAnchorEl(null);
        setEventToToggle(null);
    };

    const handleCancelEvent = () => {
        setShowConfirmationPopover(false);
        setConfirmationPopoverAnchorEl(null);
        setEventToToggle(null);
    };

    const isEventSelected = (calendlyEventId: number) => {
        return selectedEvent?.includes(calendlyEventId);
    };

    return (
        <Grid container direction="column" gap={"15px"} sx={{ paddingLeft: '15px' }}>
            <Grid item>
                {calendlyIntegrationData && calendlyIntegrationData[0]?.calendly_event_type_data?.length > 0 ? (
                    <Grid container direction="column" gap={3}>
                        <Grid item>
                            <span className={`${globalStyles.globalLibraryFontSize} ${globalStyles.globalHeaderFontWeight}`}>
                                Available Calendly Event Types
                            </span>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                                {calendlyIntegrationData[0]?.calendly_event_type_data?.map((card) => {
                                    const isSelected = isEventSelected(card.calendly_event_type_id);

                                    return (
                                        <TemplateCard
                                            key={card.calendly_event_type_id}
                                            cardImage={<img src={calenderIcon} alt='calenderIcon' />}
                                            cardName={card.event_type_name}
                                            cardDescription={card.event_type_description}
                                            cardActionItem={
                                                <GlobalButton
                                                    buttonText={isSelected ? 'Selected' : 'Select'}
                                                    className={isSelected ? 'calendlySelectedEventBtnStyle' : 'channelsThemeBtnStyle'}
                                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                        event.stopPropagation();
                                                        handleSelectEvent(card.calendly_event_type_id, event);
                                                    }}
                                                />
                                            }
                                            cardOnClick={() => { }}
                                            class={isSelected ? styles.calendlySelectedEventCardStyle : ''}
                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container className={styles.setupDiv} style={{ justifyContent: "center" }}>
                        <Grid item className={styles.setupDivText}>
                            No Event Types Available
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item>
                <ConfirmationPopover
                    open={showConfirmationPopover}
                    anchorEl={confirmationPopoverAnchorEl}
                    handleClose={handleCancelEvent}
                    onConfirmation={handleConfirmEvent}
                    descriptionText={<p style={{ margin: '0px' }}>Are you sure you want to change the selected Calendly event type?</p>}
                />
            </Grid>
        </Grid>
    );
};

export default SelectCalendlyEvent;